import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

const UserAlert = ({ text, color, isOpen }) => (
  <Alert color={color} isOpen={isOpen}>
    {text}
  </Alert>
);

UserAlert.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  isOpen: PropTypes.bool,
};

UserAlert.defaultProps = {
  text: null,
  isOpen: true,
  color: 'warning',
};

export default UserAlert;
