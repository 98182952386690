import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { Button, Row, Col } from 'reactstrap';
import InventoryColumnDragSource from './InventoryColumnDragSource';

let containerId = 1;

class InventoryColumnDropTarget extends Component {

  constructor(initialProps) {
    super(initialProps);
    this.containerId = containerId++;
  }

  render() {
    const isActive = this.props.canDrop && this.props.isOver;
    const backgroundColor = isActive ? '#F8E81C' : '#FFF';
    return this.props.connectDropTarget((
      <div style={{ backgroundColor }}>
        {(!this.props.inventoryColumns || !this.props.inventoryColumns.length) ?
          <div style={{ border: '1px dashed gray', padding: '0.5rem 1rem', margin: '.5rem', backgroundColor: 'white', cursor: 'move', lineHeight: '1.0' }}>
            <span className="text-muted" style={{ fontSize: '.65em' }}>
              Drag columns from the categories on the right to define the data that is included in this file format.
            </span>
          </div>
          :
          null
        }
        {this.props.inventoryColumns && this.props.inventoryColumns.map((inventoryColumn, index) => (
          <InventoryColumnDragSource
            containerId={this.containerId}
            index={index}
            inventoryColumn={inventoryColumn}
            showDescription={this.props.showDescription}
            showExample={this.props.showExample}
            reorderColumns={this.props.reorderColumns}
            removeColumn={this.props.removeColumn}
            key={inventoryColumn.name}
          />
        ))}
      </div>
    ));
  }
}

const containerTarget = {
  drop(props, monitor, component) {
    const item = monitor.getItem();
    if (props.addColumn && item.containerId !== component.containerId) {
      props.addColumn(item.inventoryColumn);
    }
  },

  canDrop(props, monitor) {
    const item = monitor.getItem();
    const existing = props.inventoryColumns.filter(current => current.invColumnId === item.inventoryColumn.invColumnId);
    return !!props.addColumn && !existing.length;
  },
};

InventoryColumnDropTarget.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  inventoryColumns: PropTypes.array,
  showDescription: PropTypes.bool,
  showExample: PropTypes.bool,
  addColumn: PropTypes.func,
  reorderColumns: PropTypes.func,
  removeColumn: PropTypes.func,
};

InventoryColumnDropTarget.defaultProps = {
  inventoryColumns: [],
  showDescription: true,
  showExample: true,
  addColumn: null,
  reorderColumns: null,
  removeColumn: null,
};

export default DropTarget('INV_COL', containerTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(InventoryColumnDropTarget);
