import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedCustomers = ({ selectedCustomers, disabled, deselectCustomer }) => <FilterItemList items={selectedCustomers} textField="name" disabled={disabled} onRemoveItem={deselectCustomer} />;

SelectedCustomers.propTypes = {
  selectedCustomers: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool.isRequired,
  deselectCustomer: PropTypes.func.isRequired,
};

SelectedCustomers.defaultProps = {
  selectedCustomers: [],
};

export default SelectedCustomers;
