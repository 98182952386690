/* eslint-disable no-param-reassign */
import immutable from 'object-path-immutable';
import filter from 'lodash/filter';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ATGSensorsActionTypes from '../ATGSensorsActionTypes';
import * as siteSelectors from '../../../../../SiteSelectors';
import * as selectors from '../ATGSensorsSelectors';
import { TankClient, SensorClient } from '../../../../../../../client';


function requestSiteSensors() {
  return {
    type: ATGSensorsActionTypes.REQUEST_SITE_SENSORS,
  };
}

function receiveSiteSensors(sensors) {
  return {
    type: ATGSensorsActionTypes.RECEIVE_SITE_SENSORS,
    currentSiteSensors: sensors,
  };
}

function receiveSiteSensorsError(error) {
  return {
    type: ATGSensorsActionTypes.RECEIVE_SITE_SENSORS_ERROR,
    error,
  };
}

function requestSiteTanks() {
  return {
    type: ATGSensorsActionTypes.REQUEST_SITE_TANKS,
  };
}

function receiveSiteTanks(tanks) {
  return {
    type: ATGSensorsActionTypes.RECEIVE_SITE_TANKS,
    tanks,
  };
}

function receiveEditSensor(editSensor) {
  return {
    type: ATGSensorsActionTypes.RECEIVE_EDIT_SENSOR,
    editSensor,
  };
}

export function toggleEditEquipmentModal(modalOpen, sensor) {
  return {
    type: ATGSensorsActionTypes.TOGGLE_EDIT_EQUIPMENT_MODAL,
    modalOpen: !modalOpen,
    editSensor: sensor,
  };
}

export function associateEquipment(associatedTanks) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentTanks = selectors.tanks(currentState);
    const currentSensor = selectors.editSensor(currentState);
    const matchedTanks = filter(currentTanks, ['interstitialSensorId', currentSensor.id]);
    const removedTanks = difference(matchedTanks, associatedTanks);

    const immutableCurrentTanks = cloneDeep(currentTanks);

    const doAdded = immutableCurrentTanks.map((ctank) => {
      associatedTanks.map((aTank) => {
        if (ctank.id === aTank.id) {
          ctank.interstitialSensorId = currentSensor.id;
        }
        return ctank;
      });
      return ctank;
    });
    const doRemoved = doAdded.map((ctank) => {
      removedTanks.map((rtank) => {
        if (ctank.id === rtank.id) {
          ctank.interstitialSensorId = null;
        }
        return ctank;
      });
      return ctank;
    });

    dispatch(receiveSiteTanks(doRemoved));

    const updatedSensor = immutable.set(currentSensor, 'associatedTanks', associatedTanks);
    dispatch(receiveEditSensor(updatedSensor));
  };
}

export function beginSaveAssociatedEquipment() {
  return {
    type: ATGSensorsActionTypes.BEGIN_SAVE_ASSOCIATED_EQUIPMENT,
  };
}

function saveAssociatedEquipmentFailure(error) {
  return {
    type: ATGSensorsActionTypes.SAVE_ASSOCIATED_EQUIPMENT_FAILURE,
    error,
  };
}

export function getSiteSensors() {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentSite = siteSelectors.currentSite(currentState);
    if (!currentSite) return Promise.resolve();
    const siteId = currentSite.id;
    dispatch(requestSiteSensors());
    dispatch(requestSiteTanks());
    const promises = [
      SensorClient.getSiteSensors(siteId),
      TankClient.getTanks(siteId),
    ];
    return Promise.all(promises).then(([sensors, tanks]) => {
      sensors.forEach((sensor) => {
        sensor.associatedTanks = [];
        return tanks.forEach((tank) => {
          tank.atgTankLabel = `T${tank.tankNumber} ${tank.productLabel}`;
          if (tank.interstitialSensorId === sensor.id) {
            return sensor.associatedTanks.push(tank);
          }
        });
      });
      dispatch(receiveSiteSensors(sensors));
      dispatch(receiveSiteTanks(tanks));
    }).catch((err) => {
      dispatch(receiveSiteSensorsError(err));
      return Promise.reject(err);
    });
  };
}

export function saveAssociatedEquipment(sensor) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentSite = siteSelectors.currentSite(currentState);
    const currentTanks = selectors.tanks(currentState);
    if (!currentSite) return Promise.resolve();
    const siteId = currentSite.id;
    dispatch(beginSaveAssociatedEquipment());
    const promises = currentTanks.map(tank => SensorClient.setAssociatedSensors(siteId, tank.id, { interstitialSensorId: tank.interstitialSensorId }));
    Promise.all(promises).then(() => {
      dispatch(toggleEditEquipmentModal(true, sensor));
      dispatch(getSiteSensors());
    }).catch((err) => {
      dispatch(saveAssociatedEquipmentFailure(err.response.data.error));
      return Promise.reject(err);
    });
  };
}
