import { combineReducers } from 'redux';

import ATGReducer from './screens/atg/ATGReducer';
import TankListReducer from './screens/tank-list/TankListReducer';
import TankRegistrationReducer from './screens/tank-registration/TankRegistrationReducer';
import ATGLinesReducer from './screens/atg-lines/ATGLinesReducer';
import ATGSensorsReducer from './screens/atg-sensors/ATGSensorsReducer';
import EquipmentCountsReducer from './EquipmentCountsReducer';

export default combineReducers({
  atg: ATGReducer,
  tankList: TankListReducer,
  reg: TankRegistrationReducer,
  lines: ATGLinesReducer,
  sensors: ATGSensorsReducer,
  counts: EquipmentCountsReducer,
});
