import { combineReducers } from 'redux';
import MviEditReducer from './screens/edit/MviEditReducer';
import SiteMviListReducer from './screens/list/SiteMviListReducer';
import MviActionTypes from './MviActionTypes';

function MviOverviewReducer(state = {}, action) {
  switch (action.type) {
    case MviActionTypes.GET_LATEST_MVI_STARTED:
      return {
        loading: true,
      };
    case MviActionTypes.RECEIVE_LATEST_MVI:
      return {
        latestMvi: action.latestMvi
      };
    case MviActionTypes.GET_LATEST_MVI_FAILED:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export default combineReducers({
  edit: MviEditReducer,
  list: SiteMviListReducer,
  overview: MviOverviewReducer,
});
