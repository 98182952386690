import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Row, Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';

import { track } from '../../../../../../shared/analytics';
import CanaryLoadingIndicator from '../../../../../../shared/components/CanaryLoadingIndicator';
import { PresentAlert } from '../../../../../../shared/ToastUtils';
import organizationPermissions from '../../../../../../organization-permissions';
import { HasPermission } from '../../../../../../authentication';
import IssueDropTarget from './IssueDropTarget';
import { IssuePriorities, WorkflowStatusTypes } from '../../../../../../AppConstants';
import * as SiteSelectors from '../../../../SiteSelectors';
import * as IssueActions from '../issue-edit/actions';
import * as IssueSelectors from '../issue-edit/IssueEditSelectors';
import * as actions from './OrganizerActions';
import * as selectors from './OrganizerSelectors';

class IssueOrganizerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issues: [],
      removedIssueIds: [],
      newIssueName: '',
    };
    this.newIssueIndex = 0;

    this.setIssues = this.setIssues.bind(this);
    this.navigateToIssue = this.navigateToIssue.bind(this);
    this.newIssueNameChanged = this.newIssueNameChanged.bind(this);
    this.addNewIssue = this.addNewIssue.bind(this);
    this.handleMoveAlarm = this.handleMoveAlarm.bind(this);
    this.handleRemoveIssue = this.handleRemoveIssue.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentWillMount() {
    this.setIssues(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setIssues(nextProps);
  }

  setIssues(props) {
    if (props.issueOrganizerVisible && props.issue) {
      const issues = [props.issue].concat(props.issue.relatedIssues).map((currentIssue) => {
        const clonedIssue = cloneDeep(currentIssue);
        clonedIssue.dndKey = clonedIssue.id;
        return clonedIssue;
      });
      this.setState({
        issues,
        removedIssueIds: [],
        newIssueName: '',
      });
    }
  }

  navigateToIssue(issue) {
    if (issue && !issue.isNewIssue) {
      track('Issue', {
        Component: 'Issue organizer',
        'Issue action': 'Nav to issue',
        'Issue type': issue.issueType,
      });

      this.props.onCloseIssueOrganizer();
      this.props.selectIssueById(issue);
    }
  }

  newIssueNameChanged(e) {
    this.setState({ newIssueName: e.target.value });
  }

  addNewIssue(e) {
    track('Issue', {
      Component: 'Issue organizer',
      'Issue action': 'Add issue'
    });
    e.preventDefault();
    this.newIssueIndex -= 1;
    const newIssue = {
      dndKey: this.newIssueIndex,
      isNewIssue: true,
      description: this.state.newIssueName,
      workflow: {
        status: WorkflowStatusTypes.AwaitingApproval
      },
      priority: IssuePriorities.NORMAL,
      alarms: [],
    };
    const issues = cloneDeep(this.state.issues);
    issues.push(newIssue);
    this.setState({ issues, newIssueName: '' });
  }

  handleMoveAlarm(sourceIssue, destIssue, alarm) {
    track('Issue', {
      Component: 'Issue organizer',
      'Issue action': 'Move alarm'
    });
    const updatedIssues = this.state.issues.map((currentIssue) => {
      if (currentIssue.dndKey === sourceIssue.dndKey) {
        const newSourceIssue = cloneDeep(sourceIssue);
        newSourceIssue.alarms = newSourceIssue.alarms.filter(currentAlarm => currentAlarm.id !== alarm.id);
        return newSourceIssue;
      }
      else if (currentIssue.dndKey === destIssue.dndKey) {
        const newDestIssue = cloneDeep(destIssue);
        newDestIssue.alarms = newDestIssue.alarms || [];
        newDestIssue.alarms.push(alarm);
        return newDestIssue;
      }
      return currentIssue;
    });
    this.setState({ issues: updatedIssues });
  }

  handleRemoveIssue(issue) {
    track('Issue', {
      Component: 'Issue organizer',
      'Issue action': 'Remove issue'
    });
    const newIssues = this.state.issues.filter(current => current.dndKey !== issue.dndKey);
    this.setState({ issues: newIssues });
    if (!issue.isNewIssue) {
      const newRemoveIssueIds = cloneDeep(this.state.removedIssueIds);
      newRemoveIssueIds.push(issue.id);
      this.setState({ removedIssueIds: newRemoveIssueIds });
    }
  }

  buildBulkUpdateRequest() {
    const bulkUpdateRequest = { create: [], update: [], deleteIds: [] };
    this.state.issues.forEach((issue) => {
      let allAlarmIds = [];
      issue.alarms.forEach((currentAlarm) => {
        allAlarmIds = allAlarmIds.concat(currentAlarm.ids);
      });

      if (issue.isNewIssue) {
        const newIssue = {
          siteId: this.props.issue.site.id,
          description: issue.description,
          alarmIds: allAlarmIds,
        };
        bulkUpdateRequest.create.push(newIssue);
      }
      else {
        const issueUpdate = {
          id: issue.id,
          alarmIds: allAlarmIds,
        };
        bulkUpdateRequest.update.push(issueUpdate);
      }
    });
    bulkUpdateRequest.deleteIds = this.state.removedIssueIds;
    return bulkUpdateRequest;
  }

  handleSave() {
    track('Issue', {
      Component: 'Issue organizer',
      'Issue action': 'Apply changes'
    });

    const bulkUpdateRequest = this.buildBulkUpdateRequest();

    Promise.resolve(this.props.bulkUpdate(bulkUpdateRequest)).then(() => {
      PresentAlert('Issue updates submitted');
      if (bulkUpdateRequest.deleteIds.indexOf(this.props.issue.id) === -1) {
        this.setState({ removedIssueIds: [] });
        this.props.onCloseIssueOrganizer();
      }
    }).catch((error) => {
      console.error('Failed to post issue updates: %s', error);
      this.props.onCloseIssueOrganizer();
    });
  }

  render() {
    if (!this.props.issue) {
      return null;
    }

    return (
      <Modal isOpen={this.props.issueOrganizerVisible} toggle={this.props.onCloseIssueOrganizer} size="lg">
        <ModalHeader toggle={this.props.onCloseIssueOrganizer}>{this.props.issue.siteNickname} | Issues</ModalHeader>

        {this.props.bulkUpdateInProgress ?
          <CanaryLoadingIndicator />
          :
          <Row className="pl-3 pr-3 pt-3">
            {this.state.issues && this.state.issues.map(issue => (
              <Col sm={6} className="pb-1" key={issue.dndKey}>
                <IssueDropTarget
                  site={this.props.site}
                  issue={issue}
                  onIssueClick={this.navigateToIssue}
                  onRemoveIssue={this.handleRemoveIssue}
                  onMoveAlarm={this.handleMoveAlarm}
                />
              </Col>
            ))}
          </Row>
        }

        <HasPermission customerId={this.props.issue.site.customerId} siteId={this.props.issue.site.id} permission={organizationPermissions.IssueEdit}>
          <Row className="p-3">
            <Col sm={12}>
              <Form inline>
                <FormGroup style={{ paddingRight: '10px' }}>
                  <label htmlFor="newIssueName" className="sr-only">New Issue Name</label>
                  <input id="newIssueName" type="text" placeholder="New Issue Name" onChange={this.newIssueNameChanged} value={this.state.newIssueName} />
                </FormGroup>
                <Button
                  disabled={!this.state.newIssueName.length || this.props.bulkUpdateInProgress}
                  color="primary"
                  size="sm"
                  outline
                  onClick={this.addNewIssue}
                >
                  Create
                </Button>
              </Form>
            </Col>
          </Row>
        </HasPermission>

        <ModalFooter>
          <Button color="primary" size="sm" outline className="mr-2" onClick={this.props.onCloseIssueOrganizer}>Cancel</Button>
          <Button color="primary" size="sm" onClick={() => this.handleSave()} disabled={this.props.bulkUpdateInProgress}>Apply</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    site: SiteSelectors.currentSite(state),
    issue: IssueSelectors.currentIssue(state),
    bulkUpdateInProgress: selectors.isBulkUpdateInProgress(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bulkUpdate: bulkRequest => dispatch(actions.bulkUpdate(bulkRequest)),
    selectIssueById: issue => dispatch(IssueActions.selectIssueById(issue.id)),
  };
}

IssueOrganizerModal.propTypes = {
  // public
  issueOrganizerVisible: PropTypes.bool,
  onCloseIssueOrganizer: PropTypes.func.isRequired,

  // internal
  site: PropTypes.shape({
    siteTimezone: PropTypes.string.isRequired,
  }).isRequired,
  issue: PropTypes.shape({
    site: PropTypes.shape({
      id: PropTypes.number.isRequired,
      nickname: PropTypes.string.isRequired,
      customerId: PropTypes.number.isRequired,
    }).isRequired,
  }),
  bulkUpdateInProgress: PropTypes.bool,
  bulkUpdate: PropTypes.func.isRequired,
  selectIssueById: PropTypes.func.isRequired,
};

IssueOrganizerModal.defaultProps = {
  issue: null,
  issueOrganizerVisible: false,
  bulkUpdateInProgress: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IssueOrganizerModal));
