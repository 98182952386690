import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { SiteConnectionStatus } from '../../../../../../../AppConstants';

const OK = { id: SiteConnectionStatus.OK, name: 'OK' };
const Unstable = { id: SiteConnectionStatus.Unstable, name: 'Unstable' };
const Disconnected = { id: SiteConnectionStatus.Disconnected, name: 'Disconnected' };

const ConnectionStatusSelect = ({ selectedConnectionStatuses, selectConnectionStatuses, deselectConnectionStatus, disabled }) => {
  const hasConnectionStatus = connectionStatus => !!find(selectedConnectionStatuses, current => current.id === connectionStatus.id);
  const buildUpdateFunc = connectionStatus => {
    if (hasConnectionStatus(connectionStatus)) {
      deselectConnectionStatus(connectionStatus);
    }
    else {
      selectConnectionStatuses([connectionStatus]);
    }
  };

  const toggleOk = () => buildUpdateFunc(OK);
  const okChecked = hasConnectionStatus(OK);
  const toggleUnstable = () => buildUpdateFunc(Unstable);
  const unstableChecked = hasConnectionStatus(Unstable);
  const toggleDisconnected = () => buildUpdateFunc(Disconnected);
  const disconnectedChecked = hasConnectionStatus(Disconnected);

  return (
    <Form className="d-md-flex align-items-center h-100">
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={okChecked} onChange={toggleOk} disabled={disabled} />
          OK
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={unstableChecked} onChange={toggleUnstable} disabled={disabled} />
          Unstable
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={disconnectedChecked} onChange={toggleDisconnected} disabled={disabled} />
          Disconnected
        </Label>
      </FormGroup>
    </Form>
  );
};

ConnectionStatusSelect.propTypes = {
  selectedConnectionStatuses: PropTypes.array,
  selectConnectionStatuses: PropTypes.func.isRequired,
  deselectConnectionStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ConnectionStatusSelect.defaultProps = {
  selectedConnectionStatuses: [],
};

export default ConnectionStatusSelect;
