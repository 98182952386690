import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import { WorkflowStatusTypes } from '../../AppConstants';

const WorkflowStatus = ({ className, workflowStatus }) => {
  let statusText;
  switch (workflowStatus) {
    case WorkflowStatusTypes.New:
    case WorkflowStatusTypes.NewUnread:
    case WorkflowStatusTypes.Draft:
      statusText = 'Analyzing';
      break;
    case WorkflowStatusTypes.AwaitingApproval:
    case WorkflowStatusTypes.AwaitingApprovalUnread:
      statusText = 'New';
      break;
    case WorkflowStatusTypes.PendingResolution:
      statusText = 'Pending Resolution';
      break;
    case WorkflowStatusTypes.Resolved:
      statusText = 'Closed';
      break;
    default:
  }

  return (
    <Badge className={className} color="secondary">{statusText || 'Unknown'}</Badge>
  );
};

WorkflowStatus.propTypes = {
  className: PropTypes.string,
  workflowStatus: PropTypes.string,
};

WorkflowStatus.defaultProps = {
  className: null,
  workflowStatus: null,
};

export default WorkflowStatus;
