import reduceReducers from 'reduce-reducers';
import { buildReducer, DefaultState } from '../../shared/redux-helpers/ReducerFactory';
import { buildTableReducer } from '../../shared/components/table/redux/TableReducerFactory';
import { buildViewManagerReducer } from '../../shared/components/table/view-manager/ViewManagerReducerFactory';
import { SortDirection } from '../../AppConstants';
import { DefaultTableColumns, SiteTableColumns } from './SiteListConstants';
import ActionTypes from './SiteListActionTypes';

const SiteListState = Object.assign({}, DefaultState, {
  defaultSortColumnId: SiteTableColumns.Nickname.id,
  defaultSortDirection: SortDirection.Asc,
  pageSize: 500,
});

export default reduceReducers(
  SiteListState,
  buildReducer(ActionTypes),
  buildTableReducer(ActionTypes, SiteTableColumns),
  buildViewManagerReducer(ActionTypes, SiteTableColumns, DefaultTableColumns)
);
