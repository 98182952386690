import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

import BigNumber from './BigNumber';

function mapToChartJsFormat(data) {
  return {
    labels: data.map((currentItem, index) => currentItem.label || `Category ${index + 1}`),
    datasets: [{
      data: data.map(currentItem => currentItem.value || 0),
      backgroundColor: data.map(currentItem => currentItem.color),
      borderWidth: 0,
    }],
  };
}

const ReportsChart = ({ data }) => {
  if (data.length === 0) return null;

  return (
    <div>
      <div className="m-1">
        <Doughnut
          options={{ cutoutPercentage: 80, legend: { display: false }, animation: false }}
          data={mapToChartJsFormat(data)}
          height={250}
        />
        {data.map(d => (
          <BigNumber key={d.label} label={d.label} number={d.value} color={d.color} />
        ))}
      </div>
    </div>
  );
};

ReportsChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ReportsChart;
