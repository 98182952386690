import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as docSelectors from '../../SiteDocumentSelectors';
import * as actions from '../../actions';
import FolderDropdown from './FolderDropdown';

class FolderDropdownContainer extends Component {
  componentDidMount() {
    this.props.ensureSiteFolders();
  }

  render() {
    return <FolderDropdown folders={this.props.folders} onChange={this.props.onChange} value={this.props.value} />;
  }
}

FolderDropdownContainer.propTypes = {
  // public properties
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,

  // internal stuff
  folders: PropTypes.array,
  ensureSiteFolders: PropTypes.func.isRequired,
};

FolderDropdownContainer.defaultProps = {
  value: null,
  folders: null,
};

function mapStateToProps(state) {
  return {
    folders: docSelectors.folders(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ensureSiteFolders: () => dispatch(actions.ensureSiteFolders()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderDropdownContainer);
