import React from 'react';
import PropTypes from 'prop-types';

import CanarySummaryBar from './CanarySummaryBar';
import { CanaryTable } from './CanaryTable';

const CanaryTableAndSummary = ({ summary, controls, header, data, prefix }) => {
  if (!summary && !controls) {
    return <CanaryTable header={header} data={data} keyPrefix={prefix} />;
  }

  return (
    <div>
      <CanarySummaryBar summary={summary} controls={controls} />
      <CanaryTable header={header} data={data} keyPrefix={prefix} />
    </div>
  );
};

CanaryTableAndSummary.propTypes = {
  summary: PropTypes.any,
  controls: PropTypes.any,
  header: PropTypes.any,
  data: PropTypes.any,
  prefix: PropTypes.any,
};

CanaryTableAndSummary.defaultProps = {
  summary: null,
  controls: null,
  header: null,
  data: null,
  prefix: null,
};

export default CanaryTableAndSummary;
