import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ActionBar = props => (
  <div className="col-sm-12">
    <div className="mt-4 mb-2">
      <div>
        <Button disabled={props.checkRequired(props.target, props.panel, !!(props.panel.errorMessages && props.panel.errorMessages.length))} className="mr-2" color="primary" onClick={() => props.onSavePanel(props.panel, props.target)}>Save</Button>
        <Button outline color="primary" onClick={() => props.onCancelPanel({ panel: props.panel })}>Cancel</Button>
      </div>
    </div>
  </div>
);

ActionBar.propTypes = {
  onCancelPanel: PropTypes.func.isRequired,
  onSavePanel: PropTypes.func.isRequired,
  panel: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  checkRequired: PropTypes.func.isRequired,
};

export default ActionBar;
