import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter, Redirect, Switch } from 'react-router-dom';
import Alert from 'react-s-alert';
import { connect } from 'react-redux';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import flow from 'lodash/flow';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';

import { AuthenticationActions, AuthenticationSelectors } from '../authentication';
import MonthlyComplianceOverview from './compliance/containers/MonthlyComplianceOverview';
import { Exports } from './exports';
import IssueList from './issue-list/containers/IssueList';
import MviList from './mvi/containers/MviList';
import { Site } from './sites';
import SiteList from './site-list/containers/SiteList';
import Inventory from './inventory/containers/Inventory';
import PreferencesEditor from './preferences/containers/PreferencesEditor';
import UserNavbar from './UserNavbar';

import '../../scss/index.scss';
import IssueRedirect from './sites/screens/issues/screens/issue-edit/containers/IssueRedirect';


class UserLayout extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.checkAuthorization = this.checkAuthorization.bind(this);
  }

  componentWillMount() {
    this.checkAuthorization();
  }

  checkAuthorization() {
    // // email templates with deep links will now have the customerId as a query param
    // // so that we can set the customer, otherwise we get errors because our customerId
    // // gets set too late after the login process and depends on the user navigating to
    // // a deep location. I guess this site didn't anticipate deep linking originally.
    // const search = this.props.location && this.props.location.search;
    // const params = new URLSearchParams(search);
    // const pcid = params.get('customerId');
    // // try to sanitize input
    // // make sure less than 7 digits and can be cast to a number then check if it's a number
    // // do not store unless we end up with a number less than 7 digits
    // const passedCustomerId = pcid && pcid.length < 7 ? Number.parseInt(pcid, 10) : null;
    // if (passedCustomerId && typeof passedCustomerId === 'number') {
    //   this.props.handleSetCustomerId(passedCustomerId);
    // }

    if (!this.props.isLoggedIn) {
      this.props.redirectToLogin(`${this.props.history.location.pathname}${this.props.history.location.search}`);
      return false;
    }
    return true;
  }

  render() {
    if (this.props.isLoginInProgress) {
      return <PulseLoader color="#F8E71C" size={8} />;
    }
    else if (!this.props.isLoggedIn) {
      return null;
    }

    return (
      <div>
        <UserNavbar />
        <div className="container-fluid" style={{ marginTop: '1em', marginBottom: '4em' }}>
          {this.renderContent()}
        </div>
        <Alert stack={{ limit: 3 }} />
      </div>
    );
  }

  renderContent() {
    if (!this.props.hasCustomers) {
      return (
        <div className="row mb-2">
          <div className="col-sm-10 offset-sm-1">
            <h3>Your account is not associated with any customers.</h3>
          </div>
        </div>
      );
    }

    return (
      <Switch>
        <Redirect exact from="/" to="/sites" />
        <Route exact path="/issues" name="issues">
          <IssueList />
        </Route>
        <Route path="/issues/:issueId" name="issue">
          <IssueRedirect />
        </Route>
        <Route path="/monthly-compliance" name="monthly-compliance">
          <MonthlyComplianceOverview />
        </Route>
        <Route path="/inspections">
          <MviList />
        </Route>
        <Route path="/inventory" name="inventory">
          <Inventory />
        </Route>
        <Route path="/sites/:siteId" name="site">
          <Site />
        </Route>
        <Route path="/sites" name="site-list">
          <SiteList />
        </Route>
        <Redirect from="/exports" to="/report-center" />
        <Route path="/report-center" name="report-center">
          <Exports />
        </Route>
        <Route path="/preferences" name="preferences">
          <PreferencesEditor />
        </Route>
      </Switch>
    );
  }
}

UserLayout.propTypes = {
  history: PropTypes.object.isRequired,
  isLoginInProgress: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool,
  hasCustomers: PropTypes.bool,
  location: PropTypes.object.isRequired,
  redirectToLogin: PropTypes.func.isRequired,
};

UserLayout.defaultProps = {
  isLoggedIn: false,
  hasCustomers: false,
};

function mapDispatchToProps(dispatch) {
  return {
    redirectToLogin: redirectPath => dispatch(AuthenticationActions.redirectToLogin(redirectPath)),
  };
}

function mapStateToProps(state) {
  return {
    isLoginInProgress: AuthenticationSelectors.isLoginInProgress(state),
    isLoggedIn: AuthenticationSelectors.isLoggedIn(state),
    hasCustomers: AuthenticationSelectors.hasCustomers(state),
  };
}

export default withRouter(flow(
  connect(mapStateToProps, mapDispatchToProps),
  DragDropContext(HTML5Backend),
)(UserLayout));
