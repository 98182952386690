import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, NavLink, Redirect, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import SiteDashboard from '../screens/site-dashboard/containers/SiteDashboard';
import DeviceActivationContainer from '../screens/activation/containers/DeviceActivationContainer';
import ProblemList from '../screens/problem-list/containers/ProblemList';
import SiteSelectionContainer from '../screens/activation/containers/SiteSelectionContainer';

class SiteAdminContainer extends Component {

  renderContent() {
    return (
      <Switch>
        <Redirect exact="exact" from="/admin/sites/" to={`${this.props.match.url}/dashboard`} />
        <Route exact path="/admin/sites/dashboard" name="siteDashboard">
          <SiteDashboard />
        </Route>
        <Route exact path="/admin/sites/activations" name="activations">
          <DeviceActivationContainer />
        </Route>
        <Route exact path="/admin/sites/activations/:deviceId" name="siteSelection">
          <SiteSelectionContainer />
        </Route>
        <Route exact path="/admin/sites/problematic-sites" name="problemList">
          <ProblemList />
        </Route>
      </Switch>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-2">
          <div className="nav flex-column nav-pills">
            <NavLink to={`${this.props.match.url}/dashboard`} className="nav-link">Site Dashboard</NavLink>
            <NavLink to={`${this.props.match.url}/activations`} className="nav-link">Activations</NavLink>
            <NavLink to={`${this.props.match.url}/problematic-sites`} className="nav-link">Problem Sites</NavLink>
          </div>
        </div>
        <div className="col-md-10">
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

SiteAdminContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

SiteAdminContainer.defaultProps = {
};

function mapStateToProps(state) {
  return {

    // HACK: Force the page to update on navigate
    router: state.router,
  };
}

export default connect(mapStateToProps)(withRouter(SiteAdminContainer));
