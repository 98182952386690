import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import { withTracking } from '../../../../../../../shared/analytics';
import * as selectors from '../ATGLinesSelectors';
import * as actions from '../actions';
import CanaryLoadingIndicator from '../../../../../../../shared/components/CanaryLoadingIndicator';

class LinesEditor extends Component {

  componentDidMount() {
    this.props.getSiteLines();
  }

  render() {
    if (!this.props.currentSiteLines) return <h1>No Data Available.</h1>;
    if (this.props.linesLoading) return <CanaryLoadingIndicator />;
    return (
      <div style={{ minHeight: '300px' }}>
        <div className="row">
          <div className="col-12 mt-1 mb-4">
            { this.props.currentSiteLines.length > 0 ?
              <Table striped>
                <thead className="small">
                  <tr>
                    <th>Label</th>
                    <th>Number</th>
                    <th>Line Type</th>
                    <th >Leak Detection</th>
                    <th>Piping Material</th>
                  </tr>
                </thead>

                <tbody>
                  {this.props.currentSiteLines.map(line => (
                    <tr key={line.id}>
                      <td><strong>{line.locationLabel}</strong></td>
                      <td>{line.elldSensorNumber}</td>
                      <td>{line.lineType}</td>
                      <td>{line.lldType}</td>
                      <td>{line.pipingMaterial}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            :
              <div style={{ border: '1px solid #ccc' }} className="d-flex justify-content-center align-items-center p-2 m-4"><strong>No Lines Found</strong></div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSiteLines: selectors.currentSiteLines(state),
    linesLoading: selectors.linesLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSiteLines: () => dispatch(actions.getSiteLines()),
  };
}

LinesEditor.propTypes = {
  currentSiteLines: PropTypes.array.isRequired,
  getSiteLines: PropTypes.func.isRequired,
  linesLoading: PropTypes.bool.isRequired,
};

export default withTracking('Site', 'Equipment - Lines')(connect(mapStateToProps, mapDispatchToProps)(withRouter(LinesEditor)));
