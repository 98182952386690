import React from 'react';
import PropTypes from 'prop-types';
import { MviWorkflowStatusTypes } from '../../../AppConstants';

const Passed = <img src="/images/icon_checkmark.svg" alt="PASSED" title="PASSED" width="20px" />;

const SiteMvi = ({ status }) => {
  if (status === MviWorkflowStatusTypes.Completed) {
    return Passed;
  }
  return null;
};

SiteMvi.propTypes = {
  status: PropTypes.string,
};

SiteMvi.defaultProps = {
  status: null,
};

export default SiteMvi;
