import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { SiteConnectionTypes } from '../../../../../../../AppConstants';

const Particle = { id: SiteConnectionTypes.PARTICLE, name: 'Canary' };
const TCP = { id: SiteConnectionTypes.TCP, name: 'TCP' };

const ConnectionTypeFilterMultiselect = ({ selectedConnectionTypes, selectConnectionTypes, deselectConnectionType, disabled }) => {
  const hasConnectionType = connectionType => !!find(selectedConnectionTypes, current => current.id === connectionType.id);
  const buildUpdateFunc = connectionType => {
    if (hasConnectionType(connectionType)) {
      deselectConnectionType(connectionType);
    }
    else {
      selectConnectionTypes([connectionType]);
    }
  };

  const toggleParticle = () => buildUpdateFunc(Particle);
  const particleChecked = hasConnectionType(Particle);
  const toggleTcp = () => buildUpdateFunc(TCP);
  const tcpChecked = hasConnectionType(TCP);

  return (
    <Form className="d-md-flex align-items-center h-100">
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={particleChecked} onChange={toggleParticle} disabled={disabled} />
          Canary
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={tcpChecked} onChange={toggleTcp} disabled={disabled} />
          TCP
        </Label>
      </FormGroup>
    </Form>
  );
};

ConnectionTypeFilterMultiselect.propTypes = {
  selectedConnectionTypes: PropTypes.array,
  selectConnectionTypes: PropTypes.func.isRequired,
  deselectConnectionType: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ConnectionTypeFilterMultiselect.defaultProps = {
  selectedConnectionTypes: [],
};

export default ConnectionTypeFilterMultiselect;
