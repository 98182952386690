import update from 'immutability-helper';

import OrgBreadcrumbActionTypes from './BreadcrumbActionTypes';

let key = 0;

const InitialState = {
  locations: [],
};

function pushBreadcrumb(state, action) {
  if (state && state.locations && state.locations.length) {
    const lastIndex = state.locations.length - 1;
    if (state.locations[lastIndex].path === action.location.path) {
      return state;
    }
  }

  return update(state, {
    locations: {
      $push: [{
        path: action.location.path,
        name: action.location.name,
        key: `breadcrumb_${key++}`,
      }],
    }
  });
}

export default function BreadcrumbReducer(state = InitialState, action) {
  switch (action.type) {
    case OrgBreadcrumbActionTypes.RESET:
      return Object.assign({}, state, {
        locations: [action.location]
      });
    case OrgBreadcrumbActionTypes.PUSH:
      return pushBreadcrumb(state, action);
    default:
      return state;
  }
}
