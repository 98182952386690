/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'lodash/memoize';

import { FormattedTimestamp } from '../date';
import { TextAlign } from './TableConstants';
import PropertyColumn from './PropertyColumn';

const buildRender = memoize((zone, dateOnly) => time => <FormattedTimestamp time={time} zone={zone} dateOnly={dateOnly} />);

function TimestampColumn({ title, property, zone, dateOnly, rowData }) {
  const render = buildRender(zone, dateOnly);
  return (
    <PropertyColumn
      title={title}
      property={property}
      render={render}
      rowData={rowData}
    />
  );
}

TimestampColumn.propTypes = {
  sortable: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  textAlign: PropTypes.oneOf([TextAlign.Left, TextAlign.Right, TextAlign.Center]),
  property: PropTypes.string.isRequired,
  zone: PropTypes.string,
  dateOnly: PropTypes.bool,

  rowData: PropTypes.object,
};

TimestampColumn.defaultProps = {
  sortable: false,
  title: null,
  width: null,
  textAlign: TextAlign.Left,
  zone: null,
  dateOnly: null,
  rowData: null,
};

export default TimestampColumn;
