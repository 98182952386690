/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'lodash/memoize';
import objectPath from 'object-path';

import { TextAlign } from './TableConstants';
import Column from './Column';


const buildAccessor = memoize(property => rowData => objectPath.get(rowData, property));

function PropertyColumn({ title, property, render, rowData }) {
  const accessor = buildAccessor(property);
  return (
    <Column
      title={title}
      render={render}
      accessor={accessor}
      rowData={rowData}
    />
  );
}

PropertyColumn.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  textAlign: PropTypes.oneOf([TextAlign.Left, TextAlign.Right, TextAlign.Center]),
  property: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,

  rowData: PropTypes.object,
};

PropertyColumn.defaultProps = {
  title: null,
  width: null,
  textAlign: TextAlign.Left,
  rowData: null,
};

export default PropertyColumn;
