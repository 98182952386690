/* eslint-disable no-case-declarations */
import ActionTypes from './OrganizationDropdownActionTypes';

export default function OrganizationDropdownReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_ORGANIZATION_LIST_STARTED:
      return Object.assign({}, state, {
        organizationList: [],
        organizationListLoading: true,
      });
    case ActionTypes.GET_ORGANIZATION_LIST_FAILED:
      return Object.assign({}, state, {
        organizationList: [],
        organizationListLoading: false,
      });
    case ActionTypes.RECEIVE_ORGANIZATION_LIST:
      return Object.assign({}, state, {
        organizationList: action.organizationList,
        organizationListLoading: false,
      });

    default:
      return state;
  }
}
