import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';

import { withTracking } from '../../../../../../../shared/analytics';
import { IssueClient, IssueFields } from '../../../../../../../client';
import IssueNotFound from '../components/IssueNotFound';

class IssueRedirect extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = { notFound: false };
  }

  componentDidMount() {
    this.mounted = true;
    const nextIssueId = this.props.match && this.props.match.params && this.props.match.params.issueId && Number.parseInt(this.props.match.params.issueId, 10);

    if (!Number.isInteger(nextIssueId)) {
      this.setState({ notFound: true });
      return;
    }

    IssueClient.findById(nextIssueId, [IssueFields.Id, IssueFields.SiteId]).then(result => {
      if (result && result.data && result.data.id && this.mounted) {
        this.props.navToIssue(result.data);
      }
      else if (this.mounted) {
        this.setState({ notFound: true });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    if (this.state.notFound) {
      return <IssueNotFound />;
    }
    return null;
  }
}

IssueRedirect.propTypes = {
  match: PropTypes.object.isRequired,
  navToIssue: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    navToIssue: issue => dispatch(push(`/sites/${issue.siteId}/issues/${issue.id}`)),
  };
}

export default withTracking('Site', 'Issue redirect')(withRouter(connect(null, mapDispatchToProps)(IssueRedirect)));
