import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import { Bar } from 'react-chartjs-2';
import { Alert } from 'reactstrap';
import classNames from 'classnames';

import HelpIcon from '../../../../../components/help-icon/HelpIcon';
import * as actions from '../actions';
import * as selectors from '../InvDashboardSelectors';

const CHARTJS_OPTIONS = {
  maintainAspectRatio: false,
};


class FuelHaulerHealthByPercentage extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      showHelp: false,
    };
    this.toggleHelp = this.toggleHelp.bind(this);
    this.props.getFuelHaulerHealth();
  }

  toggleHelp() {
    this.setState({
      showHelp: !this.state.showHelp,
    });
  }

  buildChartParams() {
    return {
      labels: Object.keys(this.props.fuelHaulerHealth.countsByPercentage),
      datasets: [{
        label: 'Fuel Haulers with %',
        backgroundColor: 'rgba(63,63,191,0.2)',
        borderColor: 'rgba(63,63,191,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(63,63,191,0.4)',
        hoverBorderColor: 'rgba(63,63,191,1)',
        data: Object.values(this.props.fuelHaulerHealth.countsByPercentage),
      }],
    };
  }

  render() {
    if (!this.props.fuelHaulerHealth && this.props.isLoading) {
      return (<PulseLoader color={'#F8E71C'} size={8} />);
    }
    else if (!this.props.fuelHaulerHealth || !this.props.fuelHaulerHealth.countsByPercentage) {
      return (
        <div>
          <h5>Fuel Hauler Health by Percentage</h5>
          <span className="text-muted">No data available</span>
        </div>
      );
    }

    return (
      <div>
        <div className="text-md-center">
          <h5 style={{ display: 'inline' }}>Fuel Hauler Health by Percentage</h5>
          <HelpIcon onClick={this.toggleHelp} className="ml-1" />
        </div>
        <Alert
          color="info"
          className="mb-1 small"
          isOpen={this.state.showHelp}
          toggle={this.toggleHelp}
        >
          Each bar on this graph shows the number of fuel haulers with a percentage of healthy sites within a particular range.
          For example, if the bar in the 80% position has a height of 10, then there are 10 fuel haulers where 80% - 90% of
          the sites included in the most recent export had data that was less than 10 minutes old at the time the export was generated.
        </Alert>
        <div className={classNames({ 'hidden-xs-up': this.state.showHelp })}>
          <Bar
            data={this.buildChartParams()}
            width={100}
            height={200}
            options={CHARTJS_OPTIONS}
            legend={null}
          />
        </div>
      </div>
    );
  }
}

FuelHaulerHealthByPercentage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fuelHaulerHealth: PropTypes.object,

  getFuelHaulerHealth: PropTypes.func.isRequired,
};

FuelHaulerHealthByPercentage.defaultProps = {
  fuelHaulerHealth: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isFuelHaulerHealthLoading(state),
    fuelHaulerHealth: selectors.fuelHaulerHealth(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFuelHaulerHealth: () => dispatch(actions.getFuelHaulerHealth()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelHaulerHealthByPercentage);
