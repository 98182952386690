import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as selectors from '../AuthenticationSelectors';

class HasPermissionAnySite extends Component {
  render() {
    if (this.props.isAuthorized) {
      return this.props.children;
    }
    return null;
  }
}

HasPermissionAnySite.propTypes = {
  permission: PropTypes.number,
  children: PropTypes.any,
  isAuthorized: PropTypes.bool.isRequired,
};

HasPermissionAnySite.defaultProps = {
  permission: null,
  children: null,
};

function mapStateToProps(state, ownProps) {
  return {
    isAuthorized: selectors.hasPermissionAnySite(state, ownProps.permission)
  };
}

export default connect(mapStateToProps)(HasPermissionAnySite);
