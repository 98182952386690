import parse from 'url-parse';
import ActionTypes from './ExportDestinationActionTypes';
import { DeliveryTypes, getDeliveryType } from './ExportDestinationConstants';

function convertUrlToUrlParts(deliveryUrl) {
  const urlParts = {
    deliveryType: getDeliveryType(deliveryUrl),
    deliveryEmail: '',
    deliveryHostname: '',
    deliveryPort: null,
    deliveryUsername: '',
    deliveryPublicKey: false,
    deliveryPassword: '',
    deliveryDirectory: '',
  };

  if (deliveryUrl && urlParts.deliveryType === DeliveryTypes.Email) {
    urlParts.deliveryEmail = deliveryUrl.replace(/mailto:(.*)\??.*/, '$1');
  }
  else if (urlParts.deliveryType === DeliveryTypes.FTP || urlParts.deliveryType === DeliveryTypes.SFTP) {
    const parsedUrl = parse(deliveryUrl, true);
    urlParts.deliveryHostname = parsedUrl.hostname;
    urlParts.deliveryPort = parsedUrl.port;
    urlParts.deliveryUsername = parsedUrl.username;
    urlParts.deliveryPassword = parsedUrl.password || null;
    if (urlParts.deliveryType === DeliveryTypes.SFTP) {
      urlParts.deliveryPublicKey = parsedUrl.query.publicKey || false;
    }
    urlParts.deliveryDirectory = parsedUrl.pathname;
  }

  return urlParts;
}

function buildDeliveryUrl(urlParts) {
  let deliveryUrl = null;
  if (urlParts.deliveryType === DeliveryTypes.Email) {
    deliveryUrl = `mailto:${urlParts.deliveryEmail}`;
  }
  else if (urlParts.deliveryType === DeliveryTypes.FTP || urlParts.deliveryType === DeliveryTypes.SFTP) {
    deliveryUrl = '';
    if (urlParts.deliveryType === DeliveryTypes.SFTP) {
      deliveryUrl += 's';
    }
    deliveryUrl += `ftp://${urlParts.deliveryUsername}`;
    if (!urlParts.deliveryPublicKey && urlParts.deliveryPassword) {
      deliveryUrl += `:${urlParts.deliveryPassword}`;
    }
    deliveryUrl += `@${urlParts.deliveryHostname}`;
    if (urlParts.deliveryPort) {
      deliveryUrl += `:${urlParts.deliveryPort}`;
    }
    if (urlParts.deliveryDirectory) {
      deliveryUrl += urlParts.deliveryDirectory;
    }
    if (urlParts.deliveryPublicKey) {
      deliveryUrl += '?publicKey=true';
    }
  }
  return deliveryUrl;
}

function isBlank(value) {
  return !value || value.trim() === '';
}

function validateUrlParts(urlParts) {
  const errors = [];
  if (urlParts.deliveryType === DeliveryTypes.Email) {
    if (isBlank(urlParts.deliveryEmail)) {
      errors.push('Email Address is required');
    }
  }
  else if (urlParts.deliveryType === DeliveryTypes.FTP || urlParts.deliveryType === DeliveryTypes.SFTP) {
    if (isBlank(urlParts.deliveryHostname)) {
      errors.push(`${urlParts.deliveryType} Server is required.`);
    }
    if (isBlank(urlParts.deliveryUsername)) {
      errors.push(`${urlParts.deliveryType} Username is required.`);
    }
    if (isBlank(urlParts.deliveryPassword) && (urlParts.deliveryType === DeliveryTypes.FTP || !urlParts.deliveryPublicKey)) {
      errors.push(`${urlParts.deliveryType} Password is required.`);
    }
  }
  return errors;
}

function handleReset(state, action) {
  const urlParts = convertUrlToUrlParts(action.deliveryUrl);
  const validationErrors = validateUrlParts(urlParts);
  return Object.assign({}, state, {
    urlParts,
    deliveryUrl: action.deliveryUrl,
    isValid: validationErrors.length === 0,
    validationErrors,
  });
}

function handleUpdate(state, action) {
  const validationErrors = validateUrlParts(action.urlParts);
  let deliveryUrl = null;
  if (!validationErrors.length) {
    deliveryUrl = buildDeliveryUrl(action.urlParts);
  }

  return Object.assign({}, state, {
    urlParts: action.urlParts,
    deliveryUrl,
    isValid: validationErrors.length === 0,
    validationErrors,
  });
}

const defaultState = {
  urlParts: {
    deliveryType: DeliveryTypes.Email,
    deliveryEmail: '',
    deliveryHostname: '',
    deliveryPort: null,
    deliveryUsername: '',
    deliveryPublicKey: false,
    deliveryPassword: '',
    deliveryDirectory: '',
  },
};

export default function ExportDestinationReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.RESET:
      return handleReset(state, action);
    case ActionTypes.RECEIVE_UPDATE:
      return handleUpdate(state, action);
    default:
      return state;
  }
}
