import { push } from 'react-router-redux';
import { getItemAction, deleteItemAction, getListAction, restFuncWithActionArgs } from '../../../../shared/redux-helpers/ActionCreatorFactory';
import { IngestFailureClient } from '../../../../client';
import * as MviModalActions from '../../../../shared/components/mvi/modal/ModalActions';
import ActionTypes from '../IngestFailureActionTypes';
import selectors from '../IngestFailureSelectors';

export const navToIngestFailureList = () => dispatch => {
  dispatch(push('/admin/ingest-failures'));
};

export const navToIngestFailure = ingestFailure => dispatch => {
  const id = (ingestFailure && ingestFailure.id) || ingestFailure;
  if (id) {
    dispatch(push(`/admin/ingest-failures/${id}`));
  }
};

export const navToIngestFailureFile = file => (dispatch, getState) => {
  const currentIngestFailure = selectors.item(getState());
  if (currentIngestFailure && file) {
    IngestFailureClient.getFileLink(currentIngestFailure, file).then(url => window.open(url, '_blank'));
  }
};

export const setListFilter = (subsystem) => ({ type: ActionTypes.SET_LIST_FILTER, subsystem });

export const getIngestFailuresList = getListAction(
  selectors.isLoading,
  ActionTypes.GET_LIST_STARTED,
  ActionTypes.RECEIVE_LIST,
  ActionTypes.GET_LIST_FAILED,
  restFuncWithActionArgs(IngestFailureClient.getList)
);

export const getIngestFailureById = getItemAction(
  selectors.isLoading,
  ActionTypes.GET_LIST_STARTED,
  ActionTypes.RECEIVE_ITEM,
  ActionTypes.GET_ITEM_FAILED,
  restFuncWithActionArgs(IngestFailureClient.getById)
);

const deleteInternal = deleteItemAction(
  selectors.isDeleting,
  ActionTypes.DELETE_ITEM_STARTED,
  ActionTypes.DELETE_ITEM_SUCCESS,
  ActionTypes.DELETE_ITEM_FAILED,
  restFuncWithActionArgs(IngestFailureClient.deleteIngestFailure)
);

export const deleteIngestFailure = ingestFailure => dispatch => dispatch(deleteInternal(ingestFailure)).then(() => dispatch(navToIngestFailureList()));

export const cancelConvertToMviModal = MviModalActions.closeModal;

export const openConvertToMviModal = () => (dispatch, getState) => {
  const ingestFailure = selectors.item(getState());
  const title = `Convert Ingest Failure ${ingestFailure.id} to MVI`;
  const inspectionDetails = {
    inspectionDate: ingestFailure.created
  };
  dispatch(MviModalActions.openModal(title, null, inspectionDetails));
};

const convertToMvi = deleteItemAction(
  selectors.isDeleting,
  ActionTypes.DELETE_ITEM_STARTED,
  ActionTypes.DELETE_ITEM_SUCCESS,
  ActionTypes.DELETE_ITEM_FAILED,
  (state, [site, inspectionDetails]) => {
    const ingestFailureId = selectors.item(state).id;
    const mviRequest = Object.assign({}, inspectionDetails, { siteId: site.id });
    return IngestFailureClient.convertToMvi(ingestFailureId, mviRequest);
  }
);

export const confirmConvertToMvi = (site, inspectionDetails) => dispatch => dispatch(convertToMvi(site, inspectionDetails))
  .then(mvi => {
    const mviUrl = `/sites/${mvi.siteId}/inspections/${mvi.id}`;
    window.open(mviUrl, '_blank');
    return dispatch(navToIngestFailureList());
  });
