import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { track } from '../../../../../../../../shared/analytics';
import WorkflowStatus from '../../../../../../../../shared/components/WorkflowStatus';
import * as SiteSelectors from '../../../../../../SiteSelectors';
import * as SiteActions from '../../../../../../actions';
import MviReportSelectors from './MviReportSelectors';

const MviReportIssues = ({ report, canViewIssues, navToCurrentSiteIssue }) => {
  if (!canViewIssues || !report || !report.issues || !report.issues.length) {
    return null;
  }

  return (
    <div className="mt-3">
      <h6 className="text-uppercase">Issues</h6>
      <div className="border rounded-lg p-3">
        {report.issues.map(issue => (
          <div key={issue.id} className="d-flex align-items-center mt-2">
            <Button key={issue.id} color="link" className="text-truncate" onClick={() => navToCurrentSiteIssue(issue)}>
              <span className="text-truncate">{`${issue.id}: ${issue.description}`}</span>
            </Button>
            <WorkflowStatus className="ml-2 d-inline-block" workflowStatus={issue.workflow.status} />
          </div>
        ))}
      </div>
    </div>
  );
};

MviReportIssues.propTypes = {
  report: PropTypes.shape({
    issues: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      workflow: PropTypes.shape({
        status: PropTypes.string.isRequired,
      }).isRequired,
    })),
  }),
  canViewIssues: PropTypes.bool.isRequired,
  navToCurrentSiteIssue: PropTypes.func.isRequired,
};

MviReportIssues.defaultProps = {
  report: null,
};

function mapStateToProps(state) {
  return {
    report: MviReportSelectors.item(state),
    canViewIssues: SiteSelectors.canViewIssues(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navToCurrentSiteIssue: issue => {
      track('Issue', {
        Component: 'MVI Issues',
        'Issue action': 'Nav to issue',
      });
      return dispatch(SiteActions.navToCurrentSiteIssue(issue));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MviReportIssues);
