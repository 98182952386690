import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Toggle from '../buttons/Toggle';
import * as TourSelectors from './TourSelectors';
import * as TourActions from './TourActions';

const ResetTours = ({ toursReset, resetTours, className }) => (
  <div className={classNames('d-flex', className)}>
    <Toggle onClick={resetTours} value={!toursReset} />
    <span className="ml-1">Reset on-screen guides</span>
  </div>
);

ResetTours.propTypes = {
  userId: PropTypes.number.isRequired,
  toursReset: PropTypes.bool.isRequired,
  resetTours: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ResetTours.defaultProps = {
  className: null,
};

function mapStateToProps(state) {
  return {
    toursReset: TourSelectors.isToursReset(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    resetTours: () => dispatch(TourActions.resetTours(ownProps.userId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetTours);
