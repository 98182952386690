/* eslint-disable no-param-reassign */
import update from 'immutability-helper/index';

import { ATGClient, TankClient } from '../../../../../../../client';
import * as selectors from '../TankListSelectors';
import * as siteSelectors from '../../../../../SiteSelectors';
import ActionTypes from '../TankListActionTypes';
import { TankListStep } from '../TankListConstants';


function receiveInitialData(atgInfo, tanks, registrations) {
  return {
    type: ActionTypes.RECEIVE_DATA,
    atgInfo,
    tanks,
    registrations,
  };
}

function setInitialLinks(tanks, registrations) {
  const tanksById = {};
  tanks.forEach((current) => {
    tanksById[current.id] = current;
  });

  registrations.forEach((current) => {
    if (current.atgRiser && current.atgRiser.atgTankId) {
      const tank = tanksById[current.atgRiser.atgTankId];
      current.tank = tank;
      tank.registration = current;
    }
  });
}

export function getTankList() {
  return (dispatch, getState) => {
    const currentState = getState();
    const isLoading = selectors.isLoading(currentState);
    const currentSite = siteSelectors.currentSite(currentState);
    if (isLoading || !currentSite) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_DATA_STARTED });
    const promises = [
      ATGClient.getAtgInfo(currentSite.id),
      TankClient.getTanks(currentSite.id),
      TankClient.getTankRegistrations(currentSite.id),
    ];
    return Promise.all(promises)
      .then(([atgInfo, tanks, registrations]) => {
        setInitialLinks(tanks, registrations);
        dispatch(receiveInitialData(atgInfo, tanks, registrations));
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.GET_DATA_FAILED });
        return Promise.reject(err);
      });
  };
}

function updateMatches(tanks, registrations) {
  return {
    type: ActionTypes.UPDATE_MATCHES,
    tanks,
    registrations,
  };
}

export function linkTankAndRegistration(tank, registration) {
  return (dispatch, getState) => {
    const state = getState();
    const updatedTanks = selectors.getTanks(state).map((currentTank) => {
      if (currentTank.id !== tank.id) {
        return currentTank;
      }

      return update(currentTank, {
        registration: {
          $set: registration,
        },
      });
    });

    const updatedRegistrations = selectors.getRegistrations(state).map((currentReg) => {
      if (currentReg.id !== registration.id) {
        return currentReg;
      }

      return update(currentReg, {
        tank: {
          $set: tank,
        },
        atgRiser: currentAtgRiser =>
          update(currentAtgRiser || {}, {
            atgTankId: {
              $set: tank.id,
            },
          }),
      });
    });

    dispatch(updateMatches(updatedTanks, updatedRegistrations));
  };
}

export function unlinkRegistration(registration) {
  return (dispatch, getState) => {
    const state = getState();
    const updatedTanks = selectors.getTanks(state).map((currentTank) => {
      if (!currentTank.registration || currentTank.registration.id !== registration.id) {
        return currentTank;
      }

      return update(currentTank, {
        registration: {
          $set: null,
        },
      });
    });

    const updatedRegistrations = selectors.getRegistrations(state).map((currentReg) => {
      if (currentReg.id !== registration.id) {
        return currentReg;
      }

      return update(currentReg, {
        tank: {
          $set: null,
        },
        atgRiser: currentAtgRiser =>
          update(currentAtgRiser || {}, {
            atgTankId: {
              $set: null,
            },
          }),
      });
    });

    dispatch(updateMatches(updatedTanks, updatedRegistrations));
  };
}

export function receiveStep(nextStep) {
  return {
    type: ActionTypes.RECEIVE_STEP,
    step: nextStep,
  };
}

export function showConfirmMatches() {
  return receiveStep(TankListStep.Step2ConfirmMatches);
}

export function cancelConfirmMatches() {
  return receiveStep(TankListStep.Step1MatchTanks);
}

export function confirmMatches() {
  return (dispatch, getState) => {
    const currentState = getState();
    if (selectors.isSaving(currentState)) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.SAVE_DATA_STARTED });
    const siteId = siteSelectors.currentSite(currentState).id;
    const tankRegistrations = selectors.getRegistrations(currentState).map(currentReg => update(currentReg, {
      tank: {
        $set: null,
      },
    }));
    return TankClient.saveTankRegistrations(siteId, tankRegistrations).then(() => {
      dispatch({ type: ActionTypes.SAVE_DATA_COMPLETE });
    });
  };
}

