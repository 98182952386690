import update from 'immutability-helper/index';
import { TimezonesByUSState } from '../../../../../../../AppConstants';
import ActionTypes from './SiteEditorActionTypes';
import ConnectionTypeFactory from './connection-type-factory';

function updateSite(state, incomingSite) {
  const currentUsState = state.selectedSite.state;
  const incomingUsState = incomingSite.state;

  const currentTimezone = state.selectedSite.siteTimezone;
  const incomingTimezone = incomingSite.siteTimezone;

  let updatedSite = incomingSite;
  if ((currentUsState !== incomingUsState) || (currentTimezone !== incomingTimezone)) {
    const timezone = TimezonesByUSState[incomingUsState] || incomingSite.siteTimezone || null;
    updatedSite = update(incomingSite, {
      siteTimezone: {
        $set: timezone,
      }
    });
  }

  return Object.assign({}, state, {
    pendingEdits: updatedSite,
  });
}

function applyGeocodingResults(state) {
  const geocodeResults = state.geocode.geocodeResults;
  return update(state, {
    pendingEdits: {
      address: {
        $set: geocodeResults.address,
      },
      city: {
        $set: geocodeResults.city,
      },
      zip: {
        $set: geocodeResults.zip,
      },
      location: {
        $set: geocodeResults.location,
      }
    },
    geocode: {
      $set: null
    }
  });
}

function updateSiteConnection(state, siteConnection) {
  const selectedSite = update(state.selectedSite, {
    connection: {
      $set: siteConnection,
    },
  });

  const pendingEdits = update(state.pendingEdits, {
    connection: {
      $set: siteConnection,
    },
  });

  return Object.assign({}, state, {
    isEditingSiteConnection: false,
    isSavingSiteConnection: false,
    siteConnectionDetail: ConnectionTypeFactory.fromUri(siteConnection),
    selectedSite,
    pendingEdits,
  });
}

export default function SiteEditorReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_SITE_STARTED:
      return Object.assign({}, state, {
        selectedSite: null,
        pendingEdits: null,
        siteConnectionDetail: null,
        selectedSiteLoading: true,
        error: null,
      });
    case ActionTypes.RECEIVE_SITE:
      return Object.assign({}, state, {
        selectedSite: action.selectedSite,
        pendingEdits: action.selectedSite,
        siteConnectionDetail: ConnectionTypeFactory.fromUri(action.selectedSite.connection),
        selectedSiteLoading: false,
      });
    case ActionTypes.GET_SITE_FAILED:
      return Object.assign({}, state, {
        selectedSite: null,
        pendingEdits: null,
        siteConnectionDetail: null,
        selectedSiteLoading: false,
        error: action.error,
      });

    case ActionTypes.EDIT_SITE:
      return updateSite(state, action.updatedSite);
    case ActionTypes.DISCARD_PENDING_SITE_EDITS:
      return Object.assign({}, state, {
        pendingEdits: state.selectedSite,
      });

    case ActionTypes.SAVE_SITE_STARTED:
      return Object.assign({}, state, {
        saving: true,
        error: null,
      });
    case ActionTypes.SAVE_SITE_SUCCESS:
      return Object.assign({}, state, {
        saving: false,
        selectedSite: action.site,
        pendingEdits: action.site,
      });
    case ActionTypes.SAVE_SITE_FAILED:
      return Object.assign({}, state, {
        saving: false,
        error: action.error,
      });

    case ActionTypes.GEOCODE_STARTED:
      return Object.assign({}, state, {
        geocode: {
          isGeocoding: true
        }
      });
    case ActionTypes.GEOCODE_RECEIVE_RESULTS:
      return Object.assign({}, state, {
        geocode: {
          isGeocoding: true,
          geocodeResults: action.geocodeResults
        }
      });
    case ActionTypes.GEOCODE_RECEIVE_ERROR:
      return Object.assign({}, state, {
        geocode: {
          isGeocoding: true,
          isGeocodeError: true
        }
      });
    case ActionTypes.GEOCODE_ACCEPT:
      return applyGeocodingResults(state);
    case ActionTypes.GEOCODE_CANCEL:
      return Object.assign({}, state, {
        geocode: {
          isGeocoding: false,
        }
      });
    case ActionTypes.EDIT_SITE_CONNECTION:
      return Object.assign({}, state, {
        isEditingSiteConnection: true,
      });
    case ActionTypes.CANCEL_EDIT_SITE_CONNECTION:
      return Object.assign({}, state, {
        siteConnectionDetail: ConnectionTypeFactory.fromUri(state.selectedSite.connection),
        isEditingSiteConnection: false,
      });
    case ActionTypes.UPDATE_SITE_CONNECTION:
      return Object.assign({}, state, {
        siteConnectionDetail: action.siteConnectionDetail,
      });
    case ActionTypes.SAVE_SITE_CONNECTION_STARTED:
      return Object.assign({}, state, {
        isSavingSiteConnection: true,
        openDeleteSiteConnectionModal: false,
        error: null,
      });
    case ActionTypes.SAVE_SITE_CONNECTION_SUCCESS:
      return updateSiteConnection(state, action.siteConnection);
    case ActionTypes.SAVE_SITE_CONNECTION_FAILED:
      return Object.assign({}, state, {
        isSavingSiteConnection: false,
        error: action.error,
      });
    case ActionTypes.OPEN_DELETE_SITE_CONNECTION_MODAL:
      return Object.assign({}, state, {
        openDeleteSiteConnectionModal: true,
      });
    case ActionTypes.CLOSE_DELETE_SITE_CONNECTION_MODAL:
      return Object.assign({}, state, {
        openDeleteSiteConnectionModal: false,
      });
    default:
      return state;
  }
}
