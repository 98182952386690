import { combineReducers } from 'redux';

import SiteDashboardReducer from './screens/site-dashboard/SiteDashboardReducer';
import ActivationReducer from './screens/activation/ActivationReducer';
import ProblemListReducer from './screens/problem-list/ProblemListReducer';

const SiteAdminReducer = combineReducers({
  dashboard: SiteDashboardReducer,
  activation: ActivationReducer,
  problemList: ProblemListReducer,
});

export default SiteAdminReducer;
