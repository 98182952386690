import React, { Component } from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import immutable from 'object-path-immutable';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import remove from 'lodash/remove';
import { withRouter } from 'react-router';
import withEither from '../components/withEither';
import * as actions from '../actions';
import * as selectors from '../TankRegistrationSelectors';
import * as siteSelectors from '../../../../../SiteSelectors';
import TankForm from '../components/TankForm';
import CanaryLoadingIndicator from '../../../../../../../shared/components/CanaryLoadingIndicator';
import ErrorMessage from '../../../../../../../shared/components/error-message/ErrorMessage';


function smoothScroll() {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
}

// toggle edit buttons on other panels to disabled while one panel is being edited
const toggleDisablePanels = (fb, status) => {
  const fb1 = cloneDeep(fb);
  fb1.panels.map((pnl) => {
    const newPnl = pnl;
    newPnl.disableEditButton = status;
    return newPnl;
  });
  return fb1;
};

const isLoadingConditionFn = props => props.isLoading;
const errorConditionFn = props => props.isError
&& props.error
&& props.error.response
&& props.error.response.data
&& props.error.response.data.statusCode
&& props.error.response.data.statusCode > 499
&& props.error.response.data.statusCode < 600;


const withConditionalRenderings = compose(
  withEither(isLoadingConditionFn, CanaryLoadingIndicator),
  withEither(errorConditionFn, ErrorMessage),
);

const TankFormWithConditionalRendering = withConditionalRenderings(TankForm);

const getEffectiveDates = (target, name) => {
  let historyCheck;
  if (target[name]) {
    historyCheck = target[name].map(history =>
    moment(history.effectiveDate, 'YYYY-MM-DD'),
  );
  }
  else {
    historyCheck = null;
  }
  return historyCheck;
};


class TankRegistration extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.onSavePanel = this.onSavePanel.bind(this);
    this.onCancelPanel = this.onCancelPanel.bind(this);
    this.onUpdateField = this.onUpdateField.bind(this);
    this.onValidatePanel = this.onValidatePanel.bind(this);
    this.getMinDate = this.getMinDate.bind(this);
    this.getMaxDate = this.getMaxDate.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.addNewItem = this.addNewItem.bind(this);
    this.createNewInstanceFromFields = this.createNewInstanceFromFields.bind(this);
    this.prepareTableData = this.prepareTableData.bind(this);
    this.setPanelError = this.setPanelError.bind(this);
    this.clearPanelError = this.clearPanelError.bind(this);
    this.checkRequired = this.checkRequired.bind(this);
    this.preSaveCheck = this.preSaveCheck.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  componentDidMount() {
    const tankRegistrationId = this.props.match &&
      this.props.match.params &&
      this.props.match.params.tankRegistrationId && Number.parseInt(this.props.match.params.tankRegistrationId, 10);

    const tankId = this.props.match &&
      this.props.match.params &&
      this.props.match.params.tankId && Number.parseInt(this.props.match.params.tankId, 10);

    if (tankRegistrationId) {
      this.props.loadTankRegistrationById(tankRegistrationId);
    }
    else if (tankId) {
      this.props.loadTankRegistrationByTankId(tankId);
    }


    smoothScroll(); // when you navigate from the tanks screen to the form screen, if you are scrolled down the page,
    // the previous scroll position is maintained for some reason, so you're sitting halfway down the form to begin with,
    // which is strange, so we scroll back up to the top so the user is at the top of the form.
  }

  componentWillReceiveProps(nextProps) {
    const currentTankRegistrationId = this.props.tankRegistration && this.props.tankRegistration.id;
    const nextTankRegistrationId = nextProps.match &&
      nextProps.match.params &&
      nextProps.match.params.tankRegistrationId &&
      Number.parseInt(nextProps.match.params.tankRegistrationId, 10);

    if (!this.props.isLoading && nextTankRegistrationId && currentTankRegistrationId !== nextTankRegistrationId) {
      this.props.loadTankRegistrationById(nextTankRegistrationId);
    }
  }


  onValidatePanel(fb, panel, target, field, value) {
    let newFB = cloneDeep(fb);
    let newTarget = cloneDeep(target);
    const newPanel = cloneDeep(panel);
    const newField = cloneDeep(field);

    let error = false;
    newPanel.error = false;
    newPanel.errorMessages = [];
    const panelIndex = findIndex(this.props.formBuilder.panels, ['name', newPanel.name]);
    const sourcePath = `panels.${panelIndex}`;
    newFB = immutable.set(newFB, sourcePath, newPanel);
    this.props.updateFormBuilder(newFB);
    switch (newPanel.name) {
      case 'operationalStatusHistory':
        switch (newField.name) {
          case 'effectiveDate': {
            const dates = getEffectiveDates(target, panel.name);
            dates.map((date) => {
              if (moment(date).isSame(value)) {
                error = true;
                this.setPanelError(newFB, newPanel, newField, 'Status history items must have unique dates');
              }
            });
            if (!error) {
              this.clearPanelError(newFB, newPanel, newField, newTarget, value);
            }
            break;
          }
          case 'serviceChange': {
            const previousIndex = newPanel.index === Number(objectPath.get(newTarget, newPanel.name).length - 1) ? 0 : Number(newPanel.index + 1);
            const nextIndex = newPanel.index === Number(objectPath.get(newTarget, newPanel.name).length - 1) ? null : Number(newPanel.index - 1);
            if ((newTarget[newPanel.name][previousIndex] && newTarget[newPanel.name][previousIndex].serviceChange === value) || (newTarget[newPanel.name][nextIndex] && newTarget[newPanel.name][nextIndex].serviceChange === value)) {
              error = true;
              this.setPanelError(newFB, newPanel, newField, 'Successive status history items must have distinct status types');
            }
            if (!error) {
              this.clearPanelError(newFB, newPanel, newField, newTarget, value);
            }
            break;
          }
          default:
            this.onUpdateField(newTarget, newField, value);
        }
        break;

      case 'construction':
        switch (newField.name) {
          case 'primaryMaterial':
            if (value !== 'Fiberglass Reinforced Plastic') {
              const wallType = find(newPanel.fields, { name: 'wallType' });
              wallType.editable = true;

              const preEngineeredCorrosionProtection = find(newPanel.fields, { name: 'preEngineeredCorrosionProtection' });
              preEngineeredCorrosionProtection.editable = true;
              const newFb1 = immutable.set(newFB, wallType.path, wallType);
              const newFb2 = immutable.set(newFb1, preEngineeredCorrosionProtection.path, preEngineeredCorrosionProtection);
              this.props.updateFormBuilder(newFb2);
            }
            else {
              const preEngineeredCorrosionProtection = find(newPanel.fields, { name: 'preEngineeredCorrosionProtection' });
              preEngineeredCorrosionProtection.editable = false;
              const newFb1 = immutable.set(newFB, preEngineeredCorrosionProtection.path, preEngineeredCorrosionProtection);
              this.props.updateFormBuilder(newFb1);

              // if we have "Unknown" with a Fiberglass tank, it won't validate,
              // so we have to delete it from the reg
              const newTarget1 = immutable.del(newTarget, 'construction.preEngineeredCorrosionProtection');
              this.props.editTankRegistration(newTarget1);
              this.onUpdateField(newTarget1, newField, value);
              break;
            }
            this.onUpdateField(newTarget, newField, value);
            break;

          case 'wallType': {
            const intersticeType = find(newPanel.fields, { name: 'intersticeType' });

            if (value === 'Single wall') { // don't edit interstice dropdown for single wall construction
              intersticeType.editable = false;
              const newFb1 = immutable.set(newFB, intersticeType.path, intersticeType);
              this.props.updateFormBuilder(newFb1);
              const newTarget1 = immutable.set(newTarget, 'construction.intersticeType', 'None');
              this.props.editTankRegistration(newTarget1);
              this.onUpdateField(newTarget1, newField, value);
              break;
            }
            else {
              intersticeType.editable = true;
              intersticeType.display = true;
              const newFb1 = immutable.set(newFB, intersticeType.path, intersticeType);
              this.props.updateFormBuilder(newFb1);
            }
            this.onUpdateField(newTarget, newField, value);
            break;
          }
          default:
            this.onUpdateField(newTarget, newField, value);
        }
        break;


      case 'capacity': { // only allow numbers to be entered
        const numreg = new RegExp(/^[0-9]*$/gm);
        if (numreg.test(value)) {
          this.onUpdateField(newTarget, newField, value);
        }
        else {
          // roll it back to the previously saved value if a non-numeric string is passed
          const previousValue = objectPath.get(newTarget, newField.path);
          this.onUpdateField(newTarget, newField, previousValue);
        }

        break;
      }

      case 'productHistory':
        switch (newField.name) {
          case 'effectiveDate': {
            const dates = getEffectiveDates(newTarget, panel.name);
            dates.map((date) => {
              if (moment(date).isSame(value)) {
                error = true;
                this.setPanelError(newFB, newPanel, newField, 'Product history items must have unique dates');
              }
            });
            if (!error) {
              this.clearPanelError(newFB, newPanel, newField, newTarget, value);
            }
          }
            break;

          case 'product': {
            if (value === 'Gasoline') {
              const gasGrade = find(newPanel.fields, { name: 'gasGrade' });
              gasGrade.values = ['Unknown', 'Regular', 'Mid-grade', 'Premium'];
              gasGrade.display = true;
              gasGrade.required = true;
              const ethanolContent = find(newPanel.fields, { name: 'ethanolContent' });
              ethanolContent.editable = true;
              ethanolContent.display = true;
              ethanolContent.required = true;
              const bioContent = find(newPanel.fields, { name: 'bioContent' });
              bioContent.display = false;
              bioContent.required = false;
              const offRoad = find(newPanel.fields, { name: 'offRoad' });
              offRoad.display = false;
              offRoad.required = false;

              newTarget = immutable.set(newTarget, bioContent.path, null);
              newTarget = immutable.set(newTarget, offRoad.path, null);

              const newFb1 = immutable.set(newFB, gasGrade.path, gasGrade);
              const newFb2 = immutable.set(newFb1, ethanolContent.path, ethanolContent);
              const newFb3 = immutable.set(newFb2, bioContent.path, ethanolContent);
              const newFb4 = immutable.set(newFb3, offRoad.path, offRoad);
              this.props.updateFormBuilder(newFb4);
            }
            else if (value === 'Diesel') {
              const gasGrade = find(newPanel.fields, { name: 'gasGrade' });
              gasGrade.display = false;
              gasGrade.required = false;
              const ethanolContent = find(newPanel.fields, { name: 'ethanolContent' });
              ethanolContent.display = false;
              ethanolContent.required = false;
              const bioContent = find(newPanel.fields, { name: 'bioContent' });
              bioContent.editable = true;
              bioContent.display = true;
              const offRoad = find(newPanel.fields, { name: 'offRoad' });
              offRoad.editable = true;
              offRoad.display = true;

              newTarget = immutable.set(newTarget, ethanolContent.path, null);
              newTarget = immutable.set(newTarget, gasGrade.path, null);

              const newFb1 = immutable.set(fb, gasGrade.path, gasGrade);
              const newFb2 = immutable.set(newFb1, ethanolContent.path, ethanolContent);
              const newFb3 = immutable.set(newFb2, bioContent.path, ethanolContent);
              const newFb4 = immutable.set(newFb3, offRoad.path, offRoad);
              this.props.updateFormBuilder(newFb4);
            }
            else {
              const gasGrade = find(newPanel.fields, { name: 'gasGrade' });
              gasGrade.display = false;
              gasGrade.required = false;
              const ethanolContent = find(newPanel.fields, { name: 'ethanolContent' });
              ethanolContent.display = false;
              ethanolContent.required = false;
              const bioContent = find(newPanel.fields, { name: 'bioContent' });
              bioContent.display = false;
              bioContent.required = false;
              const offRoad = find(newPanel.fields, { name: 'offRoad' });
              offRoad.display = false;
              offRoad.required = false;

              newTarget = immutable.set(newTarget, bioContent.path, null);
              newTarget = immutable.set(newTarget, offRoad.path, null);
              newTarget = immutable.set(newTarget, ethanolContent.path, null);
              newTarget = immutable.set(newTarget, gasGrade.path, null);


              const newFb1 = immutable.set(fb, gasGrade.path, gasGrade);
              const newFb2 = immutable.set(newFb1, ethanolContent.path, ethanolContent);
              const newFb3 = immutable.set(newFb2, bioContent.path, ethanolContent);
              const newFb4 = immutable.set(newFb3, offRoad.path, offRoad);
              this.props.updateFormBuilder(newFb4);
            }

            if (!error) {
              this.clearPanelError(newFB, newPanel, newField, newTarget, value);
              this.onUpdateField(newTarget, newField, value);
            }

            break;
          }

          default:
            this.onUpdateField(newTarget, newField, value);
        }
        break;

      default:
        this.onUpdateField(target, newField, value);

    }
  }


  onUpdateField(target, field, value) {
    const newTarget = immutable.set(target, field.path, value);
    this.props.editTankRegistration(newTarget);
  }

  /**
 * Handle Saving panel data.
 * @param {obj} 0bj - Object containing the updated tank registration object, the panel form metadata and the status boolean
 * @param {target} obj.target - The updated tank registration object
 * @param {string} obj.panel - The panel to change.
 * @param {string} obj.status - The boolean dictating to place into or out of edit state
 */
  onSavePanel(panel, target) {
    if (this.preSaveCheck(panel)) {
      this.props.saveTankRegistration(target).then(() => {
        this.props.toggleEditFormBuilderPanel(panel, false);
        console.log('Save success');
        // everything is handled in the action
      }).catch((error) => {
        const fb = cloneDeep(this.props.formBuilder);
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.errors && error.response.data.error.errors.length) {
          this.setPanelError(fb, panel, '', error.response.data.error.errors);
        }
        else {
          this.setPanelError(fb, panel, '', error.response.data.error.message);
        }
        this.props.rollbackTankRegistration();
      });
    }
  }

  /**
 * Handle Edit Panel state changes.
 * @param {panelEdit} panel - Object containing the panel and the status boolean
 * @param {string} panel.panel - The panel to change.
 * @param {string} panel.status - The boolean dictating to place into or out of edit state
 */

  onCancelPanel(panelEdit) {
    const newPanel = cloneDeep(panelEdit.panel);
    newPanel.edit = !panelEdit.panel.edit;
    newPanel.errorMessages = [];
    newPanel.modalOpen = false;
    newPanel.fields.map((field) => {
      const newField = field;
      newField.error = false;
      return newField;
    });
    this.props.rollbackTankRegistration();

    // roll back product panel to initial state
    if (newPanel.name === 'productHistory') {
      newPanel.fields.map((fld) => {
        if (fld.name === 'effectiveDate' || fld.name === 'product'){
          fld.display = true;
        }
        else {
          fld.display = false;
        }
        return fld;
      });
    }
    const panelIndex = findIndex(this.props.formBuilder.panels, ['name', newPanel.name]);
    const sourcePath = `panels.${panelIndex}`;
    const newFormBuilder = immutable.set(this.props.formBuilder, sourcePath, newPanel);
    const fb2 = toggleDisablePanels(newFormBuilder, newPanel.edit);
    this.props.updateFormBuilder(fb2);
  }


  setPanelError(fb, panel, field, msg) {
    const newPanel = cloneDeep(panel);
    const newFormBuilder = toggleDisablePanels(fb, false);
    let newField = field;
    if (field) {
      newPanel.errorMessages.push({ field: field.name, message: msg });
      newField = field;
      newField.error = true;
      newPanel[newField.name] = newField;
    }
    else {
      if (Array.isArray(msg)) { // if we get multiple errors from back end, separate them into distinct messages
        msg.map((amsg) => {
          newPanel.errorMessages.push({ field: '', message: amsg });
        });
      }
      else {
        newPanel.errorMessages.push({ field: '', message: msg });
      }
    }
    const panelIndex = findIndex(this.props.formBuilder.panels, ['name', newPanel.name]);
    const sourcePath = `panels.${panelIndex}`;
    const newFb2 = immutable.set(newFormBuilder, sourcePath, newPanel);
    return this.props.updateFormBuilder(newFb2);
  }


  getMinDate(target, field) {
    switch (field.path) {
      case 'installation.installDate':
        return new Date(1900, 0, 1);
      default:
        return new Date(moment(target.installation.installDate).add(1, 'days')); // all the other dates must be after the install date, so it is the default minimum for all datepickers except for installDate
    }
  }

  getMaxDate(target, field) {
    let oldestDate = new Date(2099, 11, 31);
    if (objectPath.get(target, 'operationalStatusHistory') && objectPath.get(target, 'leakDetectionHistory')) {
      const oldestStatusHistoryDate = moment.min(getEffectiveDates(target, 'operationalStatusHistory'));
      const oldestLeakDetectionDate = moment.min(getEffectiveDates(target, 'leakDetectionHistory'));
      oldestDate = moment.min([moment(oldestStatusHistoryDate), moment(oldestLeakDetectionDate)]);
    }

    switch (field.path) {
      case 'installation.installDate':
      // installDate cannot be after the oldest of service history effective date, product history effective date, or leak detection history date
        return new Date(moment(oldestDate).subtract(1, 'days'));
      default:
        return new Date(2099, 11, 31);
    }
  }

  preSaveCheck(panel) {
    let valid = true;
    let msg = '';
    let fld;
    let errorCount = 0;
    const that = this;

    // validate at save time--things that are more complex than checking an individual field
    // individual fields are validated--when possible--in onValidatePanel()

    // check productHistory
    if (panel.name === 'productHistory') {
      fld = find(panel.fields, ['name', 'product']);
      const ph = that.props.tankRegistration.productHistory;
      const sortedPH = sortBy(ph, 'effectiveDate').reverse();
      const consecutiveProductError = sortedPH.filter((productItem, index) => (Number(index) > 0 && productItem.product === sortedPH[index - 1].product) || (Number(index + 1) < sortedPH.length && productItem.product === sortedPH[index + 1].product));
      if (consecutiveProductError.length) {
        errorCount = Number(errorCount + 1);
        valid = false;
        msg = 'Successive product history items must have distinct products';
      }
    }

    const fb = cloneDeep(this.props.formBuilder);
    if (!valid) {
      that.setPanelError(fb, panel, fld, msg);
    }
    else if (errorCount > 0 && fld) { // if it was previously not valid and there had ben an error, clear it if it is now valid
      that.clearPanelError(fb, panel, this.props.tankRegistration, fld, '');
    }

    return valid;
  }


  clearPanelError(fb, panel, field, target, value) {
    const newField = cloneDeep(field);
    const newPanel = cloneDeep(panel);
    newField.error = false;
    remove(newPanel.errorMessages, {
      field: newField.name,
    });
    newPanel[field.name] = newField;
    const panelIndex = findIndex(this.props.formBuilder.panels, ['name', newPanel.name]);
    const sourcePath = `panels.${panelIndex}`;
    const newFb = immutable.set(fb, sourcePath, newPanel);
    this.props.updateFormBuilder(newFb);
    return this.onUpdateField(target, newField, value);
  }

  prepareTableData(panel, target) {
    const newTarget = cloneDeep(target);
    const rowData = objectPath.get(newTarget, panel.name) && Array.isArray(objectPath.get(newTarget, panel.name)) ? objectPath.get(newTarget, panel.name) : [];
    sortBy(rowData, 'effectiveDate').reverse();
    return rowData;
  }

  createNewInstanceFromFields(panel) {
    const newInstance = {};
    panel.fields.map((field) => {
      switch (field.type) {
        case 'select':
          if (field.name !== 'containmentType') {
            newInstance[field.name] = null;
          }
          return newInstance;

        case 'date': {
          const regItem = objectPath.get(this.props.tankRegistration, panel.name);
          const regItemIndex = regItem && regItem.length ? regItem.length : 1;
          // fix bug CC-926
          this.onUpdateField(this.props.tankRegistration, field.path.replace(/(\d+)/, regItemIndex), moment(new Date(), 'YYYY-MM-DD').toDate());
          newInstance[field.name] = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
          return newInstance;
        }
        case 'number':
          newInstance[field.name] = null;
          return newInstance;
        case 'bool':
          newInstance[field.name] = null;
          return newInstance;
        case 'text':
          newInstance[field.name] = '';
          return newInstance;
        default:
          newInstance[field.name] = '';
          return newInstance;
      }
    });
    return newInstance;
  }

  checkRequired(target, panel, error) {
    const allRequired = panel.fields.filter(field => field.required && !objectPath.get(target, field.path));
    return allRequired.length > 0 || error;
  }

  addNewItem(panel, target) {
    const regData = cloneDeep(objectPath.get(target, panel.name));
    const newPanel = cloneDeep(panel);
    newPanel.index = regData && regData.length ? regData.length : 0;
    newPanel.errorMessages = [];
    const newObj = this.createNewInstanceFromFields(newPanel);
    const newTarget = immutable.push(target, newPanel.name, newObj);
    this.props.editTankRegistration(newTarget);
    this.toggleModal(newPanel);
  }

  deleteItem(target, panel) {
    const newTarget = immutable.del(target, `${panel.name}.${panel.index}`);
    const newPanel = cloneDeep(panel);
    const newFormBuilder = cloneDeep(this.props.formBuilder);
    const updateObj = { newFormBuilder, target: newTarget };
    this.props.saveTankRegistration(updateObj.target).then(() => {
      console.log('delete success');
      toggleDisablePanels(newFormBuilder, false);
      // everything is handled in the action
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.errors && error.response.data.error.errors.length) {
        this.setPanelError(newFormBuilder, newPanel, '', error.response.data.error.errors);
      }
      else {
        this.setPanelError(newFormBuilder, newPanel, '', error.response.data.error.message);
      }
      this.props.rollbackTankRegistration();
      const fb2 = toggleDisablePanels(newFormBuilder, newPanel.edit);
      this.props.updateFormBuilder(fb2);
    });
  }


  toggleModal(panel) {
    // we have to clear out any errors relating to them not having details when the modal is opened.
    // if they choose to not enter details, the check should run and re-create the error.

    const newPanel = cloneDeep(panel);

    newPanel.modalOpen = !newPanel.modalOpen;
    newPanel.edit = !newPanel.edit;
    const panelIndex = findIndex(this.props.formBuilder.panels, ['name', newPanel.name]);
    const sourcePath = `panels.${panelIndex}`;
    const newFormBuilder = immutable.set(this.props.formBuilder, sourcePath, newPanel);
    this.props.updateFormBuilder(newFormBuilder);
  }

  render() {
    if (this.props.isLoading || !this.props.tankRegistration || !this.props.tank) {
      return (<CanaryLoadingIndicator />);
    }
    return (
      <div>
        <TankFormWithConditionalRendering
          {...this.props}
          prepareTableData={this.prepareTableData}
          createNewInstanceFromFields={this.createNewInstanceFromFields}
          addNewItem={this.addNewItem}
          toggleModal={this.toggleModal}
          deleteItem={this.deleteItem}
          onUpdateField={this.onUpdateField}
          onValidatePanel={this.onValidatePanel}
          onCancelPanel={this.onCancelPanel}
          onSavePanel={this.onSavePanel}
          getMinDate={this.getMinDate}
          getMaxDate={this.getMaxDate}
          checkRequired={this.checkRequired}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    isError: selectors.isError(state),
    error: selectors.error(state),
    site: siteSelectors.currentSite(state),
    formBuilder: selectors.formBuilder(state),
    tankRegistration: selectors.tankRegistration(state),
    tank: selectors.tank(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTankRegistrationById: id => dispatch(actions.loadTankRegistrationById(id)),
    loadTankRegistrationByTankId: tankId => dispatch(actions.loadTankRegistrationByTankId(tankId)),
    updateFormBuilder: fb => dispatch(actions.updateFormBuilder(fb)),
    editTankRegistration: updatedRegistration => dispatch(actions.editTankRegistration(updatedRegistration)),
    rollbackTankRegistration: () => dispatch(actions.rollbackTankRegistration()),
    saveTankRegistration: panel => dispatch(actions.saveTankRegistration(panel)),
    toggleEditFormBuilderPanel: (panel, status) => dispatch(actions.toggleEditFormBuilderPanel(panel, status)),
  };
}


TankRegistration.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  formBuilder: PropTypes.object.isRequired,
  tankRegistration: PropTypes.object,
  tank: PropTypes.object,
  loadTankRegistrationById: PropTypes.func.isRequired,
  loadTankRegistrationByTankId: PropTypes.func.isRequired,
  updateFormBuilder: PropTypes.func.isRequired,
  editTankRegistration: PropTypes.func.isRequired,
  rollbackTankRegistration: PropTypes.func.isRequired,
  saveTankRegistration: PropTypes.func.isRequired,
  toggleEditFormBuilderPanel: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

TankRegistration.defaultProps = {
  tankRegistration: null,
  tank: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TankRegistration));
