import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';


const toggleIcons = Object.freeze({
  on: {
    icon: faToggleOn,
    className: 'text-primary'
  },
  off: {
    icon: faToggleOff,
    className: 'text-muted',
  }
});

const Toggle = ({ value, onClick, fontSize }) => {
  const { icon, className } = (value === undefined || !value) ? toggleIcons.on : toggleIcons.off;
  let style = { fontSize };
  if (onClick) {
    style = Object.assign({}, style, { cursor: 'pointer' });
  }
  return (
    <span>
      <FontAwesomeIcon icon={icon} style={style} className={className} onClick={onClick} />
    </span>
  );
};

Toggle.propTypes = {
  value: PropTypes.bool,
  onClick: PropTypes.func,
  fontSize: PropTypes.string,
};

Toggle.defaultProps = {
  value: false,
  onClick: null,
  fontSize: '1.33rem',
};

export default Toggle;
