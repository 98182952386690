import { Get, Patch } from '../HttpUtils';

export default {
  getSiteSensors(siteId) {
    return Get(`/v2/sites/${siteId}/sensors`).then(response => response.data);
  },
  setAssociatedSensors(siteId, tankId, tankSensorAssociation) {
    return Patch(`/v2/sites/${siteId}/tanks/${tankId}/associate-sensor`, tankSensorAssociation).then(response => response.data);
  },
};
