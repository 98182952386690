import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

export function isSaving(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.users && state.admin.organizations.users.saving) || false;
}

export function isLoading(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.users && state.admin.organizations.users.loading) || false;
}

export function selectedUser(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.users && state.admin.organizations.users.selectedUser) || null;
}

const selectActiveCustomersFromPermissions = (permissions) => {
  if (!(permissions && permissions.organizations)) {
    return null;
  }
  const orgs = permissions.organizations;
  const customers = [];
  Object.keys(orgs).forEach(key => {
    if (orgs[key].enabled && orgs[key].customer) {
      customers.push(orgs[key]);
    }
  });

  return sortBy(customers, (c) => c.name);
};

export function customersRelatedToSelectedUser(state) {
  return (selectedUser(state) && selectedUser(state).permissions && selectActiveCustomersFromPermissions(selectedUser(state).permissions)) || [];
};

export function pendingUserEdits(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.users && state.admin.organizations.users.pendingEdits) || null;
}

export function isPendingUserEditsPristine(state) {
  const user = selectedUser(state);
  const edits = pendingUserEdits(state);
  return (user && edits && isEqual(user, edits)) || false;
}

export function getPendingEditWarnings(state) {
  const user = selectedUser(state);
  const edits = pendingUserEdits(state);

  if (!user || !edits || !Number.isInteger(edits.id)) {
    return [];
  }

  const warnings = [];
  if (user.active && !edits.active) {
    warnings.push('This user will not be able to login if their account is disabled.');
  }
  if (user.email !== edits.email) {
    warnings.push('Changing the user\'s email address will invalidate all current logins.');
  }

  const currentOrgId = user.organizationMembership && user.organizationMembership.organizationId;
  const newOrgId = edits.organizationMembership && edits.organizationMembership.organizationId;
  if (currentOrgId !== newOrgId) {
    warnings.push('Moving this user to another organization will change the sites that they can access.');
  }

  return warnings;
}

export function canSavePendingUserEdits(state) {
  const edits = pendingUserEdits(state);

  if (!edits) {
    return false;
  }

  const isExisting = Number.isInteger(edits.id);
  const pristine = isPendingUserEditsPristine(state);

  if (isExisting && pristine) {
    return false;
  }

  const value = edits.username
    && edits.email
    && (isExisting || hasValidPasswordLength(state))
    && edits.organizationMembership.organizationId
    && pendingUserEditsHasUniqueEmail(state)
    && pendingUserEditsHasUniqueUsername(state);
  return !!value;
}

export function hasValidPasswordLength(state) {
  const edits = pendingUserEdits(state);

  if (!edits) {
    return false;
  }

  return (edits.password && edits.password.length >= 8) || false;
}

export function pendingUserEditsHasUniqueEmail(state) {
  const edits = pendingUserEdits(state);

  if (!edits) {
    return false;
  }

  const existingUser = usersByEmail(state)[edits.email.toLowerCase()];
  return !(existingUser && existingUser.id !== edits.id);
}

export function pendingUserEditsHasUniqueUsername(state) {
  const edits = pendingUserEdits(state);

  if (!edits) {
    return false;
  }

  const existingUser = usersByUsername(state)[edits.username.toLowerCase()];
  return !(existingUser && existingUser.id !== edits.id);
}

export function usersByUsername(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.users && state.admin.organizations.users.usersByUsername) || {};
}


export function usersByEmail(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.users && state.admin.organizations.users.usersByEmail) || {};
}

export function error(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.users && state.admin.organizations.users.error) || null;
}

export function entityNotifications(state) {
  return (selectedUser(state) && selectedUser(state).entityNotifications) || [];
}

export function userSearchInProgress(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.users && state.admin.organizations.users.searchingUsers) || false;
}
