/* eslint-disable no-control-regex */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import Colors from '../../../colors';
import { AuthenticationSelectors } from '../../../authentication';

const DefaultClasses = 'p-3 border rounded';

function renderPdfLink(report, allowExport, accessToken) {
  if (!allowExport || !accessToken) {
    return null;
  }

  const id = report.eventId || report.id;
  if (!id) {
    return null;
  }

  const filename = `canary_event_${id}.pdf`;
  const url = `/api/v2/reports/events/${id}?access_token=${accessToken}`;

  return (
    <a download={filename} href={url} className="d-print-none float-right">
      <FontAwesomeIcon icon={faFilePdf} size="lg" color={Colors.Primary} />
    </a>
  );
}

function stripControlChars(data) {
  return data.replace(/\u0001/g, '').replace(/\u0003/g, '').trim();
}

const ATGReport = ({ className, report, allowExport, accessToken }) => {
  if (!report.data || report.data === '') return null;
  let actualClassName = DefaultClasses;
  if (className) {
    actualClassName = `${DefaultClasses} ${className}`;
  }

  return (
    <div className={actualClassName}>
      {renderPdfLink(report, allowExport, accessToken)}
      <pre className="text-monospace small mb-0 border-0">
        {stripControlChars(report.data)}
      </pre>
    </div>
  );
};

ATGReport.propTypes = {
  className: PropTypes.string,
  allowExport: PropTypes.bool,
  report: PropTypes.shape({
    id: PropTypes.number,
    eventId: PropTypes.number,
    data: PropTypes.string,
  }).isRequired,
  accessToken: PropTypes.string,
};

ATGReport.defaultProps = {
  allowExport: true,
  className: null,
  accessToken: null,
};

function mapStateToProps(state) {
  return {
    accessToken: AuthenticationSelectors.getAuthToken(state),
  };
}

export default connect(mapStateToProps)(ATGReport);
