import { Get } from '../HttpUtils';

export default {
  getAtgInfo(siteId) {
    return Get(`/v2/sites/${siteId}/atg-info`).then(response => response.data);
  },

  getAtgConfigEvents(siteId) {
    return Get(`/v2/sites/${siteId}/atg-info/events`).then((response) => response.data);
  },
};
