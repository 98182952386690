import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import DeleteButton from '../buttons/DeleteButton';

const DocLink = ({ doc, onDocClick, canEdit, disabled, onDocDelete }) => (
  <div key={doc.id} className="d-flex align-items-center mt-2">
    <Button color="link" className="text-truncate" onClick={() => onDocClick(doc)}>
      {doc.filename}
    </Button>
    {canEdit ? <DeleteButton className="d-inline-block align-middle ml-2" onClick={() => onDocDelete(doc)} disabled={disabled} /> : null}
  </div>
);

DocLink.propTypes = {
  doc: PropTypes.object.isRequired,
  onDocClick: PropTypes.func.isRequired,
  onDocDelete: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

DocLink.defaultProps = {
  disabled: false,
};

export default DocLink;
