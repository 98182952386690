import React from 'react';
import PropTypes from 'prop-types';
import CloseButton from '../../close-button/CloseButton';

const SelectedSites = ({ selectedSites, onRemoveSite, className }) => {
  if (!selectedSites || !selectedSites.length) {
    return (
      <span className="text-muted">
        Select a customer and type part of a site name in the box above to select a site.
      </span>
    );
  }

  let actualClassNames = 'd-flex flex-column';
  if (className) {
    actualClassNames += ' ' + className;
  }

  return (
    <div className={actualClassNames}>
      {selectedSites.map((site, index) => (
        <div key={site.id} className="border d-flex justify-content-between align-items-center">
          <div style={{padding: '0.5rem'}}>
            <div>{site.siteName}</div>
            {site.nickname !== site.siteName ? <div>{site.nickname}</div> : null }
          </div>
          <div style={{padding: '0.5rem'}}>
            <CloseButton onClose={() => onRemoveSite(site, index)} />
          </div>
        </div>
      ))}
    </div>
  );
};

SelectedSites.propTypes = {
  selectedSites: PropTypes.array.isRequired,
  onRemoveSite: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SelectedSites.defaultProps = {
  className: null,
};

export default SelectedSites;
