import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTracking } from '../../../../../../shared/analytics';
import CanaryLoadingIndicator from '../../../../../../shared/components/CanaryLoadingIndicator';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import MviLinkModalButton from '../../../../components/mvi-link-modal/MviLinkModalButton';
import * as SiteActions from '../../../../actions';
import SiteMviTable from './components/SiteMviTable';
import * as actions from './SiteMviListActions';
import selectors from './SiteMviListSelectors';

class SiteMviList extends Component {
  componentDidMount() {
    this.props.getMviList();
  }

  render() {
    if (this.props.isLoading) {
      return <CanaryLoadingIndicator />;
    }
    else if (this.props.error) {
      return <ErrorMessage error={this.props.error} />;
    }

    const incompleteCount = (this.props.incompleteMvis && this.props.incompleteMvis.length) || 0;
    const completeCount = (this.props.completedMvis && this.props.completedMvis.length) || 0;

    return (
      <div>
        <div className="d-flex mb-2">
          <h4 className="mr-auto">
            {incompleteCount}
            &nbsp;Incomplete Monthly Visual Inspections
          </h4>
          <MviLinkModalButton />
        </div>

        {this.props.incompleteMvis.length
          ? <SiteMviTable mvis={this.props.incompleteMvis} onClick={this.props.navToSiteMvi} />
          : <em className="ml-4 text-muted">No incomplete MVIs</em>}

        <h4 className="mt-4 mb-2">
          {completeCount}
          &nbsp;Completed Monthly Visual Inspections
        </h4>
        {this.props.completedMvis.length
          ? <SiteMviTable mvis={this.props.completedMvis} onClick={this.props.navToSiteMvi} />
          : <em className="ml-4 text-muted">No completed MVIs</em>}
      </div>
    );
  }
}

SiteMviList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  incompleteMvis: PropTypes.array.isRequired,
  completedMvis: PropTypes.array.isRequired,
  getMviList: PropTypes.func.isRequired,
  navToSiteMvi: PropTypes.func.isRequired,
};

SiteMviList.defaultProps = {
  error: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    error: selectors.error(state),
    incompleteMvis: selectors.incompleteMvis(state),
    completedMvis: selectors.completedMvis(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMviList: () => dispatch(actions.getMviList()),
    navToSiteMvi: mvi => dispatch(SiteActions.navToSiteMvi(mvi)),
  };
}

export default withTracking('Site', 'Inspection List')(connect(mapStateToProps, mapDispatchToProps)(SiteMviList));
