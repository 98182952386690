import update from 'immutability-helper';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import groupBy from 'lodash/groupBy';

export const buildViewManagerReducer = (ActionTypes, ColumnType, defaultColumns, defaultFilters) => {
  const columnsById = groupBy(Object.values(ColumnType), 'id');

  function selectView(state, view) {
    if (view) {
      return Object.assign({}, state, {
        editingView: false,
        editingFilters: false,
        currentView: view,
        pendingViewEdits: {
          id: view.id,
          name: view.name,
          filters: view.filters || {},
          columns: view.columns || [],
          sortColumn: view.sortColumn,
          sortDirection: view.sortDirection,
          defaultView: view.defaultView,
        },
        list: [],
        filteredList: [],
        listEtag: null,
        sortColumnId: (view.sortColumn && view.sortColumn.id) || state.defaultSortColumnId,
        sortDirection: view.sortDirection || state.defaultSortDirection,
      });
    }

    let sortColumn = null;
    if (state.sortColumnId && columnsById[state.sortColumnId]) {
      sortColumn = columnsById[state.sortColumnId];
    }
    else if (state.defaultSortColumnId && columnsById[state.defaultSortColumnId]) {
      sortColumn = columnsById[state.defaultSortColumnId];
    }

    return Object.assign({}, state, {
      editingView: false,
      editingFilters: false,
      currentView: null,
      pendingViewEdits: {
        filters: defaultFilters || {},
        columns: defaultColumns,
        sortColumn,
        sortDirection: state.sortDirection || state.defaultSortDirection,
      },
      sortColumnId: state.defaultSortColumnId,
      sortDirection: state.defaultSortDirection,
    });
  }

  function saveViewSuccess(state, view) {
    const newView = view;
    const existingList = state.viewList || [];
    const index = findIndex(existingList, v => v.id === newView.id);
    let newList;
    if (index === -1) {
      newList = [...existingList, newView];
    }
    else {
      newList = update(existingList, {
        $splice: [[index, 1, newView]]
      });
    }

    // if the new view is the default, remove any previous default
    if (view.defaultView) {
      newList = newList.map(current => {
        if (current.id !== view.id) {
          return Object.assign({}, current, { defaultView: false });
        }
        return current;
      });
    }

    return Object.assign({}, state, {
      saving: false,
      editingView: false,
      editingFilters: false,
      currentView: newView,
      pendingViewEdits: {
        id: newView.id,
        name: newView.name,
        filters: newView.filters || [],
        columns: newView.columns || [],
        sortColumn: newView.sortColumn,
        sortDirection: newView.sortDirection,
        defaultView: newView.defaultView || false,
      },
      viewList: newList
    });
  }

  function deleteViewSuccess(state, view) {
    const stateWithoutView = update(state, {
      deleting: {
        $set: false,
      },
      viewList: {
        $set: state.viewList.filter(current => current.id !== view.id)
      },
    });
    const defaultView = find(stateWithoutView.viewList, current => current.defaultView);
    return selectView(stateWithoutView, defaultView);
  }

  function selectFilterItems(state, property, incoming) {
    return update(state, {
      editingView: {
        $set: true,
      },
      pendingViewEdits: edits => update(edits || {}, {
        filters: filters => update(filters || {}, {
          [property]: prop => update(prop || [], {
            $push: incoming,
          })
        })
      })
    });
  }

  function clearFilters(state) {
    return update(state, {
      editingView: {
        $set: true,
      },
      pendingViewEdits: {
        filters: {
          $set: {}
        }
      }
    });
  }

  function deselectFilterItem(state, property, item) {
    const currentlySelected = (state.pendingViewEdits && state.pendingViewEdits.filters && state.pendingViewEdits.filters[property]) || [];
    const predicate = current => item === current || (item.id && item.id === current.id) || (item.name && item.name === current.name);
    const index = findIndex(currentlySelected, predicate);
    if (index === -1) {
      return state;
    }
    return update(state, {
      editingView: {
        $set: true,
      },
      pendingViewEdits: {
        filters: {
          [property]: {
            $splice: [[index, 1]],
          }
        }
      }
    });
  }

  function setMonthFilter(state, monthType, selectedMonth) {
    return update(state, {
      editingView: {
        $set: true,
      },
      pendingViewEdits: edits => update(edits || {}, {
        filters: filters => update(filters || {}, {
          month: m => update(m || {}, {
            monthType: {
              $set: monthType,
            },
            selectedMonth: {
              $set: selectedMonth,
            }
          })
        })
      })
    });
  }

  function setColumns(state, columns) {
    const isColumnSelected = col => find(columns, currentCol => currentCol === col || (currentCol.id && currentCol.id === col.id));
    const sortedColumns = Object.values(ColumnType).filter(isColumnSelected);

    return update(state, {
      editingView: {
        $set: true,
      },
      pendingViewEdits: edits => update(edits || {}, {
        columns: {
          $set: sortedColumns,
        }
      })
    });
  }

  function changeSort(state, sortColumnId, sortDirection) {
    const col = columnsById[sortColumnId];
    return update(state, {
      editingView: {
        $set: true,
      },
      pendingViewEdits: edits => update(edits || {}, {
        sortColumn: {
          $set: col
        },
        sortDirection: {
          $set: sortDirection
        }
      })
    });
  }

  return (state, action) => {
    switch (action.type) {
      case ActionTypes.GET_VIEWS_STARTED:
        return Object.assign({}, state, { viewListLoading: true, error: null });
      case ActionTypes.RECEIVE_VIEWS:
        return Object.assign({}, state, { viewListLoading: false, viewList: action.list });
      case ActionTypes.GET_VIEWS_FAILED:
        return Object.assign({}, state, { viewListLoading: false, error: action.error });

      case ActionTypes.SELECT_VIEW:
        return selectView(state, action.view);

      case ActionTypes.SAVE_VIEW_STARTED:
        return Object.assign({}, state, { saving: true, error: null });
      case ActionTypes.SAVE_VIEW_SUCCESS:
        return saveViewSuccess(state, action.view);
      case ActionTypes.SAVE_VIEW_FAILED:
        return Object.assign({}, state, { saving: false, error: action.error });

      case ActionTypes.DELETE_VIEW_STARTED:
        return Object.assign({}, state, { deleting: true, error: null });
      case ActionTypes.DELETE_VIEW_SUCCESS:
        return deleteViewSuccess(state, action.view);
      case ActionTypes.DELETE_VIEW_FAILED:
        return Object.assign({}, state, { deleting: false, error: action.error });

      case ActionTypes.START_FILTER_EDIT:
        return Object.assign({}, state, { editingView: true, editingFilters: true });
      case ActionTypes.CANCEL_FILTER_EDIT:
        return Object.assign({}, state, { editingFilters: false });
      case ActionTypes.CLEAR_FILTERS:
        return clearFilters(state);

      case ActionTypes.SELECT_CUSTOMERS:
        return selectFilterItems(state, 'customers', action.customers);
      case ActionTypes.DESELECT_CUSTOMER:
        return deselectFilterItem(state, 'customers', action.customer);

      case ActionTypes.SELECT_SITE_STATES:
        return selectFilterItems(state, 'siteStates', action.siteStates);
      case ActionTypes.DESELECT_SITE_STATE:
        return deselectFilterItem(state, 'siteStates', action.siteState);

      case ActionTypes.GET_SITE_LABELS_STARTED:
        return Object.assign({}, state, { labelsLoading: true });
      case ActionTypes.RECEIVE_SITE_LABELS:
        return Object.assign({}, state, {
          allSiteLabels: action.labels,
          labelsLoading: false,
        });
      case ActionTypes.GET_SITE_LABELS_FAILED:
        return Object.assign({}, state, { labelsLoading: false });

      case ActionTypes.SELECT_SITE_LABELS:
        return selectFilterItems(state, 'selectedSiteLabels', action.labels);
      case ActionTypes.DESELECT_SITE_LABEL:
        return deselectFilterItem(state, 'selectedSiteLabels', action.label);

      case ActionTypes.SELECT_CONNECTION_TYPES:
        return selectFilterItems(state, 'selectedConnectionTypes', action.connectionTypes);
      case ActionTypes.DESELECT_CONNECTION_TYPE:
        return deselectFilterItem(state, 'selectedConnectionTypes', action.connectionType);

      case ActionTypes.SELECT_CONNECTION_STATUSES:
        return selectFilterItems(state, 'selectedConnectionStatuses', action.connectionStatuses);
      case ActionTypes.DESELECT_CONNECTION_STATUS:
        return deselectFilterItem(state, 'selectedConnectionStatuses', action.connectionStatus);

      case ActionTypes.SELECT_ISSUE_TYPES:
        return selectFilterItems(state, 'issueTypes', action.issueTypes);
      case ActionTypes.DESELECT_ISSUE_TYPE:
        return deselectFilterItem(state, 'issueTypes', action.issueType);

      case ActionTypes.SELECT_ISSUE_WORKFLOW_STATUSES:
        return selectFilterItems(state, 'friendlyIssueWorkflowStatuses', action.friendlyIssueWorkflowStatuses);
      case ActionTypes.DESELECT_ISSUE_WORKFLOW_STATUS:
        return deselectFilterItem(state, 'friendlyIssueWorkflowStatuses', action.friendlyIssueWorkflowStatus);

      case ActionTypes.GET_MONTHS_STARTED:
        return Object.assign({}, state, { monthsLoading: true });
      case ActionTypes.RECEIVE_MONTHS:
        return Object.assign({}, state, { monthsLoading: false, months: action.months });
      case ActionTypes.GET_MONTHS_FAILED:
        return Object.assign({}, state, { monthsLoading: false, monthsError: action.error });
      case ActionTypes.SET_MONTH_FILTER:
        return setMonthFilter(state, action.monthType, action.selectedMonth);

      case ActionTypes.SELECT_COMPLIANCE_STATUSES:
        return selectFilterItems(state, 'complianceStatuses', action.complianceStatuses);
      case ActionTypes.DESELECT_COMPLIANCE_STATUS:
        return deselectFilterItem(state, 'complianceStatuses', action.complianceStatus);

      case ActionTypes.SELECT_INSPECTION_STATUSES:
        return selectFilterItems(state, 'inspectionStatuses', action.inspectionStatuses);
      case ActionTypes.DESELECT_INSPECTION_STATUS:
        return deselectFilterItem(state, 'inspectionStatuses', action.inspectionStatus);

      case ActionTypes.SELECT_SITES:
        return selectFilterItems(state, 'sites', action.sites);
      case ActionTypes.DESELECT_SITE:
        return deselectFilterItem(state, 'sites', action.site);

      case ActionTypes.SELECT_TANK_PRODUCT_LABELS:
        return selectFilterItems(state, 'tankProductLabels', action.tankProductLabels);
      case ActionTypes.DESELECT_TANK_PRODUCT_LABEL:
        return deselectFilterItem(state, 'tankProductLabels', action.tankProductLabel);

      case ActionTypes.SELECT_TANK_STATUSES:
        return selectFilterItems(state, 'tankStatuses', action.tankStatuses);
      case ActionTypes.DESELECT_TANK_STATUS:
        return deselectFilterItem(state, 'tankStatuses', action.tankStatus);

      case ActionTypes.SET_COLUMNS:
        return setColumns(state, action.columns);
      case ActionTypes.CHANGE_SORT:
        return changeSort(state, action.sortColumnId, action.sortDirection);

      default:
        return state;
    }
  };
};
