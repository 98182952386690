import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import IntentButton from './intent-button/IntentButton';
import { LineIntents } from './Intents';
import * as siteSelectors from '../../../SiteSelectors';


class LineTests extends Component {
  renderPlldButtons() {
    return (
      <div className="col-md-6">
        <div className="mb-1">
          <strong>Pressurized Line Leak Detection Test</strong>
        </div>
        <IntentButton intent={LineIntents.StartPlldTest} />
        <IntentButton intent={LineIntents.StopPlldTest} />
        <IntentButton intent={LineIntents.GetPlldResults} />
      </div>
    );
  }

  renderWplldButtons() {
    return (
      <div className="col-md-6">
        <div className="mb-1">
          <strong>Wirelesss Pressurized Line Leak Detection Test</strong>
        </div>
        <IntentButton intent={LineIntents.StartWplldTest} />
        <IntentButton intent={LineIntents.StopWplldTest} />
        <IntentButton intent={LineIntents.GetWplldResults} />
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="row mb-1">
          <div className="col-sm-12">
            <small className="text-uppercase"><strong><Link to={`/sites/${this.props.site.id}/atg-command`}>&laquo; BACK</Link></strong></small>
          </div>
        </div>
        <div className="row">
          {this.renderPlldButtons()}
          {this.renderWplldButtons()}
        </div>
      </div>
    );
  }
}


LineTests.propTypes = {
  site: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    site: siteSelectors.currentSite(state),
  };
}

export default connect(mapStateToProps)(LineTests);
