export const ConnectionTypes = Object.freeze({
  NONE: {
    protocol: null,
    connectionType: 'None',
    display: 'None',
  },
  PARTICLE: {
    protocol: 'particle',
    connectionType: 'PARTICLE',
    display: 'Particle',
  },
  TCP: {
    protocol: 'tcp',
    connectionType: 'TCP',
    display: 'TCP',
  },
});

export const ValidationPatterns = Object.freeze({
  validParticleUriPattern: /^particle:\/\/[1-9]\d{2,10}\/[a-f0-9]{24}$/,
  // eslint-disable-next-line max-len
  validTcpUriPattern: /^(tcp:\/\/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(6553[0-5]|655[0-2][0-9]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{0,3})(\?)?(securityCode=[a-zA-Z0-9]{0,20})?(&)?(maxReportDurationSeconds=[0-9]{0,3})?)$/,
  validTcpHostPattern: /(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/,
  validTcpPortPattern: /(6553[0-5]|655[0-2][0-9]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{0,3})/,
  validSecurityCodePattern: /[a-zA-Z0-9]{0,20}/,
  validMaxReportDurationSecondsPattern: /[0-9]{0,3}/,
});
