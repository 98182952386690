import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

import HasPermission from '../../../../../../../../authentication/containers/HasPermission';
import OrganizationPermissions from '../../../../../../../../organization-permissions';
import { WorkflowStatusTypes } from '../../../../../../../../AppConstants';
import * as IssueSelectors from '../../IssueEditSelectors';
import * as CommentSelectors from './CommentSelectors';
import * as CommentActions from './CommentActions';
import {track} from '../../../../../../../../shared/analytics';


const AddCommentButton = ({ issue, disabled, showEditForm, className }) => {
  if (issue.workflowStatus === WorkflowStatusTypes.Resolved) {
    return null;
  }

  return (
    <HasPermission customerId={issue.customerId} siteId={issue.siteId} permission={OrganizationPermissions.IssueComment}>
      <Button size="sm" color="primary" className={className} onClick={showEditForm} disabled={disabled}>Comment</Button>
    </HasPermission>
  );
};

AddCommentButton.propTypes = {
  issue: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  showEditForm: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AddCommentButton.defaultProps = {
  className: null,
};

function mapStateToProps(state) {
  return {
    issue: IssueSelectors.currentIssue(state),
    disabled: !CommentSelectors.isReady(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditForm: () => {
      track('Issue', {
        Component: 'Comment',
        'Issue action': 'Edit comment',
      });
      return dispatch(CommentActions.showEditForm());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCommentButton);
