function stateSlice(state) {
  return (state && state.sites && state.sites.issues && state.sites.issues.edit && state.sites.issues.edit.workflow) || {};
}

export function isModalOpen(state) {
  return stateSlice(state).modalOpen || false;
}

export function selectedIssue(state) {
  return stateSlice(state).issue || false;
}

export function isSaving(state) {
  return stateSlice(state).saving || false;
}
