import React from 'react';
import PropTypes from 'prop-types';

const AtgToc = ({ tableOfContents, className }) => (
  <div className={className}>
    <ul style={{ listStyleType: 'none' }} className="pl-0">
      {tableOfContents.map(current => {
        const link = `#event_${current.eventId}`;
        return (
          <li key={current.eventId}>
            <a href={link}>{current.title}</a>
          </li>
        );
      })}
    </ul>
  </div>
);

AtgToc.propTypes = {
  tableOfContents: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    eventId: PropTypes.number.isRequired,
  })).isRequired,
  className: PropTypes.string,
};

AtgToc.defaultProps = {
  className: null,
};

export default AtgToc;
