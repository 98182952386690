import React from 'react';
import PropTypes from 'prop-types';
import { CanaryTable, Column, DateOrTimeColumn, LabelListColumn, MonthYearColumn, StringColumn } from '../../../shared/components/table';
import MviWorkflowStatus from '../../../shared/components/mvi/MviWorkflowStatus';
import { MviColumns } from '../MviConstants';

const MviTable = ({ dataLoading, mvis, columns, sortColumnId, sortDirection, onSortChange, currentPage, onPageChange, pageSize, pageCount, onMviSelected }) => {
  const columnIds = columns.map(current => current.id);
  const hasColumn = col => columnIds.indexOf(col.id) !== -1;
  return (
    <CanaryTable
      dataLoading={dataLoading}
      data={mvis}
      sortColumnId={sortColumnId}
      sortDirection={sortDirection}
      onSortChange={onSortChange}
      currentPage={currentPage}
      onPageChange={onPageChange}
      pageSize={pageSize}
      pageCount={pageCount}
      onRowClick={onMviSelected}
    >
      {hasColumn(MviColumns.Month)
        ? (<MonthYearColumn columnId={MviColumns.Month.id} property="monthStartDate" title={MviColumns.Month.shortName} sortable width="10%" />)
        : null}

      {hasColumn(MviColumns.Customer)
        ? (<StringColumn columnId={MviColumns.Customer.id} property="customer.name" title={MviColumns.Customer.shortName} sortable width="15%" />)
        : null}

      {hasColumn(MviColumns.SiteNickname)
        ? (<StringColumn columnId={MviColumns.SiteNickname.id} property="site.nickname" title={MviColumns.SiteNickname.shortName} width="25%" sortable />)
        : null}

      {hasColumn(MviColumns.SiteState)
        ? (<StringColumn columnId={MviColumns.SiteState.id} property="site.state" title={MviColumns.SiteState.shortName} sortable width="5%" />)
        : null}

      {hasColumn(MviColumns.WorkflowStatus)
        ? (<Column columnId={MviColumns.WorkflowStatus.id} render={mvi => <MviWorkflowStatus workflowStatus={mvi.workflowStatus} className="d-inline-block" />} title={MviColumns.WorkflowStatus.shortName} sortable width="15%" />)
        : null}

      {hasColumn(MviColumns.WorkflowStatusDate)
        ? (<DateOrTimeColumn columnId={MviColumns.WorkflowStatusDate.id} property="workflow.statusDate" title={MviColumns.WorkflowStatusDate.shortName} sortable width="8%" />)
        : null}

      {hasColumn(MviColumns.WorkflowUsername)
        ? (<StringColumn columnId={MviColumns.WorkflowUsername.id} property="workflow.user.username" title={MviColumns.WorkflowUsername.shortName} sortable width="11%" />)
        : null}

      {hasColumn(MviColumns.SiteLabels)
        ? (<LabelListColumn columnId={MviColumns.SiteLabels.id} property="siteLabels" title={MviColumns.SiteLabels.shortName} width="20%" />)
        : null}
    </CanaryTable>
  );
};

MviTable.propTypes = {
  dataLoading: PropTypes.bool,
  mvis: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  sortColumnId: PropTypes.string,
  sortDirection: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onMviSelected: PropTypes.func.isRequired,
};

MviTable.defaultProps = {
  dataLoading: false,
  sortColumnId: null,
  sortDirection: null,
};

export default MviTable;
