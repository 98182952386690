import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';

import CanaryLoadingIndicator from '../../../shared/components/CanaryLoadingIndicator';
import Compliance from '../screens/compliance/screens/compliance-list/ComplianceList';
import ComplianceReport from '../screens/compliance/screens/compliance-report/ComplianceReport';
import SiteDocuments from '../screens/documents/containers/SiteDocuments';
import SiteEquipmentContainer from '../screens/equipment/containers/SiteEquipmentContainer';
import SiteIssueList from '../screens/issues/screens/issue-list/containers/SiteIssueList';
import Issue from '../screens/issues/screens/issue-edit/containers/Issue';
import RemoteATG from '../screens/commands/containers/RemoteATG';
import SiteMviList from '../screens/mvi/screens/list/SiteMviList';
import Mvi from '../screens/mvi/screens/edit/MviEdit';
import SiteOverview from './SiteOverview';
import SiteDispatchContainer from '../screens/dispatch/containers/SiteDispatchContainer';
import SiteNav from './SiteNav';
import * as actions from '../actions';
import * as selectors from '../SiteSelectors';
import SiteBreadcrumb from './SiteBreadcrumb';
import SiteHeader from './SiteHeader';
import DevDisconnected from './DevDisconnected';
import SiteInfo from '../screens/info/SiteInfo';
import CustomerName from './CustomerName';

class Site extends Component {
  static getSiteIdFromMatchProp(props) {
    return props.match && props.match.params && props.match.params.siteId && Number.parseInt(props.match.params.siteId, 10);
  }

  constructor(initialProps) {
    super(initialProps);

    this.renderContent = this.renderContent.bind(this);
    this.props.selectSiteById(Site.getSiteIdFromMatchProp(this.props));
  }

  componentDidUpdate(prevProps) {
    const currentSiteId = Site.getSiteIdFromMatchProp(this.props);
    const previousSiteId = Site.getSiteIdFromMatchProp(prevProps);

    if (currentSiteId !== previousSiteId && !this.props.loading && !this.props.loadingFailed) {
      this.props.selectSiteById(currentSiteId);
    }
  }

  render() {
    return (
      <div>
        <SiteBreadcrumb />
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }
    else if (!this.props.site) {
      return (
        <div>
          <h1>Site not visible</h1>
          <span>This site is not visible to you at this time.</span>
        </div>
      );
    }

    return (
      <>
        <CustomerName />
        <SiteHeader site={this.props.site} />
        <SiteNav className="my-3" />
        <DevDisconnected site={this.props.site} />
        {this.renderRoutes()}
      </>
    );
  }

  renderRoutes() {
    return (
      <Switch>
        <Redirect exact from="/sites/:siteId/" to="/sites/:siteId/overview" />
        <Route path="/sites/:siteId/overview">
          <SiteOverview />
        </Route>
        <Route exact path="/sites/:siteId/issues">
          <SiteIssueList />
        </Route>
        <Route path="/sites/:siteId/issues/:issueId">
          <Issue />
        </Route>
        <Route path="/sites/:siteId/work-orders">
          <SiteDispatchContainer siteId={this.props.site.id} />
        </Route>
        <Route exact path="/sites/:siteId/reports">
          <Compliance />
        </Route>
        <Route path="/sites/:siteId/reports/:reportId">
          <ComplianceReport />
        </Route>
        <Route path="/sites/:siteId/atg-command">
          <RemoteATG />
        </Route>
        <Route path="/sites/:siteId/equipment">
          <SiteEquipmentContainer />
        </Route>
        <Route path="/sites/:siteId/documents">
          <SiteDocuments />
        </Route>
        <Route path="/sites/:siteId/site-info">
          <SiteInfo siteId={this.props.site.id} />
        </Route>
        <Route exact path="/sites/:siteId/inspections">
          <SiteMviList />
        </Route>
        <Route exact path="/sites/:siteId/inspections/:mviId">
          <Mvi />
        </Route>
      </Switch>
    );
  }
}

Site.propTypes = {
  loading: PropTypes.bool,
  loadingFailed: PropTypes.bool,
  site: PropTypes.object,
  selectSiteById: PropTypes.func.isRequired,
};

Site.defaultProps = {
  loading: false,
  loadingFailed: false,
  site: null,
};

function mapStateToProps(state) {
  const site = selectors.currentSite(state);

  return {
    loading: selectors.isCurrentSiteLoading(state),
    loadingFailed: selectors.loadingFailed(state),
    site,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectSiteById: siteId => dispatch(actions.selectSiteById(siteId)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Site));
