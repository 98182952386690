import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const black = '#000000';
const white = '#FFFFFF';

// calculate which text color provides the most contrast with the caller-supplied background color.  see:
//    https://www.w3.org/TR/WCAG21/#dfn-relative-luminance
//    https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
//    https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors/9733420#9733420

function bestForegroundColor(color) {
  const colorRgb = hexToRgb(color);
  const whiteRgb = hexToRgb(white);
  const blackRgb = hexToRgb(black);
  const whiteContrastRatio = contrast(colorRgb, whiteRgb);
  const blackContrastRatio = contrast(colorRgb, blackRgb);
  if (whiteContrastRatio > blackContrastRatio) {
    return white;
  }
  return black;
}

function contrast(rgb1, rgb2) {
  const lum1 = luminance(rgb1.r, rgb1.g, rgb1.b);
  const lum2 = luminance(rgb2.r, rgb2.g, rgb2.b);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

function luminance(r, g, b) {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      // eslint-disable-next-line no-restricted-properties
      : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function hexToRgb(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const fullHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

const Label = ({ color, text, label, id, className }) => {
  const actualColor = (label && label.color) || color;
  const actualText = (label && label.labelText) || text;

  if (!actualColor || !actualText) {
    return null;
  }

  return (
    <span id={id} style={{ color: bestForegroundColor(actualColor), backgroundColor: actualColor }} className={classNames('badge', className)}>
      {actualText}
    </span>
  );
};

Label.propTypes = {
  // specify color + text OR label but not both
  color: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.shape({
    color: PropTypes.string,
    labelText: PropTypes.string,
  }),

  id: PropTypes.string,
  className: PropTypes.string,
};

Label.defaultProps = {
  color: null,
  text: null,
  label: null,
  id: null,
  className: null,
};

export default Label;
