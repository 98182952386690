import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faWrench } from '@fortawesome/free-solid-svg-icons';
import { faCopy, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import { DispatchStatuses } from '../../../AppConstants';

const DispatchStatusIcon = ({ dispatchWorkflowStatus, className }) => {
  let icon;
  if (dispatchWorkflowStatus === DispatchStatuses.CREATED) {
    icon = faWrench;
  }
  else if (dispatchWorkflowStatus === DispatchStatuses.TECH_NOTES_RECEIVED) {
    icon = faCopy;
  }
  else if (dispatchWorkflowStatus === DispatchStatuses.EXPIRED) {
    icon = faQuestionCircle;
  }
  else {
    icon = faClipboardCheck;
  }
  return (<FontAwesomeIcon icon={icon} className={className} />);
};

DispatchStatusIcon.propTypes = {
  dispatchWorkflowStatus: PropTypes.string.isRequired,
  className: PropTypes.string,
};

DispatchStatusIcon.defaultProps = {
  className: '',
};

export default DispatchStatusIcon;
