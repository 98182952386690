import { Get } from './HttpUtils';

export const ParameterType = {
  Scalar: 'scalar',
  List: 'list',
};

export class Param {
  constructor(name, type) {
    this._name = name;
    this._type = type;
  }

  get name() {
    return this._name;
  }

  get type() {
    return this._type;
  }
}

export function addFilters(paramTypes, params, requestValues) {
  Object.keys(paramTypes).forEach(key => {
    const param = paramTypes[key];
    if (!param) {
      return;
    }

    const requestValue = params[param.name];
    if (!requestValue) {
      return;
    }

    if (param.type === ParameterType.List) {
      if (typeof requestValue.join !== 'function') {
        throw new Error(`Expected a list for ${param.name}, got: ${requestValue}`);
      }
      if (requestValue.length) {
        requestValues[param.name] = requestValue.join(',');
      }
    }
    else {
      requestValues[param.name] = requestValue;
    }
  });
}

export function addFields(fieldTypes, fields, reqParams) {
  if (fields && fields.length) {
    const fieldSet = new Set();
    const dedupFields = [];
    for (let current of fields) {
      if (!fieldSet.has(current)) {
        fieldSet.add(current);
        dedupFields.push(current);
      }
    }
    reqParams.include = dedupFields.join(',');
  }
}

export const buildFindMethod = (requestUrl, fieldTypes, paramTypes, mapFunc) => (params, fields, etag) => {
  const reqParams = {};
  addFilters(paramTypes, params, reqParams);
  addFields(fieldTypes, fields, reqParams);

  return Get(requestUrl, reqParams, etag).then(response => {
    if (mapFunc && response.status !== 304 && response.data) {
      response.data = response.data.map(mapFunc);
    }
    return response;
  });
};

export const buildFindByIdMethod = (baseResourceUrl, fieldTypes) => (id, fields) => {
  const reqParams = {};
  addFields(fieldTypes, fields, reqParams);
  const requestUrl = `${baseResourceUrl}/${id}`;
  return Get(requestUrl, reqParams);
};
