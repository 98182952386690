import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import IngestFailureDeleteModal from './IngestFailureDeleteModal';
import MviModal from '../../../../shared/components/mvi/modal/MviModal';
import selectors from '../IngestFailureSelectors';
import * as actions from '../actions';

const IngestFailureActionButton = ({ className, ingestFailure, openConvertToMviModal, confirmConvertToMvi, deleteIngestFailure, deleting, navToIngestFailureList }) => {
  if (!ingestFailure) {
    return null;
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const confirmDelete = () => {
    setDeleteModalOpen(false);
    deleteIngestFailure(ingestFailure).then(() => navToIngestFailureList());
  };

  return (
    <>
      <UncontrolledDropdown color="primary" className={className} size="sm">
        <DropdownToggle color="primary" caret disabled={deleting}>
          Take Action
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={openConvertToMviModal}>Convert to MVI</DropdownItem>
          <DropdownItem onClick={() => setDeleteModalOpen(true)}>Delete</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <MviModal onConfirm={confirmConvertToMvi} />
      <IngestFailureDeleteModal ingestFailure={ingestFailure} open={deleteModalOpen} onCancel={() => setDeleteModalOpen(false)} onConfirm={confirmDelete} />
    </>
  );
};

IngestFailureActionButton.propTypes = {
  className: PropTypes.string,
  ingestFailure: PropTypes.object,
  openConvertToMviModal: PropTypes.func.isRequired,
  confirmConvertToMvi: PropTypes.func.isRequired,
  deleteIngestFailure: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
  navToIngestFailureList: PropTypes.func.isRequired,
};

IngestFailureActionButton.defaultProps = {
  className: null,
  ingestFailure: null,
};

function mapStateToProps(state) {
  return {
    ingestFailure: selectors.item(state),
    deleting: selectors.isDeleting(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openConvertToMviModal: () => dispatch(actions.openConvertToMviModal()),
    confirmConvertToMvi: (site, inspectionDetails) => dispatch(actions.confirmConvertToMvi(site, inspectionDetails)),
    deleteIngestFailure: ingestFailure => dispatch(actions.deleteIngestFailure(ingestFailure)),
    navToIngestFailureList: () => dispatch(actions.navToIngestFailureList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IngestFailureActionButton);
