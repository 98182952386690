import { MviClient } from '../../../../../client';
import MviActionTypes from '../MviActionTypes';
import * as SiteSelectors from '../../../SiteSelectors';
import * as MviSelectors from '../MviSelectors';

export const getLatestMvi = () => (dispatch, getState) => {
  const state = getState();
  const currentSite = SiteSelectors.currentSite(state);
  if (!currentSite || MviSelectors.isLoading(state)) {
    return Promise.resolve();
  }

  dispatch({ type: MviActionTypes.GET_LATEST_MVI_STARTED });
  return MviClient.getLatestMvi(currentSite)
    .then(latestMvi => {
      dispatch({ type: MviActionTypes.RECEIVE_LATEST_MVI, latestMvi });
    })
    .catch(error => {
      dispatch({ type: MviActionTypes.GET_LATEST_MVI_FAILED, error });
      return Promise.reject(error);
    });
};
