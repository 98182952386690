import React from 'react';
import PropTypes from 'prop-types';

import Comment from '../../../../../../../shared/components/comment/Comment';

const ComplianceComments = ({ comments }) => {
  if (!comments || !comments.length) {
    return null;
  }

  return (
    <div className="mt-2">
      {comments.map(comment => (<Comment key={comment.id} comment={comment} className="mb-2" />))}
    </div>
  );
};

ComplianceComments.propTypes = {
  comments: PropTypes.array,
};

ComplianceComments.defaultProps = {
  comments: [],
};

export default ComplianceComments;
