import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Button, Col, FormGroup, Input, Label} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import PreferenceConstants from '../PreferenceConstants';
import ListFilteringSiteAutoSuggest from '../../../shared/components/site-picker/containers/ListFilteringSiteAutoSuggest';
import Divider from '../../../shared/components/Divider';

const siteBadgeStyle = { backgroundColor: '#4A4946' };

const renderSelectedSiteBadges = (siteContext, handleRemoveSite) => (
  <div>
    {siteContext.triggeringSites.sites.map((site, siteIndex) => (
      <Badge key={`${site.id}`} size="sm" className="ml-2" style={siteBadgeStyle}>
        {site.nickname}
        <span className="ml-2">
          <FontAwesomeIcon style={siteBadgeStyle} icon={faTimes} onClick={() => handleRemoveSite(siteContext, siteIndex)} />
        </span>
      </Badge>
    ))}
  </div>
);

const isContextInvalid = (siteContext) => {
  const triggeringSites = siteContext.triggeringSites;
  return (PreferenceConstants.listedSitesAllocated === triggeringSites.siteListType && !triggeringSites.sites.length);
};

const allSitesAllocated = (siteListType) => PreferenceConstants.allSitesAllocated === siteListType;
const listedSitesAllocated = (siteListType) => PreferenceConstants.listedSitesAllocated === siteListType;
const noSitesAllocated = (siteListType) => PreferenceConstants.notAllocated === siteListType;

const SiteSelector = ({ onCancel, onConfirm, siteContext, handleAddSite, handleRemoveSite, handleSiteListTypeChange, displayNoSitesOption, confirmButtonLabel, enableConfirmButton, enableCancelButton }) => {
  const siteListType = siteContext.triggeringSites.siteListType;
  return (
    <div>
      <FormGroup row>
        <Col sm={12} className="pt-1">
          <FormGroup tag="fieldset">
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="sitesType"
                  checked={allSitesAllocated(siteListType)}
                  onChange={() => {
                    handleSiteListTypeChange(siteContext, PreferenceConstants.allSitesAllocated);
                  }}
                />
                {' '}
                All sites
              </Label>
            </FormGroup>
            <FormGroup className="mt-2" check>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Label className="mr-2" check>
                  <Input
                    type="radio"
                    name="sitesTypes"
                    checked={listedSitesAllocated(siteListType)}
                    onChange={() => {
                      handleSiteListTypeChange(siteContext, PreferenceConstants.listedSitesAllocated);
                    }}
                  />
                  {' '}
                  Only these sites:
                </Label>
                <ListFilteringSiteAutoSuggest
                  id="siteSelectorFormAutoSuggest"
                  siteList={siteContext.availableSites}
                  onSiteSelected={(site) => handleAddSite(siteContext, site)}
                />
              </div>
            </FormGroup>
            <FormGroup check>
              <Col sm={6}>
                { listedSitesAllocated(siteListType) ? renderSelectedSiteBadges(siteContext, handleRemoveSite) : null}
              </Col>
            </FormGroup>
          </FormGroup>
          {displayNoSitesOption
            ? (
              <FormGroup tag="fieldset">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="sitesType"
                      checked={noSitesAllocated(siteListType)}
                      onChange={() => {
                        handleSiteListTypeChange(siteContext, PreferenceConstants.notAllocated);
                      }}
                    />
                    {' '}
                    No sites
                  </Label>
                </FormGroup>
              </FormGroup>
            )
            : null}
        </Col>
      </FormGroup>
      <Divider />
      <div className="d-flex flex-row" style={{ marginBottom: '2em', alignContent: 'center' }}>
        <Button size="sm" className="mr-2" color="secondary" disabled={!enableCancelButton} onClick={onCancel}>CANCEL</Button>
        <Button size="sm" id="save" color="primary" onClick={() => onConfirm(siteContext)} disabled={!enableConfirmButton || isContextInvalid(siteContext)}>{confirmButtonLabel}</Button>
      </div>
    </div>
  );
};

SiteSelector.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  siteContext: PropTypes.shape({
    triggeringSites: PropTypes.shape({
      siteListType: PropTypes.string.isRequired,
      sites: PropTypes.array,
    }),
    availableSites: PropTypes.array.isRequired,
  }).isRequired,
  handleAddSite: PropTypes.func.isRequired,
  handleRemoveSite: PropTypes.func.isRequired,
  handleSiteListTypeChange: PropTypes.func.isRequired,
  displayNoSitesOption: PropTypes.bool,
  confirmButtonLabel: PropTypes.string,
  enableConfirmButton: PropTypes.bool,
  enableCancelButton: PropTypes.bool,
};

SiteSelector.defaultProps = {
  displayNoSitesOption: false,
  confirmButtonLabel: 'Confirm',
  enableConfirmButton: true,
  enableCancelButton: true,
};

export default SiteSelector;
