export function isLoading(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.reg && state.sites.equipment.reg.registrationLoading) || false;
}

export function isSaving(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.reg && state.sites.equipment.reg.registrationSaving) || false;
}

export function isError(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.reg && state.sites.equipment.reg.isError) || false;
}

export function error(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.reg && state.sites.equipment.reg.error) || {};
}

export function formBuilder(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.reg && state.sites.equipment.reg.formBuilder) || {};
}

export function tankRegistration(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.reg && state.sites.equipment.reg.tankRegistration) || null;
}

export function tank(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.reg && state.sites.equipment.reg.tank) || null;
}

export function tanksLoading(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.reg && state.sites.equipment.reg.tanksLoading) || null;
}

export function atgTankId(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.reg && state.sites.equipment.reg.tankRegistration && state.sites.equipment.reg.tankRegistration.atgRiser && state.sites.equipment.reg.tankRegistration.atgRiser.atgTankId) || null;
}
