export function isInventoryFormatListLoading(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fileFormats &&
    state.admin.inventory.fileFormats.formatListLoading) || false;
}

export function isInventoryColumnCategoriesLoading(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fileFormats &&
    state.admin.inventory.fileFormats.columnCategoriesLoading) || false;
}

export function isSelectedInventoryFormatLoading(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fileFormats &&
    state.admin.inventory.fileFormats.selectedInventoryFormatLoading) || false;
}

export function isLoading(state) {
  return isInventoryFormatListLoading(state) || isInventoryColumnCategoriesLoading(state) || isSelectedInventoryFormatLoading(state);
}

export function isSaving(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fileFormats &&
    state.admin.inventory.fileFormats.saving) || false;
}

export function isDeleting(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fileFormats &&
    state.admin.inventory.fileFormats.deleting) || false;
}

export function inventoryFormatList(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fileFormats &&
    state.admin.inventory.fileFormats.inventoryFormatList) || [];
}

export function selectedInventoryFormat(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fileFormats &&
    state.admin.inventory.fileFormats.selectedInventoryFormat) || null;
}

export function canSaveSelectedInventoryFormat(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fileFormats &&
    state.admin.inventory.fileFormats.canSaveSelectedInventoryFormat) || false;
}

export function inventoryColumnCategories(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fileFormats &&
    state.admin.inventory.fileFormats.inventoryColumnCategories) || [];
}
