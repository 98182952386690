import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Transition from 'react-transition-group/Transition';
import URLSearchParams from '@ungap/url-search-params';
import { Card, CardBody, Form, FormGroup, FormFeedback, Label, Input, Button } from 'reactstrap';


import * as AuthenticationActions from '../actions';

const LoginLogo = () => (
  <div className="LoginLogo">
    <svg version="1.1" id="_x32_color" x="0px" y="0px" viewBox="0 0 324.9 102.1">
      <g>
        <path d="M64.9,64.3c-14.5,14.6-38,14.6-52.5,0c-14.6-14.5-14.6-38,0-52.5c14.5-14.6,38-14.6,52.5,0L60.7,16 c-12.2-12.2-32-12.2-44.2,0c-12.2,12.2-12.2,32,0,44.2s32,12.2,44.2,0L64.9,64.3z" />
        <path d="M114.4,66.6c-4.6,5.2-11.3,8.6-18.8,8.6c-13.7,0-24.8-11.1-24.8-24.8c0-13.7,11.1-24.7,24.8-24.7s24.8,11.1,24.8,24.7v24.8 h-5.9V66.6z M95.6,69.3c10.1,0,18.8-7.9,18.8-18.8c0-10.4-8.4-18.8-18.8-18.8s-18.8,8.4-18.8,18.8C76.7,60.9,85.2,69.3,95.6,69.3z" />
        <path d="M128.2,25.7h5.9v8.7c4.6-5.3,11.3-8.7,18.8-8.7c13.7,0,24.8,11.1,24.8,24.7v24.8h-5.9V50.5c0-10.4-8.4-18.8-18.8-18.8 c-10.2,0-18.8,8.8-18.8,18.8v24.8h-5.9V25.7z" />
        <path d="M227.2,66.6c-4.6,5.2-11.3,8.6-18.8,8.6c-13.7,0-24.7-11.1-24.7-24.8c0-13.7,11.1-24.7,24.7-24.7s24.7,11.1,24.7,24.7v24.8 h-5.9V66.6z M208.4,69.3c10.1,0,18.8-7.9,18.8-18.8c0-10.4-8.4-18.8-18.8-18.8s-18.8,8.4-18.8,18.8C189.6,60.9,198,69.3,208.4,69.3 z" />
        <path d="M241.1,25.7h5.9v8.7c4.6-5.3,11.3-8.7,18.8-8.7v5.9c-10.2,0-18.8,8.8-18.8,18.8v24.8h-5.9V25.7z" />
        <path d="M289.2,100h-6.5l11.1-24.8l-22-49.5h6.3L296.9,68l18.8-42.3h6.4L289.2,100z" />
      </g>
      <polygon points="66.4,22 48.1,26.2 55.1,37 " />
      <circle className="st0" cx="37.5" cy="38.8" r="22.6" />
      <g>
        <path d="M123.7,96.9c-3.1,3.1-8.1,3.1-11.1,0c-3.1-3.1-3.1-8.1,0-11.1c3.1-3.1,8.1-3.1,11.1,0l-0.9,0.9c-2.6-2.6-6.8-2.6-9.4,0 s-2.6,6.8,0,9.4s6.8,2.6,9.4,0L123.7,96.9z" />
        <path d="M135.3,83.5c4.3,0,7.9,3.5,7.9,7.9s-3.5,7.9-7.9,7.9s-7.9-3.5-7.9-7.9S130.9,83.5,135.3,83.5z M135.3,98 c3.7,0,6.6-3,6.6-6.6s-3-6.6-6.6-6.6s-6.6,3-6.6,6.6S131.6,98,135.3,98z" />
        <path d="M165.6,99.2h-1.3V84.7h-0.1l-6.9,11.9h-1.4l-6.8-11.9h-0.1v14.5h-1.3V83.5h2.1l6.8,11.9l6.8-11.9h2.1V99.2z" />
        <path d="M170.1,83.5h6.8c3.2,0,5.8,2.6,5.8,5.8S180.2,95,177,95h-5.6v4.2h-1.3V83.5z M171.4,93.8h5.6c2.5,0,4.5-2,4.5-4.5 s-2-4.5-4.5-4.5h-5.6V93.8z" />
        <path d="M195.8,98v1.3h-8.5V83.5h1.3V98H195.8z" />
        <path d="M200.4,83.5h1.3v15.7h-1.3V83.5z" />
        <path d="M215.2,83.5l7,15.7h-1.4l-1.6-3.6h-10.1l-1.6,3.6h-1.4l7-15.7H215.2z M209.7,94.4h9l-4.3-9.7H214L209.7,94.4z" />
        <path d="M226.8,83.5h2.1l10.8,13.7V83.5h1.3v15.7h-1.3l-11.4-14.5H228v14.5h-1.3V83.5z" />
        <path d="M258.9,96.9c-3.1,3.1-8.1,3.1-11.1,0c-3.1-3.1-3.1-8.1,0-11.1c3.1-3.1,8.1-3.1,11.1,0l-0.9,0.9c-2.6-2.6-6.8-2.6-9.4,0 s-2.6,6.8,0,9.4s6.8,2.6,9.4,0L258.9,96.9z" />
        <path d="M263.5,83.5h8.5v1.3h-7.2v6h6V92h-6v6h7.2v1.3h-8.5V83.5z" />
      </g>
    </svg>
  </div>);

class ResetPasswordForm extends Component {
  constructor(defaultProps) {
    super(defaultProps);

    this.onSubmit = this.onSubmit.bind(this);
    this.handlePasswordEntry = this.handlePasswordEntry.bind(this);
    this.handleConfirmEntry = this.handleConfirmEntry.bind(this);
    this.state = {
      error: false,
      errorMessage: '',
      password: '',
      confirm: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        in: true,
      }, () => {
        console.log(this.state);
      });
    }, 200);
  }


  onSubmit(e) {
    const search = this.props.location.search;
    const qs = new URLSearchParams(decodeURIComponent(search));
    const accessToken = qs.get('access_token');
    e.preventDefault();
    if (this.state.password === this.state.confirm) {
      const reset = { password: this.state.password, confirm: this.state.confirm };
      this.props.resetPassword(accessToken, reset);
    }
    else {
      this.setState({
        error: true,
        errorMessage: 'PASSWORDS DO NOT MATCH',
      });
      return false;
    }
    return true;
  }


  handlePasswordEntry(e) {
    this.setState({ password: e.target.value });
  }

  handleConfirmEntry(e) {
    this.setState({ confirm: e.target.value });
  }

  render() {
    return (
      <Transition in={this.state.in} timeout={200} mountOnEnter unmountOnExit>
        { status => (<div className={`fade-transition fade-${status}`}>
          <Card className="form-signin">
            <CardBody>
              <LoginLogo />
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <div className="mt-1 mb-2">
                    <Label className="sr-only">New Password</Label>
                    <Input id="password" className={this.state.error ? 'is-invalid' : ''} type="password" onChange={this.handlePasswordEntry} placeholder="Enter new password" name="password" value={this.state.password} />
                    <FormFeedback>{this.state.errorMessage}</FormFeedback>
                  </div>
                  <div className="mt-1 mb-2">
                    <Label className="sr-only">Confirm Password</Label>
                    <Input id="confirm" type="password" onChange={this.handleConfirmEntry} className="form-control" placeholder="Confirm new password" name="confirm" value={this.state.confirm} />
                  </div>
                  <Button className="btn btn-lg btn-block" color="primary">Reset Password</Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>)}
      </Transition>);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (token, pwd) => {
      dispatch(AuthenticationActions.resetPassword(token, pwd));
    },
  };
}

ResetPasswordForm.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
