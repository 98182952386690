import React from 'react';
import PropTypes from 'prop-types';

const SiteMap = ({ site, height, width }) => {
  const haveAddress = site && site.location && site.location.lat && site.location.lon;
  if (haveAddress && window.googleApiKey) {
    return (
      <iframe
        height={height}
        width={width}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        title="Site map"
        src={`https://maps.google.com/maps/embed/v1/place?q=${site.location.lat},${site.location.lon}&key=${window.googleApiKey}`}
      />
    );
  }
  else if (haveAddress) {
    return (<div style={{ height, width }}>Map is not available</div>);
  }
  return null;
};

SiteMap.propTypes = {
  site: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  }),
  height: PropTypes.number,
  width: PropTypes.number,
};

SiteMap.defaultProps = {
  site: null,
  height: 300,
  width: 400,
};

export default SiteMap;
