import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DocLink from './DocLink';
import DocDeleteModal from './DocDeleteModal';

const InitialState = {
  modalOpen: false,
  doc: null,
};

class DocList extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = InitialState;
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleConfirmUnlink = this.handleConfirmUnlink.bind(this);
  }

  handleDeleteClick(doc) {
    this.setState({
      modalOpen: true,
      doc,
    });
  }

  handleCancel() {
    this.setState(InitialState);
  }

  handleConfirmDelete() {
    this.props.onDocDelete(this.state.doc).then(this.handleCancel);
  }

  handleConfirmUnlink() {
    this.props.onDocUnlink(this.state.doc, this.props.item).then(this.handleCancel);
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.renderDocs()}
        <DocDeleteModal
          isOpen={this.state.modalOpen}
          doc={this.state.doc}
          itemText={this.props.itemText}
          onCancel={this.handleCancel}
          onDocDelete={this.handleConfirmDelete}
          onDocUnlink={this.handleConfirmUnlink}
        />
      </div>
    );
  }

  renderDocs() {
    if (!this.props.documents || !this.props.documents.length) {
      return null;
    }

    return this.props.documents.map(doc => (
      <DocLink key={doc.id} doc={doc} onDocClick={this.props.onNavToDocument} canEdit={this.props.canEditItem} onDocDelete={this.handleDeleteClick} disabled={this.props.disabled} />
    ));
  }
}

DocList.propTypes = {
  item: PropTypes.object,
  itemText: PropTypes.string.isRequired,
  documents: PropTypes.array,
  canEditItem: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,

  onNavToDocument: PropTypes.func.isRequired,
  onDocDelete: PropTypes.func.isRequired,
  onDocUnlink: PropTypes.func.isRequired,
};

DocList.defaultProps = {
  item: null,
  documents: [],
  className: null,
  disabled: false,
};

export default DocList;
