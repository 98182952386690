// eslint-disable-next-line max-classes-per-file
import parse from 'url-parse';
import { ConnectionTypes, ValidationPatterns } from './connection-constants';

class Connection {
  constructor(protocol, connectionType, validationPattern) {
    this._protocol = protocol;
    this._connectionType = connectionType;
    this._validationPattern = validationPattern;
  }

  get protocol() {
    return this._protocol;
  }

  get connectionType() {
    return this._connectionType;
  }

  get isValid() {
    if (!this._validationPattern) {
      return true;
    }
    return this._validationPattern.test(this.asUri());
  }

  // eslint-disable-next-line class-methods-use-this
  asUri() {
    return undefined;
  }
}

class NoConnection extends Connection {
  constructor() {
    const none = ConnectionTypes.NONE;
    super(none.protocol, none.connectionType);
  }
}

class ParticleConnection extends Connection {
  constructor(deviceId) {
    const particle = ConnectionTypes.PARTICLE;
    const validationPattern = ValidationPatterns.validParticleUriPattern;
    super(particle.protocol, particle.connectionType, validationPattern);
    this._deviceId = deviceId;
  }

  get deviceId() {
    return this._deviceId;
  }

  asUri() {
    return `${this._protocol}://${this._deviceId}`;
  }
}

class TcpConnection extends Connection {
  constructor(host, port, securityCode, maxReportDurationSeconds) {
    const tcp = ConnectionTypes.TCP;
    const validationPattern = ValidationPatterns.validTcpUriPattern;
    super(tcp.protocol, tcp.connectionType, validationPattern);
    this._host = host;
    this._port = port;
    this._securityCode = securityCode;
    this._maxReportDurationSeconds = maxReportDurationSeconds;
  }

  get host() {
    return this._host;
  }

  get port() {
    return this._port;
  }

  get securityCode() {
    return this._securityCode;
  }

  get maxReportDurationSeconds() {
    return this._maxReportDurationSeconds;
  }

  get isHostValid() {
    if (!this.host) {
      return false;
    }

    return ValidationPatterns.validTcpHostPattern.test(this._host);
  }

  get isPortValid() {
    if (!this._port) {
      return false;
    }

    return ValidationPatterns.validTcpPortPattern.test(this._port);
  }

  get isSecurityCodeValid() {
    if (!this._securityCode) {
      return true;
    }

    return ValidationPatterns.validSecurityCodePattern.test(this._securityCode);
  }

  get isMaxReportDurationSecondsValid() {
    if (!this._maxReportDurationSeconds) {
      return true;
    }

    return ValidationPatterns.validMaxReportDurationSecondsPattern.test(this.securityCode);
  }

  asUri() {
    let uri = `${this._protocol}://${this._host}:${this._port}`;

    let params;
    if (this._securityCode) {
      params = `?securityCode=${this._securityCode}`;
    }

    if (this._maxReportDurationSeconds) {
      if (params) {
        params += `&maxReportDurationSeconds=${this._maxReportDurationSeconds}`;
      }
      else {
        params = `?maxReportDurationSeconds=${this._maxReportDurationSeconds}`;
      }
    }

    if (params) {
      uri += params;
    }

    return uri;
  }
}

export default {
  fromUri: connection => {
    if (!(connection && connection.connectionType)) {
      return new NoConnection();
    }

    const uri = connection.uri ? parse(connection.uri, true) : '';
    if (ConnectionTypes.PARTICLE.connectionType === connection.connectionType) {
      return new ParticleConnection(`${uri.host}${uri.pathname}`);
    }

    const query = uri.query || {};
    return new TcpConnection(uri.hostname, uri.port, query.securityCode, query.maxReportDurationSeconds);
  },
  create: connectionType => {
    if (ConnectionTypes.PARTICLE.connectionType === connectionType) {
      return new ParticleConnection();
    }
    else if (ConnectionTypes.TCP.connectionType === connectionType) {
      return new TcpConnection();
    }

    return new Connection(null, ConnectionTypes.NONE.connectionType, null);
  },
  createParticleConnection: deviceId => new ParticleConnection(deviceId),
  createTcpConnectionWithHost: (tcpConnectionDetail, host) => new TcpConnection(host, tcpConnectionDetail.port, tcpConnectionDetail.securityCode, tcpConnectionDetail.maxReportDurationSeconds),
  createTcpConnectionWithPort: (tcpConnectionDetail, port) => new TcpConnection(tcpConnectionDetail.host, port, tcpConnectionDetail.securityCode, tcpConnectionDetail.maxReportDurationSeconds),
  createTcpConnectionWithSecurityCode: (tcpConnectionDetail, securityCode) => new TcpConnection(tcpConnectionDetail.host, tcpConnectionDetail.port, securityCode, tcpConnectionDetail.maxReportDurationSeconds),
  createTcpConnectionWithMaxReportDurationSeconds: (tcpConnectionDetail, maxReportDurationSeconds) => new TcpConnection(tcpConnectionDetail.host, tcpConnectionDetail.port, tcpConnectionDetail.securityCode, maxReportDurationSeconds),
  createTcpConnection: (host, port, securityCode, maxReportDurationSeconds) => new TcpConnection(host, port, securityCode, maxReportDurationSeconds),
};
