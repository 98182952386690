function externalAccess(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.externalAccess) || {};
}

export function orgGrants(state) {
  return externalAccess(state).orgGrants || [];
}

export function receivedOrgGrants(state) {
  return externalAccess(state).receivedOrgGrants || [];
}
