import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';

import { withTracking } from '../../../shared/analytics';
import * as selectors from '../PreferencesSelectors';
import { resetUpdatedEmail, saveUpdatedEmail, setUpdatedEmail } from '../actions';
import { currentEmail } from '../../../authentication/AuthenticationSelectors';
import UserAlert from './UserAlert';
import PreferenceConstants from '../PreferenceConstants';

class EmailSettings extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.onValueChange = this.onValueChange.bind(this);
  }

  onValueChange(event) {
    this.props.setUpdatedEmail(event.target.value);
  }

  render() {
    return (
      <div className={this.props.className}>
        <h5>Change email</h5>
        <UserAlert text={PreferenceConstants.updateEmailSuccess} isOpen={this.props.updateSuccess} color="primary" />
        <Form>
          <FormGroup>
            <Label>Current email</Label>
            <Input
              type="email"
              id="currentEmail"
              value={this.props.currentEmail}
              disabled
              bsSize="sm"
            />
          </FormGroup>
          <FormGroup>
            <Label>Email address</Label>
            <Input
              type="email"
              id="updatedEmail"
              placeholder="new.email@yourorganization.com"
              onChange={this.onValueChange}
              value={this.props.updatedEmail}
              bsSize="sm"
            />
          </FormGroup>
        </Form>
        <div>
          <Button color="primary" size="sm" outline onClick={this.props.resetUpdatedEmail} disabled={!(this.props.updatedEmail)}>Cancel</Button>
          {' '}
          <Button color="primary" size="sm" onClick={this.props.saveUpdatedEmail} disabled={!this.props.canSave}>Save</Button>
        </div>
      </div>
    );
  }
}

EmailSettings.propTypes = {
  currentEmail: PropTypes.string.isRequired,
  setUpdatedEmail: PropTypes.func.isRequired,
  saveUpdatedEmail: PropTypes.func.isRequired,
  canSave: PropTypes.bool.isRequired,
  resetUpdatedEmail: PropTypes.func.isRequired,
  updatedEmail: PropTypes.string,
  updateSuccess: PropTypes.bool,
  className: PropTypes.string,
};

EmailSettings.defaultProps = {
  updatedEmail: '',
  updateSuccess: false,
  className: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    currentEmail: currentEmail(state),
    canSave: selectors.canSaveEmail(state),
    updatedEmail: selectors.updatedEmail(state),
    updateSuccess: selectors.updateEmailSuccess(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetUpdatedEmail: () => dispatch(resetUpdatedEmail()),
    setUpdatedEmail: (value) => dispatch(setUpdatedEmail(value)),
    saveUpdatedEmail: () => dispatch(saveUpdatedEmail()),
  };
}

export default withTracking('Preferences', 'Email')(connect(mapStateToProps, mapDispatchToProps)(EmailSettings));
