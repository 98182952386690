import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { IssuePriorities } from '../../AppConstants';
import PriorityIndicator from './PriorityIndicator';

const priorities = [IssuePriorities.EMERGENCY, IssuePriorities.NORMAL, IssuePriorities.LOW];

class PriorityDropdown extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      dropdownOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(currentState => ({
      dropdownOpen: !currentState.dropdownOpen,
    }));
  }

  render() {
    return (
      <Dropdown className={this.props.className} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        {priorities.includes(this.props.priority)
          ? (
            <DropdownToggle caret color="default" className="p-0">
              <PriorityIndicator priority={this.props.priority} />
            </DropdownToggle>
          ) : null}
        <DropdownMenu>
          {priorities.map((priority) => (
            <DropdownItem key={priority} onClick={() => this.props.onPriorityChange(priority)}>
              <PriorityIndicator priority={priority} />
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

PriorityDropdown.propTypes = {
  className: PropTypes.string,
  priority: PropTypes.string.isRequired,
  onPriorityChange: PropTypes.func.isRequired,
};

PriorityDropdown.defaultProps = {
  className: null,
};

export default PriorityDropdown;
