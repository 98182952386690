import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import update from 'immutability-helper/index';

import DispatchSystemEditorContainer from './containers/DispatchSystemEditorContainer';
import DispatchSystemDropdownContainer from './containers/DispatchSystemDropdownContainer';

class DispatchSystemsConfigurationList extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.closeDispatchSystemModal = this.closeDispatchSystemModal.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.renderModalHeader = this.renderModalHeader.bind(this);
    this.renderModalDispatchSystemSelector = this.renderModalDispatchSystemSelector.bind(this);
    this.saveDispatchSystemModal = this.saveDispatchSystemModal.bind(this);
    this.handelModalDispatchSystemSelect = this.handelModalDispatchSystemSelect.bind(this);
    this.handleModalValueChange = this.handleModalValueChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.state = {
      modalOpen: false,
      canSave: false,
      currentDispatchSystemConfiguration: null,
    };
  }

  closeDispatchSystemModal() {
    this.setState({
      modalOpen: false,
      canSave: false,
      currentDispatchSystemConfiguration: null,
    });
  }

  handleAddClick() {
    this.setState({
      modalOpen: true,
      canSave: false,
      currentDispatchSystemConfiguration: {
        customerId: null,
        settings: {},
        enabled: false,
      },
    });
  }

  handleEditClick(index) {
    const currentDispatchSystemConfiguration = this.props.dispatchSystems[index];
    let configuration = currentDispatchSystemConfiguration;
    if (!currentDispatchSystemConfiguration.settings) {
      configuration = update(currentDispatchSystemConfiguration, {
        settings: {
          $set: {},
        },
      });
    }
    this.setState({
      modalOpen: true,
      canSave: false,
      currentDispatchSystemConfiguration: configuration,
    });
  }

  handelModalDispatchSystemSelect(dispatchSystem) {
    const newState = update(this.state, {
      currentDispatchSystemConfiguration: {
        systemName: {
          $set: dispatchSystem,
        },
      },
    });

    this.setState(newState);
  }

  handleModalValueChange(dispatchSystemConfiguration, canSave) {
    this.setState(
      {
        modalOpen: true,
        canSave,
        currentDispatchSystemConfiguration: dispatchSystemConfiguration,
      },
    );
  }


  saveDispatchSystemModal() {
    this.props.onSaveDispatchSystemConfiguration(this.state.currentDispatchSystemConfiguration);
    this.closeDispatchSystemModal();
  }


  renderModalHeader() {
    const currentDispatchSystemConfiguration = this.state.currentDispatchSystemConfiguration;

    return currentDispatchSystemConfiguration ?
      `Edit ${currentDispatchSystemConfiguration.systemName} Configuration` :
      'Add Dispatch System Configuration';
  }

  renderModalFooter() {
    return (
      <div className="d-flex flex-wrap justify-content-end">
        <Button color="primary" outline size="sm" className="my-2" onClick={this.closeDispatchSystemModal}>Cancel</Button>
        {' '}
        <Button color="primary" size="sm" className="mx-2 my-2" onClick={this.saveDispatchSystemModal} disabled={!this.state.canSave}>Save</Button>
      </div>
    );
  }

  renderModalDispatchSystemSelector() {
    const currentDispatchSystemConfiguration = this.state.currentDispatchSystemConfiguration;

    if (!this.state.modalOpen || currentDispatchSystemConfiguration.id) {
      return null;
    }

    const dispatchSystem = (currentDispatchSystemConfiguration && currentDispatchSystemConfiguration.systemName) || '';
    const excludeDispatchSystems = this.props.dispatchSystems.map(system => system.systemName);

    return (
      <div className="mb-3">
        <strong>Dispatch System</strong>
        <DispatchSystemDropdownContainer
          excludeDispatchSystems={excludeDispatchSystems}
          selectedDispatchSystem={dispatchSystem}
          onDispatchSystemSelect={this.handelModalDispatchSystemSelect}
        />
      </div>
    );
  }

  renderModalDispatchSystemConfigurationEditor() {
    return (
      <DispatchSystemEditorContainer canSave={this.state.canSave} onSaveDispatchSystemConfiguration={this.saveDispatchSystemModal} onDispatchSystemConfigurationValueChanged={this.handleModalValueChange} dispatchSystemConfiguration={this.state.currentDispatchSystemConfiguration} />
    );
  }


  render() {
    return (
      <div>
        {this.props.dispatchSystems.map((current, index) => (
          <div className="mb-3 d-flex justify-content-between align-items-center" key={current.systemName}>
            <span>{current.systemName}</span>
            <Button size="sm" color="primary" outline className="ml-4 mr-4" onClick={() => this.handleEditClick(index)}>Edit Settings</Button>
          </div>
        ))}
        <Button size="sm" color="primary" onClick={() => this.handleAddClick()}>Add Dispatch System</Button>
        <Modal isOpen={this.state.modalOpen} toggle={this.closeDispatchSystemModal} size="lg">
          <ModalHeader>
            {this.renderModalHeader()}
          </ModalHeader>

          <ModalBody style={{ padding: '20px ' }}>
            {this.renderModalDispatchSystemSelector()}
            {this.renderModalDispatchSystemConfigurationEditor()}
          </ModalBody>

          <ModalFooter>
            {this.renderModalFooter()}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

DispatchSystemsConfigurationList.propTypes = {
  dispatchSystems: PropTypes.array.isRequired,
  onSaveDispatchSystemConfiguration: PropTypes.func.isRequired,
};

export default DispatchSystemsConfigurationList;
