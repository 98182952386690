import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DropTarget, DragSource } from 'react-dnd';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

class InventoryColumnDragSource extends Component {
  render() {
    const style = {
      border: '1px dashed gray',
      padding: '0.5rem',
      margin: '.5rem',
      backgroundColor: 'white',
      cursor: 'move',
      opacity: this.props.isDragging ? 0 : 1,
      lineHeight: '1.0',
      fontSize: '90%',
    };

    return this.props.connectDragSource(
      this.props.connectDropTarget(
        <div style={style} className="d-flex flex-row align-items">
          <div>
            <strong>{this.props.inventoryColumn.name}</strong>
            {this.props.showDescription ? <span>&nbsp;- {this.props.inventoryColumn.description}</span> : null }
            {this.props.showExample ? <br /> : null }
            {this.props.showExample ?
              <span className="text-muted" style={{ fontSize: '.65em' }}>
                Example: &quot;{this.props.inventoryColumn.example}&quot;
              </span>
              : null
            }
          </div>
          {this.props.removeColumn ?
            <div className="ml-auto">
              <Button color="link" onClick={() => this.props.removeColumn(this.props.inventoryColumn)}>
                <span className="text-muted"><FontAwesomeIcon icon={faTimesCircle} /></span>
              </Button>
            </div>
            :
            null
          }
        </div>
      )
    );
  }
}

const columnSource = {
  beginDrag(props) {
    return {
      inventoryColumn: props.inventoryColumn,
      index: props.index,
      containerId: props.containerId,
    };
  },
};

const columnTarget = {
  hover(props, monitor, component) {
    if (!props.reorderColumns) {
      return;
    }

    const dragItem = monitor.getItem();
    const dragIndex = dragItem.index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Ignore if it came from a different container instance
    if (dragItem.containerId !== props.containerId) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.reorderColumns(dragIndex, hoverIndex);

    // // Note: we're mutating the monitor item here!
    // // Generally it's better to avoid mutations,
    // // but it's good here for the sake of performance
    // // to avoid expensive index searches.
    dragItem.index = hoverIndex;
  },
};

function sourceCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

function targetCollection(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

InventoryColumnDragSource.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired,

  containerId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  inventoryColumn: PropTypes.object.isRequired,
  showDescription: PropTypes.bool,
  showExample: PropTypes.bool,

  reorderColumns: PropTypes.func,
  removeColumn: PropTypes.func,
};

InventoryColumnDragSource.defaultProps = {
  reorderColumns: null,
  removeColumn: null,
  showDescription: true,
  showExample: true,
};

export default DropTarget('INV_COL', columnTarget, targetCollection)(DragSource('INV_COL', columnSource, sourceCollect)(InventoryColumnDragSource));
