export function tankCount(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.counts && state.sites.equipment.counts.tankCount) || { count: 0 };
}

export function lineCount(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.counts && state.sites.equipment.counts.lineCount) || { count: 0 };
}

export function sensorCount(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.counts && state.sites.equipment.counts.sensorCount) || { count: 0 };
}

export function countsLoading(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.counts && state.sites.equipment.counts.countsLoading) || false;
}

