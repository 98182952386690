import OrgBreadcrumbActionTypes from './BreadcrumbActionTypes';

export function reset(name, path) {
  return {
    type: OrgBreadcrumbActionTypes.RESET,
    location: {
      name,
      path,
    }
  };
}

export function push(name, path) {
  return {
    type: OrgBreadcrumbActionTypes.PUSH,
    location: {
      name,
      path,
    }
  };
}
