import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import flow from 'lodash/flow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserCog } from '@fortawesome/free-solid-svg-icons';

import CanaryLogo from '../../shared/components/logo/CanaryLogo';
import { AuthenticationActions, AuthenticationSelectors } from '../../authentication/index';


class AdminNav extends Component {
  constructor() {
    super();

    this.state = {
      dropdownOpen: false,
      navbarOpen: false,
    };

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.exitAdmin = this.exitAdmin.bind(this);
  }

  toggleNavbar() {
    this.setState(state => ({
      navbarOpen: !state.navbarOpen,
    }));
  }

  toggleDropdown() {
    this.setState(state => ({
      dropdownOpen: !state.dropdownOpen,
    }));
  }

  handleLogout() {
    this.props.logout();
    if (this.props.onLogout) this.props.onLogout();
  }

  exitAdmin() {
    this.props.history.push('/');
  }

  render() {
    return (
      <Navbar light className="border-bottom bg-gray-100" expand="md">
        <NavbarBrand to="/admin" href="/admin">
          <CanaryLogo height={32} width={32} />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggleNavbar} />
        <Collapse isOpen={this.state.navbarOpen} navbar>
          <Nav navbar className="mr-auto">
            <NavItem>
              <NavLink to="/admin/organizations" className="nav-link">
                Organizations
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/admin/sites" className="nav-link">
                Sites
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/admin/inventory" className="nav-link">
                Inventory
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/admin/ingest-failures" className="nav-link">
                Ingest
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar>
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} nav inNavbar>
              <DropdownToggle className="nav-link" color="link" tag="a">
                <FontAwesomeIcon title="Settings" role="alert" icon={faUserCog} />
                <span className="d-md-none ml-2">User Settings</span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-md-right">
                <DropdownItem className="btn-sm" disabled>
                  <strong>
                    <FontAwesomeIcon title="User" role="alert" className="mr-2" icon={faUser} />
                    {this.props.username}
                  </strong>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="btn-sm" onClick={this.exitAdmin}> Go to Customer Site</DropdownItem>
                <DropdownItem className="btn-sm" onClick={() => this.handleLogout()}>Sign out</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: AuthenticationSelectors.currentUsername(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch(AuthenticationActions.logout());
    },
  };
}


AdminNav.propTypes = {
  history: PropTypes.object.isRequired,
  onLogout: PropTypes.func,
  logout: PropTypes.func.isRequired,
  username: PropTypes.string,
};

AdminNav.defaultProps = {
  onLogout: null,
  username: '',
};

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  DragDropContext(HTML5Backend),
)(withRouter(AdminNav));
