import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ATGSettingsContainer from '../screens/atg/containers/ATGSettingsContainer';
import TankRegistration from '../screens/tank-registration/containers/TankRegistration';
import TankListContainer from '../screens/tank-list/containers/TankListContainer';
import LinesEditor from '../screens/atg-lines/containers/LinesEditor';
import SensorsEditor from '../screens/atg-sensors/containers/SensorsEditor';
import * as selectors from '../EquipmentSelectors';
import * as actions from '../actions';

class SiteEquipmentContainer extends Component {
  componentDidMount() {
    this.props.getEquipmentCounts();
  }

  render() {
    return (
      <Switch>
        <Redirect exact="exact" from="/sites/:siteId/equipment/" to={`${this.props.match.url}/atg`} />
        <Route exact path="/sites/:siteId/equipment/atg" name="atg">
          <ATGSettingsContainer />
        </Route>
        <Route exact path="/sites/:siteId/equipment/tanks" name="tanks">
          <TankListContainer />
        </Route>
        <Route exact path="/sites/:siteId/equipment/lines" name="lines">
          <LinesEditor />
        </Route>
        <Route exact path="/sites/:siteId/equipment/sensors" name="sensors">
          <SensorsEditor />
        </Route>
        <Route exact path="/sites/:siteId/equipment/tanks/registrations/:tankRegistrationId" name="tankRegistration">
          <TankRegistration />
        </Route>
        <Route exact path="/sites/:siteId/equipment/tanks/:tankId/registration" name="tankRegistration">
          <TankRegistration />
        </Route>
      </Switch>
    );
  }
}

SiteEquipmentContainer.propTypes = {
  match: PropTypes.object.isRequired,
  getEquipmentCounts: PropTypes.func.isRequired,
};

SiteEquipmentContainer.defaultProps = {
};

function mapStateToProps(state) {
  return {
    tankCount: selectors.tankCount(state),
    lineCount: selectors.lineCount(state),
    sensorCount: selectors.sensorCount(state),
    countsLoading: selectors.countsLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEquipmentCounts: () => dispatch(actions.getEquipmentCounts()),
  };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteEquipmentContainer));
