import ActionTypes from './ATGLinesActionTypes';

export default function ATGLinesReducer(state = {}, action) {
  switch (action.type) {

    // receive site with lines (initial load)
    case ActionTypes.REQUEST_SITE_LINES:
      return Object.assign({}, state, {
        linesLoading: true,
        isError: false,
        error: null,
      });
    case ActionTypes.RECEIVE_SITE_LINES:
      return Object.assign({}, state, {
        currentSiteLines: action.currentSiteLines,
        linesLoading: false,
        lineSaving: false,
        isError: false,
      });
    case ActionTypes.RECEIVE_SITE_LINES_ERROR:
      return Object.assign({}, state, {
        linesLoading: false,
        isError: true,
        error: action.error,
      });
    default:
      return state;
  }
}
