import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CommentInput from '../../../../../../../shared/components/comment/CommentInput';
import * as selectors from '../ComplianceReportSelectors';
import * as actions from '../ComplianceReportActions';

const ComplianceCommentInput = ({ isEditingComment, isSavingComment, commentText, editComment, cancelComment, saveComment, className, isOverridingStatus }) => (
  <CommentInput
    visible={isEditingComment}
    saving={isSavingComment}
    commentText={commentText}
    onCancel={cancelComment}
    onSave={saveComment}
    onCommentTextChange={editComment}
    className={className}
    isRequired={isOverridingStatus}
  />
);

ComplianceCommentInput.propTypes = {
  isEditingComment: PropTypes.bool.isRequired,
  isSavingComment: PropTypes.bool.isRequired,
  commentText: PropTypes.string,
  editComment: PropTypes.func.isRequired,
  cancelComment: PropTypes.func.isRequired,
  saveComment: PropTypes.func.isRequired,
  className: PropTypes.string,
  isOverridingStatus: PropTypes.bool.isRequired,
};

ComplianceCommentInput.defaultProps = {
  commentText: '',
  className: null,
};

function mapStateToProps(state) {
  return {
    isEditingComment: selectors.isEditingComment(state),
    isSavingComment: selectors.isSavingComment(state),
    commentText: selectors.commentText(state),
    isOverridingStatus: selectors.isOverridingStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editComment: commentText => dispatch(actions.editComment(commentText)),
    cancelComment: () => dispatch(actions.cancelComment()),
    saveComment: () => dispatch(actions.saveComment()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComplianceCommentInput));
