import React from 'react';
import { Row, Col } from 'reactstrap';
import OverallSiteConnectivityGauge from '../sites/screens/site-dashboard/containers/OverallSiteConnectivityGauge';
import ParticleSiteConnectivityGauge from '../sites/screens/site-dashboard/containers/ParticleSiteConnectivityGauge';
import TCPSiteConnectivityGauge from '../sites/screens/site-dashboard/containers/TCPSiteConnectivityGauge';
import InvSite1HourGauge from '../inventory/screens/inv-dashboard/containers/InvSite1HourGauge';
import InvSite24HourGauge from '../inventory/screens/inv-dashboard/containers/InvSite24HourGauge';
import FuelHaulerGauge from '../inventory/screens/inv-dashboard/containers/FuelHaulerGauge';

const AdminDashboard = () => (
  <div>
    <Row>
      <Col xs={4} className="my-2">
        <h4>Device Responsiveness</h4>
      </Col>
    </Row>
    <Row className="border-bottom">
      <Col sm={4} className="my-2">
        <OverallSiteConnectivityGauge />
      </Col>
      <Col sm={4} className="my-2">
        <ParticleSiteConnectivityGauge />
      </Col>
      <Col sm={4} className="my-2">
        <TCPSiteConnectivityGauge />
      </Col>
    </Row>
    <Row>
      <Col xs={4} className="mt-4 mb-2">
        <h4>Inventory Health</h4>
      </Col>
    </Row>
    <Row>
      <Col sm={4} className="my-2">
        <InvSite1HourGauge />
      </Col>
      <Col sm={4} className="my-2">
        <InvSite24HourGauge />
      </Col>
      <Col sm={4} className="my-2">
        <FuelHaulerGauge />
      </Col>
    </Row>
  </div>
);

export default AdminDashboard;
