import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import moment from 'moment-timezone';

import { BrowserTimezone } from '../../../../../../../../shared/components/date';
import DispatchStatusIcon from '../../../../../../../../shared/components/dispatch/DispatchStatusIcon';
import * as actions from './IssueDispatchActions';
import * as selectors from './IssueDispatchSelectors';


class IssueDispatchActivity extends Component {
  componentDidMount() {
    this.props.getDispatchActivity();
  }

  render() {
    if (!this.props.dispatchActivity) {
      return null;
    }

    return (
      <div>
        {this.props.dispatchActivity.map(activity => (
          <div key={activity.statusDate}>
            <Row>
              <Col className="col-12 mt-3">
                <strong>{activity.userName}</strong>
                {' '}
                {moment(activity.statusDate).tz(BrowserTimezone).format('M/D/YY')}
                {' '}
                at
                {' '}
                {moment(activity.statusDate).tz(BrowserTimezone).format('hh:mm A z')}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="col-12">
                <div className="speech-bubble p-3" style={{ verticalAlign: 'middle' }}>
                  {' '}
                  <DispatchStatusIcon dispatchWorkflowStatus={activity.workflowStatus} />
                  {' '}
                  <span className="ml-1">{activity.statusText}</span>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  }
}

IssueDispatchActivity.propTypes = {
  dispatchActivity: PropTypes.array,
  getDispatchActivity: PropTypes.func.isRequired,
};

IssueDispatchActivity.defaultProps = {
  dispatchActivity: null,
};

function mapStateToProps(state) {
  return {
    dispatchActivity: selectors.dispatchActivity(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDispatchActivity: () => dispatch(actions.getDispatchActivity()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueDispatchActivity);
