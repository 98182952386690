import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import FlipMove from 'react-flip-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import RecentReport from './RecentReport';
import PendingIntent from './PendingIntent';
import GenerateReportAnchor from './GenerateReportAnchor';
import { CommandRequestStatus } from '../../../../../AppConstants';


const browserTimezone = moment.tz.guess();

const isOlderThanOneHour = (commandRequest) => {
  return moment(commandRequest.created).tz(browserTimezone).isBefore(moment().subtract(1, 'hours'));
};

function smoothScroll(e) {
  e.preventDefault();
  const offset = document.getElementById('atgReports').offsetTop;

  const id = e.target.getAttribute('href').slice(1);

  window.scroll({
    top: document.getElementById(`${id}`).offsetTop + offset,
    behavior: 'smooth',
  });
}

const RecentReports = ({ recentSiteCommandRequests, pendingIntents, navToSiteIssues }) => {
  function renderReports(reports) {
    return reports.map(cr => (
      <RecentReport key={cr.id} commandRequest={cr} navToSiteIssues={navToSiteIssues}/>
    ));
  }

  function sortCommandRequests(reports) {
    return reports.reduce((groups, req) => {
      const formattedDate = req && req.event ? moment(req.event.receivedDate).tz(browserTimezone).format('MM/DD/YY') : moment(req.created).tz(browserTimezone).format('MM/DD/YY');
      const dateGroup = groups.find(g => g.date === formattedDate);
      if (dateGroup) {
        dateGroup.commandRequests.push(req);
      }
      else {
        groups.push({
          date: formattedDate,
          commandRequests: [req],
        });
      }

      return groups;
    }, []);
  }

  function renderLinks(reports) {
    if (reports.length === 0) {
      return (
        <small className="text-muted">Awaiting ATG response...</small>
      );
    }

    const sortedCommandRequests = sortCommandRequests(reports);

    return sortedCommandRequests.map(g => (
      <div className="mb-2" key={g.date}>
        <small><strong>{g.date}</strong></small>
        {g.commandRequests.map(cr => (
          <div
            key={cr.id}
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '100%',
              textOverflow: 'ellipsis',
            }}
          >
            { cr.status === 'Failed' ?
              <FontAwesomeIcon size="sm" color="#FF0000" icon={faExclamationTriangle} />
            : null }
            <small className="text-muted">
              {cr && cr.event ? moment(cr.event.receivedDate).tz(browserTimezone).format('h:mmA') : moment(cr.created).tz(browserTimezone).format('h:mmA')}
            </small>

            { cr.functionName ?
              cr.status === CommandRequestStatus.Failed && isOlderThanOneHour(cr) ?
                <small className="text-muted" style={{ marginLeft: '0.5rem' }}>{cr.functionName}</small>
                :
                <small>
                  <a onClick={smoothScroll} href={`#${GenerateReportAnchor(cr.id)}`} style={{ marginLeft: '0.5rem' }}>
                    {cr.functionName}
                  </a>
                </small>
              :
              cr.status === CommandRequestStatus.Failed && !isOlderThanOneHour(cr) ?
                <small>
                  <a onClick={smoothScroll} href={`#${GenerateReportAnchor(cr.id)}`} style={{ marginLeft: '0.5rem' }}>
                  Unknown Command
                  </a>
                </small>
                :
                <small className="text-muted" style={{ marginLeft: '0.5rem' }}>Unknown Command</small>
            }
          </div>
        ))}
      </div>
    ));
  }

  function renderPendingIntents() {
    return pendingIntents.map(i => (
      <PendingIntent key={i.id} intent={i} />
    ));
  }

  if (recentSiteCommandRequests.length > 0 || pendingIntents.length > 0) {
    const reportsToDisplay = recentSiteCommandRequests.filter(cr =>
      ((cr.status === CommandRequestStatus.Resolved && cr.event.commandFormat === 'TEXT') || cr.status === CommandRequestStatus.Failed),
    );

    return (
      <div className="row mt-2">
        <div id="atgReports" className="col-md-8">
          <FlipMove duration={500} enterAnimation="accordionVertical" leaveAnimation="accordionVertical">
            {renderPendingIntents()}
            {renderReports(reportsToDisplay)}
          </FlipMove>
        </div>
        <div className="col-md-4" style={{ borderLeft: '1px solid #dfdfdf' }}>
          {renderLinks(reportsToDisplay)}
        </div>
      </div>
    );
  }

  return (
    <div className="p-1">
      <p className="text-xs-center text-muted">No reports requested for this station in the last month.</p>
    </div>
  );
};

RecentReports.propTypes = {
  recentSiteCommandRequests: PropTypes.array.isRequired,
  pendingIntents: PropTypes.array.isRequired,
  navToSiteIssues: PropTypes.func.isRequired,
};


export default RecentReports;
