import React, { Component } from 'react';
import { Alert, Form, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper/index';

import HelpIcon from '../../../../../components/help-icon/HelpIcon';
import Timezone from '../../../../../../shared/components/timezone/Timezone';

class FuelHaulerSettings extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.state = {
      enableHelp: false,
      timezoneHelp: false,
      fileFormatHelp: false,
      filenameHelp: false,
    };

    this.handleNameChange = this.buildSetPropFunction('name');
    this.toggleEnableHelp = this.buildToggleHelpFunction('enableHelp');
    this.handleEnabledChange = this.buildSetPropFunction('enabled');
    this.toggleTimezoneHelp = this.buildToggleHelpFunction('timezoneHelp');
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
  }

  buildSetPropFunction(propName) {
    function func(event) {
      const updateSpec = {};
      let newValue;
      if (event.target.type === 'checkbox') {
        newValue = event.target.checked;
      }
      else {
        newValue = event.target.value || '';
      }
      updateSpec[propName] = {
        $set: newValue,
      };
      const updatedFuelHauler = update(this.props.selectedFuelHauler, updateSpec);
      this.props.updateSelectedFuelHauler(updatedFuelHauler);
    }

    return func.bind(this);
  }

  buildToggleHelpFunction(propName) {
    function func() {
      const updateSpec = {};
      updateSpec[propName] = !this.state[propName];
      this.setState(updateSpec);
    }
    return func.bind(this);
  }

  handleTimezoneChange(newTimezone) {
    this.props.updateSelectedFuelHauler(update(this.props.selectedFuelHauler, {
      fuelHaulerTimezone: {
        $set: newTimezone,
      },
    }));
  }

  render() {
    if (!this.props.selectedFuelHauler) {
      return null;
    }

    return (
      <div className="mt-4">
        <Row>
          <Col md={8}>
            <Form>
              <FormGroup row>
                <Label for="name" sm={4}>Fuel Hauler Name</Label>
                <Col sm={6}>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Fuel hauler name"
                    value={this.props.selectedFuelHauler.name}
                    onChange={this.handleNameChange}
                  />
                </Col>
              </FormGroup>
              <Row>
                <Col sm={12}>
                  <Alert color="info" isOpen={this.state.enableHelp} toggle={this.toggleEnableHelp}>
                    Use this option to temporarily disable inventory exports for this fuel hauler without changing the site list,
                    destination settings, or configured schedules.
                  </Alert>
                </Col>
              </Row>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={this.props.selectedFuelHauler.enabled} onChange={this.handleEnabledChange} />
                  Enable this fuel hauler
                </Label>
                <HelpIcon className="ml-1" onClick={this.toggleEnableHelp} />
              </FormGroup>
              <Row>
                <Col sm={12}>
                  <Alert color="info" isOpen={this.state.timezoneHelp} toggle={this.toggleTimezoneHelp}>
                    Specify the time zone in which the fuel hauler is located.  This field is only used when the&nbsp;
                    <em>inventory_date_fh, inventory_date_fh_no_tz_24h, inventory_date_fh_no_tz_mm_dd_yyyy_24h, inventory_date_fh_no_tz_12h,
                    inventory_date_fh_no_tz_mm_dd_yyyy_12h</em> columns are included in the selected File Format.
                  </Alert>
                </Col>
              </Row>
              <FormGroup row>
                <Col sm={4}>
                  <Label for="timezone">Timezone</Label>
                  <HelpIcon onClick={this.toggleTimezoneHelp} className="ml-1" />
                </Col>
                <Col sm={6}>
                  <Timezone value={this.props.selectedFuelHauler.fuelHaulerTimezone} onTimezoneSelect={this.handleTimezoneChange} />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

FuelHaulerSettings.propTypes = {
  selectedFuelHauler: PropTypes.object,
  updateSelectedFuelHauler: PropTypes.func.isRequired,
};

FuelHaulerSettings.defaultProps = {
  selectedFuelHauler: null,
};

export default FuelHaulerSettings;
