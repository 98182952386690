import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

const NotificationModal = ({ content, isOpen, toggle }) => (
  <Modal isOpen={isOpen} size="lg">
    <ModalHeader toggle={toggle}>Notification</ModalHeader>
    <ModalBody>
      <iframe srcDoc={content} title="Notification" width="100%" height="720px" frameBorder="0" />
    </ModalBody>
  </Modal>
);

NotificationModal.propTypes = {
  content: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

NotificationModal.defaultProps = {
  content: null,
};

export default NotificationModal;
