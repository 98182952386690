import { LOCATION_CHANGE } from 'react-router-redux';
import update from 'immutability-helper';
import reduceReducers from 'reduce-reducers';

export const DefaultState = Object.freeze({
  loading: false,
  editing: false,
  saving: false,
  deleting: false,
  error: null,
});

export const identityFunction = val => val;

export const defaultIdFunction = item => (item && item.id) || item;

export const defaultValidationFunction = () => true;

export const defaultMergeFunction = (oldItem, newItem) => newItem;

export const listInsertBeginningFunction = (list, newItem) => update(list, {
  $unshift: [newItem],
});

export const listInsertEndFunction = (list, newItem) => update(list, {
  $push: [newItem],
});

export const defaultListInsertFunction = listInsertEndFunction;

export const HookTypes = {
  Id: 'Id',
  ListReceive: 'ListReceive',
  ItemReceive: 'ItemReceive',
  PendingEditsMerge: 'PendingEditsMerge',
  PendingEditsValidation: 'PendingEditsValidation',
  PostSaveMerge: 'PostSaveMerge',
  PostSaveListInsert: 'PostSaveListInsert',
};

export const DefaultHooks = {
  [HookTypes.Id]: defaultIdFunction,
  [HookTypes.ListReceive]: identityFunction,
  [HookTypes.ItemReceive]: identityFunction,
  [HookTypes.PendingEditsMerge]: defaultMergeFunction,
  [HookTypes.PendingEditsValidation]: defaultValidationFunction,
  [HookTypes.PostSaveMerge]: defaultMergeFunction,
  [HookTypes.PostSaveListInsert]: defaultListInsertFunction
};

export const listReducer = (getListStartedType, receiveListType, errorType, hooks = DefaultHooks) => (state, action) => {
  const listReceiveTransformFunction = hooks[HookTypes.ListReceive];

  if (action.type === getListStartedType) {
    return Object.assign({}, state, {
      loading: true,
      error: null,
      listEtag: null,
    });
  }
  else if (action.type === receiveListType) {
    return Object.assign({}, state, {
      loading: false,
      list: listReceiveTransformFunction(action.list),
      listEtag: action.etag,
    });
  }
  else if (action.type === errorType) {
    return Object.assign({}, state, {
      loading: false,
      error: action.error,
      list: [],
    });
  }
  return state;
};

export const listRefreshReducer = (refreshStartedType, receiveListType, refreshCanceledType, refreshErrorType, receiveListErrorType) => (state, action) => {
  if (action.type === refreshStartedType) {
    return Object.assign({}, state, {
      refreshing: true,
      error: null,
    });
  }
  else if (action.type === receiveListType || action.type === refreshCanceledType || action.type === receiveListErrorType) {
    return Object.assign({}, state, {
      refreshing: false,
    });
  }
  else if (action.type === refreshErrorType) {
    return Object.assign({}, state, {
      refreshing: false,
      error: action.error,
    });
  }
  return state;
};

export const itemReducer = (getItemStartedType, receiveItemType, errorType, hooks = DefaultHooks) => (state, action) => {
  const itemReceiveTransformFunction = hooks[HookTypes.ItemReceive];

  if (action.type === getItemStartedType) {
    return Object.assign({}, state, {
      loading: true,
      error: null,
    });
  }
  else if (action.type === receiveItemType) {
    const transformedItem = itemReceiveTransformFunction(action.item);
    return Object.assign({}, state, {
      loading: false,
      item: transformedItem,
    });
  }
  else if (action.type === errorType) {
    return Object.assign({}, state, {
      loading: false,
      error: action.error,
      item: null,
    });
  }
  return state;
};

export const itemEditReducer = (startItemEditType, itemEditType, cancelItemEditType, hooks = DefaultHooks) => (state, action) => {
  const pendingEditsMergeFunction = hooks[HookTypes.PendingEditsMerge];
  const pendingEditsValidationFunction = hooks[HookTypes.PendingEditsValidation];

  if (action.type === startItemEditType) {
    return Object.assign({}, state, {
      editing: true,
      pendingEdits: state.item,
      pendingEditsValid: pendingEditsValidationFunction(state.item),
    });
  }
  else if (action.type === itemEditType) {
    const transformedEdits = pendingEditsMergeFunction(state.pendingEdits, action.pendingEdits);
    return Object.assign({}, state, {
      pendingEditsValid: pendingEditsValidationFunction(transformedEdits),
      pendingEdits: transformedEdits,
    });
  }
  else if (action.type === cancelItemEditType) {
    return Object.assign({}, state, {
      editing: false,
      pendingEditsValid: false,
      pendingEdits: state.item,
    });
  }

  return state;
};

export const itemSaveReducer = (saveStartedType, saveSuccessType, saveErrorType, hooks = DefaultHooks) => (state, action) => {
  const postSaveMergeFunction = hooks[HookTypes.PostSaveMerge];

  if (action.type === saveStartedType) {
    return Object.assign({}, state, {
      saving: true,
      error: null,
    });
  }
  else if (action.type === saveSuccessType) {
    const transformedItem = postSaveMergeFunction(state.item, action.item);
    return Object.assign({}, state, {
      saving: false,
      item: transformedItem,
      editing: false,
      pendingEditsValid: false,
      pendingEdits: transformedItem,
      list: updateListAfterSave(state.list, transformedItem, hooks),
    });
  }
  else if (action.type === saveErrorType) {
    return Object.assign({}, state, {
      saving: false,
      error: action.error,
    });
  }
  return state;
};

function updateListAfterSave(list, updatedItem, hooks) {
  if (!list || !updatedItem) {
    return list;
  }

  const idFunction = hooks[HookTypes.Id];
  const listInsertFunction = hooks[HookTypes.PostSaveListInsert];

  const index = list.findIndex(current => idFunction(current) === idFunction(updatedItem));
  if (index !== -1) {
    return update(list, {
      $splice: [[index, 1, updatedItem]]
    });
  }

  return listInsertFunction(list, updatedItem);
}

export const itemDeleteReducer = (deleteStartedType, deleteSuccessType, deleteErrorType, hooks = DefaultHooks) => (state, action) => {
  const idFunction = hooks[HookTypes.Id];

  if (action.type === deleteStartedType) {
    return Object.assign({}, state, {
      deleting: true,
      error: null,
    });
  }
  else if (action.type === deleteSuccessType) {
    return Object.assign({}, state, {
      deleting: false,
      item: null,
      pendingEdits: null,
      list: updateListAfterDelete(state.list, action.deletedItem, idFunction)
    });
  }
  else if (action.type === deleteErrorType) {
    return Object.assign({}, state, {
      deleting: false,
      error: action.error,
    });
  }
  return state;
};

function updateListAfterDelete(list, deletedItem, idFunction) {
  if (!list || !list.length || !deletedItem) {
    return list;
  }

  const index = list.findIndex(current => idFunction(current) === idFunction(deletedItem));
  if (index === -1) {
    return list;
  }

  return update(list, {
    $splice: [[index, 1]]
  });
}

function clearErrorOnNavReducer(state, action) {
  if (action.type === LOCATION_CHANGE) {
    return Object.assign({}, state, { error: null });
  }
  return state;
}

export const buildReducer = (actionTypes, hookOverrides, initialState) => {
  let hooks = DefaultHooks;
  if (hookOverrides) {
    hooks = Object.assign({}, hooks, hookOverrides);
  }

  const chain = [clearErrorOnNavReducer];
  if (actionTypes.GET_LIST_STARTED && actionTypes.RECEIVE_LIST && actionTypes.GET_LIST_FAILED) {
    chain.push(listReducer(actionTypes.GET_LIST_STARTED, actionTypes.RECEIVE_LIST, actionTypes.GET_LIST_FAILED, hooks));

    if (actionTypes.REFRESH_LIST_STARTED && actionTypes.REFRESH_LIST_CANCELED && actionTypes.REFRESH_LIST_FAILED) {
      chain.push(listRefreshReducer(actionTypes.REFRESH_LIST_STARTED, actionTypes.RECEIVE_LIST, actionTypes.REFRESH_LIST_CANCELED, actionTypes.REFRESH_LIST_FAILED, actionTypes.GET_LIST_FAILED));
    }
  }

  if (actionTypes.GET_ITEM_STARTED && actionTypes.RECEIVE_ITEM && actionTypes.GET_ITEM_FAILED) {
    chain.push(itemReducer(actionTypes.GET_ITEM_STARTED, actionTypes.RECEIVE_ITEM, actionTypes.GET_ITEM_FAILED, hooks));
  }

  if (actionTypes.BEGIN_ITEM_EDIT && actionTypes.EDIT_ITEM && actionTypes.CANCEL_ITEM_EDIT) {
    chain.push(itemEditReducer(actionTypes.BEGIN_ITEM_EDIT, actionTypes.EDIT_ITEM, actionTypes.CANCEL_ITEM_EDIT, hooks));
  }

  if (actionTypes.SAVE_ITEM_STARTED && actionTypes.SAVE_ITEM_SUCCESS && actionTypes.SAVE_ITEM_FAILED) {
    chain.push(itemSaveReducer(actionTypes.SAVE_ITEM_STARTED, actionTypes.SAVE_ITEM_SUCCESS, actionTypes.SAVE_ITEM_FAILED, hooks));
  }

  if (actionTypes.DELETE_ITEM_STARTED && actionTypes.DELETE_ITEM_SUCCESS && actionTypes.DELETE_ITEM_FAILED) {
    chain.push(itemDeleteReducer(actionTypes.DELETE_ITEM_STARTED, actionTypes.DELETE_ITEM_SUCCESS, actionTypes.DELETE_ITEM_FAILED, hooks));
  }

  let actualInitialState = DefaultState;
  if (initialState) {
    actualInitialState = Object.assign({}, DefaultState, initialState);
  }
  return reduceReducers(actualInitialState, ...chain);
};

export const buildResetOnNavActionReducer = (defaultState = DefaultState) => (state, action) => {
  if (action.type === LOCATION_CHANGE) {
    return defaultState;
  }
  return state;
};
