import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import moment from 'moment';
import { Table, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import objectPath from 'object-path';
import immutable from 'object-path-immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import findIndex from 'lodash/findIndex';
import TextInputField from './TextInputField';
import ActionBar from './ActionBar';
import withEither from './withEither';
import Datepicker from './Datepicker';
import Numberpicker from './Numberpicker';
import Dropdown from './DropdownList';
import NullComponent from './NullComponent';
import SwitchInputField from './Switch';


const deleteClickHandler = function (index, fb, panel, updateFormBuilder, status) {
  // set panel property
  const panelIndex = findIndex(fb.panels, ['name', panel.name]);
  const sourcePath = `panels.${panelIndex}`;
  const newFB = immutable.set(fb, `${sourcePath}.deleteModalOpen`, status);
  const newFB1 = immutable.set(newFB, `${sourcePath}.index`, index);
  updateFormBuilder(newFB1);
};

const onDelete = function (target, panel, deleteItem) {
  deleteItem(target, panel);
};

const switchConditionFn = props => props.field.display && props.field.type === 'bool';
const datepickerConditionFn = props => props.field.display && props.field.type === 'date';
const numberpickerConditionFn = props => props.field.display && props.field.type === 'number';
const dropdownConditionFn = props => props.field.display && props.field.type === 'select';
const TextInputConditionFn = props => props.field.display && props.field.type === 'text';

const withConditionalRenderings = compose(
  withEither(TextInputConditionFn, TextInputField),
  withEither(switchConditionFn, SwitchInputField),
  withEither(datepickerConditionFn, Datepicker),
  withEither(numberpickerConditionFn, Numberpicker),
  withEither(dropdownConditionFn, Dropdown),
);


const PanelContentsWithConditionalRendering = withConditionalRenderings(NullComponent);


const TablePanel = props =>
  <div>
    { props.prepareTableData(props.panel, props.target) ?
      <Table className="table-panel" striped>
        <tbody>
          <tr>
            {props.panel.fields.map((field, index) => {
              if (index < 6) { // if there are more than 6 fields, only show the first 6 as table columns, currently no panel that uses a table has more than six fields
                return (
                  <th key={field.id}>{field.label}</th>
                );
              }

              return null;
            },
            )}

            <th>&nbsp;</th>
          </tr>
          {props.prepareTableData(props.panel, props.target).map((item, index) =>
            <tr key={`${props.panel.id}${index}`}>
              {props.panel.fields.map((field, idx) => {
                let displayValue;
                if (idx < 6) {
                  if (field.type === 'bool') {
                    if (objectPath.get(props.target, field.path.replace(/(\d+)/, index)) === true) {
                      displayValue = <FontAwesomeIcon icon={faCheck} />;

                    }
                    else {
                      displayValue = '';
                    }
                  }
                  else {
                    displayValue = objectPath.get(props.target, field.path.replace(/(\d+)/, index)) || '\u2014';
                  }
                  return (
                    <td key={`${field.name}${field.id}`}> {field.type === 'date' && moment.isDate(new Date(objectPath.get(props.target, field.path.replace(/(\d+)/, index)))) && moment(objectPath.get(props.target, field.path.replace(/(\d+)/, index))).isValid() ? moment(objectPath.get(props.target, field.path.replace(/(\d+)/, index))).format('L') : displayValue }</td>
                  );
                }

                return null;
              },
            )}
              <td className="rightAlign">
                {!props.panel.disableEditButton ?
                  <a title="Delete item" id={index} onClick={() => deleteClickHandler(index, props.formBuilder, props.panel, props.updateFormBuilder, true)} className="pr-2"><FontAwesomeIcon title="Delete item" icon={faTimes} /></a>
                :
                null
              }
              </td>
            </tr>,
        )}
        </tbody>
      </Table>
    :
    null
    }
    <div>
      <Modal size="lg" isOpen={props.panel.modalOpen}>
        <ModalHeader toggle={() => props.onCancelPanel({ panel: props.panel })}>{props.panel.label}</ModalHeader>
        <ModalBody>
          <div className="row mb-2">
            <div className="col-sm-10">
              <h1 className="d-flex justify-content-start canary">{props.panel.label}</h1>
            </div>
            {!props.panel.edit ?
              <div className="col-sm-2">
                <div className="d-flex justify-content-end">
                  <Button disabled={props.panel.disableEditButton || false} className="mt-2" outline color="primary" onClick={() => props.toggleEditFormBuilderPanel(props.panel, !props.panel.disableEditButton)}>EDIT</Button>
                </div>
              </div>
          : null }
          </div>
          {props.panel.errorMessages && props.panel.errorMessages.length ?

            <div className="row">
              <div className="col-sm-12">
                <div className="alert alert-danger mb-3" role="alert">
                  {props.panel.errorMessages.map((msg, ix) =>
                    <div key={`${msg.field}${ix}`}>{msg.message}</div>,
                  )}
                </div>
              </div>
            </div>

            : null
          }

          <div className="row">
            {props.panel.fields.map((field) => {
              const panelField = field;
              panelField.path = panelField.path.replace(/(\d+)/, props.panel.index);
              if (panelField.display) {
                return (<div key={panelField.id} className={`col-sm-${panelField.cols}`}>
                  <PanelContentsWithConditionalRendering key={field.id} field={field} time={false} {...props} />
                </div>
                );
              }
            },
               )}
          </div>
          {props.panel.edit ?
            <div className="row">
              <ActionBar {...props} />
            </div>
        : null}
        </ModalBody>
      </Modal>

      <Modal isOpen={props.panel.deleteModalOpen}>
        <ModalHeader toggle={() => deleteClickHandler(0, props.formBuilder, props.panel, props.updateFormBuilder, false)}>Delete Item</ModalHeader>
        <ModalBody><p>Deleting this will remove all record of it from your tank history.</p>
          <p><strong>Are you sure you want to delete this item?</strong></p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={() => deleteClickHandler(0, props.formBuilder, props.panel, props.updateFormBuilder, false)}>Cancel</Button>
          {' '}
          <Button color="primary" size="sm" onClick={() => onDelete(props.target, props.panel, props.deleteItem)}>Delete Item</Button>
        </ModalFooter>
      </Modal>

    </div>
  </div>;


TablePanel.propTypes = {
  panel: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  prepareTableData: PropTypes.func.isRequired,
  formBuilder: PropTypes.object.isRequired,
  updateFormBuilder: PropTypes.func.isRequired,
  toggleEditFormBuilderPanel: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  onCancelPanel: PropTypes.func.isRequired,
};

export default TablePanel;

