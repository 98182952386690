import update from 'immutability-helper';
import { IssueClient } from '../../../../client';
import ActionTypes from './NewIssueActionTypes';
import * as selectors from './NewIssueSelectors';

export const openModal = (site, issueParams) => ({ type: ActionTypes.OPEN_MODAL, site, issueParams });

export function cancel() {
  return {
    type: ActionTypes.CANCEL_MODAL
  };
}

export function editIssue(issue) {
  return { type: ActionTypes.EDIT_ISSUE, issue };
}

export function createIssue() {
  return (dispatch, getState) => {
    const currentState = getState();
    const issue = selectors.issue(currentState);
    if (!issue || selectors.isSaving(currentState)) {
      return Promise.resolve();
    }

    const payload = update(issue, {
      $unset: ['site'],
      siteId: {
        $set: issue.site.id,
      }
    });

    dispatch({ type: ActionTypes.CREATE_ISSUE_STARTED });
    return IssueClient.getOpenSiteIssues(issue.site.id)
      .then(openIssues => {
        const description = openIssues.map(current => current.description);
        if (description.indexOf(issue.description) !== -1) {
          const error = new Error('An issue with that name already exists at this site');
          dispatch({ type: ActionTypes.CREATE_ISSUE_FAILED, error });
          return Promise.reject(error);
        }
        return IssueClient.createIssue(payload)
          .then(persistentIssue => {
            dispatch({ type: ActionTypes.CREATE_ISSUE_SUCCESS, issue: persistentIssue });
            return persistentIssue;
          })
          .catch(error => {
            dispatch({ type: ActionTypes.CREATE_ISSUE_FAILED, error });
            return Promise.reject(error);
          });
      });
  };
}
