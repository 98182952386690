import React from 'react';
import PropTypes from 'prop-types';

const WaterLevel = ({ tankInventory }) => {
  let waterImg = null;
  const waterHeight = (tankInventory.waterHeightInches > 0) ? tankInventory.waterHeightInches.toFixed(2) : null;
  if (waterHeight) {
    // noinspection HtmlUnknownTarget (<img src".." />)
    waterImg = (
      <span className="pl-1">
        <img alt="Water in tank" title="Water in tank" src="/images/inventory/drop.svg" height="15" width="15" />
        {' '}
        <span style={{ position: 'relative', top: '0.175rem' }} className="pt-1">
          {' '}
          {`${waterHeight}\u2033`}
        </span>
      </span>
    );
  }

  return waterImg;
};

WaterLevel.propTypes = {
  tankInventory: PropTypes.object.isRequired,
};

export default WaterLevel;
