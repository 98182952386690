import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import * as ChartUtils from '../../../../../../../../components/ChartUtils';

const LineChartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      ticks: {
        min: 0,
        suggestedMax: 110,
      },
    }],
  },
};

class ConnectivityGraph extends Component {
  buildChartParams(type) {
    const data = this.props.selectedSite.healthHistory;
    const xAxisLabels = data.map(current => ChartUtils.formatDay(current.created));
    let yAxisData;
    if (type === 'device') {
      yAxisData = data.map(current => current.deviceCommSuccessPercentage * 100);
    }
    else {
      yAxisData = data.map(current => current.atgCommSuccessPercentage * 100);
    }

    return {
      labels: xAxisLabels,
      datasets: [
        ChartUtils.buildLineChartDataset('', ChartUtils.RgbColors.Blue, yAxisData),
      ],
    };
  }

  render() {
    let header = ' Responsiveness History';
    if (this.props.type === 'device') {
      header = `Device ${header}`;
    }
    else {
      header = `ATG ${header}`;
    }

    return (
      <div>
        <div className="text-md-center">
          <h5 style={{ display: 'inline' }}>{header}</h5>
        </div>
        <div className="mt-1">
          <Line
            data={this.buildChartParams(this.props.type)}
            width={100}
            height={200}
            options={LineChartOptions}
            legend={{ display: false }}
          />
        </div>
      </div>
    );
  }
}

ConnectivityGraph.propTypes = {
  selectedSite: PropTypes.object.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};

ConnectivityGraph.defaultProps = {
  className: null,
  type: 'device',
};

export default ConnectivityGraph;
