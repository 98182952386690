/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { TextAlign } from './TableConstants';

const Column = ({ title, render, accessor, rowData }) => (<td data-label={title}>{render(accessor(rowData), rowData)}</td>);

Column.propTypes = {
  columnId: PropTypes.string,
  sortable: PropTypes.bool,
  title: PropTypes.string,
  renderTitle: PropTypes.func,
  width: PropTypes.string,
  textAlign: PropTypes.oneOf([TextAlign.Left, TextAlign.Right, TextAlign.Center]),
  accessor: PropTypes.func,
  render: PropTypes.func.isRequired,
  rowData: PropTypes.any,
  initialSortDirection: PropTypes.string,
};

Column.defaultProps = {
  columnId: null,
  sortable: false,
  title: null,
  renderTitle: null,
  width: null,
  textAlign: TextAlign.Left,
  accessor: rowData => rowData,
  rowData: null,
  initialSortDirection: null,
};

export default Column;
