import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import * as selectors from '../ComplianceReportSelectors';
import * as SiteSelectors from '../../../../../SiteSelectors';
import * as actions from '../ComplianceReportActions';
import {track} from '../../../../../../../shared/analytics';

class ComplianceActionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.addComment = this.addComment.bind(this);
    this.downloadWithATGReports = this.downloadWithATGReports.bind(this);
    this.downloadWithoutATGReports = this.downloadWithoutATGReports.bind(this);
  }

  toggle() {
    this.setState(currentState => ({
      dropdownOpen: !currentState.dropdownOpen,
    }));
  }

  addComment() {
    track('Compliance', {
      Component: 'Compliance action button',
      'Compliance action': 'Edit comment',
    });
    this.props.openAddComment();
    this.toggle();
  }

  downloadWithATGReports() {
    track('Compliance', {
      Component: 'Compliance action button',
      'Compliance action': 'Export',
      'Include ATG Reports': true,
    });
    this.props.downloadReport(true);
  }

  downloadWithoutATGReports() {
    track('Compliance', {
      Component: 'Compliance action button',
      'Compliance action': 'Export',
      'Include ATG Reports': false,
    });
    this.props.downloadReport(false);
  }

  render() {
    return (
      <ButtonDropdown size="sm" isOpen={this.state.dropdownOpen} toggle={this.toggle} className={this.props.className}>
        <DropdownToggle color="primary" caret disabled={this.props.isEditingComment}>Take Action</DropdownToggle>
        <DropdownMenu right={this.props.right} style={{ zIndex: 9999 }}>
          {this.props.canEditCompliance ? <DropdownItem onClick={this.props.openAddComment}>Add comment</DropdownItem> : null}
          <DropdownItem onClick={this.downloadWithATGReports}>Export with ATG Slips</DropdownItem>
          <DropdownItem onClick={this.downloadWithoutATGReports}>Export without ATG Slips</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

ComplianceActionButton.propTypes = {
  canEditCompliance: PropTypes.bool.isRequired,
  isEditingComment: PropTypes.bool.isRequired,
  openAddComment: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  className: PropTypes.string,
  right: PropTypes.bool,
};

ComplianceActionButton.defaultProps = {
  className: null,
  right: false,
};

function mapStateToProps(state) {
  return {
    canEditCompliance: SiteSelectors.canEditCompliance(state),
    isEditingComment: selectors.isEditingComment(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openAddComment: () => dispatch(actions.openAddComment()),
    downloadReport: includeAtgReports => dispatch(actions.downloadReport(includeAtgReports)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComplianceActionButton));
