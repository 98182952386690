import moment from 'moment';
import ActionTypes from './ComplianceListActionTypes';

const reformatStartOfMonth = reports => {
  // converted "monthStartDate" to a string in reg-report-view.json, but loopback still returns
  // a formatted date. workaround until canary-api replaces the compliance api
  reports.forEach(r => {
    r.monthStartDate = moment(r.monthStartDate)
      .utc(false)
      .format('YYYY-MM-DDTHH:mm:ss');
  });
  return reports;
};

export default function ComplianceListReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_COMPLIANCE_HISTORY_STARTED:
      return Object.assign({}, state, {
        complianceHistory: null,
        complianceHistoryLoading: true,
        complianceHistoryError: null,
      });
    case ActionTypes.RECEIVE_COMPLIANCE_HISTORY:
      return Object.assign({}, state, {
        complianceHistory: reformatStartOfMonth(action.data),
        complianceHistoryLoading: false,
      });
    case ActionTypes.GET_COMPLIANCE_HISTORY_FAILED:
      return Object.assign({}, state, {
        complianceHistoryLoading: false,
        complianceHistoryError: action.error,
      });

    default:
      return state;
  }
}
