import { ExportClient, SiteFields, SiteClient, SiteParams } from '../../../client';
import { AuthenticationSelectors } from '../../../authentication';
import { PresentAlert, PresentError } from '../../../shared/ToastUtils';
import { ExportActionTypes, ExportSiteListActionTypes } from '../ExportActionTypes';
import { getListWithEtagAction } from '../../../shared/redux-helpers/ActionCreatorFactory';
import selectors, { selectedCustomerId } from '../ExportSelectors';

function recentExports(exports) {
  return {
    type: ExportActionTypes.RECEIVE_EXPORTS,
    recentExports: exports,
  };
}

const siteFields = () => [SiteFields.Id, SiteFields.Nickname, SiteFields.SiteName];

const buildParams = (currentState) => ({ [SiteParams.CustomerIds.name]: [selectedCustomerId(currentState)] });

export const getSites = getListWithEtagAction(selectors, ExportSiteListActionTypes, buildParams, siteFields, SiteClient.find);

export const updateCustomerId = (customerId) => (dispatch) => {
  dispatch({ type: ExportActionTypes.SET_CUSTOMER_ID, customerId });
  return dispatch(getSites());
};

export function getRecentExports() {
  return (dispatch, getState) => {
    const currentState = getState();
    const userId = AuthenticationSelectors.currentUserId(currentState);
    return ExportClient.getRecentExports(userId).then(exports => dispatch(recentExports(exports)));
  };
}

export function updateExportStatus(exportInstance) {
  if (exportInstance.status === 'Pending') {
    PresentAlert('Export started');
  }
  else if (exportInstance.status === 'Complete') {
    PresentAlert('Export complete');
  }
  else if (exportInstance.status === 'Failed') {
    PresentError('Export failed');
  }

  return (dispatch) => {
    dispatch(getRecentExports());
  };
}
