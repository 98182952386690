import ActionTypes from './TankRegistrationActionTypes';

export default function TankRegistrationReducer(state = {}, action) {
  switch (action.type) {

    // form builder
    case ActionTypes.RECEIVE_FORM_BUILDER:
      return Object.assign({}, state, {
        formBuilder: action.formBuilder,
      });

    // tank registration (load)
    case ActionTypes.REQUEST_TANK_REGISTRATION:
      return Object.assign({}, state, {
        registrationLoading: true,
        isError: false,
        error: null,
      });
    case ActionTypes.RECEIVE_TANK_REGISTRATION:
      return Object.assign({}, state, {
        originalTankRegistration: action.tankRegistration,
        tankRegistration: action.tankRegistration,
        registrationLoading: false,
        registrationSaving: false,
        isError: false,
      });
    case ActionTypes.RECEIVE_TANK_REGISTRATION_FAILURE:
      return Object.assign({}, state, {
        registrationLoading: false,
        isError: true,
        error: action.error,
      });

      // tanks
    case ActionTypes.REQUEST_TANKS:
      return Object.assign({}, state, {
        isError: false,
        error: null,
        tanksLoading: action.tanksLoading,
      });
    case ActionTypes.RECEIVE_TANK:
      return Object.assign({}, state, {
        tank: action.tank,
        tanksLoading: action.tanksLoading,
      });

    case ActionTypes.RECEIVE_TANK_FAILURE:
      return Object.assign({}, state, {
        isError: true,
        error: action.error,
        tanksLoading: action.tanksLoading,
      });

    // registration (edit/rollback)
    case ActionTypes.EDIT_TANK_REGISTRATION:
      return Object.assign({}, state, {
        tankRegistration: action.tankRegistration,
      });
    case ActionTypes.ROLLBACK_TANK_REGISTRATION:
      return Object.assign({}, state, {
        tankRegistration: state.originalTankRegistration,
      });

    // registration (save)
    case ActionTypes.SAVE_TANK_REGISTRATION_REQUEST:
      return Object.assign({}, state, {
        registrationSaving: true,
        isError: false,
        error: null,
      });
    case ActionTypes.SAVE_TANK_REGISTRATION_FAILURE:
      return Object.assign({}, state, {
        registrationSaving: false,
        isError: true,
        error: action.error,
      });

    default:
      return state;
  }
}
