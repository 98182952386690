import moment from 'moment-timezone';

export const BrowserTimezone = moment.tz.guess();

export function dateAsText(date) {
  if (!date) {
    return null;
  }
  return moment(date).format('M/D/YY');
}

export function dateOrTimeAsText(time) {
  if (!time) {
    return null;
  }

  const timestamp = moment(time).tz(BrowserTimezone);
  const today = moment().tz(BrowserTimezone).startOf('day');

  if (timestamp.isAfter(today)) {
    return timestamp.format('h:mm A');
  }
  return timestamp.format('M/D/YY');
}

export function timestampAsText(time, zone, dateOnly) {
  let formatString = 'M/D/YY [at] hh:mm A z';

  if (!time) return null;

  if (dateOnly) {
    formatString = 'M/D/YY';
  }

  if (!zone) {
    return moment(time).tz(BrowserTimezone).format(formatString);
  }
  return moment(time).tz(zone).format(formatString);
}

const monthYearFormat = 'MMM YYYY';

export function timestampAsMonthYear(time, zone) {
  if (!time) {
    return null;
  }
  const timestamp = zone ? moment(time).tz(zone) : moment(time).tz(BrowserTimezone);
  return timestamp.format(monthYearFormat);
}

const monthDayYearTextFormat = 'MMMM D, YYYY';

export function timestampAsMonthDayYearText(time, zone) {
  if (!time) {
    return null;
  }
  const timestamp = zone ? moment(time).tz(zone) : moment(time).tz(BrowserTimezone);
  return timestamp.format(monthDayYearTextFormat);
}

export function relativeTimestampAsText(time, longform = true) {
  if (!time) {
    return null;
  }

  const timestamp = moment(time).tz(BrowserTimezone);

  const now = moment().tz(BrowserTimezone);
  const oneHourAgo = moment().tz(BrowserTimezone).subtract(1, 'hour');
  const today = moment().tz(BrowserTimezone).startOf('day');
  const yesterday = moment().tz(BrowserTimezone).subtract(1, 'day').startOf('day');

  let formattedDate;
  if (timestamp.isAfter(oneHourAgo)) {
    if (longform) {
      formattedDate = `${moment.duration(now.valueOf() - timestamp.valueOf()).humanize()} ago`;
    }
    else {
      formattedDate = `${parseInt(moment.duration(now.valueOf() - timestamp.valueOf()).asMinutes(), 10)}m`;
    }
  }
  else if (timestamp.isAfter(today)) {
    if (longform) {
      formattedDate = `today at ${timestamp.tz(BrowserTimezone).format('h:mm A z')}`;
    }
    else {
      formattedDate = `${parseInt(moment.duration(now.valueOf() - timestamp.valueOf()).asHours(), 10)}h`;
    }
  }
  else if (timestamp.isAfter(yesterday)) {
    if (longform) {
      formattedDate = `yesterday at ${timestamp.tz(BrowserTimezone).format('h:mm A z')}`;
    }
    else {
      formattedDate = `${parseInt(moment.duration(now.valueOf() - timestamp.valueOf()).asDays(), 10)}d`;
    }
  }
  else if (longform) {
    formattedDate = timestamp.tz(BrowserTimezone).format('M/D/YY h:mm A z');
  }
  else {
    formattedDate = `${parseInt(moment.duration(now.valueOf() - timestamp.valueOf()).asDays(), 10)}d`;
  }

  return formattedDate;
}
