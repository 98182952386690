import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ATGReport from '../../../../../shared/components/atg-reports/ATGReport';
import { CommandRequestStatus } from '../../../../../AppConstants';
import GenerateReportAnchor from './GenerateReportAnchor';


const browserTimezone = moment.tz.guess();

const isOlderThanOneHour = (commandRequest) => {
  const displayDate = commandRequest.event && commandRequest.event.receivedDate ? commandRequest.event.receivedDate : commandRequest.created;
  return moment(displayDate).tz(browserTimezone).isBefore(moment().subtract(1, 'hours'));
};


class RecentReport extends Component {
  render() {
    if (this.props.commandRequest.status === CommandRequestStatus.Resolved) {
      return (
        <div id={GenerateReportAnchor(this.props.commandRequest.id)} className="mb-3" style={{ backgroundColor: '#fff' }}>
          <div className="mb-2">
            {this.props.commandRequest.event && this.props.commandRequest.event.receivedDate ?
              <small><strong>{this.props.commandRequest.functionName}</strong> - {moment(this.props.commandRequest.event.receivedDate).tz(browserTimezone).format('ddd').toUpperCase()} {`${moment(this.props.commandRequest.event.receivedDate).tz(browserTimezone).format('l')}  at ${moment(this.props.commandRequest.event.receivedDate).tz(browserTimezone).format('LT')}`}</small>
              :
              <small><strong>{this.props.commandRequest.functionName}</strong> - {moment(this.props.commandRequest.created).tz(browserTimezone).format('ddd').toUpperCase()} {`${moment(this.props.commandRequest.created).tz(browserTimezone).format('l')}  at ${moment(this.props.commandRequest.created).tz(browserTimezone).format('LT')}`}</small>
            }
          </div>
          <ATGReport report={this.props.commandRequest.event} />
        </div>
      );
    }

    if (this.props.commandRequest.status === CommandRequestStatus.Failed && !isOlderThanOneHour(this.props.commandRequest)) {
      return (
        <div className="mb-3" style={{ backgroundColor: '#fff' }}>
          <div id={GenerateReportAnchor(this.props.commandRequest.id)}>
            <div className="mb-2">
              <FontAwesomeIcon size="sm" color="#FF0000" icon={faExclamationTriangle} />
              <small><strong>
                {this.props.commandRequest.functionName ?
                  <span>{this.props.commandRequest.functionName}</span>
                  :
                  <span>Unknown Command</span>
                }
              </strong></small>
              {this.props.commandRequest.event && this.props.commandRequest.event.receivedDate ?
                <small> - {`${moment(this.props.commandRequest.event.receivedDate).tz(browserTimezone).format('ddd').toUpperCase()}  ${moment(this.props.commandRequest.event.receivedDate).tz(browserTimezone).format('l')} at ${moment(this.props.commandRequest.event.receivedDate).tz(browserTimezone).format('LT')}`}</small>
                :
                <small> - {`${moment(this.props.commandRequest.created).tz(browserTimezone).format('ddd').toUpperCase()}  ${moment(this.props.commandRequest.created).tz(browserTimezone).format('l')} at ${moment(this.props.commandRequest.created).tz(browserTimezone).format('LT')}`}</small>
              }
            </div>
            {this.props.commandRequest.functionName ?
              <div className="card card-warning p-1 atgError">
                <div className="col-sm-12">
                  <h5><strong>Canary was unable to complete your request.</strong></h5>
                  <p>Possible causes:</p>
                  <ul>
                    <li>The command you entered may not be supported by the ATG.</li>
                    <li>Sometimes cellular signals can go in and out. Please try again in a few minutes.</li>
                  </ul>
                  <p>If the problem persists:</p>
                  <ul>
                    <li>Make sure there are no open communication issues (check the <a style={{ color: '#4A90E2' }} onClick={this.props.navToSiteIssues}>issue list)</a></li>
                    <li>Make sure the Canary is plugged in (call the station)</li>
                  </ul>
                  <p>If the connection still isn&apos;t working, report the problem by clicking the help button at the
                    bottom of the screen or calling customer support at (267) 354-0823.</p>
                </div>
              </div>
              :
              <div className="card card-warning p-1">
                <small>
                  <strong>We&apos;re sorry. We don&apos;t recognize the command &quot;{this.props.commandRequest.commandText}&quot;.</strong><br />
                  Please check your ATG manual and make sure you typed it correctly.
            </small>
              </div>
            }
          </div>
        </div>
      );
    }

    return null;
  }
}

RecentReport.propTypes = {
  commandRequest: PropTypes.object.isRequired,
  navToSiteIssues: PropTypes.func.isRequired,
};

export default RecentReport;
