import * as AuthSelectors from '../../../../../../../../authentication/AuthenticationSelectors';
import { IssueClient } from '../../../../../../../../client';
import * as IssueSelectors from '../../IssueEditSelectors';
import ActionTypes from './IssueDispatchActionTypes';
import * as selectors from './IssueDispatchSelectors';

function receiveDispatchActivity(dispatchActivity) {
  return {
    type: ActionTypes.RECEIVE_DISPATCH_ACTIVITY,
    dispatchActivity,
  };
}

export function getDispatchActivity() {
  return (dispatch, getState) => {
    const currentState = getState();
    const issue = IssueSelectors.currentIssue(currentState);
    if (!issue) {
      return Promise.resolve();
    }

    const hasDispatchConfig = AuthSelectors.customerHasDispatchSystem(currentState, issue.customerId);
    const isLoading = selectors.isLoading(currentState);
    if (!hasDispatchConfig || isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_DISPATCH_ACTIVITY_STARTED });
    return IssueClient.getDispatchActivity(issue.id)
      .then(dispatchActivity => {
        dispatch(receiveDispatchActivity(dispatchActivity));
        return dispatchActivity;
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.GET_DISPATCH_ACTIVITY_FAILED, error });
        return Promise.reject(error);
      });
  };
}
