import { push } from 'react-router-redux';
import update from 'immutability-helper/index';

import { InventoryFormatClient } from '../../../../../../client';
import FileFormatActionTypes from '../FileFormatActionTypes';
import * as selectors from '../FileFormatSelectors';

const NEW = 'new';

const DEFAULT_INV_FORMAT = {
  id: NEW,
  name: '',
  delimiterCharacter: ',',
  includeHeader: true,
  quote: true,
  columns: [],
};


function getInventoryFormatListStarted() {
  return {
    type: FileFormatActionTypes.GET_INVENTORY_FORMAT_LIST_STARTED,
  };
}

function getInventoryFormatListFailed() {
  return {
    type: FileFormatActionTypes.GET_INVENTORY_FORMAT_LIST_FAILED,
  };
}

function receiveInventoryFormatList(inventoryFormatList) {
  return {
    type: FileFormatActionTypes.RECEIVE_INVENTORY_FORMAT_LIST,
    inventoryFormatList,
  };
}

export function getInventoryFormatList() {
  return (dispatch, getState) => {
    const isLoading = selectors.isInventoryFormatListLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch(getInventoryFormatListStarted());
    return InventoryFormatClient.getList()
      .then(inventoryFormatList => dispatch(receiveInventoryFormatList(inventoryFormatList)))
      .catch((err) => {
        dispatch(getInventoryFormatListFailed());
        return Promise.reject(err);
      });
  };
}


function getInventoryColumnCategoriesStarted() {
  return {
    type: FileFormatActionTypes.GET_INVENTORY_COLUMN_CATEGORIES_STARTED,
  };
}

function getInventoryColumnCategoriesFailed() {
  return {
    type: FileFormatActionTypes.GET_INVENTORY_COLUMN_CATEGORIES_FAILED,
  };
}

function receiveInventoryColumns(inventoryColumnCategories) {
  return {
    type: FileFormatActionTypes.RECEIVE_INVENTORY_COLUMN_CATEGORIES,
    inventoryColumnCategories,
  };
}

export function getInventoryColumnCategories() {
  return (dispatch, getState) => {
    const isLoading = selectors.isInventoryColumnCategoriesLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch(getInventoryColumnCategoriesStarted());
    return InventoryFormatClient.getInventoryColumnCategories()
      .then(inventoryColumnCategories => dispatch(receiveInventoryColumns(inventoryColumnCategories)))
      .catch((err) => {
        dispatch(getInventoryColumnCategoriesFailed());
        return Promise.reject(err);
      });
  };
}


function getInventoryFormatStarted() {
  return {
    type: FileFormatActionTypes.GET_INVENTORY_FORMAT_STARTED,
  };
}

function getInventoryFormatFailed() {
  return {
    type: FileFormatActionTypes.GET_INVENTORY_FORMAT_FAILED,
  };
}

function receiveInventoryFormat(selectedInventoryFormat) {
  return {
    type: FileFormatActionTypes.RECEIVE_INVENTORY_FORMAT,
    selectedInventoryFormat,
  };
}

export function selectInventoryFormat(inventoryFormat) {
  return (dispatch, getState) => {
    const isLoading = selectors.isSelectedInventoryFormatLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    const id = inventoryFormat.id || inventoryFormat;
    if (id === NEW) {
      dispatch(receiveInventoryFormat(DEFAULT_INV_FORMAT));
      return Promise.resolve();
    }

    dispatch(getInventoryFormatStarted());
    return InventoryFormatClient.getById(id)
      .then(restInventoryFormat => dispatch(receiveInventoryFormat(restInventoryFormat)))
      .catch((err) => {
        dispatch(getInventoryFormatFailed());
        return Promise.reject(err);
      });
  };
}

export function navToInventoryFormat(inventoryFormat) {
  return (dispatch, getState) => {
    const state = getState();

    const id = inventoryFormat.id || inventoryFormat;
    const currentPath = state.router.location.pathname;
    const newPath = `/admin/inventory/file-formats/${id}`;

    if (currentPath !== newPath) {
      dispatch(push(newPath));
    }
  };
}

export function navToNewInventoryFormat() {
  return navToInventoryFormat(NEW);
}

export function updateSelectedFormat(updatedFormat) {
  return receiveInventoryFormat(updatedFormat);
}

export function cancelSelectedFormatEdit() {
  return (dispatch) => {
    dispatch(push('/admin/inventory/file-formats'));
    dispatch(receiveInventoryFormat(null));
  };
}

function saveInventoryFormatStarted() {
  return {
    type: FileFormatActionTypes.SAVE_INVENTORY_FORMAT_STARTED,
  };
}

function saveInventoryFormatSuccess() {
  return {
    type: FileFormatActionTypes.SAVE_INVENTORY_FORMAT_SUCCESS,
  };
}

function saveInventoryFormatFailed() {
  return {
    type: FileFormatActionTypes.SAVE_INVENTORY_FORMAT_FAILED,
  };
}

export function saveSelectedFormat() {
  return (dispatch, getState) => {
    const isSaving = selectors.isSaving(getState());
    if (isSaving) {
      return Promise.resolve();
    }

    const selectedFormat = selectors.selectedInventoryFormat(getState());
    if (!selectedFormat) {
      return Promise.resolve();
    }

    let withoutId = selectedFormat;
    if (selectedFormat.id === NEW) {
      withoutId = update(selectedFormat, {
        id: {
          $set: null,
        },
      });
    }

    dispatch(saveInventoryFormatStarted());
    return InventoryFormatClient.save(withoutId)
      .then(() => {
        dispatch(saveInventoryFormatSuccess());
        dispatch(cancelSelectedFormatEdit());
      })
      .catch((err) => {
        dispatch(saveInventoryFormatFailed());
        return Promise.reject(err);
      });
  };
}

export function deleteSelectedInventoryFormat() {
  return (dispatch, getState) => {
    const isDeleting = selectors.isDeleting(getState());
    if (isDeleting) {
      return Promise.resolve();
    }

    const selectedFormat = selectors.selectedInventoryFormat(getState());
    if (!selectedFormat) {
      return Promise.resolve();
    }

    dispatch({ type: FileFormatActionTypes.DELETE_INVENTORY_FORMAT_STARTED });
    return InventoryFormatClient.deleteById(selectedFormat.id)
      .then(() => {
        const updatedInventoryFormatList = selectors.inventoryFormatList(getState()).filter(current => current.id !== selectedFormat.id);
        dispatch(receiveInventoryFormatList(updatedInventoryFormatList));
        dispatch({ type: FileFormatActionTypes.DELETE_INVENTORY_FORMAT_COMPLETE });
      });
  };
}
