import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import { LLDTypes } from '../../../../SiteConstants';

const NoSelectionText = {
  SelectLine: 'Select a line...',
  AllLines: 'All lines',
};

const LineSelect = ({ id, name, lines, lldType, selectedLine, allowAllLines, onSelect, disabled }) => {
  const filteredLines = lines
    .filter(current => current.lldType === lldType)
    .sort((a, b) => a.elldSensorNumber - b.elldSensorNumber);

  if (!filteredLines.length) {
    const message = `No ${lldType} lines available`;
    return (
      <Input
        type="select"
        id={id}
        name={name}
        disabled
      >
        <option value={null}>{message}</option>
      </Input>
    );
  }

  const linesById = {};
  filteredLines.forEach(line => {
    linesById[line.id] = line;
  });

  const handleSelect = event => {
    let line = null;
    if (event.target.value) {
      line = linesById[event.target.value];
    }
    onSelect(line);
  };

  const buildLabel = line => {
    if (line.lldType === LLDTypes.PressurizedLLD) {
      return `Q${line.elldSensorNumber} ${line.locationLabel}`;
    }
    return `W${line.elldSensorNumber} ${line.locationLabel}`;
  };

  const selectedLineId = (selectedLine && selectedLine.id) || '';
  const noSelectionText = allowAllLines ? NoSelectionText.AllLines : NoSelectionText.SelectLine;
  return (
    <Input
      type="select"
      id={id}
      name={name}
      value={selectedLineId}
      disabled={disabled}
      onChange={handleSelect}
    >
      <option value={null}>{noSelectionText}</option>
      {filteredLines.map(line => (
        <option key={line.id} value={line.id}>{buildLabel(line)}</option>
      ))}
    </Input>
  );
};

LineSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  lines: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    elldSensorNumber: PropTypes.number,
    lldType: PropTypes.oneOf([LLDTypes.PressurizedLLD, LLDTypes.WirelessLLD]),
    locationLabel: PropTypes.string,
  })),
  lldType: PropTypes.oneOf([LLDTypes.PressurizedLLD, LLDTypes.WirelessLLD]).isRequired,
  selectedLine: PropTypes.shape({
    id: PropTypes.number,
    elldSensorNumber: PropTypes.number,
    lldType: PropTypes.oneOf([LLDTypes.PressurizedLLD, LLDTypes.WirelessLLD]),
    locationLabel: PropTypes.string,
  }),
  allowAllLines: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

LineSelect.defaultProps = {
  id: null,
  name: null,
  lines: [],
  selectedLine: null,
  allowAllLines: false,
  disabled: false,
};

export default LineSelect;
