/* eslint-disable import/prefer-default-export */

export const IntentRequestStatus = {
  Pending: 'Pending',
  Resolved: 'Resolved',
  Failed: 'Failed',
};

export const IntentNames = {
  GetInventory: 'GET_INVENTORY',
  GetSystemStatus: 'GET_SYSTEM_STATUS',
  GetPriorityAlarmHistory: 'GET_PRIORITY_ALARM_HISTORY',
  GetLiquidSensorStatus: 'GET_LIQUID_SENSOR_STATUS',
  GetInTankDelivery: 'GET_IN_TANK_DELIVERY',
  RawCommand: 'RAW_COMMAND',
  StartPlldTest: 'START_PLLD_TEST',
  StartWplldTest: 'START_WPLLD_TEST',
  StopPlldTest: 'STOP_PLLD_TEST',
  StopWplldTest: 'STOP_WPLLD_TEST',
  GetPlldResults: 'GET_PLLD_RESULTS',
  GetWplldResults: 'GET_WPLLD_RESULTS',
  GetLeakTestHistory: 'GET_LEAK_TEST_HISTORY',
  GetLeakTestResults: 'GET_LEAK_TEST_RESULTS',
  GetPressureLineLeakResults: 'GET_PRESSURE_LINE_LEAK_RESULTS',
  GetPressureLineLeakHistory: 'GET_PRESSURE_LINE_LEAK_HISTORY',
  GetCsldResults: 'GET_CSLD_RESULTS',
  SetMaxProductLimit: 'SET_MAX_PRODUCT_LIMIT',
  SetHighProductLimit: 'SET_HIGH_PRODUCT_LIMIT',
  SetOverfillLimit: 'SET_OVERFILL_LIMIT',
  SetDeliveryNeededLimit: 'SET_DELIVERY_NEEDED_LIMIT',
  SetLowProductLimit: 'SET_LOW_PRODUCT_LIMIT',
  SetHighWaterAlarmLimit: 'SET_HIGH_WATER_ALARM_LIMIT',
  SetHighWaterWarningLimit: 'SET_HIGH_WATER_WARNING_LIMIT',
  SetReceiverAlarmStatusClear: 'SET_RECEIVER_ALARM_STATUS_CLEAR',
  SetRemoteAlarmReset: 'SET_REMOTE_ALARM_RESET',
  SetAutodialAlarmStatusClear: 'SET_AUTODIAL_ALARM_STATUS_CLEAR',
};

export const AtgDialect = Object.freeze({
  Tls250: 'TLS-250',
  Tls350: 'TLS-350',
  Unknown: 'Unknown',
});
