import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import update from 'immutability-helper';

import { withTracking } from '../../../shared/analytics';
import { AuthenticationSelectors } from '../../../authentication';
import * as selectors from '../PreferencesSelectors';
import { cancelChangePassword, changePassword, updatePasswordContext } from '../actions';
import UserAlert from './UserAlert';
import PreferenceConstants from '../PreferenceConstants';

class PasswordSettings extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.onCurrentPasswordChange = this.onCurrentPasswordChange.bind(this);
    this.onNewPasswordChange = this.onNewPasswordChange.bind(this);
    this.onConfirmationPasswordChange = this.onConfirmationPasswordChange.bind(this);
    this.updatePasswordContext = this.updatePasswordContext.bind(this);

    this.disableCancel = this.disableCancel.bind(this);
  }

  disableCancel() {
    const passwordContext = this.props.passwordContext;
    if (!passwordContext) {
      return true;
    }

    return !(passwordContext.currentPassword || passwordContext.newPassword || passwordContext.confirmationPassword);
  }

  onCurrentPasswordChange(event) {
    this.updatePasswordContext('currentPassword', event.target.value);
  }

  onNewPasswordChange(event) {
    this.updatePasswordContext('newPassword', event.target.value);
  }

  onConfirmationPasswordChange(event) {
    this.updatePasswordContext('confirmationPassword', event.target.value);
  }

  updatePasswordContext(property, value) {
    const updatedPasswordContext = update(this.props.passwordContext, {
      [property]: {
        $set: value,
      },
    });
    this.props.updatePasswordContext(updatedPasswordContext);
  }

  render() {
    return (
      <div className={this.props.className}>
        <h5>Change password</h5>
        <UserAlert text={PreferenceConstants.passwordLengthWarning} isOpen={!this.props.meetsMinimumLengthRequirement && this.props.passwordContext.newPassword.length > 0} />
        <UserAlert text={PreferenceConstants.passwordDoNotMatchWarning} isOpen={this.props.meetsMinimumLengthRequirement && !this.props.confirmationPasswordMatches && this.props.passwordContext.newPassword.length > 0 && this.props.passwordContext.confirmationPassword.length > 0} />
        <UserAlert text={PreferenceConstants.updatePasswordSuccess} isOpen={this.props.updateSuccess} color="primary" />
        <Form>
          <Input
            id="username"
            onChange={() => {}}
            value={this.props.username}
            autoComplete="username"
            maxLength={32}
            className="d-none"
          />
          <FormGroup>
            <Label>Current password</Label>
            <Input
              type="password"
              id="currentPassword"
              onChange={this.onCurrentPasswordChange}
              value={this.props.passwordContext.currentPassword}
              bsSize="sm"
              autoComplete="password"
              maxLength={32}
            />
          </FormGroup>
          <FormGroup>
            <Label for="newPassword">New password</Label>
            <Input
              type="password"
              id="newPassword"
              name="newPassword"
              onChange={this.onNewPasswordChange}
              value={this.props.passwordContext.newPassword}
              bsSize="sm"
              autoComplete="new-password"
              maxLength={32}
              invalid={this.props.passwordContext.newPassword && !this.props.meetsMinimumLengthRequirement}
            />
            <FormFeedback>Password must be at least eight characters long</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="">Confirm password</Label>
            <Input
              type="password"
              id="confirmPassword"
              onChange={this.onConfirmationPasswordChange}
              value={this.props.passwordContext.confirmationPassword}
              bsSize="sm"
              autoComplete="new-password"
              maxLength={32}
            />
          </FormGroup>
        </Form>
        <div>
          <Button color="primary" size="sm" outline onClick={this.props.cancelChangePassword} disabled={this.disableCancel()}>Cancel</Button>
          {' '}
          <Button color="primary" size="sm" onClick={this.props.changePassword} disabled={!this.props.canChange}>Change</Button>
        </div>
      </div>
    );
  }
}

PasswordSettings.propTypes = {
  username: PropTypes.string.isRequired,
  passwordContext: PropTypes.shape({
    currentPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    confirmationPassword: PropTypes.string.isRequired,
  }),
  updatePasswordContext: PropTypes.func.isRequired,
  canChange: PropTypes.bool.isRequired,
  meetsMinimumLengthRequirement: PropTypes.bool.isRequired,
  confirmationPasswordMatches: PropTypes.bool.isRequired,
  cancelChangePassword: PropTypes.func.isRequired,
  updateSuccess: PropTypes.bool,
  changePassword: PropTypes.func.isRequired,
  className: PropTypes.string,
};

PasswordSettings.defaultProps = {
  passwordContext: {
    currentPassword: '',
    newPassword: '',
    confirmationPassword: '',
  },
  updateSuccess: false,
  className: null,
};

function mapStateToProps(state) {
  return {
    username: AuthenticationSelectors.currentUsername(state),
    passwordContext: selectors.passwordContext(state),
    canChange: selectors.canChangePassword(state),
    meetsMinimumLengthRequirement: selectors.meetsMinimumLengthRequirement(state),
    confirmationPasswordMatches: selectors.confirmationPasswordMatches(state),
    hasAllRequiredValues: selectors.hasAllRequiredValues(state),
    updateSuccess: selectors.changePasswordSuccess(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePasswordContext: (passwordContext) => dispatch(updatePasswordContext(passwordContext)),
    changePassword: () => dispatch(changePassword()),
    cancelChangePassword: () => dispatch(cancelChangePassword()),
  };
}

export default withTracking('Preferences', 'Password')(connect(mapStateToProps, mapDispatchToProps)(PasswordSettings));
