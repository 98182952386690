import React from 'react';
import PropTypes from 'prop-types';

import EquipmentCard from './EquipmentCard';

const ComplianceReportCard = ({ report, onReportClick }) => {
  if (!report) {
    return <em className="ml-4 text-muted">Compliance report not available</em>;
  }

  const monthText = `${report.monthText} ${report.year}`;
  return (
    <>
      <EquipmentCard title={monthText} status={report.status} calculatedStatusOverridden={report.calculatedStatusOverridden} onClick={onReportClick} />
    </>
  );
};

ComplianceReportCard.propTypes = {
  report: PropTypes.shape({
    monthText: PropTypes.string,
    year: PropTypes.number,
    status: PropTypes.string,
    calculatedStatusOverridden: PropTypes.bool,
  }),
  onReportClick: PropTypes.func.isRequired,

};

ComplianceReportCard.defaultProps = {
  report: null,
};

export default ComplianceReportCard;
