import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';

const EmptyFolder = ({ className }) => (
  <div className={classNames('text-center w-100', className)}>
    <FontAwesomeIcon size="3x" icon={faFolderOpen} className="text-gray-400" />
    <h5 className="mt-2 text-gray-500">This folder is empty</h5>
  </div>
);

EmptyFolder.propTypes = {
  className: PropTypes.string,
};

EmptyFolder.defaultProps = {
  className: null,
};

export default EmptyFolder;
