import moment from 'moment';
import { MonthType } from './ViewManagerConstants';

export default class ViewManagerSelectors {
  constructor(rootAccessor) {
    this._root = rootAccessor;
    this.isEditingView = this.isEditingView.bind(this);
    this.pendingEdits = this.pendingEdits.bind(this);
    this.isSaving = this.isSaving.bind(this);
    this.isDeleting = this.isDeleting.bind(this);
    this.pendingFilterEdits = this.pendingFilterEdits.bind(this);
    this.error = this.error.bind(this);
    this.isViewListLoading = this.isViewListLoading.bind(this);
    this.viewList = this.viewList.bind(this);
    this.hasAnyViews = this.hasAnyViews.bind(this);
    this.currentView = this.currentView.bind(this);
    this.selectedCustomers = this.selectedCustomers.bind(this);
    this.selectedCustomerIds = this.selectedCustomerIds.bind(this);
    this.selectedSiteStates = this.selectedSiteStates.bind(this);
    this.isLabelsLoading = this.isLabelsLoading.bind(this);
    this.allSiteLabels = this.allSiteLabels.bind(this);
    this.selectedSiteLabels = this.selectedSiteLabels.bind(this);
    this.selectedSiteLabelIds = this.selectedSiteLabelIds.bind(this);
    this.selectedConnectionTypes = this.selectedConnectionTypes.bind(this);
    this.selectedConnectionTypeIds = this.selectedConnectionTypeIds.bind(this);
    this.selectedConnectionStatuses = this.selectedConnectionStatuses.bind(this);
    this.selectedConnectionStatusIds = this.selectedConnectionStatusIds.bind(this);
    this.selectedIssueTypes = this.selectedIssueTypes.bind(this);
    this.selectedFriendlyIssueWorkflowStatuses = this.selectedFriendlyIssueWorkflowStatuses.bind(this);
    this.selectedIssueWorkflowStatuses = this.selectedIssueWorkflowStatuses.bind(this);
    this.monthType = this.monthType.bind(this);
    this.isMonthsLoading = this.isMonthsLoading.bind(this);
    this.months = this.months.bind(this);
    this.selectedMonth = this.selectedMonth.bind(this);
    this.selectedComplianceStatuses = this.selectedComplianceStatuses.bind(this);
    this.selectedInternalComplianceStatuses = this.selectedInternalComplianceStatuses.bind(this);
    this.selectedInspectionStatuses = this.selectedInspectionStatuses.bind(this);
    this.selectedSites = this.selectedSites.bind(this);
    this.selectedSiteIds = this.selectedSiteIds.bind(this);
    this.selectedTankProductLabels = this.selectedTankProductLabels.bind(this);
    this.selectedTankStatuses = this.selectedTankStatuses.bind(this);
    this.isEditingFilters = this.isEditingFilters.bind(this);
    this.selectedColumns = this.selectedColumns.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.sortDirection = this.sortDirection.bind(this);
    this.apiFields = this.apiFields.bind(this);
  }

  isEditingView(state) {
    return this._root(state).editingView || false;
  }

  pendingEdits(state) {
    return this._root(state).pendingViewEdits || {};
  }

  isSaving(state) {
    return this._root(state).saving || false;
  }

  isDeleting(state) {
    return this._root(state).deleting || false;
  }

  pendingFilterEdits(state) {
    return this.pendingEdits(state).filters || {};
  }

  error(state) {
    return this._root(state).error;
  }

  isViewListLoading(state) {
    return this._root(state).viewListLoading || false;
  }

  viewList(state) {
    return this._root(state).viewList || [];
  }

  hasAnyViews(state) {
    return !!this.viewList(state).length;
  }

  currentView(state) {
    return this._root(state).currentView || null;
  }

  selectedCustomers(state) {
    return this.pendingFilterEdits(state).customers || [];
  }

  selectedCustomerIds(state) {
    return this.selectedCustomers(state).map(current => current.id);
  }

  selectedSiteStates(state) {
    return this.pendingFilterEdits(state).siteStates || [];
  }

  isLabelsLoading(state) {
    return this._root(state).labelsLoading || false;
  }

  allSiteLabels(state) {
    return this._root(state).allSiteLabels || [];
  }

  selectedSiteLabels(state) {
    return this.pendingFilterEdits(state).selectedSiteLabels || [];
  }

  selectedSiteLabelIds(state) {
    return this.selectedSiteLabels(state).map(current => current.id);
  }

  selectedConnectionTypes(state) {
    return this.pendingFilterEdits(state).selectedConnectionTypes || [];
  }

  selectedConnectionTypeIds(state) {
    return this.selectedConnectionTypes(state).map(current => current.id);
  }

  selectedConnectionStatuses(state) {
    return this.pendingFilterEdits(state).selectedConnectionStatuses || [];
  }

  selectedConnectionStatusIds(state) {
    return this.selectedConnectionStatuses(state).map(current => current.id);
  }

  selectedIssueTypes(state) {
    return this.pendingFilterEdits(state).issueTypes || [];
  }

  selectedFriendlyIssueWorkflowStatuses(state) {
    return this.pendingFilterEdits(state).friendlyIssueWorkflowStatuses || [];
  }

  selectedIssueWorkflowStatuses(state) {
    const workflowStatuses = [];
    this.selectedFriendlyIssueWorkflowStatuses(state).forEach(current => workflowStatuses.push(...current.workflowStatusTypes));
    return workflowStatuses;
  }

  monthType(state) {
    const monthObj = this.pendingFilterEdits(state).month || {};
    return monthObj.monthType || MonthType.None;
  }

  isMonthsLoading(state) {
    return this._root(state).monthsLoading || false;
  }

  months(state) {
    return this._root(state).months || [];
  }

  selectedMonth(state) {
    const currentMonthType = this.monthType(state);
    if (currentMonthType === MonthType.None) {
      return null;
    }
    else if (currentMonthType === MonthType.LatestMonth) {
      const currentMonths = this.months(state);
      if (currentMonths.length) {
        return currentMonths[0];
      }
      const now = moment();
      return {
        year: now.year(),
        month: now.month(),
        monthText: now.format('MMMM'),
      };
    }
    const monthObj = this.pendingFilterEdits(state).month || {};
    return monthObj.selectedMonth || null;
  }

  selectedComplianceStatuses(state) {
    return this.pendingFilterEdits(state).complianceStatuses || [];
  }

  selectedInternalComplianceStatuses(state) {
    const internalStatuses = [];
    this.selectedComplianceStatuses(state).forEach(status => internalStatuses.push(...status.internalStatuses));
    return internalStatuses;
  }

  selectedInspectionStatuses(state) {
    return this.pendingFilterEdits(state).inspectionStatuses || [];
  }

  selectedSites(state) {
    return this.pendingFilterEdits(state).sites || [];
  }

  selectedSiteIds(state) {
    return this.selectedSites(state).map(current => current.id);
  }

  selectedTankProductLabels(state) {
    return this.pendingFilterEdits(state).tankProductLabels || [];
  }

  selectedTankStatuses(state) {
    return this.pendingFilterEdits(state).tankStatuses || [];
  }

  isEditingFilters(state) {
    return this._root(state).editingFilters || false;
  }

  selectedColumns(state) {
    return this.pendingEdits(state).columns || [];
  }

  sortColumn(state) {
    return this.pendingEdits(state).sortColumn;
  }

  sortDirection(state) {
    return this.pendingEdits(state).sortDirection;
  }

  apiFields(state) {
    const fields = [];
    this.selectedColumns(state).forEach(column => {
      if (column.apiFields) {
        fields.push(...column.apiFields);
      }
    });
    return fields;
  }
}
