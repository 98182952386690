export default {
  REFRESH_LIST_STARTED: 'compliance-list/refresh_list_started',
  REFRESH_LIST_CANCELED: 'compliance-list/refresh_list_canceled',
  REFRESH_LIST_FAILED: 'compliance-list/refresh_list_failed',

  GET_LIST_STARTED: 'compliance-list/get_list_started',
  RECEIVE_LIST: 'compliance-list/receive_list',
  GET_LIST_FAILED: 'compliance-list/get_list_failed',

  CHANGE_SORT: 'compliance-list/change_sort',
  CHANGE_PAGE: 'compliance-list/change_page',

  // view manager actions
  GET_VIEWS_STARTED: 'compliance-list/get_views_started',
  RECEIVE_VIEWS: 'compliance-list/receive_views',
  GET_VIEWS_FAILED: 'compliance-list/get_views_failed',
  SELECT_VIEW: 'compliance-list/select_view',
  TOGGLE_DEFAULT_VIEW: 'compliance-list/toggle_default_view',
  SAVE_VIEW_STARTED: 'compliance-list/save_view_started',
  SAVE_VIEW_SUCCESS: 'compliance-list/save_view_success',
  SAVE_VIEW_FAILED: 'compliance-list/save_view_failed',
  DELETE_VIEW_STARTED: 'compliance-list/delete_view_started',
  DELETE_VIEW_SUCCESS: 'compliance-list/delete_view_success',
  DELETE_VIEW_FAILED: 'compliance-list/delete_view_failed',
  START_FILTER_EDIT: 'compliance-list/start_filter_edit',
  CANCEL_FILTER_EDIT: 'compliance-list/cancel_filter_edit',
  CLEAR_FILTERS: 'compliance-list/clear_filters',
  SELECT_CUSTOMERS: 'compliance-list/select_customers',
  DESELECT_CUSTOMER: 'compliance-list/deselect_customer',
  SELECT_SITE_STATES: 'compliance-list/select_site_states',
  DESELECT_SITE_STATE: 'compliance-list/deselect_site_state',
  GET_SITE_LABELS_STARTED: 'compliance-list/get_site_labels_started',
  RECEIVE_SITE_LABELS: 'compliance-list/receive_site_labels',
  GET_SITE_LABELS_FAILED: 'compliance-list/get_site_labels_failed',
  SELECT_SITE_LABELS: 'compliance-list/select_site_labels',
  DESELECT_SITE_LABEL: 'compliance-list/deselect_site_label',
  SELECT_COMPLIANCE_STATUSES: 'compliance-list/select_compliance_statuses',
  DESELECT_COMPLIANCE_STATUS: 'compliance-list/deselect_compliance_status',
  GET_MONTHS_STARTED: 'compliance-list/get_months_started',
  RECEIVE_MONTHS: 'compliance-list/receive_months',
  GET_MONTHS_FAILED: 'compliance-list/get_months_failed',
  SET_MONTH_FILTER: 'compliance-list/set_month_filter',
  SELECT_SITES: 'compliance-list/select_sites',
  DESELECT_SITE: 'compliance-list/deselect_sites',
  SET_COLUMNS: 'compliance-list/set_columns',
};
