import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import keyBy from 'lodash/keyBy';
import { SystemUserId } from '../../../../../AppConstants';

function mapViews(allViews, filter) {
  return allViews
    .filter(filter)
    .map(current => {
      if (current.defaultView) {
        return Object.assign({}, current, { name: `${current.name} (default view)` });
      }
      return current;
    });
}

const ReservedViewIds = {
  SelectView: -1,
  NoViewsDefined: -2,
};

const ViewsDropdown = ({ editingView, selectedView, allViews, onViewSelect, disabled, className, style }) => {
  const viewsById = keyBy(allViews, view => String(view.id));

  let selectedViewId;
  if (editingView) {
    selectedViewId = -1;
  }
  else {
    selectedViewId = (selectedView && selectedView.id) || 0;
  }

  const selectHandler = e => {
    const value = e.target.value;
    if (value !== String(ReservedViewIds.NoViewsDefined)) {
      if (value) {
        const view = viewsById[value] || null;
        onViewSelect(view);
      }
      else {
        onViewSelect(null);
      }
    }
  };

  const myViews = mapViews(allViews, v => v.userId !== SystemUserId);
  const systemViews = mapViews(allViews, v => v.userId === SystemUserId);

  return (
    <Input id="views-dropdown" name="views-dropdown" type="select" className={className} style={style} bsSize="sm" value={selectedViewId} onChange={selectHandler} disabled={disabled}>
      {selectedViewId === ReservedViewIds.SelectView ? <option value={ReservedViewIds.SelectView}>Select a view...</option> : null}
      <optgroup label="My views">
        {myViews.length
          ? myViews.map(current => (<option key={current.id} value={current.id}>{current.name}</option>))
          : (<option value={ReservedViewIds.NoViewsDefined} className="text-muted font-italic">No views defined</option>)}
      </optgroup>
      <optgroup label="System views">
        {systemViews.length
          ? systemViews.map(current => (<option key={current.id} value={current.id}>{current.name}</option>))
          : <option value={ReservedViewIds.NoViewsDefined} className="text-muted font-italic">No system views defined</option>}
      </optgroup>
    </Input>
  );
};

ViewsDropdown.propTypes = {
  editingView: PropTypes.bool.isRequired,
  selectedView: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  allViews: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  onViewSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

ViewsDropdown.defaultProps = {
  selectedView: null,
  allViews: [],
  disabled: false,
  className: null,
  style: null,
};

export default ViewsDropdown;
