export default {
  GET_USER_STARTED: 'user-admin/get_user_started',
  RECEIVE_USER: 'user-admin/receive_user',
  USERS_SEARCH_STARTED: 'user-admin/users_search_started',
  USERS_SEARCH_FAILED: 'user-admin/users_search_failed',
  RECEIVE_MATCHING_USERS: 'user-admin/receive_matching_users',
  EDIT_SELECTED_USER: 'user-admin/edit_selected_user',
  DISCARD_SELECTED_USER_EDITS: 'user-admin/discard_selected_user_edits',
  GET_USER_FAILED: 'user-admin/get_user_failed',

  SAVE_USER_STARTED: 'user-admin/save_user_started',
  SAVE_USER_SUCCESS: 'user-admin/save_user_success',
  SAVE_USER_FAILED: 'user-admin/save_user_failed',


};
