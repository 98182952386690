const StorageKeys = Object.freeze({
  UserId: 'userId',
  Token: 'token',
});

export default {
  getUserId() {
    const userId = window.localStorage.getItem(StorageKeys.UserId);
    return userId && Number.parseInt(userId, 10);
  },
  
  setUserId: userId => window.localStorage.setItem(StorageKeys.UserId, userId),
  
  getToken: () => window.localStorage.getItem(StorageKeys.Token),
  
  setToken: token => window.localStorage.setItem(StorageKeys.Token, token),
  
  reset() {
    window.localStorage.removeItem(StorageKeys.UserId);
    window.localStorage.removeItem(StorageKeys.Token);
  }
};
