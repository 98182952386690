import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';

import HelpIcon from '../../../../../components/help-icon/HelpIcon';
import { Column, IntegerColumn, StatefulTable, StringColumn, TextAlign, TimestampColumn } from '../../../../../../shared/components/table';
import CanaryLoadingIndicator from '../../../../../../shared/components/CanaryLoadingIndicator';
import FuelHaulerDropdown from '../../fuel-haulers/containers/FuelHaulerDropdown';
import FilesModal from '../../../components/FilesModal';
import DurationDropdown from '../components/DurationDropdown';
import * as actions from '../actions';
import * as selectors from '../ExportHistorySelectors';

class ExportHistoryTable extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      showHelp: false,
      showFilesModal: false,
    };
    this.toggleHelp = this.toggleHelp.bind(this);
    this.handleViewFilesClick = this.handleViewFilesClick.bind(this);
    this.closeViewFilesModal = this.closeViewFilesModal.bind(this);
    this.props.refreshExportHistory();
  }

  toggleHelp() {
    this.setState({
      showHelp: !this.state.showHelp,
    });
  }

  handleViewFilesClick(exportId) {
    this.setState({
      showFilesModal: true,
    });
    this.props.selectExport(exportId);
  }

  closeViewFilesModal() {
    this.setState({
      showFilesModal: false,
    });
  }

  render() {
    return (
      <div>
        <Alert
          color="info"
          className="mb-1"
          isOpen={this.state.showHelp}
          toggle={this.toggleHelp}
        >
          This page shows a history of the inventory files that have been exported from Canary and submitted for delivery to
          each fuel hauler. Click on the table headings to sort the data. Use the dropdowns below the table headings to filter the
          table by fuel hauler or date. Click on the <em>DOWNLOAD FILE</em> link to download a copy of a particular export file.
          <br /><br />
          <strong>Note that this list shows files that have been exported from Canary and submitted for delivery.</strong> There&#39;s no
          guarantee that the files listed here were actually delivered.
        </Alert>
        <div className="mb-3">
          <h5 style={{ display: 'inline' }}>Export history</h5>
          <HelpIcon onClick={this.toggleHelp} className="ml-1" />
        </div>
        <div className="my-2">
          {this.renderFilters()}
        </div>
        {this.renderTable()}
        <FilesModal isOpen={this.state.showFilesModal} onClose={this.closeViewFilesModal} selectedExport={this.props.selectedExport} />
      </div>
    );
  }

  renderFilters() {
    return (
      <Row>
        <Col lg={6}>
          <Form inline>
            <FormGroup>
              <Label for="fuelHauler" className="mr-2">Fuel hauler</Label>
              <FuelHaulerDropdown
                selectedFuelHauler={this.props.filter.fuelHauler}
                onFuelHaulerSelect={this.props.filterByFuelHauler}
                className="form-control-sm"
              />
            </FormGroup>
          </Form>
        </Col>
        <Col lg={6}>
          <Form inline>
            <FormGroup>
              <Label for="duration" className="mr-2">Date</Label>
              <DurationDropdown
                durationType={this.props.filter.durationType}
                onDurationSelect={this.props.filterByDurationType}
                className="form-control-sm"
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    );
  }

  renderTable() {
    if (this.props.isLoading) {
      return <CanaryLoadingIndicator />;
    }

    const renderViewFiles = record => (
      <div style={{ display: 'inline' }}>
        <Button color="link" size="sm" className="ml-1" onClick={() => this.handleViewFilesClick(record.id)}>View Files</Button>
      </div>
    );

    return (
      <StatefulTable
        initialSortColumn="created"
        initialSortDirection="desc"
        data={this.props.exportHistoryList}
      >
        <StringColumn property="id" title="ID" width="6rem" />
        <StringColumn property="fuelHauler.name" title="Fuel Hauler" sortable />
        <IntegerColumn property="healthySiteCount" title="Healthy Site Count" textAlign={TextAlign.Center} />
        <IntegerColumn property="unhealthySiteCount" title="Unhealthy Site Count" textAlign={TextAlign.Center} />
        <TimestampColumn property="created" title="Date" sortable />
        <Column columnId="viewFiles" render={renderViewFiles} />
      </StatefulTable>
    );
  }
}

ExportHistoryTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  exportHistoryList: PropTypes.array.isRequired,
  filter: PropTypes.object,
  selectedExport: PropTypes.object,

  refreshExportHistory: PropTypes.func.isRequired,
  filterByFuelHauler: PropTypes.func.isRequired,
  filterByDurationType: PropTypes.func.isRequired,
  selectExport: PropTypes.func.isRequired,
};

ExportHistoryTable.defaultProps = {
  filter: {
    fuelHauler: null,
  },
  selectedExport: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    exportHistoryList: selectors.exportHistoryList(state),
    filter: selectors.filter(state),
    selectedExport: selectors.selectedExport(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    refreshExportHistory: () => dispatch(actions.refreshExportHistory()),
    filterByFuelHauler: fuelHauler => dispatch(actions.filterByFuelHauler(fuelHauler)),
    filterByDurationType: durationType => dispatch(actions.filterByDurationType(durationType)),
    selectExport: exportId => dispatch(actions.selectExport(exportId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportHistoryTable);
