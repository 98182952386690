import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { track } from '../../../../../../../../shared/analytics';
import * as actions from '../../actions';
import * as IssueSelectors from '../../IssueEditSelectors';
import PriorityDropdown from '../../../../../../../../shared/components/PriorityDropdown';
import PriorityIndicator from '../../../../../../../../shared/components/PriorityIndicator';

class PriorityContainer extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(newPriority) {
    track('Issue', {
      Component: 'Issue priority',
      'Issue action': 'Set priority',
      'Issue type': this.props.issue.issueType,
      'Old priority': this.props.issue.priority,
      'New priority': newPriority,
    });
    this.props.updatePriority(newPriority);
  }

  render() {
    if (!this.props.issue) {
      return null;
    }
    else if (this.props.canEditIssue) {
      return <PriorityDropdown className={this.props.className} priority={this.props.issue.priority} onPriorityChange={this.handleChange} />;
    }
    return <PriorityIndicator className={this.props.className} priority={this.props.issue.priority} />;
  }
}

PriorityContainer.propTypes = {
  className: PropTypes.string,
  issue: PropTypes.object,
  canEditIssue: PropTypes.bool,
  updatePriority: PropTypes.func.isRequired,
};

PriorityContainer.defaultProps = {
  className: null,
  issue: null,
  canEditIssue: false,
};

function mapStateToProps(state) {
  return {
    issue: IssueSelectors.currentIssue(state),
    canEditIssue: IssueSelectors.canEditCurrentIssue(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePriority: newPriority => dispatch(actions.updatePriority(newPriority)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PriorityContainer);
