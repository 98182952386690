import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { AuthenticationSelectors } from '../../../../authentication';
import FilterEditBar from './components/FilterEditBar';
import { FilterType, MonthType } from './ViewManagerConstants';
import AllFilterTags from './components/AllFilterTags';

class ViewBar extends Component {
  componentDidMount() {
    Promise.all([
      this.props.getViews(),
      this.props.getMonths(),
    ]).then(() => this.props.onChange());
  }

  render() {
    return (
      <div>
        <FilterEditBar
          filterTypes={this.props.filterTypes}
          selectedCustomers={this.props.selectedCustomers}
          selectCustomers={this.props.selectCustomers}
          selectedSiteLabels={this.props.selectedSiteLabels}
          allSiteLabels={this.props.allSiteLabels}
          getAllLabels={this.props.getAllLabels}
          selectSiteLabels={this.props.selectSiteLabels}
          selectedStates={this.props.selectedStates}
          allStates={this.props.allStates}
          selectSiteStates={this.props.selectSiteStates}
          selectedConnectionTypes={this.props.selectedConnectionTypes}
          selectConnectionTypes={this.props.selectConnectionTypes}
          deselectConnectionType={this.props.deselectConnectionType}
          selectedConnectionStatuses={this.props.selectedConnectionStatuses}
          selectConnectionStatuses={this.props.selectConnectionStatuses}
          deselectConnectionStatus={this.props.deselectConnectionStatus}
          selectedIssueTypes={this.props.selectedIssueTypes}
          selectIssueTypes={this.props.selectIssueTypes}
          selectedFriendlyWorkflowStatuses={this.props.selectedFriendlyWorkflowStatuses}
          selectFriendlyIssueWorkflowStatuses={this.props.selectFriendlyIssueWorkflowStatuses}
          deselectFriendlyIssueWorkflowStatus={this.props.deselectFriendlyIssueWorkflowStatus}
          monthType={this.props.monthType}
          selectedMonth={this.props.selectedMonth}
          months={this.props.months}
          setMonthFilter={this.props.setMonthFilter}
          cancelFilterEdit={this.props.cancelFilterEdit}
          editingFilters={this.props.editingFilters}
          selectedComplianceStatuses={this.props.selectedComplianceStatuses}
          selectComplianceStatuses={this.props.selectComplianceStatuses}
          deselectComplianceStatus={this.props.deselectComplianceStatus}
          selectedInspectionStatuses={this.props.selectedInspectionStatuses}
          selectInspectionStatuses={this.props.selectInspectionStatuses}
          deselectInspectionStatus={this.props.deselectInspectionStatus}
          sitePermission={this.props.sitePermission}
          siteFeature={this.props.siteFeature}
          selectedSites={this.props.selectedSites}
          selectSites={this.props.selectSites}
          selectTankProductLabels={this.props.selectTankProductLabels}
          selectedTankProductLabels={this.props.selectedTankProductLabels}
          selectedTankStatuses={this.props.selectedTankStatuses}
          selectTankStatuses={this.props.selectTankStatuses}
          deselectTankStatus={this.props.deselectTankStatus}
          disabled={this.props.disabled}
        />
        <AllFilterTags
          disabled={this.props.disabled}
          editingView={this.props.editingView}
          clearFilters={this.props.clearFilters}
          selectedCustomers={this.props.selectedCustomers}
          deselectCustomer={this.props.deselectCustomer}
          selectedStates={this.props.selectedStates}
          deselectSiteState={this.props.deselectSiteState}
          selectedConnectionTypes={this.props.selectedConnectionTypes}
          deselectConnectionType={this.props.deselectConnectionType}
          selectedConnectionStatuses={this.props.selectedConnectionStatuses}
          deselectConnectionStatus={this.props.deselectConnectionStatus}
          selectedSiteLabels={this.props.selectedSiteLabels}
          deselectSiteLabel={this.props.deselectSiteLabel}
          selectedIssueTypes={this.props.selectedIssueTypes}
          selectedFriendlyWorkflowStatuses={this.props.selectedFriendlyWorkflowStatuses}
          deselectFriendlyIssueWorkflowStatus={this.props.deselectFriendlyIssueWorkflowStatus}
          deselectIssueType={this.props.deselectIssueType}
          monthType={this.props.monthType}
          selectedMonth={this.props.selectedMonth}
          setMonthFilter={this.props.setMonthFilter}
          selectedComplianceStatuses={this.props.selectedComplianceStatuses}
          deselectComplianceStatus={this.props.deselectComplianceStatus}
          selectedInspectionStatuses={this.props.selectedInspectionStatuses}
          deselectInspectionStatus={this.props.deselectInspectionStatus}
          selectedSites={this.props.selectedSites}
          deselectSite={this.props.deselectSite}
          selectedTankProductLabels={this.props.selectedTankProductLabels}
          deselectTankProductLabel={this.props.deselectTankProductLabel}
          selectedTankStatuses={this.props.selectedTankStatuses}
          deselectTankStatus={this.props.deselectTankStatus}
        />
      </div>
    );
  }
}

ViewBar.propTypes = {
  onChange: PropTypes.func.isRequired,

  filterTypes: PropTypes.arrayOf(PropTypes.string),

  disabled: PropTypes.bool.isRequired,
  editingView: PropTypes.bool.isRequired,
  editingFilters: PropTypes.bool.isRequired,
  getViews: PropTypes.func.isRequired,

  clearFilters: PropTypes.func.isRequired,

  selectedCustomers: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectCustomers: PropTypes.func.isRequired,
  deselectCustomer: PropTypes.func.isRequired,

  allStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectSiteStates: PropTypes.func.isRequired,
  deselectSiteState: PropTypes.func.isRequired,

  selectedConnectionTypes: PropTypes.array.isRequired,
  selectConnectionTypes: PropTypes.func.isRequired,
  deselectConnectionType: PropTypes.func.isRequired,

  selectedConnectionStatuses: PropTypes.array.isRequired,
  selectConnectionStatuses: PropTypes.func.isRequired,
  deselectConnectionStatus: PropTypes.func.isRequired,

  selectedSiteLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
  allSiteLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllLabels: PropTypes.func.isRequired,
  selectSiteLabels: PropTypes.func.isRequired,
  deselectSiteLabel: PropTypes.func.isRequired,

  selectedIssueTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectIssueTypes: PropTypes.func.isRequired,
  deselectIssueType: PropTypes.func.isRequired,

  selectedFriendlyWorkflowStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectFriendlyIssueWorkflowStatuses: PropTypes.func.isRequired,
  deselectFriendlyIssueWorkflowStatus: PropTypes.func.isRequired,

  monthType: PropTypes.string,
  selectedMonth: PropTypes.object,
  months: PropTypes.array,
  getMonths: PropTypes.func.isRequired,
  setMonthFilter: PropTypes.func.isRequired,

  selectedComplianceStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectComplianceStatuses: PropTypes.func.isRequired,
  deselectComplianceStatus: PropTypes.func.isRequired,

  selectedInspectionStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectInspectionStatuses: PropTypes.func.isRequired,
  deselectInspectionStatus: PropTypes.func.isRequired,

  sitePermission: PropTypes.number,
  siteFeature: PropTypes.string,
  selectedSites: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectSites: PropTypes.func.isRequired,
  deselectSite: PropTypes.func.isRequired,

  selectedTankProductLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectTankProductLabels: PropTypes.func.isRequired,
  deselectTankProductLabel: PropTypes.func.isRequired,

  selectedTankStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectTankStatuses: PropTypes.func.isRequired,
  deselectTankStatus: PropTypes.func.isRequired,

  cancelFilterEdit: PropTypes.func.isRequired,
};

ViewBar.defaultProps = {
  filterTypes: [FilterType.Customer, FilterType.Label, FilterType.State],
  monthType: MonthType.None,
  selectedMonth: null,
  months: [],
  sitePermission: null,
  siteFeature: null,
};

export default function buildViewBar(actions, listSelectors, viewManagerSelectors, filterTypes) {
  function mapStateToProps(state) {
    return {
      filterTypes,
      disabled: listSelectors.isLoading(state) || listSelectors.isRefreshing(state) || viewManagerSelectors.isSaving(state),
      editingView: viewManagerSelectors.isEditingView(state),
      editingFilters: viewManagerSelectors.isEditingFilters(state),
      saving: viewManagerSelectors.isSaving(state),
      selectedCustomers: viewManagerSelectors.selectedCustomers(state),
      allStates: AuthenticationSelectors.allSiteStates(state),
      selectedStates: viewManagerSelectors.selectedSiteStates(state),
      selectedConnectionTypes: viewManagerSelectors.selectedConnectionTypes(state),
      selectedConnectionStatuses: viewManagerSelectors.selectedConnectionStatuses(state),
      selectedSiteLabels: viewManagerSelectors.selectedSiteLabels(state),
      allSiteLabels: viewManagerSelectors.allSiteLabels(state),
      selectedIssueTypes: viewManagerSelectors.selectedIssueTypes(state),
      selectedFriendlyWorkflowStatuses: viewManagerSelectors.selectedFriendlyIssueWorkflowStatuses(state),
      monthType: viewManagerSelectors.monthType(state),
      selectedMonth: viewManagerSelectors.selectedMonth(state),
      months: viewManagerSelectors.months(state),
      selectedComplianceStatuses: viewManagerSelectors.selectedComplianceStatuses(state),
      selectedInspectionStatuses: viewManagerSelectors.selectedInspectionStatuses(state),
      selectedSites: viewManagerSelectors.selectedSites(state),
      selectedTankProductLabels: viewManagerSelectors.selectedTankProductLabels(state),
      selectedTankStatuses: viewManagerSelectors.selectedTankStatuses(state),
    };
  }

  function mapDispatchToProps(dispatch, ownProps) {
    const onChange = ownProps.onChange;
    const debouncedOnChange = debounce(onChange, 1500);
    return {
      getViews: () => dispatch(actions.getViews()),
      saveView: (name, defaultView) => dispatch(actions.saveView(name, defaultView)),

      clearFilters: () => Promise.resolve(dispatch(actions.clearFilters())).then(() => onChange()),

      selectCustomers: customers => Promise.resolve(dispatch(actions.selectCustomers(customers))).then(() => debouncedOnChange()),
      deselectCustomer: customer => Promise.resolve(dispatch(actions.deselectCustomer(customer))).then(() => debouncedOnChange()),

      selectSiteStates: states => Promise.resolve(dispatch(actions.selectSiteStates(states))).then(() => debouncedOnChange()),
      deselectSiteState: state => Promise.resolve(dispatch(actions.deselectSiteState(state))).then(() => debouncedOnChange()),

      selectConnectionTypes: connectionTypes => Promise.resolve(dispatch(actions.selectConnectionTypes(connectionTypes))).then(() => debouncedOnChange()),
      deselectConnectionType: connectionType => Promise.resolve(dispatch(actions.deselectConnectionType(connectionType))).then(() => debouncedOnChange()),

      selectConnectionStatuses: connectionStatuses => Promise.resolve(dispatch(actions.selectConnectionStatuses(connectionStatuses))).then(() => debouncedOnChange()),
      deselectConnectionStatus: connectionStatus => Promise.resolve(dispatch(actions.deselectConnectionStatus(connectionStatus))).then(() => debouncedOnChange()),

      getAllLabels: () => dispatch(actions.getAllLabels()),
      selectSiteLabels: labels => Promise.resolve(dispatch(actions.selectSiteLabels(labels))).then(() => debouncedOnChange()),
      deselectSiteLabel: label => Promise.resolve(dispatch(actions.deselectSiteLabel(label))).then(() => debouncedOnChange()),

      selectIssueTypes: issueTypes => Promise.resolve(dispatch(actions.selectIssueTypes(issueTypes))).then(() => debouncedOnChange()),
      deselectIssueType: issueType => Promise.resolve(dispatch(actions.deselectIssueType(issueType))).then(() => debouncedOnChange()),

      selectFriendlyIssueWorkflowStatuses: friendlyWorkflowStatuses => Promise.resolve(dispatch(actions.selectFriendlyIssueWorkflowStatuses(friendlyWorkflowStatuses))).then(() => debouncedOnChange()),
      deselectFriendlyIssueWorkflowStatus: friendlyWorkflowStatus => Promise.resolve(dispatch(actions.deselectFriendlyIssueWorkflowStatus(friendlyWorkflowStatus))).then(() => debouncedOnChange()),

      getMonths: () => dispatch(actions.getMonths()),
      setMonthFilter: (monthType, selectedMonth) => Promise.resolve(dispatch(actions.setMonthFilter(monthType, selectedMonth))).then(() => debouncedOnChange()),

      selectComplianceStatuses: complianceStatuses => Promise.resolve(dispatch(actions.selectComplianceStatuses(complianceStatuses))).then(() => debouncedOnChange()),
      deselectComplianceStatus: complianceStatus => Promise.resolve(dispatch(actions.deselectComplianceStatus(complianceStatus))).then(() => debouncedOnChange()),

      selectInspectionStatuses: inspectionStatuses => Promise.resolve(dispatch(actions.selectInspectionStatuses(inspectionStatuses))).then(() => debouncedOnChange()),
      deselectInspectionStatus: inspectionStatus => Promise.resolve(dispatch(actions.deselectInspectionStatus(inspectionStatus))).then(() => debouncedOnChange()),

      selectSites: sites => Promise.resolve(dispatch(actions.selectSites(sites))).then(() => debouncedOnChange()),
      deselectSite: site => Promise.resolve(dispatch(actions.deselectSite(site))).then(() => debouncedOnChange()),

      selectTankStatuses: tankStatuses => Promise.resolve(dispatch(actions.selectTankStatuses(tankStatuses))).then(() => debouncedOnChange()),
      deselectTankStatus: tankStatus => Promise.resolve(dispatch(actions.deselectTankStatus(tankStatus))).then(() => debouncedOnChange()),

      selectTankProductLabels: labels => Promise.resolve(dispatch(actions.selectTankProductLabels(labels))).then(() => debouncedOnChange()),
      deselectTankProductLabel: label => Promise.resolve(dispatch(actions.deselectTankProductLabel(label))).then(() => debouncedOnChange()),

      cancelFilterEdit: () => dispatch(actions.cancelFilterEdit()),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(ViewBar);
}
