import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import IssueExportForm from './IssueExportForm';

import { track } from '../../../../../../../../shared/analytics';
import CanaryLoadingIndicator from '../../../../../../../../shared/components/CanaryLoadingIndicator';
import * as selectors from './IssueExportSelectors';
import * as actions from './IssueExportActions';

class IssueExportModal extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleAnalysisRecCheck = this.buildToggleFunction('analysisRec').bind(this);
    this.handleCommentsCheck = this.buildToggleFunction('comments');
    this.handleAttachmentsCheck = this.buildToggleFunction('attachments');
    this.handleAtgReportsCheck = this.buildToggleFunction('atgReports');
    this.handleExport = this.handleExport.bind(this);
  }

  buildToggleFunction(property) {
    return checked => {
      const newOptions = update(this.props.exportOptions, {
        [property]: {
          $set: checked,
        }
      });
      this.props.setExportOptions(newOptions);
    };
  }

  renderModalBody() {
    if (!this.props.issue) {
      return null;
    }
    else if (this.props.isDownloading) {
      return <CanaryLoadingIndicator />;
    }

    const options = this.props.exportOptions;
    return (
      <ModalBody>
        <h5>{this.props.issue.description}</h5>
        <IssueExportForm
          analysisRecChecked={options.analysisRec}
          onAnalysisRecCheck={this.handleAnalysisRecCheck}
          commentsChecked={options.comments}
          onCommentsCheck={this.handleCommentsCheck}
          attachmentsChecked={options.attachments}
          onAttachmentsCheck={this.handleAttachmentsCheck}
          atgReportsChecked={options.atgReports}
          onAtgReportsCheck={this.handleAtgReportsCheck}
        />
      </ModalBody>
    );
  }

  handleExport() {
    track('Issue', {
      Component: 'Export modal',
      'Issue action': 'Export',
      'Include AR': this.props.exportOptions.analysisRec,
      'Include comments': this.props.exportOptions.comments,
      'Include attachments': this.props.exportOptions.attachments,
      'Include ATG reports': this.props.exportOptions.atgReports,
    });
    this.props.exportIssue();
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.cancelExport}>
        <ModalHeader toggle={this.props.cancelExport}>
          Export Issue
        </ModalHeader>
        {this.renderModalBody()}
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={this.props.cancelExport}>Cancel</Button>
          <Button
            color="primary"
            size="sm"
            onClick={this.handleExport}
            disabled={this.props.isDownloading}
          >
            Export
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

IssueExportModal.propTypes = {
  issue: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }),
  exportOptions: PropTypes.shape({
    analysisRec: PropTypes.bool.isRequired,
    comments: PropTypes.bool.isRequired,
    attachments: PropTypes.bool.isRequired,
    atgReports: PropTypes.bool.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  setExportOptions: PropTypes.func.isRequired,
  exportIssue: PropTypes.func.isRequired,
  cancelExport: PropTypes.func.isRequired,
};

IssueExportModal.defaultProps = {
  issue: null,
};

function mapStateToProps(state) {
  return {
    issue: selectors.selectedIssue(state),
    exportOptions: selectors.exportOptions(state),
    isOpen: selectors.isModalOpen(state),
    isDownloading: selectors.isDownloading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setExportOptions: (options) => dispatch(actions.setExportOptions(options)),
    exportIssue: () => dispatch(actions.exportIssue()),
    cancelExport: () => dispatch(actions.closeModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueExportModal);
