import React from 'react';
import PropTypes from 'prop-types';

import UserDate from '../UserDate';
import { BrowserTimezone } from '../date';

const SpeechBubble = ({ user, date, children, className }) => (
  <div className={className}>
    <UserDate user={user} date={date} timezone={BrowserTimezone} className="d-block" />
    <div className="speech-bubble p-3 mt-3" style={{ verticalAlign: 'middle' }}>
      {children}
    </div>
  </div>
);

SpeechBubble.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      username: PropTypes.string.isRequired,
    })
  ]).isRequired,
  date: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

SpeechBubble.defaultProps = {
  className: null,
};

export default SpeechBubble;
