import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import { USStateAbbreviations } from '../../../AppConstants';


const StateDropdown = ({ abbreviation, disabled, onStateSelect }) => (
  <Input
    type="select"
    id="state"
    name="state"
    value={abbreviation || ''}
    onChange={event => event.target.value && onStateSelect(event.target.value)}
    disabled={disabled}
  >
    <option value={null}>Select a state</option>
    {Object.keys(USStateAbbreviations).map(current => (
      <option value={current} key={current}>{USStateAbbreviations[current]}</option>
    ))}
  </Input>
);

StateDropdown.propTypes = {
  abbreviation: PropTypes.string,
  disabled: PropTypes.bool,
  onStateSelect: PropTypes.func.isRequired,
};

StateDropdown.defaultProps = {
  abbreviation: '',
  disabled: false
};

export default StateDropdown;
