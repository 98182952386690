import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import CanaryLoadingIndicator from '../../../../../shared/components/CanaryLoadingIndicator';
import Subheader from '../../../../../shared/components/Subheader';
import IssueCard from './IssueCard';
import * as SiteActions from '../../../actions';
import * as SiteSelectors from '../../../SiteSelectors';
import * as selectors from '../IssueSelectors';
import * as actions from '../actions';

class IssueCards extends Component {
  componentDidMount() {
    this.props.getOpenIssues();
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.renderHeader()}
        {this.renderIssues()}
        {this.renderLink()}
      </div>
    );
  }

  renderHeader() {
    if (!this.props.header) {
      return null;
    }

    const count = (this.props.issues && this.props.issues.length) || 0;
    const description = `Open Issues (${count})`;
    return <Subheader text={description} />;
  }

  renderIssues() {
    if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }

    const issues = this.props.issues;
    if (!issues) {
      return (
        <div className="ml-4 my-2">
          <h5>Issues unavailable</h5>
        </div>
      );
    }
    else if (!issues.length) {
      return (
        <div className="ml-4 my-2">
          <h5>No open issues</h5>
        </div>
      );
    }
    return issues.map(current => <IssueCard key={current.id} issue={current} onNavToIssue={this.props.navToCurrentSiteIssue} className="mb-3" />);
  }

  renderLink() {
    if (!this.props.link) {
      return null;
    }

    return (
      <div className="mt-3 ml-4">
        <Button color="link" onClick={this.props.navToSiteIssues}>See all Issues</Button>
      </div>
    );
  }
}

IssueCards.propTypes = {
  // public properties
  header: PropTypes.bool,
  link: PropTypes.bool,

  // internally managed
  loading: PropTypes.bool.isRequired,
  issues: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    priority: PropTypes.string,
    created: PropTypes.string,
  })),
  className: PropTypes.string,
  getOpenIssues: PropTypes.func.isRequired,
  navToSiteIssues: PropTypes.func.isRequired,
  navToCurrentSiteIssue: PropTypes.func.isRequired,
};

IssueCards.defaultProps = {
  header: false,
  link: false,
  issues: null,
  className: null,
};

function mapStateToProps(state) {
  return {
    siteId: SiteSelectors.currentSiteId(state),
    loading: selectors.isOpenIssuesLoading(state),
    issues: selectors.openIssues(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOpenIssues: () => dispatch(actions.getOpenIssues()),
    navToSiteIssues: () => dispatch(SiteActions.navToSiteIssues()),
    navToCurrentSiteIssue: issue => dispatch(SiteActions.navToCurrentSiteIssue(issue)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueCards);
