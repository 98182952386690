const SubmersibleTurbinePumps = { id: 7,
  name: 'submersibleTurbinePumps',
  label: 'STPs',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: false,
  card: true,
  path: 'submersibleTurbinePumps',
  index: 0,
  fields: [
    { id: 1,
      name: 'name',
      label: 'Name',
      path: 'submersibleTurbinePumps.0.name',
      type: 'text',
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
    { id: 2,
      name: 'manufacturer',
      label: 'Manufacturer',
      path: 'submersibleTurbinePumps.0.manufacturer',
      type: 'text',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 3,
      name: 'modelNumber',
      label: 'Model Number',
      path: 'submersibleTurbinePumps.0.modelNumber',
      type: 'text',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 4,
      name: 'serialNumber',
      label: 'Serial Number',
      path: 'submersibleTurbinePumps.0.serialNumber',
      type: 'text',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 5,
      name: 'installDate',
      label: 'Installation Date',
      path: 'submersibleTurbinePumps.0.installDate',
      type: 'date',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 6,
      name: 'containmentType',
      label: 'Containment Type',
      path: 'submersibleTurbinePumps.0.containment.containmentType',
      type: 'select',
      values: ['Unknown', 'None', 'Sump'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: false,
    },
  ],
};

export default SubmersibleTurbinePumps;
