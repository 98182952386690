export function isLoading(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.list && state.admin.organizations.list.loading) || false;
}

export function isUpdating(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.list && state.admin.organizations.list.updating) || false;
}

export function orgListError(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.list && state.admin.organizations.list.error) || null;
}

export function organizationList(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.list && state.admin.organizations.list.organizationList) || [];
}
