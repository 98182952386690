import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { withTracking } from '../../../shared/analytics';
import withTour from '../../../shared/components/tour/WithTour';
import * as NavContextActions from '../../../shared/components/nav-context/NavContextActions';
import CanaryLoadingIndicator from '../../../shared/components/CanaryLoadingIndicator';
import buildViewBar from '../../../shared/components/table/view-manager/ViewBar';
import buildViewControls from '../../../shared/components/table/view-manager/ViewControls';
import buildViewManagerActions from '../../../shared/components/table/view-manager/ViewManagerActions';
import { FilterType, ViewCategory } from '../../../shared/components/table/view-manager/ViewManagerConstants';
import { TablesTour } from '../../UserTours';
import ErrorMessage from '../../../shared/components/error-message/ErrorMessage';
import { navToIssue } from '../../sites/actions';
import IssueTable from '../components/IssueTable';
import { IssueTableColumns } from '../IssueListConstants';
import IssueListSelectors, { IssueListTableSelectors, IssueListViewManagerSelectors } from '../IssueListSelectors';
import ActionTypes from '../IssueListActionTypes';
import * as actions from '../actions';
import NewIssueButton from '../../sites/components/new-issue-button/NewIssueButton';

function pluralize(issues) {
  return issues && issues.length === 1 ? 'Issue' : 'Issues';
}

const viewManagerActions = buildViewManagerActions(ActionTypes, IssueListViewManagerSelectors, ViewCategory.Issue);
const IssueFilterTypes = [FilterType.Customer, FilterType.IssueType, FilterType.IssueWorkflowStatus, FilterType.State, FilterType.Site, FilterType.Label];
const IssueViewBar = buildViewBar(viewManagerActions, IssueListSelectors, IssueListViewManagerSelectors, IssueFilterTypes);
const IssueViewControls = buildViewControls(viewManagerActions, IssueListSelectors, IssueListViewManagerSelectors, IssueTableColumns);

class IssueList extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.getIssues = this.getIssues.bind(this);
    this.handleIssueClick = this.handleIssueClick.bind(this);
  }

  componentDidMount() {
    this.props.clearNavContext();
  }

  getIssues() {
    return this.props.getIssues().then(() => this.props.startTour());
  }

  handleIssueClick(issue) {
    this.props.navToIssue(issue, this.props.filteredIssues);
  }

  render() {
    if (this.props.error) {
      return (
        <div className="mt-2">
          <ErrorMessage error={this.props.error} />
        </div>
      );
    }

    return (
      <>
        <Row className="mb-2">
          <Col md={6}>
            <h5 className="mb-0">
              {this.renderTitle()}
              {this.props.isRefreshing ? <CanaryLoadingIndicator inline /> : null}
            </h5>
          </Col>
          <Col md={6} className="d-md-flex">
            <IssueViewControls onChange={this.getIssues} className="flex-grow-1" />
            <NewIssueButton onIssueCreated={this.props.navToIssue} className="ml-0 ml-md-2 flex-shrink-0 w-100 w-md-auto" />
          </Col>
        </Row>
        <IssueViewBar onChange={this.getIssues} />
        {this.renderTable()}
      </>
    );
  }

  renderTitle() {
    if (this.props.isRefreshing || this.props.isLoading) {
      return null;
    }
    const count = (this.props.issues && this.props.issues.length) || 0;
    return `${count.toLocaleString()} ${pluralize(this.props.issues)}`;
  }

  renderTable() {
    if (this.props.isLoading || this.props.isRefreshing) {
      return <CanaryLoadingIndicator />;
    }

    return (
      <IssueTable
        dataLoading={this.props.isLoading}
        issues={this.props.filteredIssues}
        columns={this.props.columns}
        sortColumnId={this.props.sortColumnId}
        sortDirection={this.props.sortDirection}
        onSortChange={this.props.changeSort}
        currentPage={this.props.currentPage}
        onPageChange={this.props.changePage}
        pageSize={this.props.pageSize}
        pageCount={this.props.pageCount}
        onIssueSelected={this.handleIssueClick}
      />
    );
  }
}

IssueList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  issues: PropTypes.array,
  filteredIssues: PropTypes.array,

  getIssues: PropTypes.func.isRequired,
  navToIssue: PropTypes.func.isRequired,
  clearNavContext: PropTypes.func.isRequired,

  // filters and columns prefs
  columns: PropTypes.array.isRequired,
  sortColumnId: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  changeSort: PropTypes.func.isRequired,

  // table pagination support
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,

  // feature tour
  startTour: PropTypes.func.isRequired,
};

IssueList.defaultProps = {
  error: null,
  issues: [],
  filteredIssues: [],
};

function mapStateToProps(state) {
  return {
    isLoading: IssueListSelectors.isLoading(state),
    isRefreshing: IssueListSelectors.isRefreshing(state),
    error: IssueListSelectors.error(state),
    issues: IssueListSelectors.list(state),
    filteredIssues: IssueListTableSelectors.filteredList(state),
    columns: IssueListViewManagerSelectors.selectedColumns(state),
    sortColumnId: IssueListTableSelectors.sortColumnId(state),
    sortDirection: IssueListTableSelectors.sortDirection(state),
    currentPage: IssueListTableSelectors.currentPage(state),
    pageSize: IssueListTableSelectors.pageSize(state),
    pageCount: IssueListTableSelectors.pageCount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getIssues: () => dispatch(actions.getIssues()),

    navToIssue: (issue, list) => dispatch(navToIssue(issue, list)),
    clearNavContext: () => dispatch(NavContextActions.clearContext()),

    // table pagination
    changeSort: (sortColumnId, sortDirection) => dispatch(actions.changeSort(sortColumnId, sortDirection)),
    changePage: page => dispatch(actions.changePage(page)),
  };
}

export default withTour(TablesTour.tourName, TablesTour.steps)(withTracking('Issue List')(connect(mapStateToProps, mapDispatchToProps)(IssueList)));
