import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { ExportClient } from '../../../client';
import { PresentAlert } from '../../../shared/ToastUtils';
import OrganizationPermissions from '../../../organization-permissions';
import { HasPermissionAnySite } from '../../../authentication';
import TermSearchSiteAutoSuggest from '../../../shared/components/site-picker/containers/TermSearchSiteAutoSuggest';
import OrganizationDropdown from '../../../shared/components/organization-dropdown/components/OrganizationDropdown';

const siteSelectionType = Object.freeze({
  allSites: 'allSites',
  allSitesSpecificCustomer: 'allSiteSpecificCustomer',
  specificSites: 'specificSites',
});

const initialState = Object.freeze({
  sites: [],
  startDate: undefined,
  endDate: undefined,
  includeIssues: false,
  includeIssuesIssueDetails: false,
  includeIssuesLogs: false,
  includeIssuesComments: false,
  includeIssuesAttachments: false,
  includeMonthlyReg: false,
  includeAtgSettings: false,
  includeInventory: false,
  includeDelivery: false,
  siteSelectionType: siteSelectionType.allSites,
});

class ExportRequest extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.state = initialState;

    this.addStationOrState = this.addStationOrState.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleAlarmHistory = this.handleAlarmHistory.bind(this);
    this.handleAnalysisRecommendationsUrgency = this.handleAnalysisRecommendationsUrgency.bind(this);
    this.handleAttachedFiles = this.handleAttachedFiles.bind(this);
    this.handleComments = this.handleComments.bind(this);
    this.handleLogs = this.handleLogs.bind(this);
    this.handleMonthlyCompliance = this.handleMonthlyCompliance.bind(this);
    this.handleATGSettings = this.handleATGSettings.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleInventory = this.handleInventory.bind(this);
    this.handleDelivery = this.handleDelivery.bind(this);
    this.handleSiteSelection = this.handleSiteSelection.bind(this);
    this.handleSelectedCustomerChange = this.handleSelectedCustomerChange.bind(this);
  }

  addStationOrState(selected) {
    const sites = [...this.state.sites];
    sites.push(selected);
    this.setState({ sites });
  }

  removeStationOrState(index) {
    const sites = [...this.state.sites];
    sites.splice(index, 1);
    this.setState({ sites });
  }

  handleStartDateChange(date) {
    this.setState({ startDate: date || undefined });
  }

  handleEndDateChange(date) {
    this.setState({ endDate: date || undefined });
  }

  handleAlarmHistory(e) {
    if (e.target.checked) {
      this.setState({ includeIssues: true });
    }
    else {
      const update = {
        includeIssues: false,
        includeIssuesIssueDetails: false,
        includeIssuesAttachments: false,
        includeIssuesComments: false,
        includeIssuesLogs: false,
      };
      this.setState(update);
    }
  }

  updateIssueProperty(propertyName, e) {
    let update;
    if (e.target.checked) {
      update = {
        includeIssues: true,
      };
      update[propertyName] = true;
    }
    else {
      update = {};
      update[propertyName] = false;
    }
    this.setState(update);
  }

  handleAnalysisRecommendationsUrgency(e) {
    this.updateIssueProperty('includeIssuesIssueDetails', e);
  }

  handleAttachedFiles(e) {
    this.updateIssueProperty('includeIssuesAttachments', e);
  }

  handleComments(e) {
    this.updateIssueProperty('includeIssuesComments', e);
  }

  handleLogs(e) {
    this.updateIssueProperty('includeIssuesLogs', e);
  }

  handleMonthlyCompliance(e) {
    this.setState({ includeMonthlyReg: e.target.checked });
  }

  handleATGSettings(e) {
    this.setState({ includeAtgSettings: e.target.checked });
  }

  handleExport(e) {
    e.preventDefault();

    const include = {
      monthlyReg: this.state.includeMonthlyReg,
      atgSettings: this.state.includeAtgSettings,
      inventory: this.state.includeInventory,
      delivery: this.state.includeDelivery,
    };

    if (this.state.includeIssues) {
      include.issues = {
        issueDetails: this.state.includeIssuesIssueDetails,
        attachments: this.state.includeIssuesAttachments,
        comments: this.state.includeIssuesComments,
        logs: this.state.includeIssuesLogs,
      };
    }

    let sites = this.state.sites;
    if (this.state.siteSelectionType === siteSelectionType.allSites || this.state.siteSelectionType === siteSelectionType.allSitesSpecificCustomer) {
      sites = this.props.sites;
    }

    const options = {
      sites: sites.map(current => current.siteName || current.nickname),
      startDate: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : undefined,
      endDate: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : undefined,
      include,
    };

    ExportClient.create(options)
      .then(() => {
        this.setState(initialState);
        console.info('Export request posted.');
      })
      .catch((err) => {
        console.error('Failed to post export request.', err);
        PresentAlert('Export request failed');
      });
  }

  handleInventory(e) {
    this.setState({ includeInventory: e.target.checked });
  }

  handleDelivery(e) {
    this.setState({ includeDelivery: e.target.checked });
  }

  isExportDisabled() {
    let siteSelectionCondition;

    if (this.state.siteSelectionType === siteSelectionType.allSites) {
      siteSelectionCondition = true;
    }
    else if (this.state.siteSelectionType === siteSelectionType.allSitesSpecificCustomer) {
      siteSelectionCondition = !!(this.props.selectedCustomerId);
    }
    else if (this.state.siteSelectionType === siteSelectionType.specificSites) {
      siteSelectionCondition = (this.state.sites && this.state.sites.length);
    }
    return !siteSelectionCondition || !this.state.startDate
      || (!this.state.includeIssues && !this.state.includeMonthlyReg && !this.state.includeAtgSettings && !this.state.includeInventory && !this.state.includeDelivery);
  }

  handleSiteSelection(e) {
    const siteSelection = e.target.value;
    this.setState({
      siteSelectionType: siteSelection,
      sites: [],
    },
    () => this.props.updateCustomerId(undefined));
  }

  handleSelectedCustomerChange(customer) {
    this.props.updateCustomerId(customer.id);
  }

  render() {
    return (
      <Row className="mt-4">
        <Col md={4}>
          <h5 className="mb-4">Select Site(s)</h5>
          <div className="form-inline">
            <div className="custom-control custom-radio">
              <input
                id={siteSelectionType.allSites}
                type="radio"
                className="custom-control-input"
                checked={this.state.siteSelectionType === siteSelectionType.allSites}
                onChange={this.handleSiteSelection}
                value={siteSelectionType.allSites}
              />
              <label htmlFor={siteSelectionType.allSites} className="custom-control-label">All Sites</label>
            </div>
            { this.props.customers.length > 1 ? (
              <div className="ml-4 custom-control custom-radio inline">
                <input
                  id={siteSelectionType.allSitesSpecificCustomer}
                  type="radio"
                  className="custom-control-input"
                  checked={this.state.siteSelectionType === siteSelectionType.allSitesSpecificCustomer}
                  onChange={this.handleSiteSelection}
                  value={siteSelectionType.allSitesSpecificCustomer}
                />
                <label htmlFor={siteSelectionType.allSitesSpecificCustomer} className="custom-control-label">Specific Customer</label>
              </div>
            ) : null }
            <div className="ml-4 custom-control custom-radio inline">
              <input
                id={siteSelectionType.specificSites}
                type="radio"
                className="custom-control-input"
                checked={this.state.siteSelectionType === siteSelectionType.specificSites}
                onChange={this.handleSiteSelection}
                value={siteSelectionType.specificSites}
              />
              <label htmlFor={siteSelectionType.specificSites} className="custom-control-label">Specific Sites</label>
            </div>
          </div>
          { this.state.siteSelectionType === siteSelectionType.allSitesSpecificCustomer ? (
            <div>
              <OrganizationDropdown selectedOrganization={this.props.selectedCustomerId} onOrganizationSelect={this.handleSelectedCustomerChange} organizationList={this.props.customers} />
            </div>
          ) : null}
          { this.state.siteSelectionType === siteSelectionType.specificSites
            ? (
              <div>
                <TermSearchSiteAutoSuggest
                  id="export_site_search"
                  excludedSites={this.state.sites}
                  onSiteSelected={this.addStationOrState}
                  placeHolderText="Enter site name..."
                  disabled={this.state.allSites}
                />
                <div className="col-sm-12 px-0 mt-2 mb-4">
                  <ul className="list-group">
                    {this.state.sites.map((station, index) => (
                      <li key={station.nickname} className="list-group-item d-flex flex-row">
                        {station.nickname}
                        <a className="d-flex ml-auto" onClick={() => this.removeStationOrState(index)}>
                          <span
                            className="text-muted"
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null }
        </Col>
        <Col md={4}>
          <h5 className="mb-4">Choose Report Type</h5>
          <div>
            <div className="col-sm-12">
              <HasPermissionAnySite permission={OrganizationPermissions.IssueView}>
                <div className="form-check">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="ah1"
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.includeIssues}
                      onChange={this.handleAlarmHistory}
                    />
                    <label htmlFor="ah1" className="custom-control-label"><strong>Alarm History</strong></label>
                  </div>
                </div>
                <div className="custom-control">
                  <div className="form-check ml-1" style={{ fontSize: '.85rem' }}>
                    <strong>INCLUDE:</strong>
                  </div>
                </div>
              </HasPermissionAnySite>
              <HasPermissionAnySite permission={OrganizationPermissions.IssueView}>
                <div className="form-check ml-4 mt-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="cci1"
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.includeIssuesIssueDetails}
                      onChange={this.handleAnalysisRecommendationsUrgency}
                    />
                    <label htmlFor="cci1" className="custom-control-label">Analysis &amp; Troubleshooting</label>
                  </div>

                </div>
              </HasPermissionAnySite>

              <HasPermissionAnySite permission={OrganizationPermissions.IssueView}>
                <div className="form-check ml-4">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="af1"
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.includeIssuesAttachments}
                      onChange={this.handleAttachedFiles}
                    />
                    <label htmlFor="af1" className="custom-control-label">Attached files</label>
                  </div>
                </div>
              </HasPermissionAnySite>

              <HasPermissionAnySite permission={OrganizationPermissions.IssueView}>
                <div className="form-check ml-4">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="co1"
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.includeIssuesComments}
                      onChange={this.handleComments}
                    />
                    <label htmlFor="co1" className="custom-control-label">Comments</label>
                  </div>
                </div>
              </HasPermissionAnySite>

              <HasPermissionAnySite permission={OrganizationPermissions.IssueView}>
                <div className="form-check ml-4">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="lo1"
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.includeIssuesLogs}
                      onChange={this.handleLogs}
                    />
                    <label htmlFor="lo1" className="custom-control-label">Logs</label>
                  </div>
                </div>
              </HasPermissionAnySite>

              <HasPermissionAnySite permission={OrganizationPermissions.ComplianceView}>
                <div className="form-check mt-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="mc1"
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.includeMonthlyReg}
                      onChange={this.handleMonthlyCompliance}
                    />
                    <label htmlFor="mc1" className="custom-control-label"><strong>Monthly Compliance </strong></label>
                  </div>
                </div>
              </HasPermissionAnySite>

              <HasPermissionAnySite permission={OrganizationPermissions.SiteList}>
                <div className="form-check mt-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="atg1"
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.includeAtgSettings}
                      onChange={this.handleATGSettings}
                    />
                    <label htmlFor="atg1" className="custom-control-label"><strong>ATG Settings</strong></label>
                  </div>
                </div>
              </HasPermissionAnySite>

              <HasPermissionAnySite permission={OrganizationPermissions.InventoryView}>
                <div className="form-check mt-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="i201"
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.includeInventory}
                      onChange={this.handleInventory}
                    />
                    <label htmlFor="i201" className="custom-control-label"><strong>Fuel Inventory</strong></label>
                  </div>
                </div>
              </HasPermissionAnySite>

              <HasPermissionAnySite permission={OrganizationPermissions.InventoryView}>
                <div className="form-check mt-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="i202"
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.includeDelivery}
                      onChange={this.handleDelivery}
                    />
                    <label htmlFor="i202" className="custom-control-label"><strong>Fuel Delivery</strong></label>
                  </div>
                </div>
              </HasPermissionAnySite>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <h5 className="mb-4">Select Date Range</h5>
          <div className="form-group">
            <label htmlFor="formGroupExampleInput">Start</label>
            <br />
            <DateTimePicker
              value={this.state.startDate}
              onChange={this.handleStartDateChange}
              max={new Date()}
              time={false}
            />
          </div>
          <div className="form-group">
            <label htmlFor="formGroupExampleInput2">End</label>
            <br />
            <DateTimePicker
              value={this.state.endDate}
              onChange={this.handleEndDateChange}
              max={new Date()}
              time={false}
            />
          </div>
          <Button
            color="primary"
            size="sm"
            className="mt-4 float-right"
            onClick={this.handleExport}
            disabled={this.isExportDisabled()}
          >
            Export
          </Button>
        </Col>
      </Row>
    );
  }
}

ExportRequest.propTypes = {
  customers: PropTypes.array,
  sites: PropTypes.array,
  updateCustomerId: PropTypes.func.isRequired,
  selectedCustomerId: PropTypes.number,
};

ExportRequest.defaultProps = {
  customers: [],
  sites: [],
  selectedCustomerId: undefined,
};

export default ExportRequest;
