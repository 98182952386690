import { WorkflowStatusTypes } from '../../../../../../AppConstants';

export const NextWorkflowStatusTypes = Object.freeze({
  [WorkflowStatusTypes.NewUnread]: WorkflowStatusTypes.AwaitingApprovalUnread,
  [WorkflowStatusTypes.New]: WorkflowStatusTypes.AwaitingApprovalUnread,
  [WorkflowStatusTypes.Draft]: WorkflowStatusTypes.AwaitingApprovalUnread,
  [WorkflowStatusTypes.AwaitingApprovalUnread]: WorkflowStatusTypes.PendingResolution,
  [WorkflowStatusTypes.AwaitingApproval]: WorkflowStatusTypes.PendingResolution,
  [WorkflowStatusTypes.PendingResolution]: WorkflowStatusTypes.Resolved,
});

export const OperationState = {
  Ready: 'ready',
  Loading: 'loading',
  Saving: 'saving',
  Commenting: 'commenting',
};
