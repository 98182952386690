export const ExportActionTypes = {
  RECEIVE_EXPORTS: 'exports/receive_exports',
  SET_CUSTOMER_ID: 'exports/set_customer_id',
};

export const ExportSiteListActionTypes = {
  REFRESH_LIST_STARTED: 'exports/site-list/refresh_list_started',
  REFRESH_LIST_CANCELED: 'exports/site-list/refresh_list_canceled',
  REFRESH_LIST_FAILED: 'exports/site-list/refresh_list_failed',

  GET_LIST_STARTED: 'exports/site-list/get_list_started',
  RECEIVE_LIST: 'exports/site-list/receive_list',
  GET_LIST_FAILED: 'exports/site-list/get_list_failed',
};
