import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import range from 'lodash/range';

const PageSelect = ({ id, name, className, disabled, currentPage, pageCount, onPageChange }) => {
  const options = range(1, pageCount + 1);
  return (
    <Input
      type="select"
      id={id}
      name={name}
      className={className}
      value={currentPage}
      bsSize="sm"
      disabled={disabled}
      onChange={event => onPageChange(Number.parseInt(event.target.value, 10))}
      style={{ width: '5rem' }}
    >
      {options.map(num => (
        <option key={num} value={num}>{num}</option>
      ))}
    </Input>
  );
};

PageSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

PageSelect.defaultProps = {
  id: null,
  name: null,
  className: null,
  disabled: false,
};

export default PageSelect;
