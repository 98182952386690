import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as selectors from '../AuthenticationSelectors';

class HasPermissionAnyOrgSite extends Component {
  render() {
    if (this.props.isAuthorized) {
      return this.props.children;
    }
    return null;
  }
}

HasPermissionAnyOrgSite.propTypes = {
  customerId: PropTypes.number,
  permission: PropTypes.number,
  children: PropTypes.element,
  isAuthorized: PropTypes.bool.isRequired,
};

HasPermissionAnyOrgSite.defaultProps = {
  customerId: null,
  permission: null,
  children: null,
};

function mapStateToProps(state, ownProps) {
  return {
    isAuthorized: selectors.hasPermissionAnyOrgSite(state, ownProps.customerId, ownProps.permission)
  };
}

export default connect(mapStateToProps)(HasPermissionAnyOrgSite);
