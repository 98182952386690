import ActionTypes from './NavContextActionTypes';

export default function NavContextReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.RECEIVE_CONTEXT:
      return {
        urls: action.urls,
        index: action.index,
        originType: action.originType,
        originUrl: action.originUrl,
      };
    case ActionTypes.PREV:
      return Object.assign({}, state, {
        index: (state.index > 0) ? state.index - 1 : state.index,
      });
    case ActionTypes.NEXT:
      return Object.assign({}, state, {
        index: (state.index < state.urls.length - 1) ? state.index + 1 : state.index,
      });
    case ActionTypes.CLEAR_CONTEXT:
      return {};
    default:
      return state;
  }
}
