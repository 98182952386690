import { ComplianceClient } from '../../../../../../client';
import { createSiteAction } from '../../../../SiteActionCreatorFactory';
import ActionTypes from './ComplianceListActionTypes';
import * as selectors from './ComplianceListSelectors';

export const getComplianceHistory = createSiteAction(
  selectors.isComplianceHistoryLoading,
  ActionTypes.GET_COMPLIANCE_HISTORY_STARTED,
  ActionTypes.RECEIVE_COMPLIANCE_HISTORY,
  ActionTypes.GET_COMPLIANCE_HISTORY_FAILED,
  ComplianceClient.getSiteRegulatoryReports
);
