import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import TermSearchSiteAutoSuggest from './TermSearchSiteAutoSuggest';

class SingleSiteInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.handleSiteSelect = this.handleSiteSelect.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.editing && this.state.editing) {
      const element = document.getElementById(this.props.id);
      if (element) {
        element.focus();
      }
    }
  }

  handleEditClick() {
    this.props.onEditStart();
    this.setState({ editing: true });
  }

  cancelEdit() {
    this.props.onEditEnd();
    this.setState({ editing: false });
  }

  handleSiteSelect(site) {
    this.props.onSiteSelect(site);
    this.props.onEditEnd();
    this.setState({
      editing: false,
    });
  }

  render() {
    return (
      <div>
        {this.state.editing ? this.renderEdit() : this.renderView()}
      </div>
    );
  }

  renderSearchButton() {
    return (
      <div className="form-control form-control-sm border-left-0 w-auto bg-gray-200" onClick={this.handleEditClick} style={{ borderRadius: '0 0.25rem 0.25rem 0', cursor: 'pointer' }}>
        <span>Search</span>
      </div>
    );
  }

  renderView() {
    const text = this.props.site ? this.props.site.nickname : 'No site selected';
    if (this.props.disabled) {
      return <Input disabled value={text} />;
    }
    return (
      <div className="d-flex">
        <div id={this.props.id} className="form-control form-control-sm w-auto flex-grow-1" onClick={this.handleEditClick} style={{ borderRadius: '0.25rem 0 0 0.25rem', cursor: 'text' }}>
          {this.props.hideNoSiteSelectedText ? <span className="text-gray-400">{this.props.placeHolderText}</span> : <span>{text}</span> }
        </div>
        {this.props.hideSearchButton ? null : this.renderSearchButton()}
      </div>
    );
  }

  renderEdit() {
    return (
      <TermSearchSiteAutoSuggest
        id={this.props.id}
        groupByCustomer={this.props.groupByCustomer}
        onSiteSelected={this.handleSiteSelect}
        customerId={this.props.customerId}
        sitePermission={this.props.sitePermission}
        feature={this.props.feature}
        excludedSites={this.props.excludedSites}
        onBlur={this.cancelEdit}
        placeHolderText={this.props.placeHolderText}
        disabled={this.props.disabled}
      />
    );
  }
}

SingleSiteInput.propTypes = {
  id: PropTypes.string.isRequired,
  hideNoSiteSelectedText: PropTypes.bool,
  hideSearchButton: PropTypes.bool,
  groupByCustomer: PropTypes.bool,
  site: PropTypes.object,
  onSiteSelect: PropTypes.func.isRequired,
  onEditStart: PropTypes.func,
  onEditEnd: PropTypes.func,
  customerId: PropTypes.number,
  sitePermission: PropTypes.number,
  feature: PropTypes.string,
  excludedSites: PropTypes.array,
  placeHolderText: PropTypes.string,
  disabled: PropTypes.bool,
};

SingleSiteInput.defaultProps = {
  hideNoSiteSelectedText: false,
  hideSearchButton: false,
  groupByCustomer: false,
  site: null,
  onEditStart: () => {},
  onEditEnd: () => {},
  customerId: null,
  sitePermission: null,
  feature: null,
  excludedSites: null,
  placeHolderText: 'Go to site...',
  disabled: false,
};

export default SingleSiteInput;
