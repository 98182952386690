import React from 'react';
import PropTypes from 'prop-types';
import { gray900 } from '../../../../scss/_variables.scss';
import { RegulatoryStatus } from '../../../AppConstants';

const StatusText = ({ status, calculatedStatusOverridden }) => {
  const normalizedStatus = RegulatoryStatus[status === 'LATE' ? 'PASSED' : status];
  const color = calculatedStatusOverridden ? gray900 : normalizedStatus.color;
  return (
    <span className="text-uppercase" style={{ color }}>{normalizedStatus.text}</span>
  );
};

StatusText.propTypes = {
  status: PropTypes.string.isRequired,
  calculatedStatusOverridden: PropTypes.bool,
};

StatusText.defaultProps = {
  calculatedStatusOverridden: false,
};

export default StatusText;
