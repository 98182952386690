import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, Row } from 'reactstrap';

import CanaryLoadingIndicator from '../../../../shared/components/CanaryLoadingIndicator';
import ErrorMessage from '../../../../shared/components/error-message/ErrorMessage';
import { StatefulTable, SortDirection, StringColumn, TimestampColumn } from '../../../../shared/components/table';
import CanarySummaryBar from '../../../../shared/components/CanarySummaryBar';
import selectors from '../IngestFailureSelectors';
import { SubsystemTypes } from '../IngestFailureConstants';
import * as actions from '../actions';

class IngestFailureList extends Component {
  componentDidMount() {
    this.props.getIngestFailuresList();
  }

  render() {
    if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }
    else if (this.props.error) {
      return <ErrorMessage title="Failed to load ingest failure list" error={this.props.error} />;
    }

    const summary = `${this.props.ingestFailures.length} Ingest Failures`;
    return (
      <>
        <Row>
          <Col xs={8}>
            <CanarySummaryBar summary={summary} />
          </Col>
          <Col xs={4}>
            <Form inline>
              <select value={this.props.selectedSubsystem} className="form-control form-control-sm ml-auto" onChange={e => this.props.setListFilter(e.target.value)}>
                <option value={SubsystemTypes.All}>All</option>
                <option value={SubsystemTypes.ATGIngest}>ATG Ingest</option>
                <option value={SubsystemTypes.Dispatch}>Dispatch</option>
                <option value={SubsystemTypes.MVI}>MVI</option>
              </select>
            </Form>
          </Col>
        </Row>

        <StatefulTable
          initialSortColumn="created"
          initialSortDirection={SortDirection.Desc}
          data={this.props.ingestFailures}
          onRowClick={this.props.navToIngestFailure}
        >
          <StringColumn title="Id" property="id" width="6rem" />
          <StringColumn title="Source" property="source" sortable width="20%" />
          <StringColumn title="Subsystem" property="subsystem" sortable width="6rem" />
          <StringColumn title="Customer" columnId="customerName" property="customerName" sortable width="20%" />
          <StringColumn title="Description" property="description" sortable width="60%" />
          <TimestampColumn title="Created" property="created" sortable width="13rem" />
        </StatefulTable>
      </>
    );
  }
}

IngestFailureList.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  ingestFailures: PropTypes.array.isRequired,
  selectedSubsystem: PropTypes.string,
  getIngestFailuresList: PropTypes.func.isRequired,
  setListFilter: PropTypes.func.isRequired,
  navToIngestFailure: PropTypes.func.isRequired,
};

IngestFailureList.defaultProps = {
  error: null,
  selectedSubsystem: 'All',
};

function mapStateToProps(state) {
  return {
    loading: selectors.isLoading(state),
    error: selectors.error(state),
    ingestFailures: selectors.filteredList(state),
    selectedSubsystem: selectors.selectedSubsystem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getIngestFailuresList: () => dispatch(actions.getIngestFailuresList()),
    setListFilter: subsystem => dispatch(actions.setListFilter(subsystem)),
    navToIngestFailure: ingestFailure => dispatch(actions.navToIngestFailure(ingestFailure)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IngestFailureList);
