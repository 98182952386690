import { ComplianceClient } from '../../../../../client';
import { createSiteAction } from '../../../SiteActionCreatorFactory';
import ActionTypes from '../ComplianceActionTypes';
import * as selectors from '../ComplianceSelectors';

export const getLatestCompliance = createSiteAction(
  selectors.isLatestComplianceLoading,
  ActionTypes.GET_LATEST_REPORT_STARTED,
  ActionTypes.RECEIVE_LATEST_REPORT,
  ActionTypes.GET_LATEST_REPORT_FAILED,
  ComplianceClient.getLatestReport
);
