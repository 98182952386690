import moment from 'moment';

export const RgbColors = {
  Red: {
    r: 191,
    g: 63,
    b: 63,
  },
  Green: {
    r: 63,
    g: 191,
    b: 63,
  },
  Blue: {
    r: 63,
    g: 63,
    b: 191,
  },
};

export const LineChartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      ticks: {
        suggestedMin: 0,
      },
    }],
  },
};

export function formatHour(hour) {
  if (hour === 0) {
    return '12 AM';
  }
  else if (hour < 12) {
    return `${hour} AM`;
  }
  if (hour === 12) {
    return '12 PM';
  }
  return `${hour - 12} PM`;
}

export function formatDay(date) {
  return moment(date).format('M/D/YY');
}

export function buildLineChartDataset(label, rgbColor, yAxisData) {
  const backgroundColor = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.4)`;
  const color = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 1)`;

  return {
    label,
    fill: false,
    lineTension: 0.1,
    backgroundColor,
    borderColor: color,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: color,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: yAxisData,
  };
}
