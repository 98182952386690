import { AuthenticationSelectors } from '../../../../../../authentication';
import OrganizationPermissions from '../../../../../../organization-permissions';
import { WorkflowStatusTypes } from '../../../../../../AppConstants';
import { OperationState } from './IssueEditConstants';

export function canEditIssue(state, issue) {
  const result = issue
    && issue.workflowStatus !== WorkflowStatusTypes.Resolved
    && issue.customerId
    && issue.siteId
    && AuthenticationSelectors.hasPermission(state, issue.customerId, issue.siteId, OrganizationPermissions.IssueEdit);
  return !!result;
}

function editRoot(state) {
  return (state && state.sites && state.sites.issues && state.sites.issues.edit && state.sites.issues.edit.root) || {};
}

export function currentIssue(state) {
  return editRoot(state).currentIssue || null;
}

export function canEditCurrentIssue(state) {
  return canEditIssue(state, currentIssue(state));
}

export function failedIssueId(state) {
  return editRoot(state).failedIssueId || null;
}

export function error(state) {
  return editRoot(state).error || null;
}

function operationState(state) {
  return editRoot(state).operationState;
}

export function isLoading(state) {
  return operationState(state) === OperationState.Loading;
}

export function isSaving(state) {
  return operationState(state) === OperationState.Saving;
}
