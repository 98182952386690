import ModalActionTypes from './ModalActionTypes';

export const openModal = (title, site, inspectionDetails) => ({
  type: ModalActionTypes.OPEN_MODAL,
  title,
  site,
  inspectionDate: inspectionDetails && inspectionDetails.inspectionDate,
  inspectorCompany: inspectionDetails && inspectionDetails.inspectorCompany,
  inspectorName: inspectionDetails && inspectionDetails.inspectorName,
});

export const closeModal = () => ({ type: ModalActionTypes.CLOSE_MODAL });

export const editSite = site => ({ type: ModalActionTypes.RECEIVE_SITE, site });

export const editInspectionDate = inspectionDate => ({ type: ModalActionTypes.RECEIVE_INSPECTION_DATE, inspectionDate });

export const editInspectorCompany = inspectorCompany => ({ type: ModalActionTypes.RECEIVE_INSPECTOR_COMPANY, inspectorCompany });

export const editInspectorName = inspectorName => ({ type: ModalActionTypes.RECEIVE_INSPECTOR_NAME, inspectorName });
