import { Get, Patch, Put, Post, Delete } from '../HttpUtils';

export default {
  getOrganizationOverviews() {
    return Get('/v2/organizations/overviews').then(response => response.data);
  },
  getList() {
    return Get('/v2/organizations?include=id,name,customer').then(response => response.data);
  },
  getById(id) {
    return Get(`/v2/organizations/${id}?include=id,name,address,city,state,zip,phone,logo,domainName,created,enabled,customer,memberships,groups,policies,receivedPolicies`).then(response => response.data);
  },
  patch(id, patchData) {
    return Patch(`/v2/organizations/${id}`, patchData)
      .then(response => response.data);
  },
  save(organization) {
    if (organization.id) {
      return Put(`/v2/organizations/${organization.id}`, organization)
        .then(response => response.data);
    }
    return Post('/v2/organizations', organization)
      .then(response => response.data);
  },
  deleteById(organization) {
    return Delete(`/v2/organizations/${organization.id}`)
      .then(response => response.data);
  },
  getPermissionSets() {
    return Get('/v2/organizations/permission-sets').then(response => response.data);
  },
};
