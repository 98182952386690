import { buildStringSort, buildEnumSort, buildNumberSort } from '../../shared/SortUtils';
import { IssuePriorities, IssuePriorityLabels, IssueTypes, WorkflowStatusTypes } from '../../AppConstants';
import { IssueFields } from '../../client';

export const IssueTableColumns = {
  Priority: {
    id: 'priority',
    name: 'Priority',
    shortName: 'Priority',
    apiFields: [IssueFields.Priority],
    exportFunc: issue => IssuePriorityLabels[issue.priority] || '',
    sortFunc: buildEnumSort('priority', IssuePriorities.LOW, IssuePriorities.NORMAL, IssuePriorities.EMERGENCY),
  },
  Customer: {
    id: 'customer',
    name: 'Customer name',
    shortName: 'Customer',
    apiFields: [IssueFields.Customer],
    exportFunc: issue => (issue.customer && issue.customer.name) || '',
    sortFunc: buildStringSort('customer.name'),
  },
  SiteNickname: {
    id: 'siteNickname',
    name: 'Site',
    shortName: 'Site',
    apiFields: [IssueFields.Site],
    exportFunc: issue => (issue.site && issue.site.nickname) || '',
    sortFunc: buildStringSort('site.nickname'),
  },
  SiteState: {
    id: 'siteState',
    name: 'State',
    shortName: 'State',
    apiFields: [IssueFields.Site],
    exportFunc: issue => (issue.site && issue.site.state) || '',
    sortFunc: buildStringSort('site.state'),
  },
  IssueType: {
    id: 'issueType',
    name: 'Issue type',
    shortName: 'Type',
    apiFields: [IssueFields.IssueType],
    exportFunc: issue => IssueTypes[issue.issueType],
    sortFunc: buildStringSort('issueType'),
  },
  Id: {
    id: 'id',
    name: 'Issue ID',
    shortName: 'Issue',
    apiFields: [IssueFields.Id],
    sortFunc: buildNumberSort('id'),
  },
  Description: {
    id: 'description',
    name: 'Description',
    shortName: 'Description',
    apiFields: [IssueFields.Description],
    exportFunc: issue => issue.description,
    sortFunc: buildStringSort('description'),
  },
  Created: {
    id: 'created',
    name: 'Date created',
    shortName: 'Created',
    apiFields: [IssueFields.Created],
    sortFunc: buildStringSort('created'),
  },
  DaysIdle: {
    id: 'daysIdle',
    name: 'Days Idle',
    shortName: 'Days Idle',
    apiFields: [IssueFields.LastModified],
    exportFunc: issue => issue.daysIdle,
    sortFunc: buildNumberSort('daysIdle'),
  },
  LatestAlarmDate: {
    id: 'latestAlarmDate',
    name: 'Date and time of latest alarm',
    shortName: 'Last Alarm',
    apiFields: [IssueFields.LatestAlarmDate],
    sortFunc: buildStringSort('latestAlarmDate'),
  },
  WorkflowStatus: {
    id: 'workflowStatus',
    name: 'Issue status',
    shortName: 'Status',
    apiFields: [IssueFields.Workflow],
    exportFunc: issue => (issue.workflow && issue.workflow.status),
    sortFunc: buildEnumSort('workflow.status', ...Object.values(WorkflowStatusTypes)),
  },
  WorkflowStatusDate: {
    id: 'workflowStatusDate',
    name: 'Status date',
    shortName: 'Status date',
    apiFields: [IssueFields.Workflow],
    exportFunc: issue => (issue.workflow && issue.workflow.statusDate),
    sortFunc: buildStringSort('workflow.statusDate'),
  },
  WorkflowUsername: {
    id: 'workflowUsername',
    name: 'Status set by',
    shortName: 'Status set by',
    apiFields: [IssueFields.Workflow],
    exportFunc: issue => (issue.workflow && issue.workflow.user && issue.workflow.user.username),
    sortFunc: buildStringSort('workflow.user.username'),
  },
  LastModifiedDate: {
    id: 'lastModifiedDate',
    name: 'Last updated',
    shortName: 'Updated',
    apiFields: [IssueFields.LastModified],
    exportFunc: issue => issue.lastModified && issue.lastModified.date,
    sortFunc: buildStringSort('lastModified.date'),
  },
  LastModificationType: {
    id: 'lastModificationType',
    name: 'What the update was',
    shortName: 'Update',
    apiFields: [IssueFields.LastModified],
    exportFunc: issue => issue.lastModified && issue.lastModified.modificationType,
    sortFunc: buildStringSort('lastModified.modificationType'),
  },
  LastModifiedUser: {
    id: 'lastModifiedUser',
    name: 'Last updated by',
    shortName: 'Updated by',
    apiFields: [IssueFields.LastModified],
    exportFunc: issue => issue.lastModified && issue.lastModified.user && issue.lastModified.user.username,
    sortFunc: buildStringSort('lastModified.user.username'),
  },
  DispatchStatus: {
    id: 'dispatchStatus',
    name: 'Open work orders',
    shortName: 'Work orders',
    apiFields: [IssueFields.Dispatch],
    exportFunc: issue => issue.dispatch && issue.dispatch.statusDate,
    sortFunc: buildStringSort('dispatch.statusDate'),
  },
  SiteLabels: {
    id: 'siteLabels',
    name: 'Site label',
    shortName: 'Site label',
    apiFields: [IssueFields.SiteLabels],
    exportFunc: issue => {
      if (!issue.siteLabels || !issue.siteLabels.length) {
        return '';
      }
      return issue.siteLabels.map(l => l.labelText).join(' ');
    }
  }
};

export const DefaultTableColumns = [
  IssueTableColumns.Priority,
  IssueTableColumns.Created,
  IssueTableColumns.Description,
  IssueTableColumns.SiteNickname,
  IssueTableColumns.LatestAlarmDate,
  IssueTableColumns.WorkflowStatus,
  IssueTableColumns.LastModifiedDate,
  IssueTableColumns.SiteLabels,
];
