function sliceState(state) {
  return (state && state.sites && state.sites.mviLinkModal) || {};
}

export function isModalOpen(state) {
  return sliceState(state).modalOpen || false;
}

export function isLoading(state) {
  return sliceState(state).loading || false;
}

export function error(state) {
  return sliceState(state).error || null;
}

export function mviFormUrl(state) {
  return sliceState(state).mviFormUrl || null;
}
