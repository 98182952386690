const getState = state => (state && state.shared && state.shared.mviModal) || {};

export function isOpen(state) {
  return getState(state).modalOpen || false;
}

export function title(state) {
  return getState(state).title;
}

export function site(state) {
  return getState(state).site || null;
}

export function inspectionDate(state) {
  return getState(state).inspectionDate || null;
}

export function inspectorCompany(state) {
  return getState(state).inspectorCompany || null;
}

export function inspectorName(state) {
  return getState(state).inspectorName || null;
}
