export function isLoading(state) {
  return (state && state.shared && state.shared.tours && state.shared.tours.loading) || false;
}

export function isSaving(state) {
  return (state && state.shared && state.shared.tours && state.shared.tours.saving) || false;
}

export function error(state) {
  return (state && state.shared && state.shared.tours && state.shared.tours.error) || null;
}

export function tours(state) {
  return (state && state.shared && state.shared.tours && state.shared.tours.tours) || [];
}

export function isResettingTours(state) {
  return (state && state.shared && state.shared.tours && state.shared.tours.resettingTours) || false;
}

export function isToursReset(state) {
  return (state && state.shared && state.shared.tours && state.shared.tours.toursReset) || false;
}
