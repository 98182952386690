import React from 'react';
import PropTypes from 'prop-types';

const FuelHeight = ({ tankInventory }) => {
  if (!tankInventory.fuelHeightInches) {
    return null;
  }
  const fuelHeight = tankInventory.fuelHeightInches.toFixed(2);
  return <span>{`${fuelHeight}\u2033`}</span>;
};

FuelHeight.propTypes = {
  tankInventory: PropTypes.object.isRequired,
};

export default FuelHeight;
