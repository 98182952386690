import React from 'react';
import PropTypes from 'prop-types';

const CanarySummaryBar = ({ summary, controls, headerType, className }) => {
  let actualClassName = 'd-flex flex-row';
  if (className) {
    actualClassName += ` ${className}`;
  }
  const HeaderType = headerType;
  return (
    <div className={actualClassName}>
      <div className="canary-summary mr-2"><HeaderType>{summary}</HeaderType></div>
      <div className="canary-summary canary-summary-controls ml-auto">{controls}</div>
    </div>
  );
};

CanarySummaryBar.propTypes = {
  summary: PropTypes.any,
  controls: PropTypes.any,
  headerType: PropTypes.elementType,
  className: PropTypes.string,
};

CanarySummaryBar.defaultProps = {
  summary: null,
  controls: null,
  headerType: 'h5',
  className: null,
};

export default CanarySummaryBar;
