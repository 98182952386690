import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import moment from 'moment-timezone';

import { Row, Col } from 'reactstrap';

const Alarm = ({ site, alarm, isDragging, connectDragSource }) => {
  const opacity = isDragging ? 0 : 1;

  return connectDragSource((
    <div style={{ border: '1px dashed gray', padding: '0.5rem 1rem', margin: '.5rem', backgroundColor: 'white', cursor: 'move', opacity, lineHeight: '1.0' }}>
      <Row>
        <Col sm={10}>
          <small>{alarm.title}</small>
        </Col>
        {alarm.ids.length > 1 ?
          <Col sm={1}>
            <span className="badge badge-secondary">{alarm.ids.length}</span>
          </Col>
          : null
        }
      </Row>
      <Row>
        <Col sm={12}>
          <span className="text-muted" style={{ fontSize: '.65em' }}>{moment(alarm.alarmDate).tz(site.siteTimezone).format('MM/DD/YY [at] h:mm A z')}</span>
        </Col>
      </Row>
    </div>
  ));
};

const alarmSource = {
  beginDrag(props) {
    return {
      issue: props.issue,
      alarm: props.alarm,
    };
  },
};

Alarm.propTypes = {
  site: PropTypes.shape({
    siteTimezone: PropTypes.string.isRequired,
  }).isRequired,
  issue: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  alarm: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    lastClearedDate: PropTypes.object,
    firstAlarmDate: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    alarmDate: PropTypes.string,
    ids: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
};

export default DragSource('ALARM', alarmSource, (connect, monitor) => ({ connectDragSource: connect.dragSource(), isDragging: monitor.isDragging() }))(Alarm);
