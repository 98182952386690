import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment';
import update from 'immutability-helper';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { track } from '../../../../../../../../shared/analytics';
import { dateAsText } from '../../../../../../../../shared/components/date';
import EditLinkButton from '../../../../../../../../shared/components/buttons/EditLinkButton';
import * as SiteSelectors from '../../../../../../SiteSelectors';
import MviEditSelectors from '../../MviEditSelectors';
import MviReportSelectors from './MviReportSelectors';
import * as actions from './MviReportActions';
import ConfirmModal from '../../../../../../../../shared/components/confirm-modal/ConfirmModal';
import * as MviEditUberSelectors from '../../MviEditUberSelectors';

class InspectionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModalOpen: false,
    };
    this.updateInspectionDate = this.updateInspectionDate.bind(this);
    this.updateInspectorCompany = this.updateInspectorCompany.bind(this);
    this.updateInspectorName = this.updateInspectorName.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.cancelConfirmModal = this.cancelConfirmModal.bind(this);
    this.handleModalSave = this.handleModalSave.bind(this);
  }

  updateInspectionDate(value) {
    const newDate = value ? moment(value).format('YYYY-MM-DD') : null;
    const updatedReport = update(this.props.pendingEdits, {
      inspectionDate: {
        $set: newDate,
      }
    });
    this.props.editReport(updatedReport);
  }

  updateInspectorCompany(e) {
    const updatedReport = update(this.props.pendingEdits, {
      inspectorCompany: {
        $set: e.target.value || '',
      }
    });
    this.props.editReport(updatedReport);
  }

  updateInspectorName(e) {
    const updatedReport = update(this.props.pendingEdits, {
      inspectorName: {
        $set: e.target.value || '',
      }
    });
    this.props.editReport(updatedReport);
  }

  handleSave() {
    if (this.pendingEditsMoveReport()) {
      this.openConfirmModal();
    }
    else {
      track('Inspection', {
        Component: 'Report header',
        'Inspection action': 'Save header',
      });
      this.props.savePendingEdits();
    }
  }

  openConfirmModal() {
    const source = `${this.props.mvi.monthText} ${this.props.mvi.year}`;
    const destination = moment(this.props.pendingEdits.inspectionDate).format('MMMM YYYY');
    const text = `Are you sure you want to move this report from ${source} to ${destination}?`;
    this.setState({
      confirmModalOpen: true,
      confirmModalText: text,
    });
  }

  cancelConfirmModal() {
    this.setState({
      confirmModalOpen: false,
      confirmModalText: null,
    });
  }

  handleModalSave() {
    this.cancelConfirmModal();
    const pendingEdits = this.props.pendingEdits;
    const moveRequest = {
      siteId: this.props.mvi.siteId,
      inspectionDate: pendingEdits.inspectionDate,
      inspectorCompany: pendingEdits.inspectorCompany || null,
      inspectorName: pendingEdits.inspectorName || null,
    };
    track('Inspection', {
      Component: 'Report header',
      'Inspection action': 'Move report',
    });
    this.props.moveReport(moveRequest);
  }

  pendingEditsMoveReport() {
    const inspectionDate = this.props.pendingEdits && this.props.pendingEdits.inspectionDate;
    if (!inspectionDate) {
      return false;
    }

    const min = this.getMinInspectionDate();
    const max = this.getMaxInspectionDate();
    const inspectionDateMoment = moment(inspectionDate);
    return inspectionDateMoment.isBefore(min) || inspectionDateMoment.isAfter(max);
  }

  getMinInspectionDate() {
    const { year, monthText } = this.props.mvi;
    return moment().year(year).month(monthText).date(1)
      .startOf('month');
  }

  getMaxInspectionDate() {
    return moment(this.getMinInspectionDate()).endOf('month');
  }

  canSave() {
    return this.props.pendingEdits && this.props.pendingEdits.inspectionDate;
  }

  render() {
    if (this.props.isEditing) {
      return this.renderEdit();
    }
    return this.renderView();
  }

  renderEdit() {
    const currentDate = this.props.pendingEdits.inspectionDate ? moment(this.props.pendingEdits.inspectionDate).toDate() : null;
    return (
      <>
        <Form inline>
          <FormGroup className="mr-2 my-2">
            <Label for="inspectionDate" className="mr-2">Inspected on:</Label>
            <DateTimePicker
              name="inspectionDate"
              time={false}
              value={currentDate}
              onChange={this.updateInspectionDate}
              style={{ maxWidth: '10em' }}
            />
          </FormGroup>
          <FormGroup className="mr-2 my-2">
            <Label for="inspectorCompany" className="mr-2">Company:</Label>
            <Input name="inspectorCompany" id="inspectorCompany" placeholder="Company" className="mr-2" value={this.props.pendingEdits.inspectorCompany || ''} onChange={this.updateInspectorCompany} />
          </FormGroup>
          <FormGroup className="mr-2 my-2">
            <Label for="inspectorName" className="mr-2">Inspector name:</Label>
            <Input name="inspectorName" id="inspectorName" placeholder="Inspector" value={this.props.pendingEdits.inspectorName || ''} onChange={this.updateInspectorName} />
          </FormGroup>
          <FormGroup className="my-2">
            <Button size="sm" color="primary" className="mr-2" onClick={this.handleSave} disabled={!this.canSave()}>Save</Button>
            <Button size="sm" color="primary" outline onClick={this.props.cancelPendingEdits}>Cancel</Button>
          </FormGroup>
        </Form>
        <ConfirmModal
          isOpen={this.state.confirmModalOpen}
          headerText="Move MVI report"
          body={this.state.confirmModalText}
          onCancel={this.cancelConfirmModal}
          onConfirm={this.handleModalSave}
        />
      </>
    );
  }

  renderView() {
    return (
      <div>
        <span className="mr-2">Inspected on:</span>
        <span>{dateAsText(this.props.currentReport.inspectionDate)}</span>
        {this.props.currentReport.inspectorCompany || this.props.currentReport.inspectorName
          ? <span className="ml-3">Inspected by:</span>
          : null}
        {this.props.currentReport.inspectorCompany
          ? <span className="ml-2">{this.props.currentReport.inspectorCompany}</span>
          : null}
        {this.props.currentReport.inspectorName
          ? <span className="ml-2">{this.props.currentReport.inspectorName}</span>
          : null}
        {this.props.canEdit
          ? <EditLinkButton className="ml-3" onClick={this.props.beginReportEdit} disabled={this.props.editDisabled} />
          : null}
      </div>
    );
  }
}

InspectionDetails.propTypes = {
  mvi: PropTypes.object.isRequired,
  currentReport: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mviId: PropTypes.number.isRequired,
    inspectorCompany: PropTypes.string,
    inspectorName: PropTypes.string,
    inspectionDate: PropTypes.string.isRequired,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  pendingEdits: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mviId: PropTypes.number.isRequired,
    inspectorCompany: PropTypes.string,
    inspectorName: PropTypes.string,
    inspectionDate: PropTypes.string.isRequired,
  }),
  beginReportEdit: PropTypes.func.isRequired,
  editReport: PropTypes.func.isRequired,
  savePendingEdits: PropTypes.func.isRequired,
  moveReport: PropTypes.func.isRequired,
  cancelPendingEdits: PropTypes.func.isRequired,
};

InspectionDetails.defaultProps = {
  pendingEdits: null,
};

function mapStateToProps(state) {
  return {
    mvi: MviEditSelectors.item(state),
    currentReport: MviReportSelectors.item(state),
    pendingEdits: MviReportSelectors.pendingEdits(state),
    canEdit: SiteSelectors.canEditMvis(state),
    editDisabled: MviEditUberSelectors.isEditDisabled(state),
    isEditing: MviReportSelectors.isEditing(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    beginReportEdit: () => {
      track('Inspection', {
        Component: 'Report header',
        'Inspection action': 'Edit header',
      });
      return dispatch(actions.beginReportEdit());
    },
    editReport: updatedReport => dispatch(actions.editReport(updatedReport)),
    savePendingEdits: () => dispatch(actions.savePendingEdits()),
    moveReport: moveRequest => dispatch(actions.moveReport(moveRequest)),
    cancelPendingEdits: () => dispatch(actions.cancelPendingReportEdits()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionDetails);
