import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from './StatusIcon';
import StatusText from './StatusText';

const StatusIconAndText = ({ report, iconSize }) => (
  <>
    <StatusIcon status={report.status} calculatedStatusOverridden={report.calculatedStatusOverridden} size={iconSize} />
    <StatusText status={report.status} calculatedStatusOverridden={report.calculatedStatusOverridden} />
  </>
);

StatusIconAndText.propTypes = {
  report: PropTypes.shape({
    status: PropTypes.string.isRequired,
    calculatedStatusOverridden: PropTypes.bool.isRequired,
  }).isRequired,
  iconSize: PropTypes.string,
};

StatusIconAndText.defaultProps = {
  iconSize: '1em',
};

export default StatusIconAndText;
