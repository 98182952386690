import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { BrowserTimezone } from '../date';
import DispatchStatusIcon from './DispatchStatusIcon';

const DispatchStatusDate = ({ dispatch, className }) => {
  if (!dispatch) {
    return null;
  }
  const date = moment(dispatch.statusDate).tz(BrowserTimezone).format('M/D/YY');
  return (
    <div className={className}>
      <DispatchStatusIcon dispatchWorkflowStatus={dispatch.status} />
      {' '}
      {date}
    </div>
  );
};

DispatchStatusDate.propTypes = {
  dispatch: PropTypes.shape({
    status: PropTypes.string,
    statusDate: PropTypes.string,
  }),
  className: PropTypes.string,
};

DispatchStatusDate.defaultProps = {
  dispatch: null,
  className: null,
};

export default DispatchStatusDate;
