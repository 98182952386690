import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { track } from '../../../../../../../shared/analytics';
import MviLinkModal from '../../../../../components/mvi-link-modal/MviLinkModal';
import * as SiteSelectors from '../../../../../SiteSelectors';
import * as MviLinkModalActions from '../../../../../components/mvi-link-modal/MviLinkModalActions';
import * as CommentActions from './comments/CommentActions';
import MviReportSelectors from './report/MviReportSelectors';
import * as MviReportActions from './report/MviReportActions';
import * as MviEditActions from '../MviEditActions';
import * as MviEditUberSelectors from '../MviEditUberSelectors';

const MviActionButton = ({
  canEdit,
  editDisabled,
  beginCommentEdit,
  canCreateIssues,
  openCreateIssueModal,
  hasReports,
  openUploadReportModal,
  currentReport,
  openMoveReportModal,
  openDeleteReportModal,
  canViewMviForms,
  openFormLinkModal,
  className
}) => {
  if (!canEdit) {
    return null;
  }

  return (
    <>
      <UncontrolledDropdown color="primary" className={className} size="sm">
        <DropdownToggle color="primary" caret disabled={editDisabled}>
          Take Action
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={beginCommentEdit}>Comment</DropdownItem>
          {canCreateIssues ? <DropdownItem onClick={openCreateIssueModal}>Create issue</DropdownItem> : null}
          {canViewMviForms && !currentReport ? <DropdownItem onClick={openFormLinkModal}>Get form link</DropdownItem> : null}
          {!hasReports ? <DropdownItem onClick={openUploadReportModal}>Upload report</DropdownItem> : null}
          {currentReport ? <DropdownItem onClick={openMoveReportModal}>Move report</DropdownItem> : null}
          {currentReport ? <DropdownItem onClick={openDeleteReportModal}>Delete report</DropdownItem> : null}
        </DropdownMenu>
      </UncontrolledDropdown>
      <MviLinkModal />
    </>
  );
};

MviActionButton.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  beginCommentEdit: PropTypes.func.isRequired,
  canCreateIssues: PropTypes.bool.isRequired,
  openCreateIssueModal: PropTypes.func.isRequired,
  hasReports: PropTypes.bool.isRequired,
  openUploadReportModal: PropTypes.func.isRequired,
  currentReport: PropTypes.object,
  openMoveReportModal: PropTypes.func.isRequired,
  openDeleteReportModal: PropTypes.func.isRequired,
  canViewMviForms: PropTypes.bool.isRequired,
  openFormLinkModal: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MviActionButton.defaultProps = {
  className: null,
  currentReport: null,
};

function mapStateToProps(state) {
  return {
    canEdit: SiteSelectors.canEditMvis(state),
    editDisabled: MviEditUberSelectors.isEditDisabled(state),
    canCreateIssues: SiteSelectors.canEditIssues(state),
    hasReports: MviReportSelectors.hasReports(state),
    currentReport: MviReportSelectors.item(state),
    canViewMviForms: SiteSelectors.canViewMviForms(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    beginCommentEdit: () => {
      track('Inspection', {
        Component: 'Inspection action button',
        'Inspection action': 'Edit comment',
      });
      return dispatch(CommentActions.beginCommentEdit());
    },
    openUploadReportModal: () => {
      track('Inspection', {
        Component: 'Inspection action button',
        'Inspection action': 'Open create report modal',
      });
      return dispatch(MviReportActions.openCreateReportModal());
    },
    openMoveReportModal: () => {
      track('Inspection', {
        Component: 'Inspection action button',
        'Inspection action': 'Open move report modal',
      });
      return dispatch(MviReportActions.openMoveReportModal());
    },
    openDeleteReportModal: () => {
      track('Inspection', {
        Component: 'Inspection action button',
        'Inspection action': 'Open delete report modal',
      });
      return dispatch(MviReportActions.openDeleteReportModal());
    },
    openCreateIssueModal: () => {
      track('Inspection', {
        Component: 'Inspection action button',
        'Inspection action': 'Open create issue modal',
      });
      return dispatch(MviEditActions.openCreateIssueModal());
    },
    openFormLinkModal: () => {
      track('Inspection', {
        Component: 'Inspection action button',
        'Inspection action': 'Open form link modal',
      });
      dispatch(MviLinkModalActions.openModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MviActionButton);
