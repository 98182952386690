import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper/index';

import DeliveryURL from '../../../../../components/export-destination/components/DeliveryURL';
import FuelHaulerDestinationModal from './FuelHaulerDestinationModal';

class FuelHaulerDestinations extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.state = {
      modalOpen: false,
      currentDestination: null,
    };

    this.handleAddDestinationClick = this.handleAddDestinationClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleModalSaveClick = this.handleModalSaveClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  handleAddDestinationClick() {
    this.setState({
      modalOpen: true,
      currentDestination: undefined,
      currentDestinationIndex: -1,
    });
  }

  handleEditClick(destination, index) {
    this.setState({
      modalOpen: true,
      currentDestination: destination,
      currentDestinationIndex: index,
    });
  }

  handleModalSaveClick(updatedDestination) {
    let newFuelHauler;
    if (this.state.currentDestinationIndex !== -1) {
      const oldDeliveryUrl = this.props.selectedFuelHauler.destinations[this.state.currentDestinationIndex].deliveryUrl;
      newFuelHauler = update(this.props.selectedFuelHauler, {
        destinations: {
          $splice: [[this.state.currentDestinationIndex, 1, updatedDestination]],
        },
        schedules: {
          $set: this.updateSchedules(oldDeliveryUrl, updatedDestination.deliveryUrl),
        },
      });
    }
    else {
      newFuelHauler = update(this.props.selectedFuelHauler, {
        destinations: {
          $push: [updatedDestination],
        },
      });
    }

    this.setState({
      modalOpen: false,
      currentDestination: null,
    });
    return this.props.updateSelectedFuelHauler(newFuelHauler);
  }

  updateSchedules(oldDeliveryUrl, newDeliveryUrl) {
    const currentSchedules = this.props.selectedFuelHauler.schedules || [];
    if (oldDeliveryUrl === newDeliveryUrl) {
      return currentSchedules;
    }
    return currentSchedules.map(schedule => update(schedule, {
      destinations: {
        $set: schedule.destinations.map((destination) => {
          if (destination.deliveryUrl === oldDeliveryUrl) {
            return {
              deliveryUrl: newDeliveryUrl,
            };
          }
          return destination;
        }),
      },
    }));
  }

  handleCancelClick() {
    this.setState({
      modalOpen: false,
      currentDestination: null,
    });
  }

  handleDeleteClick(index) {
    const removedDestination = this.props.selectedFuelHauler.destinations[index];
    const newFuelHauler = update(this.props.selectedFuelHauler, {
      destinations: {
        $splice: [[index, 1]],
      },
      schedules: {
        $set: this.removeSchedulesForDestination(removedDestination),
      },
    });
    return this.props.updateSelectedFuelHauler(newFuelHauler);
  }

  removeSchedulesForDestination(removedDestination) {
    if (!this.props.selectedFuelHauler.schedules) {
      return [];
    }

    return this.props.selectedFuelHauler.schedules
      .map((schedule) => {
        const updatedSchedule = update(schedule, {
          destinations: {
            $set: schedule.destinations
              .map((scheduledDest) => {
                if (scheduledDest.deliveryUrl !== removedDestination.deliveryUrl) {
                  return scheduledDest;
                }
                return null;
              })
              .filter(scheduledDest => !! scheduledDest)
          }
        });

        if (updatedSchedule.destinations.length) {
          return updatedSchedule;
        }

        return null;
      })
      .filter(schedule => !! schedule);
  }

  renderDestinationsRow() {
    if (!this.props.selectedFuelHauler.destinations || !this.props.selectedFuelHauler.destinations.length) {
      return (
        <div>
          <span className="text-muted">No destinations configured</span>
        </div>
      );
    }

    return (
      <Row>
        <Col xs={12}>
          {this.props.selectedFuelHauler.destinations.map((currentDestination, index) => (
            <div className="mb-3" key={currentDestination.deliveryUrl}>
              <Button color="primary" outline size="sm" className="mr-2" onClick={() => this.handleEditClick(currentDestination, index)}>Edit</Button>
              <Button color="primary" outline size="sm" className="ml-2 mr-2" onClick={() => this.handleDeleteClick(index)}>Delete</Button>
              <DeliveryURL url={currentDestination.deliveryUrl} />
            </div>
          ))}
        </Col>
      </Row>
    );
  }

  render() {
    if (!this.props.selectedFuelHauler) {
      return null;
    }

    return (
      <div className="mt-4">
        {this.renderDestinationsRow()}
        <Button color="primary" outline size="sm" className="my-2" onClick={this.handleAddDestinationClick}>Add Destination</Button>
        <FuelHaulerDestinationModal
          modalOpen={this.state.modalOpen}
          destination={this.state.currentDestination}
          existingDestinations={this.props.selectedFuelHauler.destinations}
          onSave={this.handleModalSaveClick}
          onCancel={this.handleCancelClick}
        />
      </div>
    );
  }
}

FuelHaulerDestinations.propTypes = {
  selectedFuelHauler: PropTypes.object,
  updateSelectedFuelHauler: PropTypes.func.isRequired,
};

FuelHaulerDestinations.defaultProps = {
  selectedFuelHauler: null,
};

export default FuelHaulerDestinations;
