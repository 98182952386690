import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import CanaryModal from '../../../CanaryModal';

const SaveViewModal = ({ open, allViews, name, defaultView, onNameChange, onDefaultViewChange, onSave, onCancel }) => {
  const hasName = !!(name && name.length);
  const invalid = allViews.some(v => v.name === name);
  const onFormSubmit = e => {
    e.preventDefault();
    if (!invalid && hasName) {
      onSave();
    }
  };
  return (
    <CanaryModal
      open={open}
      title="Save View"
      confirmButtonText="Save"
      canConfirm={hasName && !invalid}
      onConfirm={onSave}
      onCancel={onCancel}
      size="md"
    >
      <Form onSubmit={onFormSubmit}>
        <FormGroup>
          <Label for="editViewName">View name</Label>
          <Input
            id="editViewName"
            name="editViewName"
            type="text"
            value={name}
            onChange={e => onNameChange(e.target.value)}
            invalid={invalid}
            maxLength={32}
          />
          <FormFeedback>A view with that name already exists</FormFeedback>
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            <Input
              id="editDefault"
              name="editDefault"
              type="checkbox"
              checked={defaultView}
              onChange={e => onDefaultViewChange(e.target.checked)}
            />
            Make this my default view
          </Label>
        </FormGroup>
      </Form>
    </CanaryModal>
  );
};

SaveViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  allViews: PropTypes.array,
  name: PropTypes.string,
  defaultView: PropTypes.bool,
  onNameChange: PropTypes.func.isRequired,
  onDefaultViewChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

SaveViewModal.defaultProps = {
  allViews: [],
  name: '',
  defaultView: false,
};

export default SaveViewModal;
