import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ServiceChangeType } from '../../../EquipmentConstants';

const OperationalStatus = ({ registration }) => {
  const latestHistoryRecord = registration &&
    registration.operationalStatusHistory &&
    registration.operationalStatusHistory.length &&
    registration.operationalStatusHistory[0];

  if (!latestHistoryRecord || latestHistoryRecord.serviceChange === ServiceChangeType.InService) {
    return null;
  }

  const date = moment(latestHistoryRecord.effectiveDate).format('M/D/YY');
  return (<strong>{latestHistoryRecord.serviceChange} ({date})</strong>);
};

OperationalStatus.propTypes = {
  registration: PropTypes.object,
};

OperationalStatus.defaultProps = {
  registration: null,
};

export default OperationalStatus;
