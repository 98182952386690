import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import objectPath from 'object-path';
import momentLocalizer from 'react-widgets-moment';
import { compose } from 'recompose';
import { Badge } from 'reactstrap';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import withEither from './withEither';

Moment.locale('en');
momentLocalizer();

const dateEditorFn = props => props.edit && props.field.editable;

const DateEditor = props =>
  <div>
    <DateTimePicker
      onChange={value => props.onValidatePanel(props.formBuilder, props.panel, props.target, props.field, Moment(value).format('YYYY-MM-DD'))}
      id={props.id}
      min={props.getMinDate(props.target, props.field)}
      max={props.getMaxDate(props.target, props.field)}
      format="L"
      time={false}
      placeholder={`Enter ${props.field.label}`}
      defaultValue={Moment(objectPath.get(props.target, props.field.path), 'YYYY-MM-DD').isValid() ? Moment(objectPath.get(props.target, props.field.path), 'YYYY-MM-DD').toDate() : Moment().toDate()}
    />

    { props.field.required && !Moment(objectPath.get(props.target, props.field.path), 'YYYY-MM-DD').isValid() ?
      <div className="d-flex justify-content-begin mt-1 required"><strong>Required</strong></div>
    : null
    }

    {props.field.error ?
      <div className="d-flex justify-content-begin mt-1"><Badge color="danger">Error</Badge></div>
      : null
      }
  </div>;


const DateViewer = props =>
  <div className="pb-2 pt-2">
    {objectPath.get(props.target, props.field.path) && Moment.isDate(new Date(objectPath.get(props.target, props.field.path))) && Moment(objectPath.get(props.target, props.field.path), 'YYYY-MM-DD').isValid() ?
      <strong id={props.id}>{ Moment(objectPath.get(props.target, props.field.path)).format('L')}</strong>
    :
    '\u2014' }
  </div>;

const withConditionalRenderings = compose(
    withEither(dateEditorFn, DateEditor),
  );

const DateWithConditionalRendering = withConditionalRenderings(DateViewer);

const Datepicker = props => (
  <div className="mb-3">
    <label htmlFor={`field${props.panel.id.toString()}${props.field.id.toString()}`}>{props.field.label}:
    </label>
    <DateWithConditionalRendering id={`field${props.panel.id.toString()}${props.field.id.toString()}`} {...props} />
  </div>

);

Datepicker.propTypes = {
  field: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
};

DateEditor.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onValidatePanel: PropTypes.func.isRequired,
  error: PropTypes.object,
  getMinDate: PropTypes.func.isRequired,
  getMaxDate: PropTypes.func.isRequired,
  formBuilder: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
};

DateViewer.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

Datepicker.defaultProps = {
  error: null,
};

DateEditor.defaultProps = {
  error: null,
};

export default Datepicker;
