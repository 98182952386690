import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';

import OrgGrants from '../components/OrgGrants';
import Granted from './Granted';
import * as orgEditSelectors from '../../../OrganizationEditSelectors';
import * as selectors from '../ExternalAccessSelectors';

class ExternalAccessNav extends Component {
  renderLeftNav() {
    if (this.props.isCustomer) {
      return (
        <div className="nav flex-column nav-pills">
          <NavLink to={`${this.props.match.url}/granted`} className="nav-link" activeClassName="active">Granted</NavLink>
          <NavLink to={`${this.props.match.url}/received`} className="nav-link" activeClassName="active">Received</NavLink>
        </div>
      );
    }
    return (
      <div className="nav flex-column nav-pills">
        <NavLink to={`${this.props.match.url}/received`} className="nav-link" activeClassName="active">Received</NavLink>
      </div>
    );
  }

  renderContent() {
    if (this.props.isCustomer) {
      return (
        <Switch>
          <Redirect exact="exact" from={`${this.props.match.url}/`} to={`${this.props.match.url}/granted`}/>
          <Route exact path={`${this.props.match.url}/granted`} name="granted">
            <Granted />
          </Route>
          <Route exact path={`${this.props.match.url}/received`} name="received">
            <OrgGrants orgGrants={this.props.receivedOrgGrants} linkToGrantingOrg />
          </Route>
        </Switch>
      );
    }

    return (
      <Switch>
        <Redirect exact="exact" from={`${this.props.match.url}/`} to={`${this.props.match.url}/received`} />
        <Route exact path={`${this.props.match.url}/received`} name="received">
          <OrgGrants orgGrants={this.props.receivedOrgGrants} linkToGrantingOrg />
        </Route>
      </Switch>
    );
  }

  render() {
    return (
      <div className="row mt-4">
        <div className="col-md-2">
          {this.renderLeftNav()}
        </div>
        <div className="col-md-10">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

ExternalAccessNav.propTypes = {
  isCustomer: PropTypes.bool.isRequired,
  receivedOrgGrants: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isCustomer: orgEditSelectors.selectedOrganizationIsCustomer(state),
    receivedOrgGrants: selectors.receivedOrgGrants(state),
  };
}

export default withRouter(connect(mapStateToProps)(ExternalAccessNav));
