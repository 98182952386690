import * as siteSelectors from './SiteSelectors';

export const createSiteAction = (isLoadingFunc, startedType, receiveType, errorType, restFunc, actionDataProperty = 'data') => (...actionArgs) => (dispatch, getState) => {
  const currentState = getState();
  const isLoading = isLoadingFunc(currentState);
  const site = siteSelectors.currentSite(currentState);
  if (isLoading || !site) {
    return Promise.resolve();
  }

  dispatch({ type: startedType });
  return restFunc(site, ...actionArgs)
    .then(data => {
      dispatch({
        type: receiveType,
        [actionDataProperty]: data,
      });
      return data;
    })
    .catch(error => {
      dispatch({ type: errorType, error });
    });
};
