export function isActivationListLoading(state) {
  return (state && state.admin && state.admin.sites && state.admin.sites.activation && state.admin.sites.activation.activationsListLoading) || false;
}

export function getActivationList(state) {
  return (state && state.admin && state.admin.sites && state.admin.sites.activation && state.admin.sites.activation.activationsList) || [];
}

export function isDeleteInProgress(state) {
  return (state && state.admin && state.admin.sites && state.admin.sites.activation && state.admin.sites.activation.deleteInProgress) || false;
}

export function isSelectedActivationLoading(state) {
  return (state && state.admin && state.admin.sites && state.admin.sites.activation && state.admin.sites.activation.selectedActivationLoading) || false;
}

export function getSelectedActivation(state) {
  return (state && state.admin && state.admin.sites && state.admin.sites.activation && state.admin.sites.activation.selectedActivation) || null;
}

export function getNearbySites(state) {
  return (state && state.admin && state.admin.sites && state.admin.sites.activation && state.admin.sites.activation.nearbySites) || [];
}

export function isActivationInProgress(state) {
  return (state && state.admin && state.admin.sites && state.admin.sites.activation && state.admin.sites.activation.activationInProgress) || false;
}

export function isError(state) {
  return !isActivationListLoading(state) &&
    !isDeleteInProgress(state) &&
    !isSelectedActivationLoading(state) &&
    !isActivationInProgress(state) &&
    !!error(state);
}

export function error(state) {
  return (state && state.admin && state.admin.sites && state.admin.sites.activation && state.admin.sites.activation.error) || null;
}
