import Selectors from '../../../shared/redux-helpers/Selectors';
import { SubsystemTypes } from './IngestFailureConstants';

const getState = (state => state && state.admin && state.admin.ingestFailure) || {};

class IngestFailureSelectors extends Selectors {
  constructor() {
    super(getState);
  }

  filteredList(state) {
    return getState(state).filteredList || [];
  }

  selectedSubsystem(state) {
    return getState(state).selectedSubsystem || SubsystemTypes.All;
  }
}

export default new IngestFailureSelectors(getState);
