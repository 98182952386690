import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { FriendlyWorkflowStatusTypes } from '../../../../../../../AppConstants';

const IssueWorkflowSelect = ({ selectedFriendlyWorkflowStatuses, selectFriendlyIssueWorkflowStatuses, deselectFriendlyIssueWorkflowStatus, disabled }) => {
  const hasFriendlyWorkflowStatus = friendlyWorkflowStatus => !!(find(selectedFriendlyWorkflowStatuses, current => current.name === friendlyWorkflowStatus.name));
  const buildUpdateFunc = friendlyWorkflowStatus => {
    if (hasFriendlyWorkflowStatus(friendlyWorkflowStatus)) {
      deselectFriendlyIssueWorkflowStatus(friendlyWorkflowStatus);
    }
    else {
      selectFriendlyIssueWorkflowStatuses([friendlyWorkflowStatus]);
    }
  };

  const toggleAnalyzing = () => buildUpdateFunc(FriendlyWorkflowStatusTypes.Analyzing);
  const analyzingChecked = hasFriendlyWorkflowStatus(FriendlyWorkflowStatusTypes.Analyzing);
  const toggleNew = () => buildUpdateFunc(FriendlyWorkflowStatusTypes.New);
  const newChecked = hasFriendlyWorkflowStatus(FriendlyWorkflowStatusTypes.New);
  const togglePendingResolution = () => buildUpdateFunc(FriendlyWorkflowStatusTypes.PendingResolution);
  const pendingResolutionChecked = hasFriendlyWorkflowStatus(FriendlyWorkflowStatusTypes.PendingResolution);
  return (
    <Form className="d-md-flex align-items-center h-100">
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={analyzingChecked} onChange={toggleAnalyzing} disabled={disabled} />
          Analyzing
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={newChecked} onChange={toggleNew} disabled={disabled} />
          New
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0">
        <Label check>
          <Input type="checkbox" checked={pendingResolutionChecked} onChange={togglePendingResolution} disabled={disabled} />
          Pending Resolution
        </Label>
      </FormGroup>
    </Form>
  );
};

IssueWorkflowSelect.propTypes = {
  selectedFriendlyWorkflowStatuses: PropTypes.arrayOf(PropTypes.object),
  selectFriendlyIssueWorkflowStatuses: PropTypes.func.isRequired,
  deselectFriendlyIssueWorkflowStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

IssueWorkflowSelect.defaultProps = {
  selectedFriendlyWorkflowStatuses: [],
};

export default IssueWorkflowSelect;
