import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import { track } from '../../../../../../../../shared/analytics';
import TextAreaInputForm from '../../../../../../../../shared/components/TextAreaInputForm';
import TextToMarkup from '../../../../../../../../shared/components/TextToMarkup';
import EditButton from '../../../../../../../../shared/components/buttons/EditButton';
import DeleteButton from '../../../../../../../../shared/components/buttons/DeleteButton';
import * as SiteSelectors from '../../../../../../SiteSelectors';
import * as MviEditUberSelectors from '../../MviEditUberSelectors';
import MviReportSelectors from './MviReportSelectors';
import * as actions from './MviReportActions';

const InspectorComments = ({
  currentReport,
  isSaving,
  canEdit,
  editDisabled,
  editingInspectorComments,
  pendingInspectorCommentsEdits,
  beginInspectorCommentsEdit,
  editInspectorComments,
  cancelInspectorCommentsEdit,
  savePendingInspectorCommentsEdit,
  deleteInspectorComments,
  className
}) => {
  const renderView = () => (
    <>
      <TextToMarkup text={currentReport.inspectorComments} missingText="There are no inspector comments" className="flex-grow-1" />
      { canEdit ? (
        <>
          <EditButton onClick={beginInspectorCommentsEdit} disabled={editDisabled} className="mr-3" />
          {currentReport.inspectorComments ? <DeleteButton onClick={deleteInspectorComments} disabled={editDisabled} /> : null}
        </>
      ) : null}
    </>
  );

  const renderEdit = () => (
    <TextAreaInputForm
      value={pendingInspectorCommentsEdits}
      onChange={editInspectorComments}
      canCancel={!isSaving}
      onCancel={cancelInspectorCommentsEdit}
      canSave={!isSaving}
      onSave={savePendingInspectorCommentsEdit}
      rows={6}
      className="flex-grow-1"
    />
  );

  return (
    <Card className={className}>
      <CardBody className="d-flex align-items-center justify-content-between">
        {editingInspectorComments ? renderEdit() : renderView()}
      </CardBody>
    </Card>
  );
};

InspectorComments.propTypes = {
  currentReport: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  editingInspectorComments: PropTypes.bool.isRequired,
  pendingInspectorCommentsEdits: PropTypes.string,
  beginInspectorCommentsEdit: PropTypes.func.isRequired,
  editInspectorComments: PropTypes.func.isRequired,
  cancelInspectorCommentsEdit: PropTypes.func.isRequired,
  savePendingInspectorCommentsEdit: PropTypes.func.isRequired,
  deleteInspectorComments: PropTypes.func.isRequired,
  className: PropTypes.string,
};

InspectorComments.defaultProps = {
  pendingInspectorCommentsEdits: '',
  className: null,
};

function mapStateToProps(state) {
  return {
    currentReport: MviReportSelectors.item(state),
    isSaving: MviReportSelectors.isSaving(state),
    canEdit: SiteSelectors.canEditMvis(state),
    editDisabled: MviEditUberSelectors.isEditDisabled(state),
    editingInspectorComments: MviReportSelectors.isEditingInspectorComments(state),
    pendingInspectorCommentsEdits: MviReportSelectors.pendingInspectorCommentsEdits(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    beginInspectorCommentsEdit: () => {
      track('Inspection', {
        Component: 'Inspector comments',
        'Inspection action': 'Edit inspector comments',
      });
      return dispatch(actions.beginInspectorCommentsEdit());
    },
    editInspectorComments: inspectorComments => dispatch(actions.editInspectorComments(inspectorComments)),
    cancelInspectorCommentsEdit: () => dispatch(actions.cancelInspectorCommentsEdit()),
    savePendingInspectorCommentsEdit: () => {
      track('Inspection', {
        Component: 'Inspector comments',
        'Inspection action': 'Save inspector comments',
      });
      return dispatch(actions.savePendingInspectorCommentsEdit());
    },
    deleteInspectorComments: () => {
      track('Inspection', {
        Component: 'Inspector comments',
        'Inspection action': 'Delete inspector comments',
      });
      return dispatch(actions.deleteInspectorComments());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectorComments);
