import update from 'immutability-helper/index';

import { FuelHaulerExportClient } from '../../../../../../client';
import ActionTypes from '../ExportHistoryActionTypes';
import * as selectors from '../ExportHistorySelectors';

function receiveHistory(exportHistoryList) {
  return {
    type: ActionTypes.RECEIVE_HISTORY,
    exportHistoryList,
  };
}

function receiveFilter(filter) {
  return {
    type: ActionTypes.RECEIVE_FILTER,
    filter,
  };
}

function getExportHistory(dispatch, filter) {
  dispatch({ type: ActionTypes.GET_HISTORY_STARTED });
  return FuelHaulerExportClient.getHistory(filter)
    .then(exportHistoryList => dispatch(receiveHistory(exportHistoryList)))
    .catch((err) => {
      dispatch({ type: ActionTypes.GET_HISTORY_FAILED });
      return Promise.reject(err);
    });
}

export function refreshExportHistory() {
  return (dispatch, getState) => {
    const isLoading = selectors.isLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch(receiveFilter({}));
    return getExportHistory(dispatch);
  };
}


function applyFilterValue(filterKey, filterValue) {
  return (dispatch, getState) => {
    const currentState = getState();
    const isLoading = selectors.isLoading(currentState);
    if (isLoading) {
      return Promise.resolve();
    }

    const currentFilter = selectors.filter(currentState) || {};
    const updateSpec = {};
    updateSpec[filterKey] = {
      $set: filterValue,
    };
    const updatedFilter = update(currentFilter, updateSpec);

    dispatch(receiveFilter(updatedFilter));
    return getExportHistory(dispatch, updatedFilter);
  };
}

export function filterByFuelHauler(fuelHauler) {
  return applyFilterValue('fuelHauler', fuelHauler);
}

export function filterByDurationType(durationType) {
  return applyFilterValue('durationType', durationType);
}

function receiveExport(selectedExport) {
  return {
    type: ActionTypes.RECEIVE_EXPORT,
    selectedExport,
  };
}

export function selectExport(exportId) {
  return (dispatch, getState) => {
    const currentState = getState();
    const isLoading = selectors.isSelectedExportLoading(currentState);
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_EXPORT_STARTED });
    return FuelHaulerExportClient.getById(exportId)
      .then(fuelHaulerExport => dispatch(receiveExport(fuelHaulerExport)))
      .catch((err) => {
        dispatch({ type: ActionTypes.GET_EXPORT_FAILED });
        return Promise.reject(err);
      });
  };
}
