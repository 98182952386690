import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row } from 'reactstrap';
import includes from 'lodash/includes';
import { FilterType } from '../ViewManagerConstants';
import CustomerFilterMultiselect from './filter/customer/CustomerFilterMultiselect';
import LabelFilterMultiselect from './filter/label/LabelFilterMultiselect';
import StateFilterMultiselect from './filter/state/StateFilterMultiselect';
import ConnectionTypeFilterMultiselect from './filter/connection-type/ConnectionTypeMultiselect';
import IssueTypeSelect from './filter/issue-type/IssueTypeSelect';
import IssueWorkflowSelect from './filter/issue-workflow/IssueWorkflowSelect';
import MonthSelect from './filter/month/MonthSelect';
import ComplianceStatusSelect from './filter/compliance/ComplianceStatusSelect';
import InspectionStatusSelect from './filter/inspection-status/InspectionStatusSelect';
import SiteSearch from './filter/site/SiteSearch';
import TankProductLabelSelect from './filter/tank-product-label/TankProductLabelSelect';
import TankStatusSelect from './filter/tank-status/TankStatusSelect';
import ConnectionStatusSelect from './filter/connection-status/ConnectionStatusSelect';

class FilterEditBar extends Component {
  constructor(initialProps) {
    super(initialProps);
    const defaultFilterType = (this.props.filterTypes && this.props.filterTypes.length && this.props.filterTypes[0]) || FilterType.Customer;
    this.state = {
      filterType: defaultFilterType
    };
    this.handleFilterTypeChange = this.handleFilterTypeChange.bind(this);
  }

  handleFilterTypeChange(e) {
    this.setState({ filterType: e.target.value });
  }

  render() {
    if (!this.props.editingFilters) {
      return null;
    }
    const filterTypes = this.props.filterTypes;
    return (
      <div className="p-3 d-flex bg-gray-300 rounded">
        <Row className="flex-grow-1">
          <Col md={3}>
            <Input id="filterType" name="filterType" type="select" value={this.state.filterType} onChange={this.handleFilterTypeChange} bsSize="sm" disabled={this.props.disabled}>
              {includes(filterTypes, FilterType.Month) ? <option value={FilterType.Month}>Month</option> : null}
              {includes(filterTypes, FilterType.ConnectionType) ? <option value={FilterType.ConnectionType}>Connection type</option> : null}
              {includes(filterTypes, FilterType.ConnectionStatus) ? <option value={FilterType.ConnectionStatus}>Connection status</option> : null}
              {includes(filterTypes, FilterType.Customer) ? <option value={FilterType.Customer}>Customer</option> : null}
              {includes(filterTypes, FilterType.Site) ? <option value={FilterType.Site}>Site</option> : null}
              {includes(filterTypes, FilterType.State) ? <option value={FilterType.State}>State</option> : null}
              {includes(filterTypes, FilterType.ComplianceStatus) ? <option value={FilterType.ComplianceStatus}>Compliance status</option> : null}
              {includes(filterTypes, FilterType.InspectionStatus) ? <option value={FilterType.InspectionStatus}>Inspection status</option> : null}
              {includes(filterTypes, FilterType.TankProductLabel) ? <option value={FilterType.TankProductLabel}>Tank label</option> : null}
              {includes(filterTypes, FilterType.TankStatus) ? <option value={FilterType.TankStatus}>Tank status</option> : null}
              {includes(filterTypes, FilterType.IssueType) ? <option value={FilterType.IssueType}>Issue type</option> : null}
              {includes(filterTypes, FilterType.IssueWorkflowStatus) ? <option value={FilterType.IssueWorkflowStatus}>Workflow status</option> : null}
              {includes(filterTypes, FilterType.Label) ? <option value={FilterType.Label}>Site label</option> : null}
            </Input>
          </Col>
          <Col md={4} className="mt-2 mt-md-0">
            {this.renderFilterInput()}
          </Col>
        </Row>
        <button type="button" className="ml-3 close" onClick={this.props.cancelFilterEdit}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderFilterInput() {
    switch (this.state.filterType) {
      case (FilterType.Customer):
        return <CustomerFilterMultiselect selectedCustomers={this.props.selectedCustomers} selectCustomers={this.props.selectCustomers} disabled={this.props.disabled} />;
      case (FilterType.Label):
        return <LabelFilterMultiselect selectedSiteLabels={this.props.selectedSiteLabels} allSiteLabels={this.props.allSiteLabels} getAllLabels={this.props.getAllLabels} selectSiteLabels={this.props.selectSiteLabels} disabled={this.props.disabled} />;
      case (FilterType.State):
        return <StateFilterMultiselect selectedStates={this.props.selectedStates} allStates={this.props.allStates} selectSiteStates={this.props.selectSiteStates} disabled={this.props.disabled} />;
      case (FilterType.ConnectionType):
        return <ConnectionTypeFilterMultiselect selectedConnectionTypes={this.props.selectedConnectionTypes} selectConnectionTypes={this.props.selectConnectionTypes} deselectConnectionType={this.props.deselectConnectionType} disabled={this.props.disabled} />;
      case (FilterType.ConnectionStatus):
        return <ConnectionStatusSelect selectedConnectionStatuses={this.props.selectedConnectionStatuses} selectConnectionStatuses={this.props.selectConnectionStatuses} deselectConnectionStatus={this.props.deselectConnectionStatus} disabled={this.props.disabled} />;
      case (FilterType.IssueType):
        return <IssueTypeSelect selectedIssueTypes={this.props.selectedIssueTypes} selectIssueTypes={this.props.selectIssueTypes} disabled={this.props.disabled} />;
      case (FilterType.IssueWorkflowStatus):
        return (
          <IssueWorkflowSelect
            selectedFriendlyWorkflowStatuses={this.props.selectedFriendlyWorkflowStatuses}
            selectFriendlyIssueWorkflowStatuses={this.props.selectFriendlyIssueWorkflowStatuses}
            deselectFriendlyIssueWorkflowStatus={this.props.deselectFriendlyIssueWorkflowStatus}
            disabled={this.props.disabled}
          />
        );
      case (FilterType.Month):
        return <MonthSelect monthType={this.props.monthType} selectedMonth={this.props.selectedMonth} months={this.props.months} setMonthFilter={this.props.setMonthFilter} disabled={this.props.disabled} />;
      case (FilterType.ComplianceStatus):
        return <ComplianceStatusSelect selectedComplianceStatuses={this.props.selectedComplianceStatuses} selectComplianceStatuses={this.props.selectComplianceStatuses} deselectComplianceStatus={this.props.deselectComplianceStatus} disabled={this.props.disabled} />;
      case (FilterType.InspectionStatus):
        return <InspectionStatusSelect selectedInspectionStatuses={this.props.selectedInspectionStatuses} selectInspectionStatuses={this.props.selectInspectionStatuses} deselectInspectionStatus={this.props.deselectInspectionStatus} disabled={this.props.disabled} />;
      case (FilterType.Site):
        return <SiteSearch sitePermission={this.props.sitePermission} siteFeature={this.props.siteFeature} selectedSites={this.props.selectedSites} selectSites={this.props.selectSites} disabled={this.props.disabled} />;
      case (FilterType.TankProductLabel):
        return <TankProductLabelSelect selectedTankProductLabels={this.props.selectedTankProductLabels} selectTankProductLabels={this.props.selectTankProductLabels} disabled={this.props.disabled} />;
      case (FilterType.TankStatus):
        return <TankStatusSelect selectedTankStatuses={this.props.selectedTankStatuses} selectTankStatuses={this.props.selectTankStatuses} deselectTankStatus={this.props.deselectTankStatus} disabled={this.props.disabled} />;
      default:
        return null;
    }
  }
}

FilterEditBar.propTypes = {
  filterTypes: PropTypes.arrayOf(PropTypes.string).isRequired,

  selectedCustomers: PropTypes.array.isRequired,
  selectCustomers: PropTypes.func.isRequired,

  selectedSiteLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
  allSiteLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllLabels: PropTypes.func.isRequired,
  selectSiteLabels: PropTypes.func.isRequired,

  selectedStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  allStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectSiteStates: PropTypes.func.isRequired,

  selectedConnectionTypes: PropTypes.array.isRequired,
  selectConnectionTypes: PropTypes.func.isRequired,
  deselectConnectionType: PropTypes.func.isRequired,

  selectedConnectionStatuses: PropTypes.array.isRequired,
  selectConnectionStatuses: PropTypes.func.isRequired,
  deselectConnectionStatus: PropTypes.func.isRequired,

  selectedIssueTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectIssueTypes: PropTypes.func.isRequired,

  selectedFriendlyWorkflowStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectFriendlyIssueWorkflowStatuses: PropTypes.func.isRequired,
  deselectFriendlyIssueWorkflowStatus: PropTypes.func.isRequired,

  monthType: PropTypes.string.isRequired,
  selectedMonth: PropTypes.object,
  months: PropTypes.array,
  setMonthFilter: PropTypes.func.isRequired,

  selectedComplianceStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectComplianceStatuses: PropTypes.func.isRequired,
  deselectComplianceStatus: PropTypes.func.isRequired,

  selectedInspectionStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectInspectionStatuses: PropTypes.func.isRequired,
  deselectInspectionStatus: PropTypes.func.isRequired,

  sitePermission: PropTypes.number,
  siteFeature: PropTypes.string,
  selectedSites: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectSites: PropTypes.func.isRequired,

  selectedTankProductLabels: PropTypes.arrayOf(PropTypes.string),
  selectTankProductLabels: PropTypes.func.isRequired,

  selectedTankStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectTankStatuses: PropTypes.func.isRequired,
  deselectTankStatus: PropTypes.func.isRequired,

  cancelFilterEdit: PropTypes.func.isRequired,
  editingFilters: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

FilterEditBar.defaultProps = {
  selectedMonth: null,
  months: [],
  sitePermission: null,
  siteFeature: null,
  selectedTankProductLabels: [],
};

export default FilterEditBar;
