export default {
  SET_MODAL_STATE: 'site-picker/set_modal_state',

  GET_CUSTOMER_LIST_STARTED: 'site-picker/get_customer_list_started',
  GET_CUSTOMER_LIST_FAILED: 'site-picker/get_customer_list_failed',
  RECEIVE_CUSTOMER_LIST: 'site-picker/receive_customer_list',

  CLEAR_SELECTED_CUSTOMER: 'site-picker/clear_selected_customer',
  UPDATE_SELECTED_CUSTOMER: 'site-picker/update_selected_customer',

  GET_SITES_FOR_CUSTOMER_STARTED: 'site-picker/get_sites_for_customer_started',
  GET_SITES_FOR_CUSTOMER_FAILED: 'site-picker/get_sites_for_customer_failed',
  RECEIVE_SITE_LIST: 'site-picker/receive_site_list',

  ADD_SITE: 'site-picker/add_site',
  REMOVE_SITE: 'site-picker/remove_site',
  RESET: 'site-picker/reset',
};
