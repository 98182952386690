import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

function extractErrorObject(error) {
  // errors thrown by axios
  if (error.message && error.response && error.response.data && typeof error.response.data === 'string' && error.response.data.startsWith('<html>')) {
    return error;
  }

  // everything else
  const data = (error.response && error.response.data) || error.data || error;
  const innerError = data.error || data;
  if (typeof innerError === 'string') {
    return { message: innerError };
  }
  return innerError;
}

function extractMainErrorMessage(error) {
  const errorObject = extractErrorObject(error);
  return errorObject.message || 'Unknown Error';
}

function extractValidationErrors(error) {
  const errorObject = extractErrorObject(error);
  if (errorObject.errors && errorObject.errors.length) {
    return errorObject.errors;
  }
  return [];
}

function renderValidationErrors(error) {
  const validationErrors = extractValidationErrors(error);
  if (!validationErrors.length) {
    return null;
  }
  return (
    <ul>
      {validationErrors.map(current => (<li key={current}>{current}</li>))}
    </ul>
  );
}

function renderEnvironment() {
  const env = window.env || 'local-dev';
  return (
    <span>
      <strong>Environment:</strong>
      {` ${env}`}
      <br />
    </span>
  );
}

function renderErrorCode(error) {
  const errorObject = extractErrorObject(error);
  const code = errorObject.code || errorObject.name;
  if (code) {
    return (
      <span>
        <strong>Error code:</strong>
        {` ${code}`}
        <br />
      </span>
    );
  }
  return null;
}

function renderHttpStatusCode(error) {
  const httpStatus = (error.response && error.response.status);
  if (httpStatus) {
    return (
      <span>
        <strong>HTTP status:</strong>
        {` ${httpStatus}`}
        <br />
      </span>
    );
  }
  return null;
}

function renderErrorId(error) {
  const errorObject = extractErrorObject(error);
  if (errorObject.errorId) {
    return (
      <span>
        <strong>Error id:</strong>
        {` ${errorObject.errorId}`}
        <br />
      </span>
    );
  }
  return null;
}

class ErrorMessage extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.error && !prevProps.error) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    if (!this.props.error) {
      return null;
    }

    return (
      <Alert color="danger">
        <h5 className="alert-heading">{this.props.title}</h5>
        <p>{extractMainErrorMessage(this.props.error)}</p>
        {renderValidationErrors(this.props.error)}
        <hr />
        <p className="mb-0 small">
          {renderEnvironment()}
          {renderErrorCode(this.props.error)}
          {renderHttpStatusCode(this.props.error)}
          {renderErrorId(this.props.error)}
        </p>
      </Alert>
    );
  }
}


ErrorMessage.propTypes = {
  title: PropTypes.string,
  error: PropTypes.any,
};

ErrorMessage.defaultProps = {
  title: 'Operation failed',
  error: null,
};

export default ErrorMessage;
