import { LOCATION_CHANGE } from 'react-router-redux';
import ActionTypes from './MviLinkModalActionTypes';

const DefaultState = {
  modalOpen: false,
};

export default function MviLinkModalReducer(state = DefaultState, action) {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL:
      return Object.assign({}, state, { modalOpen: true });
    case ActionTypes.CLOSE_MODAL:
      return Object.assign({}, state, { modalOpen: false });
    case ActionTypes.GET_MVI_FORM_URL_STARTED:
      return Object.assign({}, state, { loading: true, error: null });
    case ActionTypes.RECEIVE_MVI_FORM_URL:
      return Object.assign({}, state, { loading: false, mviFormUrl: action.mviFormUrl });
    case ActionTypes.GET_MVI_FORM_URL_FAILED:
      return Object.assign({}, state, { loading: false, error: action.error });
    case LOCATION_CHANGE:
      return DefaultState;
    default:
      return state;
  }
}
