export default {
  GET_DATA_STARTED: 'tank-list/get_data_started',
  RECEIVE_DATA: 'tank-list/receive_data',
  GET_DATA_FAILED: 'tank-list/get_data_failed',

  UPDATE_MATCHES: 'tank-list/update_matches',
  RECEIVE_STEP: 'tank-list/receive_step',

  SAVE_DATA_STARTED: 'tank-list/save_data_started',
  SAVE_DATA_COMPLETE: 'tank-list/save_data_complete',
  SAVE_DATA_FAILED: 'tank-list/save_data_failed',
};
