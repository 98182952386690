import React, { Component } from 'react';
import { Alert, Button, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as router from 'react-router-redux';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import { connect } from 'react-redux';

import { SystemOrgId } from '../../../../../../AppConstants';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import OrganizationSettings from '../components/OrganizationSettings';
import * as actions from '../actions';
import * as selectors from '../OrganizationEditSelectors';


const NEW = 'new';

class OrganizationEditor extends Component {
  constructor(props) {
    super(props);

    this.renderSettingsForm = this.renderSettingsForm.bind(this);

    if (this.props.match && this.props.match.params && this.props.match.params.organizationId) {
      this.props.selectOrganization(this.props.match.params.organizationId);
    }
    else {
      this.props.selectOrganization(NEW);
    }
  }

  canSave() {
    const pendingOrgEdits = this.props.pendingOrgEdits;
    return !!(
      pendingOrgEdits
      && (!pendingOrgEdits.customer || (pendingOrgEdits.customer && pendingOrgEdits.customer.abbreviation && pendingOrgEdits.customer.abbreviation.match(/[A-Z]{3}/)))
      && pendingOrgEdits.name
    );
  }

  renderHeader() {
    if (this.props.pendingOrgEdits.id && Number.isInteger(this.props.pendingOrgEdits.id)) {
      return <h4 className="mt-1">{this.props.pendingOrgEdits.name}</h4>;
    }
    return <h4 className="mt-1">New Organization</h4>;
  }

  renderSettingsForm() {
    return (
      <OrganizationSettings
        selectedOrganization={this.props.pendingOrgEdits}
        editSelectedOrganization={this.props.editSelectedOrganization}
      />
    );
  }

  render() {
    if (!this.props.pendingOrgEdits || this.props.isLoading || this.props.isSaving) {
      return (
        <PulseLoader color="#F8E71C" size={8} />
      );
    }
    else if (this.props.pendingOrgEdits.id === SystemOrgId) {
      return (
        <Alert color="info">
          You cannot edit the system organization.
        </Alert>
      );
    }

    return (
      <div>
        {this.renderHeader()}
        <ErrorMessage title="Failed to save organization" error={this.props.error} />
        {this.renderSettingsForm()}
        <Row className="mt-2">
          <Col md={4}>
            <Button color="primary" outline size="sm" onClick={this.props.goBack}>Cancel</Button>
            {' '}
            <Button id="save" color="primary" size="sm" onClick={this.props.saveSelectedOrganization} disabled={!this.canSave()}>Save</Button>
          </Col>
        </Row>
      </div>
    );
  }
}

OrganizationEditor.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,

  pendingOrgEdits: PropTypes.object,
  error: PropTypes.object,

  selectOrganization: PropTypes.func.isRequired,
  editSelectedOrganization: PropTypes.func.isRequired,
  saveSelectedOrganization: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,

  match: PropTypes.object.isRequired,
};

OrganizationEditor.defaultProps = {
  pendingOrgEdits: null,
  error: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    isSaving: selectors.isSaving(state),
    pendingOrgEdits: selectors.pendingOrgEdits(state),
    error: selectors.error(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectOrganization: orgId => dispatch(actions.selectOrganization(orgId)),
    editSelectedOrganization: organization => dispatch(actions.editSelectedOrganization(organization)),
    saveSelectedOrganization: () => dispatch(actions.saveSelectedOrganization()).then(() => dispatch(router.goBack())),
    goBack: () => dispatch(router.goBack()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationEditor));
