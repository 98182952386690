import update from 'immutability-helper';
import { combineReducers } from 'redux';
import groupBy from 'lodash/groupBy';

import { WorkflowStatusTypes } from '../../../../AppConstants';
import ActionTypes from './IssueActionTypes';
import IssueEditReducer from './screens/issue-edit/IssueEditReducer';
import OrganizerReducer from './screens/organizer/OrganizerReducer';
import SiteIssueListReducer from './screens/issue-list/SiteIssueListReducer';
import WorkflowActionTypes from './screens/issue-edit/components/workflow/WorkflowActionTypes';
import SiteDispatchActionTypes from '../dispatch/SiteDispatchActionTypes';
import NewIssueActionTypes from '../../components/new-issue-button/NewIssueActionTypes';
import { buildStringSort } from '../../../../shared/SortUtils';

const byCreated = buildStringSort('created');

function sortIssues(issues) {
  if (!issues || !issues.length) {
    return issues;
  }

  const newIssues = [...issues];
  newIssues.sort(byCreated);
  newIssues.reverse();
  return newIssues;
}

function updateOpenIssueWorkflow(state, issue) {
  if (!state.openIssues) {
    return state;
  }

  const index = state.openIssues.findIndex(current => current.id === issue.id);
  if (index === -1) {
    return state;
  }
  else if (issue.workflow.status !== WorkflowStatusTypes.Resolved) {
    const updatedIssues = update(state, {
      openIssues: {
        $splice: [[index, 1, issue]],
      }
    });
    return sortIssues(updatedIssues);
  }
  return update(state, {
    openIssues: {
      $splice: [[index, 1]],
    }
  });
}

function updateOpenIssuesDispatchStatus(state, dispatch) {
  if (!state.openIssues || !state.openIssues.length || !dispatch.issues || !dispatch.issues.length) {
    return state;
  }

  const dispatchedIssuesById = groupBy(dispatch.issues, 'id');
  const updatedOpenIssues = state.openIssues.map(current => {
    const dispatchedIssue = dispatchedIssuesById[current.id];
    if (dispatchedIssue) {
      return Object.assign({}, current, dispatchedIssue);
    }
    return current;
  });

  return update(state, {
    openIssues: {
      $set: updatedOpenIssues,
    }
  });
}

function addNewIssue(state, issue) {
  const existingOpenIssues = state.openIssues || [];
  const updatedOpenIssues = [issue];
  updatedOpenIssues.push(...existingOpenIssues);
  return Object.assign({}, state, {
    openIssues: sortIssues(updatedOpenIssues),
  });
}

function IssueRootReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_OPEN_ISSUES_STARTED:
      return Object.assign({}, state, {
        openIssues: null,
        openIssuesLoading: true,
        openIssuesError: null,
      });
    case ActionTypes.RECEIVE_OPEN_ISSUES:
      return Object.assign({}, state, {
        openIssues: sortIssues(action.data),
        openIssuesLoading: false,
      });
    case ActionTypes.GET_OPEN_ISSUES_FAILED:
      return Object.assign({}, state, {
        openIssuesLoading: false,
        openIssuesError: action.error,
      });

    case WorkflowActionTypes.UPDATE_WORKFLOW_SUCCESS:
      return updateOpenIssueWorkflow(state, action.issue);
    case SiteDispatchActionTypes.CREATE_DISPATCH_SUCCESS:
      return updateOpenIssuesDispatchStatus(state, action.dispatch);
    case NewIssueActionTypes.CREATE_ISSUE_SUCCESS:
      return addNewIssue(state, action.issue);

    default:
      return state;
  }
}

const CombinedReducer = combineReducers({
  root: IssueRootReducer,
  edit: IssueEditReducer,
  organizer: OrganizerReducer,
  list: SiteIssueListReducer,
});

export default CombinedReducer;
