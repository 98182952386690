import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Shepherd from 'shepherd.js';

import { AuthenticationSelectors } from '../../../authentication';
import * as selectors from './TourSelectors';
import * as actions from './TourActions';

const PopperOptions = {
  modifiers: [
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [0, 20]
      }
    }
  ]
};

const withTour = (tourName, steps) => WrappedComponent => {
  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      classes: 'canary-shepherd-tour',
      cancelIcon: {
        enabled: true
      }
    },
    useModalOverlay: true
  });

  const updatedSteps = steps.map(current => Object.assign({}, current, { popperOptions: PopperOptions }));
  tour.addSteps(updatedSteps);

  class CanaryTour extends Component {
    constructor(props) {
      super(props);
      this.startTour = this.startTour.bind(this);
      this.handleTourCompleted = this.handleTourCompleted.bind(this);

      Shepherd.on('close', this.handleTourCompleted);
      Shepherd.on('cancel', this.handleTourCompleted);
      Shepherd.on('complete', this.handleTourCompleted);

      this.mounted = false;
      this.tourListPromise = this.props.getCompletedTours();
    }

    componentDidMount() {
      if (!this.props.userId) {
        return;
      }
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
      Shepherd.off('close', this.handleTourCompleted);
      Shepherd.off('cancel', this.handleTourCompleted);
      Shepherd.off('complete', this.handleTourCompleted);
    }

    startTour() {
      this.tourListPromise.then(() => {
        if (this.mounted && !this.props.completedTours.includes(tourName)) {
          tour.start();
        }
      });
    }

    handleTourCompleted() {
      this.props.markTourComplete();
    }

    render() {
      return <WrappedComponent startTour={this.startTour} {...this.props} />;
    }
  }

  CanaryTour.propTypes = {
    userId: PropTypes.number,
    completedTours: PropTypes.array.isRequired,
    getCompletedTours: PropTypes.func.isRequired,
    markTourComplete: PropTypes.func.isRequired,
  };

  CanaryTour.defaultProps = {
    userId: null,
  };

  function mapStateToProps(state) {
    return {
      userId: AuthenticationSelectors.currentUserId(state),
      completedTours: selectors.tours(state),
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      getCompletedTours: () => dispatch(actions.getCompletedTours()),
      markTourComplete: () => dispatch(actions.markTourComplete(tourName))
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(CanaryTour);
};

export default withTour;
