import { combineReducers } from 'redux';
import update from 'immutability-helper';

import { buildReducer, HookTypes } from '../../../../../../shared/redux-helpers/ReducerFactory';
import CommentReducer from './components/comments/CommentReducer';
import MviReportReducer from './components/report/MviReportReducer';
import ActionTypes from './MviEditActionTypes';

function unsetProperties(mvi) {
  return update(mvi, {
    $unset: ['comments', 'reports'],
  });
}

const MviEditRootReducer = buildReducer(ActionTypes, { [HookTypes.ItemReceive]: unsetProperties });

export default combineReducers({
  root: MviEditRootReducer,
  comments: CommentReducer,
  reports: MviReportReducer
});
