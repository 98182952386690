import ActionTypes from './SiteDashboardActionTypes';

export default function AdminReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_SITE_HEALTH_STARTED:
      return Object.assign({}, state, {
        siteHealthLoading: true,
      });
    case ActionTypes.RECEIVE_SITE_HEALTH:
      return Object.assign({}, state, {
        siteHealth: action.siteHealth,
        siteHealthLoading: false,
      });
    case ActionTypes.GET_SITE_HEALTH_FAILED:
      return Object.assign({}, state, {
        siteHealth: null,
        siteHealthLoading: false,
      });

    case ActionTypes.GET_COMMAND_REQUEST_HEALTH_STARTED:
      return Object.assign({}, state, {
        commandRequestHealthLoading: true,
      });
    case ActionTypes.RECEIVE_COMMAND_REQUEST_HEALTH:
      return Object.assign({}, state, {
        commandRequestHealth: action.commandRequestHealth,
        commandRequestHealthLoading: false,
      });
    case ActionTypes.GET_COMMAND_REQUEST_HEALTH_FAILED:
      return Object.assign({}, state, {
        commandRequestHealth: null,
        commandRequestHealthLoading: false,
      });
    default:
      return state;
  }
}
