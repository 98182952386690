import {Get} from '../HttpUtils';

let vrCommandPromise;

export default {
  getCommandRequestHealth() {
    return Get('/v2/commands/health').then(response => response.data);
  },

  getVrCommandNamesByFunctionCode() {
    if (!vrCommandPromise) {
      vrCommandPromise = Get('/v2/commands/types').then((response) => {
        const commandNamesByFuncCode = {};
        response.data.forEach((current) => {
          commandNamesByFuncCode[current.functionCode] = current.functionName;
        });
        return commandNamesByFuncCode;
      });
    }

    return vrCommandPromise;
  },
};
