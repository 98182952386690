import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import classNames from 'classnames';
import ErrorMessage from '../../../../../../../../shared/components/error-message/ErrorMessage';
import * as actions from '../actions';
import * as selectors from '../SiteEditorSelectors';
import ConnectionEditor from '../components/connection/ConnectionEditor';
import DeleteConnectionModal from '../components/connection/DeleteConnectionModal';
import SiteAppFeatures from '../components/SiteAppFeatures';
import NewSite from './NewSite';
import LatLon from '../components/LatLon';
import SiteFields from '../../../../../../../../shared/components/site-fields/SiteFields';
import CanarySummaryBar from '../../../../../../../../shared/components/CanarySummaryBar';
import EntityNotificationLog from '../../notification-log/containers/EntityNotificationLog';
import * as OrganizationEditSelectors from '../../../OrganizationEditSelectors';
import { AuthenticationSelectors } from '../../../../../../../../authentication/index';
import { SiteConnectionStatus } from '../../../../../../../../AppConstants';
import { dateOrTimeAsText } from '../../../../../../../../shared/components/date/FormatUtils';
import ConnectionType from '../../../../../../../../shared/components/ConnectionType';

class SiteEditor extends Component {
  constructor(props) {
    super(props);

    this.handleSave = this.handleSave.bind(this);
    this.handleSaveSiteConnection = this.handleSaveSiteConnection.bind(this);
    this.renderLastConnected = this.renderLastConnected.bind(this);

    if (props.match && props.match.params && props.match.params.siteId) {
      props.selectSite(props.match.params.siteId);
    }
  }

  handleSave() {
    this.props.saveSelectedSite();
  }

  handleSaveSiteConnection() {
    if (this.props.shouldConfirmDeleteSiteConnection) {
      this.props.confirmDeleteSiteConnection();
    }
    else {
      this.props.saveSiteConnection();
    }
  }

  renderContent() {
    return (
      <Switch>
        <Redirect exact="exact" from={`${this.props.match.url}/`} to={`${this.props.match.url}/name`} />
        <Route exact path={`${this.props.match.url}/name`}>
          <SiteFields
            site={this.props.pendingEdits}
            showCustomerSelector={false}
            siteLabels={this.props.labels}
            onUpdate={this.props.editSelectedSite}
            onSave={this.handleSave}
            canSave={!this.props.isPendingSiteEditsPristine}
            onCancel={this.props.discardPendingSiteEdits}
            canCancel={!this.props.isPendingSiteEditsPristine}
            showEasyVistaId={!!(this.props.customerDispatchSystems.find(d => d.systemName === 'EasyVista'))}
          >
            <LatLon selectedSite={this.props.pendingEdits} onUpdate={this.props.editSelectedSite} />
          </SiteFields>
        </Route>
        <Route exact path={`${this.props.match.url}/connection`}>
          <>
            <ConnectionEditor
              siteConnectionDetail={this.props.siteConnectionDetail}
              selectedSite={this.props.selectedSite}
              userCanEdit={this.props.userCanEditConnection}
              isEditing={this.props.isEditingSiteConnection}
              onEdit={this.props.editConnection}
              onCancel={this.props.cancelEditConnection}
              onSave={this.handleSaveSiteConnection}
              onValueChange={this.props.siteConnectionDetailChange}
              isSaving={this.props.isSavingSiteConnection}
            />
            <DeleteConnectionModal
              open={this.props.openDeleteSiteConnectionModal}
              onCancel={this.props.cancelDeleteSiteConnection}
              onConfirm={this.props.saveSiteConnection}
            />
          </>
        </Route>
        <Route exact path={`${this.props.match.url}/features`}>
          <SiteAppFeatures
            selectedSite={this.props.pendingEdits}
            updateSelectedSite={this.props.editSelectedSite}
            onSave={this.props.saveSelectedSite}
            canSave={!this.props.isPendingSiteEditsPristine}
            onCancel={this.props.discardPendingSiteEdits}
            canCancel={!this.props.isPendingSiteEditsPristine}
          />
        </Route>
        <Route exact path={`${this.props.match.url}/notifications`}>
          <EntityNotificationLog notifications={this.props.entityNotifications} />
        </Route>
      </Switch>
    );
  }

  renderLastConnected() {
    if (!this.props.selectedSite || !this.props.selectedSite.connection) {
      return null;
    }
    const site = this.props.selectedSite;
    const ok = site.connection.connectionStatus === SiteConnectionStatus.OK;
    const lastConnected = site.connection.latestEventDate ? `Last connected ${dateOrTimeAsText(site.connection.latestEventDate)}` : 'Last connected - N/A';
    return (
      <div className="d-inline-block">
        <span className={classNames({ 'text-danger': !ok }, 'mr-2')}>{lastConnected}</span>
        <ConnectionType
          connectionType={site.connection && site.connection.connectionType}
          connectionStatus={site.connection && site.connection.connectionStatus}
          latestEventDate={site.connection && site.connection.latestEventDate}
          style={{ height: '2rem' }}
        />
      </div>
    );
  }

  render() {
    if (!this.props.pendingEdits && this.props.error) {
      return (
        <div className="mt-4">
          <ErrorMessage title="Failed to load site" error={this.props.error} />
        </div>
      );
    }
    else if (this.props.isLoading || this.props.isSaving || !this.props.pendingEdits) {
      return (
        <PulseLoader color="#F8E71C" size={8} />
      );
    }
    else if (!Number.isInteger(this.props.pendingEdits.id)) {
      return <NewSite />;
    }

    return (
      <div className="row mt-4">
        <div className="col-md-2">
          <div className="nav flex-column nav-pills">
            <NavLink to={`${this.props.match.url}/name`} className="nav-link">Name & Location</NavLink>
            <NavLink to={`${this.props.match.url}/connection`} className="nav-link">Connection</NavLink>
            <NavLink to={`${this.props.match.url}/features`} className="nav-link">Features</NavLink>
            <NavLink to={`${this.props.match.url}/notifications`} className="nav-link">Notifications</NavLink>
          </div>
        </div>
        <div className="col-md-10">
          <ErrorMessage title="Failed to save site" error={this.props.error} />
          <CanarySummaryBar summary={this.props.selectedSite.nickname} controls={this.renderLastConnected()} />
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

SiteEditor.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  error: PropTypes.object,
  selectedSite: PropTypes.object,
  pendingEdits: PropTypes.object,
  isPendingSiteEditsPristine: PropTypes.bool.isRequired,
  labels: PropTypes.array.isRequired,
  selectSite: PropTypes.func.isRequired,
  editSelectedSite: PropTypes.func.isRequired,
  saveSelectedSite: PropTypes.func.isRequired,
  discardPendingSiteEdits: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  entityNotifications: PropTypes.array.isRequired,
  userCanEditConnection: PropTypes.bool.isRequired,
  isEditingSiteConnection: PropTypes.bool.isRequired,
  editConnection: PropTypes.func.isRequired,
  cancelEditConnection: PropTypes.func.isRequired,
  saveSiteConnection: PropTypes.func.isRequired,
  siteConnectionDetailChange: PropTypes.func.isRequired,
  siteConnectionDetail: PropTypes.object,
  shouldConfirmDeleteSiteConnection: PropTypes.bool.isRequired,
  confirmDeleteSiteConnection: PropTypes.func.isRequired,
  openDeleteSiteConnectionModal: PropTypes.bool.isRequired,
  cancelDeleteSiteConnection: PropTypes.func.isRequired,
  isSavingSiteConnection: PropTypes.bool.isRequired,
  customerDispatchSystems: PropTypes.array.isRequired,
};

SiteEditor.defaultProps = {
  error: null,
  selectedSite: null,
  pendingEdits: null,
  siteConnectionDetail: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isSelectedSiteLoading(state),
    isSaving: selectors.isSaving(state),
    error: selectors.siteError(state),
    selectedSite: selectors.selectedSite(state),
    pendingEdits: selectors.pendingSiteEdits(state),
    isPendingSiteEditsPristine: selectors.pendingSiteEditsPristine(state),
    labels: OrganizationEditSelectors.selectedOrganizationSiteLabels(state),
    entityNotifications: selectors.entityNotifications(state),
    userCanEditConnection: AuthenticationSelectors.isAdmin(state),
    isEditingSiteConnection: selectors.isEditingSiteConnection(state),
    siteConnectionDetail: selectors.siteConnectionDetail(state),
    shouldConfirmDeleteSiteConnection: selectors.shouldConfirmDeleteSiteConnection(state),
    openDeleteSiteConnectionModal: selectors.openDeleteSiteConnectionModal(state),
    isSavingSiteConnection: selectors.isSavingSiteConnection(state),
    customerDispatchSystems: OrganizationEditSelectors.selectedOrganizationDispatchSystems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectSite: id => dispatch(actions.selectSite(id)),
    editSelectedSite: updatedSite => dispatch(actions.editSelectedSite(updatedSite)),
    saveSelectedSite: () => dispatch(actions.saveSelectedSite()),
    discardPendingSiteEdits: () => dispatch(actions.discardPendingSiteEdits()),
    editConnection: () => dispatch(actions.editConnection()),
    cancelEditConnection: () => dispatch(actions.cancelEditConnection()),
    saveSiteConnection: () => dispatch(actions.saveConnection()),
    siteConnectionDetailChange: siteConnectionDetail => dispatch(actions.updateSiteConnectionDetail(siteConnectionDetail)),
    confirmDeleteSiteConnection: () => dispatch(actions.openDeleteSiteConnectionDetailModal()),
    cancelDeleteSiteConnection: () => dispatch(actions.closeDeleteSiteConnectionDetailModal()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteEditor));
