import React from 'react';
import PropTypes from 'prop-types';

const Summarize = ({ items, summaryFunc, maxCount }) => {
  if (!items || !items.length) {
    return null;
  }

  const parts = items.slice(0, maxCount).map(summaryFunc);
  if (items.length > maxCount) {
    const remaining = items.length - maxCount;
    parts.push(`and ${remaining} more`);
  }

  if (parts.length < 3) {
    return <span>{parts.join(' ')}</span>;
  }
  return <span>{parts.join(', ')}</span>;
};

Summarize.propTypes = {
  items: PropTypes.array,
  summaryFunc: PropTypes.func.isRequired,
  maxCount: PropTypes.number,
};

Summarize.defaultProps = {
  items: [],
  maxCount: 3
};

export default Summarize;
