import ActionTypes from './InventoryActionTypes';

export default function InventoryReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_INVENTORY_STARTED:
      return Object.assign({}, state, {
        inventoryData: null,
        inventoryLoading: true,
        inventoryError: null,
      });
    case ActionTypes.RECEIVE_INVENTORY:
      return Object.assign({}, state, {
        inventoryData: action.data,
        inventoryLoading: false,
      });
    case ActionTypes.GET_INVENTORY_FAILED:
      return Object.assign({}, state, {
        inventoryLoading: false,
        inventoryError: action.error,
      });

    default:
      return state;
  }
}
