import React from 'react';
import PropTypes from 'prop-types';

const RemoteATGButton = ({ children, onClick }) => {
  return (
    <button
      style={{
        borderWidth: '1px',
        borderColor: '#979797',
        borderStyle: 'solid',
        borderRadius: '0.25em',
        background: '#ffffff',
        color: '#4a90e2',
        padding: '0.5em',
        width: '100%',
        textAlign: 'left',
        cursor: 'pointer',
        outline: 'none',
      }}
      onClick={onClick}
      className="mb-1"
    >
      {children}
    </button>
  );
};

RemoteATGButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RemoteATGButton;
