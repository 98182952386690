import { OrganizationClient } from '../../../../../../client';
import ActionTypes from '../OrganizationListActionTypes';
import * as selectors from '../OrganizationListSelectors';

function getOrganizationListStarted() {
  return {
    type: ActionTypes.GET_ORGANIZATION_LIST_STARTED,
  };
}

function receiveOrganizationList(organizationList) {
  return {
    type: ActionTypes.RECEIVE_ORGANIZATION_LIST,
    organizationList,
  };
}

function getOrganizationListFailed(error) {
  return {
    type: ActionTypes.GET_ORGANIZATION_LIST_FAILED,
    error
  };
}

export function getOrganizationList() {
  return (dispatch, getState) => {
    const isLoading = selectors.isLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch(getOrganizationListStarted());
    return OrganizationClient.getOrganizationOverviews()
      .then(organizationList => {
        dispatch(receiveOrganizationList(organizationList));
      })
      .catch(err => {
        dispatch(getOrganizationListFailed(err));
        return Promise.reject(err);
      });
  };
}

const deleteOrgStarted = () => ({ type: ActionTypes.DELETE_ORGANIZATION_STARTED });
const deleteOrgSuccess = (organization) => ({ type: ActionTypes.DELETE_ORGANIZATION_SUCCESS, organization });
const deleteOrgFailed = (error) => ({ type: ActionTypes.DELETE_ORGANIZATION_FAILED, error });

export function deleteOrganization(organization) {
  return (dispatch, getState) => {
    const state = getState();
    if (selectors.isUpdating(state)) {
      return Promise.resolve();
    }

    dispatch(deleteOrgStarted());
    return OrganizationClient.deleteById(organization)
      .then(() => dispatch(deleteOrgSuccess(organization)))
      .catch(err => {
        dispatch(deleteOrgFailed(err));
        return Promise.reject(err);
      });
  };
}

const updateOrgStarted = () => ({ type: ActionTypes.UPDATE_ORGANIZATION_STARTED });
const updateOrgSuccess = (organization) => ({ type: ActionTypes.UPDATE_ORGANIZATION_SUCCESS, organization });
const updateOrgFailed = (error) => ({ type: ActionTypes.UPDATE_ORGANIZATION_FAILED, error });

export function toggleOrganizationEnabled(organization) {
  return (dispatch, getState) => {
    const state = getState();
    if (selectors.isUpdating(state)) {
      return Promise.resolve();
    }

    const patchData = {
      enabled: !organization.enabled
    };

    dispatch(updateOrgStarted());
    return OrganizationClient.patch(organization.id, patchData)
      .then((updatedOrg) => dispatch(updateOrgSuccess(updatedOrg)))
      .catch(err => {
        dispatch(updateOrgFailed(err));
        return Promise.reject(err);
      });
  };
}
