import React from 'react';
import PropTypes from "prop-types";

const ConfigurationEditorPlaceHolder = () => (<div />);

ConfigurationEditorPlaceHolder.propTypes = {
  onDispatchSystemConfigurationValueChanged: PropTypes.func.isRequired,
};

export default ConfigurationEditorPlaceHolder;
