import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Switch from 'react-switch';
import objectPath from 'object-path';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import withEither from './withEither';

const switchInputFn = props => props.edit && props.field.editable;

const SwitchInput = props =>
  <Switch
    id={props.id}
    onChange={checked => props.onUpdateField(props.target, props.field, checked)}
    checked={objectPath.get(props.target, props.field.path) || false}
    className="react-switch"
  />;


const SwitchViewer = props =>
  <div className="py-2">
    { objectPath.get(props.target, props.field.path) && (objectPath.get(props.target, props.field.path).toString() === 'true' || objectPath.get(props.target, props.field.path).toString() === 'false') ?
       objectPath.get(props.target, props.field.path) && objectPath.get(props.target, props.field.path).toString() === 'true' ? <FontAwesomeIcon icon={faCheck} /> : null
    :
    '\u2014'
}
  </div>;

const withConditionalRenderings = compose(
      withEither(switchInputFn, SwitchInput),
    );

const SwitchWithConditionalRendering = withConditionalRenderings(SwitchViewer);

const SwitchInputField = props => (
  <div className="mb-3">
    <label htmlFor={`field${props.panel.id.toString()}${props.field.id.toString()}`}>{props.field.label}:</label>
    <SwitchWithConditionalRendering id={`field${props.panel.id.toString()}${props.field.id.toString()}`} {...props} />
  </div>
  );

SwitchInputField.propTypes = {
  field: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
};

SwitchInput.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

SwitchViewer.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default SwitchInputField;

