import { combineReducers } from 'redux';

import ComplianceReportReducer from './screens/compliance-report/ComplianceReportReducer';
import ComplianceListReducer from './screens/compliance-list/ComplianceListReducer';
import ActionTypes from './ComplianceActionTypes';

function ComplianceRootReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_LATEST_REPORT_STARTED:
      return Object.assign({}, state, {
        latestCompliance: null,
        latestComplianceLoading: true,
        latestComplianceError: null,
      });
    case ActionTypes.RECEIVE_LATEST_REPORT:
      return Object.assign({}, state, {
        latestCompliance: action.data,
        latestComplianceLoading: false,
      });
    case ActionTypes.GET_LATEST_REPORT_FAILED:
      return Object.assign({}, state, {
        latestComplianceLoading: false,
        latestComplianceError: action.error,
      });

    default:
      return state;
  }
}

const CombinedReducer = combineReducers({
  root: ComplianceRootReducer,
  list: ComplianceListReducer,
  report: ComplianceReportReducer
});

export default CombinedReducer;
