export const TablesTour = {
  tourName: 'tables-tour-202109',
  steps: [
    {
      id: 'views-action-button',
      title: 'View Action Button',
      text: 'Customize this table with the new Manage Views button. Filter the data and choose the columns you want to see. You can save one or more views, and pick one to be your default.',
      attachTo: {
        element: '#view-action-button',
        on: 'bottom'
      },
      buttons: [
        {
          action() {
            return this.next();
          },
          classes: 'btn btn-primary btn-sm',
          text: 'Next'
        }
      ],
    },
    {
      id: 'views-dropdown',
      title: 'Views Dropdown',
      text: 'Use the View Selector to choose your view. Canary has provided some defaults. To manage a view, make sure it’s selected and then use the Manage Views button to make it your default or delete it.',
      attachTo: {
        element: '#views-dropdown',
        on: 'left-start'
      },
      buttons: [
        {
          action() {
            return this.back();
          },
          classes: 'btn btn-secondary btn-sm mr-2',
          text: 'Back'
        },
        {
          action() {
            return this.complete();
          },
          classes: 'btn btn-primary btn-sm',
          text: 'Done'
        }
      ],
    },
  ]
};
