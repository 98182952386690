import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import CanaryTable from './CanaryTable';

class LoadMoreTable extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      count: this.props.initialCount,
    };
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  handleLoadMore() {
    this.setState(currentState => ({ count: currentState.count + 5 }));
  }

  render() {
    const { initialCount, data, ...rest } = this.props;
    const filteredData = data.slice(0, this.state.count);
    const hasMore = filteredData.length < this.props.data.length;
    return (
      <div className={this.props.className}>
        <CanaryTable data={filteredData} {...rest} />
        {hasMore ? <Button color="primary" size="sm" outline onClick={this.handleLoadMore}>Load more...</Button> : null}
      </div>
    );
  }
}

LoadMoreTable.propTypes = {
  initialCount: PropTypes.number,
  className: PropTypes.string,

  data: PropTypes.array,
  idField: PropTypes.string,
  idFunc: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

LoadMoreTable.defaultProps = {
  initialCount: 5,
  className: null,
  data: null,
  idField: 'id',
  idFunc: null,
};

export default LoadMoreTable;
