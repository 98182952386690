import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import withTour from '../../../shared/components/tour/WithTour';
import { withTracking } from '../../../shared/analytics';
import * as NavContextActions from '../../../shared/components/nav-context/NavContextActions';
import buildViewBar from '../../../shared/components/table/view-manager/ViewBar';
import buildViewControls from '../../../shared/components/table/view-manager/ViewControls';
import buildViewManagerActions from '../../../shared/components/table/view-manager/ViewManagerActions';
import { FilterType, ViewCategory } from '../../../shared/components/table/view-manager/ViewManagerConstants';
import ErrorMessage from '../../../shared/components/error-message/ErrorMessage';
import CanaryLoadingIndicator from '../../../shared/components/CanaryLoadingIndicator';
import { TablesTour } from '../../UserTours';
import { navToSite } from '../../sites/actions';
import SiteTable from '../components/SiteTable';
import SiteListSelectors, { SiteListTableSelectors, SiteViewManagerSelectors } from '../SiteListSelectors';
import { SiteTableColumns } from '../SiteListConstants';
import ActionTypes from '../SiteListActionTypes';
import * as actions from '../actions';

function pluralize(sites) {
  return sites && sites.length === 1 ? 'Site' : 'Sites';
}

const viewManagerActions = buildViewManagerActions(ActionTypes, SiteViewManagerSelectors, ViewCategory.Site);
const SiteFilterTypes = [FilterType.Customer, FilterType.ConnectionType, FilterType.ConnectionStatus, FilterType.State, FilterType.Site, FilterType.Label];
const SiteViewBar = buildViewBar(viewManagerActions, SiteListSelectors, SiteViewManagerSelectors, SiteFilterTypes);
const SiteViewControls = buildViewControls(viewManagerActions, SiteListSelectors, SiteViewManagerSelectors, SiteTableColumns);

class SiteList extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.getSites = this.getSites.bind(this);
    this.handleSiteClick = this.handleSiteClick.bind(this);
  }

  componentDidMount() {
    this.props.clearNavContext();
  }

  getSites() {
    this.props.getSites().then(() => this.props.startTour());
  }

  handleSiteClick(site) {
    this.props.navToSite(site, this.props.filteredSites);
  }

  render() {
    if (this.props.error) {
      return (
        <div className="mt-2">
          <ErrorMessage error={this.props.error} />
        </div>
      );
    }

    return (
      <>
        <Row className="mb-2">
          <Col md={7}>
            <h5 className="mb-0">
              {this.renderTitle()}
              {this.props.isRefreshing ? <CanaryLoadingIndicator inline /> : null}
            </h5>
          </Col>
          <Col md={5}>
            <SiteViewControls onChange={this.getSites} />
          </Col>
        </Row>
        <SiteViewBar onChange={this.getSites} />
        {this.renderTable()}
      </>
    );
  }

  renderTitle() {
    if (this.props.isRefreshing || this.props.isLoading) {
      return null;
    }
    const count = (this.props.sites && this.props.sites.length) || 0;
    return `${count.toLocaleString()} ${pluralize(this.props.sites)}`;
  }

  renderTable() {
    if (this.props.isLoading || this.props.isRefreshing) {
      return <CanaryLoadingIndicator />;
    }

    return (
      <SiteTable
        sites={this.props.filteredSites}
        columns={this.props.columns}
        sortColumnId={this.props.sortColumnId}
        sortDirection={this.props.sortDirection}
        onSortChange={this.props.changeSort}
        currentPage={this.props.currentPage}
        onPageChange={this.props.changePage}
        pageSize={this.props.pageSize}
        pageCount={this.props.pageCount}
        onSiteSelected={this.handleSiteClick}
      />
    );
  }
}

SiteList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  sites: PropTypes.array,
  filteredSites: PropTypes.array,

  getSites: PropTypes.func.isRequired,
  navToSite: PropTypes.func.isRequired,
  clearNavContext: PropTypes.func.isRequired,

  // filters and columns prefs
  columns: PropTypes.array.isRequired,
  sortColumnId: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  changeSort: PropTypes.func.isRequired,

  // table pagination support
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,

  // feature tour
  startTour: PropTypes.func.isRequired,
};

SiteList.defaultProps = {
  error: null,
  sites: [],
  filteredSites: [],
};

function mapStateToProps(state) {
  return {
    isLoading: SiteListSelectors.isLoading(state),
    isRefreshing: SiteListSelectors.isRefreshing(state),
    error: SiteListSelectors.error(state),
    sites: SiteListSelectors.list(state),
    filteredSites: SiteListTableSelectors.filteredList(state),
    columns: SiteViewManagerSelectors.selectedColumns(state),
    sortColumnId: SiteListTableSelectors.sortColumnId(state) || SiteTableColumns.Nickname,
    sortDirection: SiteListTableSelectors.sortDirection(state),
    currentPage: SiteListTableSelectors.currentPage(state),
    pageSize: SiteListTableSelectors.pageSize(state),
    pageCount: SiteListTableSelectors.pageCount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSites: () => dispatch(actions.getSites()),

    navToSite: (siteId, list) => dispatch(navToSite(siteId, list)),
    clearNavContext: () => dispatch(NavContextActions.clearContext()),

    // table pagination
    changeSort: (sortColumnId, sortDirection) => dispatch(actions.changeSort(sortColumnId, sortDirection)),
    changePage: page => dispatch(actions.changePage(page)),
  };
}

export default withTour(TablesTour.tourName, TablesTour.steps)(withTracking('Site List')(connect(mapStateToProps, mapDispatchToProps)(SiteList)));
