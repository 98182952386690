import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faExclamationTriangle, faUserEdit } from '@fortawesome/pro-solid-svg-icons';
import { green, red, gray500, gray900 } from '../../../../scss/_variables.scss';
import ComplianceStatus from './ComplianceStatus';

class Icon {
  constructor(icon, color) {
    this.icon = icon;
    this.color = color;
  }
}

const Overridden = new Icon(faUserEdit, gray900);
const Passed = new Icon(faCheckCircle, green);
const Failed = new Icon(faExclamationTriangle, red);
const Inconclusive = new Icon(faCircle, gray500);

const StatusIcon = ({ size, status, calculatedStatusOverridden }) => {
  let icon = Inconclusive;
  if (calculatedStatusOverridden) {
    icon = Overridden;
  }
  else if (ComplianceStatus.Passed === status || ComplianceStatus.Late === status) {
    icon = Passed;
  }
  else if (ComplianceStatus.Failed === status) {
    icon = Failed;
  }

  return <FontAwesomeIcon icon={icon.icon} color={icon.color} style={{ marginTop: '-0.2em', marginRight: '0.5rem', height: size, width: size }} />;
};

StatusIcon.propTypes = {
  size: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  calculatedStatusOverridden: PropTypes.bool,
};

StatusIcon.defaultProps = {
  calculatedStatusOverridden: false,
};

export default StatusIcon;
