import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row, Button, Alert } from 'reactstrap';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import NonProdWarning from '../../../../../../shared/components/non-prod-warning/NonProdWarning';
import TabView from '../../../../../../shared/components/TabView';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import ChooseSite from '../components/ChooseSite';
import DeviceActivationDetails from '../components/DeviceActivationDetails';
import SiteInfo from '../components/SiteInfo';
import AddSite from '../components/AddSite';
import * as selectors from '../ActivationSelectors';
import * as actions from '../actions';


class SiteSelectionContainer extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.state = {
      dismissedSiteNames: [],
      userSelectedSites: [],
    };

    const id = this.props.match && this.props.match.params && this.props.match.params.deviceId;
    if (id) {
      this.props.getActivation(id);
    }

    this.activate = this.activate.bind(this);
    this.dismissNearbySite = this.dismissNearbySite.bind(this);
    this.addUserSelectedSite = this.addUserSelectedSite.bind(this);
    this.dismissUserSelectedSite = this.dismissUserSelectedSite.bind(this);
    this.renderSiteList = this.renderSiteList.bind(this);
    this.renderChooseSite = this.renderChooseSite.bind(this);
    this.renderAddSite = this.renderAddSite.bind(this);
  }

  activate(site) {
    return this.props.processActivation(this.props.selectedActivation.id, site);
  }

  dismissNearbySite(site) {
    const dismissedSiteNames = [...this.state.dismissedSiteNames];
    dismissedSiteNames.push(site.siteName);
    this.setState({
      dismissedSiteNames,
    });
  }

  addUserSelectedSite(site) {
    const userSelectedSites = [...this.state.userSelectedSites];
    userSelectedSites.push(site);
    this.setState({
      userSelectedSites,
    });
  }

  dismissUserSelectedSite(site) {
    const userSelectedSites = this.state.userSelectedSites.filter(current => current.siteName !== site.siteName);
    this.setState({
      userSelectedSites,
    });
  }

  renderLoading() {
    return (
      <div className="mt-3">
        <PulseLoader color="#F8E71C" size={8} />
      </div>
    );
  }

  renderSiteList() {
    if (this.props.nearbySites && this.props.nearbySites.length) {
      const dismissedSiteNames = this.state.dismissedSiteNames;
      const sites = this.props.nearbySites.filter(site => dismissedSiteNames.indexOf(site.siteName) === -1);
      if (sites.length) {
        return (
          <div className="mt-4">
            {sites.map(site => (<SiteInfo site={site} onActivate={this.activate} onDismiss={this.dismissNearbySite} key={site.siteName} />))}
          </div>
        );
      }
    }
    return (<div className="mt-4">No nearby sites</div>);
  }

  renderChooseSite() {
    return (
      <ChooseSite
        userSelectedSites={this.state.userSelectedSites}
        onActivate={this.activate}
        onAddUserSelectedSite={this.addUserSelectedSite}
        onDismissUserSelectedSite={this.dismissUserSelectedSite}
      />
    );
  }

  renderAddSite() {
    return (
      <AddSite
        initialState={this.props.selectedActivation.state}
        initialSiteTimezone={this.props.selectedActivation.siteTimezone}
        onSave={() => this.activate(this.state.site)}
      />
    );
  }

  render() {
    if (this.props.isLoading) {
      return this.renderLoading();
    }
    else if (this.props.isError) {
      return <ErrorMessage title="Activation failed" error={this.props.error} />;
    }
    else if (!this.props.selectedActivation) {
      return null;
    }

    const tabs = [
      {name: 'Matching Sites', render: this.renderSiteList},
      {name: 'Choose Another Site', render: this.renderChooseSite},
      {name: 'Add a Site', render: this.renderAddSite}
    ];

    return (
      <Row>
        <Col>
          <NonProdWarning>
            <Alert color="warning">Warning: any changes made on this screen will affect the production environment!</Alert>
          </NonProdWarning>
          <Button color="link" onClick={this.props.navToDeviceActivationList}>&lt; Activation Requests</Button>
          <DeviceActivationDetails activation={this.props.selectedActivation} />
          <TabView className="mt-3" tabs={tabs} />
        </Col>
      </Row>
    );
  }
}

SiteSelectionContainer.propTypes = {
  match: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.object,
  selectedActivation: PropTypes.object,
  nearbySites: PropTypes.array,
  getActivation: PropTypes.func.isRequired,
  processActivation: PropTypes.func.isRequired,
  navToDeviceActivationList: PropTypes.func.isRequired,
};

SiteSelectionContainer.defaultProps = {
  error: null,
  selectedActivation: null,
  nearbySites: [],
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isSelectedActivationLoading(state) || selectors.isActivationInProgress(state),
    isError: selectors.isError(state),
    error: selectors.error(state),
    selectedActivation: selectors.getSelectedActivation(state),
    nearbySites: selectors.getNearbySites(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getActivation: id => dispatch(actions.getActivation(id)),
    processActivation: (id, site) => dispatch(actions.processActivation(id, site)),
    navToDeviceActivationList: () => dispatch(actions.navToDeviceActivationList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SiteSelectionContainer));
