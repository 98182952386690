/* eslint-disable no-plusplus */
import { push } from 'react-router-redux';
import FileSaver from 'file-saver';
import update from 'immutability-helper/index';
import Papa from 'papaparse';

import { FuelHaulerClient, SiteClient } from '../../../../../../client';
import { PresentError, PresentNotification } from '../../../../../../shared/ToastUtils';
import FuelHaulersActionTypes from '../FuelHaulersActionTypes';
import * as selectors from '../FuelHaulersSelectors';
import validate from './validation';

const NEW = 'new';

const DEFAULT_FUEL_HAULER = {
  id: NEW,
  name: '',
  enabled: true,
  sites: [],
  schedules: [],
  destinations: [],
};

function getFuelHaulerListStarted() {
  return {
    type: FuelHaulersActionTypes.GET_FUEL_HAULER_LIST_STARTED,
  };
}

function getFuelHaulerListFailed() {
  return {
    type: FuelHaulersActionTypes.GET_FUEL_HAULER_LIST_FAILED,
  };
}

function receiveFuelHaulerList(fuelHaulerList) {
  return {
    type: FuelHaulersActionTypes.RECEIVE_FUEL_HAULER_LIST,
    fuelHaulerList,
  };
}

export function getFuelHaulerList() {
  return (dispatch, getState) => {
    const isLoading = selectors.isLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch(getFuelHaulerListStarted());
    return FuelHaulerClient.getList()
      .then(fuelHaulerList => dispatch(receiveFuelHaulerList(fuelHaulerList)))
      .catch((err) => {
        dispatch(getFuelHaulerListFailed());
        return Promise.reject(err);
      });
  };
}


function getFuelHaulerStarted() {
  return {
    type: FuelHaulersActionTypes.GET_FUEL_HAULER_STARTED,
  };
}

function getFuelHaulerFailed() {
  return {
    type: FuelHaulersActionTypes.GET_FUEL_HAULER_FAILED,
  };
}

function receiveFuelHauler(selectedFuelHauler) {
  return {
    type: FuelHaulersActionTypes.RECEIVE_FUEL_HAULER,
    selectedFuelHauler,
  };
}

function receiveValidationErrors(validationErrors) {
  return {
    type: FuelHaulersActionTypes.RECEIVE_VALIDATION_ERRORS,
    validationErrors,
  };
}

export function selectFuelHauler(fuelHauler) {
  return (dispatch, getState) => {
    const isLoading = selectors.isLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    const id = fuelHauler.id || fuelHauler;
    if (id === NEW) {
      dispatch(receiveValidationErrors([]));
      dispatch(receiveFuelHauler(DEFAULT_FUEL_HAULER));
      return Promise.resolve();
    }

    dispatch(getFuelHaulerStarted());
    return FuelHaulerClient.getById(id)
      .then((restFuelHauler) => {
        dispatch(receiveFuelHauler(restFuelHauler));
      })
      .catch((err) => {
        dispatch(getFuelHaulerFailed());
        return Promise.reject(err);
      });
  };
}


export function navToFuelHauler(fuelHauler) {
  return (dispatch, getState) => {
    const state = getState();

    const id = fuelHauler.id || fuelHauler;
    const currentPath = state.router.location.pathname;
    const newPath = `/admin/inventory/fuel-haulers/${id}`;

    if (currentPath !== newPath) {
      dispatch(push(newPath));
    }
  };
}

export function navToNewFuelHauler() {
  return navToFuelHauler(NEW);
}

export function downloadExportPreview(id) {
  return (dispatch) => {
    if (!id) {
      return Promise.resolve();
    }

    dispatch({ type: FuelHaulersActionTypes.GET_EXPORT_PREVIEW_STARTED });
    return FuelHaulerClient.exportPreview(id)
      .then((result) => {
        if (result && result.length) {
          const exportPreview = {
            created: new Date(),
            files: result,
          };
          dispatch({ type: FuelHaulersActionTypes.RECEIVE_EXPORT_PREVIEW, exportPreview });
        }
        else {
          const empty = { files: [] };
          dispatch({ type: FuelHaulersActionTypes.RECEIVE_EXPORT_PREVIEW, exportPreview: empty });
        }
      })
      .catch((err) => {
        dispatch({ type: FuelHaulersActionTypes.GET_EXPORT_PREVIEW_FAILED });
        return Promise.reject(err);
      });
  };
}

function buildSiteCsv(sites) {
  const csvLines = ['"site_name","nickname","fuel_hauler_external_id"'];
  sites.forEach((site) => {
    csvLines.push(`"${site.siteName}","${site.nickname || ''}","${site.fuelHaulerExternalId || ''}"`);
  });
  return csvLines.join('\n');
}

export function exportSelectedFuelHaulerSites() {
  return (dispatch, getState) => {
    const selectedFuelHauler = selectors.selectedFuelHauler(getState());
    if (!selectedFuelHauler || !selectedFuelHauler.sites || !selectedFuelHauler.sites.length) {
      return;
    }

    const data = buildSiteCsv(selectedFuelHauler.sites);
    const filename = `${selectedFuelHauler.name} sites.csv`;
    const file = new File([data], filename, { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(file);
  };
}

function getColIndex(headerRow, aliases) {
  for (let i = 0; i < headerRow.length; i++) {
    const currentValue = headerRow[i].toLowerCase();
    if (aliases.indexOf(currentValue) !== -1) {
      return i;
    }
  }
  return -1;
}

function getSitesFromCsv(rawCsvData) {
  const parsed = Papa.parse(rawCsvData);
  if (parsed.errors.length && !(parsed.errors.length === 1 && parsed.errors[0].code === 'UndetectableDelimiter')) {
    PresentError('Import failed - invalid CSV file');
    return null;
  }

  const headerRow = parsed.data[0];
  const siteNameColIndex = getColIndex(headerRow, ['site_name', 'site name', 'site']);
  const fuelHaulerExternalIdColIndex = getColIndex(headerRow, ['fuel_hauler_external_id', 'fuel hauler external id', 'external_id']);
  if (siteNameColIndex === -1) {
    PresentError('Import failed - did not find site_name column');
    return null;
  }

  const sites = [];
  for (let i = 1; i < parsed.data.length; i++) {
    const currentRow = parsed.data[i];
    const site = {
      siteName: currentRow[siteNameColIndex],
    };
    if (fuelHaulerExternalIdColIndex !== -1) {
      site.fuelHaulerExternalId = currentRow[fuelHaulerExternalIdColIndex] || null;
    }
    if (site.siteName && site.siteName.trim()) {
      sites.push(site);
    }
  }
  return sites;
}

export function importSites(rawCsvData) {
  return (dispatch, getState) => {
    const sitesFromCsv = getSitesFromCsv(rawCsvData);
    if (sitesFromCsv === null) {
      return Promise.resolve();
    }

    const currentFuelHauler = selectors.selectedFuelHauler(getState());
    const existingSiteNames = [];
    if (currentFuelHauler.sites) {
      currentFuelHauler.sites.forEach(currentSite => existingSiteNames.push(currentSite.siteName));
    }

    const sitesToImport = sitesFromCsv.filter(currentSite => existingSiteNames.indexOf(currentSite.siteName) === -1);
    if (!sitesToImport.length) {
      PresentNotification('No sites to import');
      return Promise.resolve();
    }

    const siteNamesToImport = sitesToImport.map(current => current.siteName);
    return SiteClient.getSitesBySiteName(siteNamesToImport)
      .then((sitesByName) => {
        const sitesToImportWithSiteIds = sitesToImport
          .map((current) => {
            const site = sitesByName[current.siteName];
            if (!site) {
              return null;
            }
            return update(current, {
              id: {
                $set: site.id,
              },
            });
          })
          .filter(current => current !== null);

        if (!sitesToImportWithSiteIds.length) {
          PresentNotification('No sites to import');
          return Promise.resolve();
        }

        const updatedFuelHauler = update(currentFuelHauler, {
          sites: {
            $push: sitesToImportWithSiteIds,
          },
        });
        dispatch(receiveFuelHauler(updatedFuelHauler));
        PresentNotification(`Imported ${sitesToImportWithSiteIds.length} sites`);
        return Promise.resolve();
      });
  };
}

export function updateSelectedFuelHauler(updatedFuelHauler) {
  return receiveFuelHauler(updatedFuelHauler);
}

export function cancelSelectedFuelHaulerEdit() {
  return (dispatch) => {
    dispatch(push('/admin/inventory/fuel-haulers'));
    dispatch(receiveFuelHauler(null));
  };
}

function validateFuelHauler() {
  return (dispatch, getState) =>
    validate(getState()).then((validationErrors) => {
      if (validationErrors.length) {
        dispatch(receiveValidationErrors(validationErrors));
        return false;
      }
      return true;
    });
}

function saveFuelHaulerStarted() {
  return {
    type: FuelHaulersActionTypes.SAVE_FUEL_HAULER_STARTED,
  };
}

function saveFuelHaulerSuccess() {
  return {
    type: FuelHaulersActionTypes.SAVE_FUEL_HAULER_SUCCESS,
  };
}

function saveFuelHaulerFailed(error) {
  return {
    type: FuelHaulersActionTypes.SAVE_FUEL_HAULER_FAILED,
    error,
  };
}

function doSave(selectedFuelHauler) {
  return (dispatch) => {
    let toSave = selectedFuelHauler;
    if (selectedFuelHauler.id === NEW) {
      toSave = update(selectedFuelHauler, {
        id: {
          $set: null,
        },
      });
    }

    dispatch(saveFuelHaulerStarted());
    return FuelHaulerClient.save(toSave)
      .then(() => {
        dispatch(saveFuelHaulerSuccess());
        dispatch(cancelSelectedFuelHaulerEdit());
      })
      .catch((err) => {
        dispatch(saveFuelHaulerFailed(err));
        return Promise.reject(err);
      });
  };
}

export function saveSelectedFuelHauler() {
  return (dispatch, getState) => {
    const isSaving = selectors.isSaving(getState());
    if (isSaving) {
      return Promise.resolve();
    }

    const selectedFuelHauler = selectors.selectedFuelHauler(getState());
    if (!selectedFuelHauler) {
      return Promise.resolve();
    }

    return dispatch(validateFuelHauler()).then((valid) => {
      if (valid) {
        return dispatch(doSave(selectedFuelHauler));
      }
      return null;
    });
  };
}

export function deleteFuelHauler(fuelHauler) {
  return (dispatch, getState) => {
    const isDeleting = selectors.isDeleting(getState());
    if (isDeleting) {
      return Promise.resolve();
    }

    const id = fuelHauler.id || fuelHauler;
    dispatch({ type: FuelHaulersActionTypes.DELETE_FUEL_HAULER_STARTED });
    return FuelHaulerClient.deleteById(id)
      .then(() => {
        const updatedFuelHaulerList = selectors.fuelHaulerList(getState()).filter(current => current.id !== id);
        dispatch(receiveFuelHaulerList(updatedFuelHaulerList));
        dispatch({ type: FuelHaulersActionTypes.DELETE_FUEL_HAULER_COMPLETE });
      });
  };
}
