import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import CanarySummaryBar from '../../../../../../../../shared/components/CanarySummaryBar';
import ErrorMessage from '../../../../../../../../shared/components/error-message/ErrorMessage';
import * as orgEditSelectors from '../../../OrganizationEditSelectors';
import Members from './Members';
import GroupPolicies from './GroupPolicies';
import * as actions from '../actions';
import * as selectors from '../GroupEditorSelectors';


class GroupEditor extends Component {
  constructor(props) {
    super(props);

    if (props.match && props.match.params && props.match.params.groupId) {
      props.selectGroup(props.match.params.groupId);
    }
  }

  renderContent() {
    return (
      <Switch>
        <Redirect exact="exact" from={`${this.props.match.url}/`} to={`${this.props.match.url}/members`} />
        <Route exact path={`${this.props.match.url}/members`} name="group-members">
          <Members />
        </Route>
        <Route exact path={`${this.props.match.url}/access`} name="group-access">
          <GroupPolicies />
        </Route>
      </Switch>
    );
  }

  render() {
    if (!this.props.selectedGroup && this.props.error) {
      return (
        <div className="mt-4">
          <ErrorMessage title="Failed to load group" error={this.props.error} />
        </div>
      );
    }
    else if (this.props.isLoading || !this.props.selectedGroup) {
      return (
        <PulseLoader color="#F8E71C" size={8} />
      );
    }
    else if (!Number.isInteger(this.props.selectedGroup.id)) {
      return (
        <div className="mt-4">
          <ErrorMessage title="Failed to save group" error={this.props.error} />
          <Members />
        </div>
      );
    }

    return (
      <div className="row mt-4">
        <div className="col-md-2">
          <div className="nav flex-column nav-pills">
            <NavLink to={`${this.props.match.url}/members`} className="nav-link">Members</NavLink>
            <NavLink to={`${this.props.match.url}/access`} className="nav-link">Access</NavLink>
          </div>
        </div>
        <div className="col-md-10">
          <ErrorMessage title="Failed to save group" error={this.props.error} />
          <CanarySummaryBar summary={this.props.selectedGroup.name} />
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

GroupEditor.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  selectedGroup: PropTypes.object,
  selectGroup: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

GroupEditor.defaultProps = {
  isLoading: false,
  error: null,
  selectedGroup: null,
};

function mapStateToProps(state) {
  return {
    isLoading: orgEditSelectors.isLoading(state),
    error: orgEditSelectors.error(state),
    selectedGroup: selectors.selectedGroup(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectGroup: id => dispatch(actions.selectGroup(id)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupEditor));
