import { Get, Delete, Post } from '../HttpUtils';

const IngestFailureFields = {
  Id: 'id',
  SubsystemType: 'subsystem',
  Description: 'description',
  Source: 'source',
  CustomerId: 'customerId',
  CustomerName: 'customerName',
  SiteId: 'siteId',
  SiteName: 'siteName',
  SiteNickname: 'siteNickname',
  Created: 'created',
  ExceptionDetails: 'exceptionDetails',
  Files: 'files',
};

const IdFields = Object.values(IngestFailureFields).join(',');
const ListFields = `${IngestFailureFields.Id},${IngestFailureFields.SubsystemType},${IngestFailureFields.Description},${IngestFailureFields.Source},${IngestFailureFields.CustomerName},${IngestFailureFields.Created}`;

export default {
  getById(id) {
    if (!id) {
      return Promise.resolve();
    }
    return Get(`/v2/ingest-failures/${id}?include=${IdFields}`).then(response => response.data);
  },

  getFileLink(ingestFailure, file) {
    const ingestFailureId = (ingestFailure && ingestFailure.id) || ingestFailure;
    const fileId = (file && file.id) || file;
    if (!ingestFailureId || !fileId) {
      return Promise.resolve();
    }
    return Get(`/v2/ingest-failures/${ingestFailureId}/files/${fileId}/link`).then(response => response.data.url);
  },

  getList() {
    return Get(`/v2/ingest-failures?include=${ListFields}`).then(response => response.data);
  },

  convertToMvi(id, mviRequest) {
    return Post(`/v2/ingest-failures/${id}/mvi`, mviRequest).then(response => response.data);
  },

  deleteIngestFailure(ingestFailure) {
    const id = (ingestFailure && ingestFailure.id) || ingestFailure;
    if (!id) {
      return Promise.resolve();
    }
    return Delete(`/v2/ingest-failures/${id}`).then(response => response.data);
  }
};
