/* eslint-disable react/no-unused-prop-types,comma-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget, DragSource } from 'react-dnd';

import Registration from './Registration';

class RegistrationDropTargetDragSource extends Component {
  render() {
    const style = {};
    if (this.props.canDrop && this.props.isOver) {
      style.border = '1px dashed gray';
    }

    return this.props.connectDropTarget(
      this.props.connectDragSource(
        <div className="py-1" style={style}>
          <Registration registration={this.props.registration} />
        </div>
      )
    );
  }
}

const registrationTargetSpec = {
  drop(props, monitor) {
    const item = monitor.getItem();
    props.linkTankAndRegistration(item, props.registration);
  },

  canDrop(props) {
    return props.registration.tank === null || props.registration.tank === undefined;
  },
};

function targetCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}


const registrationSourceSpec = {
  beginDrag(props) {
    return props.registration;
  },
  canDrag(props) {
    return props.registration && !!props.registration.tank;
  },
};

function sourceCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    canDrag: monitor.canDrag(),
    isDragging: monitor.isDragging(),
  };
}

RegistrationDropTargetDragSource.propTypes = {
  registration: PropTypes.object.isRequired,
  linkTankAndRegistration: PropTypes.func.isRequired,

  // react-dnd target properties
  connectDropTarget: PropTypes.func.isRequired,
  canDrop: PropTypes.bool,
  isOver: PropTypes.bool,

  // react-dnd source properties
  connectDragSource: PropTypes.func.isRequired,
  canDrag: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired,
};

RegistrationDropTargetDragSource.defaultProps = {
  // react-dnd target properties
  canDrop: false,
  isOver: false,
};

export default DropTarget('TANK', registrationTargetSpec, targetCollect)(
  DragSource('REG', registrationSourceSpec, sourceCollect)(RegistrationDropTargetDragSource)
);
