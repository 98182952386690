import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import update from 'immutability-helper';

import EasyVistaConfigurationEditor from '../components/EasyVistaConfigurationEditor';
import ConfigurationEditorPlaceholder from '../components/ConfigurationEditorPlaceholder';


function getEditor(systemName) {
  if (systemName === 'EasyVista') {
    return EasyVistaConfigurationEditor;
  }

  return ConfigurationEditorPlaceholder;
}

class DispatchSystemEditorContainer extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.toggle = this.toggle.bind(this);
  }

  toggle(enabled) {
    const updatedDispatchConfigurationSettings = update(this.props.dispatchSystemConfiguration, {
      enabled: {
        $set: enabled,
      },
    });

    this.props.onDispatchSystemConfigurationValueChanged(updatedDispatchConfigurationSettings, this.props.canSave);
  }

  render() {
    const systemName = (this.props.dispatchSystemConfiguration && this.props.dispatchSystemConfiguration.systemName) || 'Placeholder';
    const Editor = getEditor(systemName);
    const enabled = (this.props.dispatchSystemConfiguration && this.props.dispatchSystemConfiguration.enabled) || false;

    return (
      <div>
        <Row className="mb-1">
          <Col sm={8}>
            <Form>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={enabled}
                    onChange={event => this.toggle(event.target.checked)}
                  />
                  Enabled
                </Label>
              </FormGroup>
              <Editor onDispatchSystemConfigurationValueChanged={this.props.onDispatchSystemConfigurationValueChanged} dispatchSystemConfiguration={this.props.dispatchSystemConfiguration} />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

DispatchSystemEditorContainer.propTypes = {
  onDispatchSystemConfigurationValueChanged: PropTypes.func.isRequired,
  canSave: PropTypes.bool.isRequired,
  dispatchSystemConfiguration: PropTypes.object,
};

DispatchSystemEditorContainer.defaultProps = {
  dispatchSystemConfiguration: {},
};

export default DispatchSystemEditorContainer;
