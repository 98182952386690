export default {
  RECEIVE_ITEM: 'sites/mvi/edit/report/receive_report',
  RECEIVE_LIST: 'sites/mvi/edit/report/receive-list',

  BEGIN_ITEM_EDIT: 'sites/mvi/edit/report/begin_edit',
  EDIT_ITEM: 'sites/mvi/edit/report/edit',
  CANCEL_ITEM_EDIT: 'sites/mvi/edit/report/cancel_edit',

  SAVE_ITEM_STARTED: 'sites/mvi/edit/report/save_report_started',
  SAVE_ITEM_SUCCESS: 'sites/mvi/edit/report/save_report_success',
  SAVE_ITEM_FAILED: 'sites/mvi/edit/report/save_report_failed',

  DELETE_ITEM_STARTED: 'sites/mvi/edit/report/delete_report_started',
  DELETE_ITEM_SUCCESS: 'sites/mvi/edit/report/delete_report_success',
  DELETE_ITEM_FAILED: 'sites/mvi/edit/report/delete_report_failed',

  OPEN_CREATE_MODAL: 'sites/mvi/edit/report/open_create_modal',
  CLOSE_CREATE_MODAL: 'sites/mvi/edit/report/close_create_modal',

  OPEN_DELETE_MODAL: 'sites/mvi/edit/report/open_delete_modal',
  CLOSE_DELETE_MODAL: 'sites/mvi/edit/report/close_delete_modal',

  BEGIN_INSPECTOR_COMMENTS_EDIT: 'sites/mvi/edit/report/begin_inspector_comments_edit',
  EDIT_INSPECTOR_COMMENTS: 'sites/mvi/edit/report/edit_inspector_comments',
  CANCEL_INSPECTOR_COMMENTS_EDIT: 'sites/mvi/edit/report/cancel_inspector_comments_edit',
};
