import { IssueClient } from '../../../../../../../../client';
import { AuthenticationSelectors } from '../../../../../../../../authentication';
import * as IssueSelectors from '../../IssueEditSelectors';
import ActionTypes from './CommentActionTypes';


export function showEditForm() {
  return { type: ActionTypes.EDIT_COMMENT };
}

export function cancelEditForm() {
  return { type: ActionTypes.CANCEL_EDIT_COMMENT };
}

export function addComment(commentText) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentIssue = IssueSelectors.currentIssue(currentState);
    if (!currentIssue) {
      return Promise.resolve();
    }

    const userId = AuthenticationSelectors.currentUserId(currentState);

    const comment = {
      commentText,
      userId,
    };

    dispatch({ type: ActionTypes.SAVE_COMMENT_STARTED });
    return IssueClient.addComment(currentIssue, comment)
      .then(comments => {
        dispatch({ type: ActionTypes.SAVE_COMMENT_SUCCESS, comments });
        return comments;
      })
      .catch(error => {
        dispatch({ type: ActionTypes.SAVE_COMMENT_FAILED, error });
      });
  };
}
