import React from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

import { DurationTypes } from '../ExportHistoryConstants';

const DurationDropdown = ({ durationType, className, onDurationSelect }) => {
  return (
    <Input
      type="select"
      id="duration"
      name="duration"
      value={durationType || ''}
      className={className}
      onChange={event => onDurationSelect(event.target.value || null)}
    >
      <option value="">Show All Durations</option>
      {Object.keys(DurationTypes).map(key => (
        <option value={key} key={key}>{DurationTypes[key].label}</option>
      ))}
    </Input>
  );
};


DurationDropdown.propTypes = {
  durationType: PropTypes.string,
  className: PropTypes.string,
  onDurationSelect: PropTypes.func.isRequired,
};

DurationDropdown.defaultProps = {
  durationType: null,
  className: null,
};

export default DurationDropdown;
