import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import ScheduleBuilderModal from '../../../../../components/schedule-builder/ScheduleBuilderModal';
import DeliveryURL from '../../../../../components/export-destination/components/DeliveryURL';

class FuelHaulerScheduleModal extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      transmitSchedule: null,
      selectedDestinations: [],
    };
    this.isValid = this.isValid.bind(this);
    this.handleScheduleChange = this.handleScheduleChange.bind(this);
    this.handleDestinationCheck = this.handleDestinationCheck.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.modalOpen !== newProps.modalOpen) {
      this.setState({
        transmitSchedule: null,
        selectedDestinations: [],
      });
    }
  }

  isValid() {
    return !!this.state.transmitSchedule && !!this.state.selectedDestinations.length;
  }

  isDestinationChecked(destination) {
    return this.state.selectedDestinations.some(current => current.deliveryUrl === destination.deliveryUrl);
  }

  handleScheduleChange(schedule) {
    this.setState({
      transmitSchedule: schedule && schedule.stringValue,
    });
  }

  handleDestinationCheck(destination) {
    if (this.isDestinationChecked(destination)) {
      this.setState({
        selectedDestinations: this.state.selectedDestinations.filter(current => current.deliveryUrl !== destination.deliveryUrl),
      });
    }
    else {
      this.setState({
        selectedDestinations: this.state.selectedDestinations.concat([destination]),
      });
    }
  }

  handleSave() {
    const schedule = {
      transmitSchedule: this.state.transmitSchedule,
      destinations: this.state.selectedDestinations,
    };
    this.props.onSave(schedule);
  }

  render() {
    return (
      <ScheduleBuilderModal
        modalOpen={this.props.modalOpen}
        onSave={this.handleSave}
        onCancel={this.props.onCancel}
        onScheduleChange={this.handleScheduleChange}
        validate={this.isValid}
      >
        <Form>
          <Row>
            <Col md={2}>
              <span>Send To</span>
            </Col>
            <Col md={10}>
              {this.props.destinations && this.props.destinations.map(currentDest => (
                <FormGroup check key={currentDest.deliveryUrl}>
                  <Label check>
                    <Input type="checkbox" checked={this.isDestinationChecked(currentDest)} onChange={() => this.handleDestinationCheck(currentDest)} />
                    <DeliveryURL url={currentDest.deliveryUrl} />
                  </Label>
                </FormGroup>
              ))}
            </Col>
          </Row>
        </Form>
      </ScheduleBuilderModal>
    );
  }
}

FuelHaulerScheduleModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  destinations: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

FuelHaulerScheduleModal.defaultProps = {
  destinations: [],
};

export default FuelHaulerScheduleModal;
