import React from 'react';
import PropTypes from 'prop-types';

const Suggestion = ({ site, className, style }) => (
  <div className={className} style={style}>
    {site.nickname}
  </div>
);

Suggestion.propTypes = {
  site: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

Suggestion.defaultProps = {
  className: null,
  style: null,
};

export default Suggestion;
