import React from 'react';
import PropTypes from 'prop-types';

import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

const NumberBox = ({ loading, value, suffix, title, precision, className }) => {
  if (loading) {
    return <PulseLoader color={'#F8E71C'} size={8} />;
  }

  let roundedValue = 0;
  if (value !== null && value !== undefined) {
    roundedValue = value.toFixed(precision);
  }

  let classes = 'number-box';
  if (className) {
    classes += ` ${className}`;
  }

  return (
    <div className={classes}>
      <span>{roundedValue}{suffix ? ` ${suffix}` : ''}</span>
      <span>{title}</span>
    </div>
  );
};

NumberBox.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.number,
  suffix: PropTypes.string,
  title: PropTypes.string.isRequired,
  precision: PropTypes.number,
  className: PropTypes.string,
};

NumberBox.defaultProps = {
  loading: false,
  value: 0,
  suffix: '',
  precision: 1,
  className: null,
};

export default NumberBox;
