import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { TableStyles } from '../../../../../../shared/components/CanaryTable';
import CanaryTableAndSummary from '../../../../../../shared/components/CanaryTableAndSummary';
import FormattedTimestamp from '../../../../../../shared/components/date/FormattedTimestamp';
import Summarize from '../../../../../../shared/components/Summarize';
import DeleteButton from '../../../../../../shared/components/buttons/DeleteButton';

const Columns = {
  Description: { display: 'DESCRIPTION', columnStyle: TableStyles.ColumnStyles.FIRST_COLUMN, width: '30%' },
  Permission: { display: 'PERMISSION', columnStyle: TableStyles.ColumnStyles.TEXT, width: '20%' },
  Sites: { display: 'SITES', columnStyle: TableStyles.ColumnStyles.TEXT, width: '30%' },
  Created: { display: 'CREATED', columnStyle: TableStyles.ColumnStyles.TEXT, width: '10%' },
  Delete: { display: '', columnStyle: TableStyles.ColumnStyles.TEXT, width: '10%' },
};


function siteSummary(policy) {
  if (policy.siteGrantType === 'All') {
    return policy.siteGrantType;
  }
  return (<Summarize items={policy.grantedSites} summaryFunc={site => site.nickname} maxCount={1} />);
}

function buildRow(policy, onPolicyEdit, onPolicyDelete) {
  const columns = [];

  if (onPolicyEdit) {
    columns.push({
      display: <Button color="link" className="text-left" onClick={() => onPolicyEdit(policy)}>{policy.description}</Button>,
      columnStyle: TableStyles.ColumnStyles.FIRST_COLUMN,
    });
  }
  else {
    columns.push({
      display: policy.description,
      columnStyle: TableStyles.ColumnStyles.FIRST_COLUMN,
    });
  }

  columns.push({
    display: policy.permissionSet.name,
    columnStyle: TableStyles.ColumnStyles.TEXT,
  });

  columns.push({
    display: siteSummary(policy),
    columnStyle: TableStyles.ColumnStyles.TEXT,
  });

  columns.push({
    display: <FormattedTimestamp dateOnly time={policy.created} />,
    columnStyle: TableStyles.ColumnStyles.TEXT,
  });

  if (onPolicyDelete) {
    columns.push({
      display: <DeleteButton onClick={() => onPolicyDelete(policy)} />,
      columnStyle: TableStyles.ColumnStyles.TEXT,
    });
  }

  return {
    metadata: {},
    columns,
  };
}

const PolicyTable = ({ title, policies, onPolicyEdit, onPolicyDelete }) => {
  const headers = [Columns.Description, Columns.Permission, Columns.Sites, Columns.Created];
  if (onPolicyDelete) {
    headers.push(Columns.Delete);
  }

  const data = policies.map(policy => buildRow(policy, onPolicyEdit, onPolicyDelete));
  return (<CanaryTableAndSummary summary={title} header={headers} data={data} prefix="policy" />);
};

PolicyTable.propTypes = {
  title: PropTypes.any,
  policies: PropTypes.arrayOf(PropTypes.shape({
    permissionSet: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    siteGrantType: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
  })).isRequired,
  onPolicyEdit: PropTypes.func,
  onPolicyDelete: PropTypes.func,
};

PolicyTable.defaultProps = {
  title: null,
  onPolicyEdit: null,
  onPolicyDelete: null,
};

export default PolicyTable;
