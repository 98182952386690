import Selectors from '../../shared/redux-helpers/Selectors';
import TableSelectors from '../../shared/components/table/redux/TableSelectors';
import ViewManagerSelectors from '../../shared/components/table/view-manager/ViewManagerSelectors';

const accessor = state => state && state.compliance;
const selectors = new Selectors(accessor);

export default selectors;

export const ComplianceListTableSelectors = new TableSelectors(accessor);
export const ComplianceListViewManagerSelectors = new ViewManagerSelectors(accessor);
