import React from 'react';
import PropTypes from 'prop-types';

import ATGReport from './ATGReport';

const AtgReports = ({ reports, className }) => (
  <div className={className}>
    {reports.map(current => {
      const key = current.eventId || current.id;
      const sectionId = `event_${key}`;
      return (
        <div key={key} className="mb-2">
          <section id={sectionId} />
          <ATGReport report={current} />
        </div>
      );
    })}
  </div>
);

AtgReports.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    eventId: PropTypes.number,
    data: PropTypes.string,
  })).isRequired,
  className: PropTypes.string,
};

AtgReports.defaultProps = {
  className: null,
};

export default AtgReports;
