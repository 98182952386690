import ActionTypes from './FileFormatActionTypes';

function canSave(selectedInventoryFormat) {
  return !!(selectedInventoryFormat &&
    selectedInventoryFormat.name &&
    selectedInventoryFormat.name.trim().length &&
    selectedInventoryFormat.columns &&
    selectedInventoryFormat.columns.length);
}

export default function AdminReducer(state = {}, action) {
  switch (action.type) {

    case ActionTypes.GET_INVENTORY_FORMAT_LIST_STARTED:
      return Object.assign({}, state, {
        inventoryFormatList: [],
        formatListLoading: true,
      });
    case ActionTypes.GET_INVENTORY_FORMAT_LIST_FAILED:
      return Object.assign({}, state, {
        inventoryFormatList: [],
        formatListLoading: false,
      });
    case ActionTypes.RECEIVE_INVENTORY_FORMAT_LIST:
      return Object.assign({}, state, {
        inventoryFormatList: action.inventoryFormatList,
        formatListLoading: false,
      });

    case ActionTypes.GET_INVENTORY_COLUMN_CATEGORIES_STARTED:
      return Object.assign({}, state, {
        inventoryColumnCategories: [],
        columnCategoriesLoading: true,
      });
    case ActionTypes.GET_INVENTORY_COLUMN_CATEGORIES_FAILED:
      return Object.assign({}, state, {
        inventoryColumnCategories: [],
        columnCategoriesLoading: false,
      });
    case ActionTypes.RECEIVE_INVENTORY_COLUMN_CATEGORIES:
      return Object.assign({}, state, {
        inventoryColumnCategories: action.inventoryColumnCategories,
        columnCategoriesLoading: false,
      });

    case ActionTypes.GET_INVENTORY_FORMAT_STARTED:
      return Object.assign({}, state, {
        selectedInventoryFormatLoading: true,
        selectedInventoryFormat: null,
      });
    case ActionTypes.GET_INVENTORY_FORMAT_FAILED:
      return Object.assign({}, state, {
        selectedInventoryFormatLoading: false,
        selectedInventoryFormat: null,
      });
    case ActionTypes.RECEIVE_INVENTORY_FORMAT:
      return Object.assign({}, state, {
        selectedInventoryFormatLoading: false,
        selectedInventoryFormat: action.selectedInventoryFormat,
        canSaveSelectedInventoryFormat: canSave(action.selectedInventoryFormat),
      });

    case ActionTypes.SAVE_INVENTORY_FORMAT_STARTED:
      return Object.assign({}, state, {
        saving: true,
      });
    case ActionTypes.SAVE_INVENTORY_FORMAT_SUCCESS:
      return Object.assign({}, state, {
        saving: false,
        selectedInventoryFormat: null,
      });
    case ActionTypes.SAVE_INVENTORY_FORMAT_FAILED:
      return Object.assign({}, state, {
        saving: false,
      });

    case ActionTypes.DELETE_INVENTORY_FORMAT_STARTED:
      return Object.assign({}, state, {
        deleting: true,
      });
    case ActionTypes.DELETE_INVENTORY_FORMAT_COMPLETE:
      return Object.assign({}, state, {
        deleting: false,
        selectedInventoryFormat: null,
      });

    default:
      return state;
  }
}
