import NotificationLogConstants from './NotificationLogConstants';


const notifications = (state) => (state && state.admin && state.admin.organizations && state.admin.organizations.notifications) || {};

export const entityNotificationContentStatus = (state) => notifications(state).entityNotificationStatus || NotificationLogConstants.contentStatus.NotLoaded;

export const entityNotificationContent = (state) => notifications(state) && notifications(state).entityNotificationContent;

export const showEntityNotificationContent = (state) => (notifications(state) && notifications(state).showEntityNotificationContent) || false;

export const entityNotificationContentError = (state) => notifications(state) && notifications(state).error;
