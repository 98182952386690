import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Row, Col } from 'reactstrap';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import NonProdWarning from '../../../../../../shared/components/non-prod-warning/NonProdWarning';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import DeviceActivationRow from '../components/DeviceActivationRow';
import * as selectors from '../ActivationSelectors';
import * as actions from '../actions';


class DeviceActivationContainer extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.props.getActivationsList();
  }

  renderLoading() {
    return (
      <div className="mt-3">
        <PulseLoader color="#F8E71C" size={8} />
      </div>
    );
  }

  render() {
    if (this.props.isLoading) {
      return this.renderLoading();
    }
    else if (this.props.isError) {
      return <ErrorMessage title="Activation failed" error={this.props.error} />;
    }
    else if (this.props.activationsList && this.props.activationsList.length) {
      return (
        <div>
          <NonProdWarning>
            <Alert color="warning">Warning: any changes made on this screen will affect the production environment!</Alert>
          </NonProdWarning>
          <h4>Activation Requests</h4>
          <Row className="border-bottom mb-2">
            <Col md={2} className="align-self-center">
              <h6>Cellular</h6>
            </Col>
            <Col md={4} className="align-self-center">
              <h6>Date & Time</h6>
            </Col>
            <Col md={6} className="align-self-center">
              <h6>Near</h6>
            </Col>
          </Row>
          {this.props.activationsList.map(current => (
            <DeviceActivationRow activation={current} onActivate={this.props.navToDeviceActivation} onDelete={this.props.deleteActivation} key={current.id} />
          ))}
        </div>
      );
    }

    return (
      <Row>
        <Col>
          <h5>No pending device activations.</h5>
        </Col>
      </Row>
    );
  }
}

DeviceActivationContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.object,
  activationsList: PropTypes.array.isRequired,
  getActivationsList: PropTypes.func.isRequired,
  deleteActivation: PropTypes.func.isRequired,
  navToDeviceActivation: PropTypes.func.isRequired,
};

DeviceActivationContainer.defaultProps = {
  error: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isActivationListLoading(state) || selectors.isDeleteInProgress(state),
    isError: selectors.isError(state),
    error: selectors.error(state),
    activationsList: selectors.getActivationList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getActivationsList: () => dispatch(actions.getActivationsList()),
    deleteActivation: id => dispatch(actions.deleteActivation(id)),
    navToDeviceActivation: activation => dispatch(actions.navToDeviceActivation(activation)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeviceActivationContainer));
