export default {
  REFRESH_LIST_STARTED: 'issue-list/refresh_list_started',
  REFRESH_LIST_CANCELED: 'issue-list/refresh_list_canceled',
  REFRESH_LIST_FAILED: 'issue-list/refresh_list_failed',

  GET_LIST_STARTED: 'issue-list/get_list_started',
  RECEIVE_LIST: 'issue-list/receive_list',
  GET_LIST_FAILED: 'issue-list/get_list_failed',

  CHANGE_SORT: 'issue-list/change_sort',
  CHANGE_PAGE: 'issue-list/change_page',

  // view manager actions
  GET_VIEWS_STARTED: 'issue-list/get_views_started',
  RECEIVE_VIEWS: 'issue-list/receive_views',
  GET_VIEWS_FAILED: 'issue-list/get_views_failed',
  SELECT_VIEW: 'issue-list/select_view',
  TOGGLE_DEFAULT_VIEW: 'issue-list/toggle_default_view',
  SAVE_VIEW_STARTED: 'issue-list/save_view_started',
  SAVE_VIEW_SUCCESS: 'issue-list/save_view_success',
  SAVE_VIEW_FAILED: 'issue-list/save_view_failed',
  DELETE_VIEW_STARTED: 'issue-list/delete_view_started',
  DELETE_VIEW_SUCCESS: 'issue-list/delete_view_success',
  DELETE_VIEW_FAILED: 'issue-list/delete_view_failed',
  START_FILTER_EDIT: 'issue-list/start_filter_edit',
  CANCEL_FILTER_EDIT: 'issue-list/cancel_filter_edit',
  CLEAR_FILTERS: 'issue-list/clear_filters',
  SELECT_CUSTOMERS: 'issue-list/select_customers',
  DESELECT_CUSTOMER: 'issue-list/deselect_customer',
  SELECT_SITE_STATES: 'issue-list/select_site_states',
  DESELECT_SITE_STATE: 'issue-list/deselect_site_state',
  GET_SITE_LABELS_STARTED: 'issue-list/get_site_labels_started',
  RECEIVE_SITE_LABELS: 'issue-list/receive_site_labels',
  GET_SITE_LABELS_FAILED: 'issue-list/get_site_labels_failed',
  SELECT_SITE_LABELS: 'issue-list/select_site_labels',
  DESELECT_SITE_LABEL: 'issue-list/deselect_site_label',
  SELECT_CONNECTION_TYPES: 'issue-list/select_connection_types',
  DESELECT_CONNECTION_TYPE: 'issue-list/deselect_connection_type',
  SELECT_ISSUE_TYPES: 'issue-list/select_issue_types',
  DESELECT_ISSUE_TYPE: 'issue-list/deselect_issue_type',
  SELECT_ISSUE_WORKFLOW_STATUSES: 'issue-list/select_workflow_statuses',
  DESELECT_ISSUE_WORKFLOW_STATUS: 'issue-list/deselect_workflow_status',
  SELECT_SITES: 'issue-list/select_sites',
  DESELECT_SITE: 'issue-list/deselect_sites',
  SET_COLUMNS: 'issue-list/set_columns',
};
