import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from './BreadcrumbActions';

class CreateBreadcrumb extends Component {
  componentDidMount() {
    let name = this.props.name;
    if (typeof this.props.name === 'function') {
      name = this.props.name();
    }
    if (name) {
      this.props.pushBreadcrumb();
    }
  }

  render() {
    return null;
  }
}

CreateBreadcrumb.propTypes = {
  name: PropTypes.any.isRequired,
  pushBreadcrumb: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    pushBreadcrumb: () => dispatch(actions.push(ownProps.name, ownProps.match.url, ownProps.match.key))
  };
}

export default withRouter(connect(undefined, mapDispatchToProps)(CreateBreadcrumb));
