import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

const AdditionalInformationModal = ({ content, isOpen, onClose }) => (
  <Modal isOpen={isOpen} size="lg">
    <ModalHeader toggle={onClose}>Additional Information</ModalHeader>
    <ModalBody>
      <pre>{content}</pre>
    </ModalBody>
  </Modal>
);

AdditionalInformationModal.propTypes = {
  content: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AdditionalInformationModal.defaultProps = {
  content: null,
};

export default AdditionalInformationModal;
