export default class Selectors {
  constructor(stateAccessor) {
    this._accessor = stateAccessor;
    this.isLoading = this.isLoading.bind(this);
    this.isRefreshing = this.isRefreshing.bind(this);
    this.isEditing = this.isEditing.bind(this);
    this.isSaving = this.isSaving.bind(this);
    this.isDeleting = this.isDeleting.bind(this);
    this.error = this.error.bind(this);
    this.list = this.list.bind(this);
    this.listEtag = this.listEtag.bind(this);
    this.item = this.item.bind(this);
    this.pendingEdits = this.pendingEdits.bind(this);
  }

  isLoading(state) {
    return this._accessor(state).loading || false;
  }

  isRefreshing(state) {
    return this._accessor(state).refreshing || false;
  }

  isEditing(state) {
    return this._accessor(state).editing || false;
  }

  isSaving(state) {
    return this._accessor(state).saving || false;
  }

  isDeleting(state) {
    return this._accessor(state).deleting || false;
  }

  error(state) {
    return this._accessor(state).error;
  }

  list(state) {
    return this._accessor(state).list || [];
  }

  listEtag(state) {
    return this._accessor(state).listEtag;
  }

  item(state) {
    return this._accessor(state).item;
  }

  pendingEdits(state) {
    return this._accessor(state).pendingEdits;
  }
}
