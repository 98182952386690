import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTarget } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faPlus } from '@fortawesome/free-solid-svg-icons';


const DefaultClasses = 'border rounded p-2 canary-hoverable-icon';
const HoverClasses = `${DefaultClasses} bg-gray-200`;

class UploadDrop extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.fileInputElement = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    if (this.fileInputElement && this.fileInputElement.current) {
      const evt = new MouseEvent('click');
      this.fileInputElement.current.dispatchEvent(evt);
    }
  }

  handleChange() {
    if (this.fileInputElement && this.fileInputElement.current && this.fileInputElement.current.files && this.fileInputElement.current.files.length) {
      this.props.onFileDrop(this.fileInputElement.current.files);
    }
  }

  render() {
    const isOver = this.props.isOver;
    const className = isOver ? HoverClasses : DefaultClasses;
    const icon = isOver ? faPlus : faFile;

    return this.props.connectDropTarget((
      <div className={className} onClick={this.handleClick}>
        <input id="attachment" onChange={this.handleChange} className="d-none" type="file" multiple ref={this.fileInputElement} />
        <FontAwesomeIcon size="3x" icon={icon} />
        <h5 className="mt-1">Click to Select a File</h5>
        {isOver ? 'release to drop' : 'or drag a file here'}
      </div>
    ));
  }
}

const targetProps = {
  drop(props, monitor) {
    const item = monitor.getItem();
    if (props.onFileDrop) {
      props.onFileDrop(item.files);
    }
  },
};

UploadDrop.propTypes = {
  isOver: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  onFileDrop: PropTypes.func.isRequired,
};

export default DropTarget(NativeTypes.FILE, targetProps, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(UploadDrop);
