import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import {track, withTracking} from '../../../../../shared/analytics';
import FolderTree from '../components/folder-tree/FolderTree';
import TableView from '../components/files/TableView';
import Toolbar from './Toolbar';
import {ItemType, OperationState} from '../DocConstants';
import * as selectors from '../SiteDocumentSelectors';
import * as actions from '../actions';

class SiteDocuments extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.props.getSiteFolders();
  }

  render() {
    if (this.props.operationState === OperationState.Initial || this.props.operationState === OperationState.Loading) {
      return (
        <div className="issueLoader">
          <div className="issueLoaderBox">
            <div>
              <div className="d-inline-block mr-2">
                <PulseLoader color={'#F8E71C'} size={8} />
              </div>
              Loading...
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Row>
          <Col xs={2}>
            <h5>{`${this.props.totalDocumentCount} Documents`}</h5>
          </Col>
          <Col xs={10}>
            <Toolbar />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="pr-md-0">
            <table className="table table-sm canary-table-2 w-100">
              <thead className="border-bottom">
                <tr>
                  <th>Folder</th>
                </tr>
              </thead>
            </table>
            <FolderTree
              folders={this.props.folders}
              activeFolder={this.props.activeFolder}
              onFolderSelect={this.props.changeFolder}
              onFileDrop={this.props.moveDocuments}
              className="mr-3"
            />
          </Col>
          <Col md={9} className="pl-md-0">
            <TableView
              activeFolder={this.props.activeFolder}
              items={this.props.activeFolderItems}
              onSelectAllToggle={this.props.selectAllItems}
              onItemSelectToggle={this.props.toggleItemSelect}
              onSingleItemSelectToggle={this.props.selectSingleItem}
              onDocDragStart={this.props.beginDocDrag}
              onFolderOpen={this.props.changeFolder}
              onDocOpen={this.props.navToDocument}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

SiteDocuments.propTypes = {
  operationState: PropTypes.string.isRequired,
  folders: PropTypes.array,
  activeFolder: PropTypes.object,
  activeFolderItems: PropTypes.array,
  totalDocumentCount: PropTypes.number.isRequired,

  getSiteFolders: PropTypes.func.isRequired,
  changeFolder: PropTypes.func.isRequired,
  selectAllItems: PropTypes.func.isRequired,
  toggleItemSelect: PropTypes.func.isRequired,
  selectSingleItem: PropTypes.func.isRequired,
  beginDocDrag: PropTypes.func.isRequired,
  navToDocument: PropTypes.func.isRequired,
  moveDocuments: PropTypes.func.isRequired,
};

SiteDocuments.defaultProps = {
  folders: [],
  activeFolder: null,
  activeFolderItems: null,
};

function mapStateToProps(state) {
  return {
    operationState: selectors.operationState(state),
    activeFolder: selectors.activeFolder(state),
    activeFolderItems: selectors.activeFolderItems(state),
    folders: selectors.folders(state),
    totalDocumentCount: selectors.totalDocumentCount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSiteFolders: () => dispatch(actions.getSiteFolders()),
    changeFolder: folder => {
      if (folder) {
        track('Document', {
          Component: 'Folder tree',
          'Document action': 'Change folder',
          'Item type': ItemType.Folder,
          'Item path': folder.path,
        });
      }
      return dispatch(actions.changeFolder(folder));
    },
    selectSingleItem: item => {
      const action = item.checked ? 'Deselect item' : 'Select item exclusive';
      track('Document', {
        Component: 'Document table',
        'Document action': action,
        'Item type': item.type,
        'Item path': item.path,
      });
      return dispatch(actions.selectSingleItem(item));
    },
    toggleItemSelect: item => {
      const action = item.checked ? 'Deselect item' : 'Select item';
      track('Document', {
        Component: 'Document table',
        'Document action': action,
        'Item type': item.type,
        'Item path': item.path,
      });
      return dispatch(actions.toggleItemSelect(item));
    },

    selectAllItems: checked => {
      const action = checked ? 'Select all' : 'Deselect all';
      track('Document', {
        Component: 'Document table',
        'Document action': action,
      });
      return dispatch(actions.selectAllItems(checked));
    },
    beginDocDrag: doc => {
      track('Document', {
        Component: 'Document table',
        'Document action': 'Begin drag',
      });
      return dispatch(actions.beginDocDrag(doc));
    },
    navToDocument: doc => {
      track('Document', {
        Component: 'Document table',
        'Document action': 'Open doc',
        'Item type': ItemType.Doc,
        'Item path': doc.path,
      });
      return dispatch(actions.navToDocument(doc));
    },
    moveDocuments: (folder) => {
      track('Document', {
        Component: 'Folder tree',
        'Document action': 'End drag',
      });
      return dispatch(actions.moveDocuments(folder));
    }
  };
}

export default withTracking('Site', 'Documents')(connect(mapStateToProps, mapDispatchToProps)(SiteDocuments));
