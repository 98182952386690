import React from 'react';
import PropTypes from 'prop-types';
import CanaryModal from '../../../../shared/components/CanaryModal';

const IngestFailureDeleteModal = ({ ingestFailure, open, onCancel, onConfirm }) => {
  const description = (ingestFailure && ingestFailure.description) || '';
  return (
    <CanaryModal
      open={open}
      title="Delete Ingest Failure?"
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonText="Delete"
      size="md"
    >
      <span>
        Are you sure you want to delete &quot;
        {description}
        &quot;? This action cannot be undone.
      </span>
    </CanaryModal>
  );
};

IngestFailureDeleteModal.propTypes = {
  ingestFailure: PropTypes.object,
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

IngestFailureDeleteModal.defaultProps = {
  ingestFailure: null,
  open: false,
};

export default IngestFailureDeleteModal;
