import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from '../../../../../../../shared/components/compliance/StatusIcon';
import StatusText from '../../../../../../../shared/components/compliance/StatusText';

const renderTableRows = sensors => sensors.map((sensor) => (
  <tr key={sensor.sensorId}>
    <td><b>{sensor.sensorNumber} - {sensor.locationLabel}</b></td>
    <td style={{ fontWeight: '400' }}>
      <StatusIcon size="1em" status={sensor.status} />
      <StatusText status={sensor.status} />
    </td>
  </tr>
));

const SensorsTable = ({ sensors, sensorsRollupStatus }) => {
  if (!sensors || sensors.length === 0) {
    return (
      <div style={{ textAlign: 'center' }} className="text-muted">
        { sensorsRollupStatus === 'PASSED'
          ? 'There are no sensors at this station.'
          : 'Canary did not find any sensors to monitor at this station.'}
      </div>
    );
  }

  return (
    <table className="table canary-table borderless my-2">
      <thead>
        <tr>
          <th style={{ width: '20em' }}>Sensor</th>
          <th style={{ width: '15em' }}>Status</th>
        </tr>
      </thead>
      <tbody>
        {renderTableRows(sensors)}
      </tbody>
    </table>
  );
};

SensorsTable.propTypes = {
  sensors: PropTypes.arrayOf(
    PropTypes.shape({
      tankId: PropTypes.number,
      tankNumber: PropTypes.number,
      status: PropTypes.string,
      productLabel: PropTypes.string,
    }),
  ),
  sensorsRollupStatus: PropTypes.string,
};

SensorsTable.defaultProps = {
  sensors: [],
  sensorsRollupStatus: '',
};

export default SensorsTable;
