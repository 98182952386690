import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilterMultiselect from '../FilterMultiselect';

class LabelFilterMultiselect extends Component {
  componentDidMount() {
    this.props.getAllLabels();
  }

  render() {
    return (
      <FilterMultiselect
        textField="labelText"
        onItemsSelected={this.props.selectSiteLabels}
        selectedItems={this.props.selectedSiteLabels}
        allItems={this.props.allSiteLabels}
        className={this.props.className}
        disabled={this.props.disabled}
      />
    );
  }
}

LabelFilterMultiselect.propTypes = {
  selectedSiteLabels: PropTypes.arrayOf(PropTypes.object),
  allSiteLabels: PropTypes.arrayOf(PropTypes.object),
  getAllLabels: PropTypes.func.isRequired,
  selectSiteLabels: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

LabelFilterMultiselect.defaultProps = {
  selectedSiteLabels: [],
  allSiteLabels: [],
  className: null,
};

export default LabelFilterMultiselect;
