import React from 'react';
import PropTypes from 'prop-types';
import OrganizationDropdown from '../../../shared/components/organization-dropdown/components/OrganizationDropdown';

const buildCustomerSelectorControl = (customers, customerId, onSetCustomerId, keyPrefix) => {
  const currentCustomer = customerId ? { id: customerId } : null;
  return (
    <div className="d-inline-block ml-2" key={`${keyPrefix}-user-preferences-customer-picker`}>
      <OrganizationDropdown
        organizationList={customers}
        selectedOrganization={currentCustomer}
        onOrganizationSelect={org => onSetCustomerId(org.id)}
      />
    </div>
  );
};

const PreferencesHeaderBar = ({ displayText, customers, customerId, onSetCustomerId, keyPrefix }) => (
  <div className="mt-0 mb-2">
    <div>
      <h5 style={{ display: 'inline-block' }}>{displayText}</h5>
      {buildCustomerSelectorControl(customers, customerId, onSetCustomerId, keyPrefix)}
    </div>
  </div>
);

PreferencesHeaderBar.propTypes = {
  displayText: PropTypes.string.isRequired,
  customers: PropTypes.array.isRequired,
  customerId: PropTypes.number.isRequired,
  onSetCustomerId: PropTypes.func.isRequired,
  keyPrefix: PropTypes.string.isRequired,
};

export default PreferencesHeaderBar;
