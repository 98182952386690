import { push } from 'react-router-redux';
import ActionTypes from './NavContextActionTypes';
import * as selectors from './NavContextSelectors';

export const clearContext = () => ({ type: ActionTypes.CLEAR_CONTEXT });

export const setContext = (originType, urls, index = 0) => (dispatch, getState) => {
  const originUrl = getState().router.location.pathname;
  return dispatch({ type: ActionTypes.RECEIVE_CONTEXT, originType, urls, index, originUrl });
};

export const navBackToOrigin = () => (dispatch, getState) => {
  const originUrl = selectors.originUrl(getState());
  if (originUrl) {
    dispatch(push(originUrl));
  }
};

export const movePrev = () => (dispatch, getState) => {
  const state = getState();
  const prevUrl = selectors.prevUrl(state);
  if (prevUrl) {
    dispatch({ type: ActionTypes.PREV });
    dispatch(push(prevUrl));
  }
};

export const moveNext = () => (dispatch, getState) => {
  const state = getState();
  const nextUrl = selectors.nextUrl(state);
  if (nextUrl) {
    dispatch({ type: ActionTypes.NEXT });
    dispatch(push(nextUrl));
  }
};
