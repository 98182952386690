import React from 'react';
import PropTypes from 'prop-types';
import { InventoryStatus } from '../../../AppConstants';

function getStatusIcon(tankInventory) {
  if (!tankInventory) {
    return null;
  }

  switch (tankInventory.status) {
    case InventoryStatus.GreaterThanSeventyFivePercent:
      return <img alt="More than 75%" title="More than 75%" src="/images/inventory/full.svg" height="25" width="25" />;
    case InventoryStatus.DeliveryNeeded:
      return <img alt="Delivery Needed" title="Delivery Needed" src="/images/inventory/low.svg" height="25" width="25" />;
    case InventoryStatus.LessThanSeventyFivePercent:
    default:
      return <img alt="Less than 75% but not in alarm" title="Less than 75% but not in alarm" src="/images/inventory/half.svg" height="25" width="25" />;
  }
}

const TankIcon = props => (
  <div style={{ height: 25, width: 25 }} className="d-inline-block">
    <span>{ getStatusIcon(props.tankInventory) }</span>
  </div>
);

TankIcon.propTypes = {
  tankInventory: PropTypes.object.isRequired,
};

export default TankIcon;
