import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

import DeleteButton from '../../../../../../../../../shared/components/buttons/DeleteButton';
import TextToMarkup from '../../../../../../../../../shared/components/TextToMarkup';
import EditButton from '../../../../../../../../../shared/components/buttons/EditButton';

const ActionItem = ({ className, actionItem, canEdit, editDisabled, onBeginEdit, onDelete }) => (
  <Card className={className}>
    <CardBody className="d-flex align-items-center justify-content-between">
      <div className="flex-grow-1 d-flex align-items-center justify-content-between flex-wrap">
        <TextToMarkup text={actionItem.actionItemText} />
      </div>
      { canEdit ? (
        <>
          <EditButton onClick={() => onBeginEdit(actionItem)} disabled={editDisabled} className="mr-3" />
          <DeleteButton onClick={() => onDelete(actionItem)} disabled={editDisabled} />
        </>
      ) : null}
    </CardBody>
  </Card>
);

ActionItem.propTypes = {
  className: PropTypes.string,
  actionItem: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  editDisabled: PropTypes.bool,
  onBeginEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

ActionItem.defaultProps = {
  className: null,
  canEdit: false,
  editDisabled: false,
};

export default ActionItem;
