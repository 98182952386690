import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody } from 'reactstrap';

const newlineRegex = /(\r?\n)/g;

const StackTrace = ({ stackTrace }) => {
  const [expanded, setExpanded] = useState(false);

  if (!stackTrace) {
    return null;
  }
  else if (!expanded) {
    const lines = stackTrace.split(newlineRegex);
    return (
      <Card>
        <CardBody>
          <div className="d-flex align-items-baseline">
            <div className="canary-text-small text-truncate text-monospace">
              {lines[0]}
            </div>
            <Button className="ml-2 flex-shrink-0" color="link" onClick={() => setExpanded(true)}>Expand</Button>
          </div>
        </CardBody>
      </Card>

    );
  }
  return (
    <Card>
      <CardBody>
        <pre>{stackTrace}</pre>
      </CardBody>
    </Card>
  );
};

StackTrace.propTypes = {
  stackTrace: PropTypes.string,
};

StackTrace.defaultProps = {
  stackTrace: null,
};

export default StackTrace;
