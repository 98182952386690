import reduceReducers from 'reduce-reducers';
import { buildReducer } from '../../../shared/redux-helpers/ReducerFactory';
import ActionTypes from './IngestFailureActionTypes';
import { SubsystemTypes } from './IngestFailureConstants';

const IngestFailureReducer = buildReducer(ActionTypes);

function FilterReducer(state, action) {
  switch (action.type) {
    case ActionTypes.SET_LIST_FILTER:
      return applyFilter(state, action.subsystem);
    case ActionTypes.RECEIVE_LIST:
      return applyFilter(state, state.selectedSubsystem || SubsystemTypes.All);
    default:
      return state;
  }
}

function applyFilter(state, subsystem) {
  let filteredList;
  if (state.list && state.list.length && subsystem !== SubsystemTypes.All) {
    filteredList = state.list.filter(current => current.subsystem === subsystem);
  }
  else {
    filteredList = state.list || [];
  }

  return Object.assign({}, state, {
    filteredList,
    selectedSubsystem: subsystem,
  });
}

export default reduceReducers(IngestFailureReducer, FilterReducer);
