const OrganizationPermissions = {
  SiteList: 1,
  SiteEdit: 2,

  // ATG commands
  AtgCommandsInventory: 16,
  AtgCommandsInquire: 32,
  AtgCommandsSet: 64,

  // issues
  IssueView: 256,
  IssueComment: 512,
  IssueAttachFile: 1024,
  IssueEdit: 2048,

  // compliance
  ComplianceView: 4096,
  ComplianceEdit: 8192,

  // monthly visual inspections
  MVIView: 16384,
  MVIEdit: 32768,

  // inventory
  InventoryView: 65536,

  All: 4294967295,
};

module.exports = Object.freeze(OrganizationPermissions);
