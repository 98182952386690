const Construction = {
  id: 2,
  name: 'construction',
  label: 'Construction',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: false,
  loading: false,
  index: 0,
  fields: [
    { id: 1,
      name: 'manufacturer',
      label: 'Manufacturer',
      path: 'construction.manufacturer',
      type: 'text',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 2,
      name: 'modelNumber',
      label: 'Model Number',
      path: 'construction.modelNumber',
      type: 'text',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 3,
      name: 'primaryMaterial',
      label: 'Primary Material',
      path: 'construction.primaryMaterial',
      type: 'select',
      values: ['Unknown', 'Steel', 'Fiberglass Reinforced Plastic'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 4,
      name: 'wallType',
      label: 'Wall Type',
      path: 'construction.wallType',
      type: 'select',
      values: ['Unknown', 'Single wall', 'Double wall', 'Triple wall'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 5,
      name: 'intersticeType',
      label: 'Interstice Type',
      path: 'construction.intersticeType',
      type: 'select',
      values: ['Unknown', 'None', 'Dry', 'Hydrostatic', 'Pressure', 'Vacuum'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 6,
      name: 'preEngineeredCorrosionProtection',
      label: 'Pre-engineered Corrosion Protection',
      path: 'construction.preEngineeredCorrosionProtection',
      type: 'select',
      values: ['Unknown', 'Unprotected', 'Unlisted coating', 'STI-P3/UL 1746 Part I', 'STI ACT-100/UL 1746 Part II', 'STI Permatank/UL 1746 Part III', 'STI ACT-100-U/UL 1746 Part IV'],
      cols: 4,
      editable: false,
      required: false,
      error: false,
      display: true,
    },
    { id: 7,
      name: 'compartmentPartnerIds',
      label: 'Compartment Partner IDs',
      path: 'compartmentPartnerIds',
      type: 'text',
      cols: 4,
      editable: false,
      required: false,
      error: false,
      display: false,
    },
  ],
};

export default Construction;
