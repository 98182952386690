import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { DropTarget } from 'react-dnd';


const defaultClasses = 'list-group-item list-group-item-action py-1 pr-1 border-0';
const selectedClasses = `${defaultClasses} active`;

const Folder = ({ canDrop, isOver, connectDropTarget, folder, expanded, selected, onClick, onFileDrop }) => {
  const icon = expanded || selected ? faFolderOpen : faFolder;

  let className = selected ? selectedClasses : defaultClasses;
  if (canDrop && isOver) {
    className = `${className} bg-gray-300`;
  }
  else if (canDrop) {
    className = `${className} bg-gray-100`;
  }

  const style = {
    paddingLeft: `${(folder.depth + 1) * 0.5}em`,
  };

  const content = (
    <div className={className} style={style} onClick={() => onClick(folder)}>
      <FontAwesomeIcon size="lg" icon={icon} className="mr-1" />
      <span>{folder.folderName}</span>
      {folder.docs && folder.docs.length ? <Badge color="primary" className="float-right mr-2">{folder.docs.length}</Badge> : null}
    </div>
  );

  if (onFileDrop) {
    return connectDropTarget((content));
  }
  return content;
};

const targetProps = {
  drop(props, monitor) {
    const item = monitor.getItem();
    if (props.folder.id !== item.folderId) {
      props.onFileDrop(props.folder);
    }
  },

  canDrop(props, monitor) {
    const item = monitor.getItem();
    return props.folder.id !== item.folderId;
  },
};

Folder.propTypes = {
  folder: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onFileDrop: PropTypes.func,
};

Folder.defaultProps = {
  expanded: false,
  selected: false,
  onFileDrop: null,
};

export default DropTarget('FILE_DROP', targetProps, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(Folder);
