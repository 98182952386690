import React from 'react';
import PropTypes from 'prop-types';
import DropdownList from 'react-widgets/lib/DropdownList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';

function flatten(subFolders, flatFolders = []) {
  if (!subFolders || !subFolders.length) {
    return flatFolders;
  }

  subFolders.forEach(folder => {
    flatFolders.push(folder);
    flatten(folder.subFolders, flatFolders);
  });

  return flatFolders;
}

const FolderItem = ({ item }) => {
  const style = {
    paddingLeft: `${(item.depth + 1) * 0.5}em`,
  };
  return (
    <span style={style}>
      <FontAwesomeIcon size="sm" icon={faFolder} className="mr-1" />
      {item.folderName}
    </span>
  );
};

FolderItem.propTypes = {
  item: PropTypes.object.isRequired,
};

const FolderDropdown = ({ folders, value, onChange }) => {
  const flatFolders = flatten(folders);
  return (
    <DropdownList
      data={flatFolders}
      value={value}
      onChange={onChange}
      valueField="id"
      textField="folderName"
      itemComponent={FolderItem}
      placeholder="Select a folder..."
    />
  );
};

FolderDropdown.propTypes = {
  folders: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

FolderDropdown.defaultProps = {
  value: null,
};

export default FolderDropdown;
