import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

import * as ChartUtils from '../../../../../../../../components/ChartUtils';

const LineChartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      ticks: {
        suggestedMin: -120,
      },
    }],
  },
};

class CellSignalGraph extends Component {

  buildChartParams() {
    const cellSignalReadings = this.props.selectedSite.cellSignalStrength;
    const xAxisLabels = cellSignalReadings.map(current => ChartUtils.formatDay(current.signalDate));
    const yAxisData = cellSignalReadings.map(current => current.signalStrength);

    return {
      labels: xAxisLabels,
      datasets: [
        ChartUtils.buildLineChartDataset('Signal Strength', ChartUtils.RgbColors.Blue, yAxisData),
      ],
    };
  }

  render() {
    if (!this.props.selectedSite.connection || this.props.selectedSite.connection.connectionType !== 'PARTICLE') {
      return null;
    }

    return (
      <div>
        <div className="text-md-center">
          <h5 style={{ display: 'inline' }}>Cell Signal History</h5>
        </div>
        {this.props.selectedSite.cellSignalStrength && this.props.selectedSite.cellSignalStrength.length >= 5 ?
          <div className="mt-1">
            <Line
              data={this.buildChartParams()}
              width={100}
              height={200}
              options={LineChartOptions}
              legend={{ display: false }}
            />
          </div>
          :
          null
        }
      </div>
    );
  }
}

CellSignalGraph.propTypes = {
  selectedSite: PropTypes.object.isRequired,
};

export default CellSignalGraph;
