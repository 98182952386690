import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import { track } from '../../../../analytics';
import SelectedCustomers from './filter/customer/SelectedCustomers';
import SelectedStates from './filter/state/SelectedStates';
import SelectedConnectionTypes from './filter/connection-type/SelectedConnectionTypes';
import SelectedLabels from './filter/label/SelectedLabels';
import SelectedIssueTypes from './filter/issue-type/SelectedIssueTypes';
import SelectedIssueWorkflows from './filter/issue-workflow/SelectedIssueWorkflows';
import SelectedMonth from './filter/month/SelectedMonth';
import SelectedComplianceStatuses from './filter/compliance/SelectedComplianceStatuses';
import SelectedInspectionStatuses from './filter/inspection-status/SelectedInspectionStatuses';
import SelectedSites from './filter/site/SelectedSites';
import SelectedTankProductLabels from './filter/tank-product-label/SelectedTankProductLabels';
import SelectedTankStatuses from './filter/tank-status/SelectedTankStatuses';
import SelectedConnectionStatuses from './filter/connection-status/SelectedConnectionStatuses';

class WindowSizeListener {
  constructor() {
    this._smMql = window.matchMedia('(min-width: 576px)');
    this._mdMql = window.matchMedia('(min-width: 768px)');
    this._lgMql = window.matchMedia('(min-width: 992px)');
    this._xlMql = window.matchMedia('(min-width: 1200px)');

    this._listeners = [];
    this.onResize = this.onResize.bind(this);
    this.close = this.close.bind(this);
    this._small = this._small.bind(this);
    this._medium = this._medium.bind(this);
    this._large = this._large.bind(this);
    this._xlarge = this._xlarge.bind(this);
    this._fire = this._fire.bind(this);

    this._smMql.addEventListener('change', this._small);
    this._mdMql.addEventListener('change', this._medium);
    this._lgMql.addEventListener('change', this._large);
    this._xlMql.addEventListener('change', this._xlarge);

    if (window.innerWidth >= 1200) {
      this.size = 'xl';
    }
    else if (window.innerWidth >= 992) {
      this.size = 'lg';
    }
    else if (window.innerWidth >= 768) {
      this.size = 'md';
    }
    else if (window.innerWidth >= 576) {
      this.size = 'sm';
    }
    else {
      this.size = 'xs';
    }
  }

  onResize(func) {
    this._listeners.push(func);
  }

  close() {
    this._smMql.removeEventListener('change', this._small);
    this._mdMql.removeEventListener('change', this._medium);
    this._lgMql.removeEventListener('change', this._large);
    this._xlMql.removeEventListener('change', this._xlarge);
  }

  _small(e) {
    if (e.matches) {
      this._fire('sm');
    }
    else {
      this._fire('xs');
    }
  }

  _medium(e) {
    if (e.matches) {
      this._fire('md');
    }
    else {
      this._fire('sm');
    }
  }

  _large(e) {
    if (e.matches) {
      this._fire('lg');
    }
    else {
      this._fire('md');
    }
  }

  _xlarge(e) {
    if (e.matches) {
      this._fire('xl');
    }
    else {
      this._fire('lg');
    }
  }

  _fire(size) {
    this.size = size;
    this._listeners.forEach(current => current(size));
  }
}

const MaxTagsDisplayed = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 6
};

class AllFilterTags extends Component {
  constructor(props, context) {
    super(props, context);

    this.expandTagList = this.expandTagList.bind(this);
    this.collapseTagList = this.collapseTagList.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleCollapseClick = this.handleCollapseClick.bind(this);
    this.handleClear = this.handleClear.bind(this);

    this.tagDiv = React.createRef();
    this.expandButton = React.createRef();
    this.expandSpan = React.createRef();
    this.collapseButton = React.createRef();
    this.expanded = false;
    this.resizeListener = new WindowSizeListener();
    this.resizeListener.onResize(this.handleWindowResize);
  }

  isFilterActive() {
    return !!(this.props.selectedCustomers.length
      || this.props.selectedStates.length
      || this.props.selectedConnectionTypes.length
      || this.props.selectedConnectionStatuses.length
      || this.props.selectedSiteLabels.length
      || this.props.selectedIssueTypes.length
      || this.props.selectedFriendlyWorkflowStatuses.length
      || this.props.selectedMonth
      || this.props.selectedComplianceStatuses.length
      || this.props.selectedInspectionStatuses.length
      || this.props.selectedSites.length
      || this.props.selectedTankProductLabels.length
      || this.props.selectedTankStatuses.length);
  }

  componentDidMount() {
    this.collapseTagList();
  }

  componentDidUpdate(prevProps) {
    this.handleWindowResize();
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.resizeListener.close();
  }

  handleWindowResize() {
    if (this.unmounted) {
      return;
    }

    if (this.props.editingView) {
      this.expandTagList();
    }
    else {
      this.collapseTagList();
    }
  }

  hideExpandButton() {
    const expandButton = this.expandButton.current;
    expandButton.classList.remove('d-inline-block');
    expandButton.classList.add('d-none');
  }

  showExpandButton() {
    const expandButton = this.expandButton.current;
    expandButton.classList.remove('d-none');
    expandButton.classList.add('d-inline-block');
  }

  hideCollapseButton() {
    const collapseButton = this.collapseButton.current;
    collapseButton.classList.remove('d-inline-block');
    collapseButton.classList.add('d-none');
  }

  showCollapseButton() {
    const collapseButton = this.collapseButton.current;
    collapseButton.classList.remove('d-none');
    collapseButton.classList.add('d-inline-block');
  }

  expandTagList() {
    this.expanded = true;
    const tagDiv = this.tagDiv.current;
    tagDiv.classList.add('flex-wrap');
    tagDiv.classList.remove('overflow-hidden');
    this.hideExpandButton();

    for (let i = 0; i < tagDiv.children.length; i++) {
      const current = tagDiv.children[i];
      if (current.classList.contains('d-none') && current !== this.expandButton.current && current !== this.collapseButton.current) {
        current.classList.remove('d-none');
      }
    }

    const maxTags = MaxTagsDisplayed[this.resizeListener.size];
    const excessTagCount = tagDiv.children.length - (3 + maxTags);
    if (excessTagCount > 0 && !this.props.editingView) {
      this.showCollapseButton();
    }
    else {
      this.hideCollapseButton();
    }
  }

  collapseTagList() {
    this.expanded = false;
    const tagDiv = this.tagDiv.current;
    this.hideCollapseButton();

    const maxTags = MaxTagsDisplayed[this.resizeListener.size];
    const excessTagCount = tagDiv.children.length - (3 + maxTags);
    if (excessTagCount <= 0) {
      this.hideExpandButton();
      return;
    }

    for (let i = 0; i < tagDiv.children.length - 2; i++) {
      const current = tagDiv.children[i];

      if (i >= maxTags) {
        current.classList.add('d-none');
      }
      else if (current.classList.contains('d-none')) {
        current.classList.remove('d-none');
      }
    }

    this.expandSpan.current.innerText = `Show ${excessTagCount} more`;
    this.showExpandButton();
  }

  handleExpandClick() {
    track('View manager', { Component: 'Tag list', 'View action': 'Expand tag list' });
    this.expandTagList();
  }

  handleCollapseClick() {
    track('View manager', { Component: 'Tag list', 'View action': 'Collapse tag list' });
    this.collapseTagList();
  }

  handleClear() {
    track('View manager', { Component: 'Tag list', 'View action': 'Clear tags' });
    this.props.clearFilters();
  }

  render() {
    return (
      <div className="d-flex align-content-between overflow-hidden truncate mt-2" ref={this.tagDiv}>
        <SelectedCustomers selectedCustomers={this.props.selectedCustomers} deselectCustomer={this.props.deselectCustomer} disabled={this.props.disabled} />
        <SelectedStates selectedStates={this.props.selectedStates} deselectSiteState={this.props.deselectSiteState} disabled={this.props.disabled} />
        <SelectedConnectionTypes selectedConnectionTypes={this.props.selectedConnectionTypes} deselectConnectionType={this.props.deselectConnectionType} disabled={this.props.disabled} />
        <SelectedConnectionStatuses selectedConnectionStatuses={this.props.selectedConnectionStatuses} deselectConnectionType={this.props.deselectConnectionStatus} disabled={this.props.disabled} />
        <SelectedLabels selectedSiteLabels={this.props.selectedSiteLabels} deselectSiteLabel={this.props.deselectSiteLabel} disabled={this.props.disabled} />
        <SelectedIssueTypes selectedIssueTypes={this.props.selectedIssueTypes} deselectSiteIssueType={this.props.deselectIssueType} disabled={this.props.disabled} />
        <SelectedIssueWorkflows selectedFriendlyWorkflowStatuses={this.props.selectedFriendlyWorkflowStatuses} deselectFriendlyIssueWorkflowStatus={this.props.deselectFriendlyIssueWorkflowStatus} disabled={this.props.disabled} />
        <SelectedMonth monthType={this.props.monthType} selectedMonth={this.props.selectedMonth} setMonthFilter={this.props.setMonthFilter} disabled={this.props.disabled} />
        <SelectedComplianceStatuses selectedComplianceStatuses={this.props.selectedComplianceStatuses} deselectComplianceStatus={this.props.deselectComplianceStatus} disabled={this.props.disabled} />
        <SelectedInspectionStatuses selectedInspectionStatuses={this.props.selectedInspectionStatuses} deselectInspectionStatus={this.props.deselectInspectionStatus} disabled={this.props.disabled} />
        <SelectedSites selectedSites={this.props.selectedSites} deselectSite={this.props.deselectSite} disabled={this.props.disabled} />
        <SelectedTankProductLabels selectedTankProductLabels={this.props.selectedTankProductLabels} deselectTankProductLabel={this.props.deselectTankProductLabel} disabled={this.props.disabled} />
        <SelectedTankStatuses selectedTankStatuses={this.props.selectedTankStatuses} deselectTankStatus={this.props.deselectTankStatus} disabled={this.props.disabled} />
        {this.renderExpandButton()}
        {this.renderCollapseButton()}
        {this.renderClearButton()}
      </div>
    );
  }

  renderExpandButton() {
    return (
      <button type="button" className="d-none text-nowrap mr-2 mb-2 btn btn-link btn-sm" onClick={this.handleExpandClick} ref={this.expandButton}>
        <span ref={this.expandSpan}>Show X more</span>
        <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
      </button>
    );
  }

  renderCollapseButton() {
    return (
      <button type="button" className="d-none text-nowrap mr-2 mb-2 btn btn-link btn-sm" onClick={this.handleCollapseClick} ref={this.collapseButton}>
        <span>Collapse</span>
        <FontAwesomeIcon icon={faChevronUp} className="ml-2" />
      </button>
    );
  }

  renderClearButton() {
    if (this.isFilterActive()) {
      return <Button color="primary" onClick={this.handleClear} size="sm" className="mb-2 d-inline-block" disabled={this.props.disabled}>Clear All</Button>;
    }
    return null;
  }
}

AllFilterTags.propTypes = {
  disabled: PropTypes.bool.isRequired,
  editingView: PropTypes.bool.isRequired,

  clearFilters: PropTypes.func.isRequired,

  selectedCustomers: PropTypes.arrayOf(PropTypes.object).isRequired,
  deselectCustomer: PropTypes.func.isRequired,

  selectedStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  deselectSiteState: PropTypes.func.isRequired,

  selectedConnectionTypes: PropTypes.array.isRequired,
  deselectConnectionType: PropTypes.func.isRequired,

  selectedConnectionStatuses: PropTypes.array.isRequired,
  deselectConnectionStatus: PropTypes.func.isRequired,

  selectedSiteLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
  deselectSiteLabel: PropTypes.func.isRequired,

  selectedIssueTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  deselectIssueType: PropTypes.func.isRequired,

  selectedFriendlyWorkflowStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  deselectFriendlyIssueWorkflowStatus: PropTypes.func.isRequired,

  monthType: PropTypes.string.isRequired,
  selectedMonth: PropTypes.object,
  setMonthFilter: PropTypes.func.isRequired,

  selectedComplianceStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  deselectComplianceStatus: PropTypes.func.isRequired,

  selectedInspectionStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  deselectInspectionStatus: PropTypes.func.isRequired,

  selectedSites: PropTypes.arrayOf(PropTypes.object).isRequired,
  deselectSite: PropTypes.func.isRequired,

  selectedTankStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  deselectTankStatus: PropTypes.func.isRequired,

  selectedTankProductLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  deselectTankProductLabel: PropTypes.func.isRequired,
};

AllFilterTags.defaultProps = {
  selectedMonth: null,
};

export default AllFilterTags;
