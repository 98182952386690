import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

const DocDragSource = (props) => props.connectDragSource(React.Children.only(props.children));

const sourceProps = {
  beginDrag(props) {
    props.onDocDragStart(props.doc);
    return props.doc;
  },
};

function sourceCollect(connect) {
  return {
    connectDragSource: connect.dragSource(),
  };
}

DocDragSource.propTypes = {
  doc: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  onDocDragStart: PropTypes.func.isRequired,
};

export default DragSource('FILE_DROP', sourceProps, sourceCollect)(DocDragSource);
