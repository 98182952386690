import update from 'immutability-helper';
import * as MviReportActions from '../MviReportActions';
import MviReportSelectors from '../MviReportSelectors';
import * as ActionItemsSelectors from './ActionItemsSelectors';
import ActionItemsActionTypes from './ActionItemsActionTypes';

export const selectExistingForEdit = actionItem => ({ type: ActionItemsActionTypes.SELECT_FOR_EDIT, actionItem });

export const selectNewForEdit = () => (dispatch, getState) => {
  const currentReport = MviReportSelectors.item(getState());
  let actionItemNumber = 0;

  if (currentReport.actionItems && currentReport.actionItems.length) {
    currentReport.actionItems.forEach(item => {
      if (item.actionItemNumber >= actionItemNumber) {
        actionItemNumber = item.actionItemNumber + 1;
      }
    });
  }

  return dispatch({
    type: ActionItemsActionTypes.SELECT_NEW_ITEM_FOR_EDIT,
    actionItem: {
      actionItemNumber,
      actionItemText: '',
    }
  });
};

export const editActionItem = actionItem => ({ type: ActionItemsActionTypes.EDIT_ACTION_ITEM, actionItem });

export const openConfirmDeleteModal = actionItem => ({ type: ActionItemsActionTypes.SELECT_FOR_DELETE, actionItem });

export const cancel = () => ({ type: ActionItemsActionTypes.CANCEL });

export const saveChanges = () => (dispatch, getState) => {
  const updatedReport = buildReportUpdate(getState());
  return dispatch(MviReportActions.saveReport(updatedReport));
};

function buildReportUpdate(state) {
  const currentReport = MviReportSelectors.item(state);
  const selectedActionItem = ActionItemsSelectors.selectedActionItem(state);
  if (ActionItemsSelectors.isEditing(state)) {
    return updateActionItem(currentReport, selectedActionItem);
  }
  else if (ActionItemsSelectors.isDeleteModalOpen(state)) {
    return deleteActionItem(currentReport, selectedActionItem);
  }
  return addActionItem(currentReport, selectedActionItem);
}

function updateActionItem(report, selectedActionItem) {
  const index = report.actionItems.findIndex(current => current.actionItemNumber === selectedActionItem.actionItemNumber);
  return update(report, {
    actionItems: {
      $splice: [[index, 1, selectedActionItem]]
    },
  });
}

function deleteActionItem(report, selectedActionItem) {
  const index = report.actionItems.findIndex(current => current.actionItemNumber === selectedActionItem.actionItemNumber);
  return update(report, {
    actionItems: {
      $splice: [[index, 1]]
    },
  });
}

function addActionItem(report, selectedActionItem) {
  let updateSpec;
  if (!report.actionItems) {
    updateSpec = {
      actionItems: {
        $set: [selectedActionItem],
      },
    };
  }
  else {
    updateSpec = {
      actionItems: {
        $push: [selectedActionItem],
      },
    };
  }

  return update(report, updateSpec);
}
