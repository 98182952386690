import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper'
import findIndex from 'lodash/findIndex'
import { Form, FormGroup, Label, Input, Alert } from 'reactstrap';

class GroupList extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleGroupSelect = this.handleGroupSelect.bind(this);
  }

  handleGroupSelect(group) {
    let updateSpec;
    if (this.props.selectedUser.groups) {
      updateSpec = {
        groups: {
          $push: [group]
        }
      };
    }
    else {
      updateSpec = {
        groups: {
          $set: [group],
        }
      };
    }

    const updatedUser = update(this.props.selectedUser, updateSpec);
    this.props.editSelectedUser(updatedUser);
  }

  handleGroupUnselect(group) {
    const index = findIndex(this.props.selectedUser.groups, current => current.id === group.id);
    const updateSpec = {
      groups: {
        $splice: [[index, 1]],
      }
    };
    const updatedUser = update(this.props.selectedUser, updateSpec);
    this.props.editSelectedUser(updatedUser);
  }

  render() {
    if (!this.props.allGroups || !this.props.allGroups.length) {
      return <Alert color="info">No groups have been defined for this organization</Alert>;
    }

    const selectedGroupIds = (this.props.selectedUser.groups && this.props.selectedUser.groups.map(current => current.id)) || [];
    return (
      <Form>
        {this.props.allGroups.map(current => (
          <FormGroup check key={`${current.id}`}>
            <Label check>
              <Input
                type="checkbox"
                checked={selectedGroupIds.indexOf(current.id) !== -1}
                onChange={e => e.target.checked ? this.handleGroupSelect(current) : this.handleGroupUnselect(current)}
              />
              {' '}
              {current.name}
            </Label>
          </FormGroup>
        ))}
      </Form>
    );
  }
}

GroupList.propTypes = {
  allGroups: PropTypes.array,
  selectedUser: PropTypes.object.isRequired,
  editSelectedUser: PropTypes.func.isRequired,
};

GroupList.defaultProps = {
  allGroups: [],
};

export default GroupList;
