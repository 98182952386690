import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import * as SiteSelectors from '../../../../../../../SiteSelectors';
import * as MviEditUberSelectors from '../../../MviEditUberSelectors';
import ActionItemEdit from './ActionItemEdit';
import * as ActionItemSelectors from './ActionItemsSelectors';
import * as ActionItemsActions from './ActionItemsActions';
import {track} from '../../../../../../../../../shared/analytics';

const NewActionItem = ({
  className,
  canEdit,
  editDisabled,
  isAddingActionItem,
  selectedActionItem,
  selectNewForEdit,
  editActionItem,
  cancel,
  saveChanges
}) => {
  if (!canEdit) {
    return null;
  }
  else if (isAddingActionItem) {
    return <ActionItemEdit className={className} actionItem={selectedActionItem} onEdit={editActionItem} onCancel={cancel} onSave={saveChanges} />;
  }
  return <Button className={className} size="sm" color="primary" onClick={selectNewForEdit} disabled={editDisabled}>Add</Button>;
};

NewActionItem.propTypes = {
  className: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  isAddingActionItem: PropTypes.bool.isRequired,
  selectedActionItem: PropTypes.object,
  selectNewForEdit: PropTypes.func.isRequired,
  editActionItem: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
};

NewActionItem.defaultProps = {
  className: null,
  selectedActionItem: null,
};

function mapStateToProps(state) {
  return {
    canEdit: SiteSelectors.canEditMvis(state),
    editDisabled: MviEditUberSelectors.isEditDisabled(state),
    isAddingActionItem: ActionItemSelectors.isAdding(state),
    selectedActionItem: ActionItemSelectors.selectedActionItem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectNewForEdit: () => {
      track('Inspection', {
        Component: 'Action items',
        'Inspection action': 'Edit action item',
        'New action item': true,
      });
      return Promise.resolve(dispatch(ActionItemsActions.selectNewForEdit())).then(() => window.scrollTo(0, document.body.scrollHeight));
    },
    editActionItem: actionItem => dispatch(ActionItemsActions.editActionItem(actionItem)),
    cancel: () => dispatch(ActionItemsActions.cancel()),
    saveChanges: () => {
      track('Inspection', {
        Component: 'Action items',
        'Inspection action': 'Save action item',
        'New action item': true,
      });
      return dispatch(ActionItemsActions.saveChanges());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewActionItem);
