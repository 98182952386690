import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Input, Label } from 'reactstrap';


const IssueExportForm = (props) => (
  <Form>
    <FormGroup check>
      <Label check>
        <Input
          type="checkbox"
          checked={props.analysisRecChecked}
          onChange={e => props.onAnalysisRecCheck(e.target.checked)}
        />
        Analysis &amp; Troubleshooting
      </Label>
    </FormGroup>
    <FormGroup check>
      <Label check>
        <Input
          type="checkbox"
          checked={props.commentsChecked}
          onChange={e => props.onCommentsCheck(e.target.checked)}
        />
        Comments
      </Label>
    </FormGroup>
    <FormGroup check>
      <Label check>
        <Input
          type="checkbox"
          checked={props.attachmentsChecked}
          onChange={e => props.onAttachmentsCheck(e.target.checked)}
        />
        Documents
      </Label>
    </FormGroup>
    <FormGroup check>
      <Label check>
        <Input
          type="checkbox"
          checked={props.atgReportsChecked}
          onChange={e => props.onAtgReportsCheck(e.target.checked)}
        />
        ATG Reports
      </Label>
    </FormGroup>
  </Form>
);

IssueExportForm.propTypes = {
  analysisRecChecked: PropTypes.bool.isRequired,
  onAnalysisRecCheck: PropTypes.func.isRequired,

  commentsChecked: PropTypes.bool.isRequired,
  onCommentsCheck: PropTypes.func.isRequired,

  attachmentsChecked: PropTypes.bool.isRequired,
  onAttachmentsCheck: PropTypes.func.isRequired,

  atgReportsChecked: PropTypes.bool.isRequired,
  onAtgReportsCheck: PropTypes.func.isRequired,
};

export default IssueExportForm;
