import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContactDetails from '../../../shared/components/contact-details/ContactDetails';
import LabelList from '../../../shared/components/label/LabelList';
import ConnectionType from '../../../shared/components/ConnectionType';
import { dateOrTimeAsText } from '../../../shared/components/date';
import { SiteConnectionStatus } from '../../../AppConstants';

function renderSiteConnectionWarning(site) {
  if (!site || !site.connection) {
    return null;
  }
  const ok = site.connection.connectionStatus === SiteConnectionStatus.OK;
  const lastConnected = site.connection.latestEventDate ? `Last connected ${dateOrTimeAsText(site.connection.latestEventDate)}` : null;
  return (
    <div className="d-inline-block">
      <span className={classNames({ 'text-danger': !ok }, 'mr-2')}>{lastConnected}</span>
      <ConnectionType
        connectionType={site.connection && site.connection.connectionType}
        connectionStatus={site.connection && site.connection.connectionStatus}
        latestEventDate={site.connection && site.connection.latestEventDate}
        style={{ height: '2rem' }}
      />
    </div>
  );
}

const SiteHeader = ({ site }) => (
  <div className="row">
    <div className="col-md-9">
      <div>
        <h2 className="d-inline-block mr-4">{site.nickname}</h2>
        <LabelList labels={site.labels} />
      </div>
      <ContactDetails value={site} className="text-muted" />
    </div>
    <div className="col-md-3 d-flex justify-content-end">
      {renderSiteConnectionWarning(site)}
    </div>
  </div>
);

SiteHeader.propTypes = {
  site: PropTypes.object.isRequired,
};

export default SiteHeader;
