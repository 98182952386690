import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CanaryLoadingIndicator from '../../../../../shared/components/CanaryLoadingIndicator';
import { relativeTimestampAsText } from '../../../../../shared/components/date';
import Subheader from '../../../../../shared/components/Subheader';
import AlarmCard from '../../../components/AlarmCard';
import * as selectors from '../AlarmSelectors';
import * as actions from '../actions';


const buildActiveAlarmKey = activeAlarm => `${activeAlarm.alarmCategoryCode}_${activeAlarm.alarmNumber}_${activeAlarm.affectedItemId}`;

class AlarmStatus extends Component {
  componentDidMount() {
    this.props.getAlarmStatus();
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.renderHeader()}
        {this.renderLastUpdated()}
        {this.renderAlarmCards()}
      </div>
    );
  }

  renderHeader() {
    const count = (this.props.alarmStatus && this.props.alarmStatus.activeAlarms && this.props.alarmStatus.activeAlarms.length) || 0;
    const text = `Active Alarms (${count})`;
    return <Subheader text={text} />;
  }

  renderLastUpdated() {
    const alarmStatus = this.props.alarmStatus;
    if (alarmStatus && alarmStatus.statusDate) {
      const lastUpdated = `Updated ${relativeTimestampAsText(alarmStatus.statusDate)}`;
      return (
        <div className="ml-4 my-2">
          <span className="text-muted">{lastUpdated}</span>
        </div>
      );
    }
    return null;
  }

  renderAlarmCards() {
    if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }

    const alarmStatus = this.props.alarmStatus;
    if (!alarmStatus) {
      return (
        <div className="ml-4 my-2">
          <h5>Alarm status unavailable.</h5>
        </div>
      );
    }
    else if (!alarmStatus.activeAlarms || !alarmStatus.activeAlarms.length) {
      return (
        <div className="ml-4 my-2">
          <h5>No active alarms</h5>
        </div>
      );
    }

    return alarmStatus.activeAlarms.map(activeAlarm => (
      <AlarmCard
        key={buildActiveAlarmKey(activeAlarm)}
        title={activeAlarm.title}
        date={activeAlarm.estimatedAlarmDate}
        className="mb-3"
      />
    ));
  }
}


AlarmStatus.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  alarmStatus: PropTypes.shape({
    statusDate: PropTypes.string.isRequired,
    activeAlarms: PropTypes.arrayOf(PropTypes.shape({
      alarmCategoryCode: PropTypes.number,
      alarmNumber: PropTypes.number,
      affectedItemId: PropTypes.number,
      title: PropTypes.string,
      estimatedAlarmDate: PropTypes.string,
    }))
  }),
  getAlarmStatus: PropTypes.func.isRequired,
};

AlarmStatus.defaultProps = {
  className: null,
  alarmStatus: null,
};

function mapStateToProps(state) {
  return {
    loading: selectors.isAlarmStatusLoading(state),
    alarmStatus: selectors.alarmStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAlarmStatus: () => dispatch(actions.getAlarmStatus()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlarmStatus);
