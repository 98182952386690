/* eslint-disable react/no-unused-prop-types, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import LabelList from '../label/LabelList';
import PropertyColumn from './PropertyColumn';

const render = value => (
  <div className="d-inline-flex flex-wrap">
    <LabelList labels={value} />
  </div>
);

function LabelListColumn(props) {
  const { title, property, rowData } = props;

  return (
    <PropertyColumn
      title={title}
      property={property}
      render={render}
      rowData={rowData}
    />
  );
}

LabelListColumn.propTypes = {
  sortable: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  property: PropTypes.string.isRequired,

  rowData: PropTypes.object,
};

LabelListColumn.defaultProps = {
  sortable: false,
  title: null,
  width: null,
  rowData: null,
};

export default LabelListColumn;
