import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

class Hour extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    if (event.target.value !== '') {
      const numVal = Number.parseInt(event.target.value, 10);
      this.props.onHourSelect(numVal);
    }
    else {
      this.props.onHourSelect(null);
    }
  }

  render() {
    return (
      <Input
        type="select"
        name="hour"
        className="form-control-sm"
        value={this.props.value !== null ? this.props.value : ''}
        onChange={this.handleChange}
        style={{ display: 'inline', width: '75px', marginLeft: '10px', marginRight: '10px' }}
        disabled={this.props.disabled}
      >
        <option value="">{' '}</option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
      </Input>
    );
  }
}

Hour.propTypes = {
  value: PropTypes.number,
  disabled: PropTypes.bool,
  onHourSelect: PropTypes.func.isRequired,
};

Hour.defaultProps = {
  value: null,
  disabled: false,
};

export default Hour;
