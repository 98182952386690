import React from 'react';
import PropTypes from 'prop-types';

import { TankMaterialType } from '../EquipmentConstants';
import PlaceholderTankIcon from './PlaceholderTankIcon';
import FRPDoubleTankIcon from './icon-lib/FRPDoubleTankIcon';
import FRPSingleTankIcon from './icon-lib/FRPSingleTankIcon';
import UL1746Part1SingleWallTankIcon from './icon-lib/UL1746Part1SingleWallTankIcon';
import UL1746Part1DoubleWallTankIcon from './icon-lib/UL1746Part1DoubleWallTankIcon';
import UL1746Part2SingleWallTankIcon from './icon-lib/UL1746Part2SingleWallTankIcon';
import UL1746Part2DoubleWallTankIcon from './icon-lib/UL1746Part2DoubleWallTankIcon';
import UL1746Part3TankIcon from './icon-lib/UL1746Part3TankIcon';
import SteelDoubleTankIcon from './icon-lib/SteelDoubleTankIcon';
import SteelSingleTankIcon from './icon-lib/SteelSingleTankIcon';

import helper from './MaterialHelper';

const TankRegistrationIcon = ({ registration, text }) => {
  const construction = registration && registration.construction;

  if (helper.isFRPDoubleWall(construction)) {
    return <FRPDoubleTankIcon text={text} />;
  }
  else if (helper.isFRPSingleWall(construction) || construction.primaryMaterial === TankMaterialType.FRP) {
    return <FRPSingleTankIcon text={text} />;
  }
  else if (helper.isUL1746Part1SingleWall(construction)) {
    return <UL1746Part1SingleWallTankIcon text={text} />;
  }
  else if (helper.isUL1746Part1DoubleWall(construction)) {
    return <UL1746Part1DoubleWallTankIcon text={text} />;
  }

  // use the same icon for UL 1746 part 2 and part 4.  the only difference is the coating material (FRP vs urethane)
  else if (helper.isUL1746Part2SingleWall(construction) || helper.isUL1746Part4SingleWall(construction)) {
    return <UL1746Part2SingleWallTankIcon text={text} />;
  }
  else if (helper.isUL1746Part2DoubleWall(construction) || helper.isUL1746Part4DoubleWall(construction)) {
    return <UL1746Part2DoubleWallTankIcon text={text} />;
  }

  else if (helper.isUL1746Part3(construction)) {
    return <UL1746Part3TankIcon text={text} />;
  }
  else if (helper.isSteelDoubleWall(construction)) {
    return <SteelDoubleTankIcon text={text} />;
  }
  else if (construction.primaryMaterial === TankMaterialType.Steel) {
    return <SteelSingleTankIcon text={text} />;
  }

  return <PlaceholderTankIcon text={text} />;
};

TankRegistrationIcon.propTypes = {
  registration: PropTypes.object.isRequired,
  text: PropTypes.any,
};

TankRegistrationIcon.defaultProps = {
  text: '',
};

export default TankRegistrationIcon;
