const getState = state => (state && state.shared && state.shared.navContext) || [];

export const originType = state => getState(state).originType || null;

export const originUrl = state => getState(state).originUrl || null;

export const urls = state => getState(state).urls || [];

export const index = state => getState(state).index || 0;

export const count = state => urls(state).length;

export const prevUrl = state => urls(state)[index(state) - 1] || null;

export const nextUrl = state => urls(state)[index(state) + 1] || null;

export const hasContext = state => !!urls(state).length;
