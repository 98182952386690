import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import update from 'immutability-helper';

import { withTracking } from '../../../shared/analytics';
import PreferencesHeaderBar from './PreferencesHeaderBar';
import {
  getCustomerSites,
  loadPrefsForUser,
  saveSelectedUserNotificationLowProductRule,
  selectUserNotificationLowProductRule,
  updateSelectedUserNotificationLowProductRule
} from '../actions';
import SiteSelector from './SiteSelector';
import * as PreferenceSelectors from '../PreferencesSelectors';
import CanaryLoadingIndicator from '../../../shared/components/CanaryLoadingIndicator';
import PreferenceConstants, { emptyArray } from '../PreferenceConstants';
import UserAlert from './UserAlert';
import UserErrorMessage from './UserErrorMessage';

class UserLowProductNotificationRules extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.handleAddSite = this.handleAddSite.bind(this);
    this.handleRemoveSite = this.handleRemoveSite.bind(this);
    this.handleSiteListTypeChange = this.handleSiteListTypeChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCustomerSelected = this.handleCustomerSelected.bind(this);

    this.renderBody = this.renderBody.bind(this);
    this.renderHeader = this.renderHeader.bind(this);

    this.getCustomerId = this.getCustomerId.bind(this);
  }

  componentDidMount() {
    this.props.loadPrefsForUser(this.props.userId)
      .then(() => this.props.selectUserNotificationLowProductRule(this.props.initialCustomerId))
      .then(() => this.props.getCustomerSites());
  }

  handleAddSite(siteContext, site) {
    const updatedLowProductNotificationRule = update(this.props.selectedUserNotificationLowProductRule, {
      siteListType: {
        $set: PreferenceConstants.listedSitesAllocated,
      },
      sites: {
        $push: [site],
      },
    });
    this.props.updateSelectedUserNotificationLowProductRule(updatedLowProductNotificationRule);
  }

  handleRemoveSite(siteContext, siteIndex) {
    const updatedLowProductNotificationRule = update(this.props.selectedUserNotificationLowProductRule, {
      sites: {
        $splice: [[siteIndex, 1]],
      },
    });

    this.props.updateSelectedUserNotificationLowProductRule(updatedLowProductNotificationRule);
  }

  handleSiteListTypeChange(siteListContext, siteListType) {
    const updatedLowProductNotificationRule = update(this.props.selectedUserNotificationLowProductRule, {
      siteListType: {
        $set: siteListType,
      },
      sites: {
        $set: emptyArray,
      },
    });

    this.props.updateSelectedUserNotificationLowProductRule(updatedLowProductNotificationRule);
  }

  handleCancel() {
    this.props.selectUserNotificationLowProductRule(this.props.selectedUserNotificationLowProductRule.customerId);
  }

  handleSave() {
    this.props.saveSelectedUserNotificationLowProductRule();
  }

  handleCustomerSelected(customerId) {
    this.props.selectUserNotificationLowProductRule(customerId);
  }

  getCustomerId() {
    return (this.props.selectedUserNotificationLowProductRule && this.props.selectedUserNotificationLowProductRule.customerId) || undefined;
  }

  renderBody() {
    return (
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <SiteSelector
                handleRemoveSite={this.handleRemoveSite}
                handleSiteListTypeChange={this.handleSiteListTypeChange}
                siteContext={this.props.siteContext}
                onConfirm={this.handleSave}
                onCancel={this.handleCancel}
                handleAddSite={this.handleAddSite}
                confirmButtonLabel="Save"
                enableConfirmButton={this.props.transient}
                enableCancelButton={this.props.transient}
                displayNoSitesOption
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  renderHeader() {
    return (
      <div>
        <PreferencesHeaderBar
          displayText="Low Production Notifications for"
          customers={this.props.customers}
          customerId={this.getCustomerId()}
          onSetCustomerId={this.handleCustomerSelected}
          keyPrefix="lowProductNotificationRules"
        />
        <UserAlert text={PreferenceConstants.unsavedChangesWarning} isOpen={this.props.transient} />
      </div>
    );
  }

  render() {
    if (this.props.isLoading || !this.props.selectedUserNotificationLowProductRule) {
      return (
        <CanaryLoadingIndicator />
      );
    }

    return (
      <div>
        <UserErrorMessage />
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  }
}

UserLowProductNotificationRules.propTypes = {
  userId: PropTypes.number.isRequired,
  customers: PropTypes.array.isRequired,
  initialCustomerId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectUserNotificationLowProductRule: PropTypes.func.isRequired,
  selectedUserNotificationLowProductRule: PropTypes.shape({
    id: PropTypes.number,
    userId: PropTypes.number.isRequired,
    customerId: PropTypes.number.isRequired,
    siteListType: PropTypes.string.isRequired,
    sites: PropTypes.array.isRequired,
  }),
  siteContext: PropTypes.shape({
    triggeringSites: PropTypes.shape({
      siteListType: PropTypes.string.isRequired,
      sites: PropTypes.array,
    }),
    availableSites: PropTypes.array.isRequired,
  }),
  updateSelectedUserNotificationLowProductRule: PropTypes.func.isRequired,
  saveSelectedUserNotificationLowProductRule: PropTypes.func.isRequired,
  transient: PropTypes.bool,
  loadPrefsForUser: PropTypes.func.isRequired,
  getCustomerSites: PropTypes.func.isRequired,
};

UserLowProductNotificationRules.defaultProps = {
  selectedUserNotificationLowProductRule: null,
  siteContext: null,
  transient: false,
};

function mapStateToProps(state) {
  return {
    selectedUserNotificationLowProductRule: PreferenceSelectors.selectedUserNotificationLowProductRule(state),
    isLoading: PreferenceSelectors.isLoadingUserNotificationLowProductRule(state),
    siteContext: PreferenceSelectors.userNotificationLowProductSiteContext(state),
    transient: PreferenceSelectors.isSelectedUserNotificationLowProductRuleTransient(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectUserNotificationLowProductRule: (customerId) => dispatch(selectUserNotificationLowProductRule(customerId)),
    updateSelectedUserNotificationLowProductRule: userNotificationLowProductRule => dispatch(updateSelectedUserNotificationLowProductRule(userNotificationLowProductRule)),
    saveSelectedUserNotificationLowProductRule: () => dispatch(saveSelectedUserNotificationLowProductRule()),
    loadPrefsForUser: (userId) => dispatch(loadPrefsForUser(userId)),
    getCustomerSites: () => dispatch(getCustomerSites()),
  };
}

export default withTracking('Preferences', 'Low Product')(connect(mapStateToProps, mapDispatchToProps)(UserLowProductNotificationRules));
