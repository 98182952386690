import ActionTypes from './InvDashboardActionTypes';

export default function InvDashboardReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_HISTORY_STARTED:
      return Object.assign({}, state, {
        hourlyHistoryLoading: true,
        hourlyHistoryList: state.hourlyHistoryList || [],
      });
    case ActionTypes.GET_HISTORY_FAILED:
      return Object.assign({}, state, {
        hourlyHistoryLoading: false,
        hourlyHistoryList: [],
      });
    case ActionTypes.RECEIVE_HISTORY:
      return Object.assign({}, state, {
        hourlyHistoryLoading: false,
        hourlyHistoryList: action.hourlyHistoryList,
      });

    case ActionTypes.GET_FUEL_HAULER_HEALTH_STARTED:
      return Object.assign({}, state, {
        fuelHaulerHealthLoading: true,
        fuelHaulerHealth: state.fuelHaulerHealth || null,
      });
    case ActionTypes.GET_FUEL_HAULER_HEALTH_FAILED:
      return Object.assign({}, state, {
        fuelHaulerHealthLoading: false,
        fuelHaulerHealth: null,
      });
    case ActionTypes.RECEIVE_FUEL_HAULER_HEALTH:
      return Object.assign({}, state, {
        fuelHaulerHealthLoading: false,
        fuelHaulerHealth: action.fuelHaulerHealth,
      });

    default:
      return state;
  }
}
