import React, { Component } from 'react';
import { Alert, Form, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper/index';

import HelpIcon from '../../../../../components/help-icon/HelpIcon';

class SettingsForm extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.state = {
      delimiterHelp: false,
      includeColumnNameHelp: false,
      quoteValueHelp: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.toggleDelimiterHelp = this.buildToggleHelpFunction('delimiterHelp');
    this.handleDelimiterChange = this.handleDelimiterChange.bind(this);
    this.toggleIncludeColumnNameHelp = this.buildToggleHelpFunction('includeColumnNameHelp');
    this.handleIncludeHeaderChange = this.handleIncludeHeaderChange.bind(this);
    this.toggleQuoteValueHelp = this.buildToggleHelpFunction('quoteValueHelp');
    this.handleQuoteValuesChange = this.handleQuoteValuesChange.bind(this);
  }

  buildToggleHelpFunction(propName) {
    function func() {
      const updateSpec = {};
      updateSpec[propName] = !this.state[propName];
      this.setState(updateSpec);
    }
    return func.bind(this);
  }

  handleNameChange(event) {
    const updatedInventoryFormat = update(this.props.selectedInventoryFormat, {
      name: {
        $set: event.target.value,
      },
    });
    this.props.updateSelectedFormat(updatedInventoryFormat);
  }

  handleDelimiterChange(event) {
    const updatedInventoryFormat = update(this.props.selectedInventoryFormat, {
      delimiterCharacter: {
        $set: event.target.value,
      },
    });
    this.props.updateSelectedFormat(updatedInventoryFormat);
  }

  handleIncludeHeaderChange(event) {
    const updatedInventoryFormat = update(this.props.selectedInventoryFormat, {
      includeHeader: {
        $set: event.target.checked,
      },
    });
    this.props.updateSelectedFormat(updatedInventoryFormat);
  }

  handleQuoteValuesChange(event) {
    const updatedInventoryFormat = update(this.props.selectedInventoryFormat, {
      quote: {
        $set: event.target.checked,
      },
    });
    this.props.updateSelectedFormat(updatedInventoryFormat);
  }

  render() {
    if (!this.props.selectedInventoryFormat) {
      return null;
    }

    return (
      <div className="mt-4">
        <Row>
          <Col md={8}>
            <h6>File Format</h6>
            <Form>
              <FormGroup row>
                <Label for="name" sm={4}>File format name</Label>
                <Col sm={6}>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="File format name"
                    value={this.props.selectedInventoryFormat.name}
                    onChange={this.handleNameChange}
                  />
                </Col>
              </FormGroup>
              <Row>
                <Col sm={12}>
                  <Alert color="info" isOpen={this.state.delimiterHelp} toggle={this.toggleDelimiterHelp}>
                    Select the character used to separate the columns of data included in each line of the export file.
                    <br /><br />
                    Example of a comma delimited file:
                    <div className="m-1">
                      <em>&quot;canary_site_id&quot;,&quot;tank_number&quot;,&quot;product_label&quot;</em><br />
                      <em>&quot;FOO-PA0001&quot;,&quot;1&quot;,&quot;UNLEADED&quot;</em><br />
                      <em>&quot;FOO-PA0001&quot;,&quot;2&quot;,&quot;SUPER&quot;</em><br />
                    </div>
                    Example of a pipe delimited file:
                    <div className="m-1">
                      <em>&quot;canary_site_id&quot;|&quot;tank_number&quot;|&quot;product_label&quot;</em><br />
                      <em>&quot;FOO-PA0001&quot;|&quot;1&quot;|&quot;UNLEADED&quot;</em><br />
                      <em>&quot;FOO-PA0001&quot;|&quot;2&quot;|&quot;SUPER&quot;</em><br />
                    </div>
                    If in doubt, select <em>Comma</em>.
                  </Alert>
                </Col>
              </Row>
              <FormGroup row>
                <Col sm={4}>
                  <Label for="delimiterCharacter">Delimiter character</Label>
                  <HelpIcon onClick={this.toggleDelimiterHelp} className="ml-1" />
                </Col>
                <Col sm={6}>
                  <Input
                    type="select"
                    id="delimiterCharacter"
                    name="delimiterCharacter"
                    value={this.props.selectedInventoryFormat.delimiterCharacter}
                    onChange={this.handleDelimiterChange}
                  >
                    <option value=",">Comma</option>
                    <option value="&#9;">Tab</option>
                    <option value="|">Pipe</option>
                  </Input>
                </Col>
              </FormGroup>
              <Row>
                <Col sm={12}>
                  <Alert color="info" isOpen={this.state.includeColumnNameHelp} toggle={this.toggleIncludeColumnNameHelp}>
                    Select this option to include the name of the columns as the first line of the export file. Generally
                    this option should remained checked. Uncheck this option if the export file is being processed by
                    a computer and the recipient has hard coded the index of each column in their ingest process.

                    <br /><br />
                    Example of a file with column names:
                    <div className="m-1">
                      <em>&quot;canary_site_id&quot;,&quot;tank_number&quot;,&quot;product_label&quot;</em><br />
                      <em>&quot;FOO-PA0001&quot;,&quot;1&quot;,&quot;UNLEADED&quot;</em><br />
                      <em>&quot;FOO-PA0001&quot;,&quot;2&quot;,&quot;SUPER&quot;</em><br />
                    </div>
                    Example of a file without column names:
                    <div className="m-1">
                      <em>&quot;FOO-PA0001&quot;,&quot;1&quot;,&quot;UNLEADED&quot;</em><br />
                      <em>&quot;FOO-PA0001&quot;,&quot;2&quot;,&quot;SUPER&quot;</em><br />
                    </div>
                  </Alert>
                </Col>
              </Row>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={this.props.selectedInventoryFormat.includeHeader} onChange={this.handleIncludeHeaderChange} />{' '}
                  Include column names as the first row of data
                </Label>
                <HelpIcon onClick={this.toggleIncludeColumnNameHelp} className="ml-1" />
              </FormGroup>
              <Row>
                <Col sm={12}>
                  <Alert color="info" isOpen={this.state.quoteValueHelp} toggle={this.toggleQuoteValueHelp}>
                    Select this option to include quotes around each column value in the export file. Surrounding
                    the values with quotes prevents problems when some of the data contains delimiter characters.
                    For example, a site nickname or tank product label that contains a comma. Generally
                    this option should remained checked.
                    <br /><br />
                    Example of a file with quoted values:
                    <div className="m-1">
                      <em>&quot;canary_site_id&quot;,&quot;tank_number&quot;,&quot;product_label&quot;</em><br />
                      <em>&quot;FOO-PA0001&quot;,&quot;1&quot;,&quot;UNLEADED&quot;</em><br />
                      <em>&quot;FOO-PA0001&quot;,&quot;2&quot;,&quot;SUPER&quot;</em><br />
                    </div>
                    Example of a file without quoted values:
                    <div className="m-1">
                      <em>canary_site_id,tank_number,product_label</em><br />
                      <em>FOO-PA0001,1,UNLEADED</em><br />
                      <em>FOO-PA0001,2,SUPER</em><br />
                    </div>
                  </Alert>
                </Col>
              </Row>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={this.props.selectedInventoryFormat.quote} onChange={this.handleQuoteValuesChange} />{' '}
                  Quote all values
                </Label>
                <HelpIcon onClick={this.toggleQuoteValueHelp} className="ml-1" />
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

SettingsForm.propTypes = {
  selectedInventoryFormat: PropTypes.object,
  updateSelectedFormat: PropTypes.func.isRequired,
};

SettingsForm.defaultProps = {
  selectedInventoryFormat: null,
};

export default SettingsForm;
