import React from 'react';
import PropTypes from 'prop-types';

import { buildTableReducer } from './redux/TableReducerFactory';
import CanaryTable from './CanaryTable';

const ActionTypes = {
  RECEIVE_LIST: 'receive_list',
  CHANGE_SORT: 'change_sort',
  CHANGE_PAGE: 'change_page',
};

const reducer = buildTableReducer(ActionTypes);

const tableReducer = (state, action) => {
  let updatedState = state;
  if (action.type === ActionTypes.RECEIVE_LIST) {
    updatedState = Object.assign({}, state, {
      list: action.list,
    });
  }
  return reducer(updatedState, action);
};

function StatefulTable(props) {
  const { data, initialSortColumn, initialSortDirection, ...rest } = props;
  if (!data || !data.length || !props.children) {
    return null;
  }

  const initialState = tableReducer({ list: data, sortColumnId: initialSortColumn, sortDirection: initialSortDirection }, { type: ActionTypes.RECEIVE_LIST });
  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  React.useEffect(() => {
    dispatch({ type: ActionTypes.RECEIVE_LIST, list: data });
  }, [data]);

  return (
    <CanaryTable
      data={state.filteredList}
      sortColumnId={state.sortColumnId}
      sortDirection={state.sortDirection}
      onSortChange={(sortColumnId, sortDirection) => dispatch({ type: ActionTypes.CHANGE_SORT, sortColumnId, sortDirection })}
      currentPage={state.currentPage}
      onPageChange={page => dispatch({ type: ActionTypes.CHANGE_PAGE, page })}
      pageCount={state.pageCount}
      {...rest}
    />
  );
}

StatefulTable.propTypes = {
  initialSortColumn: PropTypes.string.isRequired,
  initialSortDirection: PropTypes.string.isRequired,

  data: PropTypes.array,
  idField: PropTypes.string,
  idFunc: PropTypes.func,
  onRowClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

StatefulTable.defaultProps = {
  data: null,
  idField: 'id',
  idFunc: null,
  onRowClick: null,
  className: null,
};

export default StatefulTable;
