import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InventoryClient } from '../../../../../../../client';
import FilterMultiselect from '../FilterMultiselect';

class TankProductLabelSelect extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      productLabelObjs: [],
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    InventoryClient.getProductLabels().then(productLabels => {
      if (!this.unmounted) {
        const productLabelObjs = productLabels.map(current => ({ id: current, name: current }));
        this.setState({ productLabelObjs });
      }
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleSelect(productLabelObjs) {
    const productLabels = productLabelObjs.map(current => current.name);
    this.props.selectTankProductLabels(productLabels);
  }

  render() {
    const selectedObjs = this.props.selectedTankProductLabels.map(current => ({ id: current, name: current }));
    return (
      <FilterMultiselect
        textField="name"
        onItemsSelected={this.handleSelect}
        selectedItems={selectedObjs}
        allItems={this.state.productLabelObjs}
        className={this.props.className}
        disabled={this.props.disabled}
      />
    );
  }
}

TankProductLabelSelect.propTypes = {
  selectedTankProductLabels: PropTypes.arrayOf(PropTypes.string),
  selectTankProductLabels: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

TankProductLabelSelect.defaultProps = {
  selectedTankProductLabels: [],
  className: null,
};

export default TankProductLabelSelect;
