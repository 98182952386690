import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

import { track } from '../../analytics';
import { SortDirection } from './TableConstants';
import PageSelect from './PageSelect';
import CanaryLoadingIndicator from '../CanaryLoadingIndicator';
import { getDataIndex } from './TableUtils';

function buildStyleRule(columnElement, index) {
  if (!columnElement) {
    return `:nth-child(${index + 1}) { display: none }`;
  }

  const styles = [
    'overflow: hidden;',
    'text-overflow: ellipsis;',
    'white-space: nowrap;'
  ];

  if (columnElement.props.textAlign) {
    styles.push(`text-align: ${columnElement.props.textAlign};`);
  }
  if (columnElement.props.width) {
    styles.push(`width: ${columnElement.props.width};`);
  }

  return `:nth-child(${index + 1}) { ${styles.join(' ')} }`;
}

const StyledTable = styled('table').attrs(props => ({
  className: classnames(props.className, { 'table-hover': props.clickable }),
}))`
  table-layout: fixed;
  tr {
    ${props => (props.clickable && 'cursor: pointer;') || ''}
    th,td {
      ${props => props.columns.map(buildStyleRule).filter(current => !!current).join('\n')}
    }
  }
  tr:last-child {
    border-bottom: 1px solid #e8e6df;
  }
`;

const TableTrackProps = { component: 'table' };

const defaultColumnTitleRender = columnElement => columnElement.props.title || '';

const directionMap = new Map();
directionMap.set(SortDirection.Asc, SortDirection.Desc);
directionMap.set(SortDirection.Desc, SortDirection.Asc);

const getSortDirection = (isCurrentColumn, initialSortDirection, currentSortDirection) => (isCurrentColumn && currentSortDirection === initialSortDirection ? directionMap.get(initialSortDirection) : initialSortDirection);

class CanaryTable extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.renderRow = this.renderRow.bind(this);
    this.renderChildTh = this.renderChildTh.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleMovePrevPage = this.handleMovePrevPage.bind(this);
    this.handleMoveNextPage = this.handleMoveNextPage.bind(this);
    this.handlePageSelect = this.handlePageSelect.bind(this);
  }

  handleSortChange(columnElement) {
    const sortId = columnElement.props.columnId || columnElement.props.property;
    const isCurrentColumn = sortId === this.props.sortColumnId;

    let sortDirection = getSortDirection(isCurrentColumn, SortDirection.Asc, this.props.sortDirection);
    if (columnElement.props.initialSortDirection) {
      sortDirection = getSortDirection(isCurrentColumn, columnElement.props.initialSortDirection, this.props.sortDirection);
    }
    const trackProps = Object.assign({}, TableTrackProps, {
      'Table action type': 'Sort',
      Column: columnElement.props.title || sortId,
      'Sort direction': sortDirection,
    });
    track('Table', trackProps);
    this.props.onSortChange(sortId, sortDirection);
  }

  handleRowClick(e) {
    const index = getDataIndex(e.target);
    if (Number.isInteger(index)) {
      const trackProps = Object.assign(TableTrackProps, {
        'Table action type': 'Row click',
        'Row index': index
      });
      track('Table', trackProps);
      const rowData = this.props.data[index];
      this.props.onRowClick(rowData);
    }
  }

  handleMovePrevPage() {
    const trackProps = Object.assign({}, TableTrackProps, {
      'Table action type': 'Page prev',
      'Current table page': this.props.currentPage,
      'Page count': this.props.pageCount,
    });
    track('Table', trackProps);
    this.props.onPageChange(this.props.currentPage - 1);
  }

  handleMoveNextPage() {
    const trackProps = Object.assign({}, TableTrackProps, {
      'Table action type': 'Page next',
      'Current table page': this.props.currentPage,
      'Page count': this.props.pageCount,
    });
    track('Table', trackProps);
    this.props.onPageChange(this.props.currentPage + 1);
  }

  handlePageSelect(pageNumber) {
    const trackProps = Object.assign({}, TableTrackProps, {
      'Table action type': 'Page select',
      'Selected table page': pageNumber,
      'Current table page': this.props.currentPage,
      'Page count': this.props.pageCount,
    });
    track('Table', trackProps);
    this.props.onPageChange(pageNumber);
  }

  render() {
    const dataLoading = this.props.dataLoading;
    const hasData = this.props.data && this.props.data.length;

    let dataStatus = null;
    if (!hasData) {
      if (dataLoading) {
        dataStatus = <CanaryLoadingIndicator />;
      }
      else {
        dataStatus = <div className="text-center">{this.props.noDataMessage}</div>;
      }
    }

    return (
      <div>
        <StyledTable
          className="table table-sm canary-table-2 canary-table-stackable-md"
          clickable={!!this.props.onRowClick}
          columns={this.props.children}
        >
          <thead>
            <tr>
              {React.Children.map(this.props.children, this.renderChildTh)}
            </tr>
          </thead>
          <tbody>
            {hasData ? this.props.data.map(this.renderRow) : null}
          </tbody>
        </StyledTable>
        {dataStatus}
        {this.renderPagination()}
      </div>
    );
  }

  renderRow(rowData, rowIndex) {
    return (
      <tr
        key={this.buildRowKey(rowData, rowIndex)}
        onClick={this.props.onRowClick && this.handleRowClick}
        data-index={rowIndex}
        className={rowData && rowData.className}
      >
        {React.Children.map(this.props.children, (columnElement, colIndex) => this.renderChildTd(columnElement, rowData, colIndex))}
      </tr>
    );
  }

  buildRowKey(rowData, rowIndex) {
    if (this.props.idFunc) {
      return this.props.idFunc(rowData);
    }
    return rowData[this.props.idField] || rowIndex;
  }

  renderChildTh(columnElement, colIndex) {
    if (!columnElement) {
      return <th />;
    }

    const titleRenderFunc = columnElement.props.renderTitle || defaultColumnTitleRender;
    const sortId = columnElement.props.columnId || columnElement.props.property;
    const key = `column_${colIndex}_${sortId || columnElement.props.title}`;

    if (!this.props.onSortChange || !columnElement.props.title || !sortId || !columnElement.props.sortable) {
      return <th key={key}>{titleRenderFunc(columnElement)}</th>;
    }

    const className = sortId === this.props.sortColumnId ? 'canary-table-2-active-sort-column' : null;

    return (
      <th key={key}>
        <a
          href="#"
          className={className}
          onClick={e => {
            e.preventDefault();
            this.handleSortChange(columnElement);
          }}
        >
          {titleRenderFunc(columnElement)}
          {this.renderSortIcons(columnElement)}
        </a>
      </th>
    );
  }

  renderSortIcons(columnElement) {
    const sortId = columnElement.props.columnId || columnElement.props.property;
    if (sortId !== this.props.sortColumnId) {
      return null;
    }

    return this.props.sortDirection === SortDirection.Asc
      ? (<span className="px-2"><FontAwesomeIcon size="lg" icon={faCaretDown} /></span>)
      : (<span className="px-2"><FontAwesomeIcon size="lg" icon={faCaretUp} /></span>);
  }

  renderChildTd(columnElement, rowData, colIndex) {
    if (!columnElement) {
      return <td />;
    }

    const key = columnElement.props.columnId || columnElement.props.property || columnElement.props.title || `blank_${colIndex}`;
    return React.cloneElement(columnElement, { rowData, key });
  }

  renderPagination() {
    if (!this.props.onPageChange || !this.props.currentPage || this.props.pageCount <= 1) {
      return null;
    }

    const canMovePrev = this.props.currentPage > 1;
    const canMoveNext = this.props.pageCount && this.props.currentPage < this.props.pageCount;

    return (
      <div className="d-flex align-self-center justify-content-center mb-1">
        <Button
          className="mr-4"
          color="primary"
          size="sm"
          outline
          onClick={this.handleMovePrevPage}
          disabled={!canMovePrev}
        >
          Previous
        </Button>
        <div>
          <span className="mr-1">Page</span>
          <PageSelect
            currentPage={this.props.currentPage}
            pageCount={this.props.pageCount}
            onPageChange={this.handlePageSelect}
            className="d-inline-block"
          />
          <span className="ml-1">
            of&nbsp;
            {this.props.pageCount}
          </span>
        </div>
        <Button
          className="ml-4"
          color="primary"
          size="sm"
          outline
          onClick={this.handleMoveNextPage}
          disabled={!canMoveNext}
        >
          Next
        </Button>
      </div>
    );
  }
}

CanaryTable.propTypes = {
  dataLoading: PropTypes.bool,
  data: PropTypes.array,
  noDataMessage: PropTypes.string,
  idField: PropTypes.string,
  idFunc: PropTypes.func,
  onRowClick: PropTypes.func,

  sortColumnId: PropTypes.string,
  sortDirection: PropTypes.string,
  onSortChange: PropTypes.func,

  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  pageCount: PropTypes.number,

  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

CanaryTable.defaultProps = {
  dataLoading: false,
  data: null,
  noDataMessage: 'No data available',
  idField: 'id',
  idFunc: null,
  onRowClick: null,
  sortColumnId: null,
  sortDirection: null,
  onSortChange: null,
  currentPage: 0,
  onPageChange: null,
  pageCount: 0,
};

export default CanaryTable;
