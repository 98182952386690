import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import PasswordSettings from './PasswordSettings';
import EmailSettings from './EmailSettings';
import ResetTours from '../../../shared/components/tour/ResetTours';
import { AuthenticationSelectors } from '../../../authentication';
import UserErrorMessage from './UserErrorMessage';

const AccountSettings = ({ currentUserId }) => (
  <>
    <UserErrorMessage />
    <div className="d-flex mb-2">
      <ResetTours userId={currentUserId} className="ml-md-auto" />
    </div>
    <Row>
      <Col md={6}>
        <PasswordSettings className="border rounded p-3" />
      </Col>
      <Col md={6}>
        <EmailSettings className="border rounded p-3" />
      </Col>
    </Row>
  </>
);

AccountSettings.propTypes = {
  currentUserId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUserId: AuthenticationSelectors.currentUserId(state),
  };
}

export default connect(mapStateToProps)(AccountSettings);
