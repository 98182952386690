import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Badge } from 'reactstrap';
import moment from 'moment-timezone';

import { DispatchStatuses } from '../../../../../AppConstants';
import { BrowserTimezone } from '../../../../../shared/components/date';
import { Column, StatefulTable, StringColumn, TimestampColumn } from '../../../../../shared/components/table';

function renderStatus(props) {
  if (!props.workflowStatus) {
    return (<span />);
  }

  let color;
  let text;
  if (!(props.workflowStatus === DispatchStatuses.CLOSED || props.workflowStatus === DispatchStatuses.EXPIRED)) {
    color = 'success';
    text = `OPEN ${moment.duration(moment.utc().diff(moment(props.creationDate))).humanize().toLocaleUpperCase()}`;
  }
  else {
    color = 'secondary';
    text = 'CLOSED';
  }

  return (<Badge className="text-inverted" color={color}>{text}</Badge>);
}

function renderTechNotes(props) {
  if (!props.workflowStatus || !props.techNotesUrl) {
    return (<span />);
  }
  const date = moment(props.receivedTechNotesOn).tz(BrowserTimezone).format('M/D/YY');
  return (
    <span>
      <a onClick={() => {
        window.open(props.techNotesUrl.url);
      }}
      >
        {' '}
        <FontAwesomeIcon icon={faCopy} />
        {' '}
        {date}
      </a>
    </span>
  );
}

function renderIssueIds(props, navToIssue) {
  if (!props.issueIds.length) {
    return (<span />);
  }

  return (props.issueIds.map((id, index) => (
    <a key={id} className="mr-1" onClick={() => navToIssue(id)}>
      {' '}
      { index === props.issueIds.length - 1 ? `${id}` : `${id},`}
    </a>
  )));
}

const DispatchTable = ({ dispatches, navToIssue }) => (
  <StatefulTable
    initialSortColumn="creationDate"
    initialSortDirection="desc"
    data={dispatches}
  >
    <TimestampColumn property="creationDate" title="Created" sortable width="12rem" />
    <StringColumn columnId="externalId" property="externalId" title="Work Order #" width="25%" />
    <StringColumn columnId="serviceProvider" property="systemName" title="Provider" width="15%" />
    <Column columnId="status" render={renderStatus} title="Status" width="15%" />
    <Column columnId="techNotes" render={renderTechNotes} title="Tech Notes" width="15%" />
    <Column columnId="issues" render={d => renderIssueIds(d, navToIssue)} title="Issues" width="15%" />
  </StatefulTable>
);

DispatchTable.propTypes = {
  dispatches: PropTypes.array.isRequired,
  navToIssue: PropTypes.func.isRequired,
};

export default DispatchTable;
