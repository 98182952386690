import { UserClient } from '../../../client';
import { AuthenticationSelectors } from '../../../authentication';
import * as selectors from './TourSelectors';
import ActionTypes from './TourActionTypes';

export function getCompletedTours() {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentUserId = AuthenticationSelectors.currentUserId(currentState);
    const loading = selectors.isLoading(currentState);
    const tours = selectors.tours(currentState);
    if (tours && tours.length) {
      return Promise.resolve(tours);
    }
    else if (!currentUserId || loading) {
      return Promise.resolve([]);
    }

    dispatch({ type: ActionTypes.GET_TOURS_STARTED });
    return UserClient.getCompletedTours(currentUserId)
      .then(completedTours => {
        dispatch({ type: ActionTypes.RECEIVE_TOURS, tours: completedTours });
        return completedTours;
      })
      .catch(error => {
        dispatch({ type: ActionTypes.GET_TOURS_FAILED, error });
      });
  };
}

export function markTourComplete(tourName) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentUserId = AuthenticationSelectors.currentUserId(currentState);
    const saving = selectors.isSaving(currentState);
    if (!currentUserId || saving) {
      return Promise.resolve();
    }
    dispatch({ type: ActionTypes.MARK_TOUR_COMPLETE_STARTED });
    return UserClient.markTourComplete(currentUserId, tourName)
      .then(() => {
        dispatch({ type: ActionTypes.MARK_TOUR_COMPLETE_SUCCESS, tourName });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.MARK_TOUR_COMPLETE_FAILED, error });
      });
  };
}

export function resetTours(userId) {
  return (dispatch, getState) => {
    const currentState = getState();
    const resetting = selectors.isResettingTours(currentState);
    if (!userId || resetting) {
      return Promise.resolve();
    }
    dispatch({ type: ActionTypes.RESET_TOURS_STARTED });
    return UserClient.resetAllTours(userId)
      .then(() => {
        dispatch({ type: ActionTypes.RESET_TOURS_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.RESET_TOURS_FAILED, error });
      });
  };
}
