import React from 'react';
import PropTypes from 'prop-types';
import { CanaryTable, StringColumn, Column, SortDirection, DateOrTimeColumn, IntegerColumn, LabelListColumn } from '../../../shared/components/table';
import { getDataIndex } from '../../../shared/components/table/TableUtils';
import { InventoryStatus } from '../../../AppConstants';
import TankIcon from './TankIcon';
import RefreshTime from './RefreshTime';
import WaterLevel from './WaterLevel';
import { InventoryTableColumns } from '../InventoryConstants';
import FuelHeight from './FuelHeight';

const DecimalFormatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

const renderCustomer = tankInventory => {
  if (tankInventory.hideInventoryDate) {
    return null;
  }
  return (tankInventory.customer && tankInventory.customer.name) || null;
};

const InventoryTable = ({ tankInventories, sitesInventoryRefreshInProgress, dataLoading, columns, changePage, changeSort, currentPage, pageCount, pageSize, sortColumnId, sortDirection, onSiteClick, onRefreshInventoryClick, noDataMessage }) => {
  const columnIds = columns.map(current => current.id);
  const hasColumn = col => columnIds.indexOf(col.id) !== -1;
  const refreshInProgress = tankInventory => sitesInventoryRefreshInProgress.some(s => s.id === tankInventory.site.id);

  const handleSiteClick = e => {
    e.preventDefault();
    const index = getDataIndex(e.target);
    if (Number.isInteger(index)) {
      const tankInventory = tankInventories[index];
      onSiteClick(tankInventory.site);
    }
  };

  const renderSiteLink = tankInventory => (<a href="#" onClick={handleSiteClick}>{tankInventory.site && tankInventory.site.nickname}</a>);

  return (
    <CanaryTable
      dataLoading={dataLoading}
      data={tankInventories}
      sortColumnId={sortColumnId}
      sortDirection={sortDirection}
      onSortChange={changeSort}
      currentPage={currentPage}
      onPageChange={changePage}
      pageSize={pageSize}
      pageCount={pageCount}
      noDataMessage={noDataMessage}
    >
      {hasColumn(InventoryTableColumns.Reading)
        ? (<Column columnId={InventoryTableColumns.Reading.id} render={tankInventory => <RefreshTime tankInventoryState={tankInventory} refreshInProgress={refreshInProgress(tankInventory)} onRefreshInventoryClick={onRefreshInventoryClick} />} title={InventoryTableColumns.Reading.shortName} width="9rem" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.Customer)
        ? (<Column columnId={InventoryTableColumns.Customer.id} render={renderCustomer} title={InventoryTableColumns.Customer.shortName} sortable width="7rem" />)
        : null}

      {hasColumn(InventoryTableColumns.SiteNickname)
        ? (<Column columnId={InventoryTableColumns.SiteNickname.id} render={renderSiteLink} onClick={handleSiteClick} title={InventoryTableColumns.SiteNickname.shortName} width="24%" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.SiteState)
        ? (<StringColumn columnId={InventoryTableColumns.SiteState.id} property="site.state" title={InventoryTableColumns.SiteState.shortName} sortable width="5rem" />)
        : null}

      {hasColumn(InventoryTableColumns.TankNumber)
        ? (<StringColumn columnId={InventoryTableColumns.TankNumber.id} property="tankNumber" title={InventoryTableColumns.TankNumber.shortName} width="1.75rem" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.TankLabel)
        ? (<StringColumn columnId={InventoryTableColumns.TankLabel.id} property="productLabel" title={InventoryTableColumns.TankLabel.shortName} width="15%" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.Status)
        ? (<Column columnId={InventoryTableColumns.Status.id} render={tankInventory => <TankIcon tankInventory={tankInventory} />} title={InventoryTableColumns.Status.shortName} width="10%" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.WaterHeight)
        ? (<Column columnId={InventoryTableColumns.WaterHeight.id} render={tankInventory => <WaterLevel tankInventory={tankInventory} />} title={InventoryTableColumns.WaterHeight.shortName} width="5rem" sortable initialSortDirection={SortDirection.Desc} />)
        : null}

      {hasColumn(InventoryTableColumns.FullVolumeGallons)
        ? (<IntegerColumn columnId={InventoryTableColumns.FullVolumeGallons.id} property="fullVolumeGallons" title={InventoryTableColumns.FullVolumeGallons.shortName} width="10%" textAlign="right" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.VolumeGallons)
        ? (<IntegerColumn columnId={InventoryTableColumns.VolumeGallons.id} property="volumeGallons" title={InventoryTableColumns.VolumeGallons.shortName} width="10%" textAlign="right" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.TcVolumeGallons)
        ? (<IntegerColumn columnId={InventoryTableColumns.TcVolumeGallons.id} property="tcVolumeGallons" title={InventoryTableColumns.TcVolumeGallons.shortName} width="10%" textAlign="right" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.Temperature)
        ? (<Column columnId={InventoryTableColumns.Temperature.id} render={i => (i.temperatureFahrenheit && i.temperatureFahrenheit.toLocaleString(undefined, DecimalFormatOptions)) || null} title={InventoryTableColumns.Temperature.shortName} width="10%" textAlign="right" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.FuelHeightInches)
        ? (<Column columnId={InventoryTableColumns.FuelHeightInches.id} render={tankInv => <FuelHeight tankInventory={tankInv} />} title={InventoryTableColumns.FuelHeightInches.shortName} width="5rem" textAlign="right" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.Ullage90Percent)
        ? (<IntegerColumn columnId={InventoryTableColumns.Ullage90Percent.id} property="ullage90PercentGallons" title={InventoryTableColumns.Ullage90Percent.shortName} width="10%" textAlign="right" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.Ullage95Percent)
        ? (<IntegerColumn columnId={InventoryTableColumns.Ullage95Percent.id} property="ullage95PercentGallons" title={InventoryTableColumns.Ullage95Percent.shortName} width="10%" textAlign="right" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.Ullage)
        ? (<IntegerColumn columnId={InventoryTableColumns.Ullage.id} property="ullageGallons" title={InventoryTableColumns.Ullage.shortName} width="10%" textAlign="right" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.LatestDeliveryVolume)
        ? (<IntegerColumn columnId={InventoryTableColumns.LatestDeliveryVolume.id} property="deliveryVolumeGallons" title={InventoryTableColumns.LatestDeliveryVolume.shortName} textAlign="right" width="5rem" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.LatestDeliveryDate)
        ? (<DateOrTimeColumn columnId={InventoryTableColumns.LatestDeliveryDate} title={InventoryTableColumns.LatestDeliveryDate.shortName} property="deliveryStartDate" textAlign="right" width="9rem" sortable />)
        : null}

      {hasColumn(InventoryTableColumns.SiteLabels)
        ? (<LabelListColumn columnId={InventoryTableColumns.SiteLabels.id} property="siteLabels" title={InventoryTableColumns.SiteLabels.shortName} width="7%" />)
        : null}

    </CanaryTable>
  );
};

InventoryTable.propTypes = {
  tankInventories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteId: PropTypes.number,
    inventoryDate: PropTypes.string,
    volumeGallons: PropTypes.number,
    tcVolumeGallons: PropTypes.number,
    ullageGallons: PropTypes.number,
    fuelHeightInches: PropTypes.number,
    waterHeightInches: PropTypes.number,
    temperatureFahrenheit: PropTypes.number,
    waterVolumeGallons: PropTypes.number,
    alerts: PropTypes.shape({
      deliveryNeeded: PropTypes.bool,
      waterWarning: PropTypes.bool,
    }),
    fillPercentage: PropTypes.number,
    ullage90PercentGallons: PropTypes.number,
    ullage95PercentGallons: PropTypes.number,
    deliveryPollDate: PropTypes.string,
    deliveryStartDate: PropTypes.string,
    deliveryStartingVolumeGallons: PropTypes.number,
    deliveryEndingVolumeGallons: PropTypes.number,
    deliveryVolumeGallons: PropTypes.number,
    tankNumber: PropTypes.number,
    productLabel: PropTypes.string,
    productType: PropTypes.shape({
      dieselTaxClass: PropTypes.string,
      dieselBioModifier: PropTypes.string,
      displayName: PropTypes.string,
      productType: PropTypes.string,
    }),
    productCode: PropTypes.number,
    fullVolumeGallons: PropTypes.number,
    productThresholds: PropTypes.shape({
      deliveryNeededThresholdGallons: PropTypes.number,
      lowProductThresholdGallons: PropTypes.number,
    }),
    status: PropTypes.oneOf([InventoryStatus.GreaterThanSeventyFivePercent, InventoryStatus.LessThanSeventyFivePercent, InventoryStatus.DeliveryNeeded, InventoryStatus.Unknown]),
    customer: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      abbreviation: PropTypes.string,
    }),
    site: PropTypes.shape({
      id: PropTypes.number,
      nickname: PropTypes.string,
      siteTimezone: PropTypes.string,
      state: PropTypes.string,
    }),
    siteLabels: PropTypes.array,
  })).isRequired,
  sitesInventoryRefreshInProgress: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  sortColumnId: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  changeSort: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  onSiteClick: PropTypes.func.isRequired,
  onRefreshInventoryClick: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  noDataMessage: PropTypes.string.isRequired,
};

export default InventoryTable;
