import React from 'react';
import PropTypes from 'prop-types';

import { relativeTimestampAsText } from './FormatUtils';

const RelativeTimestamp = ({ time, longform }) => <span>{relativeTimestampAsText(time, longform)}</span>;

RelativeTimestamp.propTypes = {
  time: PropTypes.string,
  longform: PropTypes.bool,
};

RelativeTimestamp.defaultProps = {
  time: null,
  longform: true,
};

export default RelativeTimestamp;
