import update from 'immutability-helper';

import { Get, Post } from '../HttpUtils';
import { IntentRequestStatus } from '../../user/sites/screens/commands/CommandConstants';
import { buildStringSort, composeSort, withDirection } from '../../shared/SortUtils';
import { SortDirection } from '../../AppConstants';

let vrCommandPromise;

function enhanceEventsWithFunctionName(commandRequests) {
  if (!commandRequests || !commandRequests.length) {
    return Promise.resolve([]);
  }

  return vrCommandPromise.then(vrCommands => commandRequests.map((cr) => {
    if (cr.commandText) {
      const command = vrCommands.filter(cmd => cmd.functionCode === cr.commandText.substring(1, 4))[0];
      if (command) {
        return update(cr, {
          functionName: { $set: command.functionName },
        });
      }
    }

    return cr;
  }));
}

const byCreatedDesc = withDirection(composeSort(buildStringSort('created'), buildStringSort('commandText')))(SortDirection.Desc);

export default {
  getIntentRequestById(id) {
    return Get(`/v2/intents/${id}`).then(response => response.data);
  },

  sendIntentRequest(siteId, intentName, args) {
    const intentRequest = {
      siteId,
      intentName,
      args,
    };

    return Post('/v2/intents', intentRequest).then(response => response.data);
  },

  getRecentCommandRequests(siteId) {
    vrCommandPromise = vrCommandPromise || Get('/v2/commands/types').then(response => Promise.resolve(response.data));

    let requestUrl = '/v2/intents?include=id,commandRequests';
    requestUrl += `&siteIds=${siteId}`;
    requestUrl += `&statuses=${IntentRequestStatus.Failed},${IntentRequestStatus.Resolved}`;
    requestUrl += '&maxAgeDays=30';

    return Get(requestUrl).then(response => {
      const commandRequests = [];
      response.data.forEach(current => commandRequests.push(...current.commandRequests));
      commandRequests.sort(byCreatedDesc);
      return enhanceEventsWithFunctionName(commandRequests);
    });
  },

  getPendingIntentRequests(siteId) {
    return Get(`/v2/intents/?siteIds=${siteId}&statuses=${IntentRequestStatus.Pending}`).then(response => response.data);
  },
};
