import React, { Component } from 'react';
import { Form, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import NumberFormat from 'react-number-format';
import produce from 'immer';
import base64 from 'base64-js';
import PropTypes from 'prop-types';
import DropdownList from 'react-widgets/lib/DropdownList';
import { USStates } from '../../../../../../AppConstants';

class OrganizationSettings extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.setValue = this.setValue.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.saveToCanary = this.saveToCanary.bind(this);
  }

  saveToCanary(reader) {
    // const buffer = Buffer.from(reader.result);
    const fileBytes = [];
    const byteArray = new Uint8Array(reader.result);
    byteArray.forEach(b => fileBytes.push(b));
    const updatedOrganization = produce(this.props.selectedOrganization, (draft) => {
      draft.logo = fileBytes;
    });
    this.props.editSelectedOrganization(updatedOrganization);
  }

  uploadFile(event) {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => this.saveToCanary(reader);
    reader.readAsArrayBuffer(file);
  }

  setValue(propName, value, customerProp) {
    const updatedOrganization = produce(this.props.selectedOrganization, (draft) => {
      if (!customerProp) {
        draft[propName] = value;
      }
      else {
        draft.customer[propName] = value;
      }
    });

    this.props.editSelectedOrganization(updatedOrganization);
  }

  render() {
    const isExistingCustomer = Number.isInteger(this.props.selectedOrganization.id) && this.props.selectedOrganization.customer && Number.isInteger(this.props.selectedOrganization.customer.id);

    return (
      <div>
        <Row>
          <Col sm={10}>
            <Form>
              <FormGroup row>
                <Label sm={3} for="organizationName">Organization Name</Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="organizationName"
                    id="organizationName"
                    placeholder="Organization Name"
                    value={this.props.selectedOrganization.name || ''}
                    onChange={e => this.setValue('name', e.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3} for="organizationName">Domain Name</Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="domainName"
                    id="domainName"
                    placeholder="example.com"
                    value={this.props.selectedOrganization.domainName || ''}
                    onChange={e => this.setValue('domainName', e.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup check>
                <Label check for="isCustomer">
                  <Input
                    type="checkbox"
                    name="isCustomer"
                    id="isCustomer"
                    checked={this.props.selectedOrganization.customer && this.props.selectedOrganization.customer.abbreviation}
                    disabled={isExistingCustomer}
                    onChange={e => this.setValue('customer', e.target.checked ? {
                      appFeatures: [],
                      labels: [],
                      dispatchSystemConfigurations: [],
                    } : undefined)}
                  />
                  Customer?
                </Label>
              </FormGroup>
              { this.props.selectedOrganization.customer
                ? (
                  <FormGroup row>
                    <Label for="abbreviation" sm={3}>Abbreviation</Label>
                    <Col sm={3}>
                      <Input
                        type="text"
                        name="abbreviation"
                        id="abbreviation"
                        placeholder="Abbreviation"
                        disabled={isExistingCustomer}
                        value={this.props.selectedOrganization.customer.abbreviation || ''}
                        maxLength="3"
                        onChange={e => this.setValue('abbreviation', e.target.value, true)}
                      />
                    </Col>
                  </FormGroup>
                )
                : (<div />)}
              <FormGroup row>
                <Col sm={3}>
                  <Label for="streetAddress">Street Address</Label>
                </Col>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="streetAddress"
                    id="streetAddress"
                    placeholder="Address"
                    maxLength="128"
                    value={this.props.selectedOrganization.address || ''}
                    onChange={e => this.setValue('address', e.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={3}>
                  <Label for="city">City, State, and ZIP</Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    placeholder="City"
                    maxLength="64"
                    value={this.props.selectedOrganization.city || ''}
                    onChange={e => this.setValue('city', e.target.value)}
                  />
                </Col>
                <Col sm={2}>
                  <DropdownList
                    allowCreate={false}
                    onChange={value => this.setValue('state', value)}
                    data={USStates}
                    id="state"
                    defaultValue="PA"
                    value={this.props.selectedOrganization.state || ''}
                    placeholder="State"
                  />
                </Col>
                <Col sm={2}>
                  <NumberFormat placeholder="ZIP Code" onChange={e => this.setValue('zip', e.target.value)} value={this.props.selectedOrganization.zip || ''} name="zip" id="zip" customInput={Input} format="#####" mask=" " />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={3}>
                  <Label for="streetAddress">Phone</Label>
                </Col>
                <Col sm={9}>
                  <NumberFormat placeholder="215-555-1212" onChange={e => this.setValue('phone', e.target.value)} value={this.props.selectedOrganization.phone || ''} name="phone" id="phone" customInput={Input} format="###-###-####" mask="#" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={3}>
                  <Label for="streetAddress">Upload Logo</Label>
                </Col>
                <Col sm={9}>
                  <input type="file" name="OrganizationLogoUpload" onChange={this.uploadFile} />
                </Col>
              </FormGroup>
            </Form>
          </Col>
          <Col sm={2}>
            {
              this.props.selectedOrganization.logo && this.props.selectedOrganization.logo.data
                ? <img style={{ maxWidth: '120px' }} src={`data:image/png;base64, ${base64.fromByteArray(this.props.selectedOrganization.logo.data)}`} alt="Customer logo" />
                : null
            }
          </Col>
        </Row>
      </div>
    );
  }
}

OrganizationSettings.propTypes = {
  selectedOrganization: PropTypes.object.isRequired,
  editSelectedOrganization: PropTypes.func.isRequired,
};

export default OrganizationSettings;
