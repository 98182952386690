import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CanaryLoadingIndicator from '../../../../shared/components/CanaryLoadingIndicator';
import ErrorMessage from '../../../../shared/components/error-message/ErrorMessage';
import CanarySummaryBar from '../../../../shared/components/CanarySummaryBar';
import StackTrace from '../components/StackTrace';
import ErrorDetails from '../components/ErrorDetails';
import IngestFailureActionButton from '../components/IngestFailureActionButton';
import selectors from '../IngestFailureSelectors';
import * as actions from '../actions';
import IngestFailureFiles from '../components/IngestFailureFiles';

class IngestFailureDetails extends Component {
  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.ingestFailureId) {
      const id = Number.parseInt(this.props.match.params.ingestFailureId, 10);
      this.props.getIngestFailureById(id);
    }
  }

  render() {
    if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }
    else if (this.props.error) {
      return <ErrorMessage error={this.props.error} />;
    }
    else if (!this.props.ingestFailure) {
      return null;
    }

    const current = this.props.ingestFailure;
    const summary = `${current.subsystem} Ingest Failure ${current.id}`;
    const files = (this.props.ingestFailure && this.props.ingestFailure.files) || [];
    const stackTrace = this.props.ingestFailure && this.props.ingestFailure.exceptionDetails && this.props.ingestFailure.exceptionDetails.stackTrace;

    return (
      <>
        <CanarySummaryBar headerType="h3" summary={summary} controls={<IngestFailureActionButton />} />

        <h5 className="mt-3">Error Details</h5>
        <ErrorDetails ingestFailure={current} />

        {files.length ? <h5 className="mt-3">Files</h5> : null}
        <IngestFailureFiles files={files} onClick={this.props.navToIngestFailureFile} />

        {stackTrace ? <h5 className="mt-4">Stack Trace</h5> : null}
        <StackTrace stackTrace={stackTrace} />
      </>
    );
  }
}

IngestFailureDetails.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  ingestFailure: PropTypes.object,
  getIngestFailureById: PropTypes.func.isRequired,
  navToIngestFailureFile: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

IngestFailureDetails.defaultProps = {
  error: null,
  ingestFailure: null,
};

function mapStateToProps(state) {
  return {
    loading: selectors.isLoading(state),
    deleting: selectors.isDeleting(state),
    error: selectors.error(state),
    ingestFailure: selectors.item(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getIngestFailureById: id => dispatch(actions.getIngestFailureById(id)),
    navToIngestFailureFile: file => dispatch(actions.navToIngestFailureFile(file)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IngestFailureDetails));
