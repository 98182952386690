function stateSlice(state) {
  return (state && state.sites && state.sites.issues && state.sites.issues.edit && state.sites.issues.edit.export) || {};
}

export function selectedIssue(state) {
  return stateSlice(state).issue || null;
}

export function isModalOpen(state) {
  return stateSlice(state).modalOpen || false;
}

export function exportOptions(state) {
  return stateSlice(state).options || {};
}

export function isDownloading(state) {
  return stateSlice(state).downloading || false;
}
