import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

import TankRegistrationIcon from '../../../components/TankRegistrationIcon';
import PlaceholderTankIcon from '../../../components/PlaceholderTankIcon';
import MaterialText from '../../../components/MaterialText';
import WallType from './WallType';

class TankAndRegistration extends Component {

  static renderIcon(tank, registration) {
    const text = `T${tank.tankNumber}`;
    if (registration) {
      return <TankRegistrationIcon registration={registration} text={text} />;
    }
    return <PlaceholderTankIcon text={text} />;
  }

  static renderTitle(tank, registration) {
    let registrationText = 'unknown';
    if (registration && registration.tankRegistrationNumber) {
      registrationText = registration.tankRegistrationNumber;
    }
    return (
      <div className="d-flex flex-row flex-wrap">
        <strong>ATG Tank {tank.tankNumber}</strong>
        <strong className="mx-1">–</strong>
        <strong>Registration Number: {registrationText}</strong>
      </div>
    );
  }

  static renderProductLabel(tank, registration) {
    let text = tank.productLabel || 'Not labeled in ATG';
    if (registration && registration.productHistory && registration.productHistory.length) {
      text += ` (${registration.productHistory[0].product})`;
    }
    return (
      <div>
        <span>{text}</span>
      </div>
    );
  }

  static renderVolume(tank, registration) {
    let text = 'Unknown volume';
    if (tank && tank.fullVolumeGallons) {
      text = `${tank.fullVolumeGallons.toLocaleString()} gallons`;
    }
    else if (registration && registration.nominalSizeGallons) {
      text = `${registration.nominalSizeGallons.toLocaleString()} gallons`;
    }
    return (
      <div>
        <span>{text}</span>
      </div>
    );
  }

  static renderManifoldPartners(tank) {
    if (tank.manifoldPartners && tank.manifoldPartners.length) {
      const text = `Manifolds: ${tank.manifoldPartners.join(', ')}`;
      return (
        <div>
          <span>{text}</span>
        </div>
      );
    }
    return (
      <div>
        <span>Not manifolded</span>
      </div>
    );
  }

  static renderInstallDate(registration) {
    let text = 'Install date unknown';
    if (registration && registration.installation && registration.installation.installDate) {
      const momentDate = moment(registration.installation.installDate);
      const formattedDate = momentDate.format('M/D/YYYY');
      text = `Installed ${formattedDate}`;
    }
    return (
      <div>
        <span>{text}</span>
      </div>
    );
  }

  renderDetailsLink(tank, registration) {
    if (!this.props.navToTankRegistration || (!tank && !registration) || !(registration && registration.installation && registration.installation.installDate)) {
      return null;
    }

    return (
      <div>
        <Button color="link" onClick={() => this.props.navToTankRegistration(tank, registration)}>
          Details
          <span className="pl-2">
          <FontAwesomeIcon title="Edit tank details" icon={faEdit} />
          </span>
        </Button>
      </div>
    );
  }

  render() {
    let classes = 'align-items-center';
    if (this.props.className) {
      classes += ` ${this.props.className}`;
    }

    const style = {
      backgroundColor: '#F3F3EB',
      borderRadius: '10px',
      padding: '1rem',
      width: '100%',
    };

    return (
      <Row className={classes} style={style}>
        <Col xs={2} className="d-none d-md-block">
          <div style={{ minWidth: 75, maxWidth: 125 }}>
            {TankAndRegistration.renderIcon(this.props.tank, this.props.registration)}
          </div>
        </Col>
        <Col xs={10}>
          <div className="d-flex flex-row flex-wrap justify-content-between">
            {TankAndRegistration.renderTitle(this.props.tank, this.props.registration)}
            {this.renderDetailsLink(this.props.tank, this.props.registration)}
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-between">
            <div>
              {TankAndRegistration.renderProductLabel(this.props.tank, this.props.registration)}
              {TankAndRegistration.renderVolume(this.props.tank, this.props.registration)}
              <div style={{ cursor: 'pointer' }}>
                <MaterialText registration={this.props.registration} />
              </div>
              <div>
                <WallType registration={this.props.registration} />
              </div>
            </div>
            <div style={{ minWidth: '50%' }}>
              {TankAndRegistration.renderManifoldPartners(this.props.tank)}
              {TankAndRegistration.renderInstallDate(this.props.registration)}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

TankAndRegistration.propTypes = {
  tank: PropTypes.object.isRequired,
  registration: PropTypes.object,
  className: PropTypes.string,
  navToTankRegistration: PropTypes.func,
};

TankAndRegistration.defaultProps = {
  registration: null,
  className: '',
  navToTankRegistration: null,
};

export default TankAndRegistration;
