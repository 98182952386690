import { DispatchModalState } from './SiteDispatchConstants';

// dispatch list

export function isLoadingDispatches(state) {
  return (state && state.sites && state.sites.isLoadingDispatches) || false;
}

export function dispatches(state) {
  return (state && state.sites && state.sites.dispatches && state.sites.dispatches.dispatches) || [];
}


// create dispatch modal

export function dispatchModalState(state) {
  return (state && state.sites && state.sites.dispatches && state.sites.dispatches.modalState) || DispatchModalState.Closed;
}

export function dispatchModalError(state) {
  return (state && state.sites && state.sites.dispatches && state.sites.dispatches.dispatchModalError) || null;
}

export function dispatchableIssues(state) {
  return (state && state.sites && state.sites.dispatches && state.sites.dispatches.dispatchableIssues) || [];
}

export function nonDispatchableIssues(state) {
  return (state && state.sites && state.sites.dispatches && state.sites.dispatches.nonDispatchableIssues) || [];
}

export function workOrderText(state) {
  return (state && state.sites && state.sites.dispatches && state.sites.dispatches.workOrderText) || '';
}

export function newDispatch(state) {
  return (state && state.sites && state.sites.dispatches && state.sites.dispatches.newDispatch) || null;
}
