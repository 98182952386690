import update from 'immutability-helper';

import SiteDocumentActionTypes from '../../../../../documents/SiteDocumentActionTypes';
import SiteDocumentUploadActionTypes from '../../../../../documents/components/upload/UploadActionTypes';

import ActionTypes from '../../IssueEditActionTypes';

function createDocument(state, document) {
  const currentIssueId = state.currentIssueId;
  if (!currentIssueId) {
    return state;
  }

  const shouldAdd = hasIssue(document, currentIssueId);
  if (shouldAdd) {
    return update(state, {
      documents: {
        $push: [document],
      }
    });
  }
  return state;
}

function editDocument(state, document) {
  const currentIssueId = state.currentIssueId;
  if (!currentIssueId) {
    return state;
  }

  if (hasDocument(state, document) && !hasIssue(document, currentIssueId)) {
    return removeDocuments(state, [document]);
  }

  return state;
}

function removeDocuments(state, deletedDocs) {
  const currentIssueId = state.currentIssueId;
  if (!currentIssueId || !state.documents || !state.documents.length || !deletedDocs.length) {
    return state;
  }

  const deletedDocIds = deletedDocs.map(current => current.id);
  const newIssueDocs = state.documents.filter(current => deletedDocIds.indexOf(current.id) === -1);

  return update(state, {
    documents: {
      $set: newIssueDocs
    }
  });
}

function hasDocument(state, document) {
  return state.documents
    && state.documents.length
    && state.documents.findIndex(current => current.id === document.id) !== -1;
}

function hasIssue(document, currentIssueId) {
  return document.issues
    && document.issues.length
    && document.issues.findIndex(current => current.id === currentIssueId) !== -1;
}

export default function IssueDocumentReducer(state = {}, action) {
  switch (action.type) {
    // issue load
    case ActionTypes.CHANGE_ISSUE_STARTED:
      return Object.assign({}, state, {
        currentIssueId: null,
        documents: [],
      });
    case ActionTypes.CHANGE_ISSUE_SUCCESS:
      return Object.assign({}, state, {
        currentIssueId: action.currentIssue.id,
        documents: action.currentIssue.documents || [],
      });

    // document management actions
    case SiteDocumentUploadActionTypes.CREATE_DOC_SUCCESS:
      return createDocument(state, action.document);
    case SiteDocumentActionTypes.EDIT_DOC_SUCCESS:
      return editDocument(state, action.document);
    case SiteDocumentActionTypes.DELETE_SUCCESS:
      return removeDocuments(state, action.documents);

    default:
      return state;
  }
}
