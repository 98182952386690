import { TankListStep } from './TankListConstants';

const defaultAtgInfo = Object.freeze({
  atgModel: 'unknown',
  softwareVersion: 'unknown',
});

export function isLoading(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.tankList && state.sites.equipment.tankList.isLoading) || false;
}

export function isSaving(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.tankList && state.sites.equipment.tankList.isSaving) || false;
}

export function isError(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.tankList && state.sites.equipment.tankList.isError) || false;
}

export function atgInfo(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.tankList && state.sites.equipment.tankList.atgInfo) || defaultAtgInfo;
}

export function getTanks(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.tankList && state.sites.equipment.tankList.tanks) || [];
}

export function getRegistrations(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.tankList && state.sites.equipment.tankList.registrations) || [];
}

export function getStep(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.tankList && state.sites.equipment.tankList.step) ||
    TankListStep.Step1MatchTanks;
}

export function canConfirmMatches(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.tankList && state.sites.equipment.tankList.canConfirmMatches) || false;
}
