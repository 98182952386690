import React from 'react';
import PropTypes from 'prop-types';
import CanaryModal from '../../../../../../../../../shared/components/CanaryModal';

const DeleteConnectionModal = ({ open, onCancel, onConfirm }) => (
  <CanaryModal
    open={open}
    onCancel={onCancel}
    onConfirm={onConfirm}
    title="Delete Site Connection?"
    confirmButtonText="Delete"
    size="md"
  >
    <span>
      Are you sure you want to delete the connection? This action cannot be undone.
    </span>
  </CanaryModal>
);

DeleteConnectionModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

DeleteConnectionModal.defaultProps = {
  open: false,
};

export default DeleteConnectionModal;
