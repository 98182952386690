import ActionTypes from './ATGActionTypes';

function sortEvents(events) {
  if (!events || !events.length) {
    return [];
  }

  const newSettings = [...events];
  newSettings.sort((first, second) => first.commandText.localeCompare(second.commandText));
  return newSettings;
}

export default function ATGReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_ATG_INFO_STARTED:
      return Object.assign({}, state, { atgInfoLoading: true });
    case ActionTypes.RECEIVE_ATG_INFO:
      return Object.assign({}, state, { atgInfoLoading: false, atgInfo: action.atgInfo });
    case ActionTypes.GET_ATG_INFO_FAILED:
      return Object.assign({}, state, { atgInfoLoading: false, atgInfoError: action.error });

    case ActionTypes.GET_ATG_CONFIG_EVENTS_STARTED:
      return Object.assign({}, state, { atgConfigEventsLoading: true });
    case ActionTypes.RECEIVE_ATG_CONFIG_EVENTS:
      return Object.assign({}, state, { atgConfigEventsLoading: false, atgConfigEvents: sortEvents(action.events) });
    case ActionTypes.GET_ATG_CONFIG_EVENTS_FAILED:
      return Object.assign({}, state, { atgConfigEventsLoading: false, atgConfigEventsError: action.error });
    default:
      return state;
  }
}
