import { timestampAsMonthYear } from '../../../../../../shared/components/date';
import { saveItemAction } from '../../../../../../shared/redux-helpers/ActionCreatorFactory';
import { MviClient } from '../../../../../../client';
import { MviWorkflowStatusTypes, WorkflowStatusTypes } from '../../../../../../AppConstants';
import * as NewIssueActions from '../../../../components/new-issue-button/NewIssueActions';
import MviReportActionTypes from './components/report/MviReportActionTypes';
import ActionTypes from './MviEditActionTypes';
import * as SiteSelectors from '../../../../SiteSelectors';
import MviReportSelectors from './components/report/MviReportSelectors';
import selectors from './MviEditSelectors';

export const getMviById = mviId => (dispatch, getState) => {
  const currentState = getState();
  if (selectors.isLoading(currentState)) {
    return Promise.resolve();
  }

  dispatch({ type: ActionTypes.GET_ITEM_STARTED });
  return MviClient.findById(mviId)
    .then(mvi => {
      const site = SiteSelectors.currentSite(getState());
      if (mvi && mvi.siteId !== site.id) {
        throw new Error(`MVI ${mviId} does not belong to the current site`);
      }

      dispatch({ type: ActionTypes.RECEIVE_ITEM, item: mvi });

      const reports = mvi.reports || [];
      dispatch({ type: MviReportActionTypes.RECEIVE_LIST, list: reports });

      const firstReport = mvi.reports && mvi.reports.length && mvi.reports[0];
      if (firstReport) {
        dispatch({ type: MviReportActionTypes.RECEIVE_ITEM, item: firstReport });
      }
    })
    .catch(error => {
      dispatch({ type: ActionTypes.GET_ITEM_FAILED, error });
      return Promise.reject(error);
    });
};

export const updateMviWorkflowStatus = saveItemAction(
  selectors.isLoading,
  ActionTypes.SAVE_ITEM_STARTED,
  ActionTypes.SAVE_ITEM_SUCCESS,
  ActionTypes.SAVE_ITEM_FAILED,
  (currentState, actionArgs) => {
    const mvi = selectors.item(currentState);
    const patch = {
      id: mvi.id,
      workflowStatus: actionArgs[0],
    };
    return MviClient.updateMvi(patch);
  }
);

export const markAsComplete = () => updateMviWorkflowStatus(MviWorkflowStatusTypes.Completed);

const DefaultRecommendations = 'Analysts will forward maintenance issues on to the maintenance group via email - any compliance issue will be addressed and dispatched as necessary.';

export const openCreateIssueModal = () => (dispatch, getState) => {
  const state = getState();

  const site = SiteSelectors.currentSite(state);
  const mvi = selectors.item(state);
  const reports = MviReportSelectors.list(state);
  const description = `MVI ${timestampAsMonthYear(mvi.monthStartDate).toUpperCase()} Action Items`;
  const analysis = buildAnalysis(reports);
  const recommendations = analysis ? DefaultRecommendations : null;

  const issueParams = { description, analysis, recommendations, workflowStatus: WorkflowStatusTypes.PendingResolution, issueType: 'Monthly Visual Inspection' };
  const currentReport = MviReportSelectors.item(state);
  if (currentReport) {
    issueParams.mviReports = [{ id: currentReport.id }];
    if (currentReport.documents && currentReport.documents.length) {
      issueParams.documents = currentReport.documents.map(current => ({ id: current.id }));
    }
  }

  return dispatch(NewIssueActions.openModal(site, issueParams));
};

function buildAnalysis(reports) {
  const analysisParts = [];
  if (reports && reports.length) {
    reports.forEach(currentReport => {
      if (currentReport.actionItems && currentReport.actionItems.length) {
        currentReport.actionItems.forEach(actionItem => {
          analysisParts.push(actionItem.actionItemText);
        });
      }
    });
  }

  if (analysisParts.length) {
    return 'The below exception(s) were found during the monthly visual inspection:\n\n' + analysisParts.join('\n\n');
  }
  return null;
}
