/* eslint-disable no-case-declarations */
import update from 'immutability-helper/index';
import { buildGenericSort } from '../../SortUtils';
import ActionTypes from './SitePickerActionTypes';

const sortSiteNameAsc = buildGenericSort('siteName');

function addSite(state, action) {
  const newSelectedSites = update(state.selectedSites, {
    $push: [action.site],
  });

  const index = state.siteList.indexOf(action.site);
  return update(state, {
    selectedSites: {
      $set: newSelectedSites,
    },
    siteList: {
      $splice: [[index, 1]],
    },
  });
}

function filterSiteList(siteList, selectedSites) {
  siteList.sort(sortSiteNameAsc);
  if (!selectedSites || !selectedSites.length) {
    return siteList;
  }

  return siteList.filter(site => selectedSites.indexOf(site) === -1);
}

function removeSite(state, action) {
  const stateUpdate = {
    selectedSites: {
      $splice: [[action.index, 1]],
    },
  };

  if (action.site.customerId === state.selectedCustomer.id) {
    stateUpdate.siteList = {
      $push: [action.site],
    };
  }

  return update(state, stateUpdate);
}

export default function AdminReducer(state = {}, action) {
  switch (action.type) {

    case ActionTypes.SET_MODAL_STATE:
      return Object.assign({}, state, {
        modalState: action.modalState,
      });

    case ActionTypes.GET_CUSTOMER_LIST_STARTED:
      return Object.assign({}, state, {
        customerList: [],
        customerListLoading: true,
      });
    case ActionTypes.GET_CUSTOMER_LIST_FAILED:
      return Object.assign({}, state, {
        customerList: [],
        customerListLoading: false,
      });
    case ActionTypes.RECEIVE_CUSTOMER_LIST:
      return Object.assign({}, state, {
        customerList: action.customerList,
        customerListLoading: false,
        selectedSites: state.selectedSites || [],
      });

    case ActionTypes.CLEAR_SELECTED_CUSTOMER:
      return Object.assign({}, state, {
        selectedCustomer: null,
        siteList: null,
      });
    case ActionTypes.UPDATE_SELECTED_CUSTOMER:
      return Object.assign({}, state, {
        selectedCustomer: action.selectedCustomer,
      });

    case ActionTypes.GET_SITES_FOR_CUSTOMER_STARTED:
      return Object.assign({}, state, {
        siteList: [],
        siteListLoading: true,
      });
    case ActionTypes.GET_SITES_FOR_CUSTOMER_FAILED:
      return Object.assign({}, state, {
        siteList: [],
        siteListLoading: false,
      });
    case ActionTypes.RECEIVE_SITE_LIST:
      return Object.assign({}, state, {
        siteList: filterSiteList(action.siteList, state.selectedSites),
        siteListLoading: false,
      });

    case ActionTypes.ADD_SITE:
      return addSite(state, action);
    case ActionTypes.REMOVE_SITE:
      return removeSite(state, action);

    case ActionTypes.RESET:
      return Object.assign({}, state, {
        customerList: null,
        customerListLoading: false,
        selectedCustomer: null,
        siteList: null,
        siteListLoading: false,
        selectedSites: null,
      });

    default:
      return state;
  }
}
