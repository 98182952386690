import ActionTypes from './OrganizerActionTypes';
import IssueEditActionTypes from '../issue-edit/IssueEditActionTypes';
import * as selectors from '../issue-edit/IssueEditSelectors';
import { navToSiteIssues } from '../../../../actions';
import { IssueClient } from '../../../../../../client';

const saveIssueSuccess = currentIssue => ({
  type: IssueEditActionTypes.SAVE_ISSUE_SUCCESS,
  currentIssue
});

export function bulkUpdate(bulkRequest) {
  return (dispatch, getState) => {
    const currentIssue = selectors.currentIssue(getState());
    dispatch({ type: ActionTypes.BULK_UPDATE_STARTED });
    return IssueClient.bulkUpdate(bulkRequest).then(() => {
      if (bulkRequest.deleteIds && bulkRequest.deleteIds.indexOf(currentIssue.id) !== -1) {
        dispatch({ type: ActionTypes.BULK_UPDATE_COMPLETE });
        return dispatch(navToSiteIssues());
      }

      return IssueClient.getIssueById(currentIssue.id)
        .then((updatedIssue) => {
          dispatch({ type: ActionTypes.BULK_UPDATE_COMPLETE });
          return dispatch(saveIssueSuccess(updatedIssue));
        })
        .catch((err) => {
          dispatch({ type: ActionTypes.BULK_UPDATE_COMPLETE });
          return Promise.reject(err);
        });
    });
  };
}
