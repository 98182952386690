import { CommandClient, SiteClient } from '../../../../../../client';
import AdminActionTypes from '../SiteDashboardActionTypes';
import { isSiteHealthLoading, isCommandRequestHealthLoading } from '../SiteDashboardSelectors';


function getSiteHealthStarted() {
  return {
    type: AdminActionTypes.GET_SITE_HEALTH_STARTED,
  };
}

function receiveSiteHealth(siteHealth) {
  return {
    type: AdminActionTypes.RECEIVE_SITE_HEALTH,
    siteHealth,
  };
}

function getSiteHealthFailed() {
  return {
    type: AdminActionTypes.GET_SITE_HEALTH_FAILED,
  };
}

export function getSiteHealth() {
  return (dispatch, getState) => {
    if (isSiteHealthLoading(getState())) {
      return Promise.resolve();
    }

    dispatch(getSiteHealthStarted());
    return SiteClient.getSiteHealth()
      .then((siteHealth) => {
        dispatch(receiveSiteHealth(siteHealth));
      })
      .catch(() => {
        dispatch(getSiteHealthFailed());
      });
  };
}

function getCommandRequestHealthStarted() {
  return {
    type: AdminActionTypes.GET_COMMAND_REQUEST_HEALTH_STARTED,
  };
}

function receiveCommandRequestHealth(commandRequestHealth) {
  return {
    type: AdminActionTypes.RECEIVE_COMMAND_REQUEST_HEALTH,
    commandRequestHealth,
  };
}

function getCommandRequestHealthFailed() {
  return {
    type: AdminActionTypes.GET_COMMAND_REQUEST_HEALTH_FAILED,
  };
}

export function getCommandRequestHealth() {
  return (dispatch, getState) => {
    if (isCommandRequestHealthLoading(getState())) {
      return Promise.resolve();
    }

    dispatch(getCommandRequestHealthStarted());
    return CommandClient.getCommandRequestHealth()
      .then((commandRequestHealth) => {
        dispatch(receiveCommandRequestHealth(commandRequestHealth));
      })
      .catch(() => {
        dispatch(getCommandRequestHealthFailed());
      });
  };
}
