import React from 'react';
import { Input, Form, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper/index';
import CanaryModal from '../../../../../../shared/components/CanaryModal';

const FuelHaulerSiteModal = ({ open, fuelHaulerSite, onFuelHaulerSiteEdit, onSave, onCancel }) => {
  const nickname = (fuelHaulerSite && fuelHaulerSite.nickname) || '';
  const fuelHaulerExternalId = (fuelHaulerSite && fuelHaulerSite.fuelHaulerExternalId) || '';
  const onChange = e => {
    const updatedFhs = update(fuelHaulerSite, {
      fuelHaulerExternalId: {
        $set: e.target.value,
      }
    });
    onFuelHaulerSiteEdit(updatedFhs);
  };
  return (
    <CanaryModal
      open={open}
      onCancel={onCancel}
      onConfirm={onSave}
      confirmButtonText="Save"
      title="Set Fuel Hauler ID"
      size="sm"
    >
      <Form>
        <FormGroup>
          <Label for="site">Site</Label>
          <Input type="text" name="site" id="site" value={nickname} disabled />
        </FormGroup>
        <FormGroup>
          <Label for="fuelHaulerExternalId">Fuel Hauler ID</Label>
          <Input type="text" name="fuelHaulerExternalId" id="fuelHaulerExternalId" placeholder="Fuel hauler id..." value={fuelHaulerExternalId} onChange={onChange} />
        </FormGroup>
      </Form>
    </CanaryModal>
  );
};

FuelHaulerSiteModal.propTypes = {
  open: PropTypes.bool,
  fuelHaulerSite: PropTypes.shape({
    id: PropTypes.number,
    fuelHaulerExternalId: PropTypes.string,
    siteName: PropTypes.string,
    nickname: PropTypes.string,
  }),
  onFuelHaulerSiteEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

FuelHaulerSiteModal.defaultProps = {
  open: false,
  fuelHaulerSite: null,
};

export default FuelHaulerSiteModal;
