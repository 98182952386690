import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import OrganizationPermissions from '../../../../organization-permissions';
import { track } from '../../../../shared/analytics';
import * as authSelectors from '../../../../authentication/AuthenticationSelectors';
import * as actions from './NewIssueActions';
import NewIssueModal from './NewIssueModal';

class NewIssueButton extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    track('Issue', {
      Component: 'New issue',
      'Issue action': 'Open new issue modal',
    });
    this.props.openModal(this.props.site);
  }

  render() {
    if (!this.props.hasPermissions) {
      return null;
    }

    return (
      <>
        <Button color="primary" outline size="sm" onClick={this.openModal} className={this.props.className}>New Issue</Button>
        <NewIssueModal onIssueCreated={this.props.onIssueCreated} />
      </>
    );
  }
}

NewIssueButton.propTypes = {
  // public props
  site: PropTypes.object,
  onIssueCreated: PropTypes.func,
  className: PropTypes.string,

  // internal props
  hasPermissions: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
};

NewIssueButton.defaultProps = {
  site: null,
  onIssueCreated: null,
  className: null,
};

function mapStateToProps(state) {
  return {
    hasPermissions: authSelectors.hasPermissionAnySite(state, OrganizationPermissions.IssueEdit),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: site => dispatch(actions.openModal(site)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewIssueButton);
