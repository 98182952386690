import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedTankProductLabels = ({ selectedTankProductLabels, disabled, deselectTankProductLabel }) => {
  const selectedObjs = selectedTankProductLabels.map(current => ({ id: current, name: current }));
  const handleRemove = labelObj => deselectTankProductLabel(labelObj.id);
  return (<FilterItemList items={selectedObjs} textField="name" disabled={disabled} onRemoveItem={handleRemove} />);
};

SelectedTankProductLabels.propTypes = {
  selectedTankProductLabels: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool.isRequired,
  deselectTankProductLabel: PropTypes.func.isRequired,
};

SelectedTankProductLabels.defaultProps = {
  selectedTankProductLabels: [],
};

export default SelectedTankProductLabels;
