import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { USStateAbbreviations } from '../../../../../../../AppConstants';
import FilterMultiselect from '../FilterMultiselect';

class StateFilterMultiselect extends Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
  }

  handleSave(selectedStates) {
    const abbreviations = selectedStates.map(current => current.id);
    this.props.selectSiteStates(abbreviations);
  }

  render() {
    const allStates = this.props.allStates.map(current => ({ id: current, text: USStateAbbreviations[current] }));
    const selectedStates = this.props.selectedStates.map(current => ({ id: current, text: USStateAbbreviations[current] }));

    return (
      <FilterMultiselect
        textField="text"
        onItemsSelected={this.handleSave}
        selectedItems={selectedStates}
        allItems={allStates}
        className={this.props.className}
        disabled={this.props.disabled}
      />
    );
  }
}

StateFilterMultiselect.propTypes = {
  selectedStates: PropTypes.arrayOf(PropTypes.string),
  allStates: PropTypes.arrayOf(PropTypes.string),
  selectSiteStates: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

StateFilterMultiselect.defaultProps = {
  selectedStates: [],
  allStates: [],
  className: null,
};

export default StateFilterMultiselect;
