export default {
  CHANGE_ISSUE_STARTED: 'issues/change_issue_started',
  CHANGE_ISSUE_SUCCESS: 'issues/change_issue_success',
  CHANGE_ISSUE_FAILED: 'issues/change_issue_failed',

  SAVE_ISSUE_STARTED: 'issues/save_issue_started',
  SAVE_ISSUE_SUCCESS: 'issues/save_issue_success',
  SAVE_ISSUE_FAILED: 'issues/save_issue_failed',

  NAV_TO_ISSUE: 'issues/nav_to_issue',
};
