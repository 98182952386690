import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import objectPath from 'object-path';
import { Badge } from 'reactstrap';
import NumberPicker from 'react-widgets/lib/NumberPicker';
import withEither from './withEither';

const numberInputFn = props => props.edit && props.field.editable;

const NumberEditor = props =>
  <div>
    <NumberPicker
      onChange={value => props.onUpdateField(props.target, props.field, value)} step={props.field.step}
      id={props.id}
      format="####"
      placeholder={`Enter ${props.field.label}`}
      defaultValue={objectPath.get(props.target, props.field.path)}
    />
    { props.field.required && !objectPath.get(props.target, props.field.path) ?
      <div className="d-flex justify-content-begin mt-1 required"><strong>Required</strong></div>
    : null
    }

    {props.field.error ?
      <div className="d-flex justify-content-begin mt-1"><Badge color="danger">Error</Badge></div>
      : null
      }
  </div>;


const NumberViewer = props =>
  <div className="pt-2 pb-2">
    {objectPath.get(props.target, props.field.path) ?
      <strong id={props.id}>{objectPath.get(props.target, props.field.path)} </strong>
    :
    '\u2014' }
  </div>;

const withConditionalRenderings = compose(
      withEither(numberInputFn, NumberEditor),
    );

const TextWithConditionalRendering = withConditionalRenderings(NumberViewer);

const Numberpicker = props => (
  <div className="mb-3">
    <label htmlFor={`field${props.panel.id.toString()}${props.field.id.toString()}`}>{props.field.label}:</label>
    <TextWithConditionalRendering
      onUpdateField={props.onUpdateField}
      id={`field${props.panel.id.toString()}${props.field.id.toString()}`}
      target={props.target}
      field={props.field}
      edit={props.edit}
    />
  </div>
  );

Numberpicker.propTypes = {
  field: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  target: PropTypes.object.isRequired,
};

NumberEditor.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onUpdateField: PropTypes.func.isRequired,
};

NumberViewer.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default Numberpicker;

