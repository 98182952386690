
import React from 'react';
import PropTypes from 'prop-types';
import FormPanel from './FormPanel';
import TankRegistrationIcon from '../../../components/TankRegistrationIcon';


const TankForm = props => (
  <div className="tank-registration-form">
    {props.tank && props.tank.tankNumber && props.tankRegistration && props.tankRegistration.tankRegistrationNumber ?
      <div className="d-flex flex-row align-items-center mt-2 mb-2">
        <div className="mr-2" style={{ minWidth: 75, maxWidth: 125 }}>
          <TankRegistrationIcon registration={props.tankRegistration} text={`T${props.tank.tankNumber}`} />
        </div>
        <h5> {`ATG Tank ${props.tank.tankNumber} – Registration Number ${props.tankRegistration.tankRegistrationNumber}`}</h5>
      </div>
    : null}

    { props.tankRegistration && props.tankRegistration.installation && props.tankRegistration.installation.installDate && props.formBuilder && props.formBuilder.panels && props.formBuilder.panels.length ? props.formBuilder.panels.map(panel => (
      <FormPanel
        panel={panel}
        key={`${panel.name}-${panel.id}`}
        edit={panel.edit}
        target={props.tankRegistration}
        {...props}
      />
        ))
      :
    <div className="col-sm-12">
      <div className="alert alert-danger mb-3" role="alert">
        <div>Feature not yet available for this tank.</div>
      </div>
    </div>
      }
  </div>
  );

TankForm.propTypes = {
  formBuilder: PropTypes.object.isRequired,
  tankRegistration: PropTypes.object.isRequired,
  tank: PropTypes.object.isRequired,
};


export default TankForm;
