import React from 'react';
import PropTypes from 'prop-types';

import { getDeliveryType } from '../ExportDestinationConstants';

const DeliveryType = ({ deliveryUrl }) => (<span>{getDeliveryType(deliveryUrl)}</span>);

DeliveryType.propTypes = {
  deliveryUrl: PropTypes.string,
};

DeliveryType.defaultProps = {
  deliveryUrl: null,
};

export default DeliveryType;
