import reduceReducers from 'reduce-reducers';
import { buildReducer, DefaultState } from '../../shared/redux-helpers/ReducerFactory';
import { buildTableReducer } from '../../shared/components/table/redux/TableReducerFactory';
import { buildViewManagerReducer } from '../../shared/components/table/view-manager/ViewManagerReducerFactory';
import { DefaultTableColumns, MviColumns } from './MviConstants';
import ActionTypes from './MviActionTypes';

const MviListDefaultState = Object.assign({}, DefaultState, {
  defaultSortColumnId: MviColumns.WorkflowStatusDate.id,
  defaultSortDirection: 'desc',
  pageSize: 500,
});

export default reduceReducers(
  MviListDefaultState,
  buildReducer(ActionTypes),
  buildTableReducer(ActionTypes, MviColumns),
  buildViewManagerReducer(ActionTypes, MviColumns, DefaultTableColumns)
);
