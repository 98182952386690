export default {
  REFRESH_LIST_STARTED: 'inventory/refresh_list_started',
  REFRESH_LIST_CANCELED: 'inventory/refresh_list_canceled',
  REFRESH_LIST_FAILED: 'inventory/refresh_list_failed',

  GET_LIST_STARTED: 'inventory/get_list_started',
  RECEIVE_LIST: 'inventory/receive_inventory',
  GET_LIST_FAILED: 'inventory/get_list_failed',

  REFRESH_TIMESTAMPS: 'inventory/refresh_timestamps',

  REFRESH_SITE_INVENTORY_STARTED: 'inventory/refresh_site_inventory_started',
  RECEIVE_SITE_INVENTORY_BATCH: 'inventory/receive_site_inventory_batch',
  REFRESH_SITE_INVENTORY_FAILED: 'inventory/refresh_site_inventory_failed',

  CHANGE_SORT: 'inventory/list/change_sort',
  CHANGE_PAGE: 'inventory/list/change_page',

  GET_VIEWS_STARTED: 'inventory/get_views_started',
  RECEIVE_VIEWS: 'inventory/receive_views',
  GET_VIEWS_FAILED: 'inventory/get_views_failed',
  SELECT_VIEW: 'inventory/select_view',
  TOGGLE_DEFAULT_VIEW: 'inventory/toggle_default_view',
  SAVE_VIEW_STARTED: 'inventory/save_view_started',
  SAVE_VIEW_SUCCESS: 'inventory/save_view_success',
  SAVE_VIEW_FAILED: 'inventory/save_view_failed',
  DELETE_VIEW_STARTED: 'inventory/delete_view_started',
  DELETE_VIEW_SUCCESS: 'inventory/delete_view_success',
  DELETE_VIEW_FAILED: 'inventory/delete_view_failed',
  START_FILTER_EDIT: 'inventory/start_filter_edit',
  CANCEL_FILTER_EDIT: 'inventory/cancel_filter_edit',
  CLEAR_FILTERS: 'inventory/clear_filters',
  SELECT_CUSTOMERS: 'inventory/select_customers',
  DESELECT_CUSTOMER: 'inventory/deselect_customer',
  SELECT_SITE_STATES: 'inventory/select_site_states',
  DESELECT_SITE_STATE: 'inventory/deselect_site_state',
  GET_SITE_LABELS_STARTED: 'inventory/get_site_labels_started',
  RECEIVE_SITE_LABELS: 'inventory/receive_site_labels',
  GET_SITE_LABELS_FAILED: 'inventory/get_site_labels_failed',
  SELECT_SITE_LABELS: 'inventory/select_site_labels',
  DESELECT_SITE_LABEL: 'inventory/deselect_site_label',
  SELECT_SITES: 'inventory/select_sites',
  DESELECT_SITE: 'inventory/deselect_sites',
  SELECT_TANK_PRODUCT_LABELS: 'inventory/select_tank_product_labels',
  DESELECT_TANK_PRODUCT_LABEL: 'inventory/deselect_tank_product_label',
  SELECT_TANK_STATUSES: 'inventory/select_tank_statuses',
  DESELECT_TANK_STATUS: 'inventory/deselect_tank_status',
  SET_COLUMNS: 'inventory/set_columns',
};
