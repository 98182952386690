import React from 'react';
import PropTypes from 'prop-types';
import SingleSiteInput from '../../../../../site-picker/containers/SingleSiteInput';

const SiteSearch = ({ sitePermission, siteFeature, selectedSites, selectSites, disabled }) => {
  const handleSiteSelect = site => selectSites([site]);
  return (
    <SingleSiteInput
      id="filterBarSiteSearch"
      onSiteSelect={handleSiteSelect}
      sitePermission={sitePermission}
      feature={siteFeature}
      excludedSites={selectedSites}
      placeHolderText="Site search..."
      hideNoSiteSelectedText
      disabled={disabled}
    />
  );
};

SiteSearch.propTypes = {
  sitePermission: PropTypes.number,
  siteFeature: PropTypes.string,
  selectedSites: PropTypes.arrayOf(PropTypes.object),
  selectSites: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

SiteSearch.defaultProps = {
  sitePermission: null,
  siteFeature: null,
  selectedSites: [],
};

export default SiteSearch;
