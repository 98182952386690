
import React, { Component } from 'react';


const CanaryLogo = (props) => (
<svg x="0px" y="0px" height={props.height} width={props.width}
viewBox="0 0 73.2 75" style={{enableBackground:'new 0 0 73.2 75'}}>
<path className="logo0" d="M63.9,63.9c-14.5,14.6-38,14.6-52.5,0c-14.6-14.5-14.6-38,0-52.5c14.5-14.6,38-14.6,52.5,0l-4.2,4.2
c-12.2-12.2-32-12.2-44.2,0s-12.2,32,0,44.2s32,12.2,44.2,0L63.9,63.9z"/>
<polygon className="logo0" points="65.4,21.6 47.1,25.8 54.1,36.6 "/>
<circle className="logo1" cx="36.5" cy="38.4" r="22.6"/>
</svg>
  );

  export default CanaryLogo;
