import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const Subheader = ({ className, text, secondaryText, linkText, onLinkClick }) => {
  let classes = 'd-flex w-100 bg-gray-700 px-3 py-1 align-items-center mb-3';
  if (className) {
    classes = `${classes} ${className}`;
  }

  return (
    <div className={classes}>
      <h6 className="d-inline-block mb-0 text-uppercase text-white">{text}</h6>
      {secondaryText ? <em className="d-inline-block ml-1">{secondaryText}</em> : null}
      {linkText ? <Button color="link" className="ml-auto" onClick={onLinkClick}>{linkText}</Button> : null}
    </div>
  );
};

Subheader.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  linkText: PropTypes.string,
  onLinkClick: PropTypes.func,
};

Subheader.defaultProps = {
  className: null,
  secondaryText: null,
  linkText: null,
  onLinkClick: null,
};

export default Subheader;
