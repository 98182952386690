import React, { Component } from 'react';
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import Breadcrumbs from '../../../../../components/breadcrumbs/Breadcrumbs';
import { SystemOrgId } from '../../../../../../AppConstants';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import { CreateBreadcrumb } from '../../../../../components/breadcrumbs';
import Toggle from '../../../../../../shared/components/buttons/Toggle';
import DeleteButton from '../../../../../../shared/components/buttons/DeleteButton';
import { BooleanColumn, Column, CanaryTable, BadgeColumn } from '../../../../../../shared/components/table';
import CanarySummaryBar from '../../../../../../shared/components/CanarySummaryBar';
import * as navActions from '../../../actions';
import * as actions from '../actions';
import * as selectors from '../OrganizationListSelectors';


const OrgTypes = {
  ActiveOrgs: 'Show Active Orgs',
  AllOrgs: 'Show All Orgs',
};

class OrganizationTable extends Component {
  static renderNumeric(field) {
    return (<Badge color="primary" pill>{field}</Badge>);
  }

  static renderCustomer(organization) {
    return !organization.isCustomer
      ? (<span />)
      : (
        <span style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon={faCheck} />
        </span>
      );
  }

  constructor(initialProps) {
    super(initialProps);
    this.state = {
      orgType: OrgTypes.ActiveOrgs,
      confirmModal: false,
    };
    this.setOrgType = this.setOrgType.bind(this);
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.confirmDisableOrg = this.confirmDisableOrg.bind(this);
    this.renderOrgEditLink = this.renderOrgEditLink.bind(this);
    this.renderOrgActiveToggle = this.renderOrgActiveToggle.bind(this);
    this.renderDeleteButton = this.renderDeleteButton.bind(this);
    this.props.getOrganizationList();
  }

  setOrgType(event) {
    this.setState({
      orgType: event.target.value,
    });
  }

  handleToggleOrgEnabled(organization) {
    if (organization.enabled) {
      this.openConfirmModal(organization);
    }
    else {
      this.props.toggleOrganizationEnabled(organization);
    }
  }

  openConfirmModal(organization) {
    this.setState({
      confirmModal: true,
      organization,
    });
  }

  cancelModal() {
    this.setState({
      confirmModal: false,
      organization: null,
    });
  }

  confirmDisableOrg() {
    this.cancelModal();
    this.props.toggleOrganizationEnabled(this.state.organization);
  }

  renderOrgEditLink(organization) {
    if (organization.id === SystemOrgId) {
      return <span>{organization.name}</span>;
    }
    return <Button color="link" onClick={() => this.props.navToOrganization(organization.id)}>{organization.name}</Button>;
  }

  renderOrgActiveToggle(organization) {
    if (organization.id === SystemOrgId) {
      return null;
    }
    return <Toggle value={!organization.enabled} onClick={() => this.handleToggleOrgEnabled(organization)} />;
  }

  renderDeleteButton(organization) {
    if (organization.totalSiteCount === 0 && organization.totalUserCount === 0 && organization.id !== SystemOrgId) {
      return <DeleteButton onClick={() => this.props.deleteOrganization(organization)} disabled={this.props.isUpdating} />;
    }
    return null;
  }

  render() {
    if (this.props.isLoading) {
      return (
        <PulseLoader color="#F8E71C" size={8} />
      );
    }

    const data = this.props.organizationList.filter(organization => this.state.orgType === OrgTypes.AllOrgs || organization.enabled);

    const controls = (
      <div className="d-flex">
        <select value={this.state.orgType} className="form-control form-control-sm" onChange={this.setOrgType}>
          <option value={OrgTypes.ActiveOrgs}>{OrgTypes.ActiveOrgs}</option>
          <option value={OrgTypes.AllOrgs}>{OrgTypes.AllOrgs}</option>
        </select>
        <Button className="ml-2 flex-shrink-0" color="primary" size="sm" onClick={this.props.navToNewOrganization}>ADD A NEW ORGANIZATION</Button>
      </div>
    );
    const summary = `${data.length} Organizations`;
    return (
      <div>
        <CreateBreadcrumb name="Org List" />
        <Breadcrumbs />
        <ErrorMessage title="Organization update failed" error={this.props.error} />
        <CanarySummaryBar summary={summary} controls={controls} />
        <CanaryTable data={data}>
          <Column title="Name" width="22%" render={this.renderOrgEditLink} />
          <BooleanColumn title="Customer?" width="11%" property="isCustomer" textAlign="center" trueIcon={faCheck} />
          <BadgeColumn title="Users" width="11%" property="activeUserCount" textAlign="center" color="primary" pill />
          <BadgeColumn title="Sites" width="11%" property="activeSiteCount" textAlign="center" color="primary" pill />
          <Column title="States" width="25%" render={org => org.activeSiteStates.join(', ')} />
          <Column textAlign="center" width="10%" render={this.renderOrgActiveToggle} />
          <Column textAlign="center" width="10%" render={this.renderDeleteButton} />
        </CanaryTable>
        <Modal isOpen={this.state.confirmModal} toggle={this.ca} size="sm">
          <ModalHeader toggle={this.cancelModal}>
            Disable
            {' '}
            {this.state.organization && this.state.organization.name}
          </ModalHeader>
          <ModalBody>
            Are you sure?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" size="sm" outline onClick={this.cancelModal}>Cancel</Button>
            {' '}
            <Button color="primary" size="sm" onClick={this.confirmDisableOrg}>Confirm</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

OrganizationTable.propTypes = {
  error: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  organizationList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isCustomer: PropTypes.bool.isRequired,
    activeSiteCount: PropTypes.number.isRequired,
    totalSiteCount: PropTypes.number.isRequired,
    activeUserCount: PropTypes.number.isRequired,
    totalUserCount: PropTypes.number.isRequired,
    activeSiteStates: PropTypes.array.isRequired,
    enabled: PropTypes.bool.isRequired,
  })).isRequired,
  getOrganizationList: PropTypes.func.isRequired,
  toggleOrganizationEnabled: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  navToOrganization: PropTypes.func.isRequired,
  navToNewOrganization: PropTypes.func.isRequired,
};

OrganizationTable.defaultProps = {
  error: null,
};

function mapStateToProps(state) {
  return {
    error: selectors.orgListError(state),
    isLoading: selectors.isLoading(state),
    isUpdating: selectors.isUpdating(state),
    organizationList: selectors.organizationList(state),
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrganizationList: () => dispatch(actions.getOrganizationList()),
    toggleOrganizationEnabled: organization => dispatch(actions.toggleOrganizationEnabled(organization)),
    deleteOrganization: organization => dispatch(actions.deleteOrganization(organization)),
    navToOrganization: organization => dispatch(navActions.navToOrganization(organization)),
    navToNewOrganization: () => dispatch(navActions.navToNewOrganization()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationTable));
