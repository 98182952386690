export default {
  GET_REPORT_STARTED: 'sites/compliance/get_report_started',
  RECEIVE_REPORT: 'sites/compliance/receive_report',
  GET_REPORT_FAILED: 'sites/compliance/get_report_failed',

  GET_REPORT_EVENTS_STARTED: 'sites/compliance/get_report_events_started',
  RECEIVE_REPORT_EVENTS: 'sites/compliance/receive_report_events',
  GET_REPORT_EVENTS_FAILED: 'sites/compliance/get_report_events_failed',

  TOGGLE_TANKS_SECTION: 'sites/compliance/toggle_tanks',
  TOGGLE_LINES_SECTION: 'sites/compliance/toggle_lines',
  TOGGLE_SENSORS_SECTION: 'sites/compliance/toggle_sensors',
  HIDE_ALL_SECTIONS: 'sites/compliance/hide_all',
  EXPAND_ALL_SECTIONS: 'sites/compliance/expand_all',

  OPEN_COMMENT: 'sites/compliance/open_comment',
  EDIT_COMMENT: 'sites/compliance/edit_comment',
  CANCEL_COMMENT: 'sites/compliance/cancel_comment',

  SAVE_COMMENT_STARTED: 'sites/compliance/save_comment_started',
  RECEIVE_COMMENTS: 'sites/compliance/receive_comments',
  SAVE_COMMENT_FAILED: 'sites/compliance/save_comment_failed',

  DOWNLOAD_STARTED: 'sites/compliance/download_started',
  DOWNLOAD_SUCCESS: 'sites/compliance/download_success',
  DOWNLOAD_FAILED: 'sites/compliance/download_failed',

  OVERRIDE_STATUS: 'sites/compliance/override_status',
  SAVE_OVERRIDE_STATUS_STARTED: 'sites/compliance/save_override_status_started',
  SAVE_OVERRIDE_STATUS_SUCCESS: 'sites/compliance/save_override_status_success',
  SAVE_OVERRIDE_STATUS_FAILED: 'sites/compliance/save_override_status_failed',
};
