import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'reactstrap';

import { track } from '../../../../../../../../shared/analytics';
import { dateAsText } from '../../../../../../../../shared/components/date';
import MviEditSelectors from '../../MviEditSelectors';
import * as MviEditUberSelectors from '../../MviEditUberSelectors';
import MviReportSelectors from './MviReportSelectors';
import * as actions from './MviReportActions';

const MviReportChooser = ({ className, mvi, selectedReport, reportsList, selectReport, disabled }) => {
  if (!mvi || !selectedReport || !reportsList || !reportsList.length || reportsList.length <= 1) {
    return null;
  }

  const handleClick = report => () => {
    if (!disabled) {
      selectReport(report);
    }
  };

  const title = `Multiple reports for ${mvi.monthText}:`;
  return (
    <div className={className}>
      <span className="mr-2">{title}</span>
      <ButtonGroup size="sm">
        {reportsList.map(report => (
          <Button key={report.id} outline onClick={handleClick(report)} active={report.id === selectedReport.id} disabled={disabled}>
            {dateAsText(report.inspectionDate)}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

MviReportChooser.propTypes = {
  className: PropTypes.string,
  mvi: PropTypes.object,
  selectedReport: PropTypes.object,
  reportsList: PropTypes.array,
  selectReport: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

MviReportChooser.defaultProps = {
  className: null,
  mvi: null,
  selectedReport: null,
  reportsList: null,
};

function mapStateToProps(state) {
  return {
    mvi: MviEditSelectors.item(state),
    selectedReport: MviReportSelectors.item(state),
    reportsList: MviReportSelectors.list(state),
    disabled: MviEditUberSelectors.isEditDisabled(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectReport: report => {
      track('Inspection', {
        Component: 'Report chooser',
        'Inspection action': 'Select report',
      });
      return dispatch(actions.selectReport(report));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MviReportChooser);
