function reportState(state) {
  return (state && state.sites && state.sites.compliance && state.sites.compliance.report) || {};
}

export function isReportLoading(state) {
  return reportState(state).currentReportLoading || false;
}

export function currentReport(state) {
  return reportState(state).currentReport || null;
}

export function currentReportError(state) {
  return reportState(state).currentReportError || null;
}

function complianceSectionsOpen(state) {
  return reportState(state).complianceSectionsOpen || {};
}

export function isTanksOpen(state) {
  return complianceSectionsOpen(state).tanks || false;
}

export function isLinesOpen(state) {
  return complianceSectionsOpen(state).lines || false;
}

export function isSensorsOpen(state) {
  return complianceSectionsOpen(state).sensors || false;
}

export function isEditingComment(state) {
  return reportState(state).editingComment || false;
}

export function isSavingComment(state) {
  return reportState(state).savingComment || false;
}

export function commentText(state) {
  return reportState(state).commentText || '';
}

export function isReportEventsLoading(state) {
  return reportState(state).currentReportEventsLoading || false;
}

export function currentReportEvents(state) {
  return reportState(state).currentReportEvents || [];
}

export function currentReportEventsError(state) {
  return reportState(state).currentReportEventsError || null;
}

export function isDownloading(state) {
  return reportState(state).downloading || false;
}

export function calculatedStatusOverridden(state) {
  return !!(reportState(state).overrideStatus) || (currentReport(state) && currentReport(state).calculatedStatusOverridden) || false;
}

export function status(state) {
  return reportState(state).overrideStatus || (currentReport(state) && currentReport(state).status) || null;
}

export function isSavingOverrideStatus(state) {
  return reportState(state).savingOverrideStatus || false;
}

export function isOverridingStatus(state) {
  return reportState(state).overridingStatus || false;
}

export function overrideStatus(state) {
  return reportState(state).overrideStatus || null;
}

