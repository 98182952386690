export default {
  GET_DISPATCHES_STARTED: 'site/get_dispatches_started',
  GET_DISPATCHES_FAILED: 'site/get_dispatches_failed',
  RECEIVE_DISPATCHES: 'site/receive_dispatches',

  OPEN_MODAL: 'sites/dispatch/open_modal',
  RECEIVE_ISSUES: 'sites/dispatch/receive_issues',
  NEXT_STEP: 'sites/dispatch/next_step',

  CLOSE_MODAL: 'sites/dispatch/close_modal',

  TOGGLE_ISSUE_SELECT: 'sites/dispatch/toggle_issue_select',
  UPDATE_NOTES_TO_TECH: 'sites/dispatch/update_notes_to_tech',

  CREATE_DISPATCH_STARTED: 'sites/dispatch/create_dispatch_started',
  CREATE_DISPATCH_SUCCESS: 'sites/dispatch/receive_create_dispatch_success',

  DISPATCH_MODAL_ERROR: 'sites/dispatch/dispatch_modal_error',
};
