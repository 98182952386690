import { connect } from 'react-redux';

import { track } from '../../../../../../../../shared/analytics';
import DocList from '../../../../../../../../shared/components/docs/DocList';
import * as IssueSelectors from '../../IssueEditSelectors';
import * as SiteDocActions from '../../../../../documents/actions';
import * as selectors from './IssueDocSelectors';

function mapStateToProps(state) {
  return {
    item: IssueSelectors.currentIssue(state),
    itemText: 'issue',
    documents: selectors.documents(state),
    canEditItem: IssueSelectors.canEditCurrentIssue(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onNavToDocument: doc => {
      track('Document', {
        Component: 'Issue documents',
        'Document action': 'Nav to doc',
      });
      return dispatch(SiteDocActions.navToDocument(doc))
    },
    onDocDelete: doc => {
      track('Document', {
        Component: 'Issue documents',
        'Document action': 'Delete doc',
      });
      return dispatch(SiteDocActions.deleteDocument(doc));
    },
    onDocUnlink: (doc, issue) => {
      track('Document', {
        Component: 'Issue documents',
        'Document action': 'Unlink doc',
      });
      return dispatch(SiteDocActions.removeIssue(doc, issue));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocList);
