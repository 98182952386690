import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const Tag = ({ text, className, disabled, onClose }) => (
  <Button onClick={onClose} className={className} size="sm" color="secondary" disabled={disabled}>
    {text}
    <FontAwesomeIcon icon={faTimes} className="ml-2" />
  </Button>
);

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
};

Tag.defaultProps = {
  className: null,
  onClose: null,
  disabled: false,
};

export default Tag;
