import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Label, Input, Form, FormGroup, FormFeedback } from 'reactstrap';

const ResetModes = {
  SendEmail: 'SendEmail',
  EnterPassword: 'EnterPassword',
};

const initialState = Object.freeze({
  mode: ResetModes.SendEmail,
  newPassword: '',
  successText: null,
});

class PasswordReset extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.getActionText = this.getActionText.bind(this);
    this.isActionDisabled = this.isActionDisabled.bind(this);
    this.handleModeClick = this.handleModeClick.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.validPasswordLength = this.validPasswordLength.bind(this);

    this.state = initialState;
  }

  getActionText() {
    if (this.state.mode === ResetModes.SendEmail) {
      return 'Send email';
    }
    return 'Save';
  }

  isActionDisabled() {
    return this.state.mode === ResetModes.EnterPassword && !this.validPasswordLength();
  }

  validPasswordLength() {
    return (this.state.newPassword && this.state.newPassword.length >= 8) || false;
  }

  handleModeClick(newMode) {
    this.setState({
      mode: newMode,
    });
  }

  handlePasswordChange(event) {
    this.setState({
      newPassword: event.target.value,
    });
  }

  handleAction() {
    if (this.state.mode === ResetModes.SendEmail) {
      this.props.onEmailReset(this.props.user).then(() => {
        this.setState({
          successText: `A password reset email has been sent ${this.props.user.email}.`
        });
      });
    }
    else {
      this.props.onSetNewPassword(this.props.user, this.state.newPassword).then(() => {
        this.setState({
          successText: 'Password updated.'
        });
      });
    }
  }

  render() {
    const pwDisplayStyle = {};
    if (this.state.mode !== ResetModes.EnterPassword) {
      pwDisplayStyle.display = 'none';
    }

    if (this.state.successText) {
      return (<Alert color="success">{this.state.successText}</Alert>);
    }

    return (
      <div>
        <Form>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="modeRadio"
                checked={this.state.mode === ResetModes.SendEmail}
                onChange={() => this.handleModeClick(ResetModes.SendEmail)}
              />
              {' '}
              Send a reset email
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="modeRadio"
                checked={this.state.mode === ResetModes.EnterPassword}
                onChange={() => this.handleModeClick(ResetModes.EnterPassword)}
              />
              {' '}
              Enter a new password
            </Label>
          </FormGroup>
          <FormGroup row style={pwDisplayStyle}>
            <Label for="newPassword" sm={2}>New Password</Label>
            <Col sm={6}>
              <Input
                type="text"
                id="newPassword"
                name="newPassword"
                value={this.state.newPassword}
                placeholder="New password"
                onChange={this.handlePasswordChange}
                invalid={this.state.newPassword && !this.validPasswordLength()}
              />
              <FormFeedback>Password must be at least eight characters long</FormFeedback>
            </Col>
          </FormGroup>
        </Form>
        <Button color="primary" size="sm" onClick={this.handleAction} disabled={this.isActionDisabled()}>{this.getActionText()}</Button>
      </div>
    );
  }
}

PasswordReset.propTypes = {
  user: PropTypes.object.isRequired,
  onEmailReset: PropTypes.func.isRequired,
  onSetNewPassword: PropTypes.func.isRequired,
};

export default PasswordReset;
