import React from 'react';
import PropTypes from 'prop-types';
import { IssueTypes } from '../../../../../../../AppConstants';
import FilterMultiselect from '../FilterMultiselect';

const AllIssueTypes = Object.keys(IssueTypes).map(key => ({
  id: key,
  name: IssueTypes[key],
}));

const IssueTypeSelect = ({ selectedIssueTypes, selectIssueTypes, className, disabled }) => {
  const onItemSelected = issueTypeObjs => selectIssueTypes(issueTypeObjs.map(current => current.id));
  const selectedItems = selectedIssueTypes.map(current => ({ id: current, name: IssueTypes[current] }));
  return (
    <FilterMultiselect
      textField="name"
      onItemsSelected={onItemSelected}
      selectedItems={selectedItems}
      allItems={AllIssueTypes}
      className={className}
      disabled={disabled}
    />
  );
};

IssueTypeSelect.propTypes = {
  selectedIssueTypes: PropTypes.arrayOf(PropTypes.string),
  selectIssueTypes: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

IssueTypeSelect.defaultProps = {
  selectedIssueTypes: [],
  className: null,
};

export default IssueTypeSelect;
