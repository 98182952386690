const Installation = { id: 1,
  name: 'installation',
  label: 'Installation and Registration',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: false,
  index: 0,
  fields: [
    { id: 1,
      name: 'installDate',
      label: 'Installation Date',
      type: 'date',
      path: 'installation.installDate',
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
    { id: 2,
      name: 'tankRegistrationNumber',
      label: 'Tank Registration Number',
      path: 'tankRegistrationNumber',
      type: 'text',
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
    { id: 3,
      name: 'warrantyPeriodYears',
      label: 'Warranty Period (years)',
      path: 'installation.warrantyPeriodYears',
      type: 'number',
      step: 1,
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 4,
      name: 'locationType',
      label: 'Location Type',
      path: 'locationType',
      type: 'select',
      values: ['UST', 'AST'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 5,
      name: 'tankAnchored',
      label: 'Anchored',
      path: 'installation.tankAnchored',
      type: 'bool',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 6,
      name: 'excavationLiner',
      label: 'Excavation Liner',
      path: 'installation.excavationLiner',
      type: 'bool',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 7,
      name: 'vaulted',
      label: 'Vaulted',
      path: 'installation.vaulted',
      type: 'bool',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: false,
    },
  ],
};

export default Installation;
