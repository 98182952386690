import PreferenceConstants from './PreferenceConstants';
import {currentEmail} from '../../authentication/AuthenticationSelectors';


export function customerSitesStatus(state) {
  return (state && state.preferences && state.preferences.customerSitesStatus) || PreferenceConstants.customerSiteStatuses.NotLoaded;
}

export function customerSites(state) {
  return (state && state.preferences && state.preferences.customerSites) || [];
}

export function isLoadingUserNotificationRule(state) {
  return (state && state.preferences && state.preferences.isLoadingUserNotificationRule) || false;
}

export function selectedUserNotificationRule(state) {
  return (state && state.preferences && state.preferences.selectedUserNotificationRule) || undefined;
}

export function notificationTopicContext(state) {
  return (state && state.preferences && state.preferences.notificationTopicContext) || undefined;
}

export function isSavingUserNotificationRule(state) {
  return (state && state.preferences && state.preferences.isSavingUserNotificationRule) || false;
}

function atLeastOneTriggerAndTopic(userNotificationRule) {
  const notifyAbout = userNotificationRule.notifyAbout;
  const notifyWhen = userNotificationRule.notifyWhen;

  return Object.keys(notifyAbout).some(topic => PreferenceConstants.notAllocated !== notifyAbout[topic].siteListType)
    && Object.keys(notifyWhen).some(triggerName => notifyWhen[triggerName]);
}

function noTriggersOrTopics(userNotificationRule) {
  const notifyAbout = userNotificationRule.notifyAbout;
  const notifyWhen = userNotificationRule.notifyWhen;

  return Object.keys(notifyAbout).every(topic => PreferenceConstants.notAllocated === notifyAbout[topic].siteListType)
    && Object.keys(notifyWhen).every(triggerName => !notifyWhen[triggerName]);
}

export function canSaveUserNotificationRule(state) {
  return (!isLoadingUserNotificationRule(state) && !isSavingUserNotificationRule(state) && selectedUserNotificationRule(state)
    && selectedUserNotificationRule(state).transient && !invalidUserNotificationRule(state)) || false;
}

/*
  A notification rule must contain at least one notification trigger and one notification topic
  or all triggers and topics must be off
 */
export function invalidUserNotificationRule(state) {
  return (selectedUserNotificationRule(state)
    && selectedUserNotificationRule(state).transient
    && !(atLeastOneTriggerAndTopic(selectedUserNotificationRule(state)) || noTriggersOrTopics(selectedUserNotificationRule(state)))) || false;
}

export function isLoadingUserNotificationLowProductRule(state) {
  return (state && state.preferences && state.preferences.isLoadingUserNotificationLowProductRule) || false;
}

export function selectedUserNotificationLowProductRule(state) {
  return (state && state.preferences && state.preferences.selectedUserNotificationLowProductRule) || undefined;
}

export function userNotificationLowProductSiteContext(state) {
  return (state && state.preferences && state.preferences.userNotificationLowProductSiteContext) || undefined;
}

export function isSavingUserNotificationLowProductRule(state) {
  return (state && state.preferences && state.preferences.isSavingUserNotificationLowProductRule) || false;
}

export function isSelectedUserNotificationLowProductRuleTransient(state) {
  return !!(selectedUserNotificationLowProductRule(state) && selectedUserNotificationLowProductRule(state).transient);
}

const isValidEmailPattern = (emailAddress) => PreferenceConstants.validEmailAddressPattern.test(emailAddress);

export function canSaveEmail(state) {
  return (state && state.preferences && state.preferences.updatedEmail && isValidEmailPattern(state.preferences.updatedEmail)
    && currentEmail(state) !== state.preferences.updatedEmail) || false;
}

export function updatedEmail(state) {
  return (state && state.preferences && state.preferences.updatedEmail) || '';
}

export function isSavingEmail(state) {
  return (state && state.preferences && state.preferences.isSavingEmail) || false;
}

export function updateEmailSuccess(state) {
  return (state && state.preferences && state.preferences.updateEmailSuccess) || false;
}

export function passwordContext(state) {
  return (state && state.preferences && state.preferences.passwordContext) || undefined;
}

export function hasAllRequiredValues(state) {
  return !!(
    state && state.preferences
    && state.preferences.passwordContext
    && state.preferences.passwordContext.currentPassword
    && state.preferences.passwordContext.newPassword
    && state.preferences.passwordContext.confirmationPassword);
}

export function meetsMinimumLengthRequirement(state) {
  return (state && state.preferences
    && state.preferences.passwordContext
    && state.preferences.passwordContext.newPassword
    && state.preferences.passwordContext.newPassword.length
    >= PreferenceConstants.minimumPasswordLength) || false;
}

export function confirmationPasswordMatches(state) {
  if (!(state && state.preferences && state.preferences.passwordContext)) {
    return false;
  }

  const newPassword = state.preferences.passwordContext.newPassword;
  const confirmationPassword = state.preferences.passwordContext.confirmationPassword;
  return newPassword === confirmationPassword;
}

export function canChangePassword(state) {
  return (hasAllRequiredValues(state)
    && meetsMinimumLengthRequirement(state)
    && confirmationPasswordMatches(state));
}

export function isChangingPassword(state) {
  return (state && state.preferences && state.preferences.isChangingPassword) || false;
}

export function changePasswordSuccess(state) {
  return (state && state.preferences && state.preferences.changePasswordSuccess) || false;
}

export function error(state) {
  return (state && state.preferences && state.preferences.error) || undefined;
}

export function userId(state) {
  return (state && state.preferences && state.preferences.userId) || undefined;
}
