import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import TabView from '../../../../../../shared/components/TabView';
import SettingsForm from '../components/SettingsForm';
import ColumnChooser from '../components/ColumnChooser';
import * as actions from '../actions/index';
import * as selectors from '../FileFormatSelectors';

class FileFormatEditor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      confirmModal: false,
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.renderSettingsForm = this.renderSettingsForm.bind(this);
    this.renderColumnChooser = this.renderColumnChooser.bind(this);
    this.renderModal = this.renderModal.bind(this);

    if (props.match && props.match.params && props.match.params.formatId) {
      props.selectInventoryFormat(props.match.params.formatId);
    }
    props.getInventoryColumnCategories();
  }

  handleSaveClick() {
    if (this.props.selectedInventoryFormat.destinations && this.props.selectedInventoryFormat.destinations.length) {
      this.toggleConfirm();
    }
    else {
      this.props.saveSelectedFormat();
    }
  }

  handleConfirmClick() {
    this.toggleConfirm();
    this.props.saveSelectedFormat();
  }

  toggleConfirm() {
    this.setState({
      confirmModal: !this.state.confirmModal,
    });
  }

  renderSettingsForm() {
    return (
      <SettingsForm
        selectedInventoryFormat={this.props.selectedInventoryFormat}
        updateSelectedFormat={this.props.updateSelectedFormat}
      />
    );
  }

  renderColumnChooser() {
    return (
      <ColumnChooser
        inventoryColumnCategories={this.props.inventoryColumnCategories}
        selectedInventoryFormat={this.props.selectedInventoryFormat}
        updateSelectedFormat={this.props.updateSelectedFormat}
      />
    );
  }

  renderModal() {
    if (!this.props.selectedInventoryFormat || !this.props.selectedInventoryFormat.destinations) {
      return null;
    }

    const fuelHaulerDestinationCounts = {};
    this.props.selectedInventoryFormat.destinations.forEach((current) => {
      const fuelHaulerName = current.fuelHauler.name;
      fuelHaulerDestinationCounts[fuelHaulerName] = fuelHaulerDestinationCounts[fuelHaulerName] || 0;
      fuelHaulerDestinationCounts[fuelHaulerName] += 1;
    });

    const fuelHaulers = Object.keys(fuelHaulerDestinationCounts)
      .map((current) => {
        const destCount = fuelHaulerDestinationCounts[current];
        if (destCount > 1) {
          return `${current} (${destCount} destinations)`;
        }
        return current;
      })
      .sort()
      .slice(0, 10);
    let otherCount = 0;
    if (Object.keys(fuelHaulerDestinationCounts).length > 10) {
      otherCount = Object.keys(fuelHaulerDestinationCounts).length - 10;
    }

    return (
      <Modal isOpen={this.state.confirmModal} toggle={this.toggleConfirm}>
        <ModalHeader toggle={this.toggleConfirm}>Add Sites</ModalHeader>
        <div style={{ padding: '20px' }}>
          <span>This file format is currently used by the following fuel haulers:</span>
          <ul className="m-1">
            {fuelHaulers.map(current => (
              <li key={current}>{current}</li>
            ))}
            {otherCount ?
              <li>...and {otherCount} others</li>
              :
              null
            }
          </ul>
          <span>Saving changes to this format will alter the contents of the files that are sent
           to these fuel haulers.</span>
        </div>
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={this.toggleConfirm}>Cancel</Button>
          {' '}
          <Button id="confirm" color="primary" size="sm" onClick={this.handleConfirmClick}>Confirm</Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    if (this.props.isLoading || this.props.isSaving) {
      return (
        <PulseLoader color={'#F8E71C'} size={8} />
      );
    }

    const tabs = [
      {
        name: 'Settings',
        render: this.renderSettingsForm,
      }, {
        name: 'Columns',
        render: this.renderColumnChooser,
      }];

    let name = '';
    if (this.props.selectedInventoryFormat && Number.isInteger(this.props.selectedInventoryFormat.id)) {
      name = this.props.selectedInventoryFormat.name;
    }
    else if (this.props.selectedInventoryFormat) {
      name = 'New File Format';
    }

    return (
      <div>
        <h4>{name}</h4>
        <TabView tabs={tabs} />
        <Row className="mt-2">
          <Col md={4}>
            <Button color="primary" size="sm" outline onClick={this.props.cancelSelectedFormatEdit}>Cancel</Button>
            {' '}
            <Button
              id="save"
              color="primary"
              size="sm"
              onClick={this.handleSaveClick}
              disabled={!this.props.canSaveSelectedInventoryFormat}
            >
              Save
            </Button>
            {this.renderModal()}
          </Col>
        </Row>
      </div>
    );
  }
}

FileFormatEditor.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,

  inventoryColumnCategories: PropTypes.array.isRequired,
  selectedInventoryFormat: PropTypes.object,
  canSaveSelectedInventoryFormat: PropTypes.bool.isRequired,

  getInventoryColumnCategories: PropTypes.func.isRequired,
  selectInventoryFormat: PropTypes.func.isRequired,
  updateSelectedFormat: PropTypes.func.isRequired,
  cancelSelectedFormatEdit: PropTypes.func.isRequired,
  saveSelectedFormat: PropTypes.func.isRequired,

  match: PropTypes.object.isRequired,
};

FileFormatEditor.defaultProps = {
  selectedInventoryFormat: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    isSaving: selectors.isSaving(state),

    inventoryColumnCategories: selectors.inventoryColumnCategories(state),
    selectedInventoryFormat: selectors.selectedInventoryFormat(state),
    canSaveSelectedInventoryFormat: selectors.canSaveSelectedInventoryFormat(state),

    // HACK: Force the page to update on navigate
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInventoryColumnCategories: () => dispatch(actions.getInventoryColumnCategories()),
    selectInventoryFormat: inventoryFormat => dispatch(actions.selectInventoryFormat(inventoryFormat)),
    updateSelectedFormat: updatedInventoryFormat => dispatch(actions.updateSelectedFormat(updatedInventoryFormat)),
    cancelSelectedFormatEdit: () => dispatch(actions.cancelSelectedFormatEdit()),
    saveSelectedFormat: () => dispatch(actions.saveSelectedFormat()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FileFormatEditor));

