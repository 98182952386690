import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Collapse } from 'reactstrap';

import EquipmentCard from '../../components/EquipmentCard';
import ComplianceCommentInput from './components/ComplianceCommentInput';
import ComplianceComments from './components/ComplianceComments';
import TanksTable from './components/TanksTable';
import LinesTable from './components/LinesTable';
import SensorsTable from './components/SensorsTable';
import * as actions from './ComplianceReportActions';
import * as selectors from './ComplianceReportSelectors';
import {track, withTracking} from '../../../../../../shared/analytics';

class ComplianceEquipment extends Component {
  componentDidMount() {
    let element;
    if (this.props.isTanksOpen) {
      element = document.getElementById('tanks');
    }
    else if (this.props.isSensorsOpen) {
      element = document.getElementById('sensors');
    }
    else if (this.props.isLinesOpen) {
      element = document.getElementById('lines');
    }

    if (element && element.scrollIntoView) {
      element.scrollIntoView();
    }
  }

  render() {
    if (!this.props.currentReport) {
      return null;
    }

    return (
      <div className="mt-2">
        <ComplianceCommentInput className="mb-4" />
        <ComplianceComments comments={this.props.currentReport.comments} />
        {this.renderShowHide()}
        <div className="accordion mt-1">
          <EquipmentCard id="tanks" title="Tanks" status={this.props.currentReport.tankStatus} onClick={this.props.toggleTanks}>
            {this.renderTankDetails()}
          </EquipmentCard>
          <EquipmentCard id="sensors" title="Sensors" status={this.props.currentReport.sensorStatus} onClick={this.props.toggleSensors}>
            {this.renderSensorDetails()}
          </EquipmentCard>
          <EquipmentCard id="lines" title="Lines" status={this.props.currentReport.lineStatus} onClick={this.props.toggleLines}>
            {this.renderLineDetails()}
          </EquipmentCard>
        </div>
      </div>
    );
  }

  renderShowHide() {
    if (this.props.isTanksOpen && this.props.isLinesOpen && this.props.isSensorsOpen) {
      return <Button color="link" size="sm" onClick={this.props.hideAllSections}>Hide All Details</Button>;
    }
    return <Button color="link" size="sm" onClick={this.props.expandAllSections}>Show All Details</Button>;
  }

  renderTankDetails() {
    return (
      <Collapse isOpen={this.props.isTanksOpen}>
        <TanksTable tanks={this.props.currentReport.tankStatusDetails} />
      </Collapse>
    );
  }

  renderSensorDetails() {
    return (
      <Collapse isOpen={this.props.isSensorsOpen}>
        <SensorsTable sensors={this.props.currentReport.sensorStatusDetails} sensorsRollupStatus={this.props.currentReport.sensorStatus} />
      </Collapse>
    );
  }

  renderLineDetails() {
    return (
      <Collapse isOpen={this.props.isLinesOpen}>
        <LinesTable lines={this.props.currentReport.lineStatusDetails} linesRollupStatus={this.props.currentReport.lineStatus} />
      </Collapse>
    );
  }
}

ComplianceEquipment.propTypes = {
  currentReport: PropTypes.object,
  isTanksOpen: PropTypes.bool.isRequired,
  isLinesOpen: PropTypes.bool.isRequired,
  isSensorsOpen: PropTypes.bool.isRequired,
  toggleTanks: PropTypes.func.isRequired,
  toggleLines: PropTypes.func.isRequired,
  toggleSensors: PropTypes.func.isRequired,
  hideAllSections: PropTypes.func.isRequired,
  expandAllSections: PropTypes.func.isRequired,
};

ComplianceEquipment.defaultProps = {
  currentReport: null,
};

function mapStateToProps(state) {
  return {
    currentReport: selectors.currentReport(state),
    isTanksOpen: selectors.isTanksOpen(state),
    isLinesOpen: selectors.isLinesOpen(state),
    isSensorsOpen: selectors.isSensorsOpen(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleTanks: () => {
      track('Compliance', {
        Component: 'Equipment table',
        'Compliance action': 'Toggle tanks',
      });
      return dispatch(actions.toggleTanks());
    },
    toggleLines: () => {
      track('Compliance', {
        Component: 'Equipment table',
        'Compliance action': 'Toggle lines',
      });
      return dispatch(actions.toggleLines());
    },
    toggleSensors: () => {
      track('Compliance', {
        Component: 'Equipment table',
        'Compliance action': 'Toggle sensors',
      });
      return dispatch(actions.toggleSensors());
    },
    hideAllSections: () => {
      track('Compliance', {
        Component: 'Equipment table',
        'Compliance action': 'Hide all sections',
      });
      return dispatch(actions.hideAllSections());
    },
    expandAllSections: () => {
      track('Compliance', {
        Component: 'Equipment table',
        'Compliance action': 'Show all sections',
      });
      return dispatch(actions.expandAllSections());
    },
  };
}

export default withTracking('Site', 'Compliance Equipment')(connect(mapStateToProps, mapDispatchToProps)(ComplianceEquipment));
