import { combineReducers } from 'redux';

import InvDashboardReducer from './screens/inv-dashboard/InvDashboardReducer';
import ExportHistoryReducer from './screens/export-history/ExportHistoryReducer';
import FileFormatReducer from './screens/file-formats/FileFormatReducer';
import FuelHaulersReducer from './screens/fuel-haulers/FuelHaulersReducer';

const InventoryReducer = combineReducers({
  dashboard: InvDashboardReducer,
  exportHistory: ExportHistoryReducer,
  fileFormats: FileFormatReducer,
  fuelHaulers: FuelHaulersReducer,
});

export default InventoryReducer;
