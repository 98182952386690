import update from 'immutability-helper';
import { push } from 'react-router-redux';

import { PresentNotification } from '../../../../../../../shared/ToastUtils';
import { WorkflowStatusTypes } from '../../../../../../../AppConstants';
import { IssueClient } from '../../../../../../../client';
import * as SiteSelectors from '../../../../../SiteSelectors';
import * as ReportActions from '../../../../../../../shared/components/atg-reports/AtgReportsActions';
import * as WorkflowActions from '../components/workflow/WorkflowActions';
import * as selectors from '../IssueEditSelectors';
import IssueActionTypes from '../IssueEditActionTypes';

function changeIssueStarted() {
  return {
    type: IssueActionTypes.CHANGE_ISSUE_STARTED,
  };
}

function changeIssueSuccess(issue) {
  return {
    type: IssueActionTypes.CHANGE_ISSUE_SUCCESS,
    currentIssue: issue,
  };
}

function changeIssueFailed(issueId, error) {
  return {
    type: IssueActionTypes.CHANGE_ISSUE_FAILED,
    failedIssueId: issueId,
    error,
  };
}

export function selectIssueById(issueId) {
  return (dispatch, getState) => {
    if (!issueId) {
      return Promise.resolve();
    }

    const currentState = getState();
    if (selectors.isLoading(currentState)) {
      return Promise.resolve();
    }

    const currentIssue = selectors.currentIssue(currentState);
    const intIssueId = Number.parseInt(issueId, 10);
    if (currentIssue && currentIssue.id === intIssueId) {
      return Promise.resolve(currentIssue);
    }

    return dispatch(changeIssue(intIssueId));
  };
}

function changeIssue(issueId) {
  return (dispatch, getState) => {
    if (selectors.failedIssueId(getState()) === issueId) {
      return Promise.resolve();
    }

    dispatch(changeIssueStarted());
    return IssueClient.getIssueById(issueId)
      .then((issue) => {
        const currentSiteId = SiteSelectors.currentSiteId(getState());
        if (issue.siteId !== currentSiteId) {
          return dispatch(changeIssueFailed(issueId));
        }

        dispatch(changeIssueSuccess(issue));
        dispatch(ReportActions.receiveEvents(issue.events));
        return issue;
      })
      .catch((err) => {
        dispatch(changeIssueFailed(issueId, err));
        return Promise.reject(err);
      });
  };
}

function saveIssueStarted() {
  return {
    type: IssueActionTypes.SAVE_ISSUE_STARTED,
  };
}

function saveIssueSuccess(issue) {
  return {
    type: IssueActionTypes.SAVE_ISSUE_SUCCESS,
    currentIssue: issue,
  };
}

function saveIssueFailed(error) {
  return {
    type: IssueActionTypes.SAVE_ISSUE_FAILED,
    error,
  };
}

export function markAsRead() {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentIssue = selectors.currentIssue(currentState);
    let nextWorkflowStatus;

    if (selectors.isSaving(currentState)) {
      return Promise.resolve();
    }

    if (selectors.canEditCurrentIssue(currentState) && currentIssue.workflowStatus === WorkflowStatusTypes.NewUnread) {
      nextWorkflowStatus = WorkflowStatusTypes.New;
    }
    else if (selectors.canEditCurrentIssue(currentState) && currentIssue.workflowStatus === WorkflowStatusTypes.AwaitingApprovalUnread) {
      nextWorkflowStatus = WorkflowStatusTypes.AwaitingApproval;
    }

    if (nextWorkflowStatus && !selectors.isSaving(currentState)) {
      return dispatch(WorkflowActions.setWorkflowStatus(currentIssue, nextWorkflowStatus));
    }
    return Promise.resolve();
  };
}

export function updatePriority(priority) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentIssue = selectors.currentIssue(currentState);
    if (!currentIssue || currentIssue.priority === priority || selectors.isSaving(currentState)) {
      return Promise.resolve();
    }

    dispatch(saveIssueStarted());
    return IssueClient.updatePriority(currentIssue, priority)
      .then((updatedIssue) => {
        let nextIssue = update(currentIssue, { priority: { $set: updatedIssue.priority } });
        nextIssue = updateWithToast(nextIssue, updatedIssue);
        dispatch(saveIssueSuccess(nextIssue));
        return nextIssue;
      })
      .catch(err => {
        dispatch(saveIssueFailed(err));
        return Promise.reject(err);
      });
  };
}

export function updateDescription(newDescription) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentIssue = selectors.currentIssue(currentState);
    if (!currentIssue || currentIssue.description === newDescription || selectors.isSaving(currentState)) {
      return Promise.resolve();
    }

    dispatch(saveIssueStarted());
    return IssueClient.updateDescription(currentIssue, newDescription)
      .then((updatedIssue) => {
        let nextIssue = update(currentIssue, { description: { $set: updatedIssue.description } });
        nextIssue = updateWithToast(nextIssue, updatedIssue);
        dispatch(saveIssueSuccess(nextIssue));
        return nextIssue;
      })
      .catch(err => {
        dispatch(saveIssueFailed(err));
        return Promise.reject(err);
      });
  };
}

export function updateAnalysisRec(analysis, recommendations) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentIssue = selectors.currentIssue(currentState);
    if (!currentIssue || selectors.isSaving(currentState)) {
      return Promise.resolve();
    }

    dispatch(saveIssueStarted());
    return IssueClient.updateAnalysisRecommendations(currentIssue, analysis, recommendations)
      .then((patchedIssue) => {
        let updatedIssue = update(currentIssue, {
          analysis: { $set: patchedIssue.analysis },
          analysisHistory: { $set: patchedIssue.analysisHistory },
        });
        updatedIssue = updateWithToast(updatedIssue, patchedIssue);
        dispatch(saveIssueSuccess(updatedIssue));
        return updatedIssue;
      })
      .catch(err => {
        dispatch(saveIssueFailed(err));
        return Promise.reject(err);
      });
  };
}

function updateWithToast(currentIssue, nextIssue) {
  if (currentIssue.workflow.status !== nextIssue.workflow.status && nextIssue.workflow.status === WorkflowStatusTypes.Draft) {
    PresentNotification('Issue updated to Draft');
  }

  return update(currentIssue, {
    workflow: { $set: nextIssue.workflow },
    workflowStatus: { $set: nextIssue.workflow.status },
    workflowStatusDate: { $set: nextIssue.workflow.statusDate },
    workflowUserId: { $set: nextIssue.workflow.user.id },
    workflowUsername: { $set: nextIssue.workflow.user.username },
  });
}
