/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, FormGroup, Form, Label, Input } from 'reactstrap';

class HighWaterAlarmLimitButton extends Component {
  constructor(defaultProps) {
    super(defaultProps);

    this.state = {
      tankPickerVisible: false,
      selectedTankIndex: 0,
      tankLimit: '',
      validInput: true,
    };

    this.toggleTankPicker = this.toggleTankPicker.bind(this);
    this.handleTankChange = this.handleTankChange.bind(this);
    this.handleInchTextFieldChange = this.handleInchTextFieldChange.bind(this);
    this.sendCommand = this.sendCommand.bind(this);
    this.renderTankPicker = this.renderTankPicker.bind(this);
    this.formatCurrentLimit = this.formatCurrentLimit.bind(this);
  }

  toggleTankPicker() {
    this.setState({
      tankPickerVisible: !this.state.tankPickerVisible,
      selectedTankIndex: 0,
      tankLimit: '',
      validInput: true,
    }, this.formatCurrentLimit);
  }

  handleTankChange(event) {
    this.setState({ selectedTankIndex: event.target.value }, this.formatCurrentLimit);
  }

  handleInchTextFieldChange(value) {
    this.setState({ tankLimit: value }, () => {
      const validLimit = /^(0|^[0-9]{1,2})(\.[0-9]{1})$/
      if (value > 0 && value.length >= 0 && value.match(validLimit)) {
        this.setState({
          validInput: true,
        });
      }
      else {
        this.setState({
          validInput: false,
        });
      }
    });
  }

  formatCurrentLimit() {
    const validCurrent = /^(0|^[0-9]{1,2})(\.[0-9]{1})$/;
    const actualLimit = this.props.tanks[this.state.selectedTankIndex].highWaterAlarmThresholdInches;
    if (actualLimit == null) {
      return <p className="text-danger">Current Limit: No value captured in Canary</p>;
    }
    if (actualLimit === 1.5) {
      return <p>{`Current Limit: ${actualLimit} in`}</p>;
    }
    if (actualLimit.toString().match(validCurrent)) {
      return <p className="text-danger">{`Current Limit: ${actualLimit} in`}</p>;
    }
    if (actualLimit < 1) {
      return <p className="text-danger">{`Current Limit: ${actualLimit} in`}</p>;
    }
    return <p className="text-danger">{`Current Limit: ${actualLimit}.0 in`}</p>;
  }

  sendCommand() {
    this.props.onTankSelected(this.props.tanks[this.state.selectedTankIndex].tankNumber, this.state.tankLimit);
    this.setState({
      selectedTankIndex: 0,
      tankLimit: '',
      validInput: true,
    });
  }

  renderTankPicker() {
    if (this.state.tankPickerVisible) {
      const currentLimitDisplay = this.formatCurrentLimit();
      return (
        <div>
          <br />
          <Row>
            <Col sm={12}>
              <p>
                <select className="form-control" onBlur={this.handleTankChange}>
                  {this.props.tanks.map((tank, index) => (
                    <option key={tank.id} value={index}>T{tank.tankNumber} {tank.productLabel}</option>
                  ))}
                </select>
              </p>
              {currentLimitDisplay}
              <p>
                Recommended: 1.5 in
              </p>
              <hr />
              <p>
                Change High Water Limit to:
              </p>
              <Form inline style={{ alignItems: 'normal' }}>
                <FormGroup className="mr-2">
                  <Input
                    id="inches"
                    type="number"
                    className="form-control form-control-sm"
                    placeholder="1.5"
                    onChange={e => this.handleInchTextFieldChange(e.target.value)}
                    value={this.state.tankLimit}
                    style={{ width: '7em' }}
                    invalid={!this.state.validInput}
                  />
                  <Label htmlFor="inches">&nbsp;&nbsp;in</Label>
                </FormGroup>
                <Button size="sm" style={{ height: 'min-content' }} color="primary" onClick={this.sendCommand} disabled={!this.state.validInput}>Send</Button>
                <p className={this.state.validInput ? 'd-none' : 'mt-2 text-danger'}>Water limits must be entered as one or two digits with a single decimal place.</p>
              </Form>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div
        style={{
          borderWidth: '1px',
          borderColor: '#979797',
          borderStyle: 'solid',
          borderRadius: '0.25em',
          background: '#ffffff',
          padding: '0.5em',
        }}
        className="mb-1"
      >
        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            color: '#4a90e2',
            textAlign: 'left',
            padding: 0,
            margin: 0,
            cursor: 'pointer',
            outline: 'none',
          }}
          onClick={this.toggleTankPicker}
        >
          {this.props.children}
        </button>
        {this.renderTankPicker()}
      </div>
    );
  }
}

HighWaterAlarmLimitButton.propTypes = {
  tanks: PropTypes.array.isRequired,
  children: PropTypes.string.isRequired,
  onTankSelected: PropTypes.func.isRequired,
};

export default HighWaterAlarmLimitButton;
