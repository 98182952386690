import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MonthType } from '../../../ViewManagerConstants';
import FilterItem from '../FilterItem';

const CurrentMonthItem = {
  name: 'Latest month',
};

const SelectedMonth = ({ monthType, selectedMonth, disabled, setMonthFilter }) => {
  if (monthType === MonthType.None || (monthType === MonthType.SpecificMonth && !selectedMonth)) {
    return null;
  }

  const handleClose = () => setMonthFilter(MonthType.None);
  if (monthType === MonthType.LatestMonth) {
    return <FilterItem item={CurrentMonthItem} textField="name" disabled={disabled} onClose={handleClose} className="mr-2 mb-2" />;
  }

  const monthName = moment().month(selectedMonth.month - 1).format('MMM');
  const monthItem = {
    name: `${monthName} ${selectedMonth.year}`,
  };
  return <FilterItem item={monthItem} textField="name" disabled={disabled} onClose={handleClose} className="mr-2 mb-2" />;
};

SelectedMonth.propTypes = {
  monthType: PropTypes.string.isRequired,
  selectedMonth: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  setMonthFilter: PropTypes.func.isRequired,
};

SelectedMonth.defaultProps = {
  selectedMonth: null,
};

export default SelectedMonth;
