import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import find from 'lodash/find';
import { MonthType } from '../../../ViewManagerConstants';

const getMaximumYear = years => Math.max(...Object.keys(years).map(y => years[y].year));
const getMaximumMonthFromYear = year => (year ? Math.max(...year.months.map(m => m.number)) : null);
const sortByMonth = (a, b) => a.number - b.number;
const sortByYear = (a, b) => a.year - b.year;

const groupYears = months => months.reduce(
  (acc, period) => {
    const year = period.year;
    const month = period.month;
    const monthName = period.monthText;

    if (!acc[year]) acc[year] = { year, months: [] };

    acc[year].months.push({
      name: monthName,
      number: month,
    });

    return acc;
  },
  {}
);

class MonthSelect extends Component {
  componentDidMount() {
    if (this.props.monthType === MonthType.None) {
      this.props.setMonthFilter(MonthType.LatestMonth);
    }
  }

  render() {
    const years = groupYears(this.props.months);
    const selectedYearVal = this.props.selectedMonth ? this.props.selectedMonth.year : getMaximumYear(years);
    const selectedMonthVal = this.props.selectedMonth ? this.props.selectedMonth.month : getMaximumMonthFromYear(years[selectedYearVal]);

    const monthTypeChange = monthType => {
      if (monthType === MonthType.LatestMonth) {
        this.props.setMonthFilter(MonthType.LatestMonth);
      }
      else {
        const selectedMonth = find(this.props.months, current => current.month === selectedMonthVal && current.year === selectedYearVal);
        this.props.setMonthFilter(MonthType.SpecificMonth, selectedMonth);
      }
    };

    const updateFilter = ((e) => {
      const newMonth = {
        year: selectedYearVal,
        month: selectedMonthVal,
      };

      if (e) {
        let value = e.target.value;
        if (!Number.isNaN(value)) {
          value = Number.parseInt(value, 10);
        }
        Object.assign(newMonth, { [e.target.id]: value });
        const selectedMonth = find(this.props.months, current => current.month === newMonth.month && current.year === newMonth.year);
        this.props.setMonthFilter(MonthType.SpecificMonth, selectedMonth);
      }
    });

    const renderMonthForm = () => (
      <form className="form-inline">
        <label className="sr-only" htmlFor="month">Select A Month</label>
        <select id="month" value={selectedMonthVal} className="form-control form-control-sm mr-2" onChange={updateFilter} disabled={this.props.disabled}>
          {years[selectedYearVal].months.sort(sortByMonth).map(m => (
            <option key={m.number} value={m.number}>{m.name}</option>
          ))}
        </select>
        <label className="sr-only" htmlFor="year">Select A Year</label>
        <select id="year" value={selectedYearVal} className="form-control form-control-sm mr-2" onChange={updateFilter} disabled={this.props.disabled}>
          {Object.keys(years).sort(sortByYear).map(y => (
            <option key={y} value={years[y].year}>{y}</option>
          ))}
        </select>
        <label className="sr-only" htmlFor="status">Select A Status</label>
      </form>
    );

    return (
      <>
        <Form>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="monthTypeRadio" checked={this.props.monthType === MonthType.LatestMonth} onChange={() => monthTypeChange(MonthType.LatestMonth)} disabled={this.props.disabled} />
              {' '}
              Latest month available
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="monthTypeRadio" checked={this.props.monthType === MonthType.SpecificMonth} onChange={() => monthTypeChange(MonthType.SpecificMonth)} disabled={this.props.disabled} />
              {' '}
              Choose a specific month
            </Label>
          </FormGroup>
        </Form>
        {this.props.monthType === MonthType.SpecificMonth ? renderMonthForm() : null}
      </>
    );
  }
}

MonthSelect.propTypes = {
  monthType: PropTypes.string.isRequired,
  selectedMonth: PropTypes.object,
  months: PropTypes.array,
  setMonthFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

MonthSelect.defaultProps = {
  selectedMonth: null,
  months: [],
};

export default MonthSelect;
