import React from 'react';
import PropTypes from 'prop-types';

const phoneRegex = /(\d{3})-(\d{3})-(\d{4})/;

function formatPhone(phone) {
  if (!phone) {
    return null;
  }
  const match = phone.match(phoneRegex);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phone;
}

function buildAddress(site) {
  if (!site.address && !site.city) {
    return null;
  }

  const parts = [];
  if (site.address) {
    parts.push(site.address);
  }
  if (site.address && site.city) {
    parts.push(', ');
  }
  if (site.city) {
    parts.push(site.city);
    parts.push(', ');
    parts.push(site.state);
    if (site.zip) {
      parts.push(' ');
      parts.push(site.zip);
    }
  }

  return parts.join('');
}

const ContactDetails = ({ value, tag, className }) => {
  if (!value || (!value.name && !value.businessName && !value.address && !value.city && !value.phone)) {
    return null;
  }

  const name = value.name || value.businessName;
  const address = buildAddress(value);
  const phone = formatPhone(value.phone);

  const parts = [];
  if (name) {
    parts.push(name);
  }
  if (name && address) {
    parts.push(', ');
  }
  if (address) {
    parts.push(address);
  }
  const nameAndAddress = parts.join('');
  const Tag = tag;
  return (
    <Tag className={className}>
      {nameAndAddress}
      {(nameAndAddress && phone) ? (<span className="mx-2">&bull;</span>) : null }
      {phone}
    </Tag>
  );
};

ContactDetails.propTypes = {
  value: PropTypes.object.isRequired,
  tag: PropTypes.string,
  className: PropTypes.string,
};

ContactDetails.defaultProps = {
  tag: 'h6',
  className: null,
};

export default ContactDetails;
