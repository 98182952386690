import update from 'immutability-helper';

import { WorkflowStatusTypes } from '../../../../../../AppConstants';
import ActionTypes from './SiteIssueListActionTypes';
import WorkflowActionTypes from '../issue-edit/components/workflow/WorkflowActionTypes';

const DefaultState = {
  resolvedIssues: [],
};

function addResolvedIssue(state, issue) {
  if (issue.workflowStatus !== WorkflowStatusTypes.Resolved) {
    return state;
  }
  else if (!state.resolvedIssues) {
    return Object.assign({}, state, {
      resolvedIssues: [issue],
    });
  }
  return update(state, {
    resolvedIssues: {
      $push: [issue],
    }
  });
}

export default function SiteIssueListReducer(state = DefaultState, action) {
  switch (action.type) {
    case ActionTypes.GET_RESOLVED_ISSUES_STARTED:
      return Object.assign({}, state, {
        resolvedIssuesLoading: true,
      });
    case ActionTypes.RECEIVE_RESOLVED_ISSUES:
      return Object.assign({}, state, {
        resolvedIssues: action.issues,
        resolvedIssuesLoading: false,
      });
    case ActionTypes.GET_RESOLVED_ISSUES_FAILED:
      return Object.assign({}, state, {
        resolvedIssues: [],
        resolvedIssuesLoading: false,
        resolvedIssuesError: action.error,
      });
    case WorkflowActionTypes.UPDATE_WORKFLOW_SUCCESS:
      return addResolvedIssue(state, action.issue);
    default:
      return state;
  }
}
