import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import { Col } from 'reactstrap';

import { ExportClient } from '../../../client';

const browserTimezone = moment.tz.guess();

class RecentExports extends Component {
  componentDidMount() {
    this.props.getRecentExports();
  }

  handleDownloadClick(exportInstance) {
    ExportClient.getDownloadLink(exportInstance.id).then(data => {
      window.open(data.url);
    }).catch(err => {
      console.error('Failed to fetch attachment url', err);
    });
  }

  render() {
    return (
      <div className="row">
        <Col xs={12}>
          <table className="table canary-table-2">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.props.recentExports.map((exportInstance) => (
                <tr key={exportInstance.id}>
                  <td>{exportInstance.name || 'Pending...'}</td>
                  <td>{moment(exportInstance.completed).tz(browserTimezone).format('lll')}</td>
                  <ExportIcon exportInstance={exportInstance} onDownloadClick={() => this.handleDownloadClick(exportInstance)} />
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </div>
    );
  }
}

RecentExports.propTypes = {
  getRecentExports: PropTypes.func.isRequired,
  recentExports: PropTypes.array.isRequired,
};

function ExportIcon(props) {
  let exportInstance = props.exportInstance;
  if (exportInstance.status === 'Complete') {
    return (
      <td className="download" onClick={() => props.onDownloadClick(exportInstance)}>
        <a href="#" data-toggle="tooltip" data-placement="top" title="Download ZIP">
          <span className="text-muted"><FontAwesomeIcon icon={faDownload} /></span>
        </a>
      </td>
    );
  }
  else {
    return (
      <td>
        <PulseLoader color={'#F8E71C'} size={8} />
      </td>
    );
  }
}

export default RecentExports;
