import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CursorDisabledStyle, CursorPointerStyle } from './ButtonConstants';

function FontAwesomeButton({ icon, iconEnabledClassName, size, className, style, onClick, disabled }) {
  const handleOnClick = e => {
    if (!disabled) {
      onClick(e);
    }
  };

  const actualStyle = Object.assign(
    {},
    (disabled ? CursorDisabledStyle : CursorPointerStyle),
    style || {},
  );

  return (
    <FontAwesomeIcon
      size={size}
      icon={icon}
      className={classNames({ [iconEnabledClassName]: !disabled, 'text-muted': disabled }, className)}
      style={actualStyle}
      onClick={handleOnClick}
      disabled={disabled}
    />
  );
}

FontAwesomeButton.propTypes = {
  icon: PropTypes.any.isRequired,
  iconEnabledClassName: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FontAwesomeButton.defaultProps = {
  iconEnabledClassName: 'text-primary',
  size: '1x',
  className: null,
  style: null,
  disabled: false,
};

export default FontAwesomeButton;
