import FileSaver from 'file-saver';
import { PresentError } from '../../../../../../shared/ToastUtils';
import { SiteClient } from '../../../../../../client';

import ActionTypes from '../ProblemListActionTypes';

function getProblematicSitesStarted() {
  return {
    type: ActionTypes.GET_PROBLEMATIC_SITES_STARTED,
  };
}

function receiveProblematicSites(problematicSites) {
  return {
    type: ActionTypes.RECEIVE_PROBLEMATIC_SITES,
    problematicSites,
  };
}

function getProblematicSitesFailed() {
  return {
    type: ActionTypes.GET_PROBLEMATIC_SITES_FAILED,
  };
}

export function getProblematicSites() {
  return (dispatch, getState) => {
    const isLoading = getState().admin.problematicSitesLoading;
    if (isLoading) return Promise.resolve();

    dispatch(getProblematicSitesStarted());
    return SiteClient.getProblematicSites()
      .then((problematicSites) => {
        dispatch(receiveProblematicSites(problematicSites));
      })
      .catch(() => {
        dispatch(getProblematicSitesFailed());
      });
  };
}

export function downloadProblematicSitesCsv() {
  return SiteClient.getProblematicSites(true)
    .then(content => {
      const file = new File([content], 'data.csv', { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(file);
    })
    .catch(() => PresentError('Download failed'));
}
