import reduceReducers from 'reduce-reducers';
import moment from 'moment';
import { buildReducer, DefaultState, HookTypes } from '../../shared/redux-helpers/ReducerFactory';
import { buildTableReducer } from '../../shared/components/table/redux/TableReducerFactory';
import { buildViewManagerReducer } from '../../shared/components/table/view-manager/ViewManagerReducerFactory';
import { DefaultTableColumns, IssueTableColumns } from './IssueListConstants';
import { SortDirection } from '../../AppConstants';
import ActionTypes from './IssueListActionTypes';

const IssueListState = Object.assign({}, DefaultState, {
  defaultSortColumnId: IssueTableColumns.Created.id,
  defaultSortDirection: SortDirection.Desc,
  pageSize: 500,
});

function setDaysIdle(issues) {
  if (!issues || !issues.length) {
    return issues;
  }
  const now = moment();
  return issues.map(issue => {
    if (issue.lastModified && issue.lastModified.date) {
      const daysIdle = moment.duration(now.diff(moment(issue.lastModified.date))).asDays().toFixed(0);
      return Object.assign({}, issue, { daysIdle });
    }
    return issue;
  });
}

export default reduceReducers(
  IssueListState,
  buildReducer(ActionTypes, { [HookTypes.ListReceive]: setDaysIdle }),
  buildTableReducer(ActionTypes, IssueTableColumns),
  buildViewManagerReducer(ActionTypes, IssueTableColumns, DefaultTableColumns)
);
