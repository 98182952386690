export default {
  LOGIN: 'authentication/login',
  LOGIN_SUCCESS: 'authentication/login_success',
  LOGIN_FAILURE: 'authentication/login_failure',
  LOGIN_REFRESH: 'authentication/login_refresh',

  CLEAR_LOGIN_ERROR: 'authentication/clear_login_error',

  UPDATE_EMAIL: 'authentication/update_email',

  SET_LOGIN_REDIRECT_PATH: 'authentication/set_login_redirect_path',
  CLEAR_LOGIN_REDIRECT_PATH: 'authentication/clear_login_redirect_path',

  RESET_PASSWORD: 'authentication/reset_password',
  RESET_PASSWORD_SUCCESS: 'authentication/reset_password_success',
  RESET_PASSWORD_FAILURE: 'authentication/reset_password_failure',

  LOGOUT: 'authentication/logout',
};
