export function isInventoryLoading(state) {
  return (state && state.sites && state.sites.inventory.inventoryLoading) || false;
}

export function inventory(state) {
  return (state && state.sites && state.sites.inventory.inventoryData) || null;
}

export function inventoryError(state) {
  return (state && state.sites && state.sites.inventory.inventoryError) || null;
}
