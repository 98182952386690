import IntentActionTypes from '../CommandActionTypes';
import { IntentClient } from '../../../../../client/index';
import { IntentRequestStatus } from '../CommandConstants';
import { PresentAlert, PresentError } from '../../../../../shared/ToastUtils';

import * as siteSelectors from '../../../SiteSelectors';
import { getSiteTanks, getRecentCommandRequests } from './index';

function intentRequestSuccess(intentRequest) {
  return {
    type: IntentActionTypes.INTENT_REQUEST_RESOLVE_SUCCESS,
    intentRequest,
  };
}

function intentRequestFailed(intentRequest) {
  return {
    type: IntentActionTypes.INTENT_REQUEST_RESOLVE_FAILED,
    intentRequest,
  };
}

export function updateIntentRequestStatus(intentRequestId) {
  return (dispatch, getState) => {
    IntentClient.getIntentRequestById(intentRequestId)
      .then((intentRequest) => {
        if (intentRequest.status === IntentRequestStatus.Resolved) {
          PresentAlert('Your requested action has been completed successfully.');
          dispatch(intentRequestSuccess(intentRequest));
          dispatch(getSiteTanks());
        }
        else if (intentRequest.status === IntentRequestStatus.Failed) {
          PresentError('Your requested action has failed to complete.');
          dispatch(intentRequestFailed(intentRequest));
        }

        const site = siteSelectors.currentSite(getState());
        if (site && site.id === intentRequest.siteId) {
          dispatch(getRecentCommandRequests());
        }
        return intentRequest;
      });
  };
}
