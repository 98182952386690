import { combineReducers } from 'redux';

import ExternalAccessReducer from './screens/organization-edit/screens/external-access/ExternalAccessReducer';
import GroupEditorReducer from './screens/organization-edit/screens/group-editor/GroupEditorReducer';
import OrganizationListReducer from './screens/organization-list/OrganizationListReducer';
import OrganizationEditReducer from './screens/organization-edit/OrganizationEditReducer';
import SiteEditorReducer from './screens/organization-edit/screens/site-editor/SiteEditorReducer';
import UserReducer from './screens/organization-edit/screens/user-editor/UserReducer';
import NotificationLogReducer from './screens/organization-edit/screens/notification-log/NotificationLogReducer';

const OrganizationReducer = combineReducers({
  list: OrganizationListReducer,
  edit: OrganizationEditReducer,
  users: UserReducer,
  groupEdit: GroupEditorReducer,
  sites: SiteEditorReducer,
  externalAccess: ExternalAccessReducer,
  notifications: NotificationLogReducer,
});

export default OrganizationReducer;
