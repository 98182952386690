import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

import CanaryLabel from './Label';

class LabelCheckboxes extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(event, index) {
    const label = this.props.allLabels[index];
    if (event.target.checked) {
      this.props.onLabelSelect(label);
    }
    else {
      this.props.onLabelDeselect(label);
    }
  }

  render() {
    if (!this.props.allLabels || !this.props.allLabels.length) {
      return null;
    }

    const selectedLabelIds = this.props.selectedLabels.map(current => current.id);

    return this.props.allLabels.map((current, index) => (
      <FormGroup check key={current.labelText}>
        <Label check for="label">
          <Input
            type="checkbox"
            name="label"
            checked={selectedLabelIds.indexOf(current.id) !== -1}
            onChange={e => this.handleCheck(e, index)}
            disabled={this.props.disabled}
          />
          <CanaryLabel label={current} />
        </Label>
      </FormGroup>
    ));
  }
}

LabelCheckboxes.propTypes = {
  allLabels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    labelText: PropTypes.string,
    color: PropTypes.string,
  })),
  selectedLabels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    labelText: PropTypes.string,
    color: PropTypes.string,
  })),
  onLabelSelect: PropTypes.func.isRequired,
  onLabelDeselect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

LabelCheckboxes.defaultProps = {
  allLabels: [],
  selectedLabels: [],
  disabled: false,
};

export default LabelCheckboxes;
