import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import CloseButton from '../../../../../../shared/components/close-button/CloseButton';
import ButtonWithConfirmation from '../../../../../../shared/components/button-with-confirmation/ButtonWithConfirmation';

const style = Object.freeze({
  borderRadius: '10px',
  padding: '1rem',
});

class SiteInfo extends Component {
  renderButton() {
    if (this.props.site.connection) {
      const confirmationText = `This site is already connected ${this.connectionDescription()}. ` +
        'Are you sure you want to replace the existing connection?';
      return (
        <ButtonWithConfirmation
          buttonLabel="Activate"
          buttonColor="primary"
          modalBodyText={confirmationText}
          data={this.props.site}
          onConfirm={this.props.onActivate}
        />
      );
    }
    return <Button color="primary" onClick={() => this.props.onActivate(this.props.site)}>Activate</Button>;
  }

  connectionDescription() {
    const connection = this.props.site.connection;
    if (connection.connectionType === 'PARTICLE') {
      const deviceId = connection.uri.replace('particle://', '');
      return `to Particle device ${deviceId}`;
    }
    else if (connection.connectionType === 'TCP') {
      return 'via TCP';
    }
    return `to ${connection.uri}`;
  }

  render() {
    return (
      <div className="border d-flex align-items-center mt-2" style={style}>
        <div style={{padding: '2rem'}}>
          {this.renderButton()}
        </div>
        <div className="d-flex align-items-center justify-content-between" style={{width: '100%'}}>
          <div>
            <h5>{this.props.site.nickname} ({this.props.site.siteName})</h5>
            {this.props.site.distanceMeters !== undefined ?
              <div>About {this.props.site.distanceMeters.toLocaleString()} meters away</div>
              :
              null
            }
            <div>{this.props.site.address || 'Unknown address'}, {this.props.site.city || 'Unknown city'}, {this.props.site.state}, {this.props.site.zip || 'Unknown zip'}</div>
            <div>{this.props.site.phone || 'Unknown phone number'}</div>
          </div>
          <div className="align-self-start">
            <CloseButton onClose={this.props.onDismiss} data={this.props.site} />
          </div>
        </div>
      </div>
    );
  }
}

SiteInfo.propTypes = {
  site: PropTypes.object.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default SiteInfo;
