import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { StatefulTable, StringColumn, RelativeTimestampColumn, TimestampColumn, Column } from '../../../../../../../shared/components/table';
import CanaryLoadingIndicator from '../../../../../../../shared/components/CanaryLoadingIndicator';
import ErrorMessage from '../../../../../../../shared/components/error-message/ErrorMessage';
import PriorityIndicator from '../../../../../../../shared/components/PriorityIndicator';
import * as SiteActions from '../../../../../actions';
import * as selectors from '../SiteIssueListSelectors';
import * as actions from '../actions';

class ResolvedIssuesTable extends Component {
  componentDidMount() {
    this.props.getResolvedIssues();
  }

  render() {
    if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }
    else if (this.props.error) {
      return <ErrorMessage error={this.props.error} title="Failed to load issues" />;
    }
    else if (!this.props.resolvedIssues.length) {
      return (
        <em className="ml-4 text-muted">No closed issues</em>
      );
    }

    return (
      <StatefulTable
        data={this.props.resolvedIssues}
        onRowClick={this.props.navToIssue}
        initialSortColumn="created"
        initialSortDirection="desc"
      >
        <Column title="Priority" render={issue => <PriorityIndicator priority={issue.priority} />} width="5rem" />
        <StringColumn property="id" title="Id" sortable width="5rem" />
        <RelativeTimestampColumn property="created" title="Opened" sortable width="13rem" />
        <StringColumn property="description" title="Issue" />
        <TimestampColumn property="workflow.statusDate" title="Closed" sortable width="13rem" />
      </StatefulTable>
    );
  }
}

ResolvedIssuesTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  resolvedIssues: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    workflow: PropTypes.shape({
      statusDate: PropTypes.string.isRequired,
    }),
    site: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  })).isRequired,
  getResolvedIssues: PropTypes.func.isRequired,
  navToIssue: PropTypes.func.isRequired,
};

ResolvedIssuesTable.defaultProps = {
  error: null,
};

function mapStateToProps(state) {
  return {
    loading: selectors.isResolvedIssuesLoading(state),
    error: selectors.resolvedIssuesError(state),
    resolvedIssues: selectors.resolvedIssues(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getResolvedIssues: () => dispatch(actions.getResolvedIssues()),
    navToIssue: issue => dispatch(SiteActions.navToCurrentSiteIssue(issue)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResolvedIssuesTable);
