export const DeliveryTypes = Object.freeze({
  Email: 'Email',
  SFTP: 'SFTP',
  FTP: 'FTP',
});

export function getDeliveryType(deliveryUrl) {
  if (deliveryUrl && deliveryUrl.startsWith('sftp:')) {
    return DeliveryTypes.SFTP;
  }
  else if (deliveryUrl && deliveryUrl.startsWith('ftp:')) {
    return DeliveryTypes.FTP;
  }
  return DeliveryTypes.Email;
}

export const AuthTypes = {
  Password: 'Password',
  PublicKey: 'PublicKey',
};
