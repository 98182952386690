/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, FormGroup, Form, Label, Input } from 'reactstrap';

class HighProductLimitButton extends Component {
  constructor(defaultProps) {
    super(defaultProps);

    this.state = {
      tankPickerVisible: false,
      selectedTankIndex: 0,
      tankLimit: '',
      tankLimitPer: '',
      validInput: true,
    };

    this.toggleTankPicker = this.toggleTankPicker.bind(this);
    this.handleTankChange = this.handleTankChange.bind(this);
    this.handlePerTextFieldChange = this.handlePerTextFieldChange.bind(this);
    this.handleGalTextFieldChange = this.handleGalTextFieldChange.bind(this);
    this.sendCommand = this.sendCommand.bind(this);
    this.renderTankPicker = this.renderTankPicker.bind(this);
  }

  toggleTankPicker() {
    this.setState({
      tankPickerVisible: !this.state.tankPickerVisible,
      selectedTankIndex: 0,
      tankLimit: '',
      tankLimitPer: '',
      validInput: true,
    });
  }

  handleTankChange(event) {
    this.setState({ selectedTankIndex: event.target.value });
  }

  handlePerTextFieldChange(value) {
    this.setState({ tankLimit: Math.round((value / 100) * this.props.tanks[this.state.selectedTankIndex].fullVolumeGallons), tankLimitPer: value }, () => {
      if (value > 0 && value.length >= 0 && value <= 100) {
        this.setState({
          validInput: true,
        });
      }
      else {
        this.setState({
          validInput: false,
        });
      }
    });
  }

  handleGalTextFieldChange(value) {
    this.setState({ tankLimit: value, tankLimitPer: Math.round((value / this.props.tanks[this.state.selectedTankIndex].fullVolumeGallons) * 100) }, () => {
      if (value > 0 && value.length >= 0 && value <= Math.round(this.props.tanks[this.state.selectedTankIndex].fullVolumeGallons)) {
        this.setState({ validInput: true });
      }
      else {
        this.setState({ validInput: false, });
      }
    });
  }

  sendCommand() {
    this.props.onTankSelected(this.props.tanks[this.state.selectedTankIndex].tankNumber, this.state.tankLimit);
    this.setState({
      selectedTankIndex: 0,
      tankLimit: '',
      tankLimitPer: '',
      validInput: true,
    });
  }

  renderTankPicker() {
    if (this.state.tankPickerVisible) {
      const actualLimit = Math.round(this.props.tanks[this.state.selectedTankIndex].highProductThresholdGallons);
      const actualLimitPer = Math.round((this.props.tanks[this.state.selectedTankIndex].highProductThresholdGallons / this.props.tanks[this.state.selectedTankIndex].fullVolumeGallons) * 100);
      const expectedLimit = Math.round(this.props.tanks[this.state.selectedTankIndex].fullVolumeGallons * 0.95);
      const expectedLimitPlusOne = Math.round((this.props.tanks[this.state.selectedTankIndex].fullVolumeGallons * 0.95) + 1);
      const expectedLimitMinusOne = Math.round((this.props.tanks[this.state.selectedTankIndex].fullVolumeGallons * 0.95) - 1);
      return (
        <div>
          <br />
          <Row>
            <Col sm={12}>
              <p>
                <select className="form-control" onBlur={this.handleTankChange}>
                  {this.props.tanks.map((tank, index) => (
                    <option key={tank.id} value={index}>T{tank.tankNumber}{' '}{tank.productLabel}{' '}(Tank Size: {' '}{tank.fullVolumeGallons}{' '}gal)
                    </option>
                  ))}
                </select>
              </p>
              <p className={(actualLimit === expectedLimit) || (actualLimit === expectedLimitPlusOne) || (actualLimit === expectedLimitMinusOne) ? '' : 'text-danger'}>
                Current Limit: { actualLimit ? `${actualLimitPer}% or ${actualLimit} gal` : 'No value captured in Canary'}
              </p>
              <p>
                Recommended: 95% or {expectedLimit} gal
              </p>
              <hr />
              <p>
                Change High Product Limit to:
              </p>
              <Form inline style={{ alignItems: 'normal' }}>
                <FormGroup className="mr-2">
                  <Input
                    id="percentage"
                    type="number"
                    className="form-control form-control-sm"
                    placeholder="95"
                    onChange={e => this.handlePerTextFieldChange(e.target.value)}
                    value={this.state.tankLimitPer}
                    style={{ width: '7em' }}
                    invalid={!this.state.validInput}
                  />
                  <Label htmlFor="percentage">&nbsp;&nbsp;%</Label>
                </FormGroup>
                <FormGroup className="mr-2">
                  <Input
                    id="gallons"
                    type="number"
                    className="form-control form-control-sm"
                    placeholder={expectedLimit}
                    onChange={e => this.handleGalTextFieldChange(e.target.value)}
                    value={this.state.tankLimit}
                    style={{ width: '7.5em' }}
                    invalid={!this.state.validInput}
                  />
                  <Label htmlFor="gallons">&nbsp;&nbsp;gal</Label>
                </FormGroup>
                <Button size="sm" style={{ height: 'min-content' }} color="primary" onClick={this.sendCommand} disabled={!this.state.validInput}>Send</Button>
                <p className={this.state.validInput ? 'd-none' : 'mt-2 text-danger'}>Tank limits must be greater than  zero and less than the tank&apos;s full volume.</p>
              </Form>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div
        style={{
          borderWidth: '1px',
          borderColor: '#979797',
          borderStyle: 'solid',
          borderRadius: '0.25em',
          background: '#ffffff',
          padding: '0.5em',
        }}
        className="mb-1"
      >
        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            color: '#4a90e2',
            textAlign: 'left',
            padding: 0,
            margin: 0,
            cursor: 'pointer',
            outline: 'none',
          }}
          onClick={this.toggleTankPicker}
        >
          {this.props.children}
        </button>
        {this.renderTankPicker()}
      </div>
    );
  }
}

HighProductLimitButton.propTypes = {
  tanks: PropTypes.array.isRequired,
  children: PropTypes.string.isRequired,
  onTankSelected: PropTypes.func.isRequired,
};

export default HighProductLimitButton;
