import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import HelpIcon from '../../../../../components/help-icon/HelpIcon';
import DelimiterSpan from '../components/DelimiterSpan';
import { BooleanColumn, Column, StatefulTable, StringColumn } from '../../../../../../shared/components/table';
import CanaryLoadingIndicator from '../../../../../../shared/components/CanaryLoadingIndicator';
import * as actions from '../actions';
import * as selectors from '../FileFormatSelectors';
import FileFormatDeleteModal from '../components/FileFormatDeleteModal';

class FileFormatTable extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.state = {
      showHelp: false,
      deleteModalVisible: false,
    };

    this.toggleHelp = this.toggleHelp.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);

    this.props.getInventoryFormatList();
  }

  toggleHelp() {
    this.setState({
      showHelp: !this.state.showHelp,
    });
  }

  handleEditClick(inventoryFormat) {
    if (inventoryFormat) {
      this.props.navToInventoryFormat(inventoryFormat);
    }
  }

  handleDeleteClick(inventoryFormat) {
    if (inventoryFormat) {
      this.props.selectInventoryFormat(inventoryFormat).then(() => {
        this.setState({
          deleteModalVisible: true,
        });
      });
    }
  }

  confirmDelete() {
    this.props.deleteSelectedInventoryFormat().then(() => {
      this.closeDeleteModal();
    });
  }

  closeDeleteModal() {
    this.setState({
      deleteModalVisible: false,
    });
  }

  render() {
    if (this.props.isLoading || this.props.isSaving) {
      return <CanaryLoadingIndicator />;
    }

    return (
      <div>
        <Alert
          color="info"
          className="mb-1"
          isOpen={this.state.showHelp}
          toggle={this.toggleHelp}
        >
          Use file formats to define the data that is sent to fuel haulers.  A single file format can be used by multiple fuel haulers.
          Click on a row in the table to edit an existing file format or click on the <em>ADD</em> button to create a new format.
        </Alert>
        <Row>
          <Col xs={9}>
            <div className="mb-3">
              <h5 style={{ display: 'inline' }}>File Formats</h5>
              <HelpIcon onClick={this.toggleHelp} className="ml-1" />
            </div>
          </Col>
          <Col xs={3}>
            <div className="d-flex justify-content-end mb-1">
              <Button color="primary" size="sm" onClick={this.props.navToNewInventoryFormat}>Add</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <StatefulTable
              initialSortColumn="name"
              initialSortDirection="asc"
              data={this.props.inventoryFormatList}
            >
              <StringColumn property="name" title="Name" />
              <Column columnId="delimiter" render={r => <DelimiterSpan delimiter={r.delimiterCharacter} />} title="Delimiter" width="5rem" />
              <BooleanColumn property="quote" title="Quote" trueIcon={faCheck} width="5rem" />
              <BooleanColumn property="includeHeader" title="Header" trueIcon={faCheck} width="5rem" />
              <Column columnId="edit" render={r => <Button color="link" size="sm" onClick={() => this.handleEditClick(r)}>Edit</Button>} width="5rem" />
              <Column columnId="delete" render={r => <Button color="link" size="sm" onClick={() => this.handleDeleteClick(r)}>Delete</Button>} width="5rem" />
            </StatefulTable>

            <FileFormatDeleteModal
              isOpen={this.state.deleteModalVisible}
              selectedInventoryFormat={this.props.selectedInventoryFormat}
              onDelete={this.confirmDelete}
              onCancel={this.closeDeleteModal}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

FileFormatTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  inventoryFormatList: PropTypes.array.isRequired,
  selectedInventoryFormat: PropTypes.object,

  getInventoryFormatList: PropTypes.func.isRequired,
  selectInventoryFormat: PropTypes.func.isRequired,
  deleteSelectedInventoryFormat: PropTypes.func.isRequired,
  navToInventoryFormat: PropTypes.func.isRequired,
  navToNewInventoryFormat: PropTypes.func.isRequired,
};

FileFormatTable.defaultProps = {
  selectedInventoryFormat: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    isSaving: selectors.isSaving(state),
    inventoryFormatList: selectors.inventoryFormatList(state),
    selectedInventoryFormat: selectors.selectedInventoryFormat(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInventoryFormatList: () => dispatch(actions.getInventoryFormatList()),
    selectInventoryFormat: inventoryFormat => dispatch(actions.selectInventoryFormat(inventoryFormat)),
    deleteSelectedInventoryFormat: inventoryFormat => dispatch(actions.deleteSelectedInventoryFormat(inventoryFormat)),
    navToInventoryFormat: inventoryFormat => dispatch(actions.navToInventoryFormat(inventoryFormat)),
    navToNewInventoryFormat: () => dispatch(actions.navToNewInventoryFormat()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FileFormatTable);
