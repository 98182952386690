import { CustomerClient, SiteClient } from '../../../../client';
import ActionTypes from '../SitePickerActionTypes';
import * as selectors from '../SitePickerSelectors';


export function setModalState(modalState) {
  return {
    type: ActionTypes.SET_MODAL_STATE,
    modalState,
  };
}

function receiveCustomerList(customerList) {
  return {
    type: ActionTypes.RECEIVE_CUSTOMER_LIST,
    customerList,
  };
}

export function getCustomers() {
  return (dispatch, getState) => {
    const isLoading = selectors.isCustomerListLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_CUSTOMER_LIST_STARTED });
    return CustomerClient.getList()
      .then(customerList => dispatch(receiveCustomerList(customerList)))
      .catch((err) => {
        dispatch({ type: ActionTypes.GET_CUSTOMER_LIST_FAILED });
        return Promise.reject(err);
      });
  };
}

function updateSelectedCustomer(selectedCustomer) {
  return {
    type: ActionTypes.UPDATE_SELECTED_CUSTOMER,
    selectedCustomer,
  };
}

function receiveSiteList(siteList) {
  return {
    type: ActionTypes.RECEIVE_SITE_LIST,
    siteList,
  };
}

export function clearCustomer() {
  return {
    type: ActionTypes.CLEAR_SELECTED_CUSTOMER,
  };
}

export function selectCustomer(customer) {
  return (dispatch, getState) => {
    const isLoading = selectors.isSiteListLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch(updateSelectedCustomer(customer));
    dispatch({ type: ActionTypes.GET_SITES_FOR_CUSTOMER_STARTED });
    return SiteClient.getSitesForCustomer2(customer.id)
      .then(siteList => dispatch(receiveSiteList(siteList)))
      .catch((err) => {
        dispatch({ type: ActionTypes.GET_SITES_FOR_CUSTOMER_FAILED });
        return Promise.reject(err);
      });
  };
}

export function addSite(site) {
  return {
    type: ActionTypes.ADD_SITE,
    site,
  };
}

export function removeSite(site, index) {
  return {
    type: ActionTypes.REMOVE_SITE,
    site,
    index,
  };
}

export function reset() {
  return {
    type: ActionTypes.RESET,
  };
}
