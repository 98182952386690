import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SiteConnectionStatus } from '../../AppConstants';

const ConnectionType = ({ connectionType, connectionStatus, className, style }) => {
  const actualClassName = classNames('connectivity-icon', className);

  if (connectionType === 'TCP' && connectionStatus === SiteConnectionStatus.Disconnected) {
    return <img src="/images/icon_TCPIP_out.svg" className={actualClassName} style={style} alt="TCP" />;
  }
  else if (connectionType === 'TCP' && connectionStatus === SiteConnectionStatus.Unstable) {
    return <img src="/images/icon_TCPIP_alert.svg" className={actualClassName} style={style} alt="TCP" />;
  }
  else if (connectionType === 'TCP') {
    return <img src="/images/icon_TCPIP.svg" className={actualClassName} style={style} alt="TCP" />;
  }
  else if (connectionType === 'PARTICLE' && connectionStatus === SiteConnectionStatus.Disconnected) {
    return <img src="/images/icon_cellular_out.svg" className={actualClassName} style={style} alt="Cellular" />;
  }
  else if (connectionType === 'PARTICLE' && connectionStatus === SiteConnectionStatus.Unstable) {
    return <img src="/images/icon_cellular_alert.svg" className={actualClassName} style={style} alt="Cellular" />;
  }
  else if (connectionType === 'PARTICLE') {
    return <img src="/images/icon_cellular.svg" className={actualClassName} style={style} alt="Cellular" />;
  }
  return <span>-</span>;
};

ConnectionType.propTypes = {
  connectionType: PropTypes.string,
  connectionStatus: PropTypes.oneOf([SiteConnectionStatus.OK, SiteConnectionStatus.Unstable, SiteConnectionStatus.Disconnected]),
  className: PropTypes.string,
  style: PropTypes.object,
};

ConnectionType.defaultProps = {
  connectionType: null,
  connectionStatus: SiteConnectionStatus.OK,
  className: null,
  style: null,
};

export default ConnectionType;
