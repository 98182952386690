import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import RelativeTimestamp from '../../../../../shared/components/date/RelativeTimestamp';

const IssueTitle = ({ issue, className }) => (
  <div className={classNames('canary-text-small text-uppercase text-muted', className)}>
    {`Issue ${issue.id} - opened `}
    <RelativeTimestamp time={issue.created} />
  </div>
);

IssueTitle.propTypes = {
  issue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

IssueTitle.defaultProps = {
  className: null,
};

export default IssueTitle;
