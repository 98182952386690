import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedStates = ({ selectedStates, disabled, deselectSiteState }) => {
  const handleSave = siteState => deselectSiteState(siteState.id);
  const selectedStateObjs = selectedStates.map(current => ({ id: current, text: current }));
  return <FilterItemList items={selectedStateObjs} textField="text" disabled={disabled} onRemoveItem={handleSave} />;
};

SelectedStates.propTypes = {
  selectedStates: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool.isRequired,
  deselectSiteState: PropTypes.func.isRequired,
};

SelectedStates.defaultProps = {
  selectedStates: [],
};

export default SelectedStates;
