import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TabView extends Component {
  constructor(props) {
    super(props);

    this.renderTab = this.renderTab.bind(this);
    this.renderTabRow = this.renderTabRow.bind(this);
    this.renderTabContent = this.renderTabContent.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentWillMount() {
    this.setState({
      selectedTab: this.props.tabs[0],
    });
  }

  toggle(tab) {
    this.setState({ selectedTab: tab });
  }

  renderTab(tab) {
    const activeState = tab.name === this.state.selectedTab.name ? ' active' : '';
    let classNames = `nav-link${activeState}`;
    if (this.props.disabled) {
      classNames += ' disabled';
    }
    return (
      <li key={tab.name} className="nav-item">
        <a onClick={() => this.toggle(tab)} className={classNames}>{tab.name}</a>
      </li>
    );
  }

  renderTabRow() {
    return (
      <ul className="nav nav-tabs">
        {this.props.tabs.map(tab => this.renderTab(tab))}
      </ul>
    );
  }

  renderTabContent() {
    return this.state.selectedTab.render();
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.renderTabRow()}
        <div className="tab-content">
          {this.renderTabContent()}
        </div>
      </div>
    );
  }
}

TabView.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
  })).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

TabView.defaultProps = {
  className: null,
  disabled: false,
};

export default TabView;
