import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import { TankMaterialType } from '../EquipmentConstants';
import helper from './MaterialHelper';


class MaterialText extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
    };
  }

  getTextAndTooltipText() {
    const construction = this.props.registration && this.props.registration.construction;
    let text = 'Unknown material';
    let tooltipText = 'Tank material unknown';

    if (construction && construction.primaryMaterial === TankMaterialType.FRP) {
      text = 'Fiberglass Reinforced Plastic';
      tooltipText =
        'A tank constructed entirely of non-corrosive fiberglass resin.  These tanks are constructed according to the UL 1316 standard and are available ' +
        'in both single and double wall configurations. Common manufacturers include Containment Solutions (formerly Owens Corning Fiberglass) and Xerxes ' +
        '(formerly Century Fiberglass). ';
    }
    else if (helper.isUL1746Part1(construction)) {
      text = 'Steel: sti-P3®';
      tooltipText = (
        <div>
          A steel tank with a three part pre-engineered corrosion protection system:
          <ul>
            <li>An external dielectric coating (30 mils thick)</li>
            <li>Factory installed galvanic anodes</li>
            <li>Electrical isolation bushings</li>
          </ul>
          These tanks are manufactured according to UL 58 and UL 1746 Part I standards and are available in both single and double wall configurations.
          sti-P3® is a trademark of the Steel Tank Institute and is licensed to many tank manufacturers.  Common steel tank manufacturers include Modern
          Welding and Highland Tank.
        </div>
      );
    }
    else if (helper.isUL1746Part2(construction)) {
      text = 'Steel/FRP composite';
      tooltipText = (
        <div>
          A steel tank with a 100 mil thick fiberglass coating bonded directly to the outer steel wall.  Available in both single and double wall
          configurations. Due to the thickness of the outer coating, these tanks do not require galvanic anodes.
          <br /><br />
          These tanks are manufactured according to UL 58 and UL 1746 Part II standards. Common composite tank brands include ACT-100® (trademark of the Steel
          Tank Institute, licensed to many tank manufacturers), Glasteel (Modern Welding), and Plasteel (trademark of Plasteel International, licensed to
          several tank manufacturers).
        </div>
      );
    }
    else if (helper.isUL1746Part3(construction)) {
      text = 'Steel: FRP or HDPE jacketed';
      tooltipText = (
        <div>
          A tank with an inner wall made of steel and an outer wall made of a non-corrosive material such as fiberglass reinforced plastic,
          high density polyethylene, or a proprietary polymer. By definition, these are double walled tanks with an interstitial space between
          the inner steel tank and the outer jacket.
          <br /><br />
          These tanks are manufactured according to UL 58 and UL 1746 Part III standards.  Common jacketed tank brands include Permatank (trademark of the Steel
          Tank Institute, licensed to many tank manufacturers), Glasteel II (Modern Welding), TITAN (Highland Tank). and Plasteel Elutron (trademark of Plasteel
          International, licensed to several tank manufacturers), and Total Containment.
        </div>
      );
    }
    else if (helper.isUL1746Part4(construction)) {
      text = 'Steel/urethane composite';
      tooltipText = (
        <div>
          A steel tank with a 70 mil thick urethane coating bonded directly to the outer steel wall.  Available in both single and double wall
          configurations.  Due to the thickness of the outer coating, these tanks do not require galvanic anodes.
          <br /><br />
          These tanks are manufactured according to UL 58 and UL 1746 Part IV standards. Common urethane composite tank brands include ACT-100-U (trademark of
          the Steel Tank Institute, licensed to many tank manufacturers) and HighGuard (Highland Tank).
        </div>
      );
    }
    else if (helper.isSteelWithUnknownCorrosionProtection(construction)) {
      text = 'Steel: unknown construction';
      tooltipText = (
        <div>
          The tank is made of steel but no additional details are available.
        </div>
      );
    }
    else if (helper.isSteelWithNoCorrosionProtection(construction)) {
      text = 'Steel: bare/no external coating';
      tooltipText = (
        <div>
          A steel tank with no external coating or a coating such as tar or lead paint that is not approved for corrosion protection by a nationally
          recognized standards organization. These tanks are almost always single walled.
          <br /><br />
          In order for a tank of this type to be in service today, one (or both) of the following upgrades must have been performed before December 1998:
          <ul>
            <li>
              An internal lining.
            </li>
            <li>
              Impressed current cathodic protection.
            </li>
          </ul>
        </div>
      );
    }

    return {
      text,
      tooltipText,
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  render() {
    if (!this.props.registration) {
      return <span>Unknown material</span>;
    }
    const { text, tooltipText } = this.getTextAndTooltipText();
    if (this.props.tooltip) {
      const id = `material_tooltip_${this.props.registration.id}`;

      return (
        <div style={{ display: 'inline' }}>
          <span id={id} className="tooltip-link" onClick={this.toggle}>{text}</span>
          <Popover placement="bottom" isOpen={this.state.popoverOpen} target={id} toggle={this.toggle} style={{ maxWidth: '400px' }}>
            <PopoverHeader>{text}</PopoverHeader>
            <PopoverBody>{tooltipText}</PopoverBody>
          </Popover>
        </div>
      );
    }
    return (<span>{text}</span>);
  }
}

MaterialText.propTypes = {
  registration: PropTypes.object,
  tooltip: PropTypes.bool,
};

MaterialText.defaultProps = {
  registration: null,
  tooltip: true,
};

export default MaterialText;
