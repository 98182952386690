import React from 'react';
import PropTypes from 'prop-types';
import TextToMarkup from '../../../../../../../../shared/components/TextToMarkup';

const ReadOnlyAnalysisRec = ({ className, analysis, recommendations }) => (
  <div className={className}>
    <strong className="text-uppercase d-block mb-1">Analysis</strong>
    <TextToMarkup text={analysis} missingText="No analysis available." />
    <strong className="text-uppercase d-block mt-3 mb-1">Troubleshooting</strong>
    <TextToMarkup text={recommendations} missingText="No recommendation available." />
  </div>
);

ReadOnlyAnalysisRec.propTypes = {
  className: PropTypes.string,
  analysis: PropTypes.string,
  recommendations: PropTypes.string,
};

ReadOnlyAnalysisRec.defaultProps = {
  className: null,
  analysis: null,
  recommendations: null,
};

export default ReadOnlyAnalysisRec;
