import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { RelativeTimestampColumn, StatefulTable, StringColumn } from '../../../../../../../../shared/components/table';

const LoginHistory = ({ selectedUser }) => {
  const history = selectedUser.loginHistory || [];
  if (!history.length) {
    return (<Alert color="info">No login history for the current user</Alert>);
  }

  return (
    <div className="mt-4">
      <StatefulTable
        data={history}
        initialSortColumn="loginDate"
        initialSortDirection="desc"
      >
        <RelativeTimestampColumn property="loginDate" title="Date" sortable width="13rem" />
        <StringColumn property="ipAddress" title="IP Address" />
      </StatefulTable>
    </div>
  );
};

LoginHistory.propTypes = {
  selectedUser: PropTypes.shape({
    loginHistory: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      loginDate: PropTypes.string.isRequired,
      ipAddress: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

export default LoginHistory;
