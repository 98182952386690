import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

const HOURS = [
  { value: 0, text: '12 AM' },
  { value: 1, text: '1 AM' },
  { value: 2, text: '2 AM' },
  { value: 3, text: '3 AM' },
  { value: 4, text: '4 AM' },
  { value: 5, text: '5 AM' },
  { value: 6, text: '6 AM' },
  { value: 7, text: '7 AM' },
  { value: 8, text: '8 AM' },
  { value: 9, text: '9 AM' },
  { value: 10, text: '10 AM' },
  { value: 11, text: '11 AM' },
  { value: 12, text: '12 PM' },
  { value: 13, text: '1 PM' },
  { value: 14, text: '2 PM' },
  { value: 15, text: '3 PM' },
  { value: 16, text: '4 PM' },
  { value: 17, text: '5 PM' },
  { value: 18, text: '6 PM' },
  { value: 19, text: '7 PM' },
  { value: 20, text: '8 PM' },
  { value: 21, text: '9 PM' },
  { value: 22, text: '10 PM' },
  { value: 23, text: '11 PM' },
];

class HourRange extends Component {

  constructor(initialProps) {
    super(initialProps);
    this.getAllowedEndHours = this.getAllowedEndHours.bind(this);
  }

  getAllowedEndHours() {
    if (this.props.startHour !== '') {
      const startHourNum = Number.parseInt(this.props.startHour, 10);
      return HOURS.filter(current => current.value > startHourNum);
    }
    return HOURS;
  }

  handleUpdate(startHour, endHour) {
    const startHourNum = (startHour && Number.parseInt(startHour, 10)) || null;
    const endHourNum = (endHour && Number.parseInt(endHour, 10)) || null;

    if (startHourNum && endHourNum && startHourNum >= endHourNum) {
      this.props.onRangeUpdate(startHourNum, startHourNum + 1);
    }
    else {
      this.props.onRangeUpdate(startHourNum, endHourNum);
    }
  }

  render() {
    return (
      <div style={{ display: 'inline' }}>
        <Input
          type="select"
          name="startHour"
          className="form-control-sm"
          value={this.props.startHour !== null ? this.props.startHour : ''}
          onChange={event => this.handleUpdate(event.target.value, this.props.endHour)}
          style={{ display: 'inline', width: '125px', marginLeft: '10px', marginRight: '10px' }}
          disabled={this.props.disabled}
        >
          <option value="">{' '}</option>
          {HOURS.filter(current => current.value !== 23).map(current => (
            <option value={current.value} key={current.value}>{current.text}</option>
          ))}
        </Input>
        and
        <Input
          type="select"
          name="endHour"
          className="form-control-sm"
          value={this.props.endHour !== null ? this.props.endHour : ''}
          onChange={event => this.handleUpdate(this.props.startHour, event.target.value)}
          style={{ display: 'inline', width: '125px', marginLeft: '10px', marginRight: '10px' }}
          disabled={this.props.disabled}
        >
          <option value="">{' '}</option>
          {this.getAllowedEndHours().map(current => (
            <option value={current.value} key={current.value}>{current.text}</option>
          ))}
        </Input>
      </div>
    );
  }
}

HourRange.propTypes = {
  startHour: PropTypes.number,
  endHour: PropTypes.number,
  disabled: PropTypes.bool,
  onRangeUpdate: PropTypes.func.isRequired,
};

HourRange.defaultProps = {
  startHour: null,
  endHour: null,
  disabled: false,
};

export default HourRange;
