import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Row } from 'reactstrap';

import { withTracking } from '../../../shared/analytics';
import * as NavContextActions from '../../../shared/components/nav-context/NavContextActions';
import ComplianceStatus from '../../../shared/components/compliance/ComplianceStatus';
import { navToSiteComplianceReport } from '../../sites/actions';
import { ComplianceClient } from '../../../client';
import buildViewManagerActions from '../../../shared/components/table/view-manager/ViewManagerActions';
import { FilterType, ViewCategory } from '../../../shared/components/table/view-manager/ViewManagerConstants';
import buildViewBar from '../../../shared/components/table/view-manager/ViewBar';
import buildViewControls from '../../../shared/components/table/view-manager/ViewControls';
import ErrorMessage from '../../../shared/components/error-message/ErrorMessage';
import CanaryLoadingIndicator from '../../../shared/components/CanaryLoadingIndicator';
import { TablesTour } from '../../UserTours';
import ComplianceListSelectors, { ComplianceListTableSelectors, ComplianceListViewManagerSelectors } from '../ComplianceSelectors';
import { ComplianceTableColumns } from '../ComplianceConstants';
import ReportsTable from '../components/ReportsTable';
import ReportsChart from '../components/ReportsChart';
import * as actions from '../actions';
import ActionTypes from '../ComplianceActionTypes';
import withTour from '../../../shared/components/tour/WithTour';

const viewManagerActions = buildViewManagerActions(ActionTypes, ComplianceListViewManagerSelectors, ViewCategory.Compliance, ComplianceClient.getReportingPeriods);
const ComplianceFilterTypes = [FilterType.Customer, FilterType.Month, FilterType.ComplianceStatus, FilterType.State, FilterType.Site, FilterType.Label];
const ComplianceViewBar = buildViewBar(viewManagerActions, ComplianceListSelectors, ComplianceListViewManagerSelectors, ComplianceFilterTypes);
const ComplianceViewControls = buildViewControls(viewManagerActions, ComplianceListSelectors, ComplianceListViewManagerSelectors, ComplianceTableColumns);

function pluralize(reports) {
  return reports && reports.length === 1 ? 'Report' : 'Reports';
}

class MonthlyComplianceOverview extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.getReports = this.getReports.bind(this);
    this.hasReports = this.hasReports.bind(this);
    this.generateChartData = this.generateChartData.bind(this);
    this.handleReportClick = this.handleReportClick.bind(this);
  }

  componentDidMount() {
    this.props.clearNavContext();
  }

  getReports() {
    this.props.getReports().then(() => this.props.startTour());
  }

  hasReports() {
    return this.props.reports && this.props.reports.length > 0;
  }

  handleReportClick(report) {
    this.props.navToReport(report, this.props.reports);
  }

  generateChartData() {
    if (!this.hasReports()) return [];

    let failedCount = 0;
    let passCount = 0;
    let inconclusiveCount = 0;

    this.props.reports.forEach(report => {
      if (report.status === ComplianceStatus.Passed || report.status === ComplianceStatus.Late) {
        passCount++;
      }
      else if (report.status === ComplianceStatus.Inconclusive) {
        inconclusiveCount++;
      }
      else {
        failedCount++;
      }
    });

    return [
      {
        label: 'Not Passing',
        value: failedCount,
        color: '#D22835',
      },
      {
        label: 'Passed',
        value: passCount,
        color: '#64BF00',
      },
      {
        label: 'Inconclusive',
        value: inconclusiveCount,
        color: '#D3D3D3',
      },
    ];
  }

  render() {
    if (this.props.error) {
      return (
        <div className="mt-2">
          <ErrorMessage error={this.props.error} />
        </div>
      );
    }

    return (
      <>
        <Row className="mb-2">
          <Col md={7}>
            <h5 className="mb-0">
              {this.renderTitle()}
              {this.props.isRefreshing ? <CanaryLoadingIndicator inline /> : null}
            </h5>
          </Col>
          <Col md={5}>
            <ComplianceViewControls onChange={this.getReports} />
          </Col>
        </Row>
        <ComplianceViewBar onChange={this.getReports} />
        <Row>
          <Col md={9}>
            {this.renderTable()}
          </Col>
          <Col md={3}>
            <ReportsChart data={this.generateChartData()} />
          </Col>
        </Row>
      </>
    );
  }

  renderTitle() {
    if (this.props.isRefreshing || this.props.isLoading) {
      return null;
    }
    const count = (this.props.reports && this.props.reports.length) || 0;
    return `${count.toLocaleString()} ${pluralize(this.props.filteredReports)}`;
  }

  renderTable() {
    if (this.props.isLoading || this.props.isRefreshing) {
      return <CanaryLoadingIndicator />;
    }

    return (
      <ReportsTable
        dataLoading={this.props.isLoading}
        reports={this.props.filteredReports}
        columns={this.props.columns}
        sortColumnId={this.props.sortColumnId}
        sortDirection={this.props.sortDirection}
        onSortChange={this.props.changeSort}
        currentPage={this.props.currentPage}
        onPageChange={this.props.changePage}
        pageSize={this.props.pageSize}
        pageCount={this.props.pageCount}
        onReportSelected={this.handleReportClick}
      />
    );
  }
}

MonthlyComplianceOverview.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  reports: PropTypes.array,
  filteredReports: PropTypes.array,

  getReports: PropTypes.func.isRequired,
  navToReport: PropTypes.func.isRequired,
  clearNavContext: PropTypes.func.isRequired,

  // filters and columns prefs
  columns: PropTypes.array.isRequired,
  sortColumnId: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  changeSort: PropTypes.func.isRequired,

  // table pagination support
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,

  // feature tour
  startTour: PropTypes.func.isRequired,
};

MonthlyComplianceOverview.defaultProps = {
  error: null,
  reports: [],
  filteredReports: [],
};

function mapStateToProps(state) {
  return {
    isLoading: ComplianceListSelectors.isLoading(state),
    isRefreshing: ComplianceListSelectors.isRefreshing(state),
    error: ComplianceListSelectors.error(state),
    reports: ComplianceListSelectors.list(state),

    filteredReports: ComplianceListTableSelectors.filteredList(state),
    columns: ComplianceListViewManagerSelectors.selectedColumns(state),
    sortColumnId: ComplianceListTableSelectors.sortColumnId(state),
    sortDirection: ComplianceListTableSelectors.sortDirection(state),
    currentPage: ComplianceListTableSelectors.currentPage(state),
    pageSize: ComplianceListTableSelectors.pageSize(state),
    pageCount: ComplianceListTableSelectors.pageCount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReports: () => dispatch(actions.getReports()),
    navToReport: (report, list) => dispatch(navToSiteComplianceReport(report, null, list)),
    clearNavContext: () => dispatch(NavContextActions.clearContext()),

    changeSort: (sortColumnId, sortDirection) => dispatch(actions.changeSort(sortColumnId, sortDirection)),
    changePage: page => dispatch(actions.changePage(page)),
  };
}

export default withTour(TablesTour.tourName, TablesTour.steps)(withTracking('Compliance List')(withRouter(connect(mapStateToProps, mapDispatchToProps)(MonthlyComplianceOverview))));
