import update from 'immutability-helper';

import { AssetGrantTypes } from '../../../permission-constants';
import * as orgEditActions from '../../../actions';
import * as orgEditSelectors from '../../../OrganizationEditSelectors';
import ActionTypes from '../GroupEditorActionTypes';
import * as selectors from '../GroupEditorSelectors';

const NEW = 'new';

function buildNewGroup() {
  return {
    name: '',
    members: [],
  };
}

export function selectGroup(group) {
  return (dispatch, getState) => {
    const groupId = (group && group.id) || group;
    const currentState = getState();
    const org = orgEditSelectors.selectedOrganization(currentState);
    const isLoading = orgEditSelectors.isLoading(currentState);
    if (!groupId || !org || isLoading) {
      return Promise.resolve();
    }

    if (groupId === NEW) {
      return dispatch({
        type: ActionTypes.SELECT_GROUP,
        group: buildNewGroup()
      });
    }

    const selectedGroup = org.groups.find(current => current.id === Number.parseInt(groupId, 10));
    dispatch({
      type: ActionTypes.SELECT_GROUP,
      group: selectedGroup
    });

    return Promise.resolve();
  };
}

export function editSelectedGroup(group) {
  return {
    type: ActionTypes.EDIT_SELECTED_GROUP,
    group
  };
}

export function saveSelectedGroup() {
  return (dispatch, getState) => {
    const currentState = getState();
    const isLoading = orgEditSelectors.isLoading(currentState);
    const isSaving = orgEditSelectors.isSaving(currentState);
    const org = orgEditSelectors.selectedOrganization(currentState);
    const pendingGroupEdits = selectors.pendingGroupEdits(currentState);

    if (isLoading || isSaving || !org || !pendingGroupEdits) {
      return Promise.resolve();
    }

    const editedOrg = addPendingGroupEdits(org, pendingGroupEdits);
    return dispatch(orgEditActions.editAndSaveSelectedOrganization(editedOrg));
  };
}

function addPendingGroupEdits(org, pendingGroupEdits) {
  if (pendingGroupEdits.id) {
    const groupIndex = org.groups.findIndex(current => current.id === pendingGroupEdits.id);
    return update(org, {
      groups: {
        $splice: [[groupIndex, 1, pendingGroupEdits]],
      },
    });
  }

  return update(org, {
    groups: {
      $push: [pendingGroupEdits],
    },
  });
}

export function cancelSelectedGroupEdit() {
  return {
    type: ActionTypes.CANCEL_SELECTED_GROUP_EDIT,
  };
}

export function deleteGroup(group) {
  return (dispatch, getState) => {
    const currentState = getState();
    const isLoading = orgEditSelectors.isLoading(currentState);
    const isSaving = orgEditSelectors.isSaving(currentState);
    const org = orgEditSelectors.selectedOrganization(currentState);

    if (isLoading || isSaving || !org || !group) {
      return Promise.resolve();
    }

    const editedOrg = removeGroupAndPolicies(org, group);
    return dispatch(orgEditActions.editAndSaveSelectedOrganization(editedOrg));
  };
}

function removeGroupAndPolicies(org, group) {
  const groupIndex = org.groups.findIndex(current => current.id === group.id);
  const updatedPolicies = (org.policies || []).filter(policy => isNotGroupPolicy(policy, group));
  const updateSpec = {
    groups: {
      $splice: [[groupIndex, 1]],
    },
    policies: {
      $set: updatedPolicies,
    }
  };
  return update(org, updateSpec);
}

function isNotGroupPolicy(policy, group) {
  if (policy.groupGrantType !== AssetGrantTypes.List) {
    return true;
  }

  const grantedGroup = policy.grantedToGroups && policy.grantedToGroups.length && policy.grantedToGroups[0];
  return !grantedGroup || grantedGroup.id !== group.id;
}
