import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

import SingleSiteInput from '../../../../../shared/components/site-picker/containers/SingleSiteInput';
import { track } from '../../../../../shared/analytics';
import { ItemType } from '../DocConstants';
import * as selectors from '../SiteDocumentSelectors';
import * as actions from '../actions';

class EditButton extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      dropdownOpen: false,
      renameModal: false,
      changeSiteModal: false,
      doc: null,
      filename: '',
      site: null,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.openRenameModal = this.openRenameModal.bind(this);
    this.handleFilenameChange = this.handleFilenameChange.bind(this);
    this.handleFilenameEnter = this.handleFilenameEnter.bind(this);
    this.saveFilenameChange = this.saveFilenameChange.bind(this);
    this.closeRenameModal = this.closeRenameModal.bind(this);

    this.openChangeSiteModal = this.openChangeSiteModal.bind(this);
    this.handleSiteEditStart = this.handleSiteEditStart.bind(this);
    this.handleSiteSelect = this.handleSiteSelect.bind(this);
    this.handleSiteEditEnd = this.handleSiteEditEnd.bind(this);
    this.saveChangeSite = this.saveChangeSite.bind(this);
    this.closeChangeSiteModal = this.closeChangeSiteModal.bind(this);
  }

  toggleDropdown() {
    this.setState(currentState => ({
      dropdownOpen: !currentState.dropdownOpen,
    }));
  }

  openRenameModal() {
    track('Document', {
      Component: 'Edit button',
      'Document action': 'Open rename modal',
    });
    const doc = this.props.selectedItems[0];
    this.setState({
      renameModal: true,
      doc,
      filename: doc.filename,
    });
  }

  handleFilenameChange(event) {
    this.setState({
      filename: event.target.value,
    });
  }

  handleFilenameEnter(event) {
    if (event.key === 'Enter') {
      this.saveFilenameChange();
    }
  }

  saveFilenameChange() {
    const { doc, filename } = this.state;
    this.closeRenameModal();
    this.props.renameDocument(doc, filename);
  }

  closeRenameModal() {
    this.setState({
      renameModal: false,
      doc: null,
      filename: '',
    });
  }

  openChangeSiteModal() {
    track('Document', {
      Component: 'Edit button',
      'Document action': 'Open change site modal',
    });
    const doc = this.props.selectedItems[0];
    this.setState({
      changeSiteModal: true,
      doc,
      site: null,
      editing: false,
    });
  }

  handleSiteEditStart() {
    this.setState({ editing: true });
  }

  handleSiteSelect(site) {
    this.setState({
      site,
    });
  }

  handleSiteEditEnd() {
    this.setState({ editing: false });
  }

  saveChangeSite() {
    const { doc, site } = this.state;
    this.closeChangeSiteModal();
    this.props.changeSite(doc, site);
  }

  closeChangeSiteModal() {
    this.setState({
      changeSiteModal: false,
      doc: null,
      site: null,
    });
  }

  render() {
    const disabled = this.props.selectedItems.length !== 1 || this.props.selectedItems[0].type !== ItemType.Doc;
    return (
      <>
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle caret size="sm" color="primary" outline disabled={disabled}>
            Edit
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this.openRenameModal}>Rename</DropdownItem>
            <DropdownItem onClick={this.openChangeSiteModal}>Change site</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
        {this.renderRenameModal()}
        {this.renderChangeSiteModal()}
      </>
    );
  }

  renderRenameModal() {
    return (
      <Modal isOpen={this.state.renameModal}>
        <ModalHeader>
          Rename document
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="filename">Filename</Label>
            <Input
              type="text"
              id="filename"
              name="filename"
              value={this.state.filename}
              onChange={this.handleFilenameChange}
              onKeyDown={this.handleFilenameEnter}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={this.closeRenameModal}>Cancel</Button>
          {' '}
          <Button color="primary" size="sm" onClick={this.saveFilenameChange}>Save</Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderChangeSiteModal() {
    const canSave = !!this.state.site && !this.state.editing;
    return (
      <Modal isOpen={this.state.changeSiteModal}>
        <ModalHeader>
          Move document
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="site">Site</Label>
            <SingleSiteInput
              id="moveDocSiteInput"
              site={this.state.site}
              onEditStart={this.handleSiteEditStart}
              onSiteSelect={this.handleSiteSelect}
              onEditEnd={this.handleSiteEditEnd}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={this.closeChangeSiteModal}>Cancel</Button>
          {' '}
          <Button color="primary" size="sm" onClick={this.saveChangeSite} disabled={!canSave}>Save</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

EditButton.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  renameDocument: PropTypes.func.isRequired,
  changeSite: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedItems: selectors.selectedItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    renameDocument: (doc, filename) => {
      track('Document', {
        Component: 'Edit button',
        'Document action': 'Rename',
      });
      return dispatch(actions.renameDocument(doc, filename));
    },
    changeSite: (doc, site) => {
      track('Document', {
        Component: 'Edit button',
        'Document action': 'Change site',
      });
      return dispatch(actions.changeSite(doc, site));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditButton);
