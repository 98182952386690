import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import update from 'immutability-helper/index';

function canSave(settings) {
  if (!settings.url) {
    return false;
  }
  if (!settings.username) {
    return false;
  }
  if (!settings.password) {
    return false;
  }
  if (!settings.catalogCode) {
    return false;
  }
  return true;
}

class EasyVistaConfigurationEditor extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.setValue = this.setValue.bind(this);
    this.props.onDispatchSystemConfigurationValueChanged(this.props.dispatchSystemConfiguration, canSave(this.props.dispatchSystemConfiguration.settings));
  }

  setValue(property, value) {
    const updatedSettings = update(this.props.dispatchSystemConfiguration, {
      settings: {
        [property]: {
          $set: value,
        },
      },
    });
    this.props.onDispatchSystemConfigurationValueChanged(updatedSettings, canSave(updatedSettings.settings));
  }

  render() {
    const url = (this.props.dispatchSystemConfiguration && this.props.dispatchSystemConfiguration.settings && this.props.dispatchSystemConfiguration.settings.url) || '';
    const username = (this.props.dispatchSystemConfiguration && this.props.dispatchSystemConfiguration.settings && this.props.dispatchSystemConfiguration.settings.username) || '';
    const password = (this.props.dispatchSystemConfiguration && this.props.dispatchSystemConfiguration.settings && this.props.dispatchSystemConfiguration.settings.password) || '';
    const techNotesSourceEmailAddress = (this.props.dispatchSystemConfiguration && this.props.dispatchSystemConfiguration.settings && this.props.dispatchSystemConfiguration.settings.techNotesSourceEmailAddress) || '';
    const catalogCode = (this.props.dispatchSystemConfiguration && this.props.dispatchSystemConfiguration.settings && this.props.dispatchSystemConfiguration.settings.catalogCode) || '';
    return (
      <div>
        <FormGroup row>
          <Label sm={3} for="url">URL</Label>
          <Col sm={9}>
            <Input
              type="text"
              name="url"
              id="url"
              placeholder="EasyVista URL"
              value={url}
              onChange={event => this.setValue('url', event.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} for="username">Username</Label>
          <Col sm={9}>
            <Input
              type="text"
              name="username"
              id="username"
              placeholder="EasyVista REST API Username"
              value={username}
              onChange={event => this.setValue('username', event.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} for="password">Password</Label>
          <Col sm={9}>
            <Input
              type="text"
              name="password"
              id="password"
              placeholder="EasyVista REST API Password"
              value={password}
              onChange={event => this.setValue('password', event.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} for="password">Tech notes source email</Label>
          <Col sm={9}>
            <Input
              type="text"
              name="techNotesSourceEmailAddress"
              id="techNotesSourceEmailAddress"
              placeholder="Source email address for tech notes"
              value={techNotesSourceEmailAddress}
              onChange={event => this.setValue('techNotesSourceEmailAddress', event.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} for="catalogCode">Catalog Code</Label>
          <Col sm={9}>
            <Input
              type="text"
              name="catalogCode"
              id="catalogCode"
              placeholder="EasyVista Catalog Code"
              value={catalogCode}
              onChange={event => this.setValue('catalogCode', event.target.value)}
            />
          </Col>
        </FormGroup>
      </div>
    );
  }
}

EasyVistaConfigurationEditor.propTypes = {
  onDispatchSystemConfigurationValueChanged: PropTypes.func.isRequired,
  dispatchSystemConfiguration: PropTypes.object,
};

EasyVistaConfigurationEditor.defaultProps = {
  dispatchSystemConfiguration: {},
};

export default EasyVistaConfigurationEditor;
