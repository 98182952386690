import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import CanarySummaryBar from '../../../../../../../../shared/components/CanarySummaryBar';
import PolicyTable from '../../../components/PolicyTable';


function buildTitle(orgGrant, linkToGrantingOrg, linkToGranteeOrg) {
  const grantingOrgLink = `/admin/organizations/${orgGrant.grantedByOrganization.id}/external-access/granted`;
  const granteeOrgLink = `/admin/organizations/${orgGrant.grantedToOrganization.id}/external-access/received`;

  if (linkToGrantingOrg || linkToGranteeOrg) {
    return (
      <span>
        Access granted by
        {' '}
        { linkToGrantingOrg ? <Link to={grantingOrgLink}>{orgGrant.grantedByOrganization.name}</Link> : orgGrant.grantedByOrganization.name }
        {' '}
        to
        {' '}
        { linkToGranteeOrg ? <Link to={granteeOrgLink}>{orgGrant.grantedToOrganization.name}</Link> : orgGrant.grantedToOrganization.name }
      </span>
    );
  }
  return `Access granted by ${orgGrant.grantedByOrganization.name} to ${orgGrant.grantedToOrganization.name}`;
}

function OrgGrants(props) {
  const summary = `${props.orgGrants.length} Access Rules`;
  const controls = props.onPolicyAdd ? <Button color="primary" size="sm" onClick={() => props.onPolicyAdd()}>ADD AN ACCESS RULE</Button> : null;

  return (
    <div>
      <CanarySummaryBar summary={summary} controls={controls} />
      {props.orgGrants.map(orgGrant => (
        <PolicyTable
          key={`${orgGrant.grantedByOrganization.id}_${orgGrant.grantedToOrganization.id}`}
          title={buildTitle(orgGrant, props.linkToGrantingOrg, props.linkToGranteeOrg)}
          policies={orgGrant.policies}
          onPolicyEdit={props.onPolicyEdit}
          onPolicyDelete={props.onPolicyDelete}
        />
      ))}
    </div>
  );
}

OrgGrants.propTypes = {
  orgGrants: PropTypes.arrayOf(PropTypes.shape({
    grantedByOrganization: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    grantedToOrganization: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    policies: PropTypes.arrayOf(PropTypes.shape({
      permissionSet: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      siteGrantType: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
    })).isRequired,
  })),
  linkToGrantingOrg: PropTypes.bool,
  linkToGranteeOrg: PropTypes.bool,
  onPolicyAdd: PropTypes.func,
  onPolicyEdit: PropTypes.func,
  onPolicyDelete: PropTypes.func,
};

OrgGrants.defaultProps = {
  orgGrants: [],
  linkToGrantingOrg: false,
  linkToGranteeOrg: false,
  onPolicyAdd: null,
  onPolicyEdit: null,
  onPolicyDelete: null,
};

export default OrgGrants;
