import { IssueClient, IssueFields, IssueParams } from '../../../client';
import { getListWithEtagAction } from '../../../shared/redux-helpers/ActionCreatorFactory';
import IssueListActionTypes from '../IssueListActionTypes';
import IssueListSelectors, { IssueListViewManagerSelectors } from '../IssueListSelectors';

const buildParams = currentState => ({
  [IssueParams.CustomerIds.name]: IssueListViewManagerSelectors.selectedCustomerIds(currentState),
  [IssueParams.IssueTypes.name]: IssueListViewManagerSelectors.selectedIssueTypes(currentState),
  [IssueParams.WorkflowStatuses.name]: IssueListViewManagerSelectors.selectedIssueWorkflowStatuses(currentState),
  [IssueParams.SiteStates.name]: IssueListViewManagerSelectors.selectedSiteStates(currentState),
  [IssueParams.SiteIds.name]: IssueListViewManagerSelectors.selectedSiteIds(currentState),
  [IssueParams.SiteLabelIds.name]: IssueListViewManagerSelectors.selectedSiteLabelIds(currentState),

});

const buildFields = currentState => [...IssueListViewManagerSelectors.apiFields(currentState), IssueFields.Id, IssueFields.Site, IssueFields.Created];

export const getIssues = getListWithEtagAction(IssueListSelectors, IssueListActionTypes, buildParams, buildFields, IssueClient.findIssues);

export const changeSort = (sortColumnId, sortDirection) => ({ type: IssueListActionTypes.CHANGE_SORT, sortColumnId, sortDirection });

export const changePage = page => ({ type: IssueListActionTypes.CHANGE_PAGE, page });
