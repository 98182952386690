import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import update from 'immutability-helper';
import Toggle from '../../../shared/components/buttons/Toggle';
import {
  disableUserNotificationRule,
  loadPrefsForUser,
  resetToUserNotificationRuleToDefault,
  saveSelectedUserNotificationRule,
  selectUserNotificationRule,
  setNotificationTopicContext,
  updateSelectedUserNotificationRule,
  getCustomerSites } from '../actions';
import * as PreferenceSelectors from '../PreferencesSelectors';
import CanaryLoadingIndicator from '../../../shared/components/CanaryLoadingIndicator';
import SiteSelectorModal from './SiteSelectorModal';
import ButtonWithConfirmation from '../../../shared/components/button-with-confirmation/ButtonWithConfirmation';
import PreferenceConstants, { defaultIssueUserNotificationRuleTemplateObject, emptyArray } from '../PreferenceConstants';
import PreferencesHeaderBar from './PreferencesHeaderBar';
import PreferenceCheckbox from './PreferenceCheckbox';
import UserAlert from './UserAlert';
import UserErrorMessage from './UserErrorMessage';
import {AuthenticationSelectors} from '../../../authentication';
import { withTracking } from '../../../shared/analytics';


const resetDefaultConfirmationText = 'Are you sure you want to restore notification preferences to the default settings?';
const resetDefaultConfirmationHeader = 'Restore to Default?';
const resetButtonColor = 'secondary';
const resetButtonText = 'RESET TO DEFAULT';
const buttonSize = 'sm';

const toggleStyle = Object.freeze({ display: 'inline-block', verticalAlign: 'middle' });

class UserIssueNotificationRules extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.disableResetDefaultButton = this.disableResetDefaultButton.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleNotifyWhenOnChange = this.handleNotifyWhenOnChange.bind(this);
    this.handleNotifyAboutOnChange = this.handleNotifyAboutOnChange.bind(this);
    this.handleAddNotifyAboutSite = this.handleAddNotifyAboutSite.bind(this);
    this.handleNotifyAboutSiteListTypeChange = this.handleNotifyAboutSiteListTypeChange.bind(this);
    this.handleRemoveNotifyAboutSite = this.handleRemoveNotifyAboutSite.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.renderCheckbox = this.renderCheckbox.bind(this);
    this.handleModalOnConfirm = this.handleModalOnConfirm.bind(this);
    this.handleToggleSwitch = this.handleToggleSwitch.bind(this);
    this.handleResetDefault = this.handleResetDefault.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.getCustomerId = this.getCustomerId.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderNotifyAboutContainer = this.renderNotifyAboutContainer.bind(this);
    this.renderSiteSelectorModal = this.renderSiteSelectorModal.bind(this);
    this.renderToggle = this.renderToggle.bind(this);
    this.handleCustomerSelected = this.handleCustomerSelected.bind(this);

    this.state = {
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    this.props.loadPrefsForUser(this.props.userId)
      .then(() => this.props.selectUserNotificationRule(this.props.initialCustomerId))
      .then(() => this.props.getCustomerSites());
  }

  disableResetDefaultButton() {
    const rule = this.props.selectedUserNotificationRule;
    return rule
      && Object.keys(rule.notifyWhen).every((triggerName) => defaultIssueUserNotificationRuleTemplateObject.notifyWhen[triggerName] === rule.notifyWhen[triggerName])
      && Object.keys(rule.notifyAbout).every(topicName => defaultIssueUserNotificationRuleTemplateObject.notifyAbout[topicName].siteListType === rule.notifyAbout[topicName].siteListType);
  }

  handleNotifyWhenOnChange(property, value) {
    // noinspection JSCheckFunctionSignatures
    const updatedUserNotificationRule = update(this.props.selectedUserNotificationRule, {
      notifyWhen: {
        [property]: {
          $set: value,
        },
      },
      transient: {
        $set: true,
      },
    });

    this.props.updateSelectedUserNotificationRule(updatedUserNotificationRule).then(() => {
      if (this.props.invalidUserNotificationRule) {
        this.props.disableUserNotificationRule(this.getCustomerId());
      }
    });
  }

  handleNotifyAboutOnChange(property, subscribed) {
    const siteListType = subscribed ? PreferenceConstants.allSitesAllocated : PreferenceConstants.notAllocated;
    // noinspection JSCheckFunctionSignatures
    const updatedUserNotificationRule = update(this.props.selectedUserNotificationRule, {
      notifyAbout: {
        [property]: {
          $set: {
            siteListType,
            sites: emptyArray,
          }
        },
      },
      transient: {
        $set: true,
      }
    });

    this.props.updateSelectedUserNotificationRule(updatedUserNotificationRule).then(() => {
      if (this.props.invalidUserNotificationRule) {
        this.props.disableUserNotificationRule(this.getCustomerId());
      }
    });
  }

  handleModalOnConfirm(notificationTopicContext) {
    const triggeringSites = notificationTopicContext.triggeringSites;
    // noinspection JSCheckFunctionSignatures
    const updatedUserNotificationRule = update(this.props.selectedUserNotificationRule, {
      notifyAbout: {
        [notificationTopicContext.topic]: {
          siteListType: {
            $set: triggeringSites.siteListType,
          },
          sites: {
            $set: triggeringSites.sites,
          },
        },
      },
      transient: {
        $set: true,
      },
    });
    this.props.clearNotificationTopicContext();
    this.props.updateSelectedUserNotificationRule(updatedUserNotificationRule);
  }

  handleAddNotifyAboutSite(siteContext, site) {
    const updatedTriggeringSites = update(siteContext.triggeringSites, {
      siteListType: {
        $set: PreferenceConstants.listedSitesAllocated
      },
      sites: {
        $push: [site],
      },
    });

    this.props.setNotificationTopicContext(this.props.notificationTopicContext.title, this.props.notificationTopicContext.topic, updatedTriggeringSites);
  }

  handleRemoveNotifyAboutSite(siteContext, siteIndex) {
    const updatedTriggerSites = update(siteContext.triggeringSites, {
      sites: {
        $splice: [[siteIndex, 1]],
      },
    });

    this.props.setNotificationTopicContext(this.props.notificationTopicContext.title, this.props.notificationTopicContext.topic, updatedTriggerSites);
  }

  handleNotifyAboutSiteListTypeChange(notificationTopicContext, siteListType) {
    const updatedTriggeringSites = update(notificationTopicContext.triggeringSites, {
      siteListType: {
        $set: siteListType,
      },
      sites: {
        $set: emptyArray,
      },
    });

    this.props.setNotificationTopicContext(notificationTopicContext.title, notificationTopicContext.topic, updatedTriggeringSites);
  }

  handleSave() {
    this.props.saveSelectedUserNotificationRule(this.getCustomerId());
  }

  handleCancel() {
    this.props.selectUserNotificationRule(this.props.selectedUserNotificationRule.customerId);
  }

  handleResetDefault() {
    this.props.resetToDefault(this.getCustomerId());
  }

  handleCustomerSelected(customerId) {
    this.props.selectUserNotificationRule(customerId);
  }

  getCustomerId() {
    return (this.props.selectedUserNotificationRule && this.props.selectedUserNotificationRule.customerId) || undefined;
  }

  renderCheckbox(labelText, checked, onChange, adminOnly) {
    return (
      <PreferenceCheckbox
        disabled={this.props.selectedUserNotificationRule.isDisabled}
        onChange={onChange}
        labelText={labelText}
        checked={checked}
        adminOnly={adminOnly}
      />
    );
  }

  renderButtons() {
    const button = !this.disableResetDefaultButton()
      ? (
        <ButtonWithConfirmation
          modalTitle={resetDefaultConfirmationHeader}
          modalBodyText={resetDefaultConfirmationText}
          buttonLabel={resetButtonText}
          buttonColor={resetButtonColor}
          containerClassName="float-right"
          buttonSize={buttonSize}
          onConfirm={this.handleResetDefault}
          buttonOutline
        />
      )
      : (
        <Button
          className="float-right"
          color={resetButtonColor}
          disabled
          size={buttonSize}
        >
          {resetButtonText}
        </Button>
      );
    return (
      <div style={{ float: 'right' }}>
        <div className="d-flex flex-row" style={{ marginBottom: '2em', alignContent: 'center' }}>
          <div className="clearfix mr-1">
            {button}
          </div>
          <Button
            size={buttonSize}
            className="mx-1"
            color="secondary"
            onClick={this.handleCancel}
            disabled={!this.props.selectedUserNotificationRule.transient}
            key="issueNotificationRuleCancelBtn"
          >
            CANCEL
          </Button>
          <Button
            size={buttonSize}
            id="save"
            color="primary"
            onClick={this.handleSave}
            disabled={!this.props.canSave}
            key="issueNotificationRuleSaveBtn"
          >
            SAVE
          </Button>
        </div>
      </div>
    );
  }

  toggleDropdown() {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
  }

  handleToggleSwitch() {
    const customerId = this.getCustomerId();
    if (this.props.selectedUserNotificationRule.isDisabled) {
      this.props.resetToDefault(customerId);
    }
    else {
      this.props.disableUserNotificationRule(customerId);
    }
  }

  renderNotifyAboutContainer(labelText, topicPropertyName, notificationTopic) {
    const subscribed = PreferenceConstants.notAllocated !== notificationTopic.siteListType;

    let sites = '0 sites';
    if (PreferenceConstants.allSitesAllocated === notificationTopic.siteListType) {
      sites = `${PreferenceConstants.allSitesAllocated} Sites`;
    }
    else if (PreferenceConstants.listedSitesAllocated === notificationTopic.siteListType) {
      sites = `${notificationTopic.sites.length} Sites`;
    }

    return (
      <Card className="mb-2">
        <CardBody>
          <Row>
            <Col sm="9">
              {this.renderCheckbox(labelText, subscribed, () => this.handleNotifyAboutOnChange(topicPropertyName, !subscribed))}
            </Col>
            <Col sm="3">
              {subscribed ? (
                <Button
                  role="button"
                  color="link"
                  style={{ float: 'right', color: '#4A90E2', cursor: 'pointer' }}
                  onClick={() => this.props.setNotificationTopicContext(labelText, topicPropertyName, notificationTopic)}
                >
                  {sites}
                </Button>
              ) : <span style={{ float: 'right' }}>{sites}</span>}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderBody() {
    const notifyWhen = this.props.selectedUserNotificationRule.notifyWhen;
    const notifyAbout = this.props.selectedUserNotificationRule.notifyAbout;
    return (
      <div>
        <div className="mb-3">
          Make sure you’re being notified about the most relevant issues. Choose your notification preferences below.
        </div>
        <Row className="mb-3">
          <Col sm="6">
            {this.renderToggle()}
          </Col>
          <Col sm="6">
            {this.renderButtons()}
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Label className="mb-2">Notify me when:</Label>
            <Card>
              <CardBody>
                <Form>
                  <FormGroup>
                    {this.renderCheckbox('Canary has detected an alarm and is analyzing', notifyWhen.issueCreated, () => this.handleNotifyWhenOnChange('issueCreated', !notifyWhen.issueCreated))}
                    {this.renderCheckbox('A new issue analysis failed', notifyWhen.issueAnalysisFailed, () => this.handleNotifyWhenOnChange('issueAnalysisFailed', !notifyWhen.issueAnalysisFailed), !this.props.currentUserIsAdmin)}
                    {this.renderCheckbox('A new issue has been opened', notifyWhen.issueReady, () => this.handleNotifyWhenOnChange('issueReady', !notifyWhen.issueReady))}
                    {this.renderCheckbox('New alarms have been added to an issue', notifyWhen.newAlarmsAdded, () => this.handleNotifyWhenOnChange('newAlarmsAdded', !notifyWhen.newAlarmsAdded), !this.props.currentUserIsAdmin)}
                    {this.renderCheckbox('An issue analysis has changed', notifyWhen.issueAnalysisUpdated, () => this.handleNotifyWhenOnChange('issueAnalysisUpdated', !notifyWhen.issueAnalysisUpdated))}
                    {this.renderCheckbox('All alarms in an issue have cleared', notifyWhen.alarmsCleared, () => this.handleNotifyWhenOnChange('alarmsCleared', !notifyWhen.alarmsCleared), !this.props.currentUserIsAdmin)}
                    {this.renderCheckbox('A comment has been added to an issue', notifyWhen.issueComment, () => this.handleNotifyWhenOnChange('issueComment', !notifyWhen.issueComment), !this.props.currentUserIsAdmin)}
                    {this.renderCheckbox('A file has been attached to an issue', notifyWhen.issueFile, () => this.handleNotifyWhenOnChange('issueFile', !notifyWhen.issueFile), !this.props.currentUserIsAdmin)}
                    {this.renderCheckbox('An issue has been closed', notifyWhen.issueResolved, () => this.handleNotifyWhenOnChange('issueResolved', !notifyWhen.issueResolved), !this.props.currentUserIsAdmin)}
                    {this.renderCheckbox('An issue has been deleted', notifyWhen.issueDeleted, () => this.handleNotifyWhenOnChange('issueDeleted', !notifyWhen.issueDeleted), !this.props.currentUserIsAdmin)}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Label className="mb-2">Notify me about:</Label>
            {this.renderNotifyAboutContainer('Leak Detection Issues', 'leakDetectionIssues', notifyAbout.leakDetectionIssues)}
            {this.renderNotifyAboutContainer('Equipment Failures', 'equipmentIssues', notifyAbout.equipmentIssues)}
            {this.renderNotifyAboutContainer('ATG Setting Issues', 'atgConfigurationIssues', notifyAbout.atgConfigurationIssues)}
            {this.renderNotifyAboutContainer('Tech. On Site Issues', 'techOnSiteIssues', notifyAbout.techOnSiteIssues)}
            {this.renderNotifyAboutContainer('Tank Overfills', 'tankOverfillIssues', notifyAbout.tankOverfillIssues)}
            {this.renderNotifyAboutContainer('Tank Water Problems', 'tankWaterIssues', notifyAbout.tankWaterIssues)}
            {this.renderNotifyAboutContainer('Other Issues', 'otherIssues', notifyAbout.otherIssues)}
          </Col>
        </Row>
      </div>
    );
  }

  renderHeader() {
    return (
      <div>
        <UserErrorMessage />
        <div className="mt-0">
          <PreferencesHeaderBar
            displayText="Issue Notifications for"
            customers={this.props.customers}
            onSetCustomerId={this.handleCustomerSelected}
            customerId={this.getCustomerId()}
            keyPrefix="issueNotificationRules"
          />
          <UserAlert isOpen={this.props.canSave} text={PreferenceConstants.unsavedChangesWarning} />
        </div>
      </div>
    );
  }

  renderSiteSelectorModal() {
    return this.props.notificationTopicContext ? (
      <SiteSelectorModal
        notificationTopicContext={this.props.notificationTopicContext}
        handleSiteListTypeChange={this.handleNotifyAboutSiteListTypeChange}
        handleAddSite={this.handleAddNotifyAboutSite}
        handleRemoveSite={this.handleRemoveNotifyAboutSite}
        onCancel={this.props.clearNotificationTopicContext}
        onConfirm={this.handleModalOnConfirm}
        isOpen={!!this.props.notificationTopicContext}
      />
    ) : null;
  }

  renderToggle() {
    const toggleLabel = this.props.selectedUserNotificationRule.isDisabled ? 'OFF' : 'ON';

    return (
      <div>
        <div style={toggleStyle} className="mr-2">{toggleLabel}</div>
        <div style={toggleStyle}>
          <Toggle
            id="toggle"
            onClick={this.handleToggleSwitch}
            value={this.props.selectedUserNotificationRule.isDisabled}
            fontSize="2rem"
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.props.isLoading || !this.props.selectedUserNotificationRule) {
      return (
        <CanaryLoadingIndicator />
      );
    }

    return (
      <div>
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderSiteSelectorModal()}
      </div>
    );
  }
}

UserIssueNotificationRules.propTypes = {
  userId: PropTypes.number.isRequired,
  currentUserIsAdmin: PropTypes.bool.isRequired,
  customers: PropTypes.array.isRequired,
  selectedUserNotificationRule: PropTypes.shape(
    {
      userId: PropTypes.number.isRequired,
      customerId: PropTypes.number.isRequired,
      notifyWhen: PropTypes.shape({
        issueAnalysisFailed: PropTypes.bool.isRequired,
        issueAnalysisUpdated: PropTypes.bool.isRequired,
        issueComment: PropTypes.bool.isRequired,
        issueFile: PropTypes.bool.isRequired,
        alarmsCleared: PropTypes.bool.isRequired,
        issueCreated: PropTypes.bool.isRequired,
        issueReady: PropTypes.bool.isRequired,
        issueReadyDraft: PropTypes.bool.isRequired,
        issueResolved: PropTypes.bool.isRequired,
        issueDeleted: PropTypes.bool.isRequired,
        newAlarmsAdded: PropTypes.bool.isRequired
      }).isRequired,
      notifyAbout: PropTypes.shape({
        leakDetectionIssues: PropTypes.shape({
          siteListType: PropTypes.string.isRequired,
          siteI: PropTypes.array,
        }),
        equipmentIssues: PropTypes.shape({
          siteListType: PropTypes.string.isRequired,
          sites: PropTypes.array,
        }),
        atgConfigurationIssues: PropTypes.shape({
          siteListType: PropTypes.string.isRequired,
          sites: PropTypes.array,
        }),
        techOnSiteIssues: PropTypes.shape({
          siteListType: PropTypes.string.isRequired,
          sites: PropTypes.array,
        }),
        tankOverfillIssues: PropTypes.shape({
          siteListType: PropTypes.string.isRequired,
          sites: PropTypes.array,
        }),
        tankWaterIssues: PropTypes.shape({
          siteListType: PropTypes.string.isRequired,
          sites: PropTypes.array,
        }),
        otherIssues: PropTypes.shape({
          siteListType: PropTypes.string.isRequired,
          sites: PropTypes.array,
        }),
      }).isRequired,
      transient: PropTypes.bool,
      isDisabled: PropTypes.bool,
    },
  ),
  initialCustomerId: PropTypes.number.isRequired,
  selectUserNotificationRule: PropTypes.func.isRequired,
  saveSelectedUserNotificationRule: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateSelectedUserNotificationRule: PropTypes.func.isRequired,
  setNotificationTopicContext: PropTypes.func.isRequired,
  clearNotificationTopicContext: PropTypes.func.isRequired,
  notificationTopicContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    triggeringSites: PropTypes.shape({
      siteListType: PropTypes.string.isRequired,
      sites: PropTypes.array,
    }),
    availableSites: PropTypes.array.isRequired,
  }),
  invalidUserNotificationRule: PropTypes.bool.isRequired,
  canSave: PropTypes.bool.isRequired,
  resetToDefault: PropTypes.func.isRequired,
  disableUserNotificationRule: PropTypes.func.isRequired,
  loadPrefsForUser: PropTypes.func.isRequired,
  getCustomerSites: PropTypes.func.isRequired,

};

UserIssueNotificationRules.defaultProps = {
  notificationTopicContext: null,
  selectedUserNotificationRule: null,
};

function mapStateToProps(state) {
  return {
    currentUserIsAdmin: AuthenticationSelectors.isAdmin(state),
    isLoading: PreferenceSelectors.isLoadingUserNotificationRule(state),
    selectedUserNotificationRule: PreferenceSelectors.selectedUserNotificationRule(state),
    notificationTopicContext: PreferenceSelectors.notificationTopicContext(state),
    invalidUserNotificationRule: PreferenceSelectors.invalidUserNotificationRule(state),
    canSave: PreferenceSelectors.canSaveUserNotificationRule(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectUserNotificationRule: (customerId) => dispatch(selectUserNotificationRule(customerId)),
    updateSelectedUserNotificationRule: userNotificationRule => dispatch(updateSelectedUserNotificationRule(userNotificationRule)),
    saveSelectedUserNotificationRule: () => dispatch(saveSelectedUserNotificationRule()),
    setNotificationTopicContext: (title, topic, triggeringSites) => dispatch(setNotificationTopicContext(title, topic, triggeringSites)),
    clearNotificationTopicContext: () => dispatch(setNotificationTopicContext(null, null, null)),
    resetToDefault: (customerId) => dispatch(resetToUserNotificationRuleToDefault(customerId)),
    disableUserNotificationRule: (customerId) => dispatch(disableUserNotificationRule(customerId, true)),
    loadPrefsForUser: (userId) => dispatch(loadPrefsForUser(userId)),
    getCustomerSites: () => dispatch(getCustomerSites()),
  };
}

export default withTracking('Preferences', 'Issue Notifications')(connect(mapStateToProps, mapDispatchToProps)(UserIssueNotificationRules));
