import React from 'react';
import PropTypes from 'prop-types';

const ConnectionDetails = ({ site }) => {
  function renderConnectionText() {
    if (site.connection && site.connection.connectionType === 'TCP') {
      return 'Connected via TCP/IP';
    }
    else if (site.connection && site.connection.connectionType === 'PARTICLE') {
      return 'Connected via Canary device';
    }
    return 'No Connection';
  }

  return (
    <div className="card p-1 text-uppercase text-muted my-3">
      <small>{renderConnectionText()}</small>
    </div>
  );
};

ConnectionDetails.propTypes = {
  site: PropTypes.object.isRequired,
};

export default ConnectionDetails;
