import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { loadPrefsForUser } from '../actions';
import * as selectors from '../PreferencesSelectors';

import UserIssueNotificationRulesNotificationRules from '../components/UserIssueNotificationRules';
import UserLowProductNotificationRules from '../components/UserLowProductNotificationRules';
import PreferenceConstants, { customerSiteStatuses, routePaths } from '../PreferenceConstants';
import UserAlert from '../components/UserAlert';
import { AuthenticationSelectors } from '../../../authentication';
import AccountSettings from '../components/AccountSettings';

class PreferencesEditor extends Component {
  constructor(props) {
    super(props);

    this.props.loadPrefsForUser(this.props.currentUserId);
    this.renderContent = this.renderContent.bind(this);
  }

  renderContent() {
    return (
      <Switch>
        <Redirect exact="exact" from={`${this.props.match.url}/`} to={`${this.props.match.url}/${routePaths.AccountSettings}`} />
        <Route exact path={`${this.props.match.url}/${routePaths.AccountSettings}`} name="account-settings">
          <AccountSettings />
        </Route>
        <Route exact path={`${this.props.match.url}/${routePaths.IssueNotificationSettings}`} name="issue-notification-settings">
          <UserIssueNotificationRulesNotificationRules userId={this.props.currentUserId} customers={this.props.customers} initialCustomerId={this.props.initialCustomerId} />
        </Route>
        <Route exact path={`${this.props.match.url}/${routePaths.LowProductNotificationSettings}`} name="low-product-notification-settings">
          <UserLowProductNotificationRules userId={this.props.currentUserId} customers={this.props.customers} initialCustomerId={this.props.initialCustomerId} />
        </Route>
      </Switch>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-3">
          <div className="nav nav-pills flex-column">
            <NavLink to={`${this.props.match.url}/account-settings`} className="nav-link">Account Settings</NavLink>
            <NavLink to={`${this.props.match.url}/issue-notification-settings`} className="nav-link">Issue Notification Settings</NavLink>
            <NavLink to={`${this.props.match.url}/low-product-notification-settings`} className="nav-link">Low Product Notification Settings</NavLink>
          </div>
        </div>
        <div className="col-md-9">
          <UserAlert
            isOpen={customerSiteStatuses.Failed === this.props.customerSitesStatus}
            color="danger"
            text={PreferenceConstants.customerSitesWarning}
          />
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

PreferencesEditor.propTypes = {
  customers: PropTypes.array.isRequired,
  initialCustomerId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  customerSitesStatus: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  loadPrefsForUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const customers = AuthenticationSelectors.allCustomers(state);
  return {
    customers,
    initialCustomerId: (customers && customers.length && customers[0].id),
    currentUserId: AuthenticationSelectors.currentUserId(state),
    customerSitesStatus: selectors.customerSitesStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadPrefsForUser: userId => dispatch(loadPrefsForUser(userId)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreferencesEditor));
