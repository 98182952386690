export const TestTypes = {
  PointOneGPH: '0.10 GPH',
  PointTwoGPH: '0.20 GPH',
  ThreeGPH: '3.0 GPH',
};

export const TestResults = {
  Passed: 'PASSED',
  Passing: 'PASSING',
  Failed: 'FAILED',
  Failing: 'FAILING',
  Inconclusive: 'INCONCLUSIVE',
};

export const LineTypes = {
  Pressurized: 'Pressurized',
  Suction: 'Suction',
};

export const LLDTypes = {
  PressurizedLLD: 'PLLD',
  WirelessLLD: 'WPLLD',
  MechanicalLLD: 'MLLD',
  None: 'None',
};

export const TankContainmentTypes = {
  DoubleWall: 'Double wall',
  SingleWall: 'Single wall',
};

export const SensorCategories = {
  Annular: 'Annular',
  Other: 'Other',
};

