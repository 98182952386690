import React from 'react';
import Alert from 'react-s-alert';
import { Route } from 'react-router-dom';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';

import { ResetPasswordForm } from '../authentication';

const ResetPasswordLayout = () => (
  <div className="d-flex login-container">
  <link rel="stylesheet" href="../css/login.css" />
    <div className="container">
      <Route path="/reset-password" component={ResetPasswordForm} />
    </div>
    <Alert stack={{ limit: 3 }} />
  </div>
);

export default ResetPasswordLayout;
