import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import {Badge, Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import update from 'immutability-helper';

import OrganizationDropdownContainer from '../../../../../../shared/components/organization-dropdown/containers/OrganizationDropdownContainer';
import ListFilteringSiteAutoSuggest from '../../../../../../shared/components/site-picker/containers/ListFilteringSiteAutoSuggest';
import { ActorGrantTypes, AssetGrantTypes } from '../permission-constants';
import * as selectors from '../OrganizationEditSelectors';


const SiteBadgeStyle = { backgroundColor: '#4A4946' };

class PolicyEditModal extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleOrgGranteeSelect = this.handleOrgGranteeSelect.bind(this);
    this.handlePermissionSetSelect = this.handlePermissionSetSelect.bind(this);
    this.handleSiteGrantTypeChange = this.handleSiteGrantTypeChange.bind(this);
    this.handleAddSite = this.handleAddSite.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleDescriptionChange(e) {
    const updatedPolicy = update(this.props.policy, {
      description: {
        $set: e.target.value,
      }
    });
    this.props.onPolicyUpdate(updatedPolicy);
  }

  isDescriptionValid() {
    if (!this.props.policy || !this.props.policy.description) {
      return false;
    }
    return !this.isDuplicateDescription();
  }

  isDuplicateDescription() {
    if (!this.props.policy || !this.props.policy.description || !this.props.selectedOrganization.policies || !this.props.selectedOrganization.policies.length) {
      return false;
    }
    const existing = find(this.props.selectedOrganization.policies, p => p.description.toLowerCase() === this.props.policy.description.toLowerCase());
    return existing && existing.id !== this.props.policy.id;
  }

  handleOrgGranteeSelect(orgGrantee) {
    const updatedPolicy = update(this.props.policy, {
      grantedToOrganizations: {
        $set: [orgGrantee],
      }
    });
    this.props.onPolicyUpdate(updatedPolicy);
  }

  handlePermissionSetSelect(e) {
    const permissionSet = this.props.permissionSets.find(current => current.id === Number.parseInt(e.target.value, 10));
    const updatedPolicy = update(this.props.policy, {
      permissionSet: {
        $set: permissionSet
      }
    });
    this.props.onPolicyUpdate(updatedPolicy);
  }

  handleSiteGrantTypeChange(siteGrantType) {
    const updatedPolicy = update(this.props.policy, {
      siteGrantType: {
        $set: siteGrantType
      },
      grantedSites: {
        $set: [],
      }
    });
    this.props.onPolicyUpdate(updatedPolicy);
  }

  handleAddSite(site) {
    const updateSpec = {
      siteGrantType: {
        $set: AssetGrantTypes.List,
      },
    };

    if (this.props.policy.grantedSites) {
      updateSpec.grantedSites = {
        $push: [site],
      };
    }
    else {
      updateSpec.grantedSites = {
        $set: [site],
      };
    }

    const updatedPolicy = update(this.props.policy, updateSpec);
    this.props.onPolicyUpdate(updatedPolicy);
  }

  handleRemoveSite(siteIndex) {
    const updatedPolicy = update(this.props.policy, {
      grantedSites: {
        $splice: [[siteIndex, 1]],
      }
    });
    this.props.onPolicyUpdate(updatedPolicy);
  }

  handleSave() {
    const updateSpec = {
      grantedByOrgId: {
        $set: this.props.selectedOrganization.id,
      },
      grantedByOrganization: {
        $set: this.props.selectedOrganization,
      },
    };

    if (!this.props.policy.permissionSet) {
      updateSpec.permissionSet = {
        $set: this.props.allPermissionSet,
      };
    }

    const updatedPolicy = update(this.props.policy, updateSpec);
    this.props.onSave(updatedPolicy);
  }

  canSave() {
    const policy = this.props.policy;
    if (!policy) {
      return false;
    }

    const groupGrantee = policy.grantedToGroups && policy.grantedToGroups.length && policy.grantedToGroups[0];
    const orgGrantee = policy.grantedToOrganizations && policy.grantedToOrganizations.length && policy.grantedToOrganizations[0];
    const grantedSiteCount = policy.grantedSites && policy.grantedSites.length;

    const validGrantee = (policy.groupGrantType === ActorGrantTypes.List && groupGrantee) || (policy.organizationGrantType === ActorGrantTypes.List && orgGrantee);
    const validSiteGrant = policy.siteGrantType === AssetGrantTypes.All || grantedSiteCount;
    return this.isDescriptionValid() && validGrantee && validSiteGrant;
  }

  renderGrantedTo() {
    const groupGrantee = this.props.policy.grantedToGroups && this.props.policy.grantedToGroups.length && this.props.policy.grantedToGroups[0];
    if (this.props.policy.groupGrantType === ActorGrantTypes.List && groupGrantee) {
      return (
        <FormGroup row>
          <Label for="group" sm={3}>Grant access to:</Label>
          <Col sm={9}>
            <Input id="group" name="group" type="text" value={groupGrantee.name} disabled />
          </Col>
        </FormGroup>
      );
    }

    const orgGrantee = this.props.policy.grantedToOrganizations && this.props.policy.grantedToOrganizations.length && this.props.policy.grantedToOrganizations[0];
    return (
      <FormGroup row>
        <Label for="org" sm={3}>Grant access to:</Label>
        <Col sm={9}>
          <OrganizationDropdownContainer
            id="org"
            name="org"
            selectedOrganization={orgGrantee}
            onOrganizationSelect={this.handleOrgGranteeSelect}
            disabled={Number.isInteger(this.props.policy.id)}
          />
        </Col>
      </FormGroup>
    );
  }

  renderGrantedSiteBadges() {
    const grantedSites = this.props.policy.grantedSites || [];
    return (
      <div>
        {grantedSites.map((grantedSite, siteIndex) => (
          <Badge key={`${grantedSite.id}`} size="sm" className="ml-2" style={SiteBadgeStyle}>
            {grantedSite.nickname}
            <span className="ml-2">
              <FontAwesomeIcon style={SiteBadgeStyle} icon={faTimes} onClick={() => this.handleRemoveSite(siteIndex)} />
            </span>
          </Badge>
        ))}
      </div>
    );
  }

  renderSitePicker() {
    const selectedSiteIds = (this.props.policy.grantedSites && this.props.policy.grantedSites.map(current => current.id)) || [];
    const availableSites = this.props.allSites.filter(current => selectedSiteIds.indexOf(current.id) === -1);
    return (
      <FormGroup row>
        <Label for="sites" sm={3}>Allow access to:</Label>
        <Col sm={9} className="pt-1">
          <FormGroup id="policy-modal-sites" tag="fieldset">
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="policy-modal-sites"
                  checked={this.props.policy.siteGrantType === AssetGrantTypes.All}
                  onChange={() => this.handleSiteGrantTypeChange(AssetGrantTypes.All)}
                />
                {' '}
                All sites
              </Label>
            </FormGroup>
            <FormGroup className="mt-2" check>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Label className="mr-2" check>
                  <Input
                    type="radio"
                    id="policy-modal-sites"
                    checked={this.props.policy.siteGrantType === AssetGrantTypes.List}
                    onChange={() => this.handleSiteGrantTypeChange(AssetGrantTypes.List)}
                  />
                  {' '}
                  Only these sites:
                </Label>
                <ListFilteringSiteAutoSuggest id="policyEditSiteAutoSuggest" siteList={availableSites} onSiteSelected={this.handleAddSite} />
              </div>
            </FormGroup>
          </FormGroup>
          {this.renderGrantedSiteBadges()}
        </Col>
      </FormGroup>
    );
  }

  renderModalBody() {
    const description = this.props.policy.description || '';
    const permissionSetId = (this.props.policy.permissionSet && this.props.policy.permissionSet.id) || this.props.policy.permissionSetId || this.props.allPermissionSet.id;
    return (
      <ModalBody>
        <FormGroup row>
          <Label for="description" sm={3}>Description:</Label>
          <Col sm={9}>
            <Input id="description" name="description" type="text" value={description} onChange={this.handleDescriptionChange} invalid={this.isDuplicateDescription()} />
            <FormFeedback>A policy with that name already exists</FormFeedback>
          </Col>
        </FormGroup>
        {this.renderGrantedTo()}
        <FormGroup row>
          <Label for="permissionSet" sm={3}>Permission:</Label>
          <Col sm={9}>
            <Input id="permissionSet" name="permissionSet" type="select" value={permissionSetId} onChange={this.handlePermissionSetSelect}>
              {this.props.permissionSets.map(current => (
                <option value={current.id} key={current.id}>{current.name}</option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        {this.renderSitePicker()}
      </ModalBody>
    );
  }

  renderHeader() {
    if (Number.isInteger(this.props.policy.id)) {
      return 'Edit policy';
    }
    return 'Add a new policy';
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} size="lg">
        <ModalHeader toggle={this.handleCancel}>
          {this.props.selectedOrganization && this.props.policy ? this.renderHeader() : null}
        </ModalHeader>
        {this.props.selectedOrganization && this.props.policy ? this.renderModalBody() : null}
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={this.props.onCancel}>Cancel</Button>
          {' '}
          <Button color="primary" size="sm" onClick={this.handleSave} disabled={!this.canSave()}>Save</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

PolicyEditModal.propTypes = {
  // public API
  isOpen: PropTypes.bool.isRequired,
  policy: PropTypes.shape({
    id: PropTypes.number,
    grantedByOrgId: PropTypes.number,
    description: PropTypes.string,
    permissionSetId: PropTypes.number,
    permissionSet: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    organizationGrantType: PropTypes.string,
    grantedToOrganizations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    groupGrantType: PropTypes.string,
    grantedToGroups: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    siteGrantType: PropTypes.string,
    grantedSites: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      siteName: PropTypes.string,
      nickname: PropTypes.string,
    })),
    created: PropTypes.string,
  }),
  onPolicyUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,

  // internal stuff
  selectedOrganization: PropTypes.shape({
    id: PropTypes.number,
    policies: PropTypes.array,
  }),
  allSites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteName: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
  })).isRequired,
  allPermissionSet: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  permissionSets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

PolicyEditModal.defaultProps = {
  policy: null,
  selectedOrganization: null,
};

function mapStateToProps(state) {
  return {
    selectedOrganization: selectors.selectedOrganization(state),
    allSites: selectors.selectedOrganizationSites(state),
    allPermissionSet: selectors.allPermissionSet(state),
    permissionSets: selectors.permissionSets(state),
  };
}

export default connect(mapStateToProps)(PolicyEditModal);
