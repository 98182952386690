import immutable from 'object-path-immutable';
import findIndex from 'lodash/findIndex';

import { TankClient } from '../../../../../../../client';
import * as siteSelectors from '../../../../../SiteSelectors';
import * as selectors from '../TankRegistrationSelectors';
import ActionTypes from '../TankRegistrationActionTypes';
import formBuilder from './formBuilder';


// form builder

function resetFormBuilder() {
  return {
    type: ActionTypes.RECEIVE_FORM_BUILDER,
    formBuilder,
  };
}

export function updateFormBuilder(fb) {
  return {
    type: ActionTypes.RECEIVE_FORM_BUILDER,
    formBuilder: fb,
  };
}

export function toggleEditFormBuilderPanel(panel, status) {
  return (dispatch, getState) => {
    const currentState = getState();
    const panelIndex = findIndex(selectors.formBuilder(currentState).panels, ['name', panel.name]);
    const sourcePath = `panels.${panelIndex}`;
    const fb = immutable.set(selectors.formBuilder(currentState), `${sourcePath}.edit`, !panel.edit);
    fb.panels.forEach((p) => {
      p.disableEditButton = status;
      return p;
    });
    dispatch(updateFormBuilder(fb));
  };
}

// tanks

function requestTanks() {
  return {
    type: ActionTypes.REQUEST_TANKS,
    tanksLoading: true,
  };
}

function receiveTank(tank) {
  return {
    type: ActionTypes.RECEIVE_TANK,
    tank,
    tanksLoading: false,
  };
}

function receiveTanksError(error) {
  return {
    type: ActionTypes.RECEIVE_TANK_FAILURE,
    error,
    tanksLoading: false,
  };
}

export function getTank() {
  return (dispatch, getState) => {
    const currentState = getState();
    const tanksLoading = selectors.tanksLoading(currentState);
    const currentSite = siteSelectors.currentSite(currentState);
    const atgTankId = selectors.atgTankId(currentState);
    if (tanksLoading || !currentSite) {
      return Promise.resolve();
    }
    dispatch(requestTanks);

    return TankClient.getTanks(currentSite.id).then((tanks) => {
      const tank = tanks.filter(t => t.id === atgTankId);
      dispatch(receiveTank(tank[0]));
    }).catch((err) => {
      dispatch(receiveTanksError);
      return Promise.reject(err);
    });
  };
}

// tank registration (initial load)

function requestTankRegistration() {
  return {
    type: ActionTypes.REQUEST_TANK_REGISTRATION,
  };
}

function receiveTankRegistration(tankRegistration) {
  return {
    type: ActionTypes.RECEIVE_TANK_REGISTRATION,
    tankRegistration,
  };
}

function receiveTankRegistrationError(error) {
  return {
    type: ActionTypes.RECEIVE_TANK_REGISTRATION_FAILURE,
    error,
  };
}

function loadTankRegistration(findOrCreateFunction) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentSite = siteSelectors.currentSite(currentState);
    const isLoading = selectors.isLoading(currentState);
    if (isLoading || !currentSite) return Promise.resolve();
    dispatch(requestTankRegistration());
    return findOrCreateFunction(currentSite.id).then((tankRegistration) => {
      dispatch(resetFormBuilder());
      dispatch(receiveTankRegistration(tankRegistration));
      dispatch(getTank());
    }).catch((err) => {
      dispatch(receiveTankRegistrationError(err));
    });
  };
}

export function loadTankRegistrationById(tankRegistrationId) {
  const findFunction = siteId => TankClient.getTankRegistrationById(siteId, tankRegistrationId);
  return loadTankRegistration(findFunction);
}

function createNewRegistration(siteId, tankId) {
  return {
    id: null,
    siteId,
    locationType: 'UST',
    atgRiser: {
      atgTankId: tankId,
    },
  };
}

export function loadTankRegistrationByTankId(tankId) {
  const findFunction = siteId => TankClient.getTankRegistrationsBySiteId(siteId).then((registrations) => {
    const existingRegistration = registrations.find(reg => reg.atgRiser && reg.atgRiser.atgTankId === tankId);
    return existingRegistration || createNewRegistration(siteId, tankId);
  });

  return loadTankRegistration(findFunction);
}

// tank registration (edit/rollback)

export function editTankRegistration(updatedTankRegistration) {
  return {
    type: ActionTypes.EDIT_TANK_REGISTRATION,
    tankRegistration: updatedTankRegistration,
  };
}

export function rollbackTankRegistration() {
  return {
    type: ActionTypes.ROLLBACK_TANK_REGISTRATION,
  };
}


// tank registration (save)

function saveTankRegistrationRequest() {
  return {
    type: ActionTypes.SAVE_TANK_REGISTRATION_REQUEST,
  };
}

function saveTankRegistrationFailure(error) {
  return {
    type: ActionTypes.SAVE_TANK_REGISTRATION_FAILURE,
    error,
  };
}

export function saveTankRegistration(reg) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentSite = siteSelectors.currentSite(currentState);
    const isSaving = selectors.isSaving(currentState);

    if (isSaving || !currentSite) return Promise.resolve();

    dispatch(saveTankRegistrationRequest());
    return TankClient.updateTankRegistration(reg).then((res) => {
      dispatch(receiveTankRegistration(res));
      dispatch(resetFormBuilder());
    }).catch((err) => {
      dispatch(saveTankRegistrationFailure(err.response.data));
      return Promise.reject(err);
    });
  };
}

