import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ErrorMessage from '../../../../../../../../shared/components/error-message/ErrorMessage';
import SiteFields from '../../../../../../../../shared/components/site-fields/SiteFields';
import * as OrganizationEditSelectors from '../../../OrganizationEditSelectors';
import * as navActions from '../../../../../actions';
import * as actions from '../actions';
import * as selectors from '../SiteEditorSelectors';

import LatLon from '../components/LatLon';


class NewSite extends Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
  }

  handleSave() {
    this.props.saveSelectedSite().then(this.props.navToSiteList);
  }

  render() {
    return (
      <div className="mt-4">
        <ErrorMessage title="Failed to save site" error={this.props.error} />
        <h5>New Site</h5>
        <SiteFields
          site={this.props.selectedSite}
          showCustomerSelector={false}
          siteLabels={this.props.labels}
          onUpdate={this.props.editSelectedSite}
          onSave={this.handleSave}
          onCancel={this.props.navToSiteList}
          showEasyVistaId={!!(this.props.customerDispatchSystems.find(d => d.systemName === 'EasyVista'))}
        >
          <LatLon selectedSite={this.props.selectedSite} onUpdate={this.props.editSelectedSite} />
        </SiteFields>
      </div>
    );
  }
}

NewSite.propTypes = {
  error: PropTypes.object,
  selectedSite: PropTypes.object,
  labels: PropTypes.array.isRequired,
  editSelectedSite: PropTypes.func.isRequired,
  saveSelectedSite: PropTypes.func.isRequired,
  navToSiteList: PropTypes.func.isRequired,

  match: PropTypes.object.isRequired,
  customerDispatchSystems: PropTypes.array.isRequired,
};

NewSite.defaultProps = {
  error: null,
  selectedSite: null,
};

function mapStateToProps(state) {
  return {
    error: selectors.siteError(state),
    selectedSite: selectors.pendingSiteEdits(state),
    labels: OrganizationEditSelectors.selectedOrganizationSiteLabels(state),
    customerDispatchSystems: OrganizationEditSelectors.selectedOrganizationDispatchSystems(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    selectSite: id => dispatch(actions.selectSite(id)),
    editSelectedSite: updatedSite => dispatch(actions.editSelectedSite(updatedSite)),
    saveSelectedSite: () => dispatch(actions.saveSelectedSite()),
    navToSiteList: () => dispatch(navActions.navToSiteList(ownProps.match.params.organizationId)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewSite));
