import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { MviWorkflowStatusTypes } from '../../../../../../../AppConstants';

const InspectionStatusSelect = ({ selectedInspectionStatuses, selectInspectionStatuses, deselectInspectionStatus, disabled }) => {
  const hasInspectionStatus = inspectionStatus => !!(includes(selectedInspectionStatuses, inspectionStatus));
  const buildUpdateFunc = inspectionStatus => {
    if (hasInspectionStatus(inspectionStatus)) {
      deselectInspectionStatus(inspectionStatus);
    }
    else {
      selectInspectionStatuses([inspectionStatus]);
    }
  };

  const togglePending = () => buildUpdateFunc(MviWorkflowStatusTypes.Pending);
  const pendingChecked = hasInspectionStatus(MviWorkflowStatusTypes.Pending);
  const toggleReadyForReview = () => buildUpdateFunc(MviWorkflowStatusTypes.ReadyForReview);
  const readyForReviewChecked = hasInspectionStatus(MviWorkflowStatusTypes.ReadyForReview);
  const toggleCompleted = () => buildUpdateFunc(MviWorkflowStatusTypes.Completed);
  const completedChecked = hasInspectionStatus(MviWorkflowStatusTypes.Completed);
  const toggleNotRequired = () => buildUpdateFunc(MviWorkflowStatusTypes.NotRequired);
  const notRequiredChecked = hasInspectionStatus(MviWorkflowStatusTypes.NotRequired);
  const toggleNotPerformed = () => buildUpdateFunc(MviWorkflowStatusTypes.NotPerformed);
  const notPerformedChecked = hasInspectionStatus(MviWorkflowStatusTypes.NotPerformed);

  return (
    <Form className="d-xl-flex align-items-center h-100">
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={pendingChecked} onChange={togglePending} disabled={disabled} />
          Pending
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={readyForReviewChecked} onChange={toggleReadyForReview} disabled={disabled} />
          Ready for review
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={completedChecked} onChange={toggleCompleted} disabled={disabled} />
          Completed
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={notRequiredChecked} onChange={toggleNotRequired} disabled={disabled} />
          Not required
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={notPerformedChecked} onChange={toggleNotPerformed} disabled={disabled} />
          Not performed
        </Label>
      </FormGroup>
    </Form>
  );
};

InspectionStatusSelect.propTypes = {
  selectedInspectionStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectInspectionStatuses: PropTypes.func.isRequired,
  deselectInspectionStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default InspectionStatusSelect;
