import React from 'react';
import PropTypes from 'prop-types';

import { dateOrTimeAsText } from './FormatUtils';

const DateOrTime = ({ time }) => (<span>{dateOrTimeAsText(time)}</span>);

DateOrTime.propTypes = {
  time: PropTypes.string,
};

DateOrTime.defaultProps = {
  time: null,
};

export default DateOrTime;
