import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';

import ScheduleBuilder from './ScheduleBuilder';

class ScheduleBuilderModal extends Component {
  render() {
    return (
      <Modal isOpen={this.props.modalOpen} toggle={this.props.onCancel} size="lg">
        <ModalHeader toggle={this.props.onCancel}>Create Schedule</ModalHeader>
        <div style={{ padding: '20px' }}>
          {this.props.modalOpen ?
            <ScheduleBuilder onScheduleChange={this.props.onScheduleChange} />
            : null
          }
          {this.props.children}
        </div>
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={this.props.onCancel}>Cancel</Button>
          {' '}
          <Button id="done" color="primary" size="sm" onClick={this.props.onSave} disabled={!this.props.validate()}>Save</Button>
          
        </ModalFooter>
      </Modal>
    );
  }
}

ScheduleBuilderModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onScheduleChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool,
  children: PropTypes.any,
};

ScheduleBuilderModal.defaultProps = {
  modalOpen: false,
  children: null,
};

export default ScheduleBuilderModal;
