import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import classNames from 'classnames';
import { Gauge } from 'gaugeJS';

import HelpIcon from '../help-icon/HelpIcon';

const GAUGE_OPTS = {
  lines: 12,
  angle: 0,
  lineWidth: 0.27,
  pointer: {
    length: 0.65,
    strokeWidth: 0.035,
    color: '#000000',
  },
  staticLabels: {
    font: '10px "Roboto", sans-serif',
    labels: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  },
  staticZones: [
    { strokeStyle: '#F03E3E', min: 0, max: 80 },
    { strokeStyle: '#FFDD00', min: 80, max: 90 },
    { strokeStyle: '#30B32D', min: 90, max: 100 },
  ],
  limitMax: 'false',
  percentColors: [
    [0.0, '#ff0000'],
    [0.50, '#f9c802'],
    [1.0, '#a9d70b'],
  ],
  strokeColor: '#E0E0E0',
};


class HealthGauge extends Component {

  constructor(initialProps) {
    super(initialProps);
    this.state = {
      showHelp: false,
    };

    this.toggleHelp = this.toggleHelp.bind(this);
    this.activateCanvas = this.activateCanvas.bind(this);

    window.addEventListener('resize', this.activateCanvas);
  }

  componentDidMount() {
    this.rebuildSmallGauge();
    this.rebuildLargeGauge();
  }

  componentWillReceiveProps(newProps) {
    const newValue = newProps.value || 0;
    if (!this.gaugeValue || this.gaugeValue !== newValue) {
      this.gaugeValue = newValue;
      this.smallGauge.set(newValue);
      this.largeGauge.set(newValue);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.activateCanvas);
  }

  rebuildSmallGauge() {
    this.smallTarget.width = 200;
    this.smallTarget.height = 105;
    this.smallGauge = new Gauge(this.smallTarget).setOptions(GAUGE_OPTS);
    this.smallGauge.minValue = 0;
    this.smallGauge.maxValue = 100;
    this.smallGauge.set(this.props.value || 0);
    this.smallGauge.render();
  }

  rebuildLargeGauge() {
    this.largeTarget.width = 300;
    this.largeTarget.height = 165;
    this.largeGauge = new Gauge(this.largeTarget).setOptions(GAUGE_OPTS);
    this.largeGauge.minValue = 0;
    this.largeGauge.maxValue = 100;
    this.largeGauge.set(this.props.value || 0);
    this.largeGauge.render();
  }

  activateCanvas(resizeEvent) {
    if (this.smallTarget && resizeEvent.target.outerWidth < 992 && (!this.smallTarget.width || !this.smallTarget.height)) {
      this.rebuildSmallGauge();
    }
    else if (this.largeTarget && resizeEvent.target.outerWidth >= 992 && (!this.largeTarget.width || !this.largeTarget.height)) {
      this.rebuildLargeGauge();
    }
  }

  toggleHelp(e) {
    e.preventDefault();
    this.setState({
      showHelp: !this.state.showHelp,
    });
  }

  render() {
    return (
      <div
        ref={(container) => {
          this.container = container;
        }}
      >
        <div className="d-flex justify-content-center">
          <h5 style={{ display: 'inline' }}>{this.props.label} {this.state.showHelp}</h5>
          {this.props.helpText ?
            <HelpIcon onClick={this.toggleHelp} className="ml-1" />
            : null
          }
        </div>
        {this.props.helpText ?
          <Alert
            color="info"
            className="mb-1 small"
            isOpen={this.state.showHelp}
            toggle={this.toggleHelp}

          >
            {this.props.helpText}
          </Alert>
          : null
        }
        <div className="d-flex justify-content-center">
          <canvas
            width="300"
            height="165"
            ref={(target) => {
              this.largeTarget = target;
            }}
            className={classNames({ 'd-none d-lg-block': !this.state.showHelp, 'd-none': this.state.showHelp })}
          />
          <canvas
            width="200"
            height="105"
            ref={(target) => {
              this.smallTarget = target;
            }}
            className={classNames({ 'd-lg-none': !this.state.showHelp, 'd-none': this.state.showHelp })}
          />
        </div>
        {this.props.value !== null && !this.state.showHelp ?
          <div className="d-flex justify-content-center">
            <h6>{this.props.value.toFixed(2)}%</h6>
          </div>
          : null
        }
      </div>
    );
  }
}

HealthGauge.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  helpText: PropTypes.string,
};

HealthGauge.defaultProps = {
  loading: false,
  value: null,
  helpText: null,
};

export default HealthGauge;
