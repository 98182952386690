import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CommentList from '../../../../../../../../shared/components/comment/CommentList';
import selectors from './CommentSelectors';

const MviCommentList = ({ comments }) => {
  if (!comments || !comments.length) {
    return null;
  }

  return (
    <div className="mt-4">
      <CommentList comments={comments} />
    </div>
  );
};

MviCommentList.propTypes = {
  comments: PropTypes.array,
};

MviCommentList.defaultProps = {
  comments: null,
};

function mapStateToProps(state) {
  return {
    comments: selectors.list(state)
  };
}

export default connect(mapStateToProps)(MviCommentList);
