import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Link } from 'react-router-dom';

import { track, withTracking } from '../../../../../shared/analytics';
import OrganizationPermissions from '../../../../../organization-permissions';
import HasPermission from '../../../../../authentication/containers/HasPermission';
import ConnectionDetails from '../components/ConnectionDetails';
import RemoteATGButton from '../components/RemoteATGButton';
import CallCommandButton from '../components/CallCommandButton';
import RecentReports from '../components/RecentReports';
import { IntentNames, AtgDialect } from '../CommandConstants';
import * as actions from '../actions';
import * as siteSelectors from '../../../SiteSelectors';
import * as selectors from '../CommandSelectors';
import * as siteActions from '../../../actions';
import MaxProductLimitButton from '../components/tank-settings/MaxProductLimitButton';
import HighProductLimitButton from '../components/tank-settings/HighProductLimitButton';
import OverfillLimitButton from '../components/tank-settings/OverfillLimitButton';
import DeliveryNeededLimitButton from '../components/tank-settings/DeliveryNeededLimitButton';
import LowProductLimitButton from '../components/tank-settings/LowProductLimitButton';
import HighWaterAlarmLimitButton from '../components/tank-settings/HighWaterAlarmLimitButton';
import HighWaterWarningLimitButton from '../components/tank-settings/HighWaterWarningLimitButton';
import LineTests from '../components/LineTests';
import { getAtgInfo } from '../../equipment/screens/atg/actions/index';
import CanaryLoadingIndicator from '../../../../../shared/components/CanaryLoadingIndicator';

class RemoteATG extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.sendInventoryIntentRequest = this.sendInventoryIntentRequest.bind(this);
    this.sendRawCommandIntentRequest = this.sendRawCommandIntentRequest.bind(this);
    this.sendLineSpecificIntent = this.sendLineSpecificIntent.bind(this);
    this.sendSystemStatusRequest = this.sendSystemStatusRequest.bind(this);
    this.sendPriorityAlarmHistory = this.sendPriorityAlarmHistory.bind(this);
    this.sendLeakTestHistory = this.sendLeakTestHistory.bind(this);
    this.sendLeakTestResults = this.sendLeakTestResults.bind(this);
    this.sendPressureLineLeakResults = this.sendPressureLineLeakResults.bind(this);
    this.sendPressureLineLeakHistory = this.sendPressureLineLeakHistory.bind(this);
    this.sendCsldResults = this.sendCsldResults.bind(this);
    this.navToCurrentSiteIssues = this.navToCurrentSiteIssues.bind(this);
    this.sendLiquidSensorStatusReport = this.sendLiquidSensorStatusReport.bind(this);
    this.sendInTankDelivery = this.sendInTankDelivery.bind(this);

    this.sendMaxProductLimit = this.sendMaxProductLimit.bind(this);
    this.sendHighProductLimit = this.sendHighProductLimit.bind(this);
    this.sendOverfillLimit = this.sendOverfillLimit.bind(this);
    this.sendDeliveryNeededLimit = this.sendDeliveryNeededLimit.bind(this);
    this.sendLowProductLimit = this.sendLowProductLimit.bind(this);
    this.sendHighWaterAlarmLimit = this.sendHighWaterAlarmLimit.bind(this);
    this.sendHighWaterWarningLimit = this.sendHighWaterWarningLimit.bind(this);

    this.sendSetReceiverAlarmClear = this.sendSetReceiverAlarmClear.bind(this);
    this.sendSetPriorAlarmsClear = this.sendSetPriorAlarmsClear.bind(this);
    this.sendSetAutodialAlarmClear = this.sendSetAutodialAlarmClear.bind(this);
    this.renderTls350DialectButtons = this.renderTls350DialectButtons.bind(this);
    this.renderTls250DialectButtons = this.renderTls250DialectButtons.bind(this);

    this.props.getSiteLines();
    this.props.getSiteTanks();
    this.props.getRecentCommandRequests();
    this.props.getPendingIntentRequests();
    this.props.getAtgInfo();
  }

  sendInventoryIntentRequest() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.GetInventory);
  }

  sendRawCommandIntentRequest(command) {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.RawCommand, { command });
  }

  sendLineSpecificIntent(intent, line) {
    this.props.sendIntentRequest(this.props.site.id, intent, { line });
  }

  sendSystemStatusRequest() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.GetSystemStatus);
  }

  sendPriorityAlarmHistory() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.GetPriorityAlarmHistory);
  }

  sendLeakTestHistory() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.GetLeakTestHistory);
  }

  sendLeakTestResults() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.GetLeakTestResults);
  }

  sendPressureLineLeakResults() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.GetPressureLineLeakResults);
  }

  sendPressureLineLeakHistory() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.GetPressureLineLeakHistory);
  }

  sendCsldResults() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.GetCsldResults);
  }

  navToCurrentSiteIssues() {
    this.props.navToSiteIssues(this.props.site.id);
  }

  sendLiquidSensorStatusReport() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.GetLiquidSensorStatus);
  }

  sendInTankDelivery() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.GetInTankDelivery);
  }

  sendMaxProductLimit(intent, tank, tankLimit) {
    this.props.sendIntentRequest(this.props.site.id, intent, { tank, tankLimit });
  }

  sendHighProductLimit(intent, tank, tankLimit) {
    this.props.sendIntentRequest(this.props.site.id, intent, { tank, tankLimit });
  }

  sendOverfillLimit(intent, tank, tankLimit) {
    this.props.sendIntentRequest(this.props.site.id, intent, { tank, tankLimit });
  }

  sendDeliveryNeededLimit(intent, tank, tankLimit) {
    this.props.sendIntentRequest(this.props.site.id, intent, { tank, tankLimit });
  }

  sendLowProductLimit(intent, tank, tankLimit) {
    this.props.sendIntentRequest(this.props.site.id, intent, { tank, tankLimit });
  }

  sendHighWaterAlarmLimit(intent, tank, tankLimit) {
    this.props.sendIntentRequest(this.props.site.id, intent, { tank, tankLimit });
  }

  sendHighWaterWarningLimit(intent, tank, tankLimit) {
    this.props.sendIntentRequest(this.props.site.id, intent, { tank, tankLimit });
  }

  sendSetReceiverAlarmClear() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.SetReceiverAlarmStatusClear);
  }

  sendSetPriorAlarmsClear() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.SetRemoteAlarmReset);
  }

  sendSetAutodialAlarmClear() {
    this.props.sendIntentRequest(this.props.site.id, IntentNames.SetAutodialAlarmStatusClear);
  }

  renderTls350DialectButtons() {
    return (
      <>
        { this.props.atgDialect === AtgDialect.Unknown
          ? (<div className="text-danger text-center mb-4">Canary is unable to detect the make and model of your ATG. Some or all of these command buttons may not work as expected.</div>)
          : null }
        <Switch>
          <Route exact path={this.props.match.url} name="index">
            <div>
              <div className="row">
                <div className="col-md-6">
                  <RemoteATGButton onClick={this.sendInventoryIntentRequest}>Check Inventory (I20100)</RemoteATGButton>
                  <RemoteATGButton onClick={this.sendInTankDelivery}>In-Tank Delivery Report (I20200)</RemoteATGButton>

                  <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsInquire}>
                    <RemoteATGButton onClick={this.sendLeakTestHistory}>In-Tank Leak Test History (I20700)</RemoteATGButton>
                  </HasPermission>
                  <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsInquire}>
                    <RemoteATGButton onClick={this.sendLeakTestResults}>In-Tank Leak Test Results (I20800)</RemoteATGButton>
                  </HasPermission>
                  <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsInquire}>
                    <RemoteATGButton onClick={this.sendCsldResults}>CSLD Test Results (I25100)</RemoteATGButton>
                  </HasPermission>
                  <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsInquire}>
                    <RemoteATGButton onClick={this.sendLiquidSensorStatusReport}>Liquid Sensor Status Report (I30100)</RemoteATGButton>
                  </HasPermission>
                </div>
                <div className="col-md-6">
                  <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsInquire}>
                    <RemoteATGButton onClick={this.sendSystemStatusRequest}>System Status (I10100)</RemoteATGButton>
                  </HasPermission>
                  <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsInquire}>
                    <RemoteATGButton onClick={this.sendPriorityAlarmHistory}>Priority Alarm History (I11100)</RemoteATGButton>
                  </HasPermission>
                  <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsInquire}>
                    <RemoteATGButton onClick={this.sendPressureLineLeakResults}>Pressure Line Leak Test Results - 0.20 (I37300)</RemoteATGButton>
                  </HasPermission>
                  <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsInquire}>
                    <RemoteATGButton onClick={this.sendPressureLineLeakHistory}>Pressure Line Leak Test History - 0.20 (I37400)</RemoteATGButton>
                  </HasPermission>

                  {this.props.lines.length
                    ? (
                      <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsSet}>
                        <RemoteATGButton onClick={() => this.props.history.push(`${this.props.match.url}/line-tests`)}>Line Tests</RemoteATGButton>
                      </HasPermission>
                    )
                    : null}

                  <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsSet}>
                    <RemoteATGButton onClick={() => this.props.history.push(`${this.props.match.url}/set-limits`)}>Set Tank Limits</RemoteATGButton>
                  </HasPermission>

                  <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsSet}>
                    <RemoteATGButton onClick={() => this.props.history.push(`${this.props.match.url}/clear-alarms`)}>Clear...</RemoteATGButton>
                  </HasPermission>
                </div>
              </div>
              <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsInquire}>
                <div className="row justify-content-md-center" style={{ paddingTop: 25 }}>
                  <div className="col-md-6">
                    <CallCommandButton onRawCommandSent={this.sendRawCommandIntentRequest}>Other</CallCommandButton>
                  </div>
                </div>
              </HasPermission>
            </div>
          </Route>
          <Route path="/sites/:siteId/atg-command/line-tests/" name="line-tests">
            <LineTests />
          </Route>
          <Route path="/sites/:siteId/atg-command/set-limits/" name="set-limits">
            <div>
              <div className="row mb-1">
                <div className="col-sm-12">
                  <small className="text-uppercase"><strong><Link to={this.props.match.url}>&laquo; BACK</Link></strong></small>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <MaxProductLimitButton
                    tanks={this.props.tanks}
                    onTankSelected={(tank, tankLimit) => this.sendMaxProductLimit(IntentNames.SetMaxProductLimit, tank, tankLimit)}
                  >
                    Set Max Product Limit (S628)
                  </MaxProductLimitButton>
                  <HighProductLimitButton
                    tanks={this.props.tanks}
                    onTankSelected={(tank, tankLimit) => this.sendHighProductLimit(IntentNames.SetHighProductLimit, tank, tankLimit)}
                  >
                    Set High Product Limit (S622)
                  </HighProductLimitButton>
                  <OverfillLimitButton
                    tanks={this.props.tanks}
                    onTankSelected={(tank, tankLimit) => this.sendOverfillLimit(IntentNames.SetOverfillLimit, tank, tankLimit)}
                  >
                    Set Tank Overfill Limit (S623)
                  </OverfillLimitButton>
                  <DeliveryNeededLimitButton
                    tanks={this.props.tanks}
                    onTankSelected={(tank, tankLimit) => this.sendDeliveryNeededLimit(IntentNames.SetDeliveryNeededLimit, tank, tankLimit)}
                  >
                    Set Delivery Needed Limit (S629)
                  </DeliveryNeededLimitButton>
                </div>
                <div className="col-md-6">
                  <LowProductLimitButton
                    tanks={this.props.tanks}
                    onTankSelected={(tank, tankLimit) => this.sendLowProductLimit(IntentNames.SetLowProductLimit, tank, tankLimit)}
                  >
                    Set Low Product Limit (S621)
                  </LowProductLimitButton>
                  <HighWaterAlarmLimitButton
                    tanks={this.props.tanks}
                    onTankSelected={(tank, tankLimit) => this.sendHighWaterAlarmLimit(IntentNames.SetHighWaterAlarmLimit, tank, tankLimit)}
                  >
                    Set High Water Limit (S624)
                  </HighWaterAlarmLimitButton>
                  <HighWaterWarningLimitButton
                    tanks={this.props.tanks}
                    onTankSelected={(tank, tankLimit) => this.sendHighWaterWarningLimit(IntentNames.SetHighWaterWarningLimit, tank, tankLimit)}
                  >
                    Set Water Warning Limit (S627)
                  </HighWaterWarningLimitButton>
                </div>
              </div>
            </div>
          </Route>
          <Route path="/sites/:siteId/atg-command/clear-alarms">
            <div>
              <div className="row mb-1">
                <div className="col-sm-12">
                  <small className="text-uppercase"><strong><Link to={this.props.match.url}>&laquo; BACK</Link></strong></small>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <RemoteATGButton onClick={this.sendSetAutodialAlarmClear}>Clear Autodial Alarm (S52D)</RemoteATGButton>
                </div>
                <div className="col-sm-6">
                  <RemoteATGButton onClick={this.sendSetPriorAlarmsClear}>Clear Prior Alarms (S00300)</RemoteATGButton>
                </div>
                <div className="col-sm-6">
                  <RemoteATGButton onClick={this.sendSetReceiverAlarmClear}>Clear Receiver Alarm (S52F)</RemoteATGButton>
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </>
    );
  }

  renderTls250DialectButtons() {
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <RemoteATGButton onClick={this.sendInventoryIntentRequest}>Check Inventory (200)</RemoteATGButton>
          </div>
        </div>
        <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={OrganizationPermissions.AtgCommandsInquire}>
          <div className="row justify-content-md-left" style={{ paddingTop: 25 }}>
            <div className="col-md-6">
              <CallCommandButton onRawCommandSent={this.sendRawCommandIntentRequest}>Other</CallCommandButton>
            </div>
          </div>
        </HasPermission>
      </>
    );
  }

  render() {
    if (!this.props.site) {
      return null;
    }
    else if (!this.props.site.connection || !this.props.site.enabled) {
      return (
        <h6>You cannot send commands to this site right now</h6>
      );
    }
    else if (this.props.atgInfoLoading) {
      return (<CanaryLoadingIndicator />);
    }

    const renderButtonsFunc = (this.props.atgDialect === AtgDialect.Tls350 || this.props.atgDialect === AtgDialect.Unknown) ? this.renderTls350DialectButtons : this.renderTls250DialectButtons;
    return (
      <div>
        <ConnectionDetails site={this.props.site} />
        {renderButtonsFunc()}
        <RecentReports pendingIntents={this.props.pendingIntents} recentSiteCommandRequests={this.props.recentSiteCommandRequests} navToSiteIssues={this.navToCurrentSiteIssues} />
      </div>
    );
  }
}

RemoteATG.propTypes = {
  site: PropTypes.object.isRequired,
  lines: PropTypes.array,
  tanks: PropTypes.array,
  recentSiteCommandRequests: PropTypes.array.isRequired,
  pendingIntents: PropTypes.array.isRequired,

  getSiteLines: PropTypes.func.isRequired,
  getSiteTanks: PropTypes.func.isRequired,
  getRecentCommandRequests: PropTypes.func.isRequired,
  getPendingIntentRequests: PropTypes.func.isRequired,
  getAtgInfo: PropTypes.func.isRequired,
  sendIntentRequest: PropTypes.func.isRequired,
  navToSiteIssues: PropTypes.func.isRequired,

  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  atgDialect: PropTypes.string.isRequired,
  atgInfoLoading: PropTypes.bool.isRequired,
};

RemoteATG.defaultProps = {
  lines: [],
  tanks: [],
};

function mapStateToProps(state) {
  return {
    site: siteSelectors.currentSite(state),
    lines: selectors.siteLines(state),
    tanks: selectors.siteTanks(state),
    recentSiteCommandRequests: selectors.recentCommandRequests(state),
    pendingIntents: selectors.pendingIntents(state),
    atgDialect: selectors.atgDialect(state),
    atgInfoLoading: selectors.atgInfoLoading(state),

    router: state.router,
    location: state.router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRecentCommandRequests: () => dispatch(actions.getRecentCommandRequests()),
    getPendingIntentRequests: () => dispatch(actions.getPendingIntentRequests()),
    getAtgInfo: () => dispatch(actions.getAtgInfo()),
    sendIntentRequest: (siteId, issueId, intentName, args) => {
      track('ATG Command', {
        Component: 'Command center',
        'Command action': 'Send command',
        'Command type': intentName
      });
      return dispatch(actions.sendIntentRequest(siteId, issueId, intentName, args));
    },
    getSiteLines: () => dispatch(actions.getSiteLines()),
    getSiteTanks: () => dispatch(actions.getSiteTanks()),
    navToSiteIssues: siteId => dispatch(siteActions.navToSiteIssues(siteId)),
  };
}

export default withTracking('Site', 'ATG Commands')(connect(mapStateToProps, mapDispatchToProps)(withRouter(RemoteATG)));
