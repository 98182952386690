import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTracking } from '../../../../../../../shared/analytics';
import AtgReports from '../../../../../../../shared/components/atg-reports/AtgReports';
import CanaryLoadingIndicator from '../../../../../../../shared/components/CanaryLoadingIndicator';
import ErrorMessage from '../../../../../../../shared/components/error-message/ErrorMessage';
import { FormattedTimestamp } from '../../../../../../../shared/components/date';
import * as siteSelectors from '../../../../../SiteSelectors';
import * as selectors from '../ATGSelectors';
import * as actions from '../actions';

class ATGSettingsContainer extends Component {
  componentDidMount() {
    this.props.getAtgInfo();
    this.props.getAtgConfigEvents();
  }

  render() {
    return (
      <div>
        <h4>ATG</h4>
        <div className="ml-1 p-2 mb-3">
          {this.renderInfo()}
        </div>
        <h4>ATG Configuration</h4>
        <div className="my-2">
          {this.renderEvents()}
        </div>
      </div>
    );
  }

  renderInfo() {
    if (this.props.atgInfoLoading) {
      return <CanaryLoadingIndicator />;
    }
    else if (this.props.atgInfoError) {
      return <ErrorMessage error={this.props.atgInfoError} />;
    }
    else if (!this.props.atgInfo) {
      return <span>ATG details are unavailable.</span>;
    }

    return (
      <>
        <div>
          <strong className="mr-1">Manufacturer:</strong>
          <span>{this.props.atgInfo.atgManufacturer}</span>
        </div>
        <div>
          <strong className="mr-1">Model:</strong>
          <span>{this.props.atgInfo.atgModel}</span>
        </div>
        <div>
          <strong className="mr-1">Software version:</strong>
          <span>{this.props.atgInfo.softwareVersion}</span>
        </div>
        <div>
          <strong className="mr-1">Last updated:</strong>
          <FormattedTimestamp time={this.props.atgInfo.lastUpdated} />
        </div>
      </>
    );
  }

  renderEvents() {
    if (this.props.atgConfigEventsLoading) {
      return <CanaryLoadingIndicator />;
    }
    else if (this.props.atgConfigEventsError) {
      return <ErrorMessage error={this.props.atgConfigEventsError} />;
    }
    else if (!this.props.atgConfigEvents || !this.props.atgConfigEvents.length) {
      return <span>ATG configuration reports are unavailable.</span>;
    }

    return (
      <div className="my-2">
        <AtgReports reports={this.props.atgConfigEvents} />
      </div>
    );
  }
}

ATGSettingsContainer.propTypes = {
  atgInfoLoading: PropTypes.bool.isRequired,
  atgInfoError: PropTypes.object,
  atgInfo: PropTypes.object,
  getAtgInfo: PropTypes.func.isRequired,

  atgConfigEventsLoading: PropTypes.bool.isRequired,
  atgConfigEventsError: PropTypes.object,
  atgConfigEvents: PropTypes.array,
  getAtgConfigEvents: PropTypes.func.isRequired,
};

ATGSettingsContainer.defaultProps = {
  atgInfoError: null,
  atgInfo: null,
  atgConfigEventsError: null,
  atgConfigEvents: [],
};

function mapStateToProps(state) {
  return {
    site: siteSelectors.currentSite(state),
    atgInfoLoading: selectors.atgInfoLoading(state),
    atgInfoError: selectors.atgInfoError(state),
    atgInfo: selectors.atgInfo(state),
    atgConfigEventsLoading: selectors.atgConfigEventsLoading(state),
    atgConfigEventsError: selectors.atgConfigEventsError(state),
    atgConfigEvents: selectors.atgConfigEvents(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAtgInfo: () => dispatch(actions.getAtgInfo()),
    getAtgConfigEvents: () => dispatch(actions.getAtgConfigEvents()),
  };
}

export default withTracking('Site', 'Equipment - ATG')(connect(mapStateToProps, mapDispatchToProps)(ATGSettingsContainer));
