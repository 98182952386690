import reduceReducers from 'reduce-reducers';
import { ExportActionTypes, ExportSiteListActionTypes } from './ExportActionTypes';
import { buildReducer } from '../../shared/redux-helpers/ReducerFactory';

function recentExports(state = {}, action) {
  switch (action.type) {
    case ExportActionTypes.SET_CUSTOMER_ID:
      return Object.assign({}, state, {
        customerId: action.customerId,
      });
    case ExportActionTypes.RECEIVE_EXPORTS:
      return Object.assign({}, state, {
        recentExports: action.recentExports,
      });
    default:
      return state;
  }
}

const ExportReducer = reduceReducers(
  recentExports,
  buildReducer(ExportSiteListActionTypes),
);

export default ExportReducer;
