import React from 'react';
import PropTypes from 'prop-types';

const Passed = <img src="/images/icon_checkmark.svg" alt="PASSED" title="PASSED" width="20px" />;
const Failed = <img src="/images/status-icons/FAILED.svg" title="NOT PASSING" alt="NOT PASSING" width="20px" />;

const SiteCompliance = ({ complianceReportStatus }) => {
  switch (complianceReportStatus) {
    case 'LATE':
    case 'PASSED':
      return Passed;
    case 'FAILED':
      return Failed;
    default:
      return null;
  }
};

SiteCompliance.propTypes = {
  complianceReportStatus: PropTypes.string,
};

SiteCompliance.defaultProps = {
  complianceReportStatus: null,
};

export default SiteCompliance;
