import React from 'react';
import PropTypes from 'prop-types';

import { timestampAsText } from './FormatUtils';

const FormattedTimestamp = ({ time, zone, dateOnly }) => <span>{timestampAsText(time, zone, dateOnly)}</span>;

FormattedTimestamp.propTypes = {
  time: PropTypes.any,
  zone: PropTypes.string,
  dateOnly: PropTypes.bool,
};

FormattedTimestamp.defaultProps = {
  time: null,
  zone: null,
  dateOnly: false,
};

export default FormattedTimestamp;
