import React, { Component } from 'react';
import { Input, FormGroup, Label, Col } from 'reactstrap';
import PropTypes from 'prop-types';

class Days extends Component {

  constructor(initialProps) {
    super(initialProps);
    this.isChecked = this.isChecked.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  isChecked(dayOfWeekNumber) {
    return this.props.selectedDays &&
      (this.props.selectedDays.indexOf(dayOfWeekNumber) !== -1 || this.props.selectedDays.indexOf(String(dayOfWeekNumber)) !== -1);
  }

  handleClick(dayOfWeekNumber, checked) {
    if (checked) {
      this.props.onDaySelect(dayOfWeekNumber);
    }
    else {
      this.props.onDayRemove(dayOfWeekNumber);
    }
  }

  render() {
    return (
      <FormGroup row>
        <Col md={2}>
          <span>Days</span>
        </Col>
        <Col md={10}>
          <Label check className="mr-5">
            <Input type="checkbox" checked={this.isChecked(0)} onChange={event => this.handleClick(0, event.target.checked)} />
            Sun
          </Label>
          <Label check className="mr-5">
            <Input type="checkbox" checked={this.isChecked(1)} onChange={event => this.handleClick(1, event.target.checked)} />
            Mon
          </Label>
          <Label check className="mr-5">
            <Input type="checkbox" checked={this.isChecked(2)} onChange={event => this.handleClick(2, event.target.checked)} />
            Tue
          </Label>
          <Label check className="mr-5">
            <Input type="checkbox" checked={this.isChecked(3)} onChange={event => this.handleClick(3, event.target.checked)} />
            Wed
          </Label>
          <Label check className="mr-5">
            <Input type="checkbox" checked={this.isChecked(4)} onChange={event => this.handleClick(4, event.target.checked)} />
            Thu
          </Label>
          <Label check className="mr-5">
            <Input type="checkbox" checked={this.isChecked(5)} onChange={event => this.handleClick(5, event.target.checked)} />
            Fri
          </Label>
          <Label check className="mr-5">
            <Input type="checkbox" checked={this.isChecked(6)} onChange={event => this.handleClick(6, event.target.checked)} />
            Sat
          </Label>
        </Col>
      </FormGroup>
    );
  }
}

Days.propTypes = {
  selectedDays: PropTypes.array.isRequired,
  onDaySelect: PropTypes.func.isRequired,
  onDayRemove: PropTypes.func.isRequired,
};

export default Days;

