import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedInspectionStatuses = ({ selectedInspectionStatuses, disabled, deselectInspectionStatus }) => {
  const selectedObjs = selectedInspectionStatuses.map(current => ({ name: current }));
  const handleRemove = selectedObj => deselectInspectionStatus(selectedObj.name);
  return <FilterItemList items={selectedObjs} textField="name" disabled={disabled} onRemoveItem={handleRemove} />;
};

SelectedInspectionStatuses.propTypes = {
  selectedInspectionStatuses: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool.isRequired,
  deselectInspectionStatus: PropTypes.func.isRequired,
};

SelectedInspectionStatuses.defaultProps = {
  selectedInspectionStatuses: [],
};

export default SelectedInspectionStatuses;
