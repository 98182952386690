import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AuthenticationSelectors } from '../../../authentication';
import * as SiteSelectors from '../SiteSelectors';

const CustomerName = ({ multiCustomer, currentSite }) => {
  if (!multiCustomer || !currentSite || !currentSite.customer || !currentSite.customer.name) {
    return null;
  }
  return <h6 className="text-muted">{currentSite.customer.name}</h6>;
};

CustomerName.propTypes = {
  multiCustomer: PropTypes.bool.isRequired,
  currentSite: PropTypes.shape({
    customer: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  })
};

CustomerName.defaultProps = {
  currentSite: null,
};

function mapStateToProps(state) {
  const allCustomers = AuthenticationSelectors.allCustomers(state);
  return {
    currentSite: SiteSelectors.currentSite(state),
    multiCustomer: allCustomers.length > 1,
  };
}

export default connect(mapStateToProps)(CustomerName);
