import ActionTypes from './ExportHistoryActionTypes';

export default function ExportHistoryReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.RECEIVE_FILTER:
      return Object.assign({}, state, {
        filter: action.filter,
      });

    case ActionTypes.GET_HISTORY_STARTED:
      return Object.assign({}, state, {
        loading: true,
        exportHistoryList: [],
      });
    case ActionTypes.GET_HISTORY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        exportHistoryList: [],
      });
    case ActionTypes.RECEIVE_HISTORY:
      return Object.assign({}, state, {
        loading: false,
        exportHistoryList: action.exportHistoryList,
      });

    case ActionTypes.GET_EXPORT_STARTED:
      return Object.assign({}, state, {
        selectedExportLoading: true,
        selectedExport: null,
      });
    case ActionTypes.GET_EXPORT_FAILED:
      return Object.assign({}, state, {
        selectedExportLoading: false,
        selectedExport: null,
      });
    case ActionTypes.RECEIVE_EXPORT:
      return Object.assign({}, state, {
        selectedExportLoading: false,
        selectedExport: action.selectedExport,
      });

    default:
      return state;
  }
}
