import React from 'react';
import PropTypes from 'prop-types';

const newlineRegex = /(\r?\n| {4})/g;

function asMarkup(text) {
  const parts = text.split(newlineRegex);
  const summaryParts = [];
  let previousNewline = false;
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (part === '    ') {
      previousNewline = false;
      summaryParts.push(<span key={i} className="ml-3" />);
    }
    else if (part.trim() === '' && !previousNewline) {
      previousNewline = true;
      summaryParts.push(<br key={i} />);
    }
    else {
      previousNewline = false;
      summaryParts.push(part);
    }
  }
  return summaryParts;
}

const TextToMarkup = ({ className, text, missingText }) => (
  <div className={className}>
    {text && text.length ? asMarkup(text) : missingText}
  </div>
);

TextToMarkup.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  missingText: PropTypes.string,
};

TextToMarkup.defaultProps = {
  className: null,
  text: null,
  missingText: null,
};

export default TextToMarkup;
