import { buildGenericSort, composeSort, withDirection } from '../../../SortUtils';
import { SortDirection } from '../../../../AppConstants';

const genericSortFunctionFactory = sortColumnId => buildGenericSort(sortColumnId);

function buildSortForColumn(columnsById, sortColumnId) {
  return (columnsById && columnsById[sortColumnId] && columnsById[sortColumnId].sortFunc) || genericSortFunctionFactory(sortColumnId);
}

function buildSort(state, columnsById, sortColumnId, sortDirection) {
  let sortFunc;
  if (sortColumnId) {
    sortFunc = buildSortForColumn(columnsById, sortColumnId);
  }
  else if (state.defaultSortColumnId) {
    sortFunc = buildSortForColumn(columnsById, state.defaultSortColumnId);
  }

  if (sortFunc && state.defaultSortColumnId && sortColumnId !== state.defaultSortColumnId) {
    const defaultSortFunc = buildSortForColumn(columnsById, state.defaultSortColumnId);
    sortFunc = composeSort(sortFunc, defaultSortFunc);
  }

  if (sortFunc) {
    return withDirection(sortFunc)(sortDirection);
  }
  return null;
}

function update(state, columnsById, sortColumnId, sortDirection = SortDirection.Asc, currentPage = 1, pageSize = 50) {
  const list = state.list || [];
  const pageCount = Math.ceil(list.length / pageSize);
  const actualCurrentPage = currentPage <= pageCount ? currentPage : 1;
  const startIndex = (actualCurrentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const filteredList = [...list];
  if (sortColumnId) {
    const sortFunc = buildSort(state, columnsById, sortColumnId, sortDirection);
    if (sortFunc) {
      filteredList.sort(sortFunc);
    }
  }

  return Object.assign({}, state, {
    filteredList: filteredList.slice(startIndex, endIndex),
    sortColumnId,
    sortDirection,
    currentPage: actualCurrentPage,
    pageSize,
    pageCount,
  });
}

function groupById(ColumnType) {
  const byId = {};
  if (ColumnType) {
    Object.values(ColumnType).forEach(col => {
      byId[col.id] = col;
    });
  }
  return byId;
}

export const buildTableReducer = (ActionTypes, ColumnType = {}) => {
  const columnsById = groupById(ColumnType);
  return (state = {}, action) => {
    switch (action.type) {
      case ActionTypes.CHANGE_SORT:
        return update(state, columnsById, action.sortColumnId, action.sortDirection, state.currentPage, state.pageSize);
      case ActionTypes.CHANGE_PAGE:
        return update(state, columnsById, state.sortColumnId || state.defaultSortColumnId, state.sortDirection || state.defaultSortDirection, action.page, state.pageSize);
      case ActionTypes.RECEIVE_LIST:
        return update(state, columnsById, state.sortColumnId || state.defaultSortColumnId, state.sortDirection || state.defaultSortDirection, state.currentPage, state.pageSize);
      default:
        return state;
    }
  };
};
