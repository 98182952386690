import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';

import CanaryLoadingIndicator from '../../../../../shared/components/CanaryLoadingIndicator';
import ErrorMessage from '../../../../../shared/components/error-message/ErrorMessage';
import Subheader from '../../../../../shared/components/Subheader';
import MviWorkflowStatus from '../../../../../shared/components/mvi/MviWorkflowStatus';
import * as SiteActions from '../../../actions';
import * as actions from '../actions';
import * as selectors from '../MviSelectors';

class MviOverview extends Component {
  componentDidMount() {
    this.props.getLatestMvi();
  }

  render() {
    return (
      <div className={this.props.className}>
        <Subheader text="Monthly Visual Inspections" />
        {this.renderMvi()}
        <div className="mt-3 ml-4">
          <Button color="link" onClick={this.props.navToSiteMviList}>Go to Inspections</Button>
        </div>
      </div>
    );
  }

  renderMvi() {
    const latestMvi = this.props.latestMvi;

    if (this.props.loading) {
      return (
        <div className="ml-4 my-2">
          <CanaryLoadingIndicator />
        </div>
      );
    }
    else if (this.props.error) {
      return (
        <div className="ml-4 my-2">
          <ErrorMessage error={this.props.error} />
        </div>
      );
    }
    else if (!latestMvi) {
      return (
        <div className="ml-4 my-2">
          <h5>MVI unavailable</h5>
        </div>
      );
    }

    const monthText = `${latestMvi.monthText} ${latestMvi.year}`;
    return (
      <Card>
        <CardBody>
          <div className="mb-0 cursor-pointer d-flex" onClick={() => this.props.navToSiteMvi(latestMvi)}>
            <strong className="d-inline-block" style={{ width: '10rem' }}>
              {monthText}
            </strong>
            <MviWorkflowStatus workflowStatus={latestMvi.workflowStatus} />
          </div>
        </CardBody>
      </Card>
    );
  }
}

MviOverview.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  latestMvi: PropTypes.object,
  getLatestMvi: PropTypes.func.isRequired,
  navToSiteMvi: PropTypes.func.isRequired,
  navToSiteMviList: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MviOverview.defaultProps = {
  error: null,
  latestMvi: null,
  className: null,
};

function mapStateToProps(state) {
  return {
    loading: selectors.isLoading(state),
    error: selectors.error(state),
    latestMvi: selectors.latestMvi(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLatestMvi: () => dispatch(actions.getLatestMvi()),
    navToSiteMvi: mvi => dispatch(SiteActions.navToSiteMvi(mvi)),
    navToSiteMviList: () => dispatch(SiteActions.navToSiteMviList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MviOverview);
