export const restFuncWithActionArgs = restFunc => (currentState, actionArgs) => restFunc(...actionArgs);

export const genericAction = (inProgressSelector, startedType, successActionFunc, errorType, asyncFunc) => (...actionArgs) => (dispatch, getState) => {
  const currentState = getState();
  const isLoading = inProgressSelector(currentState);
  if (isLoading) {
    return Promise.resolve();
  }

  dispatch({ type: startedType });
  return asyncFunc(currentState, actionArgs)
    .then(data => {
      const successAction = successActionFunc(actionArgs, data);
      dispatch(successAction);
      return data;
    })
    .catch(error => {
      dispatch({ type: errorType, error });
    });
};

export const getListAction = (isLoadingSelector, getListStartedType, receiveListType, errorType, asyncFunc) => genericAction(
  isLoadingSelector,
  getListStartedType,
  (actionArgs, restData) => ({ type: receiveListType, list: restData }),
  errorType,
  asyncFunc
);

export const getListWithEtagAction = (selectors, actionTypes, paramFunc, fieldsFunc, restFindFunc) => () => (dispatch, getState) => {
  const currentState = getState();

  if (selectors.isLoading(currentState) || selectors.isRefreshing(currentState)) {
    return Promise.resolve();
  }

  const params = paramFunc(currentState);
  const fields = fieldsFunc(currentState);
  const etag = selectors.listEtag(currentState);

  if (etag) {
    dispatch({ type: actionTypes.REFRESH_LIST_STARTED });
  }
  else {
    dispatch({ type: actionTypes.GET_LIST_STARTED });
  }

  return restFindFunc(params, fields, etag)
    .then(response => {
      if (response.status === 304) {
        dispatch({ type: actionTypes.REFRESH_LIST_CANCELED });
      }
      else {
        dispatch({ type: actionTypes.RECEIVE_LIST, list: response.data, etag: response.headers.etag });
      }
    })
    .catch(error => {
      if (etag) {
        dispatch({ type: actionTypes.REFRESH_LIST_FAILED, error });
      }
      else {
        dispatch({ type: actionTypes.GET_LIST_FAILED, error });
      }
    });
};

export const getItemAction = (isLoadingSelector, getItemStartedType, receiveItemType, errorType, asyncFunc) => genericAction(
  isLoadingSelector,
  getItemStartedType,
  (actionArgs, restData) => ({ type: receiveItemType, item: restData }),
  errorType,
  asyncFunc
);

export const startItemEditAction = startItemEditType => item => ({ type: startItemEditType, item });

export const editItemAction = itemEditType => pendingEdits => ({ type: itemEditType, pendingEdits });

export const cancelPendingItemEditsAction = cancelItemEditType => () => ({ type: cancelItemEditType });

export const savePendingItemEditsAction = (isSavingSelector, pendingEditsSelector, saveStartedType, saveSuccessType, saveErrorType, restFunc) => genericAction(
  isSavingSelector,
  saveStartedType,
  (actionArgs, restData) => ({ type: saveSuccessType, item: restData }),
  saveErrorType,
  (currentState) => {
    const pendingEdits = pendingEditsSelector(currentState);
    return restFunc(pendingEdits);
  }
);

export const saveItemAction = (isSavingSelector, saveStartedType, saveSuccessType, saveErrorType, asyncFunc) => genericAction(
  isSavingSelector,
  saveStartedType,
  (actionArgs, restData) => ({ type: saveSuccessType, item: restData }),
  saveErrorType,
  asyncFunc
);

export const deleteItemAction = (isDeletingSelector, startedType, successType, errorType, asyncFunc) => genericAction(
  isDeletingSelector,
  startedType,
  (actionArgs) => ({ type: successType, deletedItem: actionArgs[actionArgs.length - 1] }),
  errorType,
  asyncFunc
);
