import React from 'react';
import PropTypes from 'prop-types';

const ConnectingLine = ({ className, first, last }) => {
  let verticalLineStartY = 0;
  let verticalLineEndY = 200;
  if (first) {
    verticalLineStartY = 100;
  }
  else if (last) {
    verticalLineEndY = 100;
  }
  return (
    <svg className={className || null} viewBox="0 0 50 200" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMid slice" style={{ width: '50px' }}>
      <line x1="5" y1={verticalLineStartY} x2="5" y2={verticalLineEndY} stroke="black" />
      <line x1="5" y1="100" x2="50" y2="100" stroke="black" />
    </svg>
  );
};

ConnectingLine.propTypes = {
  className: PropTypes.string,
  first: PropTypes.bool,
  last: PropTypes.bool,
};

ConnectingLine.defaultProps = {
  className: null,
  first: false,
  last: false,
};

export default ConnectingLine;
