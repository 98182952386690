import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

const ErrorIndicator = ({ error }) => (
  <div>
    {error.message
      ? (
        <Card className="loginError" body inverse color="danger">
          <CardText className="py-0 px-1 m-0">
            {error.message}
          </CardText>
        </Card>
      )

      : (
        <Card className="loginError" body inverse color="danger">
          <CardText className="py-0 px-1 m-0"><strong>An error occurred.</strong></CardText>
          <CardText className="py-0 px-1 m-0">The Canary Compliance team has been notified of this error.</CardText>
        </Card>
      )}
  </div>
);

ErrorIndicator.propTypes = {
  error: PropTypes.object.isRequired,
};

export default ErrorIndicator;
