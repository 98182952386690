import { Get, Post, Put, JsonPatch, createSingletonPatchReplacement, Delete } from '../HttpUtils';
import { addFields } from '../param-types';

export const UserFields = {
  Id: 'id',
  Username: 'username',
  Email: 'email',
  UserType: 'userType',
  AccessToken: 'accessToken',
  OrganizationMembership: 'organizationMembership',
  Created: 'created',
  Enabled: 'enabled',
  EntityNotifications: 'entityNotifications',
  LoginHistory: 'loginHistory',
  Permissions: 'permissions'
};

const SingleUserFilter = 'groups,loginHistory,entityNotifications';

export const UserClient = {

  getUserById(id, fields, etag) {
    if (!id) {
      return Promise.resolve(null);
    }
    const requestUrl = `/v2/users/${id}`;
    const reqParams = {};
    addFields(UserFields, fields, reqParams);

    return Get(requestUrl, reqParams, etag).then(response => {
      const data = response.data;
      data.etag = response.headers.etag;
      return data;
    });
  },

  getUser(id) {
    return Get(`/v2/users/${id}?include=${SingleUserFilter}`).then(response => response.data);
  },

  getPermissions(id) {
    return Get(`/v2/users/${id}/permissions`).then(response => response.data);
  },

  saveUser(user) {
    let promise;
    if (user.id) {
      promise = Put(`/v2/users/${user.id}`, user);
    }
    else {
      promise = Post('/v2/users', user);
    }
    return promise.then(response => response.data);
  },

  sendPasswordResetEmail(user) {
    return Post('/v2/users/password-reset-request', { emailAddress: user.email });
  },

  changePassword(userId, passwordContext) {
    return Post(`/v2/users/${userId}/change-password`, passwordContext).then(response => response.data);
  },

  updateEmail(userId, email) {
    return JsonPatch(`/v2/users/${userId}`, createSingletonPatchReplacement('email', email)).then(response => response.data);
  },

  getUserNotificationRule(userId, customerId, entityType) {
    return Get(`/v2/users/${userId}/user-notification-rules/customers/${customerId}?entityType=${entityType}`).then(response => response.data);
  },

  saveUserNotificationRule(userId, userNotificationRule) {
    return Post(`/v2/users/${userId}/user-notification-rules`, userNotificationRule).then(response => response.data);
  },

  getViews(category) {
    return Get('/v2/users/views', { category }).then(response => response.data);
  },

  saveView(view) {
    if (view.id) {
      return Put(`/v2/users/views/${view.id}`, view).then(response => response.data);
    }
    return Post('/v2/users/views', view).then(response => response.data);
  },

  deleteView(view) {
    return Delete(`/v2/users/views/${view.id}`).then(response => response.data);
  },

  getCompletedTours(userId) {
    if (!userId) {
      return Promise.resolve();
    }
    return Get(`/v2/users/${userId}/tours`).then(response => response.data.map(current => current.name));
  },

  markTourComplete(userId, tourName) {
    return Post(`/v2/users/${userId}/tours`, { name: tourName });
  },

  resetAllTours(userId) {
    return Delete(`/v2/users/${userId}/tours`);
  },

  search(user) {
    return Get(`/v2/users/search?emailTerm=${user.email}&usernameTerm=${user.username}`).then(response => response.data);
  }
};
