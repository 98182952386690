import { Get, Put, Post, Delete } from '../HttpUtils';

const inventoryFormatFilter = JSON.stringify({
  include: [
    {
      relation: 'columns',
      scope: {
        include: ['column'],
      },
    },
    {
      relation: 'destinations',
      scope: {
        include: ['fuelHauler'],
      },
    },
  ],
});

export default {
  getList() {
    return Get('/InventoryFormats').then(response => response.data);
  },

  getById(id) {
    return Get(`/InventoryFormats/${id}?filter=${inventoryFormatFilter}`)
      .then((response) => {
        const inventoryFile = response.data;
        inventoryFile.columns = inventoryFile.columns
          .map(current => ({
            id: current.id,
            invFormatId: current.invFormatId,
            columnOrder: current.columnOrder,
            invColumnId: current.invColumnId,
            name: current.column.name,
            categoryId: current.column.categoryId,
            description: current.column.description,
            example: current.column.example,
          }))
          .sort((first, second) => first.columnOrder - second.columnOrder);
        return inventoryFile;
      });
  },

  deleteById(id) {
    return Delete(`/InventoryFormats/${id}`).then(() => Promise.resolve(null));
  },

  save(inventoryFormat) {
    const requestData = {
      id: inventoryFormat.id,
      name: inventoryFormat.name,
      quote: inventoryFormat.quote,
      delimiterCharacter: inventoryFormat.delimiterCharacter,
      includeHeader: inventoryFormat.includeHeader,
      columns: inventoryFormat.columns.map(current => ({
        id: current.id,
        invFormatId: inventoryFormat.id,
        invColumnId: current.invColumnId,
        columnOrder: current.columnOrder,
      })),
    };

    if (requestData.id) {
      return Put(`/InventoryFormats/${requestData.id}`, requestData);
    }
    return Post('/InventoryFormats', requestData);
  },

  getInventoryColumnCategories() {
    return Get('/InventoryColumnCategories?filter[include][columns]').then((response) => {
      const categories = response.data;
      categories.forEach((currentCategory) => {
        currentCategory.columns = currentCategory.columns.map(currentColumn => ({
          invColumnId: currentColumn.id,
          name: currentColumn.name,
          categoryId: currentColumn.categoryId,
          description: currentColumn.description,
          example: currentColumn.example,
        }));
      });
      return categories;
    });
  },
};
