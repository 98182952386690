import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import { Icons } from '../../DocConstants';

const FileIcon = ({ size, extension, className }) => {
  const icon = Icons[extension] || faFile;
  return <FontAwesomeIcon size={size} icon={icon} className={className || ''} />;
};

FileIcon.propTypes = {
  size: PropTypes.string,
  extension: PropTypes.string,
  className: PropTypes.string,
};

FileIcon.defaultProps = {
  size: 'sm',
  extension: null,
  className: null,
};

export default FileIcon;
