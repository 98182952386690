import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, UncontrolledDropdown } from 'reactstrap';

import * as selectors from '../SiteSelectors';


class SiteNav extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.state = {
      equipmentNavOpen: false,
    };

    this.toggleEquipmentNav = this.toggleEquipmentNav.bind(this);
  }

  toggleEquipmentNav() {
    this.setState(current => ({ equipmentNavOpen: !current.equipmentNavOpen }));
  }

  render() {
    return (
      <Nav tabs className={this.props.className}>
        <NavItem>
          <NavLink to={`${this.props.match.url}/overview`} className="nav-link">Dashboard</NavLink>
        </NavItem>
        {this.props.canViewIssues ? (
          <NavItem>
            <NavLink to={`${this.props.match.url}/issues`} className="nav-link">Site Issues</NavLink>
          </NavItem>
        ) : null}
        { this.props.hasDispatchSystem && this.props.canViewIssues ? (
          <NavItem>
            <NavLink to={`${this.props.match.url}/work-orders`} className="nav-link">Work Orders</NavLink>
          </NavItem>
        ) : null }
        {this.props.canViewCompliance ? (
          <NavItem>
            <NavLink to={`${this.props.match.url}/reports`} className="nav-link">Compliance</NavLink>
          </NavItem>
        ) : null}
        {this.props.canViewMvis ? (
          <NavItem>
            <NavLink to={`${this.props.match.url}/inspections`} className="nav-link">Inspections</NavLink>
          </NavItem>
        ) : null}
        {this.props.canCallSiteCommands ? (
          <NavItem>
            <NavLink to={`${this.props.match.url}/atg-command`} className="nav-link">ATG Commands</NavLink>
          </NavItem>
        ) : null}
        {this.renderEquipmentNav()}
        <NavItem>
          <NavLink to={`${this.props.match.url}/documents`} className="nav-link">Documents</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={`${this.props.match.url}/site-info`} className="nav-link">Site Info</NavLink>
        </NavItem>
      </Nav>
    );
  }

  renderEquipmentNav() {
    const equipmentActive = this.props.location.pathname.startsWith(`${this.props.match.url}/equipment`);
    const equipmentToggleClassname = equipmentActive ? 'active' : null;
    const atgActive = this.props.location.pathname.startsWith(`${this.props.match.url}/equipment/atg`);
    const tanksActive = this.props.location.pathname.startsWith(`${this.props.match.url}/equipment/tanks`);
    const linesActive = this.props.location.pathname.startsWith(`${this.props.match.url}/equipment/lines`);
    const sensorsActive = this.props.location.pathname.startsWith(`${this.props.match.url}/equipment/sensors`);
    return (
      <UncontrolledDropdown nav>
        <DropdownToggle nav caret className={equipmentToggleClassname}>
          Equipment
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem active={atgActive} onClick={this.props.navToAtg}>ATG</DropdownItem>
          <DropdownItem active={tanksActive} onClick={this.props.navToTanks}>Tanks</DropdownItem>
          <DropdownItem active={linesActive} onClick={this.props.navToLines}>Lines</DropdownItem>
          <DropdownItem active={sensorsActive} onClick={this.props.navToSensors}>Sensors</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

SiteNav.propTypes = {
  className: PropTypes.string,
  canViewIssues: PropTypes.bool,
  canViewCompliance: PropTypes.bool,
  canViewMvis: PropTypes.bool,
  canCallSiteCommands: PropTypes.bool,
  hasDispatchSystem: PropTypes.bool,

  navToAtg: PropTypes.func.isRequired,
  navToTanks: PropTypes.func.isRequired,
  navToLines: PropTypes.func.isRequired,
  navToSensors: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

SiteNav.defaultProps = {
  className: null,
  canViewIssues: false,
  canViewCompliance: false,
  canViewMvis: false,
  canCallSiteCommands: false,
  hasDispatchSystem: false,
};

function mapStateToProps(state) {
  return {
    canEditSite: selectors.canEditSite(state),
    canViewIssues: selectors.canViewIssues(state),
    canViewCompliance: selectors.canViewCompliance(state),
    canViewMvis: selectors.canViewMvis(state),
    canCallSiteCommands: selectors.canCallSiteCommands(state),
    hasDispatchSystem: selectors.hasDispatchSystem(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    navToAtg: () => dispatch(push(`${ownProps.match.url}/equipment/atg`)),
    navToTanks: () => dispatch(push(`${ownProps.match.url}/equipment/tanks`)),
    navToLines: () => dispatch(push(`${ownProps.match.url}/equipment/lines`)),
    navToSensors: () => dispatch(push(`${ownProps.match.url}/equipment/sensors`)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteNav));
