import { LOCATION_CHANGE } from 'react-router-redux';
import ModalActionTypes from './ModalActionTypes';

const DefaultState = {
  modalOpen: false,
};

export default function (state = DefaultState, action) {
  switch (action.type) {
    case ModalActionTypes.OPEN_MODAL:
      return Object.assign({}, state, {
        title: action.title,
        modalOpen: true,
        site: action.site,
        inspectionDate: action.inspectionDate,
        inspectorCompany: action.inspectorCompany,
        inspectorName: action.inspectorName,
      });
    case LOCATION_CHANGE:
    case ModalActionTypes.CLOSE_MODAL:
      return DefaultState;

    case ModalActionTypes.RECEIVE_SITE:
      return Object.assign({}, state, {
        site: action.site,
      });
    case ModalActionTypes.RECEIVE_INSPECTION_DATE:
      return Object.assign({}, state, {
        inspectionDate: action.inspectionDate,
      });
    case ModalActionTypes.RECEIVE_INSPECTOR_COMPANY:
      return Object.assign({}, state, {
        inspectorCompany: action.inspectorCompany,
      });
    case ModalActionTypes.RECEIVE_INSPECTOR_NAME:
      return Object.assign({}, state, {
        inspectorName: action.inspectorName,
      });

    default:
      return state;
  }
}
