import React from 'react';
import PropTypes from 'prop-types';
import { CanaryTable, IntegerColumn, LinkColumn, StringColumn } from '../../../../shared/components/table';

const IngestFailureFiles = ({ files, onClick }) => {
  if (!files || !files.length) {
    return null;
  }

  return (
    <CanaryTable
      data={files}
    >
      <StringColumn title="File Type" property="fileType" width="10rem" />
      <LinkColumn title="Filename" property="filename" onClick={onClick} />
      <IntegerColumn title="Size (bytes)" property="size" width="10rem" />
    </CanaryTable>
  );
};

IngestFailureFiles.propTypes = {
  files: PropTypes.array,
  onClick: PropTypes.func.isRequired,
};

IngestFailureFiles.defaultProps = {
  files: null,
};

export default IngestFailureFiles;
