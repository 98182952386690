import ActionTypes from './EquipmentActionTypes';

export default function EquipmentCountsReducer(state = {}, action) {
  switch (action.type) {

    // receive site with lines (initial load)
    case ActionTypes.GET_EQUIPMENT_COUNTS_STARTED:
      return Object.assign({}, state, {
        countsLoading: true,
      });
    case ActionTypes.RECEIVE_EQUIPMENT_COUNTS:
      return Object.assign({}, state, {
        tankCount: action.tankCount,
        lineCount: action.lineCount,
        sensorCount: action.sensorCount,
        countsLoading: false,
      });
    case ActionTypes.GET_EQUIPMENT_COUNTS_FAILED:
      return Object.assign({}, state, {
        countsLoading: false,
        error: action.error,
      });
    default:
      return state;
  }
}
