/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const UL1746Part3TankIcon = ({ text }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 225.1 225.1" style={{ enableBackground: 'new 0 0 225.1 225.1' }}>
    <g id="jacketed_steel">
      <g>

        <clipPath id="SVGID_2_">
          <path
            d="M94,1.6h35.8V23c43.9,8.5,76.5,47.4,76.5,93.5c0,52.6-42.3,95.2-94.4,95.2S17.5,169,17.5,116.5 c0-46.1,32.6-85,76.5-93.5V1.6z"
            style={{ overflow: 'visible' }}
          />
        </clipPath>
        <g style={{ clipPath: 'url(#SVGID_2_)' }}>
          <clipPath id="SVGID_4_">
            <rect
              x="-606.1"
              y="-3572.6"
              width="2445.8"
              height="7579.1"
              style={{ overflow: 'visible' }}
            />
          </clipPath>
          <rect x="6.9" y="-9" style={{ clipPath: 'url(#SVGID_4_)', fill: '#F89A1F' }} width="210" height="231.2" />
        </g>
      </g>

      <path
        style={{ fill: '#FFFFFF' }}
        d="M102.9,30.7c-43,4.5-76.5,41.2-76.5,85.7c0,47.6,38.3,86.2,85.5,86.2s85.5-38.6,85.5-86.2 c0-44.5-33.5-81.2-76.5-85.7V10.6h-18V30.7z"
      />
      <g>
        <path
          style={{ fill: '#1B1B1B'}}
          d="M111.9,44.5c39.8,0,72.2,32.4,72.2,72.2s-32.4,72.2-72.2,72.2s-72.2-32.4-72.2-72.2S72.1,44.5,111.9,44.5 M111.9,41.5c-41.5,0-75.2,33.7-75.2,75.2s33.7,75.2,75.2,75.2s75.2-33.7,75.2-75.2S153.4,41.5,111.9,41.5L111.9,41.5z"
        />
      </g>
    </g>
    <g id="Layer_11">
      <text transform="matrix(1 0 0 1 74.9844 137.3187)" style={{ fill: '#414042', fontFamily: 'Roboto', fontSize: '64px' }}>{text}</text>
    </g>
  </svg>
);

UL1746Part3TankIcon.propTypes = {
  text: PropTypes.any,
};

UL1746Part3TankIcon.defaultProps = {
  text: '',
};

export default UL1746Part3TankIcon;
