import { IssueClient } from '../../../../../../../client';
import ActionTypes from '../SiteIssueListActionTypes';
import { createSiteAction } from '../../../../../SiteActionCreatorFactory';
import * as selectors from '../SiteIssueListSelectors';

export const getResolvedIssues = createSiteAction(
  selectors.isResolvedIssuesLoading,
  ActionTypes.GET_RESOLVED_ISSUES_STARTED,
  ActionTypes.RECEIVE_RESOLVED_ISSUES,
  ActionTypes.GET_RESOLVED_ISSUES_FAILED,
  IssueClient.getResolvedSiteIssues,
  'issues'
);
