import React from 'react';
import PropTypes from 'prop-types';
import { dateOrTimeAsText } from '../../../shared/components/date';
import DispatchStatusIcon from '../../../shared/components/dispatch/DispatchStatusIcon';
import { DispatchStatuses } from '../../../AppConstants';

function buildDescription(issue) {
  if (issue.dispatchWorkflowStatus === DispatchStatuses.CREATED) {
    return `${issue.externalDispatchId} created ${dateOrTimeAsText(issue.dispatchCreationDate)}`;
  }
  else if (issue.dispatchWorkflowStatus === DispatchStatuses.TECH_NOTES_RECEIVED) {
    return `${issue.externalDispatchId} tech notes received ${dateOrTimeAsText(issue.dispatchWorkflowStatusDate)}`;
  }
  else if (issue.dispatchWorkflowStatus === DispatchStatuses.EXPIRED) {
    return `${issue.externalDispatchId} expired ${dateOrTimeAsText(issue.dispatchWorkflowStatusDate)}`;
  }
  return `${issue.externalDispatchId} closed ${dateOrTimeAsText(issue.dispatchWorkflowStatusDate)}`;
}

const DispatchDetails = ({ issue, includeClosed, className }) => {
  if (!issue || !issue.externalDispatchId || (issue.dispatchWorkflowStatus === DispatchStatuses.CLOSED && !includeClosed)
    || (issue.dispatchWorkflowStatus === DispatchStatuses.EXPIRED && !includeClosed)) {
    return null;
  }

  return (
    <span className={className}>
      <DispatchStatusIcon dispatchWorkflowStatus={issue.dispatchWorkflowStatus} className="mr-2" />
      {buildDescription(issue)}
    </span>
  );
};

DispatchDetails.propTypes = {
  issue: PropTypes.object,
  includeClosed: PropTypes.bool,
  className: PropTypes.string,
};

DispatchDetails.defaultProps = {
  issue: null,
  includeClosed: false,
  className: null,
};

export default DispatchDetails;
