/* eslint-disable import/prefer-default-export */

import io from 'socket.io-client';
import AuthenticationActionTypes from './authentication/AuthenticationActionTypes';

class SocketIoEventDispatcher {
  constructor() {
    this._eventHandlersList = [];
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
  }

  connect(loginDetails) {
    if (this._socket || !loginDetails.token) {
      return;
    }

    console.log(`Connecting to socket.io as ${loginDetails.username}`);
    const options = {
      query: {
        token: loginDetails.token,
      },
    };
    this._socket = io(options);
    this._registerHandlers();
  }

  disconnect() {
    if (this._socket) {
      console.log('Disconnecting from socket.io');
      this._socket.close();
      this._socket = null;
      this._eventHandlersList = [];
    }
  }

  addHandlers(eventHandlers) {
    this._eventHandlersList.push(eventHandlers);
    if (this._socket) {
      this._registerHandlers();
    }
  }

  _registerHandlers() {
    this._eventHandlersList.forEach(currentEventHandlers => {
      Object.keys(currentEventHandlers).forEach((currentKey) => {
        const invokeHandler = (data) => {
          const currentHandler = currentEventHandlers[currentKey];
          const parsedData = JSON.parse(data);
          currentHandler(parsedData);
        };
        console.log('Registered handler for ' + currentKey);
        this._socket.on(currentKey, invokeHandler);
      });
    });
    this._eventHandlersList = [];
  }
}

let socketIoEventDispatcher = new SocketIoEventDispatcher();

function createDispatcher(loginDetails) {
  if (socketIoEventDispatcher) {
    closeDispatcher();
  }
  socketIoEventDispatcher = new SocketIoEventDispatcher();
  socketIoEventDispatcher.connect(loginDetails);
}

function closeDispatcher() {
  if (socketIoEventDispatcher) {
    socketIoEventDispatcher.disconnect();
  }
  socketIoEventDispatcher = null;
}

export const socketLoginMiddleware = () => next => createLoginActionHandler(createDispatcher, closeDispatcher, next);

export const createSocketMiddleware = actionFactories => store => next => {
  const eventHandlers = {};
  Object.keys(actionFactories).forEach(eventType => {
    const actionFactory = actionFactories[eventType];
    eventHandlers[eventType] = data => actionFactory(data, next, store.getState);
  });

  const setupAfterLogin = () => Promise.resolve().then(() => socketIoEventDispatcher.addHandlers(eventHandlers));
  return createLoginActionHandler(setupAfterLogin, null, next);
};

function createLoginActionHandler(login, logout, next) {
  return action => {
    if (login && (action.type === AuthenticationActionTypes.LOGIN_SUCCESS || action.type === AuthenticationActionTypes.LOGIN_REFRESH)) {
      login(action);
    }
    else if (logout && (action.type === AuthenticationActionTypes.LOGOUT || action.type === AuthenticationActionTypes.LOGIN_FAILURE)) {
      logout(action);
    }
    return next(action);
  };
}
