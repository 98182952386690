import Selectors from '../../shared/redux-helpers/Selectors';
import TableSelectors from '../../shared/components/table/redux/TableSelectors';
import ViewManagerSelectors from '../../shared/components/table/view-manager/ViewManagerSelectors';

const accessor = (state => state && state.mvi) || {};
const selectors = new Selectors(accessor);

export default selectors;

export const MviTableSelectors = new TableSelectors(accessor);
export const MviViewManagerSelectors = new ViewManagerSelectors(accessor);
