import * as selectors from '../EquipmentSelectors';
import * as siteSelectors from '../../../SiteSelectors';
import ActionTypes from '../EquipmentActionTypes';
import { LineClient, SensorClient, TankClient } from '../../../../../client';
import { LLDTypes } from '../../../SiteConstants';

function filterLines(lines) {
  return lines.filter(current => current.lldType === LLDTypes.MechanicalLLD || current.lldType === LLDTypes.None || current.elldEnabled);
}

export function receiveEquipmentCounts(tankCount, lineCount, sensorCount) {
  return {
    type: ActionTypes.RECEIVE_EQUIPMENT_COUNTS,
    tankCount,
    lineCount,
    sensorCount,
  };
}

export function getEquipmentCounts() {
  return (dispatch, getState) => {
    const currentState = getState();
    const countsLoading = selectors.countsLoading(currentState);
    const currentSite = siteSelectors.currentSite(currentState);
    if (countsLoading || !currentSite) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_EQUIPMENT_COUNTS_STARTED });
    const promises = [
      LineClient.getSiteLines(currentSite.id),
      SensorClient.getSiteSensors(currentSite.id),
      TankClient.getTanks(currentSite.id),
    ];
    return Promise.all(promises)
        .then(([lines, sensors, tanks]) => {
          const tanksById = {};
          tanks.forEach((current) => {
            tanksById[current.id] = current;
          });
          dispatch(receiveEquipmentCounts({ count: tanks.length }, { count: filterLines(lines).length }, { count: sensors.length}));
        })
        .catch((err) => {
          dispatch({ type: ActionTypes.GET_EQUIPMENT_COUNTS_FAILED });
          return Promise.reject(err);
        });
  };
}
