import { ItemType, OperationState } from './DocConstants';

export function operationState(state) {
  return (state && state.sites && state.sites.documents && state.sites.documents.operationState) || OperationState.Initial;
}

export function isLoading(state) {
  return operationState(state) === OperationState.Loading;
}

export function isEditing(state) {
  return operationState(state) === OperationState.Editing;
}

export function isDeleting(state) {
  return operationState(state) === OperationState.Deleting;
}

export function isDownloading(state) {
  return operationState(state) === OperationState.Downloading;
}

export function folders(state) {
  return (state && state.sites && state.sites.documents && state.sites.documents.folders) || [];
}

export function activeFolder(state) {
  return state && state.sites && state.sites.documents && state.sites.documents.activeFolder;
}

export function activeFolderItems(state) {
  return (state && state.sites && state.sites.documents && state.sites.documents.activeFolderItems) || [];
}

export function selectedItems(state) {
  return activeFolderItems(state).filter(current => current.checked);
}

export function selectedSubFolders(state) {
  return activeFolderItems(state).filter(current => current.checked && current.type === ItemType.Folder);
}

export function selectedDocuments(state) {
  return activeFolderItems(state).filter(current => current.checked && current.type === ItemType.Doc);
}

function folderDocumentCount(folder) {
  let docCount = (folder.docs && folder.docs.length) || 0;

  if (folder.subFolders) {
    folder.subFolders.forEach(subFolder => {
      docCount += folderDocumentCount(subFolder);
    });
  }
  return docCount;
}

export function totalDocumentCount(state) {
  const root = { subFolders: folders(state) };
  return folderDocumentCount(root);
}
