import React from 'react';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

const CanaryLoadingIndicator = ({ inline }) => {
  if (inline) {
    return (
      <div className="d-inline-block mx-2">
        <PulseLoader color="#F8E71C" size={8} />
      </div>
    );
  }
  return (
    <div className="m-4 d-flex justify-content-center align-items-center">
      <div className="d-inline-block mr-2">
        <PulseLoader color="#F8E71C" size={8} />
      </div>
      Loading...
    </div>
  );
};

CanaryLoadingIndicator.propTypes = {
  inline: PropTypes.bool,
};

CanaryLoadingIndicator.defaultProps = {
  inline: false,
};

export default CanaryLoadingIndicator;
