import { buildStringSort, buildNumberSort } from '../../shared/SortUtils';
import { InventoryFields } from '../../client';

export const InventoryTableColumns = {
  Reading: {
    id: 'reading',
    name: 'Date and time of last reading',
    shortName: 'Reading',
    apiFields: [InventoryFields.InventoryDate],
    sortFunc: buildStringSort('inventoryDate'),
    exportFunc: inv => inv.inventoryDate,
  },
  Customer: {
    id: 'customer',
    name: 'Customer name',
    shortName: 'Customer',
    apiFields: [InventoryFields.Customer],
    sortFunc: buildStringSort('customer.name'),
    exportFunc: inv => (inv.customer && inv.customer.name) || '',
  },
  SiteNickname: {
    id: 'siteNickname',
    name: 'Site',
    shortName: 'Site',
    apiFields: [InventoryFields.Site],
    exportFunc: inv => (inv.site && inv.site.nickname) || '',
    sortFunc: buildStringSort('site.nickname'),
  },
  SiteState: {
    id: 'siteState',
    name: 'State',
    shortName: 'State',
    apiFields: [InventoryFields.Site],
    exportFunc: inv => (inv.site && inv.site.state) || '',
    sortFunc: buildStringSort('site.state'),
  },
  TankNumber: {
    id: 'tankNumber',
    name: 'Tank number',
    shortName: '#',
    apiFields: [InventoryFields.TankNumber],
    exportFunc: inv => inv.tankNumber,
    sortFunc: buildNumberSort('tankNumber'),
  },
  TankLabel: {
    id: 'tankLabel',
    name: 'Tank label',
    shortName: 'Tank label',
    apiFields: [InventoryFields.ProductLabel],
    exportFunc: inv => inv.productLabel,
    sortFunc: buildStringSort('productLabel'),
  },
  Status: {
    id: 'status',
    name: 'Status',
    shortName: 'Status',
    apiFields: [InventoryFields.Status],
  },
  WaterHeight: {
    id: 'waterHeight',
    name: 'Water height',
    shortName: 'Water',
    apiFields: [InventoryFields.WaterHeightInches],
    exportFunc: inv => inv.waterHeightInches,
    sortFunc: buildNumberSort('waterHeightInches')
  },
  FullVolumeGallons: {
    id: 'fullVolumeGallons',
    name: 'Tank size',
    shortName: 'Tank size',
    apiFields: [InventoryFields.FullVolumeGallons],
    exportFunc: inv => inv.fullVolumeGallons,
    sortFunc: buildNumberSort('fullVolumeGallons'),
  },
  VolumeGallons: {
    id: 'volumeGallons',
    name: 'Tank volume',
    shortName: 'Volume',
    apiFields: [InventoryFields.VolumeGallons],
    exportFunc: inv => inv.volumeGallons,
    sortFunc: buildNumberSort('volumeGallons'),
  },
  TcVolumeGallons: {
    id: 'tcVolumeGallons',
    name: 'Temperature corrected volume',
    shortName: 'TC Vol',
    apiFields: [InventoryFields.TcVolumeGallons],
    exportFunc: inv => inv.tcVolumeGallons,
    sortFunc: buildNumberSort('tcVolumeGallons'),
  },
  Temperature: {
    id: 'temperature',
    name: 'Temperature',
    shortName: 'Temp',
    apiFields: [InventoryFields.TemperatureFahrenheit],
    exportFunc: inv => inv.temperatureFahrenheit,
    sortFunc: buildNumberSort('temperatureFahrenheit'),
  },
  FuelHeightInches: {
    id: 'fuelHeightInches',
    name: 'Fuel height',
    shortName: 'Height',
    apiFields: [InventoryFields.FuelHeightInches],
    exportFunc: inv => inv.fuelHeightInches,
    sortFunc: buildNumberSort('fuelHeightInches'),
  },
  Ullage90Percent: {
    id: 'ullage90Percent',
    name: '90% ullage',
    shortName: '90% ullage',
    apiFields: [InventoryFields.Ullage90PercentGallons, InventoryFields.FullVolumeGallons],
    exportFunc: inv => inv.ullage90PercentGallons,
    sortFunc: buildNumberSort('ullage90PercentGallons')
  },
  Ullage95Percent: {
    id: 'ullage95Percent',
    name: '95% ullage',
    shortName: '95% ullage',
    apiFields: [InventoryFields.Ullage95PercentGallons, InventoryFields.FullVolumeGallons],
    exportFunc: inv => inv.ullage95PercentGallons,
    sortFunc: buildNumberSort('ullage95PercentGallons'),
  },
  Ullage: {
    id: 'ullage',
    name: 'Ullage',
    shortName: 'Ullage',
    apiFields: [InventoryFields.UllageGallons],
    exportFunc: inv => inv.ullageGallons,
    sortFunc: buildNumberSort('ullageGallons'),
  },
  LatestDeliveryVolume: {
    id: 'latestDeliveryVolume',
    name: 'Latest delivery volume',
    shortName: 'Last deliv.',
    apiFields: [InventoryFields.DeliveryVolumeGallons],
    exportFunc: inv => inv.deliveryVolumeGallons,
    sortFunc: buildNumberSort('deliveryVolumeGallons'),
  },
  LatestDeliveryDate: {
    id: 'latestDeliveryDate',
    name: 'Latest delivery date',
    shortName: 'Last deliv. date',
    apiFields: [InventoryFields.DeliveryStartDate],
    exportFunc: inv => inv.deliveryStartDate,
    sortFunc: buildStringSort('deliveryStartDate'),
  },
  SiteLabels: {
    id: 'siteLabels',
    name: 'Site label',
    shortName: 'Site label',
    apiFields: [InventoryFields.SiteLabels],
    exportFunc: inv => {
      if (!inv.siteLabels || !inv.siteLabels.length) {
        return '';
      }
      return inv.siteLabels.map(l => l.labelText).join(' ');
    }
  }
};

export const DefaultTableColumns = [
  InventoryTableColumns.Reading,
  InventoryTableColumns.SiteNickname,
  InventoryTableColumns.TankNumber,
  InventoryTableColumns.TankLabel,
  InventoryTableColumns.Status,
  InventoryTableColumns.WaterHeight,
  InventoryTableColumns.FullVolumeGallons,
  InventoryTableColumns.VolumeGallons,
  InventoryTableColumns.Ullage90Percent,
  InventoryTableColumns.SiteLabels,
];
