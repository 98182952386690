/* eslint-disable no-use-before-define */

import { InventoryFormatClient } from '../../../../../../client';
import * as selectors from '../FuelHaulersSelectors';

function isBlank(value) {
  return !value || value.trim() === '';
}

export default function validateSelectedFuelHauler(state) {
  const fuelHauler = selectors.selectedFuelHauler(state);

  const errors = [];
  if (isBlank(fuelHauler.name)) {
    errors.push('Name is required.');
  }

  if (fuelHauler.enabled && (!fuelHauler.sites || !fuelHauler.sites.length)) {
    errors.push('Add one or more sites or disable this fuel hauler.');
  }

  if (fuelHauler.enabled && (!fuelHauler.destinations || !fuelHauler.destinations.length)) {
    errors.push('Add one or destinations or disable this fuel hauler.');
  }

  if (fuelHauler.enabled && (!fuelHauler.schedules || !fuelHauler.schedules.length)) {
    errors.push('Add one or schedules or disable this fuel hauler.');
  }

  return validateDestinations(fuelHauler, errors).then(() => errors);
}

function validateDestinations(fuelHauler, errors) {
  if (!fuelHauler.destinations) {
    return Promise.resolve();
  }
  const promises = fuelHauler.destinations.map(current => validateSingleDestination(fuelHauler, current, errors));
  return Promise.all(promises);
}

function validateSingleDestination(fuelHauler, destination, errors) {
  return InventoryFormatClient.getById(destination.invFormatId)
    .then((inventoryFormat) => {
      const fhTimezoneColumns = inventoryFormat.columns.filter(current => current.name.startsWith('inventory_date_fh')).length;
      if (fhTimezoneColumns && !fuelHauler.fuelHaulerTimezone) {
        const errorMsg = 'You must set the timezone for this fuel hauler because one or more destinations use file formats that depend on it.';
        if (errors.indexOf(errorMsg) === -1) {
          errors.push(errorMsg);
        }
      }

      const fhIdColumn = inventoryFormat.columns.filter(current => current.name === 'fuel_hauler_site_id').length;
      if (fhIdColumn && fuelHauler.sites && fuelHauler.sites.length) {
        const sitesWithoutFuelHaulerId = fuelHauler.sites.filter(current => !current.fuelHaulerExternalId || !current.fuelHaulerExternalId.trim()).length;
        if (sitesWithoutFuelHaulerId) {
          const errorMsg = 'One or more destinations use a file format that includes the fuel_hauler_site_id column but one or more sites are missing a fuel hauler assigned id.';
          if (errors.indexOf(errorMsg) === -1) {
            errors.push(errorMsg);
          }
        }
      }
    });
}

