import React from 'react';
import PropTypes from 'prop-types';

const ConnectivityPercentage = ({ value, title }) => {
  let displayValue = '?';
  if (value !== null && value !== undefined) {
    displayValue = value.toFixed(0);
  }

  let color = '#d22835';
  if (value >= 90) {
    color = '#5aa800';
  }
  else if (value >= 80) {
    color = '#f6e512';
  }
  else if (value >= 70) {
    color = '#ff8913';
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center number-box" style={{ height: '8em', width: '8em' }}>
      <h1 style={{ color }}>{displayValue}%</h1>
      <span>{title}</span>
    </div>
  );
};

ConnectivityPercentage.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string.isRequired,
};

ConnectivityPercentage.defaultProps = {
  value: null,
};

export default ConnectivityPercentage;
