import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import FileSaver from 'file-saver';

class FilesModal extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleFileDownload = this.handleFileDownload.bind(this);
  }

  handleFileDownload(exportFile) {
    const file = new File([exportFile.fileContents], exportFile.filename, { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(file);
  }

  renderFiles() {
    if (this.props.selectedExport && this.props.selectedExport.files && this.props.selectedExport.files.length) {
      return (
        <div style={{ padding: '20px' }}>
          {this.props.selectedExport.files.map(current => (
            <div className="mb-3" key={current.destinationName}>
              <Button size="sm" color="primary" outline className="mr-2" onClick={() => this.handleFileDownload(current)}>Download</Button>
              <span>{current.destinationName}</span>
            </div>
          ))}
        </div>
      );
    }
    return (
      <div style={{ padding: '20px' }}>
        <span>No preview available</span>
      </div>
    );
  }
  
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.onClose}
      >
        <ModalHeader toggle={this.props.onClose}>Download Files</ModalHeader>
        {this.renderFiles()}
        <ModalFooter>
          <Button size="sm" color="primary" onClick={this.props.onClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

FilesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedExport: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

FilesModal.defaultProps = {
  selectedExport: null,
};

export default FilesModal;
