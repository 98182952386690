/* eslint-disable comma-dangle */
import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import PropTypes from 'prop-types';

import AtgTankIcon from '../../../components/AtgTankIcon';
import PlaceholderTankIcon from '../../../components/PlaceholderTankIcon';

class TankDragSource extends Component {
  static renderIcon(tank) {
    if (tank && !tank.registration) {
      const tankNumber = `T${tank.tankNumber}`;
      return <AtgTankIcon text={tankNumber} />;
    }
    return <PlaceholderTankIcon />;
  }

  static renderProductLabel(tank) {
    let text = 'Unknown product';
    if (tank && tank.productLabel) {
      text = tank.productLabel;
    }
    return (
      <div>
        <span>{text}</span>
      </div>
    );
  }

  static renderFullVolumeGallons(tank) {
    let text = 'Unknown volume';
    if (tank && tank.fullVolumeGallons) {
      text = `${tank.fullVolumeGallons.toLocaleString()} gallons `;
    }
    return (
      <div>
        <span>{text}</span>
      </div>
    );
  }

  static renderManifoldPartners(tank) {
    if (tank.manifoldPartners && tank.manifoldPartners.length) {
      const text = `Manifolds: ${tank.manifoldPartners.join(', ')}`;
      return (
        <div>
          <span>{text}</span>
        </div>
      );
    }
    return null;
  }

  render() {
    const style = {
      backgroundColor: 'transparent',
      cursor: this.props.canDrag ? 'move' : null,
      opacity: this.props.isDragging ? 0.5 : 1,
    };

    let classes = 'py-1 d-flex flex-row align-items-center';
    if (this.props.tank && this.props.tank.registration) {
      classes += ' text-muted';
    }

    return this.props.connectDragSource(
      <div style={style} className={classes}>
        <div style={{ width: 100 }}>
          {TankDragSource.renderIcon(this.props.tank)}
        </div>
        <div className="ml-2">
          <div>
            <strong>ATG Tank {this.props.tank.tankNumber} {this.props.isDragging}</strong>
          </div>
          {TankDragSource.renderProductLabel(this.props.tank)}
          {TankDragSource.renderFullVolumeGallons(this.props.tank)}
          {TankDragSource.renderManifoldPartners(this.props.tank)}
        </div>
      </div>
    );
  }
}

const tankSourceSpec = {
  beginDrag(props) {
    return props.tank;
  },
  canDrag(props) {
    return props.tank.registration === null || props.tank.registration === undefined;
  },
};

function sourceCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    canDrag: monitor.canDrag(),
  };
}

TankDragSource.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  canDrag: PropTypes.bool.isRequired,

  tank: PropTypes.object.isRequired,
};

TankDragSource.defaultProps = {
};

export default DragSource('TANK', tankSourceSpec, sourceCollect)(TankDragSource);
