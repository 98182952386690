import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HealthGauge from '../../../../../components/health-gauge/HealthGauge';
import * as actions from '../actions';
import * as selectors from '../InvDashboardSelectors';

class InvSite24HourGauge extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.props.getHourlyHistoryList();
  }

  render() {
    let gaugeValue = 0;
    if (this.props.hourlyHistoryList.length) {
      let healthyCount = 0;
      let unhealthyCount = 0;
      this.props.hourlyHistoryList.forEach((currentHour) => {
        healthyCount += currentHour.healthySiteCount;
        unhealthyCount += currentHour.unhealthySiteCount;
      });

      const totalCount = healthyCount + unhealthyCount;
      if (totalCount > 0) {
        gaugeValue = (healthyCount / totalCount) * 100;
      }
    }

    return (
      <HealthGauge
        label="Healthy Sites (24h)"
        value={gaugeValue}
        helpText="The percentage of sites where the inventory data included in the export was less than 10 minutes old at the time the export was generated.
          Only includes exports from within the past 24 hours."
      />
    );
  }
}

InvSite24HourGauge.propTypes = {
  hourlyHistoryList: PropTypes.array.isRequired,
  getHourlyHistoryList: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    hourlyHistoryList: selectors.hourlyHistoryList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHourlyHistoryList: () => dispatch(actions.getHourlyHistoryList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvSite24HourGauge);
