import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import update from 'immutability-helper';

import { track, withTracking } from '../../../../shared/analytics';
import ErrorMessage from '../../../../shared/components/error-message/ErrorMessage';
import SiteFields from '../../../../shared/components/site-fields/SiteFields';
import SiteLabelForm from '../../../../shared/components/site-fields/SiteLabelForm';
import * as actions from '../../actions';
import * as selectors from '../../SiteSelectors';

class SiteInfo extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleLabelsSave = this.handleLabelsSave.bind(this);
    this.handleNameLocationSave = this.handleNameLocationSave.bind(this);
  }

  handleNameLocationSave() {
    this.props.savePendingEdits();
    track('Site info', {
      Component: 'Name and location',
      'Site action': 'Save site',
    });
  }

  handleLabelsSave(newLabels) {
    const updatedSite = update(this.props.pendingEdits, {
      labels: {
        $set: newLabels,
      }
    });
    this.props.editSite(updatedSite);
    this.props.savePendingEdits();

    track('Site info', {
      Component: 'Labels',
      'Site action': 'Save labels',
    });
  }

  render() {
    if (!this.props.pendingEdits) {
      return null;
    }

    return (
      <div className="row mt-4">
        <div className="col-md-2">
          {this.renderNav()}
        </div>
        <div className="col-md-10">
          <ErrorMessage title="Failed to save site" error={this.props.error} />
          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderNav() {
    return (
      <div className="nav flex-column nav-pills">
        <NavLink to={`${this.props.match.url}/name`} className="nav-link">Name & Location</NavLink>
        <NavLink to={`${this.props.match.url}/labels`} className="nav-link">Labels</NavLink>
      </div>
    );
  }

  renderContent() {
    return (
      <Switch>
        <Redirect exact="exact" from={`${this.props.match.url}/`} to={`${this.props.match.url}/name`} />
        <Route exact path={`${this.props.match.url}/name`}>
          {this.renderNameAndLocation()}
        </Route>
        <Route exact path={`${this.props.match.url}/labels`}>
          {this.renderLabels()}
        </Route>
      </Switch>
    );
  }

  renderNameAndLocation() {
    const buttonDisabled = !!(this.props.pendingEditsPristine || !this.props.canEditSite || this.props.isSaving);
    return (
      <div>
        <SiteFields
          site={this.props.pendingEdits}
          showCustomerSelector={false}
          siteLabels={this.props.labels}
          onUpdate={this.props.editSite}
          disabled={!this.props.canEditSite}
          showEasyVistaId={!!(this.props.customerDispatchSystems.find(d => d.systemName === 'EasyVista'))}
        />
        <Button color="primary" size="sm" outline onClick={this.props.discardPendingEdits} disabled={buttonDisabled}>Cancel</Button>
        {' '}
        <Button color="primary" size="sm" onClick={this.handleNameLocationSave} disabled={buttonDisabled}>Save</Button>
      </div>
    );
  }

  renderLabels() {
    return (
      <SiteLabelForm
        customerLabels={this.props.labels}
        siteLabels={this.props.pendingEdits.labels}
        disabled={!this.props.canEditSite}
        onLabelsChanged={this.handleLabelsSave}
      />
    );
  }
}

SiteInfo.propTypes = {
  pendingEdits: PropTypes.object,
  pendingEditsPristine: PropTypes.bool.isRequired,
  canEditSite: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  error: PropTypes.object,
  labels: PropTypes.array.isRequired,
  editSite: PropTypes.func.isRequired,
  discardPendingEdits: PropTypes.func.isRequired,
  savePendingEdits: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  customerDispatchSystems: PropTypes.array.isRequired,
};

SiteInfo.defaultProps = {
  pendingEdits: null,
  error: null,
};

function mapStateToProps(state) {
  return {
    pendingEdits: selectors.pendingEdits(state),
    pendingEditsPristine: selectors.pendingSiteEditsPristine(state),
    canEditSite: selectors.canEditSite(state),
    isSaving: selectors.isSaving(state),
    error: selectors.saveError(state),
    labels: selectors.customerLabels(state),
    customerDispatchSystems: selectors.customerDispatchSystems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editSite: updatedSite => {
      track('Site info', {
        Component: 'Name and location',
        'Site action': 'Edit site',
      });
      return dispatch(actions.editSite(updatedSite));
    },
    savePendingEdits: () => dispatch(actions.savePendingEdits()),
    discardPendingEdits: () => dispatch(actions.discardPendingEdits()),
  };
}

export default withTracking('Site', 'Site Info')(withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteInfo)));
