export default {
  GET_ORGANIZATION_LIST_STARTED: 'organization-list/get_organization_list_started',
  RECEIVE_ORGANIZATION_LIST: 'organization-list/receive_organization_list',
  GET_ORGANIZATION_LIST_FAILED: 'organization-list/get_organization_list_failed',
  
  UPDATE_ORGANIZATION_STARTED: 'organization-list/update_organization_started',
  UPDATE_ORGANIZATION_SUCCESS: 'organization-list/update_organization_success',
  UPDATE_ORGANIZATION_FAILED: 'organization-list/update_organization_failed',
  
  DELETE_ORGANIZATION_STARTED: 'organization-list/delete_organization_started',
  DELETE_ORGANIZATION_SUCCESS: 'organization-list/delete_organization_success',
  DELETE_ORGANIZATION_FAILED: 'organization-list/delete_organization_failed',
};
