import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';

function renderListGroupItem(title, value) {
  if (!title || !value) {
    return null;
  }

  return (
    <ListGroupItem className="d-flex">
      <div className="flex-shrink-0" style={{ width: '8rem' }}>
        <strong>{title}</strong>
      </div>

      <span className="text-wrap">{value}</span>
    </ListGroupItem>
  );
}

const ErrorDetails = ({ ingestFailure }) => (
  <ListGroup>
    {renderListGroupItem('Id:', ingestFailure.id)}
    {renderListGroupItem('Subsystem:', ingestFailure.subsystem)}
    {renderListGroupItem('Date:', ingestFailure.created)}
    {renderListGroupItem('Source:', ingestFailure.source)}
    {renderListGroupItem('Description:', ingestFailure.description)}
    {renderListGroupItem('Customer:', ingestFailure.customerName)}
    {renderListGroupItem('Site name:', ingestFailure.siteName)}
    {renderListGroupItem('Site nickname:', ingestFailure.siteNickname)}
    {renderListGroupItem('Error type:', ingestFailure.exceptionDetails && ingestFailure.exceptionDetails.type)}
    {renderListGroupItem('Error message:', ingestFailure.exceptionDetails && ingestFailure.exceptionDetails.message)}
  </ListGroup>
);

ErrorDetails.propTypes = {
  ingestFailure: PropTypes.object,
};

ErrorDetails.defaultProps = {
  ingestFailure: null,
};

export default ErrorDetails;
