import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function getBarCount(signalStrength) {
  if (!signalStrength || signalStrength < -105) {
    return 0;
  }
  else if (signalStrength < -93) {
    return 1;
  }
  else if (signalStrength < -81) {
    return 2;
  }
  else if (signalStrength < -69) {
    return 3;
  }
  else if (signalStrength < -57) {
    return 4;
  }

  return 5;
}

function getActiveBarColor(barCount) {
  switch (barCount) {
    case 5:
    case 4:
      return '#5aa800';
    case 3:
      return '#f6e512';
    case 2:
      return '#ff8913';
    default:
      return '#d22835';
  }
}

function buildBarDiv(barNum, barCount) {
  const activeBarColor = getActiveBarColor(barCount);
  const style = {
    display: 'inline-block',
    height: `${barNum * 20}%`,
    width: '15%',
    backgroundColor: barCount >= barNum ? activeBarColor : 'rgba(0,0,0,0.1)',
    border: 'thin solid #f3f3f3',
    marginLeft: '1%',
  };

  return (<div style={style} />);
}

const SignalBars = ({ signalStrength, height, width }) => {
  const style = { display: 'inline', height: `${height}px`, width: `${width}px` };

  if (!signalStrength) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={style}>
      <FontAwesomeIcon className="text-muted" icon={faQuestionCircle} />
      </div>
    );
  }

  const barCount = getBarCount(signalStrength);
  return (
    <div className="d-flex justify-content-center align-items-end" style={style}>
      {buildBarDiv(1, barCount)}
      {buildBarDiv(2, barCount)}
      {buildBarDiv(3, barCount)}
      {buildBarDiv(4, barCount)}
      {buildBarDiv(5, barCount)}
    </div>
  );
};

SignalBars.propTypes = {
  signalStrength: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
};

SignalBars.defaultProps = {
  signalStrength: null,
  height: 50,
  width: 80,
};

export default SignalBars;
