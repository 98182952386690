/* eslint-disable no-use-before-define */
import { PresentAlert } from '../../../../../shared/ToastUtils';
import { IntentClient, LineClient, TankClient, ATGClient } from '../../../../../client';
import IntentActionTypes from '../CommandActionTypes';
import * as siteSelectors from '../../../SiteSelectors';
import * as selectors from '../CommandSelectors';

function receiveSiteLines(lines) {
  return {
    type: IntentActionTypes.RECEIVE_SITE_LINES,
    lines,
  };
}

export function getSiteLines() {
  return (dispatch, getState) => {
    const site = siteSelectors.currentSite(getState());
    if (!site) {
      return Promise.resolve();
    }
    return LineClient.getSiteLines(site.id).then(lines => dispatch(receiveSiteLines(lines)));
  };
}

function receiveSiteTanks(tanks) {
  return {
    type: IntentActionTypes.RECEIVE_SITE_TANKS,
    tanks,
  };
}

export function getSiteTanks() {
  return (dispatch, getState) => {
    const site = siteSelectors.currentSite(getState());
    if (!site) {
      return Promise.resolve();
    }
    return TankClient.getSiteTanks2(site.id).then(tanks => dispatch(receiveSiteTanks(tanks)));
  };
}

function intentRequestSent(intentRequest) {
  return {
    type: IntentActionTypes.INTENT_REQUEST_SENT,
    intentRequest,
  };
}

export function sendIntentRequest(siteId, intentName, args) {
  return dispatch => IntentClient.sendIntentRequest(siteId, intentName, args)
    .then((intentRequest) => {
      dispatch(intentRequestSent(intentRequest));
    })
    .catch((err) => {
      PresentAlert('Failed to complete your requested action.');
      return Promise.reject(err);
    });
}

export function getRecentCommandRequests() {
  return (dispatch, getState) => {
    const site = siteSelectors.currentSite(getState());

    if (!site) return Promise.resolve();

    dispatch({ type: IntentActionTypes.GET_COMMAND_REQUESTS_STARTED });

    return IntentClient.getRecentCommandRequests(site.id)
      .catch((err) => {
        PresentAlert('Failed to get recent reports from the site');

        dispatch({ type: IntentActionTypes.GET_COMMAND_REQUESTS_FAILED });

        return Promise.reject(err);
      })
      .then((requests) => {
        dispatch({
          type: IntentActionTypes.RECEIVE_COMMAND_REQUESTS,
          commandRequests: requests,
        });
      });
  };
}

export function getPendingIntentRequests() {
  return (dispatch, getState) => {
    const site = siteSelectors.currentSite(getState());

    if (!site) return Promise.resolve();

    dispatch({ type: IntentActionTypes.GET_INTENT_REQUESTS_STARTED });

    return IntentClient.getPendingIntentRequests(site.id)
      .catch((err) => {
        PresentAlert('Failed to get pending actions from the site');

        dispatch({ type: IntentActionTypes.GET_INTENT_REQUESTS_FAILED });

        return Promise.reject(err);
      })
      .then((requests) => {
        dispatch({
          type: IntentActionTypes.RECEIVE_INTENT_REQUESTS,
          intentRequests: requests,
        });
      });
  };
}

export function getAtgInfo() {
  return (dispatch, getState) => {
    const currentState = getState();
    const site = siteSelectors.currentSite(currentState);
    const atgInfoLoading = selectors.atgInfoLoading(currentState);

    if (!site || atgInfoLoading) {
      return Promise.resolve();
    }

    dispatch({ type: IntentActionTypes.GET_ATG_INFO_STARTED });

    return ATGClient.getAtgInfo(site.id)
      .then(atgInfo => dispatch({ type: IntentActionTypes.RECEIVE_ATG_INFO, atgInfo }))
      .catch(err => {
        dispatch({ type: IntentActionTypes.GET_ATG_INFO_FAILED });
        return Promise.reject(err);
      });
  };
}
