// TODO: break into namespaces

const notAllocated = 'N/A';
const allSitesAllocated = 'All';
const listedSitesAllocated = 'List';

const unsavedChangesWarning = 'Updates will be lost if you change customers or leave this page without pressing [Save].';
const updateEmailSuccess = 'Email updated. Please logout and log back in.';
const updatePasswordSuccess = 'Password updated. Please logout and back in.';
const passwordDoNotMatchWarning = 'Passwords do not match';
const passwordLengthWarning = 'Password must be at least 8 characters long';
const customerSitesWarning = 'Loading Sites failed. Site specific preferences cannot be updated at this time. Please contact Canary customer if problem continues';

const validEmailAddressPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const minimumPasswordLength = 8;

export const emptyArray = [];

export const defaultIssueUserNotificationRuleTemplateObject = Object.freeze({
  notifyWhen: {
    issueAnalysisFailed: false,
    issueAnalysisUpdated: true,
    issueComment: false,
    issueFile: false,
    alarmsCleared: false,
    issueCreated: false,
    issueReadyDraft: false,
    issueReady: true,
    issueResolved: false,
    issueDeleted: false,
    newAlarmsAdded: false,
  },
  notifyAbout: {
    leakDetectionIssues: {
      siteListType: allSitesAllocated,
      sites: [],
    },
    equipmentIssues: {
      siteListType: allSitesAllocated,
      sites: [],
    },
    atgConfigurationIssues: {
      siteListType: allSitesAllocated,
      sites: [],
    },
    techOnSiteIssues: {
      siteListType: allSitesAllocated,
      sites: [],
    },
    tankOverfillIssues: {
      siteListType: allSitesAllocated,
      sites: [],
    },
    tankWaterIssues: {
      siteListType: allSitesAllocated,
      sites: [],
    },
    otherIssues: {
      siteListType: allSitesAllocated,
      sites: [],
    },
  },
  transient: true,
});

export const disabledIssueNotificationRuleTemplateObject = Object.freeze({
  notifyWhen: {
    issueAnalysisFailed: false,
    issueAnalysisUpdated: false,
    issueComment: false,
    issueFile: false,
    alarmsCleared: false,
    issueCreated: false,
    issueReadyDraft: false,
    issueReady: false,
    issueResolved: false,
    issueDeleted: false,
    newAlarmsAdded: false,
  },
  notifyAbout: {
    leakDetectionIssues: {
      siteListType: notAllocated,
      sites: [],
    },
    equipmentIssues: {
      siteListType: notAllocated,
      sites: [],
    },
    atgConfigurationIssues: {
      siteListType: notAllocated,
      sites: [],
    },
    techOnSiteIssues: {
      siteListType: notAllocated,
      sites: [],
    },
    tankOverfillIssues: {
      siteListType: notAllocated,
      sites: [],
    },
    tankWaterIssues: {
      siteListType: notAllocated,
      sites: [],
    },
    otherIssues: {
      siteListType: notAllocated,
      sites: [],
    },
  },
  isDisabled: true,
});

export const customerSiteStatuses = Object.freeze({
  NotLoaded: 'NotLoaded',
  Loading: 'Loading',
  Loaded: 'Loaded',
  Failed: 'Failed',
});

export const routePaths = Object.freeze({
  AccountSettings: 'account-settings',
  IssueNotificationSettings: 'issue-notification-settings',
  LowProductNotificationSettings: 'low-product-notification-settings',
});

const mutableRoutePathSet = new Set();
Object.keys(routePaths).forEach(key => mutableRoutePathSet.add(routePaths[key]));
export const routePathSet = Object.freeze(mutableRoutePathSet);

export const notificationEntityTypes = Object.freeze({
  LowProduct: 'LowProduct',
  Issue: 'Issue',
});

export default Object.freeze({
  notAllocated,
  allSitesAllocated,
  listedSitesAllocated,
  emptyArray,
  unsavedChangesWarning,
  validEmailAddressPattern,
  updateEmailSuccess,
  updatePasswordSuccess,
  minimumPasswordLength,
  passwordDoNotMatchWarning,
  passwordLengthWarning,
  customerSiteStatuses,
  customerSitesWarning,
});
