/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { TextAlign } from './TableConstants';
import PropertyColumn from './PropertyColumn';
import { MonthYear } from '../date';

const renderMonthYear = value => <MonthYear time={value} />;

function MonthYearColumn({ title, property, rowData }) {
  return (
    <PropertyColumn
      title={title}
      property={property}
      render={renderMonthYear}
      rowData={rowData}
    />
  );
}

MonthYearColumn.propTypes = {
  sortable: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  textAlign: PropTypes.oneOf([TextAlign.Left, TextAlign.Right, TextAlign.Center]),
  property: PropTypes.string.isRequired,

  rowData: PropTypes.object,
};

MonthYearColumn.defaultProps = {
  sortable: false,
  title: null,
  width: null,
  textAlign: TextAlign.Left,
  rowData: null,
};

export default MonthYearColumn;
