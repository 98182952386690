import React from 'react';
import PropTypes from 'prop-types';

import { MonthYear } from '../../../../../../../shared/components/date';
import MviWorkflowStatusDropdown from '../../../../../../../shared/components/mvi/MviWorkflowStatusDropdown';
import MviWorkflowStatus from '../../../../../../../shared/components/mvi/MviWorkflowStatus';

const MviMonthStatus = ({ mvi, canEdit, editDisabled, onWorkflowStatusChange }) => (
  <div className="d-md-flex align-items-center">
    <h3 className="mb-2 mb-md-0 d-md-inline-block mr-3 text-uppercase">
      <MonthYear time={mvi.monthStartDate} />
      <span>&nbsp;Monthly Visual Inspection</span>
    </h3>
    { canEdit
      ? <MviWorkflowStatusDropdown workflowStatus={mvi.workflowStatus} disabled={editDisabled} onChange={onWorkflowStatusChange} />
      : <MviWorkflowStatus mviWorkflowStatus={mvi.workflowStatus} /> }
  </div>
);

MviMonthStatus.propTypes = {
  mvi: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  onWorkflowStatusChange: PropTypes.func.isRequired,
};

export default MviMonthStatus;
