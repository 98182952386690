import values from 'lodash/values';
import OrganizationEditActionTypes from '../../OrganizationEditActionTypes';

function buildOrgGrants(selectedOrganization) {
  if (!selectedOrganization || !selectedOrganization.policies || !selectedOrganization.policies.length) {
    return [];
  }

  const grantedByOrganization = {
    id: selectedOrganization.id,
    name: selectedOrganization.name,
  };

  const externalOrgsById = {};
  selectedOrganization.policies.forEach(policy => {
    if (policy.grantedToOrganizations && policy.grantedToOrganizations.length) {
      policy.grantedToOrganizations.forEach(externalOrg => {
        externalOrgsById[externalOrg.id] = externalOrgsById[externalOrg.id] || { grantedToOrganization: externalOrg };
        externalOrgsById[externalOrg.id].policies = externalOrgsById[externalOrg.id].policies || [];
        externalOrgsById[externalOrg.id].policies.push(policy);
        externalOrgsById[externalOrg.id].grantedByOrganization = grantedByOrganization;
      });
    }
  });
  return values(externalOrgsById);
}

export default function ExternalAccessReducer(state = {}, action) {
  switch (action.type) {
    case OrganizationEditActionTypes.RECEIVE_ORGANIZATION:
      return Object.assign({}, state, {
        orgGrants: buildOrgGrants(action.selectedOrganization),
        receivedOrgGrants: action.selectedOrganization.receivedPolicies,
      });
    case OrganizationEditActionTypes.SAVE_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        orgGrants: buildOrgGrants(action.selectedOrganization),
      });
    default:
      return state;
  }
}
