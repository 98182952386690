import { ATGClient } from '../../../../../../../client';
import * as selectors from '../../../../../SiteSelectors';
import ActionTypes from '../ATGActionTypes';

export function getAtgInfo() {
  return (dispatch, getState) => {
    const currentSite = selectors.currentSite(getState());
    if (!currentSite) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_ATG_INFO_STARTED });
    return ATGClient.getAtgInfo(currentSite.id)
      .then((atgInfo) => {
        dispatch({ type: ActionTypes.RECEIVE_ATG_INFO, atgInfo });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.GET_ATG_INFO_FAILED, error });
      });
  };
}

export function getAtgConfigEvents() {
  return (dispatch, getState) => {
    const currentSite = selectors.currentSite(getState());
    if (!currentSite) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_ATG_CONFIG_EVENTS_STARTED });
    return ATGClient.getAtgConfigEvents(currentSite.id)
      .then((events) => {
        dispatch({ type: ActionTypes.RECEIVE_ATG_CONFIG_EVENTS, events });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.GET_ATG_CONFIG_EVENTS_FAILED, error });
      });
  };
}
