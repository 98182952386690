import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import { connect } from 'react-redux';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import DeliveryType from '../../../../../components/export-destination/components/DeliveryType';
import { BooleanColumn, Column, StatefulTable, StringColumn } from '../../../../../../shared/components/table';
import FuelHaulerDeleteModal from '../components/FuelHaulerDeleteModal';
import FilesModal from '../../../components/FilesModal';
import * as actions from '../actions';
import * as selectors from '../FuelHaulersSelectors';

class FuelHaulerTable extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.state = {
      filesModalVisible: false,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.closeViewFilesModal = this.closeViewFilesModal.bind(this);

    this.props.getFuelHaulerList();
  }

  handleEditClick(fuelHauler) {
    this.props.navToFuelHauler(fuelHauler);
  }

  handlePreviewClick(fuelHauler) {
    this.props.downloadExportPreview(fuelHauler.id);
    this.setState({
      filesModalVisible: true,
    });
  }

  handleDeleteClick(fuelHauler) {
    this.setState({
      fuelHaulerToDelete: fuelHauler,
    });
  }

  confirmDelete() {
    return this.props.deleteFuelHauler(this.state.fuelHaulerToDelete).then(() => {
      this.closeDeleteModal();
    });
  }

  closeDeleteModal() {
    this.setState({
      fuelHaulerToDelete: null,
    });
  }

  closeViewFilesModal() {
    this.setState({
      filesModalVisible: false,
    });
  }

  render() {
    if (this.props.isLoading) {
      return (
        <PulseLoader color={'#F8E71C'} size={8} />
      );
    }

    return (
      <div>
        <Row>
          <Col xs={9}>
            <h5>Fuel Haulers</h5>
          </Col>
          <Col xs={3}>
            <div className="pull-right mb-1">
              <Button color="primary" size="sm" onClick={this.props.navToNewFuelHauler}>Add</Button>
            </div>
          </Col>
        </Row>
        <StatefulTable initialSortColumn="name" initialSortDirection="asc" data={this.props.fuelHaulerList}>
          <StringColumn property="id" title="ID" width="3rem" />
          <StringColumn property="name" title="Name" sortable />
          <Column render={fh => <DeliveryType deliveryUrl={fh.deliveryUrl} />} title="Type" width="5rem" />
          <BooleanColumn property="enabled" trueIcon={faCheck} title="Enabled" width="5rem" />
          <Column columnId="preview" render={r => <Button color="link" size="sm" onClick={() => this.handlePreviewClick(r)}>Preview</Button>} width="5rem" />
          <Column columnId="edit" render={r => <Button color="link" size="sm" onClick={() => this.handleEditClick(r)}>Edit</Button>} width="5rem" />
          <Column columnId="delete" render={r => <Button color="link" size="sm" onClick={() => this.handleDeleteClick(r)}>Delete</Button>} width="5rem" />
        </StatefulTable>

        <FuelHaulerDeleteModal
          fuelHauler={this.state.fuelHaulerToDelete}
          onDelete={this.confirmDelete}
          onCancel={this.closeDeleteModal}
        />
        <FilesModal isOpen={this.state.filesModalVisible} onClose={this.closeViewFilesModal} selectedExport={this.props.exportPreview} />
      </div>
    );
  }
}

FuelHaulerTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fuelHaulerList: PropTypes.array.isRequired,
  exportPreview: PropTypes.object,
  getFuelHaulerList: PropTypes.func.isRequired,
  navToFuelHauler: PropTypes.func.isRequired,
  navToNewFuelHauler: PropTypes.func.isRequired,
  downloadExportPreview: PropTypes.func.isRequired,
  deleteFuelHauler: PropTypes.func.isRequired,
};

FuelHaulerTable.defaultProps = {
  exportPreview: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    fuelHaulerList: selectors.fuelHaulerList(state),
    exportPreview: selectors.exportPreview(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFuelHaulerList: () => dispatch(actions.getFuelHaulerList()),
    navToFuelHauler: fuelHauler => dispatch(actions.navToFuelHauler(fuelHauler)),
    navToNewFuelHauler: () => dispatch(actions.navToNewFuelHauler()),
    downloadExportPreview: fuelHaulerId => dispatch(actions.downloadExportPreview(fuelHaulerId)),
    deleteFuelHauler: fuelHauler => dispatch(actions.deleteFuelHauler(fuelHauler)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelHaulerTable);
