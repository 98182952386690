import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from '../../../../../../../shared/components/compliance/StatusIcon';
import StatusText from '../../../../../../../shared/components/compliance/StatusText';

const renderTableRows = tanks => tanks.map(tank => (
  <tr key={tank.tankId}>
    <td>
      <b>
        {tank.tankNumber} - {tank.productLabel}
      </b>
    </td>
    <td>
      <StatusIcon size="1em" status={tank.status} />
      <StatusText status={tank.status} />
    </td>
  </tr>
));

const TanksTable = ({ tanks }) => {
  if (!tanks || tanks.length === 0) {
    return (
      <div style={{ textAlign: 'center' }} className="text-muted">
        Canary did not find any tanks to monitor at this station.
      </div>
    );
  }

  return (
    <table className="table canary-table borderless my-2">
      <thead>
        <tr>
          <th style={{ width: '20em' }}>Tank</th>
          <th style={{ width: '15em' }}>Status</th>
        </tr>
      </thead>
      <tbody>
        {renderTableRows(tanks)}
      </tbody>
    </table>
  );
};

TanksTable.propTypes = {
  tanks: PropTypes.arrayOf(
    PropTypes.shape({
      tankId: PropTypes.number,
      tankNumber: PropTypes.number,
      status: PropTypes.string,
      productLabel: PropTypes.string,
    }),
  ),
};

TanksTable.defaultProps = {
  tanks: [],
};

export default TanksTable;
