import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Row } from 'reactstrap';

import { registerProperty, unregisterProperty, withTracking } from '../../../../../../../shared/analytics';
import CanaryLoadingIndicator from '../../../../../../../shared/components/CanaryLoadingIndicator';
import { Features } from '../../../../../../../AppConstants';
import CreateDispatchModal from '../../../../dispatch/components/CreateDispatchModal';
import IssueDispatchActivity from '../components/dispatch/IssueDispatchActivity';
import AnalysisAndRec from '../components/analysis/AnalysisAndRec';
import IssueAlarms from '../components/alarms/IssueAlarms';
import IssueDocs from '../components/docs/IssueDocs';
import IssueName from '../components/IssueName';
import IssueNotFound from '../components/IssueNotFound';
import PriorityContainer from '../components/priority/PriorityContainer';
import IssueComments from '../components/comment/IssueComments';
import AddCommentForm from '../components/comment/AddCommentForm';
import AddCommentButton from '../components/comment/AddCommentButton';
import IssueExportModal from '../components/export/IssueExportModal';
import WorkflowConfirmModal from '../components/workflow/WorkflowConfirmModal';
import IssueActionButton from './IssueActionButton';
import AtgReportsAndToc from '../../../../../../../shared/components/atg-reports/AtgReportsAndToc';
import IssueOrganizerButton from '../../organizer/IssueOrganizerButton';
import IssueDocUploadButton from '../components/docs/IssueDocUploadButton';
import WorkflowStatus from '../../../../../../../shared/components/WorkflowStatus';
import IssueTitle from '../../../components/IssueTitle';
import DispatchDetails from '../../../../../components/DispatchDetails';
import IssueMviReports from '../components/mvi/IssueMviReports';
import * as SiteSelectors from '../../../../../SiteSelectors';
import * as IssueSelectors from '../IssueEditSelectors';
import * as actions from '../actions';

const IssueFeatureLevel = 'Issue feature level';

class Issue extends Component {
  componentDidMount() {
    registerProperty({ [IssueFeatureLevel]: this.props.featureLevel });
    const nextIssueId = this.props.match && this.props.match.params && this.props.match.params.issueId && Number.parseInt(this.props.match.params.issueId, 10);
    this.props.selectIssueById(nextIssueId).then(() => this.props.markAsRead);

    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const prevPath = prevProps.match && prevProps.match.url;
    const currentPath = this.props.match && this.props.match.url;
    if (prevPath !== currentPath) {
      const nextIssueId = this.props.match && this.props.match.params && this.props.match.params.issueId && Number.parseInt(this.props.match.params.issueId, 10);
      this.props.selectIssueById(nextIssueId).then(() => this.props.markAsRead);
    }
  }

  componentWillUnmount() {
    unregisterProperty(IssueFeatureLevel);
  }

  render() {
    if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }
    else if (!this.props.issue) {
      return <IssueNotFound />;
    }

    return (
      <div>
        <div className="mt-3 d-flex justify-content-between align-items-center flex-wrap-reverse">
          <div>
            <IssueTitle issue={this.props.issue} className="d-inline-block mr-2" />
            <div className="d-inline-block">
              <PriorityContainer className="d-inline-block" />
              <WorkflowStatus className="ml-1 d-inline-block" workflowStatus={this.props.issue.workflow.status} />
            </div>
          </div>
          <div className="flex-grow-1 d-flex justify-content-end align-items-center">
            <DispatchDetails issue={this.props.issue} className="d-inline-block mr-2" />
            <IssueActionButton issue={this.props.issue} right className="d-inline-block" />
          </div>
        </div>
        <Row className="mt-3">
          <Col md={8}>
            <IssueName />
          </Col>
          <Col md={4} className="d-flex justify-content-md-end">
            <IssueOrganizerButton />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <IssueAlarms issue={this.props.issue} />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={8}>
            <h6 className="text-uppercase">Analysis &amp; Troubleshooting</h6>
            <AnalysisAndRec className="border rounded-lg p-3" />
          </Col>
          <Col md={4}>
            <div className="d-flex justify-content-between align-items-baseline">
              <h6 className="text-uppercase d-inline-block mb-0">Documents</h6>
              <IssueDocUploadButton />
            </div>
            <IssueDocs className="border rounded-lg p-3" />
            <IssueMviReports className="mt-3" />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={8}>
            <AddCommentButton />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={8}>
            <AddCommentForm className="mb-4" />
            <IssueComments />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <IssueDispatchActivity />
          </Col>
        </Row>
        <AtgReportsAndToc className="mt-2" />
        <IssueExportModal />
        <CreateDispatchModal />
        <WorkflowConfirmModal />
      </div>
    );
  }
}

Issue.propTypes = {
  match: PropTypes.object.isRequired,
  selectIssueById: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  featureLevel: PropTypes.string.isRequired,

  issue: PropTypes.shape(({
    id: PropTypes.number.isRequired,
    workflow: PropTypes.shape({
      status: PropTypes.string.isRequired,
    })
  })),
  loading: PropTypes.bool,
};

Issue.defaultProps = {
  issue: null,
  loading: false,
};

function mapStateToProps(state) {
  return {
    featureLevel: SiteSelectors.featureLevel(state, Features.AlarmManagement),
    issue: IssueSelectors.currentIssue(state),
    loading: IssueSelectors.isLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectIssueById: issueId => dispatch(actions.selectIssueById(issueId)),
    markAsRead: issue => dispatch(actions.markAsRead(issue)),
  };
}

export default withTracking('Site', 'Issue')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Issue)));
