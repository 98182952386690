import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import update from 'immutability-helper';

import LabelCheckboxes from '../label/LabelCheckboxes';
import NewLabelModalButton from '../label/NewLabelModalButton';

class SiteLabelForm extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleLabelSelect = this.handleLabelSelect.bind(this);
    this.handleLabelDeselect = this.handleLabelDeselect.bind(this);
  }

  handleLabelSelect(newLabel) {
    const updatedLabels = update(this.props.siteLabels, {
      $push: [newLabel],
    });
    this.props.onLabelsChanged(updatedLabels);
  }

  handleLabelDeselect(label) {
    const selectedLabelIndex = this.props.siteLabels.findIndex(current => current.id === label.id);
    const updatedLabels = update(this.props.siteLabels, {
      $splice: [[selectedLabelIndex, 1]],
    });
    this.props.onLabelsChanged(updatedLabels);
  }

  render() {
    return (
      <Form>
        {this.renderHeader()}
        {this.renderLabelCheckboxes()}
        <NewLabelModalButton existingLabels={this.props.customerLabels} onLabelSave={this.handleLabelSelect} disabled={this.props.disabled} />
      </Form>
    );
  }

  renderHeader() {
    if (this.props.customerLabels.length) {
      return (<span className="d-block mb-2">Use the checkboxes below to select the labels you want to apply to this site:</span>);
    }
    return (<span className="d-block mb-2">There are no labels defined, use the button below to create a new label.</span>);
  }

  renderLabelCheckboxes() {
    return (
      <LabelCheckboxes
        selectedLabels={this.props.siteLabels}
        allLabels={this.props.customerLabels}
        onLabelSelect={this.handleLabelSelect}
        onLabelDeselect={this.handleLabelDeselect}
        disabled={this.props.disabled}
      />
    );
  }
}

SiteLabelForm.propTypes = {
  siteLabels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    labelText: PropTypes.string,
    color: PropTypes.string,
  })),
  customerLabels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    labelText: PropTypes.string,
    color: PropTypes.string,
  })),
  onLabelsChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SiteLabelForm.defaultProps = {
  siteLabels: [],
  customerLabels: [],
  disabled: false,
};

export default SiteLabelForm;
