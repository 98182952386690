import React from 'react';
import PropTypes from 'prop-types';

const ColumnStyles = Object.freeze({
  FIRST_COLUMN: 'firstColumn',
  NONTEXT: 'nonText',
  TEXT: 'text',
});

const RowStyles = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
});

const TableStyles = Object.freeze({
  RowStyles,
  ColumnStyles,
});

const firstColumnInHeaderStyle = Object.freeze({
  textAlign: 'left',
});

const firstColumnInBodyStyle = Object.freeze({
  textAlign: 'left',
});

const nonTextStyle = Object.freeze({
  textAlign: 'center',
});

const inactiveTextStyle = Object.freeze({
  backgroundColor: '#F7F5EF',
  color: '#CCC9C2',
});

const noStyle = Object.freeze({});


const getHeaderStyle = (columnStyle) => {
  if (ColumnStyles.FIRST_COLUMN === columnStyle) {
    return firstColumnInHeaderStyle;
  }
  else if (ColumnStyles.NONTEXT === columnStyle) {
    return nonTextStyle;
  }
  else {
    return noStyle;
  }
};

const getBodyColumnStyle = (columnStyle) => {
  if (ColumnStyles.FIRST_COLUMN === columnStyle) {
    return firstColumnInBodyStyle;
  }
  else if (ColumnStyles.NONTEXT === columnStyle) {
    return nonTextStyle;
  }
  else {
    return noStyle;
  }
};

const getRowStyle = (rowStyle) => (RowStyles.INACTIVE === rowStyle ? inactiveTextStyle : noStyle);


const isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
const noOpMethod = () => {};
const getOnClickMethod = (column) => (column.onClick !== undefined && isFunction(column.onClick) ? column.onClick : noOpMethod);

const getWidth = (column) => (column.width !== undefined ? column.width : undefined);

const CanaryTable = ({ header, data, keyPrefix }) => (
  <table className="table table-sm canary-table-2">
    <thead>
      <tr>
        { header.map((headerColumn, index) => (
          <th key={`${keyPrefix}-header-${index}`} width={getWidth(headerColumn)} style={getHeaderStyle(headerColumn.columnStyle)} onClick={getOnClickMethod(headerColumn)}>{headerColumn.display}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      { data.map((row, rowIndex) => (
        <tr key={`${keyPrefix}-body-${rowIndex}`} style={getRowStyle(row.metadata.rowStyle)}>
          { row.columns.map((column, columnIndex) => (
            <td key={`${keyPrefix}-${columnIndex}`} style={getBodyColumnStyle(column.columnStyle)} onClick={getOnClickMethod(column)}>{column.display}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

CanaryTable.propTypes = {
  header: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  keyPrefix: PropTypes.string.isRequired,
}

export {
  CanaryTable,
  TableStyles,
};
