import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTracking } from '../../../../../../shared/analytics';
import AtgReportsAndToc from '../../../../../../shared/components/atg-reports/AtgReportsAndToc';
import * as actions from './ComplianceReportActions';

class ComplianceAtgReports extends Component {
  componentDidMount() {
    this.props.getReportEvents();
  }

  render() {
    return <AtgReportsAndToc />;
  }
}

ComplianceAtgReports.propTypes = {
  getReportEvents: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

function mapDispatchToProps(dispatch) {
  return {
    getReportEvents: () => dispatch(actions.getReportEvents()),
  };
}

export default withTracking('Site', 'Compliance ATG Reports')(connect(mapStateToProps, mapDispatchToProps)(ComplianceAtgReports));
