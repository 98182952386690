export default {
  GET_SITE_STARTED: 'organization-admin/sites/get_site_started',
  GET_SITE_FAILED: 'organization-admin/sites/get_site_failed',
  RECEIVE_SITE: 'organization-admin/sites/receive_site',
  EDIT_SITE: 'organization-admin/sites/edit_site',
  DISCARD_PENDING_SITE_EDITS: 'organization-admin/sites/discard_pending_site_edits',

  SAVE_SITE_STARTED: 'organization-admin/sites/save_site_started',
  SAVE_SITE_SUCCESS: 'organization-admin/sites/save_site_success',
  SAVE_SITE_FAILED: 'organization-admin/sites/save_site_failed',

  GEOCODE_STARTED: 'organization-admin/sites/geocode_started',
  GEOCODE_RECEIVE_RESULTS: 'organization-admin/sites/geocode_receive_results',
  GEOCODE_RECEIVE_ERROR: 'organization-admin/sites/geocode_receive_error',
  GEOCODE_ACCEPT: 'organization-admin/sites/geocode_accept',
  GEOCODE_CANCEL: 'organization-admin/sites/geocode_cancel',

  EDIT_SITE_CONNECTION: 'organization-admin/sites/edit_site_connection',
  CANCEL_EDIT_SITE_CONNECTION: 'organization-admin/sites/cancel_edit_site_connection',
  SAVE_SITE_CONNECTION: 'organization-admin/sites/save_site_connection',
  UPDATE_SITE_CONNECTION: 'organization-admin/sites/update_site_connection',
  SAVE_SITE_CONNECTION_STARTED: 'organization-admin/sites/save_site_connection_started',
  SAVE_SITE_CONNECTION_SUCCESS: 'organization-admin/sites/save_site_connection_success',
  SAVE_SITE_CONNECTION_FAILED: 'organization-admin/sites/save_site_connection_failed',
  OPEN_DELETE_SITE_CONNECTION_MODAL: 'organization-admin/sites/open_delete_site_connection_modal',
  CLOSE_DELETE_SITE_CONNECTION_MODAL: 'organization-admin/sites/close_delete_site_connection_modal',
};
