import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import classNames from 'classnames';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';

import HelpIcon from '../../../../../components/help-icon/HelpIcon';
import * as actions from '../actions/index';
import * as selectors from '../InvDashboardSelectors';
import * as ChartUtils from '../../../../../components/ChartUtils';

class ExportCountByHour extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      showHelp: false,
    };
    this.toggleHelp = this.toggleHelp.bind(this);
    this.props.getHourlyHistoryList();
  }

  toggleHelp() {
    this.setState({
      showHelp: !this.state.showHelp,
    });
  }

  buildChartParams() {
    const xAxisLabels = this.props.hourlyHistoryList.map(current => ChartUtils.formatHour(current.hour));
    const yAxisData = this.props.hourlyHistoryList.map(current => current.exportCount);

    return {
      labels: xAxisLabels,
      datasets: [
        ChartUtils.buildLineChartDataset('Export count', ChartUtils.RgbColors.Green, yAxisData),
      ],
    };
  }

  render() {
    if (!this.props.hourlyHistoryList && this.props.isLoading) {
      return (<PulseLoader color={'#F8E71C'} size={8} />);
    }
    else if (!this.props.hourlyHistoryList || !this.props.hourlyHistoryList.length) {
      return (
        <div>
          <h5>Export Count by Hour</h5>
          <span className="text-muted">No data available</span>
        </div>
      );
    }

    return (
      <div>
        <div className="text-md-center">
          <h5 style={{ display: 'inline' }}>Export Count by Hour</h5>
          <HelpIcon onClick={this.toggleHelp} className="ml-1" />
        </div>
        <Alert
          color="info"
          className="mb-1 small"
          isOpen={this.state.showHelp}
          toggle={this.toggleHelp}
        >
          This graph shows the number of export files that were generated during each hour.
        </Alert>
        <div className={classNames({ 'hidden-xs-up': this.state.showHelp })}>
          <Line
            data={this.buildChartParams()}
            width={100}
            height={200}
            options={ChartUtils.LineChartOptions}
            legend={{ position: 'bottom' }}
          />
        </div>
      </div>
    );
  }
}

ExportCountByHour.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hourlyHistoryList: PropTypes.array.isRequired,

  getHourlyHistoryList: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isHourlyHistoryLoading(state),
    hourlyHistoryList: selectors.hourlyHistoryList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHourlyHistoryList: () => dispatch(actions.getHourlyHistoryList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportCountByHour);
