export function currentSiteSensors(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.sensors && state.sites.equipment.sensors.currentSiteSensors) || [];
}

export function tanks(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.sensors && state.sites.equipment.sensors.tanks) || [];
}

export function sensorsLoading(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.sensors && state.sites.equipment.sensors.sensorsLoading) || false;
}

export function modalOpen(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.sensors && state.sites.equipment.sensors.modalOpen) || false;
}

export function isError(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.sensors && state.sites.equipment.sensors.isError) || false;
}

export function editSensor(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.sensors && state.sites.equipment.sensors.editSensor) || {};
}

export function error(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.sensors && state.sites.equipment.sensors.error) || {};
}
