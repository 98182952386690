import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { track } from '../../../../../../../../shared/analytics';
import CommentInput from '../../../../../../../../shared/components/comment/CommentInput';
import selectors from './CommentSelectors';
import * as actions from './CommentActions';

const CommentEdit = ({ className, pendingEdits, isEditing, isSaving, editCommentText, cancelCommentEdit, saveComment }) => (
  <CommentInput
    commentText={(pendingEdits && pendingEdits.commentText) || ''}
    onCommentTextChange={editCommentText}
    onSave={saveComment}
    onCancel={cancelCommentEdit}
    visible={isEditing}
    saving={isSaving}
    className={className}
  />
);

CommentEdit.propTypes = {
  className: PropTypes.string,
  pendingEdits: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  editCommentText: PropTypes.func.isRequired,
  cancelCommentEdit: PropTypes.func.isRequired,
  saveComment: PropTypes.func.isRequired,
};

CommentEdit.defaultProps = {
  className: null,
  pendingEdits: null,
};

function mapStateToProps(state) {
  return {
    pendingEdits: selectors.pendingEdits(state),
    isEditing: selectors.isEditing(state),
    isSaving: selectors.isSaving(state),
    editCommentText: PropTypes.func.isRequired,
    cancelCommentEdit: PropTypes.func.isRequired,
    saveComment: PropTypes.func.isRequired,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editCommentText: commentText => dispatch(actions.editCommentText(commentText)),
    cancelCommentEdit: () => dispatch(actions.cancelCommentEdit()),
    saveComment: () => {
      track('Inspection', {
        Component: 'Comment',
        'Inspection action': 'Save comment',
      });
      return dispatch(actions.saveComment());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentEdit);
