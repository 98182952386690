import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { withTracking } from '../../../shared/analytics';
import AlarmStatus from '../screens/alarms/containers/AlarmStatus';
import LatestComplianceSummary from '../screens/compliance/containers/LatestComplianceSummary';
import DeliveryNeededTanks from '../screens/inventory/containers/DeliveryNeededTanks';
import CreateDispatchModal from '../screens/dispatch/components/CreateDispatchModal';
import IssueCards from '../screens/issues/components/IssueCards';
import IssueExportModal from '../screens/issues/screens/issue-edit/components/export/IssueExportModal';
import MviOverview from '../screens/mvi/containers/MviOverview';
import WorkflowConfirmModal from '../screens/issues/screens/issue-edit/components/workflow/WorkflowConfirmModal';
import * as selectors from '../SiteSelectors';

class SiteOverview extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { canViewIssues, canEditIssues, canViewCompliance, canViewInventory, canViewMvis } = this.props;

    if (!canViewInventory && !canViewIssues && !canViewCompliance && !canViewMvis) {
      return (
        <Row>
          <Col md={8}>
            <AlarmStatus className="my-3" />
          </Col>
        </Row>
      );
    }
    else if (canViewInventory && !canViewIssues && !canViewCompliance && !canViewMvis) {
      return (
        <Row>
          <Col md={8}>
            <DeliveryNeededTanks className="my-3" />
            <AlarmStatus className="my-3" />
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col md={8}>
          {canViewIssues ? <IssueCards header link className="my-3" /> : null}
          {canViewIssues ? <IssueExportModal /> : null}
          {canEditIssues ? <CreateDispatchModal /> : null}
          {canEditIssues ? <WorkflowConfirmModal /> : null }
          <AlarmStatus className="my-3" />
        </Col>
        <Col md={4}>
          {canViewCompliance ? <LatestComplianceSummary className="my-3" /> : null}
          {canViewMvis ? <MviOverview className="my-3" /> : null}
          {canViewInventory ? <DeliveryNeededTanks className="my-3" /> : null}
        </Col>
      </Row>
    );
  }
}

SiteOverview.propTypes = {
  canViewIssues: PropTypes.bool.isRequired,
  canEditIssues: PropTypes.bool.isRequired,
  canViewCompliance: PropTypes.bool.isRequired,
  canViewInventory: PropTypes.bool.isRequired,
  canViewMvis: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    canViewIssues: selectors.canViewIssues(state),
    canEditIssues: selectors.canViewIssues(state),
    canViewCompliance: selectors.canViewCompliance(state),
    canViewInventory: selectors.canViewInventory(state),
    canViewMvis: selectors.canViewMvis(state),
  };
}

export default withTracking('Site', 'Overview')(connect(mapStateToProps)(SiteOverview));
