import { AtgDialect } from './CommandConstants';

const commandsRoot = (state) => (state && state.sites.commands) || {};

export function siteLines(state) {
  return commandsRoot(state).lines || [];
}

export function siteTanks(state) {
  return commandsRoot(state).tanks || [];
}

export function isRecentCommandRequestsLoading(state) {
  return commandsRoot(state).recentCommandRequestsLoading || false;
}

export function recentCommandRequests(state) {
  return commandsRoot(state).recentCommandRequests || [];
}

export function pendingIntents(state) {
  return commandsRoot(state).pendingIntents || [];
}

export function atgDialect(state) {
  return commandsRoot(state).atgDialect || AtgDialect.Unknown;
}

export function atgInfoLoading(state) {
  return commandsRoot(state).atgInfoLoading || false;
}
