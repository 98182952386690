import React from 'react';
import PropTypes from 'prop-types';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';

import FontAwesomeButton from './FontAwesomeButton';

function EditButton({ size, className, onClick, disabled }) {
  return (
    <FontAwesomeButton
      icon={faPencilAlt}
      size={size}
      className={className}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

EditButton.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

EditButton.defaultProps = {
  size: '1x',
  className: null,
  disabled: false,
};

export default EditButton;
