import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import LabelModal from './LabelModal';

class NewLabelModalButton extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.toggle = this.toggle.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.state = {
      modalOpen: false,
    };
  }

  toggle() {
    this.setState(currentState => {
      if (currentState.modalOpen) {
        return {
          modalOpen: false,
        };
      }
      return {
        modalOpen: true,
        label: undefined,
      };
    });
  }

  handleLabelChange(label) {
    this.setState({ label });
  }

  handleSave(newLabel) {
    this.props.onLabelSave(newLabel);
    this.toggle();
  }

  render() {
    return (
      <div className="d-inline">
        <Button size="sm" color="primary" onClick={this.toggle} disabled={this.props.disabled}>New Label</Button>
        <LabelModal
          isOpen={this.state.modalOpen}
          label={this.state.label}
          existingLabels={this.props.existingLabels}
          onLabelChange={this.handleLabelChange}
          onLabelSave={this.handleSave}
          onCancel={this.toggle}
        />
      </div>
    );
  }
}

NewLabelModalButton.propTypes = {
  onLabelSave: PropTypes.func.isRequired,
  existingLabels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    labelText: PropTypes.string,
    color: PropTypes.string,
  })),
  disabled: PropTypes.bool,
};

NewLabelModalButton.defaultProps = {
  disabled: false,
  existingLabels: null,
};

export default NewLabelModalButton;
