import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import find from 'lodash/find';

const FilterMultiselect = ({ allItems, selectedItems, textField, placeholder, onItemsSelected, disabled }) => {
  const selectedItemIds = selectedItems.map(current => current.id);
  const availableItems = allItems.filter(current => selectedItemIds.indexOf(current.id) === -1);

  const valueOf = item => item.id;
  const textOf = item => item[textField];
  const onChange = e => {
    // eslint-disable-next-line eqeqeq
    const item = find(allItems, current => valueOf(current) == e.target.value);
    onItemsSelected([item]);
  };

  return (
    <Input id="filterType" name="filterType" type="select" value="" bsSize="sm" onChange={onChange} disabled={disabled}>
      <option className="text-muted">{placeholder}</option>
      {availableItems.map(item => (
        <option key={valueOf(item)} value={valueOf(item)}>
          {textOf(item)}
        </option>
      ))}
    </Input>
  );
};

FilterMultiselect.propTypes = {
  allItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })),
  placeholder: PropTypes.string,
  textField: PropTypes.string.isRequired,
  onItemsSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FilterMultiselect.defaultProps = {
  selectedItems: [],
  placeholder: 'Select...',
  disabled: false,
};

export default FilterMultiselect;
