import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserCog } from '@fortawesome/free-solid-svg-icons';

import { UserNavType } from '../AppConstants';
import OrganizationPermissions from '../organization-permissions';
import { HasPermissionAnySite, AuthenticationActions, AuthenticationSelectors } from '../authentication';
import * as NavContextSelectors from '../shared/components/nav-context/NavContextSelectors';
import { SitesActions } from './sites';
import { redirectToPreferences } from './preferences/actions';
import CanaryLogo from '../shared/components/logo/CanaryLogo';
import ZendeskHelp from '../shared/components/ZendeskHelp';
import SingleSiteInput from '../shared/components/site-picker/containers/SingleSiteInput';

const InitialState = {
  dropdownOpen: false,
  collapseOpen: false,
};

class UserNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
    this.toggle = this.toggle.bind(this);
    this.isSiteLinkActive = this.isSiteLinkActive.bind(this);
    this.isIssueLinkActive = this.buildIsLinkActiveFunction(UserNavType.IssueList);
    this.isComplianceLinkActive = this.buildIsLinkActiveFunction(UserNavType.ComplianceList);
    this.isMviLinkActive = this.buildIsLinkActiveFunction(UserNavType.MviList);
    this.isInventoryLinkActive = this.buildIsLinkActiveFunction(UserNavType.InventoryList);
    this.handleNavToSiteProfile = this.handleNavToSiteProfile.bind(this);
    this.collapseToggle = this.collapseToggle.bind(this);
    this.goToAdmin = this.goToAdmin.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState(InitialState);
    }
  }

  toggle() {
    this.setState(currentState => ({
      dropdownOpen: !currentState.dropdownOpen,
    }));
  }

  collapseToggle() {
    this.setState(currentState => ({
      collapseOpen: !currentState.collapseOpen,
    }));
  }

  isSiteLinkActive(match) {
    if (this.props.originType && this.props.originType !== UserNavType.SiteList) {
      return false;
    }
    return !!match;
  }

  buildIsLinkActiveFunction(originType) {
    return (match) => !!match || this.props.originType === originType;
  }

  handleNavToSiteProfile(site) {
    this.props.navToSite(site.id);
  }

  goToAdmin() {
    this.props.history.push('/admin');
  }

  renderSearch() {
    return (
      <Nav id="searchNav" navbar>
        <NavItem className="w-100">
          <SingleSiteInput
            id="navbarSiteSearch"
            onSiteSelect={this.handleNavToSiteProfile}
            groupByCustomer
            hideSearchButton
            hideNoSiteSelectedText
          />
        </NavItem>
      </Nav>
    );
  }

  render() {
    return (
      <Navbar light className="border-bottom bg-gray-100" expand="lg">
        <div className="container-fluid">
          <NavbarBrand to="/" href="/" className="clearfix">
            <CanaryLogo height={32} width={32} />
          </NavbarBrand>
          <Collapse isOpen={this.state.collapseOpen} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink to="/sites" className="nav-link" isActive={this.isSiteLinkActive}>Sites</NavLink>
              </NavItem>
              <HasPermissionAnySite permission={OrganizationPermissions.IssueView}>
                <NavItem>
                  <NavLink to="/issues" className="nav-link" isActive={this.isIssueLinkActive}>Issues</NavLink>
                </NavItem>
              </HasPermissionAnySite>
              <HasPermissionAnySite permission={OrganizationPermissions.ComplianceView}>
                <NavItem>
                  <NavLink to="/monthly-compliance" className="nav-link" isActive={this.isComplianceLinkActive}>Compliance</NavLink>
                </NavItem>
              </HasPermissionAnySite>
              <HasPermissionAnySite permission={OrganizationPermissions.MVIView}>
                <NavItem>
                  <NavLink to="/inspections" className="nav-link" isActive={this.isMviLinkActive}>Inspections</NavLink>
                </NavItem>
              </HasPermissionAnySite>
              <HasPermissionAnySite permission={OrganizationPermissions.InventoryView}>
                <NavItem>
                  <NavLink to="/inventory" className="nav-link" isActive={this.isInventoryLinkActive}>Inventory</NavLink>
                </NavItem>
              </HasPermissionAnySite>
            </Nav>
          </Collapse>
          {this.renderSearch()}
          <Collapse isOpen={this.state.collapseOpen} navbar>
            <Nav navbar className="ml-auto">
              <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} nav inNavbar>
                <DropdownToggle className="nav-link" color="link" tag="a">
                  <FontAwesomeIcon title="Settings" role="alert" icon={faUserCog} />
                  <span className="d-lg-none ml-2">User Settings</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg-right">
                  <DropdownItem className="btn-sm py-1" disabled>
                    <strong>
                      <FontAwesomeIcon title="User" role="alert" className="mr-2" icon={faUser} />
                      {this.props.username}
                    </strong>
                  </DropdownItem>
                  <DropdownItem divider />
                  {this.props.isAdmin
                    ? <DropdownItem className="btn-sm py-1" onClick={this.goToAdmin}>Go to Admin Site</DropdownItem>
                    : null}
                  <DropdownItem className="btn-sm py-1" onClick={() => this.props.redirectToPreferences()}>My Settings</DropdownItem>
                  <HasPermissionAnySite permission={OrganizationPermissions.SiteList}>
                    <DropdownItem className="btn-sm py-1" onClick={() => this.props.history.push('/exports')}>Report Center</DropdownItem>
                  </HasPermissionAnySite>
                  <DropdownItem className="btn-sm py-1" onClick={() => this.props.logout()}>Sign out</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
          <NavbarToggler onClick={this.collapseToggle} />
        </div>
      </Navbar>
    );
  }
}

UserNavbar.propTypes = {
  history: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  username: PropTypes.string,
  originType: PropTypes.string,
  location: PropTypes.object.isRequired,

  logout: PropTypes.func.isRequired,
  navToSite: PropTypes.func.isRequired,
  redirectToPreferences: PropTypes.func.isRequired,
};

UserNavbar.defaultProps = {
  isAdmin: false,
  username: null,
  originType: null,
};

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(AuthenticationActions.logout()),
    navToSite: (siteId) => dispatch(SitesActions.navToSite(siteId)),
    redirectToPreferences: () => dispatch(redirectToPreferences()),
  };
}

function mapStateToProps(state) {
  return {
    isAdmin: AuthenticationSelectors.isAdmin(state),
    username: AuthenticationSelectors.currentUsername(state),
    originType: NavContextSelectors.originType(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserNavbar));
