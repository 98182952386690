import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Input } from 'reactstrap';

function UserList(props) {
  const selectedUserIds = props.selectedUsers.map(current => current.id);
  const handleClick = (event, user) => {
    if (event.target.checked) {
      props.onUserCheck(user);
    }
    else {
      props.onUserUncheck(user);
    }
  };
  return (
    <Form>
      {props.allUsers.map(current => (
        <FormGroup check key={`${current.id}`}>
          <Label check>
            <Input
              type="checkbox"
              checked={selectedUserIds.indexOf(current.id) !== -1}
              onChange={e => handleClick(e, current)}
            />
            {' '}
            {current.username}
          </Label>
        </FormGroup>
      ))}
    </Form>
  );
}

UserList.propTypes = {
  allUsers: PropTypes.array,
  selectedUsers: PropTypes.array,
  onUserCheck: PropTypes.func.isRequired,
  onUserUncheck: PropTypes.func.isRequired,
};

UserList.defaultProps = {
  allUsers: [],
  selectedUsers: [],

};

export default UserList;
