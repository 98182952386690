import { Get, Patch, Post } from '../HttpUtils';

export default {
  getFolders(site) {
    const siteId = site.id || site;
    return Get(`/v2/sites/${siteId}/folders`).then(response => response.data);
  },

  createDocument(doc) {
    return Post(`/v2/sites/${doc.siteId}/documents`, doc).then(response => response.data);
  },

  updateDocument(site, doc) {
    const siteId = site.id || site;
    return Patch(`/v2/sites/${siteId}/documents/${doc.id}`, doc).then(response => response.data);
  },

  getDocumentLink(site, document) {
    const siteId = site.id || site;
    const docId = document.id || document;
    return Get(`/v2/sites/${siteId}/documents/${docId}/link`).then(response => response.data.url);
  },

  bulk(site, documentsToDelete) {
    const siteId = site.id || site;
    const deleteIds = documentsToDelete.map(current => current.id || current);
    const body = { deleteIds };
    return Post(`/v2/sites/${siteId}/documents/bulk`, body).then(response => response.data);
  },

  download(site, folders = [], documents = []) {
    const siteId = site.id || site;
    const folderIds = folders.map(current => current.id || current);
    const documentIds = documents.map(current => current.id || current);
    const body = { folderIds, documentIds };
    return Post(`/v2/sites/${siteId}/folders/download`, body).then(response => response.data.url);
  }
};
