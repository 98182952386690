import React from 'react';

const IssueNotFound = () => (
  <div>
    <h1>Issue not visible</h1>
    <span>This issue does not exist or all of the alarms within this issue have been moved to other issues.</span>
  </div>
);

export default IssueNotFound;
