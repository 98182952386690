import reduceReducers from 'reduce-reducers';
import { buildReducer, DefaultState } from '../../shared/redux-helpers/ReducerFactory';
import { buildTableReducer } from '../../shared/components/table/redux/TableReducerFactory';
import { buildViewManagerReducer } from '../../shared/components/table/view-manager/ViewManagerReducerFactory';
import { SortDirection } from '../../AppConstants';
import { DefaultTableColumns, ComplianceTableColumns } from './ComplianceConstants';
import ActionTypes from './ComplianceActionTypes';

const ComplianceListState = Object.assign({}, DefaultState, {
  defaultSortColumnId: ComplianceTableColumns.SiteNickname.id,
  defaultSortDirection: SortDirection.Asc,
  pageSize: 500,
});

export default reduceReducers(
  ComplianceListState,
  buildReducer(ActionTypes),
  buildTableReducer(ActionTypes, ComplianceTableColumns),
  buildViewManagerReducer(ActionTypes, ComplianceTableColumns, DefaultTableColumns)
);
