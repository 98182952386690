import { Get } from '../HttpUtils';

export default {
  getList() {
    return Get('/v2/customers').then(response => response.data);
  },
  getCustomerLabels(customerId) {
    return Get(`/v2/organizations/${customerId}/customer/labels`).then(response => response.data);
  },
  getLabels() {
    return Get('/v2/customers/labels').then(response => response.data);
  }
};
