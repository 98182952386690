export default {
  RESET: 'sites/documents/upload/reset',
  START: 'sites/documents/upload/start',
  NEXT_STEP: 'sites/documents/upload/next_step',

  SET_FILES: 'sites/documents/upload/set_files',
  SET_DESTINATION_FOLDER: 'sites/documents/upload/set_destination_folder',
  TOGGLE_ISSUE_SELECT: 'sites/documents/upload/toggle_issue_select',

  CREATE_DOC_STARTED: 'sites/documents/upload/create_doc_started',
  CREATE_DOC_FAILED: 'sites/documents/upload/create_doc_failed',
  CREATE_DOC_SUCCESS: 'sites/documents/upload/create_doc_succeeded',
};
