import { createSocketMiddleware } from '../../../../../SocketMiddleware';
import { addDeviceActivation, removeDeviceActivation } from './actions/index';

const socketMiddleware = createSocketMiddleware({
  deviceActivationCreated: (activation, dispatch) => dispatch(addDeviceActivation(activation)),
  deviceActivationDeleted: (activation, dispatch) => dispatch(removeDeviceActivation(activation)),
});

export default {
  socketMiddleware,
};
