/* eslint-disable import/prefer-default-export */
import { OrganizationClient } from '../../../../client';
import ActionTypes from '../OrganizationDropdownActionTypes';
import * as selectors from '../OrganizationDropdownSelectors';

function receiveOrganizationList(organizationList) {
  return {
    type: ActionTypes.RECEIVE_ORGANIZATION_LIST,
    organizationList,
  };
}

export function getOrganizations() {
  return (dispatch, getState) => {
    const isLoading = selectors.isOrganizationListLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch({type: ActionTypes.GET_ORGANIZATION_LIST_STARTED});
    return OrganizationClient.getList()
      .then(organizationList => dispatch(receiveOrganizationList(organizationList)))
      .catch((err) => {
        dispatch({type: ActionTypes.GET_ORGANIZATION_LIST_FAILED});
        return Promise.reject(err);
      });
  };
}
