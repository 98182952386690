import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

class PendingIntent extends Component {
  render() {
    return (
      <div className="card" style={{ backgroundColor: '#fff' }}>
        <div className="d-inline-block m-1">
          <PulseLoader color={'#F8E71C'} size={8} />
        </div> {this.props.intent.taskDescription}...
      </div>
    );
  }
}

PendingIntent.propTypes = {
  intent: PropTypes.object.isRequired,
};

export default PendingIntent;
