import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

import GeocodeButton from '../containers/GeocodeButton';

class LatLon extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleLatChange = this.handleLatChange.bind(this);
    this.handleLonChange = this.handleLonChange.bind(this);
  }

  handleLatChange(event) {
    const newLat = event.target.value;
    const existingLon = this.props.selectedSite.location && this.props.selectedSite.location.lon;
    this.setLanLon(newLat, existingLon);
  }

  handleLonChange(event) {
    const existingLat = this.props.selectedSite.location && this.props.selectedSite.location.lat;
    const newLon = event.target.value;
    this.setLanLon(existingLat, newLon);
  }

  setLanLon(lat, lon) {
    const updatedSite = update(this.props.selectedSite, {
      location: {
        $set: {
          lat, lon
        }
      }
    });
    this.props.onUpdate(updatedSite);
  }

  render() {
    if (!this.props.selectedSite) {
      return null;
    }

    return (
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="latitude">Latitude</Label>
            <Input
              type="text"
              id="latitude"
              name="latitude"
              placeholder=""
              value={(this.props.selectedSite.location && this.props.selectedSite.location.lat) || ''}
              onChange={this.handleLatChange}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="longitude">Longitude</Label>
            <Input
              type="text"
              id="longitude"
              name="longitude"
              placeholder=""
              value={(this.props.selectedSite.location && this.props.selectedSite.location.lon) || ''}
              onChange={this.handleLonChange}
            />
          </FormGroup>
        </Col>
        <Col md={4} className="d-flex align-items-md-end">
          <GeocodeButton className="mb-0 mb-md-3" />
        </Col>
      </Row>
    );
  }
}

LatLon.propTypes = {
  selectedSite: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    })
  }),
  onUpdate: PropTypes.func.isRequired,
};

LatLon.defaultProps = {
  selectedSite: null,
};

export default LatLon;
