import {Get, Post} from '../HttpUtils';

export default {
  create(siteId, dispatchDetails) {
    return Post(`/v2/sites/${siteId}/dispatches`, dispatchDetails).then(response => Promise.resolve(response.data));
  },

  getSiteDispatches(siteId) {
    return Get(`/v2/sites/${siteId}/dispatches`).then(response => Promise.resolve(response.data));
  },
};
