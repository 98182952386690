import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Input } from 'reactstrap';

import { track } from '../../../../../../../../shared/analytics';
import * as SiteSelectors from '../../../../../../SiteSelectors';
import * as IssueActions from '../../actions';
import * as IssueSelectors from '../../IssueEditSelectors';
import UserDate from '../../../../../../../../shared/components/UserDate';
import ReadOnlyAnalysisRec from './ReadOnlyAnalysisRec';
import AnalysisAndRecHistoryButton from './AnalysisAndRecHistoryButton';
import EditLinkButton from '../../../../../../../../shared/components/buttons/EditLinkButton';

const newlineRegex = /\r?\n/g;

function lineCount(value) {
  if (!value) {
    return 0;
  }
  return value.split(newlineRegex).length;
}

class AnalysisAndRecommendations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.analysisTextChanged = this.analysisTextChanged.bind(this);
    this.recommendationTextChanged = this.recommendationTextChanged.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  handleEditClick() {
    track('Issue', {
      Component: 'Issue analysis',
      'Issue action': 'Edit analysis',
      'Issue type': this.props.issue.issueType,
    });
    this.setState({
      editing: true,
      analysis: (this.props.issue.analysis && this.props.issue.analysis.analysis) || '',
      recommendations: (this.props.issue.analysis && this.props.issue.analysis.recommendations) || '',
      editStart: Date.now(),
    });
  }

  analysisTextChanged(e) {
    this.setState({
      analysis: e.target.value,
    });
  }

  recommendationTextChanged(e) {
    this.setState({
      recommendations: e.target.value,
    });
  }

  handleCancelClick() {
    this.setState({
      editing: false,
    });
  }

  handleSaveClick() {
    track('Issue', {
      Component: 'Issue analysis',
      'Issue action': 'Save analysis',
      'Issue type': this.props.issue.issueType,
      'Edit duration': (Date.now() - this.state.editStart) / 1000,
    });
    this.props.updateAnalysisRec(this.state.analysis, this.state.recommendations)
      .then(() => {
        this.setState({
          editing: false,
        });
      });
  }

  render() {
    if (this.state.editing) {
      return (
        <div className={this.props.className}>
          {this.renderEditForm()}
          <div className="d-flex">
            <Button color="primary" size="sm" outline className="ml-auto mr-2" onClick={this.handleCancelClick} disabled={this.props.isSaving}>Cancel</Button>
            <Button color="primary" size="sm" onClick={this.handleSaveClick} disabled={this.props.isSaving}>Save</Button>
          </div>
        </div>
      );
    }

    const user = this.props.issue.analysis && this.props.issue.analysis.user;
    const analysis = this.props.issue.analysis && this.props.issue.analysis.analysis;
    const recommendations = this.props.issue.analysis && this.props.issue.analysis.recommendations;
    return (
      <div className={this.props.className}>
        <div className="d-flex">
          {user
            ? <UserDate user={user} date={this.props.issue.analysis.created} timezone={this.props.site.siteTimezone} />
            : null}
          <AnalysisAndRecHistoryButton site={this.props.site} issue={this.props.issue} className="ml-3" />
          {this.props.canEditIssue ? <EditLinkButton onClick={this.handleEditClick} className="ml-auto" /> : null}
        </div>

        <ReadOnlyAnalysisRec analysis={analysis} recommendations={recommendations} className="mt-3" />
      </div>
    );
  }

  renderEditForm() {
    const analysisRows = lineCount(this.state.analysis) + 1;
    const recommendationRows = lineCount(this.state.recommendations) + 1;

    return (
      <Form>
        <strong className="d-block mb-1">Analysis</strong>
        <FormGroup>
          <Input
            id="analysisText"
            name="text"
            type="textarea"
            rows={analysisRows}
            value={this.state.analysis}
            placeholder="Share what you've found..."
            onChange={this.analysisTextChanged}
          />
        </FormGroup>

        <strong className="d-block mb-1">Troubleshooting</strong>
        <FormGroup>
          <Input
            id="recommendationText"
            name="text"
            type="textarea"
            rows={recommendationRows}
            value={this.state.recommendations}
            placeholder="Suggest what's needed..."
            onChange={this.recommendationTextChanged}
          />
        </FormGroup>
      </Form>
    );
  }
}

AnalysisAndRecommendations.propTypes = {
  className: PropTypes.string,
  site: PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteTimezone: PropTypes.string.isRequired,
  }).isRequired,
  issue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    issueType: PropTypes.string.isRequired,
    analysis: PropTypes.shape({
      analysis: PropTypes.string,
      recommendations: PropTypes.string,
      user: PropTypes.shape({
        username: PropTypes.string,
      }),
      created: PropTypes.string,
    }),
  }).isRequired,
  isSaving: PropTypes.bool.isRequired,
  canEditIssue: PropTypes.bool.isRequired,
  updateAnalysisRec: PropTypes.func.isRequired,
};

AnalysisAndRecommendations.defaultProps = {
  className: null,
};

function mapStateToProps(state) {
  return {
    site: SiteSelectors.currentSite(state),
    issue: IssueSelectors.currentIssue(state),
    isSaving: IssueSelectors.isSaving(state),
    canEditIssue: IssueSelectors.canEditCurrentIssue(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAnalysisRec: (analysis, recommendations) => dispatch(IssueActions.updateAnalysisRec(analysis, recommendations)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisAndRecommendations);
