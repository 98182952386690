import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import differenceBy from 'lodash/differenceBy';
import findIndex from 'lodash/findIndex';
import update from 'immutability-helper';

import { track } from '../../../analytics';
import { SystemUserId } from '../../../../AppConstants';
import ColumnModal from './components/ColumnModal';
import SaveViewModal from './components/SaveViewModal';
import ViewsDropdown from './components/ViewsDropdown';
import ExportOption from './components/ExportOption';

class ViewControls extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      columnModalOpen: false,
      saveModalOpen: false,
      name: '',
      defaultView: false,
    };
    this.handleColumnModalOpen = this.handleColumnModalOpen.bind(this);
    this.handleColumnSelect = this.handleColumnSelect.bind(this);
    this.handleColumnDeselect = this.handleColumnDeselect.bind(this);
    this.handleColumnModalClose = this.handleColumnModalClose.bind(this);
    this.handleColumnModalSave = this.handleColumnModalSave.bind(this);

    this.handleSaveModalOpen = this.handleSaveModalOpen.bind(this);
    this.handleSaveModalClose = this.handleSaveModalClose.bind(this);
    this.handleNameEdit = this.handleNameEdit.bind(this);
    this.handleDefaultEdit = this.handleDefaultEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleColumnModalOpen() {
    this.setState({
      columnModalOpen: true,
      selectedColumns: this.props.selectedColumns || [],
    });
  }

  handleColumnSelect(column) {
    this.setState(currentState => update(currentState, {
      selectedColumns: {
        $push: [column],
      }
    }));
  }

  handleColumnDeselect(column) {
    this.setState(currentState => {
      const index = findIndex(currentState.selectedColumns, current => current.id === column.id);
      return update(currentState, {
        selectedColumns: {
          $splice: [[index, 1]],
        }
      });
    });
  }

  handleColumnModalClose() {
    this.setState({ columnModalOpen: false });
  }

  handleColumnModalSave() {
    const currentColumns = this.props.selectedColumns;
    const selectedColumns = this.state.selectedColumns;
    this.setState({ columnModalOpen: false, selectedColumns: null });
    return Promise.resolve(this.props.setColumns(selectedColumns)).then(() => {
      if (differenceBy(selectedColumns, currentColumns).length) {
        return this.props.onChange();
      }
      return Promise.resolve();
    });
  }

  handleSaveModalOpen() {
    this.setState({
      saveModalOpen: true,
      name: '',
      defaultView: false,
    });
  }

  handleSaveModalClose() {
    this.setState({ saveModalOpen: false });
  }

  handleNameEdit(name) {
    this.setState({ name });
  }

  handleDefaultEdit(defaultView) {
    this.setState({ defaultView });
  }

  handleSave() {
    this.props.saveView(this.state.name, this.state.defaultView);
    this.setState({ saveModalOpen: false });
  }

  render() {
    return (
      <div id="view-controls" className={classNames('d-md-flex', this.props.className)}>
        <ViewsDropdown
          editingView={this.props.editingView}
          allViews={this.props.allViews}
          selectedView={this.props.currentView}
          onViewSelect={this.props.selectView}
          className="mr-0 mr-md-2 ml-md-auto my-2 my-md-0 w-lg-50"
          disabled={this.props.disabled}
        />
        <UncontrolledDropdown color="primary" size="sm" className="my-2 my-md-0" disabled={this.props.disabled}>
          <DropdownToggle id="view-action-button" color="primary" caret className="w-100 w-md-auto" disabled={this.props.disabled}>
            Manage View
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={this.props.startFilterEdit}>Apply filters</DropdownItem>
            <DropdownItem onClick={this.handleColumnModalOpen}>Customize columns</DropdownItem>
            {this.renderSaveOption()}
            {this.renderDefaultViewOption()}
            {this.renderDeleteOption()}
            <ExportOption selectedColumns={this.props.selectedColumns} columnType={this.props.columnType} data={this.props.data} />
          </DropdownMenu>
        </UncontrolledDropdown>
        <ColumnModal
          open={this.state.columnModalOpen}
          selectedColumns={this.state.selectedColumns || []}
          columns={Object.values(this.props.columnType)}
          onColumnSelect={this.handleColumnSelect}
          onColumnDeselect={this.handleColumnDeselect}
          onSave={this.handleColumnModalSave}
          onCancel={this.handleColumnModalClose}
        />
        <SaveViewModal
          open={this.state.saveModalOpen}
          allViews={this.props.allViews}
          name={this.state.name}
          defaultView={this.state.defaultView}
          onNameChange={this.handleNameEdit}
          onDefaultViewChange={this.handleDefaultEdit}
          onSave={this.handleSave}
          onCancel={this.handleSaveModalClose}
        />
      </div>
    );
  }

  renderSaveOption() {
    return <DropdownItem onClick={this.handleSaveModalOpen} disabled={!this.props.editingView}>Save view</DropdownItem>;
  }

  renderDefaultViewOption() {
    const disabled = !!(this.props.editingView || this.props.disabled || !this.props.currentView || this.props.currentView.defaultView);
    return <DropdownItem onClick={this.props.toggleDefaultView} disabled={disabled}>Make this my default view</DropdownItem>;
  }

  renderDeleteOption() {
    return <DropdownItem onClick={this.props.deleteView} disabled={!this.props.canDelete}>Delete view</DropdownItem>;
  }
}

ViewControls.propTypes = {
  onChange: PropTypes.func.isRequired,

  allViews: PropTypes.array.isRequired,
  currentView: PropTypes.object,
  editingView: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  selectView: PropTypes.func.isRequired,
  deleteView: PropTypes.func.isRequired,
  saveView: PropTypes.func.isRequired,
  toggleDefaultView: PropTypes.func.isRequired,
  columnType: PropTypes.object.isRequired,
  startFilterEdit: PropTypes.func.isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  setColumns: PropTypes.func.isRequired,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ViewControls.defaultProps = {
  currentView: null,
  selectedColumns: [],
  data: [],
  disabled: false,
  className: null,
};

export default function buildViewControls(actions, listSelectors, viewManagerSelectors, ColumnType) {
  function mapStateToProps(state) {
    const currentView = viewManagerSelectors.currentView(state);
    return {
      allViews: viewManagerSelectors.viewList(state),
      currentView: viewManagerSelectors.currentView(state),
      editingView: viewManagerSelectors.isEditingView(state),
      canDelete: !viewManagerSelectors.isEditingView(state) && !!currentView && currentView.userId !== SystemUserId,
      disabled: listSelectors.isLoading(state) || listSelectors.isRefreshing(state) || viewManagerSelectors.isSaving(state),
      columnType: ColumnType,
      selectedColumns: viewManagerSelectors.selectedColumns(state),
      data: listSelectors.list(state),
    };
  }

  function mapDispatchToProps(dispatch, ownProps) {
    const onChange = ownProps.onChange;
    return {
      saveView: (name, defaultView) => {
        track('View manager', { Component: 'View action button', 'View action': 'Save view' });
        return dispatch(actions.saveView(name, defaultView));
      },
      selectView: view => {
        track('View manager', {
          Component: 'Views dropdown',
          'View action': 'Select view',
          'View name': view.name,
          'System view': view.userId === SystemUserId,
          'View category': view.category
        });
        return Promise.resolve(dispatch(actions.selectView(view))).then(() => onChange());
      },
      deleteView: () => {
        track('View manager', { Component: 'View action button', 'View action': 'Delete view' });
        return dispatch(actions.deleteView()).then(() => onChange());
      },
      toggleDefaultView: () => {
        track('View manager', { Component: 'View action button', 'View action': 'Toggle default' });
        return dispatch(actions.toggleDefaultView());
      },
      startFilterEdit: () => {
        track('View manager', { Component: 'View action button', 'View action': 'Start edit' });
        return dispatch(actions.startFilterEdit());
      },
      setColumns: columns => {
        track('View manager', { Component: 'View action button', 'View action': 'Set columns' });
        return dispatch(actions.setColumns(columns));
      },
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(ViewControls);
}
