import { ComplianceFields } from '../../client';
import { buildStringSort } from '../../shared/SortUtils';

export const ComplianceTableColumns = {
  Month: {
    id: 'month',
    name: 'Report month',
    shortName: 'Month',
    apiFields: [ComplianceFields.Month],
    exportFunc: report => report.monthStartDate,
    sortFunc: buildStringSort('monthStartDate')
  },
  Customer: {
    id: 'customer',
    name: 'Customer name',
    shortName: 'Customer',
    apiFields: [ComplianceFields.Customer],
    exportFunc: report => (report.customer && report.customer.name) || '',
    sortFunc: buildStringSort('customer.name'),
  },
  SiteNickname: {
    id: 'siteNickname',
    name: 'Site',
    shortName: 'Site',
    apiFields: [ComplianceFields.Site],
    exportFunc: report => (report.site && report.site.nickname) || '',
    sortFunc: buildStringSort('site.nickname'),
  },
  SiteState: {
    id: 'siteState',
    name: 'State',
    shortName: 'State',
    apiFields: [ComplianceFields.Site],
    exportFunc: report => (report.site && report.site.state) || '',
    sortFunc: buildStringSort('site.state'),
  },
  EffectiveStatus: {
    id: 'effectiveStatus',
    name: 'Compliance status',
    shortName: 'Status',
    apiFields: [ComplianceFields.EffectiveStatus],
    exportFunc: report => report.status,
    sortFunc: buildStringSort('status'),
  },
  Comments: {
    id: 'comments',
    name: 'Comment',
    shortName: 'Comment',
    apiFields: [ComplianceFields.Comments],
    exportFunc: report => {
      if (!report.comments || !report.comments.length) {
        return '';
      }
      return report.comments.map(c => c.commentText).join('; ');
    }
  },
  SiteLabels: {
    id: 'siteLabels',
    name: 'Site label',
    shortName: 'Site label',
    apiFields: [ComplianceFields.SiteLabels],
    exportFunc: report => {
      if (!report.siteLabels || !report.siteLabels.length) {
        return '';
      }
      return report.siteLabels.map(l => l.labelText).join(' ');
    }
  }
};

export const DefaultTableColumns = [
  ComplianceTableColumns.Month,
  ComplianceTableColumns.SiteNickname,
  ComplianceTableColumns.EffectiveStatus,
  ComplianceTableColumns.SiteLabels,
];
