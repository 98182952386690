import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

import * as IssueSelectors from '../../IssueEditSelectors';
import UploadButton from '../../../../../documents/components/upload/UploadButton';


const IssueDocUploadButton = ({ issue, canEditIssue, className }) => {
  if (!canEditIssue) {
    return null;
  }

  const issues = [issue];
  if (issue.relatedIssues) {
    issues.push(...issue.relatedIssues);
  }
  return (
    <UploadButton
      className={className}
      defaultFolderName="Tech Notes"
      issues={issues}
      buttonColor="link"
      buttonIcon={faPaperclip}
      buttonOutline={false}
      buttonSize={null}
    />
  );
};

IssueDocUploadButton.propTypes = {
  issue: PropTypes.object,
  canEditIssue: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

IssueDocUploadButton.defaultProps = {
  issue: null,
  className: null,
};

function mapStateToProps(state) {
  return {
    issue: IssueSelectors.currentIssue(state),
    canEditIssue: IssueSelectors.canEditCurrentIssue(state),
  };
}


export default connect(mapStateToProps)(IssueDocUploadButton);
