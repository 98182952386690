import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Alert, Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/free-regular-svg-icons';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import * as selectors from '../NotificationLogSelectors';
import * as actions from '../actions';
import * as SiteActions from '../../../../../../../../user/sites/actions';
import {CanaryTable, TableStyles} from '../../../../../../../../shared/components/CanaryTable';
import NotificationModal from '../../../../../../../components/notification-log/NotificationModal';
import AdditionalInformationModal from '../../../../../../../components/notification-log/AdditionalInformationModal';
import NotificationLogConstants from '../NotificationLogConstants';
import ErrorMessage from '../../../../../../../../shared/components/error-message/ErrorMessage';

const indentNumSpaces = 2;

const tryFormat = (additionalInformation) => {
  try {
    const json = JSON.parse(additionalInformation);
    return JSON.stringify(json, null, indentNumSpaces);
  }
  catch (e) {
    return additionalInformation;
  }
};

const header = [
  {
    columnStyle: TableStyles.ColumnStyles.NONTEXT,
    width: '1%',
  },
  {
    columnStyle: TableStyles.ColumnStyles.NONTEXT,
    width: '1%',
  },
  {
    display: 'ID',
    columnStyle: TableStyles.ColumnStyles.FIRST_COLUMN,
    width: '5%',
  },
  {
    display: 'Notification Type',
    columnStyle: TableStyles.ColumnStyles.TEXT,
    width: '10%',
  },
  {
    display: 'Latest Status',
    columnStyle: TableStyles.ColumnStyles.TEXT,
    width: '10%',
  },
  {
    display: 'Status Date',
    columnStyle: TableStyles.ColumnStyles.TEXT,
    width: '15%',
  },
  {
    display: 'Subject',
    columnStyle: TableStyles.ColumnStyles.TEXT,
    width: '20%',
  },
  {
    display: 'Address',
    columnStyle: TableStyles.ColumnStyles.TEXT,
    width: '15%',
  },
  {
    display: 'Notification Date',
    columnStyle: TableStyles.ColumnStyles.TEXT,
    width: '10%',
  },
];

class EntityNotificationLog extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.renderAlert = this.renderAlert.bind(this);
    this.renderLink = this.renderLink.bind(this);
    this.renderNotificationContentIcon = this.renderNotificationContentIcon.bind(this);
    this.renderAdditionalInformationIcon = this.renderAdditionalInformationIcon.bind(this);

    this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
    this.openAdditionalInformationModal = this.openAdditionalInformationModal.bind(this);
    this.closeAdditionalInformationModal = this.closeAdditionalInformationModal.bind(this);

    this.state = {
      showAdditionalInformationModal: false,
      additionalInformationContent: null,
    };
  }

  toggleNotificationModal(id) {
    if (!this.props.showEntityNotificationContent) {
      this.props.getNotificationContent(id)
        .then(() => this.props.toggleShowEntityNotificationContent());
    }
    else {
      this.props.toggleShowEntityNotificationContent();
    }
  }

  openAdditionalInformationModal(content) {
    this.setState({
      showAdditionalInformationModal: true,
      additionalInformationContent: content,
    });
  }

  closeAdditionalInformationModal() {
    this.setState({
      showAdditionalInformationModal: false,
      additionalInformationContent: null,
    });
  }

  renderAlert() {
    if (NotificationLogConstants.contentStatus.Failed === this.props.status) {
      return <ErrorMessage error={this.props.error} />;
    }
    return null;
  }

  renderLink(notification) {
    if (notification.entityType === 'Issue') {
      const issue = {
        id: notification.entityId,
        site: {
          id: notification.siteId,
        },
      };
      return (<Button color="link" onClick={() => this.props.navToIssue(issue)}>{notification.entityId}</Button>);
    }

    return notification.entityId;
  }

  renderNotificationContentIcon(logId) {
    return <FontAwesomeIcon icon={faCopy} onClick={() => this.toggleNotificationModal(logId)} />;
  }

  renderAdditionalInformationIcon(additionalInformation) {
    if (!additionalInformation) {
      return null;
    }
    return <FontAwesomeIcon icon={faExclamationTriangle} onClick={() => this.openAdditionalInformationModal(tryFormat(additionalInformation))} />;
  }

  render() {
    if (!this.props.notifications || !this.props.notifications.length) {
      return (<Alert color="info">No notifications have been sent</Alert>);
    }

    const data = this.props.notifications.map(notification => ({
      metadata: {},
      columns: [
        {
          display: this.renderNotificationContentIcon(notification.id),
          columnStyle: TableStyles.ColumnStyles.NONTEXT,
        },
        {
          display: this.renderAdditionalInformationIcon(notification.additionalInformation),
          columnStyle: TableStyles.ColumnStyles.NONTEXT,
        },
        {
          display: this.renderLink(notification),
          columnStyle: TableStyles.ColumnStyles.FIRST_COLUMN,
        },
        {
          display: notification.entityType,
          columnStyle: TableStyles.ColumnStyles.TEXT,
        },
        {
          display: notification.latestDeliveryStatus,
          columnStyle: TableStyles.ColumnStyles.TEXT,
        },
        {
          display: notification.latestDeliveryStatusDate,
          columnStyle: TableStyles.ColumnStyles.TEXT,
        },
        {
          display: notification.subject,
          columnStyle: TableStyles.ColumnStyles.TEXT,
        },
        {
          display: notification.recipientAddress,
          columnStyle: TableStyles.ColumnStyles.TEXT,
        },
        {
          display: notification.notificationDate,
          columnStyle: TableStyles.ColumnStyles.TEXT,
        },
      ],
    }));

    return (
      <div>
        {this.renderAlert()}
        <CanaryTable
          header={header}
          data={data}
          keyPrefix="notifications"
        />
        <NotificationModal isOpen={this.props.showEntityNotificationContent} toggle={this.toggleNotificationModal} content={this.props.notificationContent} />
        <AdditionalInformationModal isOpen={this.state.showAdditionalInformationModal} onClose={this.closeAdditionalInformationModal} content={this.state.additionalInformationContent} />
      </div>
    );
  }
}

EntityNotificationLog.propTypes = {
  getNotificationContent: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    destinationType: PropTypes.string.isRequired,
    entityId: PropTypes.number.isRequired,
    entityType: PropTypes.string.isRequired,
    latestDeliveryStatus: PropTypes.string.isRequired,
    latestDeliveryStatusDate: PropTypes.string.isRequired,
    messageId: PropTypes.string.isRequired,
    notificationDate: PropTypes.string.isRequired,
    recipientAddress: PropTypes.string.isRequired,
    siteId: PropTypes.number.isRequired,
    subject: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
  })).isRequired,
  navToIssue: PropTypes.func.isRequired,
  toggleShowEntityNotificationContent: PropTypes.func.isRequired,
  showEntityNotificationContent: PropTypes.bool.isRequired,
  notificationContent: PropTypes.string,
  error: PropTypes.string,
  status: PropTypes.string.isRequired,
};

EntityNotificationLog.defaultProps = {
  notificationContent: null,
  error: null,
};

function mapStateToProps(state) {
  return {
    notificationContent: selectors.entityNotificationContent(state),
    showEntityNotificationContent: selectors.showEntityNotificationContent(state),
    error: selectors.entityNotificationContentError(state),
    status: selectors.entityNotificationContentStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNotificationContent: (id) => dispatch(actions.getEntityNotificationContent(id)),
    toggleShowEntityNotificationContent: () => dispatch(actions.toggleShowEntityNotificationContent()),
    navToIssue: issue => dispatch(SiteActions.navToIssue(issue)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityNotificationLog);
