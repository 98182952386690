import ActionTypes from './UserActionTypes';

function receiveMatchingUsers(state, users) {

  const usersByUsername = {};
  const usersByEmail = {};
  if (users) {
    users.forEach(user => {
      usersByEmail[user.email.toLowerCase()] = user;
      usersByUsername[user.username.toLowerCase()] = user;
    });
  }

  return Object.assign({}, state, {
    searchingUsers: false,
    usersByUsername,
    usersByEmail,
  });
}

export default function UserReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_USER_STARTED:
      return Object.assign({}, state, {
        loading: true,
        selectedUser: null,
        pendingEdits: null,
        error: null,
        usersByUsername: {},
        usersByEmail: {},
        searchingUsers: false,
      });
    case ActionTypes.RECEIVE_USER:
      return Object.assign({}, state, {
        selectedUser: action.selectedUser,
        pendingEdits: action.selectedUser,
        loading: false,
      });
    case ActionTypes.USERS_SEARCH_STARTED:
      return Object.assign({}, state, {
        searchingUsers: true,
        usersByUsername: {},
        usersByEmail: {},
      });
    case ActionTypes.RECEIVE_MATCHING_USERS:
      return receiveMatchingUsers(state, action.users);
    case ActionTypes.USERS_SEARCH_FAILED:
      return Object.assign({}, state, {
        searchingUsers: false,
        usersByUsername: {},
        usersByEmail: {},
        error: action.error,
      });
    case ActionTypes.EDIT_SELECTED_USER:
      return Object.assign({}, state, {
        pendingEdits: action.updatedUser,
      });
    case ActionTypes.DISCARD_SELECTED_USER_EDITS:
      return Object.assign({}, state, {
        pendingEdits: state.selectedUser,
      });
    case ActionTypes.GET_USER_FAILED:
      return Object.assign({}, state, {
        loading: false,
        selectedUser: null,
        pendingEdits: null,
        error: action.error,
      });

    case ActionTypes.SAVE_USER_STARTED:
      return Object.assign({}, state, {
        saving: true,
        error: null,
      });
    case ActionTypes.SAVE_USER_SUCCESS:
      return Object.assign({}, state, {
        saving: false,
        selectedUser: action.user,
        pendingEdits: action.user,
      });
    case ActionTypes.SAVE_USER_FAILED:
      return Object.assign({}, state, {
        saving: false,
        error: action.error,
      });

    default:
      return state;
  }
}
