import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Alert, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import HelpIcon from '../../../../../components/help-icon/HelpIcon';
import ExportDestination from '../../../../../components/export-destination/containers/ExportDestination';
import * as exportDestSelectors from '../../../../../components/export-destination/ExportDestinationSelectors';
import * as exportDestActions from '../../../../../components/export-destination/actions/index';
import * as formatSelectors from '../../file-formats/FileFormatSelectors';
import * as formatActions from '../../file-formats/actions';

const SUPPORTED_TOKENS = [
  '{{UTC_YYYY}}',
  '{{UTC_MM}}',
  '{{UTC_DD}}',
  '{{UTC_HH24}}',
  '{{UTC_HH12}}',
  '{{UTC_mm}}',
  '{{UTC_ss}}',
  '{{UTC_AMPM}}',
  '{{FH_YYYY}}',
  '{{FH_MM}}',
  '{{FH_DD}}',
  '{{FH_HH24}}',
  '{{FH_HH12}}',
  '{{FH_mm}}',
  '{{FH_ss}}',
  '{{FH_AMPM}}',
];

function filenamePatternErrors(filenamePattern, errors) {
  if (filenamePattern && filenamePattern.trim() !== '') {
    const withoutReplacementTokens = filenamePattern.replace(/{{[a-zA-Z_0-9]+}}/g, '');
    if (withoutReplacementTokens.indexOf('{') !== -1 || withoutReplacementTokens.indexOf('}') !== -1) {
      errors.push('Invalid Filename Pattern - bad token substitution.');
      return;
    }

    const tokens = filenamePattern.match(/{{([a-zA-Z_0-9]+)}}/g);
    if (!tokens || !tokens.length) {
      return;
    }

    const unsupportedTokens = tokens.filter(current => SUPPORTED_TOKENS.indexOf(current) === -1);
    if (unsupportedTokens && unsupportedTokens.length) {
      errors.push(`Invalid Filename Pattern - unknown token(s): ${unsupportedTokens.join(',')}`);
    }
  }
}

class FuelHaulerDestinationModal extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      fileFormatHelp: false,
      filenameHelp: false,
      validationErrors: [],
      invFormatId: '',
      filenamePattern: '',
    };

    this.hasRequiredFields = this.hasRequiredFields.bind(this);
    this.toggleFileFormatHelp = this.toggleFileFormatHelp.bind(this);
    this.toggleFilenameHelp = this.toggleFilenameHelp.bind(this);
    this.handleFileFormatChange = this.handleFileFormatChange.bind(this);
    this.handleFilenamePatternChange = this.handleFilenamePatternChange.bind(this);
    this.handleSave = this.handleSave.bind(this);

    if (!this.props.inventoryFormatList || !this.props.inventoryFormatList.length) {
      this.props.getInventoryFormatList();
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.modalOpen !== newProps.modalOpen) {
      this.props.resetExportDestination(newProps.destination && newProps.destination.deliveryUrl);

      let defaultInvFormatId = '';
      if (this.props.inventoryFormatList && this.props.inventoryFormatList.length) {
        defaultInvFormatId = this.props.inventoryFormatList[0].id;
      }

      this.setState({
        fileFormatHelp: false,
        filenameHelp: false,
        validationErrors: [],
        invFormatId: (newProps.destination && newProps.destination.invFormatId) || defaultInvFormatId,
        filenamePattern: (newProps.destination && newProps.destination.filenamePattern) || '',
      });
    }
  }

  hasRequiredFields() {
    return this.props.isDeliveryUrlValid &&
      this.state.invFormatId &&
      this.state.filenamePattern &&
      this.state.filenamePattern.trim() !== '';
  }

  validate() {
    const errors = [];

    if (this.props.existingDestinations && this.props.internalDeliveryUrl) {
      const conflictingDestinations = this.props.existingDestinations
        .filter(current =>
          this.props.internalDeliveryUrl.toLowerCase().trim() === current.deliveryUrl.toLowerCase().trim() && current.id !== this.props.destination.id
        );
      if (conflictingDestinations.length) {
        errors.push('Duplicate destination');
      }
    }

    filenamePatternErrors(this.state.filenamePattern, errors);
    return errors;
  }

  toggleFileFormatHelp() {
    this.setState({
      fileFormatHelp: !this.state.fileFormatHelp,
    });
  }

  toggleFilenameHelp() {
    this.setState({
      filenameHelp: !this.state.filenameHelp,
    });
  }

  handleFileFormatChange(e) {
    this.setState({
      invFormatId: e.target.value,
    });
  }

  handleFilenamePatternChange(e) {
    this.setState({
      filenamePattern: e.target.value,
    });
  }

  handleSave() {
    const errors = this.validate();
    if (!errors.length) {
      this.props.onSave({
        id: (this.props.destination && this.props.destination.id) || null,
        deliveryUrl: this.props.internalDeliveryUrl,
        invFormatId: this.state.invFormatId,
        filenamePattern: this.state.filenamePattern,
      });
    }
    else {
      this.setState({
        validationErrors: errors,
      });
    }
  }

  renderFileSettings() {
    return (
      <Row>
        <Col xs={12}>
          <Form>
            <Row>
              <Col sm={12}>
                <Alert color="info" isOpen={this.state.fileFormatHelp} toggle={this.toggleFileFormatHelp}>
                  The file format defines the columns of data that are sent to this fuel hauler.  The same file format can be used
                  by several fuel haulers.
                </Alert>
              </Col>
            </Row>
            <FormGroup row>
              <Col sm={4}>
                <Label for="fileFormat">File Format</Label>
                <HelpIcon onClick={this.toggleFileFormatHelp} className="ml-1" />
              </Col>
              <Col sm={6}>
                <Input
                  type="select"
                  id="fileFormat"
                  name="fileFormat"
                  value={this.state.invFormatId}
                  onChange={this.handleFileFormatChange}
                >
                  {this.props.inventoryFormatList.map(current => (
                    <option value={current.id} key={current.id}>{current.name}</option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <Row>
              <Col sm={12}>
                <Alert color="info" isOpen={this.state.filenameHelp} toggle={this.toggleFilenameHelp}>
                  <p>
                    Use this field to configure how Canary names the files that are sent to fuel haulers.  Use the tokens below to include
                    the date and time within the filename.  For example, enter&nbsp;
                    <em>Canary_Compliance_{'{{'}UTC_YYYY{'}}'}-{'{{'}UTC_MM{'}}'}-{'{{'}UTC_DD{'}}'}_{'{{'}UTC_HH24{'}}'}-{'{{'}UTC_mm{'}}'}.csv</em>&nbsp;
                    in the box below to send files like <em>Canary_Compliance_{moment().tz('UTC').format('YYYY-MM-DD_HH-mm')}.csv</em>
                  </p>
                  <span>Time tokens (UTC timezone):</span>
                  <ul className="ml-2 mt-1 mb-1">
                    <li><strong>UTC_YYYY</strong>: Year</li>
                    <li><strong>UTC_MM</strong>: Month</li>
                    <li><strong>UTC_DD</strong>: Day of month</li>
                    <li><strong>UTC_HH24</strong>: Hours in 24 hour format</li>
                    <li><strong>UTC_HH12</strong>: Hours in 12 hour format</li>
                    <li><strong>UTC_mm</strong>: Minutes</li>
                    <li><strong>UTC_ss</strong>: Seconds</li>
                    <li><strong>UTC_AMPM</strong>: Meridiem designator</li>
                  </ul>
                  <span>Time tokens (fuel hauler timezone):</span>
                  <ul className="ml-2 mt-1">
                    <li><strong>FH_YYYY</strong>: Year</li>
                    <li><strong>FH_MM</strong>: Month</li>
                    <li><strong>FH_DD</strong>: Day of month</li>
                    <li><strong>FH_HH24</strong>: Hours in 24 hour format</li>
                    <li><strong>FH_HH12</strong>: Hours in 12 hour format</li>
                    <li><strong>FH_mm</strong>: Minutes</li>
                    <li><strong>FH_ss</strong>: Seconds</li>
                    <li><strong>FH_AMPM</strong>: Meridiem designator</li>
                  </ul>
                </Alert>
              </Col>
            </Row>
            <FormGroup row>
              <Col sm={4}>
                <Label for="filenamePattern">Filename Pattern</Label>
                <HelpIcon onClick={this.toggleFilenameHelp} className="ml-1" />
              </Col>
              <Col sm={8}>
                <Input
                  type="textarea"
                  id="filenamePattern"
                  name="filenamePattern"
                  placeholder="Canary_export.csv"
                  style={{ height: '100px' }}
                  value={this.state.filenamePattern}
                  onChange={this.handleFilenamePatternChange}
                />
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    );
  }

  render() {
    if (this.props.inventoryFormatListLoading) {
      return (<PulseLoader color={'#F8E71C'} size={8} />);
    }

    return (
      <Modal isOpen={this.props.modalOpen} toggle={this.props.onCancel} size="lg">
        <ModalHeader toggle={this.props.onCancel}>Edit Destination</ModalHeader>
        <div style={{ padding: '20px' }}>
          {this.state.validationErrors.length ?
            <Alert color="danger" className="mt-1">
              <ul className="ml-1 mt-1">
                {this.state.validationErrors.map(current => (
                  <li key={current}>{current}</li>
                ))}
              </ul>
            </Alert>
            : null
          }
          <ExportDestination />
          {this.renderFileSettings()}
        </div>
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={this.props.onCancel}>Cancel</Button>
          {' '}
          <Button id="done" color="primary" size="sm" onClick={this.handleSave} disabled={!this.hasRequiredFields()}>Save</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

FuelHaulerDestinationModal.propTypes = {
  // public properties
  destination: PropTypes.object,
  existingDestinations: PropTypes.array,
  modalOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,

  // internal properties (delivery url)
  internalDeliveryUrl: PropTypes.string,
  isDeliveryUrlValid: PropTypes.bool.isRequired,
  resetExportDestination: PropTypes.func.isRequired,

  // internal properties (export format)
  inventoryFormatListLoading: PropTypes.bool.isRequired,
  inventoryFormatList: PropTypes.array.isRequired,
  getInventoryFormatList: PropTypes.func.isRequired,
};

FuelHaulerDestinationModal.defaultProps = {
  modalOpen: false,
  destination: {
    deliveryUrl: null,
  },
  existingDestinations: [],
  internalDeliveryUrl: null,
  inventoryFormatList: [],
};

function mapStateToProps(state) {
  return {
    internalDeliveryUrl: exportDestSelectors.deliveryUrl(state),
    isDeliveryUrlValid: exportDestSelectors.isValid(state),
    inventoryFormatListLoading: formatSelectors.isLoading(state),
    inventoryFormatList: formatSelectors.inventoryFormatList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetExportDestination: newDeliveryUrl => dispatch(exportDestActions.reset(newDeliveryUrl)),
    getInventoryFormatList: () => dispatch(formatActions.getInventoryFormatList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelHaulerDestinationModal);
