import update from 'immutability-helper/index';

import { SiteClient } from '../../../../../../../../client';
import * as orgEditSelectors from '../../../OrganizationEditSelectors';
import { ConnectionTypes } from '../connection-constants';
import ActionTypes from '../SiteEditorActionTypes';
import * as selectors from '../SiteEditorSelectors';

const NEW = 'new';

function getSiteStarted() {
  return {
    type: ActionTypes.GET_SITE_STARTED,
  };
}

function receiveSite(selectedSite) {
  return {
    type: ActionTypes.RECEIVE_SITE,
    selectedSite,
  };
}

function getSiteFailed(error) {
  return {
    type: ActionTypes.GET_SITE_FAILED,
    error
  };
}

function buildNewSite(organization) {
  return {
    siteName: '',
    nickname: '',
    businessName: '',
    enabled: true,
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    siteTimezone: '',
    customer: organization.customer,
  };
}

export function selectSite(site) {
  return (dispatch, getState) => {
    const currentState = getState();
    const isLoading = selectors.isSelectedSiteLoading(currentState);
    const org = orgEditSelectors.selectedOrganization(currentState);

    if (isLoading || !org || !site) {
      return Promise.resolve();
    }

    let sitePromise;
    const id = site.id || site;
    if (id === NEW) {
      sitePromise = Promise.resolve(buildNewSite(org));
    }
    else {
      sitePromise = SiteClient.getSiteByIdAdmin(id);
    }

    dispatch(getSiteStarted());
    return sitePromise
      .then(dispatchSite => dispatch(receiveSite(dispatchSite)))
      .catch((err) => {
        dispatch(getSiteFailed(err));
      });
  };
}

export function editSelectedSite(updatedSite) {
  return {
    type: ActionTypes.EDIT_SITE,
    updatedSite,
  };
}

export function discardPendingSiteEdits() {
  return {
    type: ActionTypes.DISCARD_PENDING_SITE_EDITS,
  };
}

function buildSiteToSave(site) {
  const updateSpec = {
    $unset: ['cellSignalStrength', 'customer', 'connection', 'entityNotifications', 'health', 'healthHistory']
  };

  Object.keys(site).forEach(key => {
    if (site[key] === '') {
      updateSpec[key] = { $set: null };
    }
  });

  if (!Number.isInteger(site.id)) {
    updateSpec.id = {
      $set: null,
    };
  }

  if (site.customer && site.customer.id && !site.customerId) {
    updateSpec.customerId = {
      $set: site.customer.id,
    };
  }

  if (site.location && site.location.lat && site.location.lon) {
    updateSpec.location = {
      $set: {
        lat: parseFloat(site.location.lat),
        lon: parseFloat(site.location.lon),
      }
    };
  }
  else {
    updateSpec.location = {
      $set: null,
    };
  }

  return update(site, updateSpec);
}

function saveSiteInternal(site) {
  const toSave = buildSiteToSave(site);

  return dispatch => {
    dispatch({ type: ActionTypes.SAVE_SITE_STARTED });
    return SiteClient.saveSite(toSave)
      .then(apiSite => {
        const updatedSite = Object.assign({}, site, apiSite);
        return dispatch({ type: ActionTypes.SAVE_SITE_SUCCESS, site: updatedSite });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.SAVE_SITE_FAILED, error });
        return Promise.reject(error);
      });
  };
}

export function saveSelectedSite() {
  return (dispatch, getState) => {
    const state = getState();
    const pendingEdits = selectors.pendingSiteEdits(state);
    if (!pendingEdits || selectors.isSaving(state)) {
      return Promise.resolve();
    }
    return dispatch(saveSiteInternal(pendingEdits));
  };
}

export function toggleSiteEnabled(site) {
  return (dispatch, getState) => {
    const state = getState();
    if (selectors.isSaving(state)) {
      return Promise.resolve();
    }

    const updatedSite = update(site, {
      enabled: {
        $set: !site.enabled,
      }
    });

    return dispatch(saveSiteInternal(updatedSite));
  };
}

function geocodeStarted() {
  return {
    type: ActionTypes.GEOCODE_STARTED,
  };
}

function geocodeReceiveResults(geocodeResults) {
  return {
    type: ActionTypes.GEOCODE_RECEIVE_RESULTS,
    geocodeResults,
  };
}

function geocodeReceiveError() {
  return {
    type: ActionTypes.GEOCODE_RECEIVE_ERROR,
  };
}

export function geocodeSelectedSite() {
  return (dispatch, getState) => {
    const currentState = getState();
    if (selectors.isGeocoding(currentState)) {
      return Promise.resolve();
    }

    const selectedSite = selectors.selectedSite(currentState);
    const { address, city, state, zip } = selectedSite;
    if (!address || !city || !state) {
      return Promise.resolve();
    }

    dispatch(geocodeStarted());
    return SiteClient.geocode(address, city, state, zip)
      .then(geocodeResults => dispatch(geocodeReceiveResults(geocodeResults)))
      .catch(err => dispatch(geocodeReceiveError(err)));
  };
}

export function geocodeAccept() {
  return {
    type: ActionTypes.GEOCODE_ACCEPT,
  };
}

export function geocodeCancel() {
  return {
    type: ActionTypes.GEOCODE_CANCEL,
  };
}

export function cancelEditConnection() {
  return {
    type: ActionTypes.CANCEL_EDIT_SITE_CONNECTION,
  };
}

export function editConnection() {
  return {
    type: ActionTypes.EDIT_SITE_CONNECTION,
  };
}

function receiveSiteConnectionDetail(siteConnection) {
  return {
    type: ActionTypes.SAVE_SITE_CONNECTION_SUCCESS,
    siteConnection,
  };
}

function saveSiteConnectionFailed(error) {
  return {
    type: ActionTypes.SAVE_SITE_CONNECTION_FAILED,
    error,
  };
}

export function saveConnection() {
  return (dispatch, getState) => {
    const currentState = getState();

    if (selectors.isSavingSiteConnection(currentState)) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.SAVE_SITE_CONNECTION_STARTED });

    const siteId = selectors.selectedSite(currentState).id;
    const siteConnectionDetail = selectors.siteConnectionDetail(currentState);

    return SiteClient.saveSiteConnection(siteId, siteConnectionDetail, ConnectionTypes.NONE.connectionType === siteConnectionDetail.connectionType)
      .then((siteConnection) => dispatch(receiveSiteConnectionDetail(siteConnection)))
      .catch(error => dispatch(saveSiteConnectionFailed(error)));
  };
}

export function updateSiteConnectionDetail(siteConnectionDetail) {
  return {
    type: ActionTypes.UPDATE_SITE_CONNECTION,
    siteConnectionDetail,
  };
}

export function openDeleteSiteConnectionDetailModal() {
  return {
    type: ActionTypes.OPEN_DELETE_SITE_CONNECTION_MODAL,
  };
}

export function closeDeleteSiteConnectionDetailModal() {
  return {
    type: ActionTypes.CLOSE_DELETE_SITE_CONNECTION_MODAL,
  };
}
