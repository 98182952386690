import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../../../../../shared/components/logo/Logo';
import ContactDetails from '../../../../../../shared/components/contact-details/ContactDetails';


function renderCustomerAbbreviation(organization) {
  if (organization.customer && organization.customer.abbreviation) {
    return (
      <span className="canary-summary-secondary-text" style={{ textJustify: 'right' }}>
        {` (${organization.customer.abbreviation})`}
      </span>
    );
  }
  return null;
}

function buildSiteStates(sites) {
  if (!sites || !sites.length) {
    return '';
  }

  const states = {};
  sites.forEach(current => {
    states[current.state] = 1;
  });

  const statesStr = Object.keys(states).sort().join(', ');
  return `STATES: ${statesStr}`;
}

const OrgHeader = ({ organization, sites, onOrganizationEdit }) => (
  <div className="d-flex flex-row canary-summary">
    <div className="d-flex flex-column">
      <h4>
        <span>{organization.name}</span>
        {renderCustomerAbbreviation(organization)}

        <Button style={{ display: 'inline-block', verticalAlign: 'baseline' }} color="link" className="ml-2" size="sm" onClick={() => onOrganizationEdit(organization)}>
          EDIT
          {' '}
          <FontAwesomeIcon icon={faPencilAlt} />
        </Button>
      </h4>
      <div className="canary-summary-secondary-text">
        <ContactDetails value={organization} />
      </div>
    </div>
    <div className="canary-summary-secondary-text ml-auto">
      <span>{buildSiteStates(sites)}</span>
    </div>
    <Logo alt="Company logo" className="ml-4" style={{ verticalAlign: 'top' }} height="55px" width="120px" src={organization} />
  </div>
);

OrgHeader.propTypes = {
  organization: PropTypes.object.isRequired,
  sites: PropTypes.array.isRequired,
  onOrganizationEdit: PropTypes.func.isRequired,
};

export default OrgHeader;
