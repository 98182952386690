import React from 'react';
import PropTypes from 'prop-types';

function NonProdWarning(props) {
  if (window.isNonProduction || window.webpackHotUpdate) {
    return (
      <>
        { props.children }
      </>
    );
  }
  return null;
}

NonProdWarning.propTypes = {
  children: PropTypes.any.isRequired,
};

export default NonProdWarning;
