import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';

import { TableStyles } from '../../../../../../../../shared/components/CanaryTable';
import CanaryTableAndSummary from '../../../../../../../../shared/components/CanaryTableAndSummary';
import ErrorMessage from '../../../../../../../../shared/components/error-message/ErrorMessage';
import ConfirmModal from '../../../../../../../../shared/components/confirm-modal/ConfirmModal';
import Toggle from '../../../../../../../../shared/components/buttons/Toggle';
import * as navActions from '../../../../../actions';
import * as orgEditSelectors from '../../../OrganizationEditSelectors';
import * as selectors from '../SiteEditorSelectors';
import * as actions from '../actions';


const headers = [
  { display: 'SITE NAME', width: '22%', columnStyle: TableStyles.ColumnStyles.FIRST_COLUMN },
  { display: 'CANARY ID', width: '9%' },
  { display: 'STREET ADDRESS', width: '18%' },
  { display: 'CITY', width: '18%' },
  { display: 'STATE', width: '5%' },
  { display: 'ZIP', width: '6%' },
  { display: 'PHONE', width: '13%' },
  { display: 'ACTIVE', width: '7%', columnStyle: TableStyles.ColumnStyles.NONTEXT }
];

const SiteTypes = {
  ActiveSites: 'Show Active Sites',
  AllSites: 'Show All Sites',
};

class SitesTable extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      siteType: SiteTypes.ActiveSites,
      confirmModal: false,
      site: null,
    };
    this.setSiteType = this.setSiteType.bind(this);
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.confirmDisableSite = this.confirmDisableSite.bind(this);
  }

  setSiteType(event) {
    this.setState({
      siteType: event.target.value,
    });
  }

  handleToggleSiteEnabled(site) {
    if (site.enabled) {
      this.openConfirmModal(site);
    }
    else {
      this.props.toggleSiteEnabled(site);
    }
  }

  openConfirmModal(site) {
    this.setState({
      confirmModal: true,
      site,
    });
  }

  cancelModal() {
    this.setState({
      confirmModal: false,
      site: null,
    });
  }

  confirmDisableSite() {
    this.cancelModal();
    this.props.toggleSiteEnabled(this.state.site);
  }

  render() {
    const controls = (
      <div className="d-flex">
        <select value={this.state.siteType} className="form-control form-control-sm" onChange={this.setSiteType}>
          <option value={SiteTypes.ActiveSites}>{SiteTypes.ActiveSites}</option>
          <option value={SiteTypes.AllSites}>{SiteTypes.AllSites}</option>
        </select>
        <Button className="ml-2 flex-shrink-0" color="primary" size="sm" onClick={this.props.navToNewSite}>ADD A NEW SITE</Button>
      </div>
    );

    const data = this.props.sites
      .filter(site => this.state.siteType === SiteTypes.AllSites || site.enabled)
      .map((site) => ({
        metadata: {
          rowStyle: site.enabled ? TableStyles.RowStyles.ACTIVE : TableStyles.RowStyles.INACTIVE,
        },
        columns: [
          { display: (<Button color="link" onClick={() => this.props.navToSiteEditor(site)}>{site.nickname}</Button>) },
          { display: site.siteName },
          { display: site.address },
          { display: site.city },
          { display: site.state },
          { display: site.zip },
          { display: site.phone },
          { display: (<Toggle value={!site.enabled} onClick={() => this.handleToggleSiteEnabled(site)} />), columnStyle: TableStyles.ColumnStyles.NONTEXT }
        ],
      }));
    const summary = `${data.length} Sites`;

    return (
      <div>
        <ErrorMessage title="Site update failed" error={this.props.error} />
        <CanaryTableAndSummary summary={summary} header={headers} controls={controls} data={data} prefix="sites" />
        <ConfirmModal
          isOpen={this.state.confirmModal}
          headerText={`Disable ${this.state.site && this.state.site.nickname}`}
          onConfirm={this.confirmDisableSite}
          onCancel={this.cancelModal}
        />
      </div>
    );
  }
}

SitesTable.propTypes = {
  error: PropTypes.object,
  sites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteName: PropTypes.string.isRequired,
    nickname: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string,
    phone: PropTypes.string,
    enabled: PropTypes.bool.isRequired,
  })),
  toggleSiteEnabled: PropTypes.func.isRequired,
  navToNewSite: PropTypes.func.isRequired,
  navToSiteEditor: PropTypes.func.isRequired,
};

SitesTable.defaultProps = {
  error: null,
  sites: [],
};

function mapStateToProps(state) {
  return {
    error: selectors.siteError(state),
    sites: orgEditSelectors.selectedOrganizationSites(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    toggleSiteEnabled: site => dispatch(actions.toggleSiteEnabled(site)),
    navToNewSite: () => dispatch(navActions.navToNewSite(ownProps.match.params.organizationId)),
    navToSiteEditor: site => dispatch(navActions.navToSiteEditor(site)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SitesTable));
