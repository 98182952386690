import update from 'immutability-helper';
import { combineReducers } from 'redux';
import { LOCATION_CHANGE } from 'react-router-redux';

import ActionTypes from './IssueEditActionTypes';
import { OperationState } from './IssueEditConstants';
import CommentReducer from './components/comment/CommentReducer';
import IssueDispatchReducer from './components/dispatch/IssueDispatchReducer';
import SiteDispatchActionTypes from '../../../dispatch/SiteDispatchActionTypes';
import IssueDocReducer from './components/docs/IssueDocReducer';
import IssueExportReducer from './components/export/IssueExportReducer';
import WorkflowReducer from './components/workflow/WorkflowReducer';
import WorkflowActionTypes from './components/workflow/WorkflowActionTypes';

const InitialState = {
  operationState: OperationState.Ready,
};

function updateDispatchStatus(state, action) {
  const issueId = state.currentIssue && state.currentIssue.id;
  if (!issueId || !action.dispatch || !action.dispatch.issues || !action.dispatch.issues.length) {
    return state;
  }
  const index = action.dispatch.issues.findIndex(current => current.id === issueId);
  if (index === -1) {
    return state;
  }

  const updatedIssue = update(action.dispatch.issues[index], {
    $unset: ['dispatchActivity'],
  });
  return update(state, {
    currentIssue: {
      $set: Object.assign({}, state.currentIssue, updatedIssue),
    }
  });
}

function IssueEditRootReducer(state = InitialState, action) {
  switch (action.type) {
    case ActionTypes.CHANGE_ISSUE_STARTED:
      return Object.assign({}, state, {
        operationState: OperationState.Loading,
        currentIssue: null,
        failedIssueId: null,
      });
    case ActionTypes.CHANGE_ISSUE_SUCCESS:
      return Object.assign({}, state, {
        operationState: OperationState.Ready,
        currentIssue: update(action.currentIssue, {
          $unset: ['comments', 'documents', 'events'],
        }),
      });
    case ActionTypes.CHANGE_ISSUE_FAILED:
      return Object.assign({}, state, {
        operationState: OperationState.Ready,
        error: action.error,
        failedIssueId: action.failedIssueId,
      });

    case ActionTypes.SAVE_ISSUE_STARTED:
      return Object.assign({}, state, {
        operationState: OperationState.Saving,
      });
    case ActionTypes.SAVE_ISSUE_SUCCESS:
      return Object.assign({}, state, {
        operationState: OperationState.Ready,
        currentIssue: action.currentIssue,
      });
    case ActionTypes.SAVE_ISSUE_FAILED:
      return Object.assign({}, state, {
        operationState: OperationState.Ready,
        error: action.error,
      });

    case WorkflowActionTypes.UPDATE_WORKFLOW_SUCCESS:
      return Object.assign({}, state, {
        currentIssue: action.issue,
      });
    case SiteDispatchActionTypes.CREATE_DISPATCH_SUCCESS:
      return updateDispatchStatus(state, action);

    case LOCATION_CHANGE:
      return { operationState: OperationState.Ready };
    default:
      return state;
  }
}

const CombinedReducer = combineReducers({
  root: IssueEditRootReducer,
  comments: CommentReducer,
  dispatch: IssueDispatchReducer,
  docs: IssueDocReducer,
  export: IssueExportReducer,
  workflow: WorkflowReducer,
});

export default CombinedReducer;
