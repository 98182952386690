import { Get, Post, PostWithResponseType } from '../HttpUtils';
import { ParameterType, Param, addFields, buildFindMethod } from '../param-types';

const statusMap = new Map();
statusMap.set('PASSED', 'PASSED,LATE');
statusMap.set('INCONCLUSIVE', 'INCONCLUSIVE');
statusMap.set('FAILED', 'FAILED');

function mapToLegacyFormat(report) {
  if (!report) {
    return null;
  }

  const site = report.site;
  if (site) {
    if (!report.customerId) {
      report.customerId = site.customerId;
    }
    if (!report.siteId) {
      report.siteId = site.id;
    }
    report.siteName = site.siteName;
    report.siteNickname = site.nickname;
    report.siteTimezone = site.siteTimezone;
    report.siteState = site.state;
  }

  const month = report.month;
  if (month) {
    report.monthId = month.id;
    report.monthStartDate = month.startDate;
    report.year = month.year;
    report.monthText = month.monthText;
    report.month = month.month;
  }

  return report;
}

export const ComplianceFields = {
  Id: 'id',
  MonthId: 'monthId',
  SiteId: 'siteId',
  CalculatedStatusDate: 'calculatedStatusDate',
  CalculatedStatus: 'calculatedStatus',
  EffectiveStatus: 'effectiveStatus',
  EffectiveStatusDate: 'effectiveStatusDate',
  TankStatus: 'tankStatus',
  LineStatus: 'lineStatus',
  SensorStatus: 'sensorStatus',
  TankStatusDetails: 'tankStatusDetails',
  LineStatusDetails: 'lineStatusDetails',
  SensorStatusDetails: 'sensorStatusDetails',

  Comments: 'comments',
  Customer: 'customer',
  Month: 'month',
  Site: 'site',
  SiteLabels: 'siteLabels',
};

export const ComplianceParams = {
  CustomerIds: new Param('customerIds', ParameterType.List),
  SiteIds: new Param('siteIds', ParameterType.List),
  SiteStates: new Param('siteStates', ParameterType.List),
  SiteLabelIds: new Param('siteLabelIds', ParameterType.List),
  Year: new Param('year', ParameterType.Scalar),
  Month: new Param('month', ParameterType.Scalar),
  Statuses: new Param('statuses', ParameterType.List),
};

const ReportByIdFields = [
  ComplianceFields.Id,
  ComplianceFields.SiteId,
  ComplianceFields.CalculatedStatusDate,
  ComplianceFields.CalculatedStatus,
  ComplianceFields.EffectiveStatus,
  ComplianceFields.EffectiveStatusDate,
  ComplianceFields.TankStatus,
  ComplianceFields.LineStatus,
  ComplianceFields.SensorStatus,
  ComplianceFields.TankStatusDetails,
  ComplianceFields.LineStatusDetails,
  ComplianceFields.SensorStatusDetails,
  ComplianceFields.Month,
  ComplianceFields.Comments,
];

const LatestReportFields = [
  ComplianceFields.Id,
  ComplianceFields.SiteId,
  ComplianceFields.CalculatedStatusDate,
  ComplianceFields.CalculatedStatus,
  ComplianceFields.EffectiveStatus,
  ComplianceFields.EffectiveStatusDate,
  ComplianceFields.TankStatus,
  ComplianceFields.LineStatus,
  ComplianceFields.SensorStatus,
  ComplianceFields.Month,
];

const SiteListFields = [
  ComplianceFields.Id,
  ComplianceFields.SiteId,
  ComplianceFields.CalculatedStatusDate,
  ComplianceFields.CalculatedStatus,
  ComplianceFields.EffectiveStatus,
  ComplianceFields.EffectiveStatusDate,
  ComplianceFields.Month,
  ComplianceFields.Comments,
];

export const ComplianceClient = {
  findReports: buildFindMethod('/v2/compliance-reports', ComplianceFields, ComplianceParams, mapToLegacyFormat),

  getReportingPeriods() {
    return Get('/v2/compliance-reports/compliance-months').then(response => response.data);
  },

  getReportById(reportId) {
    if (!reportId) {
      return Promise.resolve(null);
    }

    const params = {};
    addFields(ComplianceFields, ReportByIdFields, params);

    return Get(`/v2/compliance-reports/${reportId}`, params).then(response => mapToLegacyFormat(response.data));
  },

  getLatestReport(site) {
    const siteId = (site && site.id) || site;
    if (!siteId) {
      return Promise.resolve(null);
    }

    const params = {};
    addFields(ComplianceFields, LatestReportFields, params);

    return Get(`/v2/compliance-reports/sites/${siteId}/latest`, params).then(response => mapToLegacyFormat(response.data));
  },

  getReportEventsBySiteAndReport(report) {
    if (!report) {
      return Promise.resolve([]);
    }

    const reportId = report.id || report;
    return Get(`/v2/compliance-reports/${reportId}/event-details`).then(response => response.data);
  },

  addComment(reportId, uid, comment) {
    const payload = {
      userId: uid,
      complianceReportId: reportId,
      commentText: comment,
    };
    return Post(`/v2/compliance-reports/${reportId}/comments`, payload)
      .then(() => Get(`/v2/compliance-reports/${reportId}/comments`))
      .then(getResponse => Promise.resolve(getResponse.data));
  },

  getSiteRegulatoryReports(site) {
    const siteId = (site && site.id) || site;
    if (!siteId) {
      return Promise.resolve(null);
    }

    const params = {
      [ComplianceParams.SiteIds.name]: siteId,
    };
    addFields(ComplianceFields, SiteListFields, params);

    return Get('/v2/compliance-reports', params).then(response => response.data.map(mapToLegacyFormat));
  },

  download(reportContext) {
    return PostWithResponseType('/v2/reports', reportContext, 'blob')
      .then(response => response.data);
  },

  doOverrideStatus(reportId, userId, overrideStatus, commentText) {
    const payload = {
      userId,
      overrideStatus,
      commentText,
    };

    return Post(`/v2/compliance-reports/${reportId}/override-status`, payload)
      .then(response => response.data);
  }
};
