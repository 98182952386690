/* eslint-disable no-use-before-define */
import update from 'immutability-helper';
import moment from 'moment';
import * as _filter from 'lodash/filter';
import { WorkflowStatusTypes } from '../../AppConstants';
import {
  createPatchReplacement,
  createSingletonPatchReplacement,
  Get,
  JsonPatch,
  Post
} from '../HttpUtils';
import { ParameterType, Param, buildFindMethod, buildFindByIdMethod } from '../param-types';

function getMaxDate(alarms, dateProperty) {
  const alarmDates = alarms.map(alarm => moment(alarm[dateProperty]));
  return moment.max(alarmDates);
}

function getMinDate(alarms, dateProperty) {
  const alarmDates = alarms.map(alarm => moment(alarm[dateProperty]));
  return moment.min(alarmDates);
}

function groupAlarms(issue) {
  if (!issue.alarms || !issue.alarms.length) {
    return update(issue, { alarms: { $set: [] } });
  }

  const alarmsByKey = {};
  const alarms = issue.alarms;
  for (let i = 0; i < alarms.length; i++) {
    const current = alarms[i];
    const key = `${current.alarmTypeId}_${current.affectedItemId}`;
    if (!alarmsByKey[key]) {
      alarmsByKey[key] = [];
    }
    alarmsByKey[key].push(current);
  }

  const groupedAlarms = [];
  Object.keys(alarmsByKey).forEach((currentKey) => {
    const currentAlarms = alarmsByKey[currentKey];
    const firstAlarm = currentAlarms[0];
    const groupedAlarm = {
      id: firstAlarm.id,
      isActive: currentAlarms.filter(alarm => !alarm.clearDate).length > 0,
      lastClearedDate: getMaxDate(currentAlarms, 'clearDate'),
      firstAlarmDate: getMinDate(currentAlarms, 'alarmDate'),
      count: currentAlarms.length,
      title: firstAlarm.title,
      alarmDate: firstAlarm.alarmDate,
      ids: getAlarmIds(currentAlarms),
      siteTimezone: issue.site && issue.site.siteTimezone,
    };
    groupedAlarms.push(groupedAlarm);
  });

  return update(issue, { alarms: { $set: groupedAlarms } });
}

function getAlarmIds(alarms) {
  const alarmIds = [];

  if (alarms && alarms.length) {
    for (let i = 0; i < alarms.length; i++) {
      const current = alarms[i];
      alarmIds.push(current.id);
    }
  }

  return alarmIds;
}

const openStatuses = _filter(WorkflowStatusTypes, (value, key) => WorkflowStatusTypes.Deleted !== key && WorkflowStatusTypes.Resolved !== key);

function mapToLegacyFormat(issue) {
  const result = {
    id: issue.id,
    description: issue.description,
    created: issue.created,
    issueDate: issue.issueDate,
    issueType: issue.issueType,
    documents: issue.documents,
    site: issue.site,
    siteLabels: issue.siteLabels,
    siteId: issue.siteId || (issue.site && issue.site.id),
    analysis: issue.analysis,
    analysisHistory: issue.analysisHistory,
    priority: issue.priority,
    latestAlarmDate: issue.latestAlarmDate,
    lastModified: issue.lastModified,
    workflow: issue.workflow,
    alarms: issue.alarms,
    comments: issue.comments,
    dispatch: issue.dispatch,
    events: issue.events,
    customer: issue.customer,
  };

  result.description = issue.description;

  const workflow = issue.workflow;
  if (workflow) {
    result.workflowStatus = workflow.status;
    result.workflowStatusDate = workflow.statusDate;
    result.workflowUserId = workflow.user.id;
    result.workflowUsername = workflow.user.username;
  }

  const site = issue.site;
  if (site) {
    result.customerId = site.customerId;
    result.siteName = site.siteName;
    result.siteNickname = site.nickname;
    result.siteTimezone = site.siteTimezone;
    result.siteState = site.state;
  }

  const dispatch = issue.dispatch;
  if (dispatch) {
    result.dispatchWorkflowStatus = dispatch.status;
    result.dispatchWorkflowStatusDate = dispatch.statusDate;
    result.dispatchWorkflowUserId = dispatch.user.id;
    result.dispatchWorkflowUsername = dispatch.user.username;
    result.externalDispatchId = dispatch.externalId;
    result.dispatchCreationDate = dispatch.creationDate;
  }

  result.dispatchable = !!(!dispatch && result.workflowStatus !== WorkflowStatusTypes.Resolved && result.workflowStatus !== WorkflowStatusTypes.Deleted);

  return result;
}

export const IssueFields = {
  Id: 'id',
  SiteId: 'siteId',
  Description: 'description',
  Priority: 'priority',
  IssueType: 'issueType',
  Analysis: 'analysis',
  Workflow: 'workflow',
  Created: 'created',
  IssueDate: 'issueDate',
  Alarms: 'alarms',
  LatestAlarmDate: 'latestAlarmDate',
  Comments: 'comments',
  Tags: 'tags',
  Documents: 'documents',
  LastModified: 'lastModified',

  Customer: 'customer',
  Site: 'site',
  SiteLabels: 'siteLabels',
  AnalysisHistory: 'analysisHistory',
  Dispatch: 'dispatch',
  Events: 'events',
  MviReports: 'mviReports',
};

export const IssueParams = {
  CustomerIds: new Param('customerIds', ParameterType.List),
  SiteIds: new Param('siteIds', ParameterType.List),
  SiteStates: new Param('siteStates', ParameterType.List),
  SiteLabelIds: new Param('siteLabelIds', ParameterType.List),
  IssueTypes: new Param('issueTypes', ParameterType.List),
  WorkflowStatuses: new Param('workflowStatuses', ParameterType.List)
};

export const IssueClient = {
  findIssues: buildFindMethod('/v2/issues', IssueFields, IssueParams, mapToLegacyFormat),

  findById: buildFindByIdMethod('/v2/issues', IssueFields),

  getResolvedSiteIssues(site) {
    const id = (site && site.id) || site;
    if (!id) {
      return Promise.resolve([]);
    }

    return Get(`/v2/issues?siteIds=${id}&workflowStatuses=${WorkflowStatusTypes.Resolved}`)
      .then(response => response.data.map(mapToLegacyFormat));
  },

  getOpenSiteIssues(site) {
    const id = (site && site.id) || site;
    if (!id) {
      return Promise.resolve([]);
    }

    return Get(`/v2/issues?siteIds=${id}&workflowStatuses=${openStatuses}&include=id,description,siteId,site,created,priority,workflow,issueType,analysis,dispatch`)
      .then(response => response.data.map(mapToLegacyFormat).map(groupAlarms));
  },

  getIssueById(issueId) {
    return Get(`/v2/issues/${issueId}`).then((issueResponse) => {
      const issue = mapToLegacyFormat(groupAlarms(issueResponse.data));
      return Get(`/v2/issues?siteIds=${issue.siteId}&workflowStatuses=${openStatuses}&include=id,description,created,priority,workflow,alarms,dispatch`)
        .then((relatedIssueResponse) => {
          issue.relatedIssues = relatedIssueResponse.data
            .filter(current => current.id !== issue.id)
            .map(groupAlarms)
            .map(mapToLegacyFormat);
          return issue;
        });
    });
  },

  updatePriority(issue, newPriority) {
    const patch = createSingletonPatchReplacement('priority', newPriority);

    return JsonPatch(`/v2/issues/${issue.id}`, patch).then(response => mapToLegacyFormat(response.data));
  },

  updateAnalysisRecommendations(issue, analysis, recommendations) {
    const patch = [
      createPatchReplacement('analysis', analysis),
      createPatchReplacement('recommendations', recommendations)
    ];

    return JsonPatch(`/v2/issues/${issue.id}`, patch)
      .then((patchResponse) => patchResponse.data);
  },

  updateDescription(issue, newDescription) {
    const patch = createSingletonPatchReplacement('description', newDescription);
    return JsonPatch(`/v2/issues/${issue.id}`, patch).then(response => mapToLegacyFormat(response.data));
  },

  updateWorkflow(issueId, newWorkflowStatus) {
    const patch = createSingletonPatchReplacement('workflowStatus', newWorkflowStatus);
    return JsonPatch(`/v2/issues/${issueId}`, patch).then(response => mapToLegacyFormat(response.data));
  },

  addComment(issue, comment) {
    return Post(`/v2/issues/${issue.id}/comments`, comment)
      .then(response => response.data);
  },

  createIssue(issue) {
    return Post('/v2/issues', issue).then(response => mapToLegacyFormat(response.data));
  },

  bulkUpdate(bulkRequest) {
    return Post('/v2/issues/bulk', bulkRequest);
  },

  getDispatchActivity(issueId) {
    return Get(`/v2/issues/${issueId}/dispatch-activity`).then(response => Promise.resolve(response.data));
  },
};
