import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { track, withTracking } from '../../../../../../shared/analytics';
import CanaryLoadingIndicator from '../../../../../../shared/components/CanaryLoadingIndicator';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import { Column, MonthColumn, PropertyColumn, StatefulTable } from '../../../../../../shared/components/table';
import { SortDirection } from '../../../../../../AppConstants';
import StatusIconAndText from '../../../../../../shared/components/compliance/StatusIconAndText';
import * as SiteActions from '../../../../actions';
import * as actions from './ComplianceListActions';
import * as selectors from './ComplianceListSelectors';

const renderStatus = report => <StatusIconAndText report={report} />;

function renderComments(comments) {
  if (!comments || !comments.length) {
    return null;
  }
  return comments.map(comment => (
    <div key={comment.id} className="canary-text-small mr-2">{comment.commentText}</div>
  ));
}

class ComplianceList extends Component {
  componentDidMount() {
    this.props.getComplianceHistory();
  }

  render() {
    if (this.props.isLoading) {
      return <CanaryLoadingIndicator />;
    }
    else if (this.props.error) {
      return <ErrorMessage error={this.props.error} />;
    }

    return (
      <StatefulTable
        initialSortColumn="monthStartDate"
        initialSortDirection={SortDirection.Desc}
        data={this.props.complianceHistory}
        onRowClick={this.props.navToSiteComplianceReport}
      >
        <MonthColumn title="Month" property="monthStartDate" sortable width="6rem" />
        <Column title="Status" columnId="status" render={renderStatus} width="10rem" />
        <PropertyColumn title="Comments" property="comments" render={renderComments} />
      </StatefulTable>
    );
  }
}

ComplianceList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  complianceHistory: PropTypes.array.isRequired,
  getComplianceHistory: PropTypes.func.isRequired,
  navToSiteComplianceReport: PropTypes.func.isRequired,
};

ComplianceList.defaultProps = {
  error: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isComplianceHistoryLoading(state),
    error: selectors.complianceHistoryError(state),
    complianceHistory: selectors.complianceHistory(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getComplianceHistory: () => dispatch(actions.getComplianceHistory()),
    navToSiteComplianceReport: report => {
      track('Compliance', {
        Component: 'Compliance table',
        'Compliance action': 'Nav to report',
      });
      return dispatch(SiteActions.navToSiteComplianceReport(report));
    }
  };
}

export default withTracking('Site', 'Compliance List')(connect(mapStateToProps, mapDispatchToProps)(ComplianceList));
