import Capacity from './panels/capacity';
import Construction from './panels/construction';
import Installation from './panels/installation';
import Status from './panels/status';
import Product from './panels/product';
import LeakDetection from './panels/leakDetection';
import FillRiser from './panels/fillRiser';
import Overfill from './panels/overfill';
import SubmersibleTurbinePumps from './panels/submersibleTurbinePumps';
import VaporAdapters from './panels/vaporAdapters';
import Retrofits from './panels/retrofits';
import CathodicProtection from './panels/cathodicProtection';

export default {
  panels: [
    Installation,
    Construction,
    LeakDetection,
    CathodicProtection,
    Status,
    Capacity,
    SubmersibleTurbinePumps,
    FillRiser,
    Overfill,
    Product,
    VaporAdapters,
    Retrofits,
  ],
};
