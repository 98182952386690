import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import { connect } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../FuelHaulersSelectors';

class FuelHaulerDropdown extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.handleClick = this.handleClick.bind(this);
    this.props.getFuelHaulerList();
  }

  handleClick(event) {
    const id = event.target.value;
    let selectedFuelHauler = null;
    if (id !== '') {
      const idNumValue = Number.parseInt(id, 10);
      selectedFuelHauler = this.props.fuelHaulerList.filter(current => current.id === idNumValue)[0];
    }
    this.props.onFuelHaulerSelect(selectedFuelHauler);
  }

  render() {
    if (this.props.isLoading) {
      return (
        <PulseLoader color={'#F8E71C'} size={2} />
      );
    }

    let value = '';
    if (this.props.selectedFuelHauler) {
      value = this.props.selectedFuelHauler.id || this.props.selectedFuelHauler;
    }

    return (
      <Input
        type="select"
        id="fuelHauler"
        name="fuelHauler"
        value={value}
        className={this.props.className}
        onChange={this.handleClick}
      >
        <option value="">Show All</option>
        {this.props.fuelHaulerList.map(current => (
          <option value={current.id} key={current.id}>{current.name}</option>
        ))}
      </Input>
    );
  }
}

FuelHaulerDropdown.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fuelHaulerList: PropTypes.array.isRequired,
  selectedFuelHauler: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
    }),
    PropTypes.number,
  ]),
  className: PropTypes.string,
  getFuelHaulerList: PropTypes.func.isRequired,
  onFuelHaulerSelect: PropTypes.func.isRequired,
};

FuelHaulerDropdown.defaultProps = {
  selectedFuelHauler: null,
  className: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    fuelHaulerList: selectors.fuelHaulerList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFuelHaulerList: () => dispatch(actions.getFuelHaulerList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelHaulerDropdown);
