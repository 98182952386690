import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import CanaryLoadingIndicator from '../../../../../shared/components/CanaryLoadingIndicator';
import ErrorMessage from '../../../../../shared/components/error-message/ErrorMessage';
import Subheader from '../../../../../shared/components/Subheader';
import ComplianceReportCard from '../components/ComplianceReportCard';
import * as siteActions from '../../../actions';
import * as selectors from '../ComplianceSelectors';
import * as actions from '../actions';

class LatestComplianceSummary extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.navToReport = this.navToReport.bind(this);
  }

  navToReport() {
    this.props.navToSiteComplianceReport(this.props.latestCompliance, { tanks: false });
  }

  componentDidMount() {
    this.props.getLatestCompliance();
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.renderHeader()}
        {this.renderRows()}
        <div className="ml-4 mt-3">
          <Button color="link" onClick={this.props.navToSiteCompliance}>Go to Compliance</Button>
        </div>
      </div>
    );
  }

  renderHeader() {
    return <Subheader text="Compliance" />;
  }

  renderRows() {
    if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }
    else if (this.props.error) {
      return <ErrorMessage title="Failed to load latest compliance" error={this.props.error} />;
    }

    return <ComplianceReportCard report={this.props.latestCompliance} onReportClick={this.navToReport} />;
  }
}

LatestComplianceSummary.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  latestCompliance: PropTypes.shape({
    id: PropTypes.number,
    monthText: PropTypes.string,
    year: PropTypes.number,
    tankStatus: PropTypes.string,
    lineStatus: PropTypes.string,
    sensorStatus: PropTypes.string,
  }),
  getLatestCompliance: PropTypes.func.isRequired,
  navToSiteComplianceReport: PropTypes.func.isRequired,
  navToSiteCompliance: PropTypes.func.isRequired,
};

LatestComplianceSummary.defaultProps = {
  className: null,
  error: null,
  latestCompliance: null,
};

function mapStateToProps(state) {
  return {
    loading: selectors.isLatestComplianceLoading(state),
    latestCompliance: selectors.latestCompliance(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLatestCompliance: () => dispatch(actions.getLatestCompliance()),
    navToSiteComplianceReport: (report, options) => dispatch(siteActions.navToSiteComplianceReport(report, options)),
    navToSiteCompliance: () => dispatch(siteActions.navToSiteCompliance()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LatestComplianceSummary);
