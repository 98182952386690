import { combineReducers } from 'redux';

import { BreadcrumbReducer } from './components/breadcrumbs';
import ExportDestinationReducer from './components/export-destination/ExportDestinationReducer';
import DispatchSystemDropdownReducer from './components/dispatch-systems/DispatchSystemDropdownReducer';

import OrganizationReducer from './screens/organizations/OrganizationReducer';
import IngestFailureReducer from './screens/ingest-failures/IngestFailureReducer';
import InventoryReducer from './screens/inventory/InventoryReducer';
import SiteAdminReducer from './screens/sites/SiteAdminReducer';

const AdminReducer = combineReducers({
  // supporting components
  breadcrumbs: BreadcrumbReducer,
  exportDestination: ExportDestinationReducer,
  dispatchSystemDropdown: DispatchSystemDropdownReducer,

  // screens
  organizations: OrganizationReducer,
  ingestFailure: IngestFailureReducer,
  inventory: InventoryReducer,
  sites: SiteAdminReducer,
});

export default AdminReducer;
