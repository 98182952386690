/* eslint-disable react/no-unused-prop-types,comma-dangle,react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { DropTarget } from 'react-dnd';

import * as actions from '../actions';
import * as selectors from '../TankListSelectors';
import Atg from '../components/Atg';
import TankDragSource from '../components/TankDragSource';
import RegistrationDropTargetDragSource from '../components/RegistrationDropTargetDragSource';
import ConnectingLine from '../components/ConnectingLine';

class Step1MatchTanksContainer extends Component {
  render() {
    const leftColumnStyle = {};
    if (this.props.isOver) {
      leftColumnStyle.border = '1px dashed gray';
    }
    return (
      <div>
        <Row>
          {this.props.connectDropTarget(
            <div className="col col-md-6" style={leftColumnStyle}>
              <div className="d-flex">
                <div style={{ height: '100%' }}>
                  <ConnectingLine first />
                </div>
                <Atg atgInfo={this.props.atgInfo} />
              </div>
              {this.props.tanks.map((tank, index) => (
                <div key={`tank_${tank.id}`} className="d-flex">
                  <div style={{ height: '100%' }}>
                    <ConnectingLine last={index === this.props.tanks.length - 1} />
                  </div>
                  <TankDragSource key={tank.id} tank={tank} />
                </div>
              ))}
            </div>
          )}
          <Col md={6}>
            <div className="d-flex flex-column align-items-center">
              <h6>
                Drag the ATG tanks onto the imported data cards to match them. When all of the ATG tanks
                have been matched, click DONE MATCHING.
              </h6>
              <Button
                color="primary"
                className="my-2"
                disabled={!this.props.canConfirmMatches}
                onClick={() => this.props.showConfirmMatches()}
              >
                Done Matching
              </Button>
            </div>
            <h6>Imported Tank Data</h6>
            {this.props.registrations.map(reg => (
              <div key={`registration_${reg.id}`}>
                <hr />
                <RegistrationDropTargetDragSource
                  registration={reg}
                  linkTankAndRegistration={this.props.linkTankAndRegistration}
                />
              </div>
            ))}
          </Col>
        </Row>
      </div>
    );
  }
}

const unlinkTargetSpec = {
  drop(props, monitor) {
    const item = monitor.getItem();
    props.unlinkRegistration(item);
  },
};

function targetCollect(targetConnect, monitor) {
  return {
    connectDropTarget: targetConnect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

Step1MatchTanksContainer.propTypes = {
  atgInfo: PropTypes.object.isRequired,
  tanks: PropTypes.array.isRequired,
  registrations: PropTypes.array.isRequired,
  canConfirmMatches: PropTypes.bool.isRequired,

  linkTankAndRegistration: PropTypes.func.isRequired,
  unlinkRegistration: PropTypes.func.isRequired,
  showConfirmMatches: PropTypes.func.isRequired,

  // react-dnd target properties
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool,
};

Step1MatchTanksContainer.defaultProps = {
  isOver: false,
};

function mapStateToProps(state) {
  return {
    atgInfo: selectors.atgInfo(state),
    tanks: selectors.getTanks(state),
    registrations: selectors.getRegistrations(state),
    canConfirmMatches: selectors.canConfirmMatches(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    linkTankAndRegistration: (tank, registration) => dispatch(actions.linkTankAndRegistration(tank, registration)),
    unlinkRegistration: registration => dispatch(actions.unlinkRegistration(registration)),
    showConfirmMatches: () => dispatch(actions.showConfirmMatches()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  DropTarget('REG', unlinkTargetSpec, targetCollect)(Step1MatchTanksContainer)
);
