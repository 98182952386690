import axios from 'axios';

axios.defaults.baseURL = `${window.location.origin}/api`;

let token;

function addAuthHeader(request) {
  if (token) {
    request.headers = request.headers || {};
    request.headers.Authorization = token;
  }
}

function validateStatus(status) {
  return status >= 200 && status < 400;
}

function axiosRequest(method, url, params, data, headers, responseType) {
  const request = {
    method,
    url,
    validateStatus,
  };

  if (params) {
    request.params = params;
  }
  if (data) {
    request.data = data;
  }
  if (headers) {
    request.headers = headers;
  }

  if (responseType) {
    request.responseType = responseType;
  }
  addAuthHeader(request);

  return axios(request);
}

export function setAuthToken(authToken) {
  token = authToken;
}

export function clearAuthToken() {
  token = null;
}

export function Get(url, params, etag) {
  const headers = {};
  if (etag) {
    headers['If-None-Match'] = etag;
  }
  return axiosRequest('get', url, params, null, headers);
}

export function GetAccept(url, params, accepts) {
  const headers = {};
  if (accepts) {
    headers['Accept'] = accepts;
  }
  return axiosRequest('get', url, params, null, headers);
}

export function Post(url, payload) {
  return axiosRequest('post', url, null, payload);
}

export function PostWithResponseType(url, payload, responseType) {
  return axiosRequest('post', url, null, payload, null, responseType);
}

export function Patch(url, payload) {
  return axiosRequest('patch', url, null, payload);
}

export function JsonPatch(url, payload) {
  return axiosRequest('patch', url, null, payload, { 'Content-Type': 'application/json-patch+json' });
}

export function Put(url, payload) {
  return axiosRequest('put', url, null, payload);
}

export function Delete(url) {
  return axiosRequest('delete', url);
}

export function PostWithFile(url, params, payload, file) {
  const formData = new FormData();
  if (payload) {
    formData.append('body', JSON.stringify(payload));
  }
  if (file) {
    formData.append('file', file);
  }

  return axiosRequest('post', url, params, formData, { 'Content-Type': 'multipart/form-data' });
}

function normalizePath(path) {
  return path.startsWith('/') ? path : `/${path}`;
}

export function createPatchReplacement(path, value) {
  const objectPath = normalizePath(path);
  return {
    op: 'replace',
    path: objectPath,
    value,
  };
}

export function createSingletonPatchReplacement(path, value) {
  const patch = createPatchReplacement(path, value);
  return [patch];
}

export function createPatchAdd(path, value) {
  const objectPath = normalizePath(path);
  return {
    op: 'add',
    path: objectPath,
    value,
  };
}

export function createSingletonPatchAdd(path, value) {
  const patch = createPatchAdd(path, value);
  return [patch];
}
