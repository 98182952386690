import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import update from 'immutability-helper/index';

import { TimezonesByUSState } from '../../../AppConstants';
import OrganizationDropdownContainer from '../organization-dropdown/containers/OrganizationDropdownContainer';
import Timezone from '../timezone/Timezone';
import StateDropdown from '../state-dropdown/StateDropdown';

class SiteFields extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.handleCustomerSelect = this.handleCustomerSelect.bind(this);
    this.handleSiteNameChange = this.buildSetPropFunction('siteName');
    this.handleNicknameChange = this.buildSetPropFunction('nickname');
    this.handleFacilityIdChange = this.buildSetPropFunction('facilityId');
    this.handleBusinessNameChange = this.buildSetPropFunction('businessName');
    this.handleAddressChange = this.buildSetPropFunction('address');
    this.handleCityChange = this.buildSetPropFunction('city');
    this.handleStateSelect = this.handleStateSelect.bind(this);
    this.handleZipChange = this.buildSetPropFunction('zip');
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
    this.handleEasyVistaIdChange = this.handleEasyVistaIdChange.bind(this);
    this.save = this.save.bind(this);
  }

  buildSetPropFunction(propName) {
    function func(event) {
      const updateSpec = {};
      let newValue;
      if (event.target.type === 'checkbox') {
        newValue = event.target.checked;
      }
      else {
        newValue = event.target.value || '';
      }
      updateSpec[propName] = {
        $set: newValue,
      };
      const updatedSite = update(this.props.site, updateSpec);
      this.props.onUpdate(updatedSite);
    }

    return func.bind(this);
  }

  handleCustomerSelect(customer) {
    const updatedSite = update(this.props.site, {
      customer: {
        $set: customer,
      }
    });
    this.props.onUpdate(updatedSite);
  }

  handleStateSelect(stateAbbreviation) {
    const updatedSite = update(this.props.site, {
      state: {
        $set: stateAbbreviation,
      }
    });
    this.props.onUpdate(updatedSite);
  }

  handleTimezoneChange(newTimezone) {
    this.props.onUpdate(update(this.props.site, {
      siteTimezone: {
        $set: newTimezone,
      },
    }));
  }

  handleEasyVistaIdChange(easyVistaId) {
    const existing = this.props.site.externalIds && this.props.site.externalIds.find(externalId => externalId.externalSupplierName === 'EasyVista');
    if (existing) {
      const index = this.props.site.externalIds.indexOf(existing);
      this.props.onUpdate(update(this.props.site, {
        externalIds: {
          [index]: {
            externalSupplierName: {
              $set: 'EasyVista',
            },
            externalId: {
              $set: easyVistaId,
            },
          },
        },
      }));
    }
    else {
      const newEasyVistaId = {
        externalSupplierName: 'EasyVista',
        externalId: easyVistaId,
      };

      if (this.props.site.externalIds && this.props.site.externalIds.length) {
        this.props.onUpdate(update(this.props.site, {
          externalIds: {
            $push: [newEasyVistaId],
          },
        }));
      }
      else {
        this.props.onUpdate(update(this.props.site, {
          externalIds: {
            $set: [newEasyVistaId],
          },
        }));
      }
    }
  }

  isSiteNameInvalid() {
    const site = this.props.site;
    const prefix = this.siteNamePrefix();

    return !!(site.siteName && prefix && (!site.siteName.startsWith(prefix) || !site.siteName.match(/^[A-Z]{3}-[A-Z]{2}\d{4}$/)));
  }

  siteNamePrefix() {
    const site = this.props.site;
    const customer = site.customer;

    if (!customer) {
      return null;
    }

    let prefix = `${customer.abbreviation}-`;
    if (site.state) {
      prefix += site.state;
    }
    return prefix;
  }

  isZipInvalid() {
    const site = this.props.site;
    return !!(site.zip && !site.zip.match(/^\d{5}(?:-\d{4})?/));
  }

  isTimezoneDisabled() {
    if (this.props.disabled) {
      return true;
    }
    const site = this.props.site;
    const correctTimezone = TimezonesByUSState[site.state];
    return !!(site.id && site.siteTimezone && site.siteTimezone === correctTimezone);
  }

  isSaveDisabled() {
    // if our parent says the save button should be disabled then that overrides the logic here
    if (!this.props.canSave || this.props.disabled) {
      return true;
    }

    // even if the parent says it's OK to save we still want to check for validity
    const site = this.props.site;
    return (!site.siteName && !site.nickname)
      || !site.customer
      || !site.siteTimezone
      || this.isSiteNameInvalid()
      || this.isZipInvalid();
  }

  save() {
    const internalSite = this.props.site;
    const site = {
      customerId: internalSite.customer.id,
      siteName: internalSite.siteName || null,
      nickname: internalSite.nickname || null,
      businessName: internalSite.businessName || null,
      address: internalSite.address || null,
      city: internalSite.city || null,
      state: internalSite.state,
      zip: internalSite.zip || null,
      siteTimezone: internalSite.siteTimezone,
      facilityId: internalSite.facilityId,
      externalIds: internalSite.externalIds,
    };
    this.props.onSave(site);
  }

  renderCustomerSelector() {
    if (this.props.showCustomerSelector) {
      return (
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="customer">Customer</Label>
              <OrganizationDropdownContainer selectedOrganization={this.props.site.customer} onOrganizationSelect={this.handleCustomerSelect} customerOnly enabledOnly disabled={this.props.disabled} />
            </FormGroup>
          </Col>
        </Row>
      );
    }
    return null;
  }

  renderSiteName() {
    if (this.props.showSiteName) {
      const site = this.props.site;
      const existingSite = !!site.id;
      return (
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="nickname">Site Name</Label>
              <Input
                type="text"
                id="nickname"
                name="nickname"
                placeholder="Customer facing site name"
                value={site.nickname || ''}
                onChange={this.handleNicknameChange}
                disabled={this.props.disabled}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="name">Canary Site ID</Label>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Leave blank to auto generate"
                value={site.siteName || ''}
                onChange={this.handleSiteNameChange}
                invalid={this.isSiteNameInvalid()}
                disabled={existingSite || this.props.disabled}
              />
              {this.isSiteNameInvalid() ? (
                <FormFeedback>
                  Site name must start with
                  {this.siteNamePrefix()}
                  {' '}
                  and end with four digits
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
      );
    }
    return null;
  }

  renderButtons() {
    if (this.props.onSave || this.props.onCancel) {
      return (
        <div className="mt-4">
          {this.props.onCancel ? <Button id="cancel" color="primary" outline size="sm" onClick={this.props.onCancel} disabled={!this.props.canCancel || this.props.disabled}>{this.props.cancelButtonLabel}</Button> : null}
          {this.props.onSave && this.props.onCancel ? ' ' : null}
          {this.props.onSave ? <Button color="primary" size="sm" onClick={this.save} disabled={this.isSaveDisabled()}>{this.props.saveButtonLabel}</Button> : null}
        </div>
      );
    }
    return null;
  }

  render() {
    if (!this.props.site) {
      return null;
    }

    const site = this.props.site;
    const existingSite = !!site.id;

    const easyVista = site.externalIds && site.externalIds.find(externalId => externalId.externalSupplierName === 'EasyVista');
    const easyVistaId = (easyVista && easyVista.externalId) || '';

    return (
      <Form className={this.props.className}>
        {this.renderCustomerSelector()}
        {this.renderSiteName()}
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="businessName">Business Name</Label>
              <Input
                type="text"
                id="businessName"
                name="businessName"
                placeholder="Business Name"
                value={site.businessName || ''}
                onChange={this.handleBusinessNameChange}
                disabled={this.props.disabled}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="address">Address</Label>
              <Input
                type="text"
                id="address"
                name="address"
                placeholder="Street address"
                value={site.address || ''}
                onChange={this.handleAddressChange}
                disabled={this.props.disabled}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="facilityId">Facility Id</Label>
              <Input
                type="text"
                id="facilityId"
                name="facilityId"
                placeholder="Facility id"
                value={site.facilityId || ''}
                onChange={this.handleFacilityIdChange}
                disabled={this.props.disabled}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="city">City</Label>
              <Input
                type="text"
                id="city"
                name="city"
                placeholder="City"
                value={site.city || ''}
                onChange={this.handleCityChange}
                disabled={this.props.disabled}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="state">State</Label>
              <StateDropdown abbreviation={site.state} onStateSelect={this.handleStateSelect} disabled={existingSite || this.props.disabled} />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="zip">Zip</Label>
              <Input
                type="text"
                id="zip"
                name="zip"
                placeholder="Zip"
                value={site.zip || ''}
                onChange={this.handleZipChange}
                invalid={this.isZipInvalid()}
                disabled={this.props.disabled}
              />
              {this.isZipInvalid() ? <FormFeedback>Invalid zip</FormFeedback> : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="timezone">Timezone</Label>
              <Timezone value={site.siteTimezone} onTimezoneSelect={this.handleTimezoneChange} disabled={this.isTimezoneDisabled()} />
            </FormGroup>
          </Col>
        </Row>
        {this.props.showEasyVistaId
          ? (
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="easyVistaId">EasyVista Site ID</Label>
                  <Input
                    type="text"
                    id="easyVistaId"
                    name="easyVistaId"
                    placeholder="PA 00000"
                    value={easyVistaId}
                    onChange={e => this.handleEasyVistaIdChange(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : null }
        {this.props.children}
        {this.renderButtons()}
      </Form>
    );
  }
}

SiteFields.propTypes = {
  className: PropTypes.string,
  site: PropTypes.object,
  showCustomerSelector: PropTypes.bool,
  showSiteName: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  saveButtonLabel: PropTypes.string,
  canSave: PropTypes.bool,
  onSave: PropTypes.func,
  cancelButtonLabel: PropTypes.string,
  canCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  showEasyVistaId: PropTypes.bool,
};

SiteFields.defaultProps = {
  className: null,
  site: null,
  showCustomerSelector: true,
  showSiteName: true,
  saveButtonLabel: 'Save',
  canSave: true,
  onSave: null,
  cancelButtonLabel: 'Cancel',
  canCancel: true,
  onCancel: null,
  children: null,
  disabled: false,
  showEasyVistaId: false
};

export default SiteFields;
