import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import * as selectors from './WorkflowSelectors';
import * as actions from './WorkflowActions';


const WorkflowConfirmModal = ({ isOpen, confirm, cancel }) => (
  <Modal isOpen={isOpen} toggle={cancel}>
    <ModalHeader toggle={cancel}>
      Close Issue
    </ModalHeader>
    <ModalBody>
      Before closing this issue, please be sure you have attached any tech notes, made any comments and checked to be sure the issue
      is no longer active. Once closed, this issue cannot be changed at all.
    </ModalBody>
    <ModalFooter>
      <Button color="primary" size="sm" outline onClick={cancel}>Cancel</Button>
      <Button
        color="primary"
        size="sm"
        onClick={confirm}
      >
        Close
      </Button>
    </ModalFooter>
  </Modal>
);

WorkflowConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isOpen: selectors.isModalOpen(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    confirm: () => dispatch(actions.resolveIssue()),
    cancel: () => dispatch(actions.cancelConfirmationModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowConfirmModal);
