import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';

import RelativeTimestamp from '../../../shared/components/date/RelativeTimestamp';


const AlarmCard = ({ className, title, date }) => (
  <Card className={className}>
    <CardBody className="d-flex align-items-center justify-content-between">
      <FontAwesomeIcon icon={faBell} className="mr-3 text-danger" />
      <div className="flex-grow-1 d-flex align-items-center justify-content-between flex-wrap">
        <strong className="mr-2">
          {title}
        </strong>
        <span className="text-nowrap">
          <RelativeTimestamp time={date} />
        </span>
      </div>

    </CardBody>
  </Card>
);

AlarmCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

AlarmCard.defaultProps = {
  className: null,
};

export default AlarmCard;
