import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedConnectionTypes = ({ selectedConnectionTypes, disabled, deselectConnectionType }) => <FilterItemList items={selectedConnectionTypes} textField="name" disabled={disabled} onRemoveItem={deselectConnectionType} />;

SelectedConnectionTypes.propTypes = {
  selectedConnectionTypes: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  deselectConnectionType: PropTypes.func.isRequired,
};

SelectedConnectionTypes.defaultProps = {
  selectedConnectionTypes: [],
};

export default SelectedConnectionTypes;
