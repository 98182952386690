import update from 'immutability-helper';
import ActionTypes from './AtgReportsActionTypes';

const MaxEvents = 100;

function receiveCommandNames(state, commandNamesByFuncCode) {
  return Object.assign({}, state, {
    isLoadingCommandNames: false,
    commandNamesByFuncCode,
  });
}

function buildReports(state, events) {
  const commandNamesByFuncCode = state.commandNamesByFuncCode;
  if (!events || !events.length) {
    return Object.assign({}, state, {
      reports: [],
      tableOfContents: [],
    });
  }
  let sortedEvents = events.map(event => addFunctionName(event, commandNamesByFuncCode)).sort(compareEvents);
  if (sortedEvents.length > MaxEvents) {
    sortedEvents = latestEventsOnly(sortedEvents);
  }
  return Object.assign({}, state, {
    reports: sortedEvents,
    tableOfContents: buildToc(sortedEvents),
  });
}

function latestEventsOnly(sortedEvents) {
  const funcCodes = {};
  return sortedEvents
    .map(current => {
      if (funcCodes[current.functionCode] === undefined) {
        funcCodes[current.functionCode] = true;
        return current;
      }
      return null;
    })
    .filter(current => !!current);
}

function addFunctionName(event, commandNamesByFuncCode) {
  const functionCode = extractFunctionCode(event.commandText);
  const functionName = commandNamesByFuncCode[functionCode] || 'Unknown command';
  return update(event, {
    functionCode: { $set: functionCode },
    functionName: { $set: functionName },
  });
}

function extractFunctionCode(commandText) {
  return commandText && commandText.substring(1, 4);
}

function compareEvents(a, b) {
  const nameA = a.commandText.toLowerCase();
  const nameB = b.commandText.toLowerCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return new Date(b.receivedDate).getTime() - new Date(a.receivedDate).getTime();
}

function buildToc(events) {
  const processedFunctionCodes = {};
  const toc = [];
  events.forEach(event => {
    if (!processedFunctionCodes[event.functionCode]) {
      processedFunctionCodes[event.functionCode] = true;
      toc.push({
        title: `${event.commandText} ${event.functionName}`,
        eventId: event.id,
      });
    }
  });
  return toc;
}

const InitialState = {
  commandNamesByFuncCode: {},
};

export default function AtgReportsReducer(state = InitialState, action) {
  switch (action.type) {
    case ActionTypes.GET_COMMAND_NAMES_STARTED:
      return Object.assign({}, state, {
        isLoadingCommandNames: true,
      });
    case ActionTypes.RECEIVE_COMMAND_NAMES:
      return receiveCommandNames(state, action.commandNamesByFuncCode);
    case ActionTypes.GET_COMMAND_NAMES_FAILED:
      return Object.assign({}, state, {
        isLoadingCommandNames: false,
      });

    case ActionTypes.RECEIVE_EVENTS:
      return buildReports(state, action.events);
    case ActionTypes.RESET_EVENTS:
      return Object.assign({}, state, {
        reports: [],
        tableOfContents: [],
      });
    default:
      return state;
  }
}
