export default {
  GET_INVENTORY_FORMAT_LIST_STARTED: 'admin/get_inventory_format_list_started',
  GET_INVENTORY_FORMAT_LIST_FAILED: 'admin/get_inventory_format_list_failed',
  RECEIVE_INVENTORY_FORMAT_LIST: 'admin/receive_inventory_format_list',

  GET_INVENTORY_COLUMN_CATEGORIES_STARTED: 'admin/get_inventory_column_categories_started',
  GET_INVENTORY_COLUMN_CATEGORIES_FAILED: 'admin/get_inventory_column_categories_failed',
  RECEIVE_INVENTORY_COLUMN_CATEGORIES: 'admin/receive_inventory_column_categories',

  GET_INVENTORY_FORMAT_STARTED: 'admin/get_inventory_format_started',
  GET_INVENTORY_FORMAT_FAILED: 'admin/get_inventory_format_failed',
  RECEIVE_INVENTORY_FORMAT: 'admin/receive_inventory_format',

  SAVE_INVENTORY_FORMAT_STARTED: 'admin/save_inventory_format_started',
  SAVE_INVENTORY_FORMAT_SUCCESS: 'admin/save_inventory_format_success',
  SAVE_INVENTORY_FORMAT_FAILED: 'admin/save_inventory_format_failed',

  DELETE_INVENTORY_FORMAT_STARTED: 'admin/delete_inventory_format_started',
  DELETE_INVENTORY_FORMAT_COMPLETE: 'admin/delete_inventory_format_complete',
};
