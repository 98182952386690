import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button } from 'reactstrap';

import { track } from '../../../../../../shared/analytics';
import * as IssueSelectors from '../issue-edit/IssueEditSelectors';
import IssueOrganizerModal from './IssueOrganizerModal';

class IssueOrganizerButton extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      issueOrganizerVisible: false,
    };

    this.launchIssueOrganizer = this.launchIssueOrganizer.bind(this);
    this.closeIssueOrganizer = this.closeIssueOrganizer.bind(this);
  }

  launchIssueOrganizer() {
    track('Issue', {
      Component: 'Issue organizer',
      'Issue action': 'Open organizer',
      'Issue type': this.props.issue.issueType
    });
    this.setState({ issueOrganizerVisible: true });
  }

  closeIssueOrganizer() {
    this.setState({ issueOrganizerVisible: false });
  }

  render() {
    if (this.props.issue && this.props.canEditIssue) {
      const relatedIssues = this.props.issue.relatedIssues || [];
      const count = relatedIssues.length + 1;
      const description = count === 1 ? '1 open issue at this site' : `${count} open issues at this site`;

      return (
        <>
          <Button color="link" onClick={this.launchIssueOrganizer} className={this.props.className}>{description}</Button>
          <IssueOrganizerModal issueOrganizerVisible={this.state.issueOrganizerVisible} onCloseIssueOrganizer={this.closeIssueOrganizer} />
        </>
      );
    }
    return null;
  }
}

IssueOrganizerButton.propTypes = {
  issue: PropTypes.object,
  canEditIssue: PropTypes.bool,
  className: PropTypes.string,
};

IssueOrganizerButton.defaultProps = {
  issue: null,
  canEditIssue: false,
  className: null,
};

function mapStateToProps(state) {
  return {
    issue: IssueSelectors.currentIssue(state),
    canEditIssue: IssueSelectors.canEditCurrentIssue(state),
  };
}

export default withRouter(connect(mapStateToProps)(IssueOrganizerButton));
