import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const HelpIcon = ({ onClick, className }) => (
  <a data-toggle="tooltip" data-placement="top" title="Help" className={className} onClick={onClick}>
    <FontAwesomeIcon className="text-muted" icon={faQuestionCircle} />
  </a>
);

HelpIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

HelpIcon.defaultProps = {
  className: null,
};

export default HelpIcon;
