import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import * as siteActions from '../../../../../actions';
import * as selectors from '../TankListSelectors';
import Registration from '../components/Registration';
import Atg from '../components/Atg';
import TankAndRegistration from '../components/TankAndRegistration';
import ConnectingLine from '../components/ConnectingLine';

class Step3DisplayListContainer extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.renderTankAndRegistration = this.renderTankAndRegistration.bind(this);
    this.renderRegistration = this.renderRegistration.bind(this);
  }

  renderTankAndRegistration(tank, index) {
    const key = `tank_${tank.id}`;
    return (
      <div key={key} className="d-flex">
        <ConnectingLine className="d-none d-md-block" last={index === this.props.tanks.length - 1} />
        <TankAndRegistration
          className="my-2 mx-2"
          tank={tank}
          registration={tank.registration}
          navToTankRegistration={this.props.navToTankRegistration}
        />
      </div>
    );
  }

  renderRegistration(registration) {
    const key = `registration_${registration.id}`;
    return (
      <div key={key}>
        <Registration registration={registration} />
        <hr />
      </div>
    );
  }

  render() {
    const unmatchedRegistrations = this.props.registrations.filter(current => current.tank === null || current.tank === undefined);
    return (
      <div>
        <Row>
          <Col xs={12}>
            <div className="d-flex">
              <ConnectingLine className="d-none d-md-block" first />
              <Atg atgInfo={this.props.atgInfo} />
            </div>
            {this.props.tanks.map(this.renderTankAndRegistration)}
            {unmatchedRegistrations.length ?
              <h5 className="my-4">Other Tanks (not connected to the ATG)</h5>
              : null
            }
            {unmatchedRegistrations.map(this.renderRegistration)}
          </Col>
        </Row>
      </div>
    );
  }
}

Step3DisplayListContainer.propTypes = {
  atgInfo: PropTypes.object.isRequired,
  tanks: PropTypes.array.isRequired,
  registrations: PropTypes.array.isRequired,
  navToTankRegistration: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    atgInfo: selectors.atgInfo(state),
    tanks: selectors.getTanks(state),
    registrations: selectors.getRegistrations(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navToTankRegistration: (tank, registration) => dispatch(siteActions.navToTankRegistration(tank, registration)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step3DisplayListContainer);
