import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import OrganizationTable from '../screens/organization-list/containers/OrganizationTable';
import OrganizationEditor from '../screens/organization-edit/containers/OrganizationEditor';
import OrganizationView from '../screens/organization-edit/containers/OrganizationView';

function OrganizationNav() {
  return (
    <Switch>
      <Route exact path="/admin/organizations" name="organizationList">
        <OrganizationTable />
      </Route>
      <Route exact path="/admin/organizations/new" name="newOrganizationView">
        <OrganizationEditor />
      </Route>
      <Route exact path="/admin/organizations/:organizationId/edit" name="organizationEditView">
        <OrganizationEditor />
      </Route>
      <Route path="/admin/organizations/:organizationId" name="organizationView">
        <OrganizationView />
      </Route>
    </Switch>
  );
}

OrganizationNav.propTypes = {
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    // HACK: Force the page to update on navigate
    router: state.router,
  };
}

export default withRouter(connect(mapStateToProps)(OrganizationNav));
