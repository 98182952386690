import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import CanaryLoadingIndicator from '../../../../../shared/components/CanaryLoadingIndicator';
import { relativeTimestampAsText } from '../../../../../shared/components/date';
import { InventoryStatus } from '../../../../../AppConstants';
import LowTankIcon from '../components/LowTankIcon';
import Subheader from '../../../../../shared/components/Subheader';
import * as SiteActions from '../../../actions';
import * as selectors from '../InventorySelectors';
import * as actions from '../actions';

class DeliveryNeededTanks extends Component {
  componentDidMount() {
    this.props.getInventory();
  }

  render() {
    const tanks = this.props.inventory && this.props.inventory.filter(i => i.alerts && i.alerts.deliveryNeeded);
    const text = `Deliveries Needed (${(tanks && tanks.length) || 0})`;
    return (
      <div className={this.props.className}>
        <Subheader text={text} />
        {this.renderLastUpdated()}
        {this.renderBody(tanks)}
        <div className="mt-3 ml-4">
          <Button color="link" onClick={this.props.navToSiteInventory}>Go to Inventory</Button>
        </div>
      </div>
    );
  }

  renderLastUpdated() {
    const inventory = this.props.inventory;
    if (!inventory) {
      return null;
    }

    let latestInventoryDate;
    inventory.forEach(i => {
      if (i.inventoryDate) {
        if (!latestInventoryDate) {
          latestInventoryDate = new Date(i.inventoryDate);
        }
        else if (new Date(i.inventoryDate) > latestInventoryDate) {
          latestInventoryDate = new Date(i.inventoryDate.inventoryDate);
        }
      }
    });

    return latestInventoryDate ? (
      <div className="ml-4 my-2">
        <span className="text-muted">{`Updated ${relativeTimestampAsText(latestInventoryDate)}`}</span>
      </div>
    ) : null;
  }

  renderBody(tanks) {
    if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }
    else if (!tanks) {
      return (
        <div className="ml-4 my-2">
          <h5>Inventory data unavailable</h5>
        </div>
      );
    }
    else if (tanks.length) {
      return (
        <div className="d-flex flex-wrap justify-content-around">
          {tanks.map(tank => (
            <LowTankIcon
              key={tank.tankNumber}
              tankNumber={tank.tankNumber}
              productLabel={tank.productLabel}
              fullVolumeGallons={tank.fullVolumeGallons}
              volumeGallons={tank.volumeGallons && Math.floor(tank.volumeGallons)}
              ullageGallons={tank.ullageGallons && Math.floor(tank.ullageGallons)}
              ullage90PercentGallons={tank.ullage90PercentGallons && Math.floor(tank.ullage90PercentGallons)}
              waterHeightInches={tank.inventoryDate && tank.waterHeightInches && Math.floor(tank.waterHeightInches)}
              style={{ minWidth: '17rem', maxWidth: '18rem' }}
            />
          ))}
        </div>
      );
    }
    return (
      <div className="ml-4 my-2">
        <h5>No tanks need a delivery</h5>
      </div>
    );
  }
}

DeliveryNeededTanks.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  inventory: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteId: PropTypes.number,
    inventoryDate: PropTypes.string,
    volumeGallons: PropTypes.number,
    ullageGallons: PropTypes.number,
    waterHeightInches: PropTypes.number,
    alerts: PropTypes.shape({
      deliveryNeeded: PropTypes.bool,
      waterWarning: PropTypes.bool,
    }),
    ullage90PercentGallons: PropTypes.number,
    tankNumber: PropTypes.number,
    productLabel: PropTypes.string,
    fullVolumeGallons: PropTypes.number,
    status: PropTypes.oneOf([InventoryStatus.GreaterThanSeventyFivePercent, InventoryStatus.LessThanSeventyFivePercent, InventoryStatus.DeliveryNeeded, InventoryStatus.Unknown]),
  })),
  getInventory: PropTypes.func.isRequired,
  navToSiteInventory: PropTypes.func.isRequired,
};

DeliveryNeededTanks.defaultProps = {
  className: null,
  inventory: null,
};

function mapStateToProps(state) {
  return {
    loading: selectors.isInventoryLoading(state),
    inventory: selectors.inventory(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInventory: () => dispatch(actions.getInventory()),
    navToSiteInventory: () => dispatch(SiteActions.navToSiteInventory()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryNeededTanks);
