import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Alert, Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import ErrorMessage from '../../../../../shared/components/error-message/ErrorMessage';
import { WorkflowStatusTypes } from '../../../../../AppConstants';
import { DispatchModalState } from '../SiteDispatchConstants';
import * as actions from '../actions';
import * as selectors from '../SiteDispatchSelectors';
import {track} from '../../../../../shared/analytics';


class CreateDispatchModal extends Component {
  render() {
    const open = this.props.modalState !== DispatchModalState.Closed;
    return (
      <Modal className="modal-lg" isOpen={open} toggle={this.props.closeModal}>
        <ModalHeader>
          {this.renderModalHeader()}
        </ModalHeader>
        {this.renderInternal()}
      </Modal>
    );
  }

  renderModalHeader() {
    if (this.props.dispatchableIssues && this.props.dispatchableIssues.length) {
      return `Create Work Order at ${this.props.dispatchableIssues[0].siteNickname}`;
    }
    return 'Create Work Order';
  }

  renderInternal() {
    switch (this.props.modalState) {
      case DispatchModalState.Loading:
        return <PulseLoader color="#F8E71C" size={8} />;
      case DispatchModalState.SelectIssues:
        return this.renderIssueForm();
      case DispatchModalState.SetTechNotes:
      case DispatchModalState.Saving:
        return this.renderTechNotesForm();
      case DispatchModalState.Done:
        return this.renderReview();
      case DispatchModalState.Error:
        return this.renderError();
      case DispatchModalState.Closed:
      default:
        return null;
    }
  }

  renderIssueForm() {
    const selectedIssues = this.props.dispatchableIssues.filter(current => current.selected);
    return (
      <>
        <ModalBody>
          <Form>
            <h6>Select the open issues to include in this work order:</h6>
            {this.props.dispatchableIssues.map(issue => this.renderDispatchableIssueSelect(issue)) }
            {this.renderNonDispatchableIssues()}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline size="sm" onClick={this.props.closeModal}>Cancel</Button>
          {' '}
          <Button color="primary" size="sm" onClick={this.props.nextStep} disabled={selectedIssues.length === 0}>Next</Button>
        </ModalFooter>
      </>
    );
  }

  renderDispatchableIssueSelect(issue) {
    return (
      <FormGroup check key={issue.id}>
        <Label check>
          <Input
            type="checkbox"
            checked={issue.selected}
            onChange={() => this.props.toggleIssueSelect(issue)}
          />
          {this.renderDescription(issue)}
        </Label>
      </FormGroup>
    );
  }

  renderNonDispatchableIssues() {
    if (!this.props.nonDispatchableIssues || !this.props.nonDispatchableIssues.length) {
      return null;
    }
    return (
      <div className="mt-2">
        <h6>These issues are open but cannot be included in this dispatch:</h6>
        <ul className="pl-4">
          {this.props.nonDispatchableIssues.map(issue => this.renderNonDispatchableIssueSelect(issue)) }
        </ul>
      </div>
    );
  }

  renderNonDispatchableIssueSelect(issue) {
    let reason = '(already dispatched)';
    if (issue.workflowStatus === WorkflowStatusTypes.NewUnread || issue.workflowStatus === WorkflowStatusTypes.New || issue.workflowStatus === WorkflowStatusTypes.Draft) {
      reason = '(still analyzing)';
    }
    return (
      <li key={issue.id}>
        {this.renderDescription(issue)}
        <em className="ml-1">{reason}</em>
      </li>
    );
  }

  renderTechNotesForm() {
    const saving = this.props.modalState === DispatchModalState.Saving;
    return (
      <>
        <ModalBody>
          <Form>
            <h6>Notes to Service Tech</h6>
            {this.props.dispatchableIssues
              .filter(issue => issue.selected)
              .map(issue => this.renderIssueTechNotes(issue)) }
            <h6>Work Order Text</h6>
            <Input type="textarea" rows="8" value={this.props.workOrderText} readOnly />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline size="sm" onClick={this.props.closeModal} disabled={saving}>Cancel</Button>
          {' '}
          <Button color="primary" size="sm" onClick={this.props.createDispatch} disabled={saving}>Create</Button>
        </ModalFooter>
      </>
    );
  }

  renderIssueTechNotes(issue) {
    const name = `techNotes${issue.id}`;
    const placeholder = `Notes to tech about Issue ${issue.id}: ${issue.description}`;
    return (
      <FormGroup key={issue.id}>
        <Label for={name}>
          {this.renderDescription(issue)}
        </Label>
        <Input type="textArea" id={name} name={name} rows="3" placeholder={placeholder} value={issue.notesToTech} onChange={e => this.props.updateNotesToTech(issue, e.target.value)} />
      </FormGroup>
    );
  }

  renderDescription(issue) {
    return (
      <span>
        ISSUE
        {' '}
        {issue.id}
        {': '}
        {issue.description}
      </span>
    );
  }

  renderReview() {
    const description = `Created ${this.props.newDispatch.dispatchSystemName} work order ${this.props.newDispatch.externalId}`;
    return (
      <>
        <ModalBody>
          <Alert color="success">
            {description}
          </Alert>
          <div className="mt-2">
            <h6>The following issues were successfully dispatched:</h6>
            <ul className="pl-4">
              {this.props.dispatchableIssues
                .filter(current => current.selected)
                .map(issue => (
                  <li key={issue.id}>
                    {this.renderDescription(issue)}
                  </li>
                ))}
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" onClick={this.props.closeModal}>Close</Button>
        </ModalFooter>
      </>
    );
  }

  renderError() {
    return (
      <>
        <ModalBody>
          <ErrorMessage error={this.props.error} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" onClick={this.props.closeModal}>Close</Button>
        </ModalFooter>
      </>
    );
  }
}

CreateDispatchModal.propTypes = {
  modalState: PropTypes.string.isRequired,
  error: PropTypes.object,
  dispatchableIssues: PropTypes.array.isRequired,
  nonDispatchableIssues: PropTypes.array.isRequired,
  workOrderText: PropTypes.string,
  newDispatch: PropTypes.object,
  toggleIssueSelect: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  updateNotesToTech: PropTypes.func.isRequired,
  createDispatch: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

CreateDispatchModal.defaultProps = {
  error: null,
  workOrderText: null,
  newDispatch: null,
};

function mapStateToProps(state) {
  return {
    modalState: selectors.dispatchModalState(state),
    error: selectors.dispatchModalError(state),
    dispatchableIssues: selectors.dispatchableIssues(state),
    nonDispatchableIssues: selectors.nonDispatchableIssues(state),
    workOrderText: selectors.workOrderText(state),
    newDispatch: selectors.newDispatch(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleIssueSelect: issue => dispatch(actions.toggleIssueSelect(issue)),
    nextStep: () => dispatch(actions.nextStep()),
    updateNotesToTech: (issue, notes) => dispatch(actions.updateNotesToTech(issue, notes)),
    createDispatch: () => {
      track('Dispatch', {
        Component: 'Dispatch modal',
        'Dispatch action': 'Create',
      });
      return dispatch(actions.createDispatch());
    },
    closeModal: () => dispatch(actions.closeCreateDispatchModal()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateDispatchModal));
