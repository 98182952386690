import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';

const SiteAlarmCount = ({ site }) => {
  if (site.activeAlarmCount) {
    return (
      <div className="d-inline">
        <FontAwesomeIcon icon={faBell} className="mr-3 text-danger" />
        {site.activeAlarmCount}
      </div>
    );
  }
  return null;
};

SiteAlarmCount.propTypes = {
  site: PropTypes.object.isRequired,
};

export default SiteAlarmCount;
