import update from 'immutability-helper';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import { DefaultState, buildReducer, buildResetOnNavActionReducer } from '../../../../../../../../shared/redux-helpers/ReducerFactory';
import UploadActionTypes from '../../../../../documents/components/upload/UploadActionTypes';
import DocActionTypes from '../../../../../documents/SiteDocumentActionTypes';
import MviEditActionTypes from '../../MviEditActionTypes';
import ReportActionTypes from './MviReportActionTypes';
import ActionItemsReducer from './action-items/ActionItemsReducer';

function updateFromMviEdit(state, mvi) {
  const currentReport = state.item;
  const incomingReports = mvi.reports || [];
  if (currentReport) {
    const updatedReportIndex = incomingReports.findIndex(current => current.id === currentReport.id);
    const updatedReport = incomingReports[updatedReportIndex];
    return update(state, {
      item: {
        $set: updatedReport,
      }
    });
  }
  return state;
}

function addDoc(state, action) {
  const currentReportId = state.item && state.item.id;
  if (currentReportId) {
    const document = action.document;
    const docMviReportIds = (action.document && action.document.mviReports && action.document.mviReports.map(current => current.id)) || [];
    if (docMviReportIds.indexOf(currentReportId) !== -1) {
      const docUpdate = (state.item && state.item.documents) ? { $push: [document] } : { $set: [document] };
      return update(state, {
        item: {
          documents: docUpdate
        }
      });
    }
  }
  return state;
}

function unlinkDoc(state, action) {
  const doc = action.document;
  const currentReport = state.item;
  if (!doc || !currentReport || !currentReport.documents || !currentReport.documents.length) {
    return state;
  }
  else if (doc && doc.mviReports && doc.mviReports.some(current => current.id === currentReport.id)) {
    return state;
  }

  const updatedDocs = currentReport.documents.filter(current => current.id !== doc.id);
  return update(state, {
    item: {
      documents: {
        $set: updatedDocs,
      }
    }
  });
}

function deleteDoc(state, action) {
  if (!state.item) {
    return state;
  }

  const deletedIds = action.documents.map(current => current.id);
  const currentReport = state.item;
  const updatedDocs = currentReport.documents.filter(current => deletedIds.indexOf(current.id) === -1);
  return update(state, {
    item: {
      documents: {
        $set: updatedDocs,
      }
    }
  });
}

function ReportRootReducer(state = {}, action) {
  switch (action.type) {
    case ReportActionTypes.RECEIVE_LIST:
      return Object.assign({}, state, {
        list: action.list,
      });
    case ReportActionTypes.RECEIVE_ITEM:
      return Object.assign({}, state, {
        item: action.item,
      });

    case MviEditActionTypes.SAVE_ITEM_SUCCESS:
      return updateFromMviEdit(state, action.item);

    case ReportActionTypes.SAVE_ITEM_SUCCESS:
      return update(state, {
        $unset: ['createModalOpen', 'editingInspectorComments', 'pendingInspectorCommentsEdits'],
      });
    case ReportActionTypes.DELETE_ITEM_SUCCESS:
      return Object.assign({}, state, {
        item: (state.list && state.list.length && state.list[0]) || null,
        deleteModalOpen: false,
      });

    case ReportActionTypes.OPEN_CREATE_MODAL:
      return Object.assign({}, state, {
        createModalOpen: true,
      });
    case ReportActionTypes.CLOSE_CREATE_MODAL:
      return Object.assign({}, state, {
        createModalOpen: false,
      });

    case ReportActionTypes.OPEN_DELETE_MODAL:
      return Object.assign({}, state, {
        deleteModalOpen: true,
      });
    case ReportActionTypes.DELETE_ITEM_FAILED:
    case ReportActionTypes.CLOSE_DELETE_MODAL:
      return Object.assign({}, state, {
        deleteModalOpen: false,
      });

    case ReportActionTypes.BEGIN_INSPECTOR_COMMENTS_EDIT:
      return Object.assign({}, state, {
        editingInspectorComments: true,
        pendingInspectorCommentsEdits: state.item.inspectorComments,
      });
    case ReportActionTypes.EDIT_INSPECTOR_COMMENTS:
      return Object.assign({}, state, {
        pendingInspectorCommentsEdits: action.inspectorComments,
      });
    case ReportActionTypes.CANCEL_INSPECTOR_COMMENTS_EDIT:
      return update(state, {
        $unset: ['editingInspectorComments', 'pendingInspectorCommentsEdits'],
      });

    case UploadActionTypes.CREATE_DOC_SUCCESS:
      return addDoc(state, action);
    case DocActionTypes.EDIT_DOC_SUCCESS:
      return unlinkDoc(state, action);
    case DocActionTypes.DELETE_SUCCESS:
      return deleteDoc(state, action);

    default:
      return state;
  }
}

export default combineReducers({
  root: reduceReducers(DefaultState, buildResetOnNavActionReducer(), buildReducer(ReportActionTypes), ReportRootReducer),
  actionItems: ActionItemsReducer,
});
