import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SitePickerModalButton from '../../../../../../shared/components/site-picker/containers/SitePickerModalButton';
import SiteInfo from './SiteInfo';

const style = Object.freeze({
  borderRadius: '10px',
  padding: '2rem',
});

class ChooseSite extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.addSite = this.addSite.bind(this);
  }

  addSite(sites) {
    if (sites && sites.length && sites[0].siteName) {
      this.props.onAddUserSelectedSite(sites[0]);
    }
  }

  renderSiteChooser() {
    return (
      <div className="border d-flex align-items-center mt-2" style={style}>
        <div style={{ padding: '1rem' }}>
          <SitePickerModalButton onSave={this.addSite} buttonLabel="Select a Site" />
        </div>
        <div>
          Use the site selector to pick a different site.
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.userSelectedSites.length) {
      return this.renderSiteChooser();
    }

    return (
      <div className="d-flex flex-column">
        {this.props.userSelectedSites.map(site => (
          <SiteInfo
            site={site}
            onActivate={this.props.onActivate}
            onDismiss={this.props.onDismissUserSelectedSite}
            key={site.siteName}
          />
        ))}
      </div>
    );
  }
}

ChooseSite.propTypes = {
  userSelectedSites: PropTypes.array,
  onActivate: PropTypes.func.isRequired,
  onAddUserSelectedSite: PropTypes.func.isRequired,
  onDismissUserSelectedSite: PropTypes.func.isRequired,
};

ChooseSite.defaultProps = {
  userSelectedSites: [],
};

export default ChooseSite;
