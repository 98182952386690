import update from 'immutability-helper';

import { ItemType, OperationState } from './DocConstants';
import UploadActionTypes from './components/upload/UploadActionTypes';
import ActionTypes from './SiteDocumentActionTypes';


function receiveSiteFolders(state, folders) {
  const firstFolder = folders && folders.length && folders[0];
  return Object.assign({}, state, { operationState: OperationState.Ready, folders }, changeFolder(firstFolder));
}

function changeFolder(folder) {
  let subFolderItems = [];
  if (folder && folder.subFolders && folder.subFolders.length) {
    subFolderItems = folder.subFolders.map(current => update(current, {
      name: {
        $set: current.folderName,
      },
      checked: {
        $set: false,
      },
      type: {
        $set: ItemType.Folder,
      }
    }));
  }
  let docItems = [];
  if (folder && folder.docs && folder.docs.length) {
    docItems = folder.docs.map(current => update(current, {
      name: {
        $set: current.filename,
      },
      checked: {
        $set: false,
      },
      type: {
        $set: ItemType.Doc,
      }
    }));
  }

  return {
    activeFolder: folder,
    activeFolderItems: subFolderItems.concat(docItems)
  };
}

function selectSingleItem(state, item) {
  const updatedItem = update(item, {
    checked: {
      $set: !item.checked,
    },
  });
  const updatedItems = state.activeFolderItems.map(current => {
    if (current === item) {
      return updatedItem;
    }
    return update(current, {
      checked: {
        $set: false,
      }
    });
  });
  return update(state, {
    activeFolderItems: {
      $set: updatedItems,
    }
  });
}

function toggleItemSelect(state, item) {
  const index = state.activeFolderItems.indexOf(item);
  const updatedItem = update(item, {
    checked: {
      $set: !item.checked,
    },
  });
  return update(state, {
    activeFolderItems: {
      $splice: [[index, 1, updatedItem]],
    }
  });
}

function selectAllItems(state, checked) {
  return Object.assign({}, state, {
    activeFolderItems: state.activeFolderItems.map(current => update(current, { checked: { $set: checked } }))
  });
}

function beginDocDrag(state, doc) {
  if (doc.checked) {
    return state;
  }
  return selectSingleItem(state, doc);
}

function updateFolderTree(state, updateFunc) {
  if (!state.folders) {
    return state;
  }
  const folders = updateSubFolders(state.folders, updateFunc);
  const root = { subFolders: folders };
  const activeFolder = findFolder(root, state.activeFolder.id);
  return Object.assign({}, state, changeFolder(activeFolder), {
    folders,
    operationState: OperationState.Ready,
  });
}

const updateFolderDocs = doc => folder => {
  const docIndex = folder.docs && folder.docs.findIndex(current => current.id === doc.id);
  const existingDoc = docIndex !== -1 && folder.docs[docIndex];

  // doc is new to current folder, folder does not have other docs
  if (folder.id === doc.folderId && !folder.docs) {
    return {
      docs: {
        $set: [doc],
      }
    };
  }
  // doc is new to current folder, folder has other docs
  else if (folder.id === doc.folderId && docIndex === -1) {
    return {
      docs: {
        $push: [doc],
      }
    };
  }
  // doc already exists in current folder but has been updated
  else if (folder.id === doc.folderId && existingDoc.siteId === doc.siteId) {
    return {
      docs: {
        $splice: [[docIndex, 1, doc]],
      }
    };
  }
  // doc exists in this folder but has been moved to a new folder or a different site
  else if (Number.isInteger(docIndex) && docIndex !== -1) {
    return {
      docs: {
        $splice: [[docIndex, 1]],
      }
    };
  }

  return null;
};

const editDoc = (state, doc) => updateFolderTree(state, updateFolderDocs(doc));

const removeDocs = docs => {
  const removedDocIds = docs.map(current => current.id);
  return folder => {
    const removed = folder.docs && folder.docs.find(current => removedDocIds.indexOf(current.id) !== -1);
    if (removed) {
      return {
        docs: {
          $set: folder.docs.filter(current => removedDocIds.indexOf(current.id) === -1),
        }
      };
    }

    return null;
  };
};

const deleteDocs = (state, docs) => updateFolderTree(state, removeDocs(docs));


function updateSubFolders(subFolders, updateFunc) {
  return subFolders.map(subFolder => updateFolder(subFolder, updateFunc));
}

function updateFolder(folder, updateFunc) {
  let updateSpec = updateFunc(folder);

  // update subfolders
  if (folder.subFolders && folder.subFolders.length) {
    updateSpec = updateSpec || {};
    updateSpec.subFolders = {
      $set: updateSubFolders(folder.subFolders, updateFunc),
    };
  }

  if (updateSpec) {
    return update(folder, updateSpec);
  }
  return folder;
}

function asFolder(state, targetFolder) {
  if (targetFolder.id) {
    return targetFolder;
  }
  return findFolder({ subFolders: state.folders }, targetFolder);
}

function findFolder(folder, targetFolderId) {
  if (folder.id === targetFolderId) {
    return folder;
  }
  else if (folder.subFolders) {
    for (let i = 0; i < folder.subFolders.length; i++) {
      const result = findFolder(folder.subFolders[i], targetFolderId);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export default function SitesDocumentReducer(state = { operationState: OperationState.Initial }, action) {
  switch (action.type) {
    case ActionTypes.GET_SITE_FOLDERS_STARTED:
      return Object.assign({}, state, {
        operationState: OperationState.Loading,
        folders: [],
        error: null,
      });
    case ActionTypes.RECEIVE_SITE_FOLDERS:
      return receiveSiteFolders(state, action.data);
    case ActionTypes.GET_SITE_FOLDERS_FAILED:
      return Object.assign({}, state, {
        operationState: OperationState.Failed,
        error: action.error,
      });

    case ActionTypes.CHANGE_FOLDER:
      return Object.assign({}, state, changeFolder(asFolder(state, action.folder)));

    case ActionTypes.SELECT_SINGLE_ITEM:
      return selectSingleItem(state, action.item);
    case ActionTypes.TOGGLE_ITEM_SELECT:
      return toggleItemSelect(state, action.item);
    case ActionTypes.SELECT_ALL_ITEMS:
      return selectAllItems(state, action.checked);
    case ActionTypes.BEGIN_DOC_DRAG:
      return beginDocDrag(state, action.doc);

    case ActionTypes.EDIT_DOC_STARTED:
      return Object.assign({}, state, {
        operationState: OperationState.Editing,
        error: null,
      });
    case ActionTypes.EDIT_DOC_SUCCESS:
      return editDoc(state, action.document);
    case ActionTypes.EDIT_DOC_FAILED:
      return Object.assign({}, state, {
        operationState: OperationState.Ready,
        error: action.error,
      });

    case UploadActionTypes.CREATE_DOC_SUCCESS:
      return editDoc(state, action.document);

    case ActionTypes.DOWNLOAD_STARTED:
      return Object.assign({}, state, {
        operationState: OperationState.Downloading,
        error: null,
      });
    case ActionTypes.DOWNLOAD_SUCCESS:
    case ActionTypes.DOWNLOAD_FAILED:
      return Object.assign({}, state, {
        operationState: OperationState.Ready,
        error: action.error,
      });

    case ActionTypes.DELETE_STARTED:
      return Object.assign({}, state, {
        operationState: OperationState.Deleting,
        error: null,
      });
    case ActionTypes.DELETE_SUCCESS:
      return deleteDocs(state, action.documents);
    case ActionTypes.DELETE_FAILED:
      return Object.assign({}, state, {
        operationState: OperationState.Ready,
        error: action.error,
      });

    default:
      return state;
  }
}
