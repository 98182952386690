import { push } from 'react-router-redux';

import { CustomerClient, SiteClient } from '../../../client';
import { UserNavType } from '../../../AppConstants';
import * as NavContextActions from '../../../shared/components/nav-context/NavContextActions';
import SiteActionTypes from '../SiteActionTypes';
import * as selectors from '../SiteSelectors';
import * as InventoryNavigationActions from '../../inventory/actions/InventoryNavigationActions';

function changeSiteStarted() {
  return {
    type: SiteActionTypes.CHANGE_SITE_STARTED,
  };
}

function receiveSite(site) {
  return {
    type: SiteActionTypes.RECEIVE_SITE,
    currentSite: site,
  };
}

function changeSiteFailed() {
  return {
    type: SiteActionTypes.CHANGE_SITE_FAILED,
  };
}

function receiveCustomerLabels(customerLabels) {
  return {
    type: SiteActionTypes.RECEIVE_CUSTOMER_LABELS,
    customerLabels,
  };
}

function changeSite(siteId) {
  return (dispatch) => {
    if (!siteId) {
      return Promise.resolve();
    }

    dispatch({ type: SiteActionTypes.RESET });
    dispatch(changeSiteStarted());
    return SiteClient.getSiteById(siteId)
      .then((site) => CustomerClient.getCustomerLabels(site.customerId).then((labels) => {
        dispatch(receiveCustomerLabels(labels));
        dispatch(receiveSite(site));
      }))
      .catch(() => {
        dispatch(changeSiteFailed());
        return Promise.resolve(null);
      });
  };
}

export function selectSiteById(siteId) {
  return (dispatch, getState) => {
    if (!siteId) {
      return Promise.resolve();
    }

    const currentState = getState();
    const intSiteId = Number.parseInt(siteId, 10);
    if (selectors.isCurrentSiteLoading(currentState)) {
      return Promise.resolve();
    }

    const currentSite = selectors.currentSite(currentState);
    if (currentSite && currentSite.id === intSiteId) {
      return Promise.resolve();
    }

    return dispatch(changeSite(intSiteId));
  };
}

export function refreshCurrentlySelectedSite() {
  return (dispatch, getState) => {
    const currentSite = selectors.currentSite(getState());
    if (currentSite && currentSite.id) {
      return dispatch(changeSite(currentSite.id));
    }
    return Promise.resolve();
  };
}

export function editSite(updatedSite) {
  return {
    type: SiteActionTypes.EDIT_CURRENT_SITE,
    updatedSite,
  };
}

export function discardPendingEdits() {
  return {
    type: SiteActionTypes.DISCARD_SITE_EDITS,
  };
}

const saveSiteStarted = () => ({ type: SiteActionTypes.SAVE_SITE_STARTED });
const saveSiteSuccess = updatedSite => ({ type: SiteActionTypes.SAVE_SITE_SUCCESS, updatedSite });
const saveSiteFailed = error => ({ type: SiteActionTypes.SAVE_SITE_FAILED, error });

export function savePendingEdits() {
  return (dispatch, getState) => {
    const currentState = getState();
    const updatedSite = selectors.pendingEdits(currentState);
    if (!updatedSite || selectors.isSaving(currentState)) {
      return Promise.resolve();
    }

    dispatch(saveSiteStarted());
    return SiteClient.saveSite(updatedSite)
      .then(restSite => dispatch(saveSiteSuccess(restSite)))
      .catch(error => dispatch(saveSiteFailed(error)));
  };
}

// nav actions

export function navToSite(site, list) {
  return (dispatch, getState) => {
    const currentState = getState();
    const siteId = site.id || site;
    const currentPath = currentState.router.location.pathname;
    const sitePath = `/sites/${siteId}/overview`;

    if (currentPath === sitePath) {
      dispatch(refreshCurrentlySelectedSite());
    }
    else {
      if (list && list.length) {
        const index = list.findIndex(current => current.id === siteId);
        if (index !== -1) {
          const urls = list.map(current => `/sites/${current.id}/overview`);
          dispatch(NavContextActions.setContext(UserNavType.SiteList, urls, index));
        }
      }

      dispatch(push(sitePath));
    }

    return Promise.resolve();
  };
}

export function navToTankRegistration(tank, registration) {
  return (dispatch) => {
    if (registration && registration.id && registration.siteId) {
      const path = `/sites/${registration.siteId}/equipment/tanks/registrations/${registration.id}`;
      dispatch(push(path));
    }
    else if (tank && tank.id && tank.siteId) {
      const path = `/sites/${tank.siteId}/equipment/tanks/${tank.id}/registration`;
      dispatch(push(path));
    }
  };
}

export function navToSiteIssues(site) {
  return (dispatch, getState) => {
    const currentState = getState();
    const siteId = (site && site.id) || site || selectors.currentSiteId(currentState);
    const currentPath = currentState.router.location.pathname;
    const sitePath = `/sites/${siteId}/issues`;

    if (currentPath === sitePath) {
      dispatch(refreshCurrentlySelectedSite());
    }
    else {
      dispatch(push(sitePath));
    }

    return Promise.resolve();
  };
}

export function navToIssue(issue, list) {
  return (dispatch) => {
    const issueId = issue.id || issue;
    const siteId = (issue.site && issue.site.id) || issue.siteId;

    if (Number.isInteger(issueId) && Number.isInteger(siteId)) {
      if (list && list.length) {
        const index = list.findIndex(current => current.id === issueId);
        if (index !== -1) {
          const urls = list.map(current => {
            const currentSiteId = (current.site && current.site.id) || current.siteId;
            return `/sites/${currentSiteId}/issues/${current.id}`;
          });
          dispatch(NavContextActions.setContext(UserNavType.IssueList, urls, index));
        }
      }

      const nextIssuePath = `/sites/${siteId}/issues/${issueId}`;
      dispatch(push(nextIssuePath));
    }
  };
}

export function navToCurrentSiteIssue(issue) {
  return (dispatch, getState) => {
    const currentSiteId = selectors.currentSiteId(getState());
    const issueId = issue.id || issue;
    dispatch(push(`/sites/${currentSiteId}/issues/${issueId}`));
  };
}

export function navToSiteCompliance(site) {
  return (dispatch, getState) => {
    const currentState = getState();
    const siteId = (site && site.id) || site || selectors.currentSiteId(currentState);
    const currentPath = currentState.router.location.pathname;
    const sitePath = `/sites/${siteId}/reports`;

    if (currentPath === sitePath) {
      dispatch(refreshCurrentlySelectedSite());
    }
    else {
      dispatch(push(sitePath));
    }

    return Promise.resolve();
  };
}

export function navToSiteComplianceReport(report, complianceSectionsOpen, list) {
  return (dispatch) => {
    if (report) {
      const reportId = report.id;
      const siteId = (report.site && report.site.id) || report.siteId;
      if (reportId && siteId) {
        if (list && list.length) {
          const index = list.findIndex(current => current.id === reportId);
          if (index !== -1) {
            const urls = list.map(current => `/sites/${current.siteId}/reports/${current.id}/equipment`);
            dispatch(NavContextActions.setContext(UserNavType.ComplianceList, urls, index));
          }
        }

        const path = `/sites/${siteId}/reports/${reportId}/equipment`;
        dispatch(push(path, { complianceSectionsOpen }));
      }
    }
    return Promise.resolve();
  };
}

export function navToSiteInventory(site) {
  return (dispatch, getState) => {
    const currentState = getState();
    const actualSite = site || selectors.currentSite(currentState);
    if (actualSite) {
      dispatch(InventoryNavigationActions.navToSiteInventory(actualSite));
    }
  };
}

export function navToSiteMvi(mvi, list) {
  return (dispatch) => {
    const siteId = mvi.site && mvi.site.id;
    const mviId = mvi.id;

    if (Number.isInteger(siteId) && Number.isInteger(mviId)) {
      if (list && list.length) {
        const index = list.findIndex(current => current.id === mviId);
        if (index !== -1) {
          const urls = list.map(current => `/sites/${current.siteId}/inspections/${current.id}`);
          dispatch(NavContextActions.setContext(UserNavType.MviList, urls, index));
        }
      }

      const path = `/sites/${siteId}/inspections/${mviId}`;
      dispatch(push(path));
    }
  };
}

export function navToSiteMviList(site) {
  return (dispatch, getState) => {
    const state = getState();
    const siteId = (site && site.id) || selectors.currentSiteId(state);
    if (siteId) {
      const path = `/sites/${siteId}/inspections`;
      dispatch(push(path));
    }
  };
}
