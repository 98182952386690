import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Card, CardBody } from 'reactstrap';
import TextAreaInputForm from '../../../../../../../../../shared/components/TextAreaInputForm';

class ActionItemEdit extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  handleTextChange(value) {
    const updatedActionItem = update(this.props.actionItem, {
      actionItemText: {
        $set: value,
      }
    });
    this.props.onEdit(updatedActionItem);
  }

  render() {
    return (
      <Card className={this.props.className}>
        <CardBody>
          <TextAreaInputForm
            value={this.props.actionItem.actionItemText}
            onChange={this.handleTextChange}
            canCancel={!this.props.isSaving}
            onCancel={this.props.onCancel}
            canSave={!this.props.isSaving}
            onSave={this.props.onSave}
          />
        </CardBody>
      </Card>
    );
  }
}

ActionItemEdit.propTypes = {
  className: PropTypes.string,
  isSaving: PropTypes.bool,
  actionItem: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

ActionItemEdit.defaultProps = {
  className: null,
  isSaving: false,
};

export default ActionItemEdit;
