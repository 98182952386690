import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledAlert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug } from '@fortawesome/free-solid-svg-icons';
import NonProdWarning from '../../../shared/components/non-prod-warning/NonProdWarning';
import Colors from '../../../colors';

const DevDisconnected = ({ site }) => {
  if (!site || !site.connection || site.connection.connectionType !== 'TCP') {
    return null;
  }
  return (
    <NonProdWarning>
      <UncontrolledAlert color="warning">
        <FontAwesomeIcon icon={faPlug} size="lg" color={Colors.Fail} />
        <span className="ml-2">This site is connected with TCP which means ATG commands won&apos;t work in this development environment.</span>
      </UncontrolledAlert>
    </NonProdWarning>
  );
};

DevDisconnected.propTypes = {
  site: PropTypes.object,
};

DevDisconnected.defaultProps = {
  site: null,
};

export default DevDisconnected;
