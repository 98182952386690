import moment from 'moment';

import { Get } from '../HttpUtils';
import { DurationTypes } from '../../admin/screens/inventory/screens/export-history/ExportHistoryConstants';

function buildWhere(filterOpts) {
  const where = {};
  if (filterOpts && filterOpts.fuelHauler) {
    where.fuelHaulerId = filterOpts.fuelHauler.id;
  }
  if (filterOpts && filterOpts.durationType) {
    const durationType = DurationTypes[filterOpts.durationType];
    const date = moment().subtract(durationType.mins, 'minutes');
    where.created = {};
    where.created[durationType.filterType] = date.toISOString();
  }
  return where;
}

export default {
  getById(id) {
    const filter = {
      include: [
        {
          relation: 'fuelHauler',
          scope: {
            fields: {
              id: true,
              name: true,
            },
          },
        },
        'files',
      ],
    };
    return Get(`/FuelHaulerExports/${id}?filter=${JSON.stringify(filter)}`).then(response => response.data);
  },

  getHistory(filterOpts) {
    const filter = {
      limit: 100,
      fields: {
        fileContents: false,
      },
      include: [
        {
          relation: 'fuelHauler',
          scope: {
            fields: {
              id: true,
              name: true,
            },
          },
        },
      ],
      order: 'created DESC',
    };

    const where = buildWhere(filterOpts);
    if (Object.keys(where).length) {
      filter.where = where;
    }

    return Get(`/FuelHaulerExports?filter=${JSON.stringify(filter)}`).then(response => response.data);
  },
};
