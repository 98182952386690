import ActionTypes from './IssueExportActionTypes';

const InitialState = {
  modalOpen: false,
  issue: null,
  options: {
    analysisRec: true,
    comments: true,
    attachments: true,
    atgReports: true,
  }
};

export default function IssueReducer(state = InitialState, action) {
  switch (action.type) {
    case ActionTypes.OPEN_EXPORT_MODAL:
      return Object.assign({}, state, {
        modalOpen: true,
        issue: action.issue,
      });
    case ActionTypes.SET_OPTIONS:
      return Object.assign({}, state, {
        options: action.options,
      });
    case ActionTypes.CLOSE_EXPORT_MODAL:
      return InitialState;
    case ActionTypes.DOWNLOAD_STARTED:
      return Object.assign({}, state, {
        downloading: true,
        error: null,
      });
    case ActionTypes.DOWNLOAD_SUCCESS:
    case ActionTypes.DOWNLOAD_FAILED:
      return Object.assign({}, state, {
        downloading: false,
        error: action.error,
      });
    default:
      return state;
  }
}
