/* eslint-disable import/prefer-default-export */
import { DispatchSystemClient } from '../../../../client';
import ActionTypes from '../DispatchSystemDropdownActionTypes';
import * as selectors from '../DispatchSystemDropdownSelectors';

function receiveDispatchSystemList(dispatchSystemList) {
  return {
    type: ActionTypes.RECEIVE_DISPATCH_SYSTEM_LIST,
    dispatchSystemList,
  };
}

export function getDispatchSystems() {
  return (dispatch, getState) => {
    const isLoading = selectors.isDispatchSystemListLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_DISPATCH_SYSTEM_LIST_STARTED });
    return DispatchSystemClient
      .getDispatchSystems()
      .then(dispatchSystemList => dispatch(receiveDispatchSystemList(dispatchSystemList)))
      .catch((err) => {
        dispatch({ type: ActionTypes.GET_DISPATCH_SYSTEM_LIST_FAILED });
        return Promise.reject(err);
      });
  };
}
