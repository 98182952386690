import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import SiteMap from '../components/SiteMap';
import * as actions from '../actions';
import * as selectors from '../SiteEditorSelectors';

class GeocodeButton extends Component {
  isDisabled() {
    const site = this.props.selectedSite;
    return this.props.isGeocoding || !site || !site.address || !site.city || !site.zip || this.hasExistingLatLon();
  }

  hasExistingLatLon() {
    const location = this.props.selectedSite.location;
    return !!(location && (location.lat || location.lon));
  }

  hasResults() {
    return !!(this.props.isGeocoding && this.props.geocodeResults);
  }

  canCancel() {
    return !!(this.props.isGeocoding && (this.props.geocodeResults || this.props.isError));
  }

  currentAddress() {
    const site = this.props.selectedSite;
    let address = `${site.address}, ${site.city}, ${site.state}`;
    if (site.zip) {
      address += ` ${site.zip}`;
    }
    return address;
  }

  renderModalBody() {
    if (this.hasResults()) {
      return (
        <div>
          <h5>Current Address</h5>
          <span>{this.currentAddress()}</span>
          <h5 className="mt-1">Normalized Address</h5>
          <span>{this.props.geocodeResults.formattedAddress}</span>
          <h5 className="mt-1">Map</h5>
          <SiteMap site={this.props.geocodeResults} />
        </div>
      );
    }
    else if (this.props.isError) {
      return <span>Geocoding failed</span>;
    }
    else if (this.props.isGeocoding) {
      return <PulseLoader color="#F8E71C" size={8} />;
    }
    return null;
  }

  render() {
    if (!this.props.selectedSite) {
      return null;
    }

    return (
      <div className={this.props.className}>
        <Button color="primary" outline size="sm" onClick={this.props.geocodeSelectedSite} disabled={this.isDisabled()}>Geocode</Button>
        <Modal isOpen={this.props.isGeocoding} toggle={this.onCancel}>
          <ModalHeader>
            Geocoding
            {' '}
            {this.props.selectedSite.nickname}
          </ModalHeader>
          <ModalBody>
            {this.renderModalBody()}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline size="sm" onClick={this.props.geocodeCancel} disabled={!this.canCancel()}>Cancel</Button>
            {' '}
            <Button color="primary" size="sm" onClick={this.props.geocodeAccept} disabled={!this.hasResults()}>OK</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

GeocodeButton.propTypes = {
  selectedSite: PropTypes.shape({
    nickname: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  }),
  geocodeSelectedSite: PropTypes.func.isRequired,
  isGeocoding: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  geocodeResults: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  }),
  geocodeAccept: PropTypes.func.isRequired,
  geocodeCancel: PropTypes.func.isRequired,
  className: PropTypes.string,
};

GeocodeButton.defaultProps = {
  selectedSite: null,
  geocodeResults: null,
  className: null,
};

function mapStateToProps(state) {
  return {
    selectedSite: selectors.selectedSite(state),
    isGeocoding: selectors.isGeocoding(state),
    isError: selectors.isGeocodeError(state),
    geocodeResults: selectors.geocodeResults(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    geocodeSelectedSite: () => dispatch(actions.geocodeSelectedSite()),
    geocodeAccept: () => dispatch(actions.geocodeAccept()),
    geocodeCancel: () => dispatch(actions.geocodeCancel()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeocodeButton));
