/* eslint-disable no-param-reassign */
import { Get, Post } from '../HttpUtils';

export default {
  getTanks(siteId) {
    return Get(`/v2/sites/${siteId}/tanks`).then((response) => {
      const tanksById = {};
      response.data.forEach((current) => {
        tanksById[current.id] = current;
      });

      response.data.forEach((current) => {
        if (current.manifoldPartners) {
          current.manifoldPartners = current.manifoldPartners
            .map((currentManifoldInfo) => {
              if (tanksById[currentManifoldInfo.manifoldPartnerId]) {
                const partnerNumber = tanksById[currentManifoldInfo.manifoldPartnerId].tankNumber;
                return `T${partnerNumber} (${currentManifoldInfo.manifoldType})`;
              }
              return null;
            })
            .filter(partner => !!partner)
            .sort((first, second) => first.localeCompare(second));
        }
        else {
          current.manifoldPartners = [];
        }
      });
      return response.data;
    });
  },

  getSiteTanks2(siteId) {
    return Get(`/v2/sites/${siteId}/tanks`).then(response => response.data);
  },

  getTankRegistrations(siteId) {
    return Get(`/v2/sites/${siteId}/tank-registrations`).then(response => Promise.resolve(response.data));
  },

  getTankRegistrationsBySiteId(siteId) {
    return Get(`/v2/sites/${siteId}/tank-registrations`).then(response => Promise.resolve(response.data));
  },

  getTankRegistrationById(siteId, tankRegistrationId) {
    return Get(`/v2/sites/${siteId}/tank-registrations/${tankRegistrationId}`).then(response => Promise.resolve(response.data));
  },

  // TODO: port writeable portion of tank registration API from loopback
  updateTankRegistration(tankReg) {
    return Post(`/sites/${tankReg.siteId}/tankRegistrations/${tankReg.id}`, tankReg).then(response => Promise.resolve(response.data));
  },

  // TODO: port writeable portion of tank registration API from loopback
  saveTankRegistrations(siteId, tankRegistrations) {
    const body = {
      update: tankRegistrations,
    };
    return Post(`/sites/${siteId}/tankRegistrations/bulk`, body).then(() => null);
  },
};
