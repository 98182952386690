export function isAlarmStatusLoading(state) {
  return (state && state.sites && state.sites.alarms && state.sites.alarms.alarmStatusLoading) || false;
}

export function alarmStatus(state) {
  return (state && state.sites && state.sites.alarms && state.sites.alarms.alarmStatus) || null;
}

export function alarmStatusError(state) {
  return (state && state.sites && state.sites.alarms && state.sites.alarms.alarmStatusError) || null;
}
