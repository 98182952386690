import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import FileSaver from 'file-saver';
import Papa from 'papaparse';
import { track } from '../../../../analytics';

const UnparseConfig = {
  quotes: true,
  quoteChar: '"',
  escapeChar: '\\',
  delimiter: ','
};

class ExportOption extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.exportFuncsByColumnId = {};
    Object.values(this.props.columnType).forEach(col => {
      if (col.exportFunc) {
        this.exportFuncsByColumnId[col.id] = col.exportFunc;
      }
      else {
        this.exportFuncsByColumnId[col.id] = rowData => rowData[col.id];
      }
    });
  }

  handleExportClick() {
    const exportFuncs = this.props.selectedColumns.map(col => this.exportFuncsByColumnId[col.id]);
    const exportData = [];

    const headers = [];
    this.props.selectedColumns.forEach(col => {
      headers.push(col.id);
    });
    exportData.push(headers);

    this.props.data.forEach(currentObj => {
      const currentRowData = exportFuncs.map(func => func(currentObj) || '');
      exportData.push(currentRowData);
    });
    const csv = Papa.unparse(exportData, UnparseConfig);
    const file = new File([csv], 'canary_export.csv', { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(file);
    track('View manager', { component: 'Export button', 'View action': 'Export', 'Row count': exportData.length });
  }

  render() {
    const disabled = !this.props.data || !this.props.data.length || !this.props.selectedColumns || !this.props.selectedColumns.length;
    return (
      <DropdownItem onClick={this.handleExportClick} disabled={disabled}>Export</DropdownItem>
    );
  }
}

ExportOption.propTypes = {
  columnType: PropTypes.object.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default ExportOption;
