import React from 'react';
import PropTypes from 'prop-types';

import TextToMarkup from '../TextToMarkup';
import SpeechBubble from './SpeechBubble';

const Comment = ({ comment, className }) => {
  const username = (comment.user && comment.user.username) || comment.username;
  return (
    <SpeechBubble user={username} date={comment.created} className={className}>
      <TextToMarkup text={comment.commentText} />
    </SpeechBubble>
  );
};

Comment.propTypes = {
  comment: PropTypes.shape({
    commentText: PropTypes.string.isRequired,
    username: PropTypes.string,
    user: PropTypes.shape({
      username: PropTypes.string,
    }),
    created: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string,
};

Comment.defaultProps = {
  className: null,
};

export default Comment;
