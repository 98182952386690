import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';

const EditLinkButton = ({ onClick, disabled, className }) => (
  <Button color="link" onClick={onClick} disabled={disabled} className={className}>
    Edit
    <FontAwesomeIcon icon={faPencilAlt} className="ml-1" />
  </Button>
);

EditLinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

EditLinkButton.defaultProps = {
  disabled: false,
  className: null,
};

export default EditLinkButton;
