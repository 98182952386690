import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const CanaryModal = ({ open, isSaving, title, onCancel, canCancel, cancelButtonText, onConfirm, canConfirm, confirmButtonText, size, className, children }) => (
  <Modal isOpen={open} toggle={onCancel} className={className} size={size}>
    <ModalHeader toggle={onCancel}>{title}</ModalHeader>
    <ModalBody>
      {children}
    </ModalBody>
    <ModalFooter>
      <Button color="primary" outline size="sm" onClick={onCancel} disabled={isSaving || !canCancel}>{cancelButtonText}</Button>
      {' '}
      <Button color="primary" size="sm" onClick={onConfirm} disabled={isSaving || !canConfirm}>{confirmButtonText}</Button>
    </ModalFooter>
  </Modal>
);

CanaryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  title: PropTypes.string.isRequired,

  onCancel: PropTypes.func.isRequired,
  canCancel: PropTypes.bool,
  cancelButtonText: PropTypes.string,

  onConfirm: PropTypes.func.isRequired,
  canConfirm: PropTypes.bool,
  confirmButtonText: PropTypes.string,

  size: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

CanaryModal.defaultProps = {
  isSaving: false,
  canCancel: true,
  cancelButtonText: 'Cancel',
  canConfirm: true,
  confirmButtonText: 'Confirm',
  size: 'sm',
  className: null,
};

export default CanaryModal;
