import React from 'react';
import PropTypes from 'prop-types';

function getAtgModel(atgInfo) {
  return (atgInfo && atgInfo.atgModel) || 'unknown';
}

function getSoftwareVersion(atgInfo) {
  return (atgInfo && atgInfo.softwareVersion) || 'unknown';
}

const Atg = ({ atgInfo }) => (
  <div className="d-flex flex-column mx-2">
    <img src="/images/equipment/atg.png" width={225} alt="ATG image" />
    <div className="mt-2">
      ATG: {getAtgModel(atgInfo)}
      <br />
      Version: {getSoftwareVersion(atgInfo)}
    </div>
  </div>
);

Atg.propTypes = {
  atgInfo: PropTypes.object,
};

Atg.defaultProps = {
  atgInfo: null,
};

export default Atg;
