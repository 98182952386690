import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { RegulatoryStatus } from '../../../../../../../AppConstants';

const ComplianceStatusSelect = ({ selectedComplianceStatuses, selectComplianceStatuses, deselectComplianceStatus, disabled }) => {
  const hasComplianceStatus = complianceStatus => !!(find(selectedComplianceStatuses, current => current.text === complianceStatus.text));
  const buildUpdateFunc = complianceStatus => {
    if (hasComplianceStatus(complianceStatus)) {
      deselectComplianceStatus(complianceStatus);
    }
    else {
      selectComplianceStatuses([complianceStatus]);
    }
  };

  const toggleInconclusive = () => buildUpdateFunc(RegulatoryStatus.INCONCLUSIVE);
  const inconclusiveChecked = hasComplianceStatus(RegulatoryStatus.INCONCLUSIVE);
  const togglePassed = () => buildUpdateFunc(RegulatoryStatus.PASSED);
  const passedChecked = hasComplianceStatus(RegulatoryStatus.PASSED);
  const toggleFailed = () => buildUpdateFunc(RegulatoryStatus.FAILED);
  const failedChecked = hasComplianceStatus(RegulatoryStatus.FAILED);
  return (
    <Form className="d-md-flex align-items-center h-100">
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={inconclusiveChecked} onChange={toggleInconclusive} disabled={disabled} />
          Inconclusive
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={passedChecked} onChange={togglePassed} disabled={disabled} />
          Passed
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0">
        <Label check>
          <Input type="checkbox" checked={failedChecked} onChange={toggleFailed} disabled={disabled} />
          Not passing
        </Label>
      </FormGroup>
    </Form>
  );
};

ComplianceStatusSelect.propTypes = {
  selectedComplianceStatuses: PropTypes.arrayOf(PropTypes.object),
  selectComplianceStatuses: PropTypes.func.isRequired,
  deselectComplianceStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ComplianceStatusSelect.defaultProps = {
  selectedComplianceStatuses: [],
};

export default ComplianceStatusSelect;
