import { LOCATION_CHANGE } from 'react-router-redux';
import MviReportActionTypes from '../MviReportActionTypes';
import ActionItemsActionTypes from './ActionItemsActionTypes';

export default function ActionItemsReducer(state = {}, action) {
  switch (action.type) {
    case ActionItemsActionTypes.SELECT_FOR_EDIT:
      return Object.assign({}, state, {
        editing: true,
        selectedActionItem: action.actionItem
      });
    case ActionItemsActionTypes.EDIT_ACTION_ITEM:
      return Object.assign({}, state, {
        selectedActionItem: action.actionItem
      });

    case ActionItemsActionTypes.SELECT_NEW_ITEM_FOR_EDIT:
      return Object.assign({}, state, {
        adding: true,
        selectedActionItem: action.actionItem
      });

    case ActionItemsActionTypes.SELECT_FOR_DELETE:
      return Object.assign({}, state, {
        deleteModalOpen: true,
        selectedActionItem: action.actionItem
      });

    case MviReportActionTypes.RECEIVE_ITEM:
    case MviReportActionTypes.SAVE_ITEM_SUCCESS:
    case MviReportActionTypes.SAVE_ITEM_FAILED:
    case LOCATION_CHANGE:
    case ActionItemsActionTypes.CANCEL:
      return null;
    default:
      return state;
  }
}
