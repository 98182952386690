import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as AuthenticationSelectors from '../../../../../../../authentication/AuthenticationSelectors';

import FilterMultiselect from '../FilterMultiselect';

const CustomerFilterMultiselect = ({ selectedCustomers, allCustomers, selectCustomers, className, disabled }) => (
  <FilterMultiselect
    textField="name"
    onItemsSelected={selectCustomers}
    selectedItems={selectedCustomers}
    allItems={allCustomers}
    className={className}
    disabled={disabled}
  />
);

CustomerFilterMultiselect.propTypes = {
  // public props
  className: PropTypes.string,
  selectedCustomers: PropTypes.array,
  selectCustomers: PropTypes.func.isRequired,

  // internal stuff
  allCustomers: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
};

CustomerFilterMultiselect.defaultProps = {
  className: null,
  selectedCustomers: [],
  allCustomers: [],
};

function mapStateToProps(state) {
  return {
    allCustomers: AuthenticationSelectors.allCustomers(state),
  };
}

export default connect(mapStateToProps)(CustomerFilterMultiselect);
