import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HealthGauge from '../../../../../components/health-gauge/HealthGauge';
import * as actions from '../actions';
import * as selectors from '../InvDashboardSelectors';

class FuelHaulerGauge extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.props.getFuelHaulerHealth();
  }

  render() {
    return (
      <HealthGauge
        label="Healthy Fuel Hauler %"
        value={this.props.fuelHaulerHealth && (this.props.fuelHaulerHealth.fuelHaulerHealthPercentage * 100)}
        helpText="The percentage of fuel haulers where at least 90% of the sites included in the most recent export had inventory
          readings there were less than 10 minutes old at the time the export was generated."
      />
    );
  }
}

FuelHaulerGauge.propTypes = {
  fuelHaulerHealth: PropTypes.object,
  getFuelHaulerHealth: PropTypes.func.isRequired,
};

FuelHaulerGauge.defaultProps = {
  fuelHaulerHealth: null,
};

function mapStateToProps(state) {
  return {
    fuelHaulerHealth: selectors.fuelHaulerHealth(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFuelHaulerHealth: () => dispatch(actions.getFuelHaulerHealth()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelHaulerGauge);
