import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

function ConfirmModal({ isOpen, headerText, body, onConfirm, onCancel }) {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} size="sm">
      <ModalHeader toggle={onCancel}>
        {headerText}
      </ModalHeader>
      <ModalBody>
        {body}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="sm" outline onClick={onCancel}>Cancel</Button>
        {' '}
        <Button color="primary" size="sm" onClick={onConfirm}>Confirm</Button>
      </ModalFooter>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  headerText: PropTypes.string,
  body: PropTypes.any,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  headerText: 'Confirm action',
  body: 'Are you sure?',
};

export default ConfirmModal;
