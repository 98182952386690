import update from 'immutability-helper/index';

import { OrganizationClient, SiteClient, SiteFields, SiteParams } from '../../../../../../client';
import ActionTypes from '../OrganizationEditActionTypes';
import * as selectors from '../OrganizationEditSelectors';

const NEW = 'new';

function getOrganizationStarted() {
  return {
    type: ActionTypes.GET_ORGANIZATION_STARTED,
  };
}

function receiveOrganization(selectedOrganization) {
  return {
    type: ActionTypes.RECEIVE_ORGANIZATION,
    selectedOrganization,
  };
}

function getOrganizationFailed(error) {
  return {
    type: ActionTypes.GET_ORGANIZATION_FAILED,
    error,
  };
}

function buildNewOrganization() {
  return {
    name: '',
  };
}

function receivePermissionSets(permissionSets) {
  return {
    type: ActionTypes.RECEIVE_PERMISSION_SETS,
    permissionSets,
  };
}

export function getPermissionSets() {
  return (dispatch, getState) => {
    const permissionSets = selectors.permissionSets(getState());
    if (permissionSets && permissionSets.length) {
      return Promise.resolve();
    }

    return OrganizationClient.getPermissionSets()
      .then(restPermissionSets => dispatch(receivePermissionSets(restPermissionSets)));
  };
}

export function selectOrganization(organization) {
  return (dispatch, getState) => {
    const isLoading = selectors.isLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    let orgPromise;
    const id = organization.id || organization;
    if (id === NEW) {
      orgPromise = Promise.resolve(buildNewOrganization());
    }
    else {
      const orgByIdPromise = OrganizationClient.getById(id);
      const siteParams = {
        [SiteParams.CustomerIds.name]: [id],
        [SiteParams.IncludeInactiveSites.name]: true,
      };
      const siteFields = [SiteFields.Id, SiteFields.CustomerId, SiteFields.SiteName, SiteFields.Nickname, SiteFields.Address, SiteFields.City, SiteFields.State, SiteFields.Zip, SiteFields.Phone, SiteFields.Enabled];
      const sitesPromise = SiteClient.find(siteParams, siteFields);
      orgPromise = Promise.all([orgByIdPromise, sitesPromise]).then(([org, sites]) => {
        if (sites && sites.data && sites.data.length && org && org.customer) {
          org.customer.sites = sites.data;
        }
        else if (org.customer) {
          org.customer.sites = [];
        }
        return org;
      });
    }

    dispatch(getOrganizationStarted());
    dispatch(getPermissionSets());
    return orgPromise
      .then(restOrganization => dispatch(receiveOrganization(restOrganization)))
      .catch(err => {
        dispatch(getOrganizationFailed(err));
        return Promise.reject(err);
      });
  };
}

export function editSelectedOrganization(editedOrganization) {
  return {
    type: ActionTypes.EDIT_SELECTED_ORG,
    editedOrganization,
  };
}

export function discardPendingOrganizationEdits() {
  return { type: ActionTypes.DISCARD_SELECTED_ORG_EDITS };
}

function saveOrganizationStarted() {
  return {
    type: ActionTypes.SAVE_ORGANIZATION_STARTED,
  };
}

function saveOrganizationSuccess(updatedOrg) {
  return {
    type: ActionTypes.SAVE_ORGANIZATION_SUCCESS,
    selectedOrganization: updatedOrg,
  };
}

function saveOrganizationFailed(error) {
  return {
    type: ActionTypes.SAVE_ORGANIZATION_FAILED,
    error
  };
}

export function editAndSaveSelectedOrganization(editedOrganization) {
  return dispatch => {
    dispatch(editSelectedOrganization(editedOrganization));
    return dispatch(saveSelectedOrganization());
  };
}

export function saveSelectedOrganization() {
  return (dispatch, getState) => {
    const state = getState();
    if (selectors.isSaving(state)) {
      return Promise.resolve();
    }

    const toSave = buildOrgToSave(state);
    dispatch(saveOrganizationStarted());
    return OrganizationClient.save(toSave)
      .then(updatedOrg => {
        dispatch(saveOrganizationSuccess(updatedOrg));
      })
      .catch((err) => {
        dispatch(saveOrganizationFailed(err));
        return Promise.reject(err);
      });
  };
}

function buildOrgToSave(state) {
  const pending = selectors.pendingOrgEdits(state);

  const updateSpec = {
    name: { $set: pending.name },
    address: { $set: pending.address },
    city: { $set: pending.city },
    state: { $set: pending.state },
    zip: { $set: pending.zip },
    phone: { $set: pending.phone },
    logo: { $set: pending.logo },
    customer: { $set: pending.customer },
    policies: { $set: pending.policies },
    groups: { $set: pending.groups },
  };

  if (!Number.isInteger(pending.id)) {
    updateSpec.id = {
      $set: null,
    };
  }

  const original = selectors.selectedOrganization(state);
  return update(original, updateSpec);
}

export function savePolicy(policy) {
  return (dispatch, getState) => {
    const currentState = getState();
    const isLoading = selectors.isLoading(currentState);
    const isSaving = selectors.isSaving(currentState);
    const org = selectors.selectedOrganization(currentState);

    if (isLoading || isSaving || !org) {
      return Promise.resolve();
    }

    const updateSpec = buildPolicyUpdateSpec(org, policy);
    const updatedOrg = update(org, updateSpec);
    return dispatch(editAndSaveSelectedOrganization(updatedOrg));
  };
}

function buildPolicyUpdateSpec(org, policy) {
  if (Number.isInteger(policy.id)) {
    const index = org.policies.findIndex(current => current.id === policy.id);
    return {
      policies: {
        $splice: [[index, 1, policy]],
      }
    };
  }
  else {
    return {
      policies: {
        $push: [policy],
      }
    };
  }
}

export function deletePolicy(policy) {
  return (dispatch, getState) => {
    const currentState = getState();
    const isLoading = selectors.isLoading(currentState);
    const isSaving = selectors.isSaving(currentState);
    const org = selectors.selectedOrganization(currentState);

    if (isLoading || isSaving || !org) {
      return Promise.resolve();
    }

    const updateSpec = {
      policies: {
        $set: org.policies.filter(current => current.id !== policy.id),
      }
    };
    const updatedOrg = update(org, updateSpec);
    return dispatch(editAndSaveSelectedOrganization(updatedOrg));
  };
}
