import React from 'react';
import PropTypes from 'prop-types';

import DispatchStatusDate from '../../../shared/components/dispatch/DispatchStatusDate';

const OpenDispatches = ({ site }) => {
  if (!site.openDispatches || !site.openDispatches.length) {
    return null;
  }

  return (
    <div className="d-inline-flex flex-wrap">
      {(site.openDispatches.map((dispatch) => (<DispatchStatusDate key={dispatch.id} dispatch={dispatch} className="mr-2" />)))}
    </div>
  );
};

OpenDispatches.propTypes = {
  site: PropTypes.object.isRequired,
};

export default OpenDispatches;
