import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import OrganizationDropdown from '../components/OrganizationDropdown';
import * as selectors from '../OrganizationDropdownSelectors';
import * as actions from '../actions';

class OrganizationDropdownContainer extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.props.getOrganizations();
  }

  render() {
    if (this.props.isOrganizationListLoading) {
      return (
        <PulseLoader color="#F8E71C" size={8} />
      );
    }

    const filteredOrganizationIds = this.props.excludedOrganizations.map(current => current.id);
    const filteredOrganizationList = this.props.organizationList
      .filter(current => filteredOrganizationIds.indexOf(current.id) === -1)
      .filter(current => !this.props.customerOnly || current.customer)
      .filter(current => !this.props.enabledOnly || current.enabled);

    return (
      <OrganizationDropdown
        id={this.props.id}
        name={this.props.name}
        organizationList={filteredOrganizationList}
        selectedOrganization={this.props.selectedOrganization}
        onOrganizationSelect={this.props.onOrganizationSelect}
        disabled={this.props.disabled}
        className={this.props.className}
        style={this.props.style}
      />
    );
  }
}

OrganizationDropdownContainer.propTypes = {
  // public interface
  id: PropTypes.string,
  name: PropTypes.string,
  selectedOrganization: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  excludedOrganizations: PropTypes.array,
  onOrganizationSelect: PropTypes.func.isRequired,
  customerOnly: PropTypes.bool,
  enabledOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.any,

  // internal stuff
  isOrganizationListLoading: PropTypes.bool.isRequired,
  organizationList: PropTypes.array.isRequired,
  getOrganizations: PropTypes.func.isRequired,
};

OrganizationDropdownContainer.defaultProps = {
  id: null,
  name: null,
  selectedOrganization: null,
  excludedOrganizations: [],
  customerOnly: false,
  enabledOnly: false,
  disabled: false,
  className: null,
  style: null,
};

function mapStateToProps(state) {
  return {
    isOrganizationListLoading: selectors.isOrganizationListLoading(state),
    organizationList: selectors.organizationList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrganizations: () => dispatch(actions.getOrganizations()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDropdownContainer);
