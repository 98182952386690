import { LineClient } from '../../../../../../../client';
import ATGLinesActionTypes from '../ATGLinesActionTypes';
import { LLDTypes } from '../../../../../SiteConstants';
import * as siteSelectors from '../../../../../SiteSelectors';

function requestSiteLines() {
  return {
    type: ATGLinesActionTypes.REQUEST_SITE_LINES,
  };
}

function receiveSiteLines(lines) {
  return {
    type: ATGLinesActionTypes.RECEIVE_SITE_LINES,
    currentSiteLines: lines,
  };
}

function receiveSiteLinesError(error) {
  return {
    type: ATGLinesActionTypes.RECEIVE_SITE_LINES_ERROR,
    error,
  };
}

function filterLines(lines) {
  return lines.filter(current => current.lldType === LLDTypes.MechanicalLLD || current.lldType === LLDTypes.None || current.elldEnabled);
}

export function getSiteLines() {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentSite = siteSelectors.currentSite(currentState);
    if (!currentSite) return Promise.resolve();
    const siteId = currentSite.id;
    dispatch(requestSiteLines());
    return LineClient.getSiteLines(siteId).then((lines) => {
      dispatch(receiveSiteLines(filterLines(lines)));
    }).catch((err) => {
      dispatch(receiveSiteLinesError(err));
    });
  };
}
