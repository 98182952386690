import { DocumentClient } from '../../../../../../client';
import ActionTypes from './UploadActionTypes';
import { FileStatus, Steps } from './UploadConstants';
import * as siteSelectors from '../../../../SiteSelectors';
import * as selectors from './UploadSelectors';


export function start(files, destinationFolder, issues, mviReport) {
  return { type: ActionTypes.START, files, destinationFolder, issues, mviReport };
}

export function fileDrop(blobFiles) {
  return (dispatch) => {
    const promises = [];
    for (let i = 0; i < blobFiles.length; i++) {
      const currentFile = blobFiles[i];
      if (currentFile instanceof Blob) {
        const promise = readBlobFile(currentFile);
        promises.push(promise);
      }
    }
    return Promise.all(promises).then(files => {
      dispatch({ type: ActionTypes.SET_FILES, files, step: Steps.SelectFolder });
    });
  };
}

function fileExtension(filename) {
  if (filename) {
    const parts = filename.split('.');
    const last = parts[parts.length - 1];
    if (last.length === 3 || last.length === 4) {
      return last;
    }
  }
  return null;
}

function readBlobFile(file) {
  return new Promise((resolve, reject) => {
    let isRejected = false;
    const rejectOnce = (err) => {
      if (!isRejected) {
        isRejected = true;
        reject(err);
      }
    };

    const reader = new FileReader();
    reader.onerror = rejectOnce;
    reader.onload = event => {
      resolve({
        filename: file.name || '',
        dataUri: event.target.result,
        status: FileStatus.NotStarted,
        extension: fileExtension(file.name),
      });
    };
    reader.readAsDataURL(file);
  });
}

export function setDestinationFolder(destinationFolder) {
  return { type: ActionTypes.SET_DESTINATION_FOLDER, destinationFolder };
}

export function toggleIssueSelect(issue) {
  return { type: ActionTypes.TOGGLE_ISSUE_SELECT, issue };
}

function nextStep(step) {
  return { type: ActionTypes.NEXT_STEP, step };
}

export function reset() {
  return { type: ActionTypes.RESET };
}

export function upload() {
  return (dispatch, getState) => {
    const state = getState();
    const site = siteSelectors.currentSite(state);
    const files = selectors.files(state);
    const destinationFolder = selectors.destinationFolder(state);
    const issueIds = selectors.issueIds(state);
    const mviReportId = selectors.mviReportId(state);
    if (!site || !files || !files.length || !destinationFolder) {
      return Promise.resolve();
    }

    dispatch(nextStep(Steps.Uploading));
    let promise = Promise.resolve();
    files.forEach(file => {
      promise = promise.then(() => dispatch(createDocument(site, destinationFolder, file, issueIds, mviReportId)));
    });
    return promise.then(() => {
      dispatch(nextStep(Steps.UploadSuccess));
    });
  };
}

function createDocument(site, destinationFolder, file, issueIds, mviReportId) {
  return (dispatch) => {
    const doc = {
      siteId: site.id,
      folderId: destinationFolder.id,
      filename: file.filename,
      content: file.dataUri,
      issues: issueIds.map(current => ({ id: current })),
      mviReports: mviReportId ? [{ id: mviReportId }] : [],
    };

    dispatch({ type: ActionTypes.CREATE_DOC_STARTED, file });
    return DocumentClient.createDocument(doc)
      .then(persistentDocument => {
        dispatch({ type: ActionTypes.CREATE_DOC_SUCCESS, file, document: persistentDocument });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.CREATE_DOC_FAILED, file, error });
        return Promise.reject(error);
      });
  };
}
