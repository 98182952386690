import ActionTypes from './IssueExportActionTypes';
import * as selectors from './IssueExportSelectors';
import { ReportClient } from '../../../../../../../../client';
import SaveFileActionHelper from '../../../../../../../../shared/redux-helpers/SaveFileActionHelper';

class IssueExportContext {
  constructor(issueId, options) {
    this._issueId = issueId;
    this._issueComponents = [];
    this._includeRelatedReports = false;
    if (options) {
      if (options.analysisRec) {
        this._issueComponents.push('analysis');
      }

      if (options.comments) {
        this._issueComponents.push('comments');
      }

      if (options.attachments) {
        this._issueComponents.push('documents');
      }

      if (options.atgReports) {
        this._includeRelatedReports = true;
      }
    }
  }

  toJSON() {
    return {
      reportType: 'issueExport',
      constraint: {
        issueId: this._issueId,
        issueComponents: this._issueComponents
      },
      includeRelatedReports: this._includeRelatedReports,
    };
  }
}

export function openModal(issue) {
  return { type: ActionTypes.OPEN_EXPORT_MODAL, issue };
}

export function closeModal() {
  return { type: ActionTypes.CLOSE_EXPORT_MODAL };
}

export function exportIssue() {
  return (dispatch, getState) => {
    const currentState = getState();
    const issue = selectors.selectedIssue(currentState);
    const options = selectors.exportOptions(currentState);

    if (!issue || selectors.isDownloading(currentState)) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.DOWNLOAD_STARTED });
    return ReportClient.download(new IssueExportContext(issue.id, options))
      .then(content => dispatch(SaveFileActionHelper.saveAsFile(content, `Canary_Issue_${issue.id}`, ActionTypes.DOWNLOAD_SUCCESS)))
      .then(() => dispatch(closeModal()))
      .catch(err => {
        dispatch({ type: ActionTypes.DOWNLOAD_FAILED, error: err });
        return Promise.reject(err);
      });
  };
}

export function setExportOptions(options) {
  return { type: ActionTypes.SET_OPTIONS, options };
}
