import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, NavLink } from 'react-router-dom';
import { push } from 'react-router-redux';
import { Button, BreadcrumbItem } from 'reactstrap';

import NavContext from '../../../shared/components/nav-context/NavContext';
import * as NavContextSelectors from '../../../shared/components/nav-context/NavContextSelectors';
import * as NavContextActions from '../../../shared/components/nav-context/NavContextActions';
import * as IssueSelectors from '../screens/issues/screens/issue-edit/IssueEditSelectors';
import * as ComplianceReportSelectors from '../screens/compliance/screens/compliance-report/ComplianceReportSelectors';
import MviEditSelectors from '../screens/mvi/screens/edit/MviEditSelectors';
import * as selectors from '../SiteSelectors';
import {UserNavType} from '../../../AppConstants';

class SiteBreadcrumb extends Component {
  isSiteOverview() {
    return this.props.location.pathname === `/sites/${this.props.site.id}` || this.props.location.pathname.endsWith('/overview');
  }

  render() {
    return (
      <nav>
        <ol className="breadcrumb">
          {this.renderOriginBreadcrumb()}
          {this.renderSiteBreadcrumb()}
          {this.renderSiteSectionBreadcrumbs()}
          <li style={{ flexGrow: 9999, flexBasis: '5em' }} />
          {this.renderNavButtons()}
        </ol>
      </nav>
    );
  }

  renderOriginBreadcrumb() {
    if (!this.props.hasNavContext || !this.props.originType) {
      return (
        <BreadcrumbItem>
          <Button color="link" onClick={this.props.navToSiteList}>
            {UserNavType.SiteList}
          </Button>
        </BreadcrumbItem>
      );
    }

    return (
      <BreadcrumbItem>
        <Button color="link" onClick={this.props.navBackToOrigin}>
          {this.props.originType}
        </Button>
      </BreadcrumbItem>
    );
  }

  renderSiteBreadcrumb() {
    if (!this.props.site) {
      return null;
    }

    return (
      <BreadcrumbItem>
        <NavLink to={`/sites/${this.props.site.id}/overview`}>
          {this.props.site.nickname}
        </NavLink>
      </BreadcrumbItem>
    );
  }

  renderSiteSectionBreadcrumbs() {
    if (!this.props.site) {
      return null;
    }

    return (
      <Switch>
        <Route exact path="/sites/:siteId/overview">
          <BreadcrumbItem active>Dashboard</BreadcrumbItem>
        </Route>
        <Route exact path="/sites/:siteId/issues">
          <BreadcrumbItem active>Site Issues</BreadcrumbItem>
        </Route>
        <Route path="/sites/:siteId/issues/:issueId">
          <>
            <BreadcrumbItem>
              <NavLink to={`/sites/${this.props.site.id}/issues`}>
                Site Issues
              </NavLink>
            </BreadcrumbItem>
            {this.props.issue
              ? <BreadcrumbItem active>{`Issue ${this.props.issue.id}`}</BreadcrumbItem>
              : null}
          </>
        </Route>
        <Route exact path="/sites/:siteId/reports">
          <BreadcrumbItem active>Site Compliance</BreadcrumbItem>
        </Route>
        <Route path="/sites/:siteId/reports">
          <>
            <BreadcrumbItem>
              <NavLink to={`/sites/${this.props.site.id}/reports`}>
                Site Compliance
              </NavLink>
            </BreadcrumbItem>
            {this.props.compliance
              ? <BreadcrumbItem active>{`${this.props.compliance.monthText} ${this.props.compliance.year}`}</BreadcrumbItem>
              : null}
          </>
        </Route>
        <Route exact path="/sites/:siteId/inspections">
          <BreadcrumbItem active>Site MVIs</BreadcrumbItem>
        </Route>
        <Route path="/sites/:siteId/inspections/:mviId">
          <>
            <BreadcrumbItem>
              <NavLink to={`/sites/${this.props.site.id}/inspections`}>
                Site MVIs
              </NavLink>
            </BreadcrumbItem>
            {this.props.mvi
              ? <BreadcrumbItem active>{`${this.props.mvi.monthText} ${this.props.mvi.year}`}</BreadcrumbItem>
              : null}
          </>
        </Route>
        <Route path="/sites/:siteId/atg-command">
          <BreadcrumbItem active>ATG Command</BreadcrumbItem>
        </Route>
        <Route path="/sites/:siteId/equipment">
          <BreadcrumbItem active>Equipment</BreadcrumbItem>
        </Route>
        <Route path="/sites/:siteId/documents">
          <BreadcrumbItem active>Documents</BreadcrumbItem>
        </Route>
        <Route path="/sites/:siteId/work-orders">
          <BreadcrumbItem active>Work Orders</BreadcrumbItem>
        </Route>
        <Route path="/sites/:siteId/site-info">
          <BreadcrumbItem active>Site Info</BreadcrumbItem>
        </Route>
      </Switch>
    );
  }

  renderNavButtons() {
    if (this.props.hasNavContext) {
      return (
        <li className="flex-grow-1">
          <NavContext />
        </li>
      );
    }
    return null;
  }
}

SiteBreadcrumb.propTypes = {
  site: PropTypes.object,
  issue: PropTypes.object,
  mvi: PropTypes.object,
  compliance: PropTypes.object,
  originType: PropTypes.string,
  hasNavContext: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  navToSiteList: PropTypes.func.isRequired,
  navBackToOrigin: PropTypes.func.isRequired,
};

SiteBreadcrumb.defaultProps = {
  site: null,
  issue: null,
  mvi: null,
  compliance: null,
  originType: null,
};

function mapStateToProps(state) {
  const site = selectors.currentSite(state);

  return {
    site,
    issue: IssueSelectors.currentIssue(state),
    mvi: MviEditSelectors.item(state),
    compliance: ComplianceReportSelectors.currentReport(state),
    originType: NavContextSelectors.originType(state),
    hasNavContext: NavContextSelectors.hasContext(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navToSiteList: () => dispatch(push('/sites')),
    navBackToOrigin: () => dispatch(NavContextActions.navBackToOrigin()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteBreadcrumb));
