import React from 'react';
import PropTypes from 'prop-types';

const formatVolume = volume => (Number.isInteger(volume) && volume.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })) || 'Unknown';
const formatInches = inches => (Number.isInteger(inches) && inches.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) || 'Unknown';

const LowTankIcon = ({ tankNumber, productLabel, fullVolumeGallons, volumeGallons, ullageGallons, ullage90PercentGallons, waterHeightInches, style }) => (
  <div className="d-flex flex-column align-items-center p-2" style={style}>
    <h5 className="d-block text-center">{`T${tankNumber} ${productLabel}`}</h5>
    <svg className="tank-icon m-2" viewBox="0 0 278 293" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none">
        <path
          d="M36.0429072,246 L239.957093,246 C214.720617,273.651382 178.386302,291 138,291 C97.6136981,291 61.2793829,273.651382 36.0429072,246 Z"
          fill="#F6E512"
        />
        <path
          d="M116,16.7440594 L116,0 L160,0 L160,16.7440594 C225.758738,27.2790411 276,84.2722829 276,153 C276,229.215295 214.215295,291
           138,291 C61.7847045,291 0,229.215295 0,153 C0,84.2722829 50.2412616,27.2790411 116,16.7440594 Z"
          stroke="#7D7C77"
        />
        <text
          className="tank-icon-header"
          textAnchor="middle"
          y="85"
          x="50%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          Delivery Needed
        </text>

        <text
          className="tank-icon-label"
          textAnchor="end"
          y="115"
          x="52%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          Tank Size:
        </text>
        <text
          className="tank-icon-value"
          textAnchor="start"
          y="115"
          x="55%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          {formatVolume(fullVolumeGallons)}
        </text>

        <text
          className="tank-icon-label"
          textAnchor="end"
          y="140"
          x="52%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          Volume:
        </text>
        <text
          className="tank-icon-value"
          textAnchor="start"
          y="140"
          x="55%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          {formatVolume(volumeGallons)}
        </text>

        <text
          className="tank-icon-label"
          textAnchor="end"
          y="165"
          x="52%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          Ullage:
        </text>
        <text
          className="tank-icon-value"
          textAnchor="start"
          y="165"
          x="55%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          {formatVolume(ullageGallons)}
        </text>

        <text
          className="tank-icon-label"
          textAnchor="end"
          y="190"
          x="52%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          90% Ullage:
        </text>
        <text
          className="tank-icon-value"
          textAnchor="start"
          y="190"
          x="55%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          {formatVolume(ullage90PercentGallons)}
        </text>

        <text
          className="tank-icon-label"
          textAnchor="end"
          y="215"
          x="52%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          Water Height:
        </text>
        <text
          className="tank-icon-value"
          textAnchor="start"
          y="215"
          x="55%"
          strokeOpacity="null"
          strokeWidth="0"
          stroke="#000"
          fill="#000000"
        >
          {formatInches(waterHeightInches)}
        </text>

      </g>
    </svg>
  </div>
);

LowTankIcon.propTypes = {
  tankNumber: PropTypes.number.isRequired,
  productLabel: PropTypes.string,
  fullVolumeGallons: PropTypes.number,
  volumeGallons: PropTypes.number,
  ullageGallons: PropTypes.number,
  ullage90PercentGallons: PropTypes.number,
  waterHeightInches: PropTypes.number,
  style: PropTypes.object,
};

LowTankIcon.defaultProps = {
  productLabel: null,
  fullVolumeGallons: null,
  volumeGallons: null,
  ullageGallons: null,
  ullage90PercentGallons: null,
  waterHeightInches: null,
  style: null,
};

export default LowTankIcon;
