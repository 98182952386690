import { createSocketMiddleware } from '../../../../SocketMiddleware';
import { updateIntentRequestStatus } from './actions/CommandStatusActions';

const socketMiddleware = createSocketMiddleware({
  intentRequestFulfilled: (socketMessage, dispatch) => dispatch(updateIntentRequestStatus(socketMessage.id)),
});

export default {
  socketMiddleware,
};
