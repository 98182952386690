export default {
  GET_TOURS_STARTED: 'shared/tours/get_tours_started',
  RECEIVE_TOURS: 'shared/tours/receive_tours',
  GET_TOURS_FAILED: 'shared/tours/get_tours_failed',

  MARK_TOUR_COMPLETE_STARTED: 'shared/tours/mark_tour_complete_started',
  MARK_TOUR_COMPLETE_SUCCESS: 'shared/tours/mark_tour_complete_success',
  MARK_TOUR_COMPLETE_FAILED: 'shared/tours/mark_tour_complete_failed',

  RESET_TOURS_STARTED: 'shared/tours/reset_tours_started',
  RESET_TOURS_SUCCESS: 'shared/tours/reset_tours_success',
  RESET_TOURS_FAILED: 'shared/tours/reset_tours_failed',
};
