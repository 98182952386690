import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import { CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faGripVertical, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';

import { DateOrTime } from '../../../../../../shared/components/date';
import { ItemType } from '../../DocConstants';
import DocDragSource from './DocDragSource';
import FileIcon from './FileIcon';
import EmptyFolder from './EmptyFolder';


function buildLinkToParentRow(activeFolder, onFolderOpen) {
  if (activeFolder && !activeFolder.parentId) {
    return null;
  }
  const rowClickHandler = () => onFolderOpen(activeFolder.parentId);
  return (
    <tr>
      <td />
      <td />
      <td>
        <FontAwesomeIcon size="1x" icon={faLevelUpAlt} className="mx-1 fa-flip-horizontal" />
      </td>
      <td style={{ cursor: 'pointer' }} onClick={rowClickHandler}>parent folder</td>
      <td />
      <td />
      <td />
    </tr>
  );
}

function buildItemRow(item, onSingleItemSelectToggle, onItemSelectToggle, onDocDragStart, onFolderOpen, onDocOpen) {
  if (item.type === ItemType.Folder) {
    return buildFolderRow(item, onSingleItemSelectToggle, onItemSelectToggle, onFolderOpen);
  }
  return buildDocRow(item, onSingleItemSelectToggle, onItemSelectToggle, onDocDragStart, onDocOpen);
}

function buildFolderRow(folder, onSingleItemSelectToggle, onItemSelectToggle, onFolderOpen) {
  const rowClickHandler = e => {
    if ((e.ctrlKey || e.metaKey) && e.detail === 1) {
      onItemSelectToggle(folder);
    }
    else if (e.detail === 1) {
      onSingleItemSelectToggle(folder);
    }
    else {
      onSingleItemSelectToggle(folder);
      onFolderOpen(folder);
    }
  };

  return (
    <tr key={folder.id}>
      <td />
      <td>
        <CustomInput id={`folder_${folder.id}_check`} type="checkbox" className="mb-0" checked={folder.checked} onChange={(e) => onSingleItemSelectToggle(folder, e.target.checked)} />
      </td>
      <td>
        <FontAwesomeIcon size="1x" icon={faFolder} className="mx-1" />
      </td>
      <td style={{ cursor: 'pointer' }} onClick={rowClickHandler}>{folder.folderName}</td>
      <td style={{ cursor: 'pointer' }} onClick={rowClickHandler} className="d-none d-md-table-cell">
        <DateOrTime time={folder.created} />
      </td>
      <td style={{ cursor: 'pointer' }} onClick={rowClickHandler} className="d-none d-md-table-cell">{folder.user.username}</td>
      <td />
    </tr>
  );
}

function buildDocRow(doc, onSingleItemSelectToggle, onItemSelectToggle, onDocDragStart, onDocOpen) {
  const rowClickHandler = e => {
    if ((e.ctrlKey || e.metaKey) && e.detail === 1) {
      onItemSelectToggle(doc);
    }
    else if (e.detail === 1) {
      onSingleItemSelectToggle(doc);
    }
    else {
      onSingleItemSelectToggle(doc);
      onDocOpen(doc);
    }
  };

  return (
    <DocDragSource key={doc.id} doc={doc} onDocDragStart={onDocDragStart}>
      <tr className="canary-draggable-table-row">
        <td>
          <FontAwesomeIcon size="1x" icon={faGripVertical} className="mx-1" />
        </td>
        <td>
          <CustomInput id={`doc_${doc.id}_check`} type="checkbox" className="mb-0" checked={doc.checked} onChange={() => onItemSelectToggle(doc)} />
        </td>
        <td>
          <FileIcon size="1x" extension={doc.extension} className="mx-1" />
        </td>
        <td className="text-truncate" style={{ cursor: 'pointer' }} onClick={rowClickHandler}>
          {doc.filename}
        </td>
        <td style={{ cursor: 'pointer' }} onClick={rowClickHandler}>
          <DateOrTime time={doc.created} />
        </td>
        <td style={{ cursor: 'pointer' }} className="text-truncate d-none d-md-table-cell">
          {doc.user.username}
        </td>
        <td style={{ cursor: 'pointer' }} onClick={rowClickHandler} className="d-none d-md-table-cell">
          {filesize(doc.size, { round: 0 })}
        </td>
      </tr>
    </DocDragSource>
  );
}

const TableView = ({ activeFolder, items, onSingleItemSelectToggle, onItemSelectToggle, onSelectAllToggle, onDocDragStart, onFolderOpen, onDocOpen }) => {
  const isSelectAllChecked = !!items.length && items.every(item => item.checked);
  return (
    <>
      <table className="table table-sm table-hover canary-table-2 w-100" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '1.5rem' }} />
            <th className="py-1" style={{ width: '1.5rem' }}>
              {items.length ? <CustomInput id="select_all_check" type="checkbox" className="mb-0" checked={isSelectAllChecked} onChange={(e) => onSelectAllToggle(e.target.checked)} /> : null}
            </th>
            <th style={{ width: '1.5rem' }} />
            <th>Name</th>
            <th style={{ width: '6rem' }}>Created</th>
            <th style={{ width: '7rem' }} className="d-none d-md-table-cell">User</th>
            <th style={{ width: '5rem' }} className="d-none d-md-table-cell">Size</th>
          </tr>
        </thead>
        <tbody>
          {buildLinkToParentRow(activeFolder, onFolderOpen)}
          {items.map(current => buildItemRow(current, onSingleItemSelectToggle, onItemSelectToggle, onDocDragStart, onFolderOpen, onDocOpen))}
        </tbody>
      </table>
      {!items || !items.length ? <EmptyFolder className="mt-md-5" /> : null}
    </>
  );
};

TableView.propTypes = {
  activeFolder: PropTypes.object,
  items: PropTypes.array,
  onSingleItemSelectToggle: PropTypes.func.isRequired,
  onItemSelectToggle: PropTypes.func.isRequired,
  onSelectAllToggle: PropTypes.func.isRequired,
  onDocDragStart: PropTypes.func.isRequired,
  onFolderOpen: PropTypes.func.isRequired,
  onDocOpen: PropTypes.func.isRequired,
};

TableView.defaultProps = {
  activeFolder: null,
  items: [],
};

export default TableView;
