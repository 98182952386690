import isEqual from 'lodash/isEqual';

import { Features, MviFeatureLevels } from '../../AppConstants';
import OrganizationPermissions from '../../organization-permissions';
import { AuthenticationSelectors } from '../../authentication';

export function isCurrentSiteLoading(state) {
  return (state && state.sites && state.sites.root && state.sites.root.currentSiteLoading) || false;
}

export function loadingFailed(state) {
  return (state && state.sites && state.sites.root && state.sites.root.currentSiteLoadingFailed) || false;
}

export function isSaving(state) {
  return (state && state.sites && state.sites.root && state.sites.root.saving) || false;
}

export function saveError(state) {
  return (state && state.sites && state.sites.root && state.sites.root.saveError) || null;
}

export function currentSite(state) {
  return (state && state.sites && state.sites.root && state.sites.root.currentSite) || null;
}

export function currentSiteId(state) {
  const site = currentSite(state);
  return site && site.id;
}

export function pendingEdits(state) {
  return (state && state.sites && state.sites.root && state.sites.root.pendingEdits) || null;
}

export function pendingSiteEditsPristine(state) {
  const site = currentSite(state);
  const edits = pendingEdits(state);
  return (site && edits && isEqual(site, edits)) || false;
}

export function customerLabels(state) {
  return (state && state.sites && state.sites.root && state.sites.root.customerLabels) || [];
}

export function canEditSite(state) {
  const site = currentSite(state);
  return AuthenticationSelectors.hasPermissionForSite(state, site, OrganizationPermissions.SiteEdit);
}

export function canViewInventory(state) {
  const site = currentSite(state);
  return AuthenticationSelectors.hasPermissionForSite(state, site, OrganizationPermissions.InventoryView) && AuthenticationSelectors.isFeatureEnabledForSite(state, site, Features.Inventory);
}

export function canViewIssues(state) {
  const site = currentSite(state);
  return AuthenticationSelectors.hasPermissionForSite(state, site, OrganizationPermissions.IssueView) && AuthenticationSelectors.isFeatureEnabledForSite(state, site, Features.AlarmManagement);
}

export function canEditIssues(state) {
  const site = currentSite(state);
  return AuthenticationSelectors.hasPermissionForSite(state, site, OrganizationPermissions.IssueEdit) && AuthenticationSelectors.isFeatureEnabledForSite(state, site, Features.AlarmManagement);
}

export function canViewCompliance(state) {
  const site = currentSite(state);
  return AuthenticationSelectors.hasPermissionForSite(state, site, OrganizationPermissions.ComplianceView) && AuthenticationSelectors.isFeatureEnabledForSite(state, site, Features.Compliance);
}

export function canEditCompliance(state) {
  const site = currentSite(state);
  return AuthenticationSelectors.hasPermissionForSite(state, site, OrganizationPermissions.ComplianceEdit) && AuthenticationSelectors.isFeatureEnabledForSite(state, site, Features.Compliance);
}

export function canViewMvis(state) {
  const site = currentSite(state);
  return AuthenticationSelectors.hasPermissionForSite(state, site, OrganizationPermissions.MVIView) && AuthenticationSelectors.isFeatureEnabledForSite(state, site, Features.MonthlyVisualInspections);
}

export function canViewMviForms(state) {
  const site = currentSite(state);
  return AuthenticationSelectors.hasPermissionForSite(state, site, OrganizationPermissions.MVIView) && AuthenticationSelectors.isFeatureEnabledForSite(state, site, Features.MonthlyVisualInspections, MviFeatureLevels.MVIForm);
}

export function canEditMvis(state) {
  const site = currentSite(state);
  return AuthenticationSelectors.hasPermissionForSite(state, site, OrganizationPermissions.MVIEdit) && AuthenticationSelectors.isFeatureEnabledForSite(state, site, Features.MonthlyVisualInspections);
}

export function canCallSiteCommands(state) {
  const site = currentSite(state);
  return AuthenticationSelectors.hasPermissionForSite(state, site, OrganizationPermissions.AtgCommandsInventory);
}

export function hasDispatchSystem(state) {
  const site = currentSite(state);
  const customerId = site && site.customerId;
  return AuthenticationSelectors.customerHasDispatchSystem(state, customerId);
}

export function customerDispatchSystems(state) {
  const site = currentSite(state);
  const customerId = site && site.customerId;
  return AuthenticationSelectors.customerDispatchSystems(state, customerId);
}

export function featureLevel(state, featureName) {
  const site = currentSite(state);
  const appFeature = AuthenticationSelectors.getFeatureForSite(state, site, featureName);
  return (appFeature && appFeature.enabled && appFeature.featureLevel) || null;
}
