import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import * as selectors from './BreadcrumbSelectors';

function Breadcrumbs(props) {
  if (!props.locations.length || props.locations.length === 1) {
    return null;
  }

  const lastIndex = props.locations.length - 1;
  return (
    <Breadcrumb>
      {props.locations.map((current, index) => (
        <BreadcrumbItem active={index === lastIndex}>
          {index !== lastIndex
            ? <NavLink to={current.path} className="nav-link d-inline p-0">{current.name}</NavLink>
            : current.name}
        </BreadcrumbItem>

      ))}
    </Breadcrumb>
  );
}

Breadcrumbs.propTypes = {
  locations: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    locations: selectors.locationHistory(state),
  };
}

export default connect(mapStateToProps)(Breadcrumbs);
