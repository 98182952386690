import ActionTypes from './WorkflowActionTypes';

export default function WorkflowReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.OPEN_CONFIRMATION_MODAL:
      return Object.assign({}, state, {
        modalOpen: true,
        issue: action.issue,
      });
    case ActionTypes.CANCEL_CONFIRMATION_MODAL:
      return Object.assign({}, state, {
        modalOpen: false,
        issue: null,
      });

    case ActionTypes.UPDATE_WORKFLOW_STARTED:
      return Object.assign({}, state, {
        saving: true,
        issue: action.issue,
      });
    case ActionTypes.UPDATE_WORKFLOW_SUCCESS:
      return Object.assign({}, state, {
        saving: false,
        modalOpen: false,
        issue: null,
      });
    case ActionTypes.UPDATE_WORKFLOW_FAILED:
      return Object.assign({}, state, {
        saving: false,
        modalOpen: false,
        error: action.error,
      });
    default:
      return state;
  }
}
