import React, { Component } from 'react';
import { Alert, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import { connect } from 'react-redux';

import TabView from '../../../../../../shared/components/TabView';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import FuelHaulerSettings from '../components/FuelHaulerSettings';
import FuelHaulerSites from '../components/FuelHaulerSites';
import FuelHaulerSchedule from '../components/FuelHaulerSchedule';
import * as actions from '../actions';
import * as selectors from '../FuelHaulersSelectors';
import FuelHaulerDestinations from '../components/FuelHaulerDestinations';


class FuelHaulerEditor extends Component {
  constructor(props) {
    super(props);

    this.renderSettingsForm = this.renderSettingsForm.bind(this);
    this.renderSiteChooser = this.renderSiteChooser.bind(this);
    this.renderDestinationForm = this.renderDestinationForm.bind(this);
    this.renderScheduleForm = this.renderScheduleForm.bind(this);

    if (props.match && props.match.params && props.match.params.fuelHaulerId) {
      props.selectFuelHauler(props.match.params.fuelHaulerId);
    }
  }

  renderSettingsForm() {
    return (
      <FuelHaulerSettings
        selectedFuelHauler={this.props.selectedFuelHauler}
        updateSelectedFuelHauler={this.props.updateSelectedFuelHauler}
      />
    );
  }

  renderSiteChooser() {
    return (
      <FuelHaulerSites
        selectedFuelHauler={this.props.selectedFuelHauler}
        updateSelectedFuelHauler={this.props.updateSelectedFuelHauler}
        exportSelectedFuelHaulerSites={this.props.exportSelectedFuelHaulerSites}
        importSites={this.props.importSites}
      />
    );
  }

  renderDestinationForm() {
    return (
      <FuelHaulerDestinations
        selectedFuelHauler={this.props.selectedFuelHauler}
        updateSelectedFuelHauler={this.props.updateSelectedFuelHauler}
      />
    );
  }

  renderScheduleForm() {
    return (
      <FuelHaulerSchedule
        selectedFuelHauler={this.props.selectedFuelHauler}
        updateSelectedFuelHauler={this.props.updateSelectedFuelHauler}
      />
    );
  }

  renderErrors() {
    if (this.props.error) {
      return <ErrorMessage title="Failed to save fuel hauler" error={this.props.error} />;
    }
    else if (this.props.validationErrors.length) {
      return (
        <Alert color="danger" className="mt-1">
          There were problems saving this fuel hauler:
          <ul className="ml-1 mt-1">
            {this.props.validationErrors.map(current => (
              <li key={current}>{current}</li>
            ))}
          </ul>
        </Alert>
      );
    }
    return null;
  }

  render() {
    if (this.props.isLoading || this.props.isSaving) {
      return (
        <PulseLoader color="#F8E71C" size={8} />
      );
    }

    const tabs = [
      {
        name: 'Settings',
        render: this.renderSettingsForm,
      },
      {
        name: 'Sites',
        render: this.renderSiteChooser,
      },
      {
        name: 'Destination',
        render: this.renderDestinationForm,
      },
      {
        name: 'Schedule',
        render: this.renderScheduleForm,
      },
    ];

    let name = '';
    if (this.props.selectedFuelHauler && Number.isInteger(this.props.selectedFuelHauler.id)) {
      name = this.props.selectedFuelHauler.name;
    }
    else if (this.props.selectedFuelHauler) {
      name = 'New Fuel Hauler';
    }

    return (
      <div>
        <h4>{name}</h4>
        {this.renderErrors()}
        <TabView tabs={tabs} />
        <Row className="mt-2">
          <Col md={4}>
            <Button color="primary" outline size="sm" onClick={this.props.cancelSelectedFuelHaulerEdit}>Cancel</Button>
            {' '}
            <Button id="save" color="primary" size="sm" onClick={this.props.saveSelectedFuelHauler}>Save</Button>
          </Col>
        </Row>
      </div>
    );
  }
}

FuelHaulerEditor.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,

  selectedFuelHauler: PropTypes.object,
  validationErrors: PropTypes.array,
  error: PropTypes.object,

  selectFuelHauler: PropTypes.func.isRequired,
  exportSelectedFuelHaulerSites: PropTypes.func.isRequired,
  importSites: PropTypes.func.isRequired,
  updateSelectedFuelHauler: PropTypes.func.isRequired,
  saveSelectedFuelHauler: PropTypes.func.isRequired,
  cancelSelectedFuelHaulerEdit: PropTypes.func.isRequired,

  match: PropTypes.object.isRequired,
};

FuelHaulerEditor.defaultProps = {
  selectedFuelHauler: null,
  validationErrors: [],
  error: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    isSaving: selectors.isSaving(state),
    selectedFuelHauler: selectors.selectedFuelHauler(state),
    validationErrors: selectors.validationErrors(state),
    error: selectors.error(state),

    // HACK: Force the page to update on navigate
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectFuelHauler: fuelHauler => dispatch(actions.selectFuelHauler(fuelHauler)),
    exportSelectedFuelHaulerSites: () => dispatch(actions.exportSelectedFuelHaulerSites()),
    importSites: rawCsvData => dispatch(actions.importSites(rawCsvData)),
    updateSelectedFuelHauler: fuelHauler => dispatch(actions.updateSelectedFuelHauler(fuelHauler)),
    saveSelectedFuelHauler: fuelHauler => dispatch(actions.saveSelectedFuelHauler(fuelHauler)),
    cancelSelectedFuelHaulerEdit: () => dispatch(actions.cancelSelectedFuelHaulerEdit()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FuelHaulerEditor));
