import { combineReducers } from 'redux';

import AtgReportsReducer from './components/atg-reports/AtgReportsReducer';
import MviModalReducer from './components/mvi/modal/ModalReducer';
import NavContextReducer from './components/nav-context/NavContextReducer';
import SitePickerReducer from './components/site-picker/SitePickerReducer';
import OrganizationDropdownReducer from './components/organization-dropdown/OrganizationDropdownReducer';
import TourReducer from './components/tour/TourReducer';

const SharedReducer = combineReducers({
  atgReports: AtgReportsReducer,
  mviModal: MviModalReducer,
  navContext: NavContextReducer,
  sitePicker: SitePickerReducer,
  tours: TourReducer,
  organizationDropdown: OrganizationDropdownReducer,
});

export default SharedReducer;
