import update from 'immutability-helper';
import findIndex from 'lodash/findIndex';
import { LOCATION_CHANGE } from 'react-router-redux';
import { buildStringSort } from '../../../../../shared/SortUtils';
import SiteEditorActionTypes from './screens/site-editor/SiteEditorActionTypes';
import UserActionTypes from './screens/user-editor/UserActionTypes';
import ActionTypes from './OrganizationEditActionTypes';

const nameSort = buildStringSort('nickname');
const usernameSort = (first, second) => first.username.toLowerCase().localeCompare(second.username.toLowerCase());

function receiveOrg(state, action) {
  let selectedOrganization = action.selectedOrganization;
  if (!selectedOrganization) {
    return receiveNullOrg(state);
  }

  const updateSpec = {
    $unset: ['receivedPolicies']
  };

  let sites = [];
  if (selectedOrganization.customer) {
    sites = selectedOrganization.customer.sites.sort(nameSort);
    updateSpec.customer = {
      $unset: ['sites']
    };
  }

  selectedOrganization = update(selectedOrganization, updateSpec);

  return Object.assign({}, state, {
    loading: false,
    saving: false,
    selectedOrganization,
    editedOrganization: selectedOrganization,
    selectedOrganizationSites: sites,
  });
}

function receiveNullOrg(state) {
  return Object.assign({}, state, {
    loading: false,
    saving: false,
    selectedOrganization: null,
    editedOrganization: null,
    selectedOrganizationSites: null,
  });
}

function updateSitesAndLabels(state, updatedSite) {
  const allSites = state.selectedOrganizationSites || [];
  const index = allSites.findIndex(current => current.id === updatedSite.id);
  let stateUpdateSpec;
  if (index !== -1) {
    stateUpdateSpec = {
      selectedOrganizationSites: {
        $splice: [[index, 1, updatedSite]],
      }
    };
  }
  else {
    stateUpdateSpec = {
      selectedOrganizationSites: {
        $push: [updatedSite],
      }
    };
  }

  // check if new labels were added to a site
  const existingLabels = (state.selectedOrganization && state.selectedOrganization.customer && state.selectedOrganization.customer.labels) || [];
  const siteLabels = updatedSite.labels || [];
  const newLabels = [];
  siteLabels.forEach(siteLabel => {
    const existingLabelIndex = existingLabels.findIndex(current => current.id === siteLabel.id);
    if (existingLabelIndex === -1) {
      newLabels.push(siteLabel);
    }
  });

  // if new labels were added to a site, make the labels available through the org
  if (newLabels.length) {
    const labelUpdateSpec = {
      customer: {
        labels: {
          $push: newLabels,
        },
      }
    };
    stateUpdateSpec.selectedOrganization = labelUpdateSpec;
    stateUpdateSpec.editedOrganization = labelUpdateSpec;
  }

  const tempState = update(state, stateUpdateSpec);

  return Object.assign({}, tempState, {
    selectedOrganizationSites: tempState.selectedOrganizationSites.sort(nameSort)
  });
}

function updateUsers(state, user) {
  const memberships = (state.selectedOrganization && state.selectedOrganization.memberships) || [];
  const index = memberships.findIndex(current => current.user.id === user.id);
  const selectedOrgId = state.selectedOrganization.id;
  const targetOrgId = user.organizationMembership && user.organizationMembership.organizationId;

  // update to an existing user that was already a member of the current organization
  if (index !== -1 && selectedOrgId === targetOrgId) {
    const existingMembership = memberships[index];
    const updatedMembership = update(existingMembership, {
      isAdmin: {
        $set: user.organizationMembership.isAdmin,
      },
      user: {
        email: {
          $set: user.email,
        },
        userType: {
          $set: user.userType,
        },
        active: {
          $set: user.active,
        }
      }
    });
    const stateUpdateSpec = {
      selectedOrganization: {
        memberships: {
          $splice: [[index, 1, updatedMembership]],
        },
        groups: {
          $set: buildUpdateGroups(state.selectedOrganization, user),
        }
      }
    };
    return update(state, stateUpdateSpec);
  }
  // update to an existing user - user moved out of current organization
  else if (index !== -1) {
    return update(state, {
      selectedOrganization: {
        memberships: {
          $splice: [[index, 1]],
        },
        groups: {
          $set: buildUpdateGroups(state.selectedOrganization, user),
        }
      }
    });
  }
  // new user added to current organization
  else if (selectedOrgId === targetOrgId) {
    const membership = {
      isAdmin: user.organizationMembership.isAdmin,
      organizationId: selectedOrgId,
      userId: user.id,
      user: {
        id: user.id,
        username: user.username,
        email: user.email,
        userType: user.userType,
        created: user.created,
        active: user.active,
      }
    };
    return update(state, {
      selectedOrganization: {
        memberships: {
          $push: [membership],
        },
        groups: {
          $set: buildUpdateGroups(state.selectedOrganization, user),
        }
      }
    });
  }

  return state;
}

function buildUpdateGroups(selectedOrganization, user) {
  if (!selectedOrganization.groups || !selectedOrganization.groups.length) {
    return [];
  }

  return selectedOrganization.groups.map(g => buildUpdatedGroup(g, user));
}

function buildUpdatedGroup(group, user) {
  const groupId = group.id;
  const shouldBeMember = user.groups && user.groups.length && findIndex(user.groups, g => g.id === groupId) !== -1;
  const isCurrentMember = groupContainsUser(group, user);
  if (shouldBeMember && !isCurrentMember) {
    const newMembers = [...group.members];
    newMembers.push(user);
    newMembers.sort(usernameSort);
    return update(group, { members: { $set: newMembers } });
  }
  else if (!shouldBeMember && isCurrentMember) {
    const newMembers = group.members.filter(u => u.id !== user.id);
    return update(group, { members: { $set: newMembers } });
  }
  return group;
}

function groupContainsUser(group, user) {
  if (!group.members || !group.members.length) {
    return false;
  }
  return findIndex(group.members, u => u.id === user.id) !== -1;
}

function handleLocationChange(state, action) {
  if (!state) {
    return null;
  }
  const selectedOrgId = state.selectedOrganization && state.selectedOrganization.id;
  const selectedOrgPath = `/admin/organizations/${selectedOrgId}`;
  const newPath = action.payload && action.payload.pathname;
  if (selectedOrgId && selectedOrgPath && newPath && !newPath.startsWith(selectedOrgPath)) {
    return null;
  }

  return Object.assign({}, state, {
    error: null,
  });
}

export default function OrganizationEditReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.RECEIVE_PERMISSION_SETS:
      return Object.assign({}, state, {
        permissionSets: action.permissionSets,
      });
    case ActionTypes.GET_ORGANIZATION_STARTED:
      return Object.assign({}, state, {
        loading: true,
        saving: false,
        selectedOrganization: null,
        editedOrganization: null,
        selectedOrganizationSites: null,
        error: null,
      });
    case ActionTypes.RECEIVE_ORGANIZATION:
      return receiveOrg(state, action);
    case ActionTypes.GET_ORGANIZATION_FAILED:
      return Object.assign({}, state, {
        loading: false,
        selectedOrganization: null,
        error: action.error,
      });

    case ActionTypes.EDIT_SELECTED_ORG:
      return Object.assign({}, state, {
        editedOrganization: action.editedOrganization,
      });
    case ActionTypes.DISCARD_SELECTED_ORG_EDITS:
      return Object.assign({}, state, {
        editedOrganization: state.selectedOrganization,
      });
    case LOCATION_CHANGE:
      return handleLocationChange(state, action);

    case ActionTypes.SAVE_ORGANIZATION_STARTED:
      return Object.assign({}, state, {
        saving: true,
        error: null,
      });
    case ActionTypes.SAVE_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        saving: false,
        selectedOrganization: action.selectedOrganization,
        editedOrganization: action.selectedOrganization,
      });
    case ActionTypes.SAVE_ORGANIZATION_FAILED:
      return Object.assign({}, state, {
        saving: false,
        error: action.error,
      });

    case SiteEditorActionTypes.SAVE_SITE_SUCCESS:
      return updateSitesAndLabels(state, action.site);
    case UserActionTypes.SAVE_USER_SUCCESS:
      return updateUsers(state, action.user);

    default:
      return state;
  }
}
