import { LOCATION_CHANGE } from 'react-router-redux';
import update from 'immutability-helper';
import { IssuePriorities } from '../../../../AppConstants';
import ActionTypes from './NewIssueActionTypes';

const DefaultState = {
  modalOpen: false,
};

function openModal(state, action) {
  const issueParams = action.issueParams || {};
  const issue = {
    site: action.site || null,
    description: issueParams.description || '',
    priority: IssuePriorities.NORMAL,
    analysis: issueParams.analysis || '',
    recommendations: issueParams.recommendations || '',
  };

  if (issueParams.issueType) {
    issue.issueType = issueParams.issueType;
  }
  if (issueParams.workflowStatus) {
    issue.workflowStatus = issueParams.workflowStatus;
  }
  if (issueParams.mviReports) {
    issue.mviReports = issueParams.mviReports;
  }
  if (issueParams.documents) {
    issue.documents = issueParams.documents;
  }

  return {
    modalOpen: true,
    siteEditDisabled: !!action.site,
    issue
  };
}

export default function NewIssueReducer(state = DefaultState, action) {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL:
      return openModal(state, action);
    case LOCATION_CHANGE:
    case ActionTypes.CANCEL_MODAL:
      return DefaultState;
    case ActionTypes.EDIT_ISSUE:
      return update(state, {
        issue: {
          $set: action.issue,
        }
      });
    case ActionTypes.CREATE_ISSUE_STARTED:
      return Object.assign({}, state, {
        saving: true,
      });
    case ActionTypes.CREATE_ISSUE_SUCCESS:
      return DefaultState;
    case ActionTypes.CREATE_ISSUE_FAILED:
      return Object.assign({}, state, {
        saving: false,
        error: action.error || null,
      });
    default:
      return state;
  }
}
