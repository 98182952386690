import React from 'react';
import PropTypes from 'prop-types';

const TrueFalse = ({ value }) => {
  if (value) {
    return (<span style={{ color: 'red', fontWeight: 'bold' }}>TRUE</span>);
  }
  return (<span>FALSE</span>);
};

TrueFalse.propTypes = {
  value: PropTypes.bool,
};

TrueFalse.defaultProps = {
  value: false,
};

export default TrueFalse;
