import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CustomInput, Form, FormGroup, Input, Label } from 'reactstrap';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { Features } from '../../../AppConstants';
import OrganizationPermissions from '../../../organization-permissions';
import SingleSiteInput from '../site-picker/containers/SingleSiteInput';

const MviInputForm = ({
  site,
  siteEditDisabled,
  onSiteEditStart,
  onSiteSelect,
  onSiteEditEnd,
  minInspectionDate,
  maxInspectionDate,
  inspectionDate,
  onInspectionDateChange,
  inspectorCompany,
  onInspectorCompanyChange,
  inspectorName,
  onInspectorNameChange,
  fileUploadEnabled,
  file,
  onFileSelect
}) => {
  const currentDate = inspectionDate ? moment(inspectionDate).toDate() : null;
  const minInspectionDateMoment = minInspectionDate && moment(minInspectionDate);
  const maxInspectionDateMoment = maxInspectionDate && moment(maxInspectionDate);

  const handleInspectionDateChange = (value) => {
    const valueMoment = value ? moment(value) : null;
    let newInspectionDate = valueMoment ? valueMoment.format('YYYY-MM-DD') : null;
    if (valueMoment && minInspectionDateMoment && maxInspectionDateMoment && (valueMoment.isBefore(minInspectionDateMoment) || valueMoment.isAfter(maxInspectionDateMoment))) {
      newInspectionDate = null;
    }
    onInspectionDateChange(newInspectionDate);
  };

  const renderInspectionDateInput = () => {
    let additionalProps = {};
    if (minInspectionDateMoment && maxInspectionDateMoment) {
      additionalProps = {
        min: minInspectionDateMoment.toDate(),
        max: maxInspectionDateMoment.toDate(),
        defaultCurrentDate: minInspectionDateMoment.toDate(),
      };
    }
    return (
      <DateTimePicker
        name="inspectionDate"
        time={false}
        value={currentDate}
        onChange={handleInspectionDateChange}
        {...additionalProps}
      />
    );
  };

  const handleFileSelect = e => onFileSelect(e.target.files[0]);

  const renderFileInput = () => {
    if (fileUploadEnabled && onFileSelect) {
      return (
        <FormGroup>
          <Label for="reportFile" className="mr-2">Report File</Label>
          <CustomInput id="reportFile" name="reportFile" type="file" label={(file && file.name) || 'Choose file...'} onChange={handleFileSelect} />
        </FormGroup>
      );
    }
    return null;
  };

  return (
    <Form>
      <FormGroup>
        <Label for="mviModalSiteInput">Site</Label>
        <SingleSiteInput
          id="mviModalSiteInput"
          site={site}
          onEditStart={onSiteEditStart}
          onEditEnd={onSiteEditEnd}
          onSiteSelect={onSiteSelect}
          groupByCustomer
          disabled={siteEditDisabled}
          feature={Features.MonthlyVisualInspections}
          sitePermission={OrganizationPermissions.MVIEdit}
        />
      </FormGroup>
      <FormGroup>
        <Label for="inspectionDate" className="mr-2">Inspected on</Label>
        {renderInspectionDateInput()}
      </FormGroup>
      <FormGroup>
        <Label for="inspectorCompany" className="mr-2">Company</Label>
        <Input name="inspectorCompany" id="inspectorCompany" placeholder="Company" className="mr-2" value={inspectorCompany || ''} onChange={e => onInspectorCompanyChange(e.target.value)} />
      </FormGroup>
      <FormGroup>
        <Label for="inspectorName" className="mr-2">Inspector name</Label>
        <Input name="inspectorName" id="inspectorName" placeholder="Inspector" value={inspectorName || ''} onChange={e => onInspectorNameChange(e.target.value)} />
      </FormGroup>
      {renderFileInput()}
    </Form>
  );
};

MviInputForm.propTypes = {
  site: PropTypes.object,
  siteEditDisabled: PropTypes.bool,
  onSiteEditStart: PropTypes.func.isRequired,
  onSiteSelect: PropTypes.func.isRequired,
  onSiteEditEnd: PropTypes.func.isRequired,
  minInspectionDate: PropTypes.any,
  maxInspectionDate: PropTypes.any,
  inspectionDate: PropTypes.string,
  onInspectionDateChange: PropTypes.func.isRequired,
  inspectorCompany: PropTypes.string,
  onInspectorCompanyChange: PropTypes.func.isRequired,
  inspectorName: PropTypes.string,
  onInspectorNameChange: PropTypes.func.isRequired,
  fileUploadEnabled: PropTypes.bool,
  file: PropTypes.any,
  onFileSelect: PropTypes.func,
};

MviInputForm.defaultProps = {
  site: null,
  siteEditDisabled: false,
  minInspectionDate: null,
  maxInspectionDate: null,
  inspectionDate: null,
  inspectorCompany: null,
  inspectorName: null,
  fileUploadEnabled: false,
  file: null,
  onFileSelect: null,
};

export default MviInputForm;
