import { push } from 'react-router-redux';
import find from 'lodash/find';

import { recordLogin, recordLogout } from '../../shared/analytics';
import { PresentAlert, PresentError } from '../../shared/ToastUtils';
import { AuthClient } from '../../client';
import AuthenticationStorage from '../AuthenticationStorage';
import AuthenticationActionTypes from '../AuthenticationActionTypes';
import { isError } from '../AuthenticationSelectors';

const UNAUTHORIZED = 401;

function loginRedirectPath(redirectPath) {
  return {
    type: AuthenticationActionTypes.SET_LOGIN_REDIRECT_PATH,
    redirectPath,
  };
}

const loginFailure = (error) => {
  if (error && error.response && error.response.status === UNAUTHORIZED) {
    return {
      type: AuthenticationActionTypes.LOGIN_FAILURE,
      error: {
        message: 'Incorrect username or password. Reset your password with the link below. Still having trouble? Call Canary at (267)354-0823',
      }
    };
  }
  else {
    return {
      type: AuthenticationActionTypes.LOGIN_FAILURE,
      error: (error.response && error.response.data && error.response.data) || { message: 'Unknown error' },
    };
  }
};

function loginAnalytics(loginResponse) {
  let org = null;
  if (loginResponse && loginResponse.permissions && loginResponse.permissions.organizations) {
    org = find(Object.values(loginResponse.permissions.organizations), currentOrg => currentOrg.member);
  }
  recordLogin(
    loginResponse.username || 'unknown',
    loginResponse.email,
    loginResponse.userType,
    org
  );
}

export function login(username, password) {
  AuthenticationStorage.reset();

  return (dispatch, getState) => {
    dispatch({
      type: AuthenticationActionTypes.LOGIN,
      username,
    });

    return AuthClient.login(username, password)
      .then((response) => {
        loginAnalytics(response);
        dispatch({
          type: AuthenticationActionTypes.LOGIN_SUCCESS,
          userId: response.userId,
          username: response.username,
          email: response.email,
          token: response.token,
          permissions: response.permissions,
        });

        const redirectPath = getState().authentication.loginRedirectPath;
        if (redirectPath) {
          dispatch(loginRedirectPath(null));
          dispatch(push(redirectPath));
        }
        else if (response.permissions && response.permissions.admin) {
          dispatch(push('/admin'));
        }
        else {
          dispatch(push('/'));
        }
      })
      .then(() => {
        if (isError(getState())) {
          return dispatch(clearLoginError());
        }
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(loginFailure(error));
      });
  };
}

export function logout() {
  return (dispatch) => {
    AuthClient.logout()
      .catch(() => {})
      .then(() => {
        recordLogout();
        dispatch({
          type: AuthenticationActionTypes.LOGOUT,
          userId: null,
        });
        dispatch(push('/login'));
      });
  };
}

export function clearLoginError() {
  return (dispatch) => {
    dispatch({
      type: AuthenticationActionTypes.CLEAR_LOGIN_ERROR,
    });
  };
}

export function resetPassword(token, password) {
  return (dispatch) => {
    dispatch({
      type: AuthenticationActionTypes.RESET_PASSWORD,
      password,
    });

    return AuthClient.resetPassword(token, password)
      .then(() => {
        dispatch({
          type: AuthenticationActionTypes.RESET_PASSWORD_SUCCESS,
        });
        PresentAlert('Your password was successfully reset. Please login with your new password.');
        setTimeout(() => {
          dispatch(push('/login'));
        }, 5000);
      }).catch((err) => {
        let message;
        if (err.response.status === 403) {
          message = 'Reset password link no longer valid. Please request a new one.';
        }
        else {
          message = `We could not reset your password. Please try again or contact support. ${err.response.data.message}`;
        }
        PresentError(message);
        dispatch({ type: AuthenticationActionTypes.RESET_PASSWORD_FAILURE, err });
      });
  };
}

export function loginRefresh() {
  return (dispatch, getState) => {
    const currentState = getState();
    return AuthClient.refreshLogin(currentState.authentication.userId)
      .then((refreshResult) => {
        loginAnalytics(refreshResult);
        dispatch({
          type: AuthenticationActionTypes.LOGIN_REFRESH,
          userId: currentState.authentication.userId,
          username: refreshResult.username,
          email: refreshResult.email,
          token: currentState.authentication.token,
          permissions: refreshResult.permissions,
        });
      })
      .catch(err => {
        console.log('Login refresh failed', err);
        return dispatch(logout());
      });
  };
}

export function redirectToLogin(redirectPath) {
  return (dispatch) => {
    if (redirectPath && redirectPath !== '/' && redirectPath !== '/login') {
      dispatch(loginRedirectPath(redirectPath));
    }
    else {
      dispatch(loginRedirectPath(null));
    }

    dispatch(push('/login'));
  };
}
