'use strict';

const All = 'All';
const List = 'List';
const NA = 'N/A';

export const ActorTypes = Object.freeze({
  Group: 'Group',
  Organization: 'Organization',
})

export const ActorGrantTypes = Object.freeze({
  List,
  NA,
  isValid: value => value !== undefined && (value === List || value === NA),
});

export const AssetGrantTypes = Object.freeze({
  All,
  List,
  NA,
  isValid:  value => value !== undefined && (value === All || value === List || value === NA),
});

