import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import update from 'immutability-helper';

import AppFeatures from '../../../../../../../components/app-features/AppFeatures';

class SiteAppFeatures extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.overrideCustomerSettings = this.overrideCustomerSettings.bind(this);
    this.restoreCustomerSettings = this.restoreCustomerSettings.bind(this);
  }

  isUsingCustomerDefaultSettings() {
    return !this.props.selectedSite.appFeatures || !this.props.selectedSite.appFeatures.length;
  }

  overrideCustomerSettings() {
    const customerDefaults = this.props.selectedSite.customer.appFeatures.map(current => ({
      feature: current.feature,
      featureLevel: current.featureLevel,
      enabled: current.enabled,
    }));
    const updatedSite = update(this.props.selectedSite, {
      appFeatures: {
        $set: customerDefaults,
      },
    });
    this.props.updateSelectedSite(updatedSite);
  }

  restoreCustomerSettings() {
    const updatedSite = update(this.props.selectedSite, {
      appFeatures: {
        $set: [],
      },
    });
    Promise.resolve(this.props.updateSelectedSite(updatedSite)).then(() => this.props.onSave());
  }

  render() {
    if (this.isUsingCustomerDefaultSettings()) {
      return (
        <div>
          <Alert color="primary" fade={false}>
            This site is inheriting its settings from the customer.  Click to override.
          </Alert>
          <Button onClick={this.overrideCustomerSettings} color="primary" outline size="sm" className="mb-4">Override</Button>
          <AppFeatures targetModel={this.props.selectedSite.customer} />
        </div>
      );
    }

    return (
      <div>
        <Alert color="primary" fade={false}>
          The settings for this site override the customer level settings. Click to restore to customer default settings.
        </Alert>
        <Button onClick={this.restoreCustomerSettings} color="primary" outline size="sm" className="mb-4">Use customer defaults</Button>
        <AppFeatures targetModel={this.props.selectedSite} updateTargetModel={this.props.updateSelectedSite} />
        <div className="mt-4">
          <Button id="cancel" color="primary" outline size="sm" onClick={this.props.onCancel} disabled={!this.props.canCancel}>Cancel</Button>
          {' '}
          <Button id="save" color="primary" size="sm" onClick={this.props.onSave} disabled={!this.props.canSave}>Save</Button>
        </div>
      </div>
    );
  }
}

SiteAppFeatures.propTypes = {
  selectedSite: PropTypes.shape({
    id: PropTypes.number,
    customer: PropTypes.shape({
      appFeatures: PropTypes.arrayOf(
        PropTypes.shape({
          feature: PropTypes.string.isRequired,
          featureLevel: PropTypes.string,
          enabled: PropTypes.bool.isRequired
        })
      ),
    }),
    appFeatures: PropTypes.arrayOf(
      PropTypes.shape({
        feature: PropTypes.string.isRequired,
        featureLevel: PropTypes.string,
        enabled: PropTypes.bool.isRequired
      })
    ),
  }),
  updateSelectedSite: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  canSave: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  canCancel: PropTypes.bool.isRequired,
};

SiteAppFeatures.defaultProps = {
  selectedSite: null
};

export default SiteAppFeatures;
