import update from 'immutability-helper';

import { IssueClient } from '../../../../../../../../client';
import { WorkflowStatusTypes } from '../../../../../../../../AppConstants';
import ActionTypes from './WorkflowActionTypes';
import * as selectors from './WorkflowSelectors';

export function setWorkflowStatus(issue, workflowStatus) {
  return (dispatch, getState) => {
    const currentState = getState();
    if (selectors.isSaving(currentState)) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.UPDATE_WORKFLOW_STARTED, issue });
    return IssueClient.updateWorkflow(issue.id, workflowStatus)
      .then(apiIssue => {
        const updatedIssue = update(issue, {
          workflow: { $set: apiIssue.workflow },
          workflowStatus: { $set: apiIssue.workflowStatus },
          workflowStatusDate: { $set: apiIssue.workflowStatusDate },
          workflowUserId: { $set: apiIssue.workflowUserId },
          workflowUsername: { $set: apiIssue.workflowUsername },
          dispatchable: { $set: apiIssue.dispatchable },
        });
        dispatch({ type: ActionTypes.UPDATE_WORKFLOW_SUCCESS, issue: updatedIssue });
        return updatedIssue;
      })
      .catch(error => {
        dispatch({ type: ActionTypes.UPDATE_WORKFLOW_FAILED, error });
        return Promise.reject(error);
      });
  };
}

export function moveToAwaitingApproval(issue) {
  return setWorkflowStatus(issue, WorkflowStatusTypes.AwaitingApprovalUnread);
}

export function moveToPendingResolution(issue) {
  return setWorkflowStatus(issue, WorkflowStatusTypes.PendingResolution);
}

export function openConfirmationModal(issue) {
  return { type: ActionTypes.OPEN_CONFIRMATION_MODAL, issue };
}

export function cancelConfirmationModal() {
  return { type: ActionTypes.CANCEL_CONFIRMATION_MODAL };
}

export function resolveIssue() {
  return (dispatch, getState) => {
    const issue = selectors.selectedIssue(getState());
    return dispatch(setWorkflowStatus(issue, WorkflowStatusTypes.Resolved));
  };
}
