import { ParameterType, Param, buildFindMethod } from '../param-types';

export const SiteSummaryFields = {
  Id: 'id',
  CustomerId: 'customerId',
  Customer: 'customer',
  SiteName: 'siteName',
  Nickname: 'nickname',
  State: 'state',
  SiteTimezone: 'siteTimezone',
  ActiveAlarmCount: 'activeAlarmCount',
  OpenIssueCount: 'openIssueCount',
  ComplianceReportStatus: 'complianceReportStatus',
  MviStatus: 'mviStatus',
  ConnectionType: 'connectionType',
  Labels: 'labels',
  OpenDispatches: 'openDispatches',
  ConnectionStatus: 'connectionStatus',
};

export const SiteSummaryParams = {
  CustomerIds: new Param('customerIds', ParameterType.List),
  SiteIds: new Param('siteIds', ParameterType.List),
  SiteStates: new Param('siteStates', ParameterType.List),
  SiteLabelIds: new Param('siteLabelIds', ParameterType.List),
  ConnectionTypes: new Param('connectionTypes', ParameterType.List),
  ConnectionStatuses: new Param('connectionStatuses', ParameterType.List)
};

export const SiteSummaryClient = {
  find: buildFindMethod('/v2/sites/summaries', SiteSummaryFields, SiteSummaryParams)
};
