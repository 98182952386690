import reduceReducers from 'reduce-reducers';
import update from 'immutability-helper';
import moment from 'moment-timezone';
import { LOCATION_CHANGE } from 'react-router-redux';

import { buildTableReducer } from '../../shared/components/table/redux/TableReducerFactory';
import { buildReducer, DefaultState } from '../../shared/redux-helpers/ReducerFactory';
import { buildViewManagerReducer } from '../../shared/components/table/view-manager/ViewManagerReducerFactory';
import { BrowserTimezone } from '../../shared/components/date';
import { InventoryStatus, SortDirection } from '../../AppConstants';
import ActionTypes from './InventoryActionTypes';
import { DefaultTableColumns, InventoryTableColumns } from './InventoryConstants';

function refreshInventoryStarted(currentState, site) {
  const existingSitesRefreshInProgress = currentState.sitesRefreshInProgress || [];

  if (existingSitesRefreshInProgress.some(s => s.id === site.id)) {
    return currentState;
  }

  return update(currentState, {
    sitesRefreshInProgress: {
      $push: [site],
    }
  });
}

function receiveSiteInventoryBatch(state, batch) {
  if (!batch || !batch.length) {
    return state;
  }

  const invReadingsByTankId = new Map();
  const siteIds = new Set();

  batch.forEach(inventoryReadings => {
    inventoryReadings.forEach(current => {
      invReadingsByTankId.set(current.tankId, current);
      siteIds.add(current.site.id);
    });
  });

  const updateSpec = {
    list: {
      $set: applyInventoryReadings(state.list, invReadingsByTankId),
    },
    filteredList: {
      $set: applyInventoryReadings(state.filteredList, invReadingsByTankId),
    }
  };

  if (state.sitesRefreshInProgress && state.sitesRefreshInProgress.length) {
    updateSpec.sitesRefreshInProgress = {
      $set: state.sitesRefreshInProgress.filter(current => !siteIds.has(current.id)),
    };
  }

  return update(state, updateSpec);
}

function applyInventoryReadings(targetCollection, invReadingsByTankId) {
  if (!targetCollection || !targetCollection.length) {
    return targetCollection;
  }

  return targetCollection.map(current => {
    const inventoryReading = invReadingsByTankId.get(current.id);
    if (inventoryReading) {
      return merge(current, inventoryReading);
    }
    return current;
  });
}

function merge(currentTankInvState, inventoryReading) {
  if (inventoryReading.inventoryDate < currentTankInvState.inventoryDate) {
    return currentTankInvState;
  }

  const ullage90PercentGallons = calcUllage(currentTankInvState, inventoryReading, 0.90);
  const ullage95PercentGallons = calcUllage(currentTankInvState, inventoryReading, 0.95);
  const fillPercentage = calcFillPercentage(currentTankInvState, inventoryReading);
  const alerts = calcHealthAlerts(currentTankInvState, inventoryReading);
  const status = calcStatus(fillPercentage, alerts);

  const updated = update(currentTankInvState, {
    inventoryDate: { $set: inventoryReading.inventoryDate },
    volumeGallons: { $set: inventoryReading.volumeGallons },
    tcVolumeGallons: { $set: inventoryReading.tcVolumeGallons },
    ullageGallons: { $set: inventoryReading.ullageGallons },
    fuelHeightInches: { $set: inventoryReading.fuelHeightInches },
    waterHeightInches: { $set: inventoryReading.waterHeightInches },
    temperatureFahrenheit: { $set: inventoryReading.temperatureFahrenheit },
    waterVolumeGallons: { $set: inventoryReading.waterVolumeGallons },
    alerts: { $set: alerts },
    fillPercentage: { $set: fillPercentage },
    ullage90PercentGallons: { $set: ullage90PercentGallons },
    ullage95PercentGallons: { $set: ullage95PercentGallons },
    status: { $set: status },
  });

  const now = Date.now();
  const momentNow = moment(now);
  return applyDateDiffSingleTank(now, momentNow, updated);
}

function calcUllage(currentTankInvState, inventoryReading, ullagePercentage) {
  if (currentTankInvState.fullVolumeGallons && inventoryReading.volumeGallons) {
    return Math.max((currentTankInvState.fullVolumeGallons * ullagePercentage) - inventoryReading.volumeGallons, 0);
  }
  return null;
}

function calcFillPercentage(currentTankInvState, inventoryReading) {
  if (currentTankInvState.fullVolumeGallons && inventoryReading.volumeGallons) {
    return (inventoryReading.volumeGallons / currentTankInvState.fullVolumeGallons) * 100;
  }
  return null;
}

function calcHealthAlerts(currentTankInvState, inventoryReading) {
  const currentTankAlerts = currentTankInvState.alerts;
  const productThresholds = currentTankInvState.productThresholds;
  if (!currentTankAlerts || !productThresholds) {
    return currentTankAlerts;
  }

  if (currentTankAlerts.deliveryNeeded && currentTankInvState.volumeGallons > inventoryReading.volumeGallons) {
    return currentTankAlerts;
  }

  const lowProdExceeded = productThresholds.lowProductThresholdGallons && inventoryReading.volumeGallons < productThresholds.lowProductThresholdGallons;
  const deliveryNeededExceeded = productThresholds.deliveryNeededThresholdGallons && inventoryReading.volumeGallons < productThresholds.deliveryNeededThresholdGallons;
  return update(currentTankAlerts, {
    deliveryNeeded: {
      $set: lowProdExceeded || deliveryNeededExceeded,
    }
  });
}

function calcStatus(fillPercentage, alerts) {
  if (alerts && alerts.deliveryNeeded) {
    return InventoryStatus.DeliveryNeeded;
  }
  else if (fillPercentage && fillPercentage >= 75) {
    return InventoryStatus.GreaterThanSeventyFivePercent;
  }
  else if (fillPercentage) {
    return InventoryStatus.LessThanSeventyFivePercent;
  }
  return InventoryStatus.Unknown;
}

function removeSiteFromRefreshList(currentState, siteId) {
  const existingSitesRefreshInProgress = currentState.sitesRefreshInProgress || [];
  const idx = existingSitesRefreshInProgress.findIndex(site => site.id === siteId);
  if (idx === -1) {
    return currentState;
  }
  return update(currentState, {
    sitesRefreshInProgress: {
      $splice: [[idx, 1]],
    },
  });
}

function SiteUpdateReducer(state, action) {
  switch (action.type) {
    case ActionTypes.REFRESH_SITE_INVENTORY_STARTED:
      return refreshInventoryStarted(state, action.site);
    case ActionTypes.RECEIVE_SITE_INVENTORY_BATCH:
      return receiveSiteInventoryBatch(state, action.batch);
    case ActionTypes.REFRESH_SITE_INVENTORY_FAILED:
      return removeSiteFromRefreshList(state, action.siteId);
    default:
      return state;
  }
}

function applyFormatting(state) {
  const siteSorted = (!state.sortColumnId || state.sortColumnId === InventoryTableColumns.SiteNickname.id);
  if (!siteSorted) {
    return state;
  }

  const updatedTanks = [];
  let prevSiteId = null;
  let prevInvDate = null;
  let siteCount = 0;
  let modified = false;
  for (let i = 0; i < state.filteredList.length; i++) {
    const currentTank = state.filteredList[i];
    const currentSiteId = currentTank.site && currentTank.site.id;
    const currentInvDate = currentTank.inventoryDate;
    if (currentSiteId !== prevSiteId) {
      siteCount++;
    }

    const updateSpec = {
      className: {
        $set: (siteCount % 2 === 0) ? 'canary-table-2-alternate-row-color' : null,
      },
    };

    if (currentSiteId === prevSiteId && currentInvDate === prevInvDate) {
      updateSpec.hideInventoryDate = { $set: true };
    }
    else {
      updateSpec.hideInventoryDate = { $set: false };
    }

    const updatedTank = update(currentTank, updateSpec);
    if (updatedTank !== currentTank) {
      modified = true;
    }

    updatedTanks.push(updatedTank);
    prevSiteId = currentSiteId;
    prevInvDate = currentInvDate;
  }
  if (modified) {
    return Object.assign({}, state, { filteredList: updatedTanks });
  }
  return state;
}

const InventoryTableFormatReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SORT:
    case ActionTypes.CHANGE_PAGE:
    case ActionTypes.RECEIVE_LIST:
    case ActionTypes.RECEIVE_SITE_INVENTORY_BATCH:
      return applyFormatting(state);
    default:
      return state;
  }
};

function applyDateDiff(state) {
  if (!state.filteredList || !state.filteredList.length) {
    return state;
  }

  const now = Date.now();
  const momentNow = moment(now);
  const updatedFilteredList = state.filteredList.map(current => applyDateDiffSingleTank(now, momentNow, current));

  return Object.assign({}, state, {
    filteredList: updatedFilteredList,
    lastTimestampUpdate: now,
  });
}

function applyDateDiffSingleTank(now, momentNow, tankInventory) {
  if (!tankInventory.inventoryDate) {
    if (tankInventory.inventoryDateDiffSeconds !== -1 || tankInventory.inventoryDateFormatted !== 'Unavailable') {
      return Object.assign({}, tankInventory, {
        inventoryDateDiffSeconds: -1,
        inventoryDateFormatted: 'Unavailable',
      });
    }
    return tankInventory;
  }

  const momentInventoryDate = moment(tankInventory.inventoryDate).tz(BrowserTimezone);
  const inventoryDateDiffSeconds = now / 1000 - momentInventoryDate.unix();
  return Object.assign({}, tankInventory, {
    inventoryDateDiffSeconds,
    inventoryDateFormatted: formatInventoryDate(momentNow, momentInventoryDate, inventoryDateDiffSeconds),
  });
}

function formatInventoryDate(momentNow, momentInventoryDate, diffSeconds) {
  if (diffSeconds <= 60) {
    return momentInventoryDate.from(momentNow, true);
  }
  else if (diffSeconds <= 3600) {
    return momentInventoryDate.from(momentNow);
  }
  else if (diffSeconds <= 3600 * 24) {
    return momentInventoryDate.format('hh:mm A');
  }
  else {
    return momentInventoryDate.format('MM/DD/YY');
  }
}

const InventoryDateDiffReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SORT:
    case ActionTypes.CHANGE_PAGE:
    case ActionTypes.RECEIVE_LIST:
    case ActionTypes.RECEIVE_SITE_INVENTORY_BATCH:
    case ActionTypes.REFRESH_TIMESTAMPS:
      return applyDateDiff(state);
    default:
      return state;
  }
};

// discard the list whenever the user navigates away from the page.  inventory changes so much that we'll never get a 304.
function ClearOnNavReducer(state, action) {
  if (action.type === LOCATION_CHANGE) {
    return Object.assign({}, state, {
      list: [],
      filteredList: [],
      listEtag: null,
    });
  }
  return state;
}

const InventoryDefaultState = Object.assign({}, DefaultState, {
  defaultSortColumnId: InventoryTableColumns.SiteNickname.id,
  defaultSortDirection: SortDirection.Asc,
  pageSize: 500,
  sitesRefreshInProgress: [],
});

export default reduceReducers(
  InventoryDefaultState,
  ClearOnNavReducer,
  buildReducer(ActionTypes),
  buildTableReducer(ActionTypes, InventoryTableColumns),
  buildViewManagerReducer(ActionTypes, InventoryTableColumns, DefaultTableColumns),
  SiteUpdateReducer,
  InventoryTableFormatReducer,
  InventoryDateDiffReducer,
);
