import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { Button, Row, Col } from 'reactstrap';

import { IssuePriorities } from '../../../../../../AppConstants';
import PriorityIndicator from '../../../../../../shared/components/PriorityIndicator';
import WorkflowStatus from '../../../../../../shared/components/WorkflowStatus';
import Alarm from './Alarm';

const IssueDropTarget = ({ site, issue, onIssueClick, onRemoveIssue, canDrop, isOver, connectDropTarget }) => {
  const isActive = canDrop && isOver;
  const backgroundColor = isActive ? '#F8E81C' : '#FFF';

  let removeIssueControl;
  if (Number.isInteger(issue.dispatch && issue.dispatch.id) && issue.alarms.length === 0) {
    removeIssueControl = (<div className="p-3"><small className="text-danger ">This issue has a work order and can't be removed.</small></div>);
  }
  else if (issue.alarms.length === 0) {
    removeIssueControl = (
      <div className="p-3">
        <Button outline size="sm" onClick={() => onRemoveIssue(issue)} color="danger" block>Remove this issue</Button>
      </div>
    );
  }
  else {
    removeIssueControl = null;
  }

  return connectDropTarget((
    <div style={{ border: '1px solid gray', backgroundColor }}>
      <div
        className="pl-3"
        style={{ cursor: 'pointer', paddingTop: '5px', paddingBottom: '5px', borderBottom: '1px solid #d3d3d3', backgroundColor: '#EFECEC' }}
        onClick={() => onIssueClick(issue)}
      >
        <Row>
          <Col sm={12}>
            <WorkflowStatus workflowStatus={issue.workflow.status} />
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <PriorityIndicator priority={issue.priority ? issue.priority : IssuePriorities.NORMAL} />
          </Col>
          <Col sm={10}>
            <small><b>{issue.description}</b></small>
          </Col>
        </Row>
      </div>
      {removeIssueControl}
      {issue.alarms && issue.alarms.map(alarm => (
        <Alarm
          site={site}
          issue={issue}
          alarm={alarm}
          key={`issue_${issue.dndKey}_alarm_${alarm.id}`}
        />
      ))}
    </div>
  ));
};

const issueTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    props.onMoveAlarm(item.issue, props.issue, item.alarm);
  },

  canDrop(props, monitor) {
    const item = monitor.getItem();
    return props.issue.dndKey !== item.issue.dndKey;
  },
};

IssueDropTarget.propTypes = {
  site: PropTypes.shape({
    siteTimezone: PropTypes.string.isRequired,
  }).isRequired,
  issue: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string.isRequired,
    priority: PropTypes.string.isRequired,
    workflow: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
    alarms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      lastClearedDate: PropTypes.object,
      firstAlarmDate: PropTypes.object.isRequired,
      count: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      alarmDate: PropTypes.string,
      ids: PropTypes.arrayOf(PropTypes.number),
    })).isRequired,
    dispatch: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    dndKey: PropTypes.number.isRequired,
  }).isRequired,
  onIssueClick: PropTypes.func.isRequired,
  onRemoveIssue: PropTypes.func.isRequired,
  canDrop: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
};

export default DropTarget('ALARM', issueTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(IssueDropTarget);
