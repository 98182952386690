import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HealthGauge from '../../../../../components/health-gauge/HealthGauge';
import * as actions from '../actions';
import * as selectors from '../SiteDashboardSelectors';

class OverallSiteConnectivityGauge extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.props.getSiteHealth();
  }

  render() {
    let gaugeValue = 0;
    if (this.props.siteHealth) {
      gaugeValue = this.props.siteHealth.avgDeviceCommPercentage;
    }
    gaugeValue *= 100;

    return (
      <HealthGauge
        label="Overall"
        value={gaugeValue}
        helpText="The percentage of sites that are currently healthy."
      />
    );
  }
}

OverallSiteConnectivityGauge.propTypes = {
  siteHealth: PropTypes.object,
  getSiteHealth: PropTypes.func.isRequired,
};

OverallSiteConnectivityGauge.defaultProps = {
  siteHealth: null,
};

function mapStateToProps(state) {
  return {
    siteHealth: selectors.siteHealth(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSiteHealth: () => dispatch(actions.getSiteHealth()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverallSiteConnectivityGauge);
