/* eslint-disable no-bitwise */

const getPermissions = state => (state && state.authentication && state.authentication.permissions) || {};

export const isLoginInProgress = state => !!(state && state.authentication && state.authentication.loginInProgress);

export const isLoggedIn = state => !!(state && state.authentication && state.authentication.userId);

export const isAdmin = state => !!(getPermissions(state).admin);

export const currentUserId = state => state && state.authentication && state.authentication.userId;

export const currentUsername = state => state && state.authentication && state.authentication.username;

export const currentEmail = state => state && state.authentication && state.authentication.email;

export const isError = state => (state && state.authentication && state.authentication.isError) || false;

export const error = state => (state && state.authentication && state.authentication.error) || {};

function isOrgAdmin(org) {
  return org && org.admin;
}

function hasMinCustomerPermission(org, permission) {
  const customerPermissions = org && org.customer && org.customer.permissions;
  return customerPermissions && customerPermissions.minGrantedPermissions && (customerPermissions.minGrantedPermissions & permission) === permission;
}

function hasMaxCustomerPermission(org, permission) {
  const customerPermissions = org && org.customer && org.customer.permissions;
  return customerPermissions && customerPermissions.maxGrantedPermissions && (customerPermissions.maxGrantedPermissions & permission) === permission;
}

function hasSitePermission(org, siteId, permission) {
  const customerSitePermissions = org && org.customer && org.customer.permissions && org.customer.permissions.sites;
  return customerSitePermissions && customerSitePermissions[String(siteId)] && (customerSitePermissions[String(siteId)] & permission) === permission;
}

export const hasPermission = (state, customerId, siteId, permission) => {
  if (customerId && siteId && permission) {
    if (isAdmin(state)) {
      return true;
    }

    const permissionsState = getPermissions(state);
    const org = permissionsState.organizations && permissionsState.organizations[String(customerId)];
    return isOrgAdmin(org) || hasMinCustomerPermission(org, permission) || hasSitePermission(org, siteId, permission);
  }

  return false;
};

export const hasPermissionForSite = (state, site, permission) => {
  const siteId = (site && site.id);
  const customerId = (site && site.customerId) || (site && site.customer && site.customer.id);
  return hasPermission(state, customerId, siteId, permission);
};

export const hasPermissionAnyOrgSite = (state, customer, permission) => {
  const customerId = (customer && customer.id) || customer;
  if (customerId) {
    if (isAdmin(state)) {
      return true;
    }
    const permissionsState = getPermissions(state);
    const org = permissionsState.organizations && permissionsState.organizations[String(customerId)];
    return isOrgAdmin(org) || hasMaxCustomerPermission(org, permission) || false;
  }

  return false;
};

export const hasPermissionAnySite = (state, permission) => {
  if (isAdmin(state)) {
    return true;
  }
  const permissions = getPermissions(state);
  if (!permissions.organizations) {
    return false;
  }

  for (const currentOrgId of Object.keys(permissions.organizations)) {
    const org = permissions.organizations[currentOrgId];
    if (hasPermissionAnyOrgSite(state, org, permission)) {
      return true;
    }
  }

  return false;
};

export function getAuthToken(state) {
  return state && state.authentication && state.authentication.token;
}

export const isFeatureEnabledForSite = (state, site, featureName, featureLevel) => {
  const siteFeature = getFeatureForSite(state, site, featureName);
  return !!(siteFeature && siteFeature.enabled && (!featureLevel || siteFeature.featureLevel === featureLevel));
};

export const isFeatureEnabledAnyCustomerSite = (state, customer, featureName) => {
  const customerId = (customer && customer.id) || customer;
  if (!customerId) {
    return false;
  }

  const permissions = getPermissions(state);
  const org = permissions && permissions.organizations && permissions.organizations[String(customerId)];

  if (!org || !org.customer || !org.customer.features) {
    return false;
  }

  const orgDefault = (org.customer.features.defaults && org.customer.features.defaults[featureName] && org.customer.features.defaults[featureName].enabled) || false;
  if (orgDefault) {
    return true;
  }
  else if (!org.customer.features.sites) {
    return false;
  }

  let override = false;
  Object.keys(org.customer.features.sites).forEach(siteId => {
    const siteOverride = org.customer.features.sites[siteId];
    override = override || (siteOverride[featureName] && siteOverride[featureName].enabled);
  });
  return override;
};

export function isFeatureEnabledAnySite(state, featureName) {
  const customers = allCustomers(state);
  for (const customer of customers) {
    if (isFeatureEnabledAnyCustomerSite(state, customer, featureName)) {
      return true;
    }
  }
  return false;
}

export function getFeatureForSite(state, site, featureName) {
  const siteId = site && site.id;
  const customerId = (site && site.customerId) || (site && site.customer && site.customer.id);
  return getFeatureOverrideForSite(state, customerId, siteId, featureName) || getFeatureDefaultForCustomer(state, customerId, featureName);
}

function getFeatureDefaultForCustomer(state, customerId, featureName) {
  if (state && customerId && featureName) {
    const permissions = getPermissions(state);
    const org = permissions && permissions.organizations && permissions.organizations[String(customerId)];
    return org
      && org.customer
      && org.customer.features
      && org.customer.features.defaults
      && org.customer.features.defaults[featureName];
  }
  return null;
}

function getFeatureOverrideForSite(state, customerId, siteId, featureName) {
  if (state && customerId && siteId && featureName) {
    const permissions = getPermissions(state);
    const org = permissions && permissions.organizations && permissions.organizations[String(customerId)];
    return org
      && org.customer
      && org.customer.features
      && org.customer.features.sites
      && org.customer.features.sites[String(siteId)]
      && org.customer.features.sites[String(siteId)][featureName];
  }
  return null;
}

export function customerDispatchSystems(state, customerId) {
  if (state && customerId) {
    const permissions = getPermissions(state);
    const org = permissions && permissions.organizations && permissions.organizations[String(customerId)];
    return (org && org.customer && org.customer.dispatchSystems) || [];
  }
  return [];
}

export const customerHasDispatchSystem = (state, customerId) => {
  const systems = customerDispatchSystems(state, customerId);
  return !!systems.length;
};

export const customerHasActiveDispatchSystems = (state, customerId) => customerDispatchSystems(state, customerId).some(dispatchSystem => dispatchSystem.enabled);

function buildCustomer(org) {
  if (org && org.customer) {
    return {
      id: org.id,
      name: org.name,
      address: org.address,
      city: org.city,
      state: org.state,
      zip: org.zip,
      phone: org.phone,
      domainName: org.domainName,
      created: org.created,
      enabled: org.enabled,
      abbreviation: org.customer.abbreviation,
      dispatchSystems: org.customer.dispatchSystems || [],
      siteStates: org.customer.siteStates || [],
    };
  }
  return null;
}

export function customerById(state, customerId) {
  const permissions = getPermissions(state);
  const org = permissions && permissions.organizations && permissions.organizations[String(customerId)];
  return buildCustomer(org);
}

export function allCustomers(state) {
  const customers = [];
  const permissions = getPermissions(state);
  if (permissions && permissions.organizations) {
    Object.keys(permissions.organizations).forEach(orgId => {
      const org = permissions.organizations[orgId];
      if (org.customer) {
        const customer = buildCustomer(org);
        customers.push(customer);
      }
    });
    customers.sort((a, b) => a.name.localeCompare(b.name));
  }
  return customers;
}

export function hasCustomers(state) {
  return !!allCustomers(state).length;
}

export function allSiteStates(state) {
  const states = new Set();
  allCustomers(state).forEach(customer => customer.siteStates && customer.siteStates.forEach(siteState => states.add(siteState)));
  const stateList = [...states.values()];
  stateList.sort();
  return stateList;
}
