import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedTankStatuses = ({ selectedTankStatuses, disabled, deselectTankStatus }) => {
  const selectedObjs = selectedTankStatuses.map(current => ({ id: current, name: current }));
  const handleRemove = labelObj => deselectTankStatus(labelObj.id);
  return (<FilterItemList items={selectedObjs} textField="name" disabled={disabled} onRemoveItem={handleRemove} />);
};

SelectedTankStatuses.propTypes = {
  selectedTankStatuses: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool.isRequired,
  deselectTankStatus: PropTypes.func.isRequired,
};

SelectedTankStatuses.defaultProps = {
  selectedTankStatuses: [],
};

export default SelectedTankStatuses;
