const FillRiser = { id: 8,
  name: 'fillRiser',
  label: 'Fill Riser',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: false,
  index: 0,
  fields: [
    { id: 1,
      name: 'adapterType',
      label: 'Adapter Type',
      path: 'fillRiser.adapterType',
      type: 'select',
      values: ['Unknown', 'Loose fill', 'Tight fill', 'Tight fill coaxial'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 2,
      name: 'sealType',
      label: 'Seal Type',
      path: 'fillRiser.sealType',
      type: 'select',
      values: ['Unknown', 'Top seal', 'Side seal'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 3,
      name: 'rotationType',
      label: 'Rotation Type',
      path: 'fillRiser.rotationType',
      type: 'select',
      values: ['Unknown', 'Stationary', 'Locking', 'Swivel'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 4,
      name: 'poppeted',
      label: 'Poppeted',
      path: 'fillRiser.poppeted',
      type: 'bool',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 5,
      name: 'adapterCap',
      label: 'Adapter Cap',
      path: 'fillRiser.adapterCap',
      type: 'bool',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 6,
      name: 'remote',
      label: 'Remote',
      path: 'fillRiser.remote',
      type: 'bool',
      cols: 2,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 7,
      name: 'containmentType',
      label: 'Containment Type',
      path: 'fillRiser.containment.containmentType',
      type: 'select',
      values: ['Unknown', 'None', 'Spill bucket', 'Sump', 'Spill bucket in sump'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: false,
    },
  ],
};

export default FillRiser;
