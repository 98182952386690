import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import SiteAutoSuggest from '../components/SiteAutoSuggest';
import { SiteClient } from '../../../../client';

class TermSearchSiteAutoSuggest extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      value: '',
      suggestions: [],
    };
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleSiteSearch = debounce(this.handleSiteSearch.bind(this), 250, { maxWait: 5 });
    this.handleSiteSelected = this.handleSiteSelected.bind(this);
    this.clearSuggestions = this.clearSuggestions.bind(this);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleValueChange(value) {
    this.setState({ value });
  }

  handleSiteSearch(term) {
    if (this.unmounted) {
      return;
    }

    SiteClient.search(term, this.props.customerId, this.props.sitePermission, this.props.feature).then(sites => {
      if (this.unmounted) {
        return;
      }

      if (this.props.excludedSites && this.props.excludedSites.length) {
        const excludedIds = this.props.excludedSites.map(current => current.id);
        sites = sites.filter(current => excludedIds.indexOf(current.id) === -1);
      }

      this.setState({
        suggestions: sites,
      });
    });
  }

  handleSiteSelected(site) {
    this.clearSuggestions();
    this.props.onSiteSelected(site);
  }

  clearSuggestions() {
    if (!this.unmounted) {
      this.setState({
        suggestions: [],
        value: '',
      });
    }
  }

  render() {
    return (
      <SiteAutoSuggest
        id={this.props.id}
        value={this.state.value}
        onValueChange={this.handleValueChange}
        groupByCustomer={this.props.groupByCustomer}
        onSiteSearchRequested={this.handleSiteSearch}
        suggestions={this.state.suggestions}
        onSiteSelected={this.handleSiteSelected}
        onClear={this.clearSuggestions}
        onBlur={this.props.onBlur}
        disabled={this.props.disabled}
        placeHolderText={this.props.placeHolderText}
      />

    );
  }
}

TermSearchSiteAutoSuggest.propTypes = {
  id: PropTypes.string.isRequired,
  groupByCustomer: PropTypes.bool,
  onSiteSelected: PropTypes.func.isRequired,
  customerId: PropTypes.number,
  sitePermission: PropTypes.number,
  feature: PropTypes.string,
  excludedSites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  placeHolderText: PropTypes.string,
};

TermSearchSiteAutoSuggest.defaultProps = {
  groupByCustomer: false,
  customerId: null,
  sitePermission: null,
  excludedSites: null,
  feature: null,
  onBlur: null,
  disabled: false,
  placeHolderText: null,
};

export default TermSearchSiteAutoSuggest;
