function stateSlice(state) {
  return (state && state.sites && state.sites.issues && state.sites.issues.list) || {};
}

export function resolvedIssues(state) {
  return stateSlice(state).resolvedIssues || [];
}

export function isResolvedIssuesLoading(state) {
  return stateSlice(state).resolvedIssuesLoading || false;
}

export function resolvedIssuesError(state) {
  return stateSlice(state).resolvedIssuesError || null;
}
