import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTracking } from '../../../../../../../shared/analytics';
import * as siteSelectors from '../../../../../SiteSelectors';
import IssueExportModal from '../../issue-edit/components/export/IssueExportModal';
import CreateDispatchModal from '../../../../dispatch/components/CreateDispatchModal';
import WorkflowConfirmModal from '../../issue-edit/components/workflow/WorkflowConfirmModal';
import NewIssueButton from '../../../../../components/new-issue-button/NewIssueButton';
import ResolvedIssuesTable from '../components/ResolvedIssuesTable';
import IssueCards from '../../../components/IssueCards';
import * as SiteActions from '../../../../../actions';

const SiteIssueList = ({ currentSite, canViewIssues, canEditIssues, navToCurrentSiteIssue }) => {
  if (!canViewIssues) {
    return (
      <div>
        <h1>Site issues not visible</h1>
        <span>You are not permitted to view issues for this site.</span>
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex mb-4">
        <h4 className="mr-auto">Open Issues</h4>
        <NewIssueButton onIssueCreated={navToCurrentSiteIssue} site={currentSite} />
      </div>

      <IssueCards />
      {canEditIssues ? <WorkflowConfirmModal /> : null }
      <h4 className="mt-4">Closed Issues</h4>
      <ResolvedIssuesTable />
      <IssueExportModal />
      <CreateDispatchModal />
    </div>
  );
};

SiteIssueList.propTypes = {
  currentSite: PropTypes.object,
  canViewIssues: PropTypes.bool,
  canEditIssues: PropTypes.bool,
  navToCurrentSiteIssue: PropTypes.func.isRequired,
};

SiteIssueList.defaultProps = {
  currentSite: null,
  canViewIssues: false,
  canEditIssues: false,
};

function mapStateToProps(state) {
  return {
    currentSite: siteSelectors.currentSite(state),
    canViewIssues: siteSelectors.canViewIssues(state),
    canEditIssues: siteSelectors.canEditIssues(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navToCurrentSiteIssue: issue => dispatch(SiteActions.navToCurrentSiteIssue(issue)),
  };
}

export default withTracking('Site', 'Issue List')(connect(mapStateToProps, mapDispatchToProps)(SiteIssueList));
