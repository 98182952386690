import React from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import { Badge } from 'reactstrap';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import DropdownList from 'react-widgets/lib/DropdownList';

import withEither from './withEither';

const dropdownListFn = props => props.edit && props.field.editable && props.field.path !== 'locationType';
// only UST locationType is currently supported, so we will show Location Type as read only to avoid
// confusing and unnecessary validation if they were to choose AST; we would only tell them that ASTs are not yet supported
// so let us not waste time on that

const DropdownEditor = props =>
  <div>
    <DropdownList
      allowCreate={false}
      onChange={value => props.onValidatePanel(props.formBuilder, props.panel, props.target, props.field, value)}
      data={props.field.values}
      id={props.id}
      defaultValue={objectPath.get(props.target, props.field.path) || ''}
      placeholder={`Select ${props.field.label}`}
    />
    { props.field.required && !objectPath.get(props.target, props.field.path) ?
      <div className="d-flex justify-content-begin mt-1 required"><strong>Required</strong></div>
    : null
    }

    {props.field.error ?
      <div className="d-flex justify-content-begin mt-1"><Badge color="danger">Error</Badge></div>
      : null
      }
  </div>;

const DropdownViewer = props =>
  <div className="pb-2 pt-2">
    { props.field.name === 'serviceChange' ?
      <span className=" mr-1"><FontAwesomeIcon icon={faCheck} /></span>
    :
      <span className="mr-1" />
    }
    {objectPath.get(props.target, props.field.path) ?
      <strong id={props.id}> {objectPath.get(props.target, props.field.path)} </strong>
    :
    '\u2014' }
  </div>;

const withConditionalRenderings = compose(
      withEither(dropdownListFn, DropdownEditor),
    );

const DropdownWithConditionalRendering = withConditionalRenderings(DropdownViewer);

const Dropdown = props => (
  <div className="mb-3">
    <label htmlFor={`field${props.panel.id.toString()}${props.field.id.toString()}`}>{props.field.label}:</label>
    <DropdownWithConditionalRendering
      onUpdateField={props.onUpdateField}
      id={`field${props.panel.id.toString()}${props.field.id.toString()}`}
      target={props.target}
      field={props.field}
      edit={props.edit}
      {...props}
    />
  </div>
  );

Dropdown.propTypes = {
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  panel: PropTypes.object.isRequired,
};

DropdownEditor.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onValidatePanel: PropTypes.func.isRequired,
  formBuilder: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
};

DropdownViewer.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default Dropdown;

