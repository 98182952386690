import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faRedo } from '@fortawesome/pro-solid-svg-icons';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';
import { red, blue, gray900 } from '../../../../scss/_variables.scss';
import HasPermission from '../../../authentication/containers/HasPermission';
import organizationPermissions from '../../../organization-permissions';
import { BrowserTimezone } from '../../../shared/components/date';

class ReadingDisplay {
  constructor(message, icon, iconTitle, textColor, iconColor) {
    this._message = message;
    this._icon = icon;
    this._iconTitle = iconTitle;
    this._iconColor = iconColor;
    this._textColor = textColor;
  }

  get message() {
    return this._message;
  }

  get icon() {
    return this._icon;
  }

  get iconTitle() {
    return this._iconTitle;
  }

  get textColor() {
    return this._textColor;
  }

  get iconColor() {
    return this._iconColor;
  }
}

const RefreshTime = ({ tankInventoryState, refreshInProgress, onRefreshInventoryClick }) => {
  if (!tankInventoryState || tankInventoryState.hideInventoryDate) {
    return null;
  }

  const inventoryDateDiffSeconds = tankInventoryState.inventoryDateDiffSeconds;
  const inventoryDateFormatted = tankInventoryState.inventoryDateFormatted;
  let display;
  if (!inventoryDateDiffSeconds || inventoryDateDiffSeconds === -1) {
    display = new ReadingDisplay(inventoryDateFormatted, faExclamationTriangle, 'unavailable', red, red);
  }
  else if (inventoryDateDiffSeconds <= 60) {
    display = new ReadingDisplay(inventoryDateFormatted, faRedo, 'less than 1 minute old', gray900, blue);
  }
  else if (inventoryDateDiffSeconds <= 3600) {
    display = new ReadingDisplay(inventoryDateFormatted, faRedo, 'less than 1 hour old', gray900, blue);
  }
  else if (inventoryDateDiffSeconds <= 3600 * 24) {
    display = new ReadingDisplay(inventoryDateFormatted, faExclamationTriangle, 'is more than 1 hour old', red);
  }
  else {
    display = new ReadingDisplay(inventoryDateFormatted, faExclamationTriangle, 'is more than 1 day old', red);
  }

  if (refreshInProgress) {
    return (
      <div className="d-inline-block mr-2">
        <PulseLoader color="#F8E71C" size={8} />
      </div>
    );
  }

  return (
    <>
      <span style={{ color: display.textColor }}>
        <HasPermission
          customerId={tankInventoryState.site.customerId}
          siteId={tankInventoryState.site.id}
          permission={organizationPermissions.AtgCommandsInventory}
        >
          <span style={{ cursor: 'pointer' }} className="mr-2">
            <FontAwesomeIcon
              color={display.iconColor}
              icon={display.icon}
              title={`Inventory reading is ${display.iconTitle}`}
              onClick={(e) => {
                e.preventDefault();
                onRefreshInventoryClick(tankInventoryState);
              }}
            />
          </span>
        </HasPermission>
        {display.message}
      </span>
    </>
  );
};

RefreshTime.propTypes = {
  tankInventoryState: PropTypes.shape({
    hideInventoryDate: PropTypes.bool,
    inventoryDateDiffSeconds: PropTypes.number,
    inventoryDateFormatted: PropTypes.string,
    site: PropTypes.shape({
      id: PropTypes.number,
      customerId: PropTypes.number,
    })
  }),
  refreshInProgress: PropTypes.bool.isRequired,
  onRefreshInventoryClick: PropTypes.func.isRequired,
};

RefreshTime.defaultProps = {
  tankInventoryState: null,
};

export default RefreshTime;
