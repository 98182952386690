import { push } from 'react-router-redux';

import { getListWithEtagAction } from '../../../shared/redux-helpers/ActionCreatorFactory';
import { ComplianceClient, ComplianceParams } from '../../../client';
import ActionTypes from '../ComplianceActionTypes';
import ComplianceSelectors, { ComplianceListViewManagerSelectors } from '../ComplianceSelectors';

function buildParams(currentState) {
  const params = {
    [ComplianceParams.CustomerIds.name]: ComplianceListViewManagerSelectors.selectedCustomerIds(currentState),
    [ComplianceParams.Statuses.name]: ComplianceListViewManagerSelectors.selectedInternalComplianceStatuses(currentState),
    [ComplianceParams.SiteStates.name]: ComplianceListViewManagerSelectors.selectedSiteStates(currentState),
    [ComplianceParams.SiteIds.name]: ComplianceListViewManagerSelectors.selectedSiteIds(currentState),
    [ComplianceParams.SiteLabelIds.name]: ComplianceListViewManagerSelectors.selectedSiteLabelIds(currentState),
  };
  addMonthFilter(currentState, params);
  return params;
}

function addMonthFilter(currentState, params) {
  const selectedMonth = ComplianceListViewManagerSelectors.selectedMonth(currentState);
  if (selectedMonth) {
    params[ComplianceParams.Year.name] = selectedMonth.year;
    params[ComplianceParams.Month.name] = selectedMonth.month;
  }
}

const buildFields = currentState => ComplianceListViewManagerSelectors.apiFields(currentState);

export const getReports = getListWithEtagAction(ComplianceSelectors, ActionTypes, buildParams, buildFields, ComplianceClient.findReports);

export const changeSort = (sortColumnId, sortDirection) => ({ type: ActionTypes.CHANGE_SORT, sortColumnId, sortDirection });

export const changePage = page => ({ type: ActionTypes.CHANGE_PAGE, page });

export function navToOverview() {
  return (dispatch) => {
    dispatch(push('/monthly-compliance'));
    return Promise.resolve();
  };
}
