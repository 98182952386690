import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import CompactPicker from 'react-color/lib/components/compact/Compact';

import CanaryLabel from './Label';
import CanaryModal from '../CanaryModal';

class LabelModal extends Component {
  static getDerivedStateFromProps(props) {
    if (!props.existingLabels || !props.existingLabels.length || !props.label || !props.label.labelText) {
      return {
        invalid: false,
      };
    }

    const isConflicting = existing => existing.labelText.trim().toLowerCase() === props.label.labelText.trim().toLowerCase() && existing.id !== props.label.id;
    if (props.existingLabels.some(isConflicting)) {
      return {
        invalid: true,
      };
    }
    return {
      invalid: false,
    };
  }

  constructor(initialProps) {
    super(initialProps);

    this.handleLabelNameChange = this.handleLabelNameChange.bind(this);
    this.showColorPicker = this.showColorPicker.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.state = {
      displayColorPicker: false,
      invalid: false,
    };
  }

  handleLabelNameChange(e) {
    const newLabelText = e.target.value;
    const updatedLabel = Object.assign({}, this.props.label, {
      labelText: newLabelText
    });
    this.props.onLabelChange(updatedLabel);
  }

  showColorPicker() {
    this.setState(currentState => ({ displayColorPicker: !currentState.displayColorPicker }));
  }

  handleColorChange(color) {
    const updatedLabel = Object.assign({}, this.props.label, {
      color: color.hex,
    });
    this.props.onLabelChange(updatedLabel);
    this.setState({
      displayColorPicker: false,
    });
  }

  handleCancel() {
    this.setState({
      displayColorPicker: false,
      invalid: false,
    });
    this.props.onCancel();
  }

  canSave() {
    return !!this.props.label.labelText && !this.state.displayColorPicker && !this.state.invalid;
  }

  handleSave() {
    this.props.onLabelSave(this.props.label);
  }

  render() {
    if (!this.props.label) {
      return null;
    }

    const modalHeader = this.props.label.id ? 'Edit Label' : 'Add New Label';

    return (
      <CanaryModal
        title={modalHeader}
        open={this.props.isOpen}
        onCancel={this.handleCancel}
        onConfirm={this.handleSave}
        canConfirm={this.canSave()}
        confirmButtonText="Save"
      >
        <Form>
          <FormGroup>
            <Label for="labelname">Label Name</Label>
            <Input
              type="text"
              name="labelname"
              id="labelname"
              placeholder="Label Name"
              value={this.props.label.labelText}
              onChange={this.handleLabelNameChange}
              invalid={this.state.invalid}
            />
            <FormFeedback>Duplicate label</FormFeedback>
          </FormGroup>
          {this.renderColor()}
          {this.renderPreview()}
        </Form>
      </CanaryModal>
    );
  }

  renderPreview() {
    if (this.props.label.labelText && this.props.label.color) {
      return (
        <FormGroup>
          <Label for="preview">Preview</Label>
          <div id="preview">
            <CanaryLabel
              text={this.props.label.labelText}
              color={this.props.label.color}
            />
          </div>
        </FormGroup>
      );
    }
    return null;
  }

  renderColor() {
    return (
      <FormGroup>
        <Label for="color">Color</Label>
        <div id="color">
          <div
            style={{
              width: '45px',
              padding: '5px',
              background: '#fff',
              borderRadius: '1px',
              boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
              display: 'inlineBlock',
              cursor: 'pointer',
            }}
            onClick={this.showColorPicker}
          >
            <div
              style={{
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                backgroundColor: this.props.label.color,
              }}
            />
          </div>
        </div>
        {this.renderColorPicker()}
      </FormGroup>
    );
  }

  renderColorPicker() {
    if (!this.state.displayColorPicker) {
      return null;
    }

    return (
      <CompactPicker
        color={this.props.label.color}
        onChange={this.handleColorChange}
      />
    );
  }
}

LabelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.shape({
    id: PropTypes.number,
    labelText: PropTypes.string,
    color: PropTypes.string,
  }),
  existingLabels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    labelText: PropTypes.string,
    color: PropTypes.string,
  })),
  onLabelChange: PropTypes.func.isRequired,
  onLabelSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

LabelModal.defaultProps = {
  label: {
    labelText: '',
    color: '#999',
  },
  existingLabels: [],
};

export default LabelModal;
