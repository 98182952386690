import ActionTypes from './AlarmActionTypes';

export default function AlarmReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_ALARM_STATUS_STARTED:
      return Object.assign({}, state, {
        alarmStatus: null,
        alarmStatusLoading: true,
        alarmStatusError: null,
      });
    case ActionTypes.RECEIVE_ALARM_STATUS:
      return Object.assign({}, state, {
        alarmStatus: action.data,
        alarmStatusLoading: false,
      });
    case ActionTypes.GET_ALARM_STATUS_FAILED:
      return Object.assign({}, state, {
        alarmStatusLoading: false,
        alarmStatusError: action.error,
      });
    default:
      return state;
  }
}
