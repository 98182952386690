/* eslint-disable react/no-unused-prop-types, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { TextAlign } from './TableConstants';
import PropertyColumn from './PropertyColumn';

function LinkColumn(props) {
  const { title, property, rowData } = props;
  const render = (propertyValue) => {
    if (!propertyValue) {
      return null;
    }
    return (
      <a href="#" onClick={() => props.onClick(rowData)}>{propertyValue}</a>
    );
  };

  return (
    <PropertyColumn
      title={title}
      property={property}
      render={render}
      rowData={rowData}
    />
  );
}

LinkColumn.propTypes = {
  sortable: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  textAlign: PropTypes.oneOf([TextAlign.Left, TextAlign.Right, TextAlign.Center]),
  onClick: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,

  rowData: PropTypes.object,
};

LinkColumn.defaultProps = {
  sortable: false,
  title: null,
  width: null,
  textAlign: TextAlign.Left,
  rowData: null,
};

export default LinkColumn;
