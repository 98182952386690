import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { Column, StatefulTable, StringColumn, TimestampColumn } from '../../../../../../shared/components/table';
import CanaryLoadingIndicator from '../../../../../../shared/components/CanaryLoadingIndicator';
import * as actions from '../actions';
import * as selectors from '../ProblemListSelectors';
import TrueFalse from '../components/TrueFalse';
import { navToSiteConnection } from '../../../../organizations/actions/index';

class ProblemList extends Component {
  componentDidMount() {
    this.props.getProblematicSites();
  }

  render() {
    if (this.props.isProblematicSitesLoading) {
      return <CanaryLoadingIndicator />;
    }

    return (
      <div>
        <div className="d-flex justify-content-end mb-1">
          <Button
            data-toggle="tooltip"
            title="Download CSV"
            color="default"
            onClick={actions.downloadProblematicSitesCsv}
            className="text-muted"
            style={{ paddingRight: 0 }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </div>

        <StatefulTable
          initialSortColumn="failDate"
          initialSortDirection="desc"
          data={this.props.problematicSites}
          onRowClick={this.props.navToSiteConnection}
        >
          <StringColumn columnId="customerPreview.name" property="customerPreview.name" title="Customer Name" width="12.5" sortable />
          <StringColumn columnId="nickname" property="nickname" title="Site" width="12.5%" sortable />
          <StringColumn columnId="siteName" property="siteName" title="Canary Site ID" width="12.5%" sortable />
          <TimestampColumn columnId="failDate" property="failDate" title="Fail Date" width="12rem" sortable />
          <Column columnId="unpluggedDead" render={pd => <TrueFalse value={pd.unpluggedDead} />} title="Unplugged" sortable />
          <Column columnId="deviceCommOut" render={pd => <TrueFalse value={pd.deviceCommOut} />} title="Device Comm Out" sortable />
          <Column columnId="poorDeviceComm" render={pd => <TrueFalse value={pd.poorDeviceComm} />} title="Poor Device Comm" sortable />
          <Column columnId="poorCellularSignal" render={pd => <TrueFalse value={pd.poorCellularSignal} />} title="Poor Cell" sortable />
          <Column columnId="atgCommOut" render={pd => <TrueFalse value={pd.atgCommOut} />} title="ATG Comm Out" sortable />
          <Column columnId="poorAtgComm" render={pd => <TrueFalse value={pd.poorAtgComm} />} title="Poor ATG Comm" sortable />
          <Column columnId="parseErrors" render={pd => <TrueFalse value={pd.parseErrors} />} title="Parse Errors" sortable />
        </StatefulTable>
      </div>
    );
  }
}

ProblemList.propTypes = {
  isProblematicSitesLoading: PropTypes.bool,
  problematicSites: PropTypes.array,
  getProblematicSites: PropTypes.func.isRequired,
  navToSiteConnection: PropTypes.func.isRequired,
};

ProblemList.defaultProps = {
  isProblematicSitesLoading: false,
  problematicSites: [],
};

function mapStateToProps(state) {
  return {
    isProblematicSitesLoading: selectors.isProblematicSiteListLoading(state),
    problematicSites: selectors.getProblematicSites(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProblematicSites: () => dispatch(actions.getProblematicSites()),
    navToSiteConnection: (problemSite) => dispatch(navToSiteConnection({ id: problemSite.siteId, customerId: problemSite.customerPreview.id }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemList);
