/* eslint-disable no-use-before-define */
import { Get, Post, setAuthToken, clearAuthToken } from '../HttpUtils';

function login(username, password) {
  const credentials = { username, password };

  clearAuthToken();
  return Post('/v2/login', credentials)
    .then((response) => {
      const userId = response.data.userId;
      const email = response.data.email;
      const token = response.data.accessToken;
      setAuthToken(token);

      return {
        userId,
        username,
        email,
        token,
        permissions: response.data.permissions,
      };
    });
}

function refreshLogin(userId) {
  return Get('/v2/login').then((response) => {
    return {
      userId,
      username: response.data.username,
      email: response.data.email,
      token: response.data.token,
      permissions: response.data.permissions,
    };
  });
}

function resetPassword(token, password) {
  setAuthToken(token);
  return Post('/v2/users/password-reset', password).then(() => {
    clearAuthToken();
  });
}

function logout(token) {
  const payload = {
    sid: token,
  };
  return Post('/v2/users/logout', payload).then(() => clearAuthToken());
}

export default {
  login,
  refreshLogin,
  logout,
  resetPassword,
};
