import React from 'react';
import PropTypes from 'prop-types';

const BigNumber = ({ number, label, color }) => {
  if (number === null || label === null) return null;

  return (
    <div className="my-1">
      <div className="text-sm-center" style={{ fontSize: '1.8em', fontWeight: 'bold', color: color }}>{number}</div>
      <div className="text-sm-center">{label}</div>
    </div>
  );
};

BigNumber.propTypes = {
  number: PropTypes.number,
  label: PropTypes.string,
  color: PropTypes.string,
};

BigNumber.defaultProps = {
  number: null,
  label: null,
  color: '#000000',
};

export default BigNumber;
