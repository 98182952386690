import InventoryActionTypes from '../InventoryActionTypes';
import { isSiteInventoryRefreshInProgress } from '../InventorySelectors';
import { IntentNames } from '../../sites/screens/commands/CommandConstants';
import { IntentClient } from '../../../client';
import { track } from '../../../shared/analytics';

export function refreshInventory(tankInventory) {
  return (dispatch, getState) => {
    const currentState = getState();
    const site = tankInventory.site;
    if (isSiteInventoryRefreshInProgress(currentState, site)) {
      return Promise.resolve();
    }
    track('Inventory', { Component: 'Inventory status icon', 'Inventory action': 'Refresh site inventory' });
    dispatch({ type: InventoryActionTypes.REFRESH_SITE_INVENTORY_STARTED, site });
    return IntentClient.sendIntentRequest(site.id, IntentNames.GetInventory);
  };
}

export function receiveSiteInventory(batch) {
  return { type: InventoryActionTypes.RECEIVE_SITE_INVENTORY_BATCH, batch };
}

export function refreshInventoryFailed(siteId) {
  return {
    type: InventoryActionTypes.REFRESH_SITE_INVENTORY_FAILED,
    siteId,
  };
}
