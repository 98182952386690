import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';

import CanaryModal from '../CanaryModal';

class ButtonWithConfirmation extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      modalOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.callConfirm = this.callConfirm.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
  }

  callConfirm() {
    this.toggle();
    this.props.onConfirm(this.props.data);
  }

  render() {
    let className = 'd-inline-block';
    if (this.props.containerClassName) {
      className = `${className} ${this.props.containerClassName}`;
    }
    return (
      <div className={className}>
        <Button
          onClick={this.toggle}
          color={this.props.buttonColor}
          size={this.props.buttonSize}
          className={this.props.buttonClassName}
          outline={this.props.buttonOutline}
          disabled={this.props.buttonDisabled}
        >
          {this.props.buttonLabel}
        </Button>
        <CanaryModal
          open={this.state.modalOpen}
          isSaving={this.props.confirmInProgress}
          title={this.props.modalTitle}
          onCancel={this.toggle}
          cancelButtonText={this.props.modalCancelButtonLabel}
          onConfirm={this.callConfirm}
          confirmButtonText={this.props.modalConfirmButtonLabel}
          className={this.props.modalClassName}
        >
          <span>{this.props.modalBodyText}</span>
        </CanaryModal>
      </div>
    );
  }
}

ButtonWithConfirmation.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonOutline: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  modalClassName: PropTypes.string,
  modalTitle: PropTypes.string,
  modalBodyText: PropTypes.string.isRequired,
  modalConfirmButtonLabel: PropTypes.string,
  modalCancelButtonLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  confirmInProgress: PropTypes.bool,
  data: PropTypes.any,
};

ButtonWithConfirmation.defaultProps = {
  buttonColor: null,
  buttonClassName: null,
  buttonSize: 'sm',
  buttonOutline: false,
  buttonDisabled: false,
  containerClassName: null,
  modalClassName: null,
  modalTitle: 'Are you sure?',
  modalConfirmButtonLabel: 'Confirm',
  modalCancelButtonLabel: 'Cancel',
  confirmInProgress: false,
  data: null,
};

export default ButtonWithConfirmation;
