import isEqual from 'lodash/isEqual';
import { selectedOrganization } from '../../OrganizationEditSelectors';
import {ActorGrantTypes} from '../../permission-constants';

export function groups(state) {
  const org = selectedOrganization(state);
  return (org && org.groups) || [];
}

export function selectedGroup(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.groupEdit && state.admin.organizations.groupEdit.selectedGroup) || null;
}

export function selectedGroupPolicies(state) {
  const org = selectedOrganization(state);
  const group = selectedGroup(state);

  if (!org || !org.policies || !org.policies.length || !group) {
    return [];
  }

  return org.policies.filter(current => current.groupGrantType === ActorGrantTypes.List
    && current.grantedToGroups && current.grantedToGroups.length
    && current.grantedToGroups[0].id === group.id
  );
}

export function pendingGroupEdits(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.groupEdit && state.admin.organizations.groupEdit.editedGroup) || null;
}

export function pendingGroupEditsPristine(state) {
  const selected = selectedGroup(state);
  const edited = pendingGroupEdits(state);
  return (selected && edited && isEqual(selected, edited)) || false;
}

export function canSavePendingGroupEdits(state) {
  const edited = pendingGroupEdits(state);
  return (!pendingGroupEditsPristine(state) && edited && edited.name && edited.name.length > 0) || false;
}

export function canCancelPendingGroupEdits(state) {
  const edited = pendingGroupEdits(state);
  return (edited && !Number.isInteger(edited.id)) || !pendingGroupEditsPristine(state);
}
