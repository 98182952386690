export function isSaving(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fuelHaulers && state.admin.inventory.fuelHaulers.saving) || false;
}

export function isLoading(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fuelHaulers && state.admin.inventory.fuelHaulers.loading) || false;
}

export function isDeleting(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fuelHaulers && state.admin.inventory.fuelHaulers.deleting) || false;
}

export function error(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fuelHaulers && state.admin.inventory.fuelHaulers.error) || null;
}

export function fuelHaulerList(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fuelHaulers && state.admin.inventory.fuelHaulers.fuelHaulerList) || [];
}

export function selectedFuelHauler(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fuelHaulers && state.admin.inventory.fuelHaulers.selectedFuelHauler) || null;
}

export function validationErrors(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fuelHaulers && state.admin.inventory.fuelHaulers.validationErrors) || [];
}

export function exportPreview(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.fuelHaulers && state.admin.inventory.fuelHaulers.exportPreview) || null;
}
