import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { InventoryStatus } from '../../../../../../../AppConstants';

const TankStatusSelect = ({ selectedTankStatuses, selectTankStatuses, deselectTankStatus, disabled }) => {
  const hasTankStatus = tankStatus => selectedTankStatuses.indexOf(tankStatus) !== -1;
  const buildUpdateFunc = tankStatus => {
    if (hasTankStatus(tankStatus)) {
      deselectTankStatus(tankStatus);
    }
    else {
      selectTankStatuses([tankStatus]);
    }
  };

  const toggleGreaterThan75 = () => buildUpdateFunc(InventoryStatus.GreaterThanSeventyFivePercent);
  const greaterThan75Checked = hasTankStatus(InventoryStatus.GreaterThanSeventyFivePercent);
  const toggleLessThan75 = () => buildUpdateFunc(InventoryStatus.LessThanSeventyFivePercent);
  const lessThan75Checked = hasTankStatus(InventoryStatus.LessThanSeventyFivePercent);
  const toggleDeliveryNeeded = () => buildUpdateFunc(InventoryStatus.DeliveryNeeded);
  const deliveryNeededChecked = hasTankStatus(InventoryStatus.DeliveryNeeded);
  return (
    <Form className="d-md-flex align-items-center h-100">
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={greaterThan75Checked} onChange={toggleGreaterThan75} disabled={disabled} />
          {InventoryStatus.GreaterThanSeventyFivePercent}
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0 mr-3">
        <Label check>
          <Input type="checkbox" checked={lessThan75Checked} onChange={toggleLessThan75} disabled={disabled} />
          {InventoryStatus.LessThanSeventyFivePercent}
        </Label>
      </FormGroup>
      <FormGroup check className="flex-shrink-0 mb-0">
        <Label check>
          <Input type="checkbox" checked={deliveryNeededChecked} onChange={toggleDeliveryNeeded} disabled={disabled} />
          {InventoryStatus.DeliveryNeeded}
        </Label>
      </FormGroup>
    </Form>
  );
};

TankStatusSelect.propTypes = {
  selectedTankStatuses: PropTypes.arrayOf(PropTypes.string),
  selectTankStatuses: PropTypes.func.isRequired,
  deselectTankStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

TankStatusSelect.defaultProps = {
  selectedTankStatuses: [],
};

export default TankStatusSelect;
