import { DeliveryTypes } from './ExportDestinationConstants';

export function urlParts(state) {
  return (state && state.admin && state.admin.exportDestination && state.admin.exportDestination.urlParts) || null;
}

function getDeliveryType(state) {
  const urlPartsVal = urlParts(state);
  return urlPartsVal && urlPartsVal.deliveryType;
}

export function isEmail(state) {
  return getDeliveryType(state) === DeliveryTypes.Email;
}

export function isFtp(state) {
  return getDeliveryType(state) === DeliveryTypes.FTP;
}

export function isSftp(state) {
  return getDeliveryType(state) === DeliveryTypes.SFTP;
}

export function isFtpOrSftp(state) {
  return isFtp(state) || isSftp(state);
}

export function isSftpKeyAuth(state) {
  const urlPartsVal = urlParts(state);
  return !!(urlPartsVal && urlPartsVal.deliveryPublicKey);
}

export function deliveryUrl(state) {
  return (state && state.admin && state.admin.exportDestination && state.admin.exportDestination.deliveryUrl) || null;
}

export function isValid(state) {
  return !!(state && state.admin && state.admin.exportDestination && state.admin.exportDestination.isValid);
}

export function validationErrors(state) {
  return (state && state.admin && state.admin.exportDestination && state.admin.exportDestination.validationErrors) || [];
}
