import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { withRouter } from 'react-router';

import { track } from '../../../../../../../shared/analytics';
import * as IssueSelectors from '../IssueEditSelectors';
import * as actions from '../actions';

class IssueName extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      editing: false,
    };
    this.openEdit = this.openEdit.bind(this);
    this.updateDescription = this.updateDescription.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  openEdit() {
    this.setState({
      editing: true,
      description: this.props.issue.description,
    });
  }

  updateDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  cancelEdit() {
    this.setState({
      editing: false,
    });
  }

  handleKeyPress(event) {
    if (event.charCode === 13) {
      this.handleSave();
    }
  }

  handleSave() {
    track('Issue', {
      Component: 'Issue name',
      'Issue action': 'Set name',
      'Issue type': this.props.issue.issueType
    });
    this.props.updateDescription(this.state.description);
    this.setState({
      editing: false,
    });
  }

  isSaveDisabled() {
    return !this.state.description.length;
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.state.editing ? this.renderEditMode() : this.renderDisplayMode()}
      </div>
    );
  }

  renderDisplayMode() {
    return (
      <>
        <h3 className="d-inline-block">{this.props.issue.description}</h3>
        {this.props.canEditIssue ? <Button color="link" className="ml-2" onClick={this.openEdit}>Rename</Button> : null}
      </>
    );
  }

  renderEditMode() {
    return (
      <>
        <Form>
          <FormGroup className="flex-grow-1 mb-0">
            <Label for="description" size="sm" className="mr-2">Issue Name</Label>
            <Input
              type="text"
              name="description"
              bsSize="sm"
              className="flex-grow-1"
              value={this.state.description}
              onChange={this.updateDescription}
              onKeyPress={this.handleKeyPress}
            />
          </FormGroup>
        </Form>
        <div className="mt-2 d-flex">
          <Button size="sm" color="primary" outline className="ml-auto mr-2" onClick={this.cancelEdit}>Cancel</Button>
          <Button size="sm" color="primary" onClick={this.handleSave} disabled={this.isSaveDisabled()}>Save</Button>
        </div>
      </>
    );
  }
}

IssueName.propTypes = {
  className: PropTypes.string,
  issue: PropTypes.shape({
    description: PropTypes.string,
    issueType: PropTypes.string.isRequired,
  }),
  canEditIssue: PropTypes.bool,
  updateDescription: PropTypes.func.isRequired,
};

IssueName.defaultProps = {
  className: null,
  issue: null,
  canEditIssue: false,
};

function mapStateToProps(state) {
  return {
    issue: IssueSelectors.currentIssue(state),
    canEditIssue: IssueSelectors.canEditCurrentIssue(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateDescription: newDescription => dispatch(actions.updateDescription(newDescription)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IssueName));
