import React from 'react';
import PropTypes from 'prop-types';

const SteelSingleTankIcon = ({ text }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 225.1 225.1" style={{ enableBackground: 'new 0 0 225.1 225.1' }}>
    <g id="steel_-_single">
      <g>
        <path
          d="M94,1.6h35.8V23c43.9,8.5,76.5,47.4,76.5,93.5c0,52.6-42.3,95.2-94.4,95.2S17.5,169,17.5,116.5 c0-46.1,32.6-85,76.5-93.5V1.6z"
          style={{ overflow: 'visible', fill: '#1B1B1B' }}
        />
        <clipPath id="SVGID_2_">
          <path
            d="M94,1.6h35.8V23c43.9,8.5,76.5,47.4,76.5,93.5c0,52.6-42.3,95.2-94.4,95.2S17.5,169,17.5,116.5 c0-46.1,32.6-85,76.5-93.5V1.6z"
            style={{ overflow: 'visible' }}
          />
        </clipPath>
        <g style={{ clipPath: 'url(#SVGID_2_)' }}>
          <rect x="-606.1" y="-3572.6" width="2445.8" height="7579.1" style={{ overflow: 'visible', fill: '#1B1B1B' }} />
          <clipPath id="SVGID_4_">
            <rect x="-606.1" y="-3572.6" width="2445.8" height="7579.1" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect x="6.9" y="-9" style={{ clipPath: 'url(#SVGID_4_)', fill: '#1B1B1B' }} width="210" height="231.2" />
        </g>
      </g>
      <path
        style={{ fill: '#FFFFFF' }}
        d="M102.9,30.7c-43,4.5-76.5,41.2-76.5,85.7c0,47.6,38.3,86.2,85.5,86.2s85.5-38.6,85.5-86.2 c0-44.5-33.5-81.2-76.5-85.7V10.6h-18V30.7z"
      />
    </g>
    <g id="Layer_11">
      <text transform="matrix(1 0 0 1 74.9844 137.3187)" style={{ fill: '#414042', fontFamily: 'Roboto', fontSize: '64px' }}>{text}</text>
    </g>
  </svg>
);


SteelSingleTankIcon.propTypes = {
  text: PropTypes.any,
};

SteelSingleTankIcon.defaultProps = {
  text: '',
};

export default SteelSingleTankIcon;
