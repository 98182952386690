import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper/index';
import DispatchSystemConfigurationList from '../../../../../../components/dispatch-systems/DispatchSystemsConfigurationList';

class CustomerDispatchSystems extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleAddDispatchSystem = this.handleAddDispatchSystem.bind(this);
    this.addOrUpdateDispatchSystemConfiguration = this.addOrUpdateDispatchSystemConfiguration.bind(this);
  }

  addOrUpdateDispatchSystemConfiguration(dispatchSystem) {
    const existingConfigurationIndex = this.props.selectedOrganization.customer.dispatchSystemConfigurations.findIndex(existingSystem => existingSystem.systemName === dispatchSystem.systemName);
    if (existingConfigurationIndex !== -1) {
      return this.props.selectedOrganization.customer.dispatchSystemConfigurations.map((config, index) => {
        if (existingConfigurationIndex !== index) {
          return config;
        }
        return update(this.props.selectedOrganization.customer.dispatchSystemConfigurations[existingConfigurationIndex], {
          settings: {
            $set: dispatchSystem.settings,
          },
          enabled: {
            $set: dispatchSystem.enabled,
          },
        });
      });
    }

    return update(this.props.selectedOrganization.customer.dispatchSystemConfigurations, {
      $push: [dispatchSystem],
    });
  }

  handleAddDispatchSystem(dispatchSystem) {
    const updatedDispatchSystemConfiguration = this.addOrUpdateDispatchSystemConfiguration(dispatchSystem);
    const updatedOrganization = update(this.props.selectedOrganization, {
      customer: {
        dispatchSystemConfigurations: {
          $set: updatedDispatchSystemConfiguration,
        },
      }
    });

    this.props.editSelectedOrganization(updatedOrganization);
  }

  render() {
    return (<DispatchSystemConfigurationList dispatchSystems={this.props.selectedOrganization.customer.dispatchSystemConfigurations} onSaveDispatchSystemConfiguration={this.handleAddDispatchSystem}/>);
  }
}

CustomerDispatchSystems.propTypes = {
  selectedOrganization: PropTypes.object.isRequired,
  editSelectedOrganization: PropTypes.func.isRequired,
};

export default CustomerDispatchSystems;
