import React from 'react';
import PropTypes from 'prop-types';
import base64 from 'base64-js';

const Logo = ({ src, className, height, width, style, alt }) => {
  if (src.logo) {
    const actualStyle = Object.assign({}, style);
    if (height && width) {
      actualStyle.height = height;
      actualStyle.width = width;
    }

    return (
      <img className={className} style={actualStyle} src={`data:image/png;base64, ${src.logo}`} alt={alt} />
    );
  }
  return null;
};

Logo.propTypes = {
  src: PropTypes.shape({
    logo: PropTypes.string,
  }),
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  src: null,
  className: null,
  height: null,
  width: null,
  style: null,
};

export default Logo;
