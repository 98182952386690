import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import IngestFailureList from './IngestFailureList';
import IngestFailureDetails from './IngestFailureDetails';

class IngestFailure extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={this.props.match.url}>
          <IngestFailureList />
        </Route>
        <Route path={`${this.props.match.url}/:ingestFailureId`}>
          <IngestFailureDetails />
        </Route>
      </Switch>
    );
  }
}

IngestFailure.propTypes = {
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    router: state.router,
  };
}

export default withRouter(connect(mapStateToProps)(IngestFailure));
