import { DispatchClient, IssueClient } from '../../../../../client';
import * as AuthSelectors from '../../../../../authentication/AuthenticationSelectors';
import * as SiteSelectors from '../../../SiteSelectors';
import ActionTypes from '../SiteDispatchActionTypes';
import * as selectors from '../SiteDispatchSelectors';
import { DispatchModalState } from '../SiteDispatchConstants';


function receiveDispatches(dispatches) {
  return {
    type: ActionTypes.RECEIVE_DISPATCHES,
    dispatches,
  };
}

export default function getDispatches(siteId) {
  return (dispatch, getState) => {
    const isLoading = selectors.isLoadingDispatches(getState());

    if (isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_DISPATCHES_STARTED });
    return DispatchClient.getSiteDispatches(siteId)
      .then(dispatches => dispatch(receiveDispatches(dispatches)))
      .catch((err) => {
        dispatch({ type: ActionTypes.GET_DISPATCHES_FAILED });
        return Promise.reject(err);
      });
  };
}

function createDispatchSuccess(dispatch) {
  return {
    type: ActionTypes.CREATE_DISPATCH_SUCCESS,
    dispatch,
  };
}

function dispatchModalError(error) {
  return {
    type: ActionTypes.DISPATCH_MODAL_ERROR,
    error
  };
}

export function openCreateDispatchModal(issue) {
  return (dispatch, getState) => {
    const currentState = getState();
    if (selectors.dispatchModalState(currentState) === DispatchModalState.Loading) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.OPEN_MODAL });
    return IssueClient.getOpenSiteIssues(issue.siteId).then(openIssues => {
      const dispatchableIssues = openIssues
        .filter(current => current.dispatchable)
        .map(current => {
          current.selected = current.id === issue.id;
          current.notesToTech = '';
          return current;
        });
      const nonDispatchableIssues = openIssues.filter(current => !current.dispatchable);
      dispatch({
        type: ActionTypes.RECEIVE_ISSUES,
        dispatchableIssues,
        nonDispatchableIssues
      });
    });
  };
}

export function toggleIssueSelect(issue) {
  return {
    type: ActionTypes.TOGGLE_ISSUE_SELECT,
    issue,
  };
}

export function nextStep() {
  return { type: ActionTypes.NEXT_STEP };
}

export function updateNotesToTech(issue, notes) {
  return {
    type: ActionTypes.UPDATE_NOTES_TO_TECH,
    issue,
    notes,
  };
}

export function createDispatch() {
  return (dispatch, getState) => {
    const currentState = getState();
    if (selectors.dispatchModalState(currentState) === DispatchModalState.Saving) {
      return Promise.resolve();
    }

    const site = SiteSelectors.currentSite(currentState);
    const customerId = site.customerId;
    const dispatchSystems = AuthSelectors.customerDispatchSystems(currentState, customerId);
    const systemName = dispatchSystems[0].systemName;

    const dispatchDetails = {
      system: systemName,
      issueIds: selectors.dispatchableIssues(currentState).filter(current => current.selected).map(current => current.id),
      workOrderText: selectors.workOrderText(currentState),
    };

    dispatch({ type: ActionTypes.CREATE_DISPATCH_STARTED });
    return DispatchClient.create(site.id, dispatchDetails)
      .then((newDispatch) => {
        dispatch(createDispatchSuccess(newDispatch));
      })
      .catch((err) => {
        dispatch(dispatchModalError(err));
        return Promise.resolve();
      });
  };
}

export function closeCreateDispatchModal() {
  return {
    type: ActionTypes.CLOSE_MODAL,
  };
}
