/* eslint-disable react/no-unused-prop-types, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import { TextAlign } from './TableConstants';
import PropertyColumn from './PropertyColumn';

const buildRender = props => value => <Badge {...props}>{value}</Badge>;

function BadgeColumn(props) {
  const { sortable, title, textAlign, property, rowData, ...rest } = props;
  const render = buildRender(rest);
  return (
    <PropertyColumn
      title={title}
      property={property}
      render={render}
      rowData={rowData}
    />
  );
}

BadgeColumn.propTypes = {
  sortable: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  textAlign: PropTypes.oneOf([TextAlign.Left, TextAlign.Right, TextAlign.Center]),
  property: PropTypes.string.isRequired,

  rowData: PropTypes.object,
};

BadgeColumn.defaultProps = {
  sortable: false,
  title: null,
  width: null,
  textAlign: TextAlign.Left,
  rowData: null,
};

export default BadgeColumn;
