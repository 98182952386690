export const Steps = {
  Initial: 'initial',
  ModalHome: 'modalHome',
  SelectFolder: 'selectFolder',
  Uploading: 'uploading',
  UploadFailed: 'uploadFailed',
  UploadSuccess: 'uploadSuccess',
};

export const FileStatus = {
  NotStarted: 'notStarted',
  InProgress: 'inProgress',
  Complete: 'complete',
  Failed: 'failed',
};
