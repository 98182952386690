import React from 'react';
import PropTypes from 'prop-types';

const DeliveryURL = ({ url }) => {
  let match = url.match(/mailto:([^?]+)\??.*/);
  if (match) {
    return (<span>{match[1]}</span>);
  }
  match = url.match(/s?ftp:\/\/.*@([^:/?]+).*/);
  if (match) {
    return (<span>{match[1]}</span>);
  }
  return (<span>{url}</span>);
};

DeliveryURL.propTypes = {
  url: PropTypes.string.isRequired,
};

export default DeliveryURL;
