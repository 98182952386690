import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

class MinsPastHour extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    if (event.target.value !== '') {
      const numValue = Number.parseInt(event.target.value, 10);
      this.props.onMinSelect(numValue);
    }
    else {
      this.props.onMinSelect(null);
    }
  }

  render() {
    return (
      <Input
        type="select"
        name="minsPastHour"
        className="form-control-sm"
        value={this.props.value !== null ? this.props.value : ''}
        onChange={this.handleChange}
        style={{ display: 'inline', width: '75px', marginLeft: '10px', marginRight: '10px' }}
        disabled={this.props.disabled}
      >
        <option value="">{' '}</option>
        <option value="0">0</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
        <option value="30">30</option>
        <option value="35">35</option>
        <option value="40">40</option>
        <option value="45">45</option>
        <option value="50">50</option>
        <option value="55">55</option>
      </Input>
    );
  }
}

MinsPastHour.propTypes = {
  value: PropTypes.number,
  disabled: PropTypes.bool,
  onMinSelect: PropTypes.func.isRequired,
};

MinsPastHour.defaultProps = {
  value: null,
  disabled: false,
};

export default MinsPastHour;
