export const ViewCategory = {
  Site: 'Site',
  Compliance: 'Compliance',
  Inventory: 'Inventory',
  Issue: 'Issue',
  MVI: 'MVI',
};

export const FilterType = {
  Customer: 'customer',
  Label: 'label',
  State: 'state',
  ConnectionType: 'connectionType',
  ConnectionStatus: 'connectionStatus',
  IssueType: 'issueType',
  IssueWorkflowStatus: 'workflowStatus',
  Month: 'month',
  ComplianceStatus: 'complianceStatus',
  InspectionStatus: 'inspectionStatus',
  Site: 'site',
  TankProductLabel: 'tankProductLabel',
  TankStatus: 'tankStatus',
};

export const MonthType = {
  None: 'none',
  LatestMonth: 'latestMonth',
  SpecificMonth: 'specificMonth',
};
