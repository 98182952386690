export const step = state => state && state.sites && state.sites.documentUpload && state.sites.documentUpload.step;

export const error = state => state && state.sites && state.sites.documentUpload && state.sites.documentUpload.error;

export const files = state => state && state.sites && state.sites.documentUpload && state.sites.documentUpload.files;

export const destinationFolder = state => state && state.sites && state.sites.documentUpload && state.sites.documentUpload.destinationFolder;

export const issueIds = state => (state && state.sites && state.sites.documentUpload && state.sites.documentUpload.issueIds) || [];

export const mviReportId = state => (state && state.sites && state.sites.documentUpload && state.sites.documentUpload.mviReportId) || null;
