import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardText } from 'reactstrap';
import classnames from 'classnames';

import StatusIcon from '../../../../../shared/components/compliance/StatusIcon';
import StatusText from '../../../../../shared/components/compliance/StatusText';

const EquipmentCard = ({ id, title, status, calculatedStatusOverridden, className, onClick, children }) => (
  <Card id={id} className={className}>
    <CardBody>
      <CardText className={classnames('mb-0', { 'cursor-pointer': !!onClick })} onClick={onClick}>
        <strong className="d-inline-block" style={{ width: '10rem' }}>{title}</strong>
        <StatusIcon size="1.0em" status={status} calculatedStatusOverridden={calculatedStatusOverridden} />
        <StatusText status={status} calculatedStatusOverridden={calculatedStatusOverridden} />
      </CardText>
      {children}
    </CardBody>
  </Card>
);

EquipmentCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  calculatedStatusOverridden: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

EquipmentCard.defaultProps = {
  id: null,
  className: null,
  onClick: null,
  children: null,
};

export default EquipmentCard;
