import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { track } from '../../../../../../../../../shared/analytics';
import ConfirmModal from '../../../../../../../../../shared/components/confirm-modal/ConfirmModal';
import * as SiteSelectors from '../../../../../../../SiteSelectors';
import * as ActionItemSelectors from './ActionItemsSelectors';
import * as ActionItemsActions from './ActionItemsActions';

const ActionItemDeleteModal = ({ canEdit, isDeleteModalOpen, cancel, saveChanges }) => {
  if (!canEdit) {
    return null;
  }

  return <ConfirmModal isOpen={isDeleteModalOpen} headerText="Delete action item" onCancel={cancel} onConfirm={saveChanges} />;
};

ActionItemDeleteModal.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  isDeleteModalOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    canEdit: SiteSelectors.canEditMvis(state),
    isDeleteModalOpen: ActionItemSelectors.isDeleteModalOpen(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cancel: () => dispatch(ActionItemsActions.cancel()),
    saveChanges: () => {
      track('Inspection', {
        Component: 'Action items',
        'Inspection action': 'Delete action item',
      });
      return dispatch(ActionItemsActions.saveChanges());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionItemDeleteModal);
