export default {
  GET_SITE_FOLDERS_STARTED: 'sites/documents/get_site_folders_started',
  RECEIVE_SITE_FOLDERS: 'sites/documents/receive_site_folders',
  GET_SITE_FOLDERS_FAILED: 'sites/documents/get_site_folders_failed',

  CHANGE_FOLDER: 'sites/documents/change_folder',

  BEGIN_DOC_DRAG: 'sites/documents/begin_doc_drag',
  SELECT_SINGLE_ITEM: 'sites/documents/select_single_item',
  TOGGLE_ITEM_SELECT: 'sites/documents/toggle_item_select',
  SELECT_ALL_ITEMS: 'sites/documents/select_all_items',

  EDIT_DOC_STARTED: 'sites/documents/edit_doc_started',
  EDIT_DOC_SUCCESS: 'sites/documents/edit_doc_success',
  EDIT_DOC_FAILED: 'sites/documents/edit_doc_failed',

  DOWNLOAD_STARTED: 'sites/documents/download_started',
  DOWNLOAD_SUCCESS: 'sites/documents/download_success',
  DOWNLOAD_FAILED: 'sites/documents/download_failed',

  DELETE_STARTED: 'sites/documents/delete_started',
  DELETE_SUCCESS: 'sites/documents/delete_success',
  DELETE_FAILED: 'sites/documents/delete_failed',
};
