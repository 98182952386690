import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HasPermission from '../../../../../../../../authentication/containers/HasPermission';
import { WorkflowStatusTypes } from '../../../../../../../../AppConstants';
import OrganizationPermissions from '../../../../../../../../organization-permissions';
import * as IssueSelectors from '../../IssueEditSelectors';
import * as actions from './CommentActions';
import * as selectors from './CommentSelectors';
import CommentInput from '../../../../../../../../shared/components/comment/CommentInput';
import {track} from '../../../../../../../../shared/analytics';


class AddCommentForm extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      commentText: '',
    };
    this.updateCommentText = this.updateCommentText.bind(this);
    this.clearCommentText = this.clearCommentText.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  updateCommentText(commentText) {
    this.setState({
      commentText,
    });
  }

  clearCommentText() {
    this.setState({
      commentText: '',
    });
  }

  handleCancel() {
    this.props.cancelEdit();
    this.clearCommentText();
  }

  handleSave() {
    this.props.addComment(this.state.commentText).then(this.clearCommentText);
  }

  isVisible() {
    return this.props.issue.workflow.status !== WorkflowStatusTypes.Resolved && (this.props.isEditing || this.props.isSaving);
  }

  isSaveDisabled() {
    return !this.state.commentText || !this.state.commentText.length || this.state.commentText.trim() === '';
  }

  render() {
    const issue = this.props.issue;
    return (
      <HasPermission customerId={issue.site.customerId} siteId={issue.siteId} permission={OrganizationPermissions.IssueComment}>
        <CommentInput
          commentText={this.state.commentText}
          onCommentTextChange={this.updateCommentText}
          onSave={this.handleSave}
          onCancel={this.props.cancelEdit}
          visible={this.isVisible()}
          saving={this.isSaving}
          className={this.props.className}
        />
      </HasPermission>
    );
  }
}

AddCommentForm.propTypes = {
  issue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    workflow: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
    siteId: PropTypes.number.isRequired,
    site: PropTypes.shape({
      customerId: PropTypes.number.isRequired,
    }).isRequired,
    created: PropTypes.string.isRequired,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AddCommentForm.defaultProps = {
  className: null,
};

function mapStateToProps(state) {
  return {
    issue: IssueSelectors.currentIssue(state),
    isEditing: selectors.isEditing(state),
    isSaving: selectors.isSaving(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cancelEdit: () => dispatch(actions.cancelEditForm()),
    addComment: commentText => {
      track('Issue', {
        Component: 'Comment',
        'Issue action': 'Save comment',
      });
      return dispatch(actions.addComment(commentText));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCommentForm);
