import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedIssueWorkflows = ({ selectedFriendlyWorkflowStatuses, disabled, deselectFriendlyIssueWorkflowStatus }) => (
  <FilterItemList items={selectedFriendlyWorkflowStatuses} textField="name" disabled={disabled} onRemoveItem={deselectFriendlyIssueWorkflowStatus} />
);

SelectedIssueWorkflows.propTypes = {
  selectedFriendlyWorkflowStatuses: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool.isRequired,
  deselectFriendlyIssueWorkflowStatus: PropTypes.func.isRequired,
};

SelectedIssueWorkflows.defaultProps = {
  selectedFriendlyWorkflowStatuses: [],
};

export default SelectedIssueWorkflows;
