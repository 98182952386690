import update from 'immutability-helper';
import ActionTypes from './CommandActionTypes';
import {AtgDialect} from './CommandConstants';

function updateIntentRequest(state, action) {
  if (!state.pendingIntents) {
    return state;
  }

  const indexOfIntent = state.pendingIntents.findIndex(i => i.id === action.intentRequest.id);

  if (indexOfIntent < 0) return state;

  return update(state, {
    pendingIntents: {
      $splice: [[indexOfIntent, 1]],
    },
  });
}

function appendPendingIntents(state, action) {
  if (state.pendingIntents && state.pendingIntents.some(i => i.id === action.intentRequest.id)) {
    return state;
  }
  else if (!state.pendingIntents) {
    return Object.assign({}, state, {
      pendingIntents: [action.intentRequest],
    });
  }

  return update(state, {
    pendingIntents: {
      $push: [action.intentRequest],
    },
  });
}

export default function SiteCommandReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.RECEIVE_SITE_LINES:
      return Object.assign({}, state, {
        lines: action.lines,
      });
    case ActionTypes.RECEIVE_SITE_TANKS:
      return Object.assign({}, state, {
        tanks: action.tanks,
      });

    case ActionTypes.GET_COMMAND_REQUESTS_STARTED:
      return Object.assign({}, state, {
        recentCommandRequestsLoading: true,
      });
    case ActionTypes.RECEIVE_COMMAND_REQUESTS:
      return Object.assign({}, state, {
        recentCommandRequestsLoading: false,
        recentCommandRequests: action.commandRequests,
      });
    case ActionTypes.GET_COMMAND_REQUESTS_FAILED:
      return Object.assign({}, state, {
        recentCommandRequestsLoading: false,
      });

    case ActionTypes.INTENT_REQUEST_SENT:
      return appendPendingIntents(state, action);
    case ActionTypes.INTENT_REQUEST_RESOLVE_SUCCESS:
    case ActionTypes.INTENT_REQUEST_RESOLVE_FAILED:
      return updateIntentRequest(state, action);

    case ActionTypes.GET_INTENT_REQUESTS_STARTED:
      return Object.assign({}, state, {
        pendingIntents: state.pendingIntents || [],
        pendingIntentsLoading: true,
      });
    case ActionTypes.RECEIVE_INTENT_REQUESTS:
      return Object.assign({}, state, {
        pendingIntents: action.intentRequests,
      });
    case ActionTypes.GET_INTENT_REQUESTS_FAILED:
      return Object.assign({}, state, {
        pendingIntents: [],
      });
    case ActionTypes.GET_ATG_INFO_STARTED:
      return Object.assign({}, state, {
        atgInfoLoading: true,
        atgDialect: AtgDialect.Unknown,
      });
    case ActionTypes.RECEIVE_ATG_INFO:
      return Object.assign({}, state, {
        atgInfoLoading: false,
        atgDialect: action.atgInfo.atgDialect,
      });
    case ActionTypes.GET_ATG_INFO_FAILED:
      return Object.assign({}, state, {
        atgInfoLoading: false,
      });
    default:
      return state;
  }
}
