import update from 'immutability-helper';
import {LOCATION_CHANGE} from 'react-router-redux';

import ActionTypes from './ComplianceReportActionTypes';

const DefaultComplianceSectionState = {
  tanks: false,
  lines: false,
  sensors: false,
};

function buildEquipmentSectionsFromNavAction(action) {
  return (action && action.payload && action.payload.state && action.payload.state.complianceSectionsOpen) || DefaultComplianceSectionState;
}

function buildUpdateSections(tanks, lines, sensors) {
  return {
    tanks,
    lines,
    sensors,
  };
}

function sortComments(comments) {
  if (!comments || !comments.length) {
    return [];
  }
  return [...comments].sort((first, second) => second.created.localeCompare(first.created));
}

function applyOverrideStatus(state, overrideStatusResult) {
  const comments = state.currentReport.comments || [];
  comments.push(overrideStatusResult.comment);
  return Object.assign({}, state, {
    currentReport: update(state.currentReport, {
      status: {
        $set: overrideStatusResult.overrideStatus,
      },
      calculatedStatusOverridden: {
        $set: true,
      },
      comments: {
        $set: sortComments(comments),
      }
    }),
    overridingStatus: false,
    overrideStatus: null,
    commentText: '',
    editingComment: false,
  });
}

export default function ComplianceReportReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_REPORT_STARTED:
      return Object.assign({}, state, {
        currentReport: null,
        currentReportLoading: true,
        currentReportError: null,
        overrideStatus: null,
        overridingStatus: false,
      });
    case ActionTypes.RECEIVE_REPORT:
      return Object.assign({}, state, {
        currentReport: update(action.data, {
          comments: {
            $set: sortComments(action.data.comments),
          }
        }),
        currentReportLoading: false,
        complianceSectionsOpen: state.complianceSectionsOpen || DefaultComplianceSectionState,
      });
    case ActionTypes.GET_REPORT_FAILED:
      return Object.assign({}, state, {
        currentReportLoading: false,
        currentReportError: action.error,
      });

    case ActionTypes.GET_REPORT_EVENTS_STARTED:
      return Object.assign({}, state, {
        currentReportEvents: null,
        currentReportEventsLoading: true,
        currentReportEventsError: null,
      });
    case ActionTypes.RECEIVE_REPORT_EVENTS:
      return Object.assign({}, state, {
        currentReportEvents: action.events,
        currentReportEventsLoading: false,
      });
    case ActionTypes.GET_REPORT_EVENTS_FAILED:
      return Object.assign({}, state, {
        currentReportEventsLoading: false,
        currentReportEventsError: action.error,
      });

    case ActionTypes.TOGGLE_TANKS_SECTION:
      return update(state, {
        complianceSectionsOpen: {
          $set: buildUpdateSections(
            state.complianceSectionsOpen && !state.complianceSectionsOpen.tanks,
            state.complianceSectionsOpen && state.complianceSectionsOpen.lines,
            state.complianceSectionsOpen && state.complianceSectionsOpen.sensors
          ),
        }
      });
    case ActionTypes.TOGGLE_LINES_SECTION:
      return update(state, {
        complianceSectionsOpen: {
          $set: buildUpdateSections(
            state.complianceSectionsOpen && state.complianceSectionsOpen.tanks,
            state.complianceSectionsOpen && !state.complianceSectionsOpen.lines,
            state.complianceSectionsOpen && state.complianceSectionsOpen.sensors
          ),
        }
      });
    case ActionTypes.TOGGLE_SENSORS_SECTION:
      return update(state, {
        complianceSectionsOpen: {
          $set: buildUpdateSections(
            state.complianceSectionsOpen && state.complianceSectionsOpen.tanks,
            state.complianceSectionsOpen && state.complianceSectionsOpen.lines,
            state.complianceSectionsOpen && !state.complianceSectionsOpen.sensors
          ),
        }
      });
    case ActionTypes.HIDE_ALL_SECTIONS:
      return update(state, {
        complianceSectionsOpen: {
          $set: buildUpdateSections(false, false, false),
        }
      });
    case ActionTypes.EXPAND_ALL_SECTIONS:
      return update(state, {
        complianceSectionsOpen: {
          $set: buildUpdateSections(true, true, true),
        }
      });
    case LOCATION_CHANGE:
      return update(state, {
        complianceSectionsOpen: {
          $set: buildEquipmentSectionsFromNavAction(action),
        },
        editingComment: {
          $set: false,
        },
        commentText: {
          $set: '',
        },
        overrideStatus: {
          $set: null,
        },
        overridingStatus: {
          $set: false,
        }
      });

    case ActionTypes.OPEN_COMMENT:
      return Object.assign({}, state, {
        editingComment: true,
        commentText: '',
      });
    case ActionTypes.EDIT_COMMENT:
      return Object.assign({}, state, {
        commentText: action.commentText,
      });
    case ActionTypes.CANCEL_COMMENT:
      return Object.assign({}, state, {
        editingComment: false,
        commentText: '',
        overrideStatus: null,
        overridingStatus: false,
      });

    case ActionTypes.SAVE_COMMENT_STARTED:
      return Object.assign({}, state, {
        savingComment: true,
      });
    case ActionTypes.RECEIVE_COMMENTS:
      return update(state, {
        savingComment: {
          $set: false,
        },
        editingComment: {
          $set: false,
        },
        commentText: {
          $set: '',
        },
        currentReport: {
          comments: {
            $set: sortComments(action.comments),
          }
        }
      });
    case ActionTypes.SAVE_COMMENT_FAILED:
      return Object.assign({}, state, {
        savingComment: false,
        currentReportError: action.error,
      });
    case ActionTypes.DOWNLOAD_STARTED:
      return Object.assign({}, state, {
        downloading: true,
      });
    case ActionTypes.DOWNLOAD_SUCCESS:
    case ActionTypes.DOWNLOAD_FAILED:
      return Object.assign({}, state, {
        downloading: false,
        error: action.error,
      });
    case ActionTypes.OVERRIDE_STATUS:
      return Object.assign({}, state, {
        overrideStatus: action.overrideStatus,
        overridingStatus: true,
      });
    case ActionTypes.SAVE_OVERRIDE_STATUS_STARTED:
      return Object.assign({}, state, {
        savingOverrideStatus: true,
      });
    case ActionTypes.SAVE_OVERRIDE_STATUS_FAILED:
      return Object.assign({}, state, {
        savingOverrideStatus: false,
        currentReportError: action.error,
      });
    case ActionTypes.SAVE_OVERRIDE_STATUS_SUCCESS: {
      return applyOverrideStatus(state, action.overrideStatusResult);
    }
    default:
      return state;
  }
}
