import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import * as selectors from '../DispatchSystemDropdownSelectors';
import * as actions from '../actions';
import DispatchSystemDropdown from '../components/DispatchSystemDropdown';


class DispatchSystemDropdownContainer extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.props.getDispatchSystems();
  }

  render() {
    if (this.props.isDispatchSystemListLoading) {
      return (
        <PulseLoader color={'#F8E71C'} size={8} />
      );
    }


    const availableDispatchSystems = this.props.dispatchSystemList.filter(dispatchSystem => this.props.excludeDispatchSystems.indexOf(dispatchSystem.id) === -1);

    return (
      <DispatchSystemDropdown
        dispatchSystemList={availableDispatchSystems}
        selectedDispatchSystem={this.props.selectedDispatchSystem}
        onDispatchSystemSelect={this.props.onDispatchSystemSelect}
      />
    );
  }
}

DispatchSystemDropdownContainer.propTypes = {
  isDispatchSystemListLoading: PropTypes.bool.isRequired,
  dispatchSystemList: PropTypes.array.isRequired,
  getDispatchSystems: PropTypes.func.isRequired,
  onDispatchSystemSelect: PropTypes.func.isRequired,
  selectedDispatchSystem: PropTypes.string,
  excludeDispatchSystems: PropTypes.array,
};

DispatchSystemDropdownContainer.defaultProps = {
  selectedDispatchSystem: '',
  excludeDispatchSystems: [],
};

function mapStateToProps(state) {
  return {
    isDispatchSystemListLoading: selectors.isDispatchSystemListLoading(state),
    dispatchSystemList: selectors.dispatchSystemList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDispatchSystems: () => dispatch(actions.getDispatchSystems()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DispatchSystemDropdownContainer);
