import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import HealthGauge from '../../../../../components/health-gauge/HealthGauge';
import * as actions from '../actions';
import * as selectors from '../InvDashboardSelectors';

class InvSite1HourGauge extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.props.getHourlyHistoryList();
  }

  render() {
    let gaugeValue = 0;
    if (this.props.hourlyHistoryList.length > 2) {
      const hourlyHistoryList = this.props.hourlyHistoryList;
      const currentMin = moment().minutes();
      const currentHourPercentage = (60 - currentMin) / 100;
      const previousHourPercentage = 1 - currentHourPercentage;
      const currentHour = hourlyHistoryList[hourlyHistoryList.length - 1];
      const previousHour = hourlyHistoryList[hourlyHistoryList.length - 2];
      const healthyCount = (currentHour.healthySiteCount * currentHourPercentage) + (previousHour.healthySiteCount * previousHourPercentage);
      const unhealthyCount = (currentHour.unhealthySiteCount * currentHourPercentage) + (previousHour.unhealthySiteCount * previousHourPercentage);
      const totalCount = healthyCount + unhealthyCount;

      if (totalCount > 0) {
        gaugeValue = (healthyCount / totalCount) * 100;
      }
    }

    return (
      <HealthGauge
        label="Healthy Sites (1h)"
        value={gaugeValue}
        helpText="The percentage of sites where the inventory data included in the export was less than 10 minutes old at the time the export was generated.
          Only includes exports from within the past hour."
      />
    );
  }
}

InvSite1HourGauge.propTypes = {
  hourlyHistoryList: PropTypes.array.isRequired,
  getHourlyHistoryList: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    hourlyHistoryList: selectors.hourlyHistoryList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHourlyHistoryList: () => dispatch(actions.getHourlyHistoryList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvSite1HourGauge);
