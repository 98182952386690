import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

const FuelHaulerDeleteModal = ({ fuelHauler, onDelete, onCancel }) => {
  if (!fuelHauler) {
    return null;
  }

  return (
    <Modal isOpen toggle={onCancel}>
      <ModalHeader toggle={onCancel}>Delete Fuel Hauler</ModalHeader>
      <ModalBody>
        Are you sure you want to delete <strong>{fuelHauler.name}</strong>? Deleting a fuel hauler
        removes all saved settings, export history, and scheduled jobs. This operation cannot be
        undone.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline size="sm" onClick={onCancel}>Cancel</Button>
        {' '}
        <Button id="confirm" color="primary" size="sm" onClick={onDelete}>Delete</Button>
      </ModalFooter>
    </Modal>
  );
};

FuelHaulerDeleteModal.propTypes = {
  fuelHauler: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

FuelHaulerDeleteModal.defaultProps = {
  fuelHauler: null,
};

export default FuelHaulerDeleteModal;
