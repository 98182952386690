import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createLogger from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import IntentMiddleware from './user/sites/screens/commands/CommandMiddleware';
import { AuthenticationMiddleware } from './authentication';
import { ExportMiddleware } from './user/exports';
import DeviceActivationMiddleware from './admin/screens/sites/screens/activation/ActivationMiddleware';
import InventoryMiddleware from './user/inventory/InventoryMiddleware';
import { socketLoginMiddleware } from './SocketMiddleware';
import rootReducer from './reducer';

const middleswares = [
  AuthenticationMiddleware,
  socketLoginMiddleware,
  ExportMiddleware.socketMiddleware,
  IntentMiddleware.socketMiddleware,
  DeviceActivationMiddleware.socketMiddleware,
  InventoryMiddleware.socketMiddleware,
  thunk,
];

if (window.environmentType === 'dev' && window.environmentName === 'local') {
  const logger = createLogger({ collapsed: true });
  middleswares.push(logger);
}

export const history = createBrowserHistory();
const router = routerMiddleware(history);
middleswares.push(router);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const Store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleswares)));
