import LoginFormContainer from './containers/LoginForm';
import ResetPasswordFormContainer from './containers/ResetPasswordForm';
import HasPermissionContainer from './containers/HasPermission';
import HasPermissionAnySiteContainer from './containers/HasPermissionAnySite';
import HasPermissionAnyOrgSiteContainer from './containers/HasPermissionAnyOrgSite';
import * as actions from './actions';
import * as selectors from './AuthenticationSelectors';
import reducer from './AuthenticationReducer';
import middleware from './AuthenticationMiddleware';
import AuthenticationActionTypesImport from './AuthenticationActionTypes';

export const LoginForm = LoginFormContainer;
export const ResetPasswordForm = ResetPasswordFormContainer;
export const HasPermission = HasPermissionContainer;
export const HasPermissionAnySite = HasPermissionAnySiteContainer;
export const HasPermissionAnyOrgSite = HasPermissionAnyOrgSiteContainer;
export const AuthenticationActions = actions;
export const AuthenticationReducer = reducer;
export const AuthenticationMiddleware = middleware;
export const AuthenticationActionTypes = AuthenticationActionTypesImport;
export const AuthenticationSelectors = selectors;
