import moment from 'moment';

import { Get, Post } from '../HttpUtils';

export default {
  create(exportData) {
    return Post('/Exports', exportData).then(response => response.data);
  },

  getRecentExports(userId) {
    const url = `/exports?filter[where][status][neq]=Failed&filter[where][userId]=${userId}&filter[limit]=10&filter[order]=created DESC`;
    return Get(url)
      .then((response) => {
        const exports = response.data;
        const now = moment();

        exports.forEach((exportInstance) => {
          if (exportInstance.created) {
            const exportTime = moment(exportInstance.created);
            if (exportTime.isSame(now, 'day')) {
              exportInstance.created = exportTime.format('HH:mm');
            }
            else {
              exportInstance.created = exportTime.format('M/D HH:mm');
            }
          }
          if (exportInstance.exportUrl) {
            exportInstance.name = exportInstance.exportUrl.split('/').pop();
          }
        });

        return exports;
      });
  },

  getDownloadLink(exportId) {
    const id = exportId.id || exportId;
    return Get(`/exports/${id}/link`).then(response => response.data);
  }
};
