import ActionTypes from '../InvDashboardActionTypes';
import * as selectors from '../InvDashboardSelectors';
import { InvDashboardClient } from '../../../../../../client';

function receiveHourlyHistory(hourlyHistoryList) {
  return {
    type: ActionTypes.RECEIVE_HISTORY,
    hourlyHistoryList,
  };
}

export function getHourlyHistoryList() {
  return (dispatch, getState) => {
    const isLoading = selectors.isHourlyHistoryLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_HISTORY_STARTED });
    return InvDashboardClient.getHourlyHistory()
      .then(hourlyHistoryList => dispatch(receiveHourlyHistory(hourlyHistoryList)))
      .catch((err) => {
        dispatch({ type: ActionTypes.GET_HISTORY_FAILED });
        return Promise.reject(err);
      });
  };
}

function receiveFuelHaulerHealth(fuelHaulerHealth) {
  return {
    type: ActionTypes.RECEIVE_FUEL_HAULER_HEALTH,
    fuelHaulerHealth,
  };
}

export function getFuelHaulerHealth() {
  return (dispatch, getState) => {
    const isLoading = selectors.isFuelHaulerHealthLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.GET_FUEL_HAULER_HEALTH_STARTED });
    return InvDashboardClient.getFuelHaulerHealth()
      .then(fuelHaulerHealth => dispatch(receiveFuelHaulerHealth(fuelHaulerHealth)))
      .catch((err) => {
        dispatch({ type: ActionTypes.GET_FUEL_HAULER_HEALTH_FAILED });
        return Promise.reject(err);
      });
  };
}
