import ActionTypes from './IssueDispatchActionTypes';

export default function IssueReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_DISPATCH_ACTIVITY_STARTED:
      return Object.assign({}, state, {
        isLoading: true,
        dispatchActivity: [],
      });
    case ActionTypes.RECEIVE_DISPATCH_ACTIVITY:
      return Object.assign({}, state, {
        isLoading: false,
        dispatchActivity: action.dispatchActivity,
      });
    case ActionTypes.GET_DISPATCH_ACTIVITY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        dispatchActivity: [],
      });

    default:
      return state;
  }
}
