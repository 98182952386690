import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import { SystemUserId } from '../../../../../../../../AppConstants';
import CanarySummaryBar from '../../../../../../../../shared/components/CanarySummaryBar';
import { routePaths } from '../../../../../../../../user/preferences/PreferenceConstants';
import UserIssueNotificationRules from '../../../../../../../../user/preferences/components/UserIssueNotificationRules';
import UserLowProductNotificationRules
  from '../../../../../../../../user/preferences/components/UserLowProductNotificationRules';
import ConfirmModal from '../../../../../../../../shared/components/confirm-modal/ConfirmModal';
import * as AuthSelectors from '../../../../../../../../authentication/AuthenticationSelectors';
import * as navActions from '../../../../../actions';
import ErrorMessage from '../../../../../../../../shared/components/error-message/ErrorMessage';
import EntityNotificationLog from '../../notification-log/containers/EntityNotificationLog';
import UserSettings from '../components/UserSettings';
import PasswordReset from '../components/PasswordReset';
import LoginHistory from '../components/LoginHistory';
import * as OrgEditSelectors from '../../../OrganizationEditSelectors';
import * as actions from '../actions';
import * as selectors from '../UserSelectors';
import GroupList from '../components/GroupList';

class UserEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmModal: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleUserSave = this.handleUserSave.bind(this);
    this.confirmUserEdit = this.confirmUserEdit.bind(this);
    this.cancelUserEdit = this.cancelUserEdit.bind(this);

    if (props.match && props.match.params && props.match.params.userId) {
      props.selectUser(props.match.params.userId);
    }
  }

  canCancel() {
    return !Number.isInteger(this.props.pendingUserEdits.id) || !this.props.pendingUserEditsPristine;
  }

  handleCancel() {
    const isNew = !Number.isInteger(this.props.pendingUserEdits.id);
    if (isNew) {
      this.props.navToUserList();
    }
    else {
      this.props.discardPendingUserEdits();
    }
  }

  handleUserSave() {
    if (this.props.pendingEditWarnings.length) {
      this.setState({
        confirmModal: true,
      });
    }
    else {
      this.props.savePendingUserEdits();
    }
  }

  confirmUserEdit() {
    this.setState({
      confirmModal: false,
    });
    this.props.savePendingUserEdits();
  }

  cancelUserEdit() {
    this.setState({
      confirmModal: false,
    });
    this.props.discardPendingUserEdits();
  }

  renderSelfEditWarning() {
    if (this.props.selectedUser && this.props.selectedUser.id === this.props.loggedInUserId) {
      return (
        <Alert color="danger">
          You are editing your own account which is not recommended.  Changing settings here may cause errors.
        </Alert>
      );
    }
    return null;
  }

  renderSaveUserWarnings() {
    if (this.props.pendingEditWarnings.length === 1) {
      return <span>{this.props.pendingEditWarnings[0]}</span>;
    }
    else if (this.props.pendingEditWarnings.length) {
      return (
        <ul>
          {this.props.pendingEditWarnings.map(current => (
            <li key={current}>{current}</li>
          ))}
        </ul>
      );
    }
    return null;
  }

  renderUserSettings() {
    return (
      <div>
        <UserSettings
          selectedUser={this.props.pendingUserEdits}
          editSelectedUser={this.props.editSelectedUser}
          hasValidPasswordLength={this.props.hasValidPasswordLength}
          hasUniqueEmail={this.props.hasUniqueEmail}
          hasUniqueUsername={this.props.hasUniqueUsername}
          usersByUsername={this.props.usersByUsername}
          usersByEmail={this.props.usersByEmail}
          loggedInUserIsAdmin={this.props.loggedInUserIsAdmin}
        />
        <div className="mt-4">
          <Button color="primary" size="sm" outline onClick={this.handleCancel} disabled={!this.canCancel()}>Cancel</Button>
          {' '}
          <Button id="save" color="primary" size="sm" onClick={this.handleUserSave} disabled={!this.props.canSavePendingEdits}>Confirm</Button>
        </div>
        <ConfirmModal
          isOpen={this.state.confirmModal}
          body={this.renderSaveUserWarnings()}
          onConfirm={this.confirmUserEdit}
          onCancel={this.cancelUserEdit}
        />
      </div>
    );
  }

  renderGroups() {
    const orgGroups = (this.props.selectedOrganization && this.props.selectedOrganization.groups) || [];
    return (
      <div>
        <GroupList allGroups={orgGroups} selectedUser={this.props.pendingUserEdits} editSelectedUser={this.props.editSelectedUser} />
        <div className="mt-4">
          <Button color="primary" size="sm" outline onClick={this.handleCancel} disabled={!this.canCancel()}>Cancel</Button>
          {' '}
          <Button id="save" color="primary" size="sm" onClick={this.handleUserSave} disabled={!this.props.canSavePendingEdits}>Confirm</Button>
        </div>
      </div>
    );
  }

  renderIssueNotificationSettings() {
    if (!this.props.customers.length) {
      return (
        <Alert color="info">
          Selected user is not associated with a customer and cannot receive issue notifications
        </Alert>
      );
    }
    return <UserIssueNotificationRules userId={this.props.selectedUser.id} customers={this.props.customers} initialCustomerId={this.props.customers[0].id} />;
  }

  renderLowProductNotificationSettings() {
    if (!this.props.customers.length) {
      return (
        <Alert color="info">
          Selected user is not associated with a customer and cannot receive low product notifications
        </Alert>
      );
    }
    return <UserLowProductNotificationRules userId={this.props.selectedUser.id} customers={this.props.customers} initialCustomerId={this.props.customers[0].id} />;
  }

  renderContent() {
    if (this.props.selectedUser && this.props.selectedUser.id === SystemUserId) {
      return (
        <Alert color="info">You cannot make modifications to the system user.</Alert>
      );
    }

    return (
      <Switch>
        <Redirect exact="exact" from={`${this.props.match.url}/`} to={`${this.props.match.url}/settings`} />
        <Route exact path={`${this.props.match.url}/settings`} name="user-settings">
          {this.renderUserSettings()}
        </Route>
        <Route exact path={`${this.props.match.url}/password`} name="user-password">
          <PasswordReset user={this.props.selectedUser} onEmailReset={this.props.sendPasswordResetEmail} onSetNewPassword={this.props.resetPassword} />
        </Route>
        <Route exact path={`${this.props.match.url}/login-history`} name="user-login-history">
          <LoginHistory selectedUser={this.props.selectedUser} />
        </Route>
        <Route exact path={`${this.props.match.url}/groups`} name="user-groups">
          {this.renderGroups()}
        </Route>
        <Route exact path={`${this.props.match.url}/${routePaths.IssueNotificationSettings}`} name="user-issue-notification">
          {this.renderIssueNotificationSettings()}
        </Route>
        <Route exact path={`${this.props.match.url}/${routePaths.LowProductNotificationSettings}`} name="user-low-product-notification">
          {this.renderLowProductNotificationSettings()}
        </Route>
        <Route exact path={`${this.props.match.url}/notifications`} name="user-notifications">
          <EntityNotificationLog notifications={this.props.entityNotifications} />
        </Route>
      </Switch>
    );
  }

  render() {
    if (!this.props.selectedUser && this.props.error) {
      return (
        <div className="mt-4">
          <ErrorMessage title="Failed to load user" error={this.props.error} />
        </div>
      );
    }
    else if (!this.props.selectedUser || this.props.isLoading || this.props.isSaving) {
      return (
        <PulseLoader color="#F8E71C" size={8} />
      );
    }
    else if (!this.props.selectedUser.id) {
      return (
        <div className="mt-4">
          <h5>New User</h5>
          <ErrorMessage title="Failed to create user" error={this.props.error} />
          {this.renderUserSettings()}
        </div>
      );
    }

    return (
      <div className="row mt-4">
        <div className="col-md-2">
          <div className="nav flex-column nav-pills">
            <NavLink to={`${this.props.match.url}/settings`} className="nav-link">Settings</NavLink>
            <NavLink to={`${this.props.match.url}/password`} className="nav-link">Password</NavLink>
            <NavLink to={`${this.props.match.url}/groups`} className="nav-link">Groups</NavLink>
            <NavLink to={`${this.props.match.url}/login-history`} className="nav-link">Login History</NavLink>
            <NavLink to={`${this.props.match.url}/issue-notification-settings`} className="nav-link">Issue Notification Settings</NavLink>
            <NavLink to={`${this.props.match.url}/low-product-notification-settings`} className="nav-link">Low Product Notification Settings</NavLink>
            <NavLink to={`${this.props.match.url}/notifications`} className="nav-link">Notifications</NavLink>
          </div>
        </div>
        <div className="col-md-10">
          {this.renderSelfEditWarning()}
          <ErrorMessage title="Failed to save user" error={this.props.error} />
          <CanarySummaryBar summary={this.props.selectedUser.username} />
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

UserEditor.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  selectedOrganization: PropTypes.object,
  selectedUser: PropTypes.object,
  loggedInUserId: PropTypes.number,
  loggedInUserIsAdmin: PropTypes.bool.isRequired,
  pendingUserEdits: PropTypes.object,
  pendingUserEditsPristine: PropTypes.bool.isRequired,
  pendingEditWarnings: PropTypes.arrayOf(PropTypes.string).isRequired,
  canSavePendingEdits: PropTypes.bool.isRequired,
  error: PropTypes.object,
  selectUser: PropTypes.func.isRequired,
  editSelectedUser: PropTypes.func.isRequired,
  savePendingUserEdits: PropTypes.func.isRequired,
  discardPendingUserEdits: PropTypes.func.isRequired,
  sendPasswordResetEmail: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  navToUserList: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  customers: PropTypes.array,
  entityNotifications: PropTypes.array,
  hasValidPasswordLength: PropTypes.bool.isRequired,
  hasUniqueEmail: PropTypes.bool.isRequired,
  hasUniqueUsername: PropTypes.bool.isRequired,
  usersByUsername: PropTypes.object.isRequired,
  usersByEmail: PropTypes.object.isRequired,
};

UserEditor.defaultProps = {
  selectedOrganization: null,
  selectedUser: null,
  loggedInUserId: null,
  pendingUserEdits: null,
  error: null,
  customers: [],
  entityNotifications: [],
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    isSaving: selectors.isSaving(state),
    error: selectors.error(state),

    selectedOrganization: OrgEditSelectors.selectedOrganization(state),
    loggedInUserId: AuthSelectors.currentUserId(state),
    loggedInUserIsAdmin: AuthSelectors.isAdmin(state),
    selectedUser: selectors.selectedUser(state),
    pendingUserEdits: selectors.pendingUserEdits(state),
    pendingUserEditsPristine: selectors.isPendingUserEditsPristine(state),
    pendingEditWarnings: selectors.getPendingEditWarnings(state),
    canSavePendingEdits: selectors.canSavePendingUserEdits(state),
    customers: selectors.customersRelatedToSelectedUser(state),
    entityNotifications: selectors.entityNotifications(state),
    hasValidPasswordLength: selectors.hasValidPasswordLength(state),
    hasUniqueEmail: selectors.pendingUserEditsHasUniqueEmail(state),
    hasUniqueUsername: selectors.pendingUserEditsHasUniqueUsername(state),
    usersByUsername: selectors.usersByUsername(state),
    usersByEmail: selectors.usersByEmail(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    selectUser: user => dispatch(actions.selectUser(user)),
    editSelectedUser: user => dispatch(actions.editSelectedUser(user)),
    savePendingUserEdits: () => dispatch(actions.savePendingUserEdits()),
    discardPendingUserEdits: () => dispatch(actions.discardPendingUserEdits()),
    sendPasswordResetEmail: user => dispatch(actions.sendPasswordResetEmail(user)),
    resetPassword: (user, newPassword) => dispatch(actions.resetPassword(user, newPassword)),
    navToUserList: () => dispatch(navActions.navToUserList(ownProps.match.params.organizationId)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserEditor));
