import { MviFields } from '../../client';
import { buildEnumSort, buildStringSort } from '../../shared/SortUtils';
import { MviWorkflowStatusTypes } from '../../AppConstants';

export const MviColumns = {
  Month: {
    id: 'month',
    name: 'Inspection month',
    shortName: 'Month',
    apiFields: [MviFields.Month],
    exportFunc: mvi => mvi.monthStartDate || '',
    sortFunc: buildStringSort('monthStartDate'),
  },
  Customer: {
    id: 'customer',
    name: 'Customer name',
    shortName: 'Customer',
    apiFields: [MviFields.Customer],
    exportFunc: mvi => (mvi.customer && mvi.customer.name) || '',
    sortFunc: buildStringSort('customer.name'),
  },
  SiteNickname: {
    id: 'siteNickname',
    name: 'Site',
    shortName: 'Site',
    apiFields: [MviFields.Site],
    exportFunc: mvi => (mvi.site && mvi.site.nickname) || '',
    sortFunc: buildStringSort('site.nickname'),
  },
  SiteState: {
    id: 'siteState',
    name: 'State',
    shortName: 'State',
    apiFields: [MviFields.Site],
    exportFunc: mvi => (mvi.site && mvi.site.state) || '',
    sortFunc: buildStringSort('site.state'),
  },
  WorkflowStatus: {
    id: 'workflowStatus',
    name: 'Inspection status',
    shortName: 'Status',
    apiFields: [MviFields.Workflow],
    exportFunc: mvi => (mvi.workflow && mvi.workflow.status) || '',
    sortFunc: buildEnumSort('workflow.status', ...Object.values(MviWorkflowStatusTypes))
  },
  WorkflowStatusDate: {
    id: 'workflowStatusDate',
    name: 'Status date',
    shortName: 'Status date',
    apiFields: [MviFields.Workflow],
    exportFunc: mvi => (mvi.workflow && mvi.workflow.statusDate) || '',
    sortFunc: buildStringSort('workflow.statusDate'),
  },
  WorkflowUsername: {
    id: 'workflowUsername',
    name: 'Status set by',
    shortName: 'Status set by',
    apiFields: [MviFields.Workflow],
    exportFunc: mvi => (mvi.workflow && mvi.workflow.user && mvi.workflow.user.username) || '',
    sortFunc: buildStringSort('workflow.user.username'),
  },
  SiteLabels: {
    id: 'siteLabels',
    name: 'Site label',
    shortName: 'Site label',
    apiFields: [MviFields.SiteLabels],
    exportFunc: mvi => {
      if (!mvi.siteLabels || !mvi.siteLabels.length) {
        return '';
      }
      return mvi.siteLabels.map(l => l.labelText).join(' ');
    }
  }
};

export const DefaultTableColumns = [
  MviColumns.Month,
  MviColumns.SiteNickname,
  MviColumns.WorkflowStatus,
  MviColumns.WorkflowStatusDate,
  MviColumns.SiteLabels,
];
