import ActionTypes from '../ExportDestinationActionTypes';
import * as selectors from '../ExportDestinationSelectors';

export function updateUrlParts(urlParts) {
  return {
    type: ActionTypes.RECEIVE_UPDATE,
    urlParts,
  };
}

export function reset(deliveryUrl) {
  return (dispatch, getState) => {
    const currentDeliveryUrl = selectors.deliveryUrl(getState());
    if (currentDeliveryUrl !== deliveryUrl) {
      dispatch({
        type: ActionTypes.RESET,
        deliveryUrl,
      });
    }
  };
}
