import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const OrganizationDropdown = ({ id, name, organizationList, selectedOrganization, disabled, onOrganizationSelect, className, style }) => {
  const organizationsById = new Map();
  organizationList.forEach(currentOrganization => organizationsById.set(String(currentOrganization.id), currentOrganization));

  let currentValue = (selectedOrganization && selectedOrganization.id) || '';
  if (Number.isInteger(selectedOrganization)) {
    currentValue = selectedOrganization;
  }

  return (
    <Input
      type="select"
      id={id}
      name={name}
      value={currentValue}
      disabled={disabled}
      onChange={event => event.target.value && onOrganizationSelect(organizationsById.get(event.target.value))}
      className={className}
      style={style}
    >
      <option value={null}>Select an organization</option>
      {organizationList.map(current => (
        <option value={current.id} key={current.id}>{current.name}</option>
      ))}
    </Input>
  );
};

OrganizationDropdown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  organizationList: PropTypes.array.isRequired,
  selectedOrganization: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  disabled: PropTypes.bool,
  onOrganizationSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.any,
};

OrganizationDropdown.defaultProps = {
  id: null,
  name: null,
  selectedOrganization: null,
  disabled: false,
  className: null,
  style: null,
};

export default OrganizationDropdown;
