import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'react-router-redux';

import { timestampAsText } from '../../../../shared/components/date';
import ActionTypes from './SiteDispatchActionTypes';
import { DispatchModalState } from './SiteDispatchConstants';

const InitialListState = {
  isLoadingDispatches: false,
  dispatches: [],
};

const InitialModalState = {
  modalState: DispatchModalState.Closed,
  dispatchableIssues: null,
  nonDispatchableIssues: null,
  workOrderText: '',
  newDispatch: null,
  dispatchModalError: null,
};

const InitialState = Object.assign({}, InitialListState, InitialModalState);

function receiveIssues(state, action) {
  let modalState = DispatchModalState.SelectIssues;
  if (action.dispatchableIssues.length === 1 && action.nonDispatchableIssues.length === 0) {
    modalState = DispatchModalState.SetTechNotes;
  }

  return Object.assign({}, state, {
    modalState,
    dispatchableIssues: action.dispatchableIssues,
    nonDispatchableIssues: action.nonDispatchableIssues,
    workOrderText: buildWorkOrderText(action.dispatchableIssues),
  });
}

function toggleIssueSelect(state, issue) {
  return updateIssue(state, issue, !issue.selected, '');
}

function setNotesToTech(state, issue, notes) {
  return updateIssue(state, issue, issue.selected, notes);
}

function updateIssue(state, issue, selected, notesToTech) {
  const index = state.dispatchableIssues.findIndex(current => current.id === issue.id);
  if (index === -1) {
    return state;
  }
  const existingIssue = state.dispatchableIssues[index];
  const updatedIssue = update(existingIssue, {
    selected: {
      $set: selected,
    },
    notesToTech: {
      $set: notesToTech,
    },
  });
  const updatedIssues = update(state.dispatchableIssues, {
    $splice: [[index, 1, updatedIssue]]
  });

  return update(state, {
    dispatchableIssues: {
      $set: updatedIssues
    },
    workOrderText: {
      $set: buildWorkOrderText(updatedIssues),
    }
  });
}

function buildWorkOrderText(dispatchableIssues) {
  return dispatchableIssues.filter(current => current.selected).map(formatIssue).join('\n\n');
}

function formatIssue(issue) {
  const parts = [
    `ISSUE ${issue.id} (${issue.siteNickname}) - ${issue.description}`,
    issue.notesToTech && `NOTES TO TECH:\n\n${issue.notesToTech}`,
    issue.analysis && issue.analysis.analysis && `ANALYSIS:\n\n${issue.analysis.analysis}`,
    issue.analysis && issue.analysis.recommendations && `RECOMMENDATIONS:\n\n${issue.analysis.recommendations}`,
    formatAlarmHeader(issue),
    issue.alarms && issue.alarms.length && formatAlarmsText(issue.alarms)
  ];
  return parts.filter(current => !!current).join('\n\n');
}

function formatAlarmHeader(issue) {
  if (!issue.alarms || !issue.alarms.length) {
    return null;
  }
  else if (issue.alarms.length === 1) {
    return '1 ALARM IN THIS ISSUE:';
  }
  return `${issue.alarms.length} ALARMS IN THIS ISSUE:`;
}

function formatAlarmsText(alarms) {
  return alarms.map((alarm) => {
    const firstAlarm = timestampAsText(alarm.firstAlarmDate);
    const activeAsOf = timestampAsText(alarm.alarmDate);
    if (alarm.count === 1) {
      return `${alarm.title} (${firstAlarm})`;
    }

    return `${alarm.title} (count: ${alarm.count}, first alarm at ${firstAlarm}, active as of ${activeAsOf})`;
  }).join('\n');
}

const SiteDispatchReducer = (state = InitialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DISPATCHES_STARTED:
      return Object.assign({}, state, {
        isLoadingDispatches: true,
        dispatches: [],
      });
    case ActionTypes.RECEIVE_DISPATCHES:
      return Object.assign({}, state, {
        isLoadingDispatches: false,
        dispatches: action.dispatches,
      });
    case ActionTypes.GET_DISPATCHES_FAILED:
      return Object.assign({}, state, {
        isLoadingDispatches: false,
        dispatches: [],
      });

    case ActionTypes.OPEN_MODAL:
      return Object.assign({}, state, InitialModalState, {
        modalState: DispatchModalState.Loading,
      });
    case ActionTypes.RECEIVE_ISSUES:
      return receiveIssues(state, action);
    case ActionTypes.TOGGLE_ISSUE_SELECT:
      return toggleIssueSelect(state, action.issue);
    case ActionTypes.NEXT_STEP:
      return Object.assign({}, state, {
        modalState: DispatchModalState.SetTechNotes,
      });
    case ActionTypes.UPDATE_NOTES_TO_TECH:
      return setNotesToTech(state, action.issue, action.notes);

    case ActionTypes.CREATE_DISPATCH_STARTED:
      return Object.assign({}, state, {
        modalState: DispatchModalState.Saving,
      });
    case ActionTypes.CREATE_DISPATCH_SUCCESS:
      return Object.assign({}, state, {
        modalState: DispatchModalState.Done,
        newDispatch: action.dispatch,
      });

    case ActionTypes.DISPATCH_MODAL_ERROR:
      return Object.assign({}, state, {
        modalState: DispatchModalState.Error,
        dispatchModalError: action.error,
      });

    case LOCATION_CHANGE:
    case ActionTypes.CLOSE_MODAL:
      return Object.assign({}, state, InitialModalState);

    default:
      return state;
  }
};

export default SiteDispatchReducer;
