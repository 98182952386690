import { Get, Put, Delete } from '../HttpUtils';

export default {
  getList() {
    return Get('/devices/activations')
      .then(response => response.data);
  },

  getById(id) {
    return Get(`/devices/activations/${id}`)
      .then(response => response.data);
  },

  deleteById(id) {
    return Delete(`/devices/activations/${id}`)
      .then(response => response.data);
  },

  update(id, site) {
    return Put(`/devices/activations/${id}`, site)
      .then(response => response.data);
  },

  getNearbySites(id) {
    return Get(`/devices/activations/${id}/nearbySites`)
      .then(response => response.data);
  },
};
