import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SiteAutoSuggest from '../components/SiteAutoSuggest';

class ListFilteringSiteAutoSuggest extends Component {
  static isSiteMatch(site, inputValue) {
    if (site && site.nickname) {
      return site.nickname.toLowerCase().indexOf(inputValue) !== -1;
    }

    return site.siteName.toLowerCase().indexOf(inputValue) !== -1
      || site.siteName.toLowerCase().replace(/0/g, '').indexOf(inputValue) !== -1;
  }

  constructor(initialProps) {
    super(initialProps);
    this.state = {
      suggestions: initialProps.siteList,
      value: '',
    };
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleSiteSearch = this.handleSiteSearch.bind(this);
    this.handleSuggestionsClear = this.handleSuggestionsClear.bind(this);
    this.handleSiteSelect = this.handleSiteSelect.bind(this);
  }

  handleValueChange(value) {
    this.setState({ value });
  }

  handleSiteSearch(value) {
    let suggestions = [];
    if (value && value.length) {
      const excludedIds = this.props.excludedSites.map(current => current.id);
      suggestions = this.props.siteList
        .filter(site => ListFilteringSiteAutoSuggest.isSiteMatch(site, value))
        .filter(site => excludedIds.indexOf(site.id) === -1);
    }

    if (suggestions.length > 5) {
      suggestions = suggestions.slice(0, 5);
    }

    this.setState({
      suggestions,
    });
  }

  handleSuggestionsClear() {
    this.setState({
      suggestions: [],
      value: '',
    });
  }

  handleSiteSelect(site) {
    this.props.onSiteSelected(site);
    this.setState({
      value: '',
    });
  }

  render() {
    return (
      <SiteAutoSuggest
        id={this.props.id}
        value={this.state.value}
        onValueChange={this.handleValueChange}
        suggestions={this.state.suggestions}
        onSiteSearchRequested={this.handleSiteSearch}
        onSiteSelected={this.handleSiteSelect}
        onClear={this.handleSuggestionsClear}
        disabled={this.props.disabled}
        inputClassName={this.props.inputClassName}
      />
    );
  }
}

ListFilteringSiteAutoSuggest.propTypes = {
  id: PropTypes.string.isRequired,
  siteList: PropTypes.array.isRequired,
  onSiteSelected: PropTypes.func.isRequired,
  excludedSites: PropTypes.array,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
};

ListFilteringSiteAutoSuggest.defaultProps = {
  excludedSites: [],
  disabled: false,
  inputClassName: undefined,
};

export default ListFilteringSiteAutoSuggest;
