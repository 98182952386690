const Status = { id: 5,
  name: 'operationalStatusHistory',
  label: 'Status',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: true,
  modalOpen: false,
  deleteModalOpen: false,
  index: 0,
  fields: [
    { id: 1,
      name: 'effectiveDate',
      label: 'Effective Date',
      path: 'operationalStatusHistory.0.effectiveDate',
      type: 'date',
      cols: 4,
      editable: true,
      error: false,
      required: true,
      display: true,
    },
    { id: 2,
      name: 'serviceChange',
      label: 'Status',
      path: 'operationalStatusHistory.0.serviceChange',
      type: 'select',
      values: ['In service', 'Temporarily out of service', 'Removed', 'Abandoned in place'],
      cols: 4,
      editable: true,
      error: false,
      required: true,
      display: true,
    },
    { id: 3,
      name: 'comment',
      label: 'Comment',
      path: 'operationalStatusHistory.0.comment',
      type: 'text',
      cols: 4,
      editable: true,
      error: false,
      required: true,
      display: true,
    },
  ],
};

export default Status;
