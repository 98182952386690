import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TankRegistrationIcon from '../../../components/TankRegistrationIcon';
import PlaceholderTankIcon from '../../../components/PlaceholderTankIcon';
import MaterialText from '../../../components/MaterialText';
import WallType from './WallType';
import OperationalStatus from './OperationalStatus';

class Registration extends Component {

  static renderIcon(registration) {
    if (registration && registration.tank) {
      const text = `T${registration.tank.tankNumber}`;
      return <TankRegistrationIcon registration={registration} text={text} />;
    }
    return <PlaceholderTankIcon />;
  }

  static renderAtgTankNumber(registration) {
    if (registration && registration.tank && registration.tank.tankNumber) {
      return (
        <div>
          <strong>ATG Tank {registration.tank.tankNumber}</strong>
        </div>
      );
    }
    return null;
  }

  static renderRegistrationNumber(registration) {
    const registrationNumber = (registration && registration.tankRegistrationNumber) || 'unknown';
    return (
      <div>
        <strong>Registration Number: {registrationNumber}</strong>
      </div>
    );
  }

  static renderProduct(registration) {
    let text = 'Unknown product';
    if (registration && registration.productHistory && registration.productHistory.length) {
      text = registration.productHistory[0].product;
    }
    return (
      <div>
        <span>{text}</span>
      </div>
    );
  }

  static renderVolume(registration) {
    let text = 'Unknown volume';
    if (registration && registration.nominalSizeGallons) {
      text = `${registration.nominalSizeGallons.toLocaleString()} gallons `;
    }
    return (
      <div>
        <span>{text}</span>
      </div>
    );
  }

  render() {
    let className = 'd-flex flex-row align-items-center';
    if (this.props.className) {
      className += ` ${this.props.className}`;
    }
    return (
      <div className={className} style={this.props.style}>
        <div style={{ minWidth: 100, maxWidth: 125 }}>
          {Registration.renderIcon(this.props.registration)}
        </div>
        <div className="ml-2">
          {Registration.renderAtgTankNumber(this.props.registration)}
          {Registration.renderRegistrationNumber(this.props.registration)}
          <div>
            <OperationalStatus registration={this.props.registration} />
          </div>
          {Registration.renderProduct(this.props.registration)}
          {Registration.renderVolume(this.props.registration)}
          <div>
            <MaterialText registration={this.props.registration} />
          </div>
          <div>
            <WallType registration={this.props.registration} />
          </div>
        </div>
      </div>
    );
  }
}

Registration.propTypes = {
  registration: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

Registration.defaultProps = {
  className: '',
  style: {},
};

export default Registration;
