import ActionTypes from './GroupEditorActionTypes';

export default function GroupEditorReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.SELECT_GROUP:
      return Object.assign({}, state, {
        selectedGroup: action.group,
        editedGroup: action.group,
      });
    case ActionTypes.EDIT_SELECTED_GROUP:
      return Object.assign({}, state, {
        editedGroup: action.group,
      });
    case ActionTypes.CANCEL_SELECTED_GROUP_EDIT:
      return Object.assign({}, state, {
        editedGroup: state.selectedGroup,
      });
    default:
      return state;
  }
}
