import React from 'react';
import PropTypes from 'prop-types';
import { Column, MonthYearColumn, StatefulTable, TimestampColumn } from '../../../../../../../shared/components/table';
import MviWorkflowStatus from '../../../../../../../shared/components/mvi/MviWorkflowStatus';

const SiteMviTable = ({ mvis, onClick }) => {
  return (
    <StatefulTable
      data={mvis}
      initialSortColumn="workflowStatusDate"
      initialSortDirection="desc"
      onRowClick={onClick}
    >
      <MonthYearColumn property="monthStartDate" title="Month" width="10rem" />
      <Column columnId="workflowStatus" render={mvi => <MviWorkflowStatus workflowStatus={mvi.workflowStatus} className="d-inline-block" />} title="Status" sortable width="16rem" />
      <TimestampColumn property="workflowStatusDate" title="Updated" sortable width="13rem" />
    </StatefulTable>
  );
};

SiteMviTable.propTypes = {
  mvis: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SiteMviTable;
