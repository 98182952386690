import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, NavLink, Redirect, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import InvDashboardContainer from '../screens/inv-dashboard/containers/InvDashboardContainer';
import ExportHistoryTable from '../screens/export-history/containers/ExportHistoryTable';
import FileFormatTable from '../screens/file-formats/containers/FileFormatTable';
import FileFormatEditor from '../screens/file-formats/containers/FileFormatEditor';
import FuelHaulerTable from '../screens/fuel-haulers/containers/FuelHaulerTable';
import FuelHaulerEditor from '../screens/fuel-haulers/containers/FuelHaulerEditor';

class InventoryContainer extends Component {

  renderContent() {
    return (
      <Switch>
        <Redirect exact="exact" from="/admin/inventory/" to={`${this.props.match.url}/dashboard`} />
        <Route exact path="/admin/inventory/dashboard" name="invDashboard">
          <InvDashboardContainer />
        </Route>
        <Route exact path="/admin/inventory/history" name="exportHistoryTable">
          <ExportHistoryTable />
        </Route>
        <Route exact path="/admin/inventory/file-formats" name="fileFormatTable">
          <FileFormatTable />
        </Route>
        <Route exact path="/admin/inventory/file-formats/:formatId" name="fileFormatEdit">
          <FileFormatEditor />
        </Route>
        <Route exact path="/admin/inventory/fuel-haulers" name="fuelHaulerTable">
          <FuelHaulerTable />
        </Route>
        <Route exact path="/admin/inventory/fuel-haulers/:fuelHaulerId" name="fuelHaulerEdit">
          <FuelHaulerEditor />
        </Route>
      </Switch>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-2">
          <div className="nav flex-column nav-pills">
            <NavLink to={`${this.props.match.url}/dashboard`} className="nav-link">Export Dashboard</NavLink>
            <NavLink to={`${this.props.match.url}/history`} className="nav-link">Export History</NavLink>
            <NavLink to={`${this.props.match.url}/file-formats`} className="nav-link">File Formats</NavLink>
            <NavLink to={`${this.props.match.url}/fuel-haulers`} className="nav-link">Fuel Haulers</NavLink>
          </div>
        </div>
        <div className="col-md-10">
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

InventoryContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

InventoryContainer.defaultProps = {
};

function mapStateToProps(state) {
  return {

    // HACK: Force the page to update on navigate
    router: state.router,
  };
}

export default connect(mapStateToProps)(withRouter(InventoryContainer));
