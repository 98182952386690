export default {
  GET_FUEL_HAULER_LIST_STARTED: 'admin/get_fuel_hauler_list_started',
  GET_FUEL_HAULER_LIST_FAILED: 'admin/get_fuel_hauler_list_failed',
  RECEIVE_FUEL_HAULER_LIST: 'admin/receive_fuel_hauler_list',

  GET_FUEL_HAULER_STARTED: 'admin/get_fuel_hauler_started',
  GET_FUEL_HAULER_FAILED: 'admin/get_fuel_hauler_failed',
  RECEIVE_FUEL_HAULER: 'admin/receive_fuel_hauler',

  GET_EXPORT_PREVIEW_STARTED: 'admin/get_export_preview_started',
  GET_EXPORT_PREVIEW_FAILED: 'admin/get_export_preview_failed',
  RECEIVE_EXPORT_PREVIEW: 'admin/receive_export_preview',

  RECEIVE_VALIDATION_ERRORS: 'admin/receive_validation_errors',
  SAVE_FUEL_HAULER_STARTED: 'admin/save_fuel_hauler_started',
  SAVE_FUEL_HAULER_SUCCESS: 'admin/save_fuel_hauler_success',
  SAVE_FUEL_HAULER_FAILED: 'admin/save_fuel_hauler_failed',

  DELETE_FUEL_HAULER_STARTED: 'admin/delete_fuel_hauler_started',
  DELETE_FUEL_HAULER_COMPLETE: 'admin/delete_fuel_hauler_complete',
};
