export default {
  REFRESH_LIST_STARTED: 'mvis/refresh_list_started',
  REFRESH_LIST_CANCELED: 'mvis/refresh_list_canceled',
  REFRESH_LIST_FAILED: 'mvis/refresh_list_failed',

  GET_LIST_STARTED: 'mvis/list/get_list_started',
  RECEIVE_LIST: 'mvis/list/receive_list',
  GET_LIST_FAILED: 'mvis/list/get_list_failed',

  CHANGE_SORT: 'mvis/list/change_sort',
  CHANGE_PAGE: 'mvis/list/change_page',

  // view manager actions
  GET_VIEWS_STARTED: 'mvis/get_views_started',
  RECEIVE_VIEWS: 'mvis/receive_views',
  GET_VIEWS_FAILED: 'mvis/get_views_failed',
  SELECT_VIEW: 'mvis/select_view',
  TOGGLE_DEFAULT_VIEW: 'mvis/toggle_default_view',
  SAVE_VIEW_STARTED: 'mvis/save_view_started',
  SAVE_VIEW_SUCCESS: 'mvis/save_view_success',
  SAVE_VIEW_FAILED: 'mvis/save_view_failed',
  DELETE_VIEW_STARTED: 'mvis/delete_view_started',
  DELETE_VIEW_SUCCESS: 'mvis/delete_view_success',
  DELETE_VIEW_FAILED: 'mvis/delete_view_failed',
  START_FILTER_EDIT: 'mvis/start_filter_edit',
  CANCEL_FILTER_EDIT: 'mvis/cancel_filter_edit',
  CLEAR_FILTERS: 'mvis/clear_filters',
  SELECT_CUSTOMERS: 'mvis/select_customers',
  DESELECT_CUSTOMER: 'mvis/deselect_customer',
  SELECT_SITE_STATES: 'mvis/select_site_states',
  DESELECT_SITE_STATE: 'mvis/deselect_site_state',
  GET_SITE_LABELS_STARTED: 'mvis/get_site_labels_started',
  RECEIVE_SITE_LABELS: 'mvis/receive_site_labels',
  GET_SITE_LABELS_FAILED: 'mvis/get_site_labels_failed',
  SELECT_SITE_LABELS: 'mvis/select_site_labels',
  DESELECT_SITE_LABEL: 'mvis/deselect_site_label',
  SELECT_INSPECTION_STATUSES: 'mvis/select_inspection_statuses',
  DESELECT_INSPECTION_STATUS: 'mvis/deselect_inspection_status',
  GET_MONTHS_STARTED: 'mvis/get_months_started',
  RECEIVE_MONTHS: 'mvis/receive_months',
  GET_MONTHS_FAILED: 'mvis/get_months_failed',
  SET_MONTH_FILTER: 'mvis/set_month_filter',
  SELECT_SITES: 'mvis/select_sites',
  DESELECT_SITE: 'mvis/deselect_sites',
  SET_COLUMNS: 'mvis/set_columns',
};
