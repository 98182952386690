import FileSaver from 'file-saver';

function saveAsFile(content, filename, successAction) {
  return (dispatch) => {
    dispatch({ type: successAction });
    return FileSaver.saveAs(new File([content], filename, { type: content.type }));
  };
}

export default {
  saveAsFile,
};
