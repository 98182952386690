import Selectors from '../../../../../../shared/redux-helpers/Selectors';
import { MviWorkflowStatusTypes, SortDirection } from '../../../../../../AppConstants';
import { buildStringSort, withDirection } from '../../../../../../shared/SortUtils';

const getState = state => (state && state.sites && state.sites.mvi && state.sites.mvi.list) || {};

const sortMonthDesc = withDirection(buildStringSort('monthStart'))(SortDirection.Desc);

class SiteMviListSelectors extends Selectors {
  constructor() {
    super(getState);
  }

  incompleteMvis(state) {
    const incomplete = super.list(state).filter(current => current.workflowStatus === MviWorkflowStatusTypes.Pending || current.workflowStatus === MviWorkflowStatusTypes.ReadyForReview);
    sortMonthDesc([...incomplete]);
    return incomplete;
  }

  completedMvis(state) {
    const completed = super.list(state).filter(current => current.workflowStatus !== MviWorkflowStatusTypes.Pending && current.workflowStatus !== MviWorkflowStatusTypes.ReadyForReview);
    sortMonthDesc([...completed]);
    return completed;
  }
}

export default new SiteMviListSelectors();
