import ActionTypes from './ProblemListActionTypes';

export default function AdminReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_PROBLEMATIC_SITES_STARTED:
      return Object.assign({}, state, {
        problematicSites: [],
        problematicSitesLoading: true,
      });
    case ActionTypes.RECEIVE_PROBLEMATIC_SITES:
      return Object.assign({}, state, {
        problematicSites: action.problematicSites,
        problematicSitesLoading: false,
      });
    case ActionTypes.GET_PROBLEMATIC_SITES_FAILED:
      return Object.assign({}, state, {
        problematicSites: [],
        problematicSitesLoading: false,
      });
    default:
      return state;
  }
}
