import React, { Component } from 'react';
import { Form, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper/index';
import { connect } from 'react-redux';

import { AuthTypes, DeliveryTypes } from '../ExportDestinationConstants';
import * as selectors from '../ExportDestinationSelectors';
import * as actions from '../actions';


class ExportDestination extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.handleDeliveryTypeChange = this.buildSetPropFunction('deliveryType');
    this.handleEmailChanged = this.buildSetPropFunction('deliveryEmail');
    this.handleHostnameChanged = this.buildSetPropFunction('deliveryHostname');
    this.handleUsernameChanged = this.buildSetPropFunction('deliveryUsername');
    this.handlePasswordChanged = this.buildSetPropFunction('deliveryPassword');
    this.handlePortChanged = this.buildSetPropFunction('deliveryPort');
    this.handleDirectoryChanged = this.buildSetPropFunction('deliveryDirectory');
    this.handleAuthTypeSelect = this.handleAuthTypeSelect.bind(this);
  }

  buildSetPropFunction(propName) {
    function func(event) {
      const updateSpec = {};
      let newValue;
      if (event.target.type === 'checkbox') {
        newValue = event.target.checked;
      }
      else {
        newValue = event.target.value || '';
      }
      updateSpec[propName] = {
        $set: newValue,
      };
      const updatedFuelHauler = update(this.props.urlParts, updateSpec);
      this.props.updateUrlParts(updatedFuelHauler);
    }

    return func.bind(this);
  }

  handleAuthTypeSelect(authType) {
    const updateSpec = {
      deliveryPublicKey: {
        $set: authType === AuthTypes.PublicKey,
      },
    };
    if (authType === AuthTypes.PublicKey) {
      updateSpec.deliveryPassword = {
        $set: '',
      };
    }
    const updated = update(this.props.urlParts, updateSpec);
    this.props.updateUrlParts(updated);
  }

  render() {
    if (!this.props.urlParts) {
      return null;
    }

    return (
      <Row className="mt-1">
        <Col xs={12}>
          <Form>
            <FormGroup row>
              <Label for="deliveryType" sm={4}>Delivery Type</Label>
              <Col sm={6}>
                <Input
                  type="select"
                  id="deliveryType"
                  name="deliveryType"
                  value={this.props.urlParts.deliveryType}
                  onChange={this.handleDeliveryTypeChange}
                >
                  <option value={DeliveryTypes.Email}>{DeliveryTypes.Email}</option>
                  <option value={DeliveryTypes.FTP}>{DeliveryTypes.FTP}</option>
                  <option value={DeliveryTypes.SFTP}>{DeliveryTypes.SFTP}</option>
                </Input>
              </Col>
            </FormGroup>
            { this.props.isEmail ?
              <FormGroup row>
                <Label for="email" sm={4}>Email Address</Label>
                <Col sm={6}>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="inventory@fuelhauler.com"
                    value={this.props.urlParts.deliveryEmail || ''}
                    onChange={this.handleEmailChanged}
                  />
                </Col>
              </FormGroup>
              :
              null
            }
            { this.props.isFtpOrSftp ?
              <FormGroup row>
                <Label for="hostname" sm={4}>{this.props.urlParts.deliveryType} Server</Label>
                <Col sm={6}>
                  <Input
                    type="text"
                    id="hostname"
                    name="hostname"
                    placeholder={`${this.props.urlParts.deliveryType.toLowerCase()}.fuelhauler.com`}
                    value={this.props.urlParts.deliveryHostname || ''}
                    onChange={this.handleHostnameChanged}
                  />
                </Col>
              </FormGroup>
              :
              null
            }
            { this.props.isFtpOrSftp ?
              <FormGroup row>
                <Label for="port" sm={4}>Port</Label>
                <Col sm={6}>
                  <Input
                    type="number"
                    id="port"
                    name="port"
                    placeholder="TCP port"
                    value={this.props.urlParts.deliveryPort || ''}
                    onChange={this.handlePortChanged}
                  />
                </Col>
              </FormGroup>
              :
              null
            }
            { this.props.isFtpOrSftp ?
              <FormGroup row>
                <Label for="hostname" sm={4}>Username</Label>
                <Col sm={6}>
                  <Input
                    type="text"
                    id="hostname"
                    name="hostname"
                    placeholder="canary"
                    value={this.props.urlParts.deliveryUsername || ''}
                    onChange={this.handleUsernameChanged}
                  />
                </Col>
              </FormGroup>
              :
              null
            }
            { this.props.isFtp ?
              <FormGroup row>
                <Label for="password" sm={4}>Password</Label>
                <Col sm={6}>
                  <Input
                    type="text"
                    id="password"
                    name="password"
                    placeholder="Pa55word"
                    value={this.props.urlParts.deliveryPassword || ''}
                    onChange={this.handlePasswordChanged}
                  />
                </Col>
              </FormGroup>
              :
              null
            }
            { this.props.isSftp ?
              <Row>
                <Col sm={4}>
                  <span>Authentication</span>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="authRadio"
                          checked={!this.props.isSftpKeyAuth}
                          onChange={() => this.handleAuthTypeSelect(AuthTypes.Password)}
                        />
                        {' '}
                        Authenticate using a password
                      </Label>
                      <Input
                        type="text"
                        id="password"
                        name="password"
                        placeholder="Pa55word"
                        value={this.props.urlParts.deliveryPassword || ''}
                        onChange={this.handlePasswordChanged}
                        disabled={this.props.isSftpKeyAuth}
                      />
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="authRadio"
                          checked={this.props.isSftpKeyAuth}
                          onChange={() => this.handleAuthTypeSelect(AuthTypes.PublicKey)}
                        />
                        {' '}
                        Authenticate using the Canary public SSH key
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
              :
              null
            }
            { this.props.isFtpOrSftp ?
              <FormGroup row>
                <Label for="name" sm={4}>Upload Directory</Label>
                <Col sm={6}>
                  <Input
                    type="text"
                    id="directory"
                    name="directory"
                    placeholder="/path/to/dir"
                    value={this.props.urlParts.deliveryDirectory || ''}
                    onChange={this.handleDirectoryChanged}
                  />
                </Col>
              </FormGroup>
              :
              null
            }
          </Form>
        </Col>
      </Row>
    );
  }
}

ExportDestination.propTypes = {
  urlParts: PropTypes.object.isRequired,
  isEmail: PropTypes.bool.isRequired,
  isFtp: PropTypes.bool.isRequired,
  isSftp: PropTypes.bool.isRequired,
  isFtpOrSftp: PropTypes.bool.isRequired,
  isSftpKeyAuth: PropTypes.bool.isRequired,

  updateUrlParts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    urlParts: selectors.urlParts(state),
    isEmail: selectors.isEmail(state),
    isFtp: selectors.isFtp(state),
    isSftp: selectors.isSftp(state),
    isFtpOrSftp: selectors.isFtpOrSftp(state),
    isSftpKeyAuth: selectors.isSftpKeyAuth(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUrlParts: parts => dispatch(actions.updateUrlParts(parts)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportDestination);
