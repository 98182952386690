export default {

  SET_USER_ID: 'preferences/set_user_id',

  // Sites for customers -- consider moving to another part of state tree
  GET_CUSTOMER_SITES_STARTED: 'preferences/get_customer_sites',
  RECEIVE_CUSTOMER_SITES: 'preferences/receive_customer_sites',
  GET_CUSTOMER_SITES_FAILED: 'preferences/get_customer_sites_failed',

  // Issue notification rules
  GET_USER_NOTIFICATION_RULE_STARTED: 'preferences/get_user_notification_rule_started',
  RECEIVE_USER_NOTIFICATION_RULE: 'preferences/receive_user_notification_rule',
  GET_USER_NOTIFICATION_RULE_FAILED: 'preferences/get_user_notification_rule_failed',
  SET_NOTIFICATION_TOPIC_CONTEXT: 'preferences/set_notification_topic_context',
  SAVE_USER_NOTIFICATION_RULE_STARTED: 'preferences/save_user_notification_rule_started',
  SAVE_USER_NOTIFICATION_RULE_SUCCESS: 'preferences/save_user_notification_rule_success',
  SAVE_USER_NOTIFICATION_RULE_FAILED: 'preferences/save_user_notification_rule_failed',
  RESET_SELECTED_ISSUE_NOTIFICATION_RULE_TO_DEFAULT: 'preferences/reset_selected_issue_notification_rule_to_default',
  DISABLE_SELECTED_ISSUE_NOTIFICATION_RULE: 'preferences/disable_selected_issue_notification_rule',

  // Low product notification rules
  GET_USER_NOTIFICATION_LOW_PRODUCT_RULE_STARTED: 'preferences/get_user_notification_low_product_rule_started',
  RECEIVE_USER_NOTIFICATION_LOW_PRODUCT_RULE: 'preferences/receive_user_notification_low_product_rule',
  GET_USER_NOTIFICATION_LOW_PRODUCT_RULE_FAILED: 'preferences/get_user_notification_low_product_rule_failed',
  SET_LOW_PRODUCT_NOTIFICATION_TOPIC_CONTEXT: 'preferences/set_low_product_notification_topic_context',
  SAVE_USER_NOTIFICATION_LOW_PRODUCT_RULE_STARTED: 'preferences/save_user_notification_low_product_rule_started',
  SAVE_USER_NOTIFICATION_LOW_PRODUCT_RULE_SUCCESS: 'preferences/save_user_notification_low_product_rule_success',
  SAVE_USER_NOTIFICATION_LOW_PRODUCT_RULE_FAILED: 'preferences/save_user_notification_low_product_rule_failed',

  // Change email
  SET_UPDATED_EMAIL: 'preferences/set_updated_email',
  RESET_UPDATED_EMAIL: 'preferences/reset_updated_email',
  SAVE_UPDATED_EMAIL_STARTED: 'preferences/save_updated_email_started',
  SAVE_UPDATED_EMAIL_SUCCESS: 'preferences/save_updated_email_success',
  SAVE_UPDATED_EMAIL_FAILED: 'preferences/save_updated_email_failed',

  // Change password
  UPDATE_PASSWORD_CONTEXT: 'preferences/update_password_context',
  CHANGE_PASSWORD_STARTED: 'preferences/change_password_started',
  CHANGE_PASSWORD_SUCCESS: 'preferences/change_password_success',
  CHANGE_PASSWORD_FAILED: 'preferences/change_password_failed',
  CANCEL_CHANGE_PASSWORD: 'preferences/cancel_change_password',
};
