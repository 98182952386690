import React from 'react';
import PropTypes from 'prop-types';

import { timestampAsMonthYear } from './FormatUtils';

const MonthYear = ({ time, zone }) => <span>{timestampAsMonthYear(time, zone)}</span>;

MonthYear.propTypes = {
  time: PropTypes.any,
  zone: PropTypes.string,
};

MonthYear.defaultProps = {
  time: null,
  zone: null,
};

export default MonthYear;
