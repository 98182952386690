import AuthenticationActionTypes from './AuthenticationActionTypes';
import AuthenticationStorage from './AuthenticationStorage';

const emptyState = Object.freeze({
  userId: null,
  username: null,
  email: null,
  token: null,
  customers: null,
  permissions: null,
});

const defaultState = Object.freeze({
  userId: AuthenticationStorage.getUserId(),
  token: AuthenticationStorage.getToken(),
  loginInProgress: !!AuthenticationStorage.getToken(),
});

export default function AuthenticationReducer(state = defaultState, action) {
  switch (action.type) {
    case AuthenticationActionTypes.LOGIN:
      AuthenticationStorage.reset();
      return Object.assign({}, state, emptyState, { loginInProgress: true });
    case AuthenticationActionTypes.LOGOUT:
      AuthenticationStorage.reset();
      return Object.assign({}, state, emptyState, { loginInProgress: false });
    case AuthenticationActionTypes.LOGIN_SUCCESS:
    case AuthenticationActionTypes.LOGIN_REFRESH:
      AuthenticationStorage.setUserId(action.userId);
      AuthenticationStorage.setToken(action.token);

      return Object.assign({}, state, {
        loginInProgress: false,
        userId: action.userId,
        username: action.username,
        email: action.email,
        token: action.token,
        permissions: action.permissions,
      });
    case AuthenticationActionTypes.SET_LOGIN_REDIRECT_PATH:
      return Object.assign({}, state, {
        loginRedirectPath: action.redirectPath,
      });
    case AuthenticationActionTypes.UPDATE_EMAIL:
      return Object.assign({}, state, {
        email: action.email,
      });
    case AuthenticationActionTypes.LOGIN_FAILURE:
      return Object.assign({}, state, {
        loginInProgress: false,
        error: action.error,
        isError: true,
      });
    case AuthenticationActionTypes.CLEAR_LOGIN_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        isError: false,
      });
    default:
      return state;
  }
}
