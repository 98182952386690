import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MviReportStatusTypes } from '../../../../../../../../../AppConstants';
import AcknowledgeReportStatus from './AcknowledgeReportStatus';
import ActionItem from './ActionItem';
import ActionItemEdit from './ActionItemEdit';
import NewActionItem from './NewActionItem';
import ActionItemDeleteModal from './ActionDeleteModal';
import * as SiteSelectors from '../../../../../../../SiteSelectors';
import * as MviEditUberSelectors from '../../../MviEditUberSelectors';
import MviReportSelectors from '../MviReportSelectors';
import * as MviReportActions from '../MviReportActions';
import * as ActionItemSelectors from './ActionItemsSelectors';
import * as ActionItemsActions from './ActionItemsActions';
import {track} from '../../../../../../../../../shared/analytics';

const ActionItemsList = ({
  report,
  canEdit,
  editDisabled,
  isEditingActionItem,
  selectedActionItem,
  markReportReviewed,
  selectExistingForEdit,
  editActionItem,
  cancel,
  saveChanges,
  openConfirmDeleteModal
}) => {
  const actionItems = report.actionItems || [];
  if (!actionItems.length && report.status === MviReportStatusTypes.Reviewed) {
    return (
      <>
        <div className="border rounded-lg p-3 mt-1">
          <span>There are no action items.</span>
        </div>
        <NewActionItem className="mt-2" />
      </>
    );
  }

  const displayOrEdit = actionItem => {
    if ((isEditingActionItem && actionItem.actionItemNumber === selectedActionItem.actionItemNumber)) {
      return <ActionItemEdit key={selectedActionItem.actionItemNumber} actionItem={selectedActionItem} onEdit={editActionItem} onCancel={cancel} onSave={saveChanges} className="mb-2" />;
    }
    return (
      <ActionItem
        key={actionItem.actionItemNumber}
        actionItem={actionItem}
        canEdit={canEdit}
        editDisabled={editDisabled}
        onBeginEdit={selectExistingForEdit}
        onDelete={openConfirmDeleteModal}
        className="mb-2"
      />
    );
  };

  return (
    <>
      <AcknowledgeReportStatus report={report} canEdit={canEdit} editDisabled={editDisabled} onClose={markReportReviewed} />
      {actionItems.map(displayOrEdit)}
      <NewActionItem className="mt-2" />
      <ActionItemDeleteModal />
    </>
  );
};

ActionItemsList.propTypes = {
  report: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  isEditingActionItem: PropTypes.bool.isRequired,
  selectedActionItem: PropTypes.object,
  markReportReviewed: PropTypes.func.isRequired,
  selectExistingForEdit: PropTypes.func.isRequired,
  editActionItem: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
  openConfirmDeleteModal: PropTypes.func.isRequired,
};

ActionItemsList.defaultProps = {
  selectedActionItem: null,
};

function mapStateToProps(state) {
  return {
    report: MviReportSelectors.item(state),
    canEdit: SiteSelectors.canEditMvis(state),
    editDisabled: MviEditUberSelectors.isEditDisabled(state),
    isEditingActionItem: ActionItemSelectors.isEditing(state),
    selectedActionItem: ActionItemSelectors.selectedActionItem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    markReportReviewed: () => dispatch(MviReportActions.markReportReviewed()),
    selectExistingForEdit: actionItem => {
      track('Inspection', {
        Component: 'Action items',
        'Inspection action': 'Edit action item',
        'New action item': false,
      });
      return dispatch(ActionItemsActions.selectExistingForEdit(actionItem));
    },
    editActionItem: actionItem => dispatch(ActionItemsActions.editActionItem(actionItem)),
    cancel: () => dispatch(ActionItemsActions.cancel()),
    saveChanges: () => {
      track('Inspection', {
        Component: 'Action items',
        'Inspection action': 'Save action item',
        'New action item': false,
      });
      return dispatch(ActionItemsActions.saveChanges());
    },
    openConfirmDeleteModal: actionItem => dispatch(ActionItemsActions.openConfirmDeleteModal(actionItem)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionItemsList);
