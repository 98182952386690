import colors from './colors';

export const SortDirection = {
  Asc: 'asc',
  Desc: 'desc',
};

export const SystemUserId = 1;
export const SystemOrgId = 1;

export const UserNavType = {
  SiteList: 'Sites',
  IssueList: 'Issues',
  ComplianceList: 'Compliance',
  MviList: 'MVIs',
  InventoryList: 'Inventory',
};

export const Features = {
  AlarmManagement: 'Alarm Management',
  Compliance: 'Compliance',
  Inventory: 'Inventory',
  MonthlyVisualInspections: 'Monthly Visual Inspections',
};

export const MviFeatureLevels = {
  BasicMVIs: 'Basic MVIs',
  MVIForm: 'MVI Form',
};

export const CommandRequestStatus = {
  Pending: 'Pending',
  Resolved: 'Resolved',
  Failed: 'Failed',
};

export const InternalRegulatoryStatus = {
  PASSED: 'PASSED',
  LATE: 'LATE',
  INCONCLUSIVE: 'INCONCLUSIVE',
  FAILED: 'FAILED',
};

export const RegulatoryStatus = {
  PASSED: {
    text: 'Passed',
    color: colors.good,
    internalStatuses: [InternalRegulatoryStatus.PASSED, InternalRegulatoryStatus.LATE],
  },
  INCONCLUSIVE: {
    text: 'Inconclusive',
    color: colors.neutral,
    internalStatuses: [InternalRegulatoryStatus.INCONCLUSIVE],
  },
  FAILED: {
    text: 'Not Passing',
    color: colors.bad,
    internalStatuses: [InternalRegulatoryStatus.FAILED],
  },
};

export const AlarmManagementFeatureLevels = {
  BasicAlarmManagement: 'Basic Alarm Management',
  ReportOnlyAlarmManagement: 'Report only Alarm Management',
  AutomatedAlarmManagement: 'Automated Alarm Management',
};

export const SiteConnectionTypes = {
  TCP: 'TCP',
  PARTICLE: 'PARTICLE',
};

export const IssuePriorities = {
  EMERGENCY: 'Emergency',
  NORMAL: 'Normal',
  LOW: 'Low',
};

export const IssuePriorityLabels = {
  [IssuePriorities.LOW]: 'Low',
  [IssuePriorities.NORMAL]: 'Medium',
  [IssuePriorities.EMERGENCY]: 'High',
};

export const IssuePriorityValue = {
  LOW: 1,
  NORMAL: 2,
  EMERGENCY: 3,
};

export const WorkflowStatusTypes = {
  NewUnread: 'New Unread',
  New: 'New',
  Draft: 'Draft',
  AwaitingApprovalUnread: 'Awaiting Approval Unread',
  AwaitingApproval: 'Awaiting Approval',
  PendingResolution: 'Pending Resolution',
  Resolved: 'Resolved',
  Deleted: 'Deleted',
};

export const FriendlyWorkflowStatusTypes = {
  Analyzing: {
    name: 'Analyzing',
    workflowStatusTypes: [WorkflowStatusTypes.NewUnread, WorkflowStatusTypes.New, WorkflowStatusTypes.Draft],
  },
  New: {
    name: 'New',
    workflowStatusTypes: [WorkflowStatusTypes.AwaitingApprovalUnread, WorkflowStatusTypes.AwaitingApproval],
  },
  PendingResolution: {
    name: 'Pending Resolution',
    workflowStatusTypes: [WorkflowStatusTypes.PendingResolution],
  },
  Resolved: {
    name: 'Resolved',
    workflowStatusTypes: [WorkflowStatusTypes.Resolved],
  }
};

export const IssueTypes = {
  AtgColdStart: 'ATG Cold Start',
  TechOnSite: 'Tech on Site?',
  AtgConfiguration: 'ATG Configuration Problem',
  TankProbeFault: 'Tank Probe Fault',
  SensorFault: 'Sensor Fault',
  LineCommunicationFault: 'Line Communication Fault',
  LineSTPFault: 'STP or STP Wiring Fault',
  LineDispenserFault: 'Dispenser Fault',
  LineEquipmentFault: 'Line Equipment Fault',
  LineGrossFailure: 'Line Failure',
  TankLeakTestFailure: 'Tank Leak Test Failure',
  SensorLiquid: 'Sensor Liquid Alarm',
  LineLeakTestFailure: 'Line Leak Test Failure',
  TankLeakTestWarning: 'Tank Leak Test Warning',
  TankOverfill: 'Overfill',
  TankLowProduct: 'Low Product',
  TankWaterProblem: 'Water Problem',
  LineLeakTestWarning: 'Line Leak Test Needed Warning',
  DispenserInterfaceProblem: 'Dispenser Interface Problem',
  AutodialError: 'Legacy Communication Problem',
  PrinterProblem: 'Printer Problem',
  Legacy: 'Legacy issue',
  MonthlyVisualInspection: 'Monthly Visual Inspection',
  Unknown: 'Unknown issue',
};

export const DispatchStatuses = Object.freeze({
  CREATED: 'Created',
  TECH_NOTES_RECEIVED: 'Tech. Notes Received',
  EXPIRED: 'Expired',
  CLOSED: 'Closed',
});

export const SiteConnectionStatus = Object.freeze({
  OK: 'OK',
  Unstable: 'Unstable',
  Disconnected: 'Disconnected',
});

export const MviWorkflowStatusTypes = Object.freeze({
  Pending: 'Pending',
  ReadyForReview: 'Ready for review',
  Completed: 'Completed',
  NotRequired: 'Not required',
  NotPerformed: 'Not performed',
});

export const MviReportStatusTypes = Object.freeze({
  ReceivedNoActionItems: 'Received no action items',
  ReceivedWithActionItems: 'Received w/action items',
  ReceivedInconclusive: 'Received inconclusive',
  Reviewed: 'Reviewed',
});

export const InventoryStatus = {
  GreaterThanSeventyFivePercent: '75% or more full',
  LessThanSeventyFivePercent: 'Less than 75% full',
  DeliveryNeeded: 'Delivery needed',
  Unknown: 'Unknown'
};

export const USStates = Object.freeze([
  'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'FM', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS',
  'KY', 'LA', 'MA', 'MD', 'ME', 'MH', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY',
  'OH', 'OK', 'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'
]);

export const USStateAbbreviations = Object.freeze({
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  AS: 'American Samoa',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  FM: 'Micronesia',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MH: 'Marshall Islands',
  MI: 'Michigan',
  MN: 'Minnesota',
  MP: 'Northern Mariana Islands',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  PW: 'Palau',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VI: 'US Virgin Islands',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
});

export const TimezonesByUSState = {
  AL: 'America/Chicago',
  AK: 'America/Anchorage',
  AZ: 'America/Phoenix',
  AR: 'America/Chicago',
  CA: 'America/Los_Angeles',
  CO: 'America/Denver',
  CT: 'America/New_York',
  DE: 'America/New_York',
  GA: 'America/New_York',
  HI: 'Pacific/Honolulu',
  IL: 'America/Chicago',
  IA: 'America/Chicago',
  LA: 'America/Chicago',
  ME: 'America/New_York',
  MD: 'America/New_York',
  MA: 'America/New_York',
  MI: 'America/New_York',
  MN: 'America/Chicago',
  MS: 'America/Chicago',
  MO: 'America/Chicago',
  MT: 'America/Denver',
  NH: 'America/New_York',
  NJ: 'America/New_York',
  NM: 'America/Denver',
  NY: 'America/New_York',
  NC: 'America/New_York',
  OH: 'America/New_York',
  OK: 'America/Chicago',
  PA: 'America/New_York',
  PR: 'America/Puerto_Rico',
  RI: 'America/New_York',
  SC: 'America/New_York',
  UT: 'America/Denver',
  VT: 'America/New_York',
  VA: 'America/New_York',
  WA: 'America/Los_Angeles',
  DC: 'America/New_York',
  WV: 'America/New_York',
  WI: 'America/Chicago',
  WY: 'America/Denver'
};
