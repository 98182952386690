import { Get } from '../HttpUtils';

export default {
  getHourlyHistory() {
    return Get('/FuelHaulerExports/history').then(response => response.data);
  },

  getFuelHaulerHealth() {
    return Get('/FuelHaulers/health').then(response => response.data);
  },
};
