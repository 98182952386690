import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons';

import { track } from '../../analytics';
import FontAwesomeButton from '../buttons/FontAwesomeButton';
import * as actions from './NavContextActions';
import * as selectors from './NavContextSelectors';

const ButtonStyle = {
  width: '1.5em',
  height: '1.5em',
};

const NavContext = ({ index, count, movePrev, moveNext }) => {
  if (!count) {
    return null;
  }

  return (
    <div className="d-flex align-items-center justify-content-between h-100">
      <FontAwesomeButton icon={faArrowCircleLeft} onClick={movePrev} className="mr-3" style={ButtonStyle} disabled={index === 0} />
      <FontAwesomeButton icon={faArrowCircleRight} onClick={moveNext} style={ButtonStyle} disabled={index === count - 1} />
    </div>
  );
};

NavContext.propTypes = {
  index: PropTypes.number,
  count: PropTypes.number,
  movePrev: PropTypes.func.isRequired,
  moveNext: PropTypes.func.isRequired,
};

NavContext.defaultProps = {
  index: null,
  count: null,
};

function mapStateToProps(state) {
  return {
    index: selectors.index(state),
    count: selectors.count(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    movePrev: () => {
      track('Site nav', { Component: 'Site pager', 'Site nav action': 'Next site' });
      return dispatch(actions.movePrev());
    },
    moveNext: () => {
      track('Site nav', { Component: 'Site pager', 'Site nav action': 'Prev site' });
      return dispatch(actions.moveNext());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavContext);
