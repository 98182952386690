import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';

import * as actions from '../actions';
import * as selectors from '../TankListSelectors';
import Registration from '../components/Registration';

function sortRegistrations(first, second) {
  if (first.tank && second.tank) {
    return first.tank.tankNumber - second.tank.tankNumber;
  }
  else if (first.tank && !second.tank) {
    return -1;
  }
  else if (second.tank && !second.tank) {
    return 1;
  }
  return first.tankRegistrationNumber.localeCompare(second.tankRegistrationNumber);
}

class Step2ConfirmMatchesContainer extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.renderRegistration = this.renderRegistration.bind(this);
  }

  renderRegistration(registration) {
    return (
      <div key={registration.id}>
        <Registration registration={registration} />
        <hr />
      </div>
    );
  }

  render() {
    const sortedRegistrations = [...this.props.registrations].sort(sortRegistrations);
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h6>Tank Data Import Summary</h6>
            <hr />
            {sortedRegistrations.map(this.renderRegistration)}
            <div className="d-flex justify-content-center">
              <Button className="mr-1" onClick={() => this.props.cancelConfirmMatches()}>Back to Matching</Button>
              <Button className="ml-1" color="primary" onClick={() => this.props.confirmMatches()}>Confirm</Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

Step2ConfirmMatchesContainer.propTypes = {
  registrations: PropTypes.array.isRequired,
  cancelConfirmMatches: PropTypes.func.isRequired,
  confirmMatches: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    registrations: selectors.getRegistrations(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cancelConfirmMatches: () => dispatch(actions.cancelConfirmMatches()),
    confirmMatches: () => dispatch(actions.confirmMatches()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step2ConfirmMatchesContainer);
