import {NotificationClient} from '../../../../../../../../client';
import * as selectors from '../NotificationLogSelectors';
import NotificationLogConstants from '../NotificationLogConstants';
import ActionTypes from '../NotificationLogActionTypes';

const getEntityNotificationContentStarted = () => ({ type: ActionTypes.GET_ENTITY_NOTIFICATION_CONTENT_STARTED });
const getEntityNotificationContentSuccess = (entityNotificationContent) => ({ type: ActionTypes.GET_ENTITY_NOTIFICATION_CONTENT_SUCCESS, entityNotificationContent });
const getEntityNotificationContentFailed = (error) => ({ type: ActionTypes.GET_ENTITY_NOTIFICATION_CONTENT_FAILED, error });

export const getEntityNotificationContent = (id) => (dispatch, getState) => {
  const status = selectors.entityNotificationContentStatus(getState());

  if (NotificationLogConstants.contentStatus.Loading === status
      || NotificationLogConstants.contentStatus.Loaded === status) {
    return Promise.resolve();
  }

  dispatch(getEntityNotificationContentStarted());
  return NotificationClient.getEntityNotificationContent(id)
    .then((content) => dispatch(getEntityNotificationContentSuccess(content)))
    .catch(err => {
      dispatch(getEntityNotificationContentFailed(err));
      return Promise.reject(err);
    });
};

export const toggleShowEntityNotificationContent = () => (dispatch, getState) => {
  const showContent = selectors.showEntityNotificationContent(getState());
  const actionType = !showContent ? ActionTypes.OPEN_ENTITY_NOTIFICATION_CONTENT : ActionTypes.CLOSE_ENTITY_NOTIFICATION_CONTENT;
  return Promise.resolve(dispatch({ type: actionType }));
};
