import { PreEngineeredCorrosionProtectionType, TankMaterialType, TankWallType } from '../EquipmentConstants';

const helper = {
  isFRPSingleWall(construction) {
    return construction &&
      construction.primaryMaterial === TankMaterialType.FRP &&
      construction.wallType === TankWallType.SingleWall;
  },
  isFRPDoubleWall(construction) {
    return construction &&
      construction.primaryMaterial === TankMaterialType.FRP &&
      construction.wallType === TankWallType.DoubleWall;
  },
  isUL1746Part1(construction) {
    return construction &&
      construction.primaryMaterial === TankMaterialType.Steel &&
      construction.preEngineeredCorrosionProtection === PreEngineeredCorrosionProtectionType.Ul1746PartI;
  },
  isUL1746Part1SingleWall(construction) {
    return helper.isUL1746Part1(construction) && construction.wallType === TankWallType.SingleWall;
  },
  isUL1746Part1DoubleWall(construction) {
    return helper.isUL1746Part1(construction) && construction.wallType === TankWallType.DoubleWall;
  },
  isUL1746Part2(construction) {
    return construction &&
      construction.primaryMaterial === TankMaterialType.Steel &&
      construction.preEngineeredCorrosionProtection === PreEngineeredCorrosionProtectionType.Ul1746PartII;
  },
  isUL1746Part2SingleWall(construction) {
    return helper.isUL1746Part2(construction) && construction.wallType === TankWallType.SingleWall;
  },
  isUL1746Part2DoubleWall(construction) {
    return helper.isUL1746Part2(construction) && construction.wallType === TankWallType.DoubleWall;
  },
  isUL1746Part3(construction) {
    return construction &&
      construction.primaryMaterial === TankMaterialType.Steel &&
      construction.preEngineeredCorrosionProtection === PreEngineeredCorrosionProtectionType.Ul1746PartIII &&
      construction.wallType === TankWallType.DoubleWall;
  },
  isUL1746Part4(construction) {
    return construction &&
      construction.primaryMaterial === TankMaterialType.Steel &&
      construction.preEngineeredCorrosionProtection === PreEngineeredCorrosionProtectionType.Ul1746PartIV;
  },
  isUL1746Part4SingleWall(construction) {
    return helper.isUL1746Part4(construction) && construction.wallType === TankWallType.SingleWall;
  },
  isUL1746Part4DoubleWall(construction) {
    return helper.isUL1746Part2(construction) && construction.wallType === TankWallType.DoubleWall;
  },
  isSteelDoubleWall(construction) {
    return construction &&
      construction.primaryMaterial === TankMaterialType.Steel &&
      construction.wallType === TankWallType.DoubleWall;
  },
  isSteelWithUnknownCorrosionProtection(construction) {
    return construction &&
      construction.primaryMaterial === TankMaterialType.Steel &&
      construction.preEngineeredCorrosionProtection === PreEngineeredCorrosionProtectionType.Unknown;
  },
  isSteelWithNoCorrosionProtection(construction) {
    return construction &&
      construction.primaryMaterial === TankMaterialType.Steel && (
        construction.preEngineeredCorrosionProtection === PreEngineeredCorrosionProtectionType.Unprotected ||
        construction.preEngineeredCorrosionProtection === PreEngineeredCorrosionProtectionType.UnlistedCoating
      );
  },
};

export default helper;
