export default {
  OPEN_MODAL: 'new_issue_modal/open_issue_modal',
  CANCEL_MODAL: 'new_issue_modal/cancel_modal',

  EDIT_ISSUE: 'new_issue_modal/edit_issue',

  CREATE_ISSUE_STARTED: 'new_issue_modal/create_issue_started',
  CREATE_ISSUE_SUCCESS: 'new_issue_modal/create_issue_success',
  CREATE_ISSUE_FAILED: 'new_issue_modal/create_issue_failed',

  DUPLICATE_ISSUE_NAME: 'new_issue_modal/duplicate_issue_name',
};
