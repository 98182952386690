import { push } from 'react-router-redux';
import ActionTypes from '../PreferencesActionTypes';
import { SiteClient, UserClient } from '../../../client';
import { currentUserId, allCustomers } from '../../../authentication/AuthenticationSelectors';
import * as selectors from '../PreferencesSelectors';
import PreferenceConstants, { customerSiteStatuses, emptyArray, notificationEntityTypes } from '../PreferenceConstants';

export const loadPrefsForUser = userId => dispatch => Promise.resolve()
  .then(() => dispatch({ type: ActionTypes.SET_USER_ID, userId }));

export const redirectToPreferences = () => (dispatch) => {
  dispatch(push('/preferences'));
};

const receiveCustomerSites = customerSites => ({
  type: ActionTypes.RECEIVE_CUSTOMER_SITES,
  customerSites,
});

const getCustomerSitesStarted = () => ({ type: ActionTypes.GET_CUSTOMER_SITES_STARTED });
const getCustomerSitesFailed = (error) => ({ type: ActionTypes.GET_CUSTOMER_SITES_STARTED, error });

export const getCustomerSites = ids => (dispatch, getState) => {
  const state = getState();
  const customerSitesStatus = selectors.customerSitesStatus(state);
  const customerSites = selectors.customerSites(state);

  if (customerSiteStatuses.Loading === customerSitesStatus || customerSites.length) {
    return Promise.resolve();
  }

  dispatch(getCustomerSitesStarted());
  if (!ids || !ids.length) {
    ids = allCustomers(state).map(customer => customer.id);
  }
  return SiteClient.getSitesForMultipleCustomers(ids)
    .then((sites) => dispatch(receiveCustomerSites(sites)))
    .catch(err => {
      dispatch(getCustomerSitesFailed(err));
      return Promise.reject(err);
    });
};

const getUserNotificationRuleStarted = () => ({
  type: ActionTypes.GET_USER_NOTIFICATION_RULE_STARTED,
});

const receiveUserNotificationRule = (selectedUserNotificationRule) => ({
  type: ActionTypes.RECEIVE_USER_NOTIFICATION_RULE,
  selectedUserNotificationRule,
});

const receiveUserNotificationRuleFailed = (error) => ({
  type: ActionTypes.GET_USER_NOTIFICATION_RULE_FAILED,
  err: error
});

export function selectUserNotificationRule(customerId) {
  return (dispatch, getState) => {
    const state = getState();
    const isLoading = selectors.isLoadingUserNotificationRule(state);
    if (isLoading) {
      return Promise.resolve();
    }

    const userId = selectors.userId(state);

    if (!Number.parseInt(userId, 10)) {
      return Promise.resolve();
    }

    dispatch(getUserNotificationRuleStarted());
    return UserClient.getUserNotificationRule(userId, customerId, notificationEntityTypes.Issue)
      .then(userNotificationRule => {
        if (userNotificationRule) {
          return dispatch(receiveUserNotificationRule(userNotificationRule));
        }
        return dispatch(disableUserNotificationRule(customerId, false));
      })
      .catch(err => {
        dispatch(receiveUserNotificationRuleFailed(err));
        return Promise.reject(err);
      });
  };
}

export function updateSelectedUserNotificationRule(selectedUserNotificationRule) {
  return dispatch => Promise.resolve().then(dispatch(receiveUserNotificationRule(selectedUserNotificationRule)));
}

export function setNotificationTopicContext(title, topic, triggeringSites) {
  const notificationTopicContext = (title && triggeringSites) ? {
    title,
    topic,
    triggeringSites,
  } : undefined;

  return {
    type: ActionTypes.SET_NOTIFICATION_TOPIC_CONTEXT,
    notificationTopicContext,
  };
}

const saveUserNotificationRuleStarted = () => ({
  type: ActionTypes.SAVE_USER_NOTIFICATION_RULE_STARTED,
});

const saveUserNotificationRuleSuccess = (selectedUserNotificationRule) => ({
  type: ActionTypes.SAVE_USER_NOTIFICATION_RULE_SUCCESS,
  selectedUserNotificationRule,

});

const saveUserNotificationRuleFailed = error => ({
  type: ActionTypes.SAVE_USER_NOTIFICATION_RULE_FAILED,
  error,
});

export function saveSelectedUserNotificationRule() {
  return (dispatch, getState) => {
    const state = getState();
    if (selectors.isSavingUserNotificationRule(state)) {
      return Promise.resolve();
    }

    const toSave = selectors.selectedUserNotificationRule(state);
    dispatch(saveUserNotificationRuleStarted());
    toSave.entityType = notificationEntityTypes.Issue;
    return UserClient.saveUserNotificationRule(toSave.userId, toSave)
      .then(userNotificationRule => {
        if (!userNotificationRule) {
          return dispatch(disableUserNotificationRule(toSave.customerId, false));
        }
        return dispatch(saveUserNotificationRuleSuccess(userNotificationRule));
      })
      .catch(err => {
        dispatch(saveUserNotificationRuleFailed(err));
        return Promise.reject(err);
      });
  };
}

export function resetToUserNotificationRuleToDefault(customerId) {
  return (dispatch) => dispatch({ type: ActionTypes.RESET_SELECTED_ISSUE_NOTIFICATION_RULE_TO_DEFAULT, customerId });
}

export function disableUserNotificationRule(customerId, transient) {
  const context = {
    customerId,
    transient,
  };
  return (dispatch) => dispatch({ type: ActionTypes.DISABLE_SELECTED_ISSUE_NOTIFICATION_RULE, context });
}

const getUserNotificationLowProductRuleStarted = () => ({ type: ActionTypes.GET_USER_NOTIFICATION_LOW_PRODUCT_RULE_STARTED });
const saveUserNotificationLowProductRuleFailed = (error) => ({
  type: ActionTypes.SAVE_USER_NOTIFICATION_LOW_PRODUCT_RULE_FAILED,
  error
});
const saveUserNotificationLowProductRuleStarted = () => ({ type: ActionTypes.SAVE_USER_NOTIFICATION_LOW_PRODUCT_RULE_STARTED });
const saveUserNotificationLowProductRuleSuccess = (selectedUserNotificationLowProductRule) => ({
  type: ActionTypes.SAVE_USER_NOTIFICATION_LOW_PRODUCT_RULE_SUCCESS,
  selectedUserNotificationLowProductRule
});

// TODO: move to reducer
function getDefaultUserNotificationLowProductRule(userId, customerId) {
  return {
    userId,
    customerId,
    siteListType: PreferenceConstants.notAllocated,
    sites: emptyArray,
    entityType: notificationEntityTypes.LowProduct,
  };
}

const receiveUserNotificationLowProductRule = (userId, customerId, userNotificationLowProductRule) => {
  // TODO: split into separate actions and allow reducer define `selectedUserNotificationLowProductRule`
  let selectedUserNotificationLowProductRule;
  if (!userNotificationLowProductRule) {
    selectedUserNotificationLowProductRule = getDefaultUserNotificationLowProductRule(userId, customerId);
  }
  else {
    selectedUserNotificationLowProductRule = userNotificationLowProductRule;
  }

  return {
    type: ActionTypes.RECEIVE_USER_NOTIFICATION_LOW_PRODUCT_RULE,
    selectedUserNotificationLowProductRule,
  };
};

export function selectUserNotificationLowProductRule(customerId) {
  return (dispatch, getState) => {
    const state = getState();
    const isLoading = selectors.isLoadingUserNotificationLowProductRule(state);
    if (isLoading) {
      return Promise.resolve();
    }

    const userId = selectors.userId(state);
    dispatch(getUserNotificationLowProductRuleStarted());
    return UserClient.getUserNotificationRule(userId, customerId, notificationEntityTypes.LowProduct)
      .then(userNotificationLowProductRule => {
        dispatch(receiveUserNotificationLowProductRule(userId, customerId, userNotificationLowProductRule));
      })
      .catch(err => {
        dispatch(saveUserNotificationLowProductRuleFailed(err));
        return Promise.reject(err);
      });
  };
}

export function updateSelectedUserNotificationLowProductRule(selectedUserNotificationLowProductRule) {
  return (dispatch) => {
    const transientRule = Object.assign({}, selectedUserNotificationLowProductRule, {
      transient: true,
    });
    dispatch(receiveUserNotificationLowProductRule(selectedUserNotificationLowProductRule.userId, selectedUserNotificationLowProductRule.customerId, transientRule));
  };
}

export function saveSelectedUserNotificationLowProductRule() {
  return (dispatch, getState) => {
    const state = getState();
    const isSaving = selectors.isSavingUserNotificationLowProductRule(state);

    if (isSaving) {
      return Promise.resolve();
    }
    dispatch(saveUserNotificationLowProductRuleStarted());
    const userNotificationLowProductRule = selectors.selectedUserNotificationLowProductRule(state);
    return UserClient.saveUserNotificationRule(userNotificationLowProductRule.userId, userNotificationLowProductRule)
      .then(persistedUserNotificationLowProductRule => dispatch(saveUserNotificationLowProductRuleSuccess(persistedUserNotificationLowProductRule)))
      .catch(err => {
        dispatch(saveUserNotificationLowProductRuleFailed(err));
        return Promise.reject(err);
      });
  };
}

export function resetUpdatedEmail() {
  return (dispatch) => dispatch({ type: ActionTypes.RESET_UPDATED_EMAIL });
}

export function setUpdatedEmail(updatedEmail) {
  return (dispatch) => dispatch({
    type: ActionTypes.SET_UPDATED_EMAIL,
    updatedEmail,
  });
}

const saveUpdatedEmailStarted = () => ({ type: ActionTypes.SAVE_UPDATED_EMAIL_STARTED });
const saveUpdatedEmailSuccess = () => ({ type: ActionTypes.SAVE_UPDATED_EMAIL_SUCCESS });
const saveUpdatedEmailFailed = (error) => ({ type: ActionTypes.SAVE_UPDATED_EMAIL_FAILED, error });

export function saveUpdatedEmail() {
  return (dispatch, getState) => {
    const state = getState();
    if (selectors.isSavingEmail(state)) {
      return Promise.resolve();
    }

    dispatch(saveUpdatedEmailStarted());
    const userId = currentUserId(state);
    const updatedEmail = selectors.updatedEmail(state);
    return UserClient.updateEmail(userId, updatedEmail)
      .then(() => {
        dispatch(saveUpdatedEmailSuccess());
        dispatch(resetUpdatedEmail());
      })
      .catch(err => {
        dispatch(saveUpdatedEmailFailed(err));
      });
  };
}

export function updatePasswordContext(passwordContext) {
  return (dispatch) => dispatch({ type: ActionTypes.UPDATE_PASSWORD_CONTEXT, passwordContext });
}

const changePasswordStarted = () => ({ type: ActionTypes.CHANGE_PASSWORD_STARTED });
const changePasswordSuccess = () => ({ type: ActionTypes.CHANGE_PASSWORD_SUCCESS });
const changePasswordFailed = (error) => ({ type: ActionTypes.CHANGE_PASSWORD_FAILED, error });
const toRequestPasswordContext = (passwordContext) => ({
  oldPassword: passwordContext.currentPassword,
  newPassword: passwordContext.newPassword
});

export function changePassword() {
  return (dispatch, getState) => {
    const state = getState();
    if (selectors.isChangingPassword(state)) {
      return Promise.resolve;
    }

    const context = selectors.passwordContext(state);
    const userId = selectors.userId(state);
    dispatch(changePasswordStarted());
    return UserClient.changePassword(userId, toRequestPasswordContext(context))
      .then(() => {
        dispatch(changePasswordSuccess());
      })
      .catch(err => {
        dispatch(changePasswordFailed(err));
        return Promise.reject(err);
      });
  };
}

export const cancelChangePassword = () => ({ type: ActionTypes.CANCEL_CHANGE_PASSWORD });
