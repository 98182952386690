import update from 'immutability-helper';
import moment from 'moment';

import { cancelPendingItemEditsAction, deleteItemAction, editItemAction, restFuncWithActionArgs, saveItemAction, startItemEditAction } from '../../../../../../../../shared/redux-helpers/ActionCreatorFactory';
import { MviReportStatusTypes } from '../../../../../../../../AppConstants';
import { MviClient } from '../../../../../../../../client';
import * as ModalActions from '../../../../../../../../shared/components/mvi/modal/ModalActions';
import * as SiteSelectors from '../../../../../../SiteSelectors';
import * as SiteActions from '../../../../../../actions';
import MviEditSelectors from '../../MviEditSelectors';
import MviReportSelectors from './MviReportSelectors';
import ActionTypes from './MviReportActionTypes';

export const selectReport = report => ({ type: ActionTypes.RECEIVE_ITEM, item: report });

export const saveReport = saveItemAction(
  MviReportSelectors.isSaving,
  ActionTypes.SAVE_ITEM_STARTED,
  ActionTypes.SAVE_ITEM_SUCCESS,
  ActionTypes.SAVE_ITEM_FAILED,
  (currentState, actionArgs) => {
    const currentMvi = MviEditSelectors.item(currentState);
    const updatedReport = actionArgs[0];
    return MviClient.updateReport(currentMvi, updatedReport);
  }
);

export const createReport = saveItemAction(
  MviReportSelectors.isSaving,
  ActionTypes.SAVE_ITEM_STARTED,
  ActionTypes.SAVE_ITEM_SUCCESS,
  ActionTypes.SAVE_ITEM_FAILED,
  (currentState, [mviReport, file]) => MviClient.createReport(mviReport, file)
);

export const beginReportEdit = startItemEditAction(ActionTypes.BEGIN_ITEM_EDIT);

export const editReport = editItemAction(ActionTypes.EDIT_ITEM);

export const cancelPendingReportEdits = cancelPendingItemEditsAction(ActionTypes.CANCEL_ITEM_EDIT);

export const savePendingEdits = saveItemAction(
  MviReportSelectors.isSaving,
  ActionTypes.SAVE_ITEM_STARTED,
  ActionTypes.SAVE_ITEM_SUCCESS,
  ActionTypes.SAVE_ITEM_FAILED,
  (currentState) => {
    const currentMvi = MviEditSelectors.item(currentState);
    const updatedReport = MviReportSelectors.pendingEdits(currentState);
    return MviClient.updateReport(currentMvi, updatedReport);
  }
);

export const beginInspectorCommentsEdit = () => ({ type: ActionTypes.BEGIN_INSPECTOR_COMMENTS_EDIT });

export const editInspectorComments = inspectorComments => ({ type: ActionTypes.EDIT_INSPECTOR_COMMENTS, inspectorComments });

export const cancelInspectorCommentsEdit = () => ({ type: ActionTypes.CANCEL_INSPECTOR_COMMENTS_EDIT });

export const savePendingInspectorCommentsEdit = saveItemAction(
  MviReportSelectors.isSaving,
  ActionTypes.SAVE_ITEM_STARTED,
  ActionTypes.SAVE_ITEM_SUCCESS,
  ActionTypes.SAVE_ITEM_FAILED,
  (currentState) => {
    const currentMvi = MviEditSelectors.item(currentState);
    const currentReport = MviReportSelectors.item(currentState);
    const inspectorComments = MviReportSelectors.pendingInspectorCommentsEdits(currentState);
    const updatedReport = update(currentReport, {
      inspectorComments: {
        $set: inspectorComments || null,
      }
    });
    return MviClient.updateReport(currentMvi, updatedReport);
  }
);

export const deleteInspectorComments = () => (dispatch) => {
  dispatch(beginInspectorCommentsEdit());
  dispatch(editInspectorComments(null));
  return dispatch(savePendingInspectorCommentsEdit());
};

const moveInternal = saveItemAction(
  MviReportSelectors.isSaving,
  ActionTypes.SAVE_ITEM_STARTED,
  ActionTypes.SAVE_ITEM_SUCCESS,
  ActionTypes.SAVE_ITEM_FAILED,
  (currentState, actionArgs) => {
    const currentMvi = MviEditSelectors.item(currentState);
    const currentReport = MviReportSelectors.item(currentState);
    const moveRequest = actionArgs[0];
    return MviClient.moveReport(currentMvi, currentReport, moveRequest);
  }
);

export const moveReport = (targetSite, inspectionDetails) => dispatch => dispatch(moveInternal(targetSite, inspectionDetails))
  .then(mvi => dispatch(SiteActions.navToSiteMvi(mvi)));

export const markReportReviewed = saveItemAction(
  MviReportSelectors.isSaving,
  ActionTypes.SAVE_ITEM_STARTED,
  ActionTypes.SAVE_ITEM_SUCCESS,
  ActionTypes.SAVE_ITEM_FAILED,
  (currentState) => {
    const currentMvi = MviEditSelectors.item(currentState);
    const currentReport = MviReportSelectors.item(currentState);
    const updatedReport = update(currentReport, {
      $unset: ['statusDate', 'statusUserId'],
      status: {
        $set: MviReportStatusTypes.Reviewed,
      },
    });
    return MviClient.updateReport(currentMvi, updatedReport);
  }
);

export const deleteReport = deleteItemAction(
  MviReportSelectors.isDeleting,
  ActionTypes.DELETE_ITEM_STARTED,
  ActionTypes.DELETE_ITEM_SUCCESS,
  ActionTypes.DELETE_ITEM_FAILED,
  restFuncWithActionArgs(MviClient.deleteReport)
);

export const openCreateReportModal = () => (dispatch, getState) => {
  const state = getState();
  const currentSite = SiteSelectors.currentSite(state);
  const currentMvi = MviEditSelectors.item(state);
  const now = moment();
  const currentMviMonthStart = moment(currentMvi.monthStartDate);
  const currentMviMonthEnd = moment(currentMvi.monthStartDate).endOf('month');
  const report = {
    inspectionDate: now.isSameOrAfter(currentMviMonthStart) && now.isSameOrBefore(currentMviMonthEnd) ? now.format('YYYY-MM-DD') : null,
  };
  const title = `Create MVI report for ${currentSite.nickname} ${currentMvi.monthText} ${currentMvi.year}`;
  return dispatch(ModalActions.openModal(title, currentSite, report));
};

export const closeCreateReportModal = () => ({ type: ActionTypes.CLOSE_CREATE_MODAL });

export const confirmCreate = (site, inspectionDetails, file) => (dispatch, getState) => {
  const state = getState();
  const currentMvi = MviEditSelectors.item(state);
  const mviReport = Object.assign({}, inspectionDetails, { mviId: currentMvi.id });
  return dispatch(createReport(mviReport, file));
};

export const openMoveReportModal = () => (dispatch, getState) => {
  const state = getState();
  const currentSite = SiteSelectors.currentSite(state);
  const currentReport = MviReportSelectors.item(state);
  const title = `Move report for ${currentSite.nickname}`;
  return dispatch(ModalActions.openModal(title, currentSite, currentReport));
};

export const closeMoveReportModal = () => ({ type: ActionTypes.CLOSE_DELETE_MODAL });

export const confirmMove = (targetSite, inspectionDetails) => {
  const moveRequest = Object.assign({}, inspectionDetails, { siteId: targetSite.id });
  return moveReport(moveRequest);
};

export const openDeleteReportModal = () => ({ type: ActionTypes.OPEN_DELETE_MODAL });

export const closeDeleteReportModal = () => ({ type: ActionTypes.CLOSE_DELETE_MODAL });

export const confirmDelete = () => (dispatch, getState) => {
  const currentReport = MviReportSelectors.item(getState());
  return dispatch(deleteReport(currentReport));
};
