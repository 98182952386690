import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';
import { IssueTypes } from '../../../../../../../AppConstants';

const SelectedIssueTypes = ({ selectedIssueTypes, disabled, deselectSiteIssueType }) => {
  const handleSave = siteIssueType => deselectSiteIssueType(siteIssueType.id);
  const selectedIssueTypeObjs = selectedIssueTypes.map(current => ({ id: current, name: IssueTypes[current] }));
  return <FilterItemList items={selectedIssueTypeObjs} textField="name" disabled={disabled} onRemoveItem={handleSave} />;
};

SelectedIssueTypes.propTypes = {
  selectedIssueTypes: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool.isRequired,
  deselectSiteIssueType: PropTypes.func.isRequired,
};

SelectedIssueTypes.defaultProps = {
  selectedIssueTypes: [],
};

export default SelectedIssueTypes;
