import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Folder from './Folder';


class FolderTree extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.renderFolder = this.renderFolder.bind(this);
  }

  render() {
    return (
      <div className={this.props.className}>

        <div className="list-group">
          {this.props.folders.map(this.renderFolder)}
        </div>
      </div>
    );
  }

  renderFolder(folder) {
    const expanded = this.props.activeFolder && this.props.activeFolder.path.startsWith(folder.path);
    const selected = this.props.activeFolder && folder.id === this.props.activeFolder.id;
    return (
      <React.Fragment key={folder.id}>
        <Folder folder={folder} expanded={expanded} selected={selected} onClick={this.props.onFolderSelect} onFileDrop={this.props.onFileDrop} />
        {folder.subFolders.map(this.renderFolder)}
      </React.Fragment>
    );
  }
}

FolderTree.propTypes = {
  className: PropTypes.string,
  folders: PropTypes.array.isRequired,
  activeFolder: PropTypes.object,
  onFolderSelect: PropTypes.func.isRequired,
  onFileDrop: PropTypes.func,
};

FolderTree.defaultProps = {
  className: null,
  activeFolder: null,
  onFileDrop: null,
};

export default FolderTree;
