import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

import MviModal from '../../../../../../../../shared/components/mvi/modal/MviModal';
import UploadButton from '../../../../../documents/components/upload/UploadButton';
import ActionItemsList from './action-items/ActionItemList';
import DeleteReportModal from './DeleteReportModal';
import InspectionDetails from './InspectionDetails';
import InspectorComments from './InspectorComments';
import MviReportIssues from './MviReportIssues';
import * as MviEditUberSelectors from '../../MviEditUberSelectors';
import * as actions from './MviReportActions';
import selectors from './MviReportSelectors';
import MviReportDocs from './MviReportDocs';

const MviReport = ({ currentReport, className, editDisabled, confirmMove }) => {
  if (!currentReport) {
    return null;
  }

  return (
    <div className={className}>
      <Row>
        <Col xs={12}>
          <InspectionDetails />
        </Col>
      </Row>
      <hr className="my-3" />
      <Row>
        <Col md={8}>
          <h6 className="text-uppercase">Action Items</h6>
          <ActionItemsList />
          <h6 className="text-uppercase mt-3">Inspector Comments</h6>
          <InspectorComments />
        </Col>
        <Col md={4}>
          <div className="d-flex justify-content-between align-items-baseline">
            <h6 className="text-uppercase d-inline-block mb-0">Documents</h6>
            <UploadButton
              mviReport={currentReport}
              defaultFolderName="Inspections"
              buttonColor="link"
              buttonIcon={faPaperclip}
              buttonOutline={false}
              buttonSize={null}
              disabled={editDisabled}
            />
          </div>
          <MviReportDocs className="border rounded-lg p-3" />
          <MviReportIssues />
        </Col>
      </Row>
      <MviModal onConfirm={confirmMove} />
      <DeleteReportModal />
    </div>
  );
};

MviReport.propTypes = {
  currentReport: PropTypes.object,
  editDisabled: PropTypes.bool.isRequired,
  confirmMove: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MviReport.defaultProps = {
  currentReport: null,
  className: null,
};

function mapStateToProps(state) {
  return {
    currentReport: selectors.item(state),
    editDisabled: MviEditUberSelectors.isEditDisabled(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    confirmMove: (targetSite, inspectionDetails) => dispatch(actions.confirmMove(targetSite, inspectionDetails)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MviReport);
