import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { ActorGrantTypes, AssetGrantTypes } from '../../../permission-constants';
import PolicyTable from '../../../components/PolicyTable';
import PolicyEditModal from '../../../containers/PolicyEditModal';
import * as orgEditActions from '../../../actions';
import * as selectors from '../GroupEditorSelectors';


const InitialState = Object.freeze({
  modalOpen: false,
  policy: null,
});

class GroupPolicies extends Component {
  constructor(props) {
    super(props);

    this.state = InitialState;

    this.handleNewPolicyClick = this.handleNewPolicyClick.bind(this);
    this.handlePolicyEditClick = this.handlePolicyEditClick.bind(this);
    this.handlePolicyUpdate = this.handlePolicyUpdate.bind(this);
    this.handlePolicySave = this.handlePolicySave.bind(this);
    this.handlePolicyCancel = this.handlePolicyCancel.bind(this);
  }

  handleNewPolicyClick() {
    this.setState({
      modalOpen: true,
      policy: {
        name: '',
        groupGrantType: ActorGrantTypes.List,
        grantedToGroups: [this.props.selectedGroup],
        siteGrantType: AssetGrantTypes.All,
        grantedSites: [],
      }
    });
  }

  handlePolicyEditClick(policy) {
    this.setState({
      modalOpen: true,
      policy,
    });
  }

  handlePolicyUpdate(policy) {
    this.setState({
      policy
    });
  }

  handlePolicySave(policy) {
    this.setState(InitialState);
    this.props.savePolicy(policy);
  }

  handlePolicyCancel() {
    this.setState(InitialState);
  }

  render() {
    return (
      <div>
        <PolicyTable policies={this.props.selectedGroupPolicies} onPolicyEdit={this.handlePolicyEditClick} onPolicyDelete={this.props.deletePolicy} />
        <PolicyEditModal
          isOpen={this.state.modalOpen}
          policy={this.state.policy}
          onPolicyUpdate={this.handlePolicyUpdate}
          onSave={this.handlePolicySave}
          onCancel={this.handlePolicyCancel}
        />
        <Button size="sm" color="primary" onClick={this.handleNewPolicyClick}>Add an Access</Button>
      </div>
    );
  }
}

GroupPolicies.propTypes = {
  selectedGroup: PropTypes.object,
  selectedGroupPolicies: PropTypes.array,
  savePolicy: PropTypes.func.isRequired,
  deletePolicy: PropTypes.func.isRequired,
};

GroupPolicies.defaultProps = {
  selectedGroup: null,
  selectedGroupPolicies: [],
};

function mapStateToProps(state) {
  return {
    selectedGroup: selectors.selectedGroup(state),
    selectedGroupPolicies: selectors.selectedGroupPolicies(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePolicy: policy => dispatch(orgEditActions.savePolicy(policy)),
    deletePolicy: policy => dispatch(orgEditActions.deletePolicy(policy)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupPolicies);
