import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle as fasCheckCircle, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faCircle, faCheckCircle, faMinusCircle } from '@fortawesome/pro-regular-svg-icons';

import { MviWorkflowStatusTypes } from '../../../AppConstants';

const Icons = {
  [MviWorkflowStatusTypes.Pending]: {
    icon: faCircle,
    iconClassName: 'text-gray-600'
  },
  [MviWorkflowStatusTypes.ReadyForReview]: {
    icon: faCheckCircle,
    iconClassName: 'text-gray-600'
  },
  [MviWorkflowStatusTypes.Completed]: {
    icon: fasCheckCircle,
    iconClassName: 'text-success'
  },
  [MviWorkflowStatusTypes.NotRequired]: {
    icon: faMinusCircle,
    iconClassName: 'text-gray-600'
  },
  [MviWorkflowStatusTypes.NotPerformed]: {
    icon: faExclamationTriangle,
    iconClassName: 'text-danger'
  }
};

const MviWorkflowStatus = ({ className, workflowStatus }) => {
  if (!workflowStatus) {
    return null;
  }

  const { icon, iconClassName } = Icons[workflowStatus];
  return (
    <div className={classNames(iconClassName, className)}>
      <FontAwesomeIcon icon={icon} className="mr-2" />
      <span>{workflowStatus}</span>
    </div>
  );
};

MviWorkflowStatus.propTypes = {
  className: PropTypes.string,
  workflowStatus: PropTypes.string,
};

MviWorkflowStatus.defaultProps = {
  className: null,
  workflowStatus: null,
};

export default MviWorkflowStatus;
