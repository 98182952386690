/* eslint-disable no-unused-vars,import/prefer-default-export */

import { createSocketMiddleware } from '../../SocketMiddleware';
import { updateExportStatus } from './actions';

const socketMiddleware = createSocketMiddleware({
  export: (socketMessageData, dispatch) => dispatch(updateExportStatus(socketMessageData)),
});

export default {
  socketMiddleware,
};
