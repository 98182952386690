import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import RelativeTimestamp from '../../../../../../shared/components/date/RelativeTimestamp';
import SignalBarsWidget from '../../../../../components/signal-bars/SignalBarsWidget';
import DeleteButton from '../../../../../../shared/components/buttons/DeleteButton';

function DeviceActivationRow(props) {
  return (
    <Row className="border-bottom">
      <Col md={2} className="align-self-center">
        <SignalBarsWidget
          signalStrength={props.activation.signalStrength}
          border={false}
          displayText={false}
          sizeEm={4}
        />
      </Col>
      <Col md={3} className="align-self-center">
        <RelativeTimestamp time={props.activation.created}/>
      </Col>
      <Col md={5} className="align-self-center">
        {props.activation.formattedAddress || 'Unknown location'}
      </Col>
      <Col md={2} className="align-self-center">
        <div className="d-flex">
          <Button color="primary" size="sm" outline className="mr-2" onClick={() => props.onActivate(props.activation)}>Activate</Button>
          <DeleteButton size="sm" onClick={() => props.onDelete(props.activation)} />
        </div>

      </Col>
    </Row>
  );
}

DeviceActivationRow.propTypes = {
  activation: PropTypes.object.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeviceActivationRow;
