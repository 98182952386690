import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { track } from '../../../../shared/analytics';
import MviLinkModal from './MviLinkModal';
import * as actions from './MviLinkModalActions';
import * as siteSelectors from '../../SiteSelectors';

const MviLinkModalButton = ({ hasPermissions, openModal }) => {
  if (!hasPermissions) {
    return null;
  }

  return (
    <>
      <Button color="primary" outline size="sm" onClick={openModal}>Get Link to Form</Button>
      <MviLinkModal />
    </>
  );
};

MviLinkModalButton.propTypes = {
  hasPermissions: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    hasPermissions: siteSelectors.canViewMviForms(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: () => {
      track('Inspection', {
        Component: 'Link modal',
        'Inspection action': 'Open link modal',
      });
      return dispatch(actions.openModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MviLinkModalButton);
