import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';

import { track } from '../../../../../shared/analytics';
import PriorityIndicator from '../../../../../shared/components/PriorityIndicator';
import WorkflowStatus from '../../../../../shared/components/WorkflowStatus';
import TextToMarkup from '../../../../../shared/components/TextToMarkup';
import DispatchDetails from '../../../components/DispatchDetails';
import IssueActionButton from '../screens/issue-edit/containers/IssueActionButton';
import IssueTitle from './IssueTitle';

const IssueCard = ({ issue, onNavToIssue, className }) => {
  const navFunction = () => {
    track('Issue', {
      Component: 'Issue card',
      'Issue action': 'Nav to issue',
      'Issue type': issue.issueType,
    });
    onNavToIssue(issue);
  };
  const recommendations = issue.analysis && issue.analysis.recommendations;
  return (
    <Card className={className}>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <IssueTitle issue={issue} />
          <div>
            <WorkflowStatus className="mr-1" workflowStatus={issue.workflow.status} />
            <PriorityIndicator className="mr-1" priority={issue.priority} long />
          </div>
        </div>

        <CardTitle className="d-flex align-items-center my-2">
          <span className="text-truncate">{issue.description}</span>
        </CardTitle>
        <div className="mb-2">
          <TextToMarkup text={recommendations} missingText="No recommendation available." className="read-more" />
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap-reverse">
          <div className="mr-4">
            <Button color="primary" size="sm" className="d-inline-block" onClick={navFunction}>View Details</Button>
            <IssueActionButton issue={issue} className="d-inline-block ml-2" />
          </div>
          <div className="my-2">
            <DispatchDetails issue={issue} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

IssueCard.propTypes = {
  className: PropTypes.string,
  issue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    workflow: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
    priority: PropTypes.string.isRequired,
    analysis: PropTypes.shape({
      recommendations: PropTypes.string,
    }),
    issueType: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
  }).isRequired,
  onNavToIssue: PropTypes.func.isRequired,
};

IssueCard.defaultProps = {
  className: null,
};

export default IssueCard;
