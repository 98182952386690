import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import RelativeTimestamp from '../../../../../../shared/components/date/RelativeTimestamp';
import SignalBarsWidget from '../../../../../components/signal-bars/SignalBarsWidget';

const style = Object.freeze({
  borderRadius: '10px',
  padding: '2rem',
});

class DeviceActivationDetails extends Component {
  constructor(initialProps) {
    super(initialProps);
  }

  renderAddress() {
    if (this.props.activation.formattedAddress) {
      return (
        <div className="d-flex align-items-end flex-wrap">
          <h4 className="mr-2">{this.props.activation.formattedAddress}</h4>
          <h6 className="text-muted">within {this.props.activation.accuracyMeters.toLocaleString()} meters</h6>
        </div>
      );
    }

    return (
      <div>
        <h4>Unknown location</h4>
      </div>
    );
  }

  renderAtgHeader() {
    if (this.props.activation.header1) {
      return (
        <div>
          <strong>ATG Headers</strong>
          <div>LINE 1: {this.props.activation.header1}</div>
          <div>LINE 2: {this.props.activation.header2}</div>
          <div>LINE 3: {this.props.activation.header3}</div>
          <div>LINE 4: {this.props.activation.header4}</div>
        </div>
      );
    }

    return (
      <div>
        <strong>ATG Headers</strong>
        <div><span className="text-muted">Not available</span></div>
      </div>
    );
  }

  render() {
    return (
      <div style={style} className="border">
        {this.renderAddress()}
        <Row>
          <Col md={5}>
            {this.renderAtgHeader()}
          </Col>
          <Col md={5} className="d-flex flex-column justify-content-between">
            <div>
              <strong>Particle core id</strong>
              <span style={{display: 'block'}}>{this.props.activation.id}</span>
            </div>
            <div>
              <div><strong>Activation requested:</strong> <RelativeTimestamp time={this.props.activation.created} /></div>
              <div>Time Zone: {this.props.activation.siteTimezone || 'Unknown'}</div>
            </div>
          </Col>
          <Col md={2} className="d-none d-lg-block align-self-center">
            <SignalBarsWidget signalStrength={this.props.activation.signalStrength} border={false} />
          </Col>
        </Row>
      </div>
    );
  }
}

DeviceActivationDetails.propTypes = {
  activation: PropTypes.object.isRequired,
};

export default DeviceActivationDetails;
