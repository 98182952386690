import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { track, withTracking } from '../../../../../shared/analytics';
import DispatchTable from '../components/DispatchTable';
import * as SiteActions from '../../../actions';
import getDispatches from '../actions';
import * as selectors from '../SiteDispatchSelectors';

class SiteDispatchContainer extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.props.getSiteDispatches(this.props.siteId);
  }

  render() {
    return (
      <DispatchTable dispatches={this.props.dispatches} navToIssue={this.props.navToCurrentSiteIssue} />
    );
  }
}

SiteDispatchContainer.propTypes = {
  siteId: PropTypes.number.isRequired,
  getSiteDispatches: PropTypes.func.isRequired,
  dispatches: PropTypes.array.isRequired,
  navToCurrentSiteIssue: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    dispatches: selectors.dispatches(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSiteDispatches: siteId => dispatch(getDispatches(siteId)),
    navToCurrentSiteIssue: issueId => {
      track('Issue', {
        Component: 'Dispatch table',
        'Issue action': 'Nav to issue',
      });
      return dispatch(SiteActions.navToCurrentSiteIssue(issueId));
    }
  };
}

export default withTracking('Site', 'Work Order List')(connect(mapStateToProps, mapDispatchToProps)(SiteDispatchContainer));
