import React from 'react';
import PropTypes from 'prop-types';
import SignalBars from './SignalBars';

function signalStrengthText(signalStrength) {
  if (signalStrength) {
    return `${signalStrength} dBm`;
  }
  return 'unknown';
}

const SignalBarsWidget = ({ signalStrength, border, displayText, sizeEm }) => {

  const style = {
    height: `${sizeEm}em`,
    width: `${sizeEm}em`,
  };

  if (!border) {
    style.border = 'none';
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center number-box" style={style}>
      {displayText ? <span>{signalStrengthText(signalStrength)}</span> : null}
      <SignalBars signalStrength={signalStrength} />
      {displayText ? <span className="mt-1">Cell Signal</span> : null}
    </div>
  );
};

SignalBarsWidget.propTypes = {
  signalStrength: PropTypes.number,
  border: PropTypes.bool,
  displayText: PropTypes.bool,
  sizeEm: PropTypes.number,
};

SignalBarsWidget.defaultProps = {
  signalStrength: null,
  border: true,
  displayText: true,
  sizeEm: 8,
};

export default SignalBarsWidget;
