import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SiteFields from '../../../../../../shared/components/site-fields/SiteFields';

class AddSite extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      site: {
        customer: null,
        siteName: '',
        nickname: '',
        address: '',
        city: '',
        state: initialProps.initialState,
        zip: '',
        siteTimezone: initialProps.initialSiteTimezone,
      }
    };
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleUpdate(site) {
    this.setState({ site });
  }

  render() {
    return <SiteFields site={this.state.site} className="mt-4" saveButtonLabel="Activate" onUpdate={this.handleUpdate} onSave={this.props.onSave} />;
  }
}

AddSite.propTypes = {
  initialState: PropTypes.string,
  initialSiteTimezone: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

AddSite.defaultProps = {
  initialState: '',
  initialSiteTimezone: '',
};

export default AddSite;
