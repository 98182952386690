export default {
  GET_ACTIVATIONS_LIST_STARTED: 'admin/get_activations_list_started',
  RECEIVE_ACTIVATIONS_LIST: 'admin/receive_activations_list',
  GET_ACTIVATIONS_LIST_FAILED: 'admin/get_activations_list_failed',

  ADD_ACTIVATION: 'admin/add_activation',
  REMOVE_ACTIVATION: 'admin/remove_activation',

  GET_ACTIVATION_STARTED: 'admin/get_activation_started',
  RECEIVE_ACTIVATION: 'admin/receive_activation',
  GET_ACTIVATION_FAILED: 'admin/get_activation_failed',

  DELETE_ACTIVATION_STARTED: 'admin/delete_activation_started',
  DELETE_ACTIVATION_SUCCESS: 'admin/delete_activation_success',
  DELETE_ACTIVATION_FAILED: 'admin/delete_activation_failed',

  PROCESS_ACTIVATION_STARTED: 'admin/process_activation_started',
  PROCESS_ACTIVATION_SUCCESS: 'admin/process_activation_success',
  PROCESS_ACTIVATION_FAILED: 'admin/process_activation_failed',
};
