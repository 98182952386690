import { Delete, Get, GetAccept, Patch, Post } from '../HttpUtils';
import { ParameterType, Param, buildFindMethod } from '../param-types';

export const SiteFields = {
  Id: 'id',
  CustomerId: 'customerId',
  SiteName: 'siteName',
  Nickname: 'nickname',
  BusinessName: 'businessName',
  Address: 'address',
  City: 'city',
  State: 'state',
  Zip: 'zip',
  Phone: 'phone',
  SiteTimezone: 'siteTimezone',
  Created: 'created',
  Location: 'location',
  Enabled: 'enabled',
  FacilityId: 'facilityId',

  AlarmStatus: 'alarmStatus',
  AppFeatures: 'appFeatures',
  Connection: 'connection',
  Customer: 'customer',
  Health: 'health',
  Labels: 'labels',
  MviFormUrl: 'mviFormUrl',
};

export const SiteParams = {
  CustomerIds: new Param('customerIds', ParameterType.List),
  SiteNames: new Param('siteNames', ParameterType.List),
  SitePermission: new Param('sitePermission', ParameterType.Scalar),
  Feature: new Param('feature', ParameterType.Scalar),
  Term: new Param('term', ParameterType.Scalar),
  IncludeInactiveSites: new Param('includeInactiveSites', ParameterType.Scalar),
};

const DefaultFields = 'id,customerId,siteName,nickname,businessName,address,city,state,zip,phone,siteTimezone,created,location,enabled,facilityId';

export const SiteClient = {
  find: buildFindMethod('/v2/sites', SiteFields, SiteParams),

  getSiteById(siteId) {
    if (siteId) {
      const requestUrl = `/v2/sites/${siteId}?include=${DefaultFields},customer,labels,connection,appFeatures,externalIds`;
      return Get(requestUrl).then(response => response.data);
    }

    return Promise.resolve();
  },

  // FIXME: split this method up
  getSiteByIdAdmin(id) {
    return Promise.all([
      Get(`/v2/sites/${id}?include=${DefaultFields},connection,health,appFeatures,customerId,externalIds`).then(response => response.data),
      Get(`/v2/sites/${id}/cell-signal-strength`).then(response => response.data),
      Get(`/v2/sites/${id}/connection-health-history`).then(response => response.data),
      Get(`/v2/entity-notification-logs?siteId=${id}`).then(response => response.data),
    ]).then(([site, cellSignalStrength, healthHistory, entityNotifications]) => {
      site.cellSignalStrength = cellSignalStrength;
      site.healthHistory = healthHistory;
      site.entityNotifications = entityNotifications;
      return site;
    }).then(site => Promise.all([
      Get(`/v2/organizations/${site.customerId}?include=customer`).then(response => response.data),
      Promise.resolve(site),
    ])).then(([organization, site]) => {
      site.customer = organization.customer;
      return site;
    });
  },

  getAlarmStatus(site) {
    const id = (site && site.id) || site;
    if (!id) {
      return Promise.resolve(null);
    }
    return Get(`/v2/sites/${id}/alarmStatus`).then(response => response.data);
  },

  saveSite(site) {
    if (site.id) {
      return Patch(`v2/sites/${site.id}`, site).then(response => response.data);
    }
    return Post('/v2/sites', site).then(response => response.data);
  },

  geocode(address, city, state, zip) {
    let zipParam = '';
    if (zip) {
      zipParam = `&zip=${zip}`;
    }
    return Get(`/v2/sites/geocode?address=${address}&city=${city}&state=${state}${zipParam}`).then(response => response.data);
  },

  getSitesForCustomer(customerId, etag) {
    if (!customerId) {
      return Promise.resolve([]);
    }
    const requestUrl = '/v2/sites/summaries';
    const params = { customerIds: customerId, include: 'nickname,state,activeAlarmCount,openIssueCount,complianceReportStatus,mviStatus,connectionType,connectionStatus,openDispatches,labels' };
    return Get(requestUrl, params, etag);
  },

  getSitesForCustomer2(customerId) {
    return Get(`/v2/sites?customerIds=${customerId}&include=${DefaultFields}`).then(response => response.data);
  },

  getSitesForMultipleCustomers(customerIds) {
    return Get(`/v2/sites?customerIds=${customerIds.join(',')}&include=${DefaultFields}`).then(response => response.data);
  },

  getSitesBySiteName(siteNames) {
    return Get(`/v2/sites?siteNames=${siteNames.join(',')}&include=${DefaultFields}`).then((response) => {
      const byName = {};
      response.data.forEach((current) => {
        byName[current.siteName] = current;
      });
      return byName;
    });
  },

  getSiteHealth() {
    return Get('/v2/sites/health').then(response => response.data);
  },

  getProblematicSites(csv) {
    const url = '/v2/sites/health/problematic-sites';
    if (csv) {
      return GetAccept(url, null, 'text/csv').then(response => response.data);
    }
    return Get(url).then(response => response.data);
  },

  search(term, customerId, sitePermission, feature) {
    if (!term) {
      return Promise.resolve([]);
    }

    let url = `/v2/sites?term=${term}`;
    if (customerId) {
      url += `&customerIds=${customerId}`;
    }
    if (sitePermission) {
      url += `&sitePermission=${sitePermission}`;
    }
    if (feature) {
      url += `&feature=${feature}`;
    }

    url += '&include=id,nickname,customer';

    return Get(url).then(response => response.data);
  },

  saveSiteConnection(siteId, siteConnectionDetail, deleteConnection) {
    if (deleteConnection) {
      return Delete(`/v2/sites/${siteId}/connection`);
    }

    const payload = {
      uri: siteConnectionDetail.asUri(),
    };
    return Post(`/v2/sites/${siteId}/connection`, payload).then(response => response.data);
  },
};
