import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { track } from '../../../../../../../../shared/analytics';
import UserDate from '../../../../../../../../shared/components/UserDate';
import ReadOnlyAnalysisRec from './ReadOnlyAnalysisRec';

const DefaultState = {
  modalOpen: false,
  index: 0,
};

class AnalysisAndRecHistoryButton extends Component {
  constructor(props) {
    super(props);
    this.state = DefaultState;

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  handleOpen() {
    track('Issue', {
      Component: 'Issue analysis',
      'Issue action': 'Open analysis history',
      'Issue type': this.props.issue.issueType,
    });
    this.setState({ modalOpen: true });
  }

  handleClose() {
    this.setState(DefaultState);
  }

  canMovePrev() {
    const analysisRecHistory = this.props.issue.analysisHistory;
    return this.state.index < analysisRecHistory.length - 1;
  }

  handlePrev() {
    track('Issue', {
      Component: 'Issue analysis',
      'Issue action': 'Analysis history next',
      'Issue type': this.props.issue.issueType,
    });
    this.setState(currentState => ({
      index: currentState.index + 1,
    }));
  }

  canMoveNext() {
    return this.state.index > 0;
  }

  handleNext() {
    track('Issue', {
      Component: 'Issue analysis',
      'Issue action': 'Analysis history prev',
      'Issue type': this.props.issue.issueType,
    });
    this.setState(currentState => ({
      index: currentState.index - 1,
    }));
  }

  render() {
    const analysisRecHistory = this.props.issue.analysisHistory;
    if (!analysisRecHistory || !analysisRecHistory.length) {
      return null;
    }

    const { user, created, analysis, recommendations } = analysisRecHistory[this.state.index];

    return (
      <>
        <Button color="link" className={this.props.className} onClick={this.handleOpen}>Show History</Button>
        <Modal isOpen={this.state.modalOpen} toggle={this.handleClose}>
          <ModalHeader toggle={this.handleClose}>
            Analysis &amp; Recommendation History
          </ModalHeader>
          <ModalBody>
            <UserDate user={user} date={created} timezone={this.props.site.siteTimezone} />
            <ReadOnlyAnalysisRec analysis={analysis} recommendations={recommendations} />
          </ModalBody>
          <ModalFooter className="d-flex">
            <Button color="primary" size="sm" outline onClick={this.handlePrev} disabled={!this.canMovePrev()}>Older</Button>
            <Button color="primary" size="sm" outline onClick={this.handleNext} disabled={!this.canMoveNext()}>Newer</Button>
            <Button color="primary" size="sm" className="ml-auto" onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

AnalysisAndRecHistoryButton.propTypes = {
  className: PropTypes.string,
  site: PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteTimezone: PropTypes.string.isRequired,
  }).isRequired,
  issue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    analysisHistory: PropTypes.arrayOf(PropTypes.shape({
      analysis: PropTypes.string.isRequired,
      recommendations: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        username: PropTypes.string.isRequired,
      })
    }))
  }).isRequired
};

AnalysisAndRecHistoryButton.defaultProps = {
  className: null,
};

export default AnalysisAndRecHistoryButton;
