// noinspection DuplicatedCode

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Card, Form, FormGroup, Input, Label, Button, FormText, UncontrolledAlert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import SignalBarsWidget from '../../../../../../../../components/signal-bars/SignalBarsWidget';
import ConnectionFactory from '../../connection-type-factory';
import { ConnectionTypes } from '../../connection-constants';
import CellSignalGraph from './CellSignalGraph';
import ConnectivityPercentage from './ConnectivityPercentage';
import ConnectivityGraph from './ConnectivityGraph';
import Colors from '../../../../../../../../../colors';
import NonProdWarning from '../../../../../../../../../shared/components/non-prod-warning/NonProdWarning';

class ConnectionEditor extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.onCancelClick = this.onCancelClick.bind(this);
    this.onConnectionTypeChange = this.onConnectionTypeChange.bind(this);
    this.onDeviceIdChange = this.onDeviceIdChange.bind(this);
    this.onIpAddressChange = this.onIpAddressChange.bind(this);
    this.onPortChange = this.onPortChange.bind(this);
    this.onSecurityCodeChange = this.onSecurityCodeChange.bind(this);
    this.onMaxReportDurationSecondsChange = this.onMaxReportDurationSecondsChange.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  onCancelClick(e) {
    e.preventDefault();
    this.props.onCancel();
  }

  onEditClick(e) {
    e.preventDefault();
    this.props.onEdit();
  }

  onSaveClick(e) {
    e.preventDefault();
    this.props.onSave();
  }

  onDeviceIdChange(e) {
    const deviceId = e.target.value;
    this.props.onValueChange(ConnectionFactory.createParticleConnection(deviceId));
  }

  onIpAddressChange(e) {
    const ipAddress = e.target.value;
    this.props.onValueChange(ConnectionFactory.createTcpConnectionWithHost(this.props.siteConnectionDetail, ipAddress));
  }

  onPortChange(e) {
    const port = e.target.value;
    this.props.onValueChange(ConnectionFactory.createTcpConnectionWithPort(this.props.siteConnectionDetail, port));
  }

  onSecurityCodeChange(e) {
    const securityCode = e.target.value;
    this.props.onValueChange(ConnectionFactory.createTcpConnectionWithSecurityCode(this.props.siteConnectionDetail, securityCode));
  }

  onMaxReportDurationSecondsChange(e) {
    const maxReportDurationSeconds = e.target.value;
    this.props.onValueChange(ConnectionFactory.createTcpConnectionWithMaxReportDurationSeconds(this.props.siteConnectionDetail, maxReportDurationSeconds));
  }

  onConnectionTypeChange(e) {
    const connectionType = e.target.value;
    this.props.onValueChange(ConnectionFactory.create(connectionType));
  }

  renderInfoBoxes(connectionType) {
    if (ConnectionTypes.NONE.connectionType === connectionType || this.props.isEditing) {
      return null;
    }

    return (
      <Row className="mt-3">
        <Col sm={4} className="d-flex justify-content-center">
          <ConnectivityPercentage
            title="Device Responsiveness"
            value={this.props.selectedSite.health && this.props.selectedSite.health.deviceCommPercentage * 100}
          />
        </Col>
        {ConnectionTypes.PARTICLE.connectionType === connectionType
          ? (
            <Col sm={4} className="d-flex justify-content-center">
              <ConnectivityPercentage
                title="ATG Responsiveness"
                value={this.props.selectedSite.health && this.props.selectedSite.health.atgCommPercentage * 100}
              />
            </Col>
          )
          : null}
        {ConnectionTypes.PARTICLE.connectionType === connectionType
          ? (
            <Col sm={4} className="d-flex justify-content-center">
              <SignalBarsWidget
                signalStrength={this.props.selectedSite.health && this.props.selectedSite.health.latestCellSignalStrength}
              />
            </Col>
          )
          : null}
      </Row>
    );
  }

  renderGraphs(connectionType) {
    if (ConnectionTypes.NONE.connectionType === connectionType || this.props.isEditing) {
      return null;
    }

    return (
      <Row className="mt-5">
        <Col lg={4}>
          <ConnectivityGraph type="device" selectedSite={this.props.selectedSite} />
        </Col>
        {ConnectionTypes.PARTICLE.connectionType === connectionType
          ? (
            <Col lg={4}>
              <ConnectivityGraph type="atg" selectedSite={this.props.selectedSite} />
            </Col>
          )
          : null}
        {ConnectionTypes.PARTICLE.connectionType === connectionType
          ? (
            <Col lg={4}>
              <CellSignalGraph selectedSite={this.props.selectedSite} />
            </Col>
          )
          : null}
      </Row>
    );
  }

  render() {
    const siteConnectionDetail = this.props.siteConnectionDetail;
    const connectionType = siteConnectionDetail.connectionType;
    const connectionTypeOptions = Object.keys(ConnectionTypes).map(c => <option key={c} value={ConnectionTypes[c].connectionType}>{ConnectionTypes[c].display}</option>);
    return (
      <div>
        <NonProdWarning>
          <UncontrolledAlert color="danger" disabled>
            <FontAwesomeIcon icon={faExclamation} size="lg" color={Colors.Fail} />
            <span className="ml-2 ">
              THIS IS A NON-PRODUCTION ENVIRONMENT: Do not make changes to Particle connections for real sites.
              Changing a Particle connection here is the same as changing it in the Particle console and will disrupt connectivity for production sites.
            </span>
          </UncontrolledAlert>
        </NonProdWarning>
        <Row>
          <Col sm="12">
            <Card body color="transparent">
              <Form>
                <Row>
                  <Col sm="6">
                    <FormGroup row>
                      <Label className="font-weight-bold" sm="3" for="connectionType">Connection Type:</Label>
                      <Col sm="9">
                        <Input
                          type="select"
                          id="connectionType"
                          value={connectionType}
                          disabled={!this.props.isEditing}
                          onChange={this.onConnectionTypeChange}
                        >
                          {connectionTypeOptions}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    { ConnectionTypes.PARTICLE.connectionType === connectionType
                      ? (
                        <FormGroup row>
                          <Label className="font-weight-bold" sm="3" for="particleDeviceId">Device ID:</Label>
                          <Col sm="9">
                            <Input type="text" invalid={!siteConnectionDetail.isValid} disabled={!this.props.isEditing} onChange={this.onDeviceIdChange} value={siteConnectionDetail.deviceId || ''} />
                          </Col>
                        </FormGroup>
                      )
                      : (ConnectionTypes.TCP.connectionType === connectionType ? (
                        <FormGroup row>
                          <Label className="font-weight-bold" sm="3" for="ipAddress">IP Address:</Label>
                          <Col sm="9">
                            <Input type="text" invalid={!siteConnectionDetail.isHostValid} id="ipAddress" disabled={!this.props.isEditing} onChange={this.onIpAddressChange} value={siteConnectionDetail.host || ''} />
                          </Col>
                        </FormGroup>
                      ) : null)}
                  </Col>
                </Row>
                { ConnectionTypes.TCP.connectionType === connectionType
                  ? (
                    <>
                      <Row>
                        <Col sm="6">
                          <FormGroup row>
                            <Label className="font-weight-bold" sm="3" for="port">Port:</Label>
                            <Col sm="9">
                              <Input type="number" id="port" invalid={!siteConnectionDetail.isPortValid} disabled={!this.props.isEditing} onChange={this.onPortChange} value={siteConnectionDetail.port || ''} />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup row>
                            <Label className="font-weight-bold" sm="3" for="securityCode">Security Code:</Label>
                            <Col sm="9">
                              <Input type="text" id="securityCode" invalid={!siteConnectionDetail.isSecurityCodeValid} disabled={!this.props.isEditing} onChange={this.onSecurityCodeChange} value={siteConnectionDetail.securityCode || ''} />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup row>
                            <Label className="font-weight-bold" sm="3" for="maxReportDurationSeconds">Max Report Duration Seconds:</Label>
                            <Col sm="9">
                              <Input type="number" id="maxReportDurationSeconds" invalid={!siteConnectionDetail.isMaxReportDurationSecondsValid} disabled={!this.props.isEditing} onChange={this.onMaxReportDurationSecondsChange} value={siteConnectionDetail.maxReportDurationSeconds || ''} />
                              <FormText>Only enter a value if instructed by Canary Engineering</FormText>
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                <Row>
                  <Col sm="6">
                    {ConnectionTypes.NONE.connectionType !== connectionType && !this.props.isEditing
                      ? (
                        <FormGroup row>
                          <Label className="font-weight-bold" sm="3" for="connectedSince">Connected Since:</Label>
                          <Col sm="9">
                            <Input type="label" disabled className="border-0 bg-transparent" id="connectedSince" value={this.props.selectedSite.connection ? moment(this.props.selectedSite.connection.created).format('M/D/YYYY HH:MM') : null} />
                          </Col>
                        </FormGroup>
                      )
                      : null }
                  </Col>
                  <Col sm="6">
                    <div className="float-right">
                      {this.props.isEditing
                        ? (
                          <div>
                            <Button
                              color="primary"
                              className="mr-2"
                              disabled={this.props.isSaving}
                              onClick={this.onCancelClick}
                              outline
                            >
                              Cancel
                            </Button>
                            <Button color="primary" disabled={!siteConnectionDetail.isValid || this.props.isSaving} onClick={this.onSaveClick}>Save</Button>
                            {' '}
                          </div>
                        )
                        : (
                          <>
                            {ConnectionTypes.NONE.connectionType !== siteConnectionDetail.connectionType
                              ? (
                                <Link to={`/sites/${this.props.selectedSite.id}/atg-command`}>
                                  <Button color="primary" outline>Test in ATG Command Center</Button>
                                </Link>
                              )
                              : null}
                            <Button color="primary" className="ml-2" onClick={this.onEditClick} disabled={!this.props.userCanEdit}>Edit Connection</Button>
                          </>
                        )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        {this.renderInfoBoxes(siteConnectionDetail.connectionType)}
        {this.renderGraphs(siteConnectionDetail.connectionType)}
      </div>
    );
  }
}

ConnectionEditor.propTypes = {
  siteConnectionDetail: PropTypes.object,
  selectedSite: PropTypes.object.isRequired,
  userCanEdit: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

ConnectionEditor.defaultProps = {
  siteConnectionDetail: null,
};

export default ConnectionEditor;
