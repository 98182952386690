import React from 'react';
import PropTypes from 'prop-types';
import {Col, FormGroup, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';

import SiteSelector from './SiteSelector';


const SiteSelectorModal = ({ isOpen, onCancel, onConfirm, notificationTopicContext, handleAddSite, handleRemoveSite, handleSiteListTypeChange }) => (
  <Modal isOpen={isOpen} size="md">
    <ModalHeader>{notificationTopicContext.title}</ModalHeader>
    <ModalBody>
      <div>
        <FormGroup row>
          <Label for="sitesType" sm={12}>
            Notify me about
            {' '}
            {notificationTopicContext.title}
            {' '}
            Issues at:
          </Label>
          <Col sm={12} className="pt-1">
            <SiteSelector
              id="siteTypes"
              handleRemoveSite={handleRemoveSite}
              handleAddSite={handleAddSite}
              onCancel={onCancel}
              siteContext={notificationTopicContext}
              handleSiteListTypeChange={handleSiteListTypeChange}
              onConfirm={onConfirm}
            />
          </Col>
        </FormGroup>
      </div>
    </ModalBody>
  </Modal>
);

SiteSelectorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  notificationTopicContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    triggeringSites: PropTypes.shape({
      siteListType: PropTypes.string.isRequired,
      sites: PropTypes.array,
    }),
    availableSites: PropTypes.array.isRequired,
  }).isRequired,
  handleAddSite: PropTypes.func.isRequired,
  handleRemoveSite: PropTypes.func.isRequired,
  handleSiteListTypeChange: PropTypes.func.isRequired,
};

export default SiteSelectorModal;
