import React from 'react';
import PropTypes from 'prop-types';

const AtgTankIcon = ({ text }) => (
  <svg
    version="1.1"
    id="outline"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 225.1 225.1"
    style={{ enableBackground: 'new 0 0 225.1 225.1' }}
  >
    <g>
      <path
        d="M94,1.6h35.8V23c43.9,8.5,76.5,47.4,76.5,93.5c0,52.6-42.3,95.2-94.4,95.2S17.5,169,17.5,116.5 c0-46.1,32.6-85,76.5-93.5V1.6z"
        style={{ overflow: 'visible', fill: '#F3F1DF' }}
      />
      <clipPath id="SVGID_2_">
        <path
          d="M94,1.6h35.8V23c43.9,8.5,76.5,47.4,76.5,93.5c0,52.6-42.3,95.2-94.4,95.2S17.5,169,17.5,116.5 c0-46.1,32.6-85,76.5-93.5V1.6z"
          style={{ overflow: 'visible' }}
        />
      </clipPath>
      <g style={{ clipPath: 'url(#SVGID_2_)' }}>
        <clipPath id="SVGID_4_">
          <rect id="SVGID_3_" x="-606.1" y="-3572.6" width="2445.8" height="7579.1" style={{ overflow: 'visible' }} />
        </clipPath>
        <rect
          id="SVGID_3_"
          x="-606.1"
          y="-3572.6"
          width="2445.8"
          height="7579.1"
          style={{ overflow: 'visible', fill: 'none', stroke: '#939598', strokeMiterlimit: 10 }}
        />
      </g>
      <path
        d="M94,1.6h35.8V23c43.9,8.5,76.5,47.4,76.5,93.5c0,52.6-42.3,95.2-94.4,95.2S17.5,169,17.5,116.5 c0-46.1,32.6-85,76.5-93.5V1.6z"
        style={{ overflow: 'visible', fill: 'none', stroke: '#414042', strokeWidth: 2, strokeMiterlimit: 10 }}
      />
    </g>
    <text transform="matrix(1 0 0 1 74.9844 137.3187)" style={{ fill: '#414042', fontFamily: 'Roboto', fontSize: '64px' }}>
      {text}
    </text>
  </svg>
);


AtgTankIcon.propTypes = {
  text: PropTypes.any,
};

AtgTankIcon.defaultProps = {
  text: '',
};

export default AtgTankIcon;
