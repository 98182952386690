import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { track } from '../../../../../../../shared/analytics';
import { WorkflowStatusTypes } from '../../../../../../../AppConstants';
import * as WorkflowActions from '../components/workflow/WorkflowActions';
import * as SiteSelectors from '../../../../../SiteSelectors';
import * as IssueSelectors from '../IssueEditSelectors';
import * as DispatchActions from '../../../../dispatch/actions';
import * as IssueExportActions from '../components/export/IssueExportActions';

const IssueActionButton = ({ issue, canEditIssue, hasDispatchSystem, openExportModal, moveToAwaitingApproval, moveToPendingResolution, openCreateDispatchModal, openConfirmResolveModal, right, className }) => {
  const workflowStatus = issue.workflow.status;
  const canMoveAwaitingApproval = canEditIssue && (workflowStatus === WorkflowStatusTypes.NewUnread || workflowStatus === WorkflowStatusTypes.New || workflowStatus === WorkflowStatusTypes.Draft);
  const canMovePendingResolution = canEditIssue && (workflowStatus === WorkflowStatusTypes.AwaitingApprovalUnread || workflowStatus === WorkflowStatusTypes.AwaitingApproval);
  const canDispatch = canEditIssue && hasDispatchSystem && issue.dispatchable;
  const canResolve = canEditIssue && workflowStatus !== WorkflowStatusTypes.Resolved;

  return (
    (
      <UncontrolledDropdown color="primary" className={className} size="sm">
        <DropdownToggle color="primary" caret>
          Take Action
        </DropdownToggle>
        <DropdownMenu right={right}>
          <DropdownItem onClick={openExportModal}>Export</DropdownItem>
          {canMoveAwaitingApproval ? <DropdownItem onClick={moveToAwaitingApproval}>Analysis Complete</DropdownItem> : null}
          {canMovePendingResolution ? <DropdownItem onClick={moveToPendingResolution}>Move to Pending Resolution</DropdownItem> : null}
          {canDispatch ? <DropdownItem onClick={openCreateDispatchModal}>Create Work Order</DropdownItem> : null}
          {canResolve ? <DropdownItem onClick={openConfirmResolveModal}>Close</DropdownItem> : null}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  );
};

IssueActionButton.propTypes = {
  issue: PropTypes.shape({
    workflow: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
    dispatchable: PropTypes.bool.isRequired,
  }).isRequired,
  canEditIssue: PropTypes.bool.isRequired,
  hasDispatchSystem: PropTypes.bool.isRequired,
  openExportModal: PropTypes.func.isRequired,
  moveToAwaitingApproval: PropTypes.func.isRequired,
  moveToPendingResolution: PropTypes.func.isRequired,
  openCreateDispatchModal: PropTypes.func.isRequired,
  openConfirmResolveModal: PropTypes.func.isRequired,
  right: PropTypes.bool,
  className: PropTypes.string,
};

IssueActionButton.defaultProps = {
  right: false,
  className: null,
};

function mapStateToProps(state, ownProps) {
  return {
    canEditIssue: IssueSelectors.canEditIssue(state, ownProps.issue),
    hasDispatchSystem: SiteSelectors.hasDispatchSystem(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    openExportModal: () => {
      track('Issue', {
        Component: 'Issue action button',
        'Issue action': 'Open export modal',
        'Issue type': ownProps.issue.issueType
      });
      return dispatch(IssueExportActions.openModal(ownProps.issue));
    },
    moveToAwaitingApproval: () => {
      track('Issue', {
        Component: 'Issue action button',
        'Issue action': 'Set workflow Analyzing',
        'Issue type': ownProps.issue.issueType
      });
      return dispatch(WorkflowActions.moveToAwaitingApproval(ownProps.issue));
    },
    moveToPendingResolution: () => {
      track('Issue', {
        Component: 'Issue action button',
        'Issue action': 'Set workflow Pending Resolution',
        'Issue type': ownProps.issue.issueType
      });
      return dispatch(WorkflowActions.moveToPendingResolution(ownProps.issue));
    },
    openCreateDispatchModal: () => {
      track('Issue', {
        Component: 'Issue action button',
        'Issue action': 'Open dispatch modal',
        'Issue type': ownProps.issue.issueType
      });
      return dispatch(DispatchActions.openCreateDispatchModal(ownProps.issue));
    },
    openConfirmResolveModal: () => {
      track('Issue', {
        Component: 'Issue action button',
        'Issue action': 'Open confirm resolve modal',
        'Issue type': ownProps.issue.issueType
      });
      return dispatch(WorkflowActions.openConfirmationModal(ownProps.issue));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueActionButton);
