import React, { Component } from 'react';
import { Card, CardBody, CardSubtitle, Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper/index';
import cronstrue from 'cronstrue';

import DeliveryURL from '../../../../../components/export-destination/components/DeliveryURL';
import FuelHaulerScheduleModal from './FuelHaulerScheduleModal';


class FuelHaulerSchedule extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      modalOpen: false,
    };
    this.handleModelOpen = this.handleModelOpen.bind(this);
    this.handleCancelModel = this.handleCancelModel.bind(this);
    this.handleSaveSchedule = this.handleSaveSchedule.bind(this);
    this.handleRemoveSchedule = this.handleRemoveSchedule.bind(this);
  }

  handleModelOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  handleCancelModel() {
    this.setState({
      modalOpen: false,
    });
  }

  handleSaveSchedule(schedule) {
    const existingCronExpressions = this.props.selectedFuelHauler.schedules.map(current => current.transmitSchedule);
    const existingScheduleIndex = existingCronExpressions.indexOf(schedule.transmitSchedule);
    let newFuelHauler;
    if (existingScheduleIndex === -1) {
      newFuelHauler = update(this.props.selectedFuelHauler, {
        schedules: {
          $push: [schedule],
        },
      });
    }
    else {
      const existingSchedule = this.props.selectedFuelHauler.schedules[existingScheduleIndex];
      const updatedSchedule = this.mergeDestinationsIntoSchedule(existingSchedule, schedule.destinations);
      newFuelHauler = update(this.props.selectedFuelHauler, {
        schedules: {
          $splice: [[existingScheduleIndex, 1, updatedSchedule]],
        },
      });
    }
    this.props.updateSelectedFuelHauler(newFuelHauler);
    this.setState({
      modalOpen: false,
    });
  }

  mergeDestinationsIntoSchedule(existingSchedule, newDestinations) {
    const existingDeliveryUrls = existingSchedule.destinations.map(current => current.deliveryUrl);
    const appendDestinations = newDestinations.filter(current => existingDeliveryUrls.indexOf(current.deliveryUrl) === -1);
    if (appendDestinations.length) {
      return update(existingSchedule, {
        destinations: {
          $push: appendDestinations,
        },
      });
    }
    return existingSchedule;
  }

  handleRemoveSchedule(index) {
    const newFuelHauler = update(this.props.selectedFuelHauler, {
      schedules: {
        $splice: [[index, 1]],
      },
    });
    return this.props.updateSelectedFuelHauler(newFuelHauler);
  }

  renderSchedulesRow() {
    if (!this.props.selectedFuelHauler.schedules || !this.props.selectedFuelHauler.schedules.length) {
      return (
        <span className="text-muted">No schedule configured</span>
      );
    }

    return (
      <Row>
        <Col xs={12}>
          {this.props.selectedFuelHauler.schedules.map((currentSchedule, scheduleIndex) => (
            <Card key={currentSchedule.transmitSchedule} className="mb-2">
              <CardBody>
                <CardSubtitle>{cronstrue.toString(currentSchedule.transmitSchedule)}, send to:</CardSubtitle>
                <ul className="mt-2 ml-2">
                  {currentSchedule.destinations && currentSchedule.destinations.map(currentDest => (
                    <li key={currentDest.deliveryUrl}>
                      <DeliveryURL url={currentDest.deliveryUrl} />
                    </li>
                  ))}
                </ul>
                <Button size="sm" className="mt-2" onClick={() => this.handleRemoveSchedule(scheduleIndex)}>Unschedule</Button>
              </CardBody>
            </Card>
          ))}
        </Col>
      </Row>
    );
  }

  render() {
    if (!this.props.selectedFuelHauler) {
      return null;
    }

    return (
      <div className="mt-4">
        {this.renderSchedulesRow()}
        <Button color="primary" outline size="sm" className="d-block my-4" onClick={this.handleModelOpen}>Add Schedule</Button>
        <FuelHaulerScheduleModal
          modalOpen={this.state.modalOpen}
          destinations={this.props.selectedFuelHauler.destinations}
          onSave={this.handleSaveSchedule}
          onCancel={this.handleCancelModel}
        />
      </div>
    );
  }
}

FuelHaulerSchedule.propTypes = {
  selectedFuelHauler: PropTypes.object,
  updateSelectedFuelHauler: PropTypes.func.isRequired,
};

FuelHaulerSchedule.defaultProps = {
  selectedFuelHauler: null,
};

export default FuelHaulerSchedule;
