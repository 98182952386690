export function isOpenIssuesLoading(state) {
  return (state && state.sites && state.sites.issues && state.sites.issues.root && state.sites.issues.root.openIssuesLoading) || false;
}

export function openIssues(state) {
  return (state && state.sites && state.sites.issues && state.sites.issues.root && state.sites.issues.root.openIssues) || [];
}

export function openIssuesError(state) {
  return (state && state.sites && state.sites.issues && state.sites.issues.root && state.sites.issues.root.openIssuesError) || null;
}
