import Alert from 'react-s-alert';

export function PresentAlert(message) {
  Alert.info(message, {
    position: 'bottom-right',
    effect: 'slide',
    timeout: 5000
  });
}

export function PresentError(message) {
  Alert.error(message, {
    position: 'top-right',
    effect: 'jelly',
    timeout: 8000
  })
}

export function PresentNotification(message) {
  Alert.warning(message, {
    position: 'bottom-right',
    effect: 'bouncyflip',
    timeout: 8000
  })
}
