import { push } from 'react-router-redux';

import { ActivationClient } from '../../../../../../client';
import ActionTypes from '../ActivationActionTypes';
import * as ActivationSelectors from '../ActivationSelectors';

function getActivationsListStarted() {
  return {
    type: ActionTypes.GET_ACTIVATIONS_LIST_STARTED,
  };
}

function receiveActivationsList(activationsList) {
  return {
    type: ActionTypes.RECEIVE_ACTIVATIONS_LIST,
    activationsList,
  };
}

function getActivationsListFailed(error) {
  return {
    type: ActionTypes.GET_ACTIVATIONS_LIST_FAILED,
    error
  };
}

export function getActivationsList() {
  return (dispatch, getState) => {
    const isLoading = ActivationSelectors.isActivationListLoading(getState());
    if (isLoading) {
      return Promise.resolve();
    }

    dispatch(getActivationsListStarted());
    return ActivationClient.getList()
      .then((activationsList) => {
        dispatch(receiveActivationsList(activationsList));
      })
      .catch(err => dispatch(getActivationsListFailed(err)));
  };
}


function deleteActivationStarted() {
  return {
    type: ActionTypes.DELETE_ACTIVATION_STARTED,
  };
}

function deleteActivationSuccess(id) {
  return {
    type: ActionTypes.DELETE_ACTIVATION_SUCCESS,
    id,
  };
}

function deleteActivationFailed(error) {
  return {
    type: ActionTypes.DELETE_ACTIVATION_FAILED,
    error,
  };
}

export function deleteActivation(id) {
  return (dispatch, getState) => {
    const isDeleting = ActivationSelectors.isDeleteInProgress(getState());
    if (isDeleting || !id) {
      return Promise.resolve();
    }

    const actualId = id.id || id;
    dispatch(deleteActivationStarted());
    return ActivationClient.deleteById(actualId)
      .then(() => dispatch(deleteActivationSuccess(actualId)))
      .catch(err => dispatch(deleteActivationFailed(err)));
  };
}


export function addDeviceActivation(activation) {
  return {
    type: ActionTypes.ADD_ACTIVATION,
    activation,
  };
}

export function removeDeviceActivation(activation) {
  return {
    type: ActionTypes.REMOVE_ACTIVATION,
    activation,
  };
}

function getActivationStarted() {
  return {
    type: ActionTypes.GET_ACTIVATION_STARTED,
  };
}

function receiveActivation(selectedActivation, nearbySites) {
  return {
    type: ActionTypes.RECEIVE_ACTIVATION,
    selectedActivation,
    nearbySites,
  };
}

function getActivationFailed(error) {
  return {
    type: ActionTypes.GET_ACTIVATION_FAILED,
    error,
  };
}

export function getActivation(id) {
  return (dispatch, getState) => {
    const selectedActivationLoading = ActivationSelectors.isSelectedActivationLoading(getState());
    if (!id || selectedActivationLoading) {
      return Promise.resolve();
    }

    dispatch(getActivationStarted());

    const promises = [
      ActivationClient.getById(id),
      ActivationClient.getNearbySites(id)
    ];

    return Promise.all(promises)
      .then(([activation, nearbySites]) => {
        dispatch(receiveActivation(activation, nearbySites));
      })
      .catch(err => dispatch(getActivationFailed(err)));
  };
}


function processActivationStarted() {
  return {
    type: ActionTypes.PROCESS_ACTIVATION_STARTED,
  };
}

function processActivationSuccess(id) {
  return {
    type: ActionTypes.PROCESS_ACTIVATION_SUCCESS,
    id,
  };
}

function processActivationFailed(error) {
  return {
    type: ActionTypes.PROCESS_ACTIVATION_FAILED,
    error,
  };
}

export function processActivation(id, site) {
  return (dispatch, getState) => {
    const activationInProgress = ActivationSelectors.isActivationInProgress(getState());
    if (!id || !site || activationInProgress) {
      return Promise.resolve();
    }

    dispatch(processActivationStarted());
    return ActivationClient.update(id, site)
      .then(() => {
        dispatch(processActivationSuccess(id));
        dispatch(navToDeviceActivationList());
      })
      .catch(err => dispatch(processActivationFailed(err)));
  };
}


export function navToDeviceActivationList() {
  return (dispatch, getState) => {
    const currentState = getState();

    const currentPath = currentState.router.location.pathname;
    const activationListPath = '/admin/sites/activations';

    if (currentPath !== activationListPath) {
      dispatch(push(activationListPath));
    }
  };
}

export function navToDeviceActivation(activation) {
  return (dispatch, getState) => {
    const currentState = getState();

    const currentPath = currentState.router.location.pathname;
    const activationPath = `/admin/sites/activations/${activation.id}`;

    if (currentPath !== activationPath) {
      dispatch(push(activationPath));
    }
  };
}
