/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { timestampAsMonthYear } from '../date';
import { TextAlign } from './TableConstants';
import PropertyColumn from './PropertyColumn';

const render = timestamp => <span>{timestampAsMonthYear(timestamp)}</span>;

function MonthColumn({ title, property, rowData }) {
  return (
    <PropertyColumn
      title={title}
      property={property}
      render={render}
      rowData={rowData}
    />
  );
}

MonthColumn.propTypes = {
  sortable: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  textAlign: PropTypes.oneOf([TextAlign.Left, TextAlign.Right, TextAlign.Center]),
  property: PropTypes.string.isRequired,
  zone: PropTypes.string,

  rowData: PropTypes.object,
};

MonthColumn.defaultProps = {
  sortable: false,
  title: null,
  width: null,
  textAlign: TextAlign.Left,
  zone: null,
  rowData: null,
};

export default MonthColumn;
