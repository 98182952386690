import { push } from 'react-router-redux';

import { ComplianceClient, ReportClient } from '../../../../../../client';
import { genericAction, restFuncWithActionArgs } from '../../../../../../shared/redux-helpers/ActionCreatorFactory';
import ActionTypes from './ComplianceReportActionTypes';
import * as AuthenticationSelectors from '../../../../../../authentication/AuthenticationSelectors';
import * as AtgReportsActions from '../../../../../../shared/components/atg-reports/AtgReportsActions';
import * as SiteSelectors from '../../../../SiteSelectors';
import * as selectors from './ComplianceReportSelectors';
import SaveFileActionHelper from '../../../../../../shared/redux-helpers/SaveFileActionHelper';
import {track} from '../../../../../../shared/analytics';

class ReportContext {
  constructor(siteId, month, year, includeRelatedReports) {
    this._siteId = siteId;
    this._month = month;
    this._year = year;
    this._includeRelatedReports = includeRelatedReports;
  }

  toJSON() {
    return {
      reportType: 'monthlySiteCompliance',
      constraint: {
        siteId: this._siteId,
        month: this._month,
        year: this._year,
      },
      includeRelatedReports: this._includeRelatedReports,
    };
  }
}

export const getComplianceReportById = genericAction(
  selectors.isReportLoading,
  ActionTypes.GET_REPORT_STARTED,
  (_, report) => ({ type: ActionTypes.RECEIVE_REPORT, data: report }), // FIXME: passing undefined first argument doesn't seem right
  ActionTypes.GET_REPORT_FAILED,
  restFuncWithActionArgs(ComplianceClient.getReportById)
);

export function toggleTanks() {
  return { type: ActionTypes.TOGGLE_TANKS_SECTION };
}

export function toggleLines() {
  return { type: ActionTypes.TOGGLE_LINES_SECTION };
}

export function toggleSensors() {
  return { type: ActionTypes.TOGGLE_SENSORS_SECTION };
}

export function hideAllSections() {
  return { type: ActionTypes.HIDE_ALL_SECTIONS };
}

export function expandAllSections() {
  return { type: ActionTypes.EXPAND_ALL_SECTIONS };
}

const getReportEventsBySiteAndReport = genericAction(
  selectors.isReportEventsLoading,
  ActionTypes.GET_REPORT_EVENTS_STARTED,
  reportEvents => ({ type: ActionTypes.RECEIVE_REPORT_EVENTS, data: reportEvents }),
  ActionTypes.GET_REPORT_EVENTS_FAILED,
  restFuncWithActionArgs(ComplianceClient.getReportEventsBySiteAndReport)
);

export function getReportEvents() {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentReport = selectors.currentReport(currentState);
    return Promise.resolve(dispatch(AtgReportsActions.resetEvent()))
      .then(() => dispatch(getReportEventsBySiteAndReport(currentReport))).then(events => dispatch(AtgReportsActions.receiveEvents(events)));
  };
}

export function downloadReport(includeATGReports) {
  return (dispatch, getState) => {
    const currentState = getState();

    const site = SiteSelectors.currentSite(currentState);
    const report = selectors.currentReport(currentState);
    if (!site || !report || selectors.isDownloading(currentState)) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.DOWNLOAD_STARTED });
    return ReportClient.download(new ReportContext(site.id, report.month, report.year, includeATGReports))
      .then(content => dispatch(SaveFileActionHelper.saveAsFile(content, `Compliance Report - ${site.nickname} - ${report.month}-${report.year}`, ActionTypes.DOWNLOAD_SUCCESS)))
      .catch(error => {
        dispatch({ type: ActionTypes.DOWNLOAD_FAILED, error });
      });
  };
}

export function openAddComment() {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentPath = currentState.router.location.pathname;
    if (currentPath.indexOf('equipment') === -1) {
      const currentSite = SiteSelectors.currentSite(currentState);
      const currentReport = selectors.currentReport(currentState);
      dispatch(push(`/sites/${currentSite.id}/reports/${currentReport.id}/equipment`));
    }
    dispatch({ type: ActionTypes.OPEN_COMMENT });
  };
}

export function editComment(commentText) {
  return {
    type: ActionTypes.EDIT_COMMENT,
    commentText,
  };
}

export function cancelComment() {
  return {
    type: ActionTypes.CANCEL_COMMENT,
  };
}

function receiveComments(comments) {
  return {
    type: ActionTypes.RECEIVE_COMMENTS,
    comments,
  };
}

function receiveOverrideStatusResult(overrideStatusResult) {
  return {
    type: ActionTypes.SAVE_OVERRIDE_STATUS_SUCCESS,
    overrideStatusResult,
  };
}

export function saveComment() {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentReport = selectors.currentReport(currentState);
    const userId = AuthenticationSelectors.currentUserId(currentState);
    const comment = selectors.commentText(currentState);
    const isOverridingStatus = selectors.isOverridingStatus(currentState);

    if (isOverridingStatus) {
      if (selectors.isSavingOverrideStatus(currentState)) {
        return Promise.resolve();
      }
      const overrideStatus = selectors.overrideStatus(currentState);

      track('Compliance', {
        Component: 'Status dropdown',
        'Compliance action': 'Set status',
        'New compliance status': overrideStatus,
      });

      return ComplianceClient.doOverrideStatus(currentReport.id, userId, overrideStatus, comment)
        .then(overrideStatusResult => dispatch(receiveOverrideStatusResult(overrideStatusResult)))
        .catch(error => {
          dispatch({ type: ActionTypes.SAVE_OVERRIDE_STATUS_FAILED, error });
          return Promise.reject(error);
        });
    }
    else {
      if (selectors.isSavingComment(currentState)) {
        return Promise.resolve();
      }

      track('Compliance', {
        Component: 'Comment',
        'Compliance action': 'Save comment',
      });

      dispatch({ type: ActionTypes.SAVE_COMMENT_STARTED });
      return ComplianceClient.addComment(currentReport.id, userId, comment)
        .then((newComments) => {
          dispatch(receiveComments(newComments));
        })
        .catch(error => {
          dispatch({ type: ActionTypes.SAVE_COMMENT_FAILED, error });
          return Promise.reject(error);
        });
    }
  };
}

export function onOverrideStatus(status) {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.OVERRIDE_STATUS, overrideStatus: status });
    if (selectors.isEditingComment(getState())) {
      return Promise.resolve();
    }

    return dispatch(openAddComment());
  };
}
