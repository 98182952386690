import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import values from 'lodash/values';
import Suggestion from './Suggestion';

const getSuggestionValue = suggestion => String(suggestion.nickname);

const getSectionSuggestions = customer => customer.sites;

class SiteAutoSuggest extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleChange = this.handleChange.bind(this);
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
    this.handleSuggestionSelect = this.handleSuggestionSelect.bind(this);
  }

  handleChange(event, { newValue }) {
    event.preventDefault();
    this.props.onValueChange(newValue);
  }

  handleSuggestionsFetchRequested({ value }) {
    const inputValue = value.trim().toLowerCase();
    this.props.onSiteSearchRequested(inputValue);
  }

  handleSuggestionSelect(event, { suggestion }) {
    event.preventDefault();
    this.props.onSiteSelected(suggestion);
  }

  buildSuggestions() {
    if (this.props.groupByCustomer) {
      const sectionsByCustomerId = {};
      this.props.suggestions.forEach(site => {
        const customerId = (site.customer && site.customer.id) || site.customerId || 0;
        if (!sectionsByCustomerId[customerId]) {
          const customerName = site.customerName || (site.customer && site.customer.name) || (site.customerId && `Customer ${customerId}`) || 'Unknown customer';
          const customerAbbreviation = site.customerAbbreviation || (site.customer && site.customer.abbreviation);
          const title = customerAbbreviation ? `${customerName} (${customerAbbreviation})` : customerName;
          sectionsByCustomerId[customerId] = { title, sites: [] };
        }
        sectionsByCustomerId[customerId].sites.push(site);
      });
      return values(sectionsByCustomerId);
    }
    return this.props.suggestions;
  }

  render() {
    const inputProps = {
      id: this.props.id,
      placeholder: this.props.placeHolderText,
      value: this.props.value,
      onChange: this.handleChange,
      className: this.props.inputClassName,
      onBlur: this.props.onBlur,
      disabled: this.props.disabled,
    };

    return (
      <div>
        <Autosuggest
          id={this.props.id}
          suggestions={this.buildSuggestions()}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.props.onClear}
          onSuggestionSelected={this.handleSuggestionSelect}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={site => <Suggestion site={site} className={this.props.suggestionClassName} style={{ height: 'auto' }} />}
          renderSectionTitle={customer => <span className="d-block text-muted font-italic ml-1 mt-2">{customer.title}</span>}
          getSectionSuggestions={getSectionSuggestions}
          inputProps={inputProps}
          multiSection={this.props.groupByCustomer}
        />
      </div>
    );
  }
}

SiteAutoSuggest.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  groupByCustomer: PropTypes.bool,
  suggestions: PropTypes.array,
  onSiteSearchRequested: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSiteSelected: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  suggestionClassName: PropTypes.string,
  placeHolderText: PropTypes.string
};

SiteAutoSuggest.defaultProps = {
  value: '',
  groupByCustomer: false,
  suggestions: [],
  onBlur: null,
  disabled: false,
  inputClassName: 'form-control form-control-sm',
  suggestionClassName: 'form-control-sm',
  placeHolderText: 'Enter site name...'
};

export default SiteAutoSuggest;
