import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import produce from 'immer';

import { Column, IntegerColumn, LoadMoreTable, StringColumn } from '../../../../../../../../shared/components/table';
import TimestampColumn from '../../../../../../../../shared/components/table/TimestampColumn';
import { WorkflowStatusTypes } from '../../../../../../../../AppConstants';

const sortActive = (alarms) => produce(alarms, draft => sortBy(draft, { isActive: false }));

const renderStatus = (alarm) => {
  const clearedLabel = alarm.count > 1 ? 'Last cleared ' : 'Cleared ';
  if (alarm.isActive) {
    return 'Active';
  }
  else if (moment(alarm.lastClearedDate).isValid()) {
    return `${clearedLabel} ${moment(alarm.lastClearedDate).format('L')} at ${moment(alarm.lastClearedDate).format('LT')}`;
  }
  return null;
};

const IssueAlarms = (props) => {
  if (!props.issue.alarms.length) {
    return null;
  }

  const title = props.issue.alarms.length === 1 ? '1 Alarm in this Issue' : `${props.issue.alarms.length} Alarms in this Issue`;
  const resolved = props.issue.workflowStatus === WorkflowStatusTypes.Resolved;
  return (
    <>
      <h6 className="text-uppercase">{title}</h6>
      <LoadMoreTable data={sortActive(props.issue.alarms)} initialCount={5} className={props.className}>
        <StringColumn title="Name" property="title" width="40%" />
        <TimestampColumn title="First Alarm" property="firstAlarmDate" />
        <IntegerColumn title="Count" property="count" width="10%" />
        {!resolved ? <Column title="Alarm Status" render={renderStatus} width="30%" /> : null}
      </LoadMoreTable>
    </>
  );
};

IssueAlarms.propTypes = {
  issue: PropTypes.object.isRequired,
  className: PropTypes.string,
};

IssueAlarms.defaultProps = {
  className: null,
};

export default IssueAlarms;
