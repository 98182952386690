import ActionTypes from './ActivationActionTypes';

function addActivation(state, activation) {
  const existingActivations = state.activationsList || [];
  const updatedActivations = existingActivations.filter(current => current.id !== activation.id);
  updatedActivations.push(activation);
  return Object.assign({}, state, {
    activationsList: updatedActivations,
  });
}

function removeActivation(state, activation) {
  const existingActivations = state.activationsList || [];
  const updatedActivations = existingActivations.filter(current => current.id !== activation.id);
  const selectedActivation = state.selectedActivation;

  if (selectedActivation && selectedActivation.id === activation.id) {
    return Object.assign({}, state, {
      activationsList: updatedActivations,
      selectedActivation: null,
      error: {
        message: 'This activation was processed by another user.',
      }
    });
  }

  return Object.assign({}, state, {
    activationsList: updatedActivations,
  });
}

export default function ActivationReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_ACTIVATIONS_LIST_STARTED:
      return Object.assign({}, state, {
        activationsList: [],
        activationsListLoading: true,
        error: null,
      });
    case ActionTypes.RECEIVE_ACTIVATIONS_LIST:
      return Object.assign({}, state, {
        activationsList: action.activationsList,
        activationsListLoading: false,
      });
    case ActionTypes.GET_ACTIVATIONS_LIST_FAILED:
      return Object.assign({}, state, {
        activationsList: [],
        activationsListLoading: false,
        error: action.error,
      });

    case ActionTypes.ADD_ACTIVATION:
      return addActivation(state, action.activation);
    case ActionTypes.REMOVE_ACTIVATION:
      return removeActivation(state, action.activation);

    case ActionTypes.GET_ACTIVATION_STARTED:
      return Object.assign({}, state, {
        selectedActivation: null,
        nearbySites: null,
        selectedActivationLoading: true,
        error: null,
      });
    case ActionTypes.RECEIVE_ACTIVATION:
      return Object.assign({}, state, {
        selectedActivation: action.selectedActivation,
        nearbySites: action.nearbySites || [],
        selectedActivationLoading: false,
      });
    case ActionTypes.GET_ACTIVATION_FAILED:
      return Object.assign({}, state, {
        selectedActivationLoading: false,
        error: action.error,
      });

    case ActionTypes.DELETE_ACTIVATION_STARTED:
      return Object.assign({}, state, {
        deleteInProgress: true,
      });
    case ActionTypes.DELETE_ACTIVATION_SUCCESS:
      return Object.assign({}, state, {
        activationsList: (state.activationsList || []).filter(current => current.id !== action.id),
        deleteInProgress: false,
      });
    case ActionTypes.DELETE_ACTIVATION_FAILED:
      return Object.assign({}, state, {
        deleteInProgress: false,
        error: action.error,
      });

    case ActionTypes.PROCESS_ACTIVATION_STARTED:
      return Object.assign({}, state, {
        activationInProgress: true,
        error: null,
        selectedActivation: null,
        nearbySites: null,
      });
    case ActionTypes.PROCESS_ACTIVATION_SUCCESS:
      return Object.assign({}, state, {
        activationsList: (state.activationsList || []).filter(current => current.id !== action.id),
        activationInProgress: false,
      });
    case ActionTypes.PROCESS_ACTIVATION_FAILED:
      return Object.assign({}, state, {
        activationInProgress: false,
        error: action.error,
      });

    default:
      return state;
  }
}
