import * as actions from './actions';
import { setAuthToken } from '../client';

export default store => (next) => {
  const currentState = store.getState();
  if (currentState.authentication && currentState.authentication.userId) {
    setAuthToken(currentState.authentication.token);
    Promise.resolve().then(() => next(actions.loginRefresh()));
  }
  return action => next(action);
};
