import { CommandClient } from '../../../client';
import ActionTypes from './AtgReportsActionTypes';
import * as selectors from './AtgReportsSelectors';

export function getCommandNames() {
  return (dispatch, getState) => {
    const currentState = getState();
    if (selectors.isLoadingCommandNames(currentState)) {
      return Promise.resolve();
    }
    dispatch({ type: ActionTypes.GET_COMMAND_NAMES_STARTED });
    return CommandClient.getVrCommandNamesByFunctionCode()
      .then(commandNamesByFuncCode => {
        dispatch({ type: ActionTypes.RECEIVE_COMMAND_NAMES, commandNamesByFuncCode });
      })
      .catch(() => {
        dispatch({ type: ActionTypes.GET_COMMAND_NAMES_FAILED });
      });
  };
}

export function receiveEvents(events) {
  return (dispatch) => dispatch(getCommandNames()).then(() => {
    dispatch({
      type: ActionTypes.RECEIVE_EVENTS,
      events,
    });
  });
}

export function resetEvent() {
  return {
    type: ActionTypes.RESET_EVENTS,
  };
}
