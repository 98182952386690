import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const DispatchSystemDropdown = ({ dispatchSystemList, selectedDispatchSystem, onDispatchSystemSelect }) => {
  const currentValue = selectedDispatchSystem || '';

  return (
    <Input
      type="select"
      id="dispatchSystem"
      name="dispatchSystem"
      value={currentValue}
      onChange={event => event.target.value && onDispatchSystemSelect(event.target.value)}
    >
      <option value={''}>Select a dispatch system</option>
      {dispatchSystemList.map(current => (
        <option value={current.id} key={current.displayName}>{current.displayName}</option>
      ))}
    </Input>

  );
};

DispatchSystemDropdown.propTypes = {
  dispatchSystemList: PropTypes.array.isRequired,
  selectedDispatchSystem: PropTypes.string,
  onDispatchSystemSelect: PropTypes.func.isRequired,
};

DispatchSystemDropdown.defaultProps = {
  selectedDispatchSystem: '',
};

export default DispatchSystemDropdown;
