import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { registerProperty, track, unregisterProperty, withTracking } from '../../../../../../shared/analytics';
import CanaryLoadingIndicator from '../../../../../../shared/components/CanaryLoadingIndicator';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import { Features } from '../../../../../../AppConstants';
import NewIssueModal from '../../../../components/new-issue-button/NewIssueModal';
import CommentEdit from './components/comments/CommentEdit';
import MviMonthStatus from './components/MviMonthStatus';
import MviActionButton from './components/MviActionButton';
import MviCommentList from './components/comments/MviCommentList';
import MviReport from './components/report/MviReport';
import MviReportChooser from './components/report/MviReportChooser';
import NoReport from './components/report/NoReport';
import * as SiteSelectors from '../../../../SiteSelectors';
import MviReportSelectors from './components/report/MviReportSelectors';
import MviEditSelectors from './MviEditSelectors';
import * as MviEditUberSelectors from './MviEditUberSelectors';
import * as actions from './MviEditActions';

const MVIFeatureLevel = 'MVI feature level';

class MviEdit extends Component {
  componentDidMount() {
    registerProperty({ [MVIFeatureLevel]: this.props.featureLevel });
    const mviId = this.props.match && this.props.match.params && this.props.match.params.mviId && Number.parseInt(this.props.match.params.mviId, 10);
    this.props.getMviById(mviId);
  }

  componentWillUnmount() {
    unregisterProperty(MVIFeatureLevel);
  }

  render() {
    if (this.props.isLoading) {
      return <CanaryLoadingIndicator />;
    }
    else if (!this.props.mvi && this.props.error) {
      return <ErrorMessage error={this.props.error} />;
    }
    else if (!this.props.mvi) {
      return null;
    }

    return (
      <>
        <ErrorMessage error={this.props.error} />
        <Row>
          <Col sm={9} className="d-lg-flex align-items-center">
            <MviMonthStatus
              mvi={this.props.mvi}
              canEdit={this.props.canEdit}
              editDisabled={this.props.editDisabled}
              onWorkflowStatusChange={this.props.updateMviWorkflowStatus}
            />
            <MviReportChooser className="mt-2 mt-md-0 ml-lg-4" />
          </Col>
          <Col sm={3} className="d-flex align-items-center">
            <MviActionButton className="mt-2 mt-md-0 ml-md-auto" />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <CommentEdit />
            <MviCommentList />
          </Col>
        </Row>
        {this.props.hasReports ? <MviReport className="mt-3" /> : <NoReport className="mt-3" />}
        <NewIssueModal onIssueCreated={this.props.markAsComplete} />
      </>
    );
  }
}

MviEdit.propTypes = {
  featureLevel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  error: PropTypes.object,
  mvi: PropTypes.object,
  hasReports: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  getMviById: PropTypes.func.isRequired,
  updateMviWorkflowStatus: PropTypes.func.isRequired,
  markAsComplete: PropTypes.func.isRequired,
};

MviEdit.defaultProps = {
  error: null,
  mvi: null,
};

function mapStateToProps(state) {
  return {
    featureLevel: SiteSelectors.featureLevel(state, Features.MonthlyVisualInspections),
    isLoading: MviEditSelectors.isLoading(state),
    editDisabled: MviEditUberSelectors.isEditDisabled(state),
    error: MviEditUberSelectors.error(state),
    mvi: MviEditSelectors.item(state),
    hasReports: MviReportSelectors.hasReports(state),
    canEdit: SiteSelectors.canEditMvis(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMviById: mviId => dispatch(actions.getMviById(mviId)),
    updateMviWorkflowStatus: workflowStatus => {
      track('Inspection', {
        Component: 'Workflow dropdown',
        'Inspection action': 'Set workflow status',
        'New workflow status': workflowStatus,
      });
      return dispatch(actions.updateMviWorkflowStatus(workflowStatus));
    },
    markAsComplete: () => dispatch(actions.markAsComplete()),
  };
}

export default withTracking('Site', 'Inspection')(withRouter(connect(mapStateToProps, mapDispatchToProps)(MviEdit)));
