export default {
  RECEIVE_SITE_LINES: 'site-commands/receive_site_lines',
  RECEIVE_SITE_TANKS: 'site-commands/receive_site_tanks',

  INTENT_REQUEST_SENT: 'site-commands/intent_request_sent',
  INTENT_REQUEST_RESOLVE_SUCCESS: 'site-commands/intent_request_resolve_success',
  INTENT_REQUEST_RESOLVE_FAILED: 'site-commands/intent_request_resolve_failed',

  GET_COMMAND_REQUESTS_STARTED: 'site-commands/get_command_requests_started',
  RECEIVE_COMMAND_REQUESTS: 'site-commands/receive_command_requests',
  GET_COMMAND_REQUESTS_FAILED: 'site-commands/get_command_requests_failed',

  GET_INTENT_REQUESTS_STARTED: 'site-commands/get_intent_requests_started',
  RECEIVE_INTENT_REQUESTS: 'site-commands/receive_intent_requests',
  GET_INTENT_REQUESTS_FAILED: 'site-commands/get_intent_requests_failed',

  GET_ATG_INFO_STARTED: 'site-commands/get_atg_info',
  RECEIVE_ATG_INFO: 'site-commands/receive_atg_info',
  GET_ATG_INFO_FAILED: 'site-commands/get_atg_info_failed',
};
