import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import { withTracking } from '../../../../../../../shared/analytics';
import * as actions from '../actions';
import * as selectors from '../TankListSelectors';
import { TankListStep } from '../TankListConstants';
import Step1MatchTanksContainer from './Step1MatchTanksContainer';
import Step2ConfirmMatchesContainer from './Step2ConfirmMatchesContainer';
import Step3DisplayListContainer from './Step3DisplayListContainer';

class TankListContainer extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.props.getTankList();
  }

  render() {
    if (this.props.isLoading) {
      return (
        <PulseLoader color={'#F8E71C'} size={8} />
      );
    }
    else if (this.props.step === TankListStep.Step1MatchTanks) {
      return <Step1MatchTanksContainer />;
    }
    else if (this.props.step === TankListStep.Step2ConfirmMatches) {
      return <Step2ConfirmMatchesContainer />;
    }
    else if (this.props.step === TankListStep.Step3DisplayList) {
      return <Step3DisplayListContainer />;
    }
    return null;
  }
}

TankListContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  step: PropTypes.string.isRequired,

  getTankList: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    step: selectors.getStep(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTankList: () => dispatch(actions.getTankList()),
  };
}

export default withTracking('Site', 'Equipment - Tanks')(connect(mapStateToProps, mapDispatchToProps)(TankListContainer));
