import React from 'react';
import PropTypes from 'prop-types';

const DelimiterSpan = ({ delimiter }) => {
  let value = 'unknown';
  if (delimiter === ',') {
    value = 'comma';
  }
  else if (delimiter === '|') {
    value = 'pipe';
  }
  else if (delimiter === '\t') {
    value = 'tab';
  }
  return (<span>{value}</span>);
};

DelimiterSpan.propTypes = {
  delimiter: PropTypes.string.isRequired,
};

export default DelimiterSpan;
