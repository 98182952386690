import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from '../../../../../../../shared/components/compliance/StatusIcon';
import StatusText from '../../../../../../../shared/components/compliance/StatusText';

const renderTableRows = lines => lines.map((line) => (
  <tr key={line.lineId}>
    <td>
      <b>{line.elldSensorNumber} - {line.locationLabel}</b>
    </td>
    <td style={{ fontWeight: '400' }}>
      <StatusIcon size="1em" status={line.status} />
      <StatusText status={line.status} />
    </td>
  </tr>
));

const LinesTable = ({ lines, linesRollupStatus }) => {
  if (!lines || lines.length === 0) {
    return (
      <div style={{ textAlign: 'center' }} className="text-muted">
        { linesRollupStatus === 'PASSED'
          ? 'The site does not have electronically monitored lines.'
          : 'Canary did not find any lines to monitor at this station.'}
      </div>
    );
  }

  return (
    <table className="table canary-table borderless my-2">
      <thead>
        <tr>
          <th style={{ width: '20em' }}>Line</th>
          <th style={{ width: '15em' }}>Status</th>
        </tr>
      </thead>
      <tbody>
        {renderTableRows(lines)}
      </tbody>
    </table>
  );
};

LinesTable.propTypes = {
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      tankId: PropTypes.number,
      tankNumber: PropTypes.number,
      status: PropTypes.string,
      productLabel: PropTypes.string,
    }),
  ),
  linesRollupStatus: PropTypes.string,
};

LinesTable.defaultProps = {
  lines: [],
  linesRollupStatus: '',
};

export default LinesTable;
