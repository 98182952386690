import React from 'react';
import PropTypes from 'prop-types';
import DropdownList from 'react-widgets/lib/DropdownList';

import { MviWorkflowStatusTypes } from '../../../AppConstants';
import MviWorkflowStatus from './MviWorkflowStatus';

const values = Object.values(MviWorkflowStatusTypes);

const WorkflowItem = ({ item }) => <MviWorkflowStatus workflowStatus={item} />;

WorkflowItem.propTypes = {
  item: PropTypes.string.isRequired,
};

const MviWorkflowStatusDropdown = ({ workflowStatus, disabled, onChange, className }) => (
  <DropdownList
    data={values}
    value={workflowStatus}
    itemComponent={WorkflowItem}
    valueComponent={WorkflowItem}
    onChange={onChange}
    style={{ width: '13rem' }}
    disabled={disabled}
    containerClassName={className}
  />
);

MviWorkflowStatusDropdown.propTypes = {
  workflowStatus: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MviWorkflowStatusDropdown.defaultProps = {
  disabled: false,
  className: null,
};

export default MviWorkflowStatusDropdown;
