import { CommentOperationState } from './CommentConstants';

function stateSlice(state) {
  return (state && state.sites && state.sites.issues && state.sites.issues.edit && state.sites.issues.edit.comments) || {};
}

function operationState(state) {
  return stateSlice(state).commentOperationState;
}

export function isReady(state) {
  return operationState(state) === CommentOperationState.Ready;
}

export function isEditing(state) {
  return operationState(state) === CommentOperationState.Editing;
}

export function isSaving(state) {
  return operationState(state) === CommentOperationState.Saving;
}

export function comments(state) {
  return stateSlice(state).comments || [];
}
