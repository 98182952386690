function complianceRootState(state) {
  return (state && state.sites && state.sites.compliance && state.sites.compliance.root) || {};
}

export function isLatestComplianceLoading(state) {
  return complianceRootState(state).latestComplianceLoading || false;
}

export function latestCompliance(state) {
  return complianceRootState(state).latestCompliance || null;
}

export function latestComplianceError(state) {
  return complianceRootState(state).latestComplianceError || null;
}
