function stateSlice(state) {
  return (state && state.shared && state.shared.atgReports) || {};
}

export function isLoadingCommandNames(state) {
  return stateSlice(state).isLoadingCommandNames || false;
}

export function reports(state) {
  return stateSlice(state).reports || null;
}

export function tableOfContents(state) {
  return stateSlice(state).tableOfContents || null;
}
