import React from 'react';
import PropTypes from 'prop-types';
import {Input, Label} from 'reactstrap';

const PreferenceCheckbox = ({ labelText, checked, onChange, disabled, adminOnly }) => {
  return (
    <div className="ml-4" hidden={adminOnly}>
      <Label className="mb-2">
        <Input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
        {labelText}
      </Label>
    </div>
  );
};

PreferenceCheckbox.propTypes = {
  labelText: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  adminOnly: PropTypes.bool,
};

PreferenceCheckbox.defaultProps = {
  disabled: false,
  adminOnly: false,
};


export default PreferenceCheckbox;
