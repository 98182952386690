import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from './Label';

class LabelList extends Component {
  render() {
    if (!this.props.labels || !this.props.labels.length) {
      return null;
    }
    const lastIndex = this.props.labels.length - 1;
    return this.props.labels.map((current, index) => {
      const labelClass = classNames({ 'mr-1': index !== lastIndex });
      return <Label key={current.labelText || current.text} label={current} className={labelClass} />;
    });
  }
}

LabelList.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape({
    labelText: PropTypes.string,
    color: PropTypes.string,
  })),
};

LabelList.defaultProps = {
  labels: null,
};

export default LabelList;
