export default {
    // receive site with sensors (initial load)
  REQUEST_SITE_SENSORS: 'atg-sensors/request_site_sensors',
  RECEIVE_SITE_SENSORS: 'atg-sensors/receive_site_sensors',
  RECEIVE_SITE_SENSORS_ERROR: 'atg-sensors/receive_site_sensors_error',

  REQUEST_SITE_TANKS: 'atg-sensors/request_site_tanks',
  RECEIVE_SITE_TANKS: 'atg-sensors/receive_site_tanks',

  RECEIVE_EDIT_SENSOR: 'atg-sensors/receive_edit_sensor',

  TOGGLE_EDIT_EQUIPMENT_MODAL: 'atg-sensors/toggle_edit_equipment_modal',

  ASSOCIATE_EQUIPMENT: 'atg-sensors/associate_equipment',

  BEGIN_SAVE_ASSOCIATED_EQUIPMENT: 'atg-sensors/begin_save_associated_equipment',
  SAVE_ASSOCIATED_EQUIPMENT_FAILURE: 'atg-sensors/save_associated_equipment_failure',

};
