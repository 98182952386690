import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper/index';

import SitePickerModalButton from '../../../../../../shared/components/site-picker/containers/SitePickerModalButton';
import { Column, StatefulTable, StringColumn } from '../../../../../../shared/components/table';
import FuelHaulerSiteModal from './FuelHaulerSiteModal';
import FileReaderButton from '../../../../../../shared/components/file-reader/FileReaderButton';

const InitialState = {
  modalOpen: false,
  fuelHaulerSite: null,
  index: null,
};

class FuelHaulerSites extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.state = InitialState;

    this.handleAddSites = this.handleAddSites.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleFuelHaulerSiteUpdate = this.handleFuelHaulerSiteUpdate.bind(this);
    this.handleFuelHaulerSiteSave = this.handleFuelHaulerSiteSave.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
    this.handleSiteDelete = this.handleSiteDelete.bind(this);
  }

  handleAddSites(newSites) {
    const updatedFuelHauler = update(this.props.selectedFuelHauler, {
      sites: {
        $push: newSites,
      },
    });
    this.props.updateSelectedFuelHauler(updatedFuelHauler);
  }

  handleModalOpen(fuelHaulerSite) {
    const index = this.props.selectedFuelHauler.sites.indexOf(fuelHaulerSite);
    this.setState({ modalOpen: true, fuelHaulerSite, index });
  }

  handleFuelHaulerSiteUpdate(fuelHaulerSite) {
    this.setState({ fuelHaulerSite });
  }

  handleFuelHaulerSiteSave() {
    const updatedFuelHauler = update(this.props.selectedFuelHauler, {
      sites: {
        [this.state.index]: {
          $set: this.state.fuelHaulerSite,
        },
      },
    });
    this.props.updateSelectedFuelHauler(updatedFuelHauler);
    this.handleModalCancel();
  }

  handleModalCancel() {
    this.setState(InitialState);
  }

  handleSiteDelete(site) {
    const index = this.props.selectedFuelHauler.sites.indexOf(site);
    const updatedFuelHauler = update(this.props.selectedFuelHauler, {
      sites: {
        $splice: [[index, 1]],
      },
    });
    this.props.updateSelectedFuelHauler(updatedFuelHauler);
  }

  render() {
    if (!this.props.selectedFuelHauler) {
      return null;
    }

    return (
      <div className="mt-4">
        <div className="d-md-flex justify-content-end mb-2">
          <SitePickerModalButton onSave={this.handleAddSites} className="d-block mr-0 mr-md-2 mb-2 mb-md-0" buttonClassName="w-100 w-md-auto" />
          <FileReaderButton id="siteFileUpload" buttonOutline buttonText="Import" onUpload={this.props.importSites} buttonClassName="w-100 w-md-auto mr-0 mr-md-2 mb-2 mb-md-0" />
          <Button color="primary" outline size="sm" onClick={this.props.exportSelectedFuelHaulerSites} className="w-100 w-md-auto">Export</Button>
        </div>
        <StatefulTable initialSortColumn="name" initialSortDirection="asc" data={this.props.selectedFuelHauler.sites}>
          <StringColumn property="id" title="ID" width="5rem" />
          <StringColumn property="siteName" title="Name" width="7rem" />
          <StringColumn property="nickname" title="Nickname" />
          <StringColumn property="fuelHaulerExternalId" title="Fuel Hauler ID" width="7rem" />
          <Column columnId="edit" render={r => <Button color="link" size="sm" onClick={() => this.handleModalOpen(r)}>Edit</Button>} width="5rem" />
          <Column columnId="delete" render={r => <Button color="link" size="sm" onClick={() => this.handleSiteDelete(r)}>Delete</Button>} width="5rem" />
        </StatefulTable>
        <FuelHaulerSiteModal
          open={this.state.modalOpen}
          fuelHaulerSite={this.state.fuelHaulerSite}
          onSave={this.handleFuelHaulerSiteSave}
          onCancel={this.handleModalCancel}
          onFuelHaulerSiteEdit={this.handleFuelHaulerSiteUpdate}
        />
      </div>
    );
  }
}

FuelHaulerSites.propTypes = {
  selectedFuelHauler: PropTypes.object,
  updateSelectedFuelHauler: PropTypes.func.isRequired,
  exportSelectedFuelHaulerSites: PropTypes.func.isRequired,
  importSites: PropTypes.func.isRequired,
};

FuelHaulerSites.defaultProps = {
  selectedFuelHauler: null,
};

export default FuelHaulerSites;
