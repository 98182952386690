export function isModalOpen(state) {
  return (state && state.sites && state.sites.newIssue && state.sites.newIssue.modalOpen) || false;
}

export function isSiteEditDisabled(state) {
  return (state && state.sites && state.sites.newIssue && state.sites.newIssue.siteEditDisabled) || false;
}

export function issue(state) {
  return (state && state.sites && state.sites.newIssue && state.sites.newIssue.issue) || null;
}

export function isSaving(state) {
  return (state && state.sites && state.sites.newIssue && state.sites.newIssue.saving) || false;
}

export function error(state) {
  return (state && state.sites && state.sites.newIssue && state.sites.newIssue.error) || null;
}
