import OrganizationPermissions from '../../../../../organization-permissions';
import { LLDTypes } from '../../../SiteConstants';

export const IntentControls = {
  LINE_SELECT: 'LINE_SELECT',
  TEST_TYPE_SELECT: 'TEST_TYPE_SELECT',
};

export const LineIntents = {
  // plld
  StartPlldTest: {
    intentName: 'START_PLLD_TEST',
    description: 'Start PLLD Test (S087)',
    permission: OrganizationPermissions.AtgCommandsSet,
    controls: {
      [IntentControls.LINE_SELECT]: { lldType: LLDTypes.PressurizedLLD, allowAllLines: false },
      [IntentControls.TEST_TYPE_SELECT]: null,
    }
  },
  StopPlldTest: {
    intentName: 'STOP_PLLD_TEST',
    description: 'Stop PLLD Test (S082)',
    permission: OrganizationPermissions.AtgCommandsSet,
    controls: {
      [IntentControls.LINE_SELECT]: { lldType: LLDTypes.PressurizedLLD, allowAllLines: false },
    }
  },
  GetPressureLineLeakResults: {
    intentName: 'GET_PRESSURE_LINE_LEAK_RESULTS',
    description: 'Pressure Line Leak Test Results - 0.20 (I37300)',
    permission: OrganizationPermissions.AtgCommandsInquire,
    controls: {
      [IntentControls.LINE_SELECT]: { lldType: LLDTypes.PressurizedLLD, allowAllLines: true },
    }
  },
  GetPressureLineLeakHistory: {
    intentName: 'GET_PRESSURE_LINE_LEAK_HISTORY',
    description: 'Pressure Line Leak Test History - 0.20 (I37400)',
    permission: OrganizationPermissions.AtgCommandsInquire,
    controls: {
      [IntentControls.LINE_SELECT]: { lldType: LLDTypes.PressurizedLLD, allowAllLines: true },
    }
  },
  GetPlldResults: {
    intentName: 'GET_PLLD_RESULTS',
    description: 'Get PLLD Test Results (I383)',
    permission: OrganizationPermissions.AtgCommandsInquire,
    controls: {
      [IntentControls.LINE_SELECT]: { lldType: LLDTypes.PressurizedLLD, allowAllLines: true },
    }
  },

  // wplld
  StartWplldTest: {
    intentName: 'START_WPLLD_TEST',
    description: 'Start WPLLD Test (S088)',
    permission: OrganizationPermissions.AtgCommandsSet,
    controls: {
      [IntentControls.LINE_SELECT]: { lldType: LLDTypes.WirelessLLD, allowAllLines: false },
      [IntentControls.TEST_TYPE_SELECT]: null,
    }
  },
  StopWplldTest: {
    intentName: 'STOP_WPLLD_TEST',
    description: 'Stop WPLLD Test (S084)',
    permission: OrganizationPermissions.AtgCommandsSet,
    controls: {
      [IntentControls.LINE_SELECT]: { lldType: LLDTypes.WirelessLLD, allowAllLines: false },
    }
  },
  GetWplldResults: {
    intentName: 'GET_WPLLD_RESULTS',
    description: 'Get WPLLD Test Results (I388)',
    permission: OrganizationPermissions.AtgCommandsInquire,
    controls: {
      [IntentControls.LINE_SELECT]: { lldType: LLDTypes.WirelessLLD, allowAllLines: true },
    }
  },
};
