import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import { AdminReducer } from './admin';
import IssueListReducer from './user/issue-list/IssueListReducer';
import MviReducer from './user/mvi/MviReducer';
import { AuthenticationReducer } from './authentication';
import { SitesReducer } from './user/sites';
import SiteListReducer from './user/site-list/SiteListReducer';
import { ExportReducer } from './user/exports';
import PreferencesReducer from './user/preferences/PreferencesReducer';
import ComplianceReducer from './user/compliance/ComplianceReducer';
import InventoryReducer from './user/inventory/InventoryReducer';
import SharedReducer from './shared/SharedReducer';

const RootReducer = combineReducers({
  admin: AdminReducer,
  authentication: AuthenticationReducer,
  issueList: IssueListReducer,
  mvi: MviReducer,
  router: routerReducer,
  sites: SitesReducer,
  siteList: SiteListReducer,
  exports: ExportReducer,
  preferences: PreferencesReducer,
  compliance: ComplianceReducer,
  inventory: InventoryReducer,
  shared: SharedReducer,
});

export default RootReducer;
