import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import PriorityIndicator from '../../../shared/components/PriorityIndicator';
import WorkflowStatus from '../../../shared/components/WorkflowStatus';
import DispatchStatusDate from '../../../shared/components/dispatch/DispatchStatusDate';
import {
  Column,
  CanaryTable,
  IntegerColumn,
  StringColumn,
  TimestampColumn,
  LabelListColumn,
  DateOrTimeColumn
} from '../../../shared/components/table';
import { IssueTableColumns } from '../IssueListConstants';
import { IssueTypes } from '../../../AppConstants';

const IssueTable = React.memo(({ dataLoading, issues, columns, sortColumnId, sortDirection, onSortChange, currentPage, onPageChange, pageSize, pageCount, onIssueSelected }) => {
  const columnIds = columns.map(current => current.id);
  const hasColumn = col => columnIds.indexOf(col.id) !== -1;
  return (
    <CanaryTable
      dataLoading={dataLoading}
      data={issues}
      sortColumnId={sortColumnId}
      sortDirection={sortDirection}
      onSortChange={onSortChange}
      currentPage={currentPage}
      onPageChange={onPageChange}
      pageSize={pageSize}
      pageCount={pageCount}
      onRowClick={onIssueSelected}
    >
      {hasColumn(IssueTableColumns.Priority)
        ? (<Column columnId={IssueTableColumns.Priority.id} title={IssueTableColumns.Priority.shortName} render={issue => <PriorityIndicator priority={issue.priority} />} sortable width="3%" />)
        : null}

      {hasColumn(IssueTableColumns.Customer)
        ? (<StringColumn columnId={IssueTableColumns.Customer.id} property="customer.name" title={IssueTableColumns.Customer.shortName} sortable width="9%" />)
        : null}

      {hasColumn(IssueTableColumns.SiteNickname)
        ? (<StringColumn columnId={IssueTableColumns.SiteNickname.id} property="site.nickname" title={IssueTableColumns.SiteNickname.shortName} sortable width="7%" />)
        : null}

      {hasColumn(IssueTableColumns.SiteState)
        ? (<StringColumn columnId={IssueTableColumns.SiteState.id} property="site.state" title={IssueTableColumns.SiteState.shortName} sortable width="3%" />)
        : null}

      {hasColumn(IssueTableColumns.IssueType)
        ? (<Column columnId={IssueTableColumns.IssueType.id} title={IssueTableColumns.IssueType.shortName} render={issue => <Badge>{IssueTypes[issue.issueType]}</Badge>} sortable width="7%" />)
        : null}

      {hasColumn(IssueTableColumns.Id)
        ? (<StringColumn columnId={IssueTableColumns.Id.id} property="id" title={IssueTableColumns.Id.shortName} sortable width="3%" />)
        : null}

      {hasColumn(IssueTableColumns.Description)
        ? (<Column columnId={IssueTableColumns.Description.id} render={i => <strong>{i.description}</strong>} title={IssueTableColumns.Description.shortName} sortable width="11%" />)
        : null}

      {hasColumn(IssueTableColumns.Created)
        ? (<DateOrTimeColumn columnId={IssueTableColumns.Created.id} property="created" title={IssueTableColumns.Created.shortName} sortable width="6%" />)
        : null}

      {hasColumn(IssueTableColumns.DaysIdle)
        ? (<IntegerColumn columnId={IssueTableColumns.DaysIdle.id} property="daysIdle" title={IssueTableColumns.DaysIdle.shortName} sortable width="4%" />)
        : null}

      {hasColumn(IssueTableColumns.LatestAlarmDate)
        ? (<DateOrTimeColumn columnId={IssueTableColumns.LatestAlarmDate.id} property="latestAlarmDate" title={IssueTableColumns.LatestAlarmDate.shortName} sortable width="4%" />)
        : null}

      {hasColumn(IssueTableColumns.WorkflowStatus)
        ? (
          <Column
            columnId={IssueTableColumns.WorkflowStatus.id}
            render={issue => <WorkflowStatus workflowStatus={issue.workflow.status} />}
            title={IssueTableColumns.WorkflowStatus.shortName}
            width="5%"
            sortable
          />
        )
        : null}

      {hasColumn(IssueTableColumns.WorkflowStatusDate)
        ? (<TimestampColumn columnId={IssueTableColumns.WorkflowStatusDate.id} property="workflow.statusDate" title={IssueTableColumns.WorkflowStatusDate.shortName} sortable width="8%" />)
        : null}

      {hasColumn(IssueTableColumns.WorkflowUsername)
        ? (<StringColumn columnId={IssueTableColumns.WorkflowUsername.id} property="workflow.user.username" title={IssueTableColumns.WorkflowUsername.shortName} sortable width="5%" />)
        : null}

      {hasColumn(IssueTableColumns.LastModifiedDate)
        ? (<DateOrTimeColumn columnId={IssueTableColumns.LastModifiedDate.id} property="lastModified.date" title={IssueTableColumns.LastModifiedDate.shortName} sortable width="4%" />)
        : null}

      {hasColumn(IssueTableColumns.LastModificationType)
        ? (<StringColumn columnId={IssueTableColumns.LastModificationType.id} property="lastModified.modificationType" title={IssueTableColumns.LastModificationType.shortName} sortable width="4%" />)
        : null}

      {hasColumn(IssueTableColumns.LastModifiedUser)
        ? (<StringColumn columnId={IssueTableColumns.LastModifiedUser.id} property="lastModified.user.username" title={IssueTableColumns.LastModifiedUser.shortName} sortable width="5%" />)
        : null}

      {hasColumn(IssueTableColumns.DispatchStatus)
        ? (<Column columnId={IssueTableColumns.DispatchStatus.id} title={IssueTableColumns.DispatchStatus.shortName} render={issue => <DispatchStatusDate dispatch={issue.dispatch} />} sortable width="5%" />)
        : null}

      {hasColumn(IssueTableColumns.SiteLabels)
        ? (<LabelListColumn columnId={IssueTableColumns.SiteLabels.id} property="siteLabels" title={IssueTableColumns.SiteLabels.shortName} width="7%" />)
        : null}

    </CanaryTable>
  );
});

IssueTable.propTypes = {
  dataLoading: PropTypes.bool,
  issues: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  sortColumnId: PropTypes.string,
  sortDirection: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onIssueSelected: PropTypes.func.isRequired,
};

IssueTable.defaultProps = {
  dataLoading: false,
  sortColumnId: null,
  sortDirection: null,
};

export default IssueTable;
