export function getDataIndex(element) {
  let currentElement = element;
  let count = 0;

  while (currentElement && count < 10) {
    if (currentElement.dataset && currentElement.dataset.index) {
      return Number.parseInt(currentElement.dataset.index, 10);
    }
    count++;
    currentElement = currentElement.parentElement;
  }
  return null;
}
