import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, UncontrolledAlert } from 'reactstrap';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import NonProdWarning from '../../../../../../../../shared/components/non-prod-warning/NonProdWarning';
import ErrorMessage from '../../../../../../../../shared/components/error-message/ErrorMessage';
import ConfirmModal from '../../../../../../../../shared/components/confirm-modal/ConfirmModal';
import { TableStyles } from '../../../../../../../../shared/components/CanaryTable';
import CanaryTableAndSummary from '../../../../../../../../shared/components/CanaryTableAndSummary';
import Toggle from '../../../../../../../../shared/components/buttons/Toggle';
import FormattedTimestamp from '../../../../../../../../shared/components/date/FormattedTimestamp';
import { SystemUserId } from '../../../../../../../../AppConstants';
import * as navActions from '../../../../../actions';
import * as orgEditSelectors from '../../../OrganizationEditSelectors';
import * as actions from '../actions';
import * as selectors from '../UserSelectors';

const headers = [
  {
    display: 'USERNAME',
    columnStyle: TableStyles.ColumnStyles.FIRST_COLUMN,
    width: '22%',
  },
  {
    display: 'EMAIL ADDRESS',
    columnStyle: TableStyles.ColumnStyles.TEXT,
    width: '32%',
  },
  {
    display: 'ROLE',
    columnStyle: TableStyles.ColumnStyles.TEXT,
    width: '19%',
  },
  {
    display: 'CREATED',
    columnStyle: TableStyles.ColumnStyles.TEXT,
    width: '18%',
  },
  {
    display: 'ACTIVE',
    columnStyle: TableStyles.ColumnStyles.NONTEXT,
    width: '9%',
  }
];

function getRoleName(membership) {
  const userType = membership.user.userType;
  if (userType === 'admin') {
    return 'canary admin';
  }
  else if (membership.isAdmin) {
    return 'org admin';
  }
  return 'user';
}

const UserFilterTypes = {
  ActiveUsers: 'Show Active Users',
  AllUsers: 'Show All Users',
};

class UsersTable extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      userFilterType: UserFilterTypes.ActiveUsers,
      confirmModal: false,
      user: null,
    };
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.confirmDisableUser = this.confirmDisableUser.bind(this);
    this.setUserFilterType = this.setUserFilterType.bind(this);
  }

  setUserFilterType(event) {
    this.setState({
      userFilterType: event.target.value,
    });
  }

  handleToggleUserEnabled(user) {
    if (user.active) {
      this.openConfirmModal(user);
    }
    else {
      this.props.toggleUserEnabled(user);
    }
  }

  openConfirmModal(user) {
    this.setState({
      confirmModal: true,
      user,
    });
  }

  cancelModal() {
    this.setState({
      confirmModal: false,
      user: null,
    });
  }

  confirmDisableUser() {
    this.cancelModal();
    this.props.toggleUserEnabled(this.state.user);
  }

  renderUserLink(user) {
    if (user.id === SystemUserId) {
      return <span>{user.username}</span>;
    }
    return <Button color="link" onClick={() => this.props.navToUserEditor(user)}>{user.username}</Button>;
  }

  renderUserToggle(user) {
    if (user.id === SystemUserId) {
      return null;
    }
    return <Toggle value={!user.active} onClick={() => this.handleToggleUserEnabled(user)} />;
  }

  renderNonProdWarning(filteredData) {
    const hasAnyUsers = !!(this.props.selectedOrganization.memberships && this.props.selectedOrganization.memberships.length);
    if (hasAnyUsers && !filteredData.length && this.state.userFilterType === UserFilterTypes.ActiveUsers) {
      return (
        <NonProdWarning>
          <UncontrolledAlert color="warning">
            All users in this organization have been disabled because this is a dev environment.
          </UncontrolledAlert>
        </NonProdWarning>
      );
    }
    return null;
  }

  render() {
    if (!this.props.selectedOrganization) {
      return (
        <PulseLoader color="#F8E71C" size={8} />
      );
    }

    const controls = (
      <div className="d-flex">
        <select value={this.state.userFilterType} className="form-control form-control-sm" onChange={this.setUserFilterType}>
          <option value={UserFilterTypes.ActiveUsers}>{UserFilterTypes.ActiveUsers}</option>
          <option value={UserFilterTypes.AllUsers}>{UserFilterTypes.AllUsers}</option>
        </select>
        <Button className="ml-2 flex-shrink-0" color="primary" size="sm" onClick={this.props.navToNewUser}>ADD A NEW USER</Button>
      </div>
    );

    const memberships = this.props.selectedOrganization.memberships || [];
    const filteredData = memberships
      .filter(current => this.state.userFilterType === UserFilterTypes.AllUsers || current.user.active)
      .map((membership) => {
        const user = membership.user;

        return {
          metadata: {
            rowStyle: user.active ? TableStyles.RowStyles.ACTIVE : TableStyles.RowStyles.INACTIVE,
          },
          columns: [
            { display: this.renderUserLink(user) },
            { display: user.email },
            { display: getRoleName(membership) },
            { display: <FormattedTimestamp time={user.created} dateOnly /> },
            { display: this.renderUserToggle(user), columnStyle: TableStyles.ColumnStyles.NONTEXT }
          ],
        };
      });
    const summary = `${filteredData.length} Users`;
    return (
      <div>
        <ErrorMessage title="User update failed" error={this.props.error} />
        {this.renderNonProdWarning(filteredData)}
        <CanaryTableAndSummary summary={summary} header={headers} controls={controls} data={filteredData} prefix="users" />
        <ConfirmModal
          isOpen={this.state.confirmModal}
          headerText={`Disable ${this.state.user && this.state.user.username}`}
          body="This user will not be able to login if their account is disabled."
          onConfirm={this.confirmDisableUser}
          onCancel={this.cancelModal}
        />
      </div>
    );
  }
}

UsersTable.propTypes = {
  error: PropTypes.object,
  selectedOrganization: PropTypes.shape({
    memberships: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      isAdmin: PropTypes.bool,
      user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        username: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        created: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
      })
    }))
  }),
  toggleUserEnabled: PropTypes.func.isRequired,
  navToUserEditor: PropTypes.func.isRequired,
  navToNewUser: PropTypes.func.isRequired,
};

UsersTable.defaultProps = {
  error: null,
  selectedOrganization: null,
};

function mapStateToProps(state) {
  return {
    error: selectors.error(state),
    selectedOrganization: orgEditSelectors.selectedOrganization(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    toggleUserEnabled: user => dispatch(actions.toggleUserEnabled(user)),
    sendPasswordResetEmail: user => dispatch(actions.sendPasswordResetEmail(user)),
    resetPassword: (user, newPassword) => dispatch(actions.resetPassword(user, newPassword)),
    navToUserEditor: user => dispatch(navActions.navToUserEditor(ownProps.match.params.organizationId, user)),
    navToNewUser: () => dispatch(navActions.navToNewUser(ownProps.match.params.organizationId)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersTable));
