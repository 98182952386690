import { connect } from 'react-redux';

import { track } from '../../../../../../../../shared/analytics';
import DocList from '../../../../../../../../shared/components/docs/DocList';
import * as SiteSelectors from '../../../../../../SiteSelectors';
import * as MviEditUberSelectors from '../../MviEditUberSelectors';
import MviReportSelectors from './MviReportSelectors';
import * as SiteDocActions from '../../../../../documents/actions';

function mapStateToProps(state) {
  const currentReport = MviReportSelectors.item(state);
  const documents = (currentReport && currentReport.documents) || [];
  return {
    item: currentReport,
    itemText: 'MVI report',
    documents,
    canEditItem: SiteSelectors.canEditMvis(state),
    disabled: MviEditUberSelectors.isEditDisabled(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onNavToDocument: doc => {
      track('Document', {
        Component: 'Inspection documents',
        'Document action': 'Nav to doc',
      });
      return dispatch(SiteDocActions.navToDocument(doc));
    },
    onDocDelete: doc => {
      track('Document', {
        Component: 'Inspection documents',
        'Document action': 'Delete doc',
      });
      return dispatch(SiteDocActions.deleteDocument(doc));
    },
    onDocUnlink: (doc, mviReport) => {
      track('Document', {
        Component: 'Inspection documents',
        'Document action': 'Unlink doc',
      });
      return dispatch(SiteDocActions.removeMviReport(doc, mviReport));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocList);
