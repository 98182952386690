import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class CallCommandButton extends Component {
  constructor(defaultProps) {
    super(defaultProps);

    this.state = {
      commandText: '',
    };

    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    this.handleTextFieldKeyPress = this.handleTextFieldKeyPress.bind(this);
    this.sendCommand = this.sendCommand.bind(this);
  }


  handleTextFieldChange(event) {
    this.setState({ commandText: event.target.value.toUpperCase() });
  }

  handleTextFieldKeyPress(event) {
    if (event.charCode === 13) this.sendCommand();
  }

  sendCommand() {
    this.props.onRawCommandSent(this.state.commandText);
  }

  render() {
    return (
      <div
        style={{
          borderWidth: '1px',
          borderColor: '#979797',
          borderStyle: 'solid',
          borderRadius: '0.25em',
          background: '#ffffff',
          padding: '0.5em',
        }}
        className="mb-1"
      >
        <span
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            textAlign: 'left',
            padding: 0,
            margin: 0,
            outline: 'none',
          }}
        >
          Call Command
        </span>
        <div>
          <div className="row">
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={this.handleTextFieldChange}
                value={this.state.commandText}
                onKeyPress={this.handleTextFieldKeyPress}
              />
            </div>
            <div className="col-sm-4 justify-content-center">
              <Button color="primary" className="w-100" onClick={this.sendCommand}>Send</Button>
            </div>
            <div className="col-sm-12">
              <small><a href="https://canarycompliance.zendesk.com/hc/en-us/articles/360037228852-Serial-Commands" rel="noopener noreferrer" target="_blank">Look up a command</a></small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CallCommandButton.propTypes = {
  onRawCommandSent: PropTypes.func.isRequired,
};

export default CallCommandButton;
