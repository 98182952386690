import {buildStringSort, buildNumberSort, composeSort, buildEnumSort} from '../../shared/SortUtils';
import { SiteSummaryFields } from '../../client';
import {MviWorkflowStatusTypes, SiteConnectionTypes} from '../../AppConstants';

function sortDispatches(s1, s2) {
  if (!s1.openDispatches && !s2.openDispatches) {
    return 0;
  }

  const s1Dispatch = s1.openDispatches && s1.openDispatches.length && s1.openDispatches[s1.openDispatches.length - 1];
  const s2Dispatch = s2.openDispatches && s2.openDispatches.length && s2.openDispatches[s2.openDispatches.length - 1];
  if (!s1Dispatch && !s2Dispatch) {
    return 0;
  }
  else if (s1Dispatch && !s2Dispatch) {
    return -1;
  }
  else if (!s1Dispatch && s2Dispatch) {
    return 1;
  }
  return -s1Dispatch.statusDate.localeCompare(s2Dispatch.statusDate);
}

export const SiteTableColumns = {
  ConnectionInfo: {
    id: 'connectionInfo',
    name: 'Connection (CXN) type and status',
    shortName: 'CXN',
    apiFields: [SiteSummaryFields.ConnectionType, SiteSummaryFields.ConnectionStatus],
    sortFunc: composeSort(buildStringSort('connectionStatus'), buildStringSort('connectionType')),
    exportFunc: site => {
      if (!site.connectionType) {
        return 'not connected';
      }

      let connectionType = site.connectionType;
      if (site.connectionType === SiteConnectionTypes.PARTICLE) {
        connectionType = 'Canary';
      }
      return `${connectionType} - ${site.connectionStatus}`;
    },
  },
  ConnectionType: {
    id: 'connectionType',
    name: 'Connection (CXN) type',
    shortName: 'CXN Type',
    apiFields: [SiteSummaryFields.ConnectionType],
    sortFunc: buildStringSort('connectionType'),
    exportFunc: site => {
      if (!site.connectionType) {
        return 'not connected';
      }

      if (site.connectionType === SiteConnectionTypes.PARTICLE) {
        return 'Canary';
      }
      return site.connectionType;
    },
  },
  ConnectionStatus: {
    id: 'connectionStatus',
    name: 'Connection (CXN) status',
    shortName: 'CXN status',
    apiFields: [SiteSummaryFields.ConnectionStatus],
    sortFunc: buildStringSort('connectionStatus'),
    exportFunc: site => site.connectionStatus || '',
  },
  Customer: {
    id: 'customer',
    name: 'Customer name',
    shortName: 'Customer',
    apiFields: [SiteSummaryFields.Customer],
    sortFunc: buildStringSort('customer.name'),
    exportFunc: site => (site.customer && site.customer.name) || '',
  },
  Nickname: {
    id: 'nickname',
    name: 'Site',
    shortName: 'Site',
    apiFields: [SiteSummaryFields.Nickname],
    sortFunc: buildStringSort('nickname'),
  },
  State: {
    id: 'state',
    name: 'State',
    shortName: 'State',
    apiFields: [SiteSummaryFields.State],
    sortFunc: buildStringSort('state'),
  },
  ActiveAlarmCount: {
    id: 'activeAlarmCount',
    name: 'Active alarms (count)',
    shortName: 'Alarms',
    apiFields: [SiteSummaryFields.ActiveAlarmCount],
    sortFunc: buildNumberSort('activeAlarmCount'),
    exportFunc: site => site.activeAlarmCount || '0',
  },
  OpenIssueCount: {
    id: 'openIssueCount',
    name: 'Open issues (count)',
    shortName: 'Issues',
    apiFields: [SiteSummaryFields.OpenIssueCount],
    sortFunc: buildNumberSort('openIssueCount'),
    exportFunc: site => site.openIssueCount || '0',
  },
  WorkOrders: {
    id: 'workOrders',
    name: 'Open work orders',
    shortName: 'Work Orders',
    apiFields: [SiteSummaryFields.OpenDispatches],
    sortFunc: sortDispatches,
    exportFunc: site => (site.openDispatches && site.openDispatches.length) || '0'
  },
  ComplianceReportStatus: {
    id: 'complianceReportStatus',
    name: 'Compliance report status',
    shortName: 'Compliance',
    apiFields: [SiteSummaryFields.ComplianceReportStatus],
    sortFunc: buildStringSort('complianceReportStatus'),
    exportFunc: site => {
      if (!site.complianceReportStatus) {
        return 'no data';
      }
      else if (site.complianceReportStatus === 'LATE') {
        return 'PASSED';
      }
      return site.complianceReportStatus;
    }
  },
  MviReportStatus: {
    id: 'mviReportStatus',
    name: 'MVI report status',
    shortName: 'MVI',
    apiFields: [SiteSummaryFields.MviStatus],
    sortFunc: buildEnumSort('mviStatus', MviWorkflowStatusTypes.Completed, MviWorkflowStatusTypes.NotRequired, MviWorkflowStatusTypes.NotPerformed, MviWorkflowStatusTypes.ReadyForReview, MviWorkflowStatusTypes.Pending),
    exportFunc: site => site.mviStatus || 'no data',
  },
  Labels: {
    id: 'labels',
    name: 'Site label',
    shortName: 'Site label',
    apiFields: [SiteSummaryFields.Labels],
    exportFunc: site => {
      if (!site.labels || !site.labels.length) {
        return '';
      }
      return site.labels.map(l => l.labelText).join(' ');
    }
  },
};

export const DefaultTableColumns = [
  SiteTableColumns.ConnectionInfo,
  SiteTableColumns.Nickname,
  SiteTableColumns.ActiveAlarmCount,
  SiteTableColumns.OpenIssueCount,
  SiteTableColumns.WorkOrders,
  SiteTableColumns.ComplianceReportStatus,
  SiteTableColumns.MviReportStatus,
  SiteTableColumns.Labels
];
