import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedComplianceStatuses = ({ selectedComplianceStatuses, disabled, deselectComplianceStatus }) => <FilterItemList items={selectedComplianceStatuses} textField="text" disabled={disabled} onRemoveItem={deselectComplianceStatus} />;

SelectedComplianceStatuses.propTypes = {
  selectedComplianceStatuses: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool.isRequired,
  deselectComplianceStatus: PropTypes.func.isRequired,
};

SelectedComplianceStatuses.defaultProps = {
  selectedComplianceStatuses: [],
};

export default SelectedComplianceStatuses;
