import objectPath from 'object-path';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

export const withDirection = sortFunc => direction => (first, second) => {
  const result = sortFunc(first, second);
  if (direction === 'desc' && result !== 0) {
    return -result;
  }
  return result;
};

export function genericSort(first, second) {
  if (first === second) {
    return 0;
  }
  else if (isNil(first) && !isNil(second)) {
    return 1;
  }
  else if (!isNil(first) && isNil(second)) {
    return -1;
  }
  else if (isNil(first) && isNil(second)) {
    return 0;
  }
  else if (isNumber(first) && isNumber(second)) {
    return first - second;
  }
  else if (isString(first) && isString(second)) {
    return first.toLowerCase().localeCompare(second.toLowerCase());
  }
  else if (first < second) {
    return -1;
  }
  else if (first > second) {
    return 1;
  }
  return 0;
}

export const buildGenericSort = property => (firstRowData, secondRowData) => {
  const firstVal = objectPath.get(firstRowData, property);
  const secondVal = objectPath.get(secondRowData, property);
  return genericSort(firstVal, secondVal);
};

export const buildStringSort = (property) => (first, second) => {
  const firstVal = objectPath.get(first, property);
  const secondVal = objectPath.get(second, property);
  if (!firstVal && !secondVal) {
    return 0;
  }
  else if (!isNil(firstVal) && isNil(secondVal)) {
    return -1;
  }
  else if (isNil(firstVal) && !isNil(secondVal)) {
    return 1;
  }
  return firstVal.toLowerCase().localeCompare(secondVal.toLowerCase());
};

export const buildNumberSort = (property) => (first, second) => {
  const firstVal = objectPath.get(first, property, 0);
  const secondVal = objectPath.get(second, property, 0);
  return firstVal - secondVal;
};

export const buildEnumSort = (property, ...enumValues) => (first, second) => {
  const firstVal = objectPath.get(first, property);
  const secondVal = objectPath.get(second, property);
  if (isNil(first) && isNil(second)) {
    return 0;
  }
  else if (!isNil(firstVal) && isNil(secondVal)) {
    return -1;
  }
  else if (isNil(firstVal)) {
    return 1;
  }

  return enumValues.indexOf(firstVal) - enumValues.indexOf(secondVal);
};

export const composeSort = (...sortFuncs) => (first, second) => {
  for (let i = 0; i < sortFuncs.length; i++) {
    const sortFunc = sortFuncs[i];
    const val = sortFunc(first, second);
    if (val !== 0) {
      return val;
    }
  }
  return 0;
};
