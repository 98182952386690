import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import OrganizationDropdown from '../../organization-dropdown/components/OrganizationDropdown';
import * as selectors from '../SitePickerSelectors';
import * as actions from '../actions';
import ListFilteringSiteAutoSuggest from './ListFilteringSiteAutoSuggest';
import SelectedSites from '../components/SelectedSites';


class SitePicker extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.props.clearCustomer();
    this.props.getCustomers();
  }

  render() {
    if (this.props.isCustomerListLoading) {
      return (
        <PulseLoader color={'#F8E71C'} size={8} />
      );
    }

    return (
      <div>
        <h5>Customer</h5>
        <OrganizationDropdown
          organizationList={this.props.customerList}
          selectedOrganization={this.props.selectedCustomer}
          onOrganizationSelect={this.props.selectCustomer}
        />

        {this.props.selectedCustomer ?
          <div>
            <h5 className="mt-1">Site</h5>
            {this.props.isSiteListLoading ?
              <PulseLoader color={'#F8E71C'} size={8} />
              :
              <ListFilteringSiteAutoSuggest id="sitePickerFormAutoSuggest" siteList={this.props.siteList} onSiteSelected={this.props.addSite} clearAfterSelect />
            }

            <h5 className="mt-1">Selected Sites</h5>
            <SelectedSites selectedSites={this.props.selectedSites} onRemoveSite={this.props.removeSite} />
          </div>
          : null
        }
      </div>
    );
  }
}

SitePicker.propTypes = {
  isCustomerListLoading: PropTypes.bool.isRequired,
  isSiteListLoading: PropTypes.bool.isRequired,
  customerList: PropTypes.array.isRequired,
  selectedCustomer: PropTypes.object,
  siteList: PropTypes.array.isRequired,
  selectedSites: PropTypes.array.isRequired,

  getCustomers: PropTypes.func.isRequired,
  clearCustomer: PropTypes.func.isRequired,
  selectCustomer: PropTypes.func.isRequired,
  addSite: PropTypes.func.isRequired,
  removeSite: PropTypes.func.isRequired,
};

SitePicker.defaultProps = {
  selectedCustomer: null,
};

function mapStateToProps(state) {
  return {
    isCustomerListLoading: selectors.isCustomerListLoading(state),
    isSiteListLoading: selectors.isSiteListLoading(state),
    customerList: selectors.customerList(state),
    selectedCustomer: selectors.selectedCustomer(state),
    siteList: selectors.siteList(state),
    selectedSites: selectors.selectedSites(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCustomers: () => dispatch(actions.getCustomers()),
    clearCustomer: () => dispatch(actions.clearCustomer()),
    selectCustomer: inventoryFormat => dispatch(actions.selectCustomer(inventoryFormat)),
    addSite: site => dispatch(actions.addSite(site)),
    removeSite: (site, index) => dispatch(actions.removeSite(site, index)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SitePicker);
