/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Label } from 'reactstrap';

import { IntentControls } from '../Intents';
import RemoteATGButton from '../RemoteATGButton';
import LineSelect from './LineSelect';
import * as siteSelectors from '../../../../SiteSelectors';
import * as selectors from '../../CommandSelectors';
import * as actions from '../../actions';
import TestTypeSelect from './TestTypeSelect';
import OrganizationPermissions from '../../../../../../organization-permissions';
import HasPermission from '../../../../../../authentication/containers/HasPermission';
import { LLDTypes } from '../../../../SiteConstants';

const InitialState = {
  contentVisible: false,
  selectedLine: undefined,
  selectedTestType: undefined,
};

class IntentButton extends Component {
  constructor(defaultProps) {
    super(defaultProps);

    this.state = InitialState;

    this.toggleContent = this.toggleContent.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleLineSelect = this.handleLineSelect.bind(this);
    this.handleTestTypeSelect = this.handleTestTypeSelect.bind(this);
    this.sendCommand = this.sendCommand.bind(this);
  }

  intentHasControls() {
    return !!(this.props.intent.controls && Object.keys(this.props.intent.controls).length);
  }

  toggleContent() {
    this.setState(currentState => Object.assign({}, InitialState, { contentVisible: !currentState.contentVisible }));
  }

  handleButtonClick() {
    if (this.intentHasControls()) {
      this.toggleContent();
    }
    else {
      this.sendCommand();
    }
  }

  handleLineSelect(selectedLine) {
    this.setState({ selectedLine });
  }

  handleTestTypeSelect(selectedTestType) {
    this.setState({ selectedTestType });
  }

  sendCommand() {
    const args = {};
    if (this.state.selectedLine) {
      args.line = this.state.selectedLine.elldSensorNumber;
    }
    if (this.state.selectedTestType) {
      args.testType = this.state.selectedTestType;
    }
    this.props.sendIntentRequest(this.props.site.id, this.props.intent.intentName, args);
    this.toggleContent();
  }

  canSend() {
    return Object.keys(this.props.intent.controls)
      .map(controlName => {
        const controlOptions = this.props.intent.controls[controlName];
        return this.validateControl(controlName, controlOptions);
      })
      .every(value => !!value);
  }

  validateControl(controlName, controlOptions) {
    switch (controlName) {
      case IntentControls.LINE_SELECT:
        return this.props.lines.length && (controlOptions.allowAllLines || this.state.selectedLine);
      case IntentControls.TEST_TYPE_SELECT:
        return this.state.selectedTestType;
      default:
        return false;
    }
  }

  render() {
    const permission = this.props.intent.permission || OrganizationPermissions.AtgCommandsSet;
    return (
      <HasPermission customerId={this.props.site.customerId} siteId={this.props.site.id} permission={permission}>
        <div>
          <RemoteATGButton onClick={this.handleButtonClick}>{this.props.intent.description}</RemoteATGButton>
          {this.renderContent()}
        </div>
      </HasPermission>
    );
  }

  renderContent() {
    if (!this.state.contentVisible || !this.intentHasControls()) {
      return null;
    }

    return (
      <Form className="p-4">
        {Object.keys(this.props.intent.controls).map(controlName => {
          const controlOptions = this.props.intent.controls[controlName];
          return this.renderControl(controlName, controlOptions);
        })}
        {this.renderButtons()}
      </Form>
    );
  }

  renderControl(controlName, controlOptions) {
    switch (controlName) {
      case IntentControls.LINE_SELECT:
        return this.renderLineSelect(controlOptions);
      case IntentControls.TEST_TYPE_SELECT:
        return this.renderTestTypeSelect(controlOptions);
      default:
        return null;
    }
  }

  renderLineSelect(options) {
    const id = `${this.props.intent.intentName.toLowerCase()}_line`;
    return (
      <FormGroup key="line">
        <Label for={id}>Line</Label>
        <LineSelect
          id={id}
          name="line"
          lines={this.props.lines}
          selectedLine={this.state.selectedLine}
          onSelect={this.handleLineSelect}
          {...options}
        />
      </FormGroup>

    );
  }

  renderTestTypeSelect(options) {
    const id = `${this.props.intent.intentName.toLowerCase()}_test_type`;
    return (
      <FormGroup key="test">
        <Label for={id}>Test Type</Label>
        <TestTypeSelect
          id={id}
          name="test"
          selectedTestType={this.state.selectedTestType}
          onSelect={this.handleTestTypeSelect}
          {...options}
        />
      </FormGroup>
    );
  }

  renderButtons() {
    return (
      <div className="d-flex my-2 justify-content-end">
        <Button color="primary" size="sm" outline onClick={this.toggleContent}>Cancel</Button>
        <Button color="primary" size="sm" className="ml-2" onClick={this.sendCommand} disabled={!this.canSend()}>Send</Button>
      </div>
    );
  }
}

IntentButton.propTypes = {
  intent: PropTypes.shape({
    intentName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    permission: PropTypes.number,
    controls: PropTypes.array,
  }).isRequired,
  site: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customerId: PropTypes.number.isRequired,
  }).isRequired,
  lines: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    elldSensorNumber: PropTypes.number,
    lldType: PropTypes.oneOf([LLDTypes.PressurizedLLD, LLDTypes.WirelessLLD]),
    locationLabel: PropTypes.string,
  })),
  sendIntentRequest: PropTypes.func.isRequired,
};

IntentButton.defaultProps = {
  lines: [],
};

function mapStateToProps(state) {
  return {
    site: siteSelectors.currentSite(state),
    lines: selectors.siteLines(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendIntentRequest: (siteId, issueId, intentName, args) => dispatch(actions.sendIntentRequest(siteId, issueId, intentName, args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntentButton);
