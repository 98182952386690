import React from 'react';
import PropTypes from 'prop-types';

import { CanaryTable, Column, LabelListColumn, MonthYearColumn, StringColumn } from '../../../shared/components/table';
import StatusIcon from '../../../shared/components/compliance/StatusIcon';
import StatusText from '../../../shared/components/compliance/StatusText';
import { ComplianceTableColumns } from '../ComplianceConstants';

const renderComplianceStatus = report => (
  <div style={{ whiteSpace: 'nowrap' }}>
    <StatusIcon status={report.status} calculatedStatusOverridden={report.calculatedStatusOverridden} size="1em" />
    <StatusText status={report.status} calculatedStatusOverridden={report.calculatedStatusOverridden} />
  </div>
);

const renderComments = report => {
  if (report.comments && report.comments.length) {
    return (
      <>
        {report.comments.map(comment => (
          <div style={{ fontSize: '85%' }} key={comment.id}>{comment.commentText}</div>
        ))}
      </>
    );
  }
  return <span />;
};

const ReportsTable = React.memo(({ dataLoading, reports, columns, sortColumnId, sortDirection, onSortChange, currentPage, onPageChange, pageSize, pageCount, onReportSelected }) => {
  const columnIds = columns.map(current => current.id);
  const hasColumn = col => columnIds.indexOf(col.id) !== -1;
  return (
    <CanaryTable
      dataLoading={dataLoading}
      data={reports}
      sortColumnId={sortColumnId}
      sortDirection={sortDirection}
      onSortChange={onSortChange}
      currentPage={currentPage}
      onPageChange={onPageChange}
      pageSize={pageSize}
      pageCount={pageCount}
      onRowClick={onReportSelected}
    >
      {hasColumn(ComplianceTableColumns.Month)
        ? (<MonthYearColumn columnId={ComplianceTableColumns.Month.id} property="monthStartDate" title={ComplianceTableColumns.Month.shortName} sortable width="10%" />)
        : null}

      {hasColumn(ComplianceTableColumns.Customer)
        ? (<StringColumn columnId={ComplianceTableColumns.Customer.id} property="customer.name" title={ComplianceTableColumns.Customer.shortName} sortable width="11%" />)
        : null}

      {hasColumn(ComplianceTableColumns.SiteNickname)
        ? (<StringColumn columnId={ComplianceTableColumns.SiteNickname.id} property="site.nickname" title={ComplianceTableColumns.SiteNickname.shortName} width="23%" sortable />)
        : null}

      {hasColumn(ComplianceTableColumns.SiteState)
        ? (<StringColumn columnId={ComplianceTableColumns.SiteState.id} property="site.state" title={ComplianceTableColumns.SiteState.shortName} sortable width="6%" />)
        : null}

      {hasColumn(ComplianceTableColumns.EffectiveStatus)
        ? (<Column columnId={ComplianceTableColumns.EffectiveStatus.id} title={ComplianceTableColumns.EffectiveStatus.shortName} render={renderComplianceStatus} sortable width="13%" />)
        : null}

      {hasColumn(ComplianceTableColumns.Comments)
        ? (<Column columnId={ComplianceTableColumns.Comments.id} title={ComplianceTableColumns.Comments.shortName} render={renderComments} width="17%" />)
        : null}

      {hasColumn(ComplianceTableColumns.SiteLabels)
        ? (<LabelListColumn columnId={ComplianceTableColumns.SiteLabels.id} property="siteLabels" title={ComplianceTableColumns.SiteLabels.shortName} width="20%" />)
        : null}
    </CanaryTable>
  );
});

ReportsTable.propTypes = {
  dataLoading: PropTypes.bool,
  reports: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  sortColumnId: PropTypes.string,
  sortDirection: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onReportSelected: PropTypes.func.isRequired,
};

ReportsTable.defaultProps = {
  dataLoading: false,
  sortColumnId: null,
  sortDirection: null,
};

export default ReportsTable;
