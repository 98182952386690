/* eslint-disable import/first,import/newline-after-import */
import findIndex from 'array.prototype.findindex';
findIndex.shim();

import React from 'react';
import { render } from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import moment from 'moment';
import simpleNumberLocalizer from 'react-widgets-simple-number';
import 'react-widgets/dist/css/react-widgets.css';

simpleNumberLocalizer();

import { Store, history } from './store';
import UserLayout from './user/UserLayout';
import LoginLayout from './login/LoginLayout';
import AdminLayout from './admin/containers/AdminLayout';
import ResetPasswordLayout from './login/ResetPasswordLayout';

moment.updateLocale('en', {
  relativeTime: {
    s: 'just now',
    ss: 'just now',
    m: 'a min',
    mm: '%d min'
  },
});

render((
    <Provider store={Store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/login" component={LoginLayout} />
        <Route path="/reset-password" component={ResetPasswordLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Route path="/" component={UserLayout} />
      </Switch>
    </ConnectedRouter>
  </Provider>
), document.getElementById('root'));
