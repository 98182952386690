function stateSlice(state) {
  return (state && state.sites && state.sites.issues && state.sites.issues.edit && state.sites.issues.edit.dispatch) || {};
}

export function isLoading(state) {
  return stateSlice(state).isLoading || false;
}

export function dispatchActivity(state) {
  return stateSlice(state).dispatchActivity || [];
}
