import reduceReducers from 'reduce-reducers';
import { buildReducer, buildResetOnNavActionReducer, DefaultState, HookTypes, listInsertBeginningFunction } from '../../../../../../../../shared/redux-helpers/ReducerFactory';
import MviEditActionTypes from '../../MviEditActionTypes';
import CommentActionTypes from './CommentActionTypes';

function CommentListFromMvi(state, action) {
  if (action.type === MviEditActionTypes.RECEIVE_ITEM) {
    return Object.assign({}, state, {
      list: (action.item && action.item.comments) || [],
    });
  }
  return state;
}

const CommentReducer = buildReducer(CommentActionTypes, { [HookTypes.PostSaveListInsert]: listInsertBeginningFunction });

export default reduceReducers(DefaultState, buildResetOnNavActionReducer(), CommentListFromMvi, CommentReducer);
