const VaporAdapters = { id: 11,
  name: 'vaporAdapters',
  label: 'Vapor Adapters',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: false,
  card: true,
  path: 'vaporAdapters',
  index: 0,
  fields: [
    { id: 1,
      name: 'name',
      label: 'Name',
      path: 'vaporAdapters.0.name',
      type: 'text',
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
    { id: 2,
      name: 'sealType',
      label: 'Seal Type',
      path: 'vaporAdapters.0.sealType',
      type: 'select',
      values: ['Unknown', 'Top seal', 'Side seal'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 3,
      name: 'rotationType',
      label: 'Rotation Type',
      path: 'vaporAdapters.0.rotationType',
      type: 'select',
      values: ['Unknown', 'Stationary', 'Locking', 'Swivel'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 4,
      name: 'adapterCap',
      label: 'Adapter Cap',
      path: 'vaporAdapters.0.adapterCap',
      type: 'bool',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 5,
      name: 'poppeted',
      label: 'Poppeted',
      path: 'vaporAdapters.0.poppeted',
      type: 'bool',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 6,
      name: 'containmentType',
      label: 'Containment Type',
      path: 'vaporAdapters.0.containment.containmentType',
      type: 'select',
      values: ['Unknown', 'None', 'Spill bucket', 'Sump', 'Spill bucket in sump'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: false,
    },
  ],
};

export default VaporAdapters;
