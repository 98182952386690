import React from 'react';
import PropTypes from 'prop-types';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import FontAwesomeButton from './FontAwesomeButton';

function DeleteButton({ size, className, onClick, disabled }) {
  return (
    <FontAwesomeButton
      icon={faMinusCircle}
      iconEnabledClassName="text-danger"
      size={size}
      className={className}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

DeleteButton.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DeleteButton.defaultProps = {
  size: '1x',
  className: null,
  disabled: false,
};

export default DeleteButton;
