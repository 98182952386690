import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedSites = ({ selectedSites, disabled, deselectSite }) => <FilterItemList items={selectedSites} textField="nickname" disabled={disabled} onRemoveItem={deselectSite} />;

SelectedSites.propTypes = {
  selectedSites: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool.isRequired,
  deselectSite: PropTypes.func.isRequired,
};

SelectedSites.defaultProps = {
  selectedSites: [],
};

export default SelectedSites;
