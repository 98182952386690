import React from 'react';
import PropTypes from 'prop-types';
import Comment from './Comment';

const CommentList = ({ comments }) => {
  if (!comments || !comments.length) {
    return null;
  }

  return comments.map(comment => <Comment key={comment.id} comment={comment} className="mb-2" />);
};

CommentList.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      commentText: PropTypes.string.isRequired,
      username: PropTypes.string,
      user: PropTypes.shape({
        username: PropTypes.string,
      }),
      created: PropTypes.string.isRequired
    })
  ),
};

CommentList.defaultProps = {
  comments: null,
};

export default CommentList;
