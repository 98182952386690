import { MviClient } from '../../../../../../../../client';
import { saveItemAction } from '../../../../../../../../shared/redux-helpers/ActionCreatorFactory';
import CommentActionTypes from './CommentActionTypes';
import MviEditSelectors from '../../MviEditSelectors';
import selectors from './CommentSelectors';

export const beginCommentEdit = () => ({ type: CommentActionTypes.BEGIN_ITEM_EDIT });

export const editCommentText = commentText => ({ type: CommentActionTypes.EDIT_ITEM, pendingEdits: { commentText } });

export const cancelCommentEdit = () => ({ type: CommentActionTypes.CANCEL_ITEM_EDIT });

export const saveComment = saveItemAction(
  selectors.isSaving,
  CommentActionTypes.SAVE_ITEM_STARTED,
  CommentActionTypes.SAVE_ITEM_SUCCESS,
  CommentActionTypes.SAVE_ITEM_FAILED,
  (currentState) => {
    const currentMvi = MviEditSelectors.item(currentState);
    const currentComment = selectors.pendingEdits(currentState);
    const requestBody = {
      mviId: currentMvi.id,
      commentText: currentComment.commentText
    };
    return MviClient.createComment(requestBody);
  }
);
