import { connect } from 'react-redux';

import CommentList from '../../../../../../../../shared/components/comment/CommentList';
import * as selectors from './CommentSelectors';

function mapStateToProps(state) {
  return {
    comments: selectors.comments(state),
  };
}

export default connect(mapStateToProps)(CommentList);
