import { Delete, Get, Patch, Put, Post, PostWithFile } from '../HttpUtils';
import { ParameterType, Param, buildFindMethod } from '../param-types';

function toLegacyFormat(mvi) {
  if (!mvi) {
    return null;
  }

  const workflow = mvi.workflow;
  if (workflow) {
    mvi.workflowStatus = workflow.status;
    mvi.workflowStatusDate = workflow.statusDate;
    mvi.workflowUserId = workflow.user.id;
    mvi.workflowUserName = workflow.user.username;
  }

  const site = mvi.site;
  if (site) {
    mvi.customerId = site.customerId;
    mvi.siteId = site.id;
    mvi.siteName = site.siteName;
    mvi.siteNickname = site.nickname;
    mvi.siteTimezone = site.siteTimezone;
    mvi.siteState = site.state;
  }

  const month = mvi.month;
  if (month) {
    mvi.monthId = month.id;
    mvi.monthStartDate = month.startDate;
    mvi.year = month.year;
    mvi.monthText = month.monthText;
    mvi.month = month.month;
  }

  return mvi;
}

export const MviFields = {
  Id: 'id',
  SiteId: 'siteId',
  MonthId: 'monthId',
  Reports: 'reports',
  Workflow: 'workflow',
  Created: 'created',

  Comments: 'comments',
  Customer: 'customer',
  Month: 'month',
  Site: 'site',
  SiteLabels: 'siteLabels',
};

export const MviParams = {
  CustomerIds: new Param('customerIds', ParameterType.List),
  SiteIds: new Param('siteIds', ParameterType.List),
  SiteStates: new Param('siteStates', ParameterType.List),
  SiteLabelIds: new Param('siteLabelIds', ParameterType.List),
  Year: new Param('year', ParameterType.Scalar),
  Month: new Param('month', ParameterType.Scalar),
  WorkflowStatuses: new Param('workflowStatuses', ParameterType.List),
};

export const MviClient = {
  findMvis: buildFindMethod('/v2/mvis', MviFields, MviParams, toLegacyFormat),

  getMvis(customerId, year, month, workflowStatus) {
    if (!customerId || !year || !month) {
      return Promise.resolve();
    }

    return Get(`/v2/mvis?workflowStatuses=${[workflowStatus]}&customerIds=${customerId}&year=${Number.parseInt(year, 10)}&month=${Number.parseInt(month, 10)}`)
      .then(response => response.data.map(toLegacyFormat));
  },

  getMviFormUrl(site) {
    const siteId = (site && site.id) || site;
    if (!siteId) {
      return Promise.resolve();
    }

    const requestUrl = `/v2/sites/${siteId}?include=mviFormUrl`;
    return Get(requestUrl)
      .then(response => response.data && response.data.mviFormUrl)
      .catch(err => {
        if (err && err.response && err.response.status === 404) {
          return Promise.resolve(null);
        }
        return Promise.reject(err);
      });
  },

  getMviMonths() {
    return Get('/v2/mvis/months').then(response => response.data);
  },

  getLatestMvi(site) {
    const siteId = (site && site.id) || site;
    if (!siteId) {
      return Promise.resolve();
    }

    return Get(`/v2/mvis/sites/${siteId}/latest`)
      .then(response => toLegacyFormat(response.data))
      .catch(err => {
        if (err && err.response && err.response.status === 404) {
          return Promise.resolve(null);
        }
        return Promise.reject(err);
      });
  },

  getSiteMvis(site) {
    const siteId = (site && site.id) || site;
    if (!siteId) {
      return Promise.resolve([]);
    }

    return Get(`/v2/mvis?siteIds=${siteId}`).then(response => response.data.map(toLegacyFormat));
  },

  findById(mvi) {
    const mviId = (mvi && mvi.id) || mvi;
    if (!mviId) {
      return Promise.resolve(null);
    }
    return Get(`/v2/mvis/${mviId}`).then(response => toLegacyFormat(response.data));
  },

  createComment(comment) {
    return Post(`v2/mvis/${comment.mviId}/comments`, comment).then(response => response.data);
  },

  updateMvi(mvi) {
    return Patch(`/v2/mvis/${mvi.id}`, mvi).then(response => toLegacyFormat(response.data));
  },

  createReport(report, file) {
    if (file) {
      return PostWithFile(`/v2/mvis/${report.mviId}/reports`, null, report, file).then(response => response.data);
    }
    return Post(`/v2/mvis/${report.mviId}/reports`, report).then(response => response.data);
  },

  updateReport(mvi, report) {
    return Put(`/v2/mvis/${mvi.id}/reports/${report.id}`, report).then(response => response.data);
  },

  moveReport(mvi, report, moveRequest) {
    return Post(`/v2/mvis/${mvi.id}/reports/${report.id}/move`, moveRequest).then(response => response.data);
  },

  deleteReport(report) {
    return Delete(`/v2/mvis/${report.mviId}/reports/${report.id}`).then(response => response.data);
  }
};
