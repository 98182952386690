import { faFilePdf, faFileImage, faFileExcel, faFileWord, faFileCsv, faFileArchive } from '@fortawesome/free-solid-svg-icons';

export const ItemType = {
  Folder: 'folder',
  Doc: 'doc',
};

export const Icons = {
  csv: faFileCsv,
  doc: faFileWord,
  pdf: faFilePdf,
  jpg: faFileImage,
  jpeg: faFileImage,
  png: faFileImage,
  xls: faFileExcel,
  xlsx: faFileExcel,
  zip: faFileArchive
};

export const OperationState = {
  Initial: 'preparing',
  Loading: 'loading',
  Downloading: 'downloading',
  Editing: 'editing',
  Deleting: 'deleting',
  Ready: 'ready',
  Failed: 'failed',
};
