import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import { Button } from 'reactstrap';

import AppFeatures from '../../../../../components/app-features/AppFeatures';
import CustomerLabels from '../components/customer-settings/CustomerLabels';
import CustomerDispatchSystems from '../components/customer-settings/CustomerDispatchSystems';
import * as selectors from '../OrganizationEditSelectors';
import * as actions from '../actions';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';


class CustomerSettings extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.editCustomer = this.editCustomer.bind(this);
  }

  editCustomer(customer) {
    const updatedOrganization = update(this.props.pendingOrgEdits, {
      customer: {
        $set: customer,
      },
    });
    return this.props.editSelectedOrganization(updatedOrganization);
  }

  renderAppFeatures() {
    return (
      <div>
        <AppFeatures updateTargetModel={this.editCustomer} targetModel={this.props.pendingOrgEdits.customer} />
        {this.renderSaveButtons()}
      </div>
    );
  }

  renderSaveButtons() {
    return (
      <div className="d-flex flex-row my-2">
        <Button color="primary" outline size="sm" onClick={this.props.discardPendingOrganizationEdits} disabled={this.props.pendingOrgEditsPristine}>CANCEL</Button>
        <Button id="save" color="primary" size="sm" className="ml-2" onClick={this.props.saveSelectedOrganization} disabled={this.props.pendingOrgEditsPristine}>SAVE</Button>
      </div>
    );
  }

  render() {
    if (!this.props.pendingOrgEdits) {
      return null;
    }

    return (
      <div className="row mt-4">
        <div className="col-md-2">
          <div className="nav flex-column nav-pills">
            <NavLink to={`${this.props.match.url}/features`} className="nav-link">Features</NavLink>
            <NavLink to={`${this.props.match.url}/labels`} className="nav-link">Labels</NavLink>
            <NavLink to={`${this.props.match.url}/dispatch`} className="nav-link">Dispatch</NavLink>
          </div>
        </div>
        <div className="col-md-10">
          <ErrorMessage title="Failed to update customer settings" error={this.props.error} />
          <Switch>
            <Redirect exact="exact" from={`${this.props.match.url}/`} to={`${this.props.match.url}/features`} />
            <Route exact path={`${this.props.match.url}/features`} name="customer-features">
              {this.renderAppFeatures()}
            </Route>
            <Route exact path={`${this.props.match.url}/labels`} name="customer-labels">
              <CustomerLabels selectedOrganization={this.props.pendingOrgEdits} editSelectedOrganization={this.props.editAndSaveSelectedOrganization} />
            </Route>
            <Route exact path={`${this.props.match.url}/dispatch`} name="customer-dispatch">
              <CustomerDispatchSystems selectedOrganization={this.props.pendingOrgEdits} editSelectedOrganization={this.props.editAndSaveSelectedOrganization} />
            </Route>

          </Switch>
        </div>
      </div>
    );
  }
}

CustomerSettings.propTypes = {
  error: PropTypes.object,
  pendingOrgEdits: PropTypes.object,
  pendingOrgEditsPristine: PropTypes.bool.isRequired,
  editSelectedOrganization: PropTypes.func.isRequired,
  saveSelectedOrganization: PropTypes.func.isRequired,
  editAndSaveSelectedOrganization: PropTypes.func.isRequired,
  discardPendingOrganizationEdits: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

CustomerSettings.defaultProps = {
  error: null,
  pendingOrgEdits: null,
};

function mapStateToProps(state) {
  return {
    error: selectors.error(state),
    pendingOrgEdits: selectors.pendingOrgEdits(state),
    pendingOrgEditsPristine: selectors.pendingOrgEditsPristine(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editSelectedOrganization: organization => dispatch(actions.editSelectedOrganization(organization)),
    saveSelectedOrganization: () => dispatch(actions.saveSelectedOrganization()),
    editAndSaveSelectedOrganization: organization => dispatch(actions.editAndSaveSelectedOrganization(organization)),
    discardPendingOrganizationEdits: () => dispatch(actions.discardPendingOrganizationEdits()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerSettings));
