import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

const CommentInput = ({ commentText, onCommentTextChange, onSave, onCancel, visible, saving, className, isRequired }) => {
  if (!visible) {
    return null;
  }

  const invalid = !commentText || !commentText.length || commentText.trim() === '';

  return (
    <Form className={className}>
      <FormGroup>
        <Label for="addComment" size="sm" className="mr-2">
          Comment
          {isRequired ? <span className="text-danger"> (Required)</span> : null}
        </Label>
        <Input type="textarea" name="addComment" bsSize="sm" value={commentText || ''} onChange={e => onCommentTextChange(e.target.value)} />
      </FormGroup>
      <Button size="sm" color="primary" outline className="mr-2" onClick={onCancel} disabled={saving}>Cancel</Button>
      <Button size="sm" color="primary" onClick={onSave} disabled={invalid || saving}>Save</Button>
    </Form>
  );
};

CommentInput.propTypes = {
  commentText: PropTypes.string,
  onCommentTextChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  saving: PropTypes.bool,
  className: PropTypes.string,
};

CommentInput.defaultProps = {
  commentText: '',
  visible: true,
  saving: false,
  className: null,
};

export default CommentInput;
