import update from 'immutability-helper';
import ActionTypes from './UploadActionTypes';
import { FileStatus, Steps } from './UploadConstants';

const InitialState = {
  step: Steps.Initial,
  files: [],
  destinationFolder: null,
  issueIds: [],
  mviReportId: null,
  error: null,
};

function start(action) {
  const files = action.files || [];
  const step = files.length ? Steps.SelectFolder : Steps.ModalHome;
  const issueIds = [];
  if (action.issues && action.issues.length) {
    action.issues.forEach(issue => issueIds.push(issue.id));
  }

  return {
    step,
    files,
    issueIds,
    mviReportId: (action.mviReport && action.mviReport.id) || null,
    destinationFolder: action.destinationFolder || null,
  };
}

function toggleIssueSelect(state, issue) {
  const issueId = issue.id || issue;
  const selectedIssueIds = state.issueIds;
  const index = selectedIssueIds.indexOf(issueId);
  if (index === -1) {
    return update(state, {
      issueIds: {
        $push: [issueId],
      }
    });
  }
  return (update(state, {
    issueIds: {
      $splice: [[index, 1]],
    }
  }));
}

function updateFileStatus(state, file, status) {
  if (!state.files) {
    return state;
  }

  const index = state.files.findIndex(current => current.filename === file.filename);
  if (index === -1) {
    return state;
  }

  const updatedFile = update(file, {
    status: {
      $set: status
    }
  });
  return update(state, {
    files: {
      $splice: [[index, 1, updatedFile]],
    }
  });
}

function uploadFailed(state, failedFile, error) {
  return Object.assign({}, state, updateFileStatus(state, failedFile, FileStatus.Failed), {
    step: Steps.UploadFailed,
    error,
  });
}

export default function UploadReducer(state = InitialState, action) {
  switch (action.type) {
    case ActionTypes.RESET:
      return InitialState;
    case ActionTypes.START:
      return start(action);
    case ActionTypes.NEXT_STEP:
      return Object.assign({}, state, {
        step: action.step,
      });

    case ActionTypes.SET_FILES:
      return Object.assign({}, state, {
        step: action.step,
        files: action.files || [],
      });
    case ActionTypes.SET_DESTINATION_FOLDER:
      return Object.assign({}, state, {
        destinationFolder: action.destinationFolder,
      });
    case ActionTypes.TOGGLE_ISSUE_SELECT:
      return toggleIssueSelect(state, action.issue);

    case ActionTypes.CREATE_DOC_STARTED:
      return updateFileStatus(state, action.file, FileStatus.InProgress);
    case ActionTypes.CREATE_DOC_SUCCESS:
      return updateFileStatus(state, action.file, FileStatus.Complete);
    case ActionTypes.CREATE_DOC_FAILED:
      return uploadFailed(state, action.file, action.error);

    default:
      return state;
  }
}
