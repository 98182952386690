import find from 'lodash/find';
import { CustomerClient, UserClient } from '../../../../client';
import { getListAction } from '../../../redux-helpers/ActionCreatorFactory';
import { track } from '../../../analytics';
import { MonthType } from './ViewManagerConstants';

export default function buildViewManagerActions(ActionTypes, selectors, viewCategory, monthsRestFunc) {
  const restFunc = () => UserClient.getViews(viewCategory);
  const getViewsInternal = getListAction(selectors.isViewListLoading, ActionTypes.GET_VIEWS_STARTED, ActionTypes.RECEIVE_VIEWS, ActionTypes.GET_VIEWS_FAILED, restFunc);

  const selectView = view => ({ type: ActionTypes.SELECT_VIEW, view });

  const getViews = () => (dispatch, getState) => {
    const currentState = getState();

    let viewListPromise;

    const existingViewList = selectors.viewList(currentState);
    if (existingViewList && existingViewList.length) {
      viewListPromise = Promise.resolve(existingViewList);
    }
    else {
      viewListPromise = dispatch(getViewsInternal());
    }

    return viewListPromise.then(viewList => {
      const defaultView = find(viewList, v => v.defaultView) || (viewList.length && viewList[0]) || null;
      return dispatch(selectView(defaultView));
    });
  };

  const saveView = (viewName, defaultView) => (dispatch, getState) => {
    const currentState = getState();
    if (selectors.isSaving(currentState)) {
      return Promise.resolve();
    }

    const toSave = buildToSave(currentState, viewName, defaultView);

    dispatch({ type: ActionTypes.SAVE_VIEW_STARTED });
    return UserClient.saveView(toSave)
      .then(persistentView => {
        dispatch({ type: ActionTypes.SAVE_VIEW_SUCCESS, view: persistentView });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.SAVE_VIEW_FAILED, error });
      });
  };

  function buildToSave(currentState, viewName, defaultView) {
    const pendingEdits = selectors.pendingEdits(currentState);
    const filters = pendingEdits.filters;
    const filtersToSave = {};
    if (filters) {
      if (filters.customers && filters.customers.length) {
        filtersToSave.customers = filters.customers.map(current => ({ id: current.id, name: current.name }));
      }
      if (filters.siteStates && filters.siteStates.length) {
        filtersToSave.siteStates = filters.siteStates;
      }
      if (filters.selectedSiteLabels && filters.selectedSiteLabels.length) {
        filtersToSave.selectedSiteLabels = filters.selectedSiteLabels;
      }
      if (filters.selectedConnectionTypes && filters.selectedConnectionTypes.length) {
        filtersToSave.selectedConnectionTypes = filters.selectedConnectionTypes;
      }
      if (filters.selectedConnectionStatuses && filters.selectedConnectionStatuses.length) {
        filtersToSave.selectedConnectionStatuses = filters.selectedConnectionStatuses;
      }
      if (filters.issueTypes && filters.issueTypes.length) {
        filtersToSave.issueTypes = filters.issueTypes;
      }
      if (filters.friendlyIssueWorkflowStatuses && filters.friendlyIssueWorkflowStatuses.length) {
        filtersToSave.friendlyIssueWorkflowStatuses = filters.friendlyIssueWorkflowStatuses;
      }
      if (filters.inspectionStatuses && filters.inspectionStatuses.length) {
        filtersToSave.inspectionStatuses = filters.inspectionStatuses;
      }
      if (filters.month && filters.month.monthType !== MonthType.None) {
        filtersToSave.month = filters.month;
      }
      if (filters.complianceStatuses && filters.complianceStatuses.length) {
        filtersToSave.complianceStatuses = filters.complianceStatuses;
      }
      if (filters.inspectionStatuses && filters.inspectionStatuses.length) {
        filtersToSave.inspectionStatuses = filters.inspectionStatuses;
      }
      if (filters.sites && filters.sites.length) {
        filtersToSave.sites = filters.sites.map(current => ({ id: current.id, nickname: current.nickname }));
      }
      if (filters.tankProductLabels && filters.tankProductLabels.length) {
        filtersToSave.tankProductLabels = filters.tankProductLabels;
      }
      if (filters.tankStatuses && filters.tankStatuses.length) {
        filtersToSave.tankStatuses = filters.tankStatuses;
      }
    }

    const toSave = {
      category: viewCategory,
      name: viewName,
      filters: filtersToSave,
      columns: pendingEdits.columns || [],
      sortColumn: pendingEdits.sortColumn,
      sortDirection: pendingEdits.sortDirection,
      defaultView,
    };

    const currentView = selectors.currentView(currentState);
    if (currentView && currentView.name === viewName) {
      toSave.id = currentView.id;
    }
    return toSave;
  }

  const toggleDefaultView = () => (dispatch, getState) => {
    const currentState = getState();
    if (selectors.isEditingView(currentState) || selectors.isSaving(currentState)) {
      return Promise.resolve();
    }
    const currentView = selectors.currentView(currentState);
    const defaultView = !!currentView.defaultView;
    return dispatch(saveView(currentView.name, !defaultView));
  };

  const deleteView = () => (dispatch, getState) => {
    const currentState = getState();
    const view = selectors.currentView(currentState);
    if (selectors.isDeleting(currentState)) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.DELETE_VIEW_STARTED });
    return UserClient.deleteView(view)
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_VIEW_SUCCESS, view });

        const viewList = selectors.viewList(getState());
        const defaultView = find(viewList, v => v.defaultView) || (viewList.length && viewList[0]) || null;
        return dispatch(selectView(defaultView));
      })
      .catch(error => {
        dispatch({ type: ActionTypes.DELETE_VIEW_FAILED, error });
      });
  };

  function getAllLabels() {
    return (dispatch, getState) => {
      const currentState = getState();
      if (selectors.isLabelsLoading(currentState)) {
        return Promise.resolve();
      }

      dispatch({ type: ActionTypes.GET_SITE_LABELS_STARTED });
      return CustomerClient.getLabels()
        .then(labels => {
          dispatch({ type: ActionTypes.RECEIVE_SITE_LABELS, labels });
        })
        .catch(error => {
          dispatch({ type: ActionTypes.GET_SITE_LABELS_FAILED, error });
        });
    };
  }

  function getMonths() {
    return (dispatch, getState) => {
      const currentState = getState();
      if (selectors.isMonthsLoading(currentState) || !monthsRestFunc) {
        return Promise.resolve();
      }

      dispatch({ type: ActionTypes.GET_MONTHS_STARTED });
      return monthsRestFunc()
        .then(months => {
          dispatch({ type: ActionTypes.RECEIVE_MONTHS, months });
        })
        .catch(error => {
          dispatch({ type: ActionTypes.GET_MONTHS_FAILED, error });
        });
    };
  }

  return {
    getViews,
    selectView,
    saveView,
    toggleDefaultView,
    deleteView,
    startFilterEdit: () => ({ type: ActionTypes.START_FILTER_EDIT }),
    cancelFilterEdit: () => ({ type: ActionTypes.CANCEL_FILTER_EDIT }),
    clearFilters: () => ({ type: ActionTypes.CLEAR_FILTERS }),
    selectCustomers: customers => {
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Customer' });
      return { type: ActionTypes.SELECT_CUSTOMERS, customers };
    },
    deselectCustomer: customer => {
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Customer' });
      return { type: ActionTypes.DESELECT_CUSTOMER, customer };
    },
    selectSiteStates: states => {
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'State' });
      return { type: ActionTypes.SELECT_SITE_STATES, siteStates: states };
    },
    deselectSiteState: siteState => {
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'State' });
      return { type: ActionTypes.DESELECT_SITE_STATE, siteState };
    },
    getAllLabels,
    selectSiteLabels: labels => {
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Label' });
      return { type: ActionTypes.SELECT_SITE_LABELS, labels };
    },
    deselectSiteLabel: label => {
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Label' });
      return { type: ActionTypes.DESELECT_SITE_LABEL, label };
    },
    selectConnectionTypes: connectionTypes => {
      const filterData = connectionTypes[0] && connectionTypes[0].name;
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Connection type', 'Filter value': filterData });
      return { type: ActionTypes.SELECT_CONNECTION_TYPES, connectionTypes };
    },
    deselectConnectionType: connectionType => {
      const filterData = connectionType && connectionType.name;
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Connection type', 'Filter value': filterData });
      return { type: ActionTypes.DESELECT_CONNECTION_TYPE, connectionType };
    },
    selectConnectionStatuses: connectionStatuses => {
      const filterData = connectionStatuses[0] && connectionStatuses[0].name;
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Connection status', 'Filter value': filterData });
      return { type: ActionTypes.SELECT_CONNECTION_STATUSES, connectionStatuses };
    },
    deselectConnectionStatus: connectionStatus => {
      const filterData = connectionStatus && connectionStatus.name;
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Connection status', 'Filter value': filterData });
      return { type: ActionTypes.DESELECT_CONNECTION_STATUS, connectionStatus };
    },
    selectIssueTypes: issueTypes => {
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Issue type', 'Filter value': issueTypes[0] });
      return { type: ActionTypes.SELECT_ISSUE_TYPES, issueTypes };
    },
    deselectIssueType: issueType => {
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Issue type', 'Filter value': issueType });
      return { type: ActionTypes.DESELECT_ISSUE_TYPE, issueType };
    },
    selectFriendlyIssueWorkflowStatuses: friendlyIssueWorkflowStatuses => {
      const filterData = friendlyIssueWorkflowStatuses[0] && friendlyIssueWorkflowStatuses[0].name;
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Issue workflow', 'Filter value': filterData });
      return { type: ActionTypes.SELECT_ISSUE_WORKFLOW_STATUSES, friendlyIssueWorkflowStatuses };
    },
    deselectFriendlyIssueWorkflowStatus: friendlyIssueWorkflowStatus => {
      const filterData = friendlyIssueWorkflowStatus && friendlyIssueWorkflowStatus.name;
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Issue workflow', 'Filter value': filterData });
      return { type: ActionTypes.DESELECT_ISSUE_WORKFLOW_STATUS, friendlyIssueWorkflowStatus };
    },
    selectComplianceStatuses: complianceStatuses => {
      const filterData = complianceStatuses[0] && complianceStatuses[0].text;
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Compliance status', 'Filter value': filterData });
      return { type: ActionTypes.SELECT_COMPLIANCE_STATUSES, complianceStatuses };
    },
    deselectComplianceStatus: complianceStatus => {
      const filterData = complianceStatus && complianceStatus.text;
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Compliance status', 'Filter value': filterData });
      return { type: ActionTypes.DESELECT_COMPLIANCE_STATUS, complianceStatus };
    },
    selectInspectionStatuses: inspectionStatuses => {
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Inspection status', 'Filter value': inspectionStatuses[0] });
      return { type: ActionTypes.SELECT_INSPECTION_STATUSES, inspectionStatuses };
    },
    deselectInspectionStatus: inspectionStatus => {
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Inspection status', 'Filter value': inspectionStatus });
      return { type: ActionTypes.DESELECT_INSPECTION_STATUS, inspectionStatus };
    },
    getMonths,
    setMonthFilter: (monthType, selectedMonth) => {
      if (monthType === MonthType.None) {
        track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Month' });
      }
      else {
        track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Month', 'Filter value': monthType });
      }
      return { type: ActionTypes.SET_MONTH_FILTER, monthType, selectedMonth };
    },
    selectSites: sites => {
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Site' });
      return { type: ActionTypes.SELECT_SITES, sites };
    },
    deselectSite: site => {
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Site' });
      return { type: ActionTypes.DESELECT_SITE, site };
    },
    selectTankProductLabels: tankProductLabels => {
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Tank label' });
      return { type: ActionTypes.SELECT_TANK_PRODUCT_LABELS, tankProductLabels };
    },
    deselectTankProductLabel: tankProductLabel => {
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Tank label' });
      return { type: ActionTypes.DESELECT_TANK_PRODUCT_LABEL, tankProductLabel };
    },
    selectTankStatuses: tankStatuses => {
      track('View manager', { component: 'Filter edit', 'View action': 'Set filter', 'Filter type': 'Tank status' });
      return { type: ActionTypes.SELECT_TANK_STATUSES, tankStatuses };
    },
    deselectTankStatus: tankStatus => {
      track('View manager', { component: 'Tag list', 'View action': 'Remove filter', 'Filter type': 'Tank status' });
      return { type: ActionTypes.DESELECT_TANK_STATUS, tankStatus };
    },
    setColumns: columns => ({ type: ActionTypes.SET_COLUMNS, columns }),
  };
}
