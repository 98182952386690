export const TankMaterialType = {
  Unknown: 'Unknown',
  Steel: 'Steel',
  FRP: 'Fiberglass Reinforced Plastic',
};

export const PreEngineeredCorrosionProtectionType = {
  Unknown: 'Unknown',
  Unprotected: 'Unprotected',
  UnlistedCoating: 'Unlisted coating',
  Ul1746PartI: 'STI-P3/UL 1746 Part I',
  Ul1746PartII: 'STI ACT-100/UL 1746 Part II',
  Ul1746PartIII: 'STI Permatank/UL 1746 Part III',
  Ul1746PartIV: 'STI ACT-100-U/UL 1746 Part IV',
};

export const TankWallType = {
  Unknown: 'Unknown',
  SingleWall: 'Single wall',
  DoubleWall: 'Double wall',
  TripleWall: 'Triple wall',
};

export const ServiceChangeType = {
  InService: 'In service',
  TemporarilyOutOfService: 'Temporarily out of service',
  Removed: 'Removed',
  AbandonedInPlace: 'Abandoned in place',
};
