const Overfill = { id: 9,
  name: 'overfillPrevention',
  label: 'Overfill Prevention',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: false,
  card: true,
  path: 'overfillPrevention',
  index: 0,
  fields: [
    { id: 1,
      name: 'overfillPrevention',
      label: 'Type',
      path: 'overfillPrevention.0.overfillPrevention',
      type: 'select',
      values: ['Flow restrictor', 'Automatic shutoff', 'High level alarm'],
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
    { id: 2,
      name: 'installDate',
      label: 'Installation Date',
      path: 'overfillPrevention.0.installDate',
      type: 'date',
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
  ],
};

export default Overfill;
