import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

class Accordion extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: initialProps.initiallyOpen,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {

    return (
      <div>
        <div className="d-flex accordion" onClick={this.toggle}>
          <h4 className="text-medium mx-0 my-2">{this.props.title}</h4>
          <button
            type="button"
            className="btn btn-link align-text-top p-0 ml-auto"
          >
          <span className={this.state.isOpen ? 'caret-open expander': 'expander'} >
            <FontAwesomeIcon size="2x" icon={faCaretDown} />
            </span>
          </button>
        </div>
        <div className="row">
          <Collapse isOpen={this.state.isOpen}>
            {this.props.children}
          </Collapse>
        </div>
      </div>
    );
  }
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  initiallyOpen: PropTypes.bool,
};

Accordion.defaultProps = {
  initiallyOpen: false,
};

export default Accordion;
