import React from 'react';
import PropTypes from 'prop-types';
import { FormattedTimestamp } from './date';

const UserDate = ({ className, user, date, timezone }) => {
  const username = user.username || user;
  return (
    <span className={className}>
      <strong>{username}</strong>
      {' - '}
      <FormattedTimestamp time={date} zone={timezone} />
    </span>
  );
};

UserDate.propTypes = {
  className: PropTypes.string,
  user: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      username: PropTypes.string.isRequired,
    })
  ]).isRequired,
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

UserDate.defaultProps = {
  className: null,
};

export default UserDate;
