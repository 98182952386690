import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Card, CardBody, Button } from "reactstrap";
import TextInputField from "./TextInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ActionBar from "./ActionBar";
import withEither from "./withEither";
import Datepicker from "./Datepicker";
import Numberpicker from "./Numberpicker";
import Dropdown from "./DropdownList";
import NullComponent from "./NullComponent";
import SwitchInputField from "./Switch";
import TablePanel from "./TablePanel";
import CardPanel from "./CardPanel";

const switchConditionFn = props =>
  props.field.display && props.field.type === "bool";
const datepickerConditionFn = props =>
  props.field.display && props.field.type === "date";
const numberpickerConditionFn = props =>
  props.field.display && props.field.type === "number";
const dropdownConditionFn = props =>
  props.field.display && props.field.type === "select";
const TextInputConditionFn = props =>
  props.field.display && props.field.type === "text";

const withConditionalRenderings = compose(
  withEither(TextInputConditionFn, TextInputField),
  withEither(switchConditionFn, SwitchInputField),
  withEither(datepickerConditionFn, Datepicker),
  withEither(numberpickerConditionFn, Numberpicker),
  withEither(dropdownConditionFn, Dropdown)
);

const PanelContentsWithConditionalRendering = withConditionalRenderings(
  NullComponent
);

const FormPanel = props => (
  <div className="tank-registration-form">
    <Card>
      <CardBody>
        <div className="row mb-2">
          <div className="col-sm-10">
            <h1 className="d-flex justify-content-start canary">
              {props.panel.label}{" "}
            </h1>
          </div>
          {!props.panel.edit && !props.panel.table && !props.panel.card ? (
            <div className="col-sm-2">
              <div className="d-flex justify-content-end">
                <Button
                  disabled={props.panel.disableEditButton || false}
                  className="mt-2"
                  outline
                  color="primary"
                  onClick={() =>
                    props.toggleEditFormBuilderPanel(
                      props.panel,
                      !props.panel.disableEditButton
                    )
                  }
                >
                  EDIT
                </Button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row">
          {(props.panel.table || props.panel.card) &&
          !props.edit &&
          !props.panel.disableEditButton ? (
            <div className="d-flex col-sm-12 mb-3">
              <a
                disabled={props.panel.disableEditButton || false}
                className="add-new-reg d-flex align-items-center"
                name="addNew"
                role="button"
                onClick={e => {
                  e.preventDefault();
                  props.addNewItem(props.panel, props.target);
                }}
              >
                <span className="pl-1 pt-1 d-flex align-items-center">
                  <FontAwesomeIcon  icon={faPlusCircle} />
                </span>
                <span className="pl-1 pt-1 d-flex align-items-center">
                  Add a New{" "}
                  {props.panel.label.endsWith("s") &&
                  props.panel.label !== "Status"
                    ? props.panel.label.slice(0, -1)
                    : props.panel.label}
                </span>
              </a>
            </div>
          ) : (
            <div>
              {props.panel.table || props.panel.card ? (
                <div className="d-flex col-sm-12 mb-3">
                  <span className="pl-1 pt-1 d-flex align-items-center">
                    &nbsp;
                  </span>
                </div>
              ) : null}
            </div>
          )}

          {props.panel.errorMessages && props.panel.errorMessages.length ? (
            <div className="col-sm-12">
              <div className="alert alert-danger mb-3" role="alert">
                {props.panel.errorMessages.map((msg, idx) => (
                  <div key={idx}>{msg.message}</div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <div className="row">
          {!props.panel.table &&
            !props.panel.card &&
            props.panel.fields.map(field => {
              if (field.display) {
                return (
                  <div key={field.id} className={`col-sm-${field.cols}`}>
                    <PanelContentsWithConditionalRendering
                      key={field.id}
                      field={field}
                      time={false}
                      {...props}
                    />
                  </div>
                );
              }
            })}

          {props.panel.table ? (
            <div className="col-sm-12">
              <TablePanel {...props} />
            </div>
          ) : null}

          {props.panel.card ? (
            <div className="col-sm-12">
              <CardPanel {...props} />
            </div>
          ) : null}
        </div>
        {props.panel.table || props.panel.card || !props.edit ? null : (
          <div className="row">
            <ActionBar {...props} />
          </div>
        )}
      </CardBody>
    </Card>
  </div>
);

FormPanel.propTypes = {
  panel: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  target: PropTypes.object.isRequired,
  addNewItem: PropTypes.func.isRequired,
  toggleEditFormBuilderPanel: PropTypes.func.isRequired
};

FormPanel.defaultProps = {
  edit: false,
  error: null
};

export default FormPanel;
