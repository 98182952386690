import ActionTypes from './DispatchSystemDropdownActionTypes';


export default function DispatchSystemDropdownReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_DISPATCH_SYSTEM_LIST_STARTED:
      return Object.assign({}, state, {
        dispatchSystemList: [],
        dispatchSystemListLoading: true,
      });
    case ActionTypes.GET_DISPATCH_SYSTEM_LIST_FAILED:
      return Object.assign({}, state, {
        dispatchSystemList: [],
        dispatchSystemListLoading: false,
      });
    case ActionTypes.RECEIVE_DISPATCH_SYSTEM_LIST:
      return Object.assign({}, state, {
        dispatchSystemList: action.dispatchSystemList,
        dispatchSystemListLoading: false,
      });
    default:
      return state;
  }
}

