import CommentSelectors from './components/comments/CommentSelectors';
import MviReportSelectors from './components/report/MviReportSelectors';
import * as ActionItemsSelectors from './components/report/action-items/ActionItemsSelectors';
import MviEditSelectors from './MviEditSelectors';

export const isEditDisabled = state => CommentSelectors.isEditing(state)
  || CommentSelectors.isSaving(state)
  || MviReportSelectors.isEditing(state)
  || MviReportSelectors.isEditingInspectorComments(state)
  || MviReportSelectors.isSaving(state)
  || MviReportSelectors.isDeleting(state)
  || ActionItemsSelectors.isAdding(state)
  || ActionItemsSelectors.isEditing(state)
  || MviEditSelectors.isSaving(state);

export const error = state => CommentSelectors.error(state)
  || MviReportSelectors.error(state)
  || MviEditSelectors.error(state);
