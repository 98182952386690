import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const DeleteType = {
  Site: 'site',
  Item: 'item',
};

const InitialState = {
  deleteType: DeleteType.Site,
};

class DocDeleteModal extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = InitialState;
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
  }

  setDeleteType(deleteType) {
    this.setState({
      deleteType
    });
  }

  handleConfirmDelete() {
    if (this.state.deleteType === DeleteType.Site) {
      this.props.onDocDelete();
    }
    else {
      this.props.onDocUnlink();
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader>
          Delete document?
        </ModalHeader>
        <ModalBody>
          <FormGroup tag="fieldset">
            <FormGroup check>
              <Label check>
                <Input type="radio" name="deleteType" checked={this.state.deleteType === DeleteType.Site} onChange={() => this.setDeleteType(DeleteType.Site)} />
                {' '}
                Permanently delete this document
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="deleteType" checked={this.state.deleteType === DeleteType.Item} onChange={() => this.setDeleteType(DeleteType.Item)} />
                {' '}
                Unlink the document from this
                {' '}
                {this.props.itemText}
              </Label>
            </FormGroup>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={this.props.onCancel}>Cancel</Button>
          {' '}
          <Button color="danger" size="sm" onClick={this.handleConfirmDelete}>
            {this.state.deleteType === DeleteType.Site ? 'Delete' : 'Unlink'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

DocDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  itemText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDocDelete: PropTypes.func.isRequired,
  onDocUnlink: PropTypes.func.isRequired,
};

export default DocDeleteModal;
