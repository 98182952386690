export default {
  RESET: 'sites/reset',
  CHANGE_SITE_STARTED: 'sites/change_site_started',
  RECEIVE_SITE: 'sites/receive_site',
  CHANGE_SITE_FAILED: 'sites/change_site_failed',
  RECEIVE_CUSTOMER_LABELS: 'sites/receive_customer_labels',

  EDIT_CURRENT_SITE: 'sites/edit_current_site',
  DISCARD_SITE_EDITS: 'sites/discard_site_edits',

  SAVE_SITE_STARTED: 'sites/save_site_started',
  SAVE_SITE_SUCCESS: 'sites/save_site_success',
  SAVE_SITE_FAILED: 'sites/save_site_failed',
};
