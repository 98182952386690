import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedConnectionStatuses = ({ selectedConnectionStatuses, disabled, deselectConnectionType }) => <FilterItemList items={selectedConnectionStatuses} textField="name" disabled={disabled} onRemoveItem={deselectConnectionType} />;

SelectedConnectionStatuses.propTypes = {
  selectedConnectionStatuses: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  deselectConnectionType: PropTypes.func.isRequired,
};

SelectedConnectionStatuses.defaultProps = {
  selectedConnectionStatuses: [],
};

export default SelectedConnectionStatuses;
