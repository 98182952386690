import React, { Component } from 'react';
import { Form, Input, FormGroup, Label, Col, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper/index';
import OrganizationDropdownContainer from '../../../../../../../../shared/components/organization-dropdown/containers/OrganizationDropdownContainer';

const PseudoRoles = {
  User: 'user',
  OrgAdmin: 'org admin',
  CanaryAdmin: 'canary admin',
};

class UserSettings extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleUserOrganizationChange = this.handleUserOrganizationChange.bind(this);
    this.getPseudoRole = this.getPseudoRole.bind(this);
    this.extractOrgName = this.extractOrgName.bind(this);
  }

  getPseudoRole() {
    const canaryAdmin = this.props.selectedUser
      && this.props.selectedUser.userType === 'admin';

    const orgAdmin = this.props.selectedUser
      && this.props.selectedUser.organizationMembership
      && this.props.selectedUser.organizationMembership.isAdmin;

    if (canaryAdmin) {
      return PseudoRoles.CanaryAdmin;
    }
    else if (orgAdmin) {
      return PseudoRoles.OrgAdmin;
    }
    return PseudoRoles.User;
  }

  handleUsernameChange(event) {
    const updatedUser = update(this.props.selectedUser, {
      username: {
        $set: event.target.value,
      },
    });
    this.props.editSelectedUser(updatedUser);
  }

  handleEmailChange(event) {
    const updatedUser = update(this.props.selectedUser, {
      email: {
        $set: event.target.value,
      },
    });
    this.props.editSelectedUser(updatedUser);
  }

  handlePasswordChange(event) {
    const updatedUser = update(this.props.selectedUser, {
      password: {
        $set: event.target.value,
      },
    });
    this.props.editSelectedUser(updatedUser);
  }

  handleRoleChange(event) {
    let updateSpec;
    if (event.target.value === PseudoRoles.CanaryAdmin) {
      updateSpec = {
        userType: {
          $set: 'admin',
        },
        organizationMembership: {
          isAdmin: {
            $set: false
          },
        },
      };
    }
    else {
      updateSpec = {
        userType: {
          $set: 'non-admin',
        },
        organizationMembership: {
          isAdmin: {
            $set: event.target.value === PseudoRoles.OrgAdmin
          },
        },
      };
    }

    const updatedUser = update(this.props.selectedUser, updateSpec);
    this.props.editSelectedUser(updatedUser);
  }

  handleActiveChange(event) {
    const updatedUser = update(this.props.selectedUser, {
      active: {
        $set: event.target.checked,
      },
    });
    this.props.editSelectedUser(updatedUser);
  }

  handleUserOrganizationChange(organization) {
    let updatedUser;
    if (organization) {
      updatedUser = update(this.props.selectedUser, {
        organizationMembership: {
          organization: {
            $set: organization
          },
          organizationId: {
            $set: organization.id,
          }
        },
        groups: {
          $set: [],
        },
      });
    }
    else {
      updatedUser = update(this.props.selectedUser, {
        organizationMembership: {
          organization: {
            $set: null
          },
          organizationId: {
            $set: null,
          }
        },
        groups: {
          $set: null,
        },
      });
    }

    this.props.editSelectedUser(updatedUser);
  }

  extractOrgName(uniqueUsername, user) {
    if (uniqueUsername || !user) {
      return null;
    }
    return user.organizationMembership.organization.name;
  }

  render() {
    const newUser = !Number.isInteger(this.props.selectedUser.id);
    return (
      <div>
        <Form autoComplete="off">
          <input id="username" style={{ display: 'none' }} type="text" name="fake_username" />
          <input id="email" style={{ display: 'none' }} type="email" name="fake_email" />
          <input id="password" style={{ display: 'none' }} type="password" name="fake_password" />

          <FormGroup row>
            <Label for="realUsername" sm={2}>Username</Label>
            <Col sm={10}>
              <Input
                id="realUsername"
                type="text"
                value={this.props.selectedUser.username}
                name="realUsername"
                placeholder="Username"
                onChange={this.handleUsernameChange}
                autoComplete="invalid_value"
                disabled={!newUser}
                invalid={!this.props.hasUniqueUsername}
              />
              <FormFeedback>
                A user account already exists with this username
                { this.props.loggedInUserIsAdmin ? ` in ${this.extractOrgName(this.props.hasUniqueUsername, this.props.usersByUsername[this.props.selectedUser.username])}` : null }
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="realEmail" sm={2}>Email</Label>
            <Col sm={10}>
              <Input
                type="email"
                value={this.props.selectedUser.email}
                name="realEmail"
                id="realEmail"
                placeholder="Email"
                onChange={this.handleEmailChange}
                autoComplete="invalid_value"
                invalid={!this.props.hasUniqueEmail}
              />
              <FormFeedback>
                A user account already exists with this email address
                { this.props.loggedInUserIsAdmin ? ` in ${this.extractOrgName(this.props.hasUniqueEmail, this.props.usersByEmail[this.props.selectedUser.email])}` : null }
              </FormFeedback>
            </Col>
          </FormGroup>

          {newUser
            ? (
              <FormGroup row>
                <Label for="realPassword" sm={2}>Password</Label>
                <Col sm={10}>
                  <Input
                    type="text"
                    value={this.props.selectedUser.password}
                    name="realPassword"
                    id="realPassword"
                    placeholder="Password"
                    onChange={this.handlePasswordChange}
                    autoComplete="invalid_value"
                    invalid={!!(this.props.selectedUser && this.props.selectedUser.password) && !this.props.hasValidPasswordLength}
                  />
                  <FormFeedback>Password must be at least eight characters long</FormFeedback>
                </Col>
              </FormGroup>
            )
            : null}

          <FormGroup row>
            <Label for="role" sm={2}>Role</Label>
            <Col sm={10}>
              <Input type="select" value={this.getPseudoRole()} name="roleSelect" id="roleSelect" onChange={this.handleRoleChange}>
                <option key={PseudoRoles.User} value={PseudoRoles.User}>{PseudoRoles.User}</option>
                <option key={PseudoRoles.OrgAdmin} value={PseudoRoles.OrgAdmin}>{PseudoRoles.OrgAdmin}</option>
                <option key={PseudoRoles.CanaryAdmin} value={PseudoRoles.CanaryAdmin}>{PseudoRoles.CanaryAdmin}</option>
              </Input>
            </Col>
          </FormGroup>

          {!newUser ? (
            <FormGroup row>
              <Label for="role" sm={2}>Organization</Label>
              <Col sm={10}>
                <OrganizationDropdownContainer selectedOrganization={this.props.selectedUser.organizationMembership.organization} onOrganizationSelect={this.handleUserOrganizationChange} />
              </Col>
            </FormGroup>
          )
            : null}

          {!newUser
            ? (
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={this.props.selectedUser.active} onChange={this.handleActiveChange} />
                  Enable this user
                </Label>
              </FormGroup>
            )
            : null}
        </Form>

      </div>
    );
  }
}

UserSettings.propTypes = {
  selectedUser: PropTypes.object.isRequired,
  editSelectedUser: PropTypes.func.isRequired,
  hasValidPasswordLength: PropTypes.bool.isRequired,
  hasUniqueEmail: PropTypes.bool.isRequired,
  hasUniqueUsername: PropTypes.bool.isRequired,
  usersByUsername: PropTypes.object.isRequired,
  usersByEmail: PropTypes.object.isRequired,
  loggedInUserIsAdmin: PropTypes.bool.isRequired,
};

export default UserSettings;
