function stateSlice(state) {
  return (state && state.sites && state.sites.equipment && state.sites.equipment.atg) || {};
}

export const atgInfoLoading = (state) => stateSlice(state).atgInfoLoading || false;

export const atgInfoError = (state) => stateSlice(state).atgInfoError || null;

export const atgInfo = (state) => stateSlice(state).atgInfo || null;

export const atgConfigEventsLoading = (state) => stateSlice(state).atgConfigEventsLoading || false;

export const atgConfigEventsError = (state) => stateSlice(state).atgConfigEventsError || null;

export const atgConfigEvents = (state) => stateSlice(state).atgConfigEvents || [];
