import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import { ItemType, OperationState } from '../DocConstants';
import UploadButton from '../components/upload/UploadButton';
import * as selectors from '../SiteDocumentSelectors';
import * as actions from '../actions';
import ButtonWithConfirmation from '../../../../../shared/components/button-with-confirmation/ButtonWithConfirmation';
import EditButton from './EditButton';
import {track} from '../../../../../shared/analytics';

class Toolbar extends Component {
  render() {
    return (
      <Row className="d-flex p-2 align-items-center">
        <Col md={3} className="pr-md-0">
          {this.renderWorking()}
        </Col>
        <Col md={9} className="d-flex justify-content-md-end pl-md-0">
          <div>
            <EditButton />
            {this.renderDelete()}
            {this.renderDownload()}
            {this.renderUpload()}
          </div>
        </Col>
      </Row>
    );
  }

  renderWorking() {
    if (this.props.operationState !== OperationState.Ready) {
      return (
        <>
          <div className="d-inline-block">
            <PulseLoader color="#F8E71C" size={8} />
          </div>
          <span className="ml-2">
            {this.props.operationState}
            ...
          </span>
        </>
      );
    }
    return null;
  }

  renderDelete() {
    const disabled = !this.props.selectedItems.length || !!this.props.selectedItems.find(current => current.type === ItemType.Folder);
    return (
      <ButtonWithConfirmation
        buttonLabel="Delete"
        buttonColor="danger"
        buttonSize="sm"
        buttonOutline
        buttonDisabled={disabled}
        buttonClassName="ml-2"
        onConfirm={this.props.deleteSelectedDocuments}
        modalBodyText="Are you sure you want to delete this document? This action cannot be undone."
      />
    );
  }

  renderDownload() {
    const disabled = !this.props.selectedItems.length;
    return (
      <Button color="primary" size="sm" outline className="ml-2" onClick={this.props.downloadSelectedItems} disabled={disabled}>
        Download
      </Button>
    );
  }

  renderUpload() {
    const disabled = !!this.props.selectedItems.length;
    const folderName = this.props.activeFolder && this.props.activeFolder.folderName;
    return (
      <UploadButton className="ml-2" defaultFolderName={folderName} disabled={disabled} />
    );
  }
}

Toolbar.propTypes = {
  operationState: PropTypes.string.isRequired,
  activeFolder: PropTypes.object,
  selectedItems: PropTypes.array,

  downloadSelectedItems: PropTypes.func.isRequired,
  deleteSelectedDocuments: PropTypes.func.isRequired,
};

Toolbar.defaultProps = {
  activeFolder: null,
  selectedItems: [],
};

function mapStateToProps(state) {
  return {
    operationState: selectors.operationState(state),
    activeFolder: selectors.activeFolder(state),
    selectedItems: selectors.selectedItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    downloadSelectedItems: () => {
      track('Document', {
        Component: 'Download button',
        'Document action': 'Download',
      });
      return dispatch(actions.downloadSelectedItems());
    },
    deleteSelectedDocuments: () => {
      track('Document', {
        Component: 'Delete button',
        'Document action': 'Delete',
      });
      return dispatch(actions.deleteSelectedDocuments());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
