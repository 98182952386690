import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input } from 'reactstrap';

import { dateAsText } from '../../../../../../../../shared/components/date';
import CanaryModal from '../../../../../../../../shared/components/CanaryModal';
import * as DocActions from '../../../../../documents/actions';
import MviReportSelectors from './MviReportSelectors';
import * as MviReportActions from './MviReportActions';

class DeleteReportModal extends Component {
  constructor(initialState) {
    super(initialState);
    this.state = {
      deleteDocs: true,
    };
    this.handleDocCheck = this.handleDocCheck.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
  }

  handleDocCheck(e) {
    this.setState({
      deleteDocs: e.target.checked,
    });
  }

  handleConfirmDelete() {
    this.props.confirmDelete();
    if (this.state.deleteDocs && this.props.currentReport.documents && this.props.currentReport.documents.length) {
      this.props.deleteDocs(this.props.currentReport.documents);
    }
  }

  render() {
    const { isDeleteModalOpen, currentReport, cancelDelete } = this.props;
    const body = `Are you sure you want to delete the MVI report from ${dateAsText(currentReport && currentReport.inspectionDate)}?`;
    return (
      <CanaryModal
        open={isDeleteModalOpen}
        title="Delete report"
        body={body}
        onCancel={cancelDelete}
        onConfirm={this.handleConfirmDelete}
        confirmButtonText="Delete"
        size="md"
      >
        <span>{body}</span>
        {this.renderDocDeleteCheckbox()}
      </CanaryModal>
    );
  }

  renderDocDeleteCheckbox() {
    if (!this.props.currentReport || !this.props.currentReport.documents || !this.props.currentReport.documents.length) {
      return null;
    }

    return (
      <Form className="mt-2">
        <FormGroup check>
          <Label check>
            <Input type="checkbox" checked={this.state.deleteDocs} onChange={this.handleDocCheck} />
            Delete documents attached to this MVI
          </Label>
        </FormGroup>
      </Form>
    );
  }
}

DeleteReportModal.propTypes = {
  isDeleteModalOpen: PropTypes.bool.isRequired,
  currentReport: PropTypes.object,
  cancelDelete: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  deleteDocs: PropTypes.func.isRequired,
};

DeleteReportModal.defaultProps = {
  currentReport: null,
};

function mapStateToProps(state) {
  return {
    isDeleteModalOpen: MviReportSelectors.isDeleteModalOpen(state),
    currentReport: MviReportSelectors.item(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cancelDelete: () => dispatch(MviReportActions.closeDeleteReportModal()),
    confirmDelete: () => dispatch(MviReportActions.confirmDelete()),
    deleteDocs: docs => dispatch(DocActions.deleteDocuments(docs)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteReportModal);
