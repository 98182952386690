import { getListAction } from '../../../../../../shared/redux-helpers/ActionCreatorFactory';
import { MviClient } from '../../../../../../client';
import ActionTypes from './SiteMviListActionTypes';
import * as SiteSelectors from '../../../../SiteSelectors';
import selectors from './SiteMviListSelectors';

export const getMviList = getListAction(
  selectors.isLoading,
  ActionTypes.GET_LIST_STARTED,
  ActionTypes.RECEIVE_LIST,
  ActionTypes.GET_LIST_FAILED,
  state => {
    const site = SiteSelectors.currentSite(state);
    return MviClient.getSiteMvis(site);
  }
);
