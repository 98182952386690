import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import * as navActions from '../../../../../actions';
import * as orgEditSelectors from '../../../OrganizationEditSelectors';
import * as selectors from '../GroupEditorSelectors';
import * as actions from '../actions';
import UserList from '../../../../../../../components/user-list/UserList';

class Members extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.handleUserDeselect = this.handleUserDeselect.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleNameChange(e) {
    const updatedGroup = update(this.props.pendingGroupEdits, {
      name: {
        $set: e.target.value,
      }
    });
    this.props.editSelectedGroup(updatedGroup);
  }

  handleUserSelect(user) {
    const updateSpec = {};
    if (this.props.pendingGroupEdits.members) {
      updateSpec.members = {
        $push: [user],
      };
    }
    else {
      updateSpec.members = {
        $set: [user],
      };
    }

    const updatedGroup = update(this.props.pendingGroupEdits, updateSpec);
    this.props.editSelectedGroup(updatedGroup);
  }

  handleUserDeselect(user) {
    const index = this.props.pendingGroupEdits.members.findIndex(current => current.id === user.id);
    const updatedGroup = update(this.props.pendingGroupEdits, {
      members: {
        $splice: [[index, 1]],
      }
    });
    this.props.editSelectedGroup(updatedGroup);
  }

  handleCancel() {
    if (Number.isInteger(this.props.pendingGroupEdits.id)) {
      this.props.cancelSelectedGroupEdit();
    }
    else {
      this.props.navToGroupList();
    }
  }

  handleSave() {
    this.props.saveSelectedGroup().then(() => {
      if (Number.isInteger(this.props.pendingGroupEdits.id)) {
        return Promise.resolve();
      }

      return this.props.navToGroupList();
    });
  }

  render() {
    if (!this.props.pendingGroupEdits || this.props.isSaving) {
      return (
        <PulseLoader color="#F8E71C" size={8} />
      );
    }

    return (
      <div>
        <Form>
          <FormGroup row>
            <Label for="name" sm={2}>Group Name:</Label>
            <Col sm={10}>
              <Input type="text" id="name" placeholder="Enter a name for the group" value={this.props.pendingGroupEdits.name} onChange={this.handleNameChange} />
            </Col>
          </FormGroup>
        </Form>
        <Row>
          <Label sm={2}>Members:</Label>
          <Col sm={10}>
            <UserList
              allUsers={this.props.selectedOrganizationUsers}
              selectedUsers={this.props.pendingGroupEdits.members}
              onUserCheck={this.handleUserSelect}
              onUserUncheck={this.handleUserDeselect}
            />
          </Col>
        </Row>
        <div className="d-flex flex-row mt-4">
          <Button size="sm" color="primary" outline onClick={this.handleCancel} disabled={!this.props.canCancel}>CANCEL</Button>
          <Button size="sm" className="ml-2" id="save" color="primary" onClick={this.handleSave} disabled={!this.props.canSave}>SAVE</Button>
        </div>
      </div>
    );
  }
}

Members.propTypes = {
  selectedOrganizationUsers: PropTypes.array.isRequired,
  pendingGroupEdits: PropTypes.object,
  editSelectedGroup: PropTypes.func.isRequired,
  saveSelectedGroup: PropTypes.func.isRequired,
  cancelSelectedGroupEdit: PropTypes.func.isRequired,
  navToGroupList: PropTypes.func.isRequired,

  isSaving: PropTypes.bool.isRequired,
  canCancel: PropTypes.bool.isRequired,
  canSave: PropTypes.bool.isRequired,
};

Members.defaultProps = {
  pendingGroupEdits: null,
};

function mapStateToProps(state) {
  return {
    selectedOrganizationUsers: orgEditSelectors.selectedOrganizationUsers(state),
    selectedGroup: selectors.selectedGroup(state),
    pendingGroupEdits: selectors.pendingGroupEdits(state),
    isSaving: orgEditSelectors.isSaving(state),
    canCancel: selectors.canCancelPendingGroupEdits(state),
    canSave: selectors.canSavePendingGroupEdits(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    editSelectedGroup: (group) => dispatch(actions.editSelectedGroup(group)),
    saveSelectedGroup: () => dispatch(actions.saveSelectedGroup()),
    cancelSelectedGroupEdit: () => dispatch(actions.cancelSelectedGroupEdit()),
    navToGroupList: () => dispatch(navActions.navToGroupList(ownProps.match.params.organizationId))
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Members));
