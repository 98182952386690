import React from 'react';
import PropTypes from 'prop-types';

import ConnectionType from '../../../shared/components/ConnectionType';
import { CanaryTable, Column, IntegerColumn, LabelListColumn, SortDirection, StringColumn } from '../../../shared/components/table';
import { SiteConnectionTypes } from '../../../AppConstants';
import { SiteTableColumns } from '../SiteListConstants';
import SiteAlarmCount from './SiteAlarmCount';
import SiteCompliance from './SiteCompliance';
import WorkOrders from './SiteDispatches';
import SiteMvi from './SiteMvi';

const renderConnectionType = site => (site.connectionType === SiteConnectionTypes.PARTICLE ? 'Canary' : site.connectionType);

const SitesTable = React.memo(({ dataLoading, sites, columns, sortColumnId, sortDirection, onSortChange, currentPage, onPageChange, pageSize, pageCount, onSiteSelected }) => {
  const columnIds = columns.map(current => current.id);
  const hasColumn = col => columnIds.indexOf(col.id) !== -1;

  return (
    <CanaryTable
      dataLoading={dataLoading}
      data={sites}
      sortColumnId={sortColumnId}
      sortDirection={sortDirection}
      onSortChange={onSortChange}
      currentPage={currentPage}
      onPageChange={onPageChange}
      pageSize={pageSize}
      pageCount={pageCount}
      onRowClick={site => onSiteSelected(site.id)}
    >
      {hasColumn(SiteTableColumns.ConnectionInfo)
        ? (
          <Column
            columnId={SiteTableColumns.ConnectionInfo.id}
            render={site => <ConnectionType connectionType={site.connectionType} connectionStatus={site.connectionStatus} />}
            title={SiteTableColumns.ConnectionInfo.shortName}
            width="3%"
            sortable
          />
        )
        : null}

      {hasColumn(SiteTableColumns.ConnectionType)
        ? <Column columnId={SiteTableColumns.ConnectionType.id} render={renderConnectionType} title={SiteTableColumns.ConnectionType.shortName} width="7%" sortable />
        : null}

      {hasColumn(SiteTableColumns.ConnectionStatus)
        ? <StringColumn columnId={SiteTableColumns.ConnectionStatus.id} title={SiteTableColumns.ConnectionStatus.shortName} property="connectionStatus" width="9%" sortable />
        : null}

      {hasColumn(SiteTableColumns.Customer)
        ? <StringColumn columnId={SiteTableColumns.Customer.id} property="customer.name" title={SiteTableColumns.Customer.shortName} width="15%" sortable />
        : null}
      {hasColumn(SiteTableColumns.Nickname)
        ? <StringColumn columnId={SiteTableColumns.Nickname.id} property="nickname" title={SiteTableColumns.Nickname.shortName} width="15%" sortable />
        : null}
      {hasColumn(SiteTableColumns.State)
        ? <StringColumn columnId={SiteTableColumns.State.id} property="state" title={SiteTableColumns.State.shortName} width="4rem" sortable />
        : null}
      {hasColumn(SiteTableColumns.ActiveAlarmCount)
        ? <Column columnId={SiteTableColumns.ActiveAlarmCount.id} render={site => <SiteAlarmCount site={site} />} title={SiteTableColumns.ActiveAlarmCount.shortName} sortable initialSortDirection={SortDirection.Desc} width="5%" />
        : null}
      {hasColumn(SiteTableColumns.OpenIssueCount)
        ? <IntegerColumn columnId={SiteTableColumns.OpenIssueCount.id} property={SiteTableColumns.OpenIssueCount.id} title={SiteTableColumns.OpenIssueCount.shortName} sortable initialSortDirection={SortDirection.Desc} width="7%" />
        : null}
      {hasColumn(SiteTableColumns.WorkOrders)
        ? <Column columnId={SiteTableColumns.WorkOrders.id} render={site => <WorkOrders site={site} />} title={SiteTableColumns.WorkOrders.shortName} sortable width="7%" />
        : null}
      {hasColumn(SiteTableColumns.ComplianceReportStatus)
        ? (
          <Column
            columnId={SiteTableColumns.ComplianceReportStatus.id}
            render={site => <SiteCompliance complianceReportStatus={site.complianceReportStatus} />}
            title={SiteTableColumns.ComplianceReportStatus.shortName}
            width="7%"
            sortable
          />
        )
        : null}
      {hasColumn(SiteTableColumns.MviReportStatus)
        ? (
          <Column
            columnId={SiteTableColumns.MviReportStatus.id}
            render={site => <SiteMvi status={site.mviStatus} />}
            title={SiteTableColumns.MviReportStatus.shortName}
            width="7%"
            sortable
          />
        )
        : null}
      {hasColumn(SiteTableColumns.Labels)
        ? <LabelListColumn columnId={SiteTableColumns.Labels.id} property="labels" title={SiteTableColumns.Labels.shortName} width="11%" />
        : null}
    </CanaryTable>
  );
});

SitesTable.propTypes = {
  dataLoading: PropTypes.bool,
  sites: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  sortColumnId: PropTypes.string,
  sortDirection: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onSiteSelected: PropTypes.func.isRequired,
};

SitesTable.defaultProps = {
  dataLoading: false,
  sortColumnId: null,
  sortDirection: null,
};

export default SitesTable;
