import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { connect } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../SitePickerSelectors';
import SitePicker from './SitePicker';

class SitePickerModalButton extends Component {
  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  save() {
    this.props.onSave(this.props.selectedSites);
    this.toggle();
  }

  toggle() {
    this.props.reset();
    this.props.setModalState(!this.props.modalOpen);
  }

  render() {
    return (
      <div className={this.props.className}>
        <Button color={this.props.buttonColor} size={this.props.buttonSize} outline={this.props.buttonOutline} onClick={this.toggle} className={this.props.buttonClassName}>{this.props.buttonLabel}</Button>
        <Modal isOpen={this.props.modalOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Add Sites</ModalHeader>
          <div style={{ padding: '20px' }}>
            <SitePicker persist={false} />
          </div>
          <ModalFooter>
            <Button color="primary" outline size="sm" onClick={this.toggle}>Cancel</Button>
            {' '}
            <Button id="done" color="primary" size="sm" onClick={this.save} disabled={this.props.selectedSites.length === 0}>Done</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

SitePickerModalButton.propTypes = {
  // public properties
  buttonClassName: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonOutline: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  className: PropTypes.string,

  // internal properties
  modalOpen: PropTypes.bool.isRequired,
  selectedSites: PropTypes.array.isRequired,
  setModalState: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

SitePickerModalButton.defaultProps = {
  buttonClassName: null,
  buttonSize: 'sm',
  buttonColor: 'primary',
  buttonOutline: false,
  buttonLabel: 'Add Site',
  className: 'd-inline',
};

function mapStateToProps(state) {
  return {
    modalOpen: selectors.isModalOpen(state),
    selectedSites: selectors.selectedSites(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setModalState: modalState => dispatch(actions.setModalState(modalState)),
    reset: () => dispatch(actions.reset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SitePickerModalButton);
