/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { TextAlign } from './TableConstants';
import PropertyColumn from './PropertyColumn';

const render = value => {
  if (value) {
    return Math.round(value).toLocaleString();
  }
  return null;
};

function IntegerColumn({ title, property, rowData }) {
  return (
    <PropertyColumn
      title={title}
      property={property}
      render={render}
      rowData={rowData}
    />
  );
}

IntegerColumn.propTypes = {
  sortable: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  textAlign: PropTypes.oneOf([TextAlign.Left, TextAlign.Right, TextAlign.Center]),
  property: PropTypes.string.isRequired,
  rowData: PropTypes.object,
};

IntegerColumn.defaultProps = {
  sortable: false,
  title: null,
  width: null,
  textAlign: TextAlign.Left,
  rowData: null,
};

export default IntegerColumn;
