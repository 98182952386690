import Selectors from '../../../../../../../../shared/redux-helpers/Selectors';

const getState = state => (state && state.sites && state.sites.mvi && state.sites.mvi.edit && state.sites.mvi.edit.reports && state.sites.mvi.edit.reports.root) || {};

class MviReportSelectors extends Selectors {
  isDeleteModalOpen(state) {
    return getState(state).deleteModalOpen || false;
  }

  isEditingInspectorComments(state) {
    return getState(state).editingInspectorComments || false;
  }

  pendingInspectorCommentsEdits(state) {
    return getState(state).pendingInspectorCommentsEdits || '';
  }

  hasReports(state) {
    const reports = super.list(state);
    return !!(reports && reports.length);
  }
}

export default new MviReportSelectors(getState);
