import { InventoryClient, InventoryParams, InventoryFields } from '../../../client';
import { getListWithEtagAction } from '../../../shared/redux-helpers/ActionCreatorFactory';
import InventorySelectors, { InventoryViewManagerSelectors } from '../InventorySelectors';
import ActionTypes from '../InventoryActionTypes';

const buildParams = state => ({
  [InventoryParams.CustomerIds.name]: InventoryViewManagerSelectors.selectedCustomerIds(state),
  [InventoryParams.SiteStates.name]: InventoryViewManagerSelectors.selectedSiteStates(state),
  [InventoryParams.SiteLabelIds.name]: InventoryViewManagerSelectors.selectedSiteLabelIds(state),
  [InventoryParams.SiteIds.name]: InventoryViewManagerSelectors.selectedSiteIds(state),
  [InventoryParams.ProductLabels.name]: InventoryViewManagerSelectors.selectedTankProductLabels(state),
  [InventoryParams.TankStatus.name]: InventoryViewManagerSelectors.selectedTankStatuses(state),
});

function buildFields(state) {
  const columnFields = InventoryViewManagerSelectors.apiFields(state);
  return [...columnFields, InventoryFields.Site];
}

export const getInventory = getListWithEtagAction(InventorySelectors, ActionTypes, buildParams, buildFields, InventoryClient.findInventories);

export const changeSort = (sortColumnId, sortDirection) => ({ type: ActionTypes.CHANGE_SORT, sortColumnId, sortDirection });

export const changePage = page => ({ type: ActionTypes.CHANGE_PAGE, page });

export const refreshTimestamps = () => ({ type: ActionTypes.REFRESH_TIMESTAMPS });
