const LeakDetection = { id: 3,
  name: 'leakDetectionHistory',
  label: 'Leak Detection History',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: true,
  modalOpen: false,
  deleteModalOpen: false,
  index: 0,
  fields: [
    { id: 1,
      name: 'effectiveDate',
      label: 'Effective Date',
      path: 'leakDetectionHistory.0.effectiveDate',
      type: 'date',
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
    { id: 2,
      name: 'primaryLeakDetection',
      label: 'Primary Leak Detection',
      path: 'leakDetectionHistory.0.primaryLeakDetection',
      type: 'select',
      values: ['None', 'Unknown', 'Inventory control', 'Manual tank gauging', 'Tank tightness testing', 'Automatic tank gauging', 'Vapor monitoring', 'Groundwater monitoring', 'Interstitial monitoring', 'Statistical inventory reconciliation', 'Other method'],
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
    { id: 3,
      name: 'secondaryLeakDetection',
      label: 'Secondary Leak Detection',
      path: 'leakDetectionHistory.0.secondaryLeakDetection',
      type: 'select',
      values: ['None', 'Unknown', 'Inventory control', 'Manual tank gauging', 'Tank tightness testing', 'Automatic tank gauging', 'Vapor monitoring', 'Groundwater monitoring', 'Interstitial monitoring', 'Statistical inventory reconciliation', 'Other method'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
  ],
};

export default LeakDetection;
