import ActionTypes from './ATGSensorsActionTypes';

export default function ATGSensorsReducer(state = {}, action) {
  switch (action.type) {

    // receive site with sensors (initial load)
    case ActionTypes.REQUEST_SITE_SENSORS:
      return Object.assign({}, state, {
        sensorsLoading: true,
        isError: false,
        error: null,
        modalOpen: false,
      });
    case ActionTypes.RECEIVE_SITE_SENSORS:
      return Object.assign({}, state, {
        currentSiteSensors: action.currentSiteSensors,
        sensorsLoading: false,
        sensorSaving: false,
        isError: false,
      });
    case ActionTypes.RECEIVE_SITE_SENSORS_ERROR:
      return Object.assign({}, state, {
        sensorsLoading: false,
        isError: true,
        error: action.error,
      });
    case ActionTypes.REQUEST_SITE_TANKS:
      return Object.assign({}, state, {
        tanksLoading: true,
        isError: false,
        error: null,
      });
    case ActionTypes.RECEIVE_SITE_TANKS:
      return Object.assign({}, state, {
        tanks: action.tanks,
        tanksLoading: false,
        tankSaving: false,
        isError: false,
      });
    case ActionTypes.RECEIVE_EDIT_SENSOR:
      return Object.assign({}, state, {
        editSensor: action.editSensor,
      });
    case ActionTypes.TOGGLE_EDIT_EQUIPMENT_MODAL:
      return Object.assign({}, state, {
        modalOpen: action.modalOpen,
        editSensor: action.editSensor,
        isError: false,
      });
    case ActionTypes.ASSOCIATE_EQUIPMENT:
      return Object.assign({}, state, {
        associatedTanks: action.associatedTanks,
      });
    case ActionTypes.BEGIN_SAVE_ASSOCIATED_EQUIPMENT:
      return Object.assign({}, state, {
        associateEquipmentSaving: true,
      });
    case ActionTypes.SAVE_ASSOCIATED_EQUIPMENT_FAILURE:
      return Object.assign({}, state, {
        isError: true,
        error: action.error,
        associateEquipmentSaving: false,
      });
    default:
      return state;
  }
}
