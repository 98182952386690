import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import findIndex from 'lodash/findIndex';
import CanaryModal from '../../../CanaryModal';

const TwoColumnLayoutCount = 10;

const ColumnModal = ({ open, onColumnSelect, onColumnDeselect, onSave, onCancel, columns, selectedColumns }) => {
  const columnsSelected = !!(selectedColumns && selectedColumns.length);
  const size = columns.length < TwoColumnLayoutCount ? 'md' : 'lg';
  return (
    <CanaryModal
      open={open}
      title="Customize Table Columns"
      onConfirm={onSave}
      canConfirm={columnsSelected}
      onCancel={onCancel}
      size={size}
    >
      <Form>
        {renderColumns(onColumnSelect, onColumnDeselect, columns, selectedColumns)}
      </Form>
    </CanaryModal>
  );
};

function renderColumns(onColumnSelect, onColumnDeselect, columns, selectedColumns) {
  if (columns.length < TwoColumnLayoutCount) {
    return columns.map(column => renderSingleColumn(column, onColumnSelect, onColumnDeselect, selectedColumns));
  }

  const mid = Math.ceil(columns.length / 2);
  const left = columns.slice(0, mid);
  const right = columns.slice(mid);
  return (
    <Row>
      <Col sm={6}>
        {left.map(column => renderSingleColumn(column, onColumnSelect, onColumnDeselect, selectedColumns))}
      </Col>
      <Col sm={6}>
        {right.map(column => renderSingleColumn(column, onColumnSelect, onColumnDeselect, selectedColumns))}
      </Col>
    </Row>
  );
}

function renderSingleColumn(column, onColumnSelect, onColumnDeselect, selectedColumns) {
  return (
    <FormGroup key={column.id} check>
      <Label check>
        <Input
          type="checkbox"
          checked={findIndex(selectedColumns, current => current.id === column.id) !== -1}
          onChange={e => (e.target.checked ? onColumnSelect(column) : onColumnDeselect(column))}
        />
        {' '}
        {column.name}
      </Label>
    </FormGroup>
  );
}

ColumnModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onColumnSelect: PropTypes.func.isRequired,
  onColumnDeselect: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

ColumnModal.defaultProps = {
  selectedColumns: [],
};

export default ColumnModal;
