export function isModalOpen(state) {
  return (state && state.shared && state.shared.sitePicker && state.shared.sitePicker.modalState) || false;
}

export function isCustomerListLoading(state) {
  return (state && state.shared && state.shared.sitePicker && state.shared.sitePicker.customerListLoading) || false;
}

export function isSiteListLoading(state) {
  return (state && state.shared && state.shared.sitePicker && state.shared.sitePicker.siteListLoading) || false;
}

export function customerList(state) {
  return (state && state.shared && state.shared.sitePicker && state.shared.sitePicker.customerList) || [];
}

export function selectedCustomer(state) {
  return (state && state.shared && state.shared.sitePicker && state.shared.sitePicker.selectedCustomer) || null;
}

export function siteList(state) {
  return (state && state.shared && state.shared.sitePicker && state.shared.sitePicker.siteList) || [];
}

export function selectedSites(state) {
  return (state && state.shared && state.shared.sitePicker && state.shared.sitePicker.selectedSites) || [];
}
