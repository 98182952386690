import ActionTypes from './NotificationLogActionTypes';
import NotificationLogConstants from './NotificationLogConstants';

export default function NotificationLogReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_ENTITY_NOTIFICATION_CONTENT_STARTED:
      return Object.assign({}, state, {
        entityNotificationStatus: NotificationLogConstants.contentStatus.Loading,
        entityNotificationContent: null,
        entityNotificationContentError: null,
      });
    case ActionTypes.GET_ENTITY_NOTIFICATION_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        entityNotificationStatus: NotificationLogConstants.contentStatus.Loaded,
        entityNotificationContent: action.entityNotificationContent,
      });
    case ActionTypes.GET_ENTITY_NOTIFICATION_CONTENT_FAILED:
      return Object.assign({}, state, {
        entityNotificationStatus: NotificationLogConstants.contentStatus.Failed,
        error: action.error,
      });
    case ActionTypes.OPEN_ENTITY_NOTIFICATION_CONTENT:
      return Object.assign({}, state, {
        showEntityNotificationContent: true,
      });
    case ActionTypes.CLOSE_ENTITY_NOTIFICATION_CONTENT:
      return Object.assign({}, state, {
        entityNotificationStatus: NotificationLogConstants.contentStatus.NotLoaded,
        showEntityNotificationContent: false,
        entityNotificationContent: null,
      });
    default:
      return state;
  }
}
