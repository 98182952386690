export default {
  // form builder
  RECEIVE_FORM_BUILDER: 'tank-registration/receive_form_builder',

  // get tanks
  REQUEST_TANKS: 'tank-registration/request_tanks',
  RECEIVE_TANK: 'tank-registration/receive_tanks',
  RECEIVE_TANK_FAILURE: 'tank-registration/receive_tanks',

  // registration (initial load)
  REQUEST_TANK_REGISTRATION: 'tank-registration/request_tank_registration',
  RECEIVE_TANK_REGISTRATION: 'tank-registration/receive_tank_registration',
  RECEIVE_TANK_REGISTRATION_FAILURE: 'tank-registration/receive_tank_registration_failure',

  // registration (edit/rollback)
  EDIT_TANK_REGISTRATION: 'tank-registration/edit_tank_registration',
  ROLLBACK_TANK_REGISTRATION: 'tank-registration/rollback_tank_registration',

  // registration (save)
  SAVE_TANK_REGISTRATION_REQUEST: 'tank-registration/save_tank_registration_request',
  SAVE_TANK_REGISTRATION_SUCCESS: 'tank-registration/save_tank_registration_success',
  SAVE_TANK_REGISTRATION_FAILURE: 'tank-registration/save_tank_registration_failure',
};
