import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table} from 'reactstrap';
import Multiselect from 'react-widgets/lib/Multiselect';
import {withRouter} from 'react-router-dom';

import {track, withTracking} from '../../../../../../../shared/analytics';
import * as selectors from '../ATGSensorsSelectors';
import * as actions from '../actions';
import CanaryLoadingIndicator from '../../../../../../../shared/components/CanaryLoadingIndicator';

const ListItem = ({item}) => (
  `T${item.tankNumber} ${item.productLabel}`
);

class SensorsEditor extends Component {

  componentDidMount() {
    this.props.getSiteSensors();
  }


  renderAssociatedTanks(tanks) {
    const tankDisplay = [];
    tanks.map(tank => tankDisplay.push(tank.atgTankLabel));
    if (tankDisplay.length) {
      return <span>{tankDisplay.join(', ')}</span>;
    }
  }

  renderEditButton(sensor) {
    return <Button onClick={() => this.props.toggleEditEquipmentModal(this.props.modalOpen, sensor)} color="primary" outline size="sm">Edit</Button>;
  }


  render() {
    if (!this.props.currentSiteSensors) return <h1>No Data Available.</h1>;
    if (this.props.sensorsLoading) return <CanaryLoadingIndicator />;
    return (
      <div style={{ minHeight: '300px' }}>
        <div className="row">
          <div className="col-12 mt-1 mb-4">

            {this.props.currentSiteSensors.length > 0 ?
              <Table striped>
                <thead className="small">
                  <tr>
                    <th>Type</th>
                    <th>Sensor</th>
                    <th>Label</th>
                    <th>Sub Type</th>
                    <th>Category</th>
                    <th>Associated Equipment</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>

                <tbody>
                  {this.props.currentSiteSensors.map(sensor => (
                    <tr key={sensor.id}>
                      <td><strong>{sensor.sensorType}</strong></td>
                      <td>{sensor.sensorNumber}</td>
                      <td>{sensor.locationLabel}</td>
                      <td>{sensor.sensorSubType}</td>
                      <td>{sensor.sensorCategory}</td>
                      <td width="20%">{this.renderAssociatedTanks(sensor.associatedTanks)}</td>
                      <td>{this.renderEditButton(sensor)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              :
              <div style={{border: '1px solid #ccc'}}
                   className="d-flex justify-content-center align-items-center p-2 m-4"><strong>No Sensors
                Found</strong></div>
            }
          </div>
        </div>
        <Modal size="lg" isOpen={this.props.modalOpen} centered>
          <ModalHeader toggle={() => this.props.toggleEditEquipmentModal(this.props.modalOpen, this.props.editSensor)}>Edit
            Associated Equipment</ModalHeader>
          <ModalBody>
            {this.props.isError ?
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="danger">
                    <div>An error occurred while trying to save the equipment association. Please try again.</div>
                    {this.props.error && this.props.error.message ?
                      <div>{`Error message: ${this.props.error.message}`}</div>
                      : null
                    }
                  </Alert>
                </div>
              </div>

            : null
            }
            <div className="row">
              <div className="col-sm-3 pb-2 pt-2">Type: <strong>{this.props.editSensor.sensorType}</strong></div>
              <div className="col-sm-3 pb-2 pt-2">Sensor: <strong>{this.props.editSensor.sensorNumber}</strong></div>
              <div className="col-sm-6 pb-2 pt-2">Associated Equipment: <Multiselect dropUp defaultValue={this.props.editSensor.associatedTanks} onChange={value => this.props.associateEquipment(value)} placeholder="Click to select equipment" textField={'atgTankLabel'} valueField={'tankNumber'} itemComponent={ListItem} data={this.props.tanks} /></div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" size="sm" outline onClick={() => this.props.toggleEditEquipmentModal(this.props.modalOpen, this.props.editSensor)}>Cancel</Button>
            {' '}
            <Button color="primary" size="sm" onClick={() => this.props.saveAssociatedEquipment(this.props.editSensor)}>Save</Button>
          </ModalFooter>
        </Modal>
      </div>

    );
  }

}


function mapStateToProps(state) {
  return {
    currentSiteSensors: selectors.currentSiteSensors(state),
    sensorsLoading: selectors.sensorsLoading(state),
    modalOpen: selectors.modalOpen(state),
    editSensor: selectors.editSensor(state),
    tanks: selectors.tanks(state),
    isError: selectors.isError(state),
    error: selectors.error(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSiteSensors: () => dispatch(actions.getSiteSensors()),
    toggleEditEquipmentModal: (val, sensor) => {
      track('Sensor', {
        Component: 'Sensor table',
        'Sensor action': 'Edit sensor',
      });
      return dispatch(actions.toggleEditEquipmentModal(val, sensor));
    },
    associateEquipment: tanks => {
      track('Sensor', {
        Component: 'Sensor table',
        'Sensor action': 'Edit associated tanks',
      });
      return dispatch(actions.associateEquipment(tanks));
    },
    saveAssociatedEquipment: sensor => {
      track('Sensor', {
        Component: 'Sensor table',
        'Sensor action': 'Save sensor',
      });
      return dispatch(actions.saveAssociatedEquipment(sensor));
    },
  };
}

SensorsEditor.propTypes = {
  currentSiteSensors: PropTypes.array.isRequired,
  getSiteSensors: PropTypes.func.isRequired,
  associateEquipment: PropTypes.func.isRequired,
  saveAssociatedEquipment: PropTypes.func.isRequired,
  toggleEditEquipmentModal: PropTypes.func.isRequired,
  sensorsLoading: PropTypes.bool.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  editSensor: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  isError: PropTypes.bool.isRequired,
  tanks: PropTypes.array.isRequired,
};

export default withTracking('Site', 'Equipment - Sensors')(connect(mapStateToProps, mapDispatchToProps)(withRouter(SensorsEditor)));
