export default class TableSelectors {
  constructor(accessor) {
    this._accessor = state => accessor(state) || {};
    this.filteredList = this.filteredList.bind(this);
    this.sortColumnId = this.sortColumnId.bind(this);
    this.sortDirection = this.sortDirection.bind(this);
    this.currentPage = this.currentPage.bind(this);
    this.pageSize = this.pageSize.bind(this);
    this.pageCount = this.pageCount.bind(this);
  }

  filteredList(state) {
    return this._accessor(state).filteredList || [];
  }

  currentPage(state) {
    return this._accessor(state).currentPage || 1;
  }

  pageSize(state) {
    return this._accessor(state).pageSize || 10;
  }

  pageCount(state) {
    return this._accessor(state).pageCount || 0;
  }

  sortColumnId(state) {
    return this._accessor(state).sortColumnId || this._accessor(state).defaultSortColumnId;
  }

  sortDirection(state) {
    return this._accessor(state).sortDirection || this._accessor(state).defaultSortDirection;
  }
}
