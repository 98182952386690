import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import update from 'immutability-helper/index';

import Label from '../../../../../../../shared/components/label/Label';
import DeleteButton from '../../../../../../../shared/components/buttons/DeleteButton';
import LabelModal from '../../../../../../../shared/components/label/LabelModal';
import CanaryModal from '../../../../../../../shared/components/CanaryModal';

class CustomerLabels extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = {
      confirmDeleteModalOpen: false,
      toDeleteIndex: null,
      addOrEditModalOpen: false,
      label: null,
    };
    this.openConfirmDeleteModal = this.openConfirmDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.closeAddOrEditModal = this.closeAddOrEditModal.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleLabelSave = this.handleLabelSave.bind(this);
  }

  openConfirmDeleteModal(index) {
    this.setState({
      confirmDeleteModalOpen: true,
      toDeleteIndex: index,
    });
  }

  closeDeleteModal() {
    this.setState({
      confirmDeleteModalOpen: false,
      toDeleteIndex: null,
    });
  }

  confirmDelete() {
    const updatedOrganization = update(this.props.selectedOrganization, {
      customer: {
        labels: {
          $splice: [
            [this.state.toDeleteIndex, 1],
          ],
        },
      }
    });
    this.props.editSelectedOrganization(updatedOrganization);
  }

  openEditModal(label) {
    this.setState({
      addOrEditModalOpen: true,
      label
    });
  }

  openAddModal() {
    this.setState({
      addOrEditModalOpen: true,
      label: undefined,
    });
  }

  closeAddOrEditModal() {
    this.setState({
      addOrEditModalOpen: false,
      label: null,
    });
  }

  handleLabelChange(label) {
    this.setState({ label });
  }

  handleLabelSave(label) {
    let updateSpec;
    if (!label.id) {
      updateSpec = {
        customer: {
          labels: {
            $push: [{
              customerId: this.props.selectedOrganization.id,
              labelText: label.labelText,
              color: label.color,
            }],
          },
        }
      };
    }
    else {
      const index = this.props.selectedOrganization.customer.labels.findIndex(current => current.id === label.id);
      updateSpec = {
        customer: {
          labels: {
            $splice: [[index, 1, label]],
          },
        }
      };
    }

    const updatedOrganization = update(this.props.selectedOrganization, updateSpec);
    this.closeAddOrEditModal();
    this.props.editSelectedOrganization(updatedOrganization);
  }

  render() {
    return (
      <div>
        {this.renderLabels()}
        <LabelModal
          isOpen={this.state.addOrEditModalOpen}
          label={this.state.label}
          existingLabels={this.props.selectedOrganization.customer.labels}
          onLabelChange={this.handleLabelChange}
          onLabelSave={this.handleLabelSave}
          onCancel={this.closeAddOrEditModal}
        />
        {this.renderConfirmDeleteModal()}
        <Button size="sm" color="primary" onClick={this.openAddModal}>Add Label</Button>
      </div>
    );
  }

  renderLabels() {
    if (!this.props.selectedOrganization.customer || !this.props.selectedOrganization.customer.labels || !this.props.selectedOrganization.customer.labels.length) {
      return (
        <div className="my-4">
          <span className="text-muted">No labels have been defined for this customer</span>
        </div>
      );
    }

    return (
      <Row>
        <Col sm={4}>
          {this.props.selectedOrganization.customer.labels.map((label, index) => (
            <div className="mb-3" key={label.labelText}>
              <div className="d-flex justify-content-between align-items-center">
                <Label text={label.labelText} color={label.color} />
                <div>
                  <Button size="sm" color="link" className="mr-4" onClick={() => this.openEditModal(label)}>Edit</Button>
                  <DeleteButton size="1x" onClick={() => this.openConfirmDeleteModal(index)} />
                </div>
              </div>
              <hr className="my-2" />
            </div>
          ))}
        </Col>
      </Row>
    );
  }

  renderConfirmDeleteModal() {
    return (
      <CanaryModal title="Delete Label" open={this.state.confirmDeleteModalOpen} onCancel={this.closeDeleteModal} onConfirm={this.confirmDelete}>
        <span>Are you sure you want to delete this label?</span>
      </CanaryModal>
    );
  }
}

CustomerLabels.propTypes = {
  selectedOrganization: PropTypes.object.isRequired,
  editSelectedOrganization: PropTypes.func.isRequired,
};

export default CustomerLabels;
