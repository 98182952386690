import { MviClient, MviFields, MviParams } from '../../../client';
import { getListWithEtagAction } from '../../../shared/redux-helpers/ActionCreatorFactory';
import ActionTypes from '../MviActionTypes';
import selectors, { MviViewManagerSelectors } from '../MviSelectors';

function buildParams(state) {
  const params = {
    [MviParams.CustomerIds.name]: MviViewManagerSelectors.selectedCustomerIds(state),
    [MviParams.WorkflowStatuses.name]: MviViewManagerSelectors.selectedInspectionStatuses(state),
    [MviParams.SiteStates.name]: MviViewManagerSelectors.selectedSiteStates(state),
    [MviParams.SiteIds.name]: MviViewManagerSelectors.selectedSiteIds(state),
    [MviParams.SiteLabelIds.name]: MviViewManagerSelectors.selectedSiteLabelIds(state),
  };
  addMonthFilter(state, params);
  return params;
}

function addMonthFilter(currentState, params) {
  const selectedMonth = MviViewManagerSelectors.selectedMonth(currentState);
  if (selectedMonth) {
    params[MviParams.Year.name] = selectedMonth.year;
    params[MviParams.Month.name] = selectedMonth.month;
  }
}

const buildFields = state => [...MviViewManagerSelectors.apiFields(state), MviFields.Id, MviFields.Site];

export const getMvis = getListWithEtagAction(selectors, ActionTypes, buildParams, buildFields, MviClient.findMvis);

export const changeSort = (sortColumnId, sortDirection) => ({ type: ActionTypes.CHANGE_SORT, sortColumnId, sortDirection });

export const changePage = page => ({ type: ActionTypes.CHANGE_PAGE, page });
