import ActionTypes from './OrganizerActionTypes';

export default function OrganizerReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.BULK_UPDATE_STARTED:
      return Object.assign({}, state, {
        bulkUpdateInProgress: true,
      });
    case ActionTypes.BULK_UPDATE_COMPLETE:
      return Object.assign({}, state, {
        bulkUpdateInProgress: false,
      });

    default:
      return state;
  }
}
