export default {
  RECEIVE_PERMISSION_SETS: 'organization-admin/receive_permission_sets',

  GET_ORGANIZATION_STARTED: 'organization-admin/get_organization_started',
  RECEIVE_ORGANIZATION: 'organization-admin/receive_organization',
  GET_ORGANIZATION_FAILED: 'organization-admin/get_organization_failed',

  EDIT_SELECTED_ORG: 'organization-admin/edit_selected_org',
  DISCARD_SELECTED_ORG_EDITS: 'organization-admin/discard_selected_org_edits',

  SAVE_ORGANIZATION_STARTED: 'organization-admin/save_selected_organization_started',
  SAVE_ORGANIZATION_SUCCESS: 'organization-admin/save_selected_organization_success',
  SAVE_ORGANIZATION_FAILED: 'organization-admin/save_selected_organization_failed',
};
