import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export const ReadType = {
  ArrayBuffer: 'ArrayBuffer',
  Text: 'Text',
  DataURL: 'DataURL',
};

class FileReaderButton extends Component {
  constructor(props, context) {
    super(props, context);
    this.inputRef = React.createRef();
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
  }

  handleButtonClick() {
    const element = this.inputRef.current;
    element.value = '';
    element.click();
  }

  handleFiles(event) {
    const files = event.target.files;
    if (files && files.length && files[0] && files[0] instanceof Blob) {
      const reader = new FileReader();
      reader.onload = loadEvent => this.props.onUpload(loadEvent.target.result);
      reader[`readAs${this.props.readType}`](files[0]);
    }
  }

  render() {
    return (
      <>
        <Button
          color={this.props.buttonColor}
          outline={this.props.buttonOutline}
          size={this.props.buttonSize}
          onClick={this.handleButtonClick}
          disabled={this.props.disabled}
          className={this.props.buttonClassName}
        >
          {this.props.buttonText}
        </Button>
        <input
          id={this.props.id}
          type="file"
          onChange={this.handleFiles}
          accept={Array.isArray(this.props.fileTypes) ? this.props.fileTypes.join(',') : this.props.fileTypes}
          className="d-none"
          ref={this.inputRef}
          multiple={false}
          disabled={this.props.disabled}
        />
      </>
    );
  }
}

FileReaderButton.propTypes = {
  id: PropTypes.string.isRequired,
  readType: PropTypes.string,
  fileTypes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  buttonColor: PropTypes.string,
  buttonOutline: PropTypes.bool,
  buttonSize: PropTypes.string,
  buttonText: PropTypes.string,
  buttonClassName: PropTypes.string,

  onUpload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FileReaderButton.defaultProps = {
  buttonColor: 'primary',
  buttonOutline: false,
  buttonSize: 'sm',
  buttonText: 'Upload',
  buttonClassName: null,
  readType: ReadType.Text,
  fileTypes: ['text/csv', 'csv'],
  disabled: false,
};

export default FileReaderButton;
