export const DurationFilterTypes = {
  GT: 'gt',
  LT: 'lt',
};

export const DurationTypes = {
  PAST_5_MINS: {
    label: 'Past 5 minutes',
    filterType: DurationFilterTypes.GT,
    mins: 5,
  },
  PAST_30_MINS: {
    label: 'Past 30 minutes',
    filterType: DurationFilterTypes.GT,
    mins: 30,
  },
  PAST_HOUR: {
    label: ' Past hour',
    filterType: DurationFilterTypes.GT,
    mins: 60,
  },
  GT_1_HOUR: {
    label: ' Older than 1 hour',
    filterType: DurationFilterTypes.LT,
    mins: 60,
  },
  GT_2_HOURS: {
    label: ' Older than 2 hours',
    filterType: DurationFilterTypes.LT,
    mins: 120,
  },
  GT_3_HOURS: {
    label: ' Older than 3 hours',
    filterType: DurationFilterTypes.LT,
    mins: 180,
  },
  GT_6_HOURS: {
    label: ' Older than 6 hours',
    filterType: DurationFilterTypes.LT,
    mins: 360,
  },
  GT_12_HOURS: {
    label: ' Older than 12 hour',
    filterType: DurationFilterTypes.LT,
    mins: 720,
  },
  GT_1_DAY: {
    label: ' Older than 1 day',
    filterType: DurationFilterTypes.LT,
    mins: 1440,
  },
};
