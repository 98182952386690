import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CanaryModal from '../../CanaryModal';
import * as selectors from './ModalSelectors';
import * as actions from './ModalActions';
import MviInputForm from '../MviInputForm';

class MviModal extends Component {
  static getDerivedStateFromProps(props, state) {
    if (!props.open && (state.editingSite || state.file)) {
      return {
        editingSite: false,
        file: null,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      editingSite: false,
      file: null
    };
    this.handleSiteSelectStart = this.handleSiteSelectStart.bind(this);
    this.handleSiteSelect = this.handleSiteSelect.bind(this);
    this.handleSiteSelectEnd = this.handleSiteSelectEnd.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleSiteSelectStart() {
    this.setState({ editingSite: true });
  }

  handleSiteSelect(site) {
    this.props.editSite(site);
  }

  handleSiteSelectEnd() {
    this.setState({ editingSite: false });
  }

  handleFile(file) {
    this.setState({ file });
  }

  canSave() {
    return !this.state.editingSite && !!this.props.site && !!this.props.inspectionDate;
  }

  handleConfirm() {
    const inspectionDetails = {
      inspectionDate: this.props.inspectionDate,
      inspectorCompany: this.props.inspectorCompany || null,
      inspectorName: this.props.inspectorName || null,
    };
    this.props.closeModal();
    this.props.onConfirm(this.props.site, inspectionDetails, this.state.file);
  }

  render() {
    return (
      <CanaryModal
        open={this.props.open}
        onCancel={this.props.closeModal}
        onConfirm={this.handleConfirm}
        title={this.props.title}
        canConfirm={this.canSave()}
        size="lg"
      >
        <MviInputForm
          site={this.props.site}
          siteEditDisabled={this.props.siteEditDisabled}
          onSiteEditStart={this.handleSiteSelectStart}
          onSiteSelect={this.handleSiteSelect}
          onSiteEditEnd={this.handleSiteSelectEnd}
          minInspectionDate={this.props.minInspectionDate}
          maxInspectionDate={this.props.maxInspectionDate}
          inspectionDate={this.props.inspectionDate}
          onInspectionDateChange={this.props.editInspectionDate}
          inspectorCompany={this.props.inspectorCompany}
          onInspectorCompanyChange={this.props.editInspectorCompany}
          inspectorName={this.props.inspectorName}
          onInspectorNameChange={this.props.editInspectorName}
          fileUploadEnabled={this.props.fileUploadEnabled}
          file={this.state.file}
          onFileSelect={this.handleFile}
        />
      </CanaryModal>
    );
  }
}

MviModal.propTypes = {
  // public properties
  onConfirm: PropTypes.func.isRequired,
  minInspectionDate: PropTypes.any,
  maxInspectionDate: PropTypes.any,
  siteEditDisabled: PropTypes.bool,
  fileUploadEnabled: PropTypes.bool,

  // internal stuff
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  site: PropTypes.object,
  editSite: PropTypes.func.isRequired,
  inspectionDate: PropTypes.string,
  editInspectionDate: PropTypes.func.isRequired,
  inspectorCompany: PropTypes.string,
  editInspectorCompany: PropTypes.func.isRequired,
  inspectorName: PropTypes.string,
  editInspectorName: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

MviModal.defaultProps = {
  title: 'Move report',
  site: null,
  minInspectionDate: null,
  maxInspectionDate: null,
  siteEditDisabled: false,
  fileUploadEnabled: false,
  inspectionDate: null,
  inspectorCompany: null,
  inspectorName: null,
};

function mapStateToProps(state) {
  return {
    open: selectors.isOpen(state),
    title: selectors.title(state),
    site: selectors.site(state),
    inspectionDate: selectors.inspectionDate(state),
    inspectorCompany: selectors.inspectorCompany(state),
    inspectorName: selectors.inspectorName(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editSite: site => dispatch(actions.editSite(site)),
    editInspectionDate: inspectionDate => dispatch(actions.editInspectionDate(inspectionDate)),
    editInspectorCompany: inspectorCompany => dispatch(actions.editInspectorCompany(inspectorCompany)),
    editInspectorName: inspectorName => dispatch(actions.editInspectorName(inspectorName)),
    closeModal: () => dispatch(actions.closeModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MviModal);
