import { LOCATION_CHANGE } from 'react-router-redux';
import ActionTypes from './TourActionTypes';

function markTourComplete(state, tourName) {
  const tours = state.tours || [];
  const newTours = [...tours];
  if (newTours.includes(tourName)) {
    return state;
  }

  newTours.push(tourName);
  return Object.assign({}, state, {
    saving: false,
    tours: newTours
  });
}

export default function TourReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_TOURS_STARTED:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case ActionTypes.RECEIVE_TOURS:
      return Object.assign({}, state, {
        loading: false,
        tours: action.tours,
      });
    case ActionTypes.GET_TOURS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });

    case ActionTypes.MARK_TOUR_COMPLETE_STARTED:
      return Object.assign({}, state, {
        saving: true,
      });
    case ActionTypes.MARK_TOUR_COMPLETE_SUCCESS:
      return markTourComplete(state, action.tourName);
    case ActionTypes.MARK_TOUR_COMPLETE_FAILED:
      return Object.assign({}, state, {
        saving: false,
        error: action.error,
      });

    case ActionTypes.RESET_TOURS_STARTED:
      return Object.assign({}, state, {
        resettingTours: true,
        error: null,
      });
    case ActionTypes.RESET_TOURS_SUCCESS:
      return Object.assign({}, state, {
        tours: [],
        resettingTours: false,
        toursReset: true,
      });
    case ActionTypes.RESET_TOURS_FAILED:
      return Object.assign({}, state, {
        resettingTours: false,
        error: action.error,
      });
    case LOCATION_CHANGE:
      return Object.assign({}, state, {
        loading: false,
        saving: false,
        resettingTours: false,
        toursReset: false,
        error: null,
      });

    default:
      return state;
  }
}
