import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { Features, AlarmManagementFeatureLevels, MviFeatureLevels } from '../../../AppConstants';

class AppFeatures extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.findExistingFeature = this.findExistingFeature.bind(this);
    this.isFeatureEnabled = this.isFeatureEnabled.bind(this);
    this.toggleFeature = this.toggleFeature.bind(this);
    this.toggleNoAlarmManagement = this.toggleNoAlarmManagement.bind(this);
    this.toggleBasicAlarmManagement = this.toggleBasicAlarmManagement.bind(this);
    this.toggleReportOnlyAlarmManagement = this.toggleReportOnlyAlarmManagement.bind(this);
    this.toggleAutomatedAlarmManagement = this.toggleAutomatedAlarmManagement.bind(this);
    this.toggleNoMvis = this.toggleNoMvis.bind(this);
    this.toggleBasicMvis = this.toggleBasicMvis.bind(this);
    this.toggleMviForm = this.toggleMviForm.bind(this);
  }

  findExistingFeature(featureName) {
    return this.props.targetModel.appFeatures && this.props.targetModel.appFeatures.find(current => current.feature === featureName);
  }

  isFeatureEnabled(featureName, featureLevel) {
    const feature = this.findExistingFeature(featureName);
    return !!(feature && feature.enabled && feature.featureLevel === featureLevel);
  }

  toggleFeature(feature, featureLevel, enabled) {
    let targetModel = this.props.targetModel;

    const existing = targetModel.appFeatures && targetModel.appFeatures.find(existingFeature => existingFeature.feature === feature);

    if (existing) {
      const index = targetModel.appFeatures.indexOf(existing);
      targetModel = update(targetModel, {
        appFeatures: {
          [index]: {
            featureLevel: {
              $set: featureLevel,
            },
            enabled: {
              $set: enabled,
            },
          },
        },
      });
    }
    else {
      const newFeature = {
        feature,
        featureLevel,
        enabled,
      };
      targetModel = update(targetModel, {
        appFeatures: {
          $push: [newFeature],
        },
      });
    }

    this.props.updateTargetModel(targetModel);
  }

  toggleNoAlarmManagement() {
    this.toggleFeature(Features.AlarmManagement, null, false);
  }

  toggleBasicAlarmManagement() {
    this.toggleFeature(Features.AlarmManagement, AlarmManagementFeatureLevels.BasicAlarmManagement, true);
  }

  toggleReportOnlyAlarmManagement() {
    this.toggleFeature(Features.AlarmManagement, AlarmManagementFeatureLevels.ReportOnlyAlarmManagement, true);
  }

  toggleAutomatedAlarmManagement() {
    this.toggleFeature(Features.AlarmManagement, AlarmManagementFeatureLevels.AutomatedAlarmManagement, true);
  }

  toggleNoMvis() {
    this.toggleFeature(Features.MonthlyVisualInspections, null, false);
  }

  toggleBasicMvis() {
    this.toggleFeature(Features.MonthlyVisualInspections, MviFeatureLevels.BasicMVIs, true);
  }

  toggleMviForm() {
    this.toggleFeature(Features.MonthlyVisualInspections, MviFeatureLevels.MVIForm, true);
  }

  render() {
    const isDisabled = !!!this.props.updateTargetModel;
    const basicAlarmMgtEnabled = this.isFeatureEnabled(Features.AlarmManagement, AlarmManagementFeatureLevels.BasicAlarmManagement);
    const reportOnlyAlarmMgtEnabled = this.isFeatureEnabled(Features.AlarmManagement, AlarmManagementFeatureLevels.ReportOnlyAlarmManagement);
    const automatedAlarmMgtEnabled = this.isFeatureEnabled(Features.AlarmManagement, AlarmManagementFeatureLevels.AutomatedAlarmManagement);
    const noAlarmMgt = !basicAlarmMgtEnabled && !reportOnlyAlarmMgtEnabled && !automatedAlarmMgtEnabled;
    const complianceEnabled = this.isFeatureEnabled(Features.Compliance, null);
    const inventoryEnabled = this.isFeatureEnabled(Features.Inventory, null);

    const basicMviEnabled = this.isFeatureEnabled(Features.MonthlyVisualInspections, MviFeatureLevels.BasicMVIs);
    const mviFormEnabled = this.isFeatureEnabled(Features.MonthlyVisualInspections, MviFeatureLevels.MVIForm);
    const noMvis = !basicMviEnabled && !mviFormEnabled;


    return (
      <div>
        <Row>
          <Col md={8}>
            <Form>
              <h6>Alarm Management</h6>
              <Row>
                <Col className="pl-4">
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="alarmMgmtRadio" checked={noAlarmMgt} onChange={event => this.toggleNoAlarmManagement()} disabled={isDisabled} />
                      No alarm management
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="alarmMgmtRadio" checked={basicAlarmMgtEnabled} onChange={event => this.toggleBasicAlarmManagement()} disabled={isDisabled} />
                      Alarm Grouping (no ATG slips)
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="alarmMgmtRadio" checked={reportOnlyAlarmMgtEnabled} onChange={event => this.toggleReportOnlyAlarmManagement()} disabled={isDisabled} />
                      Alarm Grouping (with ATG slips)
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="alarmMgmtRadio" checked={automatedAlarmMgtEnabled} onChange={event => this.toggleAutomatedAlarmManagement()} disabled={isDisabled} />
                      Alarm Grouping and Automated Analysis (with ATG slips)
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <h6>Compliance</h6>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={complianceEnabled} onChange={event => this.toggleFeature(Features.Compliance, null, event.target.checked)} disabled={isDisabled} />
                  Enable compliance
                </Label>
              </FormGroup>
              <h6>Inventory</h6>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={inventoryEnabled} onChange={event => this.toggleFeature(Features.Inventory, null, event.target.checked)} disabled={isDisabled} />
                  Enable inventory
                </Label>
              </FormGroup>
              <h6>Monthly Visual Inspections</h6>
              <Row>
                <Col className="pl-4">
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="mviRadio" checked={noMvis} onChange={this.toggleNoMvis} disabled={isDisabled} />
                      No MVIs
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="mviRadio" checked={basicMviEnabled} onChange={this.toggleBasicMvis} disabled={isDisabled} />
                      Basic MVIs
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="mviRadio" checked={mviFormEnabled} onChange={this.toggleMviForm} disabled={isDisabled} />
                      Canary MVI Form
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

AppFeatures.propTypes = {
  targetModel: PropTypes.object.isRequired,
  updateTargetModel: PropTypes.func,
};

AppFeatures.defaultProps = {
  updateTargetModel: null,
};

export default AppFeatures;
