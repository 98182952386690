export function isComplianceHistoryLoading(state) {
  return (state && state.sites && state.sites.compliance && state.sites.compliance.list && state.sites.compliance.list.complianceHistoryLoading) || false;
}

export function complianceHistory(state) {
  return (state && state.sites && state.sites.compliance && state.sites.compliance.list && state.sites.compliance.list.complianceHistory) || [];
}

export function complianceHistoryError(state) {
  return (state && state.sites && state.sites.compliance && state.sites.compliance.list && state.sites.compliance.list.complianceHistoryError) || null;
}
