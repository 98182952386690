import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

const FileFormatDeleteModal = ({ isOpen, selectedInventoryFormat, onDelete, onCancel }) => {
  if (!selectedInventoryFormat) {
    return null;
  }

  if (selectedInventoryFormat.fuelHaulers && selectedInventoryFormat.fuelHaulers.length) {
    return (
      <Modal isOpen={isOpen} toggle={onCancel}>
        <ModalHeader toggle={onCancel}>Delete File Format</ModalHeader>
        <ModalBody>
          Cannot delete <strong>{selectedInventoryFormat.name}</strong> because it used by&nbsp;
          {selectedInventoryFormat.fuelHaulers.length} fuel hauler(s):
          <ul className="m-1">
            {selectedInventoryFormat.fuelHaulers.slice(0, 10).map(current => (
              <li key={current.id}>{current.name}</li>
            ))}
          </ul>
          Update these fuel haulers to use a different format before deleting this one.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline size="sm" onClick={onCancel}>Cancel</Button>
          {' '}
          <Button id="confirm" color="primary" size="sm" disabled>Delete</Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={onCancel}>
      <ModalHeader toggle={onCancel}>Delete File Format</ModalHeader>
      <ModalBody>
        Are you sure you want to <strong>{selectedInventoryFormat.name}</strong>? This operation cannot be
        undone.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="sm" outline onClick={onCancel}>Cancel</Button>
        {' '}
        <Button id="confirm" color="primary" size="sm" onClick={onDelete}>Delete</Button>
      </ModalFooter>
    </Modal>
  );
};

FileFormatDeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  selectedInventoryFormat: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

FileFormatDeleteModal.defaultProps = {
  isOpen: false,
  selectedInventoryFormat: null,
};

export default FileFormatDeleteModal;
