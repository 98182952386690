export function siteHealth(state) {
  return (state.admin && state.admin.sites && state.admin.sites.dashboard && state.admin.sites.dashboard.siteHealth) || null;
}

export function commandRequestHealth(state) {
  return (state.admin && state.admin.sites && state.admin.sites.dashboard && state.admin.sites.dashboard.commandRequestHealth) || null;
}

export function isSiteHealthLoading(state) {
  return (state.admin && state.admin.sites && state.admin.sites.dashboard && state.admin.sites.dashboard.siteHealthLoading) || false;
}

export function isCommandRequestHealthLoading(state) {
  return (state.admin && state.admin.sites && state.admin.sites.dashboard && state.admin.sites.dashboard.commandRequestHealthLoading) || false;
}
