import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input } from 'reactstrap';

const TextAreaInputForm = ({ className, name, rows, value, onChange, canCancel, onCancel, canSave, onSave }) => {
  const saveDisabled = !canSave || !value || value.trim() === '';
  return (
    <Form className={className}>
      <FormGroup>
        <Input type="textarea" name={name} bsSize="sm" value={value || ''} rows={rows} onChange={e => onChange(e.target.value)} />
      </FormGroup>
      <div className="d-flex">
        <Button size="sm" color="primary" outline className="ml-auto mr-2" onClick={onCancel} disabled={!canCancel}>Cancel</Button>
        <Button size="sm" color="primary" onClick={() => onSave(value)} disabled={saveDisabled}>Save</Button>
      </div>
    </Form>
  );
};

TextAreaInputForm.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  canCancel: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  canSave: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
};

TextAreaInputForm.defaultProps = {
  className: null,
  name: null,
  rows: 4,
  value: '',
  canCancel: true,
  canSave: true,
};

export default TextAreaInputForm;
