import React from 'react';
import PropTypes from 'prop-types';

const CloseButton = ({buttonLabel, onClose, data}) => (
  <button type="button" className="close" aria-label="Close" onClick={() => onClose(data)}>
    <span aria-hidden>{buttonLabel}</span>
  </button>
);

CloseButton.propTypes = {
  buttonLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.any,
};

CloseButton.defaultProps = {
  buttonLabel: 'x',
  data: null,
};

export default CloseButton;
