import { Get } from '../HttpUtils';
import { ParameterType, Param, buildFindMethod } from '../param-types';

export const InventoryFields = {
  Id: 'id',
  SiteId: 'siteId',
  InventoryDate: 'inventoryDate',
  VolumeGallons: 'volumeGallons',
  TcVolumeGallons: 'tcVolumeGallons',
  UllageGallons: 'ullageGallons',
  FuelHeightInches: 'fuelHeightInches',
  WaterHeightInches: 'waterHeightInches',
  TemperatureFahrenheit: 'temperatureFahrenheit',
  WaterVolumeGallons: 'waterVolumeGallons',
  Alerts: 'alerts',
  FillPercentage: 'fillPercentage',
  Ullage90PercentGallons: 'ullage90PercentGallons',
  Ullage95PercentGallons: 'ullage95PercentGallons',
  DeliveryPollDate: 'deliveryPollDate',
  DeliveryStartDate: 'deliveryStartDate',
  DeliveryStartingVolumeGallons: 'deliveryStartingVolumeGallons',
  DeliveryEndingVolumeGallons: 'deliveryEndingVolumeGallons',
  DeliveryVolumeGallons: 'deliveryVolumeGallons',
  TankNumber: 'tankNumber',
  ProductLabel: 'productLabel',
  ProductType: 'productType',
  ProductCode: 'productCode',
  FullVolumeGallons: 'fullVolumeGallons',
  ProductThresholds: 'productThresholds',
  Status: 'status',
  Customer: 'customer',
  Site: 'site',
  SiteLabels: 'siteLabels',
};

export const InventoryParams = {
  CustomerIds: new Param('customerIds', ParameterType.List),
  SiteIds: new Param('siteIds', ParameterType.List),
  SiteStates: new Param('siteStates', ParameterType.List),
  SiteLabelIds: new Param('siteLabelIds', ParameterType.List),
  ProductLabels: new Param('productLabels', ParameterType.List),
  ActiveAlerts: new Param('activeAlerts', ParameterType.List),
  MinFillPercentage: new Param('minFillPercentage', ParameterType.Scalar),
  MaxFillPercentage: new Param('maxFillPercentage', ParameterType.Scalar),
  MinInventoryDate: new Param('minInventoryDate', ParameterType.Scalar),
  MaxInventoryDate: new Param('maxInventoryDate', ParameterType.Scalar),
  TankStatus: new Param('tankStatuses', ParameterType.List),
};

export const InventoryClient = {
  findInventories: buildFindMethod('/v2/inventories', InventoryFields, InventoryParams),

  getInventoryForCustomer(inventoryFilter) {
    const requestUrl = `/v2/inventories?${inventoryFilter.toQueryParams()}`;
    return Get(requestUrl).then(response => response.data);
  },

  getInventoryForSite(site) {
    const id = (site && site.id) || site;
    if (!id) {
      return Promise.resolve(null);
    }

    const requestUrl = `/v2/inventories/?siteIds=${id}`;
    return Get(requestUrl).then(response => response.data);
  },

  getProductLabels() {
    return Get('/v2/inventories/product-labels').then(response => response.data);
  }
};
