export function isHourlyHistoryLoading(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.dashboard &&
    state.admin.inventory.dashboard.hourlyHistoryLoading) || false;
}

export function isFuelHaulerHealthLoading(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.dashboard &&
    state.admin.inventory.dashboard.fuelHaulerHealthLoading) || false;
}


export function hourlyHistoryList(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.dashboard &&
    state.admin.inventory.dashboard.hourlyHistoryList) || [];
}

export function fuelHaulerHealth(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.dashboard &&
    state.admin.inventory.dashboard.fuelHaulerHealth) || null;
}
