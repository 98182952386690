import React from 'react';
import PropTypes from 'prop-types';


const UL1746Part1DoubleWallTankIcon = ({ text }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 225.1 225.1" style={{ enableBackground: 'new 0 0 225.1 225.1' }}>
    <g id="composite_-_double">
      <ellipse style={{ fill: 'F89A1F' }} cx="112.5" cy="116.7" rx="106.1" ry="106.4"/>
      <g>
        <path
          d="M94.4,1.6h35.8V23c43.9,8.5,76.5,47.4,76.5,93.5c0,52.6-42.3,95.2-94.4,95.2S17.9,169,17.9,116.5 c0-46.1,32.6-85,76.5-93.5V1.6z"
          style={{ overflow: 'visible', fill: '#1B1B1B' }}
        />
        <clipPath id="SVGID_2_">
          <path
            d="M94.4,1.6h35.8V23c43.9,8.5,76.5,47.4,76.5,93.5c0,52.6-42.3,95.2-94.4,95.2S17.9,169,17.9,116.5 c0-46.1,32.6-85,76.5-93.5V1.6z"
            style={{ overflow: 'visible' }}
          />
        </clipPath>
        <g style={{ clipPath: 'url(#SVGID_2_)' }}>
          <rect
            x="-605.8"
            y="-3572.6"
            width="2445.8"
            height="7579.1"
            style={{ overflow: 'visible', fill: '#1B1B1B' }}
          />
          <clipPath id="SVGID_4_">
            <rect
              x="-605.8"
              y="-3572.6"
              width="2445.8"
              height="7579.1"
              style={{ overflow: 'visible' }}
            />
          </clipPath>
          <rect x="7.3" y="-9" style={{ clipPath: 'url(#SVGID_4_)', fill: '#1B1B1B'}} width="210" height="231.2"/>
        </g>
      </g>
      <path
        style={{ fill: '#FFFFFF' }}
        d="M103.3,30.7c-43,4.5-76.5,41.2-76.5,85.7c0,47.6,38.3,86.2,85.5,86.2s85.5-38.6,85.5-86.2 c0-44.5-33.5-81.2-76.5-85.7V10.6h-18V30.7z"
      />
      <g>
        <rect
          x="22.7"
          y="43.3"
          transform="matrix(0.5882 -0.8087 0.8087 0.5882 -33.0009 46.1727)"
          width="12.2"
          height="24.5"
          style={{ overflow: 'visible', fillRule: 'evenodd', clipRule: 'evenodd', fill: '#1B1B1B' }}
        />
        <clipPath id="SVGID_6_">
          <rect
            x="22.7"
            y="43.3"
            transform="matrix(0.5882 -0.8087 0.8087 0.5882 -33.0009 46.1727)"
            width="12.2"
            height="24.5"
            style={{ overflow: 'visible' }}
          />
        </clipPath>
        <g style={{ clipPath: 'url(#SVGID_6_)' }}>
          <rect
            x="-3758.1"
            y="-1000"
            transform="matrix(0.1051 -0.9945 0.9945 0.1051 -247.5885 109.7814)"
            width="7390.6"
            height="2384.9"
            style={{ overflow: 'visible', fill: '#1B1B1B' }}
          />
          <clipPath id="SVGID_8_">
            <rect
              x="-3758.1"
              y="-1000"
              transform="matrix(0.1051 -0.9945 0.9945 0.1051 -247.5885 109.7814)"
              width="7390.6"
              height="2384.9"
              style={{ overflow: 'visible' }}
            />
          </clipPath>
          <rect
            x="12.4"
            y="32.9"
            transform="matrix(0.5882 -0.8087 0.8087 0.5882 -33.0009 46.1727)"
            style={{ clipPath: 'url(#SVGID_8_)', fill: '#1B1B1B'}}
            width="32.9"
            height="45.1"
          />
        </g>
      </g>
      <g>
        <rect
          x="183.5"
          y="49.4"
          transform="matrix(0.8087 -0.5882 0.5882 0.8087 4.7997 125.7424)"
          width="24.5"
          height="12.2"
          style={{ overflow: 'visible', fillRule: 'evenodd', clipRule: 'evenodd', fill: '#1B1B1B' }}
        />
        <clipPath id="SVGID_10_">
          <rect
            x="183.5"
            y="49.4"
            transform="matrix(0.8087 -0.5882 0.5882 0.8087 4.7997 125.7424)"
            width="24.5"
            height="12.2"
            style={{ overflow: 'visible' }}
          />
        </clipPath>
        <g style={{ clipPath: 'url(#SVGID_10_)' }}>
          <rect
            x="-905.1"
            y="-3502.9"
            transform="matrix(0.9945 -0.1051 0.1051 0.9945 -18.6309 31.2584)"
            width="2384.9"
            height="7390.6"
            style={{ overflow: 'visible', fill: '#1B1B1B' }}
          />
          <clipPath id="SVGID_12_">
            <rect
              x="-905.1"
              y="-3502.9"
              transform="matrix(0.9945 -0.1051 0.1051 0.9945 -18.6309 31.2584)"
              width="2384.9"
              height="7390.6"
              style={{ overflow: 'visible' }}
            />
          </clipPath>
          <rect
            x="173.1"
            y="39"
            transform="matrix(0.8087 -0.5882 0.5882 0.8087 4.7997 125.7425)"
            style={{ clipPath: 'url(#SVGID_12_)', fill: '#1B1B1B' }}
            width="45.1"
            height="32.9"
          />
        </g>
      </g>
      <g>
        <path
          style={{ fill: '#1B1B1B' }}
          d="M111.9,44.5c39.8,0,72.2,32.4,72.2,72.2s-32.4,72.2-72.2,72.2s-72.2-32.4-72.2-72.2S72.1,44.5,111.9,44.5
            M111.9,41.5c-41.5,0-75.2,33.7-75.2,75.2s33.7,75.2,75.2,75.2s75.2-33.7,75.2-75.2S153.4,41.5,111.9,41.5L111.9,41.5z"
        />
      </g>
    </g>
    <g id="Layer_11">
      <text transform="matrix(1 0 0 1 74.9844 137.3187)" style={{ fill: '#414042', fontFamily: 'Roboto', fontSize: '64px' }}>{text}</text>
    </g>
  </svg>
);

UL1746Part1DoubleWallTankIcon.propTypes = {
  text: PropTypes.any,
};

UL1746Part1DoubleWallTankIcon.defaultProps = {
  text: '',
};

export default UL1746Part1DoubleWallTankIcon;
