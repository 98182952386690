/* eslint-disable no-param-reassign,no-use-before-define */
import { Get, Patch, Post, Delete } from '../HttpUtils';

const fuelHaulerListFilter = JSON.stringify({
  order: 'name ASC',
});

const fuelHaulerFilter = JSON.stringify({
  include: [
    {
      relation: 'schedules',
      scope: {
        include: ['destinations'],
      },
    },
    {
      relation: 'sites',
      scope: {
        include: ['site'],
      },
    },
    'destinations',
  ],
});

function buildModel(fuelHauler) {
  fuelHauler.sites = buildSitesModel(fuelHauler);
  return fuelHauler;
}

function buildSitesModel(fuelHauler) {
  const currentSites = fuelHauler.sites || [];
  return currentSites.map(current => ({
    id: current.siteId,
    fuelHaulerExternalId: current.fuelHaulerExternalId,
    siteName: current.site.siteName,
    nickname: current.site.nickname,
    _fuelHaulerSiteMapping: {
      id: current.id,
    },
  }));
}

function buildRequestPayload(fuelHauler) {
  return {
    id: fuelHauler.id,
    name: fuelHauler.name,
    fuelHaulerTimezone: fuelHauler.fuelHaulerTimezone,
    enabled: fuelHauler.enabled,
    sites: buildSiteRequestPayload(fuelHauler),
    destinations: fuelHauler.destinations,
    schedules: buildSchedulesRequestPayload(fuelHauler),
  };
}

function buildSiteRequestPayload(fuelHauler) {
  if (!fuelHauler.sites) {
    return null;
  }

  return fuelHauler.sites.map((current) => {
    const site = {
      siteId: current.id,
      fuelHaulerExternalId: current.fuelHaulerExternalId || null,
    };

    if (fuelHauler.id) {
      site.fuelHaulerId = fuelHauler.id;
    }

    if (current._fuelHaulerSiteMapping) {
      site.id = current._fuelHaulerSiteMapping.id;
    }

    return site;
  });
}

function buildSchedulesRequestPayload(fuelHauler) {
  if (!fuelHauler.schedules) {
    return null;
  }

  return fuelHauler.schedules.map((currentSchedule) => {
    const requestSchedule = {
      fuelHaulerId: fuelHauler.id,
      transmitSchedule: currentSchedule.transmitSchedule,
      destinations: [],
    };

    if (currentSchedule.id) {
      requestSchedule.id = currentSchedule.id;
    }

    if (currentSchedule.destinations) {
      requestSchedule.destinations = currentSchedule.destinations.map(currentDestination => ({
        deliveryUrl: currentDestination.deliveryUrl,
      }));
    }

    return requestSchedule;
  });
}

export default {
  getList() {
    return Get(`/FuelHaulers?filter=${fuelHaulerListFilter}`).then(response => response.data.map(buildModel));
  },

  getById(id) {
    return Get(`/FuelHaulers/${id}?filter=${fuelHaulerFilter}`).then(response => buildModel(response.data));
  },

  deleteById(id) {
    return Delete(`/FuelHaulers/${id}`).then(() => Promise.resolve(null));
  },

  save(fuelHauler) {
    const payload = buildRequestPayload(fuelHauler);
    if (fuelHauler.id) {
      return Patch(`/fuelHaulers/${fuelHauler.id}`, payload).then(response => response.data);
    }
    return Post('/fuelHaulers', payload).then(response => response.data);
  },

  exportPreview(id) {
    return Get(`/FuelHaulers/${id}/exportPreview`).then(response => response.data);
  },
};
