import { combineReducers } from 'redux';
import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'react-router-redux';

import AlarmReducer from './screens/alarms/AlarmReducer';
import ComplianceReducer from './screens/compliance/ComplianceReducer';
import CommandReducer from './screens/commands/CommandReducer';
import SiteDocumentReducer from './screens/documents/SiteDocumentReducer';
import DocUploadReducer from './screens/documents/components/upload/UploadReducer';
import EquipmentReducer from './screens/equipment/EquipmentReducer';
import InventoryReducer from './screens/inventory/InventoryReducer';
import MviReducer from './screens/mvi/MviReducer';
import NewIssueReducer from './components/new-issue-button/NewIssueReducer';
import IssueReducer from './screens/issues/IssueReducer';
import MviLinkModalReducer from './components/mvi-link-modal/MviLinkModalReducer';
import SiteDispatchReducer from './screens/dispatch/SiteDispatchReducer';

import ActionTypes from './SiteActionTypes';

function saveSiteSuccess(state, updatedSite) {
  const currentSite = update(state.currentSite, {
    nickname: { $set: updatedSite.nickname },
    businessName: { $set: updatedSite.businessName },
    address: { $set: updatedSite.address },
    city: { $set: updatedSite.city },
    zip: { $set: updatedSite.zip },
    phone: { $set: updatedSite.phone },
    siteTimezone: { $set: updatedSite.siteTimezone },
    labels: { $set: updatedSite.labels },
    facilityId: { $set: updatedSite.facilityId },
    externalIds: { $set: updatedSite.externalIds }
  });

  return update(state, {
    saving: {
      $set: false,
    },
    currentSite: {
      $set: currentSite,
    },
    pendingEdits: {
      $set: currentSite,
    },
    customerLabels: {
      $set: updateCustomerLabels(state, updatedSite),
    }
  });
}

function updateCustomerLabels(state, updatedSite) {
  const customerLabels = state.customerLabels || [];
  const siteLabels = updatedSite.labels || [];
  const updateLabels = [...customerLabels];
  siteLabels.forEach(siteLabel => {
    const index = customerLabels.findIndex(current => current.id === siteLabel.id);
    if (index === -1) {
      updateLabels.push(siteLabel);
    }
  });
  return sortLabels(updateLabels);
}

function sortLabels(labels) {
  if (!labels || !labels.length) {
    return [];
  }
  const updated = [...labels];
  updated.sort((first, second) => first.labelText.toLowerCase().localeCompare(second.labelText.toLowerCase()));
  return updated;
}

const SiteRootReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SITE_STARTED:
      return Object.assign({}, state, {
        currentSite: null,
        pendingEdits: null,
        saveError: null,
        currentSiteLoading: true,
        currentSiteLoadingFailed: false,
      });
    case ActionTypes.RECEIVE_SITE:
      return Object.assign({}, state, {
        currentSite: action.currentSite,
        pendingEdits: action.currentSite,
        currentSiteLoading: false,
      });
    case ActionTypes.CHANGE_SITE_FAILED:
      return Object.assign({}, state, {
        currentSiteLoading: false,
        currentSiteLoadingFailed: true,
      });
    case ActionTypes.RECEIVE_CUSTOMER_LABELS:
      return Object.assign({}, state, {
        customerLabels: sortLabels(action.customerLabels),
      });

    case ActionTypes.EDIT_CURRENT_SITE:
      return Object.assign({}, state, {
        pendingEdits: action.updatedSite,
      });
    case LOCATION_CHANGE:
    case ActionTypes.DISCARD_SITE_EDITS:
      return Object.assign({}, state, {
        pendingEdits: state.currentSite,
        saveError: null,
      });

    case ActionTypes.SAVE_SITE_STARTED:
      return Object.assign({}, state, {
        saving: true,
        saveError: null,
      });
    case ActionTypes.SAVE_SITE_SUCCESS:
      return saveSiteSuccess(state, action.updatedSite);
    case ActionTypes.SAVE_SITE_FAILED:
      return Object.assign({}, state, {
        saving: false,
        saveError: action.error,
      });

    default:
      return state;
  }
};

const CombinedReducer = combineReducers({
  root: SiteRootReducer,
  alarms: AlarmReducer,
  compliance: ComplianceReducer,
  commands: CommandReducer,
  documents: SiteDocumentReducer,
  documentUpload: DocUploadReducer,
  equipment: EquipmentReducer,
  inventory: InventoryReducer,
  mvi: MviReducer,
  issues: IssueReducer,
  newIssue: NewIssueReducer,
  mviLinkModal: MviLinkModalReducer,
  dispatches: SiteDispatchReducer,
});

export default function SiteReducer(state = {}, action) {
  if (action.type === ActionTypes.RESET || (action.type === LOCATION_CHANGE && !action.payload.pathname.startsWith('/sites'))) {
    return {};
  }
  return CombinedReducer(state, action);
}
