import isEqual from 'lodash/isEqual';
import { ConnectionTypes } from './connection-constants';
import ConnectionFactory from './connection-type-factory';

export function isSelectedSiteLoading(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.sites && state.admin.organizations.sites.selectedSiteLoading) || false;
}

export function selectedSite(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.sites && state.admin.organizations.sites.selectedSite) || null;
}

export function pendingSiteEdits(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.sites && state.admin.organizations.sites.pendingEdits) || null;
}

export function pendingSiteEditsPristine(state) {
  const site = selectedSite(state);
  const edits = pendingSiteEdits(state);
  return (site && edits && isEqual(site, edits)) || false;
}

export function siteError(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.sites && state.admin.organizations.sites.error) || null;
}

export function isSaving(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.sites && state.admin.organizations.sites.saving) || false;
}

function geocode(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.sites && state.admin.organizations.sites.geocode) || {};
}

export function isGeocoding(state) {
  return geocode(state).isGeocoding || false;
}

export function isGeocodeError(state) {
  return geocode(state).isGeocodeError || false;
}

export function geocodeResults(state) {
  return geocode(state).geocodeResults || null;
}

export function entityNotifications(state) {
  return (selectedSite(state) && selectedSite(state).entityNotifications) || [];
}

export function isEditingSiteConnection(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.sites && state.admin.organizations.sites.isEditingSiteConnection) || false;
}

export function isSavingSiteConnection(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.sites && state.admin.organizations.sites.isSavingSiteConnection) || false;
}

export function siteConnectionDetail(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.sites && state.admin.organizations.sites.siteConnectionDetail) || ConnectionFactory.create();
}

export function shouldConfirmDeleteSiteConnection(state) {
  return ConnectionTypes.NONE.connectionType === siteConnectionDetail(state).connectionType;
}

export function openDeleteSiteConnectionModal(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.sites && state.admin.organizations.sites.openDeleteSiteConnectionModal) || false;
}
