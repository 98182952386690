import ActionTypes from './FuelHaulersActionTypes';

export default function AdminReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_FUEL_HAULER_LIST_STARTED:
      return Object.assign({}, state, {
        loading: true,
        fuelHaulerList: [],
      });
    case ActionTypes.RECEIVE_FUEL_HAULER_LIST:
      return Object.assign({}, state, {
        loading: false,
        fuelHaulerList: action.fuelHaulerList,
      });
    case ActionTypes.GET_FUEL_HAULER_LIST_FAILED:
      return Object.assign({}, state, {
        loading: false,
        fuelHaulerList: [],
      });

    case ActionTypes.GET_FUEL_HAULER_STARTED:
      return Object.assign({}, state, {
        loading: true,
        validationErrors: [],
        selectedFuelHauler: null,
      });
    case ActionTypes.RECEIVE_FUEL_HAULER:
      return Object.assign({}, state, {
        loading: false,
        selectedFuelHauler: action.selectedFuelHauler,
      });
    case ActionTypes.GET_FUEL_HAULER_FAILED:
      return Object.assign({}, state, {
        loading: false,
        selectedFuelHauler: null,
      });

    case ActionTypes.GET_EXPORT_PREVIEW_STARTED:
      return Object.assign({}, state, {
        loading: true,
        exportPreview: null,
      });
    case ActionTypes.GET_EXPORT_PREVIEW_FAILED:
      return Object.assign({}, state, {
        loading: false,
        exportPreview: null,
      });
    case ActionTypes.RECEIVE_EXPORT_PREVIEW:
      return Object.assign({}, state, {
        loading: false,
        exportPreview: action.exportPreview,
      });

    case ActionTypes.RECEIVE_VALIDATION_ERRORS:
      return Object.assign({}, state, {
        validationErrors: action.validationErrors,
      });

    case ActionTypes.SAVE_FUEL_HAULER_STARTED:
      return Object.assign({}, state, {
        saving: true,
        error: null,
      });
    case ActionTypes.SAVE_FUEL_HAULER_SUCCESS:
      return Object.assign({}, state, {
        saving: false,
        selectedFuelHauler: null,
      });
    case ActionTypes.SAVE_FUEL_HAULER_FAILED:
      return Object.assign({}, state, {
        saving: false,
        error: action.error,
      });

    case ActionTypes.DELETE_FUEL_HAULER_STARTED:
      return Object.assign({}, state, {
        deleting: true,
      });
    case ActionTypes.DELETE_FUEL_HAULER_COMPLETE:
      return Object.assign({}, state, {
        deleting: false,
      });

    default:
      return state;
  }
}
