import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-solid-svg-icons';

import ErrorMessage from '../../../../shared/components/error-message/ErrorMessage';
import CanaryLoadingIndicator from '../../../../shared/components/CanaryLoadingIndicator';
import * as selectors from './MviLinkModalSelectors';
import * as actions from './MviLinkModalActions';
import * as siteSelectors from '../../SiteSelectors';

class MviLinkModal extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.copyLink = this.copyLink.bind(this);
    this.openForm = this.openForm.bind(this);
  }

  copyLink() {
    const span = document.querySelector('#mviFormUrl');
    const range = document.createRange();
    range.selectNode(span);
    const selection = window.getSelection();

    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
  }

  openForm() {
    window.open(this.props.mviFormUrl, '_blank');
  }

  renderBody() {
    if (this.props.error) {
      return <ErrorMessage error={this.props.error} title="Failed to build link to MVI form" />;
    }
    else if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }
    return (
      <div className="d-flex">
        <FontAwesomeIcon icon={faLink} className="mr-2" />
        <span id="mviFormUrl">{this.props.mviFormUrl}</span>
      </div>
    );
  }

  render() {
    if (!this.props.hasPermissions || !this.props.site) {
      return null;
    }

    const actionsDisabled = !!this.props.error || !this.props.mviFormUrl;
    const header = `Monthly Visual Inspection Form for ${this.props.site.nickname}`;
    return (
      <Modal isOpen={this.props.open} toggle={this.props.closeModal} size="md">
        <ModalHeader toggle={this.props.closeModal}>
          {header}
        </ModalHeader>
        <ModalBody>
          {this.renderBody()}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={this.copyLink} disabled={actionsDisabled}>Copy Link</Button>
          {' '}
          <Button color="primary" size="sm" outline onClick={this.openForm} disabled={actionsDisabled}>Open Form</Button>
          {' '}
          <Button color="primary" size="sm" onClick={this.props.closeModal}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

MviLinkModal.propTypes = {
  site: PropTypes.object,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  mviFormUrl: PropTypes.string,
  hasPermissions: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

MviLinkModal.defaultProps = {
  site: null,
  error: null,
  mviFormUrl: null
};

function mapStateToProps(state) {
  return {
    site: siteSelectors.currentSite(state),
    open: selectors.isModalOpen(state),
    loading: selectors.isLoading(state),
    error: selectors.error(state),
    mviFormUrl: selectors.mviFormUrl(state),
    hasPermissions: siteSelectors.canViewMviForms(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(actions.closeModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MviLinkModal);
