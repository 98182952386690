import { buildStringSort } from '../../../../../shared/SortUtils';
import ActionTypes from './OrganizationListActionTypes';

const nameSort = buildStringSort('name');

const sort = (orgList) => {
  if (!orgList || !orgList.length) {
    return [];
  }
  return orgList.sort(nameSort);
};

function updateOrg(state, organization) {
  const orgList = state.organizationList || [];
  return orgList.map(current => {
    if (current.id !== organization.id) {
      return current;
    }
    return Object.assign({}, current, { enabled: organization.enabled });
  }).sort(nameSort);
}

function removeOrg(state, organization) {
  const orgList = state.organizationList || [];
  return orgList.filter(current => current.id !== organization.id).sort(nameSort);
}

export default function OrganizationListReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_ORGANIZATION_LIST_STARTED:
      return Object.assign({}, state, {
        loading: true,
        organizationList: [],
      });
    case ActionTypes.RECEIVE_ORGANIZATION_LIST:
      return Object.assign({}, state, {
        loading: false,
        organizationList: sort(action.organizationList),
      });
    case ActionTypes.GET_ORGANIZATION_LIST_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
        organizationList: [],
      });

    case ActionTypes.UPDATE_ORGANIZATION_STARTED:
    case ActionTypes.DELETE_ORGANIZATION_STARTED:
      return Object.assign({}, state, {
        updating: true,
      });
    case ActionTypes.UPDATE_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        organizationList: updateOrg(state, action.organization),
      });
    case ActionTypes.DELETE_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        organizationList: removeOrg(state, action.organization),
      });
    case ActionTypes.UPDATE_ORGANIZATION_FAILED:
    case ActionTypes.DELETE_ORGANIZATION_FAILED:
      return Object.assign({}, state, {
        updating: false,
        error: action.error,
      });

    default:
      return state;
  }
}
