import {LOCATION_CHANGE} from 'react-router-redux';
import differenceBy from 'lodash/differenceBy';
import last from 'lodash/last';
import { buildGenericSort } from '../../shared/SortUtils';
import ActionTypes from './PreferencesActionTypes';
import {
  customerSiteStatuses,
  defaultIssueUserNotificationRuleTemplateObject,
  disabledIssueNotificationRuleTemplateObject,
  emptyArray,
  routePathSet,
} from './PreferenceConstants';

const updateAvailableSites = (allSites, selectedSites) => differenceBy(allSites, selectedSites, 'id');
const getAllSitesForCustomerId = (customerId, state) => {
  if (!customerId) {
    return emptyArray;
  }
  return (state.customerSites && state.customerSites.filter(site => customerId === site.customerId)) || emptyArray;
};

const enrichSiteContext = (notificationTopic, customerId, state) => {
  if (!notificationTopic) {
    return undefined;
  }

  const selectedSites = (notificationTopic.triggeringSites && notificationTopic.triggeringSites.sites) || emptyArray;
  return Object.assign({}, notificationTopic, {
    availableSites: updateAvailableSites(getAllSitesForCustomerId(customerId, state), selectedSites),
  });
};

const siteContextFromUserNotificationLowProductRule = (userNotificationLowProductRule) => ({
  triggeringSites: {
    siteListType: userNotificationLowProductRule.siteListType,
    sites: userNotificationLowProductRule.sites,
  },
  availableSites: emptyArray,
});

const getDefaultUserNotificationRule = (state, customerId) => {
  const userId = state.userId;
  return Object.assign({},
    defaultIssueUserNotificationRuleTemplateObject,
    {
      userId,
      customerId,
    });
};

const getDisabledUserNotificationRule = (state, context) => {
  const userId = state.userId;
  const customerId = context.customerId;
  const transient = context.transient;
  return Object.assign({},
    disabledIssueNotificationRuleTemplateObject,
    {
      userId,
      customerId,
      transient,
    });
};

const handleLocationChange = (state, action) => {
  if (!state) {
    return null;
  }

  const newPath = action.payload && action.payload.pathname;
  const lastPathSegments = last(newPath.split('/'));
  if (!routePathSet.has(lastPathSegments)) {
    return null;
  }

  return state;
};

const sortSiteNameAsc = buildGenericSort('siteName');

const sort = (siteList) => {
  if (!siteList) {
    return [];
  }

  siteList.sort(sortSiteNameAsc);
  return siteList;
};

function PreferencesReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.SET_USER_ID:
      return Object.assign({}, state, {
        userId: action.userId,
      });
    case ActionTypes.GET_CUSTOMER_SITES_STARTED:
      return Object.assign({}, state, {
        customerSitesStatus: customerSiteStatuses.Loading,
        error: null,
      });
    case ActionTypes.RECEIVE_CUSTOMER_SITES:
      return Object.assign({}, state, {
        customerSitesStatus: customerSiteStatuses.Loaded,
        customerSites: sort(action.customerSites),
      });
    case ActionTypes.GET_CUSTOMER_SITES_FAILED:
      return Object.assign({}, state, {
        customerSitesStatus: customerSiteStatuses.Failed,
        customerSites: [],
        error: action.error,
      });
    case ActionTypes.GET_USER_NOTIFICATION_RULE_STARTED:
      return Object.assign({}, state, {
        isLoadingUserNotificationRule: true,
        error: null,
      });
    case ActionTypes.RECEIVE_USER_NOTIFICATION_RULE:
      return Object.assign({}, state, {
        isLoadingUserNotificationRule: false,
        selectedUserNotificationRule: action.selectedUserNotificationRule,
      });
    case ActionTypes.GET_USER_NOTIFICATION_RULE_FAILED:
      return Object.assign({}, state, {
        isLoadingUserNotificationRule: false,
      });
    case ActionTypes.SET_NOTIFICATION_TOPIC_CONTEXT:
      return Object.assign({}, state, {
        notificationTopicContext: enrichSiteContext(action.notificationTopicContext, state.selectedUserNotificationRule.customerId, state),
      });
    case ActionTypes.SAVE_USER_NOTIFICATION_RULE_STARTED:
      return Object.assign({}, state, {
        isSavingUserNotificationRule: true,
        error: null,
      });
    case ActionTypes.SAVE_USER_NOTIFICATION_RULE_SUCCESS:
      return Object.assign({}, state, {
        isSavingUserNotificationRule: false,
        selectedUserNotificationRule: action.selectedUserNotificationRule,
        error: null,
      });
    case ActionTypes.SAVE_USER_NOTIFICATION_RULE_FAILED:
      return Object.assign({}, state, {
        isSavingUserNotificationRule: false,
        error: action.error,
      });
    case ActionTypes.RESET_SELECTED_ISSUE_NOTIFICATION_RULE_TO_DEFAULT:
      return Object.assign({}, state, {
        selectedUserNotificationRule: getDefaultUserNotificationRule(state, action.customerId),
        isSavingUserNotificationRule: false,
      });
    case ActionTypes.DISABLE_SELECTED_ISSUE_NOTIFICATION_RULE:
      return Object.assign({}, state, {
        selectedUserNotificationRule: getDisabledUserNotificationRule(state, action.context),
        isLoadingUserNotificationRule: false,
        isSavingUserNotificationRule: false,
      });
    case ActionTypes.GET_USER_NOTIFICATION_LOW_PRODUCT_RULE_STARTED:
      return Object.assign({}, state, {
        isLoadingUserNotificationLowProductRule: true,
        error: null,
      });
    case ActionTypes.RECEIVE_USER_NOTIFICATION_LOW_PRODUCT_RULE:
      return Object.assign({}, state, {
        isLoadingUserNotificationLowProductRule: false,
        selectedUserNotificationLowProductRule: action.selectedUserNotificationLowProductRule,
        userNotificationLowProductSiteContext: enrichSiteContext(siteContextFromUserNotificationLowProductRule(action.selectedUserNotificationLowProductRule), action.selectedUserNotificationLowProductRule.customerId, state),
      });
    case ActionTypes.GET_USER_NOTIFICATION_LOW_PRODUCT_RULE_FAILED:
      return Object.assign({}, state, {
        isLoadingUserNotificationLowProductRule: false,
      });
    case ActionTypes.SAVE_USER_NOTIFICATION_LOW_PRODUCT_RULE_STARTED:
      return Object.assign({}, state, {
        isSavingUserNotificationLowProductRule: true,
        error: null,
      });
    case ActionTypes.SAVE_USER_NOTIFICATION_LOW_PRODUCT_RULE_SUCCESS:
      return Object.assign({}, state, {
        isSavingUserNotificationLowProductRule: false,
        selectedUserNotificationLowProductRule: action.selectedUserNotificationLowProductRule,
        error: undefined,
      });
    case ActionTypes.SAVE_USER_NOTIFICATION_LOW_PRODUCT_RULE_FAILED:
      return Object.assign({}, state, {
        isSavingUserNotificationLowProductRule: false,
        error: action.error,
      });
    case ActionTypes.RESET_UPDATED_EMAIL:
      return Object.assign({}, state, {
        updatedEmail: '',
        error: null,
      });
    case ActionTypes.SET_UPDATED_EMAIL: {
      return Object.assign({}, state, {
        updatedEmail: action.updatedEmail,
      });
    }
    case ActionTypes.SAVE_UPDATED_EMAIL_STARTED:
      return Object.assign({}, state, {
        isSavingEmail: true,
        updateEmailSuccess: false,
        error: null,
      });
    case ActionTypes.SAVE_UPDATED_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        isSavingEmail: false,
        updateEmailSuccess: true,
        error: null,
      });
    case ActionTypes.SAVE_UPDATED_EMAIL_FAILED:
      return Object.assign({}, state, {
        isSavingEmail: false,
        error: action.error,
        updateEmailSuccess: false,
      });
    case ActionTypes.UPDATE_PASSWORD_CONTEXT:
      return Object.assign({}, state, {
        passwordContext: action.passwordContext,
      });
    case ActionTypes.CHANGE_PASSWORD_STARTED: {
      return Object.assign({}, state, {
        isChangingPassword: true,
        error: null,
      });
    }
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isChangingPassword: false,
        changePasswordSuccess: true,
        error: null,
        passwordContext: null,
      });
    case ActionTypes.CHANGE_PASSWORD_FAILED:
      return Object.assign({}, state, {
        isChangingPassword: false,
        changePasswordSuccess: false,
        error: action.error,
      });
    case ActionTypes.CANCEL_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        isChangingPassword: false,
        changePasswordSuccess: false,
        error: null,
        passwordContext: null,
      });
    case LOCATION_CHANGE:
      return handleLocationChange(state, action);
    default:
      return state;
  }
}
export default PreferencesReducer;
