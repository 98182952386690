const Capacity = { id: 6,
  name: 'capacity',
  label: 'Capacity',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: false,
  index: 0,
  fields: [
    { id: 1,
      name: 'nominalSizeGallons',
      label: 'Nominal Size (gallons)',
      path: 'nominalSizeGallons',
      type: 'text',
      step: 10,
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 2,
      name: 'actualCapacityGallons',
      label: 'Actual Capacity (gallons)',
      path: 'actualCapacityGallons',
      type: 'text',
      step: 10,
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: true,
    },
    { id: 3,
      name: 'fullVolumeGallons',
      label: 'Tank Volume from ATG',
      path: 'atgRiser.atgTank.fullVolumeGallons',
      type: 'text',
      step: 10,
      cols: 4,
      editable: false,
      required: false,
      error: false,
      display: true,
    },
  ],
};

export default Capacity;
