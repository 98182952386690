import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../actions';
import InvSite1HourGauge from './InvSite1HourGauge';
import InvSite24HourGauge from './InvSite24HourGauge';
import SiteHealthByHour from './SiteHealthByHour';
import FuelHaulerGauge from './FuelHaulerGauge';
import FuelHaulerHealthByPercentage from './FuelHaulerHealthByPercentage';
import ExportCountByHour from './ExportCountByHour';

class InvDashboardContainer extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
    this.props.getHourlyHistoryList();
    this.props.getFuelHaulerHealth();
  }

  handleRefreshClick() {
    this.props.getHourlyHistoryList();
    this.props.getFuelHaulerHealth();
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={12} className="mb-3">
            <div className="d-flex justify-content-end mb-1">
              <Button color="primary" size="sm" onClick={this.handleRefreshClick}>Refresh</Button>
            </div>
          </Col>
        </Row>
        <Row className="border-bottom">
          <Col sm={4} className="my-2">
            <InvSite1HourGauge />
          </Col>
          <Col sm={4} className="my-2">
            <InvSite24HourGauge />
          </Col>
          <Col sm={4} className="my-2">
            <FuelHaulerGauge />
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="my-4">
            <SiteHealthByHour />
          </Col>
          <Col lg={4} className="my-4">
            <ExportCountByHour />
          </Col>
          <Col lg={4} className="my-4">
            <FuelHaulerHealthByPercentage />
          </Col>
        </Row>
      </div>
    );
  }
}

InvDashboardContainer.propTypes = {
  getHourlyHistoryList: PropTypes.func.isRequired,
  getFuelHaulerHealth: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    getHourlyHistoryList: () => dispatch(actions.getHourlyHistoryList()),
    getFuelHaulerHealth: () => dispatch(actions.getFuelHaulerHealth()),
  };
}

export default connect(null, mapDispatchToProps)(withRouter(InvDashboardContainer));
