import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import { TestTypes } from '../../../../SiteConstants';

const TestTypeSelect = ({ id, name, selectedTestType, onSelect, disabled }) => (
  <Input
    type="select"
    id={id}
    name={name}
    value={selectedTestType || ''}
    disabled={disabled}
    onChange={event => onSelect(event.target.value || null)}
  >
    <option value="">Select test type</option>
    <option value={TestTypes.ThreeGPH}>3.0 GPH</option>
    <option value={TestTypes.PointTwoGPH}>0.20 GPH</option>
    <option value={TestTypes.PointOneGPH}>0.10 GPH</option>
  </Input>
);

TestTypeSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  selectedTestType: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TestTypeSelect.defaultProps = {
  id: null,
  name: null,
  selectedTestType: null,
  disabled: false,
};

export default TestTypeSelect;
