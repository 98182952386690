import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

import { MviReportStatusTypes } from '../../../../../../../../../AppConstants';

const TextByReportStatus = {
  [MviReportStatusTypes.ReceivedNoActionItems]: 'Canary parsed the report and did not identify any action items. Please review the report document to verify that the action items were parsed correctly.',
  [MviReportStatusTypes.ReceivedWithActionItems]: 'Canary parsed the report and identified the following action items. Please review the report document to verify that the action items were parsed correctly.',
  [MviReportStatusTypes.ReceivedInconclusive]: 'Canary was unable to parse the report. Please review the report document and manually create any action items.',
};

const AcknowledgeReportStatus = ({ canEdit, editDisabled, report, onClose }) => {
  if (!report) {
    return null;
  }

  const text = TextByReportStatus[report.status];
  if ((!canEdit || editDisabled) && !!text) {
    return (
      <Alert color="info">
        {text}
      </Alert>
    );
  }

  return (
    <Alert color="info" isOpen={!!text} toggle={onClose}>
      {text}
    </Alert>
  );
};

AcknowledgeReportStatus.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  editDisabled: PropTypes.bool,
  report: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

AcknowledgeReportStatus.defaultProps = {
  editDisabled: false,
  report: null,
};

export default AcknowledgeReportStatus;
