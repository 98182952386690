const Product = { id: 10,
  name: 'productHistory',
  label: 'Product',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: true,
  modalOpen: false,
  deleteModalOpen: false,
  index: 0,
  fields: [
    { id: 1,
      name: 'effectiveDate',
      label: 'Effective Date',
      path: 'productHistory.0.effectiveDate',
      type: 'date',
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
    { id: 2,
      name: 'product',
      label: 'Product',
      path: 'productHistory.0.product',
      type: 'select',
      values: ['Gasoline', 'Diesel', 'Kerosene', 'Heating oil', 'New oil', 'Used oil'],
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
    { id: 3,
      name: 'gasGrade',
      label: 'Grade',
      path: 'productHistory.0.gasGrade',
      type: 'select',
      values: ['Unknown', 'Regular', 'Mid-grade', 'Premium'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: false,
    },
    { id: 4,
      name: 'ethanolContent',
      label: 'Ethanol Content',
      path: 'productHistory.0.ethanolContent',
      type: 'select',
      values: ['E10', 'E15', 'E85', 'Ethanol free'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: false,
    },
    { id: 5,
      name: 'bioContent',
      label: 'Bio Content',
      path: 'productHistory.0.bioContent',
      type: 'select',
      values: ['B5', 'B20', 'B50', 'B99'],
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: false,
    },
    { id: 6,
      name: 'offRoad',
      label: 'Off Road',
      path: 'productHistory.0.offRoad',
      type: 'bool',
      cols: 4,
      editable: true,
      required: false,
      error: false,
      display: false,
    },
  ],
};

export default Product;
