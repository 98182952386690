import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PolicyEditModal from '../../../containers/PolicyEditModal';
import { ActorGrantTypes, AssetGrantTypes } from '../../../permission-constants';
import * as orgEditSelectors from '../../../OrganizationEditSelectors';
import * as orgEditActions from '../../../actions';
import * as selectors from '../ExternalAccessSelectors';
import OrgGrants from '../components/OrgGrants';
import ErrorMessage from '../../../../../../../../shared/components/error-message/ErrorMessage';
import ConfirmModal from '../../../../../../../../shared/components/confirm-modal/ConfirmModal';

const InitialState = Object.freeze({
  policyEditModalOpen: false,
  confirmModalOpen: false,
  policy: null,
});

class Granted extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.state = InitialState;

    this.handlePolicyAdd = this.handlePolicyAdd.bind(this);
    this.handlePolicyEdit = this.handlePolicyEdit.bind(this);
    this.handlePolicyUpdate = this.handlePolicyUpdate.bind(this);
    this.handlePolicySave = this.handlePolicySave.bind(this);
    this.handlePolicyDelete = this.handlePolicyDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handlePolicyAdd(organization) {
    const newPolicy = {
      organizationGrantType: ActorGrantTypes.List,
      siteGrantType: AssetGrantTypes.All,
      grantedSites: [],
    };
    if (organization) {
      newPolicy.grantedToOrganizations = [organization];
    }
    this.setState({
      policyEditModalOpen: true,
      policy: newPolicy,
    });
  }

  handlePolicyEdit(policy) {
    this.setState({
      policyEditModalOpen: true,
      policy,
    });
  }

  handlePolicyUpdate(policy) {
    this.setState({
      policy,
    });
  }

  handlePolicySave(policy) {
    this.setState(InitialState);
    this.props.savePolicy(policy);
  }

  handlePolicyDelete(policy) {
    this.setState({
      confirmModalOpen: true,
      policy,
    });
  }

  confirmDelete() {
    const policy = this.state.policy;
    this.setState(InitialState);
    this.props.deletePolicy(policy);
  }

  handleCancel() {
    this.setState(InitialState);
  }

  render() {
    return (
      <div>
        <ErrorMessage title="Policy edit failed" error={this.props.error} />
        <OrgGrants
          orgGrants={this.props.orgGrants}
          onPolicyAdd={this.handlePolicyAdd}
          onPolicyEdit={this.handlePolicyEdit}
          onPolicyDelete={this.handlePolicyDelete}
          linkToGranteeOrg
        />
        <PolicyEditModal
          isOpen={this.state.policyEditModalOpen}
          policy={this.state.policy}
          onPolicyUpdate={this.handlePolicyUpdate}
          onSave={this.handlePolicySave}
          onCancel={this.handleCancel}
        />
        <ConfirmModal
          isOpen={this.state.confirmModalOpen}
          headerText="Delete policy"
          body="Are you sure you want to delete this policy?"
          onConfirm={this.confirmDelete}
          onCancel={this.handleCancel}
        />
      </div>
    );
  }
}

Granted.propTypes = {
  error: PropTypes.object,
  orgGrants: PropTypes.arrayOf(PropTypes.shape({
    grantedByOrganization: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    grantedToOrganization: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    policies: PropTypes.arrayOf(PropTypes.shape({
      permissionSet: PropTypes.shape({
        name: PropTypes.string,
      }).isRequired,
      siteGrantType: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
    })).isRequired,
  })),
  savePolicy: PropTypes.func.isRequired,
  deletePolicy: PropTypes.func.isRequired,
};

Granted.defaultProps = {
  orgGrants: [],
  error: null,
};

function mapStateToProps(state) {
  return {
    error: orgEditSelectors.error(state),
    orgGrants: selectors.orgGrants(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePolicy: policy => dispatch(orgEditActions.savePolicy(policy)),
    deletePolicy: policy => dispatch(orgEditActions.deletePolicy(policy)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Granted));
