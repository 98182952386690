import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withTracking } from '../../../shared/analytics';
import ExportRequest from '../components/ExportRequest';
import RecentExports from '../components/RecentExports';
import TabView from '../../../shared/components/TabView';
import * as actions from '../actions';
import { AuthenticationSelectors } from '../../../authentication/index';
import ExportSelectors, { selectedCustomerId } from '../ExportSelectors';

class Exports extends Component {
  componentDidMount() {
    this.props.getSites();
  }

  render() {
    const tabs = [
      {
        name: 'Report Requests',
        render: () => <ExportRequest customers={this.props.customers} sites={this.props.sites} updateCustomerId={this.props.updateCustomerId} selectedCustomerId={this.props.selectedCustomerId} />,
      },
      {
        name: 'Recent Reports',
        render: () => <RecentExports getRecentExports={this.props.getRecentExports} recentExports={this.props.recentExports} />,
      },
    ];

    return (
      <TabView tabs={tabs} />
    );
  }
}

function mapStateToProps(state) {
  return {
    recentExports: state.exports && state.exports.recentExports,
    customers: AuthenticationSelectors.allCustomers(state),
    sites: ExportSelectors.list(state),
    selectedCustomerId: selectedCustomerId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRecentExports: () => dispatch(actions.getRecentExports()),
    getSites: () => dispatch(actions.getSites()),
    updateCustomerId: customerId => dispatch(actions.updateCustomerId(customerId)),
  };
}

Exports.propTypes = {
  recentExports: PropTypes.array,
  getRecentExports: PropTypes.func,
  customers: PropTypes.array,
  sites: PropTypes.array,
  getSites: PropTypes.func.isRequired,
  updateCustomerId: PropTypes.func.isRequired,
  selectedCustomerId: PropTypes.number,
};

Exports.defaultProps = {
  recentExports: [],
  getRecentExports: null,
  customers: [],
  sites: [],
  selectedCustomerId: undefined,
};

export default withTracking('Report Center')(connect(mapStateToProps, mapDispatchToProps)(Exports));
