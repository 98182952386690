import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { withTracking } from '../../../shared/analytics';
import withTour from '../../../shared/components/tour/WithTour';
import * as NavContextActions from '../../../shared/components/nav-context/NavContextActions';
import ErrorMessage from '../../../shared/components/error-message/ErrorMessage';
import CanaryLoadingIndicator from '../../../shared/components/CanaryLoadingIndicator';
import { MviClient } from '../../../client';
import * as SiteActions from '../../sites/actions';
import buildViewManagerActions from '../../../shared/components/table/view-manager/ViewManagerActions';
import { FilterType, ViewCategory } from '../../../shared/components/table/view-manager/ViewManagerConstants';
import buildViewBar from '../../../shared/components/table/view-manager/ViewBar';
import buildViewControls from '../../../shared/components/table/view-manager/ViewControls';
import { TablesTour } from '../../UserTours';
import ActionTypes from '../MviActionTypes';
import { MviColumns } from '../MviConstants';
import MviTable from '../components/MviTable';
import * as actions from '../actions';
import MviListSelectors, { MviTableSelectors, MviViewManagerSelectors } from '../MviSelectors';

function pluralize(reports) {
  return reports && reports.length === 1 ? 'Inspection' : 'Inspections';
}

const viewManagerActions = buildViewManagerActions(ActionTypes, MviViewManagerSelectors, ViewCategory.MVI, MviClient.getMviMonths);
const MviFilterTypes = [FilterType.Customer, FilterType.Month, FilterType.InspectionStatus, FilterType.State, FilterType.Site, FilterType.Label];
const MviViewBar = buildViewBar(viewManagerActions, MviListSelectors, MviViewManagerSelectors, MviFilterTypes);
const MviViewControls = buildViewControls(viewManagerActions, MviListSelectors, MviViewManagerSelectors, MviColumns);

class MviList extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.getMvis = this.getMvis.bind(this);
    this.handleMviClick = this.handleMviClick.bind(this);
  }

  componentDidMount() {
    this.props.clearNavContext();
  }

  getMvis() {
    this.props.getMvis().then(() => this.props.startTour());
  }

  handleMviClick(mvi) {
    this.props.navToSiteMvi(mvi, this.props.mvis);
  }

  render() {
    if (this.props.error) {
      return (
        <div className="mt-2">
          <ErrorMessage error={this.props.error} />
        </div>
      );
    }

    return (
      <>
        <Row className="mb-2">
          <Col md={6}>
            <h5 className="mb-0">
              {this.renderTitle()}
              {this.props.isRefreshing ? <CanaryLoadingIndicator inline /> : null}
            </h5>
          </Col>
          <Col md={6}>
            <MviViewControls onChange={this.getMvis} />
          </Col>
        </Row>
        <MviViewBar onChange={this.getMvis} />
        {this.renderTable()}
      </>
    );
  }

  renderTitle() {
    if (this.props.isRefreshing || this.props.isLoading) {
      return null;
    }
    const count = (this.props.mvis && this.props.mvis.length) || 0;
    return `${count.toLocaleString()} ${pluralize(this.props.mvis)}`;
  }

  renderTable() {
    if (this.props.isLoading || this.props.isRefreshing) {
      return <CanaryLoadingIndicator />;
    }

    return (
      <MviTable
        dataLoading={this.props.isLoading}
        mvis={this.props.filteredMvis}
        columns={this.props.columns}
        sortColumnId={this.props.sortColumnId}
        sortDirection={this.props.sortDirection}
        onSortChange={this.props.changeSort}
        currentPage={this.props.currentPage}
        onPageChange={this.props.changePage}
        pageSize={this.props.pageSize}
        pageCount={this.props.pageCount}
        onMviSelected={this.handleMviClick}
      />
    );
  }
}

MviList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  mvis: PropTypes.array,
  filteredMvis: PropTypes.array,

  getMvis: PropTypes.func.isRequired,
  navToSiteMvi: PropTypes.func.isRequired,
  clearNavContext: PropTypes.func.isRequired,

  // filters and columns prefs
  columns: PropTypes.array.isRequired,
  sortColumnId: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  changeSort: PropTypes.func.isRequired,

  // table pagination support
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,

  // feature tour
  startTour: PropTypes.func.isRequired,
};

MviList.defaultProps = {
  error: null,
  mvis: [],
  filteredMvis: [],
};

function mapStateToProps(state) {
  return {
    isLoading: MviListSelectors.isLoading(state),
    isRefreshing: MviListSelectors.isRefreshing(state),
    error: MviListSelectors.error(state),
    mvis: MviListSelectors.list(state),

    filteredMvis: MviTableSelectors.filteredList(state),
    columns: MviViewManagerSelectors.selectedColumns(state),
    sortColumnId: MviTableSelectors.sortColumnId(state),
    sortDirection: MviTableSelectors.sortDirection(state),
    currentPage: MviTableSelectors.currentPage(state),
    pageSize: MviTableSelectors.pageSize(state),
    pageCount: MviTableSelectors.pageCount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMvis: () => dispatch(actions.getMvis()),
    navToSiteMvi: (mvi, list) => dispatch(SiteActions.navToSiteMvi(mvi, list)),
    clearNavContext: () => dispatch(NavContextActions.clearContext()),

    // table pagination
    changeSort: (sortColumnId, sortDirection) => dispatch(actions.changeSort(sortColumnId, sortDirection)),
    changePage: page => dispatch(actions.changePage(page)),
  };
}

export default withTour(TablesTour.tourName, TablesTour.steps)(withTracking('MVI List')(connect(mapStateToProps, mapDispatchToProps)(MviList)));
