import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';

import * as actions from '../actions/index';
import * as selectors from '../SiteDashboardSelectors';
import OverallSiteConnectivityGauge from './OverallSiteConnectivityGauge';
import ParticleSiteConnectivityGauge from './ParticleSiteConnectivityGauge';
import TCPSiteConnectivityGauge from './TCPSiteConnectivityGauge';
import NumberBox from '../../../../../components/number-box/NumberBox';

class SiteDashboard extends Component {

  constructor(initialProps) {
    super(initialProps);
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
    this.props.getSiteHealth();
    this.props.getCommandRequestHealth();
  }

  handleRefreshClick() {
    this.props.getSiteHealth();
    this.props.getCommandRequestHealth();
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={10} className="mb-4">
            <h5>Device Responsiveness</h5>
          </Col>
          <Col xs={2}>
            <div className="d-flex justify-content-end mb-1">
              <Button color="primary" size="sm" onClick={this.handleRefreshClick}>Refresh</Button>
            </div>
          </Col>
        </Row>
        <Row className="border-bottom">
          <Col className="d-flex justify-content-center my-2" sm={4}>
            <OverallSiteConnectivityGauge />
          </Col>
          <Col className="d-flex justify-content-center my-2" sm={4}>
            <ParticleSiteConnectivityGauge />
          </Col>
          <Col className="d-flex justify-content-center my-2" sm={4}>
            <TCPSiteConnectivityGauge />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mt-4">
            <h5>Average Response Time (seconds)</h5>
            <div className="d-flex flex-row">
              <NumberBox
                loading={this.props.commandRequestHealthLoading}
                value={this.props.commandRequestHealth && this.props.commandRequestHealth.avgResolvedResponseTimeSec}
                title="Overall"
              />
              <NumberBox
                loading={this.props.commandRequestHealthLoading}
                value={this.props.commandRequestHealth && this.props.commandRequestHealth.avgParticleResolvedResponseTimeSec}
                title="Particle"
                className="ml-1 mr-1"
              />
              <NumberBox
                loading={this.props.commandRequestHealthLoading}
                value={this.props.commandRequestHealth && this.props.commandRequestHealth.avgTcpResolvedResponseTimeSec}
                title="TCP"
              />
            </div>
          </Col>
          <Col lg={6} className="mt-4">
            <h5>Device Counts</h5>
            <div className="d-flex flex-row">
              <NumberBox
                loading={this.props.siteHealthLoading}
                value={this.props.siteHealth && this.props.siteHealth.deviceCount}
                title="Device Count"
                precision={0}
              />
              <NumberBox
                loading={this.props.siteHealthLoading}
                value={this.props.siteHealth && this.props.siteHealth.deviceCommOutCount}
                title="Device Comm Out"
                precision={0}
                className="ml-1 mr-1"
              />
              <NumberBox
                loading={this.props.siteHealthLoading}
                value={this.props.siteHealth && this.props.siteHealth.atgCommOutCount}
                title="ATG Comm Out"
                precision={0}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

SiteDashboard.propTypes = {
  siteHealthLoading: PropTypes.bool.isRequired,
  siteHealth: PropTypes.object,
  commandRequestHealthLoading: PropTypes.bool.isRequired,
  commandRequestHealth: PropTypes.object,

  getSiteHealth: PropTypes.func.isRequired,
  getCommandRequestHealth: PropTypes.func.isRequired,
};

SiteDashboard.defaultProps = {
  siteHealth: PropTypes.object,
  commandRequestHealth: null,
};

function mapStateToProps(state) {
  return {
    siteHealth: selectors.siteHealth(state),
    siteHealthLoading: selectors.isSiteHealthLoading(state),
    commandRequestHealthLoading: selectors.isCommandRequestHealthLoading(state),
    commandRequestHealth: selectors.commandRequestHealth(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSiteHealth: () => dispatch(actions.getSiteHealth()),
    getCommandRequestHealth: () => dispatch(actions.getCommandRequestHealth()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteDashboard);
