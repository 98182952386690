import React, { Component } from 'react';
import update from 'immutability-helper';
import { Alert, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Accordion from '../../../../../../shared/components/Accordion';
import HelpIcon from '../../../../../components/help-icon/HelpIcon';
import InventoryColumnDropTarget from './InventoryColumnDropTarget';

class ColumnChooser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      helpVisible: false,
    };

    this.toggleHelp = this.toggleHelp.bind(this);
    this.addColumn = this.addColumn.bind(this);
    this.reorderColumns = this.reorderColumns.bind(this);
    this.removeColumn = this.removeColumn.bind(this);
  }

  toggleHelp() {
    this.setState({
      helpVisible: !this.state.helpVisible,
    });
  }

  addColumn(inventoryColumn) {
    const updatedInventoryFormat = update(this.props.selectedInventoryFormat, {
      columns: {
        $push: [inventoryColumn],
      },
    });
    this.props.updateSelectedFormat(this.updateColumnOrder(updatedInventoryFormat));
  }

  reorderColumns(dragIndex, hoverIndex) {
    const dragColumn = this.props.selectedInventoryFormat.columns[dragIndex];
    const updatedInventoryFormat = update(this.props.selectedInventoryFormat, {
      columns: {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragColumn]],
      },
    });
    this.props.updateSelectedFormat(this.updateColumnOrder(updatedInventoryFormat));
  }

  updateColumnOrder(inventoryFormat) {
    const updatedColumns = inventoryFormat.columns.map((current, index) => update(current, {
      columnOrder: {
        $set: index + 1,
      },
    }));
    return update(inventoryFormat, {
      columns: {
        $set: updatedColumns,
      },
    });
  }

  removeColumn(inventoryColumn) {
    const filteredColumns = this.props.selectedInventoryFormat.columns.filter(current => current.invColumnId !== inventoryColumn.invColumnId);
    const updatedInventoryFormat = update(this.props.selectedInventoryFormat, {
      columns: {
        $set: filteredColumns,
      },
    });
    this.props.updateSelectedFormat(updatedInventoryFormat);
  }

  render() {
    return (
      <div className="mt-4">
        <Row>
          <Col xs={12}>
            <Alert color="info" isOpen={this.state.helpVisible} toggle={this.toggleHelp}>
              Use this screen to select the columns that are included in this file format.  Drag
              columns from the categories on the right into the area on the left. Reorder the columns
              on the left by dragging them.  The top-to-bottom order of the columns shown below represents
              the left-to-right ordering of the columns in the export file.
              <br /><br />
              Be sure to include at least one <em>Identifier</em> column so the person or computer receiving
              the file can link each row in the file to a site.
            </Alert>
          </Col>
          <Col lg={6}>
            <h6 style={{ display: 'inline' }}>Selected fields</h6>
            <HelpIcon onClick={this.toggleHelp} className="ml-1" />
            <InventoryColumnDropTarget
              title="Selected columns"
              inventoryColumns={this.props.selectedInventoryFormat.columns}
              showExample={false}
              addColumn={this.addColumn}
              reorderColumns={this.reorderColumns}
              removeColumn={this.removeColumn}
            />
          </Col>
          <Col lg={6}>
            <h6>Available fields</h6>
            {this.props.inventoryColumnCategories.map((currentColumnCategory, index) => (
              <Accordion title={currentColumnCategory.name} initiallyOpen={index === 0} key={currentColumnCategory.id}>
                <InventoryColumnDropTarget inventoryColumns={currentColumnCategory.columns} />
              </Accordion>
            ))}
          </Col>
        </Row>
      </div>
    );
  }
}

ColumnChooser.propTypes = {
  inventoryColumnCategories: PropTypes.array.isRequired,
  selectedInventoryFormat: PropTypes.object,
  updateSelectedFormat: PropTypes.func.isRequired,
};

ColumnChooser.defaultProps = {
  selectedInventoryFormat: null,
};

export default withRouter(ColumnChooser);
