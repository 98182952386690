import React from 'react';
import PropTypes from 'prop-types';
import FilterItem from './FilterItem';

const FilterItemList = ({ items, textField, disabled, onRemoveItem }) => {
  if (!items || !items.length) {
    return null;
  }

  const handleRemoveItem = item => () => onRemoveItem(item);

  return items.map((current) => (
    <FilterItem
      key={current.id || current[textField]}
      item={current}
      textField={textField}
      className="mr-2 mb-2"
      disabled={disabled}
      onClose={handleRemoveItem(current)}
    />
  ));
};

FilterItemList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
    })
  ),
  textField: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onRemoveItem: PropTypes.func.isRequired,
};

FilterItemList.defaultProps = {
  items: [],
  disabled: false,
};

export default FilterItemList;
