export default {
  RECEIVE_FILTER: 'inventory_history/receive_filter',

  GET_HISTORY_STARTED: 'inventory_history/get_history_started',
  GET_HISTORY_FAILED: 'inventory_history/get_history_failed',
  RECEIVE_HISTORY: 'inventory_history/receive_history',

  GET_EXPORT_STARTED: 'inventory_history/get_export_started',
  GET_EXPORT_FAILED: 'inventory_history/get_export_failed',
  RECEIVE_EXPORT: 'inventory_history/receive_export',
};
