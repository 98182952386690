import React from 'react';
import PropTypes from 'prop-types';

const WallType = ({ registration }) => {
  let text = 'Unknown wall type';
  if (registration && registration.construction && registration.construction.wallType) {
    text = registration.construction.wallType;
  }
  return (<span>{text}</span>);
};

WallType.propTypes = {
  registration: PropTypes.object,
};

WallType.defaultProps = {
  registration: null,
};

export default WallType;
