import update from 'immutability-helper';
import orderBy from 'lodash/orderBy';
import IssueActionTypes from '../../IssueEditActionTypes';
import ActionTypes from './CommentActionTypes';
import { CommentOperationState } from './CommentConstants';

function buildComments(currentIssue) {
  return currentIssue.comments || [];
}

function addComment(state, comments) {
  if (!state.comments || !state.comments.length) {
    return update(state, {
      commentOperationState: {
        $set: CommentOperationState.Ready,
      },
      comments: {
        $set: comments,
      }
    });
  }

  const sortedComments = orderBy(comments, ['created'], ['desc']);
  return update(state, {
    commentOperationState: {
      $set: CommentOperationState.Ready,
    },
    comments: {
      $set: sortedComments,
    }
  });
}

const InitialState = {
  commentOperationState: CommentOperationState.Ready,
  comments: [],
};

export default function CommentReducer(state = InitialState, action) {
  switch (action.type) {
    case IssueActionTypes.CHANGE_ISSUE_STARTED:
      return Object.assign({}, state, {
        commentOperationState: CommentOperationState.Loading,
        comments: null,
      });
    case IssueActionTypes.CHANGE_ISSUE_SUCCESS:
      return Object.assign({}, state, {
        commentOperationState: CommentOperationState.Ready,
        comments: buildComments(action.currentIssue),
      });

    case ActionTypes.EDIT_COMMENT:
      return Object.assign({}, state, {
        commentOperationState: CommentOperationState.Editing,
      });
    case ActionTypes.CANCEL_EDIT_COMMENT:
      return Object.assign({}, state, {
        commentOperationState: CommentOperationState.Ready,
      });

    case ActionTypes.SAVE_COMMENT_STARTED:
      return Object.assign({}, state, {
        commentOperationState: CommentOperationState.Saving,
      });
    case ActionTypes.SAVE_COMMENT_SUCCESS:
      return addComment(state, action.comments);
    case ActionTypes.SAVE_COMMENT_FAILED:
      return Object.assign({}, state, {
        commentOperationState: CommentOperationState.Ready,
        error: action.error,
      });

    default:
      return state;
  }
}
