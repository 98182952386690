import { getListWithEtagAction } from '../../../shared/redux-helpers/ActionCreatorFactory';
import { SiteSummaryClient, SiteSummaryParams } from '../../../client';
import SiteListActionTypes from '../SiteListActionTypes';
import selectors, { SiteViewManagerSelectors } from '../SiteListSelectors';

const buildParams = currentState => ({
  [SiteSummaryParams.CustomerIds.name]: SiteViewManagerSelectors.selectedCustomerIds(currentState),
  [SiteSummaryParams.ConnectionTypes.name]: SiteViewManagerSelectors.selectedConnectionTypeIds(currentState),
  [SiteSummaryParams.ConnectionStatuses.name]: SiteViewManagerSelectors.selectedConnectionStatusIds(currentState),
  [SiteSummaryParams.SiteStates.name]: SiteViewManagerSelectors.selectedSiteStates(currentState),
  [SiteSummaryParams.SiteIds.name]: SiteViewManagerSelectors.selectedSiteIds(currentState),
  [SiteSummaryParams.SiteLabelIds.name]: SiteViewManagerSelectors.selectedSiteLabelIds(currentState),
});

export const getSites = getListWithEtagAction(selectors, SiteListActionTypes, buildParams, SiteViewManagerSelectors.apiFields, SiteSummaryClient.find);

export const changeSort = (sortColumnId, sortDirection) => ({ type: SiteListActionTypes.CHANGE_SORT, sortColumnId, sortDirection });

export const changePage = page => ({ type: SiteListActionTypes.CHANGE_PAGE, page });
