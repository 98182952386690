import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Badge, Button, Col, Row } from 'reactstrap';

import { TableStyles } from '../../../../../../../../shared/components/CanaryTable';
import CanaryTableAndSummary from '../../../../../../../../shared/components/CanaryTableAndSummary';
import ErrorMessage from '../../../../../../../../shared/components/error-message/ErrorMessage';
import DeleteButton from '../../../../../../../../shared/components/buttons/DeleteButton';
import * as navActions from '../../../../../actions';
import * as orgEditSelectors from '../../../OrganizationEditSelectors';
import * as selectors from '../GroupEditorSelectors';
import * as actions from '../actions';
import ConfirmModal from '../../../../../../../../shared/components/confirm-modal/ConfirmModal';


const headers = [
  {
    display: 'NAME',
    columnStyle: TableStyles.ColumnStyles.FIRST_COLUMN,
    width: '25%',
  },
  {
    display: 'MEMBERS',
    columnStyle: TableStyles.ColumnStyles.NONTEXT,
    width: '25%',
  },
  {
    display: '',
    columnStyle: TableStyles.ColumnStyles.NONTEXT,
    width: '25%',
  }
];

const InitialState = {
  confirmModalOpen: false,
  group: null,
};

class GroupsTable extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = InitialState;
    this.handleDelete = this.handleDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
  }

  handleDelete(group) {
    this.setState({
      confirmModalOpen: true,
      group,
    });
  }

  confirmDelete() {
    const group = this.state.group;
    this.setState(InitialState);
    this.props.deleteGroup(group);
  }

  cancelDelete() {
    this.setState(InitialState);
  }

  render() {
    const summary = `${this.props.groups.length} Groups`;
    const controls = <Button color="primary" size="sm" onClick={this.props.navToNewGroup}>ADD A GROUP</Button>;
    const data = this.props.groups.map((group) => ({
      metadata: {},
      columns: [
        {
          display: (<Button color="link" onClick={() => this.props.navToGroup(group)}>{group.name}</Button>),
          columnStyle: TableStyles.ColumnStyles.FIRST_COLUMN,
        },
        {
          display: (<Badge color="primary" pill>{(group.members && group.members.length) || 0}</Badge>),
          columnStyle: TableStyles.ColumnStyles.NONTEXT,
        },
        {
          display: (<DeleteButton onClick={() => this.handleDelete(group)} />),
          columnStyle: TableStyles.ColumnStyles.NONTEXT,
        },
      ],
    }));

    return (
      <div>
        <ErrorMessage title="Group update failed" error={this.props.error} />
        <Row>
          <Col sm={8}>
            <CanaryTableAndSummary summary={summary} header={headers} controls={controls} data={data} prefix="groups" />
          </Col>
        </Row>
        <ConfirmModal
          isOpen={this.state.confirmModalOpen}
          headerText="Delete group"
          body="Are you sure you want to delete this group?"
          onConfirm={this.confirmDelete}
          onCancel={this.cancelDelete}
        />
      </div>
    );
  }
}


GroupsTable.propTypes = {
  error: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    organizationId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  navToNewGroup: PropTypes.func.isRequired,
  navToGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
};

GroupsTable.defaultProps = {
  error: null,
};

function mapStateToProps(state) {
  return {
    error: orgEditSelectors.error(state),
    groups: selectors.groups(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    navToNewGroup: () => dispatch(navActions.navToNewGroup(ownProps.match.params.organizationId)),
    navToGroup: group => dispatch(navActions.navToGroupEditor(group)),
    deleteGroup: group => dispatch(actions.deleteGroup(group)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupsTable));
