import { createSocketMiddleware } from '../../SocketMiddleware';
import { refreshInventoryFailed, receiveSiteInventory } from './actions/InventoryCommandActions';
import { IntentNames, IntentRequestStatus } from '../sites/screens/commands/CommandConstants';
import selectors, { isInventoryPage } from './InventorySelectors';

let batch = [];
let timerId = null;

function siteInventoryUpdated(socketMessage, dispatch, getState) {
  if (!socketMessage || !socketMessage.length) {
    return;
  }

  const currentState = getState();
  if (selectors.isLoading(currentState) || selectors.isRefreshing(currentState) || !isInventoryPage(currentState)) {
    return;
  }

  if (timerId) {
    batch.push(socketMessage);
  }
  else {
    batch = [socketMessage];
    const dispatchCurrentBatch = () => {
      dispatch(receiveSiteInventory(batch));
      batch = [];
      timerId = null;
    };
    timerId = setTimeout(dispatchCurrentBatch, 1500);
  }
}

const socketMiddleware = createSocketMiddleware({
  siteInventoryUpdated,
  intentRequestFulfilled: (intentRequest, dispatch) => {
    if (intentRequest.status === IntentRequestStatus.Failed && intentRequest.intentName === IntentNames.GetInventory) {
      return dispatch(refreshInventoryFailed(intentRequest.siteId));
    }
    return Promise.resolve();
  }
});

export default {
  socketMiddleware,
};
