import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import update from 'immutability-helper/index';
import range from 'lodash/range';

import Days from './Days';
import MinsPastHour from './MinsPastHour';
import HourRange from './HourRange';
import Hour from './Hour';

const TimeTypes = {
  Hourly: 'Hourly',
  TwiceAnHour: 'TwiceAnHour',
  SpecificTime: 'SpecificTime',
};

const RangeTypes = {
  All: 'All',
  Range: 'Range',
};

const DEFAULT_STATE = {
  days: [0, 1, 2, 3, 4, 5, 6],
  timeType: TimeTypes.Hourly,
  hourlyMins: null,
  twiceAnHourMins1: null,
  twiceAnHourMins2: null,
  specificTimeHour: null,
  specificTimeMin: null,
  rangeType: RangeTypes.All,
  hourRangeStart: null,
  hourRangeEnd: null,
};


class ScheduleBuilder extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.state = DEFAULT_STATE;

    this.handleTimeTypeSelect = this.handleTimeTypeSelect.bind(this);
    this.handleDaySelect = this.handleDaySelect.bind(this);
    this.handleDayRemove = this.handleDayRemove.bind(this);
    this.handleHourlyMins = this.handleHourlyMins.bind(this);
    this.handleTwiceAnHourMins1 = this.handleTwiceAnHourMins1.bind(this);
    this.handleTwiceAnHourMins2 = this.handleTwiceAnHourMins2.bind(this);
    this.handleSpecificTimeHour = this.handleSpecificTimeHour.bind(this);
    this.handleSpecificTimeMin = this.handleSpecificTimeMin.bind(this);
    this.handleRangeTypeSelect = this.handleRangeTypeSelect.bind(this);
    this.handleHourRangeUpdate = this.handleHourRangeUpdate.bind(this);

    this.emitScheduleChangeEvent = this.emitScheduleChangeEvent.bind(this);
    this.isValid = this.isValid.bind(this);
    this.isTimeValid = this.isTimeValid.bind(this);
    this.isRangeValid = this.isRangeValid.bind(this);
  }

  handleTimeTypeSelect(timeType) {
    this.setState(update(this.state, {
      timeType: {
        $set: timeType,
      },
    }), this.emitScheduleChangeEvent);
  }

  handleDaySelect(dayOfWeekNumber) {
    this.setState(update(this.state, {
      days: {
        $push: [dayOfWeekNumber],
      },
    }), this.emitScheduleChangeEvent);
  }

  handleDayRemove(dayOfWeekNumber) {
    const index = this.state.days.indexOf(dayOfWeekNumber);
    this.setState(update(this.state, {
      days: {
        $splice: [[index, 1]],
      },
    }), this.emitScheduleChangeEvent);
  }

  handleHourlyMins(hourlyMins) {
    this.setState(update(this.state, {
      hourlyMins: {
        $set: hourlyMins,
      },
    }), this.emitScheduleChangeEvent);
  }

  handleTwiceAnHourMins1(twiceAnHourMins1) {
    this.setState(update(this.state, {
      twiceAnHourMins1: {
        $set: twiceAnHourMins1,
      },
    }), this.emitScheduleChangeEvent);
  }

  handleTwiceAnHourMins2(twiceAnHourMins2) {
    this.setState(update(this.state, {
      twiceAnHourMins2: {
        $set: twiceAnHourMins2,
      },
    }), this.emitScheduleChangeEvent);
  }

  handleSpecificTimeHour(specificTimeHour) {
    this.setState(update(this.state, {
      specificTimeHour: {
        $set: specificTimeHour,
      },
    }), this.emitScheduleChangeEvent);
  }

  handleSpecificTimeMin(specificTimeMin) {
    this.setState(update(this.state, {
      specificTimeMin: {
        $set: specificTimeMin,
      },
    }), this.emitScheduleChangeEvent);
  }

  handleRangeTypeSelect(rangeType) {
    this.setState(update(this.state, {
      rangeType: {
        $set: rangeType,
      },
    }), this.emitScheduleChangeEvent);
  }

  handleHourRangeUpdate(startHour, endHour) {
    this.setState(update(this.state, {
      hourRangeStart: {
        $set: startHour,
      },
      hourRangeEnd: {
        $set: endHour,
      },
    }), this.emitScheduleChangeEvent);
  }

  emitScheduleChangeEvent() {
    if (this.isValid()) {
      const cronExpression = this.buildCronExpression();
      this.props.onScheduleChange(cronExpression);
    }
    else {
      this.props.onScheduleChange(null);
    }
  }

  isValid() {
    return this.state.days.length && this.isTimeValid() && this.isRangeValid();
  }

  isTimeValid() {
    const state = this.state;
    return (state.timeType === TimeTypes.Hourly && state.hourlyMins !== null) ||
      (state.timeType === TimeTypes.TwiceAnHour && state.twiceAnHourMins1 !== null && state.twiceAnHourMins2 !== null) ||
      (state.timeType === TimeTypes.SpecificTime && state.specificTimeHour !== null && state.specificTimeMin !== null);
  }

  isRangeValid() {
    const state = this.state;

    return state.rangeType === RangeTypes.All ||
      (state.hourRangeStart !== null && state.hourRangeEnd !== null) ||
      state.timeType === TimeTypes.SpecificTime;
  }

  buildCronExpression() {
    const dayComponent = this.buildDayCronComponent();
    const hourComponent = this.buildHourCronComponent();
    const minComponent = this.buildMinCronComponent();

    return {
      days: dayComponent.arrayValue,
      hours: hourComponent.arrayValue,
      mins: minComponent.arrayValue,
      stringValue: `${minComponent.stringValue} ${hourComponent.stringValue} * * ${dayComponent.stringValue}`,
    };
  }

  buildDayCronComponent() {
    const daysArray = this.state.days.sort((first, second) => first - second);
    const dayComponent = {
      arrayValue: daysArray,
    };

    if (this.state.days.length === 7) {
      dayComponent.stringValue = '*';
    }
    else {
      dayComponent.stringValue = daysArray.join(',');
    }

    return dayComponent;
  }

  buildHourCronComponent() {
    const state = this.state;
    const hourComponent = {};

    if (state.timeType === TimeTypes.SpecificTime) {
      hourComponent.arrayValue = [state.specificTimeHour];
      hourComponent.stringValue = state.specificTimeHour;
    }
    else if (state.rangeType === RangeTypes.All) {
      hourComponent.arrayValue = range(0, 24);
      hourComponent.stringValue = '*';
    }
    else {
      hourComponent.arrayValue = range(state.hourRangeStart, state.hourRangeEnd + 1);

      if (state.hourRangeEnd - state.hourRangeStart > 2) {
        hourComponent.stringValue = `${state.hourRangeStart}-${state.hourRangeEnd}`;
      }
      else {
        hourComponent.stringValue = hourComponent.arrayValue.join(',');
      }
    }

    return hourComponent;
  }

  buildMinCronComponent() {
    const state = this.state;
    const minComponent = {};

    if (state.timeType === TimeTypes.Hourly) {
      minComponent.arrayValue = [state.hourlyMins];
      minComponent.stringValue = state.hourlyMins;
    }
    else if (state.timeType === TimeTypes.TwiceAnHour) {
      minComponent.arrayValue = [state.twiceAnHourMins1, state.twiceAnHourMins2];
      minComponent.stringValue = `${state.twiceAnHourMins1},${state.twiceAnHourMins2}`;
    }
    else {
      minComponent.arrayValue = [state.specificTimeMin];
      minComponent.stringValue = state.specificTimeMin;
    }
    return minComponent;
  }

  renderTimeRow() {
    return (
      <Row>
        <Col md={2}>
          <span>Time</span>
        </Col>
        <Col md={10}>
          <FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="timeRadio"
                  checked={this.state.timeType === TimeTypes.Hourly}
                  onChange={() => this.handleTimeTypeSelect(TimeTypes.Hourly)}
                />
                {' '}
                Hourly at
              </Label>
              <MinsPastHour value={this.state.hourlyMins} onMinSelect={this.handleHourlyMins} disabled={this.state.timeType !== TimeTypes.Hourly} />
              minutes past the hour
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="timeRadio"
                  checked={this.state.timeType === TimeTypes.TwiceAnHour}
                  onChange={() => this.handleTimeTypeSelect(TimeTypes.TwiceAnHour)}
                />
                {' '}
                Twice an hour at
              </Label>
              <MinsPastHour
                value={this.state.twiceAnHourMins1}
                onMinSelect={this.handleTwiceAnHourMins1}
                disabled={this.state.timeType !== TimeTypes.TwiceAnHour}
              />
              minutes past the hour and again at
              <MinsPastHour
                value={this.state.twiceAnHourMins2}
                onMinSelect={this.handleTwiceAnHourMins2}
                disabled={this.state.timeType !== TimeTypes.TwiceAnHour}
              />
              minutes past the hour
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="timeRadio"
                  checked={this.state.timeType === TimeTypes.SpecificTime}
                  onChange={() => this.handleTimeTypeSelect(TimeTypes.SpecificTime)}
                />
                {' '}
                At a particular time
              </Label>
              <Hour
                value={this.state.specificTimeHour}
                onHourSelect={this.handleSpecificTimeHour}
                disabled={this.state.timeType !== TimeTypes.SpecificTime}
              />
              :
              <MinsPastHour
                value={this.state.specificTimeMin}
                onMinSelect={this.handleSpecificTimeMin}
                disabled={this.state.timeType !== TimeTypes.SpecificTime}
              />
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderRangeRow() {
    const isRangeDisabled = this.state.timeType === TimeTypes.SpecificTime;

    return (
      <Row>
        <Col md={2}>
          <span>Between</span>
        </Col>
        <Col md={10}>
          <FormGroup>
            <FormGroup check className={classnames({ 'text-muted': isRangeDisabled })}>
              <Label check>
                <Input
                  type="radio"
                  name="betweenRadio"
                  checked={this.state.rangeType === RangeTypes.All}
                  onChange={() => this.handleRangeTypeSelect(RangeTypes.All)}
                  disabled={isRangeDisabled}
                />
                {' '}
                At all hours of the day
              </Label>
            </FormGroup>
            <FormGroup check className={classnames({ 'text-muted': isRangeDisabled })}>
              <Label check>
                <Input
                  type="radio"
                  name="betweenRadio"
                  checked={this.state.rangeType === RangeTypes.Range}
                  onChange={() => this.handleRangeTypeSelect(RangeTypes.Range)}
                  disabled={isRangeDisabled}
                />
                {' '}
                Between
              </Label>
              <HourRange
                startHour={this.state.hourRangeStart}
                endHour={this.state.hourRangeEnd}
                onRangeUpdate={this.handleHourRangeUpdate}
                disabled={this.state.rangeType !== RangeTypes.Range}
              />
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Row>
        <Col md={12}>
          <Form>
            <Days selectedDays={this.state.days} onDaySelect={this.handleDaySelect} onDayRemove={this.handleDayRemove} />
            {this.renderTimeRow()}
            {this.renderRangeRow()}
          </Form>
        </Col>
      </Row>
    );
  }
}

ScheduleBuilder.propTypes = {
  onScheduleChange: PropTypes.func.isRequired,
};

ScheduleBuilder.defaultProps = {
  children: null,
};

export default ScheduleBuilder;
