import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { track } from '../../../../../../shared/analytics';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import FolderDropdownContainer from '../folder-dropdown/FolderDropdownContainer';
import UploadDrop from './UploadDrop';
import { FileStatus, Steps } from './UploadConstants';
import * as selectors from './UploadSelectors';
import * as docActions from '../../actions';
import * as actions from './UploadActions';
import FileIcon from '../files/FileIcon';

const FileUploadIndicator = ({ file }) => {
  let icon = <div className="spinner-border spinner-border-sm mr-1 invisible" />;
  if (file.status === FileStatus.InProgress) {
    icon = <div className="spinner-border spinner-border-sm mr-1" />;
  }
  else if (file.status === FileStatus.Complete) {
    icon = <FontAwesomeIcon size="1x" icon={faCheck} className="mr-2" />;
  }
  else if (file.status === FileStatus.Failed) {
    icon = <FontAwesomeIcon size="1x" icon={faTimes} className="mr-2" />;
  }

  return (
    <div key={file.filename}>
      {icon}
      {file.filename}
    </div>
  );
};

FileUploadIndicator.propTypes = {
  file: PropTypes.shape({
    status: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
  }).isRequired,
};


class UploadButton extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleStart = this.handleStart.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
  }

  handleStart() {
    track('Document', {
      Component: 'Document upload button',
      'Document action': 'Open upload modal',
    });
    this.props.ensureSiteFolders().then(siteFolders => {
      // try to preset the destination folder
      let destinationFolder = null;
      if (this.props.defaultFolderName) {
        destinationFolder = siteFolders.find(current => current.folderName === this.props.defaultFolderName);
      }

      // mark the first issue as selected
      const issues = [];
      if (this.props.issues && this.props.issues.length) {
        issues.push(this.props.issues[0]);
      }

      return this.props.start([], destinationFolder, issues, this.props.mviReport);
    });
  }

  handleFileDrop(files) {
    if (files && files.length) {
      this.props.fileDrop(files);
    }
  }

  isModalOpen() {
    return this.props.currentStep === Steps.ModalHome
      || this.props.currentStep === Steps.SelectFolder
      || this.props.currentStep === Steps.Uploading
      || this.props.currentStep === Steps.UploadFailed
      || this.props.currentStep === Steps.UploadSuccess;
  }

  isCancelDisabled() {
    return this.props.currentStep === Steps.Uploading
      || this.props.currentStep === Steps.UploadFailed
      || this.props.currentStep === Steps.UploadSuccess;
  }

  isUploadDisabled() {
    return this.props.disabled || this.props.currentStep !== Steps.SelectFolder || !this.props.destinationFolder;
  }

  render() {
    return (
      <>
        {this.renderButton()}
        <Modal isOpen={this.isModalOpen()} size="lg">
          <ModalHeader>
            Upload
          </ModalHeader>
          {this.renderModalBody()}
          <ModalFooter>
            <Button color="primary" size="sm" outline onClick={this.props.reset} disabled={this.isCancelDisabled()}>Cancel</Button>
            {' '}
            {this.renderModalActionButton()}
          </ModalFooter>
        </Modal>
      </>
    );
  }

  renderButton() {
    return (
      <Button
        color={this.props.buttonColor}
        size={this.props.buttonSize}
        outline={this.props.buttonOutline}
        className={this.props.className}
        onClick={this.handleStart}
        disabled={this.props.currentStep !== Steps.Initial || this.props.disabled}
      >
        Upload
        {this.props.buttonIcon ? <FontAwesomeIcon icon={this.props.buttonIcon} className="ml-1" /> : null}
      </Button>
    );
  }

  renderModalActionButton() {
    if (this.props.currentStep === Steps.UploadFailed || this.props.currentStep === Steps.UploadSuccess) {
      return <Button color="primary" size="sm" onClick={this.props.reset}>Close</Button>;
    }

    return <Button color="primary" size="sm" onClick={this.props.upload} disabled={this.isUploadDisabled()}>Upload</Button>;
  }

  renderModalBody() {
    switch (this.props.currentStep) {
      case Steps.ModalHome:
        return this.renderModalHome();
      case Steps.SelectFolder:
        return this.renderModalSelectFolder();
      case Steps.Uploading:
      case Steps.UploadSuccess:
      case Steps.UploadFailed:
        return this.renderModalUploading();
      default:
        return null;
    }
  }

  renderModalHome() {
    return (
      <ModalBody className="d-flex align-items-center justify-content-around">
        <UploadDrop onFileDrop={this.handleFileDrop} />
      </ModalBody>
    );
  }

  renderModalSelectFolder() {
    const hasIssues = !!(this.props.issues && this.props.issues.length);
    const title = this.props.files.length > 1 ? `${this.props.files.length} Files Selected:` : '1 File Selected:';
    return (
      <ModalBody>
        <div>{title}</div>
        {this.props.files.map(file => (
          <div key={file.filename} className="ml-1">
            <FileIcon extension={file.extension} className="mr-1" />
            {file.filename}
          </div>
        ))}

        <Form className="mt-2">
          <FormGroup>
            <Label for="folder">Destination Folder</Label>
            <FolderDropdownContainer onChange={this.props.setDestinationFolder} value={this.props.destinationFolder} />
          </FormGroup>
          {hasIssues ? (
            <>
              <span className="d-block mb-2">Attach to Issues</span>
              {this.props.issues.map(issue => (
                <FormGroup check key={issue.id}>
                  <Label check>
                    <Input type="checkbox" checked={this.isIssueSelected(issue)} onChange={() => this.props.toggleIssueSelect(issue)} />
                    {' '}
                    {`Issue ${issue.id}: ${issue.description}`}
                  </Label>
                </FormGroup>
              ))}
            </>
          ) : null}
        </Form>
      </ModalBody>
    );
  }

  isIssueSelected(issue) {
    return this.props.issueIds.indexOf(issue.id) !== -1;
  }

  renderModalUploading() {
    const title = `Uploading to folder: ${this.props.destinationFolder.folderName}`;
    return (
      <ModalBody>
        <ErrorMessage error={this.props.error} />
        <h6>{title}</h6>
        <div className="ml-3">
          {this.props.files.map(current => <FileUploadIndicator key={current.filename} file={current} />)}
        </div>
      </ModalBody>
    );
  }
}

UploadButton.propTypes = {
  // public api
  issues: PropTypes.array,
  mviReport: PropTypes.object,
  defaultFolderName: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonOutline: PropTypes.bool,
  buttonIcon: PropTypes.any,

  // internal api
  currentStep: PropTypes.string.isRequired,
  error: PropTypes.object,
  files: PropTypes.array,
  destinationFolder: PropTypes.object,
  issueIds: PropTypes.array,
  ensureSiteFolders: PropTypes.func.isRequired,
  start: PropTypes.func.isRequired,
  fileDrop: PropTypes.func.isRequired,
  setDestinationFolder: PropTypes.func.isRequired,
  toggleIssueSelect: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

UploadButton.defaultProps = {
  issues: [],
  mviReport: null,
  defaultFolderName: null,
  buttonColor: 'primary',
  buttonSize: 'sm',
  buttonOutline: true,
  buttonIcon: null,
  error: null,
  files: [],
  destinationFolder: null,
  issueIds: [],
  className: null,
  disabled: false,
};

function mapStateToProps(state) {
  return {
    currentStep: selectors.step(state),
    error: selectors.error(state),
    files: selectors.files(state),
    destinationFolder: selectors.destinationFolder(state),
    issueIds: selectors.issueIds(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ensureSiteFolders: () => dispatch(docActions.ensureSiteFolders()),
    start: (files, destinationFolder, issues, mviReport) => dispatch(actions.start(files, destinationFolder, issues, mviReport)),
    fileDrop: files => dispatch(actions.fileDrop(files)),
    setDestinationFolder: destinationFolder => dispatch(actions.setDestinationFolder(destinationFolder)),
    toggleIssueSelect: issue => dispatch(actions.toggleIssueSelect(issue)),
    upload: () => {
      track('Document', {
        Component: 'Document upload button',
        'Document action': 'Upload',
      });
      return dispatch(actions.upload());
    },
    reset: () => dispatch(actions.reset())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadButton);
