import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import { IssuePriorities, IssuePriorityLabels } from '../../AppConstants';

const priorityClasses = {
  [IssuePriorities.LOW]: 'secondary',
  [IssuePriorities.NORMAL]: 'warning',
  [IssuePriorities.EMERGENCY]: 'danger',
};

const PriorityIndicator = ({ className, priority, long }) => {
  const text = long ? `${IssuePriorityLabels[priority]} Priority` : IssuePriorityLabels[priority];
  return <Badge className={className} color={priorityClasses[priority]}>{text}</Badge>;
};

PriorityIndicator.propTypes = {
  className: PropTypes.string,
  priority: PropTypes.string,
  long: PropTypes.bool,
};

PriorityIndicator.defaultProps = {
  className: null,
  priority: IssuePriorities.LOW,
  long: false,
};

export default PriorityIndicator;
