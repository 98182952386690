import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as selectors from '../AuthenticationSelectors';

class HasPermission extends Component {
  render() {
    if (this.props.isAuthorized) {
      return this.props.children;
    }
    return null;
  }
}

HasPermission.propTypes = {
  customerId: PropTypes.number,
  siteId: PropTypes.number,
  permission: PropTypes.number,
  children: PropTypes.element,
  isAuthorized: PropTypes.bool,
};

HasPermission.defaultProps = {
  customerId: null,
  siteId: null,
  permission: null,
  children: null,
  isAuthorized: false,
};

function mapStateToProps(state, ownProps) {
  return {
    isAuthorized: selectors.hasPermission(state, ownProps.customerId, ownProps.siteId, ownProps.permission)
  };
}

export default connect(mapStateToProps)(HasPermission);
