import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Button, Modal, ModalHeader, ModalFooter, FormGroup, Input, ModalBody, Form, Label } from 'reactstrap';

import {track} from '../../../../shared/analytics';
import ErrorMessage from '../../../../shared/components/error-message/ErrorMessage';
import SingleSiteInput from '../../../../shared/components/site-picker/containers/SingleSiteInput';
import PriorityDropdown from '../../../../shared/components/PriorityDropdown';
import OrganizationPermissions from '../../../../organization-permissions';
import { Features } from '../../../../AppConstants';
import * as authSelectors from '../../../../authentication/AuthenticationSelectors';
import * as actions from './NewIssueActions';
import * as selectors from './NewIssueSelectors';

class NewIssueModal extends Component {
  static getDerivedStateFromProps(props, state) {
    if (!props.open && state.editingSite) {
      return { editingSite: false };
    }
    return null;
  }

  constructor(initialProps) {
    super(initialProps);
    this.state = {
      editingSite: false,
    };
    this.handleSiteEditStart = this.handleSiteEditStart.bind(this);
    this.handleSiteSelect = this.handleSiteSelect.bind(this);
    this.handleSiteEditEnd = this.handleSiteEditEnd.bind(this);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleAnalysisChange = this.handleAnalysisChange.bind(this);
    this.handleRecommendationsChange = this.handleRecommendationsChange.bind(this);
    this.handleCreateIssue = this.handleCreateIssue.bind(this);
    this.isSaveDisabled = this.isSaveDisabled.bind(this);
  }

  handleSiteEditStart() {
    this.setState({ editingSite: true });
  }

  handleSiteSelect(site) {
    const issueEdit = update(this.props.issue, {
      site: {
        $set: site,
      }
    });
    this.props.edit(issueEdit);
  }

  handleSiteEditEnd() {
    this.setState({ editingSite: false });
  }

  handlePriorityChange(priority) {
    const issueEdit = update(this.props.issue, {
      priority: {
        $set: priority,
      }
    });
    this.props.edit(issueEdit);
  }

  handleDescriptionChange(e) {
    const issueEdit = update(this.props.issue, {
      description: {
        $set: e.target.value,
      }
    });
    this.props.edit(issueEdit);
  }

  handleAnalysisChange(e) {
    const issueEdit = update(this.props.issue, {
      analysis: {
        $set: e.target.value,
      }
    });
    this.props.edit(issueEdit);
  }

  handleRecommendationsChange(e) {
    const issueEdit = update(this.props.issue, {
      recommendations: {
        $set: e.target.value,
      }
    });
    this.props.edit(issueEdit);
  }

  handleCreateIssue() {
    this.props.createIssue().then(issue => {
      if (this.props.onIssueCreated) {
        this.props.onIssueCreated(issue);
      }
    });
  }

  isSaveDisabled() {
    return !this.props.issue
      || !this.props.issue.site
      || this.state.editingSite
      || !this.props.issue.description
      || this.props.isSaving;
  }

  render() {
    if (!this.props.hasPermissions) {
      return null;
    }

    return (
      <Modal isOpen={this.props.open} toggle={this.props.cancel} size="lg">
        <ModalHeader toggle={this.props.cancel}>Create New Issue</ModalHeader>
        <ModalBody>
          <ErrorMessage error={this.props.error} title="Failed to create issue" />
          {this.renderForm()}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={this.props.cancel} disabled={this.props.isSaving}>Cancel</Button>
          {' '}
          <Button color="primary" size="sm" onClick={this.handleCreateIssue} disabled={this.isSaveDisabled()}>Create Issue</Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderForm() {
    if (!this.props.issue) {
      return null;
    }

    return (
      <Form>
        <FormGroup>
          <Label for="newIssueSitePicker">Site</Label>
          {this.renderSiteInput()}
        </FormGroup>
        <FormGroup>
          <Label for="priority">Priority</Label>
          <PriorityDropdown priority={this.props.issue.priority} onPriorityChange={this.handlePriorityChange} />
        </FormGroup>

        <FormGroup>
          <Label for="description">Issue Name</Label>
          <Input
            id="description"
            name="description"
            type="text"
            placeholder="Name"
            value={this.props.issue.description}
            onChange={this.handleDescriptionChange}
          />
        </FormGroup>

        <FormGroup>
          <Label for="analysis">Analysis</Label>
          <Input
            id="analysis"
            name="analysis"
            type="textarea"
            rows="4"
            placeholder="Analysis..."
            value={this.props.issue.analysis}
            onChange={this.handleAnalysisChange}
          />
        </FormGroup>

        <FormGroup>
          <Label for="recommendations">Troubleshooting</Label>
          <Input
            id="recommendations"
            name="recommendations"
            type="textarea"
            className="form-control"
            rows="4"
            placeholder="Troubleshooting..."
            value={this.props.issue.recommendations}
            onChange={this.handleRecommendationsChange}
          />
        </FormGroup>
      </Form>
    );
  }

  renderSiteInput() {
    if (this.props.isSiteEditDisabled) {
      return (
        <Input id="newIssueSitePicker" name="newIssueSitePicker" type="text" value={this.props.issue.site.nickname} disabled />
      );
    }

    return (
      <SingleSiteInput
        id="newIssueSitePicker"
        site={this.props.issue.site}
        onEditStart={this.handleSiteEditStart}
        onEditEnd={this.handleSiteEditEnd}
        onSiteSelect={this.handleSiteSelect}
        feature={Features.AlarmManagement}
        groupByCustomer
      />
    );
  }
}

NewIssueModal.propTypes = {
  // public properties
  onIssueCreated: PropTypes.func,

  // internal stuff
  open: PropTypes.bool.isRequired,
  isSiteEditDisabled: PropTypes.bool.isRequired,
  issue: PropTypes.object,
  isSaving: PropTypes.bool.isRequired,
  error: PropTypes.object,
  hasPermissions: PropTypes.bool.isRequired,
  edit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  createIssue: PropTypes.func.isRequired,
};

NewIssueModal.defaultProps = {
  onIssueCreated: null,
  issue: null,
  error: null,
};

function mapStateToProps(state) {
  return {
    open: selectors.isModalOpen(state),
    isSiteEditDisabled: selectors.isSiteEditDisabled(state),
    issue: selectors.issue(state),
    isSaving: selectors.isSaving(state),
    error: selectors.error(state),
    hasPermissions: authSelectors.hasPermissionAnySite(state, OrganizationPermissions.IssueEdit),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    edit: issue => dispatch(actions.editIssue(issue)),
    cancel: () => dispatch(actions.cancel()),
    createIssue: () => {
      track('Issue', {
        Component: 'New issue',
        'Issue action': 'Create issue',
      });
      return dispatch(actions.createIssue());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewIssueModal);
