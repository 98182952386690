export default {
  GET_LIST_STARTED: 'ingest-failures/list/get-list-started',
  RECEIVE_LIST: 'ingest-failures/list/receive-list',
  GET_LIST_FAILED: 'ingest-failures/list/get-list-failed',
  SET_LIST_FILTER: 'ingest-failures/list/set-filter',

  GET_ITEM_STARTED: 'ingest-failures/item/get-item-started',
  RECEIVE_ITEM: 'ingest-failures/item/receive-item',
  GET_ITEM_FAILED: 'ingest-failures/item/get-item-failed',

  BEGIN_ITEM_EDIT: 'ingest-failures/item/begin-edit',
  EDIT_ITEM: 'ingest-failures/item/edit',
  CANCEL_ITEM_EDIT: 'ingest-failures/item/cancel-edit',

  SAVE_ITEM_STARTED: 'ingest-failures/item/save-started',
  SAVE_ITEM_SUCCESS: 'ingest-failures/item/save-success',
  SAVE_ITEM_FAILED: 'ingest-failures/item/save-item-failed',

  DELETE_ITEM_STARTED: 'ingest-failures/item/delete-started',
  DELETE_ITEM_SUCCESS: 'ingest-failures/item/delete-success',
  DELETE_ITEM_FAILED: 'ingest-failures/item/delete-failed',
};
