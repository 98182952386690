import { push } from 'react-router-redux';

const NEW = 'new';

export function navToOrganization(organization, edit) {
  return (dispatch, getState) => {
    const state = getState();

    const id = organization.id || organization;
    const currentPath = state.router.location.pathname;
    const newPath = edit ? `/admin/organizations/${id}/edit` : `/admin/organizations/${id}`;

    if (currentPath !== newPath) {
      dispatch(push(newPath));
    }
  };
}

export function navToNewOrganization() {
  return navToOrganization(NEW);
}

export function navToOrganizationList() {
  return (dispatch) => {
    dispatch(push('/admin/organizations'));
  };
}

export function navToSiteList(orgId) {
  return (dispatch) => {
    const actualOrgId = orgId.id || orgId;
    dispatch(push(`/admin/organizations/${actualOrgId}/sites`));
  };
}

export function navToSiteEditor(site) {
  return (dispatch) => {
    dispatch(push(`/admin/organizations/${site.customerId}/sites/${site.id}`));
  };
}

export function navToSiteConnection(site) {
  return (dispatch) => {
    dispatch(push(`/admin/organizations/${site.customerId}/sites/${site.id}/connection`));
  };
}

export function navToNewSite(orgId) {
  return (dispatch) => {
    const actualOrgId = orgId.id || orgId;
    dispatch(push(`/admin/organizations/${actualOrgId}/sites/new`));
  };
}

export function navToUserList(orgId) {
  return (dispatch) => {
    const actualOrgId = orgId.id || orgId;
    dispatch(push(`/admin/organizations/${actualOrgId}/users`));
  };
}

export function navToUserEditor(orgId, userId) {
  return (dispatch) => {
    const actualOrgId = orgId.id || orgId;
    const actualUserId = userId.id || userId;
    dispatch(push(`/admin/organizations/${actualOrgId}/users/${actualUserId}`));
  };
}

export function navToNewUser(orgId) {
  return (dispatch) => {
    const actualOrgId = orgId.id || orgId;
    dispatch(push(`/admin/organizations/${actualOrgId}/users/new`));
  };
}

export function navToGroupList(orgId) {
  return (dispatch) => {
    const actualOrgId = orgId.id || orgId;
    dispatch(push(`/admin/organizations/${actualOrgId}/groups`));
  };
}

export function navToGroupEditor(group) {
  return (dispatch) => {
    dispatch(push(`/admin/organizations/${group.organizationId}/groups/${group.id}`));
  };
}

export function navToNewGroup(orgId) {
  return (dispatch) => {
    const actualOrgId = orgId.id || orgId;
    dispatch(push(`/admin/organizations/${actualOrgId}/groups/new`));
  };
}
