import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tag from '../../../../Tag';

const MaxLength = 30;

const FilterItem = ({ item, textField, className, disabled, onClose }) => {
  let shortText = item[textField];
  if (shortText && shortText.length && shortText.length > MaxLength) {
    shortText = shortText.slice(0, MaxLength - 3) + '...';
  }
  const classes = classNames('text-nowrap', className);
  return <Tag text={shortText} className={classes} disabled={disabled} onClose={() => onClose(item)} />;
};

FilterItem.propTypes = {
  item: PropTypes.object.isRequired,
  textField: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

FilterItem.defaultProps = {
  className: null,
  disabled: false,
};

export default FilterItem;
