import update from 'immutability-helper';

import { DocumentClient } from '../../../../../client';
import * as siteSelectors from '../../../SiteSelectors';
import { createSiteAction } from '../../../SiteActionCreatorFactory';
import ActionTypes from '../SiteDocumentActionTypes';
import * as selectors from '../SiteDocumentSelectors';

export const getSiteFolders = createSiteAction(selectors.isLoading, ActionTypes.GET_SITE_FOLDERS_STARTED, ActionTypes.RECEIVE_SITE_FOLDERS, ActionTypes.GET_SITE_FOLDERS_FAILED, DocumentClient.getFolders);

export function ensureSiteFolders() {
  return (dispatch, getState) => {
    const folders = selectors.folders(getState());
    if (folders && folders.length) {
      return Promise.resolve(folders);
    }

    return dispatch(getSiteFolders());
  };
}

export function changeFolder(folder) {
  return { type: ActionTypes.CHANGE_FOLDER, folder };
}

export function selectSingleItem(item) {
  return { type: ActionTypes.SELECT_SINGLE_ITEM, item };
}

export function toggleItemSelect(item) {
  return { type: ActionTypes.TOGGLE_ITEM_SELECT, item };
}

export function selectAllItems(checked) {
  return { type: ActionTypes.SELECT_ALL_ITEMS, checked };
}

export function beginDocDrag(doc) {
  return { type: ActionTypes.BEGIN_DOC_DRAG, doc };
}

const editDoc = createSiteAction(selectors.isEditing, ActionTypes.EDIT_DOC_STARTED, ActionTypes.EDIT_DOC_SUCCESS, ActionTypes.EDIT_DOC_FAILED, DocumentClient.updateDocument, 'document');

export function moveDocuments(folder) {
  return ((dispatch, getState) => {
    const selectedDocs = selectors.selectedDocuments(getState());
    let promise = Promise.resolve();
    selectedDocs.forEach(doc => {
      promise = promise.then(() => {
        const updatedDocument = update(doc, {
          folderId: {
            $set: folder.id,
          }
        });
        return dispatch(editDoc(updatedDocument));
      });
    });
    return promise;
  });
}

export function renameDocument(doc, filename) {
  const updatedDocument = update(doc, {
    filename: {
      $set: filename,
    }
  });
  return editDoc(updatedDocument);
}

export function changeSite(doc, newSite) {
  const siteId = newSite.id || newSite;
  const updatedDocument = update(doc, {
    siteId: {
      $set: siteId,
    }
  });
  return editDoc(updatedDocument);
}

export function removeIssue(doc, issue) {
  const issueId = issue.id || issue;
  const updatedDocument = update(doc, {
    issues: {
      $set: doc.issues.filter(current => current.id !== issueId),
    }
  });
  return editDoc(updatedDocument);
}

export function removeMviReport(doc, mviReport) {
  const mviReportId = mviReport.id || mviReport;
  const mviReports = doc.mviReports || [];
  const updatedDocument = update(doc, {
    mviReports: {
      $set: mviReports.filter(current => current.id !== mviReportId),
    }
  });
  return editDoc(updatedDocument);
}

export function downloadSelectedItems() {
  return (dispatch, getState) => {
    const currentState = getState();
    const site = siteSelectors.currentSite(currentState);
    if (!site || selectors.isDownloading(currentState)) {
      return Promise.resolve();
    }

    const selectedFolderIds = selectors.selectedSubFolders(currentState).map(current => current.id);
    const selectedDocIds = selectors.selectedDocuments(currentState).map(current => current.id);
    if (!selectedFolderIds.length && !selectedDocIds.length) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.DOWNLOAD_STARTED });
    return DocumentClient.download(site, selectedFolderIds, selectedDocIds)
      .then(url => {
        dispatch({ type: ActionTypes.DOWNLOAD_SUCCESS });
        window.open(url, '_blank');
      })
      .catch(error => {
        dispatch({ type: ActionTypes.DOWNLOAD_FAILED, error });
      });
  };
}

export function deleteSelectedDocuments() {
  return (dispatch, getState) => {
    const currentState = getState();
    const site = siteSelectors.currentSite(currentState);
    if (!site || selectors.isDeleting(currentState)) {
      return Promise.resolve();
    }

    const selectedFolderIds = selectors.selectedSubFolders(currentState).map(current => current.id);
    const selectedDocs = selectors.selectedDocuments(currentState);
    if (selectedFolderIds.length || !selectedDocs.length) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.DELETE_STARTED });
    return DocumentClient.bulk(site, selectedDocs)
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_SUCCESS, documents: selectedDocs });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.DELETE_FAILED, error });
      });
  };
}

export function deleteDocument(doc) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_STARTED });
    return DocumentClient.bulk(doc.siteId, [doc])
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_SUCCESS, documents: [doc] });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.DELETE_FAILED, error });
      });
  };
}

export function deleteDocuments(documents) {
  return (dispatch) => {
    if (!documents || !documents.length || !documents[0].siteId) {
      return Promise.resolve();
    }
    const siteId = documents[0].siteId;

    dispatch({ type: ActionTypes.DELETE_STARTED });
    return DocumentClient.bulk(siteId, documents)
      .then(() => {
        dispatch({ type: ActionTypes.DELETE_SUCCESS, documents });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.DELETE_FAILED, error });
      });
  };
}

export function navToDocument(document) {
  return (dispatch, getState) => {
    if (document) {
      const siteId = document.siteId || siteSelectors.currentSiteId(getState());
      return DocumentClient.getDocumentLink(siteId, document.id).then(url => {
        window.open(url);
      });
    }
    return Promise.resolve();
  };
}
