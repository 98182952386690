import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Select, { components } from 'react-select';

import { withTracking } from '../../../../../../shared/analytics';
import CanaryLoadingIndicator from '../../../../../../shared/components/CanaryLoadingIndicator';
import ComplianceStatus from '../../../../../../shared/components/compliance/ComplianceStatus';
import StatusIconAndText from '../../../../../../shared/components/compliance/StatusIconAndText';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import ComplianceActionButton from './components/ComplianceActionButton';
import ComplianceEquipment from './ComplianceEquipment';
import ComplianceAtgReports from './ComplianceAtgReports';
import * as actions from './ComplianceReportActions';
import * as selectors from './ComplianceReportSelectors';
import * as SiteSelectors from '../../../../SiteSelectors';

const { Option, SingleValue } = components;

const overrideStatuses = [ComplianceStatus.Passed, ComplianceStatus.Inconclusive, ComplianceStatus.Failed];

const StatusOption = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Option {...props}>
    {/* eslint-disable-next-line react/prop-types */}
    <StatusIconAndText report={{ status: props.data, calculatedStatusOverridden: true }} />
  </Option>
);

const SelectStyles = {
  input: styles => ({
    ...styles,
    color: 'transparent'
  }),
};

const SelectedValue = (props) => (
  <SingleValue {...props}><StatusIconAndText report={{ status: props.data.value.status, calculatedStatusOverridden: props.data.value.calculatedStatusOverridden}} /></SingleValue>
);

class ComplianceReport extends Component {
  constructor(initialProps) {
    super(initialProps);

    this.renderStatusDropdown = this.renderStatusDropdown.bind(this);
  }

  componentDidMount() {
    const reportId = this.props.match.params.reportId;
    this.props.getComplianceReportById(reportId);
  }

  render() {
    return (
      <div className="row mt-4">
        <div className="col-md-2">
          <div className="nav flex-column nav-pills">
            <NavLink to={`${this.props.match.url}/equipment`} className="nav-link">Equipment</NavLink>
            <NavLink to={`${this.props.match.url}/atg-reports`} className="nav-link">ATG Reports</NavLink>
          </div>
        </div>
        <div className="col-md-10">
          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderContent() {
    if (this.props.error) {
      return <ErrorMessage title="Failed to load report" error={this.props.error} />;
    }
    else if (this.props.loading) {
      return <CanaryLoadingIndicator />;
    }
    else if (!this.props.currentReport) {
      return null;
    }

    const report = this.props.currentReport;
    const yearMonth = `${report.monthText} ${report.year} - `;

    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="d-inline-block mr-3 mb-0 text-uppercase">
            {yearMonth}
            {this.props.canEditCompliance ? this.renderStatusDropdown() : <StatusIconAndText report={report} />}
          </h3>
          <ComplianceActionButton onExport={this.props.downloadReport} right />
        </div>
        <Switch>
          <Redirect exact="exact" from={`${this.props.match.url}/`} to={`${this.props.match.url}/equipment`} />
          <Route exact path={`${this.props.match.url}/equipment`}>
            <ComplianceEquipment />
          </Route>
          <Route exact path={`${this.props.match.url}/atg-reports`}>
            <ComplianceAtgReports />
          </Route>
        </Switch>
      </>
    );
  }

  renderStatusDropdown() {
    if (!this.props.currentReport) {
      return null;
    }

    const status = { status: this.props.status, calculatedStatusOverridden:  this.props.calculatedStatusOverridden };

    return (
      <div style={{ display: 'inline-block', width: '11em' }}>
        <Select
          options={overrideStatuses.filter(s => s !== status.status)}
          value={{ value: status }}
          components={{ Option: StatusOption, SingleValue: SelectedValue, IndicatorSeparator: () => null }}
          styles={SelectStyles}
          onChange={(e) => this.props.onOverrideStatus(e)}
        />
      </div>
    );
  }
}

ComplianceReport.propTypes = {
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  currentReport: PropTypes.object,
  getComplianceReportById: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  canEditCompliance: PropTypes.bool.isRequired,
  onOverrideStatus: PropTypes.func.isRequired,
  status: PropTypes.string,
  calculatedStatusOverridden: PropTypes.bool,
};

ComplianceReport.defaultProps = {
  error: null,
  currentReport: null,
  status: null,
  calculatedStatusOverridden: false,
};

function mapStateToProps(state) {
  return {
    loading: selectors.isReportLoading(state),
    error: selectors.currentReportError(state),
    currentReport: selectors.currentReport(state),
    canEditCompliance: SiteSelectors.canEditCompliance(state),
    status: selectors.status(state),
    calculatedStatusOverridden: selectors.calculatedStatusOverridden(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getComplianceReportById: reportId => dispatch(actions.getComplianceReportById(reportId)),
    downloadReport: includeAtgReports => dispatch(actions.downloadReport(includeAtgReports)),
    onOverrideStatus: status => dispatch(actions.onOverrideStatus(status)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComplianceReport));
