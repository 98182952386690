import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, NavLink, Redirect, Switch, withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import PulseLoader from 'react-spinners/dist/spinners/PulseLoader';

import { SystemOrgId } from '../../../../../../AppConstants';
import ErrorMessage from '../../../../../../shared/components/error-message/ErrorMessage';
import { CreateBreadcrumb } from '../../../../../components/breadcrumbs';
import Breadcrumbs from '../../../../../components/breadcrumbs/Breadcrumbs';
import OrgHeader from '../components/OrgHeader';
import UsersTable from '../screens/user-editor/containers/UsersTable';
import UserEditor from '../screens/user-editor/containers/UserEditor';
import SitesTable from '../screens/site-editor/containers/SitesTable';
import SiteEditor from '../screens/site-editor/containers/SiteEditor';
import GroupsTable from '../screens/group-editor/containers/GroupsTable';
import GroupEditor from '../screens/group-editor/containers/GroupEditor';
import ExternalAccessNav from '../screens/external-access/containers/ExternalAccessNav';
import CustomerSettings from './CustomerSettings';
import * as navActions from '../../../actions';
import * as selectors from '../OrganizationEditSelectors';
import * as actions from '../actions';


class OrganizationView extends Component {
  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.organizationId) {
      this.props.selectOrganization(this.props.match.params.organizationId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.organizationId !== prevProps.match.params.organizationId) {
      this.props.selectOrganization(this.props.match.params.organizationId);
    }
  }

  render() {
    if (!this.props.selectedOrganization && this.props.error) {
      return (
        <div className="mt-4">
          <ErrorMessage title="Failed to load organization" error={this.props.error} />
        </div>
      );
    }
    else if (!this.props.selectedOrganization || this.props.isLoading || this.props.isSaving) {
      return (
        <PulseLoader color="#F8E71C" size={8} />
      );
    }
    else if (this.props.selectedOrganization.id === SystemOrgId) {
      return (
        <Alert color="info">
          You cannot edit the system organization.
        </Alert>
      );
    }

    const isCustomer = this.props.selectedOrganization.customer;
    return (
      <div>
        <CreateBreadcrumb name={this.props.selectedOrganization.name} />
        <Breadcrumbs />
        <OrgHeader
          organization={this.props.selectedOrganization}
          sites={this.props.selectedOrganizationSites}
          onOrganizationEdit={this.props.navToOrganizationEdit}
        />
        <div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <NavLink to={`${this.props.match.url}/users`} className="nav-link">Users</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`${this.props.match.url}/sites`} className="nav-link">Sites</NavLink>
            </li>
            {isCustomer
              ? <li className="nav-item"><NavLink to={`${this.props.match.url}/groups`} className="nav-link">Groups</NavLink></li>
              : null}
            <NavLink to={`${this.props.match.url}/external-access`} className="nav-link">External Access</NavLink>
            {isCustomer
              ? <li className="nav-item"><NavLink to={`${this.props.match.url}/customer-settings`} className="nav-link">Customer Settings</NavLink></li>
              : null}
          </ul>
          <div className="tab-content mt-4">
            <Switch>
              <Redirect exact="exact" from="/admin/organizations/:organizationId/" to={`${this.props.match.url}/users`} />
              <Route exact path="/admin/organizations/:organizationId/users" name="org-user-list">
                <UsersTable />
              </Route>
              <Route path="/admin/organizations/:organizationId/users/:userId" name="org-user-edit">
                <UserEditor />
              </Route>
              <Route exact path="/admin/organizations/:organizationId/sites" name="org-site-list">
                <SitesTable />
              </Route>
              <Route path="/admin/organizations/:organizationId/sites/:siteId" name="org-site-edit">
                <SiteEditor />
              </Route>
              <Route exact path="/admin/organizations/:organizationId/groups" name="org-group-list">
                <GroupsTable />
              </Route>
              <Route path="/admin/organizations/:organizationId/groups/:groupId" name="org-group-edit">
                <GroupEditor />
              </Route>
              <Route path="/admin/organizations/:organizationId/external-access" name="org-external-access">
                <ExternalAccessNav />
              </Route>
              <Route path="/admin/organizations/:organizationId/customer-settings" name="org-customer-settings">
                <CustomerSettings />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

OrganizationView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  selectedOrganization: PropTypes.object,
  selectedOrganizationSites: PropTypes.array,
  error: PropTypes.object,
  selectOrganization: PropTypes.func.isRequired,
  navToOrganizationEdit: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

OrganizationView.defaultProps = {
  selectedOrganization: null,
  selectedOrganizationSites: [],
  error: null,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    isSaving: selectors.isSaving(state),
    selectedOrganization: selectors.selectedOrganization(state),
    selectedOrganizationSites: selectors.selectedOrganizationSites(state),
    error: selectors.error(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectOrganization: organization => dispatch(actions.selectOrganization(organization)),
    navToOrganizationEdit: organization => dispatch(navActions.navToOrganization(organization, true)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationView));
