import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import flow from 'lodash/flow';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import { AuthenticationActions, AuthenticationSelectors } from '../../authentication/index';
import OrganizationNav from '../screens/organizations/containers/OrganizationNav';
import SiteAdminContainer from '../screens/sites/containers/SiteAdminContainer';
import IngestFailure from '../screens/ingest-failures/containers/IngestFailure';
import InventoryContainer from '../screens/inventory/containers/InventoryContainer';
import AdminDashboard from '../screens/admin-dashboard/AdminDashboard';
import AdminNav from './AdminNav';

class AdminLayout extends Component {
  constructor(defaultProps) {
    super(defaultProps);
    this.checkAuthorization = this.checkAuthorization.bind(this);
  }

  componentDidMount() {
    this.checkAuthorization();
  }

  componentDidUpdate() {
    this.checkAuthorization();
  }

  checkAuthorization() {
    if (!this.props.isLoggedIn) {
      this.props.redirectToLogin();
    }
  }

  renderNotAuthorized() {
    return (
      <div className="container" style={{ marginTop: '1em' }}>
        <div className="row mb-2">
          <div className="col-sm-10 offset-sm-1">
            <h3>Not authorized</h3>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.isAdmin) {
      return this.renderNotAuthorized();
    }

    return (
      <div className="mb-4">
        <AdminNav onLogout={this.props.logout} location={this.props.location} />
        <div className="container-fluid" style={{ marginTop: '1em' }}>
          <Switch>
            <Route exact path={`${this.props.match.url}/`} name="adminDashboard">
              <AdminDashboard />
            </Route>
            <Route path={`${this.props.match.url}/organizations`} name="clientManagement" component={OrganizationNav} />
            <Route path={`${this.props.match.url}/sites`} name="siteManagement" component={SiteAdminContainer} />
            <Route path={`${this.props.match.url}/ingest-failures`} component={IngestFailure} />
            <Route
              path={`${this.props.match.url}/inventory`}
              name="inventoryExportManagement"
              component={InventoryContainer}
            />
          </Switch>
        </div>
        <Alert stack={{ limit: 3 }} />
      </div>
    );
  }
}

AdminLayout.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
  isAdmin: PropTypes.bool,
  redirectToLogin: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

AdminLayout.defaultProps = {
  isLoggedIn: false,
  isAdmin: false,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: AuthenticationSelectors.isLoggedIn(state),
    isAdmin: AuthenticationSelectors.isAdmin(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    redirectToLogin: () => dispatch(AuthenticationActions.redirectToLogin()),
    logout: () => dispatch(AuthenticationActions.logout()),
  };
}

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  DragDropContext(HTML5Backend),
)(withRouter(AdminLayout));
