import { connect } from 'react-redux';
import ErrorMessage from '../../../shared/components/error-message/ErrorMessage';
import * as TourSelectors from '../../../shared/components/tour/TourSelectors';
import * as selectors from '../PreferencesSelectors';

function mapStateToProps(state) {
  return {
    error: selectors.error(state) || TourSelectors.error(state),
  };
}

export default connect(mapStateToProps)(ErrorMessage);
