export function isLoading(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.exportHistory &&
    state.admin.inventory.exportHistory.loading) || false;
}

export function exportHistoryList(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.exportHistory &&
    state.admin.inventory.exportHistory.exportHistoryList) || [];
}

export function filter(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.exportHistory &&
    state.admin.inventory.exportHistory.filter);
}

export function isSelectedExportLoading(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.exportHistory &&
    state.admin.inventory.exportHistory.selectedExportLoading) || false;
}

export function selectedExport(state) {
  return (state && state.admin && state.admin.inventory && state.admin.inventory.exportHistory &&
    state.admin.inventory.exportHistory.selectedExport);
}
