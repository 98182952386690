import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import * as selectors from './AtgReportsSelectors';
import AtgReports from './AtgReports';
import AtgToc from './AtgToc';

const AtgReportsAndToc = ({ reports, tableOfContents, className }) => {
  if (!reports || !reports.length) {
    return null;
  }

  return (
    <Row className={className}>
      <Col md={8}>
        <h6 className="text-uppercase">ATG Reports</h6>
        <AtgReports reports={reports} />
      </Col>
      <Col md={4}>
        <div className="sticky-top">
          <h6 className="text-uppercase">Report Index</h6>
          <AtgToc tableOfContents={tableOfContents} />
        </div>
      </Col>
    </Row>
  );
};

AtgReportsAndToc.propTypes = {
  reports: PropTypes.array,
  tableOfContents: PropTypes.array,
  className: PropTypes.string,
};

AtgReportsAndToc.defaultProps = {
  reports: null,
  tableOfContents: null,
  className: null,
};

function mapStateToProps(state) {
  return {
    reports: selectors.reports(state),
    tableOfContents: selectors.tableOfContents(state),
  };
}

export default connect(mapStateToProps)(AtgReportsAndToc);
