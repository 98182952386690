import { MviClient } from '../../../../client';
import ActionTypes from './MviLinkModalActionTypes';
import * as SiteSelectors from '../../SiteSelectors';
import * as selectors from './MviLinkModalSelectors';

export function openModal() {
  return (dispatch, getState) => {
    const state = getState();
    const site = SiteSelectors.currentSite(state);
    if (!site) {
      return Promise.resolve();
    }

    dispatch({ type: ActionTypes.OPEN_MODAL });
    if (!selectors.mviFormUrl(state)) {
      dispatch({ type: ActionTypes.GET_MVI_FORM_URL_STARTED });

      return MviClient.getMviFormUrl(site)
        .then(mviFormUrl => dispatch({ type: ActionTypes.RECEIVE_MVI_FORM_URL, mviFormUrl }))
        .catch(error => dispatch({ type: ActionTypes.GET_MVI_FORM_URL_FAILED, error }));
    }

    return Promise.resolve();
  };
}

export function closeModal() {
  return { type: ActionTypes.CLOSE_MODAL };
}
