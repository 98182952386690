import React, { Component } from 'react';

export function recordLogin(username, email, role, org) {
  const mixpanel = window.mixpanel;
  if (!mixpanel) {
    return;
  }
  try {
    registerProperty({ Environment: window.env });
    mixpanel.identify(username);
    const userProps = { $name: username, Role: role };
    if (email) {
      userProps.$email = email;
    }

    if (org) {
      userProps.Organization = org.name;
      userProps['Organization id'] = org.id;
    }
    mixpanel.people.set(userProps);
  }
  catch (e) {
    console.error('Failed to initialize Mixpanel', e);
  }
}

export function recordLogout() {
  const mixpanel = window.mixpanel;
  if (!mixpanel) {
    return;
  }
  try {
    mixpanel.reset();
  }
  catch (e) {
    console.error('Mixpanel logout failed', e);
  }
}

export function track(eventName, eventDetails) {
  const mixpanel = window.mixpanel;
  if (!mixpanel) {
    return;
  }
  try {
    mixpanel.track(eventName, eventDetails);
  }
  catch (e) {
    console.error('Failed to send event to Mixpanel', e);
  }
}

export function registerProperty(value) {
  const mixpanel = window.mixpanel;
  if (!mixpanel) {
    return;
  }
  try {
    mixpanel.register(value);
  }
  catch (ignored) {
  }
}

export function unregisterProperty(name) {
  const mixpanel = window.mixpanel;
  if (!mixpanel) {
    return;
  }
  try {
    mixpanel.unregister(name);
  }
  catch (ignored) {
  }
}

export const withTracking = (page, subPage) => WrappedComponent => {
  return class extends Component {
    componentDidMount() {
      this.loadTime = Date.now();
      const trackData = { page };
      if (subPage) {
        trackData['Sub page'] = subPage;
      }

      registerProperty(trackData);
      track('Page enter');
    }

    componentWillUnmount() {
      const duration = (Date.now() - this.loadTime) / 1000;
      track('Page exit', { duration });
      unregisterProperty('page');
      unregisterProperty('Sub page');
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};
