import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

class Timezone extends Component {
  constructor(initialProps) {
    super(initialProps);
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
  }

  handleTimezoneChange(event) {
    if (event.target.value) {
      this.props.onTimezoneSelect(event.target.value);
    }
    else {
      this.props.onTimezoneSelect(null);
    }
  }

  render() {
    const optionValue = this.props.value || '';

    return (
      <Input type="select" value={optionValue} id={this.props.id} name={this.props.name} onChange={this.handleTimezoneChange} disabled={this.props.disabled}>
        <option value="" />
        <option key="America/New_York" value="America/New_York">America/New_York</option>
        <option key="America/Chicago" value="America/Chicago">America/Chicago</option>
        <option key="America/Denver" value="America/Denver">America/Denver</option>
        <option key="America/Phoenix" value="America/Phoenix">America/Phoenix</option>
        <option key="America/Los_Angeles" value="America/Los_Angeles">America/Los_Angeles</option>
        <option key="America/Anchorage" value="America/Anchorage">America/Anchorage</option>
        <option key="America/Adak" value="America/Adak">America/Adak</option>
        <option key="Pacific/Honolulu" value="Pacific/Honolulu">Pacific/Honolulu</option>
        <option key="America/Puerto_Rico" value="America/Puerto_Rico">America/Puerto_Rico</option>
      </Input>
    );
  }
}

Timezone.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onTimezoneSelect: PropTypes.func.isRequired,
};

Timezone.defaultProps = {
  id: 'timezone',
  name: 'timezone',
  value: null,
  disabled: false,
};

export default Timezone;
