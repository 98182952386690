const CathodicProtection = { id: 4,
  name: 'cathodicProtection',
  label: 'Cathodic Protection',
  edit: false,
  disableEditButton: false,
  errorMessages: [],
  table: false,
  card: true,
  path: 'cathodicProtection',
  index: 0,
  fields: [
    { id: 1,
      name: 'installDate',
      label: 'Installation Date',
      path: 'cathodicProtection.0.installDate',
      type: 'date',
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
    { id: 2,
      name: 'cathodicProtectionType',
      label: 'Cathodic Protection Type',
      path: 'cathodicProtection.0.cathodicProtectionType',
      type: 'select',
      values: ['Unknown', 'Impressed current', 'Galvanic'],
      cols: 4,
      editable: true,
      required: true,
      error: false,
      display: true,
    },
  ],
};

export default CathodicProtection;
