import update from 'immutability-helper/index';

import {UserClient} from '../../../../../../../../client';
import {PresentAlert, PresentError} from '../../../../../../../../shared/ToastUtils';
import ActionTypes from '../UserActionTypes';
import * as orgEditSelectors from '../../../OrganizationEditSelectors';
import * as selectors from '../UserSelectors';
import {navToUserList} from '../../../../../actions';

const NEW = 'new';

const MIN_SEARCH_VALUE_LENGTH = 3;

function getUserStarted() {
  return {
    type: ActionTypes.GET_USER_STARTED,
  };
}

function receiveUser(selectedUser) {
  return {
    type: ActionTypes.RECEIVE_USER,
    selectedUser
  };
}

function getUserFailed(error) {
  return {
    type: ActionTypes.GET_USER_FAILED,
    error,
  };
}

function buildNewUser(organization) {
  return {
    username: '',
    email: '',
    password: '',
    active: true,
    userType: 'non-admin',
    organizationMembership: {
      organizationId: organization.id,
      organization,
      isAdmin: false,
    }
  };
}

export function selectUser(user) {
  return (dispatch, getState) => {
    const currentState = getState();
    const isLoading = selectors.isLoading(currentState);
    const org = orgEditSelectors.selectedOrganization(currentState);
    if (isLoading || !org || !user) {
      return Promise.resolve();
    }

    dispatch(getUserStarted());
    return Promise.resolve().then(() => {
      const id = user.id || user;
      let userPromise;
      if (id === NEW) {
        userPromise = Promise.resolve(buildNewUser(org));
      }
      else {
        userPromise = Promise.all([UserClient.getUser(id), UserClient.getPermissions(id)]).then(([apiUser, permissions]) => {
          apiUser.permissions = permissions;
          return apiUser;
        });
      }

      return userPromise
        .then((dispatchUser) => dispatch(receiveUser(dispatchUser)))
        .catch((err) => {
          dispatch(getUserFailed(err));
          return Promise.reject(err);
        });
    });
  };
}

function receiveUsers(users) {
  return {
    type: ActionTypes.RECEIVE_MATCHING_USERS,
    users,
  };
}

function usersSearchStarted() {
  return {
    type: ActionTypes.USERS_SEARCH_STARTED,
  };
}

function usersSearchFailed(error) {
  return {
    type: ActionTypes.USERS_SEARCH_FAILED,
    error,
  };
}

export function editSelectedUser(user) {
  return (dispatch, getState) => {
    const currentState = getState();
    const userSearchInProgress = selectors.userSearchInProgress(currentState);
    let searchPromise;
    if (!userSearchInProgress && ((user.username && user.username.length > MIN_SEARCH_VALUE_LENGTH) || (user.email && user.email.length > MIN_SEARCH_VALUE_LENGTH))) {
      dispatch(usersSearchStarted());
      searchPromise = UserClient.search(user)
        .then(users => dispatch(receiveUsers(users)))
        .catch(err => {
          dispatch(usersSearchFailed());
          return Promise.reject(err);
        });
    }
    else {
      searchPromise = Promise.resolve();
    }

    return searchPromise.then(dispatch({ type: ActionTypes.EDIT_SELECTED_USER, updatedUser: user }));
  };
}

function saveUserStarted() {
  return {
    type: ActionTypes.SAVE_USER_STARTED,
  };
}

function saveUserSuccess(user) {
  return {
    type: ActionTypes.SAVE_USER_SUCCESS,
    user,
  };
}

function saveUserFailed(error) {
  return {
    type: ActionTypes.SAVE_USER_FAILED,
    error,
  };
}

function saveInternal(user) {
  return (dispatch, getState) => {
    const state = getState();
    const isSaving = selectors.isSaving(state);
    if (isSaving) {
      return Promise.resolve();
    }

    let toSave = user;
    if (!Number.isInteger(toSave.id)) {
      toSave = update(toSave, {
        id: {
          $set: null,
        },
        $unset: ['permissions']
      });
    }
    else {
      toSave = update(toSave, {
        $unset: ['permissions', 'password']
      });
    }
    dispatch(saveUserStarted());
    return UserClient.saveUser(toSave)
      .then(persistentUser => UserClient.getPermissions(persistentUser.id)
        .then((permissions) => {
          persistentUser.permissions = permissions;
          dispatch(saveUserSuccess(persistentUser));

          // go to user list if save was successful and a new user was just created
          if (!toSave.id) {
            const org = orgEditSelectors.selectedOrganization(state);
            dispatch(navToUserList(org.id));
          }
        }))
      .catch((error) => {
        dispatch(saveUserFailed(error));
        return Promise.reject(error);
      });
  };
}

export function savePendingUserEdits() {
  return (dispatch, getState) => {
    const state = getState();
    const user = selectors.pendingUserEdits(state);
    return dispatch(saveInternal(user));
  };
}

export function sendPasswordResetEmail(user) {
  return () => UserClient.sendPasswordResetEmail(user)
    .then(() => PresentAlert(`Password reset sent to ${user.email}`))
    .catch((err) => {
      PresentError('Failed to send password reset email.');
      return Promise.reject(err);
    });
}

export function resetPassword(user, newPassword) {
  return () => {
    const userId = user.id || user;
    return UserClient.getUser(userId)
      .then((restUser) => {
        const updatedUser = update(restUser, {
          password: {
            $set: newPassword,
          },
        });
        return UserClient.saveUser(updatedUser)
          .then(() => PresentAlert(`Successfully reset password for ${restUser.username}`))
          .catch((err) => {
            PresentError(`Failed to reset password for ${restUser.username}`);
            return Promise.reject(err);
          });
      });
  };
}

export function discardPendingUserEdits() {
  return { type: ActionTypes.DISCARD_SELECTED_USER_EDITS };
}

export function toggleUserEnabled(user) {
  const updatedUser = update(user, {
    active: {
      $set: !user.active,
    }
  });
  return saveInternal(updatedUser);
}
