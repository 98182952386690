import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { track } from '../../../../../../../../shared/analytics';
import { dateAsText, timestampAsMonthYear } from '../../../../../../../../shared/components/date';
import * as SiteSelectors from '../../../../../../SiteSelectors';
import * as SiteActions from '../../../../../../actions';
import * as IssueSelectors from '../../IssueEditSelectors';

const IssueMviReports = ({ issue, canViewMvis, navToSiteMvi, className }) => {
  if (!canViewMvis || !issue || !issue.mviReports || !issue.mviReports.length) {
    return null;
  }

  const handleNavClick = report => {
    track('Inspection', {
      Component: 'Issue MVIs',
      'Inspection action': 'Nav to inspection',
    });
    const mvi = {
      id: report.mviId,
      siteId: issue.siteId,
    };
    navToSiteMvi(mvi);
  };

  return (
    <div className={className}>
      <h6 className="text-uppercase">MVI</h6>
      <div className="border rounded-lg p-3">
        {issue.mviReports.map(report => (
          <div key={report.id}>
            <Button color="link" onClick={() => handleNavClick(report)}>
              {`MVI ${timestampAsMonthYear(report.inspectionDate).toUpperCase()} (${dateAsText(report.inspectionDate)})`}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

IssueMviReports.propTypes = {
  issue: PropTypes.object,
  canViewMvis: PropTypes.bool.isRequired,
  navToSiteMvi: PropTypes.func.isRequired,
  className: PropTypes.string,
};

IssueMviReports.defaultProps = {
  issue: null,
  className: null,
};

function mapStateToProps(state) {
  return {
    issue: IssueSelectors.currentIssue(state),
    canViewMvis: SiteSelectors.canViewMvis(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navToSiteMvi: mvi => dispatch(SiteActions.navToSiteMvi(mvi)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueMviReports);
