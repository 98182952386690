import isEqual from 'lodash/isEqual';
import find from 'lodash/find';

export function isLoading(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.edit && state.admin.organizations.edit.loading) || false;
}

export function isSaving(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.edit && state.admin.organizations.edit.saving) || false;
}

export function error(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.edit && state.admin.organizations.edit.error) || null;
}


export function permissionSets(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.edit && state.admin.organizations.edit.permissionSets) || [];
}

export function allPermissionSet(state) {
  return find(permissionSets(state), current => current.name === 'All');
}

export function selectedOrganization(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.edit && state.admin.organizations.edit.selectedOrganization) || null;
}

export function selectedOrganizationIsCustomer(state) {
  const org = selectedOrganization(state);
  return !!(org && org.customer);
}

export function selectedOrganizationSites(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.edit && state.admin.organizations.edit.selectedOrganizationSites) || [];
}

export function selectedOrganizationUsers(state) {
  const org = selectedOrganization(state);
  if (org && org.memberships) {
    return org.memberships
      .map(current => ({
        id: current.user.id,
        username: current.user.username,
        email: current.user.email,
        active: current.user.active,
        created: current.user.created,
      }));
  }
  return [];
}

export function selectedOrganizationSiteLabels(state) {
  const org = selectedOrganization(state);
  return (org && org.customer && org.customer.labels) || [];
}

export function selectedOrganizationDispatchSystems(state) {
  const org = selectedOrganization(state);
  return (org && org.customer && org.customer.dispatchSystemConfigurations) || [];
}

export function pendingOrgEdits(state) {
  return (state && state.admin && state.admin.organizations && state.admin.organizations.edit && state.admin.organizations.edit.editedOrganization) || null;
}

export function pendingOrgEditsPristine(state) {
  const selectedOrg = selectedOrganization(state);
  const pendingEdits = pendingOrgEdits(state);
  return (selectedOrg && pendingEdits && isEqual(selectedOrg, pendingEdits)) || false;
}
