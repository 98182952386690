import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import moment from 'moment';

import MviEditSelectors from '../../MviEditSelectors';
import { MviWorkflowStatusTypes } from '../../../../../../../../AppConstants';
import MviModal from '../../../../../../../../shared/components/mvi/modal/MviModal';
import * as MviReportActions from './MviReportActions';

const TextByWorkflowStatus = {
  [MviWorkflowStatusTypes.Pending]: 'The inspection has not been performed or the results have not been received by Canary yet.',
  [MviWorkflowStatusTypes.ReadyForReview]: 'The MVI was marked as ready for review in Canary but no report is available.',
  [MviWorkflowStatusTypes.Completed]: 'The MVI was marked as completed in Canary but no report is available.',
  [MviWorkflowStatusTypes.NotRequired]: 'No MVI was required for this month.',
  [MviWorkflowStatusTypes.NotPerformed]: 'No MVI was performed for this month.'
};

const NoReport = ({ mvi, confirmCreate, className }) => {
  if (!mvi) {
    return null;
  }

  const renderAlert = () => {
    const text = TextByWorkflowStatus[mvi.workflowStatus];
    if (text) {
      return <Alert color="info" className={className}>{text}</Alert>;
    }
    return null;
  };
  const minInspectionDate = moment(mvi.monthStartDate).startOf('month');
  const maxInspectionDate = moment(mvi.monthStartDate).endOf('month');
  return (
    <>
      {renderAlert()}
      <MviModal minInspectionDate={minInspectionDate} maxInspectionDate={maxInspectionDate} siteEditDisabled fileUploadEnabled onConfirm={confirmCreate} />
    </>
  );
};

NoReport.propTypes = {
  mvi: PropTypes.object,
  confirmCreate: PropTypes.func.isRequired,
  className: PropTypes.string,
};

NoReport.defaultProps = {
  mvi: null,
  className: null,
};

function mapStateToProps(state) {
  return {
    mvi: MviEditSelectors.item(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    confirmCreate: (site, inspectionDetails, file) => dispatch(MviReportActions.confirmCreate(site, inspectionDetails, file)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoReport);
