import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Col, Row } from 'reactstrap';

import { withTracking } from '../../../shared/analytics';
import withTour from '../../../shared/components/tour/WithTour';
import { Features, SortDirection } from '../../../AppConstants';
import * as NavContextActions from '../../../shared/components/nav-context/NavContextActions';
import ErrorMessage from '../../../shared/components/error-message/ErrorMessage';
import { TablesTour } from '../../UserTours';
import * as authSelectors from '../../../authentication/AuthenticationSelectors';
import InventoryTable from '../components/InventoryTable';
import * as actions from '../actions';
import InventoryListSelectors, { InventoryTableSelectors, InventoryViewManagerSelectors, sitesInventoryRefreshInProgress, lastTimestampUpdate } from '../InventorySelectors';
import { SitesActions } from '../../sites/index';
import { refreshInventory } from '../actions/InventoryCommandActions';
import { FilterType } from '../../../shared/components/table/view-manager/ViewManagerConstants';
import buildViewBar from '../../../shared/components/table/view-manager/ViewBar';
import buildViewControls from '../../../shared/components/table/view-manager/ViewControls';
import { InventoryTableColumns } from '../InventoryConstants';
import CanaryLoadingIndicator from '../../../shared/components/CanaryLoadingIndicator';
import InventoryViewManagerActions from '../actions/InventoryViewManagerActions';

function pluralize(inventories) {
  return inventories && inventories.length === 1 ? 'Tank' : 'Tanks';
}

const InventoryFilterTypes = [FilterType.Customer, FilterType.State, FilterType.Label, FilterType.Site, FilterType.TankProductLabel, FilterType.TankStatus];
const InventoryViewBar = buildViewBar(InventoryViewManagerActions, InventoryListSelectors, InventoryViewManagerSelectors, InventoryFilterTypes);
const InventoryViewControls = buildViewControls(InventoryViewManagerActions, InventoryListSelectors, InventoryViewManagerSelectors, InventoryTableColumns);

class Inventory extends Component {
  constructor(props, context) {
    super(props, context);
    this.getInventory = this.getInventory.bind(this);
    this.refreshTimestampsIfMounted = this.refreshTimestampsIfMounted.bind(this);
  }

  componentDidMount() {
    this.props.clearNavContext();
    this.mounted = true;
    this.intervalId = setInterval(this.refreshTimestampsIfMounted, [30000]);
  }

  getInventory() {
    this.props.getInventory().then(() => this.props.startTour());
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  refreshTimestampsIfMounted() {
    if (this.mounted && !this.props.isLoading && !this.props.isRefreshing) {
      const diff = Date.now() - this.props.lastTimestampUpdate;
      if (diff > 25000) {
        this.props.refreshTimestamps();
      }
    }
  }

  render() {
    if (this.props.inventoryFeatureDisabled) {
      return (
        <div className="mt-4">
          <Alert color="info">
            <h5 className="alert-heading">Inventory Not Available</h5>
            <p>The inventory feature is disabled.</p>
          </Alert>
        </div>
      );
    }
    else if (this.props.error) {
      return (
        <div className="mt-2">
          <ErrorMessage error={this.props.error} />
        </div>
      );
    }

    return (
      <>
        <Row className="mb-2">
          <Col md={7}>
            <h5 className="mb-0">
              {this.renderTitle()}
              {this.props.isRefreshing ? <CanaryLoadingIndicator inline /> : null}
            </h5>
          </Col>
          <Col md={5}>
            <InventoryViewControls onChange={this.getInventory} className="ml-auto" />
          </Col>
        </Row>
        <InventoryViewBar onChange={this.getInventory} />
        {this.renderTable()}
      </>
    );
  }

  renderTitle() {
    if (this.props.isRefreshing || this.props.isLoading) {
      return null;
    }
    const count = (this.props.inventory && this.props.inventory.length) || 0;
    return `${count.toLocaleString()} ${pluralize(this.props.inventory)}`;
  }

  renderTable() {
    return (
      <InventoryTable
        dataLoading={this.props.isLoading}
        tankInventories={this.props.filteredInventory}
        sitesInventoryRefreshInProgress={this.props.sitesInventoryRefreshInProgress}
        columns={this.props.columns}
        pageCount={this.props.pageCount}
        pageSize={this.props.pageSize}
        currentPage={this.props.currentPage}
        changePage={this.props.changePage}
        changeSort={this.props.changeSort}
        sortColumnId={this.props.sortColumnId}
        sortDirection={this.props.sortDirection}
        onSiteClick={this.props.navToSite}
        onRefreshInventoryClick={this.props.refreshTankInventory}
        noDataMessage="No data"
      />
    );
  }
}

Inventory.propTypes = {
  inventoryFeatureDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  inventory: PropTypes.array.isRequired,
  sitesInventoryRefreshInProgress: PropTypes.array.isRequired,
  filteredInventory: PropTypes.array.isRequired,
  lastTimestampUpdate: PropTypes.number,

  getInventory: PropTypes.func.isRequired,
  clearNavContext: PropTypes.func.isRequired,

  columns: PropTypes.arrayOf(PropTypes.object).isRequired,

  // table pagination support
  sortColumnId: PropTypes.string,
  sortDirection: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  changeSort: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,

  navToSite: PropTypes.func.isRequired,

  refreshTimestamps: PropTypes.func.isRequired,
  refreshTankInventory: PropTypes.func.isRequired,

  // feature tour
  startTour: PropTypes.func.isRequired,
};

Inventory.defaultProps = {
  error: null,
  lastTimestampUpdate: null,
  sortColumnId: 'siteDisplay',
  sortDirection: SortDirection.Asc,
};

function mapStateToProps(state) {
  return {
    inventoryFeatureDisabled: !authSelectors.isFeatureEnabledAnySite(state, Features.Inventory),
    isLoading: InventoryListSelectors.isLoading(state),
    isRefreshing: InventoryListSelectors.isRefreshing(state),
    error: InventoryListSelectors.error(state),
    inventory: InventoryListSelectors.list(state),
    sitesInventoryRefreshInProgress: sitesInventoryRefreshInProgress(state),
    filteredInventory: InventoryTableSelectors.filteredList(state),
    lastTimestampUpdate: lastTimestampUpdate(state),
    columns: InventoryViewManagerSelectors.selectedColumns(state),
    sortColumnId: InventoryTableSelectors.sortColumnId(state),
    sortDirection: InventoryTableSelectors.sortDirection(state),
    currentPage: InventoryTableSelectors.currentPage(state),
    pageSize: InventoryTableSelectors.pageSize(state),
    pageCount: InventoryTableSelectors.pageCount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInventory: () => dispatch(actions.getInventory()),
    navToSite: siteId => dispatch(SitesActions.navToSite(siteId)),
    clearNavContext: () => dispatch(NavContextActions.clearContext()),
    refreshTimestamps: () => dispatch(actions.refreshTimestamps()),
    refreshTankInventory: (tankStateInventory) => (dispatch(refreshInventory(tankStateInventory))),

    // table pagination
    changeSort: (sortColumnId, sortDirection) => dispatch(actions.changeSort(sortColumnId, sortDirection)),
    changePage: page => dispatch(actions.changePage(page)),
  };
}

export default withTour(TablesTour.tourName, TablesTour.steps)(withTracking('Inventory List')(connect(mapStateToProps, mapDispatchToProps)(Inventory)));
