import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Input, InputGroup, Badge } from 'reactstrap';
import objectPath from 'object-path';

import withEither from './withEither';

const textInputFn = props => props.edit && props.field.editable;

const TextEditor = props =>
  <div>
    <InputGroup>
      {props.field.name === 'comment' ?
        <Input
          type="textarea"
          maxLength="1000"
          onChange={e => props.onValidatePanel(props.formBuilder, props.panel, props.target, props.field, e.target.value)}
          id={props.id}
          placeholder={`Enter ${props.field.label}`}
          value={objectPath.get(props.target, props.field.path) || ''}
        /> :

        <Input
          maxLength="100"
          onChange={e => props.onValidatePanel(props.formBuilder, props.panel, props.target, props.field, e.target.value)}
          id={props.id}
          placeholder={`Enter ${props.field.label}`}
          value={objectPath.get(props.target, props.field.path) || ''}
        />
}
    </InputGroup>
    { props.field.required && !objectPath.get(props.target, props.field.path) ?
      <div className="d-flex justify-content-begin mt-1 required"><strong>Required</strong></div>
    : null
    }

    {props.field.error ?
      <div className="d-flex justify-content-begin mt-1"><Badge color="danger">Error</Badge></div>
      : null
      }
  </div>;


const TextViewer = props =>
  <div className="pb-2 pt-2">
    { objectPath.get(props.target, props.field.path) ?
      <strong id={props.id}>{ Array.isArray(objectPath.get(props.target, props.field.path)) ? objectPath.get(props.target, props.field.path).join() : (objectPath.get(props.target, props.field.path)) }</strong>
    : '\u2014' }
  </div>;

const withConditionalRenderings = compose(
      withEither(textInputFn, TextEditor),
    );

const TextWithConditionalRendering = withConditionalRenderings(TextViewer);

const TextInputField = props => (
  <div className="mb-3">
    <label htmlFor={`field${props.panel.id.toString()}${props.field.id.toString()}`}>{props.field.label}:</label>
    <TextWithConditionalRendering id={`field${props.panel.id.toString()}${props.field.id.toString()}`} {...props} />
  </div>
  );

TextInputField.propTypes = {
  field: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
};

TextEditor.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  formBuilder: PropTypes.object.isRequired,
  onValidatePanel: PropTypes.func.isRequired,
};

TextViewer.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default TextInputField;

