import Selectors from '../../shared/redux-helpers/Selectors';
import TableSelectors from '../../shared/components/table/redux/TableSelectors';
import ViewManagerSelectors from '../../shared/components/table/view-manager/ViewManagerSelectors';

const accessor = (state => state && state.inventory) || {};
const selectors = new Selectors(accessor);

export default selectors;

export const InventoryTableSelectors = new TableSelectors(accessor);
export const InventoryViewManagerSelectors = new ViewManagerSelectors(accessor);

export const isSiteInventoryRefreshInProgress = (state, site) => {
  const invState = accessor(state);
  const siteId = site.id || site;
  return invState && invState.sitesRefreshInProgress && invState.sitesRefreshInProgress.length && invState.sitesRefreshInProgress.some(s => s.id === siteId);
};

export const isInventoryPage = state => state.router && state.router.location && state.router.location.pathname && state.router.location.pathname === '/inventory';

export const sitesInventoryRefreshInProgress = (state) => accessor(state).sitesRefreshInProgress || [];

export const lastTimestampUpdate = state => accessor(state).lastTimestampUpdate || null;
