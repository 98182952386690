/* eslint-disable react/no-unused-prop-types, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'lodash/memoize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TextAlign } from './TableConstants';
import PropertyColumn from './PropertyColumn';

const buildRender = memoize((trueIcon, falseIcon) => value => {
  const icon = value ? trueIcon : falseIcon;
  if (icon) {
    return (
      <span>
        <FontAwesomeIcon icon={icon} />
      </span>
    );
  }
  return null;
});

function BooleanColumn({ title, property, rowData, trueIcon, falseIcon }) {
  const render = buildRender(trueIcon, falseIcon);
  return (
    <PropertyColumn
      title={title}
      property={property}
      render={render}
      rowData={rowData}
    />
  );
}

BooleanColumn.propTypes = {
  sortable: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  textAlign: PropTypes.oneOf([TextAlign.Left, TextAlign.Right, TextAlign.Center]),
  property: PropTypes.string.isRequired,
  trueIcon: PropTypes.any,
  falseIcon: PropTypes.any,

  rowData: PropTypes.object,
};

BooleanColumn.defaultProps = {
  sortable: false,
  title: null,
  width: null,
  textAlign: TextAlign.Left,
  rowData: null,
  trueIcon: null,
  falseIcon: null,
};

export default BooleanColumn;
