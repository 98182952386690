import ActionTypes from './TankListActionTypes';
import { TankListStep } from './TankListConstants';

function allTanksMatched(action) {
  const tanks = action.tanks || [];
  const matchedTanks = tanks.filter(current => !!current.registration);
  return tanks.length && tanks.length === matchedTanks.length;
}

function noRegistrations(action) {
  return action.registrations === undefined || action.registrations === null || !action.registrations.length;
}

function getInitialStep(action) {
  if (allTanksMatched(action) || noRegistrations(action)) {
    return TankListStep.Step3DisplayList;
  }
  return TankListStep.Step1MatchTanks;
}

export default function TankListReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_DATA_STARTED:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
        tanks: null,
        registrations: null,
        atgInfo: null,
        step: null,
      });
    case ActionTypes.RECEIVE_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        isError: false,
        tanks: action.tanks,
        registrations: action.registrations,
        atgInfo: action.atgInfo,
        step: getInitialStep(action),
        canConfirmMatches: false,
      });
    case ActionTypes.GET_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
      });

    case ActionTypes.UPDATE_MATCHES:
      return Object.assign({}, state, {
        tanks: action.tanks,
        registrations: action.registrations,
        canConfirmMatches: allTanksMatched(action),
      });
    case ActionTypes.RECEIVE_STEP:
      return Object.assign({}, state, {
        step: action.step,
      });

    case ActionTypes.SAVE_DATA_STARTED:
      return Object.assign({}, state, {
        isSaving: true,
        isError: false,
      });
    case ActionTypes.SAVE_DATA_COMPLETE:
      return Object.assign({}, state, {
        isSaving: false,
        isError: false,
        step: TankListStep.Step3DisplayList,
      });
    case ActionTypes.SAVE_DATA_FAILED:
      return Object.assign({}, state, {
        isSaving: false,
        isError: true,
      });
    default:
      return state;
  }
}
