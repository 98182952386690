import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Card, CardHeader, CardBody, CardFooter, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import objectPath from 'object-path';
import immutable from 'object-path-immutable';
import findIndex from 'lodash/findIndex';
import moment from 'moment';
import ActionBar from './ActionBar';
import TextInputField from './TextInputField';
import withEither from './withEither';
import Datepicker from './Datepicker';
import Numberpicker from './Numberpicker';
import Dropdown from './DropdownList';
import NullComponent from './NullComponent';
import SwitchInputField from './Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const preparePanelModal = (panel, index) => {
  const newPanel = panel;
  newPanel.index = index;
  newPanel.errorMessages = [];
  newPanel.fields.map((fld) => {
    const newField = fld;
    newField.error = false;
    newField.path = fld.path.replace(/(\d+)/, index);
    return newField;
  });
  return newPanel;
};

const deleteClickHandler = function (index, fb, panel, updateFormBuilder, status) {
  // set panel property
  const panelIndex = findIndex(fb.panels, ['name', panel.name]);
  const sourcePath = `panels.${panelIndex}`;
  const newFB = immutable.set(fb, `${sourcePath}.deleteModalOpen`, status);
  const newFB1 = immutable.set(newFB, `${sourcePath}.index`, index);
  updateFormBuilder(newFB1);
};

const onDelete = function (target, panel, deleteItem) {
  deleteItem(target, panel);
};

const switchConditionFn = props => props.field.display && props.field.type === 'bool';
const datepickerConditionFn = props => props.field.display && props.field.type === 'date';
const numberpickerConditionFn = props => props.field.display && props.field.type === 'number';
const dropdownConditionFn = props => props.field.display && props.field.type === 'select';
const TextInputConditionFn = props => props.field.display && props.field.type === 'text';

const withConditionalRenderings = compose(
  withEither(TextInputConditionFn, TextInputField),
  withEither(switchConditionFn, SwitchInputField),
  withEither(datepickerConditionFn, Datepicker),
  withEither(numberpickerConditionFn, Numberpicker),
  withEither(dropdownConditionFn, Dropdown),
);


const PanelContentsWithConditionalRendering = withConditionalRenderings(NullComponent);


const CardPanel = props =>

  <div className="row">
    {props.prepareTableData(props.panel, props.target) && props.prepareTableData(props.panel, props.target).map((item, index) =>
      <div key={`${props.panel.id}${index}`} className="col-sm-6">
        <Card className="mb-3">
          <CardHeader>{objectPath.get(props.target, props.panel.path) && objectPath.get(props.target, props.panel.path)[index] ? objectPath.get(props.target, props.panel.path)[index].name : `${props.panel.label} ${Number(index + 1)}`}</CardHeader>
          <CardBody>
            <ListGroup>
              {props.panel.fields.map((field, idx) => {
                let displayValue;
                if (idx < 6) {
                  if (field.type === 'bool') {
                    if (objectPath.get(props.target, field.path.replace(/(\d+)/, index)) === true) {
                      displayValue = <FontAwesomeIcon size="sm" icon={faCheck} />;
                    }
                    else {
                      displayValue = '';
                    }
                  }
                  else if (field.type === 'date') {
                    displayValue = <strong>{moment(objectPath.get(props.target, field.path.replace(/(\d+)/, index)), 'YYYY-MM-DD').isValid() ? moment(objectPath.get(props.target, field.path.replace(/(\d+)/, index)), 'YYYY-MM-DD').format('L') : '\u2014' }</strong>;
                  }
                  else {
                    displayValue = <strong>{objectPath.get(props.target, field.path.replace(/(\d+)/, index))}</strong> || '\u2014';
                  }

                  if (field.display) {
                    return (
                      <ListGroupItem key={`${field.name}${field.id}`}> {field.label}: { displayValue }</ListGroupItem>
                    );
                  }
                }

                return null;
              },
            )}
            </ListGroup>
          </CardBody>
          <CardFooter>
            <Button disabled={props.panel.disableEditButton || false} size="sm" color="primary" onClick={e => props.toggleModal(preparePanelModal(props.panel, index), e.target.value)}>EDIT</Button>
            <Button disabled={props.panel.disableEditButton || false} className="ml-2" outline size="sm" color="primary" onClick={() => deleteClickHandler(index, props.formBuilder, props.panel, props.updateFormBuilder, true)}>DELETE</Button>
          </CardFooter>
        </Card>
      </div>,
        )}

    <div>
      <Modal size="lg" isOpen={props.panel.modalOpen}>
        <ModalHeader toggle={() => props.onCancelPanel({ panel: props.panel })}>{props.panel.label}</ModalHeader>
        <ModalBody>
          <div className="row mb-2">
            <div className="col-sm-10">
              <h1 className="d-flex justify-content-start canary">{props.panel.label}</h1>
            </div>
            {!props.panel.edit ?
              <div className="col-sm-2">
                <div className="d-flex justify-content-end">
                  <Button disabled={props.panel.disableEditButton || false} className="mt-2" outline color="primary" onClick={() => props.toggleEditFormBuilderPanel(props.panel, !props.panel.disableEditButton)}>EDIT</Button>
                </div>
              </div>
          : null }
          </div>
          {props.panel.errorMessages && props.panel.errorMessages.length ?

            <div className="row">
              <div className="col-sm-12">
                <div className="alert alert-danger mb-3" role="alert">
                  {props.panel.errorMessages.map((msg, ix) =>
                    <div key={`${msg.field}${ix}`}>{msg.message}</div>,
                  )}
                </div>
              </div>
            </div>

            : null
          }

          <div className="row">
            {props.panel.fields.map((field) => {
              const panelField = field;
              panelField.path = panelField.path.replace(/(\d+)/, props.panel.index);
              if (panelField.display) {
                return (<div key={panelField.id} className={`col-sm-${panelField.cols}`}>
                  <PanelContentsWithConditionalRendering field={field} time={false} {...props} />
                </div>
                );
              }
            },
               )}
          </div>
          {!props.panel.table && props.edit ?
            <div className="row">
              <ActionBar {...props} />
            </div>
        : null}
        </ModalBody>
      </Modal>

      {/* // TODO consolidate this into one component used by both CardPanel and TablePanel and any other subsequent components that need a delete function */}
      <Modal isOpen={props.panel.deleteModalOpen}>
        <ModalHeader toggle={() => deleteClickHandler(0, props.formBuilder, props.panel, props.updateFormBuilder, false)}>Delete Item</ModalHeader>
        <ModalBody><p>Deleting this will remove all record of it from your tank history.</p>
          <p><strong>Are you sure you want to delete this item?</strong></p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" outline onClick={() => deleteClickHandler(0, props.formBuilder, props.panel, props.updateFormBuilder, false)}>Cancel</Button>
          {' '}
          <Button color="primary" size="sm" onClick={() => onDelete(props.target, props.panel, props.deleteItem)}>Delete Item</Button>
        </ModalFooter>
      </Modal>
    </div>
  </div>;


CardPanel.propTypes = {
  panel: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  onCancelPanel: PropTypes.func.isRequired,
  prepareTableData: PropTypes.func.isRequired,
  updateFormBuilder: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  toggleEditFormBuilderPanel: PropTypes.func.isRequired,
  formBuilder: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
};

export default CardPanel;

