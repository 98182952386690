import React from 'react';
import PropTypes from 'prop-types';
import FilterItemList from '../FilterItemList';

const SelectedLabels = ({ selectedSiteLabels, disabled, deselectSiteLabel }) => <FilterItemList items={selectedSiteLabels} textField="labelText" disabled={disabled} onRemoveItem={deselectSiteLabel} />;

SelectedLabels.propTypes = {
  selectedSiteLabels: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool.isRequired,
  deselectSiteLabel: PropTypes.func.isRequired,
};

SelectedLabels.defaultProps = {
  selectedSiteLabels: [],
};

export default SelectedLabels;
